import type { PropsWithChildren, AnchorHTMLAttributes } from 'react'
import { NavLink, Link as RRLink } from 'react-router-dom'

import {
  type Tracking,
  mapTrackingKeysToDataAttributes,
} from '../../../../utils/tracking-utils'

// TODO: This is domain specific and should not be in equity,
// instead either the component should be moved to the domain-specific folder
// or app paths should be passed as a prop
// For now putting the list here as we need to have the all-shared lib ready asap
const APP_PATHS = [
  'access-manager',
  'managed-file-transfer',
  'ads',
  'custodian-manager',
  'container',
  'document-library',
  'document-tagging',
  'fund-manager',
  'funds-trade',
  'funds-pre-trade',
  'funds-product-master',
  'home-office-insights',
  'homepage',
  'investor-profile',
  'product-master',
  'structured-products',
  'structured-investments',
  'systems-manager',
  'model-portfolios',
  'downstream-data',
  'market-data',
] as const

const httpRegex = /^(https?:)?\/\//i

export const isExternalLink = (link: string): boolean => httpRegex.test(link)

export type LinkProps = Tracking &
  PropsWithChildren<{
    /** The URL of the link */
    href: string
    /** Additional CSS classes */
    className?: string
    /** If the link is external or internal */
    external?: boolean
    /** The onClick event to run while redirecting */
    onClick?: AnchorHTMLAttributes<HTMLAnchorElement>['onClick']
    /** If the link should open in a new window */
    openInNewWindow?: boolean
    /** @deprecated Prefer `*ByRole('link', {name: [accessible name]})` instead */
    testId?: string
    /** If the link is a nav link */
    isNav?: boolean
    title?: string
  }>

/**
 * Link component that detects external link based on a simple regex (so this may need to be enhanced)
 * and returns an internal or external link.
 */
export function Link(props: LinkProps) {
  const {
    children = '',
    href,
    isNav = false,
    openInNewWindow,
    tracking,
    title,
  } = props
  const external = props.external || isExternalLink(href)
  const currentAPP = window.location.pathname.split('/')[1]
  const appPathsWithoutCurrent = APP_PATHS.filter((path) => path !== currentAPP)
  let hrefBasepath = href.startsWith('/') ? href.substring(1) : href
  hrefBasepath = hrefBasepath.split('/')[0]
  const isCrossAppLink = !!appPathsWithoutCurrent.find(
    (path) => hrefBasepath === path
  )

  const linkAttributes = {
    className: props.className ?? undefined,
    onClick: props.onClick ?? undefined,
    'data-testid': props.testId ?? undefined,
    title,
    ...mapTrackingKeysToDataAttributes({
      status: 'on',
      click_type: 'Link',
      ...tracking,
    }),
  }

  if (external || isCrossAppLink) {
    return (
      <a
        href={href}
        rel="noreferrer noopener"
        target={openInNewWindow === false ? undefined : '_blank'}
        {...linkAttributes}
      >
        {children}
      </a>
    )
  }

  if (isNav) {
    return (
      <NavLink
        to={href}
        target={openInNewWindow ? '_blank' : undefined}
        {...linkAttributes}
      >
        {children}
      </NavLink>
    )
  }
  return (
    <RRLink
      to={href}
      target={openInNewWindow ? '_blank' : undefined}
      {...linkAttributes}
    >
      {children}
    </RRLink>
  )
}
