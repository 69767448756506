import { FacebookIcon, LinkedInIcon, XIcon } from './icons'

const legacyCaisLinks = [
  { title: 'Terms', href: 'https://www.caisgroup.com/legal/terms-of-use' },
  {
    title: 'Privacy Policy',
    href: 'https://www.caisgroup.com/legal/privacy-policy',
  },
  {
    title: 'Finra Brokercheck©',
    href: 'https://brokercheck.finra.org/?_ga=2.237142720.1949434765.1592849053-1685646890.1560532187#sthash.GDCHm7oa.dpuf',
  },
  {
    title: 'Shareholder Login',
    href: 'https://services.intralinks.com/login/?_ga=2.78103632.532057894.1596720552-1434804984.1596720552',
  },
]

const caisLinks = [
  ...legacyCaisLinks,
  {
    title: 'Disclosure',
    href: '/homepage/disclosure',
  },
]

const socialLinks = [
  {
    href: 'https://www.facebook.com/CAISGroup/',
    icon: FacebookIcon,
    ariaLabel: 'Connect with us on Facebook',
  },
  {
    href: 'https://twitter.com/CAISgroup',
    icon: XIcon,
    ariaLabel: 'Follow us on X',
  },
  {
    href: 'https://www.linkedin.com/company/cais',
    icon: LinkedInIcon,
    ariaLabel: 'Connect with us on LinkedIn',
  },
]

export const Footer = () => {
  return (
    <footer
      className="mt-auto"
      data-testid="footer-bottom-content"
      role="contentinfo"
    >
      <ul className="small flex w-full flex-col justify-between gap-x-24 gap-y-16 bg-neutral-900 px-32 py-32 md:flex-row md:items-center md:py-[28px]">
        <li className="order-2 flex flex-col flex-wrap gap-y-8 md:order-1 md:flex-row md:items-center md:justify-start">
          {caisLinks.map((link, index) => (
            <a
              key={link.href}
              className="caption-mono duration-short-1 ease-standard text-neutral-400 transition hover:text-neutral-500"
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link.title}
              {index !== caisLinks.length - 1 ? (
                <span className="caption-mono mr-8 text-neutral-400">,</span>
              ) : null}
            </a>
          ))}
        </li>
        <li className="order-1 flex grow items-center gap-x-24 md:order-2 md:justify-end">
          {socialLinks.map((link) => (
            <a
              className="group"
              target="_blank"
              key={link.href}
              href={link.href}
              aria-label={link.ariaLabel}
              rel="noopener noreferrer"
            >
              {link.icon()}
            </a>
          ))}
        </li>
        <li className="caption-mono order-2 text-neutral-400 md:order-3">
          {`© ${new Date().getFullYear()} Capital Integration Systems LLC`}
        </li>
      </ul>
    </footer>
  )
}
