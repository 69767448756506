import { match } from 'ts-pattern'

import { ContentfulBreadcrumbs as Breadcrumbs } from '@cais-group/shared/ui/contentful/breadcrumb'

import { APP_URL } from '../../../utils/common/constants'

export interface PageBreadcrumbsProps {
  status: 'loading' | 'error' | 'success' | 'idle'
  accessStatus?: 'granted' | 'denied' | 'not-found'
  breadcrumbs: {
    category?: string | null
    currentPage?: string | null
    levelOneOnly?: boolean
  }
}

export const PageBreadcrumbs = ({
  status,
  accessStatus,
  breadcrumbs,
}: PageBreadcrumbsProps) => {
  return (
    <>
      {match({ status, accessStatus, breadcrumbs })
        .with(
          {
            status: 'success',
            accessStatus: 'granted',
          },
          ({ breadcrumbs }) => (
            <Breadcrumbs
              baseUrl={APP_URL}
              category={breadcrumbs.category || ''}
              currentPage={breadcrumbs.currentPage || ''}
              levelOneOnly={breadcrumbs.levelOneOnly}
            />
          )
        )
        .otherwise(() => null)}
    </>
  )
}
