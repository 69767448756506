import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@mui/material'
import cx from 'classnames'
import { supportsTouchEvents } from 'detect-it'
import { useState } from 'react'

import { TouchEventTooltip } from './touchscreen'

type TooltipSize = 'large' | 'small'

type TooltipColor = 'light' | 'dark'

export type TooltipProps = {
  /** Where the tooltip appears relative to child element */
  placement?: 'top' | 'left' | 'right' | 'bottom'
  /** Font-size of the tooltip contents */
  size?: TooltipSize
  /** Allows the tooltip to follow the cursor, within the bounds of the child element  */
  followCursor?: boolean
  /** Content the tooltip relates to */
  children: React.ReactElement
  /** The tooltip text  */
  title: React.ReactElement | string
  /** The tooltip background color  */
  color?: TooltipColor
  /** Disables the portal behavior of the tooltip (which is required when showing a tooltip in a <dialog> element) */
  disablePortal?: boolean
  /** Controls whether the tooltip is visible or not  */
  open?: boolean
}

/**
 * Tooltips display informative text when users hover over, focus on, or tap an element.
 *
 * The element which it is applied to must export standard props and forward ref (so care must be taken applying to e.g. Equity components with a more streamlined API). See Prebuilt versions for applying to Icons.
 */
export const Tooltip = ({
  placement = 'right',
  size = 'large',
  children,
  title,
  color = 'dark',
  followCursor,
  disablePortal,
  open: externalOpen,
}: TooltipProps) => {
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  const commonTooltipProps: Omit<MuiTooltipProps, 'children'> = {
    arrow: true,
    title,
    classes: {
      tooltip: cx('py-8 px-16 !rounded-none w-max', {
        '!small': size === 'large',
        '!caption': size === 'small',
        '!bg-neutral-900 !text-neutral-0': color === 'dark',
        '!bg-neutral-0 !text-neutral-900 !shadow-5': color === 'light',
      }),
      arrow: cx({
        '!text-neutral-900': color === 'dark',
        '!text-neutral-0': color === 'light',
      }),
    },
    placement,
    followCursor,
  }

  if (supportsTouchEvents) {
    return (
      <TouchEventTooltip
        {...commonTooltipProps}
        handleTooltipOpen={handleTooltipOpen}
        handleTooltipClose={handleTooltipClose}
        open={open}
        externalOpen={externalOpen}
      >
        {children}
      </TouchEventTooltip>
    )
  }

  return (
    <MuiTooltip
      {...commonTooltipProps}
      open={externalOpen}
      PopperProps={{ disablePortal }}
    >
      {children}
    </MuiTooltip>
  )
}
