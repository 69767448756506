import { BaseDatum, SegmentItem, SegmentSpec } from '../types'

export function labelLayouts<Datum extends BaseDatum>({
  // For now I'm not even passing in labels... need to figure that out/how will reference etc
  segments: rawSegments,
  radius,
  center,
  maxLabelsPerSide,
  minLabelWidth,
  threshold,
  width,
  height,
  spacing,
  labelSpacing,
}: {
  segments: SegmentItem<Datum>[]
  radius: number
  center: [number, number]
  maxLabelsPerSide: number
  minLabelWidth: number
  threshold?: number
  height: number
  width: number
  spacing: number
  labelSpacing: number
}): SegmentSpec<Datum>[] {
  const availableSpaceOnEachSide = width / 2 - radius - labelSpacing - spacing

  //   If too little space we do zero labels
  if (availableSpaceOnEachSide < minLabelWidth) return []

  //   This is special case/intrinsically different as we have circle, with label on rhs, but point line goes to is actual center of circle
  if (rawSegments.length === 1) {
    return [
      {
        item: rawSegments[0].item,
        position: [center[0] + radius + labelSpacing, center[1]],
        rightHandSide: true,
        width: availableSpaceOnEachSide,
        height: height - spacing * 2,
        centroid: center,
        proportion: 1,
        index: 0,
      },
    ]
  }

  // If we pass in threshold only include labels of greater size
  let segments = rawSegments
  if (typeof threshold === 'number') {
    segments = segments.filter(({ proportion }) => proportion > threshold)

    if (threshold > 0.1 || threshold < 0.001) {
      console.warn(
        `Threshold value of ${threshold} is unusual. This is the proportion of the overall chart above which we show a label by default`
      )
    }
  }

  let segmentsToLabel: SegmentItem<Datum>[]

  const countOnRight = segments.filter((s) => s.rightHandSide).length
  const countOnLeft = segments.filter((s) => !s.rightHandSide).length

  if (countOnRight <= maxLabelsPerSide && countOnLeft <= maxLabelsPerSide) {
    // nice case, allowed number already on both sides
    segmentsToLabel = segments
  } else {
    // we want to show as many labels as will fit, however we won't show things labelled on LHS from RHS as this ends up looking weird; define a m which is the max we'll select from RHS that will fit on RHS (we still include RHS on LHS if necessary)
    const m = Math.min(countOnRight, maxLabelsPerSide)

    segmentsToLabel = [
      ...segments.slice(0, m).map((s) => ({ ...s, rightHandSide: true })),
      ...segments
        .slice(m, m + maxLabelsPerSide)
        .map((s) => ({ ...s, rightHandSide: false })),
    ]
  }

  //   Now we have the actual segments we will display:
  const segmentsOnRight = segmentsToLabel.filter((s) => s.rightHandSide)
  const segmentsOnLeft = segmentsToLabel.filter((s) => !s.rightHandSide)

  // Now the complicated transforms, which may need to take account of number on each side

  return [
    ...positionSpecs({
      segments: segmentsOnRight,
      rightHandSide: true,
      width,
      height,
      radius,
      center,
      spacing,
      labelSpacing,
    }),
    ...positionSpecs({
      segments: segmentsOnLeft.reverse(),
      rightHandSide: false,
      width,
      height,
      radius,
      center,
      spacing,
      labelSpacing,
    }),
  ]
}

export function positionSpecs<Datum extends BaseDatum>({
  segments,
  rightHandSide,
  radius,
  center,
  height,
  spacing,
  labelSpacing,
}: {
  radius: number
  center: [number, number]
  height: number
  width: number
  segments: SegmentItem<Datum>[]
  //   technically redundant in most cases, but means this code can work with 0 segments
  rightHandSide: boolean
  spacing: number
  labelSpacing: number
}): SegmentSpec<Datum>[] {
  const n = segments.length
  if (n === 0) return []

  const availableHeight = height - spacing * 2

  //   we work with the vertical center of labels
  const sY = availableHeight / (2 * n) + spacing
  const dY = availableHeight / n

  const itemWidth = center[0] - radius - labelSpacing

  const calcX = (y: number) => {
    const pieWidthAtY = Math.sqrt(radius ** 2 - (y - center[1]) ** 2)
    return center[0] + (rightHandSide ? 1 : -1) * (pieWidthAtY + labelSpacing)
  }

  return segments.map(({ centroid, item, proportion, index }, i) => ({
    item,
    index,
    position: [calcX(sY + i * dY), sY + i * dY],
    rightHandSide,
    width: itemWidth,
    height: dY,
    centroid,
    proportion,
  }))
}

export type LabelLayoutsParameters<Extra> = Parameters<
  typeof labelLayouts<Extra & BaseDatum>
>
