import type { Options } from '@contentful/rich-text-react-renderer'
import { createElement, type ReactNode } from 'react'

import { BodyLinks } from '../../../../util/type/body'
import { AssetMap, EntryMap } from '../../../../util/type/rich-text-json'

import { richTextConfig } from './rich-text-base-components'
import { Override, Overrides } from './types'

type LinkItems =
  | BodyLinks['assets']['block']
  | BodyLinks['entries']['block']
  | BodyLinks['entries']['hyperlink']

export function assetsAndEntriesById(links?: BodyLinks) {
  const { assets } = links || {}
  const assetMap = getItemsById(assets?.block) as AssetMap
  const entryMap = entriesById(links) as EntryMap

  return {
    assetMap,
    entryMap,
  }
}

function entriesById(links?: BodyLinks) {
  const { entries } = links || {}

  let entryMap = getItemsById(entries?.block)
  entryMap = getItemsById(entries?.inline, entryMap)
  entryMap = getItemsById(entries?.hyperlink, entryMap)
  return entryMap
}

function getItemsById(linkItems?: LinkItems, initial?: AssetMap | EntryMap) {
  const byId = initial || new Map()

  if (!linkItems) {
    return byId
  }

  for (const link of linkItems) {
    if (link?.sys?.id) {
      byId.set(link.sys.id, link)
    }
  }

  return byId
}

export type RichTextElementProps = Override['props'] & {
  tag: keyof Overrides
  customTag?: Override['customTag']
}

export function makeRichTextElement(
  children: ReactNode,
  args: RichTextElementProps & {
    props?: Override['props']
  }
) {
  // if a component is provided, it will override the type of element being created for the overridden node
  const { tag, customTag, props: overrideProps, ...rest } = args

  const Element = createElement(
    customTag || tag,
    overrideProps || rest,
    children
  )
  return Element
}

export function mergeOptions(options: {
  renderNode: Options['renderNode']
  overrides?: Overrides
}) {
  const { overrides, renderNode } = options
  if (!overrides) return renderNode
  const newRenderNode = { ...renderNode }
  for (const [tag, override] of Object.entries(overrides)) {
    const nodeTag = richTextConfig[tag as keyof typeof richTextConfig]?.block
    const { component: Component, ...rest } = override
    if (nodeTag) {
      newRenderNode[nodeTag] = (_, children) =>
        Component ? (
          <Component>{children}</Component>
        ) : (
          makeRichTextElement(children, {
            ...rest,
            tag: tag as keyof Overrides,
          })
        )
    }
  }
  return newRenderNode
}
