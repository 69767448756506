import { useQuery } from 'react-query'

import { getLegalPageBySlug } from '@cais-group/shared/domain/contentful/api'

import {
  PageBreadcrumbs,
  PageTemplate,
} from '../../ui/components/page-template'

import { DisclosurePage } from './disclosure'

export const DISCLOSURE_PAGE_SLUG = 'disclosure'

export function DisclosurePageContainer() {
  const { data: { legalPage } = {}, status } = useQuery(
    ['legalPage', DISCLOSURE_PAGE_SLUG],
    () => getLegalPageBySlug(DISCLOSURE_PAGE_SLUG)
  )

  return (
    <>
      <PageBreadcrumbs
        status={status}
        accessStatus="granted"
        breadcrumbs={{
          currentPage: 'Disclosure',
          levelOneOnly: true,
        }}
      />
      <PageTemplate
        status={status}
        accessStatus="granted" // Legal pages don't have access controls
        data={legalPage}
        title="Disclosure"
        renderContent={(data) => <DisclosurePage data={data} />}
      />
    </>
  )
}
