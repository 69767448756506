/* eslint react-hooks/exhaustive-deps: 0 */
/* eslint @nx/enforce-module-boundaries: 0 */
import { useMemo, useState } from 'react'

import { HouseholdResponseV1 } from '@cais-group/shared/domain/investor-profile'

// eslint-disable-next-line @nx/enforce-module-boundaries

import { HouseHoldings } from './houseHoldings'
import { HouseholdCard } from './householdCard'

type Props = {
  households: HouseholdResponseV1[]
  searchText?: string
}

export const Households = ({ households, searchText }: Props) => {
  const [selectedHouseholdId, setSelectedHouseholdId] = useState<
    string | undefined
  >(households.length === 1 ? households[0].id : undefined)

  const stats = useMemo((): [number, number, number] => {
    let first = Math.floor(Math.random() * 100)
    let second = Math.floor(Math.random() * 100)
    if (first + second > 100) {
      first = Math.floor(first / 2)
      second = Math.floor(second / 2)
    }
    return [first, second, 100 - first - second]
  }, [selectedHouseholdId])

  const gains = useMemo(() => {
    const first = Math.floor(Math.random() * 40)
    const second = Math.floor(Math.random() * 15)

    return Math.round(first - second * 10) / 10
  }, [selectedHouseholdId])

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-4 gap-24 [@media(min-width:2080px)]:grid-cols-5">
        {households.length > 1 && (
          <>
            <h4 className="small-strong col-span-full">Household</h4>
            {households.slice(0, 4).map((household) => {
              return (
                <HouseholdCard
                  key={household.id}
                  household={household}
                  isSelected={selectedHouseholdId === household.id}
                  onClick={() => setSelectedHouseholdId(household.id)}
                  highlight={searchText}
                />
              )
            })}
          </>
        )}
        {selectedHouseholdId && (
          <div className="col-span-full">
            <HouseHoldings
              householdId={selectedHouseholdId}
              gains={gains}
              stats={stats}
            />
          </div>
        )}
      </div>
    </div>
  )
}
