import { AnalyticsBrowser } from '@segment/analytics-next'
import { ReactNode, useEffect, useMemo, createContext, useRef } from 'react'

import { getEnvConfig } from '../../../ui/env'

export const SegmentContext = createContext<AnalyticsBrowser | undefined>(
  undefined
)

export type SegmentProviderProps = {
  children: ReactNode
  identity: {
    id?: string
    name?: string
    email?: string
    firmId?: string | number
    firmName?: string
  }
  traits?: Record<string, string>
  apiKey?: string
  isAnalyticsDisabled?: boolean
}

export const SegmentProvider = ({
  children,
  identity,
  traits = {},
  apiKey,
  isAnalyticsDisabled = false,
}: SegmentProviderProps) => {
  const config = getEnvConfig()

  const identityId = useRef<string>(identity.id || '')
  const segmentAnalytics = useMemo(() => {
    if (isAnalyticsDisabled || (!apiKey && !config?.SEGMENT_API_KEY)) {
      return undefined
    }
    return AnalyticsBrowser.load(
      {
        writeKey: apiKey || config.SEGMENT_API_KEY,
      },
      {
        integrations: {
          'Segment.io': {
            deliveryStrategy: {
              config: {
                keepalive: true,
                retries: true,
              },
            },
          },
        },
      }
    )
  }, [config, apiKey, isAnalyticsDisabled])

  useEffect(() => {
    if (isAnalyticsDisabled) {
      return
    }
    if (identity.id && identity.id !== identityId.current && segmentAnalytics) {
      identityId.current = identity.id
      segmentAnalytics.identify(identityId.current, {
        ...traits,
        name: identity.name,
        email: identity.email,
      })
      identity?.firmId &&
        segmentAnalytics.group(String(identity.firmId), {
          name: identity.firmName,
        })
    }
  }, [identity, identityId, segmentAnalytics, traits, isAnalyticsDisabled])

  return (
    <SegmentContext.Provider value={segmentAnalytics}>
      {children}
    </SegmentContext.Provider>
  )
}
