/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Funds Investments API
 * A service for managing positions and transactions.

 * OpenAPI spec version: v1
 */

import { useInfiniteQuery, useMutation, useQuery } from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'

import { fetchInstance, ErrorType } from '@cais-group/shared/util/fetch'

import type {
  ActionResults,
  ApprovedExceptionsRequest,
  ApprovedExceptionsResponse,
  AuditInfo,
  BatchIdentifier,
  CreatePositionRequest,
  DataPipelineFilesRequest,
  DataPipelineFilesResponse,
  DataPipelineFilterFundAdminsResponse,
  ExternalIds,
  FileUploadFundAdminTemplateResponse,
  FileUploadFundResponse,
  FilterOptionsAccountsResponse,
  FilterOptionsAdvisorFirmsResponse,
  FilterOptionsFundsResponse,
  FilterOptionsHouseholdsResponse,
  FundsConfig,
  GetAuditInfoParams,
  GetBatchInfoParams,
  GetPositionAccountingLotValuationsBatchInfoParams,
  GetRawPositionAccountingLotValuationAuditInfoParams,
  GetRawPositionAccountingLotValuationBatchInfoParams,
  GetRawPositionAccountingLotValuationsBatchParams,
  GetRawTransactionsBatchParams,
  Investment,
  OrderExecutions,
  PatchBody,
  Position,
  PositionAccountingLot,
  PositionAccountingLotValuation,
  PositionAccountingLotValuations,
  PositionFundMappingRequest,
  PositionShareClassMappingRequest,
  PostDataTaskLogBody,
  PublishPositionAccountingLotValuationResults,
  PublishPositionAccountingLotValuationsRequest,
  PublishTransactionsRequest,
  RawBatchInfoList,
  RawBatchInfoOutbound,
  RawPositionAccountingLotValuationsBatch,
  RawPositionAccountingLotValuationsBatchOutbound,
  RawTransactionsBatch,
  RawTransactionsBatchOutbound,
  SearchDataPipelineRequest,
  SearchDataPipelineResponse,
  SearchFlatTransactionsResponse,
  SearchInvestmentsRequest,
  SearchInvestmentsResponse,
  SearchPositionAccountingLotValuationsRequest,
  SearchPositionAccountingLotValuationsResponse,
  SearchPositionAccountingLotValuationsWithExpandedReferenceResponse,
  SearchPositionAccountingLotsRequest,
  SearchPositionAccountingLotsResponse,
  SearchPositionsRequest,
  SearchPositionsResponse,
  SearchRawPositionAccountingLotValuationsRequest,
  SearchRawPositionAccountingLotValuationsResponse,
  SearchRawTransactionsRequest,
  SearchRawTransactionsResponse,
  SearchTransactionsRequest,
  SearchTransactionsResponse,
  Transaction,
  Transactions,
  TransactionsResults,
} from './funds-investments-api.schemas'

/**
 * Returns a list of batch objects containing batch metadata including the id and version.
 * @summary Get all raw transaction batches
 */
export const listRawTransactionsBatchIds = (signal?: AbortSignal) => {
  return fetchInstance<RawBatchInfoList>({
    url: `/funds-investments/v1/transactions/raw/batch`,
    method: 'GET',
    signal,
  })
}

export const getListRawTransactionsBatchIdsQueryKey = () => {
  return [`/funds-investments/v1/transactions/raw/batch`] as const
}

export const getListRawTransactionsBatchIdsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listRawTransactionsBatchIds>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listRawTransactionsBatchIds>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getListRawTransactionsBatchIdsQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listRawTransactionsBatchIds>>
  > = ({ signal }) => listRawTransactionsBatchIds(signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listRawTransactionsBatchIds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ListRawTransactionsBatchIdsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof listRawTransactionsBatchIds>>
>
export type ListRawTransactionsBatchIdsInfiniteQueryError = ErrorType<void>

/**
 * @summary Get all raw transaction batches
 */
export const useListRawTransactionsBatchIdsInfinite = <
  TData = Awaited<ReturnType<typeof listRawTransactionsBatchIds>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listRawTransactionsBatchIds>>,
    TError,
    TData
  >
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getListRawTransactionsBatchIdsInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getListRawTransactionsBatchIdsQueryOptions = <
  TData = Awaited<ReturnType<typeof listRawTransactionsBatchIds>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listRawTransactionsBatchIds>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getListRawTransactionsBatchIdsQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listRawTransactionsBatchIds>>
  > = ({ signal }) => listRawTransactionsBatchIds(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listRawTransactionsBatchIds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ListRawTransactionsBatchIdsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listRawTransactionsBatchIds>>
>
export type ListRawTransactionsBatchIdsQueryError = ErrorType<void>

/**
 * @summary Get all raw transaction batches
 */
export const useListRawTransactionsBatchIds = <
  TData = Awaited<ReturnType<typeof listRawTransactionsBatchIds>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listRawTransactionsBatchIds>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListRawTransactionsBatchIdsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Upload a batch of raw transactions. Uses information in .batch to determine whether to create a new batch or new version of an existing batch. e.g. When the source type is API, if a batch with the same value for the combination of externalId and fundAdminId then a new version of that batch will be created, otherwise a new batch will be created with a unique id and version equal to 1 The batch must contain more than zero transactions.

 * @summary Create a raw transaction batch
 */
export const uploadRawTransactionsBatch = (
  rawTransactionsBatch: RawTransactionsBatch
) => {
  return fetchInstance<BatchIdentifier>({
    url: `/funds-investments/v1/transactions/raw/batch`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: rawTransactionsBatch,
  })
}

export const getUploadRawTransactionsBatchMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadRawTransactionsBatch>>,
    TError,
    { data: RawTransactionsBatch },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadRawTransactionsBatch>>,
  TError,
  { data: RawTransactionsBatch },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadRawTransactionsBatch>>,
    { data: RawTransactionsBatch }
  > = (props) => {
    const { data } = props ?? {}

    return uploadRawTransactionsBatch(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UploadRawTransactionsBatchMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadRawTransactionsBatch>>
>
export type UploadRawTransactionsBatchMutationBody = RawTransactionsBatch
export type UploadRawTransactionsBatchMutationError = ErrorType<void>

/**
 * @summary Create a raw transaction batch
 */
export const useUploadRawTransactionsBatch = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadRawTransactionsBatch>>,
    TError,
    { data: RawTransactionsBatch },
    TContext
  >
}) => {
  const mutationOptions = getUploadRawTransactionsBatchMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Returns a batch of raw transactions.
 * @summary Get all transactions in a raw batch
 */
export const getRawTransactionsBatch = (
  batchId: string,
  params?: GetRawTransactionsBatchParams,
  signal?: AbortSignal
) => {
  return fetchInstance<RawTransactionsBatchOutbound>({
    url: `/funds-investments/v1/transactions/raw/batch/${batchId}`,
    method: 'GET',
    params,
    signal,
  })
}

export const getGetRawTransactionsBatchQueryKey = (
  batchId: string,
  params?: GetRawTransactionsBatchParams
) => {
  return [
    `/funds-investments/v1/transactions/raw/batch/${batchId}`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetRawTransactionsBatchInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getRawTransactionsBatch>>,
  TError = ErrorType<void>
>(
  batchId: string,
  params?: GetRawTransactionsBatchParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getRawTransactionsBatch>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetRawTransactionsBatchQueryKey(batchId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getRawTransactionsBatch>>
  > = ({ signal }) => getRawTransactionsBatch(batchId, params, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!batchId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getRawTransactionsBatch>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetRawTransactionsBatchInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRawTransactionsBatch>>
>
export type GetRawTransactionsBatchInfiniteQueryError = ErrorType<void>

/**
 * @summary Get all transactions in a raw batch
 */
export const useGetRawTransactionsBatchInfinite = <
  TData = Awaited<ReturnType<typeof getRawTransactionsBatch>>,
  TError = ErrorType<void>
>(
  batchId: string,
  params?: GetRawTransactionsBatchParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getRawTransactionsBatch>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRawTransactionsBatchInfiniteQueryOptions(
    batchId,
    params,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetRawTransactionsBatchQueryOptions = <
  TData = Awaited<ReturnType<typeof getRawTransactionsBatch>>,
  TError = ErrorType<void>
>(
  batchId: string,
  params?: GetRawTransactionsBatchParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRawTransactionsBatch>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetRawTransactionsBatchQueryKey(batchId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getRawTransactionsBatch>>
  > = ({ signal }) => getRawTransactionsBatch(batchId, params, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!batchId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getRawTransactionsBatch>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetRawTransactionsBatchQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRawTransactionsBatch>>
>
export type GetRawTransactionsBatchQueryError = ErrorType<void>

/**
 * @summary Get all transactions in a raw batch
 */
export const useGetRawTransactionsBatch = <
  TData = Awaited<ReturnType<typeof getRawTransactionsBatch>>,
  TError = ErrorType<void>
>(
  batchId: string,
  params?: GetRawTransactionsBatchParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRawTransactionsBatch>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRawTransactionsBatchQueryOptions(
    batchId,
    params,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Search raw transactions, returning a paginated response. When the format is set to XLSX in the request then pagination parameters will be ignored and the response with be streamed as an XLSX file.

 * @summary Search for transactions in a raw batch
 */
export const searchRawTransactionsInBatches = (
  batchId: string,
  searchRawTransactionsRequest: SearchRawTransactionsRequest
) => {
  return fetchInstance<SearchRawTransactionsResponse | Blob>({
    url: `/funds-investments/v1/transactions/raw/batch/${batchId}/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchRawTransactionsRequest,
  })
}

export const getSearchRawTransactionsInBatchesQueryKey = (
  batchId: string,
  searchRawTransactionsRequest: SearchRawTransactionsRequest
) => {
  return [
    `/funds-investments/v1/transactions/raw/batch/${batchId}/search`,
    searchRawTransactionsRequest,
  ] as const
}

export const getSearchRawTransactionsInBatchesQueryOptions = <
  TData = Awaited<ReturnType<typeof searchRawTransactionsInBatches>>,
  TError = ErrorType<void>
>(
  batchId: string,
  searchRawTransactionsRequest: SearchRawTransactionsRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchRawTransactionsInBatches>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getSearchRawTransactionsInBatchesQueryKey(
      batchId,
      searchRawTransactionsRequest
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchRawTransactionsInBatches>>
  > = () =>
    searchRawTransactionsInBatches(batchId, searchRawTransactionsRequest)

  return {
    queryKey,
    queryFn,
    enabled: !!batchId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof searchRawTransactionsInBatches>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchRawTransactionsInBatchesQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchRawTransactionsInBatches>>
>
export type SearchRawTransactionsInBatchesQueryError = ErrorType<void>

/**
 * @summary Search for transactions in a raw batch
 */
export const useSearchRawTransactionsInBatches = <
  TData = Awaited<ReturnType<typeof searchRawTransactionsInBatches>>,
  TError = ErrorType<void>
>(
  batchId: string,
  searchRawTransactionsRequest: SearchRawTransactionsRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchRawTransactionsInBatches>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchRawTransactionsInBatchesQueryOptions(
    batchId,
    searchRawTransactionsRequest,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Will return batch Info for the given batch ID.
 * @summary Get batch info for a raw transaction batch
 */
export const getBatchInfo = (
  batchId: string,
  params?: GetBatchInfoParams,
  signal?: AbortSignal
) => {
  return fetchInstance<RawBatchInfoOutbound>({
    url: `/funds-investments/v1/transactions/raw/batch/${batchId}/info`,
    method: 'GET',
    params,
    signal,
  })
}

export const getGetBatchInfoQueryKey = (
  batchId: string,
  params?: GetBatchInfoParams
) => {
  return [
    `/funds-investments/v1/transactions/raw/batch/${batchId}/info`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetBatchInfoInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getBatchInfo>>,
  TError = ErrorType<void>
>(
  batchId: string,
  params?: GetBatchInfoParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getBatchInfo>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetBatchInfoQueryKey(batchId, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBatchInfo>>> = ({
    signal,
  }) => getBatchInfo(batchId, params, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!batchId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getBatchInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetBatchInfoInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBatchInfo>>
>
export type GetBatchInfoInfiniteQueryError = ErrorType<void>

/**
 * @summary Get batch info for a raw transaction batch
 */
export const useGetBatchInfoInfinite = <
  TData = Awaited<ReturnType<typeof getBatchInfo>>,
  TError = ErrorType<void>
>(
  batchId: string,
  params?: GetBatchInfoParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getBatchInfo>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBatchInfoInfiniteQueryOptions(
    batchId,
    params,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetBatchInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getBatchInfo>>,
  TError = ErrorType<void>
>(
  batchId: string,
  params?: GetBatchInfoParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBatchInfo>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetBatchInfoQueryKey(batchId, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBatchInfo>>> = ({
    signal,
  }) => getBatchInfo(batchId, params, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!batchId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getBatchInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetBatchInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBatchInfo>>
>
export type GetBatchInfoQueryError = ErrorType<void>

/**
 * @summary Get batch info for a raw transaction batch
 */
export const useGetBatchInfo = <
  TData = Awaited<ReturnType<typeof getBatchInfo>>,
  TError = ErrorType<void>
>(
  batchId: string,
  params?: GetBatchInfoParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBatchInfo>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBatchInfoQueryOptions(batchId, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Will return audit info for the given batch ID and version.
 * @summary Get audit info for a raw transaction batch
 */
export const getAuditInfo = (
  batchId: string,
  params?: GetAuditInfoParams,
  signal?: AbortSignal
) => {
  return fetchInstance<AuditInfo>({
    url: `/funds-investments/v1/transactions/raw/batch/${batchId}/audit`,
    method: 'GET',
    params,
    signal,
  })
}

export const getGetAuditInfoQueryKey = (
  batchId: string,
  params?: GetAuditInfoParams
) => {
  return [
    `/funds-investments/v1/transactions/raw/batch/${batchId}/audit`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetAuditInfoInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getAuditInfo>>,
  TError = ErrorType<void>
>(
  batchId: string,
  params?: GetAuditInfoParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getAuditInfo>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetAuditInfoQueryKey(batchId, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAuditInfo>>> = ({
    signal,
  }) => getAuditInfo(batchId, params, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!batchId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getAuditInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAuditInfoInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAuditInfo>>
>
export type GetAuditInfoInfiniteQueryError = ErrorType<void>

/**
 * @summary Get audit info for a raw transaction batch
 */
export const useGetAuditInfoInfinite = <
  TData = Awaited<ReturnType<typeof getAuditInfo>>,
  TError = ErrorType<void>
>(
  batchId: string,
  params?: GetAuditInfoParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getAuditInfo>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAuditInfoInfiniteQueryOptions(
    batchId,
    params,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetAuditInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getAuditInfo>>,
  TError = ErrorType<void>
>(
  batchId: string,
  params?: GetAuditInfoParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAuditInfo>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetAuditInfoQueryKey(batchId, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAuditInfo>>> = ({
    signal,
  }) => getAuditInfo(batchId, params, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!batchId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAuditInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAuditInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAuditInfo>>
>
export type GetAuditInfoQueryError = ErrorType<void>

/**
 * @summary Get audit info for a raw transaction batch
 */
export const useGetAuditInfo = <
  TData = Awaited<ReturnType<typeof getAuditInfo>>,
  TError = ErrorType<void>
>(
  batchId: string,
  params?: GetAuditInfoParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAuditInfo>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAuditInfoQueryOptions(batchId, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Create, update or delete canonical transactions. - On update, the version must be provided inside the model, and externalIds should be null. 

 * @summary Create/update/delete transactions
 */
export const createUpdateOrDeleteTransactions = (
  transactions: Transactions
) => {
  return fetchInstance<TransactionsResults>({
    url: `/funds-investments/v1/transactions`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: transactions,
  })
}

export const getCreateUpdateOrDeleteTransactionsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUpdateOrDeleteTransactions>>,
    TError,
    { data: Transactions },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createUpdateOrDeleteTransactions>>,
  TError,
  { data: Transactions },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createUpdateOrDeleteTransactions>>,
    { data: Transactions }
  > = (props) => {
    const { data } = props ?? {}

    return createUpdateOrDeleteTransactions(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateUpdateOrDeleteTransactionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof createUpdateOrDeleteTransactions>>
>
export type CreateUpdateOrDeleteTransactionsMutationBody = Transactions
export type CreateUpdateOrDeleteTransactionsMutationError = ErrorType<void>

/**
 * @summary Create/update/delete transactions
 */
export const useCreateUpdateOrDeleteTransactions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createUpdateOrDeleteTransactions>>,
    TError,
    { data: Transactions },
    TContext
  >
}) => {
  const mutationOptions =
    getCreateUpdateOrDeleteTransactionsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Search transactions
 * @summary Search for transactions
 */
export const searchTransactions = (
  searchTransactionsRequest: SearchTransactionsRequest
) => {
  return fetchInstance<SearchTransactionsResponse>({
    url: `/funds-investments/v1/transactions/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchTransactionsRequest,
  })
}

export const getSearchTransactionsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchTransactions>>,
    TError,
    { data: SearchTransactionsRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchTransactions>>,
  TError,
  { data: SearchTransactionsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchTransactions>>,
    { data: SearchTransactionsRequest }
  > = (props) => {
    const { data } = props ?? {}

    return searchTransactions(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchTransactionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof searchTransactions>>
>
export type SearchTransactionsMutationBody = SearchTransactionsRequest
export type SearchTransactionsMutationError = ErrorType<void>

/**
 * @summary Search for transactions
 */
export const useSearchTransactions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchTransactions>>,
    TError,
    { data: SearchTransactionsRequest },
    TContext
  >
}) => {
  const mutationOptions = getSearchTransactionsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Search transactions, returning a paginated response. When the format is set to XLSX in the request then pagination parameters will be ignored and the response with be streamed as an XLSX file.

 * @summary Search for flattened transactions
 */
export const searchTransactionsReturnsFlatTransactionStructure = (
  searchTransactionsRequest: SearchTransactionsRequest
) => {
  return fetchInstance<SearchFlatTransactionsResponse>({
    url: `/funds-investments/v1/transactions/search.flat`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchTransactionsRequest,
  })
}

export const getSearchTransactionsReturnsFlatTransactionStructureQueryKey = (
  searchTransactionsRequest: SearchTransactionsRequest
) => {
  return [
    `/funds-investments/v1/transactions/search.flat`,
    searchTransactionsRequest,
  ] as const
}

export const getSearchTransactionsReturnsFlatTransactionStructureQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof searchTransactionsReturnsFlatTransactionStructure>
    >,
    TError = ErrorType<void>
  >(
    searchTransactionsRequest: SearchTransactionsRequest,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<typeof searchTransactionsReturnsFlatTransactionStructure>
        >,
        TError,
        TData
      >
    }
  ) => {
    const { query: queryOptions } = options ?? {}

    const queryKey =
      queryOptions?.queryKey ??
      getSearchTransactionsReturnsFlatTransactionStructureQueryKey(
        searchTransactionsRequest
      )

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof searchTransactionsReturnsFlatTransactionStructure>
      >
    > = () =>
      searchTransactionsReturnsFlatTransactionStructure(
        searchTransactionsRequest
      )

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<typeof searchTransactionsReturnsFlatTransactionStructure>
      >,
      TError,
      TData
    > & { queryKey: QueryKey }
  }

export type SearchTransactionsReturnsFlatTransactionStructureQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof searchTransactionsReturnsFlatTransactionStructure>
    >
  >
export type SearchTransactionsReturnsFlatTransactionStructureQueryError =
  ErrorType<void>

/**
 * @summary Search for flattened transactions
 */
export const useSearchTransactionsReturnsFlatTransactionStructure = <
  TData = Awaited<
    ReturnType<typeof searchTransactionsReturnsFlatTransactionStructure>
  >,
  TError = ErrorType<void>
>(
  searchTransactionsRequest: SearchTransactionsRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof searchTransactionsReturnsFlatTransactionStructure>
      >,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getSearchTransactionsReturnsFlatTransactionStructureQueryOptions(
      searchTransactionsRequest,
      options
    )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Publish transactions
 * @summary Publish transactions
 */
export const publishTransactions = (
  publishTransactionsRequest: PublishTransactionsRequest
) => {
  return fetchInstance<TransactionsResults>({
    url: `/funds-investments/v1/transactions/publish`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: publishTransactionsRequest,
  })
}

export const getPublishTransactionsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof publishTransactions>>,
    TError,
    { data: PublishTransactionsRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof publishTransactions>>,
  TError,
  { data: PublishTransactionsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof publishTransactions>>,
    { data: PublishTransactionsRequest }
  > = (props) => {
    const { data } = props ?? {}

    return publishTransactions(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PublishTransactionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof publishTransactions>>
>
export type PublishTransactionsMutationBody = PublishTransactionsRequest
export type PublishTransactionsMutationError = ErrorType<void>

/**
 * @summary Publish transactions
 */
export const usePublishTransactions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof publishTransactions>>,
    TError,
    { data: PublishTransactionsRequest },
    TContext
  >
}) => {
  const mutationOptions = getPublishTransactionsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Get a transaction external IDs.
 * @summary Get a transaction external IDs
 */
export const getTransactionExternalIds = (
  transactionId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<ExternalIds>({
    url: `/funds-investments/v1/transactions/${transactionId}/external-ids`,
    method: 'GET',
    signal,
  })
}

export const getGetTransactionExternalIdsQueryKey = (transactionId: string) => {
  return [
    `/funds-investments/v1/transactions/${transactionId}/external-ids`,
  ] as const
}

export const getGetTransactionExternalIdsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getTransactionExternalIds>>,
  TError = ErrorType<void>
>(
  transactionId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getTransactionExternalIds>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetTransactionExternalIdsQueryKey(transactionId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTransactionExternalIds>>
  > = ({ signal }) => getTransactionExternalIds(transactionId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!transactionId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getTransactionExternalIds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetTransactionExternalIdsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTransactionExternalIds>>
>
export type GetTransactionExternalIdsInfiniteQueryError = ErrorType<void>

/**
 * @summary Get a transaction external IDs
 */
export const useGetTransactionExternalIdsInfinite = <
  TData = Awaited<ReturnType<typeof getTransactionExternalIds>>,
  TError = ErrorType<void>
>(
  transactionId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getTransactionExternalIds>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTransactionExternalIdsInfiniteQueryOptions(
    transactionId,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetTransactionExternalIdsQueryOptions = <
  TData = Awaited<ReturnType<typeof getTransactionExternalIds>>,
  TError = ErrorType<void>
>(
  transactionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTransactionExternalIds>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetTransactionExternalIdsQueryKey(transactionId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTransactionExternalIds>>
  > = ({ signal }) => getTransactionExternalIds(transactionId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!transactionId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getTransactionExternalIds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetTransactionExternalIdsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTransactionExternalIds>>
>
export type GetTransactionExternalIdsQueryError = ErrorType<void>

/**
 * @summary Get a transaction external IDs
 */
export const useGetTransactionExternalIds = <
  TData = Awaited<ReturnType<typeof getTransactionExternalIds>>,
  TError = ErrorType<void>
>(
  transactionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTransactionExternalIds>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTransactionExternalIdsQueryOptions(
    transactionId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Update a transaction external IDs.
 * @summary Update a transaction external IDs
 */
export const updateTransactionExternalIds = (
  transactionId: string,
  externalIds: ExternalIds
) => {
  return fetchInstance<Transaction>({
    url: `/funds-investments/v1/transactions/${transactionId}/external-ids`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: externalIds,
  })
}

export const getUpdateTransactionExternalIdsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTransactionExternalIds>>,
    TError,
    { transactionId: string; data: ExternalIds },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTransactionExternalIds>>,
  TError,
  { transactionId: string; data: ExternalIds },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTransactionExternalIds>>,
    { transactionId: string; data: ExternalIds }
  > = (props) => {
    const { transactionId, data } = props ?? {}

    return updateTransactionExternalIds(transactionId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateTransactionExternalIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTransactionExternalIds>>
>
export type UpdateTransactionExternalIdsMutationBody = ExternalIds
export type UpdateTransactionExternalIdsMutationError = ErrorType<void>

/**
 * @summary Update a transaction external IDs
 */
export const useUpdateTransactionExternalIds = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTransactionExternalIds>>,
    TError,
    { transactionId: string; data: ExternalIds },
    TContext
  >
}) => {
  const mutationOptions =
    getUpdateTransactionExternalIdsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Delete a transaction external IDs.
 * @summary Delete a transaction external IDs
 */
export const deleteTransactionExternalIds = (transactionId: string) => {
  return fetchInstance<Transaction>({
    url: `/funds-investments/v1/transactions/${transactionId}/external-ids`,
    method: 'DELETE',
  })
}

export const getDeleteTransactionExternalIdsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTransactionExternalIds>>,
    TError,
    { transactionId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteTransactionExternalIds>>,
  TError,
  { transactionId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteTransactionExternalIds>>,
    { transactionId: string }
  > = (props) => {
    const { transactionId } = props ?? {}

    return deleteTransactionExternalIds(transactionId)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteTransactionExternalIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTransactionExternalIds>>
>

export type DeleteTransactionExternalIdsMutationError = ErrorType<void>

/**
 * @summary Delete a transaction external IDs
 */
export const useDeleteTransactionExternalIds = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTransactionExternalIds>>,
    TError,
    { transactionId: string },
    TContext
  >
}) => {
  const mutationOptions =
    getDeleteTransactionExternalIdsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Patch a transaction external IDs.
 * @summary Patch a transaction external IDs
 */
export const patchTransactionExternalIds = (
  transactionId: string,
  patchBody: PatchBody
) => {
  return fetchInstance<Transaction>({
    url: `/funds-investments/v1/transactions/${transactionId}/external-ids`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchBody,
  })
}

export const getPatchTransactionExternalIdsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchTransactionExternalIds>>,
    TError,
    { transactionId: string; data: PatchBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchTransactionExternalIds>>,
  TError,
  { transactionId: string; data: PatchBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchTransactionExternalIds>>,
    { transactionId: string; data: PatchBody }
  > = (props) => {
    const { transactionId, data } = props ?? {}

    return patchTransactionExternalIds(transactionId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchTransactionExternalIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchTransactionExternalIds>>
>
export type PatchTransactionExternalIdsMutationBody = PatchBody
export type PatchTransactionExternalIdsMutationError = ErrorType<void>

/**
 * @summary Patch a transaction external IDs
 */
export const usePatchTransactionExternalIds = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchTransactionExternalIds>>,
    TError,
    { transactionId: string; data: PatchBody },
    TContext
  >
}) => {
  const mutationOptions = getPatchTransactionExternalIdsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Get a sub-transaction external IDs.
 * @summary Get a sub-transaction external IDs
 */
export const getSubTransactionExternalIds = (
  transactionId: string,
  subTransactionId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<ExternalIds>({
    url: `/funds-investments/v1/transactions/${transactionId}/sub-transactions/${subTransactionId}/external-ids`,
    method: 'GET',
    signal,
  })
}

export const getGetSubTransactionExternalIdsQueryKey = (
  transactionId: string,
  subTransactionId: string
) => {
  return [
    `/funds-investments/v1/transactions/${transactionId}/sub-transactions/${subTransactionId}/external-ids`,
  ] as const
}

export const getGetSubTransactionExternalIdsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getSubTransactionExternalIds>>,
  TError = ErrorType<void>
>(
  transactionId: string,
  subTransactionId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getSubTransactionExternalIds>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetSubTransactionExternalIdsQueryKey(transactionId, subTransactionId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSubTransactionExternalIds>>
  > = ({ signal }) =>
    getSubTransactionExternalIds(transactionId, subTransactionId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(transactionId && subTransactionId),
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getSubTransactionExternalIds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetSubTransactionExternalIdsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSubTransactionExternalIds>>
>
export type GetSubTransactionExternalIdsInfiniteQueryError = ErrorType<void>

/**
 * @summary Get a sub-transaction external IDs
 */
export const useGetSubTransactionExternalIdsInfinite = <
  TData = Awaited<ReturnType<typeof getSubTransactionExternalIds>>,
  TError = ErrorType<void>
>(
  transactionId: string,
  subTransactionId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getSubTransactionExternalIds>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSubTransactionExternalIdsInfiniteQueryOptions(
    transactionId,
    subTransactionId,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetSubTransactionExternalIdsQueryOptions = <
  TData = Awaited<ReturnType<typeof getSubTransactionExternalIds>>,
  TError = ErrorType<void>
>(
  transactionId: string,
  subTransactionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSubTransactionExternalIds>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetSubTransactionExternalIdsQueryKey(transactionId, subTransactionId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSubTransactionExternalIds>>
  > = ({ signal }) =>
    getSubTransactionExternalIds(transactionId, subTransactionId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(transactionId && subTransactionId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getSubTransactionExternalIds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetSubTransactionExternalIdsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSubTransactionExternalIds>>
>
export type GetSubTransactionExternalIdsQueryError = ErrorType<void>

/**
 * @summary Get a sub-transaction external IDs
 */
export const useGetSubTransactionExternalIds = <
  TData = Awaited<ReturnType<typeof getSubTransactionExternalIds>>,
  TError = ErrorType<void>
>(
  transactionId: string,
  subTransactionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSubTransactionExternalIds>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSubTransactionExternalIdsQueryOptions(
    transactionId,
    subTransactionId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Update a sub-transaction external IDs.
 * @summary Update a sub-transaction external IDs
 */
export const updateSubTransactionExternalIds = (
  transactionId: string,
  subTransactionId: string,
  externalIds: ExternalIds
) => {
  return fetchInstance<Transaction>({
    url: `/funds-investments/v1/transactions/${transactionId}/sub-transactions/${subTransactionId}/external-ids`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: externalIds,
  })
}

export const getUpdateSubTransactionExternalIdsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSubTransactionExternalIds>>,
    TError,
    { transactionId: string; subTransactionId: string; data: ExternalIds },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateSubTransactionExternalIds>>,
  TError,
  { transactionId: string; subTransactionId: string; data: ExternalIds },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateSubTransactionExternalIds>>,
    { transactionId: string; subTransactionId: string; data: ExternalIds }
  > = (props) => {
    const { transactionId, subTransactionId, data } = props ?? {}

    return updateSubTransactionExternalIds(
      transactionId,
      subTransactionId,
      data
    )
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateSubTransactionExternalIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateSubTransactionExternalIds>>
>
export type UpdateSubTransactionExternalIdsMutationBody = ExternalIds
export type UpdateSubTransactionExternalIdsMutationError = ErrorType<void>

/**
 * @summary Update a sub-transaction external IDs
 */
export const useUpdateSubTransactionExternalIds = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateSubTransactionExternalIds>>,
    TError,
    { transactionId: string; subTransactionId: string; data: ExternalIds },
    TContext
  >
}) => {
  const mutationOptions =
    getUpdateSubTransactionExternalIdsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Delete a sub-transaction external IDs.
 * @summary Delete a sub-transaction external IDs
 */
export const deleteSubTransactionExternalIds = (
  transactionId: string,
  subTransactionId: string
) => {
  return fetchInstance<Transaction>({
    url: `/funds-investments/v1/transactions/${transactionId}/sub-transactions/${subTransactionId}/external-ids`,
    method: 'DELETE',
  })
}

export const getDeleteSubTransactionExternalIdsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSubTransactionExternalIds>>,
    TError,
    { transactionId: string; subTransactionId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSubTransactionExternalIds>>,
  TError,
  { transactionId: string; subTransactionId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSubTransactionExternalIds>>,
    { transactionId: string; subTransactionId: string }
  > = (props) => {
    const { transactionId, subTransactionId } = props ?? {}

    return deleteSubTransactionExternalIds(transactionId, subTransactionId)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteSubTransactionExternalIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSubTransactionExternalIds>>
>

export type DeleteSubTransactionExternalIdsMutationError = ErrorType<void>

/**
 * @summary Delete a sub-transaction external IDs
 */
export const useDeleteSubTransactionExternalIds = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSubTransactionExternalIds>>,
    TError,
    { transactionId: string; subTransactionId: string },
    TContext
  >
}) => {
  const mutationOptions =
    getDeleteSubTransactionExternalIdsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Returns a single position.
 * @summary Get a position
 */
export const getPosition = (positionId: string, signal?: AbortSignal) => {
  return fetchInstance<Position>({
    url: `/funds-investments/v1/positions/${positionId}`,
    method: 'GET',
    signal,
  })
}

export const getGetPositionQueryKey = (positionId: string) => {
  return [`/funds-investments/v1/positions/${positionId}`] as const
}

export const getGetPositionInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getPosition>>,
  TError = ErrorType<void>
>(
  positionId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getPosition>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPositionQueryKey(positionId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPosition>>> = ({
    signal,
  }) => getPosition(positionId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!positionId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getPosition>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPositionInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPosition>>
>
export type GetPositionInfiniteQueryError = ErrorType<void>

/**
 * @summary Get a position
 */
export const useGetPositionInfinite = <
  TData = Awaited<ReturnType<typeof getPosition>>,
  TError = ErrorType<void>
>(
  positionId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getPosition>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPositionInfiniteQueryOptions(positionId, options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetPositionQueryOptions = <
  TData = Awaited<ReturnType<typeof getPosition>>,
  TError = ErrorType<void>
>(
  positionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPosition>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPositionQueryKey(positionId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPosition>>> = ({
    signal,
  }) => getPosition(positionId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!positionId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPosition>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPositionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPosition>>
>
export type GetPositionQueryError = ErrorType<void>

/**
 * @summary Get a position
 */
export const useGetPosition = <
  TData = Awaited<ReturnType<typeof getPosition>>,
  TError = ErrorType<void>
>(
  positionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPosition>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPositionQueryOptions(positionId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Update a position.
 * @summary Update a position
 */
export const updatePosition = (position: Position) => {
  return fetchInstance<Position>({
    url: `/funds-investments/v1/positions`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: position,
  })
}

export const getUpdatePositionMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePosition>>,
    TError,
    { data: Position },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePosition>>,
  TError,
  { data: Position },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePosition>>,
    { data: Position }
  > = (props) => {
    const { data } = props ?? {}

    return updatePosition(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdatePositionMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePosition>>
>
export type UpdatePositionMutationBody = Position
export type UpdatePositionMutationError = ErrorType<void>

/**
 * @summary Update a position
 */
export const useUpdatePosition = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePosition>>,
    TError,
    { data: Position },
    TContext
  >
}) => {
  const mutationOptions = getUpdatePositionMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Create a single position.
 * @summary Create a position
 */
export const createPosition = (
  createPositionRequest: CreatePositionRequest
) => {
  return fetchInstance<Position>({
    url: `/funds-investments/v1/positions`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createPositionRequest,
  })
}

export const getCreatePositionMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPosition>>,
    TError,
    { data: CreatePositionRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPosition>>,
  TError,
  { data: CreatePositionRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPosition>>,
    { data: CreatePositionRequest }
  > = (props) => {
    const { data } = props ?? {}

    return createPosition(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreatePositionMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPosition>>
>
export type CreatePositionMutationBody = CreatePositionRequest
export type CreatePositionMutationError = ErrorType<void>

/**
 * @summary Create a position
 */
export const useCreatePosition = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPosition>>,
    TError,
    { data: CreatePositionRequest },
    TContext
  >
}) => {
  const mutationOptions = getCreatePositionMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Search order executions.
 * @summary Search for order executions of a position
 */
export const searchOrderExecutions = (positionId: string) => {
  return fetchInstance<OrderExecutions>({
    url: `/funds-investments/v1/positions/${positionId}/order-executions/search`,
    method: 'POST',
  })
}

export const getSearchOrderExecutionsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchOrderExecutions>>,
    TError,
    { positionId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchOrderExecutions>>,
  TError,
  { positionId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchOrderExecutions>>,
    { positionId: string }
  > = (props) => {
    const { positionId } = props ?? {}

    return searchOrderExecutions(positionId)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchOrderExecutionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof searchOrderExecutions>>
>

export type SearchOrderExecutionsMutationError = ErrorType<void>

/**
 * @summary Search for order executions of a position
 */
export const useSearchOrderExecutions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchOrderExecutions>>,
    TError,
    { positionId: string },
    TContext
  >
}) => {
  const mutationOptions = getSearchOrderExecutionsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Search positions. Note - search currently operates only on the latest version of a position.
 * @summary Search for a position
 */
export const searchPositions = (
  searchPositionsRequest: SearchPositionsRequest
) => {
  return fetchInstance<SearchPositionsResponse>({
    url: `/funds-investments/v1/positions/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchPositionsRequest,
  })
}

export const getSearchPositionsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchPositions>>,
    TError,
    { data: SearchPositionsRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchPositions>>,
  TError,
  { data: SearchPositionsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchPositions>>,
    { data: SearchPositionsRequest }
  > = (props) => {
    const { data } = props ?? {}

    return searchPositions(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchPositionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof searchPositions>>
>
export type SearchPositionsMutationBody = SearchPositionsRequest
export type SearchPositionsMutationError = ErrorType<void>

/**
 * @summary Search for a position
 */
export const useSearchPositions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchPositions>>,
    TError,
    { data: SearchPositionsRequest },
    TContext
  >
}) => {
  const mutationOptions = getSearchPositionsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Get a position external IDs.
 * @summary Get a position external IDs
 */
export const getPositionExternalIds = (
  positionId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<ExternalIds>({
    url: `/funds-investments/v1/positions/${positionId}/external-ids`,
    method: 'GET',
    signal,
  })
}

export const getGetPositionExternalIdsQueryKey = (positionId: string) => {
  return [`/funds-investments/v1/positions/${positionId}/external-ids`] as const
}

export const getGetPositionExternalIdsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getPositionExternalIds>>,
  TError = ErrorType<void>
>(
  positionId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getPositionExternalIds>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetPositionExternalIdsQueryKey(positionId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPositionExternalIds>>
  > = ({ signal }) => getPositionExternalIds(positionId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!positionId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getPositionExternalIds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPositionExternalIdsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPositionExternalIds>>
>
export type GetPositionExternalIdsInfiniteQueryError = ErrorType<void>

/**
 * @summary Get a position external IDs
 */
export const useGetPositionExternalIdsInfinite = <
  TData = Awaited<ReturnType<typeof getPositionExternalIds>>,
  TError = ErrorType<void>
>(
  positionId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getPositionExternalIds>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPositionExternalIdsInfiniteQueryOptions(
    positionId,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetPositionExternalIdsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPositionExternalIds>>,
  TError = ErrorType<void>
>(
  positionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPositionExternalIds>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetPositionExternalIdsQueryKey(positionId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPositionExternalIds>>
  > = ({ signal }) => getPositionExternalIds(positionId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!positionId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPositionExternalIds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPositionExternalIdsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPositionExternalIds>>
>
export type GetPositionExternalIdsQueryError = ErrorType<void>

/**
 * @summary Get a position external IDs
 */
export const useGetPositionExternalIds = <
  TData = Awaited<ReturnType<typeof getPositionExternalIds>>,
  TError = ErrorType<void>
>(
  positionId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPositionExternalIds>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPositionExternalIdsQueryOptions(
    positionId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Update a position external IDs.
 * @summary Update a position external IDs
 */
export const updatePositionExternalIds = (
  positionId: string,
  externalIds: ExternalIds
) => {
  return fetchInstance<Position>({
    url: `/funds-investments/v1/positions/${positionId}/external-ids`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: externalIds,
  })
}

export const getUpdatePositionExternalIdsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePositionExternalIds>>,
    TError,
    { positionId: string; data: ExternalIds },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePositionExternalIds>>,
  TError,
  { positionId: string; data: ExternalIds },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePositionExternalIds>>,
    { positionId: string; data: ExternalIds }
  > = (props) => {
    const { positionId, data } = props ?? {}

    return updatePositionExternalIds(positionId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdatePositionExternalIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePositionExternalIds>>
>
export type UpdatePositionExternalIdsMutationBody = ExternalIds
export type UpdatePositionExternalIdsMutationError = ErrorType<void>

/**
 * @summary Update a position external IDs
 */
export const useUpdatePositionExternalIds = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePositionExternalIds>>,
    TError,
    { positionId: string; data: ExternalIds },
    TContext
  >
}) => {
  const mutationOptions = getUpdatePositionExternalIdsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Delete a position external IDs.
 * @summary Delete a position external IDs
 */
export const deletePositionExternalIds = (positionId: string) => {
  return fetchInstance<Position>({
    url: `/funds-investments/v1/positions/${positionId}/external-ids`,
    method: 'DELETE',
  })
}

export const getDeletePositionExternalIdsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePositionExternalIds>>,
    TError,
    { positionId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePositionExternalIds>>,
  TError,
  { positionId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePositionExternalIds>>,
    { positionId: string }
  > = (props) => {
    const { positionId } = props ?? {}

    return deletePositionExternalIds(positionId)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeletePositionExternalIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePositionExternalIds>>
>

export type DeletePositionExternalIdsMutationError = ErrorType<void>

/**
 * @summary Delete a position external IDs
 */
export const useDeletePositionExternalIds = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePositionExternalIds>>,
    TError,
    { positionId: string },
    TContext
  >
}) => {
  const mutationOptions = getDeletePositionExternalIdsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Patch a position external IDs.
 * @summary Patch a position external IDs
 */
export const patchPositionExternalIds = (
  positionId: string,
  patchBody: PatchBody
) => {
  return fetchInstance<Position>({
    url: `/funds-investments/v1/positions/${positionId}/external-ids`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchBody,
  })
}

export const getPatchPositionExternalIdsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchPositionExternalIds>>,
    TError,
    { positionId: string; data: PatchBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchPositionExternalIds>>,
  TError,
  { positionId: string; data: PatchBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchPositionExternalIds>>,
    { positionId: string; data: PatchBody }
  > = (props) => {
    const { positionId, data } = props ?? {}

    return patchPositionExternalIds(positionId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchPositionExternalIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchPositionExternalIds>>
>
export type PatchPositionExternalIdsMutationBody = PatchBody
export type PatchPositionExternalIdsMutationError = ErrorType<void>

/**
 * @summary Patch a position external IDs
 */
export const usePatchPositionExternalIds = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchPositionExternalIds>>,
    TError,
    { positionId: string; data: PatchBody },
    TContext
  >
}) => {
  const mutationOptions = getPatchPositionExternalIdsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Returns a single position accounting lot.
 * @summary Get a position accounting lot
 */
export const getPositionAccountingLot = (
  positionAccountingLotId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<PositionAccountingLot>({
    url: `/funds-investments/v1/position-accounting-lots/${positionAccountingLotId}`,
    method: 'GET',
    signal,
  })
}

export const getGetPositionAccountingLotQueryKey = (
  positionAccountingLotId: string
) => {
  return [
    `/funds-investments/v1/position-accounting-lots/${positionAccountingLotId}`,
  ] as const
}

export const getGetPositionAccountingLotInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getPositionAccountingLot>>,
  TError = ErrorType<void>
>(
  positionAccountingLotId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getPositionAccountingLot>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPositionAccountingLotQueryKey(positionAccountingLotId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPositionAccountingLot>>
  > = ({ signal }) => getPositionAccountingLot(positionAccountingLotId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!positionAccountingLotId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getPositionAccountingLot>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPositionAccountingLotInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPositionAccountingLot>>
>
export type GetPositionAccountingLotInfiniteQueryError = ErrorType<void>

/**
 * @summary Get a position accounting lot
 */
export const useGetPositionAccountingLotInfinite = <
  TData = Awaited<ReturnType<typeof getPositionAccountingLot>>,
  TError = ErrorType<void>
>(
  positionAccountingLotId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getPositionAccountingLot>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPositionAccountingLotInfiniteQueryOptions(
    positionAccountingLotId,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetPositionAccountingLotQueryOptions = <
  TData = Awaited<ReturnType<typeof getPositionAccountingLot>>,
  TError = ErrorType<void>
>(
  positionAccountingLotId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPositionAccountingLot>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPositionAccountingLotQueryKey(positionAccountingLotId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPositionAccountingLot>>
  > = ({ signal }) => getPositionAccountingLot(positionAccountingLotId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!positionAccountingLotId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPositionAccountingLot>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPositionAccountingLotQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPositionAccountingLot>>
>
export type GetPositionAccountingLotQueryError = ErrorType<void>

/**
 * @summary Get a position accounting lot
 */
export const useGetPositionAccountingLot = <
  TData = Awaited<ReturnType<typeof getPositionAccountingLot>>,
  TError = ErrorType<void>
>(
  positionAccountingLotId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPositionAccountingLot>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPositionAccountingLotQueryOptions(
    positionAccountingLotId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Delete a single accounting lot.
 * @summary Delete a position accounting lot
 */
export const deletePositionAccountingLot = (
  positionAccountingLotId: string
) => {
  return fetchInstance<PositionAccountingLot>({
    url: `/funds-investments/v1/position-accounting-lots/${positionAccountingLotId}`,
    method: 'DELETE',
  })
}

export const getDeletePositionAccountingLotMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePositionAccountingLot>>,
    TError,
    { positionAccountingLotId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePositionAccountingLot>>,
  TError,
  { positionAccountingLotId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePositionAccountingLot>>,
    { positionAccountingLotId: string }
  > = (props) => {
    const { positionAccountingLotId } = props ?? {}

    return deletePositionAccountingLot(positionAccountingLotId)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeletePositionAccountingLotMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePositionAccountingLot>>
>

export type DeletePositionAccountingLotMutationError = ErrorType<void>

/**
 * @summary Delete a position accounting lot
 */
export const useDeletePositionAccountingLot = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePositionAccountingLot>>,
    TError,
    { positionAccountingLotId: string },
    TContext
  >
}) => {
  const mutationOptions = getDeletePositionAccountingLotMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Update a single accounting lot.
 * @summary Update a position accounting lot
 */
export const updatePositionAccountingLot = (
  positionAccountingLot: PositionAccountingLot
) => {
  return fetchInstance<PositionAccountingLot>({
    url: `/funds-investments/v1/position-accounting-lots`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: positionAccountingLot,
  })
}

export const getUpdatePositionAccountingLotMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePositionAccountingLot>>,
    TError,
    { data: PositionAccountingLot },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePositionAccountingLot>>,
  TError,
  { data: PositionAccountingLot },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePositionAccountingLot>>,
    { data: PositionAccountingLot }
  > = (props) => {
    const { data } = props ?? {}

    return updatePositionAccountingLot(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdatePositionAccountingLotMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePositionAccountingLot>>
>
export type UpdatePositionAccountingLotMutationBody = PositionAccountingLot
export type UpdatePositionAccountingLotMutationError = ErrorType<void>

/**
 * @summary Update a position accounting lot
 */
export const useUpdatePositionAccountingLot = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePositionAccountingLot>>,
    TError,
    { data: PositionAccountingLot },
    TContext
  >
}) => {
  const mutationOptions = getUpdatePositionAccountingLotMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Create new position accounting lot.
 * @summary Create a position accounting lot
 */
export const createPositionAccountingLot = (
  positionAccountingLot: PositionAccountingLot
) => {
  return fetchInstance<PositionAccountingLot>({
    url: `/funds-investments/v1/position-accounting-lots`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: positionAccountingLot,
  })
}

export const getCreatePositionAccountingLotMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPositionAccountingLot>>,
    TError,
    { data: PositionAccountingLot },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPositionAccountingLot>>,
  TError,
  { data: PositionAccountingLot },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPositionAccountingLot>>,
    { data: PositionAccountingLot }
  > = (props) => {
    const { data } = props ?? {}

    return createPositionAccountingLot(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreatePositionAccountingLotMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPositionAccountingLot>>
>
export type CreatePositionAccountingLotMutationBody = PositionAccountingLot
export type CreatePositionAccountingLotMutationError = ErrorType<void>

/**
 * @summary Create a position accounting lot
 */
export const useCreatePositionAccountingLot = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPositionAccountingLot>>,
    TError,
    { data: PositionAccountingLot },
    TContext
  >
}) => {
  const mutationOptions = getCreatePositionAccountingLotMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Search position accounting lots
 * @summary Search for position accounting lots
 */
export const searchPositionAccountingLots = (
  searchPositionAccountingLotsRequest: SearchPositionAccountingLotsRequest
) => {
  return fetchInstance<SearchPositionAccountingLotsResponse>({
    url: `/funds-investments/v1/position-accounting-lots/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchPositionAccountingLotsRequest,
  })
}

export const getSearchPositionAccountingLotsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchPositionAccountingLots>>,
    TError,
    { data: SearchPositionAccountingLotsRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchPositionAccountingLots>>,
  TError,
  { data: SearchPositionAccountingLotsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchPositionAccountingLots>>,
    { data: SearchPositionAccountingLotsRequest }
  > = (props) => {
    const { data } = props ?? {}

    return searchPositionAccountingLots(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchPositionAccountingLotsMutationResult = NonNullable<
  Awaited<ReturnType<typeof searchPositionAccountingLots>>
>
export type SearchPositionAccountingLotsMutationBody =
  SearchPositionAccountingLotsRequest
export type SearchPositionAccountingLotsMutationError = ErrorType<void>

/**
 * @summary Search for position accounting lots
 */
export const useSearchPositionAccountingLots = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchPositionAccountingLots>>,
    TError,
    { data: SearchPositionAccountingLotsRequest },
    TContext
  >
}) => {
  const mutationOptions =
    getSearchPositionAccountingLotsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Get a position accounting lot external IDs.
 * @summary Get a position accounting lot external IDs
 */
export const getPositionAccountingLotsExternalIds = (
  positionAccountingLotId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<ExternalIds>({
    url: `/funds-investments/v1/position-accounting-lots/${positionAccountingLotId}/external-ids`,
    method: 'GET',
    signal,
  })
}

export const getGetPositionAccountingLotsExternalIdsQueryKey = (
  positionAccountingLotId: string
) => {
  return [
    `/funds-investments/v1/position-accounting-lots/${positionAccountingLotId}/external-ids`,
  ] as const
}

export const getGetPositionAccountingLotsExternalIdsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getPositionAccountingLotsExternalIds>>,
  TError = ErrorType<void>
>(
  positionAccountingLotId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getPositionAccountingLotsExternalIds>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPositionAccountingLotsExternalIdsQueryKey(positionAccountingLotId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPositionAccountingLotsExternalIds>>
  > = ({ signal }) =>
    getPositionAccountingLotsExternalIds(positionAccountingLotId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!positionAccountingLotId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getPositionAccountingLotsExternalIds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPositionAccountingLotsExternalIdsInfiniteQueryResult =
  NonNullable<Awaited<ReturnType<typeof getPositionAccountingLotsExternalIds>>>
export type GetPositionAccountingLotsExternalIdsInfiniteQueryError =
  ErrorType<void>

/**
 * @summary Get a position accounting lot external IDs
 */
export const useGetPositionAccountingLotsExternalIdsInfinite = <
  TData = Awaited<ReturnType<typeof getPositionAccountingLotsExternalIds>>,
  TError = ErrorType<void>
>(
  positionAccountingLotId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getPositionAccountingLotsExternalIds>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetPositionAccountingLotsExternalIdsInfiniteQueryOptions(
      positionAccountingLotId,
      options
    )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetPositionAccountingLotsExternalIdsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPositionAccountingLotsExternalIds>>,
  TError = ErrorType<void>
>(
  positionAccountingLotId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPositionAccountingLotsExternalIds>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPositionAccountingLotsExternalIdsQueryKey(positionAccountingLotId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPositionAccountingLotsExternalIds>>
  > = ({ signal }) =>
    getPositionAccountingLotsExternalIds(positionAccountingLotId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!positionAccountingLotId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPositionAccountingLotsExternalIds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPositionAccountingLotsExternalIdsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPositionAccountingLotsExternalIds>>
>
export type GetPositionAccountingLotsExternalIdsQueryError = ErrorType<void>

/**
 * @summary Get a position accounting lot external IDs
 */
export const useGetPositionAccountingLotsExternalIds = <
  TData = Awaited<ReturnType<typeof getPositionAccountingLotsExternalIds>>,
  TError = ErrorType<void>
>(
  positionAccountingLotId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPositionAccountingLotsExternalIds>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPositionAccountingLotsExternalIdsQueryOptions(
    positionAccountingLotId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Update a position accounting lot external IDs.
 * @summary Update a position accounting lot external IDs
 */
export const updatePositionAccountingLotsExternalIds = (
  positionAccountingLotId: string,
  externalIds: ExternalIds
) => {
  return fetchInstance<PositionAccountingLot>({
    url: `/funds-investments/v1/position-accounting-lots/${positionAccountingLotId}/external-ids`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: externalIds,
  })
}

export const getUpdatePositionAccountingLotsExternalIdsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePositionAccountingLotsExternalIds>>,
    TError,
    { positionAccountingLotId: string; data: ExternalIds },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePositionAccountingLotsExternalIds>>,
  TError,
  { positionAccountingLotId: string; data: ExternalIds },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePositionAccountingLotsExternalIds>>,
    { positionAccountingLotId: string; data: ExternalIds }
  > = (props) => {
    const { positionAccountingLotId, data } = props ?? {}

    return updatePositionAccountingLotsExternalIds(
      positionAccountingLotId,
      data
    )
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdatePositionAccountingLotsExternalIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePositionAccountingLotsExternalIds>>
>
export type UpdatePositionAccountingLotsExternalIdsMutationBody = ExternalIds
export type UpdatePositionAccountingLotsExternalIdsMutationError =
  ErrorType<void>

/**
 * @summary Update a position accounting lot external IDs
 */
export const useUpdatePositionAccountingLotsExternalIds = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePositionAccountingLotsExternalIds>>,
    TError,
    { positionAccountingLotId: string; data: ExternalIds },
    TContext
  >
}) => {
  const mutationOptions =
    getUpdatePositionAccountingLotsExternalIdsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Delete a position accounting lot external IDs.
 * @summary Delete a position accounting lot external IDs
 */
export const deletePositionAccountingLotsExternalIds = (
  positionAccountingLotId: string
) => {
  return fetchInstance<PositionAccountingLot>({
    url: `/funds-investments/v1/position-accounting-lots/${positionAccountingLotId}/external-ids`,
    method: 'DELETE',
  })
}

export const getDeletePositionAccountingLotsExternalIdsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePositionAccountingLotsExternalIds>>,
    TError,
    { positionAccountingLotId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePositionAccountingLotsExternalIds>>,
  TError,
  { positionAccountingLotId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePositionAccountingLotsExternalIds>>,
    { positionAccountingLotId: string }
  > = (props) => {
    const { positionAccountingLotId } = props ?? {}

    return deletePositionAccountingLotsExternalIds(positionAccountingLotId)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeletePositionAccountingLotsExternalIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePositionAccountingLotsExternalIds>>
>

export type DeletePositionAccountingLotsExternalIdsMutationError =
  ErrorType<void>

/**
 * @summary Delete a position accounting lot external IDs
 */
export const useDeletePositionAccountingLotsExternalIds = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePositionAccountingLotsExternalIds>>,
    TError,
    { positionAccountingLotId: string },
    TContext
  >
}) => {
  const mutationOptions =
    getDeletePositionAccountingLotsExternalIdsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Patch a position accounting lot external IDs.
 * @summary Patch a position accounting lot external IDs
 */
export const patchPositionAccountingLotsExternalIds = (
  positionAccountingLotId: string,
  patchBody: PatchBody
) => {
  return fetchInstance<PositionAccountingLot>({
    url: `/funds-investments/v1/position-accounting-lots/${positionAccountingLotId}/external-ids`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchBody,
  })
}

export const getPatchPositionAccountingLotsExternalIdsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchPositionAccountingLotsExternalIds>>,
    TError,
    { positionAccountingLotId: string; data: PatchBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchPositionAccountingLotsExternalIds>>,
  TError,
  { positionAccountingLotId: string; data: PatchBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchPositionAccountingLotsExternalIds>>,
    { positionAccountingLotId: string; data: PatchBody }
  > = (props) => {
    const { positionAccountingLotId, data } = props ?? {}

    return patchPositionAccountingLotsExternalIds(positionAccountingLotId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchPositionAccountingLotsExternalIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchPositionAccountingLotsExternalIds>>
>
export type PatchPositionAccountingLotsExternalIdsMutationBody = PatchBody
export type PatchPositionAccountingLotsExternalIdsMutationError =
  ErrorType<void>

/**
 * @summary Patch a position accounting lot external IDs
 */
export const usePatchPositionAccountingLotsExternalIds = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchPositionAccountingLotsExternalIds>>,
    TError,
    { positionAccountingLotId: string; data: PatchBody },
    TContext
  >
}) => {
  const mutationOptions =
    getPatchPositionAccountingLotsExternalIdsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Create, update or delete canonical position accounting lot valuations. - On update, the version must be provided inside the model, and externalIds should be null.

 * @summary Create/update/delete position accounting lot valuations
 */
export const createUpdateOrDeletePositionAccountingLotValuations = (
  positionAccountingLotValuations: PositionAccountingLotValuations
) => {
  return fetchInstance<ActionResults>({
    url: `/funds-investments/v1/position-accounting-lot-valuations`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: positionAccountingLotValuations,
  })
}

export const getCreateUpdateOrDeletePositionAccountingLotValuationsMutationOptions =
  <TError = ErrorType<void>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof createUpdateOrDeletePositionAccountingLotValuations>
      >,
      TError,
      { data: PositionAccountingLotValuations },
      TContext
    >
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof createUpdateOrDeletePositionAccountingLotValuations>
    >,
    TError,
    { data: PositionAccountingLotValuations },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {}

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof createUpdateOrDeletePositionAccountingLotValuations>
      >,
      { data: PositionAccountingLotValuations }
    > = (props) => {
      const { data } = props ?? {}

      return createUpdateOrDeletePositionAccountingLotValuations(data)
    }

    return { mutationFn, ...mutationOptions }
  }

export type CreateUpdateOrDeletePositionAccountingLotValuationsMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof createUpdateOrDeletePositionAccountingLotValuations>
    >
  >
export type CreateUpdateOrDeletePositionAccountingLotValuationsMutationBody =
  PositionAccountingLotValuations
export type CreateUpdateOrDeletePositionAccountingLotValuationsMutationError =
  ErrorType<void>

/**
 * @summary Create/update/delete position accounting lot valuations
 */
export const useCreateUpdateOrDeletePositionAccountingLotValuations = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof createUpdateOrDeletePositionAccountingLotValuations>
    >,
    TError,
    { data: PositionAccountingLotValuations },
    TContext
  >
}) => {
  const mutationOptions =
    getCreateUpdateOrDeletePositionAccountingLotValuationsMutationOptions(
      options
    )

  return useMutation(mutationOptions)
}

/**
 * Returns a single position accounting lot valuation.
 * @summary Get a position accounting lot valuation
 */
export const getPositionAccountingLotValuation = (
  positionAccountingLotValuationId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<PositionAccountingLotValuation>({
    url: `/funds-investments/v1/position-accounting-lot-valuations/${positionAccountingLotValuationId}`,
    method: 'GET',
    signal,
  })
}

export const getGetPositionAccountingLotValuationQueryKey = (
  positionAccountingLotValuationId: string
) => {
  return [
    `/funds-investments/v1/position-accounting-lot-valuations/${positionAccountingLotValuationId}`,
  ] as const
}

export const getGetPositionAccountingLotValuationInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getPositionAccountingLotValuation>>,
  TError = ErrorType<void>
>(
  positionAccountingLotValuationId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getPositionAccountingLotValuation>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPositionAccountingLotValuationQueryKey(
      positionAccountingLotValuationId
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPositionAccountingLotValuation>>
  > = ({ signal }) =>
    getPositionAccountingLotValuation(positionAccountingLotValuationId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!positionAccountingLotValuationId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getPositionAccountingLotValuation>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPositionAccountingLotValuationInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPositionAccountingLotValuation>>
>
export type GetPositionAccountingLotValuationInfiniteQueryError =
  ErrorType<void>

/**
 * @summary Get a position accounting lot valuation
 */
export const useGetPositionAccountingLotValuationInfinite = <
  TData = Awaited<ReturnType<typeof getPositionAccountingLotValuation>>,
  TError = ErrorType<void>
>(
  positionAccountingLotValuationId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getPositionAccountingLotValuation>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPositionAccountingLotValuationInfiniteQueryOptions(
    positionAccountingLotValuationId,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetPositionAccountingLotValuationQueryOptions = <
  TData = Awaited<ReturnType<typeof getPositionAccountingLotValuation>>,
  TError = ErrorType<void>
>(
  positionAccountingLotValuationId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPositionAccountingLotValuation>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPositionAccountingLotValuationQueryKey(
      positionAccountingLotValuationId
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPositionAccountingLotValuation>>
  > = ({ signal }) =>
    getPositionAccountingLotValuation(positionAccountingLotValuationId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!positionAccountingLotValuationId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPositionAccountingLotValuation>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPositionAccountingLotValuationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPositionAccountingLotValuation>>
>
export type GetPositionAccountingLotValuationQueryError = ErrorType<void>

/**
 * @summary Get a position accounting lot valuation
 */
export const useGetPositionAccountingLotValuation = <
  TData = Awaited<ReturnType<typeof getPositionAccountingLotValuation>>,
  TError = ErrorType<void>
>(
  positionAccountingLotValuationId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPositionAccountingLotValuation>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPositionAccountingLotValuationQueryOptions(
    positionAccountingLotValuationId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Search position accounting lot valuations. When the format is set to XLSX in the request then pagination parameters will be ignored and the response with be streamed as an XLSX file.

 * @summary Search for position accounting lot valuations
 */
export const searchPositionAccountingLotValuations = (
  searchPositionAccountingLotValuationsRequest: SearchPositionAccountingLotValuationsRequest
) => {
  return fetchInstance<SearchPositionAccountingLotValuationsResponse | Blob>({
    url: `/funds-investments/v1/position-accounting-lot-valuations/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchPositionAccountingLotValuationsRequest,
  })
}

export const getSearchPositionAccountingLotValuationsQueryKey = (
  searchPositionAccountingLotValuationsRequest: SearchPositionAccountingLotValuationsRequest
) => {
  return [
    `/funds-investments/v1/position-accounting-lot-valuations/search`,
    searchPositionAccountingLotValuationsRequest,
  ] as const
}

export const getSearchPositionAccountingLotValuationsQueryOptions = <
  TData = Awaited<ReturnType<typeof searchPositionAccountingLotValuations>>,
  TError = ErrorType<void>
>(
  searchPositionAccountingLotValuationsRequest: SearchPositionAccountingLotValuationsRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchPositionAccountingLotValuations>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getSearchPositionAccountingLotValuationsQueryKey(
      searchPositionAccountingLotValuationsRequest
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchPositionAccountingLotValuations>>
  > = () =>
    searchPositionAccountingLotValuations(
      searchPositionAccountingLotValuationsRequest
    )

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchPositionAccountingLotValuations>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchPositionAccountingLotValuationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchPositionAccountingLotValuations>>
>
export type SearchPositionAccountingLotValuationsQueryError = ErrorType<void>

/**
 * @summary Search for position accounting lot valuations
 */
export const useSearchPositionAccountingLotValuations = <
  TData = Awaited<ReturnType<typeof searchPositionAccountingLotValuations>>,
  TError = ErrorType<void>
>(
  searchPositionAccountingLotValuationsRequest: SearchPositionAccountingLotValuationsRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchPositionAccountingLotValuations>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchPositionAccountingLotValuationsQueryOptions(
    searchPositionAccountingLotValuationsRequest,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Search position accounting lot valuations, filling the account data To be used in the data browser / positions UI

 * @summary Search for position accounting lot valuations with account
 */
export const searchPositionAccountingLotValuationsWithExpandedReference = (
  searchPositionAccountingLotValuationsRequest: SearchPositionAccountingLotValuationsRequest
) => {
  return fetchInstance<SearchPositionAccountingLotValuationsWithExpandedReferenceResponse>(
    {
      url: `/funds-investments/v1/position-accounting-lot-valuations-with-expanded-reference/search`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: searchPositionAccountingLotValuationsRequest,
    }
  )
}

export const getSearchPositionAccountingLotValuationsWithExpandedReferenceMutationOptions =
  <TError = ErrorType<void>, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof searchPositionAccountingLotValuationsWithExpandedReference
        >
      >,
      TError,
      { data: SearchPositionAccountingLotValuationsRequest },
      TContext
    >
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof searchPositionAccountingLotValuationsWithExpandedReference
      >
    >,
    TError,
    { data: SearchPositionAccountingLotValuationsRequest },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {}

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof searchPositionAccountingLotValuationsWithExpandedReference
        >
      >,
      { data: SearchPositionAccountingLotValuationsRequest }
    > = (props) => {
      const { data } = props ?? {}

      return searchPositionAccountingLotValuationsWithExpandedReference(data)
    }

    return { mutationFn, ...mutationOptions }
  }

export type SearchPositionAccountingLotValuationsWithExpandedReferenceMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof searchPositionAccountingLotValuationsWithExpandedReference
      >
    >
  >
export type SearchPositionAccountingLotValuationsWithExpandedReferenceMutationBody =
  SearchPositionAccountingLotValuationsRequest
export type SearchPositionAccountingLotValuationsWithExpandedReferenceMutationError =
  ErrorType<void>

/**
 * @summary Search for position accounting lot valuations with account
 */
export const useSearchPositionAccountingLotValuationsWithExpandedReference = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        typeof searchPositionAccountingLotValuationsWithExpandedReference
      >
    >,
    TError,
    { data: SearchPositionAccountingLotValuationsRequest },
    TContext
  >
}) => {
  const mutationOptions =
    getSearchPositionAccountingLotValuationsWithExpandedReferenceMutationOptions(
      options
    )

  return useMutation(mutationOptions)
}

/**
 * Publish position accounting lot valuations
 * @summary Publish position accounting lot valuations
 */
export const publishPositionAccountingLotValuations = (
  publishPositionAccountingLotValuationsRequest: PublishPositionAccountingLotValuationsRequest
) => {
  return fetchInstance<PublishPositionAccountingLotValuationResults>({
    url: `/funds-investments/v1/position-accounting-lot-valuations/publish`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: publishPositionAccountingLotValuationsRequest,
  })
}

export const getPublishPositionAccountingLotValuationsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof publishPositionAccountingLotValuations>>,
    TError,
    { data: PublishPositionAccountingLotValuationsRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof publishPositionAccountingLotValuations>>,
  TError,
  { data: PublishPositionAccountingLotValuationsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof publishPositionAccountingLotValuations>>,
    { data: PublishPositionAccountingLotValuationsRequest }
  > = (props) => {
    const { data } = props ?? {}

    return publishPositionAccountingLotValuations(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PublishPositionAccountingLotValuationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof publishPositionAccountingLotValuations>>
>
export type PublishPositionAccountingLotValuationsMutationBody =
  PublishPositionAccountingLotValuationsRequest
export type PublishPositionAccountingLotValuationsMutationError =
  ErrorType<void>

/**
 * @summary Publish position accounting lot valuations
 */
export const usePublishPositionAccountingLotValuations = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof publishPositionAccountingLotValuations>>,
    TError,
    { data: PublishPositionAccountingLotValuationsRequest },
    TContext
  >
}) => {
  const mutationOptions =
    getPublishPositionAccountingLotValuationsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Get the batch information that the canonical position accounting lot was generated from
 * @summary Get batch information
 */
export const getPositionAccountingLotValuationsBatchInfo = (
  positionAccountingLotValuationId: string,
  params?: GetPositionAccountingLotValuationsBatchInfoParams,
  signal?: AbortSignal
) => {
  return fetchInstance<RawBatchInfoOutbound>({
    url: `/funds-investments/v1/position-accounting-lot-valuations/${positionAccountingLotValuationId}/batch-info`,
    method: 'GET',
    params,
    signal,
  })
}

export const getGetPositionAccountingLotValuationsBatchInfoQueryKey = (
  positionAccountingLotValuationId: string,
  params?: GetPositionAccountingLotValuationsBatchInfoParams
) => {
  return [
    `/funds-investments/v1/position-accounting-lot-valuations/${positionAccountingLotValuationId}/batch-info`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetPositionAccountingLotValuationsBatchInfoInfiniteQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getPositionAccountingLotValuationsBatchInfo>
    >,
    TError = ErrorType<void>
  >(
    positionAccountingLotValuationId: string,
    params?: GetPositionAccountingLotValuationsBatchInfoParams,
    options?: {
      query?: UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getPositionAccountingLotValuationsBatchInfo>>,
        TError,
        TData
      >
    }
  ) => {
    const { query: queryOptions } = options ?? {}

    const queryKey =
      queryOptions?.queryKey ??
      getGetPositionAccountingLotValuationsBatchInfoQueryKey(
        positionAccountingLotValuationId,
        params
      )

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof getPositionAccountingLotValuationsBatchInfo>>
    > = ({ signal }) =>
      getPositionAccountingLotValuationsBatchInfo(
        positionAccountingLotValuationId,
        params,
        signal
      )

    return {
      queryKey,
      queryFn,
      enabled: !!positionAccountingLotValuationId,
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getPositionAccountingLotValuationsBatchInfo>>,
      TError,
      TData
    > & { queryKey: QueryKey }
  }

export type GetPositionAccountingLotValuationsBatchInfoInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getPositionAccountingLotValuationsBatchInfo>>
  >
export type GetPositionAccountingLotValuationsBatchInfoInfiniteQueryError =
  ErrorType<void>

/**
 * @summary Get batch information
 */
export const useGetPositionAccountingLotValuationsBatchInfoInfinite = <
  TData = Awaited<
    ReturnType<typeof getPositionAccountingLotValuationsBatchInfo>
  >,
  TError = ErrorType<void>
>(
  positionAccountingLotValuationId: string,
  params?: GetPositionAccountingLotValuationsBatchInfoParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getPositionAccountingLotValuationsBatchInfo>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetPositionAccountingLotValuationsBatchInfoInfiniteQueryOptions(
      positionAccountingLotValuationId,
      params,
      options
    )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetPositionAccountingLotValuationsBatchInfoQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getPositionAccountingLotValuationsBatchInfo>
  >,
  TError = ErrorType<void>
>(
  positionAccountingLotValuationId: string,
  params?: GetPositionAccountingLotValuationsBatchInfoParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPositionAccountingLotValuationsBatchInfo>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPositionAccountingLotValuationsBatchInfoQueryKey(
      positionAccountingLotValuationId,
      params
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPositionAccountingLotValuationsBatchInfo>>
  > = ({ signal }) =>
    getPositionAccountingLotValuationsBatchInfo(
      positionAccountingLotValuationId,
      params,
      signal
    )

  return {
    queryKey,
    queryFn,
    enabled: !!positionAccountingLotValuationId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPositionAccountingLotValuationsBatchInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPositionAccountingLotValuationsBatchInfoQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getPositionAccountingLotValuationsBatchInfo>>
  >
export type GetPositionAccountingLotValuationsBatchInfoQueryError =
  ErrorType<void>

/**
 * @summary Get batch information
 */
export const useGetPositionAccountingLotValuationsBatchInfo = <
  TData = Awaited<
    ReturnType<typeof getPositionAccountingLotValuationsBatchInfo>
  >,
  TError = ErrorType<void>
>(
  positionAccountingLotValuationId: string,
  params?: GetPositionAccountingLotValuationsBatchInfoParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPositionAccountingLotValuationsBatchInfo>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetPositionAccountingLotValuationsBatchInfoQueryOptions(
      positionAccountingLotValuationId,
      params,
      options
    )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Get a position valuation external IDs.
 * @summary Get a position accounting lot valuation external IDs
 */
export const getPositionValuationExternalIds = (
  positionAccountingLotValuationId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<ExternalIds>({
    url: `/funds-investments/v1/position-accounting-lot-valuations/${positionAccountingLotValuationId}/external-ids`,
    method: 'GET',
    signal,
  })
}

export const getGetPositionValuationExternalIdsQueryKey = (
  positionAccountingLotValuationId: string
) => {
  return [
    `/funds-investments/v1/position-accounting-lot-valuations/${positionAccountingLotValuationId}/external-ids`,
  ] as const
}

export const getGetPositionValuationExternalIdsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getPositionValuationExternalIds>>,
  TError = ErrorType<void>
>(
  positionAccountingLotValuationId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getPositionValuationExternalIds>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPositionValuationExternalIdsQueryKey(positionAccountingLotValuationId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPositionValuationExternalIds>>
  > = ({ signal }) =>
    getPositionValuationExternalIds(positionAccountingLotValuationId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!positionAccountingLotValuationId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getPositionValuationExternalIds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPositionValuationExternalIdsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPositionValuationExternalIds>>
>
export type GetPositionValuationExternalIdsInfiniteQueryError = ErrorType<void>

/**
 * @summary Get a position accounting lot valuation external IDs
 */
export const useGetPositionValuationExternalIdsInfinite = <
  TData = Awaited<ReturnType<typeof getPositionValuationExternalIds>>,
  TError = ErrorType<void>
>(
  positionAccountingLotValuationId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getPositionValuationExternalIds>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPositionValuationExternalIdsInfiniteQueryOptions(
    positionAccountingLotValuationId,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetPositionValuationExternalIdsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPositionValuationExternalIds>>,
  TError = ErrorType<void>
>(
  positionAccountingLotValuationId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPositionValuationExternalIds>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPositionValuationExternalIdsQueryKey(positionAccountingLotValuationId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPositionValuationExternalIds>>
  > = ({ signal }) =>
    getPositionValuationExternalIds(positionAccountingLotValuationId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!positionAccountingLotValuationId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPositionValuationExternalIds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPositionValuationExternalIdsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPositionValuationExternalIds>>
>
export type GetPositionValuationExternalIdsQueryError = ErrorType<void>

/**
 * @summary Get a position accounting lot valuation external IDs
 */
export const useGetPositionValuationExternalIds = <
  TData = Awaited<ReturnType<typeof getPositionValuationExternalIds>>,
  TError = ErrorType<void>
>(
  positionAccountingLotValuationId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPositionValuationExternalIds>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPositionValuationExternalIdsQueryOptions(
    positionAccountingLotValuationId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Update a position valuation external IDs.
 * @summary Update a position accounting lot valuation external IDs
 */
export const updatePositionValuationExternalIds = (
  positionAccountingLotValuationId: string,
  externalIds: ExternalIds
) => {
  return fetchInstance<PositionAccountingLotValuation>({
    url: `/funds-investments/v1/position-accounting-lot-valuations/${positionAccountingLotValuationId}/external-ids`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: externalIds,
  })
}

export const getUpdatePositionValuationExternalIdsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePositionValuationExternalIds>>,
    TError,
    { positionAccountingLotValuationId: string; data: ExternalIds },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePositionValuationExternalIds>>,
  TError,
  { positionAccountingLotValuationId: string; data: ExternalIds },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePositionValuationExternalIds>>,
    { positionAccountingLotValuationId: string; data: ExternalIds }
  > = (props) => {
    const { positionAccountingLotValuationId, data } = props ?? {}

    return updatePositionValuationExternalIds(
      positionAccountingLotValuationId,
      data
    )
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdatePositionValuationExternalIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePositionValuationExternalIds>>
>
export type UpdatePositionValuationExternalIdsMutationBody = ExternalIds
export type UpdatePositionValuationExternalIdsMutationError = ErrorType<void>

/**
 * @summary Update a position accounting lot valuation external IDs
 */
export const useUpdatePositionValuationExternalIds = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePositionValuationExternalIds>>,
    TError,
    { positionAccountingLotValuationId: string; data: ExternalIds },
    TContext
  >
}) => {
  const mutationOptions =
    getUpdatePositionValuationExternalIdsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Delete a position valuation external IDs.
 * @summary Delete a position accounting lot valuation external IDs
 */
export const deletePositionValuationExternalIds = (
  positionAccountingLotValuationId: string
) => {
  return fetchInstance<PositionAccountingLotValuation>({
    url: `/funds-investments/v1/position-accounting-lot-valuations/${positionAccountingLotValuationId}/external-ids`,
    method: 'DELETE',
  })
}

export const getDeletePositionValuationExternalIdsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePositionValuationExternalIds>>,
    TError,
    { positionAccountingLotValuationId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePositionValuationExternalIds>>,
  TError,
  { positionAccountingLotValuationId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePositionValuationExternalIds>>,
    { positionAccountingLotValuationId: string }
  > = (props) => {
    const { positionAccountingLotValuationId } = props ?? {}

    return deletePositionValuationExternalIds(positionAccountingLotValuationId)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeletePositionValuationExternalIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePositionValuationExternalIds>>
>

export type DeletePositionValuationExternalIdsMutationError = ErrorType<void>

/**
 * @summary Delete a position accounting lot valuation external IDs
 */
export const useDeletePositionValuationExternalIds = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePositionValuationExternalIds>>,
    TError,
    { positionAccountingLotValuationId: string },
    TContext
  >
}) => {
  const mutationOptions =
    getDeletePositionValuationExternalIdsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Patch a position valuation external IDs.
 * @summary Patch a position accounting lot valuation external IDs
 */
export const patchPositionValuationExternalIds = (
  positionAccountingLotValuationId: string,
  patchBody: PatchBody
) => {
  return fetchInstance<PositionAccountingLotValuation>({
    url: `/funds-investments/v1/position-accounting-lot-valuations/${positionAccountingLotValuationId}/external-ids`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchBody,
  })
}

export const getPatchPositionValuationExternalIdsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchPositionValuationExternalIds>>,
    TError,
    { positionAccountingLotValuationId: string; data: PatchBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchPositionValuationExternalIds>>,
  TError,
  { positionAccountingLotValuationId: string; data: PatchBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchPositionValuationExternalIds>>,
    { positionAccountingLotValuationId: string; data: PatchBody }
  > = (props) => {
    const { positionAccountingLotValuationId, data } = props ?? {}

    return patchPositionValuationExternalIds(
      positionAccountingLotValuationId,
      data
    )
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchPositionValuationExternalIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchPositionValuationExternalIds>>
>
export type PatchPositionValuationExternalIdsMutationBody = PatchBody
export type PatchPositionValuationExternalIdsMutationError = ErrorType<void>

/**
 * @summary Patch a position accounting lot valuation external IDs
 */
export const usePatchPositionValuationExternalIds = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchPositionValuationExternalIds>>,
    TError,
    { positionAccountingLotValuationId: string; data: PatchBody },
    TContext
  >
}) => {
  const mutationOptions =
    getPatchPositionValuationExternalIdsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Returns a list of batch objects containing batch metadata including the id and version.
 * @summary Get all raw position accounting lot valuation batches
 */
export const listRawPositionAccountingLotValuationsBatches = (
  signal?: AbortSignal
) => {
  return fetchInstance<RawBatchInfoList>({
    url: `/funds-investments/v1/position-accounting-lot-valuations/raw/batch`,
    method: 'GET',
    signal,
  })
}

export const getListRawPositionAccountingLotValuationsBatchesQueryKey = () => {
  return [
    `/funds-investments/v1/position-accounting-lot-valuations/raw/batch`,
  ] as const
}

export const getListRawPositionAccountingLotValuationsBatchesInfiniteQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof listRawPositionAccountingLotValuationsBatches>
    >,
    TError = ErrorType<void>
  >(options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof listRawPositionAccountingLotValuationsBatches>>,
      TError,
      TData
    >
  }) => {
    const { query: queryOptions } = options ?? {}

    const queryKey =
      queryOptions?.queryKey ??
      getListRawPositionAccountingLotValuationsBatchesQueryKey()

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof listRawPositionAccountingLotValuationsBatches>>
    > = ({ signal }) => listRawPositionAccountingLotValuationsBatches(signal)

    return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof listRawPositionAccountingLotValuationsBatches>>,
      TError,
      TData
    > & { queryKey: QueryKey }
  }

export type ListRawPositionAccountingLotValuationsBatchesInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof listRawPositionAccountingLotValuationsBatches>>
  >
export type ListRawPositionAccountingLotValuationsBatchesInfiniteQueryError =
  ErrorType<void>

/**
 * @summary Get all raw position accounting lot valuation batches
 */
export const useListRawPositionAccountingLotValuationsBatchesInfinite = <
  TData = Awaited<
    ReturnType<typeof listRawPositionAccountingLotValuationsBatches>
  >,
  TError = ErrorType<void>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listRawPositionAccountingLotValuationsBatches>>,
    TError,
    TData
  >
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getListRawPositionAccountingLotValuationsBatchesInfiniteQueryOptions(
      options
    )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getListRawPositionAccountingLotValuationsBatchesQueryOptions = <
  TData = Awaited<
    ReturnType<typeof listRawPositionAccountingLotValuationsBatches>
  >,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listRawPositionAccountingLotValuationsBatches>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getListRawPositionAccountingLotValuationsBatchesQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listRawPositionAccountingLotValuationsBatches>>
  > = ({ signal }) => listRawPositionAccountingLotValuationsBatches(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listRawPositionAccountingLotValuationsBatches>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ListRawPositionAccountingLotValuationsBatchesQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof listRawPositionAccountingLotValuationsBatches>>
  >
export type ListRawPositionAccountingLotValuationsBatchesQueryError =
  ErrorType<void>

/**
 * @summary Get all raw position accounting lot valuation batches
 */
export const useListRawPositionAccountingLotValuationsBatches = <
  TData = Awaited<
    ReturnType<typeof listRawPositionAccountingLotValuationsBatches>
  >,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listRawPositionAccountingLotValuationsBatches>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getListRawPositionAccountingLotValuationsBatchesQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Upload a batch of raw position accounting lot valuations. Uses information in .batch to determine whether to create a new batch or new version of an existing batch. e.g. When the source type is API, if a batch with the same value for the combination of externalId and fundAdminId then a new version of that batch will be created, otherwise a new batch will be created with a unique id and version equal to 1.

 * @summary Create a position accounting lot valuation batch
 */
export const uploadRawPositionAccountingLotValuationsBatch = (
  rawPositionAccountingLotValuationsBatch: RawPositionAccountingLotValuationsBatch
) => {
  return fetchInstance<BatchIdentifier>({
    url: `/funds-investments/v1/position-accounting-lot-valuations/raw/batch`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: rawPositionAccountingLotValuationsBatch,
  })
}

export const getUploadRawPositionAccountingLotValuationsBatchMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadRawPositionAccountingLotValuationsBatch>>,
    TError,
    { data: RawPositionAccountingLotValuationsBatch },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadRawPositionAccountingLotValuationsBatch>>,
  TError,
  { data: RawPositionAccountingLotValuationsBatch },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadRawPositionAccountingLotValuationsBatch>>,
    { data: RawPositionAccountingLotValuationsBatch }
  > = (props) => {
    const { data } = props ?? {}

    return uploadRawPositionAccountingLotValuationsBatch(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UploadRawPositionAccountingLotValuationsBatchMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof uploadRawPositionAccountingLotValuationsBatch>>
  >
export type UploadRawPositionAccountingLotValuationsBatchMutationBody =
  RawPositionAccountingLotValuationsBatch
export type UploadRawPositionAccountingLotValuationsBatchMutationError =
  ErrorType<void>

/**
 * @summary Create a position accounting lot valuation batch
 */
export const useUploadRawPositionAccountingLotValuationsBatch = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadRawPositionAccountingLotValuationsBatch>>,
    TError,
    { data: RawPositionAccountingLotValuationsBatch },
    TContext
  >
}) => {
  const mutationOptions =
    getUploadRawPositionAccountingLotValuationsBatchMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Returns a batch of raw position accounting lot valuations.
 * @summary Get all position accounting lot valuations in a raw batch
 */
export const getRawPositionAccountingLotValuationsBatch = (
  batchId: string,
  params?: GetRawPositionAccountingLotValuationsBatchParams,
  signal?: AbortSignal
) => {
  return fetchInstance<RawPositionAccountingLotValuationsBatchOutbound>({
    url: `/funds-investments/v1/position-accounting-lot-valuations/raw/batch/${batchId}`,
    method: 'GET',
    params,
    signal,
  })
}

export const getGetRawPositionAccountingLotValuationsBatchQueryKey = (
  batchId: string,
  params?: GetRawPositionAccountingLotValuationsBatchParams
) => {
  return [
    `/funds-investments/v1/position-accounting-lot-valuations/raw/batch/${batchId}`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetRawPositionAccountingLotValuationsBatchInfiniteQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getRawPositionAccountingLotValuationsBatch>
    >,
    TError = ErrorType<void>
  >(
    batchId: string,
    params?: GetRawPositionAccountingLotValuationsBatchParams,
    options?: {
      query?: UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getRawPositionAccountingLotValuationsBatch>>,
        TError,
        TData
      >
    }
  ) => {
    const { query: queryOptions } = options ?? {}

    const queryKey =
      queryOptions?.queryKey ??
      getGetRawPositionAccountingLotValuationsBatchQueryKey(batchId, params)

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof getRawPositionAccountingLotValuationsBatch>>
    > = ({ signal }) =>
      getRawPositionAccountingLotValuationsBatch(batchId, params, signal)

    return {
      queryKey,
      queryFn,
      enabled: !!batchId,
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getRawPositionAccountingLotValuationsBatch>>,
      TError,
      TData
    > & { queryKey: QueryKey }
  }

export type GetRawPositionAccountingLotValuationsBatchInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getRawPositionAccountingLotValuationsBatch>>
  >
export type GetRawPositionAccountingLotValuationsBatchInfiniteQueryError =
  ErrorType<void>

/**
 * @summary Get all position accounting lot valuations in a raw batch
 */
export const useGetRawPositionAccountingLotValuationsBatchInfinite = <
  TData = Awaited<
    ReturnType<typeof getRawPositionAccountingLotValuationsBatch>
  >,
  TError = ErrorType<void>
>(
  batchId: string,
  params?: GetRawPositionAccountingLotValuationsBatchParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getRawPositionAccountingLotValuationsBatch>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetRawPositionAccountingLotValuationsBatchInfiniteQueryOptions(
      batchId,
      params,
      options
    )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetRawPositionAccountingLotValuationsBatchQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getRawPositionAccountingLotValuationsBatch>
  >,
  TError = ErrorType<void>
>(
  batchId: string,
  params?: GetRawPositionAccountingLotValuationsBatchParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRawPositionAccountingLotValuationsBatch>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetRawPositionAccountingLotValuationsBatchQueryKey(batchId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getRawPositionAccountingLotValuationsBatch>>
  > = ({ signal }) =>
    getRawPositionAccountingLotValuationsBatch(batchId, params, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!batchId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getRawPositionAccountingLotValuationsBatch>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetRawPositionAccountingLotValuationsBatchQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRawPositionAccountingLotValuationsBatch>>
>
export type GetRawPositionAccountingLotValuationsBatchQueryError =
  ErrorType<void>

/**
 * @summary Get all position accounting lot valuations in a raw batch
 */
export const useGetRawPositionAccountingLotValuationsBatch = <
  TData = Awaited<
    ReturnType<typeof getRawPositionAccountingLotValuationsBatch>
  >,
  TError = ErrorType<void>
>(
  batchId: string,
  params?: GetRawPositionAccountingLotValuationsBatchParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRawPositionAccountingLotValuationsBatch>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetRawPositionAccountingLotValuationsBatchQueryOptions(
      batchId,
      params,
      options
    )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Will search for the raw position valuation wrapping response in pages.
 * @summary Search for position accounting lot valuations in a raw batch
 */
export const searchRawPositionAccountingLotValuationsBatch = (
  batchId: string,
  searchRawPositionAccountingLotValuationsRequest: SearchRawPositionAccountingLotValuationsRequest
) => {
  return fetchInstance<SearchRawPositionAccountingLotValuationsResponse>({
    url: `/funds-investments/v1/position-accounting-lot-valuations/raw/batch/${batchId}/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchRawPositionAccountingLotValuationsRequest,
  })
}

export const getSearchRawPositionAccountingLotValuationsBatchMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchRawPositionAccountingLotValuationsBatch>>,
    TError,
    { batchId: string; data: SearchRawPositionAccountingLotValuationsRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchRawPositionAccountingLotValuationsBatch>>,
  TError,
  { batchId: string; data: SearchRawPositionAccountingLotValuationsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchRawPositionAccountingLotValuationsBatch>>,
    { batchId: string; data: SearchRawPositionAccountingLotValuationsRequest }
  > = (props) => {
    const { batchId, data } = props ?? {}

    return searchRawPositionAccountingLotValuationsBatch(batchId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchRawPositionAccountingLotValuationsBatchMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof searchRawPositionAccountingLotValuationsBatch>>
  >
export type SearchRawPositionAccountingLotValuationsBatchMutationBody =
  SearchRawPositionAccountingLotValuationsRequest
export type SearchRawPositionAccountingLotValuationsBatchMutationError =
  ErrorType<void>

/**
 * @summary Search for position accounting lot valuations in a raw batch
 */
export const useSearchRawPositionAccountingLotValuationsBatch = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchRawPositionAccountingLotValuationsBatch>>,
    TError,
    { batchId: string; data: SearchRawPositionAccountingLotValuationsRequest },
    TContext
  >
}) => {
  const mutationOptions =
    getSearchRawPositionAccountingLotValuationsBatchMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Will return batch Info for the given batch ID.
 * @summary Get batch info for a raw position accounting lot valuation batch
 */
export const getRawPositionAccountingLotValuationBatchInfo = (
  batchId: string,
  params?: GetRawPositionAccountingLotValuationBatchInfoParams,
  signal?: AbortSignal
) => {
  return fetchInstance<RawBatchInfoOutbound>({
    url: `/funds-investments/v1/position-accounting-lot-valuations/raw/batch/${batchId}/info`,
    method: 'GET',
    params,
    signal,
  })
}

export const getGetRawPositionAccountingLotValuationBatchInfoQueryKey = (
  batchId: string,
  params?: GetRawPositionAccountingLotValuationBatchInfoParams
) => {
  return [
    `/funds-investments/v1/position-accounting-lot-valuations/raw/batch/${batchId}/info`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetRawPositionAccountingLotValuationBatchInfoInfiniteQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getRawPositionAccountingLotValuationBatchInfo>
    >,
    TError = ErrorType<void>
  >(
    batchId: string,
    params?: GetRawPositionAccountingLotValuationBatchInfoParams,
    options?: {
      query?: UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof getRawPositionAccountingLotValuationBatchInfo>
        >,
        TError,
        TData
      >
    }
  ) => {
    const { query: queryOptions } = options ?? {}

    const queryKey =
      queryOptions?.queryKey ??
      getGetRawPositionAccountingLotValuationBatchInfoQueryKey(batchId, params)

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof getRawPositionAccountingLotValuationBatchInfo>>
    > = ({ signal }) =>
      getRawPositionAccountingLotValuationBatchInfo(batchId, params, signal)

    return {
      queryKey,
      queryFn,
      enabled: !!batchId,
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getRawPositionAccountingLotValuationBatchInfo>>,
      TError,
      TData
    > & { queryKey: QueryKey }
  }

export type GetRawPositionAccountingLotValuationBatchInfoInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getRawPositionAccountingLotValuationBatchInfo>>
  >
export type GetRawPositionAccountingLotValuationBatchInfoInfiniteQueryError =
  ErrorType<void>

/**
 * @summary Get batch info for a raw position accounting lot valuation batch
 */
export const useGetRawPositionAccountingLotValuationBatchInfoInfinite = <
  TData = Awaited<
    ReturnType<typeof getRawPositionAccountingLotValuationBatchInfo>
  >,
  TError = ErrorType<void>
>(
  batchId: string,
  params?: GetRawPositionAccountingLotValuationBatchInfoParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getRawPositionAccountingLotValuationBatchInfo>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetRawPositionAccountingLotValuationBatchInfoInfiniteQueryOptions(
      batchId,
      params,
      options
    )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetRawPositionAccountingLotValuationBatchInfoQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getRawPositionAccountingLotValuationBatchInfo>
  >,
  TError = ErrorType<void>
>(
  batchId: string,
  params?: GetRawPositionAccountingLotValuationBatchInfoParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRawPositionAccountingLotValuationBatchInfo>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetRawPositionAccountingLotValuationBatchInfoQueryKey(batchId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getRawPositionAccountingLotValuationBatchInfo>>
  > = ({ signal }) =>
    getRawPositionAccountingLotValuationBatchInfo(batchId, params, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!batchId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getRawPositionAccountingLotValuationBatchInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetRawPositionAccountingLotValuationBatchInfoQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getRawPositionAccountingLotValuationBatchInfo>>
  >
export type GetRawPositionAccountingLotValuationBatchInfoQueryError =
  ErrorType<void>

/**
 * @summary Get batch info for a raw position accounting lot valuation batch
 */
export const useGetRawPositionAccountingLotValuationBatchInfo = <
  TData = Awaited<
    ReturnType<typeof getRawPositionAccountingLotValuationBatchInfo>
  >,
  TError = ErrorType<void>
>(
  batchId: string,
  params?: GetRawPositionAccountingLotValuationBatchInfoParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRawPositionAccountingLotValuationBatchInfo>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetRawPositionAccountingLotValuationBatchInfoQueryOptions(
      batchId,
      params,
      options
    )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Will return audit info for the given batch ID and version.
 * @summary Get audit info for a raw position accounting lot valuation batch
 */
export const getRawPositionAccountingLotValuationAuditInfo = (
  batchId: string,
  params?: GetRawPositionAccountingLotValuationAuditInfoParams,
  signal?: AbortSignal
) => {
  return fetchInstance<AuditInfo>({
    url: `/funds-investments/v1/position-accounting-lot-valuations/raw/batch/${batchId}/audit`,
    method: 'GET',
    params,
    signal,
  })
}

export const getGetRawPositionAccountingLotValuationAuditInfoQueryKey = (
  batchId: string,
  params?: GetRawPositionAccountingLotValuationAuditInfoParams
) => {
  return [
    `/funds-investments/v1/position-accounting-lot-valuations/raw/batch/${batchId}/audit`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetRawPositionAccountingLotValuationAuditInfoInfiniteQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getRawPositionAccountingLotValuationAuditInfo>
    >,
    TError = ErrorType<void>
  >(
    batchId: string,
    params?: GetRawPositionAccountingLotValuationAuditInfoParams,
    options?: {
      query?: UseInfiniteQueryOptions<
        Awaited<
          ReturnType<typeof getRawPositionAccountingLotValuationAuditInfo>
        >,
        TError,
        TData
      >
    }
  ) => {
    const { query: queryOptions } = options ?? {}

    const queryKey =
      queryOptions?.queryKey ??
      getGetRawPositionAccountingLotValuationAuditInfoQueryKey(batchId, params)

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof getRawPositionAccountingLotValuationAuditInfo>>
    > = ({ signal }) =>
      getRawPositionAccountingLotValuationAuditInfo(batchId, params, signal)

    return {
      queryKey,
      queryFn,
      enabled: !!batchId,
      ...queryOptions,
    } as UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getRawPositionAccountingLotValuationAuditInfo>>,
      TError,
      TData
    > & { queryKey: QueryKey }
  }

export type GetRawPositionAccountingLotValuationAuditInfoInfiniteQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getRawPositionAccountingLotValuationAuditInfo>>
  >
export type GetRawPositionAccountingLotValuationAuditInfoInfiniteQueryError =
  ErrorType<void>

/**
 * @summary Get audit info for a raw position accounting lot valuation batch
 */
export const useGetRawPositionAccountingLotValuationAuditInfoInfinite = <
  TData = Awaited<
    ReturnType<typeof getRawPositionAccountingLotValuationAuditInfo>
  >,
  TError = ErrorType<void>
>(
  batchId: string,
  params?: GetRawPositionAccountingLotValuationAuditInfoParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getRawPositionAccountingLotValuationAuditInfo>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetRawPositionAccountingLotValuationAuditInfoInfiniteQueryOptions(
      batchId,
      params,
      options
    )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetRawPositionAccountingLotValuationAuditInfoQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getRawPositionAccountingLotValuationAuditInfo>
  >,
  TError = ErrorType<void>
>(
  batchId: string,
  params?: GetRawPositionAccountingLotValuationAuditInfoParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRawPositionAccountingLotValuationAuditInfo>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetRawPositionAccountingLotValuationAuditInfoQueryKey(batchId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getRawPositionAccountingLotValuationAuditInfo>>
  > = ({ signal }) =>
    getRawPositionAccountingLotValuationAuditInfo(batchId, params, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!batchId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getRawPositionAccountingLotValuationAuditInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetRawPositionAccountingLotValuationAuditInfoQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof getRawPositionAccountingLotValuationAuditInfo>>
  >
export type GetRawPositionAccountingLotValuationAuditInfoQueryError =
  ErrorType<void>

/**
 * @summary Get audit info for a raw position accounting lot valuation batch
 */
export const useGetRawPositionAccountingLotValuationAuditInfo = <
  TData = Awaited<
    ReturnType<typeof getRawPositionAccountingLotValuationAuditInfo>
  >,
  TError = ErrorType<void>
>(
  batchId: string,
  params?: GetRawPositionAccountingLotValuationAuditInfoParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRawPositionAccountingLotValuationAuditInfo>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetRawPositionAccountingLotValuationAuditInfoQueryOptions(
      batchId,
      params,
      options
    )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Search Investments
 * @summary Search for an investment
 */
export const searchInvestments = (
  searchInvestmentsRequest: SearchInvestmentsRequest
) => {
  return fetchInstance<SearchInvestmentsResponse>({
    url: `/funds-investments/v1/investments/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchInvestmentsRequest,
  })
}

export const getSearchInvestmentsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchInvestments>>,
    TError,
    { data: SearchInvestmentsRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchInvestments>>,
  TError,
  { data: SearchInvestmentsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchInvestments>>,
    { data: SearchInvestmentsRequest }
  > = (props) => {
    const { data } = props ?? {}

    return searchInvestments(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchInvestmentsMutationResult = NonNullable<
  Awaited<ReturnType<typeof searchInvestments>>
>
export type SearchInvestmentsMutationBody = SearchInvestmentsRequest
export type SearchInvestmentsMutationError = ErrorType<void>

/**
 * @summary Search for an investment
 */
export const useSearchInvestments = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchInvestments>>,
    TError,
    { data: SearchInvestmentsRequest },
    TContext
  >
}) => {
  const mutationOptions = getSearchInvestmentsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Get an investment external IDs.
 * @summary Get an investment external IDs
 */
export const getInvestmentExternalIds = (
  investmentId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<ExternalIds>({
    url: `/funds-investments/v1/investments/${investmentId}/external-ids`,
    method: 'GET',
    signal,
  })
}

export const getGetInvestmentExternalIdsQueryKey = (investmentId: string) => {
  return [
    `/funds-investments/v1/investments/${investmentId}/external-ids`,
  ] as const
}

export const getGetInvestmentExternalIdsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getInvestmentExternalIds>>,
  TError = ErrorType<void>
>(
  investmentId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getInvestmentExternalIds>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetInvestmentExternalIdsQueryKey(investmentId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getInvestmentExternalIds>>
  > = ({ signal }) => getInvestmentExternalIds(investmentId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!investmentId,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getInvestmentExternalIds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetInvestmentExternalIdsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInvestmentExternalIds>>
>
export type GetInvestmentExternalIdsInfiniteQueryError = ErrorType<void>

/**
 * @summary Get an investment external IDs
 */
export const useGetInvestmentExternalIdsInfinite = <
  TData = Awaited<ReturnType<typeof getInvestmentExternalIds>>,
  TError = ErrorType<void>
>(
  investmentId: string,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getInvestmentExternalIds>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetInvestmentExternalIdsInfiniteQueryOptions(
    investmentId,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetInvestmentExternalIdsQueryOptions = <
  TData = Awaited<ReturnType<typeof getInvestmentExternalIds>>,
  TError = ErrorType<void>
>(
  investmentId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInvestmentExternalIds>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetInvestmentExternalIdsQueryKey(investmentId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getInvestmentExternalIds>>
  > = ({ signal }) => getInvestmentExternalIds(investmentId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!investmentId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getInvestmentExternalIds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetInvestmentExternalIdsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getInvestmentExternalIds>>
>
export type GetInvestmentExternalIdsQueryError = ErrorType<void>

/**
 * @summary Get an investment external IDs
 */
export const useGetInvestmentExternalIds = <
  TData = Awaited<ReturnType<typeof getInvestmentExternalIds>>,
  TError = ErrorType<void>
>(
  investmentId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getInvestmentExternalIds>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetInvestmentExternalIdsQueryOptions(
    investmentId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Update an investment external IDs.
 * @summary Update an investment external IDs
 */
export const updateInvestmentExternalIds = (
  investmentId: string,
  externalIds: ExternalIds
) => {
  return fetchInstance<Investment>({
    url: `/funds-investments/v1/investments/${investmentId}/external-ids`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: externalIds,
  })
}

export const getUpdateInvestmentExternalIdsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInvestmentExternalIds>>,
    TError,
    { investmentId: string; data: ExternalIds },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateInvestmentExternalIds>>,
  TError,
  { investmentId: string; data: ExternalIds },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateInvestmentExternalIds>>,
    { investmentId: string; data: ExternalIds }
  > = (props) => {
    const { investmentId, data } = props ?? {}

    return updateInvestmentExternalIds(investmentId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateInvestmentExternalIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateInvestmentExternalIds>>
>
export type UpdateInvestmentExternalIdsMutationBody = ExternalIds
export type UpdateInvestmentExternalIdsMutationError = ErrorType<void>

/**
 * @summary Update an investment external IDs
 */
export const useUpdateInvestmentExternalIds = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateInvestmentExternalIds>>,
    TError,
    { investmentId: string; data: ExternalIds },
    TContext
  >
}) => {
  const mutationOptions = getUpdateInvestmentExternalIdsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Delete an investment external IDs.
 * @summary Delete an investment external IDs
 */
export const deleteInvestmentExternalIds = (investmentId: string) => {
  return fetchInstance<Investment>({
    url: `/funds-investments/v1/investments/${investmentId}/external-ids`,
    method: 'DELETE',
  })
}

export const getDeleteInvestmentExternalIdsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteInvestmentExternalIds>>,
    TError,
    { investmentId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteInvestmentExternalIds>>,
  TError,
  { investmentId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteInvestmentExternalIds>>,
    { investmentId: string }
  > = (props) => {
    const { investmentId } = props ?? {}

    return deleteInvestmentExternalIds(investmentId)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteInvestmentExternalIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteInvestmentExternalIds>>
>

export type DeleteInvestmentExternalIdsMutationError = ErrorType<void>

/**
 * @summary Delete an investment external IDs
 */
export const useDeleteInvestmentExternalIds = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteInvestmentExternalIds>>,
    TError,
    { investmentId: string },
    TContext
  >
}) => {
  const mutationOptions = getDeleteInvestmentExternalIdsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Patch an investment external IDs.
 * @summary Patch an investment external IDs
 */
export const patchInvestmentExternalIds = (
  investmentId: string,
  patchBody: PatchBody
) => {
  return fetchInstance<Investment>({
    url: `/funds-investments/v1/investments/${investmentId}/external-ids`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchBody,
  })
}

export const getPatchInvestmentExternalIdsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchInvestmentExternalIds>>,
    TError,
    { investmentId: string; data: PatchBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchInvestmentExternalIds>>,
  TError,
  { investmentId: string; data: PatchBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchInvestmentExternalIds>>,
    { investmentId: string; data: PatchBody }
  > = (props) => {
    const { investmentId, data } = props ?? {}

    return patchInvestmentExternalIds(investmentId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchInvestmentExternalIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchInvestmentExternalIds>>
>
export type PatchInvestmentExternalIdsMutationBody = PatchBody
export type PatchInvestmentExternalIdsMutationError = ErrorType<void>

/**
 * @summary Patch an investment external IDs
 */
export const usePatchInvestmentExternalIds = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchInvestmentExternalIds>>,
    TError,
    { investmentId: string; data: PatchBody },
    TContext
  >
}) => {
  const mutationOptions = getPatchInvestmentExternalIdsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Create a position fund mapping
 */
export const createPositionFundMapping = (
  positionFundMappingRequest: PositionFundMappingRequest
) => {
  return fetchInstance<void>({
    url: `/funds-investments/v1/configuration/position-creation/fund-mapping`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: positionFundMappingRequest,
  })
}

export const getCreatePositionFundMappingMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPositionFundMapping>>,
    TError,
    { data: PositionFundMappingRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPositionFundMapping>>,
  TError,
  { data: PositionFundMappingRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPositionFundMapping>>,
    { data: PositionFundMappingRequest }
  > = (props) => {
    const { data } = props ?? {}

    return createPositionFundMapping(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreatePositionFundMappingMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPositionFundMapping>>
>
export type CreatePositionFundMappingMutationBody = PositionFundMappingRequest
export type CreatePositionFundMappingMutationError = ErrorType<unknown>

/**
 * @summary Create a position fund mapping
 */
export const useCreatePositionFundMapping = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPositionFundMapping>>,
    TError,
    { data: PositionFundMappingRequest },
    TContext
  >
}) => {
  const mutationOptions = getCreatePositionFundMappingMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Create a position share class mapping
 */
export const createPositionShareClassMapping = (
  positionShareClassMappingRequest: PositionShareClassMappingRequest
) => {
  return fetchInstance<void>({
    url: `/funds-investments/v1/configuration/position-creation/share-class-mapping`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: positionShareClassMappingRequest,
  })
}

export const getCreatePositionShareClassMappingMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPositionShareClassMapping>>,
    TError,
    { data: PositionShareClassMappingRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPositionShareClassMapping>>,
  TError,
  { data: PositionShareClassMappingRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPositionShareClassMapping>>,
    { data: PositionShareClassMappingRequest }
  > = (props) => {
    const { data } = props ?? {}

    return createPositionShareClassMapping(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreatePositionShareClassMappingMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPositionShareClassMapping>>
>
export type CreatePositionShareClassMappingMutationBody =
  PositionShareClassMappingRequest
export type CreatePositionShareClassMappingMutationError = ErrorType<unknown>

/**
 * @summary Create a position share class mapping
 */
export const useCreatePositionShareClassMapping = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPositionShareClassMapping>>,
    TError,
    { data: PositionShareClassMappingRequest },
    TContext
  >
}) => {
  const mutationOptions =
    getCreatePositionShareClassMappingMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * TACTICAL(It will be replaced by a stand-alone service in the future) Approve Exception for the QC Tool rules checking in the Canonicalization Pipeline.
 * @summary Create approved exceptions
 */
export const approveExceptions = (
  approvedExceptionsRequest: ApprovedExceptionsRequest
) => {
  return fetchInstance<ApprovedExceptionsResponse>({
    url: `/funds-investments/v1/approved-exceptions`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: approvedExceptionsRequest,
  })
}

export const getApproveExceptionsMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof approveExceptions>>,
    TError,
    { data: ApprovedExceptionsRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof approveExceptions>>,
  TError,
  { data: ApprovedExceptionsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof approveExceptions>>,
    { data: ApprovedExceptionsRequest }
  > = (props) => {
    const { data } = props ?? {}

    return approveExceptions(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type ApproveExceptionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof approveExceptions>>
>
export type ApproveExceptionsMutationBody = ApprovedExceptionsRequest
export type ApproveExceptionsMutationError = ErrorType<void>

/**
 * @summary Create approved exceptions
 */
export const useApproveExceptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof approveExceptions>>,
    TError,
    { data: ApprovedExceptionsRequest },
    TContext
  >
}) => {
  const mutationOptions = getApproveExceptionsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Return advisor firms of accounts that have a position.
 * @summary Get all advisor firm filter options
 */
export const filterOptionsAdvisorFirms = () => {
  return fetchInstance<FilterOptionsAdvisorFirmsResponse>({
    url: `/funds-investments/v1/filter-options/advisor-firms`,
    method: 'POST',
  })
}

export const getFilterOptionsAdvisorFirmsQueryKey = () => {
  return [`/funds-investments/v1/filter-options/advisor-firms`] as const
}

export const getFilterOptionsAdvisorFirmsQueryOptions = <
  TData = Awaited<ReturnType<typeof filterOptionsAdvisorFirms>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof filterOptionsAdvisorFirms>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getFilterOptionsAdvisorFirmsQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof filterOptionsAdvisorFirms>>
  > = () => filterOptionsAdvisorFirms()

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof filterOptionsAdvisorFirms>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FilterOptionsAdvisorFirmsQueryResult = NonNullable<
  Awaited<ReturnType<typeof filterOptionsAdvisorFirms>>
>
export type FilterOptionsAdvisorFirmsQueryError = ErrorType<void>

/**
 * @summary Get all advisor firm filter options
 */
export const useFilterOptionsAdvisorFirms = <
  TData = Awaited<ReturnType<typeof filterOptionsAdvisorFirms>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof filterOptionsAdvisorFirms>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFilterOptionsAdvisorFirmsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Return households that are tied to investments
 * @summary Get all household filter options
 */
export const filterOptionsHouseholds = (signal?: AbortSignal) => {
  return fetchInstance<FilterOptionsHouseholdsResponse>({
    url: `/funds-investments/v1/filter-options/households`,
    method: 'GET',
    signal,
  })
}

export const getFilterOptionsHouseholdsQueryKey = () => {
  return [`/funds-investments/v1/filter-options/households`] as const
}

export const getFilterOptionsHouseholdsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof filterOptionsHouseholds>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof filterOptionsHouseholds>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getFilterOptionsHouseholdsQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof filterOptionsHouseholds>>
  > = ({ signal }) => filterOptionsHouseholds(signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof filterOptionsHouseholds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FilterOptionsHouseholdsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof filterOptionsHouseholds>>
>
export type FilterOptionsHouseholdsInfiniteQueryError = ErrorType<void>

/**
 * @summary Get all household filter options
 */
export const useFilterOptionsHouseholdsInfinite = <
  TData = Awaited<ReturnType<typeof filterOptionsHouseholds>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof filterOptionsHouseholds>>,
    TError,
    TData
  >
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFilterOptionsHouseholdsInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getFilterOptionsHouseholdsQueryOptions = <
  TData = Awaited<ReturnType<typeof filterOptionsHouseholds>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof filterOptionsHouseholds>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getFilterOptionsHouseholdsQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof filterOptionsHouseholds>>
  > = ({ signal }) => filterOptionsHouseholds(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof filterOptionsHouseholds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FilterOptionsHouseholdsQueryResult = NonNullable<
  Awaited<ReturnType<typeof filterOptionsHouseholds>>
>
export type FilterOptionsHouseholdsQueryError = ErrorType<void>

/**
 * @summary Get all household filter options
 */
export const useFilterOptionsHouseholds = <
  TData = Awaited<ReturnType<typeof filterOptionsHouseholds>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof filterOptionsHouseholds>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFilterOptionsHouseholdsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Returns funds
 * @summary Get all fund filter options
 */
export const filterOptionsFunds = (signal?: AbortSignal) => {
  return fetchInstance<FilterOptionsFundsResponse>({
    url: `/funds-investments/v1/filter-options/funds`,
    method: 'GET',
    signal,
  })
}

export const getFilterOptionsFundsQueryKey = () => {
  return [`/funds-investments/v1/filter-options/funds`] as const
}

export const getFilterOptionsFundsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof filterOptionsFunds>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof filterOptionsFunds>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getFilterOptionsFundsQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof filterOptionsFunds>>
  > = ({ signal }) => filterOptionsFunds(signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof filterOptionsFunds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FilterOptionsFundsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof filterOptionsFunds>>
>
export type FilterOptionsFundsInfiniteQueryError = ErrorType<void>

/**
 * @summary Get all fund filter options
 */
export const useFilterOptionsFundsInfinite = <
  TData = Awaited<ReturnType<typeof filterOptionsFunds>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof filterOptionsFunds>>,
    TError,
    TData
  >
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFilterOptionsFundsInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getFilterOptionsFundsQueryOptions = <
  TData = Awaited<ReturnType<typeof filterOptionsFunds>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof filterOptionsFunds>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getFilterOptionsFundsQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof filterOptionsFunds>>
  > = ({ signal }) => filterOptionsFunds(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof filterOptionsFunds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FilterOptionsFundsQueryResult = NonNullable<
  Awaited<ReturnType<typeof filterOptionsFunds>>
>
export type FilterOptionsFundsQueryError = ErrorType<void>

/**
 * @summary Get all fund filter options
 */
export const useFilterOptionsFunds = <
  TData = Awaited<ReturnType<typeof filterOptionsFunds>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof filterOptionsFunds>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFilterOptionsFundsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Returns fund admins
 * @summary Get all fund admins that have files in the data pipeline
 */
export const dataPipelineFilterFundAdmins = (signal?: AbortSignal) => {
  return fetchInstance<DataPipelineFilterFundAdminsResponse>({
    url: `/funds-investments/v1/data-pipeline/filter/fund-admins`,
    method: 'GET',
    signal,
  })
}

export const getDataPipelineFilterFundAdminsQueryKey = () => {
  return [`/funds-investments/v1/data-pipeline/filter/fund-admins`] as const
}

export const getDataPipelineFilterFundAdminsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof dataPipelineFilterFundAdmins>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof dataPipelineFilterFundAdmins>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getDataPipelineFilterFundAdminsQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof dataPipelineFilterFundAdmins>>
  > = ({ signal }) => dataPipelineFilterFundAdmins(signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof dataPipelineFilterFundAdmins>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type DataPipelineFilterFundAdminsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof dataPipelineFilterFundAdmins>>
>
export type DataPipelineFilterFundAdminsInfiniteQueryError = ErrorType<void>

/**
 * @summary Get all fund admins that have files in the data pipeline
 */
export const useDataPipelineFilterFundAdminsInfinite = <
  TData = Awaited<ReturnType<typeof dataPipelineFilterFundAdmins>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof dataPipelineFilterFundAdmins>>,
    TError,
    TData
  >
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getDataPipelineFilterFundAdminsInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getDataPipelineFilterFundAdminsQueryOptions = <
  TData = Awaited<ReturnType<typeof dataPipelineFilterFundAdmins>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof dataPipelineFilterFundAdmins>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getDataPipelineFilterFundAdminsQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof dataPipelineFilterFundAdmins>>
  > = ({ signal }) => dataPipelineFilterFundAdmins(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof dataPipelineFilterFundAdmins>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type DataPipelineFilterFundAdminsQueryResult = NonNullable<
  Awaited<ReturnType<typeof dataPipelineFilterFundAdmins>>
>
export type DataPipelineFilterFundAdminsQueryError = ErrorType<void>

/**
 * @summary Get all fund admins that have files in the data pipeline
 */
export const useDataPipelineFilterFundAdmins = <
  TData = Awaited<ReturnType<typeof dataPipelineFilterFundAdmins>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof dataPipelineFilterFundAdmins>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDataPipelineFilterFundAdminsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Returns Accounts
 * @summary Get all account filter options
 */
export const filterOptionsAccounts = (signal?: AbortSignal) => {
  return fetchInstance<FilterOptionsAccountsResponse>({
    url: `/funds-investments/v1/filter-options/accounts`,
    method: 'GET',
    signal,
  })
}

export const getFilterOptionsAccountsQueryKey = () => {
  return [`/funds-investments/v1/filter-options/accounts`] as const
}

export const getFilterOptionsAccountsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof filterOptionsAccounts>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof filterOptionsAccounts>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getFilterOptionsAccountsQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof filterOptionsAccounts>>
  > = ({ signal }) => filterOptionsAccounts(signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof filterOptionsAccounts>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FilterOptionsAccountsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof filterOptionsAccounts>>
>
export type FilterOptionsAccountsInfiniteQueryError = ErrorType<void>

/**
 * @summary Get all account filter options
 */
export const useFilterOptionsAccountsInfinite = <
  TData = Awaited<ReturnType<typeof filterOptionsAccounts>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof filterOptionsAccounts>>,
    TError,
    TData
  >
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFilterOptionsAccountsInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getFilterOptionsAccountsQueryOptions = <
  TData = Awaited<ReturnType<typeof filterOptionsAccounts>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof filterOptionsAccounts>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getFilterOptionsAccountsQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof filterOptionsAccounts>>
  > = ({ signal }) => filterOptionsAccounts(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof filterOptionsAccounts>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FilterOptionsAccountsQueryResult = NonNullable<
  Awaited<ReturnType<typeof filterOptionsAccounts>>
>
export type FilterOptionsAccountsQueryError = ErrorType<void>

/**
 * @summary Get all account filter options
 */
export const useFilterOptionsAccounts = <
  TData = Awaited<ReturnType<typeof filterOptionsAccounts>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof filterOptionsAccounts>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFilterOptionsAccountsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Will search for funds data used for display on the data pipeline.
 * @summary Search data pipeline
 */
export const searchDataPipeline = (
  searchDataPipelineRequest: SearchDataPipelineRequest
) => {
  return fetchInstance<SearchDataPipelineResponse>({
    url: `/funds-investments/v1/data-pipeline/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchDataPipelineRequest,
  })
}

export const getSearchDataPipelineMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchDataPipeline>>,
    TError,
    { data: SearchDataPipelineRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchDataPipeline>>,
  TError,
  { data: SearchDataPipelineRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchDataPipeline>>,
    { data: SearchDataPipelineRequest }
  > = (props) => {
    const { data } = props ?? {}

    return searchDataPipeline(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchDataPipelineMutationResult = NonNullable<
  Awaited<ReturnType<typeof searchDataPipeline>>
>
export type SearchDataPipelineMutationBody = SearchDataPipelineRequest
export type SearchDataPipelineMutationError = ErrorType<void>

/**
 * @summary Search data pipeline
 */
export const useSearchDataPipeline = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchDataPipeline>>,
    TError,
    { data: SearchDataPipelineRequest },
    TContext
  >
}) => {
  const mutationOptions = getSearchDataPipelineMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Returns file links associated with this pipeline run identifier.
 * @summary Get all files for a pipeline run
 */
export const getPipelineRunFiles = (
  dataPipelineFilesRequest: DataPipelineFilesRequest
) => {
  return fetchInstance<DataPipelineFilesResponse>({
    url: `/funds-investments/v1/data-pipeline/files`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: dataPipelineFilesRequest,
  })
}

export const getGetPipelineRunFilesQueryKey = (
  dataPipelineFilesRequest: DataPipelineFilesRequest
) => {
  return [
    `/funds-investments/v1/data-pipeline/files`,
    dataPipelineFilesRequest,
  ] as const
}

export const getGetPipelineRunFilesQueryOptions = <
  TData = Awaited<ReturnType<typeof getPipelineRunFiles>>,
  TError = ErrorType<void>
>(
  dataPipelineFilesRequest: DataPipelineFilesRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPipelineRunFiles>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPipelineRunFilesQueryKey(dataPipelineFilesRequest)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPipelineRunFiles>>
  > = () => getPipelineRunFiles(dataPipelineFilesRequest)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPipelineRunFiles>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPipelineRunFilesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPipelineRunFiles>>
>
export type GetPipelineRunFilesQueryError = ErrorType<void>

/**
 * @summary Get all files for a pipeline run
 */
export const useGetPipelineRunFiles = <
  TData = Awaited<ReturnType<typeof getPipelineRunFiles>>,
  TError = ErrorType<void>
>(
  dataPipelineFilesRequest: DataPipelineFilesRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPipelineRunFiles>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPipelineRunFilesQueryOptions(
    dataPipelineFilesRequest,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Saves information about a running pipeline task.
 * @summary Sets the information about a running pipeline task.
 */
export const postDataTaskLog = (postDataTaskLogBody: PostDataTaskLogBody) => {
  return fetchInstance<void>({
    url: `/funds-investments/v1/data-pipeline/tasklog`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: postDataTaskLogBody,
  })
}

export const getPostDataTaskLogMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postDataTaskLog>>,
    TError,
    { data: PostDataTaskLogBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof postDataTaskLog>>,
  TError,
  { data: PostDataTaskLogBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postDataTaskLog>>,
    { data: PostDataTaskLogBody }
  > = (props) => {
    const { data } = props ?? {}

    return postDataTaskLog(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostDataTaskLogMutationResult = NonNullable<
  Awaited<ReturnType<typeof postDataTaskLog>>
>
export type PostDataTaskLogMutationBody = PostDataTaskLogBody
export type PostDataTaskLogMutationError = ErrorType<unknown>

/**
 * @summary Sets the information about a running pipeline task.
 */
export const usePostDataTaskLog = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postDataTaskLog>>,
    TError,
    { data: PostDataTaskLogBody },
    TContext
  >
}) => {
  const mutationOptions = getPostDataTaskLogMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Runs the function  SyncPublishedTransactionsToPortalTransactions synchronous
 * @summary Execute published transactions sync
 */
export const writePublishedTransactionsToPortal = () => {
  return fetchInstance<void>({
    url: `/funds-investments/v1/sync-published-transactions`,
    method: 'POST',
  })
}

export const getWritePublishedTransactionsToPortalMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof writePublishedTransactionsToPortal>>,
    TError,
    void,
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof writePublishedTransactionsToPortal>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof writePublishedTransactionsToPortal>>,
    void
  > = () => {
    return writePublishedTransactionsToPortal()
  }

  return { mutationFn, ...mutationOptions }
}

export type WritePublishedTransactionsToPortalMutationResult = NonNullable<
  Awaited<ReturnType<typeof writePublishedTransactionsToPortal>>
>

export type WritePublishedTransactionsToPortalMutationError = ErrorType<unknown>

/**
 * @summary Execute published transactions sync
 */
export const useWritePublishedTransactionsToPortal = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof writePublishedTransactionsToPortal>>,
    TError,
    void,
    TContext
  >
}) => {
  const mutationOptions =
    getWritePublishedTransactionsToPortalMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Runs the function SyncPublishedPositionValuationsToPortalPositions synchronous
 * @summary Execute published position valuations sync
 */
export const writePublishedPositionValuationsToPortal = () => {
  return fetchInstance<void>({
    url: `/funds-investments/v1/sync-published-position-valuations`,
    method: 'POST',
  })
}

export const getWritePublishedPositionValuationsToPortalMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof writePublishedPositionValuationsToPortal>>,
    TError,
    void,
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof writePublishedPositionValuationsToPortal>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof writePublishedPositionValuationsToPortal>>,
    void
  > = () => {
    return writePublishedPositionValuationsToPortal()
  }

  return { mutationFn, ...mutationOptions }
}

export type WritePublishedPositionValuationsToPortalMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof writePublishedPositionValuationsToPortal>>
  >

export type WritePublishedPositionValuationsToPortalMutationError =
  ErrorType<unknown>

/**
 * @summary Execute published position valuations sync
 */
export const useWritePublishedPositionValuationsToPortal = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof writePublishedPositionValuationsToPortal>>,
    TError,
    void,
    TContext
  >
}) => {
  const mutationOptions =
    getWritePublishedPositionValuationsToPortalMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Set the funds configuration used to drive the sync/migration to/from portal and overall mastering of fund data
 * @summary Set funds config
 */
export const setFundsConfig = (
  fundAdminId: string,
  fundId: string,
  fundsConfig: FundsConfig
) => {
  return fetchInstance<void>({
    url: `/funds-investments/v1/admin/ads-funds-config/${fundAdminId}/${fundId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: fundsConfig,
  })
}

export const getSetFundsConfigMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setFundsConfig>>,
    TError,
    { fundAdminId: string; fundId: string; data: FundsConfig },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof setFundsConfig>>,
  TError,
  { fundAdminId: string; fundId: string; data: FundsConfig },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setFundsConfig>>,
    { fundAdminId: string; fundId: string; data: FundsConfig }
  > = (props) => {
    const { fundAdminId, fundId, data } = props ?? {}

    return setFundsConfig(fundAdminId, fundId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SetFundsConfigMutationResult = NonNullable<
  Awaited<ReturnType<typeof setFundsConfig>>
>
export type SetFundsConfigMutationBody = FundsConfig
export type SetFundsConfigMutationError = ErrorType<unknown>

/**
 * @summary Set funds config
 */
export const useSetFundsConfig = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setFundsConfig>>,
    TError,
    { fundAdminId: string; fundId: string; data: FundsConfig },
    TContext
  >
}) => {
  const mutationOptions = getSetFundsConfigMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Delete the funds configuration used to drive the sync/migration to/from portal and overall mastering of fund data
 * @summary Delete funds config
 */
export const deleteFundsConfig = (fundAdminId: string, fundId: string) => {
  return fetchInstance<void>({
    url: `/funds-investments/v1/admin/ads-funds-config/${fundAdminId}/${fundId}`,
    method: 'DELETE',
  })
}

export const getDeleteFundsConfigMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFundsConfig>>,
    TError,
    { fundAdminId: string; fundId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteFundsConfig>>,
  TError,
  { fundAdminId: string; fundId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteFundsConfig>>,
    { fundAdminId: string; fundId: string }
  > = (props) => {
    const { fundAdminId, fundId } = props ?? {}

    return deleteFundsConfig(fundAdminId, fundId)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteFundsConfigMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteFundsConfig>>
>

export type DeleteFundsConfigMutationError = ErrorType<unknown>

/**
 * @summary Delete funds config
 */
export const useDeleteFundsConfig = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFundsConfig>>,
    TError,
    { fundAdminId: string; fundId: string },
    TContext
  >
}) => {
  const mutationOptions = getDeleteFundsConfigMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Recreate transaction
 * @summary Recreate transaction
 */
export const recreateTransaction = (transactionId: string) => {
  return fetchInstance<TransactionsResults>({
    url: `/funds-investments/v1/admin/recreate-transaction/${transactionId}`,
    method: 'PUT',
  })
}

export const getRecreateTransactionMutationOptions = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recreateTransaction>>,
    TError,
    { transactionId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof recreateTransaction>>,
  TError,
  { transactionId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof recreateTransaction>>,
    { transactionId: string }
  > = (props) => {
    const { transactionId } = props ?? {}

    return recreateTransaction(transactionId)
  }

  return { mutationFn, ...mutationOptions }
}

export type RecreateTransactionMutationResult = NonNullable<
  Awaited<ReturnType<typeof recreateTransaction>>
>

export type RecreateTransactionMutationError = ErrorType<void>

/**
 * @summary Recreate transaction
 */
export const useRecreateTransaction = <
  TError = ErrorType<void>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recreateTransaction>>,
    TError,
    { transactionId: string },
    TContext
  >
}) => {
  const mutationOptions = getRecreateTransactionMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Returns the list of fund administrator templates
 * @summary Get a list of the relationships between funds administrators and templates
 */
export const listFundAdministratorTemplates = (signal?: AbortSignal) => {
  return fetchInstance<FileUploadFundAdminTemplateResponse>({
    url: `/funds-investments/v1/file-upload/fund-administrators/templates`,
    method: 'GET',
    signal,
  })
}

export const getListFundAdministratorTemplatesQueryKey = () => {
  return [
    `/funds-investments/v1/file-upload/fund-administrators/templates`,
  ] as const
}

export const getListFundAdministratorTemplatesInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listFundAdministratorTemplates>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listFundAdministratorTemplates>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getListFundAdministratorTemplatesQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listFundAdministratorTemplates>>
  > = ({ signal }) => listFundAdministratorTemplates(signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listFundAdministratorTemplates>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ListFundAdministratorTemplatesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof listFundAdministratorTemplates>>
>
export type ListFundAdministratorTemplatesInfiniteQueryError = ErrorType<void>

/**
 * @summary Get a list of the relationships between funds administrators and templates
 */
export const useListFundAdministratorTemplatesInfinite = <
  TData = Awaited<ReturnType<typeof listFundAdministratorTemplates>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listFundAdministratorTemplates>>,
    TError,
    TData
  >
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getListFundAdministratorTemplatesInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getListFundAdministratorTemplatesQueryOptions = <
  TData = Awaited<ReturnType<typeof listFundAdministratorTemplates>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listFundAdministratorTemplates>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getListFundAdministratorTemplatesQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listFundAdministratorTemplates>>
  > = ({ signal }) => listFundAdministratorTemplates(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listFundAdministratorTemplates>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ListFundAdministratorTemplatesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listFundAdministratorTemplates>>
>
export type ListFundAdministratorTemplatesQueryError = ErrorType<void>

/**
 * @summary Get a list of the relationships between funds administrators and templates
 */
export const useListFundAdministratorTemplates = <
  TData = Awaited<ReturnType<typeof listFundAdministratorTemplates>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listFundAdministratorTemplates>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListFundAdministratorTemplatesQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Returns a list of funds that are managed by a fund administrator.
 * @summary Returns all funds that were consumed by funds-investments from the FPM team, that are not deleted and have the fund administrator set
 */
export const listFundsWithAdmin = (signal?: AbortSignal) => {
  return fetchInstance<FileUploadFundResponse>({
    url: `/funds-investments/v1/file-upload/funds`,
    method: 'GET',
    signal,
  })
}

export const getListFundsWithAdminQueryKey = () => {
  return [`/funds-investments/v1/file-upload/funds`] as const
}

export const getListFundsWithAdminInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof listFundsWithAdmin>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listFundsWithAdmin>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getListFundsWithAdminQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listFundsWithAdmin>>
  > = ({ signal }) => listFundsWithAdmin(signal)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listFundsWithAdmin>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ListFundsWithAdminInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof listFundsWithAdmin>>
>
export type ListFundsWithAdminInfiniteQueryError = ErrorType<void>

/**
 * @summary Returns all funds that were consumed by funds-investments from the FPM team, that are not deleted and have the fund administrator set
 */
export const useListFundsWithAdminInfinite = <
  TData = Awaited<ReturnType<typeof listFundsWithAdmin>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof listFundsWithAdmin>>,
    TError,
    TData
  >
}): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListFundsWithAdminInfiniteQueryOptions(options)

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getListFundsWithAdminQueryOptions = <
  TData = Awaited<ReturnType<typeof listFundsWithAdmin>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listFundsWithAdmin>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getListFundsWithAdminQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof listFundsWithAdmin>>
  > = ({ signal }) => listFundsWithAdmin(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof listFundsWithAdmin>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ListFundsWithAdminQueryResult = NonNullable<
  Awaited<ReturnType<typeof listFundsWithAdmin>>
>
export type ListFundsWithAdminQueryError = ErrorType<void>

/**
 * @summary Returns all funds that were consumed by funds-investments from the FPM team, that are not deleted and have the fund administrator set
 */
export const useListFundsWithAdmin = <
  TData = Awaited<ReturnType<typeof listFundsWithAdmin>>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof listFundsWithAdmin>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getListFundsWithAdminQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}
