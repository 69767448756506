import { motion } from 'framer-motion'
import { useState } from 'react'

import { TextInput } from '@cais-group/equity/atoms/text-input'
import {
  SearchSource,
  useGlobalSearch,
} from '@cais-group/global-search/domain/use-global-search'
import { ExpandedView } from '@cais-group/global-search/ui/expanded-view'
import { ModalView } from '@cais-group/global-search/ui/modal-view'
import { allSearchSources } from '@cais-group/global-search/ui/search-domains'

export const GlobalSearch = () => {
  const [searchText, setSearchText] = useState('')
  const [showResults, setShowResults] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [activeSearchSources, setActiveSearchSources] =
    useState<SearchSource[]>(allSearchSources)

  const toggleSearchSources = (sources: SearchSource[]) => {
    setActiveSearchSources((state) =>
      sources.reduce((acc, source) => {
        if (acc.includes(source)) {
          return acc.filter((d) => d !== source)
        }
        return [...acc, source]
      }, state)
    )
  }

  const onUpdateSearch = (searchString: string) => {
    setSearchText(searchString)
    setShowResults(true)
  }

  const onClose = () => {
    setIsExpanded(false)
    setShowResults(false)
  }

  const data = useGlobalSearch({
    searchText,
    sources: activeSearchSources,
  })

  return (
    <div className="relative ml-32 mr-56 min-w-[400px]">
      <TextInput
        placeholder="Search for anything"
        value={searchText}
        onChange={(e) => onUpdateSearch(e.target.value || '')}
        startAdornment={{
          variant: 'icon',
          icon: 'Search',
          color: 'eq-color-neutral-900',
        }}
        endAdornment={
          searchText
            ? {
                variant: 'icon',
                icon: 'Close',
                color: 'eq-color-neutral-400',
                onClick: () => onUpdateSearch(''),
              }
            : undefined
        }
        onFocus={() => {
          if (searchText) {
            setShowResults(true)
          }
        }}
      />
      {isExpanded ? (
        <motion.div
          className="fixed bottom-0 left-0 right-0 top-0 z-50 flex h-[100vh] min-h-[100vh] flex-col overflow-y-scroll"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <ExpandedView
            data={data}
            toggleSearchSources={toggleSearchSources}
            activeSearchSources={activeSearchSources}
            updateSearchText={onUpdateSearch}
            onClose={() => {
              setIsExpanded(false)
              setShowResults(false)
            }}
            searchText={searchText}
          />
        </motion.div>
      ) : null}
      {showResults && !isExpanded ? (
        <ModalView
          data={data}
          toggleSearchSources={toggleSearchSources}
          activeSearchSources={activeSearchSources}
          onClose={onClose}
          onExpand={() => setIsExpanded(true)}
          searchText={searchText}
        />
      ) : null}
    </div>
  )
}
