import cx from 'classnames'
import * as React from 'react'
import * as R from 'remeda'

import { Color, TailwindEquityColor } from '../../../../particles/colors'
import { IconType } from '../../../../particles/icons'
import { Button, ButtonProps } from '../../../atoms/button'
import { Icon } from '../../../atoms/icon'

type AttentionBoxType = 'error' | 'success' | 'warning' | 'info'

const borderColorMap: Record<AttentionBoxType, TailwindEquityColor> = {
  error: 'border-error-600',
  success: 'border-success-600',
  warning: 'border-warning-600',
  info: 'border-primary-600',
}

const iconTypeMap: Record<AttentionBoxType, IconType> = {
  error: 'Error',
  success: 'Check',
  warning: 'Warning',
  info: 'Info',
}

const iconColorMap: Record<AttentionBoxType, Color> = {
  error: 'eq-color-error-600',
  success: 'eq-color-success-600',
  warning: 'eq-color-warning-600',
  info: 'eq-color-primary-600',
}

export type Action = Omit<ButtonProps, 'onClick'> & {
  text: string
  handler: () => void
  visible?: boolean
}

export type AttentionBoxProps = {
  type?: AttentionBoxType
  onDismiss?: () => void
  action?: Action | Action[]
  hidden?: boolean
} & (
  | {
      title: string
      children: React.ReactNode
    }
  | {
      title?: undefined
      children: React.ReactNode
    }
  | {
      title: string
      children?: undefined
    }
)

export const AttentionBox = React.forwardRef<HTMLDivElement, AttentionBoxProps>(
  ({ type = 'info', title, onDismiss, children, action, hidden }, ref) => {
    return (
      <div
        ref={ref}
        tabIndex={-1}
        className={cx(
          'shadow-1 bg-neutral-0 flex flex-col py-16 pl-32 pr-16',
          'border-b-0 border-l-4 border-r-0 border-t-0 border-solid',
          borderColorMap[type],
          hidden ? 'hidden' : ''
        )}
      >
        <div>
          <div className="flex w-full justify-between">
            <div className="mb-8 flex gap-x-16 pt-16">
              {title ? (
                <>
                  <AttentionBoxIcon type={type} />
                  <div className="body-strong">{title}</div>
                </>
              ) : null}
            </div>
            {onDismiss ? (
              <div
                className="ml-4 flex cursor-pointer"
                data-testid="attention-box-dismiss-button"
                onClick={onDismiss}
              >
                <Icon type="Close" color="eq-color-neutral-400" />
              </div>
            ) : null}
          </div>

          <div className="flex gap-x-16">
            {!title && children ? <AttentionBoxIcon type={type} /> : null}
            {children}
          </div>
          <div className="flex gap-16 pt-24">
            {R.pipe(
              action,
              (a) => (Array.isArray(a) ? a : [a]),
              (a) => a.filter(R.isDefined),
              (a) =>
                a.filter((x) =>
                  typeof x.visible === 'boolean' ? x.visible : true
                ),
              (a) =>
                a.map(({ visible, handler, ...x }, index) => (
                  <Button
                    key={index}
                    variant="secondary"
                    type="button"
                    data-testid={`attention-box-action-button__${index}`}
                    onClick={handler}
                    {...x}
                  >
                    {x.text}
                  </Button>
                ))
            )}
          </div>
        </div>
      </div>
    )
  }
)

const AttentionBoxIcon = ({ type }: { type: AttentionBoxType }) => (
  <Icon type={iconTypeMap[type]} color={iconColorMap[type]} />
)
