import { useMemo } from 'react'

export function useDerivedSizes(
  width: number,
  height: number,
  spacing: number
) {
  return useMemo(() => {
    return {
      radius: Math.min(width, height) / 2 - spacing,
      center: [width / 2, height / 2] as [number, number],
    }
  }, [width, height, spacing])
}
