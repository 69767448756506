/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Portfolios API
 * API for portfolios management
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'

import { fetchInstance } from '../../../../../../all-shared/legacy-shared/util/fetch/lib/fetch'
import type { ErrorType } from '../../../../../../all-shared/legacy-shared/util/fetch/lib/fetch'

import type {
  ActiveSettingsRequestV1,
  AllocationSummaryRequestV1,
  AllocationSummaryV1,
  AssetClassFundStrategyMappingsV1,
  AvailableSettingsV1,
  CmasRequestV1,
  CmasV1,
  CorrelationMatrixV1,
  CtaStatusV1,
  DeleteCmasByIdHeaders,
  DeleteIndexChoicesByIdHeaders,
  DeleteLimitsByIdHeaders,
  DeletePortfolioHeaders,
  EntitlementsMapV1,
  ErrorResponseV1,
  HistogramV1,
  IndexChoicesRequestV1,
  IndexChoicesV1,
  IndexV1,
  InvestmentRegistrationRequestV1,
  LimitsRequestV1,
  LimitsV1,
  OrderDraftRequestV1,
  OrderDraftV1,
  PagedPortfolioDocumentV1,
  PagedPortfolioListItemsV1,
  PagedSummaryFeedbackListItemsV1,
  PatchPortfolioHeaders,
  PatchRequest,
  PortfolioAllocationV1,
  PortfolioAssetClassCorrelationMatrixRequestV1,
  PortfolioCountV1,
  PortfolioDataPointDatesResponseV1,
  PortfolioDatesRequestV1,
  PortfolioDocumentDownloadLinkV1,
  PortfolioDocumentSearchRequestV1,
  PortfolioDocumentV1,
  PortfolioDrawdownRequestV1,
  PortfolioFacetRequestV1,
  PortfolioFacetsV1,
  PortfolioFilterV1,
  PortfolioGrowthOfRequestV1,
  PortfolioReportRequestV1,
  PortfolioReturnDistributionRequestV1,
  PortfolioReturnsRequestV1,
  PortfolioSeriesStatResponseV1,
  PortfolioShareInformationV1,
  PortfolioSummaryStatsRequestV1,
  PortfolioSummaryStatsResponseV1,
  PortfolioUpdateRequestV1,
  PortfolioV1,
  PortfoliosSearchRequestV1,
  ReferenceAllocationsRequestV1,
  SettingsV1,
  SummaryFeedbackRequestV1,
  SummaryFeedbackSearchRequestV1,
  SummaryFeedbackV1,
  UpdateCmasByIdHeaders,
  UpdateIndexChoicesByIdHeaders,
  UpdateLimitsByIdHeaders,
  UpdatePortfolioHeaders,
  UploadPortfolioDocumentBody,
} from './api.schemas'

/**
 * Retrieve summary statistics for portfolios
 */
export const getSummaryStats = (
  portfolioSummaryStatsRequestV1: PortfolioSummaryStatsRequestV1
) => {
  return fetchInstance<PortfolioSummaryStatsResponseV1[]>({
    url: `/api/portfolios/v1/analytics/summary-stats`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: portfolioSummaryStatsRequestV1,
  })
}

export const getGetSummaryStatsMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getSummaryStats>>,
    TError,
    { data: PortfolioSummaryStatsRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof getSummaryStats>>,
  TError,
  { data: PortfolioSummaryStatsRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getSummaryStats>>,
    { data: PortfolioSummaryStatsRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return getSummaryStats(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type GetSummaryStatsMutationResult = NonNullable<
  Awaited<ReturnType<typeof getSummaryStats>>
>
export type GetSummaryStatsMutationBody = PortfolioSummaryStatsRequestV1
export type GetSummaryStatsMutationError = ErrorType<ErrorResponseV1>

export const useGetSummaryStats = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getSummaryStats>>,
    TError,
    { data: PortfolioSummaryStatsRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getGetSummaryStatsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve series of growth of a principal amount
 */
export const getGrowthOf = (
  portfolioGrowthOfRequestV1: PortfolioGrowthOfRequestV1
) => {
  return fetchInstance<PortfolioSeriesStatResponseV1[]>({
    url: `/api/portfolios/v1/analytics/growth-of`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: portfolioGrowthOfRequestV1,
  })
}

export const getGetGrowthOfMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getGrowthOf>>,
    TError,
    { data: PortfolioGrowthOfRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof getGrowthOf>>,
  TError,
  { data: PortfolioGrowthOfRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getGrowthOf>>,
    { data: PortfolioGrowthOfRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return getGrowthOf(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type GetGrowthOfMutationResult = NonNullable<
  Awaited<ReturnType<typeof getGrowthOf>>
>
export type GetGrowthOfMutationBody = PortfolioGrowthOfRequestV1
export type GetGrowthOfMutationError = ErrorType<ErrorResponseV1>

export const useGetGrowthOf = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getGrowthOf>>,
    TError,
    { data: PortfolioGrowthOfRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getGetGrowthOfMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve series of returns for portfolios
 */
export const getReturns = (
  portfolioReturnsRequestV1: PortfolioReturnsRequestV1
) => {
  return fetchInstance<PortfolioSeriesStatResponseV1[]>({
    url: `/api/portfolios/v1/analytics/returns`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: portfolioReturnsRequestV1,
  })
}

export const getGetReturnsMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getReturns>>,
    TError,
    { data: PortfolioReturnsRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof getReturns>>,
  TError,
  { data: PortfolioReturnsRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getReturns>>,
    { data: PortfolioReturnsRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return getReturns(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type GetReturnsMutationResult = NonNullable<
  Awaited<ReturnType<typeof getReturns>>
>
export type GetReturnsMutationBody = PortfolioReturnsRequestV1
export type GetReturnsMutationError = ErrorType<ErrorResponseV1>

export const useGetReturns = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getReturns>>,
    TError,
    { data: PortfolioReturnsRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getGetReturnsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve series of drawdown for portfolios
 */
export const getDrawdown = (
  portfolioDrawdownRequestV1: PortfolioDrawdownRequestV1
) => {
  return fetchInstance<PortfolioSeriesStatResponseV1[]>({
    url: `/api/portfolios/v1/analytics/drawdown`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: portfolioDrawdownRequestV1,
  })
}

export const getGetDrawdownMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getDrawdown>>,
    TError,
    { data: PortfolioDrawdownRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof getDrawdown>>,
  TError,
  { data: PortfolioDrawdownRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getDrawdown>>,
    { data: PortfolioDrawdownRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return getDrawdown(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type GetDrawdownMutationResult = NonNullable<
  Awaited<ReturnType<typeof getDrawdown>>
>
export type GetDrawdownMutationBody = PortfolioDrawdownRequestV1
export type GetDrawdownMutationError = ErrorType<ErrorResponseV1>

export const useGetDrawdown = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getDrawdown>>,
    TError,
    { data: PortfolioDrawdownRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getGetDrawdownMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve the start and end data point dates for portfolios
 */
export const getDates = (portfolioDatesRequestV1: PortfolioDatesRequestV1) => {
  return fetchInstance<PortfolioDataPointDatesResponseV1>({
    url: `/api/portfolios/v1/analytics/dates`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: portfolioDatesRequestV1,
  })
}

export const getGetDatesMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getDates>>,
    TError,
    { data: PortfolioDatesRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof getDates>>,
  TError,
  { data: PortfolioDatesRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getDates>>,
    { data: PortfolioDatesRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return getDates(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type GetDatesMutationResult = NonNullable<
  Awaited<ReturnType<typeof getDates>>
>
export type GetDatesMutationBody = PortfolioDatesRequestV1
export type GetDatesMutationError = ErrorType<ErrorResponseV1>

export const useGetDates = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getDates>>,
    TError,
    { data: PortfolioDatesRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getGetDatesMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve asset class correlation matrix for portfolios
 */
export const getAssetClassCorrelationMatrix = (
  portfolioAssetClassCorrelationMatrixRequestV1: PortfolioAssetClassCorrelationMatrixRequestV1
) => {
  return fetchInstance<CorrelationMatrixV1>({
    url: `/api/portfolios/v1/analytics/correlation-matrix/asset-class`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: portfolioAssetClassCorrelationMatrixRequestV1,
  })
}

export const getGetAssetClassCorrelationMatrixMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getAssetClassCorrelationMatrix>>,
    TError,
    { data: PortfolioAssetClassCorrelationMatrixRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof getAssetClassCorrelationMatrix>>,
  TError,
  { data: PortfolioAssetClassCorrelationMatrixRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getAssetClassCorrelationMatrix>>,
    { data: PortfolioAssetClassCorrelationMatrixRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return getAssetClassCorrelationMatrix(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type GetAssetClassCorrelationMatrixMutationResult = NonNullable<
  Awaited<ReturnType<typeof getAssetClassCorrelationMatrix>>
>
export type GetAssetClassCorrelationMatrixMutationBody =
  PortfolioAssetClassCorrelationMatrixRequestV1
export type GetAssetClassCorrelationMatrixMutationError =
  ErrorType<ErrorResponseV1>

export const useGetAssetClassCorrelationMatrix = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getAssetClassCorrelationMatrix>>,
    TError,
    { data: PortfolioAssetClassCorrelationMatrixRequestV1 },
    TContext
  >
}) => {
  const mutationOptions =
    getGetAssetClassCorrelationMatrixMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve return distribution for portfolios
 */
export const getReturnDistribution = (
  portfolioReturnDistributionRequestV1: PortfolioReturnDistributionRequestV1
) => {
  return fetchInstance<HistogramV1[]>({
    url: `/api/portfolios/v1/analytics/return-distribution`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: portfolioReturnDistributionRequestV1,
  })
}

export const getGetReturnDistributionMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getReturnDistribution>>,
    TError,
    { data: PortfolioReturnDistributionRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof getReturnDistribution>>,
  TError,
  { data: PortfolioReturnDistributionRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getReturnDistribution>>,
    { data: PortfolioReturnDistributionRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return getReturnDistribution(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type GetReturnDistributionMutationResult = NonNullable<
  Awaited<ReturnType<typeof getReturnDistribution>>
>
export type GetReturnDistributionMutationBody =
  PortfolioReturnDistributionRequestV1
export type GetReturnDistributionMutationError = ErrorType<ErrorResponseV1>

export const useGetReturnDistribution = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getReturnDistribution>>,
    TError,
    { data: PortfolioReturnDistributionRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getGetReturnDistributionMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve all indices
 */
export const getIndices = (signal?: AbortSignal) => {
  return fetchInstance<IndexV1[]>({
    url: `/api/portfolios/v1/indices`,
    method: 'GET',
    signal,
  })
}

export const getGetIndicesQueryKey = () => {
  return [`/api/portfolios/v1/indices`] as const
}

export const getGetIndicesQueryOptions = <
  TData = Awaited<ReturnType<typeof getIndices>>,
  TError = ErrorType<ErrorResponseV1>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getIndices>>, TError, TData>
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetIndicesQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getIndices>>> = ({
    signal,
  }) => getIndices(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getIndices>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetIndicesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIndices>>
>
export type GetIndicesQueryError = ErrorType<ErrorResponseV1>

export const useGetIndices = <
  TData = Awaited<ReturnType<typeof getIndices>>,
  TError = ErrorType<ErrorResponseV1>
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getIndices>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetIndicesQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Create a new portfolio
 */
export const createPortfolio = (
  portfolioUpdateRequestV1: PortfolioUpdateRequestV1
) => {
  return fetchInstance<PortfolioV1>({
    url: `/api/portfolios/v1/portfolios/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: portfolioUpdateRequestV1,
  })
}

export const getCreatePortfolioMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPortfolio>>,
    TError,
    { data: PortfolioUpdateRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPortfolio>>,
  TError,
  { data: PortfolioUpdateRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPortfolio>>,
    { data: PortfolioUpdateRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return createPortfolio(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreatePortfolioMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPortfolio>>
>
export type CreatePortfolioMutationBody = PortfolioUpdateRequestV1
export type CreatePortfolioMutationError = ErrorType<ErrorResponseV1>

export const useCreatePortfolio = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPortfolio>>,
    TError,
    { data: PortfolioUpdateRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreatePortfolioMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve the latest version of a portfolio by its ID
 */
export const getPortfolio = (portfolioId: string, signal?: AbortSignal) => {
  return fetchInstance<PortfolioV1>({
    url: `/api/portfolios/v1/portfolios/${portfolioId}`,
    method: 'GET',
    signal,
  })
}

export const getGetPortfolioQueryKey = (portfolioId: string) => {
  return [`/api/portfolios/v1/portfolios/${portfolioId}`] as const
}

export const getGetPortfolioQueryOptions = <
  TData = Awaited<ReturnType<typeof getPortfolio>>,
  TError = ErrorType<ErrorResponseV1>
>(
  portfolioId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPortfolio>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetPortfolioQueryKey(portfolioId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPortfolio>>> = ({
    signal,
  }) => getPortfolio(portfolioId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!portfolioId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPortfolio>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPortfolioQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPortfolio>>
>
export type GetPortfolioQueryError = ErrorType<ErrorResponseV1>

export const useGetPortfolio = <
  TData = Awaited<ReturnType<typeof getPortfolio>>,
  TError = ErrorType<ErrorResponseV1>
>(
  portfolioId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPortfolio>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPortfolioQueryOptions(portfolioId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Update a portfolio by its ID
 */
export const updatePortfolio = (
  portfolioId: string,
  portfolioUpdateRequestV1: PortfolioUpdateRequestV1,
  headers?: UpdatePortfolioHeaders
) => {
  return fetchInstance<PortfolioV1>({
    url: `/api/portfolios/v1/portfolios/${portfolioId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: portfolioUpdateRequestV1,
  })
}

export const getUpdatePortfolioMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePortfolio>>,
    TError,
    {
      portfolioId: string
      data: PortfolioUpdateRequestV1
      headers?: UpdatePortfolioHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePortfolio>>,
  TError,
  {
    portfolioId: string
    data: PortfolioUpdateRequestV1
    headers?: UpdatePortfolioHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePortfolio>>,
    {
      portfolioId: string
      data: PortfolioUpdateRequestV1
      headers?: UpdatePortfolioHeaders
    }
  > = (props) => {
    const { portfolioId, data, headers } = props ?? {}

    return updatePortfolio(portfolioId, data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdatePortfolioMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePortfolio>>
>
export type UpdatePortfolioMutationBody = PortfolioUpdateRequestV1
export type UpdatePortfolioMutationError = ErrorType<ErrorResponseV1>

export const useUpdatePortfolio = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePortfolio>>,
    TError,
    {
      portfolioId: string
      data: PortfolioUpdateRequestV1
      headers?: UpdatePortfolioHeaders
    },
    TContext
  >
}) => {
  const mutationOptions = getUpdatePortfolioMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Delete a portfolio and all its versions by its ID
 */
export const deletePortfolio = (
  portfolioId: string,
  headers?: DeletePortfolioHeaders
) => {
  return fetchInstance<void>({
    url: `/api/portfolios/v1/portfolios/${portfolioId}`,
    method: 'DELETE',
    headers,
  })
}

export const getDeletePortfolioMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePortfolio>>,
    TError,
    { portfolioId: string; headers?: DeletePortfolioHeaders },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePortfolio>>,
  TError,
  { portfolioId: string; headers?: DeletePortfolioHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePortfolio>>,
    { portfolioId: string; headers?: DeletePortfolioHeaders }
  > = (props) => {
    const { portfolioId, headers } = props ?? {}

    return deletePortfolio(portfolioId, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeletePortfolioMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePortfolio>>
>

export type DeletePortfolioMutationError = ErrorType<ErrorResponseV1>

export const useDeletePortfolio = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePortfolio>>,
    TError,
    { portfolioId: string; headers?: DeletePortfolioHeaders },
    TContext
  >
}) => {
  const mutationOptions = getDeletePortfolioMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Patch a portfolio by its ID
 */
export const patchPortfolio = (
  portfolioId: string,
  patchRequest: PatchRequest,
  headers?: PatchPortfolioHeaders
) => {
  return fetchInstance<PortfolioV1>({
    url: `/api/portfolios/v1/portfolios/${portfolioId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: patchRequest,
  })
}

export const getPatchPortfolioMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchPortfolio>>,
    TError,
    {
      portfolioId: string
      data: PatchRequest
      headers?: PatchPortfolioHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchPortfolio>>,
  TError,
  { portfolioId: string; data: PatchRequest; headers?: PatchPortfolioHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchPortfolio>>,
    { portfolioId: string; data: PatchRequest; headers?: PatchPortfolioHeaders }
  > = (props) => {
    const { portfolioId, data, headers } = props ?? {}

    return patchPortfolio(portfolioId, data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchPortfolioMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchPortfolio>>
>
export type PatchPortfolioMutationBody = PatchRequest
export type PatchPortfolioMutationError = ErrorType<ErrorResponseV1>

export const usePatchPortfolio = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchPortfolio>>,
    TError,
    {
      portfolioId: string
      data: PatchRequest
      headers?: PatchPortfolioHeaders
    },
    TContext
  >
}) => {
  const mutationOptions = getPatchPortfolioMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve a specific version of a portfolio by the portfolio ID and the version number
 */
export const getPortfolioVersion = (
  portfolioId: string,
  version: number,
  signal?: AbortSignal
) => {
  return fetchInstance<PortfolioV1>({
    url: `/api/portfolios/v1/portfolios/${portfolioId}/versions/${version}`,
    method: 'GET',
    signal,
  })
}

export const getGetPortfolioVersionQueryKey = (
  portfolioId: string,
  version: number
) => {
  return [
    `/api/portfolios/v1/portfolios/${portfolioId}/versions/${version}`,
  ] as const
}

export const getGetPortfolioVersionQueryOptions = <
  TData = Awaited<ReturnType<typeof getPortfolioVersion>>,
  TError = ErrorType<ErrorResponseV1>
>(
  portfolioId: string,
  version: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPortfolioVersion>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPortfolioVersionQueryKey(portfolioId, version)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPortfolioVersion>>
  > = ({ signal }) => getPortfolioVersion(portfolioId, version, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(portfolioId && version),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPortfolioVersion>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPortfolioVersionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPortfolioVersion>>
>
export type GetPortfolioVersionQueryError = ErrorType<ErrorResponseV1>

export const useGetPortfolioVersion = <
  TData = Awaited<ReturnType<typeof getPortfolioVersion>>,
  TError = ErrorType<ErrorResponseV1>
>(
  portfolioId: string,
  version: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPortfolioVersion>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPortfolioVersionQueryOptions(
    portfolioId,
    version,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Search portfolios
 */
export const searchPortfolios = (
  portfoliosSearchRequestV1: PortfoliosSearchRequestV1
) => {
  return fetchInstance<PagedPortfolioListItemsV1>({
    url: `/api/portfolios/v1/portfolios/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: portfoliosSearchRequestV1,
  })
}

export const getSearchPortfoliosMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchPortfolios>>,
    TError,
    { data: PortfoliosSearchRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchPortfolios>>,
  TError,
  { data: PortfoliosSearchRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchPortfolios>>,
    { data: PortfoliosSearchRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return searchPortfolios(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchPortfoliosMutationResult = NonNullable<
  Awaited<ReturnType<typeof searchPortfolios>>
>
export type SearchPortfoliosMutationBody = PortfoliosSearchRequestV1
export type SearchPortfoliosMutationError = ErrorType<ErrorResponseV1>

export const useSearchPortfolios = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchPortfolios>>,
    TError,
    { data: PortfoliosSearchRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getSearchPortfoliosMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Count portfolios
 */
export const countPortfolios = (portfolioFilterV1: PortfolioFilterV1) => {
  return fetchInstance<PortfolioCountV1>({
    url: `/api/portfolios/v1/portfolios/count`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: portfolioFilterV1,
  })
}

export const getCountPortfoliosMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof countPortfolios>>,
    TError,
    { data: PortfolioFilterV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof countPortfolios>>,
  TError,
  { data: PortfolioFilterV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof countPortfolios>>,
    { data: PortfolioFilterV1 }
  > = (props) => {
    const { data } = props ?? {}

    return countPortfolios(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CountPortfoliosMutationResult = NonNullable<
  Awaited<ReturnType<typeof countPortfolios>>
>
export type CountPortfoliosMutationBody = PortfolioFilterV1
export type CountPortfoliosMutationError = ErrorType<ErrorResponseV1>

export const useCountPortfolios = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof countPortfolios>>,
    TError,
    { data: PortfolioFilterV1 },
    TContext
  >
}) => {
  const mutationOptions = getCountPortfoliosMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Get portfolio facets
 */
export const getPortfolioSearchFacets = (
  portfolioFacetRequestV1: PortfolioFacetRequestV1
) => {
  return fetchInstance<PortfolioFacetsV1>({
    url: `/api/portfolios/v1/portfolios/search/facets`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: portfolioFacetRequestV1,
  })
}

export const getGetPortfolioSearchFacetsMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getPortfolioSearchFacets>>,
    TError,
    { data: PortfolioFacetRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof getPortfolioSearchFacets>>,
  TError,
  { data: PortfolioFacetRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getPortfolioSearchFacets>>,
    { data: PortfolioFacetRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return getPortfolioSearchFacets(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type GetPortfolioSearchFacetsMutationResult = NonNullable<
  Awaited<ReturnType<typeof getPortfolioSearchFacets>>
>
export type GetPortfolioSearchFacetsMutationBody = PortfolioFacetRequestV1
export type GetPortfolioSearchFacetsMutationError = ErrorType<ErrorResponseV1>

export const useGetPortfolioSearchFacets = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getPortfolioSearchFacets>>,
    TError,
    { data: PortfolioFacetRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getGetPortfolioSearchFacetsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Upload a document to a portfolio
 */
export const uploadPortfolioDocument = (
  portfolioId: string,
  uploadPortfolioDocumentBody: UploadPortfolioDocumentBody
) => {
  const formData = new FormData()
  formData.append(
    'request',
    JSON.stringify(uploadPortfolioDocumentBody.request)
  )
  formData.append('file', uploadPortfolioDocumentBody.file)

  return fetchInstance<PortfolioDocumentV1>({
    url: `/api/portfolios/v1/portfolios/${portfolioId}/documents`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  })
}

export const getUploadPortfolioDocumentMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadPortfolioDocument>>,
    TError,
    { portfolioId: string; data: UploadPortfolioDocumentBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadPortfolioDocument>>,
  TError,
  { portfolioId: string; data: UploadPortfolioDocumentBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadPortfolioDocument>>,
    { portfolioId: string; data: UploadPortfolioDocumentBody }
  > = (props) => {
    const { portfolioId, data } = props ?? {}

    return uploadPortfolioDocument(portfolioId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UploadPortfolioDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadPortfolioDocument>>
>
export type UploadPortfolioDocumentMutationBody = UploadPortfolioDocumentBody
export type UploadPortfolioDocumentMutationError = ErrorType<ErrorResponseV1>

export const useUploadPortfolioDocument = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadPortfolioDocument>>,
    TError,
    { portfolioId: string; data: UploadPortfolioDocumentBody },
    TContext
  >
}) => {
  const mutationOptions = getUploadPortfolioDocumentMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Delete a document from a portfolio
 */
export const deletePortfolioDocument = (
  portfolioId: string,
  documentId: string
) => {
  return fetchInstance<PortfolioDocumentV1>({
    url: `/api/portfolios/v1/portfolios/${portfolioId}/documents/${documentId}`,
    method: 'DELETE',
  })
}

export const getDeletePortfolioDocumentMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePortfolioDocument>>,
    TError,
    { portfolioId: string; documentId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePortfolioDocument>>,
  TError,
  { portfolioId: string; documentId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePortfolioDocument>>,
    { portfolioId: string; documentId: string }
  > = (props) => {
    const { portfolioId, documentId } = props ?? {}

    return deletePortfolioDocument(portfolioId, documentId)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeletePortfolioDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePortfolioDocument>>
>

export type DeletePortfolioDocumentMutationError = ErrorType<ErrorResponseV1>

export const useDeletePortfolioDocument = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePortfolioDocument>>,
    TError,
    { portfolioId: string; documentId: string },
    TContext
  >
}) => {
  const mutationOptions = getDeletePortfolioDocumentMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Search documents belonging to a portfolio
 */
export const searchPortfolioDocuments = (
  portfolioId: string,
  portfolioDocumentSearchRequestV1: PortfolioDocumentSearchRequestV1
) => {
  return fetchInstance<PagedPortfolioDocumentV1>({
    url: `/api/portfolios/v1/portfolios/${portfolioId}/documents/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: portfolioDocumentSearchRequestV1,
  })
}

export const getSearchPortfolioDocumentsMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchPortfolioDocuments>>,
    TError,
    { portfolioId: string; data: PortfolioDocumentSearchRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchPortfolioDocuments>>,
  TError,
  { portfolioId: string; data: PortfolioDocumentSearchRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchPortfolioDocuments>>,
    { portfolioId: string; data: PortfolioDocumentSearchRequestV1 }
  > = (props) => {
    const { portfolioId, data } = props ?? {}

    return searchPortfolioDocuments(portfolioId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchPortfolioDocumentsMutationResult = NonNullable<
  Awaited<ReturnType<typeof searchPortfolioDocuments>>
>
export type SearchPortfolioDocumentsMutationBody =
  PortfolioDocumentSearchRequestV1
export type SearchPortfolioDocumentsMutationError = ErrorType<ErrorResponseV1>

export const useSearchPortfolioDocuments = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchPortfolioDocuments>>,
    TError,
    { portfolioId: string; data: PortfolioDocumentSearchRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getSearchPortfolioDocumentsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve a download link for a specific portfolio document
 */
export const getPortfolioDocumentDownloadLink = (
  portfolioId: string,
  documentId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<PortfolioDocumentDownloadLinkV1>({
    url: `/api/portfolios/v1/portfolios/${portfolioId}/documents/${documentId}/downloadLink`,
    method: 'GET',
    signal,
  })
}

export const getGetPortfolioDocumentDownloadLinkQueryKey = (
  portfolioId: string,
  documentId: string
) => {
  return [
    `/api/portfolios/v1/portfolios/${portfolioId}/documents/${documentId}/downloadLink`,
  ] as const
}

export const getGetPortfolioDocumentDownloadLinkQueryOptions = <
  TData = Awaited<ReturnType<typeof getPortfolioDocumentDownloadLink>>,
  TError = ErrorType<ErrorResponseV1>
>(
  portfolioId: string,
  documentId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPortfolioDocumentDownloadLink>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPortfolioDocumentDownloadLinkQueryKey(portfolioId, documentId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPortfolioDocumentDownloadLink>>
  > = ({ signal }) =>
    getPortfolioDocumentDownloadLink(portfolioId, documentId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(portfolioId && documentId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPortfolioDocumentDownloadLink>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPortfolioDocumentDownloadLinkQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPortfolioDocumentDownloadLink>>
>
export type GetPortfolioDocumentDownloadLinkQueryError =
  ErrorType<ErrorResponseV1>

export const useGetPortfolioDocumentDownloadLink = <
  TData = Awaited<ReturnType<typeof getPortfolioDocumentDownloadLink>>,
  TError = ErrorType<ErrorResponseV1>
>(
  portfolioId: string,
  documentId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPortfolioDocumentDownloadLink>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPortfolioDocumentDownloadLinkQueryOptions(
    portfolioId,
    documentId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Register that a user has invested in a particular portfolio
 */
export const registerInvestment = (
  portfolioId: string,
  investmentRegistrationRequestV1: InvestmentRegistrationRequestV1
) => {
  return fetchInstance<void>({
    url: `/api/portfolios/v1/portfolios/${portfolioId}/investments`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: investmentRegistrationRequestV1,
  })
}

export const getRegisterInvestmentMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerInvestment>>,
    TError,
    { portfolioId: string; data: InvestmentRegistrationRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof registerInvestment>>,
  TError,
  { portfolioId: string; data: InvestmentRegistrationRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registerInvestment>>,
    { portfolioId: string; data: InvestmentRegistrationRequestV1 }
  > = (props) => {
    const { portfolioId, data } = props ?? {}

    return registerInvestment(portfolioId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type RegisterInvestmentMutationResult = NonNullable<
  Awaited<ReturnType<typeof registerInvestment>>
>
export type RegisterInvestmentMutationBody = InvestmentRegistrationRequestV1
export type RegisterInvestmentMutationError = ErrorType<ErrorResponseV1>

export const useRegisterInvestment = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerInvestment>>,
    TError,
    { portfolioId: string; data: InvestmentRegistrationRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getRegisterInvestmentMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve sharing information for the portfolio
 */
export const getSharingInformation = (
  portfolioId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<PortfolioShareInformationV1>({
    url: `/api/portfolios/v1/portfolio/${portfolioId}/share`,
    method: 'GET',
    signal,
  })
}

export const getGetSharingInformationQueryKey = (portfolioId: string) => {
  return [`/api/portfolios/v1/portfolio/${portfolioId}/share`] as const
}

export const getGetSharingInformationQueryOptions = <
  TData = Awaited<ReturnType<typeof getSharingInformation>>,
  TError = ErrorType<ErrorResponseV1>
>(
  portfolioId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSharingInformation>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetSharingInformationQueryKey(portfolioId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSharingInformation>>
  > = ({ signal }) => getSharingInformation(portfolioId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!portfolioId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getSharingInformation>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetSharingInformationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSharingInformation>>
>
export type GetSharingInformationQueryError = ErrorType<ErrorResponseV1>

export const useGetSharingInformation = <
  TData = Awaited<ReturnType<typeof getSharingInformation>>,
  TError = ErrorType<ErrorResponseV1>
>(
  portfolioId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSharingInformation>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSharingInformationQueryOptions(
    portfolioId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Create an order draft for a portfolio
 */
export const createOrderDraft = (
  portfolioId: string,
  orderDraftRequestV1: OrderDraftRequestV1
) => {
  return fetchInstance<OrderDraftV1>({
    url: `/api/portfolios/v1/portfolio/${portfolioId}/orderDrafts`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: orderDraftRequestV1,
  })
}

export const getCreateOrderDraftMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOrderDraft>>,
    TError,
    { portfolioId: string; data: OrderDraftRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createOrderDraft>>,
  TError,
  { portfolioId: string; data: OrderDraftRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createOrderDraft>>,
    { portfolioId: string; data: OrderDraftRequestV1 }
  > = (props) => {
    const { portfolioId, data } = props ?? {}

    return createOrderDraft(portfolioId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateOrderDraftMutationResult = NonNullable<
  Awaited<ReturnType<typeof createOrderDraft>>
>
export type CreateOrderDraftMutationBody = OrderDraftRequestV1
export type CreateOrderDraftMutationError = ErrorType<ErrorResponseV1>

export const useCreateOrderDraft = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOrderDraft>>,
    TError,
    { portfolioId: string; data: OrderDraftRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreateOrderDraftMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve an order draft for a portfolio
 */
export const getOrderDraft = (
  portfolioId: string,
  orderDraftId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<OrderDraftV1>({
    url: `/api/portfolios/v1/portfolio/${portfolioId}/orderDrafts/${orderDraftId}`,
    method: 'GET',
    signal,
  })
}

export const getGetOrderDraftQueryKey = (
  portfolioId: string,
  orderDraftId: string
) => {
  return [
    `/api/portfolios/v1/portfolio/${portfolioId}/orderDrafts/${orderDraftId}`,
  ] as const
}

export const getGetOrderDraftQueryOptions = <
  TData = Awaited<ReturnType<typeof getOrderDraft>>,
  TError = ErrorType<ErrorResponseV1>
>(
  portfolioId: string,
  orderDraftId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrderDraft>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetOrderDraftQueryKey(portfolioId, orderDraftId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrderDraft>>> = ({
    signal,
  }) => getOrderDraft(portfolioId, orderDraftId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(portfolioId && orderDraftId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getOrderDraft>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetOrderDraftQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOrderDraft>>
>
export type GetOrderDraftQueryError = ErrorType<ErrorResponseV1>

export const useGetOrderDraft = <
  TData = Awaited<ReturnType<typeof getOrderDraft>>,
  TError = ErrorType<ErrorResponseV1>
>(
  portfolioId: string,
  orderDraftId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getOrderDraft>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetOrderDraftQueryOptions(
    portfolioId,
    orderDraftId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Get reference allocations for a list of criteria
 */
export const getReferenceAllocations = (
  referenceAllocationsRequestV1: ReferenceAllocationsRequestV1
) => {
  return fetchInstance<PortfolioAllocationV1[]>({
    url: `/api/portfolios/v1/reference-allocations`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: referenceAllocationsRequestV1,
  })
}

export const getGetReferenceAllocationsMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getReferenceAllocations>>,
    TError,
    { data: ReferenceAllocationsRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof getReferenceAllocations>>,
  TError,
  { data: ReferenceAllocationsRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getReferenceAllocations>>,
    { data: ReferenceAllocationsRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return getReferenceAllocations(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type GetReferenceAllocationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof getReferenceAllocations>>
>
export type GetReferenceAllocationsMutationBody = ReferenceAllocationsRequestV1
export type GetReferenceAllocationsMutationError = ErrorType<ErrorResponseV1>

export const useGetReferenceAllocations = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getReferenceAllocations>>,
    TError,
    { data: ReferenceAllocationsRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getGetReferenceAllocationsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve a report for a portfolio by its ID
 */
export const getPortfolioReport = (
  portfolioReportRequestV1: PortfolioReportRequestV1
) => {
  return fetchInstance<Blob>({
    url: `/api/portfolios/v1/reports/portfolio-report`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: portfolioReportRequestV1,
    responseType: 'blob',
  })
}

export const getGetPortfolioReportMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getPortfolioReport>>,
    TError,
    { data: PortfolioReportRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof getPortfolioReport>>,
  TError,
  { data: PortfolioReportRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getPortfolioReport>>,
    { data: PortfolioReportRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return getPortfolioReport(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type GetPortfolioReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof getPortfolioReport>>
>
export type GetPortfolioReportMutationBody = PortfolioReportRequestV1
export type GetPortfolioReportMutationError = ErrorType<ErrorResponseV1>

export const useGetPortfolioReport = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getPortfolioReport>>,
    TError,
    { data: PortfolioReportRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getGetPortfolioReportMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Get firm-specific settings
 */
export const getFirmSpecificSettings = (
  firmId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<EntitlementsMapV1>({
    url: `/api/portfolios/v1/settings/firms/${firmId}`,
    method: 'GET',
    signal,
  })
}

export const getGetFirmSpecificSettingsQueryKey = (firmId: string) => {
  return [`/api/portfolios/v1/settings/firms/${firmId}`] as const
}

export const getGetFirmSpecificSettingsQueryOptions = <
  TData = Awaited<ReturnType<typeof getFirmSpecificSettings>>,
  TError = ErrorType<ErrorResponseV1>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmSpecificSettings>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetFirmSpecificSettingsQueryKey(firmId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFirmSpecificSettings>>
  > = ({ signal }) => getFirmSpecificSettings(firmId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!firmId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFirmSpecificSettings>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFirmSpecificSettingsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFirmSpecificSettings>>
>
export type GetFirmSpecificSettingsQueryError = ErrorType<ErrorResponseV1>

export const useGetFirmSpecificSettings = <
  TData = Awaited<ReturnType<typeof getFirmSpecificSettings>>,
  TError = ErrorType<ErrorResponseV1>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmSpecificSettings>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFirmSpecificSettingsQueryOptions(firmId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Update firm-specific settings
 */
export const updateFirmSpecificSettings = (
  firmId: string,
  entitlementsMapV1: EntitlementsMapV1
) => {
  return fetchInstance<EntitlementsMapV1>({
    url: `/api/portfolios/v1/settings/firms/${firmId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: entitlementsMapV1,
  })
}

export const getUpdateFirmSpecificSettingsMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFirmSpecificSettings>>,
    TError,
    { firmId: string; data: EntitlementsMapV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateFirmSpecificSettings>>,
  TError,
  { firmId: string; data: EntitlementsMapV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateFirmSpecificSettings>>,
    { firmId: string; data: EntitlementsMapV1 }
  > = (props) => {
    const { firmId, data } = props ?? {}

    return updateFirmSpecificSettings(firmId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateFirmSpecificSettingsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateFirmSpecificSettings>>
>
export type UpdateFirmSpecificSettingsMutationBody = EntitlementsMapV1
export type UpdateFirmSpecificSettingsMutationError = ErrorType<ErrorResponseV1>

export const useUpdateFirmSpecificSettings = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFirmSpecificSettings>>,
    TError,
    { firmId: string; data: EntitlementsMapV1 },
    TContext
  >
}) => {
  const mutationOptions = getUpdateFirmSpecificSettingsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve cmas by ID
 */
export const getCmasById = (cmasId: string, signal?: AbortSignal) => {
  return fetchInstance<CmasV1>({
    url: `/api/portfolios/v1/settings/cmas/${cmasId}`,
    method: 'GET',
    signal,
  })
}

export const getGetCmasByIdQueryKey = (cmasId: string) => {
  return [`/api/portfolios/v1/settings/cmas/${cmasId}`] as const
}

export const getGetCmasByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCmasById>>,
  TError = ErrorType<ErrorResponseV1>
>(
  cmasId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCmasById>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCmasByIdQueryKey(cmasId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCmasById>>> = ({
    signal,
  }) => getCmasById(cmasId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!cmasId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCmasById>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetCmasByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCmasById>>
>
export type GetCmasByIdQueryError = ErrorType<ErrorResponseV1>

export const useGetCmasById = <
  TData = Awaited<ReturnType<typeof getCmasById>>,
  TError = ErrorType<ErrorResponseV1>
>(
  cmasId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCmasById>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCmasByIdQueryOptions(cmasId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Update cmas by ID
 */
export const updateCmasById = (
  cmasId: string,
  cmasRequestV1: CmasRequestV1,
  headers?: UpdateCmasByIdHeaders
) => {
  return fetchInstance<CmasV1>({
    url: `/api/portfolios/v1/settings/cmas/${cmasId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: cmasRequestV1,
  })
}

export const getUpdateCmasByIdMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCmasById>>,
    TError,
    { cmasId: string; data: CmasRequestV1; headers?: UpdateCmasByIdHeaders },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateCmasById>>,
  TError,
  { cmasId: string; data: CmasRequestV1; headers?: UpdateCmasByIdHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateCmasById>>,
    { cmasId: string; data: CmasRequestV1; headers?: UpdateCmasByIdHeaders }
  > = (props) => {
    const { cmasId, data, headers } = props ?? {}

    return updateCmasById(cmasId, data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateCmasByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCmasById>>
>
export type UpdateCmasByIdMutationBody = CmasRequestV1
export type UpdateCmasByIdMutationError = ErrorType<ErrorResponseV1>

export const useUpdateCmasById = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateCmasById>>,
    TError,
    { cmasId: string; data: CmasRequestV1; headers?: UpdateCmasByIdHeaders },
    TContext
  >
}) => {
  const mutationOptions = getUpdateCmasByIdMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Delete cmas by ID
 */
export const deleteCmasById = (
  cmasId: string,
  headers?: DeleteCmasByIdHeaders
) => {
  return fetchInstance<void>({
    url: `/api/portfolios/v1/settings/cmas/${cmasId}`,
    method: 'DELETE',
    headers,
  })
}

export const getDeleteCmasByIdMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCmasById>>,
    TError,
    { cmasId: string; headers?: DeleteCmasByIdHeaders },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteCmasById>>,
  TError,
  { cmasId: string; headers?: DeleteCmasByIdHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteCmasById>>,
    { cmasId: string; headers?: DeleteCmasByIdHeaders }
  > = (props) => {
    const { cmasId, headers } = props ?? {}

    return deleteCmasById(cmasId, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteCmasByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCmasById>>
>

export type DeleteCmasByIdMutationError = ErrorType<ErrorResponseV1>

export const useDeleteCmasById = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteCmasById>>,
    TError,
    { cmasId: string; headers?: DeleteCmasByIdHeaders },
    TContext
  >
}) => {
  const mutationOptions = getDeleteCmasByIdMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve custom cmas for a user by ID
 */
export const getCmasByUserId = (userId: string, signal?: AbortSignal) => {
  return fetchInstance<CmasV1[]>({
    url: `/api/portfolios/v1/settings/cmas/users/${userId}`,
    method: 'GET',
    signal,
  })
}

export const getGetCmasByUserIdQueryKey = (userId: string) => {
  return [`/api/portfolios/v1/settings/cmas/users/${userId}`] as const
}

export const getGetCmasByUserIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCmasByUserId>>,
  TError = ErrorType<ErrorResponseV1>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCmasByUserId>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCmasByUserIdQueryKey(userId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCmasByUserId>>> = ({
    signal,
  }) => getCmasByUserId(userId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCmasByUserId>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetCmasByUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCmasByUserId>>
>
export type GetCmasByUserIdQueryError = ErrorType<ErrorResponseV1>

export const useGetCmasByUserId = <
  TData = Awaited<ReturnType<typeof getCmasByUserId>>,
  TError = ErrorType<ErrorResponseV1>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCmasByUserId>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCmasByUserIdQueryOptions(userId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Create user level cmas
 */
export const createCmasForUser = (
  userId: string,
  cmasRequestV1: CmasRequestV1
) => {
  return fetchInstance<CmasV1>({
    url: `/api/portfolios/v1/settings/cmas/users/${userId}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: cmasRequestV1,
  })
}

export const getCreateCmasForUserMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCmasForUser>>,
    TError,
    { userId: string; data: CmasRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCmasForUser>>,
  TError,
  { userId: string; data: CmasRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCmasForUser>>,
    { userId: string; data: CmasRequestV1 }
  > = (props) => {
    const { userId, data } = props ?? {}

    return createCmasForUser(userId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateCmasForUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCmasForUser>>
>
export type CreateCmasForUserMutationBody = CmasRequestV1
export type CreateCmasForUserMutationError = ErrorType<ErrorResponseV1>

export const useCreateCmasForUser = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCmasForUser>>,
    TError,
    { userId: string; data: CmasRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreateCmasForUserMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve custom cmas for a firm by ID
 */
export const getCmasByFirmId = (firmId: string, signal?: AbortSignal) => {
  return fetchInstance<CmasV1[]>({
    url: `/api/portfolios/v1/settings/cmas/firms/${firmId}`,
    method: 'GET',
    signal,
  })
}

export const getGetCmasByFirmIdQueryKey = (firmId: string) => {
  return [`/api/portfolios/v1/settings/cmas/firms/${firmId}`] as const
}

export const getGetCmasByFirmIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getCmasByFirmId>>,
  TError = ErrorType<ErrorResponseV1>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCmasByFirmId>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCmasByFirmIdQueryKey(firmId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCmasByFirmId>>> = ({
    signal,
  }) => getCmasByFirmId(firmId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!firmId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getCmasByFirmId>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetCmasByFirmIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCmasByFirmId>>
>
export type GetCmasByFirmIdQueryError = ErrorType<ErrorResponseV1>

export const useGetCmasByFirmId = <
  TData = Awaited<ReturnType<typeof getCmasByFirmId>>,
  TError = ErrorType<ErrorResponseV1>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCmasByFirmId>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCmasByFirmIdQueryOptions(firmId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Create firm level cmas
 */
export const createCmasForFirm = (
  firmId: string,
  cmasRequestV1: CmasRequestV1
) => {
  return fetchInstance<CmasV1>({
    url: `/api/portfolios/v1/settings/cmas/firms/${firmId}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: cmasRequestV1,
  })
}

export const getCreateCmasForFirmMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCmasForFirm>>,
    TError,
    { firmId: string; data: CmasRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createCmasForFirm>>,
  TError,
  { firmId: string; data: CmasRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createCmasForFirm>>,
    { firmId: string; data: CmasRequestV1 }
  > = (props) => {
    const { firmId, data } = props ?? {}

    return createCmasForFirm(firmId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateCmasForFirmMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCmasForFirm>>
>
export type CreateCmasForFirmMutationBody = CmasRequestV1
export type CreateCmasForFirmMutationError = ErrorType<ErrorResponseV1>

export const useCreateCmasForFirm = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createCmasForFirm>>,
    TError,
    { firmId: string; data: CmasRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreateCmasForFirmMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve index choices by ID
 */
export const getIndexChoicesById = (
  indexChoicesId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<IndexChoicesV1>({
    url: `/api/portfolios/v1/settings/index-choices/${indexChoicesId}`,
    method: 'GET',
    signal,
  })
}

export const getGetIndexChoicesByIdQueryKey = (indexChoicesId: string) => {
  return [
    `/api/portfolios/v1/settings/index-choices/${indexChoicesId}`,
  ] as const
}

export const getGetIndexChoicesByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getIndexChoicesById>>,
  TError = ErrorType<ErrorResponseV1>
>(
  indexChoicesId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getIndexChoicesById>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetIndexChoicesByIdQueryKey(indexChoicesId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getIndexChoicesById>>
  > = ({ signal }) => getIndexChoicesById(indexChoicesId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!indexChoicesId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getIndexChoicesById>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetIndexChoicesByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIndexChoicesById>>
>
export type GetIndexChoicesByIdQueryError = ErrorType<ErrorResponseV1>

export const useGetIndexChoicesById = <
  TData = Awaited<ReturnType<typeof getIndexChoicesById>>,
  TError = ErrorType<ErrorResponseV1>
>(
  indexChoicesId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getIndexChoicesById>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetIndexChoicesByIdQueryOptions(
    indexChoicesId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Update index choices by ID
 */
export const updateIndexChoicesById = (
  indexChoicesId: string,
  indexChoicesRequestV1: IndexChoicesRequestV1,
  headers?: UpdateIndexChoicesByIdHeaders
) => {
  return fetchInstance<IndexChoicesV1>({
    url: `/api/portfolios/v1/settings/index-choices/${indexChoicesId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: indexChoicesRequestV1,
  })
}

export const getUpdateIndexChoicesByIdMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateIndexChoicesById>>,
    TError,
    {
      indexChoicesId: string
      data: IndexChoicesRequestV1
      headers?: UpdateIndexChoicesByIdHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateIndexChoicesById>>,
  TError,
  {
    indexChoicesId: string
    data: IndexChoicesRequestV1
    headers?: UpdateIndexChoicesByIdHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateIndexChoicesById>>,
    {
      indexChoicesId: string
      data: IndexChoicesRequestV1
      headers?: UpdateIndexChoicesByIdHeaders
    }
  > = (props) => {
    const { indexChoicesId, data, headers } = props ?? {}

    return updateIndexChoicesById(indexChoicesId, data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateIndexChoicesByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateIndexChoicesById>>
>
export type UpdateIndexChoicesByIdMutationBody = IndexChoicesRequestV1
export type UpdateIndexChoicesByIdMutationError = ErrorType<ErrorResponseV1>

export const useUpdateIndexChoicesById = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateIndexChoicesById>>,
    TError,
    {
      indexChoicesId: string
      data: IndexChoicesRequestV1
      headers?: UpdateIndexChoicesByIdHeaders
    },
    TContext
  >
}) => {
  const mutationOptions = getUpdateIndexChoicesByIdMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Delete index choices by ID
 */
export const deleteIndexChoicesById = (
  indexChoicesId: string,
  headers?: DeleteIndexChoicesByIdHeaders
) => {
  return fetchInstance<void>({
    url: `/api/portfolios/v1/settings/index-choices/${indexChoicesId}`,
    method: 'DELETE',
    headers,
  })
}

export const getDeleteIndexChoicesByIdMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteIndexChoicesById>>,
    TError,
    { indexChoicesId: string; headers?: DeleteIndexChoicesByIdHeaders },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteIndexChoicesById>>,
  TError,
  { indexChoicesId: string; headers?: DeleteIndexChoicesByIdHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteIndexChoicesById>>,
    { indexChoicesId: string; headers?: DeleteIndexChoicesByIdHeaders }
  > = (props) => {
    const { indexChoicesId, headers } = props ?? {}

    return deleteIndexChoicesById(indexChoicesId, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteIndexChoicesByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteIndexChoicesById>>
>

export type DeleteIndexChoicesByIdMutationError = ErrorType<ErrorResponseV1>

export const useDeleteIndexChoicesById = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteIndexChoicesById>>,
    TError,
    { indexChoicesId: string; headers?: DeleteIndexChoicesByIdHeaders },
    TContext
  >
}) => {
  const mutationOptions = getDeleteIndexChoicesByIdMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve index choices for a user by ID
 */
export const getIndexChoicesByUserId = (
  userId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<IndexChoicesV1[]>({
    url: `/api/portfolios/v1/settings/index-choices/users/${userId}`,
    method: 'GET',
    signal,
  })
}

export const getGetIndexChoicesByUserIdQueryKey = (userId: string) => {
  return [`/api/portfolios/v1/settings/index-choices/users/${userId}`] as const
}

export const getGetIndexChoicesByUserIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getIndexChoicesByUserId>>,
  TError = ErrorType<ErrorResponseV1>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getIndexChoicesByUserId>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetIndexChoicesByUserIdQueryKey(userId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getIndexChoicesByUserId>>
  > = ({ signal }) => getIndexChoicesByUserId(userId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getIndexChoicesByUserId>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetIndexChoicesByUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIndexChoicesByUserId>>
>
export type GetIndexChoicesByUserIdQueryError = ErrorType<ErrorResponseV1>

export const useGetIndexChoicesByUserId = <
  TData = Awaited<ReturnType<typeof getIndexChoicesByUserId>>,
  TError = ErrorType<ErrorResponseV1>
>(
  userId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getIndexChoicesByUserId>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetIndexChoicesByUserIdQueryOptions(userId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Create a user level index choices
 */
export const createIndexChoicesForUser = (
  userId: string,
  indexChoicesRequestV1: IndexChoicesRequestV1
) => {
  return fetchInstance<IndexChoicesV1>({
    url: `/api/portfolios/v1/settings/index-choices/users/${userId}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: indexChoicesRequestV1,
  })
}

export const getCreateIndexChoicesForUserMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createIndexChoicesForUser>>,
    TError,
    { userId: string; data: IndexChoicesRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createIndexChoicesForUser>>,
  TError,
  { userId: string; data: IndexChoicesRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createIndexChoicesForUser>>,
    { userId: string; data: IndexChoicesRequestV1 }
  > = (props) => {
    const { userId, data } = props ?? {}

    return createIndexChoicesForUser(userId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateIndexChoicesForUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof createIndexChoicesForUser>>
>
export type CreateIndexChoicesForUserMutationBody = IndexChoicesRequestV1
export type CreateIndexChoicesForUserMutationError = ErrorType<ErrorResponseV1>

export const useCreateIndexChoicesForUser = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createIndexChoicesForUser>>,
    TError,
    { userId: string; data: IndexChoicesRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreateIndexChoicesForUserMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve index choices for a firm by ID
 */
export const getIndexChoicesByFirmId = (
  firmId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<IndexChoicesV1[]>({
    url: `/api/portfolios/v1/settings/index-choices/firms/${firmId}`,
    method: 'GET',
    signal,
  })
}

export const getGetIndexChoicesByFirmIdQueryKey = (firmId: string) => {
  return [`/api/portfolios/v1/settings/index-choices/firms/${firmId}`] as const
}

export const getGetIndexChoicesByFirmIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getIndexChoicesByFirmId>>,
  TError = ErrorType<ErrorResponseV1>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getIndexChoicesByFirmId>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetIndexChoicesByFirmIdQueryKey(firmId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getIndexChoicesByFirmId>>
  > = ({ signal }) => getIndexChoicesByFirmId(firmId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!firmId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getIndexChoicesByFirmId>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetIndexChoicesByFirmIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIndexChoicesByFirmId>>
>
export type GetIndexChoicesByFirmIdQueryError = ErrorType<ErrorResponseV1>

export const useGetIndexChoicesByFirmId = <
  TData = Awaited<ReturnType<typeof getIndexChoicesByFirmId>>,
  TError = ErrorType<ErrorResponseV1>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getIndexChoicesByFirmId>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetIndexChoicesByFirmIdQueryOptions(firmId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Create a firm level index choices
 */
export const createIndexChoicesForFirm = (
  firmId: string,
  indexChoicesRequestV1: IndexChoicesRequestV1
) => {
  return fetchInstance<IndexChoicesV1>({
    url: `/api/portfolios/v1/settings/index-choices/firms/${firmId}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: indexChoicesRequestV1,
  })
}

export const getCreateIndexChoicesForFirmMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createIndexChoicesForFirm>>,
    TError,
    { firmId: string; data: IndexChoicesRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createIndexChoicesForFirm>>,
  TError,
  { firmId: string; data: IndexChoicesRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createIndexChoicesForFirm>>,
    { firmId: string; data: IndexChoicesRequestV1 }
  > = (props) => {
    const { firmId, data } = props ?? {}

    return createIndexChoicesForFirm(firmId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateIndexChoicesForFirmMutationResult = NonNullable<
  Awaited<ReturnType<typeof createIndexChoicesForFirm>>
>
export type CreateIndexChoicesForFirmMutationBody = IndexChoicesRequestV1
export type CreateIndexChoicesForFirmMutationError = ErrorType<ErrorResponseV1>

export const useCreateIndexChoicesForFirm = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createIndexChoicesForFirm>>,
    TError,
    { firmId: string; data: IndexChoicesRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreateIndexChoicesForFirmMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve asset class and liquidity limits by ID
 */
export const getLimitsById = (limitsId: string, signal?: AbortSignal) => {
  return fetchInstance<LimitsV1>({
    url: `/api/portfolios/v1/settings/limits/${limitsId}`,
    method: 'GET',
    signal,
  })
}

export const getGetLimitsByIdQueryKey = (limitsId: string) => {
  return [`/api/portfolios/v1/settings/limits/${limitsId}`] as const
}

export const getGetLimitsByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getLimitsById>>,
  TError = ErrorType<ErrorResponseV1>
>(
  limitsId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLimitsById>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetLimitsByIdQueryKey(limitsId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLimitsById>>> = ({
    signal,
  }) => getLimitsById(limitsId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!limitsId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getLimitsById>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetLimitsByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLimitsById>>
>
export type GetLimitsByIdQueryError = ErrorType<ErrorResponseV1>

export const useGetLimitsById = <
  TData = Awaited<ReturnType<typeof getLimitsById>>,
  TError = ErrorType<ErrorResponseV1>
>(
  limitsId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLimitsById>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLimitsByIdQueryOptions(limitsId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Update asset class and liquidity limits by ID
 */
export const updateLimitsById = (
  limitsId: string,
  limitsRequestV1: LimitsRequestV1,
  headers?: UpdateLimitsByIdHeaders
) => {
  return fetchInstance<LimitsV1>({
    url: `/api/portfolios/v1/settings/limits/${limitsId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: limitsRequestV1,
  })
}

export const getUpdateLimitsByIdMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateLimitsById>>,
    TError,
    {
      limitsId: string
      data: LimitsRequestV1
      headers?: UpdateLimitsByIdHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateLimitsById>>,
  TError,
  {
    limitsId: string
    data: LimitsRequestV1
    headers?: UpdateLimitsByIdHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateLimitsById>>,
    {
      limitsId: string
      data: LimitsRequestV1
      headers?: UpdateLimitsByIdHeaders
    }
  > = (props) => {
    const { limitsId, data, headers } = props ?? {}

    return updateLimitsById(limitsId, data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateLimitsByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateLimitsById>>
>
export type UpdateLimitsByIdMutationBody = LimitsRequestV1
export type UpdateLimitsByIdMutationError = ErrorType<ErrorResponseV1>

export const useUpdateLimitsById = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateLimitsById>>,
    TError,
    {
      limitsId: string
      data: LimitsRequestV1
      headers?: UpdateLimitsByIdHeaders
    },
    TContext
  >
}) => {
  const mutationOptions = getUpdateLimitsByIdMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Delete asset class and liquidity limits by ID
 */
export const deleteLimitsById = (
  limitsId: string,
  headers?: DeleteLimitsByIdHeaders
) => {
  return fetchInstance<void>({
    url: `/api/portfolios/v1/settings/limits/${limitsId}`,
    method: 'DELETE',
    headers,
  })
}

export const getDeleteLimitsByIdMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteLimitsById>>,
    TError,
    { limitsId: string; headers?: DeleteLimitsByIdHeaders },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteLimitsById>>,
  TError,
  { limitsId: string; headers?: DeleteLimitsByIdHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteLimitsById>>,
    { limitsId: string; headers?: DeleteLimitsByIdHeaders }
  > = (props) => {
    const { limitsId, headers } = props ?? {}

    return deleteLimitsById(limitsId, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteLimitsByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteLimitsById>>
>

export type DeleteLimitsByIdMutationError = ErrorType<ErrorResponseV1>

export const useDeleteLimitsById = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteLimitsById>>,
    TError,
    { limitsId: string; headers?: DeleteLimitsByIdHeaders },
    TContext
  >
}) => {
  const mutationOptions = getDeleteLimitsByIdMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve custom asset class and liquidity limits for a firm by ID
 */
export const getLimitsByFirmId = (firmId: string, signal?: AbortSignal) => {
  return fetchInstance<LimitsV1>({
    url: `/api/portfolios/v1/settings/limits/firms/${firmId}`,
    method: 'GET',
    signal,
  })
}

export const getGetLimitsByFirmIdQueryKey = (firmId: string) => {
  return [`/api/portfolios/v1/settings/limits/firms/${firmId}`] as const
}

export const getGetLimitsByFirmIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getLimitsByFirmId>>,
  TError = ErrorType<ErrorResponseV1>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLimitsByFirmId>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetLimitsByFirmIdQueryKey(firmId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLimitsByFirmId>>
  > = ({ signal }) => getLimitsByFirmId(firmId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!firmId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getLimitsByFirmId>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetLimitsByFirmIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLimitsByFirmId>>
>
export type GetLimitsByFirmIdQueryError = ErrorType<ErrorResponseV1>

export const useGetLimitsByFirmId = <
  TData = Awaited<ReturnType<typeof getLimitsByFirmId>>,
  TError = ErrorType<ErrorResponseV1>
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLimitsByFirmId>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLimitsByFirmIdQueryOptions(firmId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Create firm level asset class and liquidity limits
 */
export const createLimitsForFirm = (
  firmId: string,
  limitsRequestV1: LimitsRequestV1
) => {
  return fetchInstance<LimitsV1>({
    url: `/api/portfolios/v1/settings/limits/firms/${firmId}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: limitsRequestV1,
  })
}

export const getCreateLimitsForFirmMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createLimitsForFirm>>,
    TError,
    { firmId: string; data: LimitsRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createLimitsForFirm>>,
  TError,
  { firmId: string; data: LimitsRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createLimitsForFirm>>,
    { firmId: string; data: LimitsRequestV1 }
  > = (props) => {
    const { firmId, data } = props ?? {}

    return createLimitsForFirm(firmId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateLimitsForFirmMutationResult = NonNullable<
  Awaited<ReturnType<typeof createLimitsForFirm>>
>
export type CreateLimitsForFirmMutationBody = LimitsRequestV1
export type CreateLimitsForFirmMutationError = ErrorType<ErrorResponseV1>

export const useCreateLimitsForFirm = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createLimitsForFirm>>,
    TError,
    { firmId: string; data: LimitsRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreateLimitsForFirmMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve the all available settings for the current user
 */
export const getCurrentUserSettings = (signal?: AbortSignal) => {
  return fetchInstance<AvailableSettingsV1>({
    url: `/api/portfolios/v1/settings/current-user`,
    method: 'GET',
    signal,
  })
}

export const getGetCurrentUserSettingsQueryKey = () => {
  return [`/api/portfolios/v1/settings/current-user`] as const
}

export const getGetCurrentUserSettingsQueryOptions = <
  TData = Awaited<ReturnType<typeof getCurrentUserSettings>>,
  TError = ErrorType<ErrorResponseV1>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getCurrentUserSettings>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCurrentUserSettingsQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getCurrentUserSettings>>
  > = ({ signal }) => getCurrentUserSettings(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCurrentUserSettings>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetCurrentUserSettingsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCurrentUserSettings>>
>
export type GetCurrentUserSettingsQueryError = ErrorType<ErrorResponseV1>

export const useGetCurrentUserSettings = <
  TData = Awaited<ReturnType<typeof getCurrentUserSettings>>,
  TError = ErrorType<ErrorResponseV1>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getCurrentUserSettings>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCurrentUserSettingsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Current active settings for the current user
 */
export const getActiveSettingsForCurrentUser = (signal?: AbortSignal) => {
  return fetchInstance<SettingsV1>({
    url: `/api/portfolios/v1/settings/current-user/active`,
    method: 'GET',
    signal,
  })
}

export const getGetActiveSettingsForCurrentUserQueryKey = () => {
  return [`/api/portfolios/v1/settings/current-user/active`] as const
}

export const getGetActiveSettingsForCurrentUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getActiveSettingsForCurrentUser>>,
  TError = ErrorType<ErrorResponseV1>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getActiveSettingsForCurrentUser>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetActiveSettingsForCurrentUserQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getActiveSettingsForCurrentUser>>
  > = ({ signal }) => getActiveSettingsForCurrentUser(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getActiveSettingsForCurrentUser>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetActiveSettingsForCurrentUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getActiveSettingsForCurrentUser>>
>
export type GetActiveSettingsForCurrentUserQueryError =
  ErrorType<ErrorResponseV1>

export const useGetActiveSettingsForCurrentUser = <
  TData = Awaited<ReturnType<typeof getActiveSettingsForCurrentUser>>,
  TError = ErrorType<ErrorResponseV1>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getActiveSettingsForCurrentUser>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetActiveSettingsForCurrentUserQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Set active settings for the current user
 */
export const setActiveSettingsForCurrentUser = (
  activeSettingsRequestV1: ActiveSettingsRequestV1
) => {
  return fetchInstance<SettingsV1>({
    url: `/api/portfolios/v1/settings/current-user/active`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: activeSettingsRequestV1,
  })
}

export const getSetActiveSettingsForCurrentUserMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setActiveSettingsForCurrentUser>>,
    TError,
    { data: ActiveSettingsRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof setActiveSettingsForCurrentUser>>,
  TError,
  { data: ActiveSettingsRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setActiveSettingsForCurrentUser>>,
    { data: ActiveSettingsRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return setActiveSettingsForCurrentUser(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SetActiveSettingsForCurrentUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof setActiveSettingsForCurrentUser>>
>
export type SetActiveSettingsForCurrentUserMutationBody =
  ActiveSettingsRequestV1
export type SetActiveSettingsForCurrentUserMutationError =
  ErrorType<ErrorResponseV1>

export const useSetActiveSettingsForCurrentUser = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setActiveSettingsForCurrentUser>>,
    TError,
    { data: ActiveSettingsRequestV1 },
    TContext
  >
}) => {
  const mutationOptions =
    getSetActiveSettingsForCurrentUserMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Generate a summary for the given allocations
 */
export const getAllocationSummary = (
  allocationSummaryRequestV1: AllocationSummaryRequestV1
) => {
  return fetchInstance<AllocationSummaryV1>({
    url: `/api/portfolios/v1/summaries/allocations`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: allocationSummaryRequestV1,
  })
}

export const getGetAllocationSummaryMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getAllocationSummary>>,
    TError,
    { data: AllocationSummaryRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof getAllocationSummary>>,
  TError,
  { data: AllocationSummaryRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getAllocationSummary>>,
    { data: AllocationSummaryRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return getAllocationSummary(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type GetAllocationSummaryMutationResult = NonNullable<
  Awaited<ReturnType<typeof getAllocationSummary>>
>
export type GetAllocationSummaryMutationBody = AllocationSummaryRequestV1
export type GetAllocationSummaryMutationError = ErrorType<ErrorResponseV1>

export const useGetAllocationSummary = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getAllocationSummary>>,
    TError,
    { data: AllocationSummaryRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getGetAllocationSummaryMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve a summary by its ID
 */
export const getAllocationSummaryById = (
  allocationSummaryId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<AllocationSummaryV1>({
    url: `/api/portfolios/v1/summaries/allocations/${allocationSummaryId}`,
    method: 'GET',
    signal,
  })
}

export const getGetAllocationSummaryByIdQueryKey = (
  allocationSummaryId: string
) => {
  return [
    `/api/portfolios/v1/summaries/allocations/${allocationSummaryId}`,
  ] as const
}

export const getGetAllocationSummaryByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllocationSummaryById>>,
  TError = ErrorType<ErrorResponseV1>
>(
  allocationSummaryId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAllocationSummaryById>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetAllocationSummaryByIdQueryKey(allocationSummaryId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAllocationSummaryById>>
  > = ({ signal }) => getAllocationSummaryById(allocationSummaryId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!allocationSummaryId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllocationSummaryById>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAllocationSummaryByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllocationSummaryById>>
>
export type GetAllocationSummaryByIdQueryError = ErrorType<ErrorResponseV1>

export const useGetAllocationSummaryById = <
  TData = Awaited<ReturnType<typeof getAllocationSummaryById>>,
  TError = ErrorType<ErrorResponseV1>
>(
  allocationSummaryId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAllocationSummaryById>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllocationSummaryByIdQueryOptions(
    allocationSummaryId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Delete a summary by its ID
 */
export const deleteAllocationSummaryById = (allocationSummaryId: string) => {
  return fetchInstance<void>({
    url: `/api/portfolios/v1/summaries/allocations/${allocationSummaryId}`,
    method: 'DELETE',
  })
}

export const getDeleteAllocationSummaryByIdMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAllocationSummaryById>>,
    TError,
    { allocationSummaryId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteAllocationSummaryById>>,
  TError,
  { allocationSummaryId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteAllocationSummaryById>>,
    { allocationSummaryId: string }
  > = (props) => {
    const { allocationSummaryId } = props ?? {}

    return deleteAllocationSummaryById(allocationSummaryId)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteAllocationSummaryByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteAllocationSummaryById>>
>

export type DeleteAllocationSummaryByIdMutationError =
  ErrorType<ErrorResponseV1>

export const useDeleteAllocationSummaryById = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteAllocationSummaryById>>,
    TError,
    { allocationSummaryId: string },
    TContext
  >
}) => {
  const mutationOptions = getDeleteAllocationSummaryByIdMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Accept click through agreement for AI summaries
 */
export const acceptSummaryCta = () => {
  return fetchInstance<void>({
    url: `/api/portfolios/v1/cta/summaries/accept`,
    method: 'POST',
  })
}

export const getAcceptSummaryCtaMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acceptSummaryCta>>,
    TError,
    void,
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof acceptSummaryCta>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof acceptSummaryCta>>,
    void
  > = () => {
    return acceptSummaryCta()
  }

  return { mutationFn, ...mutationOptions }
}

export type AcceptSummaryCtaMutationResult = NonNullable<
  Awaited<ReturnType<typeof acceptSummaryCta>>
>

export type AcceptSummaryCtaMutationError = ErrorType<ErrorResponseV1>

export const useAcceptSummaryCta = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acceptSummaryCta>>,
    TError,
    void,
    TContext
  >
}) => {
  const mutationOptions = getAcceptSummaryCtaMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve click through agreement status for AI summaries
 */
export const getSummaryCtaStatus = (signal?: AbortSignal) => {
  return fetchInstance<CtaStatusV1>({
    url: `/api/portfolios/v1/cta/summaries`,
    method: 'GET',
    signal,
  })
}

export const getGetSummaryCtaStatusQueryKey = () => {
  return [`/api/portfolios/v1/cta/summaries`] as const
}

export const getGetSummaryCtaStatusQueryOptions = <
  TData = Awaited<ReturnType<typeof getSummaryCtaStatus>>,
  TError = ErrorType<ErrorResponseV1>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getSummaryCtaStatus>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSummaryCtaStatusQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSummaryCtaStatus>>
  > = ({ signal }) => getSummaryCtaStatus(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSummaryCtaStatus>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetSummaryCtaStatusQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSummaryCtaStatus>>
>
export type GetSummaryCtaStatusQueryError = ErrorType<ErrorResponseV1>

export const useGetSummaryCtaStatus = <
  TData = Awaited<ReturnType<typeof getSummaryCtaStatus>>,
  TError = ErrorType<ErrorResponseV1>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getSummaryCtaStatus>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSummaryCtaStatusQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Load index data
 */
export const loadIndexData = () => {
  return fetchInstance<void>({
    url: `/api/portfolios/v1/admin/load-index-data`,
    method: 'POST',
  })
}

export const getLoadIndexDataMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loadIndexData>>,
    TError,
    void,
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof loadIndexData>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof loadIndexData>>,
    void
  > = () => {
    return loadIndexData()
  }

  return { mutationFn, ...mutationOptions }
}

export type LoadIndexDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof loadIndexData>>
>

export type LoadIndexDataMutationError = ErrorType<ErrorResponseV1>

export const useLoadIndexData = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof loadIndexData>>,
    TError,
    void,
    TContext
  >
}) => {
  const mutationOptions = getLoadIndexDataMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve the reference asset allocation framework documentation
 */
export const getReferenceAssetAllocationFrameworkDocumentation = (
  signal?: AbortSignal
) => {
  return fetchInstance<Blob>({
    url: `/api/portfolios/v1/documentation/reference-asset-allocation-framework`,
    method: 'GET',
    responseType: 'blob',
    signal,
  })
}

export const getGetReferenceAssetAllocationFrameworkDocumentationQueryKey =
  () => {
    return [
      `/api/portfolios/v1/documentation/reference-asset-allocation-framework`,
    ] as const
  }

export const getGetReferenceAssetAllocationFrameworkDocumentationQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getReferenceAssetAllocationFrameworkDocumentation>
    >,
    TError = ErrorType<ErrorResponseV1>
  >(options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof getReferenceAssetAllocationFrameworkDocumentation>
      >,
      TError,
      TData
    >
  }) => {
    const { query: queryOptions } = options ?? {}

    const queryKey =
      queryOptions?.queryKey ??
      getGetReferenceAssetAllocationFrameworkDocumentationQueryKey()

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof getReferenceAssetAllocationFrameworkDocumentation>
      >
    > = ({ signal }) =>
      getReferenceAssetAllocationFrameworkDocumentation(signal)

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<typeof getReferenceAssetAllocationFrameworkDocumentation>
      >,
      TError,
      TData
    > & { queryKey: QueryKey }
  }

export type GetReferenceAssetAllocationFrameworkDocumentationQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getReferenceAssetAllocationFrameworkDocumentation>
    >
  >
export type GetReferenceAssetAllocationFrameworkDocumentationQueryError =
  ErrorType<ErrorResponseV1>

export const useGetReferenceAssetAllocationFrameworkDocumentation = <
  TData = Awaited<
    ReturnType<typeof getReferenceAssetAllocationFrameworkDocumentation>
  >,
  TError = ErrorType<ErrorResponseV1>
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<typeof getReferenceAssetAllocationFrameworkDocumentation>
    >,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetReferenceAssetAllocationFrameworkDocumentationQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Saves a summary feedback
 */
export const createSummaryFeedback = (
  summaryFeedbackRequestV1: SummaryFeedbackRequestV1
) => {
  return fetchInstance<SummaryFeedbackV1>({
    url: `/api/portfolios/v1/summary-feedback`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: summaryFeedbackRequestV1,
  })
}

export const getCreateSummaryFeedbackMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSummaryFeedback>>,
    TError,
    { data: SummaryFeedbackRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createSummaryFeedback>>,
  TError,
  { data: SummaryFeedbackRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createSummaryFeedback>>,
    { data: SummaryFeedbackRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return createSummaryFeedback(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateSummaryFeedbackMutationResult = NonNullable<
  Awaited<ReturnType<typeof createSummaryFeedback>>
>
export type CreateSummaryFeedbackMutationBody = SummaryFeedbackRequestV1
export type CreateSummaryFeedbackMutationError = ErrorType<ErrorResponseV1>

export const useCreateSummaryFeedback = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createSummaryFeedback>>,
    TError,
    { data: SummaryFeedbackRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreateSummaryFeedbackMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Search for summary feedback
 */
export const searchSummaryFeedback = (
  summaryFeedbackSearchRequestV1: SummaryFeedbackSearchRequestV1
) => {
  return fetchInstance<PagedSummaryFeedbackListItemsV1>({
    url: `/api/portfolios/v1/summary-feedback/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: summaryFeedbackSearchRequestV1,
  })
}

export const getSearchSummaryFeedbackMutationOptions = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchSummaryFeedback>>,
    TError,
    { data: SummaryFeedbackSearchRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchSummaryFeedback>>,
  TError,
  { data: SummaryFeedbackSearchRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchSummaryFeedback>>,
    { data: SummaryFeedbackSearchRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return searchSummaryFeedback(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchSummaryFeedbackMutationResult = NonNullable<
  Awaited<ReturnType<typeof searchSummaryFeedback>>
>
export type SearchSummaryFeedbackMutationBody = SummaryFeedbackSearchRequestV1
export type SearchSummaryFeedbackMutationError = ErrorType<ErrorResponseV1>

export const useSearchSummaryFeedback = <
  TError = ErrorType<ErrorResponseV1>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchSummaryFeedback>>,
    TError,
    { data: SummaryFeedbackSearchRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getSearchSummaryFeedbackMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve asset class fund strategy mappings
 */
export const getAssetClassFundStrategyMappings = (signal?: AbortSignal) => {
  return fetchInstance<AssetClassFundStrategyMappingsV1[]>({
    url: `/api/portfolios/v1/asset-class-fund-strategy-mappings`,
    method: 'GET',
    signal,
  })
}

export const getGetAssetClassFundStrategyMappingsQueryKey = () => {
  return [`/api/portfolios/v1/asset-class-fund-strategy-mappings`] as const
}

export const getGetAssetClassFundStrategyMappingsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAssetClassFundStrategyMappings>>,
  TError = ErrorType<ErrorResponseV1>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAssetClassFundStrategyMappings>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetAssetClassFundStrategyMappingsQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAssetClassFundStrategyMappings>>
  > = ({ signal }) => getAssetClassFundStrategyMappings(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAssetClassFundStrategyMappings>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAssetClassFundStrategyMappingsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAssetClassFundStrategyMappings>>
>
export type GetAssetClassFundStrategyMappingsQueryError =
  ErrorType<ErrorResponseV1>

export const useGetAssetClassFundStrategyMappings = <
  TData = Awaited<ReturnType<typeof getAssetClassFundStrategyMappings>>,
  TError = ErrorType<ErrorResponseV1>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getAssetClassFundStrategyMappings>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAssetClassFundStrategyMappingsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}
