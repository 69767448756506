import { useQuery } from 'react-query'

import {
  searchFirmsV1,
  searchTeamsV1,
  searchUsersV1,
} from '@cais-group/access-manager/domain/api'
import { searchPositions } from '@cais-group/ads/domain/rest/funds-investments'
import {
  FundProductFilterV1QueryStringMatchType,
  searchFundProducts,
} from '@cais-group/funds-pre-trade/domain/api'
import { searchFundOrdersWithExpandedResourcesV2 } from '@cais-group/funds-trade/domain/api'
import { searchPortfolios } from '@cais-group/portfolio-construction-tool/domain/api'
import {
  searchAccounts,
  searchHouseholds,
} from '@cais-group/shared/domain/investor-profile'
import { searchStructuredProductsV2 } from '@cais-group/shared/domain/product-master-api'
import { useDebouncedState } from '@cais-group/shared/util/hook/use-debounced-state'
import { searchStructuredProductOrders } from '@cais-group/structured-products/domain/structured-product-orders-api'

export type SearchSource =
  | 'products'
  | 'funds-orders'
  | 'portfolios'
  | 'sp-products'
  | 'sp-orders'
  | 'positions'
  | 'iam-users'
  | 'iam-teams'
  | 'iam-firms'
  | 'accounts'
  | 'households'

export type UseGlobalSearchProps = {
  searchText: string
  sources: SearchSource[]
}

function searchStringLike(searchText: string) {
  return { like: '%' + searchText + '%', caseSensitive: false }
}

async function SearchProducts(searchText: string) {
  const searchFilter = searchText?.length
    ? {
        queryString: searchText,
        queryStringMatchType:
          FundProductFilterV1QueryStringMatchType.BEST_FIELDS,
      }
    : {}

  return searchFundProducts({
    filter: { ...searchFilter },
    pageRequest: { first: 10 },
  }).then((page) => page.items)
}

async function SearchFundOrders(searchText: string) {
  return searchFundOrdersWithExpandedResourcesV2({
    pageRequest: { first: 10 },
    filter: {
      searchText: searchStringLike(searchText),
    },
  }).then((page) => page.items)
}

async function SearchPortfolios(searchText: string) {
  return searchPortfolios({
    pageRequest: { first: 10 },
    filter: {
      name: searchStringLike(searchText),
    },
  }).then((page) => page.items)
}

async function SearchStructuredProducts(searchText: string) {
  return searchStructuredProductsV2({
    pageRequest: { first: 10 },
    filter: {
      // or: [
      // {
      //   identifier: {
      //     cusip: {
      //       like: '%' + searchText + '%',
      //       caseSensitive: false,
      //     },
      //   },
      // },
      // {
      structureName: searchStringLike(searchText),
      // },
      // ],
    },
  }).then((page) => page.items)
}

async function SearchStructuredProductOrders(searchText: string) {
  return searchStructuredProductOrders({
    pageRequest: { first: 10 },
    filter: {
      cusip: searchStringLike(searchText),
    },
  }).then((page) => page.items)
}

async function SearchPositions(searchText: string) {
  return searchPositions({
    pageRequest: { first: 10 },
    filter: {},
  })
}

async function SearchIAMUsers(searchText: string) {
  return searchUsersV1({
    filter: { name: searchStringLike(searchText) },
    pageRequest: { first: 10 },
  }).then((page) => page.items)
}

async function SearchIAMTeams(searchText: string) {
  return searchTeamsV1({
    filter: { name: searchStringLike(searchText) },
    pageRequest: { first: 10 },
  }).then((page) => page.items)
}

async function SearchIAMFirms(searchText: string) {
  return searchFirmsV1({
    filter: { searchText: searchStringLike(searchText) },
    pageRequest: { first: 10 },
  }).then((page) => page.items)
}

async function SearchHouseholds(searchText: string) {
  return searchHouseholds({
    filter: { householdName: searchStringLike(searchText) },
    pageRequest: { first: 10 },
  }).then((page) => page.items)
}

async function SearchAccounts(searchText: string) {
  return searchAccounts({
    filter: {
      or: [
        {
          accountName: searchStringLike(searchText),
        },
        {
          accountNumber: searchStringLike(searchText),
        },
      ],
    },
    pageRequest: { first: 10 },
  }).then((page) => page.items)
}

export function useGlobalSearch({ searchText, sources }: UseGlobalSearchProps) {
  const debouncedSearchText = useDebouncedState(searchText, 300)

  const defaultQueryOptions = (enabled?: boolean) => ({
    enabled: Boolean(enabled) && debouncedSearchText.length > 1,
    staleTime: 1000 * 5 * 60,
  })

  const productResults = useQuery({
    queryKey: ['products', debouncedSearchText],
    queryFn: () => SearchProducts(debouncedSearchText),
    ...defaultQueryOptions(sources?.includes('products')),
  })

  const fundOrderResults = useQuery({
    queryKey: ['funds-orders', debouncedSearchText],
    queryFn: () => SearchFundOrders(debouncedSearchText),
    ...defaultQueryOptions(sources?.includes('funds-orders')),
  })

  const portfolioResults = useQuery({
    queryKey: ['portfolios', debouncedSearchText],
    queryFn: () => SearchPortfolios(debouncedSearchText),
    ...defaultQueryOptions(sources?.includes('portfolios')),
  })

  const spProductResults = useQuery({
    queryKey: ['spProducts', debouncedSearchText],
    queryFn: () => SearchStructuredProducts(debouncedSearchText),
    ...defaultQueryOptions(sources?.includes('sp-products')),
  })

  const spProductOrdersResults = useQuery({
    queryKey: ['spOrders', debouncedSearchText],
    queryFn: () => SearchStructuredProductOrders(debouncedSearchText),
    ...defaultQueryOptions(sources?.includes('sp-orders')),
  })

  const positionsResults = useQuery({
    queryKey: ['positions', debouncedSearchText],
    queryFn: () => SearchPositions(debouncedSearchText),
    ...defaultQueryOptions(sources?.includes('positions')),
  })

  const iamUsersResults = useQuery({
    queryKey: ['iam-users', debouncedSearchText],
    queryFn: () => SearchIAMUsers(debouncedSearchText),
    ...defaultQueryOptions(sources?.includes('iam-users')),
  })

  const iamTeamsResults = useQuery({
    queryKey: ['iam-teams', debouncedSearchText],
    queryFn: () => SearchIAMTeams(debouncedSearchText),
    ...defaultQueryOptions(sources?.includes('iam-teams')),
  })

  const iamFirmsResults = useQuery({
    queryKey: ['iam-firms', debouncedSearchText],
    queryFn: () => SearchIAMFirms(debouncedSearchText),
    ...defaultQueryOptions(sources?.includes('iam-firms')),
  })

  const houseHoldResults = useQuery({
    queryKey: ['households', debouncedSearchText],
    queryFn: () => SearchHouseholds(debouncedSearchText),
    ...defaultQueryOptions(sources?.includes('households')),
  })

  const accountsResults = useQuery({
    queryKey: ['accounts', debouncedSearchText],
    queryFn: () => SearchAccounts(debouncedSearchText),
    ...defaultQueryOptions(sources?.includes('accounts')),
  })

  function sum(...args: { data?: unknown[] }[]) {
    return args.reduce((acc, curr) => acc + (curr.data?.length || 0), 0)
  }

  return {
    isLoading:
      productResults.isLoading ||
      fundOrderResults.isLoading ||
      portfolioResults.isLoading ||
      // spProductResults.isLoading ||
      spProductOrdersResults.isLoading ||
      positionsResults.isLoading ||
      iamUsersResults.isLoading ||
      iamTeamsResults.isLoading ||
      iamFirmsResults.isLoading ||
      houseHoldResults.isLoading ||
      accountsResults.isLoading,
    isFetching:
      productResults.isFetching ||
      fundOrderResults.isFetching ||
      portfolioResults.isFetching ||
      // spProductResults.isFetching ||
      spProductOrdersResults.isFetching ||
      positionsResults.isFetching ||
      iamUsersResults.isFetching ||
      iamTeamsResults.isFetching ||
      iamFirmsResults.isFetching ||
      houseHoldResults.isFetching ||
      accountsResults.isFetching,

    totalResults: sum(
      productResults,
      fundOrderResults,
      portfolioResults,
      spProductResults,
      spProductOrdersResults,
      iamUsersResults,
      iamTeamsResults,
      iamFirmsResults,
      houseHoldResults,
      accountsResults
    ),

    results: {
      products: maybeSource(sources, 'products', productResults),
      'funds-orders': maybeSource(sources, 'funds-orders', fundOrderResults),
      portfolios: maybeSource(sources, 'portfolios', portfolioResults),
      'sp-products': maybeSource(sources, 'sp-products', spProductResults),
      'sp-orders': maybeSource(sources, 'sp-orders', spProductOrdersResults),
      positions: maybeSource(sources, 'positions', positionsResults),
      'iam-users': maybeSource(sources, 'iam-users', iamUsersResults),
      'iam-teams': maybeSource(sources, 'iam-teams', iamTeamsResults),
      'iam-firms': maybeSource(sources, 'iam-firms', iamFirmsResults),
      households: maybeSource(sources, 'households', houseHoldResults),
      accounts: maybeSource(sources, 'accounts', accountsResults),
    },
  }
}

function maybeSource<T>(
  sources: SearchSource[],
  source: SearchSource,
  results: T
): T {
  return sources.includes(source) ? results : ([] as T)
}
