import Decimal from 'decimal.js'

import { AllocationInputs, AssetClass } from '../../../types'

const assetClasses = [
  AssetClass.PUBLIC_EQUITY,
  AssetClass.PUBLIC_FIXED_INCOME,
  AssetClass.HEDGE_FUNDS,
  AssetClass.PRIVATE_EQUITY,
  AssetClass.PRIVATE_DEBT,
  AssetClass.PRIVATE_REAL_ASSETS,
]

export const calculateTotalAllocation = (allocations: AllocationInputs) => {
  const totalAllocation = assetClasses.reduce((sum, assetClass) => {
    const value = allocations[assetClass]
    return sum.plus(
      !value || Number.isNaN(value) ? new Decimal(0) : new Decimal(value)
    )
  }, new Decimal(0)) // Start with a Decimal for precision

  return totalAllocation.mul(100).toDecimalPlaces(2).toNumber()
}
