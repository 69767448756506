import Decimal from 'decimal.js'

import { Icon } from '@cais-group/equity/atoms/icon'
import { InfoTag } from '@cais-group/equity/atoms/info-tag'
import {
  NameAndPercentageValueLabel,
  PieChart,
} from '@cais-group/equity/labs/pie-chart'
import { HighlightedText } from '@cais-group/equity/molecules/list-item'
import { IconType } from '@cais-group/equity/particles/icons'
import { formatNumberToCurrency } from '@cais-group/equity/utilitarian'
import {
  FundObjective,
  InvestorLevel,
} from '@cais-group/funds-pre-trade/domain/api'
import { PortfolioListItemV1 } from '@cais-group/portfolio-construction-tool/domain/api'
import { useGenerateUiAllocationInputs } from '@cais-group/portfolio-construction-tool/shared'
import { CloudinaryImage } from '@cais-group/shared/ui/cloudinary-image'
import { FUND_INVESTOR_LEVEL_MAPPING } from '@cais-group/shared/util/funds-enums-formatter'

/*

This can be generated by exporting the following environment variable (it shouldn't change unless the API_KEY is rotated):

export CLOUDINARY_URL=cloudinary://API_KEY:SECRET@cais

then running the following node.js script:

var cloudinary = require("cloudinary").v2;

console.log(
  cloudinary.url("firm-logos.json", { type: "list", sign_url: true })
);

*/
const FIRM_LOGO_LIST_ENDPOINT =
  'https://res.cloudinary.com/cais/image/list/s--bW9xzZz9--/firm-logos.json?_a=BAMCkGOa0'

export const firmLogoList: Record<string, string> = {}

fetch(FIRM_LOGO_LIST_ENDPOINT)
  .then((response) => {
    if (response.ok) {
      response.json().then((data) => {
        data.resources.forEach(
          (resource: {
            public_id: string
            secure_url: string
            context: { custom: { firmIds: string } }
          }) => {
            try {
              if (resource?.context?.custom?.firmIds) {
                const firmIds = JSON.parse(resource.context.custom.firmIds)
                firmIds.forEach((id: string) => {
                  firmLogoList[id] = resource.public_id
                })
              }
            } catch (e) {
              console.error('Cloudinary metadata malformed')
            }
          }
        )
      })
    }
  })
  .catch(() => {
    console.error('Failed to load firm logos')
  })

export const ObjectiveIcon: Record<Exclude<FundObjective, null>, IconType> = {
  [FundObjective.DIVERSIFY_RISK]: 'ForkedArrow',
  [FundObjective.ENHANCE_RETURNS]: 'BarChart',
  [FundObjective.PRESERVE_CAPITAL]: 'Shield',
  [FundObjective.SUPPLEMENT_INCOME]: 'AddToLibrary',
}

export const fundObjectives: Record<Exclude<FundObjective, null>, string> = {
  PRESERVE_CAPITAL: 'Preserve Capital',
  DIVERSIFY_RISK: 'Diversify Risk',
  ENHANCE_RETURNS: 'Enhance Returns',
  SUPPLEMENT_INCOME: 'Supplement Income',
}

type Props = {
  portfolio: PortfolioListItemV1
  highlight?: string
}

export const PortfolioCard = ({ portfolio, highlight }: Props) => {
  const isModel = portfolio.type === 'MODEL'

  const uiAllocationInputs = useGenerateUiAllocationInputs()
  const allocationsData = uiAllocationInputs.map((input) => ({
    ...input,
    id: input.name,
    value:
      portfolio?.allocations?.find(
        ({ assetClass }) => assetClass === input.name
      )?.allocation || 0,
  }))

  return (
    <article
      className="hover:shadow-2 duration-short-2 ease-standard active:border-primary-600 focus-within:border-primary-600 border-1 bg-neutral-0 relative flex grow flex-col border-neutral-200 transition-shadow focus-within:border hover:border-neutral-200"
      data-testid={`portfolio-card-${portfolio.id}`}
    >
      <div
        className={`border-primary-200 hover:border-primary-500 duration-short-2 flex grow flex-col border-l-8 px-32 py-24 transition-all [&:focus-within_a]:outline-none [&_a]:after:absolute [&_a]:after:inset-0 [&_a]:after:z-10 [&_a]:after:content-[""]`}
      >
        <div className="flex items-center justify-between">
          {portfolio.firmId && firmLogoList[portfolio.firmId] ? (
            <CloudinaryImage
              layout="fixed"
              priority
              height={68}
              width={120}
              src={firmLogoList[portfolio.firmId]}
              className="h-full w-full"
            />
          ) : isModel ? (
            <div />
          ) : (
            <InfoTag>Custom Portfolio</InfoTag>
          )}

          <div>
            <InfoTag startAdornment="ProductOutlined">{`${
              portfolio.fundAllocations?.length || 0
            }`}</InfoTag>
          </div>
        </div>

        <div className="mt-8 flex w-full flex-col gap-y-16">
          <h6 className="headline-s-strong text-neutral-900">
            <HighlightedText
              isSearchable
              textToHighlight={highlight}
              inputText={portfolio.name}
              includeWrapper
            />
          </h6>

          {portfolio.primaryObjective || portfolio?.secondaryObjective ? (
            <div className="flex flex-wrap items-center gap-x-24 gap-y-8">
              {portfolio.primaryObjective ? (
                <div className="flex items-center gap-x-4">
                  <div className="flex h-full items-center  pb-4">
                    <Icon
                      size="small"
                      color="eq-color-primary-600"
                      type={
                        ObjectiveIcon[
                          portfolio.primaryObjective as Exclude<
                            FundObjective,
                            null
                          >
                        ]
                      }
                    />
                  </div>
                  <span className="small">
                    {
                      fundObjectives[
                        portfolio.primaryObjective as Exclude<
                          FundObjective,
                          null
                        >
                      ]
                    }
                  </span>
                </div>
              ) : null}
              {portfolio.secondaryObjective ? (
                <div className="flex items-center gap-x-4">
                  <div className="flex h-full items-center pb-4">
                    <Icon
                      size="small"
                      color="eq-color-primary-600"
                      type={
                        ObjectiveIcon[
                          portfolio.secondaryObjective as Exclude<
                            FundObjective,
                            null
                          >
                        ]
                      }
                    />
                  </div>
                  <span className="small">
                    {
                      fundObjectives[
                        portfolio.secondaryObjective as Exclude<
                          FundObjective,
                          null
                        >
                      ]
                    }
                  </span>
                </div>
              ) : null}
            </div>
          ) : null}

          {portfolio?.shortDescription ? (
            <div className="small text-neutral-600">
              {portfolio?.shortDescription}
            </div>
          ) : null}

          {portfolio.minInitialInvestment ? (
            <div className="flex flex-col gap-y-8">
              <div className="small-strong">Minimum Investment</div>
              <div className="">
                <span className="small bg-neutral-100 px-8">
                  {formatNumberToCurrency({
                    value: portfolio.minInitialInvestment || 0,
                  })}
                </span>
              </div>
            </div>
          ) : null}

          {portfolio.minInvestorLevel ? (
            <div className="flex flex-col gap-y-8">
              <div className="small-strong">Minimum Investor Accreditation</div>
              <div className="">
                <span className="small bg-neutral-100 px-8">
                  {
                    FUND_INVESTOR_LEVEL_MAPPING[
                      portfolio.minInvestorLevel as InvestorLevel
                    ]
                  }
                </span>
              </div>
            </div>
          ) : null}
        </div>

        <div className="mt-24 flex grow flex-col justify-end">
          <div className="mt-24 flex  items-center">
            <div className="grid w-3/4  grid-cols-2 gap-y-8">
              {allocationsData.map(({ color, name, value, title }) =>
                value ? (
                  <div className="flex items-center gap-x-8" key={name}>
                    <div
                      className="size-16"
                      style={{ backgroundColor: color }}
                    />
                    <div className="flex flex-col">
                      <div className="caption">{title}</div>
                      <div className="caption">
                        {new Decimal(value)
                          .mul(100)
                          .toDecimalPlaces(2)
                          .toString()}
                        %
                      </div>
                    </div>
                  </div>
                ) : null
              )}
            </div>
            <div className="flex w-1/4 items-center justify-center">
              <PieChart
                data={allocationsData}
                label={({ title }, proportion) => (
                  <NameAndPercentageValueLabel
                    name={title}
                    value={proportion}
                  />
                )}
                chartHeight={100}
                chartWidth={100}
              />
            </div>
          </div>
        </div>
      </div>
    </article>
  )
}
