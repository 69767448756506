import { useMemo } from 'react'

import { BaseDatum } from './types'

const ε = 1e-20

export function cleanData<Datum extends BaseDatum>(data: Datum[]): Datum[] {
  // negative or zero values should not be included, picking a small positive value as threshold to avoid floating point issues (but very small values would be invisible anyway on pie chart)
  return data.filter((d) => d.value > ε)
}

export function useCleanData<Datum extends BaseDatum>(data: Datum[]) {
  return useMemo(() => cleanData(data), [data])
}
