import { useNavigate } from 'react-router-dom'

import { Icon } from '@cais-group/equity/atoms/icon'
import { FundOrderV2 } from '@cais-group/funds-trade/domain/api'
import { StructuredProductOrderExpanded } from '@cais-group/structured-products/domain/structured-product-orders-api'

import { SearchResult } from '../search-result'

const VIEW_ORDER_ROUTE = (id: string) => `/funds-pre-trade/funds/${id}`

type Props = {
  orders: FundOrderV2[]
  spOrders: StructuredProductOrderExpanded[]
  searchText?: string
}

export const Orders = ({ orders, spOrders, searchText }: Props) => {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col gap-y-8">
      <div className="small-strong">
        {orders.length + spOrders.length} orders
      </div>
      {orders.slice(0, 3).map((order) => {
        return (
          <SearchResult
            key={order.id}
            label={`Order #${order.caisId}`}
            actions={[
              {
                text: 'View Order',
                handler: () => navigate(VIEW_ORDER_ROUTE(order.id)),
              },
            ]}
            highlight={searchText}
            type="Fund Order"
            renderAvatar={() => (
              <Icon type="Document" color="eq-color-neutral-400" />
            )}
          />
        )
      })}

      {spOrders
        .slice(0, 3 + (orders?.length < 4 ? 3 - orders.length : 0))
        .map((order) => {
          return (
            <SearchResult
              key={order.order.id}
              label={`Order #${order.order.caisId}`}
              actions={[
                {
                  text: 'View Order',
                  handler: () => navigate(VIEW_ORDER_ROUTE(order.order.id)),
                },
              ]}
              highlight={searchText}
              type="SI Order"
              renderAvatar={() => (
                <Icon type="Document" color="eq-color-neutral-400" />
              )}
            />
          )
        })}
    </div>
  )
}
