import { Fragment } from 'react'

import { type MenuItemsProps } from '../types'

export const MenuItems = (props: MenuItemsProps) => {
  const { classNames, isMenuOpen, menuContents, menuId, type } = props

  // Note: this extra div is required to allow the animation to work when the menu is expanded
  const Wrapper = type === 'mobile' ? 'div' : Fragment
  const hasSubmenus = Array.isArray(menuContents)
  const submenuCount = hasSubmenus ? menuContents.length : 0
  const isHorizontal = menuId === 'manage-cais-menu-desktop' && submenuCount > 3 // Could determine based only on submenu count but will then need to consider menu going off-screen. Therefore, only apply to Manage CAIS menu for now

  return (
    <div
      id={menuId}
      className={classNames.menuItem(isHorizontal ? 'horizontal' : 'vertical')}
      aria-expanded={isMenuOpen}
      data-submenu-container
      data-layout={isHorizontal ? 'horizontal' : 'vertical'}
      style={
        isHorizontal
          ? {
              // @ts-expect-error
              '--horizontal-offset': offsetToLastColumn(submenuCount),
            }
          : {}
      }
    >
      <Wrapper>
        {isHorizontal && hasSubmenus
          ? menuContents.map((submenu, index) => (
              <div key={index}>{submenu}</div>
            ))
          : menuContents}
      </Wrapper>
    </div>
  )
}

const offsetToLastColumn = (cols: number) => `${(-100 * (cols - 1)) / cols}%`
