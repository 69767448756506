import { FeatureFlag } from '@cais-group/shared/ui/feature-flag'

import { DisclosurePageContainer as DisclosurePageContainerWithBff } from './with-bff'
import { DisclosurePageContainer as DisclosurePageContainerWithoutBff } from './without-bff'

export const DISCLOSURE_PAGE_SLUG = 'disclosure'

export function DisclosurePageContainer() {
  return (
    <FeatureFlag
      featureFlagKey="release-homepage-bff"
      alternative={<DisclosurePageContainerWithoutBff />}
    >
      <DisclosurePageContainerWithBff />
    </FeatureFlag>
  )
}
