import { Color, colors } from '@cais-group/equity/particles/colors'
import { FundObjective } from '@cais-group/funds-pre-trade/domain/api'
import {
  AssetClassLimitV1,
  PortfolioFundAllocationV1,
  PortfolioUpdateRequestV1RiskProfile,
  PortfolioWeightsV1,
  ReferenceAllocationsRequestV1RiskProfile,
} from '@cais-group/portfolio-construction-tool/domain/api'

export enum AssetClass {
  PUBLIC_EQUITY = 'PUBLIC_EQUITY',
  PUBLIC_FIXED_INCOME = 'PUBLIC_FIXED_INCOME',
  HEDGE_FUNDS = 'HEDGE_FUNDS',
  PRIVATE_EQUITY = 'PRIVATE_EQUITY',
  PRIVATE_DEBT = 'PRIVATE_DEBT',
  PRIVATE_REAL_ASSETS = 'PRIVATE_REAL_ASSETS',
}

export const AssetClassPrettyNameMapping: { [key in AssetClass]: string } = {
  [AssetClass.PUBLIC_EQUITY]: 'Public Equity',
  [AssetClass.PUBLIC_FIXED_INCOME]: 'Public Fixed Income',
  [AssetClass.HEDGE_FUNDS]: 'Hedge Funds',
  [AssetClass.PRIVATE_EQUITY]: 'Private Equity',
  [AssetClass.PRIVATE_DEBT]: 'Private Debt',
  [AssetClass.PRIVATE_REAL_ASSETS]: 'Private Real Assets',
}

export type AllocationInputs = {
  [AssetClass.PUBLIC_EQUITY]: number
  [AssetClass.PUBLIC_FIXED_INCOME]: number
  [AssetClass.HEDGE_FUNDS]: number
  [AssetClass.PRIVATE_EQUITY]: number
  [AssetClass.PRIVATE_DEBT]: number
  [AssetClass.PRIVATE_REAL_ASSETS]: number
}

export type PortfolioDraftState = {
  name: string
  allocations: AllocationInputs
  minLiquidity?: number
  riskProfile?: PortfolioUpdateRequestV1RiskProfile
  assetClassLimits?: AssetClassLimitV1[]
  fundAllocations: (PortfolioFundAllocationV1 & {
    name: string
    strategyDisplay: string
  })[]
}

export const AssetClassColorMapping: Record<AssetClass, Color> = {
  [AssetClass.PUBLIC_EQUITY]: colors['eq-color-datavis-1'] as Color,
  [AssetClass.PUBLIC_FIXED_INCOME]: colors['eq-color-datavis-2'] as Color,
  [AssetClass.HEDGE_FUNDS]: colors['eq-color-datavis-3'] as Color,
  [AssetClass.PRIVATE_EQUITY]: colors['eq-color-datavis-4'] as Color,
  [AssetClass.PRIVATE_DEBT]: colors['eq-color-datavis-20'] as Color,
  [AssetClass.PRIVATE_REAL_ASSETS]: colors['eq-color-datavis-6'] as Color,
}

export type UIAllocationInputType = {
  color: Color
  title: string
  name: AssetClass
  isAlt: boolean
  strategyDisplayNames: string[]
}

export const riskProfiles = [
  ReferenceAllocationsRequestV1RiskProfile.CONSERVATIVE_20_80,
  ReferenceAllocationsRequestV1RiskProfile.CONSERVATIVE_30_70,
  ReferenceAllocationsRequestV1RiskProfile.CONSERVATIVE_40_60,
  ReferenceAllocationsRequestV1RiskProfile.BALANCED_50_50,
  ReferenceAllocationsRequestV1RiskProfile.BALANCED_60_40,
  ReferenceAllocationsRequestV1RiskProfile.BALANCED_70_30,
  ReferenceAllocationsRequestV1RiskProfile.AGGRESSIVE_80_20,
  ReferenceAllocationsRequestV1RiskProfile.AGGRESSIVE_90_10,
  ReferenceAllocationsRequestV1RiskProfile.AGGRESSIVE_100_0,
]

export type ComparedPorfolio = {
  id: string
  indexId?: string
  label: string
  weights: (Omit<PortfolioWeightsV1, 'productType' | 'productId'> & {
    productId?: string
  })[]
}

export const modelPortfolios: Record<string, ComparedPorfolio> = {
  [ReferenceAllocationsRequestV1RiskProfile.CONSERVATIVE_20_80]: {
    id: ReferenceAllocationsRequestV1RiskProfile.CONSERVATIVE_20_80,
    label: '20/80',
    weights: [
      {
        assetClass: AssetClass.PUBLIC_EQUITY,
        weight: 0.2,
      },
      {
        assetClass: AssetClass.PUBLIC_FIXED_INCOME,
        weight: 0.8,
      },
    ],
  },
  [ReferenceAllocationsRequestV1RiskProfile.CONSERVATIVE_30_70]: {
    id: ReferenceAllocationsRequestV1RiskProfile.CONSERVATIVE_30_70,
    label: '30/70',
    weights: [
      {
        assetClass: AssetClass.PUBLIC_EQUITY,
        weight: 0.3,
      },
      {
        assetClass: AssetClass.PUBLIC_FIXED_INCOME,
        weight: 0.7,
      },
    ],
  },
  [ReferenceAllocationsRequestV1RiskProfile.CONSERVATIVE_40_60]: {
    id: ReferenceAllocationsRequestV1RiskProfile.CONSERVATIVE_40_60,
    label: '40/60',
    weights: [
      {
        assetClass: AssetClass.PUBLIC_EQUITY,
        weight: 0.4,
      },
      {
        assetClass: AssetClass.PUBLIC_FIXED_INCOME,
        weight: 0.6,
      },
    ],
  },
  [ReferenceAllocationsRequestV1RiskProfile.BALANCED_50_50]: {
    id: ReferenceAllocationsRequestV1RiskProfile.BALANCED_50_50,
    label: '50/50',
    weights: [
      {
        assetClass: AssetClass.PUBLIC_EQUITY,
        weight: 0.5,
      },
      {
        assetClass: AssetClass.PUBLIC_FIXED_INCOME,
        weight: 0.5,
      },
    ],
  },
  [ReferenceAllocationsRequestV1RiskProfile.BALANCED_60_40]: {
    id: ReferenceAllocationsRequestV1RiskProfile.BALANCED_60_40,
    label: '60/40',
    weights: [
      {
        assetClass: AssetClass.PUBLIC_EQUITY,
        weight: 0.6,
      },
      {
        assetClass: AssetClass.PUBLIC_FIXED_INCOME,
        weight: 0.4,
      },
    ],
  },
  [ReferenceAllocationsRequestV1RiskProfile.BALANCED_70_30]: {
    id: ReferenceAllocationsRequestV1RiskProfile.BALANCED_70_30,
    label: '70/30',
    weights: [
      {
        assetClass: AssetClass.PUBLIC_EQUITY,
        weight: 0.7,
      },
      {
        assetClass: AssetClass.PUBLIC_FIXED_INCOME,
        weight: 0.3,
      },
    ],
  },
  [ReferenceAllocationsRequestV1RiskProfile.AGGRESSIVE_80_20]: {
    id: ReferenceAllocationsRequestV1RiskProfile.AGGRESSIVE_80_20,
    label: '80/20',
    weights: [
      {
        assetClass: AssetClass.PUBLIC_EQUITY,
        weight: 0.8,
      },
      {
        assetClass: AssetClass.PUBLIC_FIXED_INCOME,
        weight: 0.2,
      },
    ],
  },
  [ReferenceAllocationsRequestV1RiskProfile.AGGRESSIVE_90_10]: {
    id: ReferenceAllocationsRequestV1RiskProfile.AGGRESSIVE_90_10,
    label: '90/10',
    weights: [
      {
        assetClass: AssetClass.PUBLIC_EQUITY,
        weight: 0.9,
      },
      {
        assetClass: AssetClass.PUBLIC_FIXED_INCOME,
        weight: 0.1,
      },
    ],
  },
  [ReferenceAllocationsRequestV1RiskProfile.AGGRESSIVE_100_0]: {
    id: ReferenceAllocationsRequestV1RiskProfile.AGGRESSIVE_100_0,
    label: '100/0',
    weights: [
      {
        assetClass: AssetClass.PUBLIC_EQUITY,
        weight: 1,
      },
      {
        assetClass: AssetClass.PUBLIC_FIXED_INCOME,
        weight: 0,
      },
    ],
  },
}

export const assetClassPortfolios: Record<AssetClass, ComparedPorfolio> = {
  [AssetClass.PUBLIC_EQUITY]: {
    id: AssetClass.PUBLIC_EQUITY,
    indexId: '',
    label: '',
    weights: [
      {
        assetClass: AssetClass.PUBLIC_EQUITY,
        weight: 1,
      },
    ],
  },
  [AssetClass.PUBLIC_FIXED_INCOME]: {
    id: AssetClass.PUBLIC_FIXED_INCOME,
    indexId: '',
    label: '',
    weights: [
      {
        assetClass: AssetClass.PUBLIC_FIXED_INCOME,
        weight: 1,
      },
    ],
  },
  [AssetClass.HEDGE_FUNDS]: {
    id: AssetClass.HEDGE_FUNDS,
    indexId: '',
    label: '',
    weights: [
      {
        assetClass: AssetClass.HEDGE_FUNDS,
        weight: 1,
      },
    ],
  },
  [AssetClass.PRIVATE_EQUITY]: {
    id: AssetClass.PRIVATE_EQUITY,
    indexId: '',
    label: '',
    weights: [
      {
        assetClass: AssetClass.PRIVATE_EQUITY,
        weight: 1,
      },
    ],
  },
  [AssetClass.PRIVATE_DEBT]: {
    id: AssetClass.PRIVATE_DEBT,
    indexId: '',
    label: '',
    weights: [
      {
        assetClass: AssetClass.PRIVATE_DEBT,
        weight: 1,
      },
    ],
  },
  [AssetClass.PRIVATE_REAL_ASSETS]: {
    id: AssetClass.PRIVATE_REAL_ASSETS,
    indexId: '',
    label: '',
    weights: [
      {
        assetClass: AssetClass.PRIVATE_REAL_ASSETS,
        weight: 1,
      },
    ],
  },
}

export const FundObjectiveExtended = {
  ...FundObjective,
  MULTIPLE_OBJECTIVES: 'MULTIPLE_OBJECTIVES',
} as const

export type FundObjectiveExtendedType =
  | (typeof FundObjectiveExtended)[keyof typeof FundObjectiveExtended]
  | null
