/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Fund Orders Service API
 * OpenAPI spec version: v1
 */
import { fetchInstance } from '../../../../../../all-shared/legacy-shared/util/fetch/lib/fetch'

import type {
  AccountAndFundIdsV1,
  AdvisorSalesRegionsV1,
  AdvisorSalesSubregionsV1,
  BackfillOrdersRequestV1,
  BulkUpdateFundOrdersAdminDetailsBody,
  CreateFundOrderAccountSearchRequestV1,
  CreateFundOrderCombinedResponseV1,
  CreateFundOrderFundAccountShareClassDetailsV1,
  CreateFundOrderNewOrderDetailsV1,
  CreateFundOrderRequestFidelityDetailsV1,
  CreateFundOrderRequestPershingDetailsV1,
  CreateFundOrderRequestV1,
  CreateFundOrderResponseV1,
  CreateFundOrderTeamSearchRequestV1,
  CreateFundOrderUserSearchRequestV1,
  DynamicFiltersResponseV1,
  EditFundOrderParams,
  EditFundOrderRequestV1,
  EditFundOrderTaskRequestV1,
  EtaStatusResponseV1,
  ExecuteClosesRequestV1,
  ExternalNigoCommentV1,
  FirmFeaturesResponseV1,
  FirmSettingsResponseV1,
  FundAdminReviewCommentV1,
  FundOrderAdvisorFirmExportRequestV1,
  FundOrderAndTaskFilterV1,
  FundOrderCaisOpsExportRequestV1,
  FundOrderCancellationReasonV1,
  FundOrderCloseSearchRequestV1,
  FundOrderCustodianDetailsV1,
  FundOrderDocumentStatusCloseRequestV1,
  FundOrderDocumentStatusRequestV1,
  FundOrderEventLogV1,
  FundOrderGroupSearchRequestV1,
  FundOrderInternalCommentRequestV1,
  FundOrderInternalCommentV1,
  FundOrderNotificationsV1,
  FundOrderSearchRequestV1,
  FundOrderStatsV1,
  FundOrderTaskExportRequestV1,
  FundOrderTaskRequestV1,
  FundOrderTaskSearchRequestV1,
  FundOrderTaskStatusRequestV1,
  FundOrderTaskStatusResponseV1,
  FundOrderTaskV1,
  FundOrderTransferResponseV1,
  FundOrderV1,
  FundOrderV2,
  FundOrderWireStatusRequestV1,
  FundOrdersAdminDetailsBulkUpdateResponseV1,
  FundOrdersCancellationRequestV1,
  FundOrdersCloseV1,
  FundResponseV1,
  FundSearchRequestV1,
  FundTemplateRequestV1,
  FundTemplateResponseV1,
  GetCreateFundOrderNewOrderDetailsParams,
  GetFundOrderNotificationsParams,
  GetFundOrderWithExpandedResourcesParams,
  GetFundOrderWithExpandedResourcesV2Params,
  NigoReminderResponseV1,
  OrderId,
  PagedCreateFundOrderAccountDetailsV1,
  PagedCreateFundOrderTeamDetailsV1,
  PagedCreateFundOrderUserDetailsV1,
  PagedFundOrderGroupsV1,
  PagedFundOrderTasksV1,
  PagedFundOrderTasksV2,
  PagedFundOrdersCloseV1,
  PagedFundOrdersV1,
  PagedFundOrdersV2,
  ReminderRequestV1,
  SearchFundOrdersWithExpandedResourcesParams,
  SearchFundOrdersWithExpandedResourcesV2Params,
  SearchTasksParams,
  SearchTasksV2Params,
  TaskId,
  TransferFundOrderBody,
  UpdateAdminReviewCommentRequestV1,
  UpdateFundOrderFundWireStatusV1,
  UpdateFundOrderRequestV1,
  UpdateFundOrdersCloseDateV1,
} from './api.schemas'

/**
 * @summary Publish previously created draft admin review status for one or more fund orders
 */
export const closeNigoComment = (
  fundOrderDocumentStatusCloseRequestV1: FundOrderDocumentStatusCloseRequestV1
) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/admin-review/close`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrderDocumentStatusCloseRequestV1,
  })
}

/**
 * @summary Update admin review status for one or more fund orders
 */
export const updateDocumentStatus = (
  fundOrderDocumentStatusRequestV1: FundOrderDocumentStatusRequestV1[]
) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/admin-review`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrderDocumentStatusRequestV1,
  })
}

/**
 * @summary Updates NIGO reasons and comments for a given admin review comment id
 */
export const updateFundOrderAdminReviewComment = (
  adminReviewCommentId: string,
  updateAdminReviewCommentRequestV1: UpdateAdminReviewCommentRequestV1
) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/admin-review-comments/${adminReviewCommentId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateAdminReviewCommentRequestV1,
  })
}

/**
 * @summary Retrieve NIGO reminder for a given order
 */
export const getNigoReminder = (orderId: OrderId) => {
  return fetchInstance<NigoReminderResponseV1>({
    url: `/fund-orders/v1/orders/${orderId}/reminder`,
    method: 'GET',
  })
}

/**
 * @summary Send reminder email of a specified type for a given order
 */
export const sendReminder = (
  orderId: OrderId,
  reminderRequestV1: ReminderRequestV1
) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/${orderId}/reminder`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: reminderRequestV1,
  })
}

/**
 * @summary Updates close date based on filter criteria
 */
export const updateCloseDate = (
  updateFundOrdersCloseDateV1: UpdateFundOrdersCloseDateV1
) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/close-date`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateFundOrdersCloseDateV1,
  })
}

/**
 * @summary Creates fund closes for provided funds and close dates
 */
export const executeFundsCloses = (
  executeClosesRequestV1: ExecuteClosesRequestV1
) => {
  return fetchInstance<FundOrdersCloseV1[]>({
    url: `/fund-orders/v1/orders/closes`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: executeClosesRequestV1,
  })
}

/**
 * @summary Retrieve fund close by ID
 */
export const getClose = (closeId: string) => {
  return fetchInstance<FundOrdersCloseV1>({
    url: `/fund-orders/v1/orders/closes/${closeId}`,
    method: 'GET',
  })
}

/**
 * @summary Deletes a close. 204 is returned when empty close has been deleted. An attempt to delete not empty close results in 409 response.
 */
export const deleteClose = (closeId: string) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/closes/${closeId}`,
    method: 'DELETE',
  })
}

/**
 * @summary Find orders closes matching filter criteria
 */
export const searchFundOrderCloses = (
  fundOrderCloseSearchRequestV1: FundOrderCloseSearchRequestV1
) => {
  return fetchInstance<PagedFundOrdersCloseV1>({
    url: `/fund-orders/v1/orders/closes/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrderCloseSearchRequestV1,
  })
}

/**
 * @summary Count fund orders by filter criteria
 */
export const countFundOrders = (
  fundOrderAndTaskFilterV1: FundOrderAndTaskFilterV1
) => {
  return fetchInstance<number>({
    url: `/fund-orders/v1/orders/search/count`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrderAndTaskFilterV1,
  })
}

/**
 * @summary Return a aggregation of orders grouped by requested column
 */
export const searchFundOrderGroups = (
  fundOrderGroupSearchRequestV1: FundOrderGroupSearchRequestV1
) => {
  return fetchInstance<PagedFundOrderGroupsV1>({
    url: `/fund-orders/v1/orders/search/groups`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrderGroupSearchRequestV1,
  })
}

/**
 * @summary Find fund order IDs by filter criteria
 */
export const findFundOrderIds = (
  fundOrderAndTaskFilterV1: FundOrderAndTaskFilterV1
) => {
  return fetchInstance<string[]>({
    url: `/fund-orders/v1/orders/search/ids`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrderAndTaskFilterV1,
  })
}

/**
 * @summary Retrieve statistics for fund orders by filter criteria (counts and total amounts)
 */
export const fundOrdersStats = (
  fundOrderAndTaskFilterV1: FundOrderAndTaskFilterV1
) => {
  return fetchInstance<FundOrderStatsV1>({
    url: `/fund-orders/v1/orders/search/stats`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrderAndTaskFilterV1,
  })
}

/**
 * This endpoint is deprecated. Use `/fund-orders/v2/orders/search` instead.
 * @deprecated
 * @summary Search fund orders (Deprecated)
 */
export const searchFundOrdersWithExpandedResources = (
  fundOrderSearchRequestV1: FundOrderSearchRequestV1,
  params?: SearchFundOrdersWithExpandedResourcesParams
) => {
  return fetchInstance<PagedFundOrdersV1>({
    url: `/fund-orders/v1/orders/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrderSearchRequestV1,
    params,
  })
}

/**
 * @summary Search fund orders
 */
export const searchFundOrdersWithExpandedResourcesV2 = (
  fundOrderSearchRequestV1: FundOrderSearchRequestV1,
  params?: SearchFundOrdersWithExpandedResourcesV2Params
) => {
  return fetchInstance<PagedFundOrdersV2>({
    url: `/fund-orders/v2/orders/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrderSearchRequestV1,
    params,
  })
}

/**
 * @summary Transfer order
 */
export const transferFundOrder = (
  transferFundOrderBody: TransferFundOrderBody
) => {
  const formData = new FormData()
  formData.append('request', JSON.stringify(transferFundOrderBody.request))
  if (transferFundOrderBody.file !== undefined) {
    formData.append('file', transferFundOrderBody.file)
  }

  return fetchInstance<FundOrderTransferResponseV1>({
    url: `/fund-orders/v1/orders/transfer`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  })
}

/**
 * @summary Export file with funds orders for a given filter
 */
export const downloadOrdersForCaisOps = (
  fundOrderCaisOpsExportRequestV1: FundOrderCaisOpsExportRequestV1
) => {
  return fetchInstance<Blob>({
    url: `/fund-orders/v1/orders/export/cais-ops`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrderCaisOpsExportRequestV1,
    responseType: 'blob',
  })
}

/**
 * @summary Export file with orders for a given filter
 */
export const downloadOrdersForAdvisor = (
  fundOrderAdvisorFirmExportRequestV1: FundOrderAdvisorFirmExportRequestV1
) => {
  return fetchInstance<Blob>({
    url: `/fund-orders/v1/orders/export/advisor-firm`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrderAdvisorFirmExportRequestV1,
    responseType: 'blob',
  })
}

/**
 * @summary Update wire status for one or more fund orders
 */
export const updateWireStatus = (
  fundOrderWireStatusRequestV1: FundOrderWireStatusRequestV1
) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/wire-status`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrderWireStatusRequestV1,
  })
}

/**
 * @summary Retrieve all Fund Order Events for a given order
 */
export const getFundOrderEventLogs = (orderId: OrderId) => {
  return fetchInstance<FundOrderEventLogV1[]>({
    url: `/fund-orders/v1/orders/${orderId}/event-logs`,
    method: 'GET',
  })
}

/**
 * @summary Retrieve all current review comments of a Fund Order
 */
export const getFundOrderReviewComments = (orderId: OrderId) => {
  return fetchInstance<FundAdminReviewCommentV1[]>({
    url: `/fund-orders/v1/orders/${orderId}/review-comments`,
    method: 'GET',
  })
}

/**
 * @summary Delete open NIGO comment.
 */
export const deleteOpenReviewComment = (orderId: OrderId) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/${orderId}/review-comments`,
    method: 'DELETE',
  })
}

/**
 * @summary Set trade services attention flag
 */
export const enableTradeServicesAttention = (orderId: OrderId) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/${orderId}/review-comments/trade-attention`,
    method: 'POST',
  })
}

/**
 * @summary Dismiss trade services attention flag
 */
export const dismissTradeServicesAttention = (orderId: OrderId) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/${orderId}/review-comments/trade-attention`,
    method: 'DELETE',
  })
}

/**
 * @summary Retrieve all current external review comments of a Fund Order
 */
export const getFundOrderExternalReviewComments = (orderId: OrderId) => {
  return fetchInstance<ExternalNigoCommentV1[]>({
    url: `/fund-orders/v1/orders/${orderId}/external-review-comments`,
    method: 'GET',
  })
}

/**
 * @summary Update fund order fund wire status and date
 */
export const updateFundOrderFundWireStatus = (
  orderId: OrderId,
  updateFundOrderFundWireStatusV1: UpdateFundOrderFundWireStatusV1
) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/${orderId}/wire-status`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateFundOrderFundWireStatusV1,
  })
}

/**
 * This endpoint is deprecated. Use `/fund-orders/v2/orders/{orderId}` instead.
 * @deprecated
 * @summary Retrieve a Fund Order (Deprecated)
 */
export const getFundOrderWithExpandedResources = (
  orderId: OrderId,
  params?: GetFundOrderWithExpandedResourcesParams
) => {
  return fetchInstance<FundOrderV1>({
    url: `/fund-orders/v1/orders/${orderId}`,
    method: 'GET',
    params,
  })
}

/**
 * @summary Updates a Fund Order
 */
export const updateFundOrder = (
  orderId: OrderId,
  updateFundOrderRequestV1: UpdateFundOrderRequestV1
) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/${orderId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: updateFundOrderRequestV1,
  })
}

/**
 * @summary Retrieve a Fund Order
 */
export const getFundOrderWithExpandedResourcesV2 = (
  orderId: OrderId,
  params?: GetFundOrderWithExpandedResourcesV2Params
) => {
  return fetchInstance<FundOrderV2>({
    url: `/fund-orders/v2/orders/${orderId}`,
    method: 'GET',
    params,
  })
}

/**
 * @summary Process KYC changes for a given order
 */
export const processKycChanges = (orderId: OrderId) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/${orderId}/process-kyc-changes`,
    method: 'POST',
  })
}

/**
 * @summary Cancels (deletes) fund order by id
 */
export const cancelFundOrder = (
  orderId: OrderId,
  fundOrderCancellationReasonV1: FundOrderCancellationReasonV1
) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/${orderId}/cancel`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrderCancellationReasonV1,
  })
}

/**
 * @summary Cancels (deletes) fund orders by ids
 */
export const cancelFundOrders = (
  fundOrdersCancellationRequestV1: FundOrdersCancellationRequestV1
) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/cancel`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrdersCancellationRequestV1,
  })
}

/**
 * @summary Restores (un-deletes) fund order by id
 */
export const restoreFundOrder = (orderId: OrderId) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/${orderId}/restore`,
    method: 'POST',
  })
}

/**
 * @summary Retrieve PDF document tags used in documents for a given order
 */
export const getFundOrderRequiredTags = (orderId: OrderId) => {
  return fetchInstance<string[]>({
    url: `/fund-orders/v1/orders/${orderId}/required-tags`,
    method: 'GET',
  })
}

/**
 * @summary Retrieve notifications of a certain type for a given order
 */
export const getFundOrderNotifications = (
  orderId: OrderId,
  params: GetFundOrderNotificationsParams
) => {
  return fetchInstance<FundOrderNotificationsV1>({
    url: `/fund-orders/v1/orders/${orderId}/notifications`,
    method: 'GET',
    params,
  })
}

/**
 * @summary Retrieve all advisor sales regions
 */
export const getAdvisorSalesRegions = () => {
  return fetchInstance<AdvisorSalesRegionsV1>({
    url: `/fund-orders/v1/regions`,
    method: 'GET',
  })
}

/**
 * @summary Retrieve all advisor sales subregions
 */
export const getAdvisorSalesSubregions = () => {
  return fetchInstance<AdvisorSalesSubregionsV1>({
    url: `/fund-orders/v1/subregions`,
    method: 'GET',
  })
}

/**
 * @summary Run the process to complete orders that are due for completion
 */
export const processOrdersDueForCompletion = () => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/admin/process-order-completion`,
    method: 'POST',
  })
}

/**
 * @summary Run the process to backfill orders from Sales Force to portal and OMS
 */
export const backfillOrdersFromSalesForce = (
  backfillOrdersRequestV1: BackfillOrdersRequestV1
) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/admin/backfill-from-sales-force`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: backfillOrdersRequestV1,
  })
}

/**
 * @summary Run the process to reconcile order close dates with executed closes
 */
export const reconcileOrderCloses = () => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/admin/reconcile-order-closes`,
    method: 'POST',
  })
}

/**
 * @summary Run the process to send email notifications for pending wires due
 */
export const sendWireDueNotifications = () => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/admin/send-wire-due-notifications`,
    method: 'POST',
  })
}

/**
 * @summary Run the process to copy OMS nigo reasons to portal
 */
export const populateNigoReasonsInPortal = () => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/admin/orders/nigo-reasons/portal-migration`,
    method: 'POST',
  })
}

/**
 * @summary Run the process to send NIGO summary email
 */
export const triggerNigoSummary = () => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/admin/orders/nigo-summary`,
    method: 'POST',
  })
}

/**
 * @summary Retrieve account details and eligibility required for new order creation
 */
export const searchCreateFundOrderAccountDetails = (
  createFundOrderAccountSearchRequestV1: CreateFundOrderAccountSearchRequestV1
) => {
  return fetchInstance<PagedCreateFundOrderAccountDetailsV1>({
    url: `/fund-orders/v1/orders/account-details`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createFundOrderAccountSearchRequestV1,
  })
}

/**
 * @summary Retrieve user details and education status required for new order creation
 */
export const searchCreateFundOrderUserDetails = (
  createFundOrderUserSearchRequestV1: CreateFundOrderUserSearchRequestV1
) => {
  return fetchInstance<PagedCreateFundOrderUserDetailsV1>({
    url: `/fund-orders/v1/orders/user-details`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createFundOrderUserSearchRequestV1,
  })
}

/**
 * @summary Retrieve team details and education status required for new order creation
 */
export const searchCreateFundOrderTeamDetails = (
  createFundOrderTeamSearchRequestV1: CreateFundOrderTeamSearchRequestV1
) => {
  return fetchInstance<PagedCreateFundOrderTeamDetailsV1>({
    url: `/fund-orders/v1/orders/team-details`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createFundOrderTeamSearchRequestV1,
  })
}

/**
 * @summary Retrieve share class details required for new order creation
 */
export const searchCreateFundOrderShareClasses = (
  accountAndFundIdsV1: AccountAndFundIdsV1[]
) => {
  return fetchInstance<CreateFundOrderFundAccountShareClassDetailsV1[]>({
    url: `/fund-orders/v1/orders/share-class-details`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: accountAndFundIdsV1,
  })
}

/**
 * @summary Retrieve firm-specific details required for new order creation
 */
export const getCreateFundOrderNewOrderDetails = (
  params: GetCreateFundOrderNewOrderDetailsParams
) => {
  return fetchInstance<CreateFundOrderNewOrderDetailsV1>({
    url: `/fund-orders/v1/orders/new-order-details`,
    method: 'GET',
    params,
  })
}

/**
 * @summary Create a new fund order
 */
export const createFundOrder = (
  createFundOrderRequestV1: CreateFundOrderRequestV1
) => {
  return fetchInstance<CreateFundOrderResponseV1>({
    url: `/fund-orders/v1/orders`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createFundOrderRequestV1,
  })
}

/**
 * @summary Create a new fund order
 */
export const createFundOrders = (
  createFundOrderRequestV1: CreateFundOrderRequestV1[]
) => {
  return fetchInstance<CreateFundOrderCombinedResponseV1[]>({
    url: `/fund-orders/v1/orders/batch`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createFundOrderRequestV1,
  })
}

/**
 * @summary Edit an existing Fund Order
 */
export const editFundOrder = (
  orderId: OrderId,
  editFundOrderRequestV1: EditFundOrderRequestV1,
  params?: EditFundOrderParams
) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/edit/${orderId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: editFundOrderRequestV1,
    params,
  })
}

/**
 * @summary Get custodian details for an existing Fund Order
 */
export const getOrderCustodianDetails = (orderId: OrderId) => {
  return fetchInstance<FundOrderCustodianDetailsV1>({
    url: `/fund-orders/v1/orders/${orderId}/custodian-details`,
    method: 'GET',
  })
}

/**
 * @summary Add fidelity details to an existing Fund Order
 */
export const addFidelityDetails = (
  orderId: OrderId,
  createFundOrderRequestFidelityDetailsV1: CreateFundOrderRequestFidelityDetailsV1
) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/${orderId}/fidelity-details`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: createFundOrderRequestFidelityDetailsV1,
  })
}

/**
 * @summary Add pershing details to an existing Fund Order
 */
export const addPershingDetails = (
  orderId: OrderId,
  createFundOrderRequestPershingDetailsV1: CreateFundOrderRequestPershingDetailsV1
) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/${orderId}/pershing-details`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: createFundOrderRequestPershingDetailsV1,
  })
}

/**
 * @summary Retrieve all current internal comments of a Fund Order
 */
export const getFundOrderInternalComments = (orderId: OrderId) => {
  return fetchInstance<FundOrderInternalCommentV1[]>({
    url: `/fund-orders/v1/orders/${orderId}/internal-comments`,
    method: 'GET',
  })
}

/**
 * @summary Create internal comments for a Fund Order
 */
export const createFundOrderInternalComments = (
  orderId: OrderId,
  fundOrderInternalCommentRequestV1: FundOrderInternalCommentRequestV1[]
) => {
  return fetchInstance<FundOrderInternalCommentV1[]>({
    url: `/fund-orders/v1/orders/${orderId}/internal-comments`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrderInternalCommentRequestV1,
  })
}

/**
 * @summary Update internal comment for a Fund Order
 */
export const updateFundOrderInternalComment = (
  orderId: OrderId,
  internalCommentId: string,
  fundOrderInternalCommentRequestV1: FundOrderInternalCommentRequestV1
) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/${orderId}/internal-comments/${internalCommentId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrderInternalCommentRequestV1,
  })
}

/**
 * @summary Delete internal comment for a Fund Order
 */
export const deleteFundOrderInternalComment = (
  orderId: OrderId,
  internalCommentId: string
) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/${orderId}/internal-comments/${internalCommentId}`,
    method: 'DELETE',
  })
}

/**
 * @summary Get tasks for a Fund Order
 */
export const getFundOrderTasks = (orderId: OrderId) => {
  return fetchInstance<FundOrderTaskV1[]>({
    url: `/fund-orders/v1/orders/${orderId}/tasks`,
    method: 'GET',
  })
}

/**
 * @summary Create task for a Fund Order
 */
export const createFundOrderTask = (
  orderId: OrderId,
  fundOrderTaskRequestV1: FundOrderTaskRequestV1
) => {
  return fetchInstance<FundOrderTaskV1>({
    url: `/fund-orders/v1/orders/${orderId}/tasks`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrderTaskRequestV1,
  })
}

/**
 * @summary Delete task for a Fund Order
 */
export const deleteFundOrderTask = (orderId: OrderId, taskId: TaskId) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/orders/${orderId}/tasks/${taskId}`,
    method: 'DELETE',
  })
}

/**
 * @summary Edit task for a Fund Order
 */
export const editFundOrderTask = (
  orderId: OrderId,
  taskId: TaskId,
  editFundOrderTaskRequestV1: EditFundOrderTaskRequestV1
) => {
  return fetchInstance<FundOrderTaskV1>({
    url: `/fund-orders/v1/orders/${orderId}/tasks/${taskId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: editFundOrderTaskRequestV1,
  })
}

/**
 * @summary Update status for tasks
 */
export const updateTasksStatus = (
  fundOrderTaskStatusRequestV1: FundOrderTaskStatusRequestV1
) => {
  return fetchInstance<FundOrderTaskStatusResponseV1>({
    url: `/fund-orders/v1/tasks/status`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrderTaskStatusRequestV1,
  })
}

/**
 * This endpoint is deprecated. Use `/fund-orders/v2/tasks/search` instead.
 * @deprecated
 * @summary Search tasks by criteria (Deprecated)
 */
export const searchTasks = (
  fundOrderTaskSearchRequestV1: FundOrderTaskSearchRequestV1,
  params?: SearchTasksParams
) => {
  return fetchInstance<PagedFundOrderTasksV1>({
    url: `/fund-orders/v1/tasks/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrderTaskSearchRequestV1,
    params,
  })
}

/**
 * @summary Search tasks by criteria
 */
export const searchTasksV2 = (
  fundOrderTaskSearchRequestV1: FundOrderTaskSearchRequestV1,
  params?: SearchTasksV2Params
) => {
  return fetchInstance<PagedFundOrderTasksV2>({
    url: `/fund-orders/v2/tasks/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrderTaskSearchRequestV1,
    params,
  })
}

/**
 * @summary Export list of tasks matching criteria
 */
export const exportTasks = (
  fundOrderTaskExportRequestV1: FundOrderTaskExportRequestV1
) => {
  return fetchInstance<Blob>({
    url: `/fund-orders/v1/tasks/export`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrderTaskExportRequestV1,
    responseType: 'blob',
  })
}

/**
 * Bulk update order fund admin related information by uploading a trade blotter file
 */
export const bulkUpdateFundOrdersAdminDetails = (
  bulkUpdateFundOrdersAdminDetailsBody: BulkUpdateFundOrdersAdminDetailsBody
) => {
  const formData = new FormData()
  formData.append('file', bulkUpdateFundOrdersAdminDetailsBody.file)

  return fetchInstance<FundOrdersAdminDetailsBulkUpdateResponseV1>({
    url: `/fund-orders/v1/orders/admin/bulk-update`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  })
}

/**
 * Retrieve ETA status
 */
export const getEtaStatus = (firmId: string) => {
  return fetchInstance<EtaStatusResponseV1>({
    url: `/fund-orders/v1/eta/status/${firmId}`,
    method: 'GET',
  })
}

/**
 * Retrieve firm features
 * @summary Retrieve firm features
 */
export const getFirmFeatures = (firmId: string) => {
  return fetchInstance<FirmFeaturesResponseV1>({
    url: `/fund-orders/v1/firms/${firmId}/features`,
    method: 'GET',
  })
}

/**
 * Retrieve firm settings
 * @summary Retrieve firm settings
 */
export const getFirmSettings = (firmId: string) => {
  return fetchInstance<FirmSettingsResponseV1>({
    url: `/fund-orders/v1/firms/${firmId}/settings`,
    method: 'GET',
  })
}

/**
 * Search for funds eligible for closes
 */
export const searchFunds = (fundSearchRequestV1: FundSearchRequestV1) => {
  return fetchInstance<FundResponseV1[]>({
    url: `/fund-orders/v1/funds/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundSearchRequestV1,
  })
}

/**
 * Returns a set of data for composing dynamic filters whose state depends on previously selected filter
 */
export const dynamicFilters = (
  fundOrderAndTaskFilterV1: FundOrderAndTaskFilterV1
) => {
  return fetchInstance<DynamicFiltersResponseV1>({
    url: `/fund-orders/v1/filters`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundOrderAndTaskFilterV1,
  })
}

/**
 * @summary Retrieve all templates for Fund
 */
export const searchFundTemplatesByFundId = (fundId: number) => {
  return fetchInstance<FundTemplateResponseV1[]>({
    url: `/fund-orders/v1/admin/fund-template/search/${fundId}`,
    method: 'GET',
  })
}

/**
 * @summary Create template for a Fund
 */
export const createFundTemplate = (
  fundTemplateRequestV1: FundTemplateRequestV1
) => {
  return fetchInstance<FundTemplateResponseV1>({
    url: `/fund-orders/v1/admin/fund-template`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: fundTemplateRequestV1,
  })
}

/**
 * @summary Update template for a Fund
 */
export const updateFundTemplate = (
  fundTemplateId: number,
  fundTemplateRequestV1: FundTemplateRequestV1
) => {
  return fetchInstance<FundTemplateResponseV1>({
    url: `/fund-orders/v1/admin/fund-template/${fundTemplateId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: fundTemplateRequestV1,
  })
}

/**
 * @summary Delete template for a Fund
 */
export const deleteFundTemplate = (fundTemplateId: number) => {
  return fetchInstance<void>({
    url: `/fund-orders/v1/admin/fund-template/${fundTemplateId}`,
    method: 'DELETE',
  })
}

export type CloseNigoCommentResult = NonNullable<
  Awaited<ReturnType<typeof closeNigoComment>>
>
export type UpdateDocumentStatusResult = NonNullable<
  Awaited<ReturnType<typeof updateDocumentStatus>>
>
export type UpdateFundOrderAdminReviewCommentResult = NonNullable<
  Awaited<ReturnType<typeof updateFundOrderAdminReviewComment>>
>
export type GetNigoReminderResult = NonNullable<
  Awaited<ReturnType<typeof getNigoReminder>>
>
export type SendReminderResult = NonNullable<
  Awaited<ReturnType<typeof sendReminder>>
>
export type UpdateCloseDateResult = NonNullable<
  Awaited<ReturnType<typeof updateCloseDate>>
>
export type ExecuteFundsClosesResult = NonNullable<
  Awaited<ReturnType<typeof executeFundsCloses>>
>
export type GetCloseResult = NonNullable<Awaited<ReturnType<typeof getClose>>>
export type DeleteCloseResult = NonNullable<
  Awaited<ReturnType<typeof deleteClose>>
>
export type SearchFundOrderClosesResult = NonNullable<
  Awaited<ReturnType<typeof searchFundOrderCloses>>
>
export type CountFundOrdersResult = NonNullable<
  Awaited<ReturnType<typeof countFundOrders>>
>
export type SearchFundOrderGroupsResult = NonNullable<
  Awaited<ReturnType<typeof searchFundOrderGroups>>
>
export type FindFundOrderIdsResult = NonNullable<
  Awaited<ReturnType<typeof findFundOrderIds>>
>
export type FundOrdersStatsResult = NonNullable<
  Awaited<ReturnType<typeof fundOrdersStats>>
>
export type SearchFundOrdersWithExpandedResourcesResult = NonNullable<
  Awaited<ReturnType<typeof searchFundOrdersWithExpandedResources>>
>
export type SearchFundOrdersWithExpandedResourcesV2Result = NonNullable<
  Awaited<ReturnType<typeof searchFundOrdersWithExpandedResourcesV2>>
>
export type TransferFundOrderResult = NonNullable<
  Awaited<ReturnType<typeof transferFundOrder>>
>
export type DownloadOrdersForCaisOpsResult = NonNullable<
  Awaited<ReturnType<typeof downloadOrdersForCaisOps>>
>
export type DownloadOrdersForAdvisorResult = NonNullable<
  Awaited<ReturnType<typeof downloadOrdersForAdvisor>>
>
export type UpdateWireStatusResult = NonNullable<
  Awaited<ReturnType<typeof updateWireStatus>>
>
export type GetFundOrderEventLogsResult = NonNullable<
  Awaited<ReturnType<typeof getFundOrderEventLogs>>
>
export type GetFundOrderReviewCommentsResult = NonNullable<
  Awaited<ReturnType<typeof getFundOrderReviewComments>>
>
export type DeleteOpenReviewCommentResult = NonNullable<
  Awaited<ReturnType<typeof deleteOpenReviewComment>>
>
export type EnableTradeServicesAttentionResult = NonNullable<
  Awaited<ReturnType<typeof enableTradeServicesAttention>>
>
export type DismissTradeServicesAttentionResult = NonNullable<
  Awaited<ReturnType<typeof dismissTradeServicesAttention>>
>
export type GetFundOrderExternalReviewCommentsResult = NonNullable<
  Awaited<ReturnType<typeof getFundOrderExternalReviewComments>>
>
export type UpdateFundOrderFundWireStatusResult = NonNullable<
  Awaited<ReturnType<typeof updateFundOrderFundWireStatus>>
>
export type GetFundOrderWithExpandedResourcesResult = NonNullable<
  Awaited<ReturnType<typeof getFundOrderWithExpandedResources>>
>
export type UpdateFundOrderResult = NonNullable<
  Awaited<ReturnType<typeof updateFundOrder>>
>
export type GetFundOrderWithExpandedResourcesV2Result = NonNullable<
  Awaited<ReturnType<typeof getFundOrderWithExpandedResourcesV2>>
>
export type ProcessKycChangesResult = NonNullable<
  Awaited<ReturnType<typeof processKycChanges>>
>
export type CancelFundOrderResult = NonNullable<
  Awaited<ReturnType<typeof cancelFundOrder>>
>
export type CancelFundOrdersResult = NonNullable<
  Awaited<ReturnType<typeof cancelFundOrders>>
>
export type RestoreFundOrderResult = NonNullable<
  Awaited<ReturnType<typeof restoreFundOrder>>
>
export type GetFundOrderRequiredTagsResult = NonNullable<
  Awaited<ReturnType<typeof getFundOrderRequiredTags>>
>
export type GetFundOrderNotificationsResult = NonNullable<
  Awaited<ReturnType<typeof getFundOrderNotifications>>
>
export type GetAdvisorSalesRegionsResult = NonNullable<
  Awaited<ReturnType<typeof getAdvisorSalesRegions>>
>
export type GetAdvisorSalesSubregionsResult = NonNullable<
  Awaited<ReturnType<typeof getAdvisorSalesSubregions>>
>
export type ProcessOrdersDueForCompletionResult = NonNullable<
  Awaited<ReturnType<typeof processOrdersDueForCompletion>>
>
export type BackfillOrdersFromSalesForceResult = NonNullable<
  Awaited<ReturnType<typeof backfillOrdersFromSalesForce>>
>
export type ReconcileOrderClosesResult = NonNullable<
  Awaited<ReturnType<typeof reconcileOrderCloses>>
>
export type SendWireDueNotificationsResult = NonNullable<
  Awaited<ReturnType<typeof sendWireDueNotifications>>
>
export type PopulateNigoReasonsInPortalResult = NonNullable<
  Awaited<ReturnType<typeof populateNigoReasonsInPortal>>
>
export type TriggerNigoSummaryResult = NonNullable<
  Awaited<ReturnType<typeof triggerNigoSummary>>
>
export type SearchCreateFundOrderAccountDetailsResult = NonNullable<
  Awaited<ReturnType<typeof searchCreateFundOrderAccountDetails>>
>
export type SearchCreateFundOrderUserDetailsResult = NonNullable<
  Awaited<ReturnType<typeof searchCreateFundOrderUserDetails>>
>
export type SearchCreateFundOrderTeamDetailsResult = NonNullable<
  Awaited<ReturnType<typeof searchCreateFundOrderTeamDetails>>
>
export type SearchCreateFundOrderShareClassesResult = NonNullable<
  Awaited<ReturnType<typeof searchCreateFundOrderShareClasses>>
>
export type GetCreateFundOrderNewOrderDetailsResult = NonNullable<
  Awaited<ReturnType<typeof getCreateFundOrderNewOrderDetails>>
>
export type CreateFundOrderResult = NonNullable<
  Awaited<ReturnType<typeof createFundOrder>>
>
export type CreateFundOrdersResult = NonNullable<
  Awaited<ReturnType<typeof createFundOrders>>
>
export type EditFundOrderResult = NonNullable<
  Awaited<ReturnType<typeof editFundOrder>>
>
export type GetOrderCustodianDetailsResult = NonNullable<
  Awaited<ReturnType<typeof getOrderCustodianDetails>>
>
export type AddFidelityDetailsResult = NonNullable<
  Awaited<ReturnType<typeof addFidelityDetails>>
>
export type AddPershingDetailsResult = NonNullable<
  Awaited<ReturnType<typeof addPershingDetails>>
>
export type GetFundOrderInternalCommentsResult = NonNullable<
  Awaited<ReturnType<typeof getFundOrderInternalComments>>
>
export type CreateFundOrderInternalCommentsResult = NonNullable<
  Awaited<ReturnType<typeof createFundOrderInternalComments>>
>
export type UpdateFundOrderInternalCommentResult = NonNullable<
  Awaited<ReturnType<typeof updateFundOrderInternalComment>>
>
export type DeleteFundOrderInternalCommentResult = NonNullable<
  Awaited<ReturnType<typeof deleteFundOrderInternalComment>>
>
export type GetFundOrderTasksResult = NonNullable<
  Awaited<ReturnType<typeof getFundOrderTasks>>
>
export type CreateFundOrderTaskResult = NonNullable<
  Awaited<ReturnType<typeof createFundOrderTask>>
>
export type DeleteFundOrderTaskResult = NonNullable<
  Awaited<ReturnType<typeof deleteFundOrderTask>>
>
export type EditFundOrderTaskResult = NonNullable<
  Awaited<ReturnType<typeof editFundOrderTask>>
>
export type UpdateTasksStatusResult = NonNullable<
  Awaited<ReturnType<typeof updateTasksStatus>>
>
export type SearchTasksResult = NonNullable<
  Awaited<ReturnType<typeof searchTasks>>
>
export type SearchTasksV2Result = NonNullable<
  Awaited<ReturnType<typeof searchTasksV2>>
>
export type ExportTasksResult = NonNullable<
  Awaited<ReturnType<typeof exportTasks>>
>
export type BulkUpdateFundOrdersAdminDetailsResult = NonNullable<
  Awaited<ReturnType<typeof bulkUpdateFundOrdersAdminDetails>>
>
export type GetEtaStatusResult = NonNullable<
  Awaited<ReturnType<typeof getEtaStatus>>
>
export type GetFirmFeaturesResult = NonNullable<
  Awaited<ReturnType<typeof getFirmFeatures>>
>
export type GetFirmSettingsResult = NonNullable<
  Awaited<ReturnType<typeof getFirmSettings>>
>
export type SearchFundsResult = NonNullable<
  Awaited<ReturnType<typeof searchFunds>>
>
export type DynamicFiltersResult = NonNullable<
  Awaited<ReturnType<typeof dynamicFilters>>
>
export type SearchFundTemplatesByFundIdResult = NonNullable<
  Awaited<ReturnType<typeof searchFundTemplatesByFundId>>
>
export type CreateFundTemplateResult = NonNullable<
  Awaited<ReturnType<typeof createFundTemplate>>
>
export type UpdateFundTemplateResult = NonNullable<
  Awaited<ReturnType<typeof updateFundTemplate>>
>
export type DeleteFundTemplateResult = NonNullable<
  Awaited<ReturnType<typeof deleteFundTemplate>>
>
