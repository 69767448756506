/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Experience Manager API
 * API for Theme and Content Management
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'

import { fetchInstanceExperienceManager } from '../fetch'

import type {
  Asset,
  AssetLabel,
  ErrorResponseV1,
  FirmExperienceRequestV1,
  FirmExperienceV1,
  GetFirmAssets200,
  GetFirmMessages200,
  Message,
  MessageLabel,
  MessageResponseV1,
  PatchRequestBodyBody,
  TableSettingsRequestV1,
  TableSettingsV1,
  ThemeDetailsV1,
  ThemeRequestV1,
  ThemesV1,
  UploadOrUpdateFirmAssetBody,
} from './api.schemas'

/**
 * Retrieve firm experience details for a given firm
 */
export const getExperienceForFirm = (firmId: string, signal?: AbortSignal) => {
  return fetchInstanceExperienceManager<FirmExperienceV1>({
    url: `/api/experience-manager/v1/firms/${firmId}`,
    method: 'GET',
    signal,
  })
}

export const getGetExperienceForFirmQueryKey = (firmId: string) => {
  return [`/api/experience-manager/v1/firms/${firmId}`] as const
}

export const getGetExperienceForFirmQueryOptions = <
  TData = Awaited<ReturnType<typeof getExperienceForFirm>>,
  TError = ErrorResponseV1
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getExperienceForFirm>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetExperienceForFirmQueryKey(firmId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getExperienceForFirm>>
  > = ({ signal }) => getExperienceForFirm(firmId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!firmId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getExperienceForFirm>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetExperienceForFirmQueryResult = NonNullable<
  Awaited<ReturnType<typeof getExperienceForFirm>>
>
export type GetExperienceForFirmQueryError = ErrorResponseV1

export const useGetExperienceForFirm = <
  TData = Awaited<ReturnType<typeof getExperienceForFirm>>,
  TError = ErrorResponseV1
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getExperienceForFirm>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetExperienceForFirmQueryOptions(firmId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Create firm experience for a given firm
 */
export const createExperienceForFirm = (
  firmId: string,
  firmExperienceRequestV1: FirmExperienceRequestV1
) => {
  return fetchInstanceExperienceManager<FirmExperienceV1>({
    url: `/api/experience-manager/v1/firms/${firmId}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: firmExperienceRequestV1,
  })
}

export const getCreateExperienceForFirmMutationOptions = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createExperienceForFirm>>,
    TError,
    { firmId: string; data: FirmExperienceRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createExperienceForFirm>>,
  TError,
  { firmId: string; data: FirmExperienceRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createExperienceForFirm>>,
    { firmId: string; data: FirmExperienceRequestV1 }
  > = (props) => {
    const { firmId, data } = props ?? {}

    return createExperienceForFirm(firmId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateExperienceForFirmMutationResult = NonNullable<
  Awaited<ReturnType<typeof createExperienceForFirm>>
>
export type CreateExperienceForFirmMutationBody = FirmExperienceRequestV1
export type CreateExperienceForFirmMutationError = ErrorResponseV1

export const useCreateExperienceForFirm = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createExperienceForFirm>>,
    TError,
    { firmId: string; data: FirmExperienceRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreateExperienceForFirmMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Update firm experience for a given firm
 */
export const updateExperienceForFirm = (
  firmId: string,
  patchRequestBodyBody: PatchRequestBodyBody
) => {
  return fetchInstanceExperienceManager<FirmExperienceV1>({
    url: `/api/experience-manager/v1/firms/${firmId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchRequestBodyBody,
  })
}

export const getUpdateExperienceForFirmMutationOptions = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateExperienceForFirm>>,
    TError,
    { firmId: string; data: PatchRequestBodyBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateExperienceForFirm>>,
  TError,
  { firmId: string; data: PatchRequestBodyBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateExperienceForFirm>>,
    { firmId: string; data: PatchRequestBodyBody }
  > = (props) => {
    const { firmId, data } = props ?? {}

    return updateExperienceForFirm(firmId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateExperienceForFirmMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateExperienceForFirm>>
>
export type UpdateExperienceForFirmMutationBody = PatchRequestBodyBody
export type UpdateExperienceForFirmMutationError = ErrorResponseV1

export const useUpdateExperienceForFirm = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateExperienceForFirm>>,
    TError,
    { firmId: string; data: PatchRequestBodyBody },
    TContext
  >
}) => {
  const mutationOptions = getUpdateExperienceForFirmMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve all assets urls for a given firm
 */
export const getFirmAssets = (firmId: string, signal?: AbortSignal) => {
  return fetchInstanceExperienceManager<GetFirmAssets200>({
    url: `/api/experience-manager/v1/firms/${firmId}/assets`,
    method: 'GET',
    signal,
  })
}

export const getGetFirmAssetsQueryKey = (firmId: string) => {
  return [`/api/experience-manager/v1/firms/${firmId}/assets`] as const
}

export const getGetFirmAssetsQueryOptions = <
  TData = Awaited<ReturnType<typeof getFirmAssets>>,
  TError = ErrorResponseV1
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmAssets>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetFirmAssetsQueryKey(firmId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFirmAssets>>> = ({
    signal,
  }) => getFirmAssets(firmId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!firmId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFirmAssets>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFirmAssetsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFirmAssets>>
>
export type GetFirmAssetsQueryError = ErrorResponseV1

export const useGetFirmAssets = <
  TData = Awaited<ReturnType<typeof getFirmAssets>>,
  TError = ErrorResponseV1
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmAssets>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFirmAssetsQueryOptions(firmId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Retrieve all messages for a given firm
 */
export const getFirmMessages = (firmId: string, signal?: AbortSignal) => {
  return fetchInstanceExperienceManager<GetFirmMessages200>({
    url: `/api/experience-manager/v1/firms/${firmId}/messages`,
    method: 'GET',
    signal,
  })
}

export const getGetFirmMessagesQueryKey = (firmId: string) => {
  return [`/api/experience-manager/v1/firms/${firmId}/messages`] as const
}

export const getGetFirmMessagesQueryOptions = <
  TData = Awaited<ReturnType<typeof getFirmMessages>>,
  TError = ErrorResponseV1
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmMessages>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetFirmMessagesQueryKey(firmId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFirmMessages>>> = ({
    signal,
  }) => getFirmMessages(firmId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!firmId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFirmMessages>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFirmMessagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFirmMessages>>
>
export type GetFirmMessagesQueryError = ErrorResponseV1

export const useGetFirmMessages = <
  TData = Awaited<ReturnType<typeof getFirmMessages>>,
  TError = ErrorResponseV1
>(
  firmId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFirmMessages>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFirmMessagesQueryOptions(firmId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Create or Updates a firm asset for a given firm
 */
export const uploadOrUpdateFirmAsset = (
  firmId: string,
  assetLabel: AssetLabel,
  uploadOrUpdateFirmAssetBody: UploadOrUpdateFirmAssetBody
) => {
  const formData = new FormData()
  formData.append('file', uploadOrUpdateFirmAssetBody.file)

  return fetchInstanceExperienceManager<Asset>({
    url: `/api/experience-manager/v1/firms/${firmId}/assets/${assetLabel}`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  })
}

export const getUploadOrUpdateFirmAssetMutationOptions = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadOrUpdateFirmAsset>>,
    TError,
    {
      firmId: string
      assetLabel: AssetLabel
      data: UploadOrUpdateFirmAssetBody
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadOrUpdateFirmAsset>>,
  TError,
  { firmId: string; assetLabel: AssetLabel; data: UploadOrUpdateFirmAssetBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadOrUpdateFirmAsset>>,
    {
      firmId: string
      assetLabel: AssetLabel
      data: UploadOrUpdateFirmAssetBody
    }
  > = (props) => {
    const { firmId, assetLabel, data } = props ?? {}

    return uploadOrUpdateFirmAsset(firmId, assetLabel, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UploadOrUpdateFirmAssetMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadOrUpdateFirmAsset>>
>
export type UploadOrUpdateFirmAssetMutationBody = UploadOrUpdateFirmAssetBody
export type UploadOrUpdateFirmAssetMutationError = ErrorResponseV1

export const useUploadOrUpdateFirmAsset = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadOrUpdateFirmAsset>>,
    TError,
    {
      firmId: string
      assetLabel: AssetLabel
      data: UploadOrUpdateFirmAssetBody
    },
    TContext
  >
}) => {
  const mutationOptions = getUploadOrUpdateFirmAssetMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Delete a asset for a given firm
 */
export const deleteFirmAsset = (firmId: string, assetLabel: AssetLabel) => {
  return fetchInstanceExperienceManager<void>({
    url: `/api/experience-manager/v1/firms/${firmId}/assets/${assetLabel}`,
    method: 'DELETE',
  })
}

export const getDeleteFirmAssetMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFirmAsset>>,
    TError,
    { firmId: string; assetLabel: AssetLabel },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteFirmAsset>>,
  TError,
  { firmId: string; assetLabel: AssetLabel },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteFirmAsset>>,
    { firmId: string; assetLabel: AssetLabel }
  > = (props) => {
    const { firmId, assetLabel } = props ?? {}

    return deleteFirmAsset(firmId, assetLabel)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteFirmAssetMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteFirmAsset>>
>

export type DeleteFirmAssetMutationError = unknown

export const useDeleteFirmAsset = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFirmAsset>>,
    TError,
    { firmId: string; assetLabel: AssetLabel },
    TContext
  >
}) => {
  const mutationOptions = getDeleteFirmAssetMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve a message for a given firm
 */
export const getMessage = (
  firmId: string,
  messageLabel: string,
  signal?: AbortSignal
) => {
  return fetchInstanceExperienceManager<MessageResponseV1>({
    url: `/api/experience-manager/v1/firms/${firmId}/messages/${messageLabel}/`,
    method: 'GET',
    signal,
  })
}

export const getGetMessageQueryKey = (firmId: string, messageLabel: string) => {
  return [
    `/api/experience-manager/v1/firms/${firmId}/messages/${messageLabel}/`,
  ] as const
}

export const getGetMessageQueryOptions = <
  TData = Awaited<ReturnType<typeof getMessage>>,
  TError = ErrorResponseV1
>(
  firmId: string,
  messageLabel: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMessage>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetMessageQueryKey(firmId, messageLabel)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMessage>>> = ({
    signal,
  }) => getMessage(firmId, messageLabel, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(firmId && messageLabel),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getMessage>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetMessageQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMessage>>
>
export type GetMessageQueryError = ErrorResponseV1

export const useGetMessage = <
  TData = Awaited<ReturnType<typeof getMessage>>,
  TError = ErrorResponseV1
>(
  firmId: string,
  messageLabel: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMessage>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMessageQueryOptions(firmId, messageLabel, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Create or update a label and message attached to the firm with the corresponding firm-id
 */
export const createOrUpdateMessage = (
  firmId: string,
  messageLabel: string,
  message: Message
) => {
  return fetchInstanceExperienceManager<MessageResponseV1>({
    url: `/api/experience-manager/v1/firms/${firmId}/messages/${messageLabel}/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: message,
  })
}

export const getCreateOrUpdateMessageMutationOptions = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOrUpdateMessage>>,
    TError,
    { firmId: string; messageLabel: string; data: Message },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createOrUpdateMessage>>,
  TError,
  { firmId: string; messageLabel: string; data: Message },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createOrUpdateMessage>>,
    { firmId: string; messageLabel: string; data: Message }
  > = (props) => {
    const { firmId, messageLabel, data } = props ?? {}

    return createOrUpdateMessage(firmId, messageLabel, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateOrUpdateMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof createOrUpdateMessage>>
>
export type CreateOrUpdateMessageMutationBody = Message
export type CreateOrUpdateMessageMutationError = ErrorResponseV1

export const useCreateOrUpdateMessage = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createOrUpdateMessage>>,
    TError,
    { firmId: string; messageLabel: string; data: Message },
    TContext
  >
}) => {
  const mutationOptions = getCreateOrUpdateMessageMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Delete a label and message
 */
export const deleteMessage = (firmId: string, messageLabel: MessageLabel) => {
  return fetchInstanceExperienceManager<void>({
    url: `/api/experience-manager/v1/firms/${firmId}/messages/${messageLabel}/`,
    method: 'DELETE',
  })
}

export const getDeleteMessageMutationOptions = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMessage>>,
    TError,
    { firmId: string; messageLabel: MessageLabel },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteMessage>>,
  TError,
  { firmId: string; messageLabel: MessageLabel },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteMessage>>,
    { firmId: string; messageLabel: MessageLabel }
  > = (props) => {
    const { firmId, messageLabel } = props ?? {}

    return deleteMessage(firmId, messageLabel)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteMessageMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteMessage>>
>

export type DeleteMessageMutationError = ErrorResponseV1

export const useDeleteMessage = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMessage>>,
    TError,
    { firmId: string; messageLabel: MessageLabel },
    TContext
  >
}) => {
  const mutationOptions = getDeleteMessageMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve all themes
 */
export const getThemes = (signal?: AbortSignal) => {
  return fetchInstanceExperienceManager<ThemesV1>({
    url: `/api/experience-manager/v1/branding/themes`,
    method: 'GET',
    signal,
  })
}

export const getGetThemesQueryKey = () => {
  return [`/api/experience-manager/v1/branding/themes`] as const
}

export const getGetThemesQueryOptions = <
  TData = Awaited<ReturnType<typeof getThemes>>,
  TError = ErrorResponseV1
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getThemes>>, TError, TData>
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetThemesQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getThemes>>> = ({
    signal,
  }) => getThemes(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getThemes>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetThemesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getThemes>>
>
export type GetThemesQueryError = ErrorResponseV1

export const useGetThemes = <
  TData = Awaited<ReturnType<typeof getThemes>>,
  TError = ErrorResponseV1
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getThemes>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetThemesQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Create a theme
 */
export const createTheme = (themeRequestV1: ThemeRequestV1) => {
  return fetchInstanceExperienceManager<ThemeDetailsV1>({
    url: `/api/experience-manager/v1/branding/themes`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: themeRequestV1,
  })
}

export const getCreateThemeMutationOptions = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTheme>>,
    TError,
    { data: ThemeRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createTheme>>,
  TError,
  { data: ThemeRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTheme>>,
    { data: ThemeRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return createTheme(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateThemeMutationResult = NonNullable<
  Awaited<ReturnType<typeof createTheme>>
>
export type CreateThemeMutationBody = ThemeRequestV1
export type CreateThemeMutationError = ErrorResponseV1

export const useCreateTheme = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTheme>>,
    TError,
    { data: ThemeRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreateThemeMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve theme details for a given theme
 */
export const getTheme = (themeId: string, signal?: AbortSignal) => {
  return fetchInstanceExperienceManager<ThemeDetailsV1>({
    url: `/api/experience-manager/v1/branding/themes/${themeId}`,
    method: 'GET',
    signal,
  })
}

export const getGetThemeQueryKey = (themeId: string) => {
  return [`/api/experience-manager/v1/branding/themes/${themeId}`] as const
}

export const getGetThemeQueryOptions = <
  TData = Awaited<ReturnType<typeof getTheme>>,
  TError = ErrorResponseV1
>(
  themeId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTheme>>, TError, TData>
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetThemeQueryKey(themeId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTheme>>> = ({
    signal,
  }) => getTheme(themeId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!themeId,
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getTheme>>, TError, TData> & {
    queryKey: QueryKey
  }
}

export type GetThemeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTheme>>
>
export type GetThemeQueryError = ErrorResponseV1

export const useGetTheme = <
  TData = Awaited<ReturnType<typeof getTheme>>,
  TError = ErrorResponseV1
>(
  themeId: string,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getTheme>>, TError, TData>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetThemeQueryOptions(themeId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Update a theme
 */
export const updateTheme = (
  themeId: string,
  themeRequestV1: ThemeRequestV1
) => {
  return fetchInstanceExperienceManager<ThemeDetailsV1>({
    url: `/api/experience-manager/v1/branding/themes/${themeId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: themeRequestV1,
  })
}

export const getUpdateThemeMutationOptions = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTheme>>,
    TError,
    { themeId: string; data: ThemeRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTheme>>,
  TError,
  { themeId: string; data: ThemeRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTheme>>,
    { themeId: string; data: ThemeRequestV1 }
  > = (props) => {
    const { themeId, data } = props ?? {}

    return updateTheme(themeId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateThemeMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTheme>>
>
export type UpdateThemeMutationBody = ThemeRequestV1
export type UpdateThemeMutationError = ErrorResponseV1

export const useUpdateTheme = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTheme>>,
    TError,
    { themeId: string; data: ThemeRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getUpdateThemeMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Delete a theme
 */
export const deleteTheme = (themeId: string) => {
  return fetchInstanceExperienceManager<void>({
    url: `/api/experience-manager/v1/branding/themes/${themeId}`,
    method: 'DELETE',
  })
}

export const getDeleteThemeMutationOptions = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTheme>>,
    TError,
    { themeId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteTheme>>,
  TError,
  { themeId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteTheme>>,
    { themeId: string }
  > = (props) => {
    const { themeId } = props ?? {}

    return deleteTheme(themeId)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteThemeMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTheme>>
>

export type DeleteThemeMutationError = ErrorResponseV1

export const useDeleteTheme = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTheme>>,
    TError,
    { themeId: string },
    TContext
  >
}) => {
  const mutationOptions = getDeleteThemeMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve all settings for a given table
 */
export const getAllTableSettings = (tableId: string, signal?: AbortSignal) => {
  return fetchInstanceExperienceManager<TableSettingsV1[]>({
    url: `/api/experience-manager/v1/table-settings/${tableId}`,
    method: 'GET',
    signal,
  })
}

export const getGetAllTableSettingsQueryKey = (tableId: string) => {
  return [`/api/experience-manager/v1/table-settings/${tableId}`] as const
}

export const getGetAllTableSettingsQueryOptions = <
  TData = Awaited<ReturnType<typeof getAllTableSettings>>,
  TError = ErrorResponseV1
>(
  tableId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAllTableSettings>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetAllTableSettingsQueryKey(tableId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getAllTableSettings>>
  > = ({ signal }) => getAllTableSettings(tableId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!tableId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getAllTableSettings>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetAllTableSettingsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAllTableSettings>>
>
export type GetAllTableSettingsQueryError = ErrorResponseV1

export const useGetAllTableSettings = <
  TData = Awaited<ReturnType<typeof getAllTableSettings>>,
  TError = ErrorResponseV1
>(
  tableId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getAllTableSettings>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetAllTableSettingsQueryOptions(tableId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Create a table settings
 */
export const createTableSettings = (
  tableId: string,
  tableSettingsRequestV1: TableSettingsRequestV1
) => {
  return fetchInstanceExperienceManager<TableSettingsV1>({
    url: `/api/experience-manager/v1/table-settings/${tableId}`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: tableSettingsRequestV1,
  })
}

export const getCreateTableSettingsMutationOptions = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTableSettings>>,
    TError,
    { tableId: string; data: TableSettingsRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createTableSettings>>,
  TError,
  { tableId: string; data: TableSettingsRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTableSettings>>,
    { tableId: string; data: TableSettingsRequestV1 }
  > = (props) => {
    const { tableId, data } = props ?? {}

    return createTableSettings(tableId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateTableSettingsMutationResult = NonNullable<
  Awaited<ReturnType<typeof createTableSettings>>
>
export type CreateTableSettingsMutationBody = TableSettingsRequestV1
export type CreateTableSettingsMutationError = ErrorResponseV1

export const useCreateTableSettings = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTableSettings>>,
    TError,
    { tableId: string; data: TableSettingsRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getCreateTableSettingsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Retrieve a specific set of table settings
 */
export const getTableSettings = (
  tableId: string,
  settingsId: string,
  signal?: AbortSignal
) => {
  return fetchInstanceExperienceManager<TableSettingsV1>({
    url: `/api/experience-manager/v1/table-settings/${tableId}/${settingsId}`,
    method: 'GET',
    signal,
  })
}

export const getGetTableSettingsQueryKey = (
  tableId: string,
  settingsId: string
) => {
  return [
    `/api/experience-manager/v1/table-settings/${tableId}/${settingsId}`,
  ] as const
}

export const getGetTableSettingsQueryOptions = <
  TData = Awaited<ReturnType<typeof getTableSettings>>,
  TError = ErrorResponseV1
>(
  tableId: string,
  settingsId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTableSettings>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetTableSettingsQueryKey(tableId, settingsId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getTableSettings>>
  > = ({ signal }) => getTableSettings(tableId, settingsId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(tableId && settingsId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getTableSettings>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetTableSettingsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTableSettings>>
>
export type GetTableSettingsQueryError = ErrorResponseV1

export const useGetTableSettings = <
  TData = Awaited<ReturnType<typeof getTableSettings>>,
  TError = ErrorResponseV1
>(
  tableId: string,
  settingsId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getTableSettings>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTableSettingsQueryOptions(
    tableId,
    settingsId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Update table settings
 */
export const updateTableSettings = (
  tableId: string,
  settingsId: string,
  tableSettingsRequestV1: TableSettingsRequestV1
) => {
  return fetchInstanceExperienceManager<TableSettingsV1>({
    url: `/api/experience-manager/v1/table-settings/${tableId}/${settingsId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: tableSettingsRequestV1,
  })
}

export const getUpdateTableSettingsMutationOptions = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTableSettings>>,
    TError,
    { tableId: string; settingsId: string; data: TableSettingsRequestV1 },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTableSettings>>,
  TError,
  { tableId: string; settingsId: string; data: TableSettingsRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTableSettings>>,
    { tableId: string; settingsId: string; data: TableSettingsRequestV1 }
  > = (props) => {
    const { tableId, settingsId, data } = props ?? {}

    return updateTableSettings(tableId, settingsId, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateTableSettingsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTableSettings>>
>
export type UpdateTableSettingsMutationBody = TableSettingsRequestV1
export type UpdateTableSettingsMutationError = ErrorResponseV1

export const useUpdateTableSettings = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTableSettings>>,
    TError,
    { tableId: string; settingsId: string; data: TableSettingsRequestV1 },
    TContext
  >
}) => {
  const mutationOptions = getUpdateTableSettingsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Delete table settings
 */
export const deleteTableSettings = (tableId: string, settingsId: string) => {
  return fetchInstanceExperienceManager<void>({
    url: `/api/experience-manager/v1/table-settings/${tableId}/${settingsId}`,
    method: 'DELETE',
  })
}

export const getDeleteTableSettingsMutationOptions = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTableSettings>>,
    TError,
    { tableId: string; settingsId: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteTableSettings>>,
  TError,
  { tableId: string; settingsId: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteTableSettings>>,
    { tableId: string; settingsId: string }
  > = (props) => {
    const { tableId, settingsId } = props ?? {}

    return deleteTableSettings(tableId, settingsId)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteTableSettingsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTableSettings>>
>

export type DeleteTableSettingsMutationError = ErrorResponseV1

export const useDeleteTableSettings = <
  TError = ErrorResponseV1,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTableSettings>>,
    TError,
    { tableId: string; settingsId: string },
    TContext
  >
}) => {
  const mutationOptions = getDeleteTableSettingsMutationOptions(options)

  return useMutation(mutationOptions)
}
