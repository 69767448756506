import { ReactComponent as AccountsIcon } from './assets/accounts.svg'
import { ReactComponent as AlternativesIcon } from './assets/alternatives.svg'
import { ReactComponent as AssetManagersIcon } from './assets/asset-managers.svg'
import { ReactComponent as BankIssuersIcon } from './assets/bank-issuers.svg'
import { ReactComponent as CaisIQIcon } from './assets/cais-iq.svg'
import { ReactComponent as CaisSolutionsIcon } from './assets/cais-solutions.svg'
import { ReactComponent as CapitalPreservationIcon } from './assets/capital-preservation.svg'
import { ReactComponent as CompassIcon } from './assets/compass.svg'
import { ReactComponent as CustomFundsIcon } from './assets/custom-funds.svg'
import { ReactComponent as DashboardIcon } from './assets/dashboard.svg'
import { ReactComponent as DiversificationIcon } from './assets/diversification.svg'
import { ReactComponent as EnterpriseIcon } from './assets/enterprise.svg'
import { ReactComponent as MarketplaceIcon } from './assets/marketplace.svg'
import { ReactComponent as ModelPortfoliosIcon } from './assets/model-portfolios.svg'
import { ReactComponent as MonetizationIcon } from './assets/monetization.svg'
import { ReactComponent as StructuredNotesIcon } from './assets/structured-notes.svg'
import { ReactComponent as TaxEfficiencyIcon } from './assets/tax-efficiency.svg'
import { ReactComponent as TradeIcon } from './assets/trade.svg'
import { ReactComponent as TutorialsIcon } from './assets/tutorials.svg'
import { ReactComponent as WebinarsIcon } from './assets/webinars.svg'
import { BrandIconType } from './brand-icons'

type Icon = [string, typeof AccountsIcon]

export const brandIconsMapping: Record<BrandIconType, Icon> = {
  Accounts: ['Accounts', AccountsIcon],
  Alternatives: ['Alternatives', AlternativesIcon],
  AssetManagers: ['AssetManagers', AssetManagersIcon],
  BankIssuers: ['BankIssuers', BankIssuersIcon],
  CaisIQ: ['CaisIQ', CaisIQIcon],
  CaisSolutions: ['CaisSolutions', CaisSolutionsIcon],
  CapitalPreservation: ['CapitalPreservation', CapitalPreservationIcon],
  Compass: ['Compass', CompassIcon],
  CustomFunds: ['CustomFunds', CustomFundsIcon],
  Dashboard: ['Dashboard', DashboardIcon],
  Diversification: ['Diversification', DiversificationIcon],
  Enterprise: ['Enterprise', EnterpriseIcon],
  Marketplace: ['Marketplace', MarketplaceIcon],
  ModelPortfolios: ['ModelPortfolios', ModelPortfoliosIcon],
  Monetization: ['Monetization', MonetizationIcon],
  StructuredNotes: ['StructuredNotes', StructuredNotesIcon],
  Trade: ['Trade', TradeIcon],
  TaxEfficiency: ['TaxEfficiency', TaxEfficiencyIcon],
  Tutorials: ['Tutorials', TutorialsIcon],
  Webinars: ['Webinars', WebinarsIcon],
}
