import { AxiosProgressEvent, AxiosRequestConfig } from 'axios'

import { fetchInstance } from '@cais-group/shared/util/fetch'

export async function fetchWithOverrides<T>(
  config: AxiosRequestConfig,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
) {
  return fetchInstance<T>({
    ...config,
    onUploadProgress,
  })
}
