import { useNavigate } from 'react-router-dom'

import { TeamV1 } from '@cais-group/access-manager/domain/api'
import { Icon } from '@cais-group/equity/atoms/icon'
import { HighlightedText } from '@cais-group/equity/molecules/list-item'

const VIEW_TEAM_ROUTE = (firmId: string, teamId: string) =>
  `/access-manager/firms/${firmId}/teams/${teamId}`

type Props = {
  teams: TeamV1[]
  searchText?: string
}

export const Teams = ({ teams, searchText }: Props) => {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col">
      <div className="mb-12">Teams</div>

      <div className="grid grid-cols-4 gap-24 [@media(min-width:2080px)]:grid-cols-5">
        {teams.map((team) => {
          return (
            <div
              key={team.id}
              className="hover:shadow-2 flex cursor-pointer items-center gap-x-8 border-2 px-24 py-16"
              onClick={() =>
                navigate(VIEW_TEAM_ROUTE(team.firmId ?? '', team.id))
              }
            >
              <div className="flex size-[42px] items-center justify-center rounded-full border-2 bg-neutral-100">
                <Icon type="Teams" color="eq-color-neutral-400" />
              </div>
              <div>
                <div className="body-strong">
                  <HighlightedText
                    isSearchable
                    textToHighlight={searchText}
                    inputText={team.name}
                    includeWrapper
                  />
                </div>
                <div className="small text-neutral-600">
                  {team?.parent?.parentName}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
