import { useNavigate } from 'react-router-dom'

import { Icon } from '@cais-group/equity/atoms/icon'
import { PortfolioListItemV1 } from '@cais-group/portfolio-construction-tool/domain/api'

import { SearchResult } from '../search-result'

const VIEW_PORTFOLIO_ROUTE = (id: string) => `/cais-compass/portfolios/${id}`

type Props = {
  portfolios: PortfolioListItemV1[]
  searchText?: string
}

export const Portfolios = ({ portfolios, searchText }: Props) => {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col gap-y-8">
      <div className="small-strong">{portfolios.length} portfolios</div>
      {portfolios.slice(0, 5).map((portfolio) => {
        return (
          <SearchResult
            key={portfolio.id}
            label={portfolio.name}
            actions={[
              {
                text: 'View Portfolio',
                handler: () => navigate(VIEW_PORTFOLIO_ROUTE(portfolio.id)),
              },
            ]}
            highlight={searchText}
            type="Portfolio"
            renderAvatar={() => (
              <Icon type="BarChart" color="eq-color-neutral-400" />
            )}
          />
        )
      })}
    </div>
  )
}
