import cx from 'classnames'
import type { PropsWithChildren } from 'react'

const layoutMap = {
  gap: {
    16: 'gap-16',
    32: 'gap-32',
  },
  itemMinWidth: {
    small: 'md:[--min-width:280px]',
    medium: 'md:[--min-width:360px]',
    large: 'md:[--min-width:480px]',
    mediumToLarge: 'md:[--min-width:360px] xl:[--min-width:480px]',
  },
  repeat: {
    fill: '[--repeat-type:auto-fill]',
    fit: '[--repeat-type:auto-fit]',
  },
}

export type AutoGridProps = PropsWithChildren<{
  gap?: keyof typeof layoutMap.gap
  itemMinWidth?: keyof typeof layoutMap.itemMinWidth
  repeat?: keyof typeof layoutMap.repeat
}>

export function AutoGrid(props: AutoGridProps) {
  const { gap = 32, repeat = 'fill', itemMinWidth = 'medium' } = props
  const gapCssVar = layoutMap.gap[gap]
  const itemMinWidthCssVar = layoutMap.itemMinWidth[itemMinWidth]
  const repeatCssVar = layoutMap.repeat[repeat]
  return (
    <div
      className={cx(
        `grid grid-cols-1 sm:grid-cols-[repeat(var(--repeat-type),minmax(min(var(--min-width),100%),1fr))]`,
        `${gapCssVar} ${itemMinWidthCssVar} ${repeatCssVar}`
      )}
    >
      {props.children}
    </div>
  )
}
