import {
  TooltipWithBounds,
  defaultStyles as defaultTooltipStyles,
} from '@visx/tooltip'
import { ReactNode } from 'react'

import { BaseDatum, ItemWithSizing } from '../types'

export function SliceTooltip<Datum extends BaseDatum>({
  items,
  id,
  tooltipLabel,
}: {
  tooltipLabel?: (d: Datum, proportion: number) => ReactNode
  items: ItemWithSizing<Datum>[]
  id: string | undefined
}) {
  if (id === undefined || !tooltipLabel) return null

  const item = items.find((item) => item.item.id === id)
  if (!item) return null

  return (
    <TooltipWithBounds
      top={item.centroid[1]}
      left={item.centroid[0]}
      style={{
        ...defaultTooltipStyles,
        background: 'rgb(var(--colors-neutral-900))',
        color: 'rgb(var(--colors-neutral-0))',
      }}
    >
      {tooltipLabel(item.item, item.proportion)}
    </TooltipWithBounds>
  )
}
