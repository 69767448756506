import cx from 'classnames'
import { ReactNode } from 'react'

import { percentageFormatter } from '../helpers/percentageFormatter'

type CustomFormatter = (value: number | null | undefined) => string

export function NameAndPercentageValueLabel({
  name,
  value,
  lineClamp = 1,
  rightHandSide = true,
  customFormatter,
}: {
  name: string | ReactNode
  value: number
  // If user wants 2 they will want a fairly low max labels per side
  lineClamp?: 1 | 2
  rightHandSide?: boolean
  customFormatter?: CustomFormatter
}) {
  const formatter = customFormatter ?? percentageFormatter
  const formattedValue = formatter(value)

  return (
    <>
      <p
        className={cx('small-strong', {
          'line-clamp-1': lineClamp === 1,
          'line-clamp-2': lineClamp === 2,
          'text-left': rightHandSide,
          'text-right': !rightHandSide,
        })}
        data-testid="pie-chart-item-label"
      >
        {name}
      </p>
      <p className="small text-neutral-600" data-testid="pie-chart-item-value">
        {formattedValue}
      </p>
    </>
  )
}
