import { ReactNode } from 'react'
import { match } from 'ts-pattern'

import {
  LoadingContainer,
  LoadingState,
} from '@cais-group/approved/ui/loading-container'
import { TrackingAttributes } from '@cais-group/equity/util/tracking-utils'
import {
  PageErrorSplash,
  Main,
} from '@cais-group/shared/ui/contentful/components'
import { DocumentTitle } from '@cais-group/shared/util/document-title'

export interface PageTemplateProps<T> {
  status: 'loading' | 'error' | 'success' | 'idle'
  accessStatus?: 'granted' | 'denied' | 'not-found'
  data?: T
  title?: string | null
  fullWidth?: boolean
  trackingAttributes?: TrackingAttributes
  loadingComponent?: ReactNode
  renderContent: (data: T) => ReactNode
}

export const PageTemplate = <T,>({
  status,
  accessStatus,
  data,
  title,
  fullWidth = true,
  trackingAttributes,
  loadingComponent,
  renderContent,
}: PageTemplateProps<T>) => {
  const renderLoader = () =>
    loadingComponent ?? (
      <LoadingContainer
        state={LoadingState.LOADING}
        coverPage="FULL_SCREEN_WITH_HEADER"
        type="large"
      />
    )

  return (
    <>
      {title && <DocumentTitle title={title} />}

      <Main fullWidth={fullWidth} trackingAttributes={trackingAttributes}>
        {match({ status, accessStatus, data })
          .with({ status: 'loading' }, () => renderLoader())
          .with({ status: 'error' }, () => <PageErrorSplash.General />)
          .with({ accessStatus: 'not-found' }, () => (
            <PageErrorSplash.NotReached />
          ))
          .with({ accessStatus: 'denied' }, () => <PageErrorSplash.NoAccess />)
          .with(
            {
              status: 'success',
              accessStatus: 'granted',
            },
            ({ data }) => (data ? renderContent(data) : null)
          )
          .otherwise(() => (
            <PageErrorSplash.NotFound />
          ))}
      </Main>
    </>
  )
}
