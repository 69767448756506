import {
  CurrencyCode,
  DueDateType,
  FundFrequency,
  FundOfferingType,
  FundRedemptionGateAdminV1FrequencyType,
  FundRedemptionGateAdminV1Type,
  FundAdvisoryType,
  FundFeeExpenseBase,
  FundBrokerageFeesAdminV1PlacementFeeCalculationMethod,
  FundFeePaymentFrequency,
  FundFeeExpenseType,
  FundLockUpAdminV1Period,
  InvestorLevel,
  InvestorType,
  TaxReporting,
  FundTermsAdminV1BasisOfAccounting,
  FundingStructure,
  FundSubscriptionStatus,
  FundReportingStatus,
  FundReportingSubStatus,
  FundGeneralInformationAdminV1LegalStructure,
  FundGeneralInformationAdminV1Jurisdiction,
  FundType,
  FundGeneralInformationAdminV1GeographicFocusItem,
  FundAccess,
  FundSecurityType,
  FundGeneralInformationAdminV1SecuritySubType,
  FundraisingType,
  FundSubStrategy,
  FundStrategy,
  FundStructureType,
  FundStructureSubType,
  MercerRating,
  EsgRating,
  FundStage,
  FundGeneralInformationAdminV1RegisteredFundIndicator,
  FundUnitizedIndicator,
  FundTransactionSupportAdminV1ElectronicSignatureTypeItem,
  FundProductAdminV1ShareClassIndicator,
  FundShareClassTermsAdminV1WaterFallType,
  FundLockUpAdminV1Type,
  FundGeneralInformationAdminV1CapabilitiesItem,
  FundObjective,
  FundCloseDateScheduleType,
  CalendarCloseDateScheduleAllOfSchedule,
  CalendarCloseDateScheduleAllOfCutOffPoint,
  FundOffsetDateType,
  Approval,
  FundPublishState,
  FundSolutionType,
  FundSolutionOfferingType,
} from '@cais-group/funds-pre-trade/domain/api'

import { toMapping, toOptions, toPartialMapping } from './utils'

export const FUND_OFFERING_TYPE_MAPPING: Record<FundOfferingType, string> = {
  [FundOfferingType.MARKETPLACE]: 'Marketplace',
  [FundOfferingType.NON_MARKETPLACE]: 'Non-Marketplace',
}

export const FUND_SOLUTION_TYPE_MAPPING = toMapping(FundSolutionType)
export const FUND_SOLUTION_OFFERING_TYPE_MAPPING = toMapping(
  FundSolutionOfferingType
)

export const FUND_FREQUENCY_MAPPING: Record<FundFrequency, string> = {
  [FundFrequency.DAILY]: 'Daily',
  [FundFrequency.WEEKLY]: 'Weekly',
  [FundFrequency.SEMI_MONTHLY]: 'Semi-Monthly',
  [FundFrequency.MONTHLY]: 'Monthly',
  [FundFrequency.QUARTERLY]: 'Quarterly',
  [FundFrequency.SEMI_ANNUALLY]: 'Semi-Annually',
  [FundFrequency.ANNUALLY]: 'Annually',
  [FundFrequency.AD_HOC]: 'Ad hoc',
  [FundFrequency.NOT_APPLICABLE]: 'N/A',
}

export const CURRENCY_CODE_MAPPING = toMapping(CurrencyCode)
export const FUND_DUE_DATE_TYPE: Record<DueDateType, string> = {
  [DueDateType.SPECIFIC_DATE]: 'Specific Date',
  [DueDateType.OFFSET_FROM_INVESTMENT_DATE]: 'Offset from Investment Date',
  [DueDateType.TBD]: 'TBD',
  [DueDateType.DUE_DAILY]: 'Daily',
}
export const FUND_LEGACY_DUE_DATE_TYPE: Record<DueDateType, string> = {
  [DueDateType.SPECIFIC_DATE]: 'Specific Date',
  [DueDateType.OFFSET_FROM_INVESTMENT_DATE]: 'Offset from Investment Date',
  [DueDateType.TBD]: 'N/A',
  [DueDateType.DUE_DAILY]: 'Daily',
}

export const REDEMPTION_GATE_TYPE_MAPPING = toMapping(
  FundRedemptionGateAdminV1Type
)
export const FUND_REDEMPTION_GATE_FREQUENCY_TYPE_MAPPING = toMapping(
  FundRedemptionGateAdminV1FrequencyType
)
export const FUND_ADVISORY_TYPE_MAPPING: Record<FundAdvisoryType, string> = {
  [FundAdvisoryType.BROKERAGE]: 'Brokerage',
  [FundAdvisoryType.ADVISORY]: 'Advisory',
}
export const FUND_PLACEMENT_FEE_CALCULATION_METHOD_MAPPING: Record<
  Exclude<FundBrokerageFeesAdminV1PlacementFeeCalculationMethod, null>,
  string
> = {
  [FundBrokerageFeesAdminV1PlacementFeeCalculationMethod.NET]: 'Net',
  [FundBrokerageFeesAdminV1PlacementFeeCalculationMethod.GROSS]: 'Gross',
}
export const FUND_FEE_EXPENSE_BASE_MAPPING: Record<FundFeeExpenseBase, string> =
  {
    [FundFeeExpenseBase.NET_ASSET_VALUE]: 'Net Asset Value (NAV)',
    [FundFeeExpenseBase.GROSS_ASSET_VALUE]: 'Gross Asset Value',
    [FundFeeExpenseBase.COMMITMENT]: 'Commitment',
    [FundFeeExpenseBase.UNFUNDED_COMMITMENT]: 'Unfunded Commitment',
    [FundFeeExpenseBase.NET_INVESTED_CAPITAL]: 'Net Invested Capital',
    [FundFeeExpenseBase.GROSS_INVESTED_CAPITAL]: 'Gross Invested Capital',
    [FundFeeExpenseBase.COLLATERALIZED_LOAN_ASSETS]:
      'Collateralized Loan (CLO) Assets',
  }

export const FUND_FEE_PAYMENT_FREQUENCY_MAPPING: Record<
  FundFeePaymentFrequency,
  string
> = {
  [FundFeePaymentFrequency.MONTHLY_IN_ADVANCE]: 'Monthly in Advance',
  [FundFeePaymentFrequency.MONTHLY_IN_ARREARS]: 'Monthly in Arrears',
  [FundFeePaymentFrequency.QUARTERLY_IN_ADVANCE]: 'Quarterly in Advance',
  [FundFeePaymentFrequency.QUARTERLY_IN_ARREARS]: 'Quarterly in Arrears',
  [FundFeePaymentFrequency.SEMI_ANNUALLY_IN_ADVANCE]:
    'Semi-annually in Advance',
  [FundFeePaymentFrequency.SEMI_ANNUALLY_IN_ARREARS]:
    'Semi-annually in Arrears',
  [FundFeePaymentFrequency.ANNUALLY_IN_ADVANCE]: 'Annually in Advance',
  [FundFeePaymentFrequency.ANNUALLY_IN_ARREARS]: 'Annually in Arrears',
}

export const FUND_FEE_EXPENSE_TYPE_MAPPING = toMapping(FundFeeExpenseType)
export const LOCK_UP_PERIOD_MAPPING: Record<
  Exclude<FundLockUpAdminV1Period, null>,
  string
> = {
  [FundLockUpAdminV1Period.ROLLING]: 'Rolling',
  [FundLockUpAdminV1Period.FIXED]: 'Fixed',
}
export const FUND_INVESTOR_LEVEL_MAPPING: Record<InvestorLevel, string> = {
  [InvestorLevel.NON_ACCREDITED]: 'Non-Accredited',
  [InvestorLevel.ACCREDITED_INVESTOR]: 'Accredited Investor',
  [InvestorLevel.QUALIFIED_CLIENT]: 'Qualified Client',
  [InvestorLevel.QUALIFIED_PURCHASER]: 'Qualified Purchaser',
}
export const FUND_INVESTOR_TYPE_MAPPING: Record<InvestorType, string> = {
  [InvestorType.NON_US]: 'Non-U.S.',
  [InvestorType.US_TAXABLE]: 'U.S. Taxable',
  [InvestorType.US_TAX_EXEMPT]: 'U.S. Tax Exempt',
}
export const FUND_TAX_REPORTING_MAPPING: Record<TaxReporting, string> = {
  [TaxReporting.FORM_1099]: '1099',
  [TaxReporting.K1_K3]: 'K-1 / K-3',
  [TaxReporting.PFIC]: 'PFIC',
  [TaxReporting.NA]: 'N/A',
}
export const BASIS_OF_FUND_ACCOUNTING_MAPPING: Record<
  Exclude<FundTermsAdminV1BasisOfAccounting, null>,
  string
> = {
  [FundTermsAdminV1BasisOfAccounting.US_GAAP]: 'US GAAP',
  [FundTermsAdminV1BasisOfAccounting.IFRS]: 'IFRS',
  [FundTermsAdminV1BasisOfAccounting.LUX_GAAP]: 'LUX GAAP',
  [FundTermsAdminV1BasisOfAccounting.OTHER]: 'Other',
}
export const FUNDING_STRUCTURE_MAPPING: Record<FundingStructure, string> = {
  [FundingStructure.DRAWDOWN]: 'Drawdown',
  [FundingStructure.FULLY_FUNDED]: 'Fully-Funded',
}
export const FUND_SUBSCRIPTION_STATUS_MAPPING: Record<
  FundSubscriptionStatus,
  string
> = {
  [FundSubscriptionStatus.OPEN]: 'Open',
  [FundSubscriptionStatus.CLOSED]: 'Closed',
  [FundSubscriptionStatus.WAIT_LIST]: 'Wait List',
  [FundSubscriptionStatus.PENDING]: 'Pending',
  [FundSubscriptionStatus.ONBOARDING]: 'Onboarding',
}
export const FUND_REPORTING_STATUS_MAPPING: Record<
  FundReportingStatus,
  string
> = {
  [FundReportingStatus.ACTIVE_FOR_POST_TRADE_REPORTING]:
    'Active for Post-Trade Reporting',
  [FundReportingStatus.IN_ACTIVE_FOR_POST_TRADE_REPORTING]:
    'Inactive for Post-Trade Reporting',
}

export const FUND_REPORTING_SUB_STATUS_MAPPING: Record<
  FundReportingSubStatus,
  string
> = {
  [FundReportingSubStatus.WENT_PUBLIC]: 'Went Public',
  [FundReportingSubStatus.LISTED_PRODUCT]: 'Listed Product',
  [FundReportingSubStatus.LIQUIDATED]: 'Liquidated',
  [FundReportingSubStatus.REMOVED_FROM_PLATFORM]: 'Removed From the Platform',
  [FundReportingSubStatus.FIRM_REQUESTED_OR_ARRANGEMENT]:
    'Firm Requested / Arrangement',
}

export const FUND_LEGAL_STRUCTURE_MAPPING: Record<
  Exclude<FundGeneralInformationAdminV1LegalStructure, null>,
  string
> = {
  [FundGeneralInformationAdminV1LegalStructure.LIMITED_PARTNERSHIP_LP]:
    'Limited Partnership (LP)',
  [FundGeneralInformationAdminV1LegalStructure.LIMITED_LIABILITY_COMPANY_LLC]:
    'Limited Liability Company (LLC)',
  [FundGeneralInformationAdminV1LegalStructure.LIMITED_LTD]: 'Limited (LTD)',
  [FundGeneralInformationAdminV1LegalStructure.COMPANY]: 'Company',
  [FundGeneralInformationAdminV1LegalStructure.UNIT_TRUST]: 'Unit Trust',
  [FundGeneralInformationAdminV1LegalStructure.DELAWARE_STATUTORY_TRUST]:
    'Delaware Statutory Trusts (DSTs)',
  [FundGeneralInformationAdminV1LegalStructure.OTHER]: 'Other',
}

export const FUND_JURISDICTION_MAPPING = toMapping(
  FundGeneralInformationAdminV1Jurisdiction
)

// Set other last
delete FUND_JURISDICTION_MAPPING[
  FundGeneralInformationAdminV1Jurisdiction.OTHER_JURISDICTION
]
FUND_JURISDICTION_MAPPING[
  FundGeneralInformationAdminV1Jurisdiction.WASHINGTON_DC
] = 'Washington, D.C.'
FUND_JURISDICTION_MAPPING[
  FundGeneralInformationAdminV1Jurisdiction.JERSEY_GUERNSEY
] = 'Jersey / Guernsey'
FUND_JURISDICTION_MAPPING[
  FundGeneralInformationAdminV1Jurisdiction.OTHER_JURISDICTION
] = 'Other'

export const FUND_TYPE_MAPPING: Record<FundType, string> = {
  [FundType.ONSHORE]: 'Onshore / Domestic',
  [FundType.OFFSHORE]: 'Offshore',
}
export const FUND_GEOGRAPHIC_FOCUS_MAPPING = toMapping(
  FundGeneralInformationAdminV1GeographicFocusItem
)
export const FUND_ACCESS_MAPPING: Record<FundAccess, string> = {
  [FundAccess.DIRECT]: 'Direct',
  [FundAccess.CONDUIT_VEHICLE]: 'Conduit Vehicle',
  [FundAccess.REFERENCE]: 'Reference',
}

export const FUND_SECURITY_TYPE_MAPPING = toMapping(FundSecurityType)
FUND_SECURITY_TYPE_MAPPING[FundSecurityType.PRIVATE_MARKETS] =
  'Private Markets Fund'

export const FUND_SECURITY_SUB_TYPE_MAPPING = toMapping(
  FundGeneralInformationAdminV1SecuritySubType
)
export const FUNDRAISING_TYPE_MAPPING: Record<FundraisingType, string> = {
  [FundraisingType.OPEN_ENDED]: 'Open-Ended',
  [FundraisingType.CONTINUOUSLY_OFFERED_CLOSED_ENDED]:
    'Continuously Offered Closed-Ended',
  [FundraisingType.CLOSED_ENDED]: 'Closed-Ended',
}
export const FUND_SUB_STRATEGY_MAPPING = toMapping(FundSubStrategy)
delete FUND_SUB_STRATEGY_MAPPING[FundSubStrategy.OTHER_SUB_STRATEGY]
FUND_SUB_STRATEGY_MAPPING[FundSubStrategy.MULTI_STRATEGY] = 'Multi-Strategy'
FUND_SUB_STRATEGY_MAPPING[FundSubStrategy.DISTRESSED_RESTRUCTURING] =
  'Distressed / Restructuring'
FUND_SUB_STRATEGY_MAPPING[FundSubStrategy.PRIVATE_ISSUE_REGULATION_D] =
  'Private Issue / Regulation D'
FUND_SUB_STRATEGY_MAPPING[FundSubStrategy.FIXED_INCOME_ASSET_BACKED] =
  'Fixed Income - Asset Backed'
FUND_SUB_STRATEGY_MAPPING[FundSubStrategy.FIXED_INCOME_CONVERTIBLE_ARBITRAGE] =
  'Fixed Income - Convertible Arbitrage'
FUND_SUB_STRATEGY_MAPPING[FundSubStrategy.FIXED_INCOME_CORPORATE] =
  'Fixed Income - Corporate'
FUND_SUB_STRATEGY_MAPPING[FundSubStrategy.FIXED_INCOME_SOVEREIGN] =
  'Fixed Income - Sovereign'
FUND_SUB_STRATEGY_MAPPING[FundSubStrategy.VALUE_ADDED] = 'Value-Added'
FUND_SUB_STRATEGY_MAPPING[FundSubStrategy.NATURAL_RESOURCES_SUB_STRATEGY] =
  'Natural Resources'
FUND_SUB_STRATEGY_MAPPING[FundSubStrategy.MULTI_ASSET_SUB_STRATEGY] =
  'Multi-Asset'
FUND_SUB_STRATEGY_MAPPING[FundSubStrategy.FUND_OF_FUNDS_SUB_STRATEGY] =
  'Fund of Funds'
FUND_SUB_STRATEGY_MAPPING[FundSubStrategy.NATURAL_RESOURCES_SUB_STRATEGY] =
  'Natural Resources'
FUND_SUB_STRATEGY_MAPPING[FundSubStrategy.OTHER_SUB_STRATEGY] = 'Other'

export const FUND_STRATEGY_MAPPING = toMapping(FundStrategy)
delete FUND_STRATEGY_MAPPING[FundStrategy.OTHER_STRATEGY]
FUND_STRATEGY_MAPPING[FundStrategy.EVENT_DRIVEN] = 'Event-Driven'
FUND_STRATEGY_MAPPING[FundStrategy.MULTI_ASSET] = 'Multi-Asset'
FUND_STRATEGY_MAPPING[FundStrategy.OTHER_STRATEGY] = 'Other'

export const FUND_STRUCTURE_TYPE_MAPPING = toMapping(FundStructureType)
export const FUND_STRUCTURE_SUB_TYPE_MAPPING = toMapping(FundStructureSubType)
FUND_STRUCTURE_SUB_TYPE_MAPPING[
  FundStructureSubType.BUSINESS_DEVELOPMENT_COMPANY
] = 'Business Development Company (BDC)'
FUND_STRUCTURE_SUB_TYPE_MAPPING[FundStructureSubType.REIT] = 'REIT'
FUND_STRUCTURE_SUB_TYPE_MAPPING[FundStructureSubType.NON_TRADED_REIT] =
  'Non-Traded REIT'
FUND_STRUCTURE_SUB_TYPE_MAPPING[FundStructureSubType.EXCHANGE_1031] =
  '1031 Exchange'

export const MERCER_RATING_MAPPING: Record<MercerRating, string> = {
  [MercerRating.RATING_A]: 'A',
  [MercerRating.RATING_B_PLUS]: 'B+',
  [MercerRating.RATING_B]: 'B',
  [MercerRating.RATING_C]: 'C',
  [MercerRating.RATING_A_W]: 'A (W)',
  [MercerRating.RATING_B_PLUS_W]: 'B+ (W)',
  [MercerRating.RATING_B_W]: 'B (W)',
  [MercerRating.RATING_A_T]: 'A (T)',
  [MercerRating.RATING_B_PLUS_T]: 'B+ (T)',
  [MercerRating.RATING_B_T]: 'B (T)',
  [MercerRating.RATING_C_T]: 'C (T)',
  [MercerRating.RATING_A_P]: 'A (P)',
  [MercerRating.RATING_B_PLUS_P]: 'B+ (P)',
  [MercerRating.RATING_B_P]: 'B (P)',
  [MercerRating.RATING_RV]: 'RV',
  [MercerRating.RATING_NOT_AVAILABLE]: 'Mercer Report Not Available',
  [MercerRating.RATING_UNDER_REVIEW]: 'Mercer Report Under Review',
}
export const ESG_RATING_MAPPING: Record<EsgRating, string> = {
  [EsgRating.RATING_ESG_4]: 'ESG 4',
  [EsgRating.RATING_ESG_3]: 'ESG 3',
  [EsgRating.RATING_ESG_2]: 'ESG 2',
  [EsgRating.RATING_ESG_1]: 'ESG 1',
  [EsgRating.RATING_ESG_NOT_AVAILABLE]: 'ESG Rating Not Available (ESGN)',
  [EsgRating.MERCER_REPORT_NOT_AVAILABLE]: 'Mercer Report Not Available',
  [EsgRating.MERCER_REPORT_UNDER_REVIEW]: 'Mercer Report Under Review',
}
export const FUND_STAGE_MAPPING: Record<FundStage, string> = {
  [FundStage.FUNDRAISING]: 'Fundraising',
  [FundStage.INVESTING]: 'Investing',
  [FundStage.HARVESTING]: 'Harvesting',
  [FundStage.FULLY_REALIZED]: 'Fully Realized',
  [FundStage.WINDING_DOWN]: 'Winding Down',
  [FundStage.LIQUIDATED_FULLY_RETURNED]: 'Liquidated / Fully Returned',
}
export const FUND_REGISTRATION_INDICATOR_MAPPING = toMapping(
  FundGeneralInformationAdminV1RegisteredFundIndicator
)
export const FUND_UNITIZED_INDICATOR_MAPPING: Record<
  FundUnitizedIndicator,
  string
> = {
  [FundUnitizedIndicator.UNITIZED]: 'Unitized',
  [FundUnitizedIndicator.CAPITAL_BALANCE]: 'Capital Balance',
}
export const FUND_TRANSACTION_ELECTRONIC_SIGNATURE_TYPE_MAPPING: Record<
  FundTransactionSupportAdminV1ElectronicSignatureTypeItem,
  string
> = {
  [FundTransactionSupportAdminV1ElectronicSignatureTypeItem.CAIS]: 'CAIS',
  [FundTransactionSupportAdminV1ElectronicSignatureTypeItem.SCHWAB]: 'Schwab',
  [FundTransactionSupportAdminV1ElectronicSignatureTypeItem.OTHER]:
    'Other Firm Supported Docusign',
}

export const FUND_SHARE_CLASS_INDICATOR_MAPPING = toMapping(
  FundProductAdminV1ShareClassIndicator
)
export const WATERFALL_TYPE_MAPPING = toMapping(
  FundShareClassTermsAdminV1WaterFallType
)
export const LOCK_TYPE_MAPPING: Record<
  Exclude<FundLockUpAdminV1Type, null>,
  string
> = {
  [FundLockUpAdminV1Type.SOFT]: 'Soft Lockup',
  [FundLockUpAdminV1Type.HARD]: 'Hard Lockup',
  [FundLockUpAdminV1Type.NOT_APPLICABLE]: 'N/A',
}
export const FUND_PRODUCT_CAPABILITIES: Record<
  FundGeneralInformationAdminV1CapabilitiesItem,
  string
> = {
  [FundGeneralInformationAdminV1CapabilitiesItem.FULL]: 'All',
  [FundGeneralInformationAdminV1CapabilitiesItem.SUBSCRIPTIONS_ONLY]:
    'Subscriptions',
}

export const FUND_OBJECTIVE_MAPPING = toMapping(FundObjective)
export const FUND_CLOSE_DATE_SCHEDULE_TYPE_MAPPING = toMapping(
  FundCloseDateScheduleType
)
export const CALENDAR_CLOSE_DATE_SCHEDULE_ALL_OF_SCHEDULE_MAPPING = toMapping(
  CalendarCloseDateScheduleAllOfSchedule
)
export const CALENDAR_CLOSE_DATE_SCHEDULE_ALL_OF_CUT_OFF_POINT_MAPPING =
  toMapping(CalendarCloseDateScheduleAllOfCutOffPoint)
export const FUND_OFFSET_DATE_TYPE_MAPPING = toMapping(FundOffsetDateType)

export const FUND_SHARE_CLASS_APPROVAL_STATUS_MAPPING = toMapping(Approval)

export const FUND_OFFERING_TYPE_OPTIONS = toOptions(FUND_OFFERING_TYPE_MAPPING)
export const FUND_SOLUTION_TYPE_OPTIONS = toOptions(FUND_SOLUTION_TYPE_MAPPING)
export const FUND_SOLUTION_OFFERING_TYPE_OPTIONS = toOptions(
  FUND_SOLUTION_OFFERING_TYPE_MAPPING
)
export const FUND_FREQUENCY_OPTIONS = toOptions(FUND_FREQUENCY_MAPPING)
export const CURRENCY_CODE_OPTIONS = toOptions(CURRENCY_CODE_MAPPING).map(
  ({ value, label }) => ({
    value,
    label: label.toUpperCase(),
  })
)
export const FUND_DUE_DATE_TYPE_OPTIONS = toOptions(FUND_DUE_DATE_TYPE)
export const FUND_LEGACY_DUE_DATE_TYPE_OPTIONS = toOptions(
  FUND_LEGACY_DUE_DATE_TYPE
)
export const REDEMPTION_GATE_TYPE_OPTIONS = toOptions(
  REDEMPTION_GATE_TYPE_MAPPING
)
export const FUND_REDEMPTION_GATE_FREQUENCY_TYPE_OPTIONS = toOptions(
  FUND_REDEMPTION_GATE_FREQUENCY_TYPE_MAPPING
)
export const FUND_ADVISORY_TYPE_OPTIONS = toOptions(FUND_ADVISORY_TYPE_MAPPING)
export const FUND_PLACEMENT_FEE_CALCULATION_METHOD_OPTIONS = toOptions(
  FUND_PLACEMENT_FEE_CALCULATION_METHOD_MAPPING
)
export const FUND_FEE_EXPENSE_BASE_OPTIONS = toOptions(
  FUND_FEE_EXPENSE_BASE_MAPPING
)
export const FUND_FEE_PAYMENT_FREQUENCY_OPTIONS = toOptions(
  FUND_FEE_PAYMENT_FREQUENCY_MAPPING
)
export const FUND_FEE_EXPENSE_TYPE_OPTIONS = toOptions(
  FUND_FEE_EXPENSE_TYPE_MAPPING
)
export const LOCK_UP_PERIOD_OPTIONS = toOptions(LOCK_UP_PERIOD_MAPPING)
export const FUND_INVESTOR_LEVEL_OPTIONS = toOptions(
  FUND_INVESTOR_LEVEL_MAPPING
)
export const FUND_INVESTOR_TYPE_OPTIONS = toOptions(FUND_INVESTOR_TYPE_MAPPING)
export const FUND_TAX_REPORTING_OPTIONS = toOptions(FUND_TAX_REPORTING_MAPPING)
export const BASIS_OF_FUND_ACCOUNTING_OPTIONS = toOptions(
  BASIS_OF_FUND_ACCOUNTING_MAPPING
)
export const FUNDING_STRUCTURE_OPTIONS = toOptions(FUNDING_STRUCTURE_MAPPING)

export const FUND_SUBSCRIPTION_STATUS_OPTIONS = toOptions(
  FUND_SUBSCRIPTION_STATUS_MAPPING
)
export const FUND_REPORTING_STATUS_OPTIONS = toOptions(
  FUND_REPORTING_STATUS_MAPPING
)

export const FUND_REPORTING_SUB_STATUS_OPTIONS = toOptions(
  FUND_REPORTING_SUB_STATUS_MAPPING
)
export const FUND_LEGAL_STRUCTURE_OPTIONS = toOptions(
  FUND_LEGAL_STRUCTURE_MAPPING
)
export const FUND_JURISDICTION_OPTIONS = toOptions(FUND_JURISDICTION_MAPPING)
export const FUND_TYPE_OPTIONS = toOptions(FUND_TYPE_MAPPING)
export const FUND_GEOGRAPHIC_FOCUS_OPTIONS = toOptions(
  FUND_GEOGRAPHIC_FOCUS_MAPPING
)
export const FUND_ACCESS_OPTIONS = toOptions(FUND_ACCESS_MAPPING)

export const FUND_SECURITY_TYPE_OPTIONS = toOptions(FUND_SECURITY_TYPE_MAPPING)
export const FUND_SECURITY_SUB_TYPE_OPTIONS = toOptions(
  FUND_SECURITY_SUB_TYPE_MAPPING
)
export const FUNDRAISING_TYPE_OPTIONS = toOptions(FUNDRAISING_TYPE_MAPPING)
export const FUND_SUB_STRATEGY_OPTIONS = toOptions(
  FUND_SUB_STRATEGY_MAPPING
).filter((d) => d.value !== 'OTHER_EQUITY_MARKET_NEUTRAL')
export const FUND_STRATEGY_OPTIONS = toOptions(FUND_STRATEGY_MAPPING)
export const FUND_STRUCTURE_TYPE_OPTIONS = toOptions(
  FUND_STRUCTURE_TYPE_MAPPING
)
export const FUND_STRUCTURE_SUB_TYPE_OPTIONS = toOptions(
  FUND_STRUCTURE_SUB_TYPE_MAPPING
)
export const MERCER_RATING_OPTIONS = toOptions(MERCER_RATING_MAPPING)
export const ESG_RATING_OPTIONS = toOptions(ESG_RATING_MAPPING)
export const FUND_STAGE_OPTIONS = toOptions(FUND_STAGE_MAPPING)
export const FUND_REGISTRATION_INDICATOR_OPTIONS = toOptions(
  FUND_REGISTRATION_INDICATOR_MAPPING
)
export const FUND_UNITIZED_INDICATOR_OPTIONS = toOptions(
  FUND_UNITIZED_INDICATOR_MAPPING
)
export const FUND_TRANSACTION_ELECTRONIC_SIGNATURE_TYPE_OPTIONS = toOptions(
  FUND_TRANSACTION_ELECTRONIC_SIGNATURE_TYPE_MAPPING
)
export const FUND_SHARE_CLASS_INDICATOR_OPTIONS = toOptions(
  FUND_SHARE_CLASS_INDICATOR_MAPPING
)
export const WATERFALL_TYPE_OPTIONS = toOptions(WATERFALL_TYPE_MAPPING)
export const LOCK_TYPE_OPTIONS = toOptions(LOCK_TYPE_MAPPING)

export const FUND_PRODUCT_CAPABILITIES_OPTIONS = toOptions(
  FUND_PRODUCT_CAPABILITIES
)

export const FUND_OBJECTIVE_OPTIONS = toOptions(FUND_OBJECTIVE_MAPPING)
export const FUND_CLOSE_DATE_SCHEDULE_TYPE_OPTIONS = toOptions(
  FUND_CLOSE_DATE_SCHEDULE_TYPE_MAPPING
)
export const CALENDAR_CLOSE_DATE_SCHEDULE_ALL_OF_SCHEDULE_OPTIONS = toOptions(
  CALENDAR_CLOSE_DATE_SCHEDULE_ALL_OF_SCHEDULE_MAPPING
)
export const CALENDAR_CLOSE_DATE_SCHEDULE_ALL_OF_CUT_OFF_POINT_OPTIONS =
  toOptions(CALENDAR_CLOSE_DATE_SCHEDULE_ALL_OF_CUT_OFF_POINT_MAPPING)
export const FUND_OFFSET_DATE_TYPE_OPTIONS = toOptions(
  FUND_OFFSET_DATE_TYPE_MAPPING
)

const HedgeFundStrategy: Partial<Record<FundStrategy, string>> =
  toPartialMapping(
    [
      FundStrategy.EQUITY_HEDGE,
      FundStrategy.EVENT_DRIVEN,
      FundStrategy.MACRO,
      FundStrategy.RELATIVE_VALUE,
      FundStrategy.FUND_OF_FUNDS,
      FundStrategy.DIGITAL_ASSETS,
      FundStrategy.OTHER_STRATEGY,
      FundStrategy.UNKNOWN_STRATEGY,
    ],
    FUND_STRATEGY_MAPPING
  )

const PrivateMarketStrategy: Partial<Record<FundStrategy, string>> =
  toPartialMapping(
    [
      FundStrategy.PRIVATE_EQUITY,
      FundStrategy.PRIVATE_DEBT,
      FundStrategy.REAL_ESTATE,
      FundStrategy.INFRASTRUCTURE,
      FundStrategy.NATURAL_RESOURCES,
      FundStrategy.MULTI_ASSET,
      FundStrategy.DIGITAL_ASSETS,
      FundStrategy.OTHER_STRATEGY,
      FundStrategy.UNKNOWN_STRATEGY,
    ],
    FUND_STRATEGY_MAPPING
  )

const OperatingCompanyStrategy: Record<FundStrategy, string> =
  toMapping(FundStrategy)

export const getStategyValues = (securityType?: FundSecurityType | null) => {
  switch (securityType) {
    case FundSecurityType.HEDGE_FUND:
      return toOptions(HedgeFundStrategy)
    case FundSecurityType.PRIVATE_MARKETS:
      return toOptions(PrivateMarketStrategy)
    case FundSecurityType.OPERATING_COMPANY:
      return toOptions(OperatingCompanyStrategy)
    default:
      return []
  }
}

const COMMON_SUBSTRATEGIES = [
  FundSubStrategy.OTHER_SUB_STRATEGY,
  FundSubStrategy.UNKNOWN_SUB_STRATEGY,
]
const EquityHedgeSubstrategies: Partial<Record<FundSubStrategy, string>> =
  toPartialMapping(
    [
      FundSubStrategy.EQUITY_MARKET_NEUTRAL,
      FundSubStrategy.FUNDAMENTAL_GROWTH,
      FundSubStrategy.FUNDAMENTAL_VALUE,
      FundSubStrategy.QUANTITATIVE_DIRECTIONAL,
      FundSubStrategy.SECTOR,
      FundSubStrategy.SHORT_BIAS,
      FundSubStrategy.MULTI_STRATEGY,
      ...COMMON_SUBSTRATEGIES,
    ],
    FUND_SUB_STRATEGY_MAPPING
  )

const EventDrivenSubstrategies: Partial<Record<FundSubStrategy, string>> =
  toPartialMapping(
    [
      FundSubStrategy.ACTIVE_TRADING,
      FundSubStrategy.CREDIT_ARBITRAGE,
      FundSubStrategy.DISTRESSED_RESTRUCTURING,
      FundSubStrategy.MERGER_ARBITRAGE,
      FundSubStrategy.PRIVATE_ISSUE_REGULATION_D,
      FundSubStrategy.SPECIAL_SITUATIONS,
      FundSubStrategy.MULTI_STRATEGY,
      ...COMMON_SUBSTRATEGIES,
    ],
    FUND_SUB_STRATEGY_MAPPING
  )

const MacroSubstrategies: Partial<Record<FundSubStrategy, string>> =
  toPartialMapping(
    [
      FundSubStrategy.ACTIVE_TRADING,
      FundSubStrategy.CURRENCY,
      FundSubStrategy.DISCRETIONARY_THEMATIC,
      FundSubStrategy.SYSTEMATIC_DIVERSIFIED,
      FundSubStrategy.MULTI_STRATEGY,
      ...COMMON_SUBSTRATEGIES,
    ],
    FUND_SUB_STRATEGY_MAPPING
  )

const RelativeValueSubstrategies: Partial<Record<FundSubStrategy, string>> =
  toPartialMapping(
    [
      FundSubStrategy.FIXED_INCOME_ASSET_BACKED,
      FundSubStrategy.FIXED_INCOME_CONVERTIBLE_ARBITRAGE,
      FundSubStrategy.FIXED_INCOME_CORPORATE,
      FundSubStrategy.FIXED_INCOME_SOVEREIGN,
      FundSubStrategy.VOLATILITY,
      FundSubStrategy.YIELD_ALTERNATIVES,
      FundSubStrategy.MULTI_STRATEGY,
      ...COMMON_SUBSTRATEGIES,
    ],
    FUND_SUB_STRATEGY_MAPPING
  )

const FundOfFundsSubstrategies: Partial<Record<FundSubStrategy, string>> =
  toPartialMapping(
    [
      FundSubStrategy.CONSERVATIVE,
      FundSubStrategy.DIVERSIFIED,
      FundSubStrategy.MARKET_DEFENSIVE,
      FundSubStrategy.STRATEGIC,
      ...COMMON_SUBSTRATEGIES,
    ],
    FUND_SUB_STRATEGY_MAPPING
  )

const PrivateEquitySubstrategies: Partial<Record<FundSubStrategy, string>> =
  toPartialMapping(
    [
      FundSubStrategy.BUYOUT,
      FundSubStrategy.VENTURE_CAPITAL,
      FundSubStrategy.GROWTH,
      FundSubStrategy.FUND_OF_FUNDS_SUB_STRATEGY,
      FundSubStrategy.SECONDARIES,
      FundSubStrategy.MULTI_STRATEGY,
      ...COMMON_SUBSTRATEGIES,
    ],
    FUND_SUB_STRATEGY_MAPPING
  )

const PrivateDebtSubstrategies: Partial<Record<FundSubStrategy, string>> =
  toPartialMapping(
    [
      FundSubStrategy.DISTRESSED_DEBT,
      FundSubStrategy.SPECIAL_SITUATIONS,
      FundSubStrategy.DIRECT_LENDING,
      FundSubStrategy.MEZZANINE,
      FundSubStrategy.FUND_OF_FUNDS_SUB_STRATEGY,
      FundSubStrategy.VENTURE_DEBT,
      FundSubStrategy.MULTI_STRATEGY,
      ...COMMON_SUBSTRATEGIES,
    ],
    FUND_SUB_STRATEGY_MAPPING
  )

const RealEstateAndInfrastructureSubstrategies: Partial<
  Record<FundSubStrategy, string>
> = toPartialMapping(
  [
    FundSubStrategy.OPPORTUNISTIC,
    FundSubStrategy.DISTRESSED,
    FundSubStrategy.VALUE_ADDED,
    FundSubStrategy.SECONDARIES,
    FundSubStrategy.CORE,
    FundSubStrategy.DEBT,
    FundSubStrategy.CORE_PLUS,
    FundSubStrategy.FUND_OF_FUNDS_SUB_STRATEGY,
    FundSubStrategy.MULTI_STRATEGY,
    ...COMMON_SUBSTRATEGIES,
  ],
  FUND_SUB_STRATEGY_MAPPING
)

const NaturalResourcesSubstrategies: Partial<Record<FundSubStrategy, string>> =
  toPartialMapping(
    [FundSubStrategy.NATURAL_RESOURCES_SUB_STRATEGY, ...COMMON_SUBSTRATEGIES],
    FUND_SUB_STRATEGY_MAPPING
  )

const MultiAssetSubstrategies: Partial<Record<FundSubStrategy, string>> =
  toPartialMapping(
    [FundSubStrategy.MULTI_ASSET_SUB_STRATEGY, ...COMMON_SUBSTRATEGIES],
    FUND_SUB_STRATEGY_MAPPING
  )

const DigitalAssetsSubstrategies: Partial<Record<FundSubStrategy, string>> =
  toPartialMapping(
    [FundSubStrategy.CRYPTOCURRENCY, ...COMMON_SUBSTRATEGIES],
    FUND_SUB_STRATEGY_MAPPING
  )

export const getSubStategyValues = (strategy?: FundStrategy | null) => {
  switch (strategy) {
    case FundStrategy.EQUITY_HEDGE:
      return toOptions(EquityHedgeSubstrategies)
    case FundStrategy.EVENT_DRIVEN:
      return toOptions(EventDrivenSubstrategies)
    case FundStrategy.MACRO:
      return toOptions(MacroSubstrategies)
    case FundStrategy.RELATIVE_VALUE:
      return toOptions(RelativeValueSubstrategies)
    case FundStrategy.FUND_OF_FUNDS:
      return toOptions(FundOfFundsSubstrategies)
    case FundStrategy.PRIVATE_EQUITY:
      return toOptions(PrivateEquitySubstrategies)
    case FundStrategy.PRIVATE_DEBT:
      return toOptions(PrivateDebtSubstrategies)
    case FundStrategy.REAL_ESTATE:
      return toOptions(RealEstateAndInfrastructureSubstrategies)
    case FundStrategy.INFRASTRUCTURE:
      return toOptions(RealEstateAndInfrastructureSubstrategies)
    case FundStrategy.NATURAL_RESOURCES:
      return toOptions(NaturalResourcesSubstrategies)
    case FundStrategy.MULTI_ASSET:
      return toOptions(MultiAssetSubstrategies)
    case FundStrategy.DIGITAL_ASSETS:
      return toOptions(DigitalAssetsSubstrategies)
    case FundStrategy.OTHER_STRATEGY:
    case FundStrategy.UNKNOWN_STRATEGY:
      return FUND_SUB_STRATEGY_OPTIONS
    default:
      return []
  }
}

const StructureFiniteLiteSubType: Partial<
  Record<FundStructureSubType, string>
> = toPartialMapping(
  [
    FundStructureSubType.PRIVATE_PLACEMENT,
    FundStructureSubType.BUSINESS_DEVELOPMENT_COMPANY,
    FundStructureSubType.REIT,
    FundStructureSubType.PREFERRED_STOCK,
    FundStructureSubType.EXCHANGE_1031,
  ],
  FUND_STRUCTURE_SUB_TYPE_MAPPING
)

const StructurePerpetualSubType: Partial<Record<FundStructureSubType, string>> =
  toPartialMapping(
    [
      FundStructureSubType.PRIVATE_PLACEMENT,
      FundStructureSubType.BUSINESS_DEVELOPMENT_COMPANY,
      FundStructureSubType.TENDER_FUND,
      FundStructureSubType.INTERVAL_FUND,
      FundStructureSubType.NON_TRADED_REIT,
      FundStructureSubType.EXCHANGE_1031,
    ],
    FUND_STRUCTURE_SUB_TYPE_MAPPING
  )

export const getStructureSubTypes = (
  structureType?: FundStructureType | null
) => {
  switch (structureType) {
    case FundStructureType.FINITE_LIFE:
      return toOptions(StructureFiniteLiteSubType)
    case FundStructureType.PERPETUAL:
      return toOptions(StructurePerpetualSubType)
    default:
      return FUND_STRUCTURE_SUB_TYPE_OPTIONS
  }
}

export const OFFSHORE_JURISDICTIONS = [
  FundGeneralInformationAdminV1Jurisdiction.ARGENTINA,
  FundGeneralInformationAdminV1Jurisdiction.ARUBA,
  FundGeneralInformationAdminV1Jurisdiction.AUSTRALIA,
  FundGeneralInformationAdminV1Jurisdiction.BAHAMAS,
  FundGeneralInformationAdminV1Jurisdiction.BERMUDA,
  FundGeneralInformationAdminV1Jurisdiction.BRAZIL,
  FundGeneralInformationAdminV1Jurisdiction.BRITISH_VIRGIN_ISLANDS,
  FundGeneralInformationAdminV1Jurisdiction.CANADA,
  FundGeneralInformationAdminV1Jurisdiction.CAYMAN_ISLANDS,
  FundGeneralInformationAdminV1Jurisdiction.CHILE,
  FundGeneralInformationAdminV1Jurisdiction.COLUMBIA,
  FundGeneralInformationAdminV1Jurisdiction.DOMINICAN_REPUBLIC,
  FundGeneralInformationAdminV1Jurisdiction.EL_SALVADOR,
  FundGeneralInformationAdminV1Jurisdiction.GUATEMALA,
  FundGeneralInformationAdminV1Jurisdiction.HONG_KONG,
  FundGeneralInformationAdminV1Jurisdiction.IRELAND,
  FundGeneralInformationAdminV1Jurisdiction.ISRAEL,
  FundGeneralInformationAdminV1Jurisdiction.JAMAICA,
  FundGeneralInformationAdminV1Jurisdiction.JERSEY_GUERNSEY,
  FundGeneralInformationAdminV1Jurisdiction.LUXEMBOURG,
  FundGeneralInformationAdminV1Jurisdiction.MEXICO,
  FundGeneralInformationAdminV1Jurisdiction.NEW_ZEALAND,
  FundGeneralInformationAdminV1Jurisdiction.PANAMA,
  FundGeneralInformationAdminV1Jurisdiction.PERU,
  FundGeneralInformationAdminV1Jurisdiction.SINGAPORE,
  FundGeneralInformationAdminV1Jurisdiction.SWITZERLAND,
  FundGeneralInformationAdminV1Jurisdiction.TURKS_AND_CAICOS,
  FundGeneralInformationAdminV1Jurisdiction.UNITED_KINGDOM,
  FundGeneralInformationAdminV1Jurisdiction.URUGUAY,
  FundGeneralInformationAdminV1Jurisdiction.VENEZUELA,
] as FundGeneralInformationAdminV1Jurisdiction[]

export const FUND_SHARE_CLASS_APPROVAL_STATUS_OPTIONS = toOptions(
  FUND_SHARE_CLASS_APPROVAL_STATUS_MAPPING
)

export const FUND_PUBLISH_STATUS_MAPPING = toMapping(FundPublishState)
