import styled from '@emotion/styled'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import cx from 'classnames'
import React, { ReactNode, useState } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { colors } from '../../../../../equity/particles/colors'
import { spacing } from '../../../../../equity/particles/spacing'
import { Icon } from '../../../../../equity/web/atoms/icon'
import { BodySmall } from '../../../../../legacy-approved/ui/typography'
import { TrackingProps } from '../../../../../legacy-shared/util/segment'
import { RoundButton } from '../button-round'

export type MenuVariant = 'PRIMARY' | 'SECONDARY'

const StyledLabel = styled(BodySmall)<{ variant?: MenuVariant }>`
  color: ${({ variant }) =>
    variant === 'SECONDARY'
      ? colors['eq-color-error-600']
      : colors['eq-color-neutral-900']};
`

const StyledMenu = styled(Menu)`
  * > ul {
    padding: 0;
    min-width: fit-content;
  }
`

const StyledMenuItem = styled(MenuItem, {
  // Stops selected props getting forwarded to the DOM element
  shouldForwardProp: (prop) => !['$hasSubtext'].includes(prop),
})<{ $hasSubtext?: boolean }>`
  padding: ${spacing.s8};
  flex-direction: column;
  align-items: start;
  gap: ${({ $hasSubtext }) => (!$hasSubtext ? '12px' : 0)};
  &:hover {
    background-color: ${colors['eq-color-neutral-100']};
  }
`
export type MenuTableAction = {
  text: string
  subtext?: string
  handler?: (e?: React.MouseEvent) => void
  variant?: MenuVariant
  testId?: string
  disabled?: boolean
  shown?: boolean
  destination?: string
  permissions?: string[]
  somePermissions?: string[]
}

export type MenuTableActions = Array<MenuTableAction & TrackingProps>

export type ContextMenuTableProps = {
  actions: MenuTableActions
  testId?: string
  className?: string
  variant?: 'Default' | 'RoundButton'
  label?: string | ReactNode
  width?: number | string
  transformHorizontal?: 'left' | 'right'
  disabled?: boolean
  a11yLabel?: string
} & TrackingProps

export const ContextMenuTable = ({
  actions,
  testId = 'user-actions-button',
  variant,
  className,
  label,
  width = 180,
  transformHorizontal = 'left',
  disabled,
  a11yLabel = 'Actions',
  ...props
}: ContextMenuTableProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (
    event: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLLIElement>
  ) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return actions.length ? (
    <>
      {variant === 'RoundButton' ? (
        <RoundButton
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          data-testid={testId}
          disabled={disabled}
          className={cx({
            'text-primary-600': !disabled,
            'border-primary-600 bg-primary-100': open,
            className: true,
          })}
          aria-label={a11yLabel}
          title={a11yLabel}
        >
          {label} {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </RoundButton>
      ) : (
        <IconButton
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          aria-label={a11yLabel}
          title={a11yLabel}
          onClick={handleClick}
          data-testid={testId}
          className={twMerge(
            cx(
              'hover:eq-color-neutral-100 h-32 w-32 text-neutral-400 hover:text-neutral-600',
              {
                'text-neutral-900': open,
              }
            ),
            className
          )}
          disabled={disabled}
        >
          <Icon type="MoreVert" />
        </IconButton>
      )}
      <StyledMenu
        classes={{ paper: 'shadow-5', list: 'my-16 mx-24' }}
        id="user-actions"
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: 'top',
          horizontal: transformHorizontal,
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        data-testid="user-actions-menu"
        PaperProps={{
          style: {
            width,
          },
        }}
      >
        {actions
          ?.filter((action) => action.shown !== false)
          .map((action) => {
            const trackItemName =
              action['data-track-item-name'] ??
              `${props['data-track-event']}: ${action.text}`
            return (
              <StyledMenuItem
                key={action.text}
                data-track-event={props['data-track-event']}
                data-track-item-name={trackItemName}
                disabled={action.disabled}
                $hasSubtext={!!action.subtext}
                onClick={(event) => {
                  handleClose(event)
                  if (action.handler) {
                    action.handler(event)
                  }
                }}
                data-testid={action.testId ?? 'user-actions-item'}
              >
                {action.destination ? (
                  <Link
                    className="small w-full text-neutral-900"
                    to={action.destination}
                  >
                    {action.text}
                  </Link>
                ) : (
                  <>
                    <StyledLabel variant={action.variant}>
                      {action.text}
                    </StyledLabel>
                    {action.subtext ? (
                      <p className="small text-neutral-500">{action.subtext}</p>
                    ) : null}
                  </>
                )}
              </StyledMenuItem>
            )
          })}
      </StyledMenu>
    </>
  ) : null
}
