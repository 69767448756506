/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Structured Product Order Service API
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'

import { fetchWithOverrides } from '../fetchWithOverrides'

import type {
  BatchUpdateRollRequestItem,
  BulkUploadCreateForAdvisor400,
  BulkUploadCreateRequestForAdvisorV1,
  BulkUploadCreateResponseForAdvisorV1,
  BulkUploadExtractForAdvisorBody,
  BulkUploadExtractResponseForAdvisorV1,
  CancelStructuredProductOrderForAdvisorHeaders,
  CancelStructuredProductOrderHeaders,
  CreatePlaceholderProductRequest,
  ErrorResponse,
  GetRollsParams,
  OrdersCsvExportRequestV1,
  OrdersFilterMetadataRequest,
  OrdersFilterMetadataResult,
  PlaceholderStructuredProductV1,
  PositionLifecycleEventStatsRequest,
  PositionLifecycleEventStatsResponse,
  RollV1,
  StructuredProductLifecycleMetadataRequest,
  StructuredProductLifecycleMetadataResponse,
  StructuredProductLifecyclePageResult,
  StructuredProductLifecycleSearchRequest,
  StructuredProductOrderAdvisorRequest,
  StructuredProductOrderCsvExportResult,
  StructuredProductOrderExpandedAdvisorPageResult,
  StructuredProductOrderExpandedPageResult,
  StructuredProductOrderForAdvisorV1,
  StructuredProductOrderRequest,
  StructuredProductOrderSearchRequest,
  StructuredProductOrderV1,
  StructuredProductPlaceholderPageResult,
  StructuredProductPlaceholderSearchRequest,
  UpdatePlaceholderProductRequest,
  UpdatePlaceholderStructuredProductHeaders,
  UpdateStructuredProductOrderForAdvisorHeaders,
  UpdateStructuredProductOrderHeaders,
  UpdateStructuredProductOrderRequest,
  UpdateStructuredProductOrderRequestForAdvisor,
} from './api.schemas'

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

/**
 * @summary Create a Structured Product Order
 */
export const createStructuredProductOrder = (
  structuredProductOrderRequest: StructuredProductOrderRequest,
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  return fetchWithOverrides<StructuredProductOrderV1>(
    {
      url: `/api/structured-products/orders/v1`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: structuredProductOrderRequest,
    },
    options
  )
}

export const getCreateStructuredProductOrderMutationOptions = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createStructuredProductOrder>>,
    TError,
    { data: StructuredProductOrderRequest },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}): UseMutationOptions<
  Awaited<ReturnType<typeof createStructuredProductOrder>>,
  TError,
  { data: StructuredProductOrderRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createStructuredProductOrder>>,
    { data: StructuredProductOrderRequest }
  > = (props) => {
    const { data } = props ?? {}

    return createStructuredProductOrder(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateStructuredProductOrderMutationResult = NonNullable<
  Awaited<ReturnType<typeof createStructuredProductOrder>>
>
export type CreateStructuredProductOrderMutationBody =
  StructuredProductOrderRequest
export type CreateStructuredProductOrderMutationError = ErrorResponse

/**
 * @summary Create a Structured Product Order
 */
export const useCreateStructuredProductOrder = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createStructuredProductOrder>>,
    TError,
    { data: StructuredProductOrderRequest },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}) => {
  const mutationOptions =
    getCreateStructuredProductOrderMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Retrieve a Structured Product Order
 */
export const getStructuredProductOrder = (
  id: string,
  options?: SecondParameter<typeof fetchWithOverrides>,
  signal?: AbortSignal
) => {
  return fetchWithOverrides<StructuredProductOrderV1>(
    { url: `/api/structured-products/orders/v1/${id}`, method: 'GET', signal },
    options
  )
}

export const getGetStructuredProductOrderQueryKey = (id: string) => {
  return [`/api/structured-products/orders/v1/${id}`] as const
}

export const getGetStructuredProductOrderQueryOptions = <
  TData = Awaited<ReturnType<typeof getStructuredProductOrder>>,
  TError = ErrorResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStructuredProductOrder>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetStructuredProductOrderQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getStructuredProductOrder>>
  > = ({ signal }) => getStructuredProductOrder(id, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getStructuredProductOrder>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetStructuredProductOrderQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStructuredProductOrder>>
>
export type GetStructuredProductOrderQueryError = ErrorResponse

/**
 * @summary Retrieve a Structured Product Order
 */
export const useGetStructuredProductOrder = <
  TData = Awaited<ReturnType<typeof getStructuredProductOrder>>,
  TError = ErrorResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStructuredProductOrder>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetStructuredProductOrderQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Update a Structured Product Order
 */
export const updateStructuredProductOrder = (
  id: string,
  updateStructuredProductOrderRequest: UpdateStructuredProductOrderRequest,
  headers?: UpdateStructuredProductOrderHeaders,
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  return fetchWithOverrides<StructuredProductOrderV1>(
    {
      url: `/api/structured-products/orders/v1/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', ...headers },
      data: updateStructuredProductOrderRequest,
    },
    options
  )
}

export const getUpdateStructuredProductOrderMutationOptions = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStructuredProductOrder>>,
    TError,
    {
      id: string
      data: UpdateStructuredProductOrderRequest
      headers?: UpdateStructuredProductOrderHeaders
    },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateStructuredProductOrder>>,
  TError,
  {
    id: string
    data: UpdateStructuredProductOrderRequest
    headers?: UpdateStructuredProductOrderHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateStructuredProductOrder>>,
    {
      id: string
      data: UpdateStructuredProductOrderRequest
      headers?: UpdateStructuredProductOrderHeaders
    }
  > = (props) => {
    const { id, data, headers } = props ?? {}

    return updateStructuredProductOrder(id, data, headers, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateStructuredProductOrderMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateStructuredProductOrder>>
>
export type UpdateStructuredProductOrderMutationBody =
  UpdateStructuredProductOrderRequest
export type UpdateStructuredProductOrderMutationError = ErrorResponse

/**
 * @summary Update a Structured Product Order
 */
export const useUpdateStructuredProductOrder = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStructuredProductOrder>>,
    TError,
    {
      id: string
      data: UpdateStructuredProductOrderRequest
      headers?: UpdateStructuredProductOrderHeaders
    },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}) => {
  const mutationOptions =
    getUpdateStructuredProductOrderMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Cancel a Structured Product Order
 */
export const cancelStructuredProductOrder = (
  id: string,
  headers?: CancelStructuredProductOrderHeaders,
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  return fetchWithOverrides<StructuredProductOrderV1>(
    {
      url: `/api/structured-products/orders/v1/${id}/cancel`,
      method: 'POST',
      headers,
    },
    options
  )
}

export const getCancelStructuredProductOrderMutationOptions = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelStructuredProductOrder>>,
    TError,
    { id: string; headers?: CancelStructuredProductOrderHeaders },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}): UseMutationOptions<
  Awaited<ReturnType<typeof cancelStructuredProductOrder>>,
  TError,
  { id: string; headers?: CancelStructuredProductOrderHeaders },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cancelStructuredProductOrder>>,
    { id: string; headers?: CancelStructuredProductOrderHeaders }
  > = (props) => {
    const { id, headers } = props ?? {}

    return cancelStructuredProductOrder(id, headers, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type CancelStructuredProductOrderMutationResult = NonNullable<
  Awaited<ReturnType<typeof cancelStructuredProductOrder>>
>

export type CancelStructuredProductOrderMutationError = ErrorResponse

/**
 * @summary Cancel a Structured Product Order
 */
export const useCancelStructuredProductOrder = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelStructuredProductOrder>>,
    TError,
    { id: string; headers?: CancelStructuredProductOrderHeaders },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}) => {
  const mutationOptions =
    getCancelStructuredProductOrderMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Search for Structured Product Orders
 */
export const searchStructuredProductOrders = (
  structuredProductOrderSearchRequest: StructuredProductOrderSearchRequest,
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  return fetchWithOverrides<StructuredProductOrderExpandedPageResult>(
    {
      url: `/api/structured-products/orders/v1/search`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: structuredProductOrderSearchRequest,
    },
    options
  )
}

export const getSearchStructuredProductOrdersQueryKey = (
  structuredProductOrderSearchRequest: StructuredProductOrderSearchRequest
) => {
  return [
    `/api/structured-products/orders/v1/search`,
    structuredProductOrderSearchRequest,
  ] as const
}

export const getSearchStructuredProductOrdersQueryOptions = <
  TData = Awaited<ReturnType<typeof searchStructuredProductOrders>>,
  TError = ErrorResponse
>(
  structuredProductOrderSearchRequest: StructuredProductOrderSearchRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchStructuredProductOrders>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getSearchStructuredProductOrdersQueryKey(
      structuredProductOrderSearchRequest
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchStructuredProductOrders>>
  > = () =>
    searchStructuredProductOrders(
      structuredProductOrderSearchRequest,
      requestOptions
    )

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchStructuredProductOrders>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchStructuredProductOrdersQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchStructuredProductOrders>>
>
export type SearchStructuredProductOrdersQueryError = ErrorResponse

/**
 * @summary Search for Structured Product Orders
 */
export const useSearchStructuredProductOrders = <
  TData = Awaited<ReturnType<typeof searchStructuredProductOrders>>,
  TError = ErrorResponse
>(
  structuredProductOrderSearchRequest: StructuredProductOrderSearchRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchStructuredProductOrders>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchStructuredProductOrdersQueryOptions(
    structuredProductOrderSearchRequest,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Search through structured product position lifecycle events
 */
export const searchStructuredProductPositionLifecycleEvents = (
  structuredProductLifecycleSearchRequest: StructuredProductLifecycleSearchRequest,
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  return fetchWithOverrides<StructuredProductLifecyclePageResult>(
    {
      url: `/api/structured-products/lifecycle/v1/search`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: structuredProductLifecycleSearchRequest,
    },
    options
  )
}

export const getSearchStructuredProductPositionLifecycleEventsQueryKey = (
  structuredProductLifecycleSearchRequest: StructuredProductLifecycleSearchRequest
) => {
  return [
    `/api/structured-products/lifecycle/v1/search`,
    structuredProductLifecycleSearchRequest,
  ] as const
}

export const getSearchStructuredProductPositionLifecycleEventsQueryOptions = <
  TData = Awaited<
    ReturnType<typeof searchStructuredProductPositionLifecycleEvents>
  >,
  TError = ErrorResponse
>(
  structuredProductLifecycleSearchRequest: StructuredProductLifecycleSearchRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof searchStructuredProductPositionLifecycleEvents>
      >,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getSearchStructuredProductPositionLifecycleEventsQueryKey(
      structuredProductLifecycleSearchRequest
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchStructuredProductPositionLifecycleEvents>>
  > = () =>
    searchStructuredProductPositionLifecycleEvents(
      structuredProductLifecycleSearchRequest,
      requestOptions
    )

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchStructuredProductPositionLifecycleEvents>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchStructuredProductPositionLifecycleEventsQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof searchStructuredProductPositionLifecycleEvents>>
  >
export type SearchStructuredProductPositionLifecycleEventsQueryError =
  ErrorResponse

/**
 * @summary Search through structured product position lifecycle events
 */
export const useSearchStructuredProductPositionLifecycleEvents = <
  TData = Awaited<
    ReturnType<typeof searchStructuredProductPositionLifecycleEvents>
  >,
  TError = ErrorResponse
>(
  structuredProductLifecycleSearchRequest: StructuredProductLifecycleSearchRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof searchStructuredProductPositionLifecycleEvents>
      >,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getSearchStructuredProductPositionLifecycleEventsQueryOptions(
      structuredProductLifecycleSearchRequest,
      options
    )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Returns next and most recent lifecycle event observation dates
 */
export const structuredProductPositionLifecycleEventsMetadata = (
  structuredProductLifecycleMetadataRequest: StructuredProductLifecycleMetadataRequest,
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  return fetchWithOverrides<StructuredProductLifecycleMetadataResponse>(
    {
      url: `/api/structured-products/lifecycle/v1/metadata`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: structuredProductLifecycleMetadataRequest,
    },
    options
  )
}

export const getStructuredProductPositionLifecycleEventsMetadataQueryKey = (
  structuredProductLifecycleMetadataRequest: StructuredProductLifecycleMetadataRequest
) => {
  return [
    `/api/structured-products/lifecycle/v1/metadata`,
    structuredProductLifecycleMetadataRequest,
  ] as const
}

export const getStructuredProductPositionLifecycleEventsMetadataQueryOptions = <
  TData = Awaited<
    ReturnType<typeof structuredProductPositionLifecycleEventsMetadata>
  >,
  TError = ErrorResponse
>(
  structuredProductLifecycleMetadataRequest: StructuredProductLifecycleMetadataRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof structuredProductPositionLifecycleEventsMetadata>
      >,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getStructuredProductPositionLifecycleEventsMetadataQueryKey(
      structuredProductLifecycleMetadataRequest
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof structuredProductPositionLifecycleEventsMetadata>>
  > = () =>
    structuredProductPositionLifecycleEventsMetadata(
      structuredProductLifecycleMetadataRequest,
      requestOptions
    )

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<typeof structuredProductPositionLifecycleEventsMetadata>
    >,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type StructuredProductPositionLifecycleEventsMetadataQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof structuredProductPositionLifecycleEventsMetadata>>
  >
export type StructuredProductPositionLifecycleEventsMetadataQueryError =
  ErrorResponse

/**
 * @summary Returns next and most recent lifecycle event observation dates
 */
export const useStructuredProductPositionLifecycleEventsMetadata = <
  TData = Awaited<
    ReturnType<typeof structuredProductPositionLifecycleEventsMetadata>
  >,
  TError = ErrorResponse
>(
  structuredProductLifecycleMetadataRequest: StructuredProductLifecycleMetadataRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof structuredProductPositionLifecycleEventsMetadata>
      >,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getStructuredProductPositionLifecycleEventsMetadataQueryOptions(
      structuredProductLifecycleMetadataRequest,
      options
    )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Export lifecycle
 */
export const lifecycleExportCsv = (
  structuredProductLifecycleSearchRequest: StructuredProductLifecycleSearchRequest,
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  return fetchWithOverrides<Blob>(
    {
      url: `/api/structured-products/lifecycle/v1/export-csv`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: structuredProductLifecycleSearchRequest,
      responseType: 'blob',
    },
    options
  )
}

export const getLifecycleExportCsvMutationOptions = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof lifecycleExportCsv>>,
    TError,
    { data: StructuredProductLifecycleSearchRequest },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}): UseMutationOptions<
  Awaited<ReturnType<typeof lifecycleExportCsv>>,
  TError,
  { data: StructuredProductLifecycleSearchRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof lifecycleExportCsv>>,
    { data: StructuredProductLifecycleSearchRequest }
  > = (props) => {
    const { data } = props ?? {}

    return lifecycleExportCsv(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type LifecycleExportCsvMutationResult = NonNullable<
  Awaited<ReturnType<typeof lifecycleExportCsv>>
>
export type LifecycleExportCsvMutationBody =
  StructuredProductLifecycleSearchRequest
export type LifecycleExportCsvMutationError = ErrorResponse

/**
 * @summary Export lifecycle
 */
export const useLifecycleExportCsv = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof lifecycleExportCsv>>,
    TError,
    { data: StructuredProductLifecycleSearchRequest },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}) => {
  const mutationOptions = getLifecycleExportCsvMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get the summary stats of position lifecycle events along with per period breakdown
 */
export const getRedemptionStats = (
  positionLifecycleEventStatsRequest: PositionLifecycleEventStatsRequest,
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  return fetchWithOverrides<PositionLifecycleEventStatsResponse>(
    {
      url: `/api/structured-products/lifecycle/v1/stats`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: positionLifecycleEventStatsRequest,
    },
    options
  )
}

export const getGetRedemptionStatsQueryKey = (
  positionLifecycleEventStatsRequest: PositionLifecycleEventStatsRequest
) => {
  return [
    `/api/structured-products/lifecycle/v1/stats`,
    positionLifecycleEventStatsRequest,
  ] as const
}

export const getGetRedemptionStatsQueryOptions = <
  TData = Awaited<ReturnType<typeof getRedemptionStats>>,
  TError = ErrorResponse
>(
  positionLifecycleEventStatsRequest: PositionLifecycleEventStatsRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRedemptionStats>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetRedemptionStatsQueryKey(positionLifecycleEventStatsRequest)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getRedemptionStats>>
  > = () =>
    getRedemptionStats(positionLifecycleEventStatsRequest, requestOptions)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getRedemptionStats>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetRedemptionStatsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRedemptionStats>>
>
export type GetRedemptionStatsQueryError = ErrorResponse

/**
 * @summary Get the summary stats of position lifecycle events along with per period breakdown
 */
export const useGetRedemptionStats = <
  TData = Awaited<ReturnType<typeof getRedemptionStats>>,
  TError = ErrorResponse
>(
  positionLifecycleEventStatsRequest: PositionLifecycleEventStatsRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRedemptionStats>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRedemptionStatsQueryOptions(
    positionLifecycleEventStatsRequest,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary CSV export for SP orders
 */
export const exportOrdersToCsv = (
  ordersCsvExportRequestV1: OrdersCsvExportRequestV1,
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  return fetchWithOverrides<StructuredProductOrderCsvExportResult>(
    {
      url: `/api/structured-products/orders/v2/export-csv`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: ordersCsvExportRequestV1,
    },
    options
  )
}

export const getExportOrdersToCsvMutationOptions = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exportOrdersToCsv>>,
    TError,
    { data: OrdersCsvExportRequestV1 },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}): UseMutationOptions<
  Awaited<ReturnType<typeof exportOrdersToCsv>>,
  TError,
  { data: OrdersCsvExportRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof exportOrdersToCsv>>,
    { data: OrdersCsvExportRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return exportOrdersToCsv(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ExportOrdersToCsvMutationResult = NonNullable<
  Awaited<ReturnType<typeof exportOrdersToCsv>>
>
export type ExportOrdersToCsvMutationBody = OrdersCsvExportRequestV1
export type ExportOrdersToCsvMutationError = ErrorResponse

/**
 * @summary CSV export for SP orders
 */
export const useExportOrdersToCsv = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exportOrdersToCsv>>,
    TError,
    { data: OrdersCsvExportRequestV1 },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}) => {
  const mutationOptions = getExportOrdersToCsvMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get metadata for Structured Product Order filter criteria
 */
export const filterMetadataStructuredProductOrders = (
  ordersFilterMetadataRequest: OrdersFilterMetadataRequest[],
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  return fetchWithOverrides<OrdersFilterMetadataResult[]>(
    {
      url: `/api/structured-products/orders/v1/filter-metadata`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: ordersFilterMetadataRequest,
    },
    options
  )
}

export const getFilterMetadataStructuredProductOrdersQueryKey = (
  ordersFilterMetadataRequest: OrdersFilterMetadataRequest[]
) => {
  return [
    `/api/structured-products/orders/v1/filter-metadata`,
    ordersFilterMetadataRequest,
  ] as const
}

export const getFilterMetadataStructuredProductOrdersQueryOptions = <
  TData = Awaited<ReturnType<typeof filterMetadataStructuredProductOrders>>,
  TError = ErrorResponse
>(
  ordersFilterMetadataRequest: OrdersFilterMetadataRequest[],
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof filterMetadataStructuredProductOrders>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getFilterMetadataStructuredProductOrdersQueryKey(
      ordersFilterMetadataRequest
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof filterMetadataStructuredProductOrders>>
  > = () =>
    filterMetadataStructuredProductOrders(
      ordersFilterMetadataRequest,
      requestOptions
    )

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof filterMetadataStructuredProductOrders>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FilterMetadataStructuredProductOrdersQueryResult = NonNullable<
  Awaited<ReturnType<typeof filterMetadataStructuredProductOrders>>
>
export type FilterMetadataStructuredProductOrdersQueryError = ErrorResponse

/**
 * @summary Get metadata for Structured Product Order filter criteria
 */
export const useFilterMetadataStructuredProductOrders = <
  TData = Awaited<ReturnType<typeof filterMetadataStructuredProductOrders>>,
  TError = ErrorResponse
>(
  ordersFilterMetadataRequest: OrdersFilterMetadataRequest[],
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof filterMetadataStructuredProductOrders>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFilterMetadataStructuredProductOrdersQueryOptions(
    ordersFilterMetadataRequest,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Get metadata for Structured Product Order filter criteria as an Advisor
 */
export const filterMetadataStructuredProductOrdersForAdvisor = (
  ordersFilterMetadataRequest: OrdersFilterMetadataRequest[],
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  return fetchWithOverrides<OrdersFilterMetadataResult[]>(
    {
      url: `/api/structured-products/orders/advisors/v1/filter-metadata`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: ordersFilterMetadataRequest,
    },
    options
  )
}

export const getFilterMetadataStructuredProductOrdersForAdvisorQueryKey = (
  ordersFilterMetadataRequest: OrdersFilterMetadataRequest[]
) => {
  return [
    `/api/structured-products/orders/advisors/v1/filter-metadata`,
    ordersFilterMetadataRequest,
  ] as const
}

export const getFilterMetadataStructuredProductOrdersForAdvisorQueryOptions = <
  TData = Awaited<
    ReturnType<typeof filterMetadataStructuredProductOrdersForAdvisor>
  >,
  TError = ErrorResponse
>(
  ordersFilterMetadataRequest: OrdersFilterMetadataRequest[],
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof filterMetadataStructuredProductOrdersForAdvisor>
      >,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getFilterMetadataStructuredProductOrdersForAdvisorQueryKey(
      ordersFilterMetadataRequest
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof filterMetadataStructuredProductOrdersForAdvisor>>
  > = () =>
    filterMetadataStructuredProductOrdersForAdvisor(
      ordersFilterMetadataRequest,
      requestOptions
    )

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof filterMetadataStructuredProductOrdersForAdvisor>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FilterMetadataStructuredProductOrdersForAdvisorQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof filterMetadataStructuredProductOrdersForAdvisor>>
  >
export type FilterMetadataStructuredProductOrdersForAdvisorQueryError =
  ErrorResponse

/**
 * @summary Get metadata for Structured Product Order filter criteria as an Advisor
 */
export const useFilterMetadataStructuredProductOrdersForAdvisor = <
  TData = Awaited<
    ReturnType<typeof filterMetadataStructuredProductOrdersForAdvisor>
  >,
  TError = ErrorResponse
>(
  ordersFilterMetadataRequest: OrdersFilterMetadataRequest[],
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof filterMetadataStructuredProductOrdersForAdvisor>
      >,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getFilterMetadataStructuredProductOrdersForAdvisorQueryOptions(
      ordersFilterMetadataRequest,
      options
    )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Create a Structured Product Order as an Advisor
 */
export const createStructuredProductAdvisorOrder = (
  structuredProductOrderAdvisorRequest: StructuredProductOrderAdvisorRequest,
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  return fetchWithOverrides<StructuredProductOrderForAdvisorV1>(
    {
      url: `/api/structured-products/orders/advisors/v1`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: structuredProductOrderAdvisorRequest,
    },
    options
  )
}

export const getCreateStructuredProductAdvisorOrderMutationOptions = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createStructuredProductAdvisorOrder>>,
    TError,
    { data: StructuredProductOrderAdvisorRequest },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}): UseMutationOptions<
  Awaited<ReturnType<typeof createStructuredProductAdvisorOrder>>,
  TError,
  { data: StructuredProductOrderAdvisorRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createStructuredProductAdvisorOrder>>,
    { data: StructuredProductOrderAdvisorRequest }
  > = (props) => {
    const { data } = props ?? {}

    return createStructuredProductAdvisorOrder(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateStructuredProductAdvisorOrderMutationResult = NonNullable<
  Awaited<ReturnType<typeof createStructuredProductAdvisorOrder>>
>
export type CreateStructuredProductAdvisorOrderMutationBody =
  StructuredProductOrderAdvisorRequest
export type CreateStructuredProductAdvisorOrderMutationError = ErrorResponse

/**
 * @summary Create a Structured Product Order as an Advisor
 */
export const useCreateStructuredProductAdvisorOrder = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createStructuredProductAdvisorOrder>>,
    TError,
    { data: StructuredProductOrderAdvisorRequest },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}) => {
  const mutationOptions =
    getCreateStructuredProductAdvisorOrderMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Retrieve a Structured Product Order as an Advisor
 */
export const getStructuredProductOrderForAdvisor = (
  id: string,
  options?: SecondParameter<typeof fetchWithOverrides>,
  signal?: AbortSignal
) => {
  return fetchWithOverrides<StructuredProductOrderForAdvisorV1>(
    {
      url: `/api/structured-products/orders/advisors/v1/${id}`,
      method: 'GET',
      signal,
    },
    options
  )
}

export const getGetStructuredProductOrderForAdvisorQueryKey = (id: string) => {
  return [`/api/structured-products/orders/advisors/v1/${id}`] as const
}

export const getGetStructuredProductOrderForAdvisorQueryOptions = <
  TData = Awaited<ReturnType<typeof getStructuredProductOrderForAdvisor>>,
  TError = ErrorResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStructuredProductOrderForAdvisor>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetStructuredProductOrderForAdvisorQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getStructuredProductOrderForAdvisor>>
  > = ({ signal }) =>
    getStructuredProductOrderForAdvisor(id, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getStructuredProductOrderForAdvisor>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetStructuredProductOrderForAdvisorQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStructuredProductOrderForAdvisor>>
>
export type GetStructuredProductOrderForAdvisorQueryError = ErrorResponse

/**
 * @summary Retrieve a Structured Product Order as an Advisor
 */
export const useGetStructuredProductOrderForAdvisor = <
  TData = Awaited<ReturnType<typeof getStructuredProductOrderForAdvisor>>,
  TError = ErrorResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStructuredProductOrderForAdvisor>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetStructuredProductOrderForAdvisorQueryOptions(
    id,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Update a Structured Product Order as an Advisor
 */
export const updateStructuredProductOrderForAdvisor = (
  id: string,
  updateStructuredProductOrderRequestForAdvisor: UpdateStructuredProductOrderRequestForAdvisor,
  headers?: UpdateStructuredProductOrderForAdvisorHeaders,
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  return fetchWithOverrides<StructuredProductOrderForAdvisorV1>(
    {
      url: `/api/structured-products/orders/advisors/v1/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', ...headers },
      data: updateStructuredProductOrderRequestForAdvisor,
    },
    options
  )
}

export const getUpdateStructuredProductOrderForAdvisorMutationOptions = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStructuredProductOrderForAdvisor>>,
    TError,
    {
      id: string
      data: UpdateStructuredProductOrderRequestForAdvisor
      headers?: UpdateStructuredProductOrderForAdvisorHeaders
    },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateStructuredProductOrderForAdvisor>>,
  TError,
  {
    id: string
    data: UpdateStructuredProductOrderRequestForAdvisor
    headers?: UpdateStructuredProductOrderForAdvisorHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateStructuredProductOrderForAdvisor>>,
    {
      id: string
      data: UpdateStructuredProductOrderRequestForAdvisor
      headers?: UpdateStructuredProductOrderForAdvisorHeaders
    }
  > = (props) => {
    const { id, data, headers } = props ?? {}

    return updateStructuredProductOrderForAdvisor(
      id,
      data,
      headers,
      requestOptions
    )
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateStructuredProductOrderForAdvisorMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateStructuredProductOrderForAdvisor>>
>
export type UpdateStructuredProductOrderForAdvisorMutationBody =
  UpdateStructuredProductOrderRequestForAdvisor
export type UpdateStructuredProductOrderForAdvisorMutationError = ErrorResponse

/**
 * @summary Update a Structured Product Order as an Advisor
 */
export const useUpdateStructuredProductOrderForAdvisor = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStructuredProductOrderForAdvisor>>,
    TError,
    {
      id: string
      data: UpdateStructuredProductOrderRequestForAdvisor
      headers?: UpdateStructuredProductOrderForAdvisorHeaders
    },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}) => {
  const mutationOptions =
    getUpdateStructuredProductOrderForAdvisorMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Cancel a Structured Product Order as an Advisor
 */
export const cancelStructuredProductOrderForAdvisor = (
  id: string,
  headers?: CancelStructuredProductOrderForAdvisorHeaders,
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  return fetchWithOverrides<StructuredProductOrderForAdvisorV1>(
    {
      url: `/api/structured-products/orders/advisors/v1/${id}/cancel`,
      method: 'POST',
      headers,
    },
    options
  )
}

export const getCancelStructuredProductOrderForAdvisorMutationOptions = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelStructuredProductOrderForAdvisor>>,
    TError,
    { id: string; headers?: CancelStructuredProductOrderForAdvisorHeaders },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}): UseMutationOptions<
  Awaited<ReturnType<typeof cancelStructuredProductOrderForAdvisor>>,
  TError,
  { id: string; headers?: CancelStructuredProductOrderForAdvisorHeaders },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cancelStructuredProductOrderForAdvisor>>,
    { id: string; headers?: CancelStructuredProductOrderForAdvisorHeaders }
  > = (props) => {
    const { id, headers } = props ?? {}

    return cancelStructuredProductOrderForAdvisor(id, headers, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type CancelStructuredProductOrderForAdvisorMutationResult = NonNullable<
  Awaited<ReturnType<typeof cancelStructuredProductOrderForAdvisor>>
>

export type CancelStructuredProductOrderForAdvisorMutationError = ErrorResponse

/**
 * @summary Cancel a Structured Product Order as an Advisor
 */
export const useCancelStructuredProductOrderForAdvisor = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cancelStructuredProductOrderForAdvisor>>,
    TError,
    { id: string; headers?: CancelStructuredProductOrderForAdvisorHeaders },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}) => {
  const mutationOptions =
    getCancelStructuredProductOrderForAdvisorMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Search for Structured Product Orders as an Advisor
 */
export const searchStructuredProductOrdersForAdvisor = (
  structuredProductOrderSearchRequest: StructuredProductOrderSearchRequest,
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  return fetchWithOverrides<StructuredProductOrderExpandedAdvisorPageResult>(
    {
      url: `/api/structured-products/orders/advisors/v1/search`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: structuredProductOrderSearchRequest,
    },
    options
  )
}

export const getSearchStructuredProductOrdersForAdvisorQueryKey = (
  structuredProductOrderSearchRequest: StructuredProductOrderSearchRequest
) => {
  return [
    `/api/structured-products/orders/advisors/v1/search`,
    structuredProductOrderSearchRequest,
  ] as const
}

export const getSearchStructuredProductOrdersForAdvisorQueryOptions = <
  TData = Awaited<ReturnType<typeof searchStructuredProductOrdersForAdvisor>>,
  TError = ErrorResponse
>(
  structuredProductOrderSearchRequest: StructuredProductOrderSearchRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchStructuredProductOrdersForAdvisor>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getSearchStructuredProductOrdersForAdvisorQueryKey(
      structuredProductOrderSearchRequest
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchStructuredProductOrdersForAdvisor>>
  > = () =>
    searchStructuredProductOrdersForAdvisor(
      structuredProductOrderSearchRequest,
      requestOptions
    )

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchStructuredProductOrdersForAdvisor>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchStructuredProductOrdersForAdvisorQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchStructuredProductOrdersForAdvisor>>
>
export type SearchStructuredProductOrdersForAdvisorQueryError = ErrorResponse

/**
 * @summary Search for Structured Product Orders as an Advisor
 */
export const useSearchStructuredProductOrdersForAdvisor = <
  TData = Awaited<ReturnType<typeof searchStructuredProductOrdersForAdvisor>>,
  TError = ErrorResponse
>(
  structuredProductOrderSearchRequest: StructuredProductOrderSearchRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchStructuredProductOrdersForAdvisor>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchStructuredProductOrdersForAdvisorQueryOptions(
    structuredProductOrderSearchRequest,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary CSV export for SP advisor orders
 */
export const exportAdvisorOrdersToCsv = (
  ordersCsvExportRequestV1: OrdersCsvExportRequestV1,
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  return fetchWithOverrides<StructuredProductOrderCsvExportResult>(
    {
      url: `/api/structured-products/orders/advisors/v2/export-csv`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: ordersCsvExportRequestV1,
    },
    options
  )
}

export const getExportAdvisorOrdersToCsvMutationOptions = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exportAdvisorOrdersToCsv>>,
    TError,
    { data: OrdersCsvExportRequestV1 },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}): UseMutationOptions<
  Awaited<ReturnType<typeof exportAdvisorOrdersToCsv>>,
  TError,
  { data: OrdersCsvExportRequestV1 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof exportAdvisorOrdersToCsv>>,
    { data: OrdersCsvExportRequestV1 }
  > = (props) => {
    const { data } = props ?? {}

    return exportAdvisorOrdersToCsv(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type ExportAdvisorOrdersToCsvMutationResult = NonNullable<
  Awaited<ReturnType<typeof exportAdvisorOrdersToCsv>>
>
export type ExportAdvisorOrdersToCsvMutationBody = OrdersCsvExportRequestV1
export type ExportAdvisorOrdersToCsvMutationError = ErrorResponse

/**
 * @summary CSV export for SP advisor orders
 */
export const useExportAdvisorOrdersToCsv = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof exportAdvisorOrdersToCsv>>,
    TError,
    { data: OrdersCsvExportRequestV1 },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}) => {
  const mutationOptions = getExportAdvisorOrdersToCsvMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Create a Placeholder Structured Product
 */
export const createPlaceholderStructuredProduct = (
  createPlaceholderProductRequest: CreatePlaceholderProductRequest,
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  return fetchWithOverrides<PlaceholderStructuredProductV1>(
    {
      url: `/api/structured-products/placeholder-products/v1`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createPlaceholderProductRequest,
    },
    options
  )
}

export const getCreatePlaceholderStructuredProductMutationOptions = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPlaceholderStructuredProduct>>,
    TError,
    { data: CreatePlaceholderProductRequest },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPlaceholderStructuredProduct>>,
  TError,
  { data: CreatePlaceholderProductRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPlaceholderStructuredProduct>>,
    { data: CreatePlaceholderProductRequest }
  > = (props) => {
    const { data } = props ?? {}

    return createPlaceholderStructuredProduct(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreatePlaceholderStructuredProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPlaceholderStructuredProduct>>
>
export type CreatePlaceholderStructuredProductMutationBody =
  CreatePlaceholderProductRequest
export type CreatePlaceholderStructuredProductMutationError = ErrorResponse

/**
 * @summary Create a Placeholder Structured Product
 */
export const useCreatePlaceholderStructuredProduct = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPlaceholderStructuredProduct>>,
    TError,
    { data: CreatePlaceholderProductRequest },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}) => {
  const mutationOptions =
    getCreatePlaceholderStructuredProductMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Search for Placeholder Structured Products
 */
export const searchStructuredProductPlaceholders = (
  structuredProductPlaceholderSearchRequest: StructuredProductPlaceholderSearchRequest,
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  return fetchWithOverrides<StructuredProductPlaceholderPageResult>(
    {
      url: `/api/structured-products/placeholder-products/v1/search`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: structuredProductPlaceholderSearchRequest,
    },
    options
  )
}

export const getSearchStructuredProductPlaceholdersQueryKey = (
  structuredProductPlaceholderSearchRequest: StructuredProductPlaceholderSearchRequest
) => {
  return [
    `/api/structured-products/placeholder-products/v1/search`,
    structuredProductPlaceholderSearchRequest,
  ] as const
}

export const getSearchStructuredProductPlaceholdersQueryOptions = <
  TData = Awaited<ReturnType<typeof searchStructuredProductPlaceholders>>,
  TError = ErrorResponse
>(
  structuredProductPlaceholderSearchRequest: StructuredProductPlaceholderSearchRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchStructuredProductPlaceholders>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getSearchStructuredProductPlaceholdersQueryKey(
      structuredProductPlaceholderSearchRequest
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchStructuredProductPlaceholders>>
  > = () =>
    searchStructuredProductPlaceholders(
      structuredProductPlaceholderSearchRequest,
      requestOptions
    )

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchStructuredProductPlaceholders>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchStructuredProductPlaceholdersQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchStructuredProductPlaceholders>>
>
export type SearchStructuredProductPlaceholdersQueryError = ErrorResponse

/**
 * @summary Search for Placeholder Structured Products
 */
export const useSearchStructuredProductPlaceholders = <
  TData = Awaited<ReturnType<typeof searchStructuredProductPlaceholders>>,
  TError = ErrorResponse
>(
  structuredProductPlaceholderSearchRequest: StructuredProductPlaceholderSearchRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchStructuredProductPlaceholders>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchStructuredProductPlaceholdersQueryOptions(
    structuredProductPlaceholderSearchRequest,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Retrieve a Placeholder Structured Product
 */
export const getPlaceholderStructuredProduct = (
  id: string,
  options?: SecondParameter<typeof fetchWithOverrides>,
  signal?: AbortSignal
) => {
  return fetchWithOverrides<PlaceholderStructuredProductV1>(
    {
      url: `/api/structured-products/placeholder-products/v1/${id}`,
      method: 'GET',
      signal,
    },
    options
  )
}

export const getGetPlaceholderStructuredProductQueryKey = (id: string) => {
  return [`/api/structured-products/placeholder-products/v1/${id}`] as const
}

export const getGetPlaceholderStructuredProductQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlaceholderStructuredProduct>>,
  TError = ErrorResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPlaceholderStructuredProduct>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetPlaceholderStructuredProductQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPlaceholderStructuredProduct>>
  > = ({ signal }) =>
    getPlaceholderStructuredProduct(id, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlaceholderStructuredProduct>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPlaceholderStructuredProductQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPlaceholderStructuredProduct>>
>
export type GetPlaceholderStructuredProductQueryError = ErrorResponse

/**
 * @summary Retrieve a Placeholder Structured Product
 */
export const useGetPlaceholderStructuredProduct = <
  TData = Awaited<ReturnType<typeof getPlaceholderStructuredProduct>>,
  TError = ErrorResponse
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPlaceholderStructuredProduct>>,
      TError,
      TData
    >
    request?: SecondParameter<typeof fetchWithOverrides>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlaceholderStructuredProductQueryOptions(
    id,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Update a Placeholder Structured Product
 */
export const updatePlaceholderStructuredProduct = (
  id: string,
  updatePlaceholderProductRequest: UpdatePlaceholderProductRequest,
  headers?: UpdatePlaceholderStructuredProductHeaders,
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  return fetchWithOverrides<PlaceholderStructuredProductV1>(
    {
      url: `/api/structured-products/placeholder-products/v1/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', ...headers },
      data: updatePlaceholderProductRequest,
    },
    options
  )
}

export const getUpdatePlaceholderStructuredProductMutationOptions = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePlaceholderStructuredProduct>>,
    TError,
    {
      id: string
      data: UpdatePlaceholderProductRequest
      headers?: UpdatePlaceholderStructuredProductHeaders
    },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}): UseMutationOptions<
  Awaited<ReturnType<typeof updatePlaceholderStructuredProduct>>,
  TError,
  {
    id: string
    data: UpdatePlaceholderProductRequest
    headers?: UpdatePlaceholderStructuredProductHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updatePlaceholderStructuredProduct>>,
    {
      id: string
      data: UpdatePlaceholderProductRequest
      headers?: UpdatePlaceholderStructuredProductHeaders
    }
  > = (props) => {
    const { id, data, headers } = props ?? {}

    return updatePlaceholderStructuredProduct(id, data, headers, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdatePlaceholderStructuredProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePlaceholderStructuredProduct>>
>
export type UpdatePlaceholderStructuredProductMutationBody =
  UpdatePlaceholderProductRequest
export type UpdatePlaceholderStructuredProductMutationError = ErrorResponse

/**
 * @summary Update a Placeholder Structured Product
 */
export const useUpdatePlaceholderStructuredProduct = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updatePlaceholderStructuredProduct>>,
    TError,
    {
      id: string
      data: UpdatePlaceholderProductRequest
      headers?: UpdatePlaceholderStructuredProductHeaders
    },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}) => {
  const mutationOptions =
    getUpdatePlaceholderStructuredProductMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Retrieve the rolls for a product maturity event
 */
export const getRolls = (
  productId: string,
  date: string,
  params?: GetRollsParams,
  options?: SecondParameter<typeof fetchWithOverrides>,
  signal?: AbortSignal
) => {
  return fetchWithOverrides<RollV1[]>(
    {
      url: `/api/structured-products/rolls/v1/${productId}/${date}`,
      method: 'GET',
      params,
      signal,
    },
    options
  )
}

export const getGetRollsQueryKey = (
  productId: string,
  date: string,
  params?: GetRollsParams
) => {
  return [
    `/api/structured-products/rolls/v1/${productId}/${date}`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetRollsQueryOptions = <
  TData = Awaited<ReturnType<typeof getRolls>>,
  TError = ErrorResponse
>(
  productId: string,
  date: string,
  params?: GetRollsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getRolls>>, TError, TData>
    request?: SecondParameter<typeof fetchWithOverrides>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetRollsQueryKey(productId, date, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRolls>>> = ({
    signal,
  }) => getRolls(productId, date, params, requestOptions, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(productId && date),
    ...queryOptions,
  } as UseQueryOptions<Awaited<ReturnType<typeof getRolls>>, TError, TData> & {
    queryKey: QueryKey
  }
}

export type GetRollsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRolls>>
>
export type GetRollsQueryError = ErrorResponse

/**
 * @summary Retrieve the rolls for a product maturity event
 */
export const useGetRolls = <
  TData = Awaited<ReturnType<typeof getRolls>>,
  TError = ErrorResponse
>(
  productId: string,
  date: string,
  params?: GetRollsParams,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getRolls>>, TError, TData>
    request?: SecondParameter<typeof fetchWithOverrides>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRollsQueryOptions(productId, date, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Atomically update a list of rolls
 */
export const updateRollBatch = (
  batchUpdateRollRequestItem: BatchUpdateRollRequestItem[],
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  return fetchWithOverrides<RollV1[]>(
    {
      url: `/api/structured-products/rolls/v1/batch`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: batchUpdateRollRequestItem,
    },
    options
  )
}

export const getUpdateRollBatchMutationOptions = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateRollBatch>>,
    TError,
    { data: BatchUpdateRollRequestItem[] },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateRollBatch>>,
  TError,
  { data: BatchUpdateRollRequestItem[] },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateRollBatch>>,
    { data: BatchUpdateRollRequestItem[] }
  > = (props) => {
    const { data } = props ?? {}

    return updateRollBatch(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateRollBatchMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateRollBatch>>
>
export type UpdateRollBatchMutationBody = BatchUpdateRollRequestItem[]
export type UpdateRollBatchMutationError = ErrorResponse

/**
 * @summary Atomically update a list of rolls
 */
export const useUpdateRollBatch = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateRollBatch>>,
    TError,
    { data: BatchUpdateRollRequestItem[] },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}) => {
  const mutationOptions = getUpdateRollBatchMutationOptions(options)

  return useMutation(mutationOptions)
}

export const bulkUploadExtractForAdvisor = (
  bulkUploadExtractForAdvisorBody: BulkUploadExtractForAdvisorBody,
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  const formData = new FormData()
  formData.append('file', bulkUploadExtractForAdvisorBody.file)

  return fetchWithOverrides<BulkUploadExtractResponseForAdvisorV1>(
    {
      url: `/api/structured-products/orders/advisors/v1/bulk-upload/extract`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options
  )
}

export const getBulkUploadExtractForAdvisorMutationOptions = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkUploadExtractForAdvisor>>,
    TError,
    { data: BulkUploadExtractForAdvisorBody },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}): UseMutationOptions<
  Awaited<ReturnType<typeof bulkUploadExtractForAdvisor>>,
  TError,
  { data: BulkUploadExtractForAdvisorBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bulkUploadExtractForAdvisor>>,
    { data: BulkUploadExtractForAdvisorBody }
  > = (props) => {
    const { data } = props ?? {}

    return bulkUploadExtractForAdvisor(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type BulkUploadExtractForAdvisorMutationResult = NonNullable<
  Awaited<ReturnType<typeof bulkUploadExtractForAdvisor>>
>
export type BulkUploadExtractForAdvisorMutationBody =
  BulkUploadExtractForAdvisorBody
export type BulkUploadExtractForAdvisorMutationError = ErrorResponse

export const useBulkUploadExtractForAdvisor = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkUploadExtractForAdvisor>>,
    TError,
    { data: BulkUploadExtractForAdvisorBody },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}) => {
  const mutationOptions = getBulkUploadExtractForAdvisorMutationOptions(options)

  return useMutation(mutationOptions)
}

export const bulkUploadCreateForAdvisor = (
  bulkUploadCreateRequestForAdvisorV1: BulkUploadCreateRequestForAdvisorV1,
  options?: SecondParameter<typeof fetchWithOverrides>
) => {
  return fetchWithOverrides<BulkUploadCreateResponseForAdvisorV1>(
    {
      url: `/api/structured-products/orders/advisors/v1/bulk-upload/create`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: bulkUploadCreateRequestForAdvisorV1,
    },
    options
  )
}

export const getBulkUploadCreateForAdvisorMutationOptions = <
  TError = BulkUploadCreateForAdvisor400 | ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkUploadCreateForAdvisor>>,
    TError,
    { data: BulkUploadCreateRequestForAdvisorV1 },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}): UseMutationOptions<
  Awaited<ReturnType<typeof bulkUploadCreateForAdvisor>>,
  TError,
  { data: BulkUploadCreateRequestForAdvisorV1 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bulkUploadCreateForAdvisor>>,
    { data: BulkUploadCreateRequestForAdvisorV1 }
  > = (props) => {
    const { data } = props ?? {}

    return bulkUploadCreateForAdvisor(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type BulkUploadCreateForAdvisorMutationResult = NonNullable<
  Awaited<ReturnType<typeof bulkUploadCreateForAdvisor>>
>
export type BulkUploadCreateForAdvisorMutationBody =
  BulkUploadCreateRequestForAdvisorV1
export type BulkUploadCreateForAdvisorMutationError =
  | BulkUploadCreateForAdvisor400
  | ErrorResponse

export const useBulkUploadCreateForAdvisor = <
  TError = BulkUploadCreateForAdvisor400 | ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkUploadCreateForAdvisor>>,
    TError,
    { data: BulkUploadCreateRequestForAdvisorV1 },
    TContext
  >
  request?: SecondParameter<typeof fetchWithOverrides>
}) => {
  const mutationOptions = getBulkUploadCreateForAdvisorMutationOptions(options)

  return useMutation(mutationOptions)
}
