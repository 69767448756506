import cx from 'classnames'

import { Avatar } from '@cais-group/equity/atoms/avatar'
import { HighlightedText } from '@cais-group/equity/molecules/list-item'
import { AccountListItemV1 } from '@cais-group/shared/domain/investor-profile'

type AccountCardProps = {
  account: AccountListItemV1
  isSelected?: boolean
  onClick?: () => void
  highlight?: string
}

export function AccountCard({
  account,
  isSelected,
  onClick,
  highlight,
}: AccountCardProps) {
  return (
    <div
      key={account.account.id}
      className={cx(
        'hover:shadow-2 flex h-[200px]  cursor-pointer flex-col items-start gap-x-8 border-2 p-24',
        {
          'border-primary-600 bg-primary-100': isSelected,
        }
      )}
      onClick={onClick}
    >
      <div className="flex items-center justify-center">
        <Avatar type="group">{account.account.name.trim().toString()}</Avatar>
      </div>
      <div className="flex grow flex-col justify-end">
        <div className="headline-s-strong">
          {' '}
          <HighlightedText
            isSearchable
            textToHighlight={highlight}
            inputText={account.account.name}
            includeWrapper
          />
        </div>
        <div className="body text-neutral-600">{account.account.id}</div>
      </div>
    </div>
  )
}
