/**
 * Returns the intersection point of a line and a circle (if it exists), if two return first one in direction of a to b.
 *
 * @param a The first point
 * @param b The second point
 * @param radius The radius of the circle
 * @param center The center of the circle
 * @returns The intersection point or null if there is no intersection.
 */
export function circleIntersection({
  a,
  b,
  radius,
  center,
}: {
  a: [number, number]
  b: [number, number]
  radius: number
  center: [number, number]
}): [number, number] | null {
  // work with relative coordinates (as if center is 0,0; as makes the maths easier); but we do need to transfer back
  const [x1, y1] = [a[0] - center[0], a[1] - center[1]]
  const [x2, y2] = [b[0] - center[0], b[1] - center[1]]

  const dX = x2 - x1
  const dY = y2 - y1
  const dRSq = dX * dX + dY * dY
  const dR = Math.sqrt(dRSq)
  const determinant = x1 * y2 - x2 * y1
  const discriminant = radius * radius * dR * dR - determinant * determinant

  if (discriminant < 0) {
    return null
  } else if (discriminant === 0) {
    return withinOrMakeNull({
      candidate: [
        (determinant * dY) / dRSq + center[0],
        (-determinant * dX) / dRSq + center[1],
      ],
      a,
      b,
    })
  } else {
    const sign = dY < 0 ? -1 : 1
    const iX1 = (determinant * dY + sign * dX * Math.sqrt(discriminant)) / dRSq
    const iY1 =
      (-determinant * dX + Math.abs(dY) * Math.sqrt(discriminant)) / dRSq

    const iX2 = (determinant * dY - sign * dX * Math.sqrt(discriminant)) / dRSq
    const iY2 =
      (-determinant * dX - Math.abs(dY) * Math.sqrt(discriminant)) / dRSq

    // minor optimization: compare squared distances instead of distances
    const distanceSq1 = (x1 - iX1) * (x1 - iX1) + (y1 - iY1) * (y1 - iY1)
    const distanceSq2 = (x1 - iX2) * (x1 - iX2) + (y1 - iY2) * (y1 - iY2)

    return distanceSq1 < distanceSq2
      ? withinOrMakeNull({
          candidate: [iX1 + center[0], iY1 + center[1]],
          a,
          b,
        })
      : withinOrMakeNull({
          candidate: [iX2 + center[0], iY2 + center[1]],
          a,
          b,
        })
  }
}

// This should be okay for use with above (isn't really checking a point is on the line, but within bounding box; but for our use fine as we are only want to use it to check if the intersection point is on the line segment itself not infinite line). Actually we might not really need to do this in our case (as we are drawing line from outside circle to approximate centroid of a slice... so closest intersection to starting point will be on the line segment anyway)
export function withinOrMakeNull({
  candidate,
  a,
  b,
}: {
  candidate: [number, number]
  a: [number, number]
  b: [number, number]
}): [number, number] | null {
  const minX = Math.min(a[0], b[0])
  const maxX = Math.max(a[0], b[0])
  const minY = Math.min(a[1], b[1])
  const maxY = Math.max(a[1], b[1])

  if (
    candidate[0] >= minX &&
    candidate[0] <= maxX &&
    candidate[1] >= minY &&
    candidate[1] <= maxY
  ) {
    return candidate
  } else {
    return null
  }
}
