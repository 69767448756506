/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Structured Product Positions API
 * OpenAPI spec version: v1
 */

import { useMutation, useQuery } from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'

import { fetchInstance, ErrorType } from '@cais-group/shared/util/fetch'

import type {
  CreatePositionRequest,
  CsvExportResult,
  ErrorResponse,
  PageResultAggregatedExpandedPositionDtoV1,
  PageResultExpandedStructuredProductPositionDto,
  PatchRequestBodyBody,
  PositionHoldersFilterMetadataResult,
  PositionSearchAggregatedDynamicRequest,
  PositionSearchAggregatedDynamicResponse,
  PositionStatsRequest,
  PositionStatsResponse,
  PositionUploadDto,
  PositionsFilterMetadataRequest,
  PositionsFilterMetadataResult,
  PositionsMarketValueSummaryRequest,
  PositionsMarketValueSummaryResponse,
  PositionsSummaryRequest,
  PositionsSummaryResponse,
  PositionsTeamsIdsPerProductRequest,
  PositionsTeamsIdsPerProductResponse,
  SearchRequestPositionsFilterPositionsSort,
  StructuredProductPositionV1,
  StructuredProductRedemptionStatsRequest,
  StructuredProductRedemptionStatsResponse,
  UploadPositionsBody,
} from './api.schemas'

/**
 * @summary Create Position
 */
export const createPosition = (
  createPositionRequest: CreatePositionRequest
) => {
  return fetchInstance<StructuredProductPositionV1>({
    url: `/api/positions/v1`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createPositionRequest,
  })
}

export const getCreatePositionMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPosition>>,
    TError,
    { data: CreatePositionRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPosition>>,
  TError,
  { data: CreatePositionRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPosition>>,
    { data: CreatePositionRequest }
  > = (props) => {
    const { data } = props ?? {}

    return createPosition(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreatePositionMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPosition>>
>
export type CreatePositionMutationBody = CreatePositionRequest
export type CreatePositionMutationError = ErrorType<ErrorResponse>

/**
 * @summary Create Position
 */
export const useCreatePosition = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPosition>>,
    TError,
    { data: CreatePositionRequest },
    TContext
  >
}) => {
  const mutationOptions = getCreatePositionMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Upload a CSV file of SP positions
 */
export const uploadPositions = (uploadPositionsBody: UploadPositionsBody) => {
  const formData = new FormData()
  formData.append('file', uploadPositionsBody.file)

  return fetchInstance<PositionUploadDto>({
    url: `/api/positions/v1/upload`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  })
}

export const getUploadPositionsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadPositions>>,
    TError,
    { data: UploadPositionsBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof uploadPositions>>,
  TError,
  { data: UploadPositionsBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof uploadPositions>>,
    { data: UploadPositionsBody }
  > = (props) => {
    const { data } = props ?? {}

    return uploadPositions(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UploadPositionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof uploadPositions>>
>
export type UploadPositionsMutationBody = UploadPositionsBody
export type UploadPositionsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Upload a CSV file of SP positions
 */
export const useUploadPositions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof uploadPositions>>,
    TError,
    { data: UploadPositionsBody },
    TContext
  >
}) => {
  const mutationOptions = getUploadPositionsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get a summary of SP positions
 */
export const getPositionsSummary = (
  positionsSummaryRequest: PositionsSummaryRequest
) => {
  return fetchInstance<PositionsSummaryResponse>({
    url: `/api/positions/v1/summary`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: positionsSummaryRequest,
  })
}

export const getGetPositionsSummaryQueryKey = (
  positionsSummaryRequest: PositionsSummaryRequest
) => {
  return [`/api/positions/v1/summary`, positionsSummaryRequest] as const
}

export const getGetPositionsSummaryQueryOptions = <
  TData = Awaited<ReturnType<typeof getPositionsSummary>>,
  TError = ErrorType<ErrorResponse>
>(
  positionsSummaryRequest: PositionsSummaryRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPositionsSummary>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPositionsSummaryQueryKey(positionsSummaryRequest)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPositionsSummary>>
  > = () => getPositionsSummary(positionsSummaryRequest)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPositionsSummary>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPositionsSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPositionsSummary>>
>
export type GetPositionsSummaryQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get a summary of SP positions
 */
export const useGetPositionsSummary = <
  TData = Awaited<ReturnType<typeof getPositionsSummary>>,
  TError = ErrorType<ErrorResponse>
>(
  positionsSummaryRequest: PositionsSummaryRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPositionsSummary>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPositionsSummaryQueryOptions(
    positionsSummaryRequest,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Get the market value summary of Positions
 */
export const getPositionsMarketValueSummary = (
  positionsMarketValueSummaryRequest: PositionsMarketValueSummaryRequest
) => {
  return fetchInstance<PositionsMarketValueSummaryResponse>({
    url: `/api/positions/v1/summary/market-value`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: positionsMarketValueSummaryRequest,
  })
}

export const getGetPositionsMarketValueSummaryMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getPositionsMarketValueSummary>>,
    TError,
    { data: PositionsMarketValueSummaryRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof getPositionsMarketValueSummary>>,
  TError,
  { data: PositionsMarketValueSummaryRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getPositionsMarketValueSummary>>,
    { data: PositionsMarketValueSummaryRequest }
  > = (props) => {
    const { data } = props ?? {}

    return getPositionsMarketValueSummary(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type GetPositionsMarketValueSummaryMutationResult = NonNullable<
  Awaited<ReturnType<typeof getPositionsMarketValueSummary>>
>
export type GetPositionsMarketValueSummaryMutationBody =
  PositionsMarketValueSummaryRequest
export type GetPositionsMarketValueSummaryMutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Get the market value summary of Positions
 */
export const useGetPositionsMarketValueSummary = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getPositionsMarketValueSummary>>,
    TError,
    { data: PositionsMarketValueSummaryRequest },
    TContext
  >
}) => {
  const mutationOptions =
    getGetPositionsMarketValueSummaryMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get the count, aggregated notional and redemption value of Positions
 */
export const getPositionStats = (
  positionStatsRequest: PositionStatsRequest
) => {
  return fetchInstance<PositionStatsResponse>({
    url: `/api/positions/v1/summary/position-stats`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: positionStatsRequest,
  })
}

export const getGetPositionStatsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getPositionStats>>,
    TError,
    { data: PositionStatsRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof getPositionStats>>,
  TError,
  { data: PositionStatsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getPositionStats>>,
    { data: PositionStatsRequest }
  > = (props) => {
    const { data } = props ?? {}

    return getPositionStats(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type GetPositionStatsMutationResult = NonNullable<
  Awaited<ReturnType<typeof getPositionStats>>
>
export type GetPositionStatsMutationBody = PositionStatsRequest
export type GetPositionStatsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Get the count, aggregated notional and redemption value of Positions
 */
export const useGetPositionStats = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getPositionStats>>,
    TError,
    { data: PositionStatsRequest },
    TContext
  >
}) => {
  const mutationOptions = getGetPositionStatsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get the summary stats of position redemptions along with per day breakdown
 */
export const getRedemptionStats = (
  structuredProductRedemptionStatsRequest: StructuredProductRedemptionStatsRequest
) => {
  return fetchInstance<StructuredProductRedemptionStatsResponse>({
    url: `/api/positions/v1/lifecycle/redemption-stats`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: structuredProductRedemptionStatsRequest,
  })
}

export const getGetRedemptionStatsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getRedemptionStats>>,
    TError,
    { data: StructuredProductRedemptionStatsRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof getRedemptionStats>>,
  TError,
  { data: StructuredProductRedemptionStatsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getRedemptionStats>>,
    { data: StructuredProductRedemptionStatsRequest }
  > = (props) => {
    const { data } = props ?? {}

    return getRedemptionStats(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type GetRedemptionStatsMutationResult = NonNullable<
  Awaited<ReturnType<typeof getRedemptionStats>>
>
export type GetRedemptionStatsMutationBody =
  StructuredProductRedemptionStatsRequest
export type GetRedemptionStatsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Get the summary stats of position redemptions along with per day breakdown
 */
export const useGetRedemptionStats = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getRedemptionStats>>,
    TError,
    { data: StructuredProductRedemptionStatsRequest },
    TContext
  >
}) => {
  const mutationOptions = getGetRedemptionStatsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Search for positions
 */
export const positionsSearch = (
  searchRequestPositionsFilterPositionsSort: SearchRequestPositionsFilterPositionsSort
) => {
  return fetchInstance<PageResultExpandedStructuredProductPositionDto>({
    url: `/api/positions/v1/structured-products/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchRequestPositionsFilterPositionsSort,
  })
}

export const getPositionsSearchMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof positionsSearch>>,
    TError,
    { data: SearchRequestPositionsFilterPositionsSort },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof positionsSearch>>,
  TError,
  { data: SearchRequestPositionsFilterPositionsSort },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof positionsSearch>>,
    { data: SearchRequestPositionsFilterPositionsSort }
  > = (props) => {
    const { data } = props ?? {}

    return positionsSearch(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PositionsSearchMutationResult = NonNullable<
  Awaited<ReturnType<typeof positionsSearch>>
>
export type PositionsSearchMutationBody =
  SearchRequestPositionsFilterPositionsSort
export type PositionsSearchMutationError = ErrorType<ErrorResponse>

/**
 * @summary Search for positions
 */
export const usePositionsSearch = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof positionsSearch>>,
    TError,
    { data: SearchRequestPositionsFilterPositionsSort },
    TContext
  >
}) => {
  const mutationOptions = getPositionsSearchMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @deprecated
 * @summary Search for positions aggregated by product
 */
export const positionSearchAggregatedByProduct = (
  searchRequestPositionsFilterPositionsSort: SearchRequestPositionsFilterPositionsSort
) => {
  return fetchInstance<PageResultAggregatedExpandedPositionDtoV1>({
    url: `/api/structured-products/positions/v1/product-aggregates/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchRequestPositionsFilterPositionsSort,
  })
}

export const getPositionSearchAggregatedByProductQueryKey = (
  searchRequestPositionsFilterPositionsSort: SearchRequestPositionsFilterPositionsSort
) => {
  return [
    `/api/structured-products/positions/v1/product-aggregates/search`,
    searchRequestPositionsFilterPositionsSort,
  ] as const
}

export const getPositionSearchAggregatedByProductQueryOptions = <
  TData = Awaited<ReturnType<typeof positionSearchAggregatedByProduct>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestPositionsFilterPositionsSort: SearchRequestPositionsFilterPositionsSort,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof positionSearchAggregatedByProduct>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getPositionSearchAggregatedByProductQueryKey(
      searchRequestPositionsFilterPositionsSort
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof positionSearchAggregatedByProduct>>
  > = () =>
    positionSearchAggregatedByProduct(searchRequestPositionsFilterPositionsSort)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof positionSearchAggregatedByProduct>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type PositionSearchAggregatedByProductQueryResult = NonNullable<
  Awaited<ReturnType<typeof positionSearchAggregatedByProduct>>
>
export type PositionSearchAggregatedByProductQueryError =
  ErrorType<ErrorResponse>

/**
 * @deprecated
 * @summary Search for positions aggregated by product
 */
export const usePositionSearchAggregatedByProduct = <
  TData = Awaited<ReturnType<typeof positionSearchAggregatedByProduct>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestPositionsFilterPositionsSort: SearchRequestPositionsFilterPositionsSort,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof positionSearchAggregatedByProduct>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPositionSearchAggregatedByProductQueryOptions(
    searchRequestPositionsFilterPositionsSort,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Export positions aggregated by product
 */
export const positionExportCsvAggregatedByProduct = (
  searchRequestPositionsFilterPositionsSort: SearchRequestPositionsFilterPositionsSort
) => {
  return fetchInstance<CsvExportResult>({
    url: `/api/structured-products/positions/v1/product-aggregates/export-csv`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchRequestPositionsFilterPositionsSort,
  })
}

export const getPositionExportCsvAggregatedByProductQueryKey = (
  searchRequestPositionsFilterPositionsSort: SearchRequestPositionsFilterPositionsSort
) => {
  return [
    `/api/structured-products/positions/v1/product-aggregates/export-csv`,
    searchRequestPositionsFilterPositionsSort,
  ] as const
}

export const getPositionExportCsvAggregatedByProductQueryOptions = <
  TData = Awaited<ReturnType<typeof positionExportCsvAggregatedByProduct>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestPositionsFilterPositionsSort: SearchRequestPositionsFilterPositionsSort,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof positionExportCsvAggregatedByProduct>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getPositionExportCsvAggregatedByProductQueryKey(
      searchRequestPositionsFilterPositionsSort
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof positionExportCsvAggregatedByProduct>>
  > = () =>
    positionExportCsvAggregatedByProduct(
      searchRequestPositionsFilterPositionsSort
    )

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof positionExportCsvAggregatedByProduct>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type PositionExportCsvAggregatedByProductQueryResult = NonNullable<
  Awaited<ReturnType<typeof positionExportCsvAggregatedByProduct>>
>
export type PositionExportCsvAggregatedByProductQueryError =
  ErrorType<ErrorResponse>

/**
 * @summary Export positions aggregated by product
 */
export const usePositionExportCsvAggregatedByProduct = <
  TData = Awaited<ReturnType<typeof positionExportCsvAggregatedByProduct>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestPositionsFilterPositionsSort: SearchRequestPositionsFilterPositionsSort,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof positionExportCsvAggregatedByProduct>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPositionExportCsvAggregatedByProductQueryOptions(
    searchRequestPositionsFilterPositionsSort,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Search for positions aggregated dynamic
 */
export const positionSearchAggregatedDynamic = (
  positionSearchAggregatedDynamicRequest: PositionSearchAggregatedDynamicRequest
) => {
  return fetchInstance<PositionSearchAggregatedDynamicResponse>({
    url: `/api/structured-products/positions/v1/dynamic-aggregates/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: positionSearchAggregatedDynamicRequest,
  })
}

export const getPositionSearchAggregatedDynamicQueryKey = (
  positionSearchAggregatedDynamicRequest: PositionSearchAggregatedDynamicRequest
) => {
  return [
    `/api/structured-products/positions/v1/dynamic-aggregates/search`,
    positionSearchAggregatedDynamicRequest,
  ] as const
}

export const getPositionSearchAggregatedDynamicQueryOptions = <
  TData = Awaited<ReturnType<typeof positionSearchAggregatedDynamic>>,
  TError = ErrorType<ErrorResponse>
>(
  positionSearchAggregatedDynamicRequest: PositionSearchAggregatedDynamicRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof positionSearchAggregatedDynamic>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getPositionSearchAggregatedDynamicQueryKey(
      positionSearchAggregatedDynamicRequest
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof positionSearchAggregatedDynamic>>
  > = () =>
    positionSearchAggregatedDynamic(positionSearchAggregatedDynamicRequest)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof positionSearchAggregatedDynamic>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type PositionSearchAggregatedDynamicQueryResult = NonNullable<
  Awaited<ReturnType<typeof positionSearchAggregatedDynamic>>
>
export type PositionSearchAggregatedDynamicQueryError = ErrorType<ErrorResponse>

/**
 * @summary Search for positions aggregated dynamic
 */
export const usePositionSearchAggregatedDynamic = <
  TData = Awaited<ReturnType<typeof positionSearchAggregatedDynamic>>,
  TError = ErrorType<ErrorResponse>
>(
  positionSearchAggregatedDynamicRequest: PositionSearchAggregatedDynamicRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof positionSearchAggregatedDynamic>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPositionSearchAggregatedDynamicQueryOptions(
    positionSearchAggregatedDynamicRequest,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Get filter metadata for positions
 */
export const positionsFilterMetadata = (
  positionsFilterMetadataRequest: PositionsFilterMetadataRequest
) => {
  return fetchInstance<PositionsFilterMetadataResult>({
    url: `/api/positions/v1/filterMetadata`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: positionsFilterMetadataRequest,
  })
}

export const getPositionsFilterMetadataMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof positionsFilterMetadata>>,
    TError,
    { data: PositionsFilterMetadataRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof positionsFilterMetadata>>,
  TError,
  { data: PositionsFilterMetadataRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof positionsFilterMetadata>>,
    { data: PositionsFilterMetadataRequest }
  > = (props) => {
    const { data } = props ?? {}

    return positionsFilterMetadata(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PositionsFilterMetadataMutationResult = NonNullable<
  Awaited<ReturnType<typeof positionsFilterMetadata>>
>
export type PositionsFilterMetadataMutationBody = PositionsFilterMetadataRequest
export type PositionsFilterMetadataMutationError = ErrorType<ErrorResponse>

/**
 * @summary Get filter metadata for positions
 */
export const usePositionsFilterMetadata = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof positionsFilterMetadata>>,
    TError,
    { data: PositionsFilterMetadataRequest },
    TContext
  >
}) => {
  const mutationOptions = getPositionsFilterMetadataMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get filter metadata for position holders
 */
export const positionHoldersFilterMetadata = (
  positionsFilterMetadataRequest: PositionsFilterMetadataRequest
) => {
  return fetchInstance<PositionHoldersFilterMetadataResult>({
    url: `/api/positions/v1/holderFilterMetadata`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: positionsFilterMetadataRequest,
  })
}

export const getPositionHoldersFilterMetadataMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof positionHoldersFilterMetadata>>,
    TError,
    { data: PositionsFilterMetadataRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof positionHoldersFilterMetadata>>,
  TError,
  { data: PositionsFilterMetadataRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof positionHoldersFilterMetadata>>,
    { data: PositionsFilterMetadataRequest }
  > = (props) => {
    const { data } = props ?? {}

    return positionHoldersFilterMetadata(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PositionHoldersFilterMetadataMutationResult = NonNullable<
  Awaited<ReturnType<typeof positionHoldersFilterMetadata>>
>
export type PositionHoldersFilterMetadataMutationBody =
  PositionsFilterMetadataRequest
export type PositionHoldersFilterMetadataMutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Get filter metadata for position holders
 */
export const usePositionHoldersFilterMetadata = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof positionHoldersFilterMetadata>>,
    TError,
    { data: PositionsFilterMetadataRequest },
    TContext
  >
}) => {
  const mutationOptions =
    getPositionHoldersFilterMetadataMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get filter metadata for position holders as a List
 */
export const positionHoldersFilterMetadataList = (
  positionsFilterMetadataRequest: PositionsFilterMetadataRequest[]
) => {
  return fetchInstance<PositionHoldersFilterMetadataResult[]>({
    url: `/api/positions/v1/holderFilterMetadataList`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: positionsFilterMetadataRequest,
  })
}

export const getPositionHoldersFilterMetadataListQueryKey = (
  positionsFilterMetadataRequest: PositionsFilterMetadataRequest[]
) => {
  return [
    `/api/positions/v1/holderFilterMetadataList`,
    positionsFilterMetadataRequest,
  ] as const
}

export const getPositionHoldersFilterMetadataListQueryOptions = <
  TData = Awaited<ReturnType<typeof positionHoldersFilterMetadataList>>,
  TError = ErrorType<ErrorResponse>
>(
  positionsFilterMetadataRequest: PositionsFilterMetadataRequest[],
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof positionHoldersFilterMetadataList>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getPositionHoldersFilterMetadataListQueryKey(positionsFilterMetadataRequest)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof positionHoldersFilterMetadataList>>
  > = () => positionHoldersFilterMetadataList(positionsFilterMetadataRequest)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof positionHoldersFilterMetadataList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type PositionHoldersFilterMetadataListQueryResult = NonNullable<
  Awaited<ReturnType<typeof positionHoldersFilterMetadataList>>
>
export type PositionHoldersFilterMetadataListQueryError =
  ErrorType<ErrorResponse>

/**
 * @summary Get filter metadata for position holders as a List
 */
export const usePositionHoldersFilterMetadataList = <
  TData = Awaited<ReturnType<typeof positionHoldersFilterMetadataList>>,
  TError = ErrorType<ErrorResponse>
>(
  positionsFilterMetadataRequest: PositionsFilterMetadataRequest[],
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof positionHoldersFilterMetadataList>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPositionHoldersFilterMetadataListQueryOptions(
    positionsFilterMetadataRequest,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Get filter metadata list for positions
 */
export const positionsFilterMetadataList = (
  positionsFilterMetadataRequest: PositionsFilterMetadataRequest[]
) => {
  return fetchInstance<PositionsFilterMetadataResult[]>({
    url: `/api/positions/v1/filterMetadataList`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: positionsFilterMetadataRequest,
  })
}

export const getPositionsFilterMetadataListQueryKey = (
  positionsFilterMetadataRequest: PositionsFilterMetadataRequest[]
) => {
  return [
    `/api/positions/v1/filterMetadataList`,
    positionsFilterMetadataRequest,
  ] as const
}

export const getPositionsFilterMetadataListQueryOptions = <
  TData = Awaited<ReturnType<typeof positionsFilterMetadataList>>,
  TError = ErrorType<ErrorResponse>
>(
  positionsFilterMetadataRequest: PositionsFilterMetadataRequest[],
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof positionsFilterMetadataList>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getPositionsFilterMetadataListQueryKey(positionsFilterMetadataRequest)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof positionsFilterMetadataList>>
  > = () => positionsFilterMetadataList(positionsFilterMetadataRequest)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof positionsFilterMetadataList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type PositionsFilterMetadataListQueryResult = NonNullable<
  Awaited<ReturnType<typeof positionsFilterMetadataList>>
>
export type PositionsFilterMetadataListQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get filter metadata list for positions
 */
export const usePositionsFilterMetadataList = <
  TData = Awaited<ReturnType<typeof positionsFilterMetadataList>>,
  TError = ErrorType<ErrorResponse>
>(
  positionsFilterMetadataRequest: PositionsFilterMetadataRequest[],
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof positionsFilterMetadataList>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPositionsFilterMetadataListQueryOptions(
    positionsFilterMetadataRequest,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Get a Position by id
 */
export const getPositionById = (id: string, signal?: AbortSignal) => {
  return fetchInstance<StructuredProductPositionV1>({
    url: `/api/positions/v1/${id}`,
    method: 'GET',
    signal,
  })
}

export const getGetPositionByIdQueryKey = (id: string) => {
  return [`/api/positions/v1/${id}`] as const
}

export const getGetPositionByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPositionById>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPositionById>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPositionByIdQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPositionById>>> = ({
    signal,
  }) => getPositionById(id, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPositionById>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPositionByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPositionById>>
>
export type GetPositionByIdQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get a Position by id
 */
export const useGetPositionById = <
  TData = Awaited<ReturnType<typeof getPositionById>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPositionById>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPositionByIdQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Delete a Position by id
 */
export const deletePosition = (id: string) => {
  return fetchInstance<void>({
    url: `/api/positions/v1/${id}`,
    method: 'DELETE',
  })
}

export const getDeletePositionMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePosition>>,
    TError,
    { id: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePosition>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePosition>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {}

    return deletePosition(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeletePositionMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePosition>>
>

export type DeletePositionMutationError = ErrorType<ErrorResponse>

/**
 * @summary Delete a Position by id
 */
export const useDeletePosition = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePosition>>,
    TError,
    { id: string },
    TContext
  >
}) => {
  const mutationOptions = getDeletePositionMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Update a Position with a Patch
 */
export const patchUpdatePosition = (
  id: string,
  patchRequestBodyBody: PatchRequestBodyBody
) => {
  return fetchInstance<StructuredProductPositionV1>({
    url: `/api/positions/v1/${id}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchRequestBodyBody,
  })
}

export const getPatchUpdatePositionMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUpdatePosition>>,
    TError,
    { id: string; data: PatchRequestBodyBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchUpdatePosition>>,
  TError,
  { id: string; data: PatchRequestBodyBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchUpdatePosition>>,
    { id: string; data: PatchRequestBodyBody }
  > = (props) => {
    const { id, data } = props ?? {}

    return patchUpdatePosition(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchUpdatePositionMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchUpdatePosition>>
>
export type PatchUpdatePositionMutationBody = PatchRequestBodyBody
export type PatchUpdatePositionMutationError = ErrorType<ErrorResponse>

/**
 * @summary Update a Position with a Patch
 */
export const usePatchUpdatePosition = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchUpdatePosition>>,
    TError,
    { id: string; data: PatchRequestBodyBody },
    TContext
  >
}) => {
  const mutationOptions = getPatchUpdatePositionMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get the statuses of position uploads
 */
export const positionsUploadsStatuses = (signal?: AbortSignal) => {
  return fetchInstance<PositionUploadDto[]>({
    url: `/api/positions/v1/upload/status`,
    method: 'GET',
    signal,
  })
}

export const getPositionsUploadsStatusesQueryKey = () => {
  return [`/api/positions/v1/upload/status`] as const
}

export const getPositionsUploadsStatusesQueryOptions = <
  TData = Awaited<ReturnType<typeof positionsUploadsStatuses>>,
  TError = ErrorType<ErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof positionsUploadsStatuses>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getPositionsUploadsStatusesQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof positionsUploadsStatuses>>
  > = ({ signal }) => positionsUploadsStatuses(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof positionsUploadsStatuses>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type PositionsUploadsStatusesQueryResult = NonNullable<
  Awaited<ReturnType<typeof positionsUploadsStatuses>>
>
export type PositionsUploadsStatusesQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get the statuses of position uploads
 */
export const usePositionsUploadsStatuses = <
  TData = Awaited<ReturnType<typeof positionsUploadsStatuses>>,
  TError = ErrorType<ErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof positionsUploadsStatuses>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPositionsUploadsStatusesQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Get the IDs of teams holding a given product
 */
export const teamIdsPerProduct = (
  positionsTeamsIdsPerProductRequest: PositionsTeamsIdsPerProductRequest
) => {
  return fetchInstance<PositionsTeamsIdsPerProductResponse>({
    url: `/api/positions/v1/team-ids-per-product`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: positionsTeamsIdsPerProductRequest,
  })
}

export const getTeamIdsPerProductMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamIdsPerProduct>>,
    TError,
    { data: PositionsTeamsIdsPerProductRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof teamIdsPerProduct>>,
  TError,
  { data: PositionsTeamsIdsPerProductRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof teamIdsPerProduct>>,
    { data: PositionsTeamsIdsPerProductRequest }
  > = (props) => {
    const { data } = props ?? {}

    return teamIdsPerProduct(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type TeamIdsPerProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof teamIdsPerProduct>>
>
export type TeamIdsPerProductMutationBody = PositionsTeamsIdsPerProductRequest
export type TeamIdsPerProductMutationError = ErrorType<ErrorResponse>

/**
 * @summary Get the IDs of teams holding a given product
 */
export const useTeamIdsPerProduct = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof teamIdsPerProduct>>,
    TError,
    { data: PositionsTeamsIdsPerProductRequest },
    TContext
  >
}) => {
  const mutationOptions = getTeamIdsPerProductMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Export lifecycle
 */
export const lifecycleExportCsv = (
  searchRequestPositionsFilterPositionsSort: SearchRequestPositionsFilterPositionsSort
) => {
  return fetchInstance<Blob>({
    url: `/api/positions/v1/lifecycle/export-csv`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchRequestPositionsFilterPositionsSort,
    responseType: 'blob',
  })
}

export const getLifecycleExportCsvMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof lifecycleExportCsv>>,
    TError,
    { data: SearchRequestPositionsFilterPositionsSort },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof lifecycleExportCsv>>,
  TError,
  { data: SearchRequestPositionsFilterPositionsSort },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof lifecycleExportCsv>>,
    { data: SearchRequestPositionsFilterPositionsSort }
  > = (props) => {
    const { data } = props ?? {}

    return lifecycleExportCsv(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type LifecycleExportCsvMutationResult = NonNullable<
  Awaited<ReturnType<typeof lifecycleExportCsv>>
>
export type LifecycleExportCsvMutationBody =
  SearchRequestPositionsFilterPositionsSort
export type LifecycleExportCsvMutationError = ErrorType<ErrorResponse>

/**
 * @summary Export lifecycle
 */
export const useLifecycleExportCsv = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof lifecycleExportCsv>>,
    TError,
    { data: SearchRequestPositionsFilterPositionsSort },
    TContext
  >
}) => {
  const mutationOptions = getLifecycleExportCsvMutationOptions(options)

  return useMutation(mutationOptions)
}
