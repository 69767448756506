export function toOptions(mapping: Record<string, string>) {
  return Object.entries(mapping).map(([value, label]) => ({ value, label }))
}
export function upperCaseWords(str: string) {
  return str
    .split('_')
    .map((word) => word[0].toUpperCase() + word.substring(1).toLowerCase())
    .join(' ')
}

export function toMapping(mapping: Record<string, string>) {
  return Object.values(mapping).reduce((acc, value) => {
    acc[value] = upperCaseWords(value)
    return acc
  }, {} as Record<string, string>)
}

export function toPartialMapping(
  keys: string[],
  values: Record<string, string>
) {
  return keys.reduce((acc, value) => {
    acc[value] = values[value]
    return acc
  }, {} as Record<string, string>)
}
