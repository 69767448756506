import { camelCase } from 'lodash-es'
import { useRef } from 'react'
import { useMemo, type ReactNode } from 'react'
import { useMedia } from 'react-use'
import { match, P } from 'ts-pattern'

import {
  Card,
  CardFeatured,
  isEvent,
  prepareBasicCardProps,
  prepareEventCardProps,
  preparePressReleaseCardProps,
} from '@cais-group/shared/ui/contentful/card'
import type {
  CardProps,
  FeaturedCardProps,
} from '@cais-group/shared/ui/contentful/card'
import {
  Carousel,
  contentContainer,
  Section,
} from '@cais-group/shared/ui/contentful/components'
import { BREAKPOINTS } from '@cais-group/shared/ui/design-tokens'
import {
  checkArrayMatch,
  type ContentDataType,
  type CuratedContentDataType,
  type HomeContentType,
  type PressReleaseType,
} from '@cais-group/shared/util/contentful/types'
import { useUserProfile } from '@cais-group/shared/util/hook/use-user-profile'
import { ContentDisclaimer } from '@cais-group/shared-ui-contentful-curated-content-disclaimer'

import { CarouselSplash } from '../../ui/components/carousel-no-results'
import { CuratedContent } from '../../ui/components/curated-content/curated-content'
import { PillTabs } from '../../ui/components/pill-tabs'
import { SkeletonHeader } from '../../ui/components/skeleton.header'
import { SkeletonCardList } from '../../ui/components/skeletons/cardSkeleton'
import { FeaturedSkeleton } from '../../ui/components/skeletons/featuredCardSkeleton'
import { ViewAll } from '../../ui/components/view-all/view-all'
import { APP_URL } from '../../utils/common/constants'
import { getPillsConfig, type PillTabsTypes } from '../../utils/pill-config'

type HomeProps = {
  featured: ContentDataType | null
  press: {
    pressIndexLink: string
    pressReleases: PressReleaseType[] | null
    error: boolean
  }
  browseContent: HomeContentType & {
    loading: boolean
    error: boolean
  }
  activePill: number
  setActivePill: (arg: number) => void
  featureLoading: boolean
  featuredError: boolean
  curatedContent: {
    data: CuratedContentDataType[] | null
    error: boolean
    loading: boolean
  }
  quickLinks?: ReactNode
}

const VIEW_ALL_TEXT = `View All`
// TODO Update the pattern matching when we migrate the home fetch. possibly can do this when we convert Tutorial in HP-636
export const Home = (props: HomeProps) => {
  const {
    featured,
    press,
    browseContent,
    activePill,
    setActivePill,
    featureLoading,
    featuredError,
    curatedContent,
    quickLinks,
  } = props
  const ref = useRef<HTMLUListElement>(null)
  const isNotMobile = useMedia(`(min-width: ${BREAKPOINTS.breakpointSm})`)
  const {
    userProfileState: { userProfile },
  } = useUserProfile()

  const firmName = userProfile?.firm?.name
  const { pills, pillLabels } = useMemo(() => {
    const { pills } = getPillsConfig(browseContent, firmName)
    const pillLabels = pills.map(([label]) => label)
    return { pills, pillLabels }
  }, [browseContent, firmName])

  const featuredCardProps = featured
    ? prepareBasicCardProps<FeaturedCardProps>(featured, APP_URL)
    : null

  const { pressReleases, pressIndexLink, error: pressReleasesError } = press
  const {
    data: curatedData,
    loading: curatedLoading,
    error: curatedError,
  } = curatedContent

  const handlePillClick = (
    activeTab: PillTabsTypes,
    activePillIndex: number
  ) => {
    setActivePill(activePillIndex)
    const activePill = document.querySelector(
      `#${camelCase(activeTab)}`
    ) as HTMLElement
    if (!activePill || !ref.current || isNotMobile) return

    const ulCenter = ref.current.offsetWidth / 2
    const pillCenter = activePill.offsetLeft + activePill.offsetWidth / 2
    const scrollDistance = pillCenter - ulCenter

    ref.current.scrollTo({ left: scrollDistance, behavior: 'smooth' })
  }
  const { loading, error: allCarouselContentError } = browseContent

  const allContentItems = [
    ...pills.flatMap(([, { label }]) => browseContent[label].carousel),
    ...(curatedData ?? []),
    featured ?? ({} as ContentDataType),
  ]

  return (
    <div
      className={`${contentContainer} grid grid-cols-1 gap-32 pb-56 lg:grid-cols-[1fr_2fr]`}
    >
      {/* Column 1 */}
      <div
        className="mb-0 space-y-32 overflow-hidden lg:mb-0"
        aria-label="resources-section"
      >
        {quickLinks}
        {match({ curatedData, curatedLoading, curatedError })
          .with(
            {
              curatedData: P.not(P.nullish),
              curatedLoading: false,
              curatedError: false,
            },
            ({ curatedData }) => {
              return <CuratedContent cards={curatedData} />
            }
          )
          .otherwise(() => null)}
      </div>

      {/* Column 2 */}
      <div
        className="@container/main-column lg:overflow-hidden [&>section:not(:first-child,:last-child)]:mb-32"
        aria-label="main-content-section"
      >
        {match({ featuredError, featureLoading, featuredCardProps })
          .with({ featureLoading: true, featuredError: false }, () => (
            <Section sectionId="Featured Content" withPadding>
              <FeaturedSkeleton compact flip="horizontal" testId="homepage" />
            </Section>
          ))
          .with(
            {
              featuredError: false,
              featuredCardProps: P.not(P.nullish),
              featureLoading: false,
            },
            ({ featuredCardProps }) => (
              <Section sectionId="Featured Content" withPadding>
                <CardFeatured
                  compact
                  flip="horizontal"
                  {...featuredCardProps}
                />
              </Section>
            )
          )
          .otherwise(() => null)}

        {[browseContent].map((content) => {
          const [pillKey, pillValue] = pills[activePill]
          const { label, href } = pillValue
          const data = content[label].carousel
          const error = content[label].error
          const viewAllText = `${VIEW_ALL_TEXT} ${pillKey}`
          const showViewAll = (content[label].carousel.length || !error) && href
          return (
            // conditional rendering of the section title && controls based on loading state to get rid of repeated code
            <Section
              title={!loading ? 'Browse Latest' : ''}
              key={label}
              endAdornment={
                showViewAll ? <ViewAll href={href} text={viewAllText} /> : null
              }
              controls={
                <PillTabs
                  onHandlePillClick={handlePillClick}
                  activePillTab={activePill}
                  pillLabels={pillLabels}
                  pillRef={ref}
                />
              }
              withPadding
            >
              {match({ error, data, loading })
                .with({ loading: true, error: false }, () => (
                  <SkeletonCardList
                    testId="browse-content"
                    key="browse-content-skeleton"
                    skeletonControls={
                      <SkeletonHeader title="Browse Content" type="tabs" />
                    }
                  />
                ))
                .with({ error: true }, () => <CarouselSplash.Error />)
                // If an empty array is returned, show the no results splash
                .with({ data: checkArrayMatch('equalTo') }, () => (
                  <CarouselSplash.NoResults title="Content not yet available" />
                ))
                .with(
                  {
                    error: false,
                    data: checkArrayMatch('greaterThan'),
                    loading: false,
                  },
                  ({ data }) => (
                    <Carousel
                      items={data}
                      itemsPerPage={3}
                      key={label}
                      itemMinWidth="small"
                      minHeight="400px"
                      renderItem={({ item }) => {
                        const props = isEvent(item)
                          ? prepareEventCardProps<CardProps>(item)
                          : prepareBasicCardProps<CardProps>(item, APP_URL)

                        return props ? (
                          <Card {...props} testId="browse-content" />
                        ) : null
                      }}
                      testId="browse-content"
                    />
                  )
                )
                .otherwise(() => null)}
            </Section>
          )
        })}

        {match({ allContentItems })
          .with(
            {
              allContentItems: P.not(P.nullish),
            },
            ({ allContentItems }) => {
              return <ContentDisclaimer items={allContentItems} />
            }
          )
          .otherwise(() => null)}

        {match({
          allCarouselContentError,
          pressReleasesError,
          pressReleases,
          loading,
        })
          .with({ pressReleasesError: true }, () => (
            <Section
              title="Press Releases"
              endAdornment={
                <ViewAll href={pressIndexLink} text={VIEW_ALL_TEXT} />
              }
              withPadding
            >
              <CarouselSplash.Error />
            </Section>
          ))
          .with(
            {
              loading: true,
              pressReleasesError: false,
              allCarouselContentError: false,
            },
            () => (
              <Section withPadding>
                <SkeletonCardList
                  testId="press-releases"
                  key="press-releases-skeleton"
                  skeletonControls={<SkeletonHeader title="Press Releases" />}
                />
              </Section>
            )
          )
          .with(
            {
              pressReleases: P.intersection(
                P.not(P.nullish),
                P.when((x) => Array.isArray(x) && x.length > 0)
              ),
            },
            ({ pressReleases }) => (
              <Section
                title="Press Releases"
                endAdornment={
                  pressReleases &&
                  !allCarouselContentError && (
                    <ViewAll href={pressIndexLink} text={VIEW_ALL_TEXT} />
                  )
                }
                withPadding
              >
                <Carousel
                  items={pressReleases}
                  itemsPerPage={3}
                  itemMinWidth="small"
                  renderItem={({ item }) => {
                    const props = preparePressReleaseCardProps<CardProps>(
                      item,
                      pressIndexLink
                    )
                    return props ? (
                      <Card {...props} testId="press-release" />
                    ) : null
                  }}
                />
              </Section>
            )
          )
          .otherwise(() => null)}
      </div>
    </div>
  )
}
