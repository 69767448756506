import { colors } from '../../../../../particles/colors'
import { circleIntersection } from '../helpers/circleIntersection'

export function PieAwareLine({
  centroid,
  position,
  radius,
  center,
  hide,
  highlighted,
}: {
  center: [number, number]
  centroid: [number, number]
  position: [number, number]
  radius: number
  hide: boolean
  highlighted: boolean
}) {
  if (hide) return null

  const intersection = circleIntersection({
    center,
    radius,
    a: position,
    b: centroid,
  })

  //   If we only draw one slice then shouldn't do modified line (as looks weird/unclear); a more ideal solution might be to fully do intersection with slice (but this is already complex), a in between (but still complex) option is to see if intersection with circle is for current data/slice (we'd need the angle info) and then still apply in that case (I think more complex than it is worth but an option, especially as with tooltips hide anyway).
  if (intersection && !highlighted) {
    return (
      <>
        <line
          // we draw both lines from intersection and add a stroke dash offset to correct/give appearance of seamless dashed line
          x2={centroid[0]}
          x1={intersection[0]}
          y2={centroid[1]}
          y1={intersection[1]}
          stroke={colors['eq-color-neutral-100']}
          strokeDasharray="4,4"
          strokeDashoffset={4}
        />
        <line
          x1={intersection[0]}
          x2={position[0]}
          y1={intersection[1]}
          y2={position[1]}
          stroke={colors['eq-color-neutral-500']}
          strokeDasharray="4,4"
        />
      </>
    )
  } else {
    return (
      <line
        x1={centroid[0]}
        x2={position[0]}
        y1={centroid[1]}
        y2={position[1]}
        stroke={colors['eq-color-neutral-500']}
        strokeDasharray="4,4"
      />
    )
  }
}
