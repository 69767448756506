import { useNavigate } from 'react-router-dom'

import { UserV1 } from '@cais-group/access-manager/domain/api'
import { Avatar } from '@cais-group/equity/atoms/avatar'
import { HighlightedText } from '@cais-group/equity/molecules/list-item'

const VIEW_USER_ROUTE = (id: string) => `/access-manager/users/${id}`

type Props = {
  users: UserV1[]
  searchText?: string
}

export const Users = ({ users, searchText }: Props) => {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col">
      <div className="mb-12">Users</div>

      <div className="grid grid-cols-4 gap-24 [@media(min-width:2080px)]:grid-cols-5">
        {users.map((user) => {
          const name = `${user.firstName} ${user.lastName}`

          return (
            <div
              key={user.id}
              className="hover:shadow-2 flex cursor-pointer items-center gap-x-12 border-2 px-24 py-16"
              onClick={() => navigate(VIEW_USER_ROUTE(user.id))}
            >
              <Avatar size="small">{name}</Avatar>
              <div>
                <div className="body-strong">
                  <HighlightedText
                    isSearchable
                    textToHighlight={searchText}
                    inputText={name}
                    includeWrapper
                  />
                </div>
                <div className="small text-neutral-600">{user.email}</div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
