import { GetTokenSilentlyOptions } from '@auth0/auth0-react'
import { Auth0ContextInterface } from '@auth0/auth0-react/src/auth0-context'

import { logAction, logInfo } from '../../../util/logging'

import { removeAuth0QueryParams } from './removeAuth0QueryParams'

// Keep the naming in order to be in sync with the portal
export const SESSION_START_TIME = 'sessionTimer'
export const IS_SESSION_VALID = 'isSessionValid'
export const AUTH0_RETURN_TO = 'auth0ReturnTo'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const unsetTokenGenerator = (options?: GetTokenSilentlyOptions) => {
  return Promise.resolve('token generator not set')
}

let isSet = false
let tokenGenerator = unsetTokenGenerator

const reset = () => {
  isSet = false
  tokenGenerator = unsetTokenGenerator
}

const setTokenGenerator = (
  generator: Auth0ContextInterface['getAccessTokenSilently']
) => {
  logInfo({ message: 'Setting token generator' })
  tokenGenerator = generator
  isSet = true
}

const resetSessionStart = () => {
  setIsValidSession(true)
  localStorage.setItem(SESSION_START_TIME, new Date().getTime().toString())
}

const getSessionStartTime = () => {
  return Number(localStorage.getItem(SESSION_START_TIME))
}

const removeSessionStartTime = () => {
  localStorage.removeItem(SESSION_START_TIME)
}

const setAuthReturnTo = () => {
  // setting the homepage as the default route if the first one is auth-redirect (the user could be entering this path from a saved URL)
  const pathname = window.location.pathname.replace('auth-redirect', 'homepage')
  const searchParams = removeAuth0QueryParams()
  sessionStorage.setItem(AUTH0_RETURN_TO, `${pathname}${searchParams}`)
}

const getAuthReturnTo = () => {
  // auth-redirect should never be the returnTo path. It is not an app
  return sessionStorage
    .getItem(AUTH0_RETURN_TO)
    ?.replace('auth-redirect', 'homepage')
}

const getIsValidSession = () => {
  return sessionStorage.getItem(IS_SESSION_VALID) === 'true'
}

const setIsValidSession = (isValid: boolean) => {
  return sessionStorage.setItem(IS_SESSION_VALID, `${isValid}`)
}

const invalidateSession = () => {
  logAction({ message: 'User session being invalidated' })
  setIsValidSession(false)
  setAuthReturnTo()
}

const getAccessTokenSilently = (tokenOptions?: GetTokenSilentlyOptions) => {
  return new Promise<string>((resolve, reject) => {
    if (isSet) {
      tokenGenerator(tokenOptions)
        .then((token) => {
          resetSessionStart()
          resolve(token)
        })
        .catch(reject)
    } else {
      let counter = 0
      const interval = 200
      const sevenSeconds = 7000 / interval

      /**
       * Keep checking until the token generator is available, then resolve
       * with whatever tokenGenerator()'s returned promise resolves with */
      const intervalId = setInterval(() => {
        if (counter <= sevenSeconds) {
          counter += 1
          if (isSet) {
            clearInterval(intervalId)
            tokenGenerator(tokenOptions)
              .then((token) => {
                resetSessionStart()
                resolve(token)
              })
              .catch(reject)
          }
        } else {
          /** send user to login screen if no token function for 7 seconds */
          clearInterval(intervalId)
          reject(
            new Error(
              'getAccessTokenSilently has no tokenGenerator function registered after 7 seconds'
            )
          )
        }
      }, interval)
    }
  })
}

export const authService = {
  reset,
  setTokenGenerator,
  getAccessTokenSilently,
  getSessionStartTime,
  resetSessionStart,
  removeSessionStartTime,
  setAuthReturnTo,
  getAuthReturnTo,
  getIsValidSession,
  setIsValidSession,
  invalidateSession,
}
