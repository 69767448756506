/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Funds Product Master API
 * API for Funds Product Master within CAIS
 * OpenAPI spec version: v1
 */
export type PatchFundRanksHeaders = {
  Authorization?: string
}

export type GetFundRanksHeaders = {
  Authorization?: string
}

export type GetFundRanksParams = {
  /**
   * The subject type
   */
  subjectType: SubjectType
  /**
   * The subject id
   */
  subjectId: string
  /**
   * true if the IDs of fund product associated with this rank should be included
   */
  includeFundProductIds?: boolean | null
}

export type PublishAllFundsInBulkUploadHeaders = {
  Authorization?: string
}

export type DraftAllFundsInBulkUploadHeaders = {
  Authorization?: string
}

export type ValidateAllFundsInBulkUploadHeaders = {
  Authorization?: string
}

export type DownloadFundBulkUploadTemplateHeaders = {
  Authorization?: string
}

export type DownloadFundBulkUploadTemplateParams = {
  /**
   * Download the original template without enrichment of enum values and reference data
   */
  original?: boolean
}

export type GetFundBulkUploadHeaders = {
  Authorization?: string
}

export type SearchFundBulkUploadsHeaders = {
  Authorization?: string
}

export type BulkUploadFundsBody = {
  file: Blob
}

export type BulkUploadFundsHeaders = {
  Authorization?: string
}

export type GetFeatureTogglesHeaders = {
  Authorization?: string
}

export type AcceptClickThroughAgreementHeaders = {
  Authorization?: string
}

export type GetLatestAcceptedClickThroughAgreementHeaders = {
  Authorization?: string
}

export type GetClickThroughAgreementHeaders = {
  Authorization?: string
}

export type TriggerReIngestionHeaders = {
  Authorization?: string
}

export type GetFundManagersHeaders = {
  Authorization?: string
}

export type GetFundManagersParams = {
  /**
   * Whether to include deleted entries in the response (defaults to false)
   */
  includeDeleted?: boolean
}

export type GetFundAdministratorsHeaders = {
  Authorization?: string
}

export type GetCustodiansHeaders = {
  Authorization?: string
}

export type GetCustodiansParams = {
  /**
   * page cursor when paging forwards
   */
  after?: string
  /**
   * page cursor when paging backwards
   */
  before?: string
  /**
   * How many results to return when paging forwards
   */
  first?: number
  /**
   * How many results to return when paging backwards
   */
  last?: number
}

export type GetBenchmarksHeaders = {
  Authorization?: string
}

export type GetDocumentDeliveryWorkflowsHeaders = {
  Authorization?: string
}

export type SearchFirmsHeaders = {
  Authorization?: string
}

export type SearchSubjectFirstAccessPoliciesHeaders = {
  Authorization?: string
}

export type PatchAccessPoliciesHeaders = {
  Authorization?: string
}

export type GetShareClassAccessPoliciesByFundProductAndSubjectHeaders = {
  Authorization?: string
}

export type GetShareClassAccessPoliciesByFundProductAndSubjectParams = {
  /**
   * The subject id
   */
  subjectId: string
  subjectType?: SubjectType
}

export type GetFundProductPoliciesHeaders = {
  Authorization?: string
}

export type GetFundProductPoliciesParams = {
  /**
   * The subject id
   */
  subjectId: string
  /**
   * The subject type (optional), default to FIRM
   */
  subjectType?: SubjectType
  /**
   * Hide the AND/OR operator if there is only one item in the list
   */
  hideOperatorForOneItem?: boolean
}

export type GetShareClassApprovalsByFundProductHeaders = {
  Authorization?: string
}

export type GetShareClassApprovalsByFundProductParams = {
  /**
   * The subject id
   */
  subjectId: string
  /**
   * The subject type (optional), default to FIRM
   */
  subjectType?: SubjectType
}

export type GetSubjectAccessPolicyHeaders = {
  Authorization?: string
}

export type GetSubjectAccessPolicyParams = {
  /**
   * The subject id
   */
  subjectId: string
  /**
   * The subject type
   */
  subjectType: SubjectType
}

export type FetchFundProductCoursesHeaders = {
  Authorization?: string
}

export type CheckRequiredCoursesHeaders = {
  Authorization?: string
}

export type SearchAccessPoliciesHeaders = {
  Authorization?: string
}

export type GetUserAllowedActionsByFundProductIdHeaders = {
  Authorization?: string
}

export type GetFundProductUserAllowedActionsHeaders = {
  Authorization?: string
}

export type PublishFundProductAdminEventsBulkHeaders = {
  Authorization?: string
}

export type PublishFundProductAdminEventHeaders = {
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
  Authorization?: string
}

export type RunValidation200 = { [key: string]: Violations }

export type RunValidationHeaders = {
  Authorization?: string
}

export type UpdateShareClassPolicyStatusHeaders = {
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
  Authorization?: string
}

export type GetPerformanceReportHeaders = {
  Authorization?: string
}

export type GetPerformanceReportParams = {
  /**
   * Filter by offshore status
   */
  fundType?: FundType
}

export type PreviewAdminFundProductsHeaders = {
  Authorization?: string
}

export type SearchAdminFundProductsHeaders = {
  Authorization?: string
}

export type SearchAdminFundProductsParams = {
  /**
   * @deprecated
   */
  payloadIncludes: FundPayloadIncludes
  /**
   * Whether to include deleted entries in the response (defaults to false)
   */
  includeDeleted?: boolean
}

export type SearchFundProductsHeaders = {
  Authorization?: string
}

export type SearchFundProductsParams = {
  /**
   * list of associated resources needed to be fetched along with the main resource
   */
  expand?: string[]
}

export type SearchFundProductAggregationsHeaders = {
  Authorization?: string
}

export type SearchFundProductAggregationsListHeaders = {
  Authorization?: string
}

export type BulkPatchFundProductsHeaders = {
  /**
   * Forces the operation to be executed regardless of any validation violations.
   */
  'Suppress-Violations'?: boolean
  Authorization?: string
}

export type CreateFundProductHeaders = {
  Authorization?: string
}

export type UpdateFundProductVariantOverridesHeaders = {
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
  Authorization?: string
}

export type GetFundProductVariantOverridesHeaders = {
  Authorization?: string
}

export type DeleteFundProductVariantHeaders = {
  Authorization?: string
}

export type PatchFundFirmConfigurationHeaders = {
  Authorization?: string
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
}

export type DeleteFundFirmConfigurationHeaders = {
  Authorization?: string
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
}

export type UpdateFundFirmConfigurationHeaders = {
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
  Authorization?: string
}

export type GetFundFirmConfigurationHeaders = {
  Authorization?: string
}

export type GetFundFirmConfigurationParams = {
  /**
   * Whether to include deleted entries in the response (defaults to false)
   */
  includeDeleted?: boolean
}

export type BulkPatchFundFirmConfigurationsHeaders = {
  Authorization?: string
}

export type GetFundFirmConfigurationsHeaders = {
  Authorization?: string
}

export type GetFundFirmConfigurationsParams = {
  /**
   * Whether to include deleted entries in the response (defaults to false)
   */
  includeDeleted?: boolean
}

export type SearchFundProductIdsAndNamesHeaders = {
  Authorization?: string
}

export type SearchFundProductIdsAndNamesParams = {
  /**
   * list of associated resources needed to be fetched along with the main resource
   */
  expand?: string[]
  /**
   * The firm id within the context of the operation
   */
  contextualFirmId?: string
}

export type GetFundProductIdsAndNamesHeaders = {
  Authorization?: string
}

export type GetFundProductIdsAndNamesParams = {
  /**
   * list of associated resources needed to be fetched along with the main resource
   */
  expand?: string[]
}

export type GetFundProductByUserIdHeaders = {
  Authorization?: string
}

export type GetFundProductByUserIdParams = {
  /**
   * Whether to include the performance details for the fund. Deprecated, use the expand query param instead.
   * @deprecated
   */
  includePerformance?: boolean
  /**
   * list of associated resources needed to be fetched along with the main resource
   */
  expand?: string[]
}

export type GetFundProductsByUserIdHeaders = {
  Authorization?: string
}

export type GetFundProductsByUserIdParams = {
  /**
   * page cursor when paging forwards
   */
  after?: string
  /**
   * page cursor when paging backwards
   */
  before?: string
  /**
   * How many results to return when paging forwards
   */
  first?: number
  /**
   * How many results to return when paging backwards
   */
  last?: number
  /**
   * Whether to include the performance details for the fund. Deprecated, use the expand query param instead.
   * @deprecated
   */
  includePerformance?: boolean
  /**
   * list of associated resources needed to be fetched along with the main resource
   */
  expand?: string[]
}

export type GetFundProductHeaders = {
  Authorization?: string
}

export type GetFundProductParams = {
  /**
   * Whether to include the performance details for the fund. Deprecated, use the expand query param instead.
   * @deprecated
   */
  includePerformance?: boolean
  /**
   * list of associated resources needed to be fetched along with the main resource
   */
  expand?: string[]
}

export type CountFundProductsHeaders = {
  Authorization?: string
}

export type CountFundProductsDeprecatedHeaders = {
  Authorization?: string
}

export type CountFundProductsDeprecatedParams = {
  'security-type'?: FundSecurityType
}

export type RecalculateAndSaveCloseDatesAndDueDatesHeaders = {
  Authorization?: string
}

export type CalculateCloseDatesAndDueDatesHeaders = {
  Authorization?: string
}

export type PublishShareClassHeaders = {
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
  Authorization?: string
}

export type PublishFundProductHeaders = {
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
  Authorization?: string
}

export type PublishFundProductParams = {
  /**
   * also publish the given share classes by name. Use "all" to publish all share classes.
   */
  includeShareClasses?: string[]
}

export type PatchFundProductHeaders = {
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
  /**
   * Forces the operation to be executed regardless of any validation violations.
   */
  'Suppress-Violations'?: boolean
  Authorization?: string
}

export type DeleteFundProductHeaders = {
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
  Authorization?: string
}

export type UpdateFundProductHeaders = {
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
  /**
   * Forces the operation to be executed regardless of any validation violations.
   */
  'Suppress-Violations'?: boolean
  Authorization?: string
}

export type DownloadMercerDocumentHeaders = {
  Authorization?: string
}

export type DownloadMercerDocumentParams = {
  fileVersion?: number
  contentDisposition?: ContentDisposition
}

export type GetMercerDocumentHeaders = {
  Authorization?: string
}

export type UpsertMercerServicesDocumentBody = {
  file: Blob
}

export type UpsertMercerServicesDocumentHeaders = {
  Authorization?: string
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
}

export type UpsertMercerServicesDocumentParams = {
  /**
   * Supports either MERCER_ADVISOR_SERVICES or MERCER_INVESTMENT_SERVICES
   */
  type: string
}

export type GetMercerDocumentsHeaders = {
  Authorization?: string
}

export type GetDocumentCategoriesHeaders = {
  Authorization?: string
}

export type CreateFundProductVariantDocumentBody = {
  file: Blob
  request: FundDocumentRequestV1
}

export type CreateFundProductVariantDocumentHeaders = {
  Authorization?: string
}

export type GetFundVariantDocumentsHeaders = {
  Authorization?: string
}

export type GetFundVariantDocumentsSortItem =
  (typeof GetFundVariantDocumentsSortItem)[keyof typeof GetFundVariantDocumentsSortItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetFundVariantDocumentsSortItem = {
  '+title': '+title',
  '-title': '-title',
  title: 'title',
  '+category': '+category',
  '-category': '-category',
  category: 'category',
  '+created-at': '+created-at',
  '-created-at': '-created-at',
  'created-at': 'created-at',
  '+content-type': '+content-type',
  '-content-type': '-content-type',
  'content-type': 'content-type',
  '+id': '+id',
  '-id': '-id',
  id: 'id',
  '+year-month': '+year-month',
  '-year-month': '-year-month',
  'year-month': 'year-month',
} as const

export type GetFundVariantDocumentsParams = {
  sort?: GetFundVariantDocumentsSortItem[]
  /**
   * page cursor when paging forwards
   */
  after?: string
  /**
   * page cursor when paging backwards
   */
  before?: string
  /**
   * How many results to return when paging forwards
   */
  first?: number
  /**
   * How many results to return when paging backwards
   */
  last?: number
  /**
   * Cais Commons UUID Filter
   */
  'document-id'?: string[]
  /**
   * Cais Commons Instant Filter
   */
  'created-at'?: string[]
  /**
   * Cais Commons Instant Filter
   */
  'updated-at'?: string[]
  /**
   * Cais Commons Instant Filter
   */
  'deleted-at'?: string[]
  /**
   * Cais Commons Enum Filter
   */
  'content-type'?: string[]
  /**
   * Cais Commons UUID Filter
   */
  'file-id'?: string[]
  /**
   * Cais Commons String Filter
   */
  'file-name'?: string[]
  /**
   * Cais Commons YearMonth Filter
   */
  'year-month'?: string[]
  /**
   * Cais Commons Enum Filter
   * @deprecated
   */
  category?: string[]
  /**
   * Cais Commons UUID Filter
   */
  'category-id'?: string[]
  /**
   * Cais Commons String Filter
   */
  title?: string[]
}

export type GetFundProductVariantDocumentVersionsHeaders = {
  Authorization?: string
}

export type DownloadFundProductVariantDocumentHeaders = {
  Authorization?: string
}

export type DownloadFundProductVariantDocumentParams = {
  fileVersion?: number
  contentDisposition?: ContentDisposition
}

export type UpdateFundVariantDocumentFileBody = {
  file: Blob
  request: FundDocumentRequestV1
}

export type UpdateFundVariantDocumentFileHeaders = {
  Authorization?: string
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
}

export type UpdateFundVariantDocumentMetadataHeaders = {
  Authorization?: string
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
}

export type DeleteFundVariantDocumentHeaders = {
  Authorization?: string
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
}

export type GetFundVariantDocumentHeaders = {
  Authorization?: string
}

export type CreateFundProductDocumentBody = {
  file: Blob
  request: FundDocumentRequestV1
}

export type CreateFundProductDocumentHeaders = {
  Authorization?: string
}

export type GetFundDocumentsHeaders = {
  Authorization?: string
}

export type GetFundDocumentsSortItem =
  (typeof GetFundDocumentsSortItem)[keyof typeof GetFundDocumentsSortItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetFundDocumentsSortItem = {
  '+title': '+title',
  '-title': '-title',
  title: 'title',
  '+category': '+category',
  '-category': '-category',
  category: 'category',
  '+created-at': '+created-at',
  '-created-at': '-created-at',
  'created-at': 'created-at',
  '+content-type': '+content-type',
  '-content-type': '-content-type',
  'content-type': 'content-type',
  '+id': '+id',
  '-id': '-id',
  id: 'id',
  '+year-month': '+year-month',
  '-year-month': '-year-month',
  'year-month': 'year-month',
} as const

export type GetFundDocumentsParams = {
  sort?: GetFundDocumentsSortItem[]
  /**
   * page cursor when paging forwards
   */
  after?: string
  /**
   * page cursor when paging backwards
   */
  before?: string
  /**
   * How many results to return when paging forwards
   */
  first?: number
  /**
   * How many results to return when paging backwards
   */
  last?: number
  /**
   * Cais Commons UUID Filter
   */
  'document-id'?: string[]
  /**
   * Cais Commons Instant Filter
   */
  'created-at'?: string[]
  /**
   * Cais Commons Instant Filter
   */
  'updated-at'?: string[]
  /**
   * Cais Commons Instant Filter
   */
  'deleted-at'?: string[]
  /**
   * Cais Commons Enum Filter
   */
  'content-type'?: string[]
  /**
   * Cais Commons UUID Filter
   */
  'file-id'?: string[]
  /**
   * Cais Commons String Filter
   */
  'file-name'?: string[]
  /**
   * Cais Commons YearMonth Filter
   */
  'year-month'?: string[]
  /**
   * Cais Commons Enum Filter
   * @deprecated
   */
  category?: string[]
  /**
   * Cais Commons UUID Filter
   */
  'category-id'?: string[]
  /**
   * Cais Commons String Filter
   */
  title?: string[]
}

export type GetFundProductDocumentVersionsHeaders = {
  Authorization?: string
}

export type DownloadFundProductDocumentHeaders = {
  Authorization?: string
}

export type DownloadFundProductDocumentParams = {
  fileVersion?: number
  contentDisposition?: ContentDisposition
}

export type UpdateFundDocumentFileBody = {
  file: Blob
  request: FundDocumentRequestV1
}

export type UpdateFundDocumentFileHeaders = {
  Authorization?: string
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
}

export type UpdateFundDocumentMetadataHeaders = {
  Authorization?: string
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
}

export type DeleteFundDocumentHeaders = {
  Authorization?: string
  /**
   * A new version of the file will only be created if the current version number matches the given value.
   */
  'If-Match'?: string
}

export type GetFundDocumentHeaders = {
  Authorization?: string
}

export type UpsertUserExperienceHeaders = {
  Authorization?: string
}

export type UpsertUserExperienceParams = {
  /**
   * The subject type
   */
  subjectType: SubjectType
  /**
   * The subject id
   */
  subjectId: string
}

export type GetUserExperienceHeaders = {
  Authorization?: string
}

export type GetUserExperienceParams = {
  /**
   * The subject type
   */
  subjectType: SubjectType
  /**
   * The subject id
   */
  subjectId: string
}

export type GetCurrentUserHeaders = {
  Authorization?: string
}

export type SearchParentFundsHeaders = {
  Authorization?: string
}

export type CreateParentFundHeaders = {
  Authorization?: string
}

/**
 * Hide the AND/OR operator if there is only one item in the list
 */
export type HideOperatorForOneItemParameter = boolean

/**
 * How many results to return when paging backwards
 */
export type LastParameter = number

/**
 * How many results to return when paging forwards
 */
export type FirstParameter = number

/**
 * page cursor when paging forwards
 */
export type AfterParameter = string

/**
 * page cursor when paging backwards
 */
export type BeforeParameter = string

export type ContentDispositionParameter =
  (typeof ContentDispositionParameter)[keyof typeof ContentDispositionParameter]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContentDispositionParameter = {
  inline: 'inline',
  attachment: 'attachment',
} as const

export type FileVersionParameter = number

export type VersionParameter = number

/**
 * list of associated resources needed to be fetched along with the main resource
 */
export type ExpandParameter = string[]

export type SecurityTypeParameter =
  (typeof SecurityTypeParameter)[keyof typeof SecurityTypeParameter]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SecurityTypeParameter = {
  HEDGE_FUND: 'HEDGE_FUND',
  PRIVATE_MARKETS: 'PRIVATE_MARKETS',
  OPERATING_COMPANY: 'OPERATING_COMPANY',
} as const

/**
 * How many results to return when paging backwards
 */
export type PaginationLastParameter = number

/**
 * How many results to return when paging forwards
 */
export type PaginationFirstParameter = number

/**
 * page cursor when paging forwards
 */
export type PaginationAfterParameter = string

/**
 * page cursor when paging backwards
 */
export type PaginationBeforeParameter = string

/**
 * Supports either MERCER_ADVISOR_SERVICES or MERCER_INVESTMENT_SERVICES
 */
export type MercerServicesDocumentTypeQueryParameter = string

export type SubjectTypeOptionalParameter =
  (typeof SubjectTypeOptionalParameter)[keyof typeof SubjectTypeOptionalParameter]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubjectTypeOptionalParameter = {
  FIRM: 'FIRM',
  TEAM: 'TEAM',
  USER: 'USER',
} as const

export type SubjectTypeParameter =
  (typeof SubjectTypeParameter)[keyof typeof SubjectTypeParameter]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubjectTypeParameter = {
  FIRM: 'FIRM',
  TEAM: 'TEAM',
  USER: 'USER',
} as const

/**
 * The subject id
 */
export type SubjectIdParameter = string

/**
 * Whether to include the performance details for the fund. Deprecated, use the expand query param instead.
 * @deprecated
 */
export type IncludePerformanceParameter = boolean

/**
 * Whether to include deleted entries in the response (defaults to false)
 */
export type IncludeDeletedParameter = boolean

/**
 * The firm id within the context of the operation
 */
export type ContextualFirmIdParameter = string

/**
 * Internal server error
 */
export type N500Response = ErrorResponse

/**
 * Precondition Failed
 */
export type N412Response = ErrorResponse

/**
 * Conflict
 */
export type N409Response = ErrorResponse

/**
 * Not found
 */
export type N404Response = ErrorResponse

/**
 * User not authorized to perform operation
 */
export type N403Response = ErrorResponse

/**
 * User not authenticated
 */
export type N401Response = ErrorResponse

/**
 * Bad request
 */
export type N400Response = ErrorResponse

/**
 * No Content
 */
export type N204Response = void

/**
 * Accepted
 */
export type N202Response = void

/**
 * Created
 */
export type N201Response = void

/**
 * OK
 */
export type N200Response = void

/**
 * The type of action which the event occurred (create|update|delete|read)
 */
export type EnvelopeTypeActionType =
  (typeof EnvelopeTypeActionType)[keyof typeof EnvelopeTypeActionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnvelopeTypeActionType = {
  create: 'create',
  update: 'update',
  delete: 'delete',
  read: 'read',
} as const

/**
 * Object to specify the type of the event enclosed within the envelope.
 */
export interface EnvelopeTypeAction {
  /** The name of action which the event occurred */
  name: string
  /** The type of action which the event occurred (create|update|delete|read) */
  type: EnvelopeTypeActionType
}

/**
 * Object to specify the type of the event enclosed within the envelope.
 */
export type EnvelopeType = {
  action: EnvelopeTypeAction
  /** The list of actions which describe the changes in the event occurred */
  actions?: EnvelopeTypeAction[]
  /** The domain of the event */
  domain: string
  /** The type of resource upon which the event occurred */
  resource: string
  /** The schema major version of the event type */
  version: number
}

export type EnvelopeRelatedIdsItem = {
  /** The domain of the related resource ID */
  domain: string
  /** Unique ID of the related resource ID */
  id: string
  /** The resource type of the related ID */
  resource: string
  /** The schema major version of the resource of the related ID */
  version?: number
}

/**
 * Object to specify the actor of the event enclosed within the envelope. This can include both the real and switch user.
 */
export type EnvelopeActor = {
  /** The ID of the real user. */
  actorId: string
  /** The ID of the effective user. Only required where the real user is different from the effective user. */
  switchActorId?: string | null
}

/**
 * The operation to be performed
 */
export type PatchDocumentOp =
  (typeof PatchDocumentOp)[keyof typeof PatchDocumentOp]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchDocumentOp = {
  add: 'add',
  remove: 'remove',
  replace: 'replace',
} as const

/**
 * A JSONPatch document as defined by RFC 6902 https://jsonpatch.com/
 */
export interface PatchDocument {
  /** Previous value for replace operation */
  fromValue?: string
  /** The operation to be performed */
  op: PatchDocumentOp
  /** A JSON-Pointer */
  path: string
  /** The value to be used within the operations. */
  value?: unknown
}

/**
 * CAIS Event envelope model
 */
export interface Envelope {
  /** Object to specify the actor of the event enclosed within the envelope. This can include both the real and switch user. */
  actor?: EnvelopeActor
  /**
   * Deprecated. Use actor instead.
   * @deprecated
   */
  actorId?: string
  /** Optional correlation identifier which can be used to logically group or associate events */
  correlationId?: string
  /** Human readable description of the event */
  description: string
  /** The list of differences represented as JSONPatch PatchDocument objects */
  diff?: PatchDocument[]
  /** The ID of the firm that owns the resource. May not be present if the event does not relate to a specific resource. */
  firmId?: string
  /** Unique identifier for an event */
  id: string
  /** An optional list of related IDs. As an example an Order event may include a related product's ID */
  relatedIds?: EnvelopeRelatedIdsItem[]
  /** The ID of the resource affected by the event. May not be present if the event does not relate to a specific resource. */
  resourceId?: string
  /** ISO Datetime when the event occurred. */
  timestamp: string
  /** Object to specify the type of the event enclosed within the envelope. */
  type: EnvelopeType
}

export type ContentDisposition =
  (typeof ContentDisposition)[keyof typeof ContentDisposition]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ContentDisposition = {
  inline: 'inline',
  attachment: 'attachment',
} as const

export type Rank = (typeof Rank)[keyof typeof Rank]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Rank = {
  HIGHEST: 'HIGHEST',
  VERY_HIGH: 'VERY_HIGH',
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
  VERY_LOW: 'VERY_LOW',
  LOWEST: 'LOWEST',
} as const

export type SubjectFirstFundRankField = StringField | UUIDField

export interface SubjectFirstFundRankPatch {
  field: SubjectFirstFundRankField
  op: PatchOperationType
  rank: Rank
  subjectId: string
  subjectType: SubjectType
}

/**
 * rank of fund products for a subject
 */
export interface SubjectFirstFundRank {
  displayName?: string
  fundProductIds?: IdAndName[]
  rank?: Rank
}

/**
 * A representation of the custom ranks of a subject to funds
 */
export interface SubjectFirstFundRanks {
  ranks?: SubjectFirstFundRank[]
  subjectId?: string
  subjectType?: SubjectType
}

/**
 * A lightweight representation of a subject (firm/team/user) can link to the parent subject
 */
export interface SubjectLite {
  parents?: SubjectLite[]
  subjectId: string
  subjectName?: string
  subjectType: SubjectType
}

export type FundOffsetDateType =
  | (typeof FundOffsetDateType)[keyof typeof FundOffsetDateType]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundOffsetDateType = {
  BUSINESS: 'BUSINESS',
  CALENDAR: 'CALENDAR',
} as const

export interface FundCloseAndDueDateBlock {
  closeDate: DateWithSource
  documentDueDate?: DateWithSource
  documentIgoDate?: DateWithSource
  wireDueDate?: DateWithSource
}

export type DueDateOverride = {
  date?: string | null
  deleted?: boolean | null
  estimated?: boolean | null
} | null

export interface CloseDateOverride {
  date: string
  estimated?: boolean | null
}

export interface FundCloseAndDueDateOverride {
  closeDate: CloseDateOverride
  deleted?: boolean | null
  documentDueDate?: DueDateOverride
  documentIgoDate?: DueDateOverride
  wireDueDate?: DueDateOverride
}

export type DateWithSourceSource =
  (typeof DateWithSourceSource)[keyof typeof DateWithSourceSource]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DateWithSourceSource = {
  SCHEDULE: 'SCHEDULE',
  AD_HOC: 'AD_HOC',
} as const

export interface DateWithSource {
  date?: string | null
  estimated: boolean
  source: DateWithSourceSource
}

export type FundCloseDateScheduleType =
  (typeof FundCloseDateScheduleType)[keyof typeof FundCloseDateScheduleType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundCloseDateScheduleType = {
  DAILY: 'DAILY',
  CALENDAR: 'CALENDAR',
} as const

export type CalendarCloseDateScheduleType =
  (typeof CalendarCloseDateScheduleType)[keyof typeof CalendarCloseDateScheduleType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CalendarCloseDateScheduleType = {
  CALENDAR: 'CALENDAR',
} as const

export type CalendarCloseDateSchedule = FundCloseDateSchedule &
  CalendarCloseDateScheduleAllOf & {
    type: CalendarCloseDateScheduleType
  }

export type CalendarCloseDateScheduleAllOfSchedule =
  (typeof CalendarCloseDateScheduleAllOfSchedule)[keyof typeof CalendarCloseDateScheduleAllOfSchedule]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CalendarCloseDateScheduleAllOfSchedule = {
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  SEMI_ANNUALLY: 'SEMI_ANNUALLY',
  ANNUALLY: 'ANNUALLY',
  AD_HOC: 'AD_HOC',
} as const

export type CalendarCloseDateScheduleAllOfCutOffPoint =
  | (typeof CalendarCloseDateScheduleAllOfCutOffPoint)[keyof typeof CalendarCloseDateScheduleAllOfCutOffPoint]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CalendarCloseDateScheduleAllOfCutOffPoint = {
  LAST_DAY_OF_PREVIOUS_PERIOD: 'LAST_DAY_OF_PREVIOUS_PERIOD',
  FIRST_DAY_OF_NEXT_PERIOD: 'FIRST_DAY_OF_NEXT_PERIOD',
  SPECIFIC_DAY_OF_NEXT_PERIOD: 'SPECIFIC_DAY_OF_NEXT_PERIOD',
} as const

export type CalendarCloseDateScheduleAllOf = {
  closeDateOffsetType?: FundOffsetDateType
  cutOffDay?: number | null
  cutOffPoint?: CalendarCloseDateScheduleAllOfCutOffPoint
  documentDueDateOffset?: number | null
  documentIgoDateOffset?: number | null
  finalCloseDate?: string | null
  initialCloseDate?: string | null
  overrides?: FundCloseAndDueDateOverride[]
  schedule: CalendarCloseDateScheduleAllOfSchedule
  wireDueDateOffset?: number | null
  [key: string]: any
}

export type DailyCloseDateScheduleType =
  (typeof DailyCloseDateScheduleType)[keyof typeof DailyCloseDateScheduleType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DailyCloseDateScheduleType = {
  DAILY: 'DAILY',
} as const

export type DailyCloseDateScheduleAllOf = {
  finalCloseDate?: string | null
  initialCloseDate?: string | null
  [key: string]: any
}

export type FundCloseDateSchedule = {
  type: FundCloseDateScheduleType
} | null

export type DailyCloseDateSchedule = FundCloseDateSchedule &
  DailyCloseDateScheduleAllOf & {
    type: DailyCloseDateScheduleType
  }

/**
 * A request to recalculate close and due dates for a subset of funds (max 200)
 */
export interface FundCloseAndDueDateRecalculationRequest {
  ids: string[]
}

export interface FundCloseAndDueDateCalculationRequest {
  closeDateSchedule?: FundCloseDateSchedule
  id?: string | null
}

/**
 * A request to ingest for all or for a subset of funds. List of fund IDs take precedence over the all flag.
 */
export interface FundProductIngestionRequest {
  all?: boolean
  ids?: string[]
}

export interface UserCourses {
  missingCourses?: string[]
  requiredCourses?: string[]
  userId?: string
}

export interface FundProductCourses {
  courses?: UserCourses[]
}

export interface FundProductRequiredCoursesCompletedUsers {
  coursesCompletedUserIds?: string[]
  coursesNotCompletedUserIds?: string[]
}

export interface FundProductRequiredCoursesCheckRequest {
  userIds: string[]
}

export interface ShareClassAccessPolicyV1 {
  canInvest?: boolean | null
  hasDefaultAccess?: boolean
  modifier?: AccessModifier
  shareClassId: string
}

export type ShareClassAccessPolicyEventV1 = Envelope & {
  payload: ShareClassAccessPolicyV1
}

export interface SubjectV1 {
  name?: string
  policyId: string
  subjectId: string
  subjectType: SubjectType
  subscribeToMarketPlace?: boolean
}

export interface SubjectShareClassAccessPolicyV1 {
  hasFundAccess: boolean
  shareClassAccessPolicies: ShareClassAccessPolicyV1[]
  subject: SubjectV1
}

export interface CustodianPlatformV1 {
  id?: string
  name?: string
}

export interface CustodianV1 {
  eSignEnabled: boolean
  id: string
  isDefault: boolean
  name: string
  platforms: CustodianPlatformV1[]
}

export interface SubjectShareClassAccessPolicyPageV1 {
  items: SubjectShareClassAccessPolicyV1[]
  pageInfo: PageInfo
}

export type AccessPolicyApprovalStateFilterEq =
  (typeof AccessPolicyApprovalStateFilterEq)[keyof typeof AccessPolicyApprovalStateFilterEq]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccessPolicyApprovalStateFilterEq = {
  APPROVED: 'APPROVED',
  NOT_APPROVED: 'NOT_APPROVED',
} as const

export interface AccessPolicyApprovalStateFilter {
  eq?: AccessPolicyApprovalStateFilterEq
}

export interface AccessPolicyAdvisorTypeTypeFilter {
  eq?: FundAdvisoryType
}

export type AccessPolicyHasLearnToInvestCourseSetUpFilterEq =
  (typeof AccessPolicyHasLearnToInvestCourseSetUpFilterEq)[keyof typeof AccessPolicyHasLearnToInvestCourseSetUpFilterEq]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccessPolicyHasLearnToInvestCourseSetUpFilterEq = {
  NOT_SET_UP: 'NOT_SET_UP',
  SET_UP: 'SET_UP',
} as const

export interface AccessPolicyHasLearnToInvestCourseSetUpFilter {
  eq?: AccessPolicyHasLearnToInvestCourseSetUpFilterEq
}

export interface CustodianPageV1 {
  items: CustodianV1[]
  pageInfo: PageInfo
}

export type AccessPolicyHasFundAccessFilterV1Eq =
  (typeof AccessPolicyHasFundAccessFilterV1Eq)[keyof typeof AccessPolicyHasFundAccessFilterV1Eq]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccessPolicyHasFundAccessFilterV1Eq = {
  HAS_FUND_ACCESS: 'HAS_FUND_ACCESS',
  DOES_NOT_HAVE_FUND_ACCESS: 'DOES_NOT_HAVE_FUND_ACCESS',
} as const

export interface AccessPolicyHasFundAccessFilterV1 {
  eq?: AccessPolicyHasFundAccessFilterV1Eq
}

export interface AccessPolicyOfferingTypeFilterV1 {
  eq?: FundOfferingType
}

export interface FundProductAccessPolicyFilterV1 {
  firmName?: StringFilter
  fundProductId: string
  hasFundAccess?: AccessPolicyHasFundAccessFilterV1
  offeringType?: AccessPolicyOfferingTypeFilterV1
  subjectId?: string
  subjectType?: SubjectType
}

export type FundProductAccessPolicySearchRequestV1SortingItem =
  (typeof FundProductAccessPolicySearchRequestV1SortingItem)[keyof typeof FundProductAccessPolicySearchRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundProductAccessPolicySearchRequestV1SortingItem = {
  FIRM_NAME_ASC: 'FIRM_NAME_ASC',
  FIRM_NAME_DESC: 'FIRM_NAME_DESC',
} as const

export interface FundProductAccessPolicySearchRequestV1 {
  filter: FundProductAccessPolicyFilterV1
  pageRequest?: PageRequest
  sorting?: FundProductAccessPolicySearchRequestV1SortingItem[] | null
}

export interface ShareClassAllowedActionV1 {
  allowedActions?: AllowedAction[]
  deniedActions?: DeniedAction[]
  shareClassId: string
}

export type ItemOperator = (typeof ItemOperator)[keyof typeof ItemOperator]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ItemOperator = {
  AND: 'AND',
  OR: 'OR',
} as const

export interface ItemGroup {
  items: string[]
  operator?: ItemOperator
}

export interface ItemGroupList {
  groups: ItemGroup[]
  operator?: ItemOperator
}

export type PolicyDenialType =
  (typeof PolicyDenialType)[keyof typeof PolicyDenialType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PolicyDenialType = {
  POLICY_DENIAL: 'POLICY_DENIAL',
} as const

export type PolicyDenialAllOf = {
  message?: string
  [key: string]: any
}

export type PolicyDenial = DenyReason &
  PolicyDenialAllOf & {
    type: PolicyDenialType
  }

export type NotApprovedType =
  (typeof NotApprovedType)[keyof typeof NotApprovedType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NotApprovedType = {
  NOT_APPROVED: 'NOT_APPROVED',
} as const

export type NotApprovedAllOf = {
  message?: string
  [key: string]: any
}

export type NotApproved = DenyReason &
  NotApprovedAllOf & {
    type: NotApprovedType
  }

export type RequestApprovalToInvestRejectedType =
  (typeof RequestApprovalToInvestRejectedType)[keyof typeof RequestApprovalToInvestRejectedType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RequestApprovalToInvestRejectedType = {
  REQUEST_APPROVAL_TO_INVEST_REJECTED: 'REQUEST_APPROVAL_TO_INVEST_REJECTED',
} as const

export type RequestApprovalToInvestRejectedAllOf = {
  message?: string
  [key: string]: any
}

export type RequestApprovalToInvestRejected = DenyReason &
  RequestApprovalToInvestRejectedAllOf & {
    type: RequestApprovalToInvestRejectedType
  }

export type UserAlreadyRequestedApprovalType =
  (typeof UserAlreadyRequestedApprovalType)[keyof typeof UserAlreadyRequestedApprovalType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserAlreadyRequestedApprovalType = {
  USER_ALREADY_REQUESTED_APPROVAL: 'USER_ALREADY_REQUESTED_APPROVAL',
} as const

export type UserAlreadyRequestedApprovalAllOf = {
  message?: string
  [key: string]: any
}

export type UserAlreadyRequestedApproval = DenyReason &
  UserAlreadyRequestedApprovalAllOf & {
    type: UserAlreadyRequestedApprovalType
  }

export type RequiresApprovalToInvestType =
  (typeof RequiresApprovalToInvestType)[keyof typeof RequiresApprovalToInvestType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RequiresApprovalToInvestType = {
  REQUIRES_APPROVAL_TO_INVEST: 'REQUIRES_APPROVAL_TO_INVEST',
} as const

export type RequiresApprovalToInvestAllOf = {
  message?: string
  [key: string]: any
}

export type RequiresApprovalToInvest = DenyReason &
  RequiresApprovalToInvestAllOf & {
    type: RequiresApprovalToInvestType
  }

export type NonInvestableType =
  (typeof NonInvestableType)[keyof typeof NonInvestableType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NonInvestableType = {
  NON_INVESTABLE: 'NON_INVESTABLE',
} as const

export type NonInvestableAllOf = {
  message?: string
  [key: string]: any
}

export type NonInvestable = DenyReason &
  NonInvestableAllOf & {
    type: NonInvestableType
  }

export type MissingAgreementAcceptanceType =
  (typeof MissingAgreementAcceptanceType)[keyof typeof MissingAgreementAcceptanceType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MissingAgreementAcceptanceType = {
  MISSING_AGREEMENT_ACCEPTANCE: 'MISSING_AGREEMENT_ACCEPTANCE',
} as const

export type MissingAgreementAcceptanceAllOf = {
  message?: string
  missing?: ItemGroupList
  required?: ItemGroupList
  [key: string]: any
}

export type MissingAgreementAcceptance = DenyReason &
  MissingAgreementAcceptanceAllOf & {
    type: MissingAgreementAcceptanceType
  }

export type MissingLicensesType =
  (typeof MissingLicensesType)[keyof typeof MissingLicensesType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MissingLicensesType = {
  MISSING_LICENSES: 'MISSING_LICENSES',
} as const

export type MissingLicensesAllOf = {
  message?: string
  missing?: ItemGroupList
  required?: ItemGroupList
  [key: string]: any
}

export type MissingLicenses = DenyReason &
  MissingLicensesAllOf & {
    type: MissingLicensesType
  }

export type MissingCoursesType =
  (typeof MissingCoursesType)[keyof typeof MissingCoursesType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MissingCoursesType = {
  MISSING_COURSES: 'MISSING_COURSES',
} as const

export type MissingCoursesAllOf = {
  message?: string
  missing?: ItemGroupList
  required?: ItemGroupList
  [key: string]: any
}

export type MissingCourses = DenyReason &
  MissingCoursesAllOf & {
    type: MissingCoursesType
  }

export type DenyReasonType =
  (typeof DenyReasonType)[keyof typeof DenyReasonType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DenyReasonType = {
  MISSING_COURSES: 'MISSING_COURSES',
  MISSING_LICENSES: 'MISSING_LICENSES',
  MISSING_AGREEMENT_ACCEPTANCE: 'MISSING_AGREEMENT_ACCEPTANCE',
  NON_INVESTABLE: 'NON_INVESTABLE',
  NOT_APPROVED: 'NOT_APPROVED',
  POLICY_DENIAL: 'POLICY_DENIAL',
  REQUIRES_APPROVAL_TO_INVEST: 'REQUIRES_APPROVAL_TO_INVEST',
  USER_ALREADY_REQUESTED_APPROVAL: 'USER_ALREADY_REQUESTED_APPROVAL',
  REQUEST_APPROVAL_TO_INVEST_REJECTED: 'REQUEST_APPROVAL_TO_INVEST_REJECTED',
} as const

export interface DenyReason {
  type: DenyReasonType
}

export interface DeniedAction {
  action: AllowedAction
  reasons?: DenyReason[]
}

export interface FundProductAllowedActionV1 {
  allowedActions: AllowedAction[]
  caisId?: string
  deniedActions?: DeniedAction[]
  fundProductId: string
  shareClassesAllowedActions: ShareClassAllowedActionV1[]
}

export interface FundProductAllowedActionsV1 {
  allowedActions?: FundProductAllowedActionV1[]
}

export interface FundProductUserAllowedAction {
  allowedActions?: AllowedAction[]
  deniedActions?: DeniedAction[]
  fundProductId?: string
  legalName?: string
  userId?: string
}

export interface FundProductUserAllowedActions {
  allowedActions?: FundProductUserAllowedAction[]
}

export type VariantIdsFirm = {
  id: string
}

export type VariantIdsBaseFund = {
  caisId?: string
  id: string
}

export interface VariantIds {
  baseFund: VariantIdsBaseFund
  firm: VariantIdsFirm
}

export interface ShareClassIdAndName {
  allowedActions?: AllowedAction[]
  caisId?: string
  deniedActions?: DeniedAction[]
  name?: string
  shareClassId: string
}

export interface FundProductIdAndName {
  allowedActions?: AllowedAction[]
  caisId?: string
  deniedActions?: DeniedAction[]
  documents?: FundProductDocumentV1[]
  fundProductId: string
  legalName?: string
  shareClasses?: ShareClassIdAndName[]
  /** optional field to be used if the user cannot be derived */
  userId?: string
  variantIds?: VariantIds
}

export interface FundProductIdsAndNames {
  fundProducts?: FundProductIdAndName[]
}

export interface IdAndName {
  caisId?: string
  id: string
  name?: string
}

export interface CheckFundProductUserAccessPolicyRequestV1 {
  userIds: string[]
}

export interface CheckFundProductAccessPolicyRequestV1 {
  fundProductIds: string[]
}

export interface SearchFundProductIdsAndNamesRequestV1 {
  fundProductIds: string[]
  userIds: string[]
}

export type FundProductAccessPolicyRequiredCourseLevel =
  (typeof FundProductAccessPolicyRequiredCourseLevel)[keyof typeof FundProductAccessPolicyRequiredCourseLevel]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundProductAccessPolicyRequiredCourseLevel = {
  FUND_PRODUCT_ONLY: 'FUND_PRODUCT_ONLY',
  SUBJECT_ONLY: 'SUBJECT_ONLY',
} as const

export type FundProductAccessPolicyStrategy =
  (typeof FundProductAccessPolicyStrategy)[keyof typeof FundProductAccessPolicyStrategy]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundProductAccessPolicyStrategy = {
  INHERIT: 'INHERIT',
  OVERRIDE: 'OVERRIDE',
} as const

export type FundProductShareClassPolicyStatus =
  (typeof FundProductShareClassPolicyStatus)[keyof typeof FundProductShareClassPolicyStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundProductShareClassPolicyStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
} as const

export type PatchOperationType =
  (typeof PatchOperationType)[keyof typeof PatchOperationType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatchOperationType = {
  upsert: 'upsert',
  delete: 'delete',
} as const

export type AccessPolicyOperationType =
  (typeof AccessPolicyOperationType)[keyof typeof AccessPolicyOperationType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccessPolicyOperationType = {
  UPSERT: 'UPSERT',
  DELETE: 'DELETE',
} as const

export type SubjectType = (typeof SubjectType)[keyof typeof SubjectType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubjectType = {
  FIRM: 'FIRM',
  TEAM: 'TEAM',
  USER: 'USER',
} as const

export type AccessModifier =
  (typeof AccessModifier)[keyof typeof AccessModifier]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccessModifier = {
  EXCLUDE: 'EXCLUDE',
  INCLUDE: 'INCLUDE',
} as const

export type ApprovalState = (typeof ApprovalState)[keyof typeof ApprovalState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApprovalState = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
} as const

export type Approval = (typeof Approval)[keyof typeof Approval]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Approval = {
  APPROVED: 'APPROVED',
  NOT_APPROVED: 'NOT_APPROVED',
} as const

export type FundProductAccessPolicyStrategyFieldName =
  (typeof FundProductAccessPolicyStrategyFieldName)[keyof typeof FundProductAccessPolicyStrategyFieldName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundProductAccessPolicyStrategyFieldName = {
  strategy: 'strategy',
} as const

export interface FundProductAccessPolicyStrategyField {
  name: FundProductAccessPolicyStrategyFieldName
  value?: FundProductAccessPolicyStrategy
}

export type RequiredCourseLevelFieldName =
  (typeof RequiredCourseLevelFieldName)[keyof typeof RequiredCourseLevelFieldName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RequiredCourseLevelFieldName = {
  requiredCourseLevel: 'requiredCourseLevel',
} as const

export interface RequiredCourseLevelField {
  name: RequiredCourseLevelFieldName
  value?: FundProductAccessPolicyRequiredCourseLevel
}

export type ItemGroupListFieldValue = null | ItemGroupList

export type ItemGroupListFieldName =
  (typeof ItemGroupListFieldName)[keyof typeof ItemGroupListFieldName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ItemGroupListFieldName = {
  requiredLicenses: 'requiredLicenses',
} as const

export interface ItemGroupListField {
  name: ItemGroupListFieldName
  value?: ItemGroupListFieldValue
}

export type UUIDFieldName = (typeof UUIDFieldName)[keyof typeof UUIDFieldName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UUIDFieldName = {
  fundProductId: 'fundProductId',
} as const

export interface UUIDField {
  name: UUIDFieldName
  value?: string
}

export type StringFieldName =
  (typeof StringFieldName)[keyof typeof StringFieldName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StringFieldName = {
  displayName: 'displayName',
} as const

export interface StringField {
  name: StringFieldName
  value?: string
}

export type BooleanFieldName =
  (typeof BooleanFieldName)[keyof typeof BooleanFieldName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BooleanFieldName = {
  subscribeToMarketplace: 'subscribeToMarketplace',
} as const

export interface BooleanField {
  name: BooleanFieldName
  value?: boolean
}

export type ApproveStateFieldName =
  (typeof ApproveStateFieldName)[keyof typeof ApproveStateFieldName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApproveStateFieldName = {
  approveState: 'approveState',
} as const

export interface ApproveStateField {
  name: ApproveStateFieldName
  value?: ApprovalState
}

export type AccessModifierFieldName =
  (typeof AccessModifierFieldName)[keyof typeof AccessModifierFieldName]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccessModifierFieldName = {
  modifier: 'modifier',
} as const

export interface AccessModifierField {
  name: AccessModifierFieldName
  value?: AccessModifier
}

export type AccessPolicyField =
  | AccessModifierField
  | ApproveStateField
  | BooleanField
  | ItemGroupListField
  | FundProductAccessPolicyStrategyField
  | RequiredCourseLevelField

/**
 * # Subject access policy
## How to identify  
* fundProductId absent
* shareClassId absent

## Supported fields
* strategy
* subscribeToMarketplace
* requireApproval
* requireCaisIq
* requiredCourseLevel
* requiredCourses
* requireApprovalToInvest

# Fund Product access policy
## How to identify
* fundProductId present
* shareClassId absent

## Supported fields
* requiredCourses
* requiredLicenses
* canViewDocuments
* canViewMercer
* canViewOverview
* canViewPerformance

# Share Class access policy
## How to identify
* fundProductId present
* shareClassId present

## Supported fields
* modifier
* canInvest
* approveState

 */
export interface AccessPolicyPatchV2 {
  field: AccessPolicyField
  /** Required for fund product or share class access policy */
  fundProductId?: string
  op: AccessPolicyOperationType
  /** Required for share class access policy */
  shareClassId?: string
  subjectId: string
  /** Only required when creating a new subject access policy of type FIRM on the fly */
  subjectName?: string
  subjectType: SubjectType
}

export type AccessPolicyPatchAggregateV2 = AccessPolicyPatchV2[]

export interface SubjectShareClassApprovalV1 {
  approvedAt?: string
  approveState: ApprovalState
  shareClassId: string
  subjectId: string
  subjectType: SubjectType
}

export type SubjectShareClassApprovalEventV1 = Envelope & {
  payload: SubjectShareClassApprovalV1
}

export interface SubjectFirstAccessPolicyFundProduct {
  caisId?: string
  canViewDocuments?: boolean
  canViewMercer?: boolean
  canViewOverview?: boolean
  canViewPerformance?: boolean
  fundProductId: string
  legalName?: string
  publishState?: FundPublishState
  reportingSubStatus?: FundReportingSubStatus
  requireApprovalToInvest?: boolean
  requiredCourses?: ItemGroupList
  requiredLicenses?: ItemGroupList
  subscriptionStatus?: FundSubscriptionStatus
}

export interface SubjectFirstAccessPolicyShareClass {
  advisoryTypes?: FundAdvisoryType[]
  approveState?: ApprovalState
  fundProduct: SubjectFirstAccessPolicyFundProduct
  hasDefaultAccess?: boolean
  modifier?: AccessModifier
  name?: string
  offeringType?: FundOfferingType
  policyStatus?: FundPolicyStatus
  publishState?: FundPublishState
  shareClassId: string
  solutionType?: FundSolutionType[]
  subscriptionStatus?: FundSubscriptionStatus
}

export type SubjectFirstAccessPolicyShareClassPageSubjectAccessPolicy = {
  requireApproval: boolean
  requireApprovalInheritedFromParent?: boolean
  requireApprovalToInvest?: boolean
  requireApprovalToInvestInheritedFromParent?: boolean
  requireCaisIq: boolean
  requireCaisIqInheritedFromParent?: boolean
  requiredCourseLevel: FundProductAccessPolicyRequiredCourseLevel
  strategy: FundProductAccessPolicyStrategy
  subjectId: string
  subjectName?: string
  subjectType: SubjectType
  subscribeToMarketplace: boolean
  subscribeToMarketplaceInheritedFromParent?: boolean
}

export interface SubjectFirstAccessPolicyShareClassPage {
  items: SubjectFirstAccessPolicyShareClass[]
  pageInfo: PageInfo
  subjectAccessPolicy?: SubjectFirstAccessPolicyShareClassPageSubjectAccessPolicy
}

export interface SubjectFirstAccessPolicyFilter {
  advisoryType?: AccessPolicyAdvisorTypeTypeFilter
  fundLegalName?: StringFilter
  hasFundAccess?: AccessPolicyHasFundAccessFilterV1
  hasLearnToInvestCourseSetUp?: AccessPolicyHasLearnToInvestCourseSetUpFilter
  hasShareClassApproval?: AccessPolicyApprovalStateFilter
  offeringType?: AccessPolicyOfferingTypeFilterV1
  subjectId: string
  subjectType: SubjectType
}

export type SubjectFirstAccessPolicySearchRequestSortingItem =
  (typeof SubjectFirstAccessPolicySearchRequestSortingItem)[keyof typeof SubjectFirstAccessPolicySearchRequestSortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubjectFirstAccessPolicySearchRequestSortingItem = {
  FUND_LEGAL_NAME_ASC: 'FUND_LEGAL_NAME_ASC',
  FUND_LEGAL_NAME_DESC: 'FUND_LEGAL_NAME_DESC',
  SHARE_CLASS_NAME_ASC: 'SHARE_CLASS_NAME_ASC',
  SHARE_CLASS_NAME_DESC: 'SHARE_CLASS_NAME_DESC',
} as const

export interface SubjectFirstAccessPolicySearchRequest {
  filter: SubjectFirstAccessPolicyFilter
  pageRequest?: PageRequest
  sorting?: SubjectFirstAccessPolicySearchRequestSortingItem[] | null
}

export interface FundProductAccessPolicy {
  canViewDocuments?: boolean
  canViewMercer?: boolean
  canViewOverview?: boolean
  canViewPerformance?: boolean
  fundProductId?: string
  requireApprovalToInvest?: boolean
  requiredCourses?: ItemGroupList
  requiredLicenses?: ItemGroupList
  subjectId?: string
  subjectName?: string
  subjectType?: SubjectType
}

export type FundProductAccessPolicyEventV1 = Envelope & {
  payload: FundProductAccessPolicy
}

export interface FundProductAccessPolicies {
  policies?: FundProductAccessPolicy[]
}

export interface ShareClassAccessPolicy {
  advisoryTypes?: FundAdvisoryType[]
  canInvest?: boolean
  hasDefaultAccess?: boolean
  legalName?: string
  modifier?: AccessModifier
  offeringType?: FundOfferingType
  shareClassId?: string
}

export interface ShareClassAccessPolicies {
  policies?: ShareClassAccessPolicy[]
}

export interface ShareClassApproval {
  advisoryTypes?: FundAdvisoryType[]
  approvalState?: ApprovalState
  approvedAt?: string
  legalName?: string
  offeringType?: FundOfferingType
  shareClassId: string
}

export interface ShareClassesApprovals {
  approvals?: ShareClassApproval[]
}

export type SubjectFirstAccessPolicySettingParent = {
  parents?: SubjectLite[]
  requireApproval: boolean
  requireApprovalToInvest?: boolean
  requireCaisIq: boolean
  subscribeToMarketplace: boolean
}

export interface SubjectFirstAccessPolicySetting {
  parent?: SubjectFirstAccessPolicySettingParent
  requireApproval?: boolean
  requireApprovalToInvest?: boolean
  requireCaisIq?: boolean
  requiredCourseLevel?: FundProductAccessPolicyRequiredCourseLevel
  requiredCourses?: ItemGroupList
  strategy?: FundProductAccessPolicyStrategy
  subjectId: string
  subjectName?: string
  subjectType: SubjectType
  subscribeToMarketplace?: boolean
}

export interface ShareClassAccessPolicyResponseV1 {
  canInvest?: boolean | null
  modifier?: AccessModifier
  shareClassId: string
}

export interface SubjectAccessPolicyResponseV1 {
  access?: ShareClassAccessPolicyResponseV1[]
  subjectId?: string
  subjectType?: SubjectType
}

export interface SubjectAccessPolicyPayloadV1 {
  requireApproval: boolean
  requireApprovalToInvest?: boolean
  requireCaisIq: boolean
  requiredCourseLevel: FundProductAccessPolicyRequiredCourseLevel
  strategy: FundProductAccessPolicyStrategy
  subjectId: string
  subjectName?: string
  subjectType: SubjectType
  subscribeToMarketplace: boolean
}

export interface FundProductSubjectAccessPolicyRequestV1 {
  offeringType: FundOfferingType
  requiresApproval: boolean
  requiresCaisIq: boolean
  strategy: FundProductAccessPolicyStrategy
}

export type FundPublishState =
  (typeof FundPublishState)[keyof typeof FundPublishState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundPublishState = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
} as const

/**
 * @deprecated
 */
export interface FundPayloadIncludes {
  shareClass?: boolean
}

export interface UUIDFilter {
  eq?: string | null
  in?: string[] | null
}

export interface BooleanFilter {
  eq?: boolean
}

export interface StringFilter {
  caseSensitive?: boolean | null
  eq?: string | null
  exists?: boolean | null
  in?: string[] | null
  like?: string | null
  ne?: string | null
}

export type FundProductsAdminSearchRequestV1SortingItem =
  (typeof FundProductsAdminSearchRequestV1SortingItem)[keyof typeof FundProductsAdminSearchRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundProductsAdminSearchRequestV1SortingItem = {
  LEGAL_NAME_ASC: 'LEGAL_NAME_ASC',
  LEGAL_NAME_DESC: 'LEGAL_NAME_DESC',
  SUBSCRIPTION_STATUS_ASC: 'SUBSCRIPTION_STATUS_ASC',
  SUBSCRIPTION_STATUS_DESC: 'SUBSCRIPTION_STATUS_DESC',
  CLOSING_DATE_ASC: 'CLOSING_DATE_ASC',
  CLOSING_DATE_DESC: 'CLOSING_DATE_DESC',
  CREATED_AT_ASC: 'CREATED_AT_ASC',
  CREATED_AT_DESC: 'CREATED_AT_DESC',
  ID_ASC: 'ID_ASC',
  ID_DESC: 'ID_DESC',
} as const

export type FirmRequestV1SortingItem =
  (typeof FirmRequestV1SortingItem)[keyof typeof FirmRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FirmRequestV1SortingItem = {
  NAME_ASC: 'NAME_ASC',
  NAME_DESC: 'NAME_DESC',
} as const

export type FundProductsSearchRequestV1SortingItem =
  (typeof FundProductsSearchRequestV1SortingItem)[keyof typeof FundProductsSearchRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundProductsSearchRequestV1SortingItem = {
  FUND_NAME_ASC: 'FUND_NAME_ASC',
  FUND_NAME_DESC: 'FUND_NAME_DESC',
  FUND_MANAGER_ASC: 'FUND_MANAGER_ASC',
  FUND_MANAGER_DESC: 'FUND_MANAGER_DESC',
  FUND_MANAGER_SHORT_NAME_ASC: 'FUND_MANAGER_SHORT_NAME_ASC',
  FUND_MANAGER_SHORT_NAME_DESC: 'FUND_MANAGER_SHORT_NAME_DESC',
  PRIMARY_OBJECTIVE_ASC: 'PRIMARY_OBJECTIVE_ASC',
  PRIMARY_OBJECTIVE_DESC: 'PRIMARY_OBJECTIVE_DESC',
  STRATEGY_ASC: 'STRATEGY_ASC',
  STRATEGY_DESC: 'STRATEGY_DESC',
  SUB_STRATEGY_ASC: 'SUB_STRATEGY_ASC',
  SUB_STRATEGY_DESC: 'SUB_STRATEGY_DESC',
  FUNDRAISING_TYPE_ASC: 'FUNDRAISING_TYPE_ASC',
  FUNDRAISING_TYPE_DESC: 'FUNDRAISING_TYPE_DESC',
  LEGACY_DOCUMENT_DUE_DATE_ASC: 'LEGACY_DOCUMENT_DUE_DATE_ASC',
  LEGACY_DOCUMENT_DUE_DATE_DESC: 'LEGACY_DOCUMENT_DUE_DATE_DESC',
  MERCER_RATING_ASC: 'MERCER_RATING_ASC',
  MERCER_RATING_DESC: 'MERCER_RATING_DESC',
  INVESTOR_LEVEL_ASC: 'INVESTOR_LEVEL_ASC',
  INVESTOR_LEVEL_DESC: 'INVESTOR_LEVEL_DESC',
  FUNDING_STRUCTURE_ASC: 'FUNDING_STRUCTURE_ASC',
  FUNDING_STRUCTURE_DESC: 'FUNDING_STRUCTURE_DESC',
  INVESTOR_TYPE_ASC: 'INVESTOR_TYPE_ASC',
  INVESTOR_TYPE_DESC: 'INVESTOR_TYPE_DESC',
  TAX_REPORTING_ASC: 'TAX_REPORTING_ASC',
  TAX_REPORTING_DESC: 'TAX_REPORTING_DESC',
  SUBSCRIPTION_INTERVALS_ASC: 'SUBSCRIPTION_INTERVALS_ASC',
  SUBSCRIPTION_INTERVALS_DESC: 'SUBSCRIPTION_INTERVALS_DESC',
  REDEMPTION_INTERVALS_ASC: 'REDEMPTION_INTERVALS_ASC',
  REDEMPTION_INTERVALS_DESC: 'REDEMPTION_INTERVALS_DESC',
  FUND_ACCESS_ASC: 'FUND_ACCESS_ASC',
  FUND_ACCESS_DESC: 'FUND_ACCESS_DESC',
  FUND_ADVISORY_TYPE_ASC: 'FUND_ADVISORY_TYPE_ASC',
  FUND_ADVISORY_TYPE_DESC: 'FUND_ADVISORY_TYPE_DESC',
  MINIMUM_INVESTMENT_AMOUNT_ASC: 'MINIMUM_INVESTMENT_AMOUNT_ASC',
  MINIMUM_INVESTMENT_AMOUNT_DESC: 'MINIMUM_INVESTMENT_AMOUNT_DESC',
  MINIMUM_INITIAL_INVESTMENT_ASC: 'MINIMUM_INITIAL_INVESTMENT_ASC',
  MINIMUM_INITIAL_INVESTMENT_DESC: 'MINIMUM_INITIAL_INVESTMENT_DESC',
  MINIMUM_ADDITIONAL_INVESTMENT_ASC: 'MINIMUM_ADDITIONAL_INVESTMENT_ASC',
  MINIMUM_ADDITIONAL_INVESTMENT_DESC: 'MINIMUM_ADDITIONAL_INVESTMENT_DESC',
  STRATEGY_DISPLAY_ASC: 'STRATEGY_DISPLAY_ASC',
  STRATEGY_DISPLAY_DESC: 'STRATEGY_DISPLAY_DESC',
  SUB_STRATEGY_DISPLAY_ASC: 'SUB_STRATEGY_DISPLAY_ASC',
  SUB_STRATEGY_DISPLAY_DESC: 'SUB_STRATEGY_DISPLAY_DESC',
  DOCUMENT_DUE_DATE_ASC: 'DOCUMENT_DUE_DATE_ASC',
  DOCUMENT_DUE_DATE_DESC: 'DOCUMENT_DUE_DATE_DESC',
  WIRE_DUE_DATE_ASC: 'WIRE_DUE_DATE_ASC',
  WIRE_DUE_DATE_DESC: 'WIRE_DUE_DATE_DESC',
  RANK_ASC: 'RANK_ASC',
  RANK_DESC: 'RANK_DESC',
} as const

export interface PageRequest {
  after?: string | null
  before?: string | null
  first?: number | null
  last?: number | null
}

export interface FundProductsAdminSearchRequestV1 {
  filter?: FundProductAdminFilter
  pageRequest?: PageRequest
  sorting?: FundProductsAdminSearchRequestV1SortingItem[] | null
}

export interface FirmRequestV1 {
  filter?: FirmFilter
  pageRequest?: PageRequest
  sorting?: FirmRequestV1SortingItem[] | null
}

export interface FundProductsSearchRequestV1 {
  filter?: FundProductFilterV1
  /**
   * Deprecated, use the expand query param instead
   * @deprecated
   */
  includePerformance?: boolean
  pageRequest?: PageRequest
  sorting?: FundProductsSearchRequestV1SortingItem[]
}

export interface InstantFilter {
  after?: string | null
  before?: string | null
  eq?: string | null
  exists?: boolean | null
  ne?: string | null
  notAfter?: string | null
  notBefore?: string | null
}

export interface FundProductAdminFilter {
  access?: FundAccessFilter
  and?: FundProductAdminFilter[] | null
  caisId?: StringFilter
  createdAt?: InstantFilter
  deletedAt?: InstantFilter
  fundManagerId?: UUIDFilter
  fundType?: FundTypeFilter
  id?: UUIDFilter
  legalName?: StringFilter
  not?: FundProductAdminFilter[] | null
  or?: FundProductAdminFilter[] | null
  publishState?: PublishStateFilter
  securityType?: SecurityTypeFilter
  strategy?: FundStrategyFilter
  structure?: FundStructureTypeFilter
  structureSubType?: FundStructureSubTypeFilter
  underlying?: boolean | null
}

export interface FirmFilter {
  id?: UUIDFilter
  name?: StringFilter
}

export type FundProductFilterV1QueryStringMatchType =
  (typeof FundProductFilterV1QueryStringMatchType)[keyof typeof FundProductFilterV1QueryStringMatchType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundProductFilterV1QueryStringMatchType = {
  BEST_FIELDS: 'BEST_FIELDS',
  PHRASE_PREFIX: 'PHRASE_PREFIX',
} as const

export type FundProductFilterV1Approval = {
  in: Approval[]
}

export interface OfferingTypeFilter {
  eq?: FundOfferingType
  in?: FundOfferingType[] | null
  ne?: FundOfferingType
}

export interface MercerRatingFilter {
  eq?: MercerRating
  in?: MercerRating[] | null
  ne?: MercerRating
}

export interface SubscriptionStatusFilter {
  eq?: FundSubscriptionStatus
  in?: FundSubscriptionStatus[] | null
  ne?: FundSubscriptionStatus
}

export interface AdvisoryTypeFilter {
  eq?: FundAdvisoryType
  in?: FundAdvisoryType[] | null
  ne?: FundAdvisoryType
}

export interface FundAccessFilter {
  eq?: FundAccess
  in?: FundAccess[] | null
  ne?: FundAccess
}

export interface InvestorTypeFilter {
  eq?: InvestorType
  in?: InvestorType[]
  ne?: InvestorType
}

export interface InvestorLevelFilter {
  eq?: InvestorLevel
  in?: InvestorLevel[]
  ne?: InvestorLevel
}

export interface TaxReportingFilter {
  eq?: TaxReporting
  in?: TaxReporting[]
  ne?: TaxReporting
}

export interface ObjectiveFilter {
  eq?: FundObjective
  in?: FundObjective[]
  ne?: FundObjective
}

export interface SecurityTypeFilter {
  eq?: FundSecurityType
  in?: FundSecurityType[]
  ne?: FundSecurityType
}

export interface FundProductFilterV1 {
  access?: FundAccessFilter
  advisoryType?: AdvisoryTypeFilter
  approval?: FundProductFilterV1Approval
  custodian?: UUIDFilter
  fundingStructure?: FundingStructureFilter
  fundManagerId?: UUIDFilter
  fundManagerShortName?: StringFilter
  fundraisingType?: FundraisingTypeFilter
  id?: UUIDFilter
  investorLevel?: InvestorLevelFilter
  investorType?: InvestorTypeFilter
  mercerRating?: MercerRatingFilter
  minimumAdditionalInvestment?: IntegerFilter
  minimumInitialInvestment?: IntegerFilter
  minimumInvestmentAmount?: IntegerFilter
  objective?: ObjectiveFilter
  offeringType?: OfferingTypeFilter
  queryString?: string
  queryStringMatchType?: FundProductFilterV1QueryStringMatchType
  rank?: RankFilter
  securityType?: SecurityTypeFilter
  solutionOfferingType?: SolutionOfferingTypeFilter
  strategy?: FundStrategyFilter
  strategyDisplay?: StringFilter
  strategySubStrategy?: StrategySubStrategyFilter
  strategySubStrategyDisplay?: StrategySubStrategyDisplayFilter
  structureType?: FundStructureTypeFilter
  subscriptionStatus?: SubscriptionStatusFilter
  taxReporting?: TaxReportingFilter
}

export type FundUnitizedIndicator =
  (typeof FundUnitizedIndicator)[keyof typeof FundUnitizedIndicator]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundUnitizedIndicator = {
  UNITIZED: 'UNITIZED',
  CAPITAL_BALANCE: 'CAPITAL_BALANCE',
} as const

export type AllowedAction = (typeof AllowedAction)[keyof typeof AllowedAction]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AllowedAction = {
  VIEW: 'VIEW',
  INVEST: 'INVEST',
  LEARN: 'LEARN',
  APPROVE: 'APPROVE',
  REQUEST_APPROVAL_TO_INVEST: 'REQUEST_APPROVAL_TO_INVEST',
  VIEW_DOCUMENTS: 'VIEW_DOCUMENTS',
  VIEW_MERCER: 'VIEW_MERCER',
  VIEW_OVERVIEW: 'VIEW_OVERVIEW',
  VIEW_PERFORMANCE: 'VIEW_PERFORMANCE',
} as const

export type FundPolicyStatus =
  (typeof FundPolicyStatus)[keyof typeof FundPolicyStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundPolicyStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
} as const

export type FundStage = (typeof FundStage)[keyof typeof FundStage]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundStage = {
  FUNDRAISING: 'FUNDRAISING',
  INVESTING: 'INVESTING',
  HARVESTING: 'HARVESTING',
  FULLY_REALIZED: 'FULLY_REALIZED',
  WINDING_DOWN: 'WINDING_DOWN',
  LIQUIDATED_FULLY_RETURNED: 'LIQUIDATED_FULLY_RETURNED',
} as const

export type EsgRating = (typeof EsgRating)[keyof typeof EsgRating]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EsgRating = {
  RATING_ESG_4: 'RATING_ESG_4',
  RATING_ESG_3: 'RATING_ESG_3',
  RATING_ESG_2: 'RATING_ESG_2',
  RATING_ESG_1: 'RATING_ESG_1',
  RATING_ESG_NOT_AVAILABLE: 'RATING_ESG_NOT_AVAILABLE',
  MERCER_REPORT_NOT_AVAILABLE: 'MERCER_REPORT_NOT_AVAILABLE',
  MERCER_REPORT_UNDER_REVIEW: 'MERCER_REPORT_UNDER_REVIEW',
} as const

export type MercerRating = (typeof MercerRating)[keyof typeof MercerRating]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MercerRating = {
  RATING_A: 'RATING_A',
  RATING_A_P: 'RATING_A_P',
  RATING_A_T: 'RATING_A_T',
  RATING_A_W: 'RATING_A_W',
  RATING_B: 'RATING_B',
  RATING_B_P: 'RATING_B_P',
  RATING_B_PLUS: 'RATING_B_PLUS',
  RATING_B_PLUS_P: 'RATING_B_PLUS_P',
  RATING_B_PLUS_T: 'RATING_B_PLUS_T',
  RATING_B_PLUS_W: 'RATING_B_PLUS_W',
  RATING_B_T: 'RATING_B_T',
  RATING_B_W: 'RATING_B_W',
  RATING_C: 'RATING_C',
  RATING_C_T: 'RATING_C_T',
  RATING_NOT_AVAILABLE: 'RATING_NOT_AVAILABLE',
  RATING_UNDER_REVIEW: 'RATING_UNDER_REVIEW',
  RATING_RV: 'RATING_RV',
} as const

export type FundStructureSubType =
  (typeof FundStructureSubType)[keyof typeof FundStructureSubType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundStructureSubType = {
  BUSINESS_DEVELOPMENT_COMPANY: 'BUSINESS_DEVELOPMENT_COMPANY',
  INTERVAL_FUND: 'INTERVAL_FUND',
  NON_TRADED_REIT: 'NON_TRADED_REIT',
  PRIVATE_PLACEMENT: 'PRIVATE_PLACEMENT',
  PREFERRED_STOCK: 'PREFERRED_STOCK',
  REIT: 'REIT',
  TENDER_FUND: 'TENDER_FUND',
  EXCHANGE_1031: 'EXCHANGE_1031',
} as const

export type FundStructureType =
  (typeof FundStructureType)[keyof typeof FundStructureType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundStructureType = {
  FINITE_LIFE: 'FINITE_LIFE',
  PERPETUAL: 'PERPETUAL',
} as const

export type FundSubStrategy =
  (typeof FundSubStrategy)[keyof typeof FundSubStrategy]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundSubStrategy = {
  ACTIVE_TRADING: 'ACTIVE_TRADING',
  ACTIVIST: 'ACTIVIST',
  BUYOUT: 'BUYOUT',
  CONSERVATIVE: 'CONSERVATIVE',
  CORE: 'CORE',
  CORE_PLUS: 'CORE_PLUS',
  CREDIT_ARBITRAGE: 'CREDIT_ARBITRAGE',
  CRYPTOCURRENCY: 'CRYPTOCURRENCY',
  CURRENCY: 'CURRENCY',
  DEBT: 'DEBT',
  DIRECT_LENDING: 'DIRECT_LENDING',
  DISCRETIONARY_THEMATIC: 'DISCRETIONARY_THEMATIC',
  DISTRESSED: 'DISTRESSED',
  DISTRESSED_RESTRUCTURING: 'DISTRESSED_RESTRUCTURING',
  DISTRESSED_DEBT: 'DISTRESSED_DEBT',
  DIVERSIFIED: 'DIVERSIFIED',
  EQUITY_MARKET_NEUTRAL: 'EQUITY_MARKET_NEUTRAL',
  FIXED_INCOME_ASSET_BACKED: 'FIXED_INCOME_ASSET_BACKED',
  FIXED_INCOME_CONVERTIBLE_ARBITRAGE: 'FIXED_INCOME_CONVERTIBLE_ARBITRAGE',
  FIXED_INCOME_CORPORATE: 'FIXED_INCOME_CORPORATE',
  FIXED_INCOME_SOVEREIGN: 'FIXED_INCOME_SOVEREIGN',
  FUND_OF_FUNDS_SUB_STRATEGY: 'FUND_OF_FUNDS_SUB_STRATEGY',
  FUNDAMENTAL_GROWTH: 'FUNDAMENTAL_GROWTH',
  FUNDAMENTAL_VALUE: 'FUNDAMENTAL_VALUE',
  GROWTH: 'GROWTH',
  MARKET_DEFENSIVE: 'MARKET_DEFENSIVE',
  MERGER_ARBITRAGE: 'MERGER_ARBITRAGE',
  MEZZANINE: 'MEZZANINE',
  MULTI_ASSET_SUB_STRATEGY: 'MULTI_ASSET_SUB_STRATEGY',
  MULTI_STRATEGY: 'MULTI_STRATEGY',
  NATURAL_RESOURCES_SUB_STRATEGY: 'NATURAL_RESOURCES_SUB_STRATEGY',
  OPPORTUNISTIC: 'OPPORTUNISTIC',
  PRIVATE_ISSUE_REGULATION_D: 'PRIVATE_ISSUE_REGULATION_D',
  QUANTITATIVE_DIRECTIONAL: 'QUANTITATIVE_DIRECTIONAL',
  SECONDARIES: 'SECONDARIES',
  SECTOR: 'SECTOR',
  SHORT_BIAS: 'SHORT_BIAS',
  SPECIAL_SITUATIONS: 'SPECIAL_SITUATIONS',
  STRATEGIC: 'STRATEGIC',
  SYSTEMATIC_DIVERSIFIED: 'SYSTEMATIC_DIVERSIFIED',
  VALUE_ADDED: 'VALUE_ADDED',
  VENTURE_CAPITAL: 'VENTURE_CAPITAL',
  VENTURE_DEBT: 'VENTURE_DEBT',
  VOLATILITY: 'VOLATILITY',
  YIELD_ALTERNATIVES: 'YIELD_ALTERNATIVES',
  OTHER_SUB_STRATEGY: 'OTHER_SUB_STRATEGY',
  UNKNOWN_SUB_STRATEGY: 'UNKNOWN_SUB_STRATEGY',
} as const

export type FundStrategy = (typeof FundStrategy)[keyof typeof FundStrategy]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundStrategy = {
  EQUITY_HEDGE: 'EQUITY_HEDGE',
  EVENT_DRIVEN: 'EVENT_DRIVEN',
  MACRO: 'MACRO',
  RELATIVE_VALUE: 'RELATIVE_VALUE',
  FUND_OF_FUNDS: 'FUND_OF_FUNDS',
  DIGITAL_ASSETS: 'DIGITAL_ASSETS',
  PRIVATE_EQUITY: 'PRIVATE_EQUITY',
  PRIVATE_DEBT: 'PRIVATE_DEBT',
  REAL_ESTATE: 'REAL_ESTATE',
  INFRASTRUCTURE: 'INFRASTRUCTURE',
  NATURAL_RESOURCES: 'NATURAL_RESOURCES',
  MULTI_ASSET: 'MULTI_ASSET',
  OTHER_STRATEGY: 'OTHER_STRATEGY',
  UNKNOWN_STRATEGY: 'UNKNOWN_STRATEGY',
} as const

export interface StrategySubStrategyDisplay {
  strategyDisplay: string
  subStrategyDisplay?: string | null
}

export interface StrategySubStrategy {
  strategy: FundStrategy
  subStrategy: FundSubStrategy
}

export type FundraisingType =
  (typeof FundraisingType)[keyof typeof FundraisingType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundraisingType = {
  OPEN_ENDED: 'OPEN_ENDED',
  CLOSED_ENDED: 'CLOSED_ENDED',
  CONTINUOUSLY_OFFERED_CLOSED_ENDED: 'CONTINUOUSLY_OFFERED_CLOSED_ENDED',
} as const

export type CurrencyCode = (typeof CurrencyCode)[keyof typeof CurrencyCode]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CurrencyCode = {
  AED: 'AED',
  AFN: 'AFN',
  ALL: 'ALL',
  ANG: 'ANG',
  ARS: 'ARS',
  AUD: 'AUD',
  AWG: 'AWG',
  AZN: 'AZN',
  BAM: 'BAM',
  BBD: 'BBD',
  BGN: 'BGN',
  BMD: 'BMD',
  BND: 'BND',
  BOB: 'BOB',
  BRL: 'BRL',
  BSD: 'BSD',
  BWP: 'BWP',
  BYN: 'BYN',
  BZD: 'BZD',
  CAD: 'CAD',
  CHF: 'CHF',
  CLP: 'CLP',
  CNY: 'CNY',
  COP: 'COP',
  CRC: 'CRC',
  CUP: 'CUP',
  CZK: 'CZK',
  DKK: 'DKK',
  DOP: 'DOP',
  EGP: 'EGP',
  EUR: 'EUR',
  FJD: 'FJD',
  FKP: 'FKP',
  GBP: 'GBP',
  GGP: 'GGP',
  GHS: 'GHS',
  GIP: 'GIP',
  GTQ: 'GTQ',
  GYD: 'GYD',
  HKD: 'HKD',
  HNL: 'HNL',
  HRK: 'HRK',
  HUF: 'HUF',
  IDR: 'IDR',
  ILS: 'ILS',
  IMP: 'IMP',
  INR: 'INR',
  IRR: 'IRR',
  ISK: 'ISK',
  JEP: 'JEP',
  JMD: 'JMD',
  JPY: 'JPY',
  KGS: 'KGS',
  KHR: 'KHR',
  KPW: 'KPW',
  KRW: 'KRW',
  KYD: 'KYD',
  KZT: 'KZT',
  LAK: 'LAK',
  LBP: 'LBP',
  LKR: 'LKR',
  LRD: 'LRD',
  MKD: 'MKD',
  MNT: 'MNT',
  MUR: 'MUR',
  MXN: 'MXN',
  MYR: 'MYR',
  MZN: 'MZN',
  NAD: 'NAD',
  NGN: 'NGN',
  NIO: 'NIO',
  NOK: 'NOK',
  NPR: 'NPR',
  NZD: 'NZD',
  OMR: 'OMR',
  PAB: 'PAB',
  PEN: 'PEN',
  PHP: 'PHP',
  PKR: 'PKR',
  PLN: 'PLN',
  PYG: 'PYG',
  QAR: 'QAR',
  RON: 'RON',
  RSD: 'RSD',
  RUB: 'RUB',
  SAR: 'SAR',
  SBD: 'SBD',
  SCR: 'SCR',
  SEK: 'SEK',
  SGD: 'SGD',
  SHP: 'SHP',
  SOS: 'SOS',
  SRD: 'SRD',
  SVC: 'SVC',
  SYP: 'SYP',
  THB: 'THB',
  TRY: 'TRY',
  TTD: 'TTD',
  TVD: 'TVD',
  TWD: 'TWD',
  UAH: 'UAH',
  USD: 'USD',
  UYU: 'UYU',
  UZS: 'UZS',
  VEF: 'VEF',
  VND: 'VND',
  XCD: 'XCD',
  YER: 'YER',
  ZAR: 'ZAR',
  ZWD: 'ZWD',
} as const

export type FundAdvisoryType =
  (typeof FundAdvisoryType)[keyof typeof FundAdvisoryType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundAdvisoryType = {
  BROKERAGE: 'BROKERAGE',
  ADVISORY: 'ADVISORY',
} as const

export type InvestorLevel = (typeof InvestorLevel)[keyof typeof InvestorLevel]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvestorLevel = {
  NON_ACCREDITED: 'NON_ACCREDITED',
  ACCREDITED_INVESTOR: 'ACCREDITED_INVESTOR',
  QUALIFIED_CLIENT: 'QUALIFIED_CLIENT',
  QUALIFIED_PURCHASER: 'QUALIFIED_PURCHASER',
} as const

export type TaxReporting = (typeof TaxReporting)[keyof typeof TaxReporting]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TaxReporting = {
  FORM_1099: 'FORM_1099',
  K1_K3: 'K1_K3',
  PFIC: 'PFIC',
  NA: 'NA',
} as const

export type InvestorType = (typeof InvestorType)[keyof typeof InvestorType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvestorType = {
  NON_US: 'NON_US',
  US_TAXABLE: 'US_TAXABLE',
  US_TAX_EXEMPT: 'US_TAX_EXEMPT',
} as const

export type FundingStructure =
  (typeof FundingStructure)[keyof typeof FundingStructure]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundingStructure = {
  DRAWDOWN: 'DRAWDOWN',
  FULLY_FUNDED: 'FULLY_FUNDED',
} as const

export type FundAccess = (typeof FundAccess)[keyof typeof FundAccess]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundAccess = {
  DIRECT: 'DIRECT',
  CONDUIT_VEHICLE: 'CONDUIT_VEHICLE',
  REFERENCE: 'REFERENCE',
} as const

export type FundType = (typeof FundType)[keyof typeof FundType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundType = {
  ONSHORE: 'ONSHORE',
  OFFSHORE: 'OFFSHORE',
} as const

export type FundEntityType =
  (typeof FundEntityType)[keyof typeof FundEntityType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundEntityType = {
  FUND: 'FUND',
  VARIANT: 'VARIANT',
} as const

export type DisplayAs = string | null

export type FundFrequency = (typeof FundFrequency)[keyof typeof FundFrequency]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundFrequency = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  SEMI_MONTHLY: 'SEMI_MONTHLY',
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  SEMI_ANNUALLY: 'SEMI_ANNUALLY',
  ANNUALLY: 'ANNUALLY',
  AD_HOC: 'AD_HOC',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
} as const

export type FundFeePaymentFrequency =
  (typeof FundFeePaymentFrequency)[keyof typeof FundFeePaymentFrequency]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundFeePaymentFrequency = {
  MONTHLY_IN_ADVANCE: 'MONTHLY_IN_ADVANCE',
  MONTHLY_IN_ARREARS: 'MONTHLY_IN_ARREARS',
  QUARTERLY_IN_ADVANCE: 'QUARTERLY_IN_ADVANCE',
  QUARTERLY_IN_ARREARS: 'QUARTERLY_IN_ARREARS',
  SEMI_ANNUALLY_IN_ADVANCE: 'SEMI_ANNUALLY_IN_ADVANCE',
  SEMI_ANNUALLY_IN_ARREARS: 'SEMI_ANNUALLY_IN_ARREARS',
  ANNUALLY_IN_ADVANCE: 'ANNUALLY_IN_ADVANCE',
  ANNUALLY_IN_ARREARS: 'ANNUALLY_IN_ARREARS',
} as const

export type FundFeeExpenseType =
  (typeof FundFeeExpenseType)[keyof typeof FundFeeExpenseType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundFeeExpenseType = {
  SINGLE_RATE: 'SINGLE_RATE',
  TIERED_RATE: 'TIERED_RATE',
} as const

export type FundFeeExpenseBase =
  (typeof FundFeeExpenseBase)[keyof typeof FundFeeExpenseBase]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundFeeExpenseBase = {
  NET_ASSET_VALUE: 'NET_ASSET_VALUE',
  GROSS_ASSET_VALUE: 'GROSS_ASSET_VALUE',
  COMMITMENT: 'COMMITMENT',
  UNFUNDED_COMMITMENT: 'UNFUNDED_COMMITMENT',
  NET_INVESTED_CAPITAL: 'NET_INVESTED_CAPITAL',
  GROSS_INVESTED_CAPITAL: 'GROSS_INVESTED_CAPITAL',
  COLLATERALIZED_LOAN_ASSETS: 'COLLATERALIZED_LOAN_ASSETS',
} as const

export type FundReportingSubStatus =
  (typeof FundReportingSubStatus)[keyof typeof FundReportingSubStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundReportingSubStatus = {
  WENT_PUBLIC: 'WENT_PUBLIC',
  LISTED_PRODUCT: 'LISTED_PRODUCT',
  LIQUIDATED: 'LIQUIDATED',
  REMOVED_FROM_PLATFORM: 'REMOVED_FROM_PLATFORM',
  FIRM_REQUESTED_OR_ARRANGEMENT: 'FIRM_REQUESTED_OR_ARRANGEMENT',
} as const

export type FundReportingStatus =
  (typeof FundReportingStatus)[keyof typeof FundReportingStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundReportingStatus = {
  ACTIVE_FOR_POST_TRADE_REPORTING: 'ACTIVE_FOR_POST_TRADE_REPORTING',
  IN_ACTIVE_FOR_POST_TRADE_REPORTING: 'IN_ACTIVE_FOR_POST_TRADE_REPORTING',
} as const

export type FundSubscriptionStatus =
  (typeof FundSubscriptionStatus)[keyof typeof FundSubscriptionStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundSubscriptionStatus = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  WAIT_LIST: 'WAIT_LIST',
  PENDING: 'PENDING',
  ONBOARDING: 'ONBOARDING',
} as const

export type FundSecurityType =
  (typeof FundSecurityType)[keyof typeof FundSecurityType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundSecurityType = {
  HEDGE_FUND: 'HEDGE_FUND',
  PRIVATE_MARKETS: 'PRIVATE_MARKETS',
  OPERATING_COMPANY: 'OPERATING_COMPANY',
} as const

export interface IntegerFilter {
  eq?: number | null
  exists?: boolean
  gt?: number | null
  gte?: number | null
  in?: number[] | null
  lt?: number | null
  lte?: number | null
  ne?: number | null
  notIn?: number[] | null
}

export interface FundingStructureFilter {
  eq?: FundingStructure
  in?: FundingStructure[] | null
  ne?: FundingStructure
}

export interface FundraisingTypeFilter {
  eq?: FundraisingType
  in?: FundraisingType[] | null
  ne?: FundraisingType
}

export interface RankFilter {
  eq?: Rank
  in?: Rank[] | null
  ne?: Rank
}

export interface SolutionOfferingTypeFilter {
  eq?: FundSolutionOfferingType
  in?: FundSolutionOfferingType[] | null
  ne?: FundSolutionOfferingType
}

export interface PublishStateFilter {
  eq?: FundPublishState
  in?: FundPublishState[] | null
  ne?: FundPublishState
}

export interface FundTypeFilter {
  eq?: FundType
  in?: FundType[] | null
  ne?: FundType
}

export interface FundStructureTypeFilter {
  eq?: FundStructureType
  in?: FundStructureType[] | null
  ne?: FundStructureType
}

export interface FundStructureSubTypeFilter {
  eq?: FundStructureSubType
  in?: FundStructureSubType[] | null
  ne?: FundStructureSubType
}

export interface StrategySubStrategyDisplayFilter {
  eq?: StrategySubStrategyDisplay
  in?: StrategySubStrategyDisplay[] | null
  ne?: StrategySubStrategyDisplay
}

export interface StrategySubStrategyFilter {
  eq?: StrategySubStrategy
  in?: StrategySubStrategy[] | null
  ne?: StrategySubStrategy
}

export interface FundStrategyFilter {
  eq?: FundStrategy
  in?: FundStrategy[] | null
  ne?: FundStrategy
}

export interface Unit {
  [key: string]: any
}

export type FundDateScheduleCalendarType =
  (typeof FundDateScheduleCalendarType)[keyof typeof FundDateScheduleCalendarType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundDateScheduleCalendarType = {
  SCHEDULE: 'SCHEDULE',
} as const

export type FundDateScheduleCalendarAllOfFrequency =
  (typeof FundDateScheduleCalendarAllOfFrequency)[keyof typeof FundDateScheduleCalendarAllOfFrequency]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundDateScheduleCalendarAllOfFrequency = {
  MONTHLY: 'MONTHLY',
  QUARTERLY: 'QUARTERLY',
  SEMI_ANNUALLY: 'SEMI_ANNUALLY',
  ANNUALLY: 'ANNUALLY',
  AD_HOC: 'AD_HOC',
} as const

export type FundDateScheduleCalendarAllOf = {
  frequency?: FundDateScheduleCalendarAllOfFrequency
  schedule?: FundCloseAndDueDateBlock[]
  [key: string]: any
}

export type FundDateScheduleDailyType =
  (typeof FundDateScheduleDailyType)[keyof typeof FundDateScheduleDailyType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundDateScheduleDailyType = {
  DAILY: 'DAILY',
} as const

export type FundDateScheduleDailyAllOf = { [key: string]: any }

export interface FundDateScheduleBase {
  finalCloseDate?: string
  type: FundDateScheduleType
  [key: string]: any
}

export type FundDateScheduleCalendar = FundDateScheduleBase &
  FundDateScheduleCalendarAllOf & {
    type: FundDateScheduleCalendarType
  }

export type FundDateScheduleDaily = FundDateScheduleBase &
  FundDateScheduleDailyAllOf & {
    type: FundDateScheduleDailyType
  }

export type TbdLegacyDueDateDueDateType =
  (typeof TbdLegacyDueDateDueDateType)[keyof typeof TbdLegacyDueDateDueDateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TbdLegacyDueDateDueDateType = {
  TBD: 'TBD',
} as const

export type TbdLegacyDueDateAllOf = { [key: string]: any }

export type TbdLegacyDueDate = FundLegacyDueDateAdminV1 &
  TbdLegacyDueDateAllOf & {
    dueDateType: TbdLegacyDueDateDueDateType
  }

export type TbdDueDateDueDateType =
  (typeof TbdDueDateDueDateType)[keyof typeof TbdDueDateDueDateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TbdDueDateDueDateType = {
  TBD: 'TBD',
} as const

export type TbdDueDateAllOf = { [key: string]: any }

export type TbdDueDate = FundDueDateAdminV1 &
  TbdDueDateAllOf & {
    dueDateType: TbdDueDateDueDateType
  }

export type SpecificLegacyDueDateDueDateType =
  (typeof SpecificLegacyDueDateDueDateType)[keyof typeof SpecificLegacyDueDateDueDateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpecificLegacyDueDateDueDateType = {
  SPECIFIC_DATE: 'SPECIFIC_DATE',
} as const

export type SpecificLegacyDueDateAllOf = {
  dueDate?: string
  [key: string]: any
}

export type SpecificLegacyDueDate = FundLegacyDueDateAdminV1 &
  SpecificLegacyDueDateAllOf & {
    dueDateType: SpecificLegacyDueDateDueDateType
  }

export type SpecificDueDateDueDateType =
  (typeof SpecificDueDateDueDateType)[keyof typeof SpecificDueDateDueDateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SpecificDueDateDueDateType = {
  SPECIFIC_DATE: 'SPECIFIC_DATE',
} as const

export type SpecificDueDateAllOf = {
  specificDueDate?: string
  [key: string]: any
}

export type SpecificDueDate = FundDueDateAdminV1 &
  SpecificDueDateAllOf & {
    dueDateType: SpecificDueDateDueDateType
  }

export type OffsetFromInvestmentDateLegacyDueDateDueDateType =
  (typeof OffsetFromInvestmentDateLegacyDueDateDueDateType)[keyof typeof OffsetFromInvestmentDateLegacyDueDateDueDateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OffsetFromInvestmentDateLegacyDueDateDueDateType = {
  OFFSET_FROM_INVESTMENT_DATE: 'OFFSET_FROM_INVESTMENT_DATE',
} as const

export type OffsetFromInvestmentDateLegacyDueDateAllOf = {
  calculatedEstimatedDueDate?: string
  offsetFromInvestmentDateInDays?: number
  [key: string]: any
}

export type OffsetFromInvestmentDateLegacyDueDate = FundLegacyDueDateAdminV1 &
  OffsetFromInvestmentDateLegacyDueDateAllOf & {
    dueDateType: OffsetFromInvestmentDateLegacyDueDateDueDateType
  }

export type OffsetFromInvestmentDateDueDateDueDateType =
  (typeof OffsetFromInvestmentDateDueDateDueDateType)[keyof typeof OffsetFromInvestmentDateDueDateDueDateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OffsetFromInvestmentDateDueDateDueDateType = {
  OFFSET_FROM_INVESTMENT_DATE: 'OFFSET_FROM_INVESTMENT_DATE',
} as const

export type OffsetFromInvestmentDateDueDateAllOf = {
  offsetDateType?: FundOffsetDateType
  offsetFromInvestmentDate?: number
  offsetFromInvestmentDateFrequency?: FundFrequency
  [key: string]: any
}

export type OffsetFromInvestmentDateDueDate = FundDueDateAdminV1 &
  OffsetFromInvestmentDateDueDateAllOf & {
    dueDateType: OffsetFromInvestmentDateDueDateDueDateType
  }

export type DailyLegacyDueDateDueDateType =
  (typeof DailyLegacyDueDateDueDateType)[keyof typeof DailyLegacyDueDateDueDateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DailyLegacyDueDateDueDateType = {
  DUE_DAILY: 'DUE_DAILY',
} as const

export type DailyLegacyDueDateAllOf = { [key: string]: any }

export type DailyLegacyDueDate = FundLegacyDueDateAdminV1 &
  DailyLegacyDueDateAllOf & {
    dueDateType: DailyLegacyDueDateDueDateType
  }

export type DailyDueDateDueDateType =
  (typeof DailyDueDateDueDateType)[keyof typeof DailyDueDateDueDateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DailyDueDateDueDateType = {
  DUE_DAILY: 'DUE_DAILY',
} as const

export type DailyDueDateAllOf = { [key: string]: any }

export type DailyDueDate = FundDueDateAdminV1 &
  DailyDueDateAllOf & {
    dueDateType: DailyDueDateDueDateType
  }

export type DueDateType = (typeof DueDateType)[keyof typeof DueDateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DueDateType = {
  SPECIFIC_DATE: 'SPECIFIC_DATE',
  OFFSET_FROM_INVESTMENT_DATE: 'OFFSET_FROM_INVESTMENT_DATE',
  TBD: 'TBD',
  DUE_DAILY: 'DUE_DAILY',
} as const

export type FundDateScheduleType =
  (typeof FundDateScheduleType)[keyof typeof FundDateScheduleType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundDateScheduleType = {
  DAILY: 'DAILY',
  SCHEDULE: 'SCHEDULE',
} as const

export interface FundWireInstructionsAdminV1 {
  abaNumber?: string | null
  accountName?: string | null
  accountNumber?: string | null
  bankName?: string | null
  city?: string | null
  country?: string | null
  postalCode?: string | null
  state?: string | null
  street1?: string | null
  street2?: string | null
  swiftCode?: string | null
}

export interface FundUnderlyingShareClassAdminV1 {
  underlyingFundProductId: string
  underlyingShareClassId: string
}

export type FundTransactionSupportAdminV1ElectronicSignatureTypeItem =
  (typeof FundTransactionSupportAdminV1ElectronicSignatureTypeItem)[keyof typeof FundTransactionSupportAdminV1ElectronicSignatureTypeItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundTransactionSupportAdminV1ElectronicSignatureTypeItem = {
  CAIS: 'CAIS',
  SCHWAB: 'SCHWAB',
  OTHER: 'OTHER',
} as const

export interface FundTransactionSupportAdminV1 {
  acceptElectronicSignature?: boolean
  closeAndDueDates?: FundCloseAndDueDateBlock[] | null
  closeDateSchedule?: FundCloseDateSchedule
  /** @deprecated */
  closingSchedule?: FundClosingScheduleAdminV1[]
  currentCloseDate?: string | null
  readonly currentDisplayCloseDate?: string | null
  documentDeliveryWorkflowIds?: string[]
  documentDueDate?: FundDueDatesAdminV1
  electronicSignatureType?: FundTransactionSupportAdminV1ElectronicSignatureTypeItem[]
  /** @deprecated */
  finalClosingDate?: string
  readonly nextCloseDate?: string | null
  readonly nextDisplayCloseDate?: string | null
  overrideFatcaRulesAndRequireFatcaForm?: boolean | null
  wireDueDate?: FundDueDatesAdminV1
}

export interface FundTransactionFeeOffSetAdminV1 {
  description?: string | null
  offset?: number | null
}

export type FundTermsAdminV1BasisOfAccounting =
  | (typeof FundTermsAdminV1BasisOfAccounting)[keyof typeof FundTermsAdminV1BasisOfAccounting]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundTermsAdminV1BasisOfAccounting = {
  US_GAAP: 'US_GAAP',
  IFRS: 'IFRS',
  LUX_GAAP: 'LUX_GAAP',
  OTHER: 'OTHER',
} as const

export interface FundTermsAdminV1 {
  basisOfAccounting?: FundTermsAdminV1BasisOfAccounting
  breakPoint?: number[]
  currencyCode?: CurrencyCode
  fundTermDisplayAs?: DisplayAs
  fundTermExtensionInYearsDecimal?: number[]
  fundTermInYearsDecimal?: number | null
  investmentPeriodExtensionInYearsDecimal?: number[]
  investmentPeriodInYearsDecimal?: number | null
  investorLevel?: InvestorLevel[]
  investorType?: InvestorType[]
  taxReporting?: TaxReporting[]
}

export interface FundTargetGrossAndNetAdminV1 {
  gross?: number | null
  net?: number | null
}

export interface FundTargetPerformanceAdminV1 {
  targetIRR?: FundTargetGrossAndNetAdminV1
  targetIRRHigh?: FundTargetGrossAndNetAdminV1
  targetLeverage?: number | null
  targetLeverageDescription?: string | null
  targetLeverageHigh?: number | null
  targetMoic?: FundTargetGrossAndNetAdminV1
  targetMoicHigh?: FundTargetGrossAndNetAdminV1
  targetReturn?: FundTargetGrossAndNetAdminV1
  targetReturnHigh?: FundTargetGrossAndNetAdminV1
  targetSharpeRatio?: number | null
  targetSharpeRatioHigh?: number | null
  targetVolatility?: number | null
  targetVolatilityHigh?: number | null
}

export type FundShareClassTermsAdminV1WaterFallType =
  | (typeof FundShareClassTermsAdminV1WaterFallType)[keyof typeof FundShareClassTermsAdminV1WaterFallType]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundShareClassTermsAdminV1WaterFallType = {
  AMERICAN: 'AMERICAN',
  EUROPEAN: 'EUROPEAN',
} as const

export interface FundShareClassTermsAdminV1 {
  advisoryTypes?: FundAdvisoryType[]
  auditHoldBack?: number | null
  auditHoldBackDisplayAs?: DisplayAs
  breakPoint?: number[]
  brokerageFees?: FundBrokerageFeesAdminV1
  catchup?: number | null
  clawbackDescription?: string | null
  clawbackFeature?: boolean | null
  closingFrequency?: FundFrequency
  conduitLevelMinimumAdditionalInvestment?: number | null
  conduitLevelMinimumInitialInvestment?: number | null
  conduitLevelMinimumRedemptionOrWithdrawal?: number | null
  conduitLevelMinimumRemainingInvestment?: number | null
  currency?: CurrencyCode
  distributionDividendFrequency?: FundFrequency
  exitFees?: FundExitFeesAdminV1
  expenseCap?: FundExpensesAdminV1
  gpCommitment?: number | null
  gpCommitmentDescription?: string | null
  hardCap?: number | null
  highWaterMark?: boolean | null
  incentiveFees?: FundIncentiveFeesAdminV1
  lockUp?: FundLockUpAdminV1
  managementFees?: FundFeesAdminV1
  managerExpenseAccommodation?: FundExpensesAdminV1
  minimumAdditionalInvestment?: number | null
  minimumInitialInvestment?: number | null
  minimumRedemptionOrWithdrawal?: number | null
  minimumRemainingInvestment?: number | null
  noFaultTermination?: boolean | null
  noFaultTerminationDescription?: string | null
  noticeForRedemptions?: string | null
  noticeForSubscriptions?: string | null
  passThroughExpense?: boolean | null
  redemptionGates?: FundRedemptionGateAdminV1[]
  redemptionIntervals?: FundFrequency
  redemptionIntervalsDisplayAs?: DisplayAs
  reportingStatus?: FundReportingStatus
  reportingSubStatus?: FundReportingSubStatus
  servicingFees?: FundFeesAdminV1
  sponsorFees?: FundFeesAdminV1
  statedMinimumAdditionalInvestment?: number | null
  statedMinimumInitialInvestment?: number | null
  statedMinimumRedemptionOrWithdrawal?: number | null
  statedMinimumRemainingInvestment?: number | null
  subscriptionIntervals?: FundFrequency
  subscriptionIntervalsDisplayAs?: DisplayAs
  subscriptionStatus?: FundSubscriptionStatus
  technologyFees?: FundFeesAdminV1
  transactionFeeOffSet?: FundTransactionFeeOffSetAdminV1
  transferFrequency?: FundFrequency
  transferFrequencyDisplayAs?: DisplayAs
  underlyingManagementFeeDisplayAs?: DisplayAs
  valuationFrequency?: FundFrequency
  waterFallType?: FundShareClassTermsAdminV1WaterFallType
}

export interface FundShareClassAdminV1 {
  caisId?: string
  createdAt: string
  createdBy: string
  deletedAt?: string
  deletedBy?: string
  details: FundShareClassDetailsAdminV1
  id: string
  policyStatus?: FundPolicyStatus
  publishState: FundPublishState
  terms: FundShareClassTermsAdminV1
  updatedAt?: string
  updatedBy?: string
}

export interface FundShareClassRequest {
  details: FundShareClassDetailsAdminV1
  id?: string | null
  terms: FundShareClassTermsAdminV1
}

export type FundSolutionType =
  (typeof FundSolutionType)[keyof typeof FundSolutionType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundSolutionType = {
  ADVISOR: 'ADVISOR',
  MANAGER: 'MANAGER',
} as const

export type FundSolutionOfferingType =
  (typeof FundSolutionOfferingType)[keyof typeof FundSolutionOfferingType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundSolutionOfferingType = {
  MARKETPLACE: 'MARKETPLACE',
  SOLUTION: 'SOLUTION',
} as const

export type FundOfferingType =
  (typeof FundOfferingType)[keyof typeof FundOfferingType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundOfferingType = {
  MARKETPLACE: 'MARKETPLACE',
  NON_MARKETPLACE: 'NON_MARKETPLACE',
} as const

export interface FundShareClassDetailsAdminV1 {
  caisPlatformLaunchDate?: string | null
  capitalCall?: boolean | null
  /** @deprecated */
  customSolutionsFirmId?: string[]
  description?: string | null
  fundingStructure?: FundingStructure
  fundraisingType?: FundraisingType
  generalDisclaimer?: string | null
  identifiers?: FundIdentifiersAdminV1
  inceptionDate?: string | null
  isDefault?: boolean | null
  name?: string
  nonMarketplaceDesignatedFirmIds?: string[]
  offeringType?: FundOfferingType
  performanceDisclaimer?: string | null
  proprietaryNetworkFirmId?: string | null
  solutionType?: FundSolutionType[]
  stage?: FundStage
  subscriptionDisclaimer?: string | null
  underlyingShareClasses?: FundUnderlyingShareClassAdminV1[]
}

export interface FundSensitiveInformationAdminV1 {
  fundWireInstructions?: FundWireInstructionsAdminV1
  taxId?: string | null
}

export type FundRedemptionGateAdminV1Type =
  | (typeof FundRedemptionGateAdminV1Type)[keyof typeof FundRedemptionGateAdminV1Type]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundRedemptionGateAdminV1Type = {
  FUND_LEVEL: 'FUND_LEVEL',
  INVESTOR_LEVEL: 'INVESTOR_LEVEL',
  SHARE_CLASS_LEVEL: 'SHARE_CLASS_LEVEL',
} as const

export type FundRedemptionGateAdminV1FrequencyType =
  | (typeof FundRedemptionGateAdminV1FrequencyType)[keyof typeof FundRedemptionGateAdminV1FrequencyType]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundRedemptionGateAdminV1FrequencyType = {
  FISCAL: 'FISCAL',
  ROLLING: 'ROLLING',
} as const

export interface FundRedemptionGateAdminV1 {
  frequency?: FundFrequency
  frequencyType?: FundRedemptionGateAdminV1FrequencyType
  redemptionGatePercentage?: number | null
  type?: FundRedemptionGateAdminV1Type
}

export interface FundProductLegacyAdminV1 {
  auditHoldBack?: number | null
  exitFee?: string | null
  highWaterMark?: boolean | null
  incentiveFee?: string | null
  /**
   * Migrating away towards configurable sorting ranks for funds for a firm
   * @deprecated
   */
  isFocusNetworkFund?: boolean | null
  legacyFundPageMode?: boolean
  lockup?: string | null
  managementFee?: string | null
  minimumAdditionalInvestment?: number
  minimumInitialInvestment?: number
  noticeForRedemptions?: string | null
  parentFundProductId?: string | null
  redemptionInterval?: string | null
  subscriptionInterval?: string | null
}

export interface FundProductAdminRequestV1 {
  fundDetails: FundDetailsAdminV1
  shareClasses: FundShareClassRequest[]
}

export interface DocumentsVariantOverridesV1 {
  allowDocuments?: string[] | null
  suppressCategories?: string[] | null
  suppressDocuments?: string[] | null
}

export type ShareClassVariantOverridesV1Terms = {
  brokerageFees?: FundBrokerageFeesAdminV1
  minimumAdditionalInvestment?: number | null
  minimumInitialInvestment?: number | null
  reportingStatus?: FundReportingStatus
  reportingSubStatus?: FundReportingSubStatus
  subscriptionStatus?: FundSubscriptionStatus
}

export interface ShareClassVariantOverridesV1 {
  id: string
  terms?: ShareClassVariantOverridesV1Terms
}

export type FundVariantFundDetailsV1Terms = {
  investorLevel?: InvestorLevel[]
  investorType?: InvestorType[]
  reportingStatus?: FundReportingStatus
  reportingSubStatus?: FundReportingSubStatus
  subscriptionStatus?: FundSubscriptionStatus
}

export type FundVariantFundDetailsV1GeneralInformation = {
  videoPlayListId?: string | null
}

export type FundVariantFundDetailsV1ClosingSchedule = {
  closeAndDueDates?: FundCloseAndDueDateBlock[] | null
  readonly currentCloseDate?: string
  readonly currentDisplayCloseDate?: string
  readonly nextCloseDate?: string
  readonly nextDisplayCloseDate?: string
  schedule?: FundCloseDateSchedule
}

export interface FundVariantFundDetailsV1 {
  closingSchedule?: FundVariantFundDetailsV1ClosingSchedule
  generalInformation?: FundVariantFundDetailsV1GeneralInformation
  terms?: FundVariantFundDetailsV1Terms
}

export interface FundVariantOverridesV1 {
  description?: string
  documents?: DocumentsVariantOverridesV1
  fundDetails?: FundVariantFundDetailsV1
  publishState: FundPublishState
  shareClasses?: ShareClassVariantOverridesV1[]
}

export type FundAdminVariantDetailsV1Firm = {
  id: string
  name: string
}

export type FundAdminVariantDetailsV1BaseFund = {
  id: string
  version: number
}

export interface FundAdminVariantDetailsV1 {
  baseFund?: FundAdminVariantDetailsV1BaseFund
  firm: FundAdminVariantDetailsV1Firm
  id: string
  name?: string
  overrides: FundVariantOverridesV1
  /** The version of the variant overrides. */
  version: number
}

export type FundFirmConfigurationV1Fund = {
  id: string
}

export type FundFirmConfigurationV1Firm = {
  id: string
  /** The display name of the firm. */
  name?: string
}

export type FundFirmConfigurationConfigV1ShareClassesSolutions = {
  postTrade: boolean
  preTrade: boolean
  trade: boolean
}

export type FundFirmConfigurationConfigV1ShareClasses = {
  [key: string]: {
    solutions?: FundFirmConfigurationConfigV1ShareClassesSolutions
  }
}

export interface FundFirmConfigurationConfigV1 {
  shareClasses?: FundFirmConfigurationConfigV1ShareClasses
}

export interface FundFirmConfigurationV1 {
  config: FundFirmConfigurationConfigV1
  createdAt: string
  createdBy: string
  firm: FundFirmConfigurationV1Firm
  fund: FundFirmConfigurationV1Fund
  id: string
  isDeleted: boolean
  updatedAt: string
  updatedBy: string
  version: number
}

export type FundVariantSummaryV1Firm = {
  id: string
  name: string
}

export interface FundVariantSummaryV1 {
  description?: string
  firm: FundVariantSummaryV1Firm
  id: string
  publishState: FundPublishState
}

export type FundLockUpAdminV1Type =
  | (typeof FundLockUpAdminV1Type)[keyof typeof FundLockUpAdminV1Type]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundLockUpAdminV1Type = {
  SOFT: 'SOFT',
  HARD: 'HARD',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
} as const

export type FundLockUpAdminV1Period =
  | (typeof FundLockUpAdminV1Period)[keyof typeof FundLockUpAdminV1Period]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundLockUpAdminV1Period = {
  ROLLING: 'ROLLING',
  FIXED: 'FIXED',
} as const

export interface FundLockUpAdminV1 {
  displayAs?: DisplayAs
  period?: FundLockUpAdminV1Period
  timeFrameInMonths?: number | null
  type?: FundLockUpAdminV1Type
}

export interface FundLegacyDueDateAdminV1 {
  dueDateType: DueDateType
}

export interface FundDueDateAdminV1 {
  dueDateType: DueDateType
}

export type FundDateScheduleV1 =
  | FundDateScheduleDaily
  | FundDateScheduleCalendar

export interface FundIncentiveFeeAdminV1 {
  description?: string | null
  displayAs?: DisplayAs
  fee?: number | null
  incentiveFeeCrystallizationFrequency?: FundFrequency
  preferredReturnAndHurdleRate?: number | null
}

export interface FundIncentiveFeesAdminV1 {
  fees?: FundIncentiveFeeAdminV1[]
  feeSchedule?: string[]
}

export type FundObjective =
  | (typeof FundObjective)[keyof typeof FundObjective]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundObjective = {
  ENHANCE_RETURNS: 'ENHANCE_RETURNS',
  SUPPLEMENT_INCOME: 'SUPPLEMENT_INCOME',
  DIVERSIFY_RISK: 'DIVERSIFY_RISK',
  PRESERVE_CAPITAL: 'PRESERVE_CAPITAL',
} as const

export type FundObjectivesAdminV1Scoring = { [key: string]: number } | null

export interface FundObjectivesAdminV1 {
  description?: string | null
  primary?: FundObjective
  scoring?: FundObjectivesAdminV1Scoring
  secondary?: FundObjective
}

export interface FundIdentifiersAdminV1 {
  caisInternalSymbols?: string[]
  custodianExternalIds?: FundCustodianExternalIdentifierAdminV1[]
  firmExternalIds?: FundExternalIdentifierAdminV1[]
  fundAdminExternalIds?: FundExternalIdentifierAdminV1[]
  fundManagerExternalIds?: FundExternalIdentifierAdminV1[]
  nsccNumbers?: string[]
  tickers?: string[]
}

export type FundGeneralInformationAdminV1SecuritySubType =
  | (typeof FundGeneralInformationAdminV1SecuritySubType)[keyof typeof FundGeneralInformationAdminV1SecuritySubType]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundGeneralInformationAdminV1SecuritySubType = {
  REGISTERED_INVESTMENT_COMPANY: 'REGISTERED_INVESTMENT_COMPANY',
  PRIVATE_FUND: 'PRIVATE_FUND',
  EXCHANGE_FUND: 'EXCHANGE_FUND',
  EXCHANGE_1013: 'EXCHANGE_1013',
} as const

export type FundGeneralInformationAdminV1RegisteredFundIndicator =
  | (typeof FundGeneralInformationAdminV1RegisteredFundIndicator)[keyof typeof FundGeneralInformationAdminV1RegisteredFundIndicator]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundGeneralInformationAdminV1RegisteredFundIndicator = {
  REGISTERED: 'REGISTERED',
  UNREGISTERED: 'UNREGISTERED',
} as const

export type FundGeneralInformationAdminV1LegalStructure =
  | (typeof FundGeneralInformationAdminV1LegalStructure)[keyof typeof FundGeneralInformationAdminV1LegalStructure]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundGeneralInformationAdminV1LegalStructure = {
  LIMITED_PARTNERSHIP_LP: 'LIMITED_PARTNERSHIP_LP',
  LIMITED_LIABILITY_COMPANY_LLC: 'LIMITED_LIABILITY_COMPANY_LLC',
  LIMITED_LTD: 'LIMITED_LTD',
  COMPANY: 'COMPANY',
  UNIT_TRUST: 'UNIT_TRUST',
  DELAWARE_STATUTORY_TRUST: 'DELAWARE_STATUTORY_TRUST',
  OTHER: 'OTHER',
} as const

export type FundGeneralInformationAdminV1Jurisdiction =
  | (typeof FundGeneralInformationAdminV1Jurisdiction)[keyof typeof FundGeneralInformationAdminV1Jurisdiction]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundGeneralInformationAdminV1Jurisdiction = {
  OTHER_JURISDICTION: 'OTHER_JURISDICTION',
  ALABAMA: 'ALABAMA',
  ALASKA: 'ALASKA',
  ARGENTINA: 'ARGENTINA',
  ARIZONA: 'ARIZONA',
  ARKANSAS: 'ARKANSAS',
  ARUBA: 'ARUBA',
  AUSTRALIA: 'AUSTRALIA',
  BAHAMAS: 'BAHAMAS',
  BERMUDA: 'BERMUDA',
  BRAZIL: 'BRAZIL',
  BRITISH_VIRGIN_ISLANDS: 'BRITISH_VIRGIN_ISLANDS',
  CALIFORNIA: 'CALIFORNIA',
  CANADA: 'CANADA',
  CAYMAN_ISLANDS: 'CAYMAN_ISLANDS',
  CHILE: 'CHILE',
  COLORADO: 'COLORADO',
  COLUMBIA: 'COLUMBIA',
  CONNECTICUT: 'CONNECTICUT',
  DELAWARE: 'DELAWARE',
  DOMINICAN_REPUBLIC: 'DOMINICAN_REPUBLIC',
  EL_SALVADOR: 'EL_SALVADOR',
  FLORIDA: 'FLORIDA',
  GEORGIA: 'GEORGIA',
  GUATEMALA: 'GUATEMALA',
  HAWAII: 'HAWAII',
  HONG_KONG: 'HONG_KONG',
  IDAHO: 'IDAHO',
  IRELAND: 'IRELAND',
  ILLINOIS: 'ILLINOIS',
  INDIANA: 'INDIANA',
  IOWA: 'IOWA',
  ISRAEL: 'ISRAEL',
  JAMAICA: 'JAMAICA',
  JERSEY_GUERNSEY: 'JERSEY_GUERNSEY',
  KANSAS: 'KANSAS',
  KENTUCKY: 'KENTUCKY',
  LOUISIANA: 'LOUISIANA',
  LUXEMBOURG: 'LUXEMBOURG',
  MAINE: 'MAINE',
  MARYLAND: 'MARYLAND',
  MASSACHUSETTS: 'MASSACHUSETTS',
  MEXICO: 'MEXICO',
  MICHIGAN: 'MICHIGAN',
  MINNESOTA: 'MINNESOTA',
  MISSISSIPPI: 'MISSISSIPPI',
  MISSOURI: 'MISSOURI',
  MONTANA: 'MONTANA',
  NEBRASKA: 'NEBRASKA',
  NEVADA: 'NEVADA',
  NEW_HAMPSHIRE: 'NEW_HAMPSHIRE',
  NEW_JERSEY: 'NEW_JERSEY',
  NEW_MEXICO: 'NEW_MEXICO',
  NEW_YORK: 'NEW_YORK',
  NEW_ZEALAND: 'NEW_ZEALAND',
  NORTH_CAROLINA: 'NORTH_CAROLINA',
  NORTH_DAKOTA: 'NORTH_DAKOTA',
  OHIO: 'OHIO',
  OKLAHOMA: 'OKLAHOMA',
  OREGON: 'OREGON',
  PANAMA: 'PANAMA',
  PENNSYLVANIA: 'PENNSYLVANIA',
  PERU: 'PERU',
  RHODE_ISLAND: 'RHODE_ISLAND',
  SINGAPORE: 'SINGAPORE',
  SOUTH_CAROLINA: 'SOUTH_CAROLINA',
  SOUTH_DAKOTA: 'SOUTH_DAKOTA',
  SWITZERLAND: 'SWITZERLAND',
  TENNESSEE: 'TENNESSEE',
  TEXAS: 'TEXAS',
  TURKS_AND_CAICOS: 'TURKS_AND_CAICOS',
  UNITED_KINGDOM: 'UNITED_KINGDOM',
  URUGUAY: 'URUGUAY',
  UTAH: 'UTAH',
  VENEZUELA: 'VENEZUELA',
  VERMONT: 'VERMONT',
  VIRGINIA: 'VIRGINIA',
  WASHINGTON: 'WASHINGTON',
  WASHINGTON_DC: 'WASHINGTON_DC',
  WEST_VIRGINIA: 'WEST_VIRGINIA',
  WISCONSIN: 'WISCONSIN',
  WYOMING: 'WYOMING',
} as const

export type FundGeneralInformationAdminV1GeographicFocusItem =
  (typeof FundGeneralInformationAdminV1GeographicFocusItem)[keyof typeof FundGeneralInformationAdminV1GeographicFocusItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundGeneralInformationAdminV1GeographicFocusItem = {
  AFRICA: 'AFRICA',
  ANTARCTICA: 'ANTARCTICA',
  ASIA: 'ASIA',
  EUROPE: 'EUROPE',
  NORTH_AMERICA: 'NORTH_AMERICA',
  OCEANIA: 'OCEANIA',
  SOUTH_AMERICA: 'SOUTH_AMERICA',
  GLOBAL: 'GLOBAL',
} as const

export type FundGeneralInformationAdminV1CapabilitiesItem =
  (typeof FundGeneralInformationAdminV1CapabilitiesItem)[keyof typeof FundGeneralInformationAdminV1CapabilitiesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundGeneralInformationAdminV1CapabilitiesItem = {
  FULL: 'FULL',
  SUBSCRIPTIONS_ONLY: 'SUBSCRIPTIONS_ONLY',
} as const

/**
 * @deprecated
 */
export type FundGeneralInformationAdminV1ActThirtyThreeRegistrationIndicator =
  | (typeof FundGeneralInformationAdminV1ActThirtyThreeRegistrationIndicator)[keyof typeof FundGeneralInformationAdminV1ActThirtyThreeRegistrationIndicator]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundGeneralInformationAdminV1ActThirtyThreeRegistrationIndicator =
  {
    REGISTERED: 'REGISTERED',
    UNREGISTERED: 'UNREGISTERED',
  } as const

export interface FundGeneralInformationAdminV1 {
  access?: FundAccess
  /** @deprecated */
  actThirtyThreeRegistrationIndicator?: FundGeneralInformationAdminV1ActThirtyThreeRegistrationIndicator
  adminId?: string | null
  aipServiceBureau?: boolean | null
  areSlotsRequired?: boolean | null
  benchmarkId?: string | null
  caisPlatformLaunchDate?: string | null
  capabilities?: FundGeneralInformationAdminV1CapabilitiesItem[] | null
  capitalCall?: boolean | null
  chartName?: string | null
  description?: string | null
  esgRating?: EsgRating
  fundingStructure?: FundingStructure
  fundManagerId?: string | null
  fundraisingType?: FundraisingType
  fundStage?: FundStage
  generalDisclaimer?: string | null
  generalPartnerId?: string | null
  geographicFocus?: FundGeneralInformationAdminV1GeographicFocusItem[]
  inboundUnitizedIndicator?: FundUnitizedIndicator
  inceptionDate?: string | null
  industry?: string | null
  jurisdiction?: FundGeneralInformationAdminV1Jurisdiction
  legalName?: string | null
  legalStructure?: FundGeneralInformationAdminV1LegalStructure
  mercerRating?: MercerRating
  numberOfSlotsAvailable?: number | null
  outboundUnitizedIndicator?: FundUnitizedIndicator
  parentFundId?: string
  performanceDisclaimer?: string | null
  /** @deprecated */
  referenceFund?: boolean | null
  registeredFundIndicator?: FundGeneralInformationAdminV1RegisteredFundIndicator
  sector?: string | null
  securitySubType?: FundGeneralInformationAdminV1SecuritySubType
  securityType?: FundSecurityType
  shortName?: string | null
  strategy?: FundStrategy
  strategyAum?: number | null
  strategyAumAsOfDate?: string | null
  strategyIfOther?: string | null
  structureSubType?: FundStructureSubType
  structureType?: FundStructureType
  subscriptionDisclaimer?: string | null
  subStrategy?: FundSubStrategy
  subStrategyIfOther?: string | null
  totalNumberOfSlots?: number | null
  trackRecordStartDate?: string | null
  type?: FundType
  underlying?: boolean
  underlyingFundProductId?: string[]
  videoPlayListId?: string | null
  vintageFundYear?: number | null
}

export interface FundFeeV1 {
  base?: FundFeeExpenseBase
  description?: string | null
  displayAs?: DisplayAs
  effectiveEndDate?: string | null
  effectiveStartDate?: string | null
  fee?: number | null
}

export interface FundFeesV1 {
  fees?: FundFeeV1[]
  paymentFrequency?: FundFeePaymentFrequency
  type?: FundFeeExpenseType
}

export interface FundFeeAdminV1 {
  base?: FundFeeExpenseBase
  description?: string | null
  displayAs?: DisplayAs
  effectiveEndDate?: string | null
  effectiveStartDate?: string | null
  fee?: number | null
}

export interface FundFeesAdminV1 {
  fees: FundFeeAdminV1[]
  paymentFrequency?: FundFeePaymentFrequency
  type?: FundFeeExpenseType
}

export interface FundExternalIdentifierAdminV1 {
  partyId: string
  value: string
}

export interface FundExpensesAdminV1 {
  expenses: FundExpenseAdminV1[]
  frequency?: FundFrequency
  type?: FundFeeExpenseType
}

export interface FundExpenseV1 {
  base?: FundFeeExpenseBase
  description?: string | null
  displayAs?: DisplayAs
  effectiveEndDate?: string | null
  effectiveStartDate?: string | null
  expense?: number | null
}

export interface FundExpensesV1 {
  expenses: FundExpenseV1[]
  frequency?: FundFrequency
  type?: FundFeeExpenseType
}

export interface FundExpenseAdminV1 {
  base?: FundFeeExpenseBase
  description?: string | null
  displayAs?: DisplayAs
  effectiveEndDate?: string | null
  effectiveStartDate?: string | null
  expense?: number | null
}

export interface FundExitFeeAdminV1 {
  base?: FundFeeExpenseBase
  description?: string | null
  displayAs?: DisplayAs
  fee?: number | null
}

export interface FundExitFeesAdminV1 {
  exitFees: FundExitFeeAdminV1[]
}

/**
 * @deprecated
 */
export interface FundDueDatesAdminV1 {
  dueDates?: FundDueDateAdminV1[]
  legacyDueDate?: FundLegacyDueDateAdminV1
}

export interface FundDetailsAdminV1 {
  agreementsAndDisclosures?: FundAgreementsAndDisclosuresV1
  bypassCusipValidation?: boolean
  generalInformation: FundGeneralInformationAdminV1
  identifiers?: FundIdentifiersAdminV1
  legacy?: FundProductLegacyAdminV1
  objectives?: FundObjectivesAdminV1
  reportingStatus?: FundReportingStatus
  reportingSubStatus?: FundReportingSubStatus
  sensitiveInformation: FundSensitiveInformationAdminV1
  subscriptionStatus?: FundSubscriptionStatus
  targetPerformance?: FundTargetPerformanceAdminV1
  terms: FundTermsAdminV1
  transactionSupport: FundTransactionSupportAdminV1
}

export interface FirmV1 {
  id?: string
  name?: string
}

export interface FundCustodianExternalIdentifierAdminV1 {
  additionalInvestment?: number | null
  capitalCallExternalIdentifier?: string | null
  externalIdentifier: string
  fidelityServiceAgreement?: boolean
  firmIdsWaivedFromMinimumInvestmentRequirements?: string[]
  id: string
  /**
   * Deprecated, use supportedPlatformIds instead
   * @deprecated
   */
  iwsAvailable?: boolean
  minimumInvestment?: number | null
  /**
   * Deprecated, use supportedPlatformIds instead
   * @deprecated
   */
  nfsAvailable?: boolean
  /**
   * Deprecated, use supportedPlatformIds instead
   * @deprecated
   */
  schwabAiMarketPlaceFund?: boolean
  /** IDs of the supported custodian platforms */
  supportedPlatformIds?: string[] | null
}

export interface FundClosingScheduleV1 {
  closingDate: string
  isFinalClose: boolean
}

export interface FundClosingScheduleAdminV1 {
  closingDate: string
  isFinalClose: boolean
}

export type FundBrokerageFeesAdminV1PlacementFeeCalculationMethod =
  | (typeof FundBrokerageFeesAdminV1PlacementFeeCalculationMethod)[keyof typeof FundBrokerageFeesAdminV1PlacementFeeCalculationMethod]
  | null

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundBrokerageFeesAdminV1PlacementFeeCalculationMethod = {
  NET: 'NET',
  GROSS: 'GROSS',
} as const

export interface FundBrokerageFeesAdminV1 {
  allowableIncrementsWithinRange?: number | null
  maximumPlacementFee?: number | null
  minimumPlacementFee?: number | null
  placementFeeCalculationMethod?: FundBrokerageFeesAdminV1PlacementFeeCalculationMethod
  placementFeeDescription?: string | null
  trailPayment?: number | null
  trailPaymentDescription?: string | null
  trailPaymentDisplayAs?: DisplayAs
}

export interface FundProductAdminPageV1 {
  items: FundProductAdminV1[]
  pageInfo: PageInfo
}

export interface FirmPageV1 {
  items: FirmV1[]
  pageInfo: PageInfo
}

export interface ParentFundPageV1 {
  items: ParentFundV1[]
  pageInfo: PageInfo
}

export type FundProductAdminV1ShareClassIndicator =
  (typeof FundProductAdminV1ShareClassIndicator)[keyof typeof FundProductAdminV1ShareClassIndicator]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundProductAdminV1ShareClassIndicator = {
  FUND_HAS_SHARE_CLASSES: 'FUND_HAS_SHARE_CLASSES',
  FUND_HAS_SHARE_CLASS_BASED_ON_ADDITIONAL_FUND_DETAILS:
    'FUND_HAS_SHARE_CLASS_BASED_ON_ADDITIONAL_FUND_DETAILS',
} as const

export interface FundProductAdminV1 {
  /** The fund or variant CAIS identifier */
  caisId?: string
  createdAt: string
  createdBy: string
  deleted?: boolean
  deletedAt?: string
  deletedBy?: string
  fundDetails?: FundDetailsAdminV1
  /** The fund or variant identifier */
  id: string
  publishState: FundPublishState
  shareClasses?: FundShareClassAdminV1[]
  shareClassIndicator?: FundProductAdminV1ShareClassIndicator
  type?: FundEntityType
  updatedAt?: string
  updatedBy?: string
  variantDetails?: FundAdminVariantDetailsV1
  variants?: FundVariantSummaryV1[]
  /** The event version. For funds, this is the fund version. For variants, this is the product of the fund version and the variant version. */
  version: number
}

export type PublishProgressStatus =
  (typeof PublishProgressStatus)[keyof typeof PublishProgressStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublishProgressStatus = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
} as const

export type PublishProgressState = {
  failedFundIds: string[]
  remainingFundIds: string[]
  succeededFundIds: string[]
}

export interface PublishProgress {
  completedTime?: string
  startTime?: string
  state?: PublishProgressState
  status?: PublishProgressStatus
  taskInstance?: string
  taskName?: string
}

export interface CustodianAggregation {
  count: number
  id: string
  name?: string
  value: string
}

export interface RankAggregation {
  count: number
  value: Rank
}

export interface StructureTypeAggregation {
  count: number
  value: FundStructureType
}

export interface FundingStructureAggregation {
  count: number
  value: FundingStructure
}

export interface FundraisingTypeAggregation {
  count: number
  value: FundraisingType
}

export interface TaxReportingAggregation {
  count: number
  value: TaxReporting
}

export interface SolutionOfferingTypeAggregation {
  count: number
  value: FundSolutionOfferingType
}

export interface OfferingTypeAggregation {
  count: number
  value: FundOfferingType
}

export interface MercerRatingAggregation {
  count: number
  value: MercerRating
}

export interface StringAggregation {
  count: number
  value: string
}

export interface UUIDAggregation {
  count: number
  value: string
}

export interface SubscriptionStatusAggregation {
  count: number
  value: FundSubscriptionStatus
}

export interface AccessAggregation {
  count: number
  value: FundAccess
}

export interface SecurityTypeAggregation {
  count: number
  value: FundSecurityType
}

export interface AdvisoryAggregation {
  count: number
  value: FundAdvisoryType
}

export interface InvestorTypeAggregation {
  count: number
  value: InvestorType
}

export interface InvestorLevelAggregation {
  count: number
  value: InvestorLevel
}

export interface StrategyDisplayAggregation {
  count: number
  subStrategies?: StringAggregation[]
  value: string
}

export interface SubStrategyAggregation {
  count: number
  value: FundSubStrategy
}

export interface StrategyAggregation {
  count: number
  subStrategies?: SubStrategyAggregation[]
  value: FundStrategy
}

export interface ObjectiveAggregation {
  count: number
  value: FundObjective
}

export interface MinimumInvestmentAggregation {
  max: number
}

export interface FundProductAggregationsV1 {
  access: AccessAggregation[]
  advisory: AdvisoryAggregation[]
  custodian?: CustodianAggregation[]
  fundingStructure?: FundingStructureAggregation[]
  fundManager: UUIDAggregation[]
  fundManagerShortName: StringAggregation[]
  fundraisingType?: FundraisingTypeAggregation[]
  investorLevel: InvestorLevelAggregation[]
  investorType: InvestorTypeAggregation[]
  mercerRating?: MercerRatingAggregation[]
  minimumAdditionalInvestment: MinimumInvestmentAggregation
  minimumInitialInvestment: MinimumInvestmentAggregation
  objective: ObjectiveAggregation[]
  offeringType?: OfferingTypeAggregation[]
  rank?: RankAggregation[]
  securityType: SecurityTypeAggregation[]
  solutionOfferingType?: SolutionOfferingTypeAggregation[]
  strategy: StrategyAggregation[]
  strategyDisplay?: StrategyDisplayAggregation[]
  structureType?: StructureTypeAggregation[]
  subscriptionStatus: SubscriptionStatusAggregation[]
  taxReporting?: TaxReportingAggregation[]
  totalCount?: number
}

export interface BenchmarkV1 {
  id: string
  name: string
}

export interface DocumentDeliveryWorkflowV1 {
  id: string
  name: string
}

export interface FundManagerV1 {
  id: string
  name: string
  shortName?: string
}

export interface FundAdministratorV1 {
  id: string
  name: string
}

export interface GlobalViolation {
  message?: string
}

export interface FieldViolation {
  fieldName?: string
  message?: string
}

export interface Violations {
  fieldViolations?: FieldViolation[]
  globalViolations?: GlobalViolation[]
}

export type ErrorResponseErrorAttributes = {
  violations?: Violations
}

export interface ErrorResponse {
  displayMessage?: string
  errorAttributes?: ErrorResponseErrorAttributes
  errorCode?: string
  errorMessage: string
  errorUri?: string
  status: number
}

export interface PageInfo {
  endCursor?: string | null
  hasNextPage?: boolean
  hasPreviousPage?: boolean
  startCursor?: string | null
}

export interface BenchmarkCumulative {
  cumulativeAmount?: number
  date?: string
  fundProductId?: string
  seriesId?: number
}

export interface BenchmarkAnnual {
  fundAnnual?: number
  fundProductId?: string
  seriesId?: number
  year?: number
}

export interface BenchmarkPerformanceV1 {
  benchmarkAnnual?: BenchmarkAnnual[]
  benchmarkCumulative?: BenchmarkCumulative[]
  benchmarkId?: string
}

export interface Month {
  month?: number
  year?: number
}

export interface UnderlyingFundData {
  annual?: number
  april?: number
  august?: number
  december?: number
  february?: number
  feederAnnual?: number
  january?: number
  july?: number
  june?: number
  march?: number
  may?: number
  /** 1 = January, 2 = February, 3 = March, 4 = April, 5 = May, 6 = June, 7 = July, 8 = August, 9 = September, 10 = October, 11 = November, 12 = December */
  monthsWithEstimateValue?: number[]
  november?: number
  october?: number
  september?: number
  year?: number
}

export interface Statistic {
  date?: string
  seriesId?: number
  statisticRunId?: number
  statisticType?: string
  statisticValue?: number
}

export interface AnnualPerformance {
  annualPerformanceType?: string
  annualValue?: number
  seriesId?: number
  year?: number
}

export interface MonthlyPerformance {
  month?: Month
  performanceTypeId?: number
  performanceValue?: number
  seriesId?: number
}

export interface FundPerformanceEventV1 {
  annualPerformance?: AnnualPerformance[]
  fundProductId?: string
  monthlyPerformance?: MonthlyPerformance[]
  statistic?: Statistic[]
  underlyingFundData?: UnderlyingFundData[]
}

export type SubjectAccessPolicyEventV1 = Envelope & {
  payload: SubjectAccessPolicyPayloadV1
}

export interface FundProductDocumentEventPayloadV1 {
  diff?: PatchDocument[]
  fundProductDocument: FundProductDocumentV1
}

export type FundProductDocumentEventV1 = Envelope & {
  payload: FundProductDocumentEventPayloadV1
}

export interface FundProductAuditEventPayloadV1 {
  diff?: PatchDocument[]
  fundProduct: FundProductAdminV1
}

export type FundProductAuditEventV1 = Envelope & {
  payload: FundProductAuditEventPayloadV1
}

export interface FundFirmConfigurationAuditEventPayloadV1 {
  diff?: PatchDocument[]
  fundFirmConfiguration?: FundFirmConfigurationV1
}

export type FundFirmConfigurationAuditEventV1 = Envelope & {
  payload: FundFirmConfigurationAuditEventPayloadV1
}

export interface FundProductDocumentVersionV1 {
  createdAt: string
  createdBy?: string
  fileName: string
  version: number
}

export interface DocumentDownloadLink {
  downloadUrl: string
  expiresAt: string
}

export type MercerServicesDocumentV1Type =
  (typeof MercerServicesDocumentV1Type)[keyof typeof MercerServicesDocumentV1Type]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MercerServicesDocumentV1Type = {
  MERCER_ADVISOR_SERVICES: 'MERCER_ADVISOR_SERVICES',
  MERCER_INVESTMENT_SERVICES: 'MERCER_INVESTMENT_SERVICES',
} as const

export interface MercerServicesDocumentV1 {
  createdAt: string
  createdBy: string
  fileId: string
  fileVersion: number
  type: MercerServicesDocumentV1Type
  updatedAt?: string
  updatedBy?: string
  version: number
}

export interface FundProductDocumentV1 {
  applicability: FundDocumentApplicability
  categoryId2?: string
  contentType: FundDocumentContentType
  createdAt: string
  createdBy: string
  deletedAt?: string
  deletedBy?: string
  description?: string
  fileId: string
  fileName: string
  firmId?: string | null
  fundProductId: string
  id: string
  /** true if the document should be part of the prequalification package */
  qualifyForPrequal?: boolean
  /**
   * Not currently supported
   * @deprecated
   */
  shareClasses: string[]
  title: string
  updatedAt?: string
  updatedBy?: string
  variantId?: string | null
  version: number
  yearMonth?: string
}

export interface ParentFundSearchFilterV1 {
  id?: UUIDFilter
  name?: StringFilter
}

export type ParentFundSearchRequestV1SortingItem =
  (typeof ParentFundSearchRequestV1SortingItem)[keyof typeof ParentFundSearchRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ParentFundSearchRequestV1SortingItem = {
  NAME_ASC: 'NAME_ASC',
  NAME_DESC: 'NAME_DESC',
} as const

export interface ParentFundSearchRequestV1 {
  filter?: ParentFundSearchFilterV1
  pageRequest?: PageRequest
  sorting?: ParentFundSearchRequestV1SortingItem[] | null
}

export interface ParentFundV1 {
  id: string
  name: string
}

export interface ParentFundRequestV1 {
  name: string
}

export interface ApproveToInvestCopyV1 {
  approvalRequiredTooltip?: string
  approveOrRejectAccessDialog?: string
  requestAccessDialog?: string
}

export type UserExperienceV1ContactUsBannerOverride = {
  contactButtonText?: string
  contactEmail?: string
  enabled?: boolean
  text?: string
  title?: string
}

export interface UserExperienceV1 {
  approveToInvestCopy?: ApproveToInvestCopyV1
  contactUsBannerOverride?: UserExperienceV1ContactUsBannerOverride
  fundRankEnabled?: boolean
  mercerEnabled?: boolean
}

export type UserContextV1ContactUsBannerOverride = {
  contactButtonText?: string
  contactEmail?: string
  enabled?: boolean
  text?: string
  title?: string
}

export interface UserContextV1 {
  approveToInvestCopy?: ApproveToInvestCopyV1
  canSeeUnapprovedShareClasses?: boolean
  contactUsBannerOverride?: UserContextV1ContactUsBannerOverride
  isApprovalEnabledForUsersFirm: boolean
  isFundRankEnabled?: boolean
  isMercerEnabledForUsersFirm?: boolean
  ranks?: SubjectFirstFundRank[]
}

export interface FundProductDocumentPageV1 {
  items: FundProductDocumentV1[]
  pageInfo: PageInfo
}

export type FundDocumentContentType =
  (typeof FundDocumentContentType)[keyof typeof FundDocumentContentType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundDocumentContentType = {
  PDF: 'PDF',
} as const

export interface FundDocumentRequestV1 {
  applicability: FundDocumentApplicability
  categoryId2?: string
  contentType: FundDocumentContentType
  description?: string | null
  fileName: string
  qualifyForPrequal?: boolean | null
  /**
   * Not currently supported
   * @deprecated
   */
  shareClasses?: string[]
  title: string
  yearMonth?: string | null
}

export interface DocumentCategoryV1 {
  canBeWrapped?: boolean
  dated?: boolean
  id: string
  includePerformanceData?: boolean
  isMercerRelated?: boolean
  name: string
  onlyOneAllowedPerFund?: boolean
  qualifyForPrequal?: boolean
}

/**
 * PER_SHARE_CLASS is not currently supported
 */
export type FundDocumentApplicability =
  (typeof FundDocumentApplicability)[keyof typeof FundDocumentApplicability]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundDocumentApplicability = {
  FUND_WIDE: 'FUND_WIDE',
  PER_SHARE_CLASS: 'PER_SHARE_CLASS',
} as const

export interface ShareClassPolicyStatusRequest {
  shareClassId: string
  status: FundProductShareClassPolicyStatus
}

export interface FundProductShareClassPolicyStatusRequestV1 {
  shareClassPolicyStatus: ShareClassPolicyStatusRequest[]
}

export interface FundProductShareClassSubjectAccessV1 {
  modifier: AccessModifier
  subjectID: string
  subjectType: SubjectType
}

export interface FundProductShareClassAccessPolicyOperation {
  accesses: FundProductShareClassSubjectAccessV1[]
  op: AccessPolicyOperationType
}

export interface FundProductShareClassAccessPolicy {
  operations: FundProductShareClassAccessPolicyOperation[]
  shareClassId: string
  status: FundProductShareClassPolicyStatus
}

export interface FundProductAccessPolicyRequest {
  policies: FundProductShareClassAccessPolicy[]
}

export interface FundProductSubjectAccessPolicyRequest {
  offeringType: FundOfferingType
  requiresApproval: boolean
  requiresCaisIq: boolean
  strategy: FundProductAccessPolicyStrategy
}

export interface NetMonthlyReturnsV1 {
  annual?: number
  april?: number
  august?: number
  december?: number
  february?: number
  january?: number
  july?: number
  june?: number
  march?: number
  may?: number
  /** 1 = January, 2 = February, 3 = March, 4 = April, 5 = May, 6 = June, 7 = July, 8 = August, 9 = September, 10 = October, 11 = November, 12 = December */
  monthsWithEstimateValue?: number[]
  november?: number
  october?: number
  september?: number
  year?: number
}

export interface FundPerformanceNetMonthlyReturnsV1 {
  bestMonth?: number
  monthlyReturns?: NetMonthlyReturnsV1[]
  positiveMonths?: number
  worstMonth?: number
}

export interface CorrelationV1 {
  barcapGlobal?: number
  hfriFofCompIndex?: number
  hfriHfCompIndex?: number
  msciWorld?: number
  sp500?: number
}

export interface RiskV1 {
  annualizedVolatility?: number
  downsideDeviation?: number
  maxDrawdown?: number
  sharpeRatio?: number
  sortinoRatio?: number
}

export interface AnnualBenchmarkValuesV1 {
  value?: number
  year?: number
}

export interface RiskAndCorrelationBenchmarkV1 {
  annualBenchmarkValues?: AnnualBenchmarkValuesV1[]
  name?: string
}

export interface RiskAndCorrelationAnnualPerformanceV1 {
  value?: number
  year?: number
}

export interface FundPerformanceRiskAndCorrelationV1 {
  annualPerformance?: RiskAndCorrelationAnnualPerformanceV1[]
  benchmark?: RiskAndCorrelationBenchmarkV1[]
  correlation?: CorrelationV1
  risk?: RiskV1
}

export interface FundPerformanceOverviewAnnualizedReturnV1 {
  '10years'?: number
  '1year'?: number
  '3years'?: number
  '5years'?: number
  sinceInception?: number
}

export interface FundPerformanceOverviewBenchmarkCumulativeValueOf100kValuesV1 {
  date?: string
  value?: number
}

export interface FundPerformanceOverviewBenchmarkCumulativeValueOf100kV1 {
  cumulativeBenchmarkValues?: FundPerformanceOverviewBenchmarkCumulativeValueOf100kValuesV1[]
  name?: string
}

export interface FundPerformanceOverviewValueOf100kV1 {
  date?: string
  value?: number
}

export interface FundPerformanceOverviewV1 {
  annualizedReturn?: FundPerformanceOverviewAnnualizedReturnV1
  benchmarkCumulativeValueOf100k?: FundPerformanceOverviewBenchmarkCumulativeValueOf100kV1[]
  cumulativeInception?: number
  cumulativeYearToDate?: number
  monthToDate?: number
  referenceDate?: string
  valueOf100k?: FundPerformanceOverviewValueOf100kV1[]
}

export interface FundProductPerformanceV1 {
  netMonthlyReturns?: FundPerformanceNetMonthlyReturnsV1
  overview?: FundPerformanceOverviewV1
  riskAndCorrelation?: FundPerformanceRiskAndCorrelationV1
}

export type FundShareClassV1TermsLockup = {
  displayAs?: DisplayAs
  timeFrameInMonths?: number
}

export type FundShareClassV1TermsIncentiveFeesFeesItem = {
  description?: string
  displayAs?: DisplayAs
  fee?: number
  incentiveFeeCrystallizationFrequency?: FundFrequency
  preferredReturnAndHurdleRate?: number
}

export type FundShareClassV1TermsIncentiveFees = {
  fees?: FundShareClassV1TermsIncentiveFeesFeesItem[]
  feeSchedule?: string[]
}

export type FundShareClassV1TermsExitFeesItem = {
  displayAs?: DisplayAs
}

export type FundShareClassV1TermsBrokerageFees = {
  trailPaymentDisplayAs?: DisplayAs
}

export type FundShareClassV1Terms = {
  advisoryTypes?: FundAdvisoryType[]
  auditHoldBack?: number
  auditHoldBackDisplayAs?: DisplayAs
  brokerageFees?: FundShareClassV1TermsBrokerageFees
  exitFees?: FundShareClassV1TermsExitFeesItem[]
  expenseCap?: FundExpensesV1
  incentiveFees?: FundShareClassV1TermsIncentiveFees
  lockup?: FundShareClassV1TermsLockup
  managementFees?: FundFeesV1
  minimumAdditionalInvestment?: number
  minimumInitialInvestment?: number
  noticeForRedemptions?: string
  redemptionIntervals?: FundFrequency
  redemptionIntervalsDisplayAs?: DisplayAs
  sponsorFees?: FundFeesV1
  subscriptionIntervals?: FundFrequency
  subscriptionIntervalsDisplayAs?: DisplayAs
  subscriptionStatus?: FundSubscriptionStatus
  technologyFees?: FundFeesV1
  transferFrequency?: FundFrequency
  transferFrequencyDisplayAs?: DisplayAs
  underlyingManagementFeeDisplayAs?: DisplayAs
}

export type FundShareClassV1PolicyStatus =
  (typeof FundShareClassV1PolicyStatus)[keyof typeof FundShareClassV1PolicyStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundShareClassV1PolicyStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
} as const

export type FundShareClassV1Details = {
  fundingStructure?: FundingStructure
  fundraisingType?: FundraisingType
  generalDisclaimer?: string
  name?: string
  offeringType?: FundOfferingType
  performanceDisclaimer?: string
  solutionOfferingType?: FundSolutionOfferingType
  subscriptionDisclaimer?: string
}

export interface FundShareClassV1 {
  allowedActions?: AllowedAction[]
  caisId: string
  deniedActions?: DeniedAction[]
  details?: FundShareClassV1Details
  id: string
  isApproved?: boolean
  policyStatus?: FundShareClassV1PolicyStatus
  supportedCustodians?: SupportedCustodianV1[]
  terms?: FundShareClassV1Terms
}

export type CloseAndDueDateBlock = {
  closeDate: string
  documentDueDate?: string | null
  documentIgoDate?: string | null
  wireDueDate?: string | null
} | null

export interface SupportedCustodianV1 {
  acceptsESignature: boolean
  cusip: string
  id: string
  name: string
}

export type FundProductV1VariantDetailsFirm = {
  id: string
}

export type FundProductV1VariantDetailsBaseFund = {
  caisId?: string
  id: string
}

export type FundProductV1VariantDetails = {
  baseFund: FundProductV1VariantDetailsBaseFund
  firm: FundProductV1VariantDetailsFirm
}

export type FundProductV1TransactionSupport = {
  closingSchedule?: FundClosingScheduleV1[]
  currentCloseAndDueDates?: CloseAndDueDateBlock
  finalCloseDate?: string | null
  legacyDocumentDueDate?: string
  legacyWireDueDate?: string
  nextCloseAndDueDates?: CloseAndDueDateBlock
}

export type FundProductV1Terms = {
  fundTermDisplayAs?: DisplayAs
  fundTermInYearsDecimal?: number | null
  investorLevel?: InvestorLevel[]
  investorType?: InvestorType[]
  taxReporting?: TaxReporting[]
}

export type FundProductV1TargetPerformance = {
  targetNetIRR?: number
  targetNetIRRHigh?: number
  targetNetMoic?: number
  targetNetMoicHigh?: number
  targetNetReturn?: number
  targetNetReturnHigh?: number
}

export type FundProductV1Rank = {
  displayName?: string
  name?: Rank
}

export type FundProductV1OtherRanksItem = {
  displayName?: string
  name?: Rank
}

export type FundProductV1ObjectivesScoringItem = {
  objective: FundObjective
  score: number
}

export type FundProductV1Objectives = {
  primary?: FundObjective
  scoring?: FundProductV1ObjectivesScoringItem[]
  secondary?: FundObjective
}

export type FundProductV1Legacy = {
  auditHoldBack?: number
  exitFee?: string
  highWaterMark?: boolean
  incentiveFee?: string
  /**
   * Migrating away towards configurable sorting ranks for funds for a firm
   * @deprecated
   */
  isFocusNetworkFund?: boolean
  legacyFundPageMode?: boolean
  lockup?: string
  managementFee?: string
  minimumAdditionalInvestment?: number
  minimumInitialInvestment?: number
  noticeForRedemptions?: string
  parentFundProductId?: string
  redemptionInterval?: string
  subscriptionInterval?: string
}

export interface FundProductV1 {
  allowedActions?: AllowedAction[]
  caisId: string
  dateSchedule?: FundDateScheduleV1
  deniedActions?: DeniedAction[]
  documents?: FundProductDocumentV1[]
  experience?: FundProductV1Experience
  fundProductVersion?: number
  generalInformation: FundProductV1GeneralInformation
  id: string
  legacy?: FundProductV1Legacy
  objectives?: FundProductV1Objectives
  otherRanks?: FundProductV1OtherRanksItem[]
  performance?: FundProductPerformanceV1
  rank?: FundProductV1Rank
  requiredCourses?: ItemGroupList
  shareClasses?: FundShareClassV1[]
  subscriptionStatus?: FundSubscriptionStatus
  targetPerformance?: FundProductV1TargetPerformance
  terms?: FundProductV1Terms
  transactionSupport?: FundProductV1TransactionSupport
  type?: FundEntityType
  variantDetails?: FundProductV1VariantDetails
}

export type FundProductV1GeneralInformationFundManager = {
  description?: string
  id: string
  name: string
  shortName?: string
}

export type FundProductV1GeneralInformation = {
  access: FundAccess
  caisPlatformLaunchDate?: string
  description?: string
  fundingStructure?: FundingStructure
  fundManager: FundProductV1GeneralInformationFundManager
  fundraisingType?: FundraisingType
  generalDisclaimer?: string
  inceptionDate?: string
  legalName: string
  mercerRating?: MercerRating
  parentFund?: ParentFundV1
  performanceDisclaimer?: string
  securityType: FundSecurityType
  shortName?: string
  strategy?: FundStrategy
  strategyDisplay?: string
  strategyIfOther?: string
  structureSubType?: FundStructureSubType
  structureType?: FundStructureType
  subscriptionDisclaimer?: string
  subStrategy?: FundSubStrategy
  subStrategyDisplay?: string
  subStrategyIfOther?: string
  trackRecordStartDate?: string
  type?: FundType
  videoPlayListId?: string
}

export type FundProductV1Experience = {
  showFundSpecificInfoForInvest?: boolean
}

export interface FundProductPageV1 {
  items: FundProductV1[]
  pageInfo: PageInfo
}

export interface CountResult {
  count?: number
}

export type BulkPatchResponseErrorAttributes = {
  violations?: Violations
}

export interface BulkPatchResponse {
  [key: string]: {
    displayMessage?: string
    errorAttributes?: BulkPatchResponseErrorAttributes
    errorCode?: string
    errorMessage?: string
    errorUri?: string
    status: number
  }
}

/**
 * Valid JSON patch request - https://jsonpatch.com
 */
export type PatchRequest = unknown

export interface BulkPublishResult {
  failedToPublish: string[]
  successfullyPublished: string[]
}

/**
 * A request to publish events for all or for a subset of funds. List of fund IDs take precedence over the all flag.
 */
export interface BulkPublishRequest {
  all?: boolean
  ids?: string[]
}

export interface ClickThroughAgreementAcceptance {
  acceptedAt?: string
  acceptedBy?: string
  clickThroughAgreementId: string
  fundProductId?: string
}

export interface ClickThroughAgreement {
  checkboxText: string
  content: string
  /** client only needs to provide it when accepting the agreement of a specific ID */
  id?: string
}

export interface FundAgreementsAndDisclosuresV1 {
  /** true if the fund requires a click through agreement to be accepted before viewing the fund page and investing */
  acceptsClickThroughAgreementToAccessFund?: boolean
  clickThroughAgreement?: ClickThroughAgreement
}

export interface FeatureToggle {
  enabled: boolean
  name: string
}

export type FeatureTogglesV1FeatureToggles = { [key: string]: FeatureToggle }

export interface FeatureTogglesV1 {
  featureToggles: FeatureTogglesV1FeatureToggles
}

export type FundBulkUploadSearchRequestV1SortingItem =
  (typeof FundBulkUploadSearchRequestV1SortingItem)[keyof typeof FundBulkUploadSearchRequestV1SortingItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundBulkUploadSearchRequestV1SortingItem = {
  FILE_NAME_ASC: 'FILE_NAME_ASC',
  FILE_NAME_DESC: 'FILE_NAME_DESC',
  UPLOADED_BY_ASC: 'UPLOADED_BY_ASC',
  UPLOADED_BY_DESC: 'UPLOADED_BY_DESC',
  UPLOADED_AT_ASC: 'UPLOADED_AT_ASC',
  UPLOADED_AT_DESC: 'UPLOADED_AT_DESC',
} as const

export interface FundBulkUploadSearchRequestV1 {
  pageRequest?: PageRequest
  sorting?: FundBulkUploadSearchRequestV1SortingItem[] | null
}

export interface FundBulkUploadSummaryV1 {
  createdAt: string
  createdBy: string
  fileId: string
  fileName: string
  id: string
  state: FundBulkUploadStateSummary
}

export interface FundBulkUploadSummaryPageV1 {
  items: FundBulkUploadSummaryV1[]
  pageInfo: PageInfo
}

export type FundBulkUploadStateStatus =
  (typeof FundBulkUploadStateStatus)[keyof typeof FundBulkUploadStateStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundBulkUploadStateStatus = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
} as const

export type FundBulkUploadStateType =
  (typeof FundBulkUploadStateType)[keyof typeof FundBulkUploadStateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundBulkUploadStateType = {
  PARSING_FILE: 'PARSING_FILE',
  VALIDATING_FUNDS: 'VALIDATING_FUNDS',
  DRAFTING_FUNDS: 'DRAFTING_FUNDS',
  PUBLISHING_FUNDS: 'PUBLISHING_FUNDS',
} as const

export interface FundBulkUploadStateSummary {
  status: FundBulkUploadStateStatus
  type: FundBulkUploadStateType
}

export interface UploadedShareClass {
  name?: string | null
  new: boolean
  offeringType?: FundOfferingType
  publishState?: FundPublishState
}

export type UploadedFundCapabilitiesItem =
  (typeof UploadedFundCapabilitiesItem)[keyof typeof UploadedFundCapabilitiesItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UploadedFundCapabilitiesItem = {
  FULL: 'FULL',
  SUBSCRIPTIONS_ONLY: 'SUBSCRIPTIONS_ONLY',
} as const

export interface UploadedFund {
  capabilities?: UploadedFundCapabilitiesItem[] | null
  id: string
  legalName: string
  new: boolean
  publishState?: FundPublishState
  shareClasses?: UploadedShareClass[] | null
  version: number
}

export interface UploadedFundWithError {
  error?: ErrorResponse
  fund: UploadedFund
}

export type FundBulkUploadNextStep = {
  httpVerb: string
  url: string
} | null

export type FundBulkUploadPublishingStateType =
  (typeof FundBulkUploadPublishingStateType)[keyof typeof FundBulkUploadPublishingStateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundBulkUploadPublishingStateType = {
  PUBLISHING_FUNDS: 'PUBLISHING_FUNDS',
} as const

export interface FundBulkUploadPublishingState {
  fundsToPublish: UploadedFund[]
  publishedFunds: UploadedFundWithError[]
  status: FundBulkUploadStateStatus
  type: FundBulkUploadPublishingStateType
}

export type FundBulkUploadDraftingStateType =
  (typeof FundBulkUploadDraftingStateType)[keyof typeof FundBulkUploadDraftingStateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundBulkUploadDraftingStateType = {
  DRAFTING_FUNDS: 'DRAFTING_FUNDS',
} as const

export interface FundBulkUploadDraftingState {
  draftedFunds: UploadedFundWithError[]
  fundsToDraft: UploadedFund[]
  status: FundBulkUploadStateStatus
  type: FundBulkUploadDraftingStateType
}

export type FundBulkUploadValidationStateType =
  (typeof FundBulkUploadValidationStateType)[keyof typeof FundBulkUploadValidationStateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundBulkUploadValidationStateType = {
  VALIDATING_FUNDS: 'VALIDATING_FUNDS',
} as const

export interface FundBulkUploadValidationState {
  fundsToValidate: UploadedFund[]
  status: FundBulkUploadStateStatus
  type: FundBulkUploadValidationStateType
  validatedFunds: UploadedFundWithError[]
}

export interface FundBulkUploadParsingError {
  column: string
  columnName: string
  errorMessage: string
  row: number
  sheet: string
}

export type FundBulkUploadParsingStateType =
  (typeof FundBulkUploadParsingStateType)[keyof typeof FundBulkUploadParsingStateType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FundBulkUploadParsingStateType = {
  PARSING_FILE: 'PARSING_FILE',
} as const

export interface FundBulkUploadParsingState {
  parsingErrors: FundBulkUploadParsingError[]
  status: FundBulkUploadStateStatus
  type: FundBulkUploadParsingStateType
}

export type FundBulkUploadState =
  | FundBulkUploadParsingState
  | FundBulkUploadValidationState
  | FundBulkUploadDraftingState
  | FundBulkUploadPublishingState

export interface FundBulkUploadV1 {
  createdAt: string
  createdBy: string
  fileId: string
  fileName: string
  id: string
  nextStep?: FundBulkUploadNextStep
  state: FundBulkUploadState
}

export interface FundBulkUploadResponseV1 {
  uploadId: string
}
