/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Structured Product Master API
 * OpenAPI spec version: v1
 */
import { useInfiniteQuery, useMutation, useQuery } from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'

import { fetchInstance } from '../../../../../../all-shared/legacy-shared/util/fetch/lib/fetch'
import type { ErrorType } from '../../../../../../all-shared/legacy-shared/util/fetch/lib/fetch'

import type {
  BulkCreateStructuredProductResponse,
  BulkDeleteStructuredProductResponse,
  BulkDeleteStructuredProductsRequest,
  BulkDraftUploadBody,
  CreateDraftStructuredProductRequest,
  CreateIssuerGroup,
  CreateProductPermission,
  CreateProductPermissionBulkRequest,
  CreateReferenceAssetRequest,
  CreateStructuredProductRequest,
  CreateTailoredMarketplaceProfile,
  Currency,
  ErrorResponse,
  GetProductPricesParams,
  GetProductsPricesParams,
  GetStructuredProductByIdParams,
  IssuerDto,
  IssuerGroupDto,
  IssuerRequest,
  PageResultIssuerDto,
  PageResultIssuerGroupDto,
  PageResultProductPermission,
  PageResultReferenceAssetDto,
  PageResultStructureTemplateDto,
  PageResultStructuredProductDto,
  PageResultStructuredProductUploadDto,
  PageResultStructuredProductUploadDtoV2,
  PageResultStructuredProductUploadResultDto,
  PageResultTailoredMarketplaceProfiles,
  ProductPermission,
  ProductPermissionsBulkCreationResult,
  ProductPriceDto,
  ProductPricesDto,
  PublishBulkDraftsRequest,
  PublishDraftStructuredProductRequest,
  ReferenceAssetDto,
  RemapStructuredProductByIdParams,
  RemapStructuredProductsParams,
  RepublishLifecycleEventsParams,
  RepublishReferenceAssetsParams,
  RepublishStructuredProductsParams,
  SearchRequestIssuer,
  SearchRequestIssuerGroup,
  SearchRequestProductPermission,
  SearchRequestReferenceAsset,
  SearchRequestStructureTemplate,
  SearchRequestStructuredProduct,
  SearchRequestStructuredProductUpload,
  SearchRequestStructuredProductUploadResult,
  SearchRequestTailoredMarketplaceProfiles,
  StructureTemplateDto,
  StructureTemplateRequest,
  StructuredProductUploadDto,
  StructuredProductUploadDtoV2,
  StructuredProductUploadPublishAllDto,
  StructuredProductV1,
  StructuredProductsFilterMetadataRequest,
  StructuredProductsFilterMetadataResult,
  TailoredMarketplaceProfile,
  UpdateIssuer,
  UpdateIssuerGroup,
  UpdateReferenceAssetRequest,
  UpdateStructureTemplateRequest,
  UpdateStructuredProductRequest,
  WsdIngestionRequest,
} from './api.schemas'

/**
 * @summary Get a reference asset by id
 */
export const referenceAssetById = (id: string, signal?: AbortSignal) => {
  return fetchInstance<ReferenceAssetDto>({
    url: `/api/products/structured-products/v1/reference-assets/${id}`,
    method: 'GET',
    signal,
  })
}

export const getReferenceAssetByIdQueryKey = (id: string) => {
  return [
    `/api/products/structured-products/v1/reference-assets/${id}`,
  ] as const
}

export const getReferenceAssetByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof referenceAssetById>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof referenceAssetById>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getReferenceAssetByIdQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof referenceAssetById>>
  > = ({ signal }) => referenceAssetById(id, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof referenceAssetById>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ReferenceAssetByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof referenceAssetById>>
>
export type ReferenceAssetByIdQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get a reference asset by id
 */
export const useReferenceAssetById = <
  TData = Awaited<ReturnType<typeof referenceAssetById>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof referenceAssetById>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getReferenceAssetByIdQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Update a reference asset
 */
export const updateReferenceAsset = (
  id: string,
  updateReferenceAssetRequest: UpdateReferenceAssetRequest
) => {
  return fetchInstance<ReferenceAssetDto>({
    url: `/api/products/structured-products/v1/reference-assets/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateReferenceAssetRequest,
  })
}

export const getUpdateReferenceAssetMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReferenceAsset>>,
    TError,
    { id: string; data: UpdateReferenceAssetRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateReferenceAsset>>,
  TError,
  { id: string; data: UpdateReferenceAssetRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateReferenceAsset>>,
    { id: string; data: UpdateReferenceAssetRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return updateReferenceAsset(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateReferenceAssetMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateReferenceAsset>>
>
export type UpdateReferenceAssetMutationBody = UpdateReferenceAssetRequest
export type UpdateReferenceAssetMutationError = ErrorType<ErrorResponse>

/**
 * @summary Update a reference asset
 */
export const useUpdateReferenceAsset = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateReferenceAsset>>,
    TError,
    { id: string; data: UpdateReferenceAssetRequest },
    TContext
  >
}) => {
  const mutationOptions = getUpdateReferenceAssetMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Search reference assets
 */
export const searchReferenceAssets = (
  searchRequestReferenceAsset: SearchRequestReferenceAsset
) => {
  return fetchInstance<PageResultReferenceAssetDto>({
    url: `/api/products/structured-products/v1/reference-assets/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchRequestReferenceAsset,
  })
}

export const getSearchReferenceAssetsQueryKey = (
  searchRequestReferenceAsset: SearchRequestReferenceAsset
) => {
  return [
    `/api/products/structured-products/v1/reference-assets/search`,
    searchRequestReferenceAsset,
  ] as const
}

export const getSearchReferenceAssetsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof searchReferenceAssets>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestReferenceAsset: SearchRequestReferenceAsset,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof searchReferenceAssets>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getSearchReferenceAssetsQueryKey(searchRequestReferenceAsset)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchReferenceAssets>>
  > = () => searchReferenceAssets(searchRequestReferenceAsset)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof searchReferenceAssets>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchReferenceAssetsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchReferenceAssets>>
>
export type SearchReferenceAssetsInfiniteQueryError = ErrorType<ErrorResponse>

/**
 * @summary Search reference assets
 */
export const useSearchReferenceAssetsInfinite = <
  TData = Awaited<ReturnType<typeof searchReferenceAssets>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestReferenceAsset: SearchRequestReferenceAsset,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof searchReferenceAssets>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchReferenceAssetsInfiniteQueryOptions(
    searchRequestReferenceAsset,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSearchReferenceAssetsQueryOptions = <
  TData = Awaited<ReturnType<typeof searchReferenceAssets>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestReferenceAsset: SearchRequestReferenceAsset,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchReferenceAssets>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getSearchReferenceAssetsQueryKey(searchRequestReferenceAsset)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchReferenceAssets>>
  > = () => searchReferenceAssets(searchRequestReferenceAsset)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchReferenceAssets>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchReferenceAssetsQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchReferenceAssets>>
>
export type SearchReferenceAssetsQueryError = ErrorType<ErrorResponse>

/**
 * @summary Search reference assets
 */
export const useSearchReferenceAssets = <
  TData = Awaited<ReturnType<typeof searchReferenceAssets>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestReferenceAsset: SearchRequestReferenceAsset,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchReferenceAssets>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchReferenceAssetsQueryOptions(
    searchRequestReferenceAsset,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Create a reference asset
 */
export const createReferenceAsset = (
  createReferenceAssetRequest: CreateReferenceAssetRequest
) => {
  return fetchInstance<ReferenceAssetDto>({
    url: `/api/products/structured-products/v1/reference-assets`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createReferenceAssetRequest,
  })
}

export const getCreateReferenceAssetMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReferenceAsset>>,
    TError,
    { data: CreateReferenceAssetRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createReferenceAsset>>,
  TError,
  { data: CreateReferenceAssetRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createReferenceAsset>>,
    { data: CreateReferenceAssetRequest }
  > = (props) => {
    const { data } = props ?? {}

    return createReferenceAsset(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateReferenceAssetMutationResult = NonNullable<
  Awaited<ReturnType<typeof createReferenceAsset>>
>
export type CreateReferenceAssetMutationBody = CreateReferenceAssetRequest
export type CreateReferenceAssetMutationError = ErrorType<ErrorResponse>

/**
 * @summary Create a reference asset
 */
export const useCreateReferenceAsset = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createReferenceAsset>>,
    TError,
    { data: CreateReferenceAssetRequest },
    TContext
  >
}) => {
  const mutationOptions = getCreateReferenceAssetMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Create a structured product
 */
export const createStructuredProduct = (
  createStructuredProductRequest: CreateStructuredProductRequest
) => {
  return fetchInstance<StructuredProductV1>({
    url: `/api/products/structured-products/v1`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: createStructuredProductRequest,
  })
}

export const getCreateStructuredProductMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createStructuredProduct>>,
    TError,
    { data: CreateStructuredProductRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createStructuredProduct>>,
  TError,
  { data: CreateStructuredProductRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createStructuredProduct>>,
    { data: CreateStructuredProductRequest }
  > = (props) => {
    const { data } = props ?? {}

    return createStructuredProduct(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateStructuredProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof createStructuredProduct>>
>
export type CreateStructuredProductMutationBody = CreateStructuredProductRequest
export type CreateStructuredProductMutationError = ErrorType<ErrorResponse>

/**
 * @summary Create a structured product
 */
export const useCreateStructuredProduct = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createStructuredProduct>>,
    TError,
    { data: CreateStructuredProductRequest },
    TContext
  >
}) => {
  const mutationOptions = getCreateStructuredProductMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Bulk delete structured products
 */
export const bulkDeleteStructuredProducts = (
  bulkDeleteStructuredProductsRequest: BulkDeleteStructuredProductsRequest
) => {
  return fetchInstance<BulkDeleteStructuredProductResponse>({
    url: `/api/products/structured-products/v1`,
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    data: bulkDeleteStructuredProductsRequest,
  })
}

export const getBulkDeleteStructuredProductsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkDeleteStructuredProducts>>,
    TError,
    { data: BulkDeleteStructuredProductsRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof bulkDeleteStructuredProducts>>,
  TError,
  { data: BulkDeleteStructuredProductsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bulkDeleteStructuredProducts>>,
    { data: BulkDeleteStructuredProductsRequest }
  > = (props) => {
    const { data } = props ?? {}

    return bulkDeleteStructuredProducts(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type BulkDeleteStructuredProductsMutationResult = NonNullable<
  Awaited<ReturnType<typeof bulkDeleteStructuredProducts>>
>
export type BulkDeleteStructuredProductsMutationBody =
  BulkDeleteStructuredProductsRequest
export type BulkDeleteStructuredProductsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Bulk delete structured products
 */
export const useBulkDeleteStructuredProducts = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkDeleteStructuredProducts>>,
    TError,
    { data: BulkDeleteStructuredProductsRequest },
    TContext
  >
}) => {
  const mutationOptions =
    getBulkDeleteStructuredProductsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get a structured product by id
 */
export const getStructuredProductById = (
  id: string,
  params?: GetStructuredProductByIdParams,
  signal?: AbortSignal
) => {
  return fetchInstance<StructuredProductV1>({
    url: `/api/products/structured-products/v1/${id}`,
    method: 'GET',
    params,
    signal,
  })
}

export const getGetStructuredProductByIdQueryKey = (
  id: string,
  params?: GetStructuredProductByIdParams
) => {
  return [
    `/api/products/structured-products/v1/${id}`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetStructuredProductByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getStructuredProductById>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  params?: GetStructuredProductByIdParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStructuredProductById>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetStructuredProductByIdQueryKey(id, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getStructuredProductById>>
  > = ({ signal }) => getStructuredProductById(id, params, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getStructuredProductById>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetStructuredProductByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStructuredProductById>>
>
export type GetStructuredProductByIdQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get a structured product by id
 */
export const useGetStructuredProductById = <
  TData = Awaited<ReturnType<typeof getStructuredProductById>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  params?: GetStructuredProductByIdParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStructuredProductById>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetStructuredProductByIdQueryOptions(
    id,
    params,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Update a structured product using PUT
 */
export const updateStructuredProduct = (
  id: string,
  updateStructuredProductRequest: UpdateStructuredProductRequest
) => {
  return fetchInstance<StructuredProductV1>({
    url: `/api/products/structured-products/v1/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateStructuredProductRequest,
  })
}

export const getUpdateStructuredProductMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStructuredProduct>>,
    TError,
    { id: string; data: UpdateStructuredProductRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateStructuredProduct>>,
  TError,
  { id: string; data: UpdateStructuredProductRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateStructuredProduct>>,
    { id: string; data: UpdateStructuredProductRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return updateStructuredProduct(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateStructuredProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateStructuredProduct>>
>
export type UpdateStructuredProductMutationBody = UpdateStructuredProductRequest
export type UpdateStructuredProductMutationError = ErrorType<ErrorResponse>

/**
 * @summary Update a structured product using PUT
 */
export const useUpdateStructuredProduct = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStructuredProduct>>,
    TError,
    { id: string; data: UpdateStructuredProductRequest },
    TContext
  >
}) => {
  const mutationOptions = getUpdateStructuredProductMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Delete a structured product
 */
export const deleteStructuredProduct = (id: string) => {
  return fetchInstance<void>({
    url: `/api/products/structured-products/v1/${id}`,
    method: 'DELETE',
  })
}

export const getDeleteStructuredProductMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteStructuredProduct>>,
    TError,
    { id: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteStructuredProduct>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteStructuredProduct>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {}

    return deleteStructuredProduct(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteStructuredProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteStructuredProduct>>
>

export type DeleteStructuredProductMutationError = ErrorType<ErrorResponse>

/**
 * @summary Delete a structured product
 */
export const useDeleteStructuredProduct = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteStructuredProduct>>,
    TError,
    { id: string },
    TContext
  >
}) => {
  const mutationOptions = getDeleteStructuredProductMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Publish a draft structured product
 */
export const publishDraftStructuredProduct = (
  publishDraftStructuredProductRequest: PublishDraftStructuredProductRequest
) => {
  return fetchInstance<StructuredProductV1>({
    url: `/api/products/structured-products/v1/publish`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: publishDraftStructuredProductRequest,
  })
}

export const getPublishDraftStructuredProductMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof publishDraftStructuredProduct>>,
    TError,
    { data: PublishDraftStructuredProductRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof publishDraftStructuredProduct>>,
  TError,
  { data: PublishDraftStructuredProductRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof publishDraftStructuredProduct>>,
    { data: PublishDraftStructuredProductRequest }
  > = (props) => {
    const { data } = props ?? {}

    return publishDraftStructuredProduct(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PublishDraftStructuredProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof publishDraftStructuredProduct>>
>
export type PublishDraftStructuredProductMutationBody =
  PublishDraftStructuredProductRequest
export type PublishDraftStructuredProductMutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Publish a draft structured product
 */
export const usePublishDraftStructuredProduct = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof publishDraftStructuredProduct>>,
    TError,
    { data: PublishDraftStructuredProductRequest },
    TContext
  >
}) => {
  const mutationOptions =
    getPublishDraftStructuredProductMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Bulk create structured products
 */
export const bulkCreate = (
  createStructuredProductRequest: CreateStructuredProductRequest[]
) => {
  return fetchInstance<BulkCreateStructuredProductResponse>({
    url: `/api/products/structured-products/v1/bulk-create`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: createStructuredProductRequest,
  })
}

export const getBulkCreateMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkCreate>>,
    TError,
    { data: CreateStructuredProductRequest[] },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof bulkCreate>>,
  TError,
  { data: CreateStructuredProductRequest[] },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bulkCreate>>,
    { data: CreateStructuredProductRequest[] }
  > = (props) => {
    const { data } = props ?? {}

    return bulkCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type BulkCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof bulkCreate>>
>
export type BulkCreateMutationBody = CreateStructuredProductRequest[]
export type BulkCreateMutationError = ErrorType<ErrorResponse>

/**
 * @summary Bulk create structured products
 */
export const useBulkCreate = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkCreate>>,
    TError,
    { data: CreateStructuredProductRequest[] },
    TContext
  >
}) => {
  const mutationOptions = getBulkCreateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get a structure template by id
 */
export const getStructureTemplateById = (id: string, signal?: AbortSignal) => {
  return fetchInstance<StructureTemplateDto>({
    url: `/api/products/structured-products/structure-templates/v1/${id}`,
    method: 'GET',
    signal,
  })
}

export const getGetStructureTemplateByIdQueryKey = (id: string) => {
  return [
    `/api/products/structured-products/structure-templates/v1/${id}`,
  ] as const
}

export const getGetStructureTemplateByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getStructureTemplateById>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStructureTemplateById>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetStructureTemplateByIdQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getStructureTemplateById>>
  > = ({ signal }) => getStructureTemplateById(id, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getStructureTemplateById>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetStructureTemplateByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStructureTemplateById>>
>
export type GetStructureTemplateByIdQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get a structure template by id
 */
export const useGetStructureTemplateById = <
  TData = Awaited<ReturnType<typeof getStructureTemplateById>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStructureTemplateById>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetStructureTemplateByIdQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Update a structure template by id
 */
export const updateStructureTemplate = (
  id: string,
  updateStructureTemplateRequest: UpdateStructureTemplateRequest
) => {
  return fetchInstance<StructureTemplateDto>({
    url: `/api/products/structured-products/structure-templates/v1/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateStructureTemplateRequest,
  })
}

export const getUpdateStructureTemplateMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStructureTemplate>>,
    TError,
    { id: string; data: UpdateStructureTemplateRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateStructureTemplate>>,
  TError,
  { id: string; data: UpdateStructureTemplateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateStructureTemplate>>,
    { id: string; data: UpdateStructureTemplateRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return updateStructureTemplate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateStructureTemplateMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateStructureTemplate>>
>
export type UpdateStructureTemplateMutationBody = UpdateStructureTemplateRequest
export type UpdateStructureTemplateMutationError = ErrorType<ErrorResponse>

/**
 * @summary Update a structure template by id
 */
export const useUpdateStructureTemplate = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateStructureTemplate>>,
    TError,
    { id: string; data: UpdateStructureTemplateRequest },
    TContext
  >
}) => {
  const mutationOptions = getUpdateStructureTemplateMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Delete a structure template by id
 */
export const deleteStructureTemplateById = (id: string) => {
  return fetchInstance<void>({
    url: `/api/products/structured-products/structure-templates/v1/${id}`,
    method: 'DELETE',
  })
}

export const getDeleteStructureTemplateByIdMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteStructureTemplateById>>,
    TError,
    { id: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteStructureTemplateById>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteStructureTemplateById>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {}

    return deleteStructureTemplateById(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteStructureTemplateByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteStructureTemplateById>>
>

export type DeleteStructureTemplateByIdMutationError = ErrorType<ErrorResponse>

/**
 * @summary Delete a structure template by id
 */
export const useDeleteStructureTemplateById = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteStructureTemplateById>>,
    TError,
    { id: string },
    TContext
  >
}) => {
  const mutationOptions = getDeleteStructureTemplateByIdMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get an issuer by id
 */
export const getIssuerById = (id: string, signal?: AbortSignal) => {
  return fetchInstance<IssuerDto>({
    url: `/api/products/structured-products/issuers/v1/${id}`,
    method: 'GET',
    signal,
  })
}

export const getGetIssuerByIdQueryKey = (id: string) => {
  return [`/api/products/structured-products/issuers/v1/${id}`] as const
}

export const getGetIssuerByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getIssuerById>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getIssuerById>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetIssuerByIdQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getIssuerById>>> = ({
    signal,
  }) => getIssuerById(id, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getIssuerById>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetIssuerByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIssuerById>>
>
export type GetIssuerByIdQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get an issuer by id
 */
export const useGetIssuerById = <
  TData = Awaited<ReturnType<typeof getIssuerById>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getIssuerById>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetIssuerByIdQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Update an issuer by id
 */
export const updateIssuer = (id: string, updateIssuer: UpdateIssuer) => {
  return fetchInstance<IssuerDto>({
    url: `/api/products/structured-products/issuers/v1/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateIssuer,
  })
}

export const getUpdateIssuerMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateIssuer>>,
    TError,
    { id: string; data: UpdateIssuer },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateIssuer>>,
  TError,
  { id: string; data: UpdateIssuer },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateIssuer>>,
    { id: string; data: UpdateIssuer }
  > = (props) => {
    const { id, data } = props ?? {}

    return updateIssuer(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateIssuerMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateIssuer>>
>
export type UpdateIssuerMutationBody = UpdateIssuer
export type UpdateIssuerMutationError = ErrorType<ErrorResponse>

/**
 * @summary Update an issuer by id
 */
export const useUpdateIssuer = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateIssuer>>,
    TError,
    { id: string; data: UpdateIssuer },
    TContext
  >
}) => {
  const mutationOptions = getUpdateIssuerMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Delete an issuer by id
 */
export const deleteIssuer = (id: string) => {
  return fetchInstance<void>({
    url: `/api/products/structured-products/issuers/v1/${id}`,
    method: 'DELETE',
  })
}

export const getDeleteIssuerMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteIssuer>>,
    TError,
    { id: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteIssuer>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteIssuer>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {}

    return deleteIssuer(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteIssuerMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteIssuer>>
>

export type DeleteIssuerMutationError = ErrorType<ErrorResponse>

/**
 * @summary Delete an issuer by id
 */
export const useDeleteIssuer = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteIssuer>>,
    TError,
    { id: string },
    TContext
  >
}) => {
  const mutationOptions = getDeleteIssuerMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get an issuer group by id
 */
export const getIssuerGroupById = (id: string, signal?: AbortSignal) => {
  return fetchInstance<IssuerGroupDto>({
    url: `/api/products/structured-products/issuer-groups/v1/${id}`,
    method: 'GET',
    signal,
  })
}

export const getGetIssuerGroupByIdQueryKey = (id: string) => {
  return [`/api/products/structured-products/issuer-groups/v1/${id}`] as const
}

export const getGetIssuerGroupByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getIssuerGroupById>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getIssuerGroupById>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetIssuerGroupByIdQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getIssuerGroupById>>
  > = ({ signal }) => getIssuerGroupById(id, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getIssuerGroupById>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetIssuerGroupByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getIssuerGroupById>>
>
export type GetIssuerGroupByIdQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get an issuer group by id
 */
export const useGetIssuerGroupById = <
  TData = Awaited<ReturnType<typeof getIssuerGroupById>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getIssuerGroupById>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetIssuerGroupByIdQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Update an issuer group by id
 */
export const updateIssuerGroup = (
  id: string,
  updateIssuerGroup: UpdateIssuerGroup
) => {
  return fetchInstance<IssuerGroupDto>({
    url: `/api/products/structured-products/issuer-groups/v1/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: updateIssuerGroup,
  })
}

export const getUpdateIssuerGroupMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateIssuerGroup>>,
    TError,
    { id: string; data: UpdateIssuerGroup },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateIssuerGroup>>,
  TError,
  { id: string; data: UpdateIssuerGroup },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateIssuerGroup>>,
    { id: string; data: UpdateIssuerGroup }
  > = (props) => {
    const { id, data } = props ?? {}

    return updateIssuerGroup(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateIssuerGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateIssuerGroup>>
>
export type UpdateIssuerGroupMutationBody = UpdateIssuerGroup
export type UpdateIssuerGroupMutationError = ErrorType<ErrorResponse>

/**
 * @summary Update an issuer group by id
 */
export const useUpdateIssuerGroup = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateIssuerGroup>>,
    TError,
    { id: string; data: UpdateIssuerGroup },
    TContext
  >
}) => {
  const mutationOptions = getUpdateIssuerGroupMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Run WallStreet Docs ingestion
 */
export const runWsdIngestion = (wsdIngestionRequest: WsdIngestionRequest) => {
  return fetchInstance<void>({
    url: `/api/products/structured-products/wsd-ingestion/v1`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: wsdIngestionRequest,
  })
}

export const getRunWsdIngestionMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runWsdIngestion>>,
    TError,
    { data: WsdIngestionRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof runWsdIngestion>>,
  TError,
  { data: WsdIngestionRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof runWsdIngestion>>,
    { data: WsdIngestionRequest }
  > = (props) => {
    const { data } = props ?? {}

    return runWsdIngestion(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type RunWsdIngestionMutationResult = NonNullable<
  Awaited<ReturnType<typeof runWsdIngestion>>
>
export type RunWsdIngestionMutationBody = WsdIngestionRequest
export type RunWsdIngestionMutationError = ErrorType<ErrorResponse>

/**
 * @summary Run WallStreet Docs ingestion
 */
export const useRunWsdIngestion = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runWsdIngestion>>,
    TError,
    { data: WsdIngestionRequest },
    TContext
  >
}) => {
  const mutationOptions = getRunWsdIngestionMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get structured product uploads
 */
export const getStructuredProductUploads = (
  searchRequestStructuredProductUpload: SearchRequestStructuredProductUpload
) => {
  return fetchInstance<PageResultStructuredProductUploadDto>({
    url: `/api/products/structured-products/v1/upload`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchRequestStructuredProductUpload,
  })
}

export const getGetStructuredProductUploadsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getStructuredProductUploads>>,
    TError,
    { data: SearchRequestStructuredProductUpload },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof getStructuredProductUploads>>,
  TError,
  { data: SearchRequestStructuredProductUpload },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getStructuredProductUploads>>,
    { data: SearchRequestStructuredProductUpload }
  > = (props) => {
    const { data } = props ?? {}

    return getStructuredProductUploads(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type GetStructuredProductUploadsMutationResult = NonNullable<
  Awaited<ReturnType<typeof getStructuredProductUploads>>
>
export type GetStructuredProductUploadsMutationBody =
  SearchRequestStructuredProductUpload
export type GetStructuredProductUploadsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Get structured product uploads
 */
export const useGetStructuredProductUploads = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getStructuredProductUploads>>,
    TError,
    { data: SearchRequestStructuredProductUpload },
    TContext
  >
}) => {
  const mutationOptions = getGetStructuredProductUploadsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get structured product uploads v2
 */
export const getStructuredProductUploadsV2 = (
  searchRequestStructuredProductUpload: SearchRequestStructuredProductUpload
) => {
  return fetchInstance<PageResultStructuredProductUploadDtoV2>({
    url: `/api/products/structured-products/v2/upload`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchRequestStructuredProductUpload,
  })
}

export const getGetStructuredProductUploadsV2QueryKey = (
  searchRequestStructuredProductUpload: SearchRequestStructuredProductUpload
) => {
  return [
    `/api/products/structured-products/v2/upload`,
    searchRequestStructuredProductUpload,
  ] as const
}

export const getGetStructuredProductUploadsV2InfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getStructuredProductUploadsV2>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestStructuredProductUpload: SearchRequestStructuredProductUpload,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getStructuredProductUploadsV2>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetStructuredProductUploadsV2QueryKey(
      searchRequestStructuredProductUpload
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getStructuredProductUploadsV2>>
  > = () => getStructuredProductUploadsV2(searchRequestStructuredProductUpload)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getStructuredProductUploadsV2>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetStructuredProductUploadsV2InfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStructuredProductUploadsV2>>
>
export type GetStructuredProductUploadsV2InfiniteQueryError =
  ErrorType<ErrorResponse>

/**
 * @summary Get structured product uploads v2
 */
export const useGetStructuredProductUploadsV2Infinite = <
  TData = Awaited<ReturnType<typeof getStructuredProductUploadsV2>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestStructuredProductUpload: SearchRequestStructuredProductUpload,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getStructuredProductUploadsV2>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetStructuredProductUploadsV2InfiniteQueryOptions(
    searchRequestStructuredProductUpload,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getGetStructuredProductUploadsV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getStructuredProductUploadsV2>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestStructuredProductUpload: SearchRequestStructuredProductUpload,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStructuredProductUploadsV2>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetStructuredProductUploadsV2QueryKey(
      searchRequestStructuredProductUpload
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getStructuredProductUploadsV2>>
  > = () => getStructuredProductUploadsV2(searchRequestStructuredProductUpload)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getStructuredProductUploadsV2>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetStructuredProductUploadsV2QueryResult = NonNullable<
  Awaited<ReturnType<typeof getStructuredProductUploadsV2>>
>
export type GetStructuredProductUploadsV2QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get structured product uploads v2
 */
export const useGetStructuredProductUploadsV2 = <
  TData = Awaited<ReturnType<typeof getStructuredProductUploadsV2>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestStructuredProductUpload: SearchRequestStructuredProductUpload,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStructuredProductUploadsV2>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetStructuredProductUploadsV2QueryOptions(
    searchRequestStructuredProductUpload,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Get structured product upload results
 */
export const getStructuredProductUploadResults = (
  id: string,
  searchRequestStructuredProductUploadResult: SearchRequestStructuredProductUploadResult
) => {
  return fetchInstance<PageResultStructuredProductUploadResultDto>({
    url: `/api/products/structured-products/v1/upload/${id}/results`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchRequestStructuredProductUploadResult,
  })
}

export const getGetStructuredProductUploadResultsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getStructuredProductUploadResults>>,
    TError,
    { id: string; data: SearchRequestStructuredProductUploadResult },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof getStructuredProductUploadResults>>,
  TError,
  { id: string; data: SearchRequestStructuredProductUploadResult },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getStructuredProductUploadResults>>,
    { id: string; data: SearchRequestStructuredProductUploadResult }
  > = (props) => {
    const { id, data } = props ?? {}

    return getStructuredProductUploadResults(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type GetStructuredProductUploadResultsMutationResult = NonNullable<
  Awaited<ReturnType<typeof getStructuredProductUploadResults>>
>
export type GetStructuredProductUploadResultsMutationBody =
  SearchRequestStructuredProductUploadResult
export type GetStructuredProductUploadResultsMutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Get structured product upload results
 */
export const useGetStructuredProductUploadResults = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getStructuredProductUploadResults>>,
    TError,
    { id: string; data: SearchRequestStructuredProductUploadResult },
    TContext
  >
}) => {
  const mutationOptions =
    getGetStructuredProductUploadResultsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Publish bulk upload drafts
 */
export const publishBulkUploadDrafts = (
  id: string,
  publishBulkDraftsRequest: PublishBulkDraftsRequest
) => {
  return fetchInstance<StructuredProductUploadPublishAllDto>({
    url: `/api/products/structured-products/v1/upload/${id}/publish`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: publishBulkDraftsRequest,
  })
}

export const getPublishBulkUploadDraftsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof publishBulkUploadDrafts>>,
    TError,
    { id: string; data: PublishBulkDraftsRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof publishBulkUploadDrafts>>,
  TError,
  { id: string; data: PublishBulkDraftsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof publishBulkUploadDrafts>>,
    { id: string; data: PublishBulkDraftsRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return publishBulkUploadDrafts(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type PublishBulkUploadDraftsMutationResult = NonNullable<
  Awaited<ReturnType<typeof publishBulkUploadDrafts>>
>
export type PublishBulkUploadDraftsMutationBody = PublishBulkDraftsRequest
export type PublishBulkUploadDraftsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Publish bulk upload drafts
 */
export const usePublishBulkUploadDrafts = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof publishBulkUploadDrafts>>,
    TError,
    { id: string; data: PublishBulkDraftsRequest },
    TContext
  >
}) => {
  const mutationOptions = getPublishBulkUploadDraftsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get upload publish by Id
 */
export const getUploadResultsById = (
  uploadId: string,
  id: string,
  signal?: AbortSignal
) => {
  return fetchInstance<StructuredProductUploadPublishAllDto>({
    url: `/api/products/structured-products/v1/upload/${uploadId}/publish/${id}`,
    method: 'GET',
    signal,
  })
}

export const getGetUploadResultsByIdQueryKey = (
  uploadId: string,
  id: string
) => {
  return [
    `/api/products/structured-products/v1/upload/${uploadId}/publish/${id}`,
  ] as const
}

export const getGetUploadResultsByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getUploadResultsById>>,
  TError = ErrorType<ErrorResponse>
>(
  uploadId: string,
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUploadResultsById>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetUploadResultsByIdQueryKey(uploadId, id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUploadResultsById>>
  > = ({ signal }) => getUploadResultsById(uploadId, id, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(uploadId && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUploadResultsById>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetUploadResultsByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUploadResultsById>>
>
export type GetUploadResultsByIdQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get upload publish by Id
 */
export const useGetUploadResultsById = <
  TData = Awaited<ReturnType<typeof getUploadResultsById>>,
  TError = ErrorType<ErrorResponse>
>(
  uploadId: string,
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUploadResultsById>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUploadResultsByIdQueryOptions(
    uploadId,
    id,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Bulk draft Structured Product upload
 */
export const bulkDraftUpload = (bulkDraftUploadBody: BulkDraftUploadBody) => {
  const formData = new FormData()
  formData.append('file', bulkDraftUploadBody.file)

  return fetchInstance<StructuredProductUploadDto>({
    url: `/api/products/structured-products/v1/upload/draft`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: formData,
  })
}

export const getBulkDraftUploadMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkDraftUpload>>,
    TError,
    { data: BulkDraftUploadBody },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof bulkDraftUpload>>,
  TError,
  { data: BulkDraftUploadBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bulkDraftUpload>>,
    { data: BulkDraftUploadBody }
  > = (props) => {
    const { data } = props ?? {}

    return bulkDraftUpload(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type BulkDraftUploadMutationResult = NonNullable<
  Awaited<ReturnType<typeof bulkDraftUpload>>
>
export type BulkDraftUploadMutationBody = BulkDraftUploadBody
export type BulkDraftUploadMutationError = ErrorType<ErrorResponse>

/**
 * @summary Bulk draft Structured Product upload
 */
export const useBulkDraftUpload = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkDraftUpload>>,
    TError,
    { data: BulkDraftUploadBody },
    TContext
  >
}) => {
  const mutationOptions = getBulkDraftUploadMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Search structured products v2
 */
export const searchStructuredProductsV2 = (
  searchRequestStructuredProduct: SearchRequestStructuredProduct
) => {
  return fetchInstance<PageResultStructuredProductDto>({
    url: `/api/products/structured-products/v2/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchRequestStructuredProduct,
  })
}

export const getSearchStructuredProductsV2QueryKey = (
  searchRequestStructuredProduct: SearchRequestStructuredProduct
) => {
  return [
    `/api/products/structured-products/v2/search`,
    searchRequestStructuredProduct,
  ] as const
}

export const getSearchStructuredProductsV2QueryOptions = <
  TData = Awaited<ReturnType<typeof searchStructuredProductsV2>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestStructuredProduct: SearchRequestStructuredProduct,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchStructuredProductsV2>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getSearchStructuredProductsV2QueryKey(searchRequestStructuredProduct)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchStructuredProductsV2>>
  > = () => searchStructuredProductsV2(searchRequestStructuredProduct)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchStructuredProductsV2>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchStructuredProductsV2QueryResult = NonNullable<
  Awaited<ReturnType<typeof searchStructuredProductsV2>>
>
export type SearchStructuredProductsV2QueryError = ErrorType<ErrorResponse>

/**
 * @summary Search structured products v2
 */
export const useSearchStructuredProductsV2 = <
  TData = Awaited<ReturnType<typeof searchStructuredProductsV2>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestStructuredProduct: SearchRequestStructuredProduct,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchStructuredProductsV2>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchStructuredProductsV2QueryOptions(
    searchRequestStructuredProduct,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Get metadata for Structured Products filter criteria
 */
export const filterMetadataStructuredProducts = (
  structuredProductsFilterMetadataRequest: StructuredProductsFilterMetadataRequest
) => {
  return fetchInstance<StructuredProductsFilterMetadataResult>({
    url: `/api/products/structured-products/v1/filter-metadata`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: structuredProductsFilterMetadataRequest,
  })
}

export const getFilterMetadataStructuredProductsQueryKey = (
  structuredProductsFilterMetadataRequest: StructuredProductsFilterMetadataRequest
) => {
  return [
    `/api/products/structured-products/v1/filter-metadata`,
    structuredProductsFilterMetadataRequest,
  ] as const
}

export const getFilterMetadataStructuredProductsQueryOptions = <
  TData = Awaited<ReturnType<typeof filterMetadataStructuredProducts>>,
  TError = ErrorType<ErrorResponse>
>(
  structuredProductsFilterMetadataRequest: StructuredProductsFilterMetadataRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof filterMetadataStructuredProducts>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getFilterMetadataStructuredProductsQueryKey(
      structuredProductsFilterMetadataRequest
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof filterMetadataStructuredProducts>>
  > = () =>
    filterMetadataStructuredProducts(structuredProductsFilterMetadataRequest)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof filterMetadataStructuredProducts>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FilterMetadataStructuredProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof filterMetadataStructuredProducts>>
>
export type FilterMetadataStructuredProductsQueryError =
  ErrorType<ErrorResponse>

/**
 * @summary Get metadata for Structured Products filter criteria
 */
export const useFilterMetadataStructuredProducts = <
  TData = Awaited<ReturnType<typeof filterMetadataStructuredProducts>>,
  TError = ErrorType<ErrorResponse>
>(
  structuredProductsFilterMetadataRequest: StructuredProductsFilterMetadataRequest,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof filterMetadataStructuredProducts>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFilterMetadataStructuredProductsQueryOptions(
    structuredProductsFilterMetadataRequest,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Get metadata list for Structured Products filter criteria
 */
export const filterMetadataListStructuredProducts = (
  structuredProductsFilterMetadataRequest: StructuredProductsFilterMetadataRequest[]
) => {
  return fetchInstance<StructuredProductsFilterMetadataResult[]>({
    url: `/api/products/structured-products/v1/filter-metadata-list`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: structuredProductsFilterMetadataRequest,
  })
}

export const getFilterMetadataListStructuredProductsQueryKey = (
  structuredProductsFilterMetadataRequest: StructuredProductsFilterMetadataRequest[]
) => {
  return [
    `/api/products/structured-products/v1/filter-metadata-list`,
    structuredProductsFilterMetadataRequest,
  ] as const
}

export const getFilterMetadataListStructuredProductsQueryOptions = <
  TData = Awaited<ReturnType<typeof filterMetadataListStructuredProducts>>,
  TError = ErrorType<ErrorResponse>
>(
  structuredProductsFilterMetadataRequest: StructuredProductsFilterMetadataRequest[],
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof filterMetadataListStructuredProducts>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getFilterMetadataListStructuredProductsQueryKey(
      structuredProductsFilterMetadataRequest
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof filterMetadataListStructuredProducts>>
  > = () =>
    filterMetadataListStructuredProducts(
      structuredProductsFilterMetadataRequest
    )

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof filterMetadataListStructuredProducts>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type FilterMetadataListStructuredProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof filterMetadataListStructuredProducts>>
>
export type FilterMetadataListStructuredProductsQueryError =
  ErrorType<ErrorResponse>

/**
 * @summary Get metadata list for Structured Products filter criteria
 */
export const useFilterMetadataListStructuredProducts = <
  TData = Awaited<ReturnType<typeof filterMetadataListStructuredProducts>>,
  TError = ErrorType<ErrorResponse>
>(
  structuredProductsFilterMetadataRequest: StructuredProductsFilterMetadataRequest[],
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof filterMetadataListStructuredProducts>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getFilterMetadataListStructuredProductsQueryOptions(
    structuredProductsFilterMetadataRequest,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Get product ids that the calling user can purchase
 */
export const getPurchasableProductIds = (signal?: AbortSignal) => {
  return fetchInstance<string[]>({
    url: `/api/products/structured-products/v1/purchasable-product-ids`,
    method: 'GET',
    signal,
  })
}

export const getGetPurchasableProductIdsQueryKey = () => {
  return [
    `/api/products/structured-products/v1/purchasable-product-ids`,
  ] as const
}

export const getGetPurchasableProductIdsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPurchasableProductIds>>,
  TError = ErrorType<ErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPurchasableProductIds>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetPurchasableProductIdsQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPurchasableProductIds>>
  > = ({ signal }) => getPurchasableProductIds(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPurchasableProductIds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPurchasableProductIdsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPurchasableProductIds>>
>
export type GetPurchasableProductIdsQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get product ids that the calling user can purchase
 */
export const useGetPurchasableProductIds = <
  TData = Awaited<ReturnType<typeof getPurchasableProductIds>>,
  TError = ErrorType<ErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPurchasableProductIds>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPurchasableProductIdsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Create a draft structured product
 */
export const createDraft = (
  createDraftStructuredProductRequest: CreateDraftStructuredProductRequest
) => {
  return fetchInstance<StructuredProductV1>({
    url: `/api/products/structured-products/v1/create/draft`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createDraftStructuredProductRequest,
  })
}

export const getCreateDraftMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createDraft>>,
    TError,
    { data: CreateDraftStructuredProductRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createDraft>>,
  TError,
  { data: CreateDraftStructuredProductRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createDraft>>,
    { data: CreateDraftStructuredProductRequest }
  > = (props) => {
    const { data } = props ?? {}

    return createDraft(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateDraftMutationResult = NonNullable<
  Awaited<ReturnType<typeof createDraft>>
>
export type CreateDraftMutationBody = CreateDraftStructuredProductRequest
export type CreateDraftMutationError = ErrorType<ErrorResponse>

/**
 * @summary Create a draft structured product
 */
export const useCreateDraft = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createDraft>>,
    TError,
    { data: CreateDraftStructuredProductRequest },
    TContext
  >
}) => {
  const mutationOptions = getCreateDraftMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get structured product currencies
 */
export const getStructuredProductCurrencies = (signal?: AbortSignal) => {
  return fetchInstance<Currency[]>({
    url: `/api/products/structured-products/v1/currencies`,
    method: 'GET',
    signal,
  })
}

export const getGetStructuredProductCurrenciesQueryKey = () => {
  return [`/api/products/structured-products/v1/currencies`] as const
}

export const getGetStructuredProductCurrenciesQueryOptions = <
  TData = Awaited<ReturnType<typeof getStructuredProductCurrencies>>,
  TError = ErrorType<ErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getStructuredProductCurrencies>>,
    TError,
    TData
  >
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetStructuredProductCurrenciesQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getStructuredProductCurrencies>>
  > = ({ signal }) => getStructuredProductCurrencies(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getStructuredProductCurrencies>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetStructuredProductCurrenciesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStructuredProductCurrencies>>
>
export type GetStructuredProductCurrenciesQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get structured product currencies
 */
export const useGetStructuredProductCurrencies = <
  TData = Awaited<ReturnType<typeof getStructuredProductCurrencies>>,
  TError = ErrorType<ErrorResponse>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getStructuredProductCurrencies>>,
    TError,
    TData
  >
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetStructuredProductCurrenciesQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Add a structure template
 */
export const addStructure = (
  structureTemplateRequest: StructureTemplateRequest
) => {
  return fetchInstance<StructureTemplateDto>({
    url: `/api/products/structured-products/structure-templates/v1`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: structureTemplateRequest,
  })
}

export const getAddStructureMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addStructure>>,
    TError,
    { data: StructureTemplateRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof addStructure>>,
  TError,
  { data: StructureTemplateRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addStructure>>,
    { data: StructureTemplateRequest }
  > = (props) => {
    const { data } = props ?? {}

    return addStructure(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AddStructureMutationResult = NonNullable<
  Awaited<ReturnType<typeof addStructure>>
>
export type AddStructureMutationBody = StructureTemplateRequest
export type AddStructureMutationError = ErrorType<ErrorResponse>

/**
 * @summary Add a structure template
 */
export const useAddStructure = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addStructure>>,
    TError,
    { data: StructureTemplateRequest },
    TContext
  >
}) => {
  const mutationOptions = getAddStructureMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Search Structure Templates
 */
export const searchStructureTemplates = (
  searchRequestStructureTemplate: SearchRequestStructureTemplate
) => {
  return fetchInstance<PageResultStructureTemplateDto>({
    url: `/api/products/structured-products/structure-templates/v1/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchRequestStructureTemplate,
  })
}

export const getSearchStructureTemplatesQueryKey = (
  searchRequestStructureTemplate: SearchRequestStructureTemplate
) => {
  return [
    `/api/products/structured-products/structure-templates/v1/search`,
    searchRequestStructureTemplate,
  ] as const
}

export const getSearchStructureTemplatesInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof searchStructureTemplates>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestStructureTemplate: SearchRequestStructureTemplate,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof searchStructureTemplates>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getSearchStructureTemplatesQueryKey(searchRequestStructureTemplate)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchStructureTemplates>>
  > = () => searchStructureTemplates(searchRequestStructureTemplate)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof searchStructureTemplates>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchStructureTemplatesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchStructureTemplates>>
>
export type SearchStructureTemplatesInfiniteQueryError =
  ErrorType<ErrorResponse>

/**
 * @summary Search Structure Templates
 */
export const useSearchStructureTemplatesInfinite = <
  TData = Awaited<ReturnType<typeof searchStructureTemplates>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestStructureTemplate: SearchRequestStructureTemplate,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof searchStructureTemplates>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchStructureTemplatesInfiniteQueryOptions(
    searchRequestStructureTemplate,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSearchStructureTemplatesQueryOptions = <
  TData = Awaited<ReturnType<typeof searchStructureTemplates>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestStructureTemplate: SearchRequestStructureTemplate,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchStructureTemplates>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getSearchStructureTemplatesQueryKey(searchRequestStructureTemplate)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchStructureTemplates>>
  > = () => searchStructureTemplates(searchRequestStructureTemplate)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchStructureTemplates>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchStructureTemplatesQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchStructureTemplates>>
>
export type SearchStructureTemplatesQueryError = ErrorType<ErrorResponse>

/**
 * @summary Search Structure Templates
 */
export const useSearchStructureTemplates = <
  TData = Awaited<ReturnType<typeof searchStructureTemplates>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestStructureTemplate: SearchRequestStructureTemplate,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchStructureTemplates>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchStructureTemplatesQueryOptions(
    searchRequestStructureTemplate,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Run update statuses batch job
 */
export const runUpdateStatuses = () => {
  return fetchInstance<void>({
    url: `/api/products/structured-products/status-update/v1`,
    method: 'POST',
  })
}

export const getRunUpdateStatusesMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runUpdateStatuses>>,
    TError,
    void,
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof runUpdateStatuses>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof runUpdateStatuses>>,
    void
  > = () => {
    return runUpdateStatuses()
  }

  return { mutationFn, ...mutationOptions }
}

export type RunUpdateStatusesMutationResult = NonNullable<
  Awaited<ReturnType<typeof runUpdateStatuses>>
>

export type RunUpdateStatusesMutationError = ErrorType<ErrorResponse>

/**
 * @summary Run update statuses batch job
 */
export const useRunUpdateStatuses = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof runUpdateStatuses>>,
    TError,
    void,
    TContext
  >
}) => {
  const mutationOptions = getRunUpdateStatusesMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Add an issuer
 */
export const addIssuer = (issuerRequest: IssuerRequest) => {
  return fetchInstance<IssuerDto>({
    url: `/api/products/structured-products/issuers/v1`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: issuerRequest,
  })
}

export const getAddIssuerMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addIssuer>>,
    TError,
    { data: IssuerRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof addIssuer>>,
  TError,
  { data: IssuerRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof addIssuer>>,
    { data: IssuerRequest }
  > = (props) => {
    const { data } = props ?? {}

    return addIssuer(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AddIssuerMutationResult = NonNullable<
  Awaited<ReturnType<typeof addIssuer>>
>
export type AddIssuerMutationBody = IssuerRequest
export type AddIssuerMutationError = ErrorType<ErrorResponse>

/**
 * @summary Add an issuer
 */
export const useAddIssuer = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof addIssuer>>,
    TError,
    { data: IssuerRequest },
    TContext
  >
}) => {
  const mutationOptions = getAddIssuerMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Search Issuers
 */
export const searchIssuers = (searchRequestIssuer: SearchRequestIssuer) => {
  return fetchInstance<PageResultIssuerDto>({
    url: `/api/products/structured-products/issuers/v1/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchRequestIssuer,
  })
}

export const getSearchIssuersQueryKey = (
  searchRequestIssuer: SearchRequestIssuer
) => {
  return [
    `/api/products/structured-products/issuers/v1/search`,
    searchRequestIssuer,
  ] as const
}

export const getSearchIssuersInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof searchIssuers>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestIssuer: SearchRequestIssuer,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof searchIssuers>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getSearchIssuersQueryKey(searchRequestIssuer)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchIssuers>>
  > = () => searchIssuers(searchRequestIssuer)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof searchIssuers>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchIssuersInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchIssuers>>
>
export type SearchIssuersInfiniteQueryError = ErrorType<ErrorResponse>

/**
 * @summary Search Issuers
 */
export const useSearchIssuersInfinite = <
  TData = Awaited<ReturnType<typeof searchIssuers>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestIssuer: SearchRequestIssuer,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof searchIssuers>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchIssuersInfiniteQueryOptions(
    searchRequestIssuer,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSearchIssuersQueryOptions = <
  TData = Awaited<ReturnType<typeof searchIssuers>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestIssuer: SearchRequestIssuer,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchIssuers>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getSearchIssuersQueryKey(searchRequestIssuer)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchIssuers>>
  > = () => searchIssuers(searchRequestIssuer)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchIssuers>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchIssuersQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchIssuers>>
>
export type SearchIssuersQueryError = ErrorType<ErrorResponse>

/**
 * @summary Search Issuers
 */
export const useSearchIssuers = <
  TData = Awaited<ReturnType<typeof searchIssuers>>,
  TError = ErrorType<ErrorResponse>
>(
  searchRequestIssuer: SearchRequestIssuer,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchIssuers>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchIssuersQueryOptions(
    searchRequestIssuer,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Create a tailored marketplace profile
 */
export const createTailoredMarketplaceProfile = (
  createTailoredMarketplaceProfile: CreateTailoredMarketplaceProfile
) => {
  return fetchInstance<TailoredMarketplaceProfile>({
    url: `/api/products/structured-products/v1/tailored-marketplace-profiles`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createTailoredMarketplaceProfile,
  })
}

export const getCreateTailoredMarketplaceProfileMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTailoredMarketplaceProfile>>,
    TError,
    { data: CreateTailoredMarketplaceProfile },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createTailoredMarketplaceProfile>>,
  TError,
  { data: CreateTailoredMarketplaceProfile },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createTailoredMarketplaceProfile>>,
    { data: CreateTailoredMarketplaceProfile }
  > = (props) => {
    const { data } = props ?? {}

    return createTailoredMarketplaceProfile(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateTailoredMarketplaceProfileMutationResult = NonNullable<
  Awaited<ReturnType<typeof createTailoredMarketplaceProfile>>
>
export type CreateTailoredMarketplaceProfileMutationBody =
  CreateTailoredMarketplaceProfile
export type CreateTailoredMarketplaceProfileMutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Create a tailored marketplace profile
 */
export const useCreateTailoredMarketplaceProfile = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createTailoredMarketplaceProfile>>,
    TError,
    { data: CreateTailoredMarketplaceProfile },
    TContext
  >
}) => {
  const mutationOptions =
    getCreateTailoredMarketplaceProfileMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Search tailored marketplace profiles
 */
export const searchTailoredMarketplaceProfiles = (
  searchRequestTailoredMarketplaceProfiles: SearchRequestTailoredMarketplaceProfiles
) => {
  return fetchInstance<PageResultTailoredMarketplaceProfiles>({
    url: `/api/products/structured-products/v1/tailored-marketplace-profiles/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchRequestTailoredMarketplaceProfiles,
  })
}

export const getSearchTailoredMarketplaceProfilesMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchTailoredMarketplaceProfiles>>,
    TError,
    { data: SearchRequestTailoredMarketplaceProfiles },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchTailoredMarketplaceProfiles>>,
  TError,
  { data: SearchRequestTailoredMarketplaceProfiles },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchTailoredMarketplaceProfiles>>,
    { data: SearchRequestTailoredMarketplaceProfiles }
  > = (props) => {
    const { data } = props ?? {}

    return searchTailoredMarketplaceProfiles(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchTailoredMarketplaceProfilesMutationResult = NonNullable<
  Awaited<ReturnType<typeof searchTailoredMarketplaceProfiles>>
>
export type SearchTailoredMarketplaceProfilesMutationBody =
  SearchRequestTailoredMarketplaceProfiles
export type SearchTailoredMarketplaceProfilesMutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Search tailored marketplace profiles
 */
export const useSearchTailoredMarketplaceProfiles = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchTailoredMarketplaceProfiles>>,
    TError,
    { data: SearchRequestTailoredMarketplaceProfiles },
    TContext
  >
}) => {
  const mutationOptions =
    getSearchTailoredMarketplaceProfilesMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Create a product permission
 */
export const createProductPermission = (
  createProductPermission: CreateProductPermission
) => {
  return fetchInstance<ProductPermission>({
    url: `/api/products/structured-products/v1/permissions`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createProductPermission,
  })
}

export const getCreateProductPermissionMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createProductPermission>>,
    TError,
    { data: CreateProductPermission },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createProductPermission>>,
  TError,
  { data: CreateProductPermission },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createProductPermission>>,
    { data: CreateProductPermission }
  > = (props) => {
    const { data } = props ?? {}

    return createProductPermission(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateProductPermissionMutationResult = NonNullable<
  Awaited<ReturnType<typeof createProductPermission>>
>
export type CreateProductPermissionMutationBody = CreateProductPermission
export type CreateProductPermissionMutationError = ErrorType<ErrorResponse>

/**
 * @summary Create a product permission
 */
export const useCreateProductPermission = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createProductPermission>>,
    TError,
    { data: CreateProductPermission },
    TContext
  >
}) => {
  const mutationOptions = getCreateProductPermissionMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Create a number of product permissions
 */
export const bulkCreateProductPermission = (
  createProductPermissionBulkRequest: CreateProductPermissionBulkRequest
) => {
  return fetchInstance<ProductPermissionsBulkCreationResult>({
    url: `/api/products/structured-products/v1/permissions/bulk`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createProductPermissionBulkRequest,
  })
}

export const getBulkCreateProductPermissionMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkCreateProductPermission>>,
    TError,
    { data: CreateProductPermissionBulkRequest },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof bulkCreateProductPermission>>,
  TError,
  { data: CreateProductPermissionBulkRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bulkCreateProductPermission>>,
    { data: CreateProductPermissionBulkRequest }
  > = (props) => {
    const { data } = props ?? {}

    return bulkCreateProductPermission(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type BulkCreateProductPermissionMutationResult = NonNullable<
  Awaited<ReturnType<typeof bulkCreateProductPermission>>
>
export type BulkCreateProductPermissionMutationBody =
  CreateProductPermissionBulkRequest
export type BulkCreateProductPermissionMutationError = ErrorType<ErrorResponse>

/**
 * @summary Create a number of product permissions
 */
export const useBulkCreateProductPermission = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkCreateProductPermission>>,
    TError,
    { data: CreateProductPermissionBulkRequest },
    TContext
  >
}) => {
  const mutationOptions = getBulkCreateProductPermissionMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Search product permissions
 */
export const searchProductPermissions = (
  searchRequestProductPermission: SearchRequestProductPermission
) => {
  return fetchInstance<PageResultProductPermission>({
    url: `/api/products/structured-products/v1/permissions/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchRequestProductPermission,
  })
}

export const getSearchProductPermissionsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchProductPermissions>>,
    TError,
    { data: SearchRequestProductPermission },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchProductPermissions>>,
  TError,
  { data: SearchRequestProductPermission },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchProductPermissions>>,
    { data: SearchRequestProductPermission }
  > = (props) => {
    const { data } = props ?? {}

    return searchProductPermissions(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchProductPermissionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof searchProductPermissions>>
>
export type SearchProductPermissionsMutationBody =
  SearchRequestProductPermission
export type SearchProductPermissionsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Search product permissions
 */
export const useSearchProductPermissions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchProductPermissions>>,
    TError,
    { data: SearchRequestProductPermission },
    TContext
  >
}) => {
  const mutationOptions = getSearchProductPermissionsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Delete a product permission
 */
export const deleteProductPermission = (id: string) => {
  return fetchInstance<void>({
    url: `/api/products/structured-products/v1/permissions/${id}`,
    method: 'DELETE',
  })
}

export const getDeleteProductPermissionMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProductPermission>>,
    TError,
    { id: string },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteProductPermission>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteProductPermission>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {}

    return deleteProductPermission(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteProductPermissionMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteProductPermission>>
>

export type DeleteProductPermissionMutationError = ErrorType<ErrorResponse>

/**
 * @summary Delete a product permission
 */
export const useDeleteProductPermission = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProductPermission>>,
    TError,
    { id: string },
    TContext
  >
}) => {
  const mutationOptions = getDeleteProductPermissionMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Create an issuer group
 */
export const createIssuerGroup = (createIssuerGroup: CreateIssuerGroup) => {
  return fetchInstance<IssuerGroupDto>({
    url: `/api/products/structured-products/issuer-groups/v1`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: createIssuerGroup,
  })
}

export const getCreateIssuerGroupMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createIssuerGroup>>,
    TError,
    { data: CreateIssuerGroup },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createIssuerGroup>>,
  TError,
  { data: CreateIssuerGroup },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createIssuerGroup>>,
    { data: CreateIssuerGroup }
  > = (props) => {
    const { data } = props ?? {}

    return createIssuerGroup(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateIssuerGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof createIssuerGroup>>
>
export type CreateIssuerGroupMutationBody = CreateIssuerGroup
export type CreateIssuerGroupMutationError = ErrorType<ErrorResponse>

/**
 * @summary Create an issuer group
 */
export const useCreateIssuerGroup = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createIssuerGroup>>,
    TError,
    { data: CreateIssuerGroup },
    TContext
  >
}) => {
  const mutationOptions = getCreateIssuerGroupMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Search issuer groups
 */
export const searchIssuerGroups = (
  searchRequestIssuerGroup: SearchRequestIssuerGroup
) => {
  return fetchInstance<PageResultIssuerGroupDto>({
    url: `/api/products/structured-products/issuer-groups/v1/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: searchRequestIssuerGroup,
  })
}

export const getSearchIssuerGroupsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchIssuerGroups>>,
    TError,
    { data: SearchRequestIssuerGroup },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchIssuerGroups>>,
  TError,
  { data: SearchRequestIssuerGroup },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchIssuerGroups>>,
    { data: SearchRequestIssuerGroup }
  > = (props) => {
    const { data } = props ?? {}

    return searchIssuerGroups(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchIssuerGroupsMutationResult = NonNullable<
  Awaited<ReturnType<typeof searchIssuerGroups>>
>
export type SearchIssuerGroupsMutationBody = SearchRequestIssuerGroup
export type SearchIssuerGroupsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Search issuer groups
 */
export const useSearchIssuerGroups = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchIssuerGroups>>,
    TError,
    { data: SearchRequestIssuerGroup },
    TContext
  >
}) => {
  const mutationOptions = getSearchIssuerGroupsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Remap structured products
 */
export const remapStructuredProducts = (
  params?: RemapStructuredProductsParams
) => {
  return fetchInstance<void>({
    url: `/api/products/admin/structured-products/remap/v1`,
    method: 'POST',
    params,
  })
}

export const getRemapStructuredProductsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof remapStructuredProducts>>,
    TError,
    { params?: RemapStructuredProductsParams },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof remapStructuredProducts>>,
  TError,
  { params?: RemapStructuredProductsParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof remapStructuredProducts>>,
    { params?: RemapStructuredProductsParams }
  > = (props) => {
    const { params } = props ?? {}

    return remapStructuredProducts(params)
  }

  return { mutationFn, ...mutationOptions }
}

export type RemapStructuredProductsMutationResult = NonNullable<
  Awaited<ReturnType<typeof remapStructuredProducts>>
>

export type RemapStructuredProductsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Remap structured products
 */
export const useRemapStructuredProducts = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof remapStructuredProducts>>,
    TError,
    { params?: RemapStructuredProductsParams },
    TContext
  >
}) => {
  const mutationOptions = getRemapStructuredProductsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Remap structured product by id
 */
export const remapStructuredProductById = (
  id: string,
  params?: RemapStructuredProductByIdParams
) => {
  return fetchInstance<void>({
    url: `/api/products/admin/structured-products/remap/v1/${id}`,
    method: 'POST',
    params,
  })
}

export const getRemapStructuredProductByIdMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof remapStructuredProductById>>,
    TError,
    { id: string; params?: RemapStructuredProductByIdParams },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof remapStructuredProductById>>,
  TError,
  { id: string; params?: RemapStructuredProductByIdParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof remapStructuredProductById>>,
    { id: string; params?: RemapStructuredProductByIdParams }
  > = (props) => {
    const { id, params } = props ?? {}

    return remapStructuredProductById(id, params)
  }

  return { mutationFn, ...mutationOptions }
}

export type RemapStructuredProductByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof remapStructuredProductById>>
>

export type RemapStructuredProductByIdMutationError = ErrorType<ErrorResponse>

/**
 * @summary Remap structured product by id
 */
export const useRemapStructuredProductById = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof remapStructuredProductById>>,
    TError,
    { id: string; params?: RemapStructuredProductByIdParams },
    TContext
  >
}) => {
  const mutationOptions = getRemapStructuredProductByIdMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Map structured products with WSD data
 */
export const mapStructuredProductsWithWsdData = () => {
  return fetchInstance<void>({
    url: `/api/products/admin/structured-products/map/wsd-data/v1`,
    method: 'POST',
  })
}

export const getMapStructuredProductsWithWsdDataMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof mapStructuredProductsWithWsdData>>,
    TError,
    void,
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof mapStructuredProductsWithWsdData>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof mapStructuredProductsWithWsdData>>,
    void
  > = () => {
    return mapStructuredProductsWithWsdData()
  }

  return { mutationFn, ...mutationOptions }
}

export type MapStructuredProductsWithWsdDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof mapStructuredProductsWithWsdData>>
>

export type MapStructuredProductsWithWsdDataMutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Map structured products with WSD data
 */
export const useMapStructuredProductsWithWsdData = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof mapStructuredProductsWithWsdData>>,
    TError,
    void,
    TContext
  >
}) => {
  const mutationOptions =
    getMapStructuredProductsWithWsdDataMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Republish structured products events
 */
export const republishStructuredProducts = (
  params?: RepublishStructuredProductsParams
) => {
  return fetchInstance<void>({
    url: `/api/products/admin/structured-products/re-publish/v1`,
    method: 'POST',
    params,
  })
}

export const getRepublishStructuredProductsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof republishStructuredProducts>>,
    TError,
    { params?: RepublishStructuredProductsParams },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof republishStructuredProducts>>,
  TError,
  { params?: RepublishStructuredProductsParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof republishStructuredProducts>>,
    { params?: RepublishStructuredProductsParams }
  > = (props) => {
    const { params } = props ?? {}

    return republishStructuredProducts(params)
  }

  return { mutationFn, ...mutationOptions }
}

export type RepublishStructuredProductsMutationResult = NonNullable<
  Awaited<ReturnType<typeof republishStructuredProducts>>
>

export type RepublishStructuredProductsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Republish structured products events
 */
export const useRepublishStructuredProducts = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof republishStructuredProducts>>,
    TError,
    { params?: RepublishStructuredProductsParams },
    TContext
  >
}) => {
  const mutationOptions = getRepublishStructuredProductsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Republish Reference Assets
 */
export const republishReferenceAssets = (
  params?: RepublishReferenceAssetsParams
) => {
  return fetchInstance<void>({
    url: `/api/products/admin/structured-products/reference-assets/re-publish/v1`,
    method: 'POST',
    params,
  })
}

export const getRepublishReferenceAssetsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof republishReferenceAssets>>,
    TError,
    { params?: RepublishReferenceAssetsParams },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof republishReferenceAssets>>,
  TError,
  { params?: RepublishReferenceAssetsParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof republishReferenceAssets>>,
    { params?: RepublishReferenceAssetsParams }
  > = (props) => {
    const { params } = props ?? {}

    return republishReferenceAssets(params)
  }

  return { mutationFn, ...mutationOptions }
}

export type RepublishReferenceAssetsMutationResult = NonNullable<
  Awaited<ReturnType<typeof republishReferenceAssets>>
>

export type RepublishReferenceAssetsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Republish Reference Assets
 */
export const useRepublishReferenceAssets = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof republishReferenceAssets>>,
    TError,
    { params?: RepublishReferenceAssetsParams },
    TContext
  >
}) => {
  const mutationOptions = getRepublishReferenceAssetsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * @summary Get upload status by Id
 */
export const getUploadStatusById = (id: string, signal?: AbortSignal) => {
  return fetchInstance<StructuredProductUploadDto>({
    url: `/api/products/structured-products/v1/upload/${id}`,
    method: 'GET',
    signal,
  })
}

export const getGetUploadStatusByIdQueryKey = (id: string) => {
  return [`/api/products/structured-products/v1/upload/${id}`] as const
}

export const getGetUploadStatusByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getUploadStatusById>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUploadStatusById>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUploadStatusByIdQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUploadStatusById>>
  > = ({ signal }) => getUploadStatusById(id, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUploadStatusById>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetUploadStatusByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUploadStatusById>>
>
export type GetUploadStatusByIdQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get upload status by Id
 */
export const useGetUploadStatusById = <
  TData = Awaited<ReturnType<typeof getUploadStatusById>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUploadStatusById>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUploadStatusByIdQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Get upload status by Id v2
 */
export const getUploadStatusByIdV2 = (id: string, signal?: AbortSignal) => {
  return fetchInstance<StructuredProductUploadDtoV2>({
    url: `/api/products/structured-products/v2/upload/${id}`,
    method: 'GET',
    signal,
  })
}

export const getGetUploadStatusByIdV2QueryKey = (id: string) => {
  return [`/api/products/structured-products/v2/upload/${id}`] as const
}

export const getGetUploadStatusByIdV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getUploadStatusByIdV2>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUploadStatusByIdV2>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetUploadStatusByIdV2QueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUploadStatusByIdV2>>
  > = ({ signal }) => getUploadStatusByIdV2(id, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getUploadStatusByIdV2>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetUploadStatusByIdV2QueryResult = NonNullable<
  Awaited<ReturnType<typeof getUploadStatusByIdV2>>
>
export type GetUploadStatusByIdV2QueryError = ErrorType<ErrorResponse>

/**
 * @summary Get upload status by Id v2
 */
export const useGetUploadStatusByIdV2 = <
  TData = Awaited<ReturnType<typeof getUploadStatusByIdV2>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUploadStatusByIdV2>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUploadStatusByIdV2QueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Get product prices by product id
 */
export const getProductPrices = (
  productId: string,
  params?: GetProductPricesParams,
  signal?: AbortSignal
) => {
  return fetchInstance<ProductPriceDto[]>({
    url: `/api/products/structured-products/v1/prices/${productId}`,
    method: 'GET',
    params,
    signal,
  })
}

export const getGetProductPricesQueryKey = (
  productId: string,
  params?: GetProductPricesParams
) => {
  return [
    `/api/products/structured-products/v1/prices/${productId}`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetProductPricesQueryOptions = <
  TData = Awaited<ReturnType<typeof getProductPrices>>,
  TError = ErrorType<ErrorResponse>
>(
  productId: string,
  params?: GetProductPricesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getProductPrices>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetProductPricesQueryKey(productId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getProductPrices>>
  > = ({ signal }) => getProductPrices(productId, params, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!productId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getProductPrices>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetProductPricesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProductPrices>>
>
export type GetProductPricesQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get product prices by product id
 */
export const useGetProductPrices = <
  TData = Awaited<ReturnType<typeof getProductPrices>>,
  TError = ErrorType<ErrorResponse>
>(
  productId: string,
  params?: GetProductPricesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getProductPrices>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductPricesQueryOptions(
    productId,
    params,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Get products prices by product ids
 */
export const getProductsPrices = (
  params: GetProductsPricesParams,
  signal?: AbortSignal
) => {
  return fetchInstance<ProductPricesDto[]>({
    url: `/api/products/structured-products/v2/prices`,
    method: 'GET',
    params,
    signal,
  })
}

export const getGetProductsPricesQueryKey = (
  params: GetProductsPricesParams
) => {
  return [
    `/api/products/structured-products/v2/prices`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetProductsPricesQueryOptions = <
  TData = Awaited<ReturnType<typeof getProductsPrices>>,
  TError = ErrorType<ErrorResponse>
>(
  params: GetProductsPricesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getProductsPrices>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetProductsPricesQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getProductsPrices>>
  > = ({ signal }) => getProductsPrices(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProductsPrices>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetProductsPricesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProductsPrices>>
>
export type GetProductsPricesQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get products prices by product ids
 */
export const useGetProductsPrices = <
  TData = Awaited<ReturnType<typeof getProductsPrices>>,
  TError = ErrorType<ErrorResponse>
>(
  params: GetProductsPricesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getProductsPrices>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProductsPricesQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * @summary Republish lifecycle events for all products or a given list of product ids
 */
export const republishLifecycleEvents = (
  params?: RepublishLifecycleEventsParams
) => {
  return fetchInstance<void>({
    url: `/api/products/admin/structured-products/lifecycle/re-publish/v1`,
    method: 'POST',
    params,
  })
}

export const getRepublishLifecycleEventsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof republishLifecycleEvents>>,
    TError,
    { params?: RepublishLifecycleEventsParams },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof republishLifecycleEvents>>,
  TError,
  { params?: RepublishLifecycleEventsParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof republishLifecycleEvents>>,
    { params?: RepublishLifecycleEventsParams }
  > = (props) => {
    const { params } = props ?? {}

    return republishLifecycleEvents(params)
  }

  return { mutationFn, ...mutationOptions }
}

export type RepublishLifecycleEventsMutationResult = NonNullable<
  Awaited<ReturnType<typeof republishLifecycleEvents>>
>

export type RepublishLifecycleEventsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Republish lifecycle events for all products or a given list of product ids
 */
export const useRepublishLifecycleEvents = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof republishLifecycleEvents>>,
    TError,
    { params?: RepublishLifecycleEventsParams },
    TContext
  >
}) => {
  const mutationOptions = getRepublishLifecycleEventsMutationOptions(options)

  return useMutation(mutationOptions)
}
