import * as styles from './styles'

export function EnvironmentTag() {
  const environmentColorMap: Record<string, string> = {
    localhost: 'bg-neutral-200 text-neutral-900',
    staging: 'bg-primary-200 text-neutral-900',
    dev: 'bg-warning-600 text-neutral-900',
    qa: 'bg-success-500 text-neutral-0',
    ephemeral: 'bg-error-500 text-neutral-0',
  }

  type Environment = 'localhost' | 'ephemeral' | 'dev' | 'qa' | 'staging'

  const determineEnvironment = (hostname: string): Environment | '' => {
    if (hostname === 'localhost' || hostname.includes('127.0.0.1')) {
      return 'localhost'
    }
    if (hostname.includes('ephemeral')) {
      return 'ephemeral'
    }
    if (hostname.includes('.dev.')) {
      return 'dev'
    }
    if (hostname.includes('.qa.')) {
      return 'qa'
    }
    if (hostname.includes('.staging.')) {
      return 'staging'
    }
    return ''
  }

  const environment = determineEnvironment(window.location.hostname)

  return environment ? (
    <span
      className={`small-mono grid w-fit place-items-center rounded-full px-16 py-4 ${styles.wideScreenBreakpoint.environmentTag}
        ${environmentColorMap[environment]}`}
    >
      {environment.toUpperCase()}
    </span>
  ) : null
}
