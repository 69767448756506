// eslint-disable-next-line @nx/enforce-module-boundaries
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'

import { LoadingIndicator } from '@cais-group/equity/atoms/loading-indicator'
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  PortfolioListItemV1,
  useGetActiveSettingsForCurrentUser,
  useGetAllocationSummary,
} from '@cais-group/portfolio-construction-tool/domain/api'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { modelPortfolios } from '@cais-group/portfolio-construction-tool/shared'

const LightbulbIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4352_3321)">
        <path
          d="M7.49984 18.3332C7.49984 18.3332 7.83317 18.3332 8.33317 18.3332H11.6665C12.1665 18.3332 12.4998 18.3332 12.4998 18.3332V16.6665H7.49984V18.3332ZM9.99984 1.6665C6.74984 1.6665 4.1665 4.24984 4.1665 7.49984C4.1665 9.49984 5.1665 11.2498 6.6665 12.2498V14.1665C6.6665 14.5832 6.6665 14.9998 6.6665 14.9998H12.4998C12.9998 14.9998 13.3332 14.9998 13.3332 14.9998V12.2498C14.8332 11.1665 15.8332 9.4165 15.8332 7.49984C15.8332 4.24984 13.2498 1.6665 9.99984 1.6665Z"
          fill="url(#paint0_linear_4352_3321)"
        />
        <path
          d="M11.8351 6.28011L13.2235 8.88428L14.6126 6.28011L17.2168 4.89094L14.6126 3.50178L13.2235 0.897611L11.8351 3.50178L9.23096 4.89094L11.8351 6.28011Z"
          fill="url(#paint1_linear_4352_3321)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4352_3321"
          x1="4.1665"
          y1="9.99984"
          x2="15.8332"
          y2="9.99984"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#002C55" />
          <stop offset="1" stop-color="#0067C6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4352_3321"
          x1="17.2168"
          y1="4.89094"
          x2="9.23096"
          y2="4.89094"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FEB95F" />
          <stop offset="1" stop-color="#FFEED9" />
        </linearGradient>
        <clipPath id="clip0_4352_3321">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const Summarize = ({
  portfolio,
}: {
  portfolio: PortfolioListItemV1
}) => {
  const [summary, setSummary] = useState<null | string>(null)

  const { mutateAsync } = useGetAllocationSummary()
  const allocations = portfolio.allocations

  const { data: settings } = useGetActiveSettingsForCurrentUser()
  const comparedPortfolio = modelPortfolios['BALANCED_60_40']

  useEffect(() => {
    const generateSummary = async () => {
      try {
        const data = await mutateAsync({
          data: {
            items: [
              {
                cmaId: settings?.capitalMarketAssumptions.id,
                portfolioWeights:
                  allocations?.map(({ assetClass, allocation }) => ({
                    assetClass: assetClass,
                    weight: Number.isNaN(allocation) ? 0 : allocation,
                    productId:
                      settings?.indexChoices.indexChoices[assetClass] || '',
                    productType: 'IDX',
                  })) || [],
                label: portfolio.name,
              },
              {
                cmaId: settings?.capitalMarketAssumptions.id,
                label: comparedPortfolio.label,
                portfolioWeights: comparedPortfolio.weights.map(
                  ({ assetClass, weight }) => ({
                    assetClass: assetClass,
                    weight: Number.isNaN(weight) ? 0 : weight,
                    productId:
                      settings?.indexChoices.indexChoices[assetClass] || '',
                    productType: 'IDX',
                  })
                ),
              },
            ],
          },
        })

        if (!summary && settings) {
          setSummary(data.summary)
        }
      } catch (e) {
        console.warn(e)
      }
    }
    generateSummary()
  }, [
    allocations,
    comparedPortfolio,
    settings,
    portfolio.name,
    mutateAsync,
    summary,
  ])

  return (
    <div className="flex grow flex-col">
      {summary ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.5,
          }}
          className="small text-neutral-600"
        >
          {summary}
        </motion.div>
      ) : (
        <div className="small-strong text-primary-600 flex h-full w-full flex-col items-center justify-center gap-y-16 text-justify">
          <LightbulbIcon />
          Generating portfolio summary...
          <LoadingIndicator size="small" />
        </div>
      )}
    </div>
  )
}
