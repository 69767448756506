import { useState } from 'react'
import { useEffectOnce } from 'react-use'

import {
  defaultState,
  userContentPermissionsService,
  type LegacyContentPermissionsState,
} from '../content-permissions.service'

export function useGetAllowedContentPermissions() {
  const [permissions, setPermissions] =
    useState<LegacyContentPermissionsState>(defaultState)

  useEffectOnce(() => {
    const fetchPermissions = async () => {
      const permissions =
        await userContentPermissionsService.contentPermissionsAsync
      const firmIds = userContentPermissionsService.getFirmIds()
      const fundProductIds = permissions?.data?.fundProductIds

      setPermissions({
        ...permissions,
        data: {
          firmIds,
          fundProductIds,
        },
      })
    }
    fetchPermissions()
  })

  return permissions
}
