import styled from '@emotion/styled'
import { forwardRef, type ForwardedRef } from 'react'
import { omit } from 'remeda'

import { ButtonProps, Loading } from './material-button-abstract'

type Size = 'large' | 'medium' | 'small'
export type BtnColor = 'btn-green' | 'btn-gray' | 'btn-red' | 'btn-blue'

const getPadding = (size?: Size): string => {
  switch (size) {
    case 'large':
      return '0.75rem var(--s24)'
    case 'medium':
      return 'var(--s8) var(--s16)'
    case 'small':
      return '0.25rem 0.75rem'
    default:
      return '0.75rem var(--s24)'
  }
}

// TODO: use font components from the Text file
const getFontSize = (size?: Size): string => {
  switch (size) {
    case 'large':
      return 'var(--s16)'
    case 'medium':
      return '0.875rem'
    case 'small':
      return '0.75rem'
    default:
      return 'var(--s16)'
  }
}

const Hidden = styled.div`
  visibility: hidden;
`

const ButtonAbstractStyled = styled.button`
  position: relative;
`

export const ButtonAbstract = styled(
  forwardRef((props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { children, loading } = props
    const passedProps = omit(props, ['children', 'loading'])
    return (
      <ButtonAbstractStyled ref={ref} {...passedProps}>
        {loading ? (
          <>
            <Hidden>{children}</Hidden> <Loading />{' '}
          </>
        ) : (
          children
        )}
      </ButtonAbstractStyled>
    )
  })
)`
  padding: ${(props) => getPadding(props.size)};
  font-size: ${(props) => getFontSize(props.size)};
  display: flex;

  &:disabled {
    color: rgb(var(--colors-neutral-300));
  }
`
ButtonAbstract.displayName = 'ButtonAbstract'
