import type { Options, RenderNode } from '@contentful/rich-text-react-renderer'

import { BodyLinks } from '../../../../util/type/body'
import { baseBlockComponents } from '../../rich-text-base/lib/rich-text-base-components'
import { Overrides } from '../../rich-text-base/lib/types'
import { mergeOptions } from '../../rich-text-base/lib/utils'

import { baseEmbeddedBlocksAndInlines } from './rich-text-embedded-entry'

export type RichTextRenderProps = {
  links?: BodyLinks
  segmentPageId?: string
  renderNode?:
    | RenderNode
    | ((links?: BodyLinks | undefined) => RenderNode)
    | undefined
  urlPrefix?: string
  overrides?: Overrides
}

export function richTextRenderOptions(props: RichTextRenderProps): Options {
  const { renderNode, ...rest } = props
  const renderOptions = {
    renderNode:
      typeof renderNode === 'function' ? renderNode(rest.links) : renderNode,
  }

  return {
    renderNode: {
      ...baseEmbeddedBlocksAndInlines(rest),
      ...mergeOptions({
        renderNode: { ...baseBlockComponents },
        overrides: props.overrides,
      }),
      ...renderOptions.renderNode,
    },
  }
}
