import cx from 'classnames'
import { ReactElement, ReactNode } from 'react'
import { useMeasure } from 'react-use'

import { useCleanData } from './cleanData'
import { PieAwareLine } from './components/PieAwareLine'
import { SliceTooltip } from './components/SliceTooltip'
import { useDerivedSizes } from './helpers/useDerivedSizes'
import { useHoverState } from './helpers/useHoverState'
import { SEGMENT_CIRCLE_RATIO, usePathData } from './helpers/usePathData'
import { BaseDatum } from './types'

const SPACING = 16
const LABEL_SPACING = 64
const MIN_LABEL_WIDTH = 30
const MAX_LABELS_PER_SIDE = 5
const CHART_HEIGHT = 348

export function PieChart<Datum extends BaseDatum>({
  data: rawData,
  label,
  tooltipLabel,
  maxLabelsPerSide = MAX_LABELS_PER_SIDE,
  minLabelWidth = MIN_LABEL_WIDTH,
  threshold,
  spacing = SPACING,
  labelSpacing = LABEL_SPACING,
  chartHeight = CHART_HEIGHT,
  chartWidth,
  highlightedId,
  onChangeHighlightedId,
  testId = 'pie-chart',
}: {
  data: Datum[]
  label: (d: Datum, proportion: number, rightHandSide: boolean) => ReactNode
  tooltipLabel?: (d: Datum, proportion: number) => ReactNode
  maxLabelsPerSide?: number
  minLabelWidth?: number
  threshold?: number
  spacing?: number
  labelSpacing?: number
  chartHeight?: number
  chartWidth?: number
  highlightedId?: string
  onChangeHighlightedId?: (index: string | undefined) => void
  testId?: string
}) {
  const [ref, { width: computedWidth }] = useMeasure<HTMLDivElement>()
  const width: number = chartWidth ? chartWidth : computedWidth
  const height: number = chartHeight

  const { hoverId, enterHandlerFor, leaveHandlerFor, toggleFor, resetHoverId } =
    useHoverState(onChangeHighlightedId)

  const { radius, center } = useDerivedSizes(width, height, spacing)

  const data = useCleanData(rawData)

  const {
    pathProps,
    labels,
    itemsWithSizing,
    segmentPathProps,
    segmentItemsWithSizing,
  } = usePathData<Datum>({
    data,
    radius,
    width,
    height,
    maxLabelsPerSide,
    minLabelWidth,
    spacing,
    labelSpacing,
    threshold,
  })

  const currentHighlightId = hoverId ?? highlightedId
  const hasHighlight = currentHighlightId !== undefined
  const hasTooltip = !!tooltipLabel

  let pie: ReactElement | null
  let segmentPie: ReactElement | null

  // SVG doesn't really support drawing a circle via a path (you can get arbitrarily close), but this is more correct (if verbose)
  if (pathProps.length > 1) {
    pie = (
      <>
        {pathProps.map((props) => (
          <path
            {...props}
            key={props.id}
            onMouseEnter={enterHandlerFor(props.id)}
            onMouseLeave={leaveHandlerFor(props.id)}
            onClick={toggleFor(props.id)}
            fillOpacity={
              currentHighlightId !== undefined
                ? currentHighlightId === props.id
                  ? 1
                  : 0.25
                : 1
            }
            data-testid={`${testId}-slice-${props.id}`}
            stroke="white"
            strokeWidth={1}
          />
        ))}
      </>
    )
  } else if (pathProps.length === 1) {
    const id = pathProps[0].id
    pie = (
      <circle
        fill={pathProps[0].fill}
        cx={center[0]}
        cy={center[1]}
        r={radius}
        onMouseEnter={enterHandlerFor(id)}
        onMouseLeave={leaveHandlerFor(id)}
        onClick={toggleFor(id)}
        fillOpacity={
          currentHighlightId !== undefined
            ? currentHighlightId === id
              ? 1
              : 0.25
            : 1
        }
        data-testid={`${testId}-slice-${id}`}
        // Not really needed but should keep size consistent:
        stroke="white"
        strokeWidth={1}
      />
    )
  } else {
    pie = null
  }

  let SegmentBackgroundCircle: () => ReactElement | null = () => null
  let SegmentCentralCircle: () => ReactElement | null = () => null

  if (segmentPathProps && segmentPathProps?.length > 0) {
    SegmentBackgroundCircle = () => (
      <circle
        fill="white"
        cx={center[0]}
        cy={center[1]}
        r={radius * SEGMENT_CIRCLE_RATIO}
        stroke="white"
        strokeWidth={1}
      />
    )

    SegmentCentralCircle = () => (
      <circle
        fill="white"
        cx={center[0]}
        cy={center[1]}
        r={(radius * 4) / 15}
        stroke="white"
        strokeWidth={1}
      />
    )
  }
  // SVG doesn't really support drawing a circle via a path (you can get arbitrarily close), but this is more correct (if verbose)
  if (segmentPathProps && segmentPathProps?.length > 1) {
    segmentPie = (
      <>
        <SegmentBackgroundCircle />
        {segmentPathProps.map((props) => (
          <path
            {...props}
            key={props.id}
            onMouseEnter={enterHandlerFor(props.id)}
            onMouseLeave={leaveHandlerFor(props.id)}
            onClick={toggleFor(props.id)}
            fillOpacity={
              currentHighlightId !== undefined
                ? currentHighlightId === props.id
                  ? 1
                  : 0.25
                : props.fillOpacity || 1
            }
            data-testid={`${testId}-slice-inner-${props.id}`}
            stroke="white"
            strokeWidth={1}
          />
        ))}
        <SegmentCentralCircle />
      </>
    )
  } else if (segmentPathProps && segmentPathProps.length === 1) {
    const id = segmentPathProps[0].id
    segmentPie = (
      <>
        <SegmentBackgroundCircle />
        <circle
          fill={segmentPathProps[0].fill}
          cx={center[0]}
          cy={center[1]}
          r={radius}
          onMouseEnter={enterHandlerFor(id)}
          onMouseLeave={leaveHandlerFor(id)}
          onClick={toggleFor(id)}
          fillOpacity={
            currentHighlightId !== undefined
              ? currentHighlightId === id
                ? 1
                : 0.25
              : 0.75
          }
          data-testid={`${testId}-slice-inner-${id}`}
          stroke="white"
          strokeWidth={1}
        />
        <SegmentCentralCircle />
      </>
    )
  } else {
    segmentPie = null
  }

  return (
    <div className="relative w-full" ref={ref} style={{ height }}>
      {!width || !height ? null : (
        <>
          <svg
            style={{ width: '100%', height }}
            data-testid={testId}
            onMouseLeave={resetHoverId}
          >
            {pie}

            {segmentPie}

            {labels.map(({ centroid, position, item: { id } }) => (
              <PieAwareLine
                centroid={centroid}
                position={position}
                center={center}
                radius={radius}
                key={id}
                hide={
                  hasHighlight
                    ? currentHighlightId === id
                      ? hasTooltip
                      : true
                    : false
                }
                highlighted={currentHighlightId === id}
              />
            ))}
          </svg>
          {labels.map(
            (
              { width, height, position, rightHandSide, item, proportion },
              index
            ) => (
              <div
                className={cx('absolute flex flex-col justify-center px-8', {
                  'items-start': rightHandSide,
                  'items-end': !rightHandSide,
                })}
                key={index}
                style={{
                  top: position[1] - height / 2,
                  left: position[0] - (rightHandSide ? 0 : width),
                  width,
                  height,
                }}
              >
                {label(item, proportion, rightHandSide)}
              </div>
            )
          )}
          <SliceTooltip
            items={itemsWithSizing.concat(
              (segmentItemsWithSizing || []) as typeof itemsWithSizing
            )}
            // Don't do tooltip based on external highlight, only hover (or click)
            id={hoverId}
            tooltipLabel={tooltipLabel}
          />
        </>
      )}
    </div>
  )
}
