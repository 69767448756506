import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '@cais-group/equity/atoms/button'
import { PortfolioListItemV1 } from '@cais-group/portfolio-construction-tool/domain/api'

import { PortfolioCard } from '../portfolio-card'

import { Growth } from './growth'
import { Summarize } from './summarize'

const VIEW_PORTFOLIO_ROUTE = (id: string) => `/cais-compass/portfolios/${id}`

type Props = {
  portfolios: PortfolioListItemV1[]
  closeSearch: () => void
  searchText?: string
}

export const Portfolios = ({ portfolios, closeSearch, searchText }: Props) => {
  const [selectedPortfolio, setSelectedPortfolio] =
    useState<PortfolioListItemV1 | null>(
      portfolios.length === 1 ? portfolios[0] : null
    )

  const navigate = useNavigate()

  useEffect(() => {
    if (portfolios.length === 1) {
      setSelectedPortfolio(portfolios[0])
    }
  }, [portfolios])

  return (
    <div className="flex flex-col">
      <div className="">Portfolios</div>
      {selectedPortfolio ? (
        <PortfolioExpandedView
          portfolio={selectedPortfolio}
          onClose={() => setSelectedPortfolio(null)}
          viewPortfolio={() => {
            navigate(VIEW_PORTFOLIO_ROUTE(selectedPortfolio.id))
            closeSearch()
          }}
        />
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="mt-16 grid grid-cols-3 gap-32 [@media(min-width:2080px)]:grid-cols-4"
        >
          {portfolios.map((portfolio) => {
            return (
              <div
                key={portfolio.id}
                className="hover:shadow-2 flex grow cursor-pointer flex-col"
                onClick={() => setSelectedPortfolio(portfolio)}
              >
                <PortfolioCard portfolio={portfolio} highlight={searchText} />
              </div>
            )
          })}
        </motion.div>
      )}
    </div>
  )
}

const PortfolioExpandedView = ({
  portfolio,
  onClose,
  viewPortfolio,
}: {
  portfolio: PortfolioListItemV1
  onClose: () => void
  viewPortfolio: () => void
}) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="mt-32 flex w-full flex-col"
    >
      <div className="flex justify-between">
        <div className="headline-s-strong">{portfolio.name}</div>
        <div className="flex items-center gap-x-8">
          <Button variant="secondary" color="neutral" onClick={onClose}>
            Show all portfolios
          </Button>
          <Button onClick={viewPortfolio}>View in Compass</Button>
        </div>
      </div>
      <div className="gap-x-88 gap-x-88 flex">
        <div className="flex w-[500px] min-w-[500px] grow">
          <Summarize portfolio={portfolio} />
        </div>

        <div className="flex grow flex-col">
          <div className="body-strong">Growth of $1,000,000</div>
          <Growth portfolio={portfolio} />
        </div>
      </div>
    </motion.div>
  )
}
