import { type NavItemMenu } from '@cais-group/shared/util/entitled-nav-links'

const compassLinks: NavItemMenu['menu']['groups'][0] = {
  items: [
    {
      name: 'Model Portfolios',
      href: '/model-portfolios',
      icon: 'PieChart',
      entitlements: { permission: 'portfolios:model:read' },
      status: 'New',
    },
    {
      name: 'CAIS Compass',
      href: '/cais-compass',
      icon: 'Explore',
      entitlements: { permission: 'portfolios:custom:read' },
      status: 'New',
    },
  ],
}

const defaultGroups: NavItemMenu['menu']['groups'] = [
  {
    name: 'Alternative Investments',
    items: [
      {
        name: 'Discover Offerings',
        href: '/funds-pre-trade/funds',
        icon: 'Product',
        entitlements: { permission: 'funds:products:advisor-read' },
      },
      {
        name: 'Manage Orders',
        href: '/investment-pipeline/ai/grid/5',
        icon: 'Build',
        entitlements: {
          or: [
            { permission: 'portal:view-pipeline' },
            { permission: 'portal:view-manage-transaction-details' },
            { permission: 'portal:generate-recommendations' },
            { feature: 'PORTAL_PIPELINE_ALTERNATIVES_ACTIVE_USER' },
            { feature: 'PORTAL_PIPELINE_ALTERNATIVES_ACTIVE_ADMIN' },
          ],
        },
      },
      {
        name: 'View Holdings',
        href: '/cais-accounts/holdings/hedge-funds/positions/cais',
        icon: 'Map',
        entitlements: { permission: 'portal:view-cais-accounts' },
      },
    ],
  },
  {
    name: 'Structured Investments',
    items: [
      {
        name: 'Discover Offerings',
        href: '/structured-investments/current-offerings',
        icon: 'Product',
        entitlements: {
          permission: 'structured-products:current-offerings:read',
        },
      },
      {
        name: 'Manage Orders',
        href: '/structured-investments/advisor/order-management-tool',
        icon: 'Build',
        entitlements: {
          or: [
            { permission: 'structured-products:orders:advisors:read' },
            { permission: 'structured-products:orders:advisors:write' },
          ],
        },
      },
      {
        name: 'View Holdings',
        href: '/structured-investments/holdings/summary',
        icon: 'Map',
        entitlements: {
          permission: 'structured-products:read',
        },
      },
    ],
  },
]

export const quickLinks: NavItemMenu[] = [
  {
    name: 'Quick Links',
    menu: {
      id: 'quick-links',
      groups: [compassLinks, ...defaultGroups],
    },
  },
]
