import {
  BLOCKS,
  INLINES,
  type Block,
  type Inline,
  type Node,
} from '@contentful/rich-text-types'
import { Suspense, type ReactNode } from 'react'

import { Markdown } from '../../../../../equity/web/atoms/markdown'
import { FileDownload } from '../../../../../equity/web/labs/file-download'
import { getFileExtension } from '../../../../util/js/js-util-common'
import { millisecondsToSeconds } from '../../../../util/js/js-util-format-length'
import { BodyLinks } from '../../../../util/type/body/lib/type-body'
import { EntryMap } from '../../../../util/type/rich-text-json'
import { ContentfulLightboxWrapper } from '../../../lightbox'
import { MuxVideoPlayer } from '../../../video/mux-video-player'
import { ChartContentful } from '../../chart'
import { assetsAndEntriesById, embeddedAsset } from '../../rich-text-base'

import { embeddedEntryLink, inlineLink } from './link-components'

export function embeddedEntry(node: Node, entryMap: EntryMap) {
  const id = node.data['target'].sys.id
  const entry = entryMap.get(id)

  if (!entry?.__typename) {
    return null
  }
  switch (entry.__typename) {
    case 'Chart': {
      return <ChartContentful {...entry} />
    }
    case 'CaisiqVideo': {
      const durationInSeconds = entry.muxVideo
        ? entry.muxVideo.duration
        : millisecondsToSeconds(entry.duration ?? 0, 'round')
      const videoSource = entry?.muxVideo
        ? { playbackId: entry.muxVideo.playbackId }
        : { src: entry?.video?.url }
      return (
        videoSource &&
        entry && (
          <Suspense fallback={null}>
            <MuxVideoPlayer
              {...videoSource}
              variant="inPageComponent"
              metadata={{
                url: window.location.href,
                video_title: entry.title || '',
                duration: durationInSeconds,
                watchId: (Math.random() * 10).toString(36).replace('.', ''),
                video_id: entry.sys.id,
                viewer_user_id: 'Unknown content entry user',
              }}
            />
          </Suspense>
        )
      )
    }
    case 'Pdf': {
      return (
        <FileDownload
          fileType={getFileExtension(entry.pdf?.fileName?.toUpperCase() ?? '')}
          key={entry.title}
          title={entry.title ?? 'Download'}
          source={entry.pdf?.url ?? ''}
        />
      )
    }
    case 'Markdown': {
      return entry.type === 'footnote' ? (
        <Markdown
          options={{ overrides: { span: { props: { className: 'caption' } } } }}
        >
          {entry.text as string}
        </Markdown>
      ) : null
    }
    case 'ComponentImage': {
      return <ContentfulLightboxWrapper {...entry} />
    }
    default:
      return null
  }
}

type BaseEmbeddedEntryProps = {
  links?: BodyLinks
  segmentPageId?: string
  urlPrefix?: string
}
export const baseEmbeddedBlocksAndInlines = ({
  links,
  segmentPageId,
  urlPrefix,
}: BaseEmbeddedEntryProps) => {
  const { assetMap, entryMap } = assetsAndEntriesById(links)

  return {
    [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline) =>
      embeddedAsset(node, assetMap),
    [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline) =>
      embeddedEntry(node, entryMap),
    [INLINES.HYPERLINK]: (node: Block | Inline, children: ReactNode) =>
      inlineLink(node, children, segmentPageId),
    [INLINES.ENTRY_HYPERLINK]: (node: Block | Inline, children: ReactNode) =>
      embeddedEntryLink(node, children, entryMap, urlPrefix),
    [INLINES.EMBEDDED_ENTRY]: (node: Block | Inline) =>
      embeddedEntry(node, entryMap),
  }
}
