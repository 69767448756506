import { useMemo } from 'react'

import { useGetAssetClassFundStrategyMappings } from '@cais-group/portfolio-construction-tool/domain/api'

import {
  AssetClass,
  AssetClassColorMapping,
  UIAllocationInputType,
} from '../../types'

export type StrategyAssetClassMapping = {
  [key: string]: AssetClass
}

export type AssetClassToStrategyMapping = {
  [key: string]: string[]
}

export function useStrategyAssetClassMapping(): StrategyAssetClassMapping {
  const { data } = useGetAssetClassFundStrategyMappings({
    query: {
      queryKey: 'asset-class-fund-strategy-mappings',
    },
  })
  const strategyToAssetClassMapping = useMemo(() => {
    const mapping: StrategyAssetClassMapping = {}
    data?.forEach(({ assetClass, fundStrategies }) => {
      fundStrategies.forEach(({ displayName }) => {
        mapping[displayName] = assetClass as AssetClass
      })
    })
    return mapping
  }, [data])
  return strategyToAssetClassMapping
}

export function useAssetClassToStrategyMapping(): AssetClassToStrategyMapping {
  const { data } = useGetAssetClassFundStrategyMappings({
    query: {
      queryKey: 'asset-class-fund-strategy-mappings',
    },
  })
  const assetClassToStrategyMapping = useMemo(() => {
    const mapping: { [key: string]: string[] } = {}
    data?.forEach(({ assetClass, fundStrategies }) => {
      mapping[assetClass] = fundStrategies.map(({ displayName }) => displayName)
    })
    return mapping
  }, [data])
  return assetClassToStrategyMapping
}

const assetClassesTitles = {
  PUBLIC_EQUITY: 'Public Equity',
  PUBLIC_FIXED_INCOME: 'Public Fixed Income',
  HEDGE_FUNDS: 'Hedge Funds',
  PRIVATE_EQUITY: 'Private Equity',
  PRIVATE_DEBT: 'Private Debt',
  PRIVATE_REAL_ASSETS: 'Private Real Assets',
}
export function useGenerateUiAllocationInputs(): UIAllocationInputType[] {
  const { data = [] } = useGetAssetClassFundStrategyMappings({
    query: {
      queryKey: 'asset-class-fund-strategy-mappings',
    },
  })

  return useMemo(() => generateUiAllocationInputs(data), [data])
}

export function generateUiAllocationInputs(
  data: {
    assetClass: string
    fundStrategies: { displayName: string }[]
  }[]
): UIAllocationInputType[] {
  return data.map(({ assetClass, fundStrategies }) => ({
    color: AssetClassColorMapping[assetClass as AssetClass],
    title: assetClassesTitles[assetClass as AssetClass] || assetClass,
    name: assetClass as AssetClass,
    isAlt:
      assetClass !== AssetClass.PUBLIC_EQUITY &&
      assetClass !== AssetClass.PUBLIC_FIXED_INCOME,
    strategyDisplayNames: fundStrategies.map(({ displayName }) => displayName),
  }))
}
