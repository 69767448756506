import { useState } from 'react'
import { match, P } from 'ts-pattern'

import {
  FirmV1,
  useGetFirmByIdV1,
  UserProfileV1,
} from '@cais-group/access-manager/domain/api'
import {
  PageErrorSplash,
  Main,
} from '@cais-group/shared/ui/contentful/components'
import { useUserProfile } from '@cais-group/shared/util/hook/use-user-profile'
import { TUserRole } from '@cais-group/shared/util/type/caisiq-be'

import { useGetHome } from '../../domain/contentful/home/useGetHome'
import { DashboardHeader } from '../../ui/dashboard-header/homepage-ui-dashboard-header'
import { quickLinks } from '../../ui/quick-links/default-quick-links'
import { QuickLinks } from '../../ui/quick-links/quick-links'

import { Home } from './home'

// TODO HP-? (future PR): Where do we want to put the userSettingsService util/class?

let _user: UserProfileV1 | null

export const userSettingsService = {
  initiateUser: (user: UserProfileV1 | null) => {
    _user = user
  },
  get permissions(): string[] {
    return _user?.permissions ?? []
  },
  hasRole: (key: TUserRole) => {
    return userSettingsService.permissions.includes(key) || false
  },
}

const extractWelcomeMessageFromFirm = (firm?: FirmV1): string | null => {
  return firm?.attributes?.['CUSTOM_WELCOME_MESSAGE'] as string | null
}

export const HomeIndex = () => {
  const {
    userProfileState: { userProfile },
  } = useUserProfile()

  const {
    pageData,
    error: featureError,
    loading,
  } = useGetHome({
    options: { pressReleasesEnabled: false },
  })
  const userName = userProfile?.firstName || ''
  const firmId = userProfile?.firm?.id
  const { data: firm } = useGetFirmByIdV1(firmId || '', {
    query: { enabled: !!firmId },
  })

  const [activePill, setActivePill] = useState<number>(0)
  const welcomeMessage = extractWelcomeMessageFromFirm(firm)

  const contentError = pageData?.content.error ? pageData?.content.error : false
  // only show page level error if both featured and content have errors
  const error = featureError && contentError

  return (
    <>
      <DashboardHeader welcomeMessage={welcomeMessage} userName={userName} />
      <Main fullWidth background="brandBackground">
        {match({ error, pageData })
          .with({ error: true }, () => <PageErrorSplash.General />)
          .with(
            {
              error: false,
              pageData: P.not(P.nullish),
            },
            ({ pageData }) => (
              <Home
                featured={pageData.featured}
                press={pageData.press}
                browseContent={pageData.content}
                activePill={activePill}
                setActivePill={setActivePill}
                featureLoading={loading}
                featuredError={featureError}
                curatedContent={pageData.curatedContent}
                quickLinks={
                  <QuickLinks links={quickLinks} userProfile={userProfile} />
                }
              />
            )
          )
          .otherwise(() => null)}
      </Main>
    </>
  )
}
