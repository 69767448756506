import type { PropsWithChildren } from 'react'

import {
  mapTrackingKeysToDataAttributes,
  type Tracking,
} from '../../../../../equity/utils/tracking-utils'

import { cardVariantTypes as variantTypes, BG } from './types'

const afterStyles =
  '[&_a]:after:absolute [&_a]:after:inset-0 [&_a]:after:z-10 [&_a]:after:content-[""]'

const defaultBackground = 'bg-neutral-0'
const curated = `${afterStyles} gap-8 border border-solid p-16 border-transparent focus:border-primary-600 has-[a]:hover:shadow-2`

const variants: Record<keyof typeof variantTypes, (bg: BG) => string> = {
  default: (bg: BG) =>
    `${afterStyles} ${bg} border border-solid border-neutral-200 has-[a]:hover:shadow-2`,
  curated: (bg: BG) => `${bg} ${curated}`,
  'curated-primary': () => `${curated} bg-brand-100 hover:border-brand-200`,
  'featured-compact': (bg: BG) =>
    `w-full mx-auto gap-16 lg:gap-32 lg:flex-row lg:items-center ${afterStyles} ${bg}`,
  featured: (bg: BG) =>
    `w-full lg:gap-24 lg:flex-row items-center ${afterStyles} ${bg}`,
  text: (bg: BG) => bg,
} as const

type Variants = keyof typeof variants

export type BaseCardProps = Tracking &
  PropsWithChildren<{
    animate?: boolean
    backgroundColor?: BG
    style?: Record<string, string>
    testId?: string
    variant?: Variants
  }>

export function CardBase(props: BaseCardProps) {
  const backgroundColor = props.backgroundColor || defaultBackground
  const variantStyles = variants[props.variant || 'default'](backgroundColor)

  return (
    <article
      data-testid={props.testId}
      className={`
        duration-short-2 ease-standard active:border-primary-600 focus-within:border-primary-600 relative
        isolate flex w-full min-w-[var(--min-width)] flex-col
        transition-shadow focus-within:border hover:border-neutral-200 [&:focus-within_a]:outline-none
        ${props.animate ? 'animate-fadeIn' : ''}
        ${variantStyles}
      `}
      style={props.style}
      {...mapTrackingKeysToDataAttributes(props.tracking)}
    >
      {props.children}
    </article>
  )
}
