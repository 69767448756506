import { useMemo } from 'react'

import { BaseDatum } from '../types'

import { labelLayouts } from './labelLayout'

export const SEGMENT_CIRCLE_RATIO = 3 / 5

type PathsType<Datum> = {
  item: Datum & { fillOpacity?: number }
  index: number
  pathProps: {
    d: string
    fill: string
    id: string
    fillOpacity?: number
  }
  rightHandSide: boolean
  centroid: [x: number, y: number]
  proportion: number
}[]

function angleToCoordinates(
  angle: number,
  radius: number,
  center: [number, number]
): [x: number, y: number] {
  return [
    center[0] + radius * Math.cos(angle),
    center[1] + radius * Math.sin(angle),
  ]
}

export function usePathData<Datum extends BaseDatum>({
  data,
  radius,
  width,
  height,
  maxLabelsPerSide,
  minLabelWidth,
  spacing,
  labelSpacing,
  threshold,
}: {
  data: Datum[]
  radius: number
  width: number
  height: number
  maxLabelsPerSide: number
  minLabelWidth: number
  spacing: number
  labelSpacing: number
  threshold?: number
}) {
  return useMemo(() => {
    if (!data)
      return {
        pathProps: [],
        labels: [],
        itemsWithSizing: [] as PathsType<Datum>,
      }

    const center = [width / 2, height / 2] as [number, number]

    const total = data.reduce((sum, { value }) => {
      if (value < 0) throw new Error('Negative value for pie chart segment')
      return sum + value
    }, 0)

    let currentAngle = -Math.PI / 2
    const angles = data.map(({ id, value }) => {
      const startAngle = currentAngle
      const endAngle = currentAngle + (value / total) * 2 * Math.PI
      currentAngle = endAngle
      return {
        startAngle,
        endAngle,
        proportion: value / total,
        id,
      }
    })

    const hasSegments = data.some(({ segments }) => !!segments)
    const segmentData = hasSegments
      ? data.reduce<(Datum & { fillOpacity: number })[]>(
          (acc, { id, value, color, segments }) => {
            if (!segments) {
              return acc.concat([
                {
                  id,
                  value,
                  color,
                  fillOpacity: 0,
                } as Datum & { fillOpacity: number },
              ])
            }

            const totalSegmentsValue = segments.reduce(
              (acc, { value }) => acc + value,
              0
            )
            const isMissingSegment = totalSegmentsValue < value
            const missingSegmentValue = isMissingSegment
              ? value - totalSegmentsValue
              : 0

            return acc
              .concat(
                segments.map(({ id, value, ...rest }, index) => {
                  return {
                    id,
                    value,
                    color,
                    fillOpacity:
                      1 - (segments.length - index) / (segments.length + 1),
                    ...rest,
                  }
                }) as (Datum & { fillOpacity: number })[]
              )
              .concat(
                isMissingSegment
                  ? ({
                      id: `${id}.missingSegment`,
                      value: missingSegmentValue,
                      color,
                      fillOpacity: 0,
                    } as Datum & { fillOpacity: number })
                  : ([] as (Datum & { fillOpacity: number })[])
              )
          },
          []
        )
      : undefined

    currentAngle = -Math.PI / 2
    const segmentAngles = segmentData
      ? segmentData.map(({ id, value }) => {
          const startAngle = currentAngle
          const endAngle = currentAngle + (value / total) * 2 * Math.PI
          currentAngle = endAngle
          return {
            startAngle,
            endAngle,
            proportion: value / total,
            id,
          }
        })
      : undefined

    const paths: PathsType<Datum> = angles.map(
      ({ startAngle, endAngle, proportion, id }, index) => {
        const [startCoordinateX, startCoordinateY] = angleToCoordinates(
          startAngle,
          radius,
          center
        )
        const [endCoordinateX, endCoordinateY] = angleToCoordinates(
          endAngle,
          radius,
          center
        )
        const { sweepFlag, largeArcFlag } =
          endAngle - startAngle <= Math.PI
            ? {
                sweepFlag: 1,
                largeArcFlag: 0,
              }
            : {
                sweepFlag: 1,
                largeArcFlag: 1,
              }

        const d = [
          'M',
          startCoordinateX,
          startCoordinateY,
          'A',
          radius,
          radius,
          0,
          largeArcFlag,
          sweepFlag,
          endCoordinateX,
          endCoordinateY,
          'L',
          center[0],
          center[1],
          'Z',
        ].join(' ')
        return {
          item: data[index],
          index,
          pathProps: {
            d,
            fill: data[index].color,
            id,
          },
          rightHandSide: (startAngle + endAngle) / 2 < Math.PI / 2,

          centroid: angleToCoordinates(
            (startAngle + endAngle) / 2,
            radius * 0.6666666666667,
            center
          ),
          proportion,
        }
      }
    )

    const segmentPaths: PathsType<Datum> | undefined =
      segmentData && segmentAngles
        ? segmentAngles.map(
            ({ startAngle, endAngle, proportion, id }, index) => {
              const [startCoordinateX, startCoordinateY] = angleToCoordinates(
                startAngle,
                radius * SEGMENT_CIRCLE_RATIO,
                center
              )
              const [endCoordinateX, endCoordinateY] = angleToCoordinates(
                endAngle,
                radius * SEGMENT_CIRCLE_RATIO,
                center
              )
              const { sweepFlag, largeArcFlag } =
                endAngle - startAngle <= Math.PI
                  ? {
                      sweepFlag: 1,
                      largeArcFlag: 0,
                    }
                  : {
                      sweepFlag: 1,
                      largeArcFlag: 1,
                    }

              const d = [
                'M',
                startCoordinateX,
                startCoordinateY,
                'A',
                radius * SEGMENT_CIRCLE_RATIO,
                radius * SEGMENT_CIRCLE_RATIO,
                0,
                largeArcFlag,
                sweepFlag,
                endCoordinateX,
                endCoordinateY,
                'L',
                center[0],
                center[1],
                'Z',
              ].join(' ')
              return {
                item: segmentData[index],
                index,
                pathProps: {
                  d,
                  fill: segmentData[index].color,
                  fillOpacity: segmentData[index].fillOpacity,
                  id,
                  display:
                    segmentData[index].fillOpacity === 0 ? 'none' : undefined,
                },
                rightHandSide: (startAngle + endAngle) / 2 < Math.PI / 2,
                centroid: angleToCoordinates(
                  (startAngle + endAngle) / 2,
                  radius * SEGMENT_CIRCLE_RATIO * 0.6666666666667,
                  center
                ),
                proportion,
              }
            }
          )
        : undefined

    const labels = labelLayouts({
      segments: paths,
      maxLabelsPerSide,
      minLabelWidth,
      width,
      height,
      center,
      radius,
      spacing,
      labelSpacing,
      threshold,
    })

    return {
      pathProps: paths.map(({ pathProps }) => pathProps),
      segmentPathProps: segmentPaths?.map(({ pathProps }) => pathProps),
      labels,
      itemsWithSizing: paths,
      segmentItemsWithSizing: segmentPaths,
    }
  }, [
    width,
    height,
    data,
    maxLabelsPerSide,
    minLabelWidth,
    radius,
    spacing,
    labelSpacing,
    threshold,
  ])
}
