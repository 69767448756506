/* eslint @nx/enforce-module-boundaries: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */

import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'

import { LoadingIndicator } from '@cais-group/equity/atoms/loading-indicator'

const Caisey = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
  >
    <mask
      id="mask0_224_14016"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="29"
      height="29"
    >
      <circle
        cx="14.053"
        cy="14.053"
        r="14.053"
        fill="url(#paint0_linear_224_14016)"
      />
    </mask>
    <g mask="url(#mask0_224_14016)">
      <rect
        y="-3.51324"
        width="29.8626"
        height="39.8169"
        fill="url(#paint1_linear_224_14016)"
      />
      <path
        d="M22.834 12.3731C18.1204 12.1913 16.2149 9.88073 16.1925 6.14832C16.0853 10.1578 13.9618 12.3112 9.55957 12.3731C13.5355 12.2565 16.0081 14.1253 16.1967 19.0102C16.0586 15.1105 17.8547 12.5995 22.834 12.3731Z"
        fill="white"
      />
      <path
        d="M9.76563 13.3678C9.78535 16.2615 8.35139 17.8223 5.26917 17.8643C7.96309 17.7854 9.63785 19.0521 9.76563 22.3607C9.673 19.7193 10.8874 18.0177 14.2621 17.8643C10.9534 17.7373 9.68659 16.0615 9.76563 13.3678Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_224_14016"
        x1="0"
        y1="14.053"
        x2="28.106"
        y2="14.053"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#002C55" />
        <stop offset="1" stop-color="#0067C6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_224_14016"
        x1="0.698542"
        y1="22.1837"
        x2="30.0624"
        y2="21.4473"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.129129" stop-color="#025BE1" />
        <stop offset="0.515" stop-color="#106AF1" />
        <stop offset="1" stop-color="#569AFF" />
      </linearGradient>
    </defs>
  </svg>
)

const matches = ['blue owl', 'blue bird', 'pajaro azul']

type Props = {
  searchText: string
}

export const AISearch = ({ searchText }: Props) => {
  const [show, setShow] = useState(false)
  const [isLoading, setIsloading] = useState(true)

  useEffect(() => {
    if (matches.includes(searchText.toLowerCase())) {
      setShow(true)
      setIsloading(true)

      setTimeout(() => setIsloading(false), 2000)

      return
    }

    setShow(false)
    setIsloading(false)
  }, [searchText])

  return show ? (
    <motion.div
      className="flex flex-col gap-y-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex items-center gap-x-8">
        <Caisey />
        <div className="body-strong">Caisey Overview</div>
      </div>

      <div className="bg-primary-100 max-w-[900px] p-24">
        {isLoading ? (
          <div className="text-primary-600 body-strong flex items-center gap-x-16">
            Generating...
            <LoadingIndicator size="small" />
          </div>
        ) : (
          <div className="text-neutral-600">
            Blue Owl (NYSE: OWL) is a leading asset manager that seeks to invest
            across three multi-strategy platforms: Credit, GP Strategic Capital,
            and Real Estate. Anchored by a permanent capital base, Blue Owl
            seeks to provide businesses with private capital solutions to drive
            long-term growth and offer institutional and individual investors
            differentiated alternative investment opportunities that aim to
            deliver strong performance, risk-adjusted returns, and capital
            preservation. To learn more, visit www.blueowl.com.
          </div>
        )}
      </div>
    </motion.div>
  ) : null
}
