import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  QueryFunctionContext,
} from 'react-query'
import { createFetcher } from '@cais-group/shared/util/hook/use-graphql-contentful-request'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z,
   *     compliant with the 'date-time' format outlined in section 5.6 of
   *     the RFC 3339 profile of the ISO 8601 standard for representation
   *     of dates and times using the Gregorian calendar.
   */
  DateTime: any
  /** The 'Dimension' type represents dimensions as whole numeric values between `1` and `4000`. */
  Dimension: any
  /** The 'HexColor' type represents color in `rgb:ffffff` string format. */
  HexColor: any
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any
  /** The 'Quality' type represents quality as whole numeric values between `1` and `100`. */
  Quality: any
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accessControl) */
export type AccessControl = Entry &
  _Node & {
    __typename?: 'AccessControl'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<AccessControlLinkingCollections>
    sys: Sys
    type?: Maybe<Scalars['String']>
    value?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accessControl) */
export type AccessControlInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accessControl) */
export type AccessControlLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accessControl) */
export type AccessControlTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accessControl) */
export type AccessControlValueArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accessControl2) */
export type AccessControl2 = Entry &
  _Node & {
    __typename?: 'AccessControl2'
    _id: Scalars['ID']
    capability?: Maybe<Array<Maybe<Scalars['String']>>>
    contentfulMetadata: ContentfulMetadata
    feature?: Maybe<Array<Maybe<Scalars['String']>>>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<AccessControl2LinkingCollections>
    permission?: Maybe<Array<Maybe<Scalars['String']>>>
    persona?: Maybe<Array<Maybe<Scalars['String']>>>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accessControl2) */
export type AccessControl2CapabilityArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accessControl2) */
export type AccessControl2FeatureArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accessControl2) */
export type AccessControl2InternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accessControl2) */
export type AccessControl2LinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accessControl2) */
export type AccessControl2PermissionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accessControl2) */
export type AccessControl2PersonaArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type AccessControl2Collection = {
  __typename?: 'AccessControl2Collection'
  items: Array<Maybe<AccessControl2>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type AccessControl2Filter = {
  AND?: InputMaybe<Array<InputMaybe<AccessControl2Filter>>>
  OR?: InputMaybe<Array<InputMaybe<AccessControl2Filter>>>
  capability_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  capability_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  capability_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  capability_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  feature_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  feature_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  feature_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  feature_exists?: InputMaybe<Scalars['Boolean']>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  permission_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  permission_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  permission_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  permission_exists?: InputMaybe<Scalars['Boolean']>
  persona_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  persona_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  persona_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  persona_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type AccessControl2LinkingCollections = {
  __typename?: 'AccessControl2LinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type AccessControl2LinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum AccessControl2Order {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accessControl3) */
export type AccessControl3 = Entry &
  _Node & {
    __typename?: 'AccessControl3'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    features?: Maybe<Array<Maybe<Scalars['String']>>>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<AccessControl3LinkingCollections>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accessControl3) */
export type AccessControl3FeaturesArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accessControl3) */
export type AccessControl3InternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accessControl3) */
export type AccessControl3LinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type AccessControl3Collection = {
  __typename?: 'AccessControl3Collection'
  items: Array<Maybe<AccessControl3>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type AccessControl3Filter = {
  AND?: InputMaybe<Array<InputMaybe<AccessControl3Filter>>>
  OR?: InputMaybe<Array<InputMaybe<AccessControl3Filter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  features_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  features_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  features_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  features_exists?: InputMaybe<Scalars['Boolean']>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type AccessControl3LinkingCollections = {
  __typename?: 'AccessControl3LinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type AccessControl3LinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum AccessControl3Order {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type AccessControlCollection = {
  __typename?: 'AccessControlCollection'
  items: Array<Maybe<AccessControl>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type AccessControlFilter = {
  AND?: InputMaybe<Array<InputMaybe<AccessControlFilter>>>
  OR?: InputMaybe<Array<InputMaybe<AccessControlFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  type?: InputMaybe<Scalars['String']>
  type_contains?: InputMaybe<Scalars['String']>
  type_exists?: InputMaybe<Scalars['Boolean']>
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type_not?: InputMaybe<Scalars['String']>
  type_not_contains?: InputMaybe<Scalars['String']>
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  value?: InputMaybe<Scalars['String']>
  value_contains?: InputMaybe<Scalars['String']>
  value_exists?: InputMaybe<Scalars['Boolean']>
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  value_not?: InputMaybe<Scalars['String']>
  value_not_contains?: InputMaybe<Scalars['String']>
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type AccessControlLinkingCollections = {
  __typename?: 'AccessControlLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type AccessControlLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum AccessControlOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accordion) */
export type Accordion = Entry &
  _Node & {
    __typename?: 'Accordion'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    description?: Maybe<AccordionDescription>
    image?: Maybe<ComponentImage>
    link?: Maybe<AccordionLink>
    linkedFrom?: Maybe<AccordionLinkingCollections>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accordion) */
export type AccordionDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accordion) */
export type AccordionImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentImageFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accordion) */
export type AccordionLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accordion) */
export type AccordionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accordion) */
export type AccordionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type AccordionCollection = {
  __typename?: 'AccordionCollection'
  items: Array<Maybe<Accordion>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type AccordionDescription = {
  __typename?: 'AccordionDescription'
  json: Scalars['JSON']
  links: AccordionDescriptionLinks
}

export type AccordionDescriptionAssets = {
  __typename?: 'AccordionDescriptionAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type AccordionDescriptionEntries = {
  __typename?: 'AccordionDescriptionEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type AccordionDescriptionLinks = {
  __typename?: 'AccordionDescriptionLinks'
  assets: AccordionDescriptionAssets
  entries: AccordionDescriptionEntries
  resources: AccordionDescriptionResources
}

export type AccordionDescriptionResources = {
  __typename?: 'AccordionDescriptionResources'
  block: Array<AccordionDescriptionResourcesBlock>
  hyperlink: Array<AccordionDescriptionResourcesHyperlink>
  inline: Array<AccordionDescriptionResourcesInline>
}

export type AccordionDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'AccordionDescriptionResourcesBlock'
  sys: ResourceSys
}

export type AccordionDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'AccordionDescriptionResourcesHyperlink'
  sys: ResourceSys
}

export type AccordionDescriptionResourcesInline = ResourceLink & {
  __typename?: 'AccordionDescriptionResourcesInline'
  sys: ResourceSys
}

export type AccordionFilter = {
  AND?: InputMaybe<Array<InputMaybe<AccordionFilter>>>
  OR?: InputMaybe<Array<InputMaybe<AccordionFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_not_contains?: InputMaybe<Scalars['String']>
  image?: InputMaybe<CfComponentImageNestedFilter>
  image_exists?: InputMaybe<Scalars['Boolean']>
  link_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type AccordionLink = CaisiqArticle | Link | Page | PageLanding

export type AccordionLinkingCollections = {
  __typename?: 'AccordionLinkingCollections'
  accordionWithImageCollection?: Maybe<AccordionWithImageCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type AccordionLinkingCollectionsAccordionWithImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<AccordionLinkingCollectionsAccordionWithImageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AccordionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum AccordionLinkingCollectionsAccordionWithImageCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum AccordionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accordionWithImage) */
export type AccordionWithImage = Entry &
  _Node & {
    __typename?: 'AccordionWithImage'
    _id: Scalars['ID']
    accordionsCollection?: Maybe<AccordionWithImageAccordionsCollection>
    contentfulMetadata: ContentfulMetadata
    image?: Maybe<ComponentImage>
    linkedFrom?: Maybe<AccordionWithImageLinkingCollections>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accordionWithImage) */
export type AccordionWithImageAccordionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<AccordionWithImageAccordionsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<AccordionFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accordionWithImage) */
export type AccordionWithImageImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentImageFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accordionWithImage) */
export type AccordionWithImageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/accordionWithImage) */
export type AccordionWithImageTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type AccordionWithImageAccordionsCollection = {
  __typename?: 'AccordionWithImageAccordionsCollection'
  items: Array<Maybe<Accordion>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum AccordionWithImageAccordionsCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type AccordionWithImageCollection = {
  __typename?: 'AccordionWithImageCollection'
  items: Array<Maybe<AccordionWithImage>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type AccordionWithImageFilter = {
  AND?: InputMaybe<Array<InputMaybe<AccordionWithImageFilter>>>
  OR?: InputMaybe<Array<InputMaybe<AccordionWithImageFilter>>>
  accordions?: InputMaybe<CfAccordionNestedFilter>
  accordionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  image?: InputMaybe<CfComponentImageNestedFilter>
  image_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type AccordionWithImageLinkingCollections = {
  __typename?: 'AccordionWithImageLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
}

export type AccordionWithImageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AccordionWithImageLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<AccordionWithImageLinkingCollectionsPageLandingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum AccordionWithImageLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum AccordionWithImageOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Announcement [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/announcement) */
export type Announcement = Entry &
  _Node & {
    __typename?: 'Announcement'
    _id: Scalars['ID']
    buttonText?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    dateTimeEnd?: Maybe<Scalars['DateTime']>
    dateTimeStart?: Maybe<Scalars['DateTime']>
    description?: Maybe<Scalars['String']>
    destination?: Maybe<Link>
    firmsCollection?: Maybe<AnnouncementFirmsCollection>
    fundProductIds?: Maybe<FundProductId>
    icon?: Maybe<Scalars['String']>
    image?: Maybe<Scalars['JSON']>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<AnnouncementLinkingCollections>
    sys: Sys
    timezone?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
  }

/** Announcement [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/announcement) */
export type AnnouncementButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Announcement [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/announcement) */
export type AnnouncementDateTimeEndArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Announcement [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/announcement) */
export type AnnouncementDateTimeStartArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Announcement [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/announcement) */
export type AnnouncementDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Announcement [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/announcement) */
export type AnnouncementDestinationArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<LinkFilter>
}

/** Announcement [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/announcement) */
export type AnnouncementFirmsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AnnouncementFirmsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MemberFirmFilter>
}

/** Announcement [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/announcement) */
export type AnnouncementFundProductIdsArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<FundProductIdFilter>
}

/** Announcement [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/announcement) */
export type AnnouncementIconArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Announcement [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/announcement) */
export type AnnouncementImageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Announcement [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/announcement) */
export type AnnouncementInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Announcement [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/announcement) */
export type AnnouncementLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Announcement [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/announcement) */
export type AnnouncementTimezoneArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Announcement [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/announcement) */
export type AnnouncementTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type AnnouncementCollection = {
  __typename?: 'AnnouncementCollection'
  items: Array<Maybe<Announcement>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type AnnouncementFilter = {
  AND?: InputMaybe<Array<InputMaybe<AnnouncementFilter>>>
  Description?: InputMaybe<Scalars['String']>
  Description_contains?: InputMaybe<Scalars['String']>
  Description_exists?: InputMaybe<Scalars['Boolean']>
  Description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  Description_not?: InputMaybe<Scalars['String']>
  Description_not_contains?: InputMaybe<Scalars['String']>
  Description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  OR?: InputMaybe<Array<InputMaybe<AnnouncementFilter>>>
  buttonText?: InputMaybe<Scalars['String']>
  buttonText_contains?: InputMaybe<Scalars['String']>
  buttonText_exists?: InputMaybe<Scalars['Boolean']>
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonText_not?: InputMaybe<Scalars['String']>
  buttonText_not_contains?: InputMaybe<Scalars['String']>
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  dateTimeEnd?: InputMaybe<Scalars['DateTime']>
  dateTimeEnd_exists?: InputMaybe<Scalars['Boolean']>
  dateTimeEnd_gt?: InputMaybe<Scalars['DateTime']>
  dateTimeEnd_gte?: InputMaybe<Scalars['DateTime']>
  dateTimeEnd_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  dateTimeEnd_lt?: InputMaybe<Scalars['DateTime']>
  dateTimeEnd_lte?: InputMaybe<Scalars['DateTime']>
  dateTimeEnd_not?: InputMaybe<Scalars['DateTime']>
  dateTimeEnd_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  dateTimeStart?: InputMaybe<Scalars['DateTime']>
  dateTimeStart_exists?: InputMaybe<Scalars['Boolean']>
  dateTimeStart_gt?: InputMaybe<Scalars['DateTime']>
  dateTimeStart_gte?: InputMaybe<Scalars['DateTime']>
  dateTimeStart_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  dateTimeStart_lt?: InputMaybe<Scalars['DateTime']>
  dateTimeStart_lte?: InputMaybe<Scalars['DateTime']>
  dateTimeStart_not?: InputMaybe<Scalars['DateTime']>
  dateTimeStart_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  destination?: InputMaybe<CfLinkNestedFilter>
  destination_exists?: InputMaybe<Scalars['Boolean']>
  firms?: InputMaybe<CfMemberFirmNestedFilter>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  fundProductIds?: InputMaybe<CfFundProductIdNestedFilter>
  fundProductIds_exists?: InputMaybe<Scalars['Boolean']>
  icon?: InputMaybe<Scalars['String']>
  icon_contains?: InputMaybe<Scalars['String']>
  icon_exists?: InputMaybe<Scalars['Boolean']>
  icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  icon_not?: InputMaybe<Scalars['String']>
  icon_not_contains?: InputMaybe<Scalars['String']>
  icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  image_exists?: InputMaybe<Scalars['Boolean']>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  timezone?: InputMaybe<Scalars['String']>
  timezone_contains?: InputMaybe<Scalars['String']>
  timezone_exists?: InputMaybe<Scalars['Boolean']>
  timezone_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  timezone_not?: InputMaybe<Scalars['String']>
  timezone_not_contains?: InputMaybe<Scalars['String']>
  timezone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type AnnouncementFirmsCollection = {
  __typename?: 'AnnouncementFirmsCollection'
  items: Array<Maybe<MemberFirm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum AnnouncementFirmsCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type AnnouncementLinkingCollections = {
  __typename?: 'AnnouncementLinkingCollections'
  announcementSetCollection?: Maybe<AnnouncementSetCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type AnnouncementLinkingCollectionsAnnouncementSetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<AnnouncementLinkingCollectionsAnnouncementSetCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AnnouncementLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum AnnouncementLinkingCollectionsAnnouncementSetCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum AnnouncementOrder {
  DescriptionAsc = 'Description_ASC',
  DescriptionDesc = 'Description_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  DateTimeEndAsc = 'dateTimeEnd_ASC',
  DateTimeEndDesc = 'dateTimeEnd_DESC',
  DateTimeStartAsc = 'dateTimeStart_ASC',
  DateTimeStartDesc = 'dateTimeStart_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimezoneAsc = 'timezone_ASC',
  TimezoneDesc = 'timezone_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Announcement Set [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/announcementSet) */
export type AnnouncementSet = Entry &
  _Node & {
    __typename?: 'AnnouncementSet'
    _id: Scalars['ID']
    announcementDefault?: Maybe<Announcement>
    announcementOptionsCollection?: Maybe<AnnouncementSetAnnouncementOptionsCollection>
    contentfulMetadata: ContentfulMetadata
    excludedUrls?: Maybe<Array<Maybe<Scalars['String']>>>
    id?: Maybe<Scalars['String']>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<AnnouncementSetLinkingCollections>
    sys: Sys
  }

/** Announcement Set [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/announcementSet) */
export type AnnouncementSetAnnouncementDefaultArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<AnnouncementFilter>
}

/** Announcement Set [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/announcementSet) */
export type AnnouncementSetAnnouncementOptionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<AnnouncementSetAnnouncementOptionsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<AnnouncementFilter>
}

/** Announcement Set [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/announcementSet) */
export type AnnouncementSetExcludedUrlsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Announcement Set [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/announcementSet) */
export type AnnouncementSetIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Announcement Set [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/announcementSet) */
export type AnnouncementSetInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Announcement Set [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/announcementSet) */
export type AnnouncementSetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type AnnouncementSetAnnouncementOptionsCollection = {
  __typename?: 'AnnouncementSetAnnouncementOptionsCollection'
  items: Array<Maybe<Announcement>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum AnnouncementSetAnnouncementOptionsCollectionOrder {
  DescriptionAsc = 'Description_ASC',
  DescriptionDesc = 'Description_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  DateTimeEndAsc = 'dateTimeEnd_ASC',
  DateTimeEndDesc = 'dateTimeEnd_DESC',
  DateTimeStartAsc = 'dateTimeStart_ASC',
  DateTimeStartDesc = 'dateTimeStart_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimezoneAsc = 'timezone_ASC',
  TimezoneDesc = 'timezone_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type AnnouncementSetCollection = {
  __typename?: 'AnnouncementSetCollection'
  items: Array<Maybe<AnnouncementSet>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type AnnouncementSetFilter = {
  AND?: InputMaybe<Array<InputMaybe<AnnouncementSetFilter>>>
  OR?: InputMaybe<Array<InputMaybe<AnnouncementSetFilter>>>
  announcementDefault?: InputMaybe<CfAnnouncementNestedFilter>
  announcementDefault_exists?: InputMaybe<Scalars['Boolean']>
  announcementOptions?: InputMaybe<CfAnnouncementNestedFilter>
  announcementOptionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  excludedURLS_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  excludedURLS_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  excludedURLS_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  excludedURLS_exists?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['String']>
  id_contains?: InputMaybe<Scalars['String']>
  id_exists?: InputMaybe<Scalars['Boolean']>
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_not?: InputMaybe<Scalars['String']>
  id_not_contains?: InputMaybe<Scalars['String']>
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type AnnouncementSetLinkingCollections = {
  __typename?: 'AnnouncementSetLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type AnnouncementSetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum AnnouncementSetOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
  __typename?: 'Asset'
  contentType?: Maybe<Scalars['String']>
  contentfulMetadata: ContentfulMetadata
  description?: Maybe<Scalars['String']>
  fileName?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['Int']>
  linkedFrom?: Maybe<AssetLinkingCollections>
  size?: Maybe<Scalars['Int']>
  sys: Sys
  title?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['Int']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
  locale?: InputMaybe<Scalars['String']>
  transform?: InputMaybe<ImageTransformOptions>
}

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type AssetCollection = {
  __typename?: 'AssetCollection'
  items: Array<Maybe<Asset>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type AssetFilter = {
  AND?: InputMaybe<Array<InputMaybe<AssetFilter>>>
  OR?: InputMaybe<Array<InputMaybe<AssetFilter>>>
  contentType?: InputMaybe<Scalars['String']>
  contentType_contains?: InputMaybe<Scalars['String']>
  contentType_exists?: InputMaybe<Scalars['Boolean']>
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentType_not?: InputMaybe<Scalars['String']>
  contentType_not_contains?: InputMaybe<Scalars['String']>
  contentType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fileName?: InputMaybe<Scalars['String']>
  fileName_contains?: InputMaybe<Scalars['String']>
  fileName_exists?: InputMaybe<Scalars['Boolean']>
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fileName_not?: InputMaybe<Scalars['String']>
  fileName_not_contains?: InputMaybe<Scalars['String']>
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  height?: InputMaybe<Scalars['Int']>
  height_exists?: InputMaybe<Scalars['Boolean']>
  height_gt?: InputMaybe<Scalars['Int']>
  height_gte?: InputMaybe<Scalars['Int']>
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  height_lt?: InputMaybe<Scalars['Int']>
  height_lte?: InputMaybe<Scalars['Int']>
  height_not?: InputMaybe<Scalars['Int']>
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  size?: InputMaybe<Scalars['Int']>
  size_exists?: InputMaybe<Scalars['Boolean']>
  size_gt?: InputMaybe<Scalars['Int']>
  size_gte?: InputMaybe<Scalars['Int']>
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  size_lt?: InputMaybe<Scalars['Int']>
  size_lte?: InputMaybe<Scalars['Int']>
  size_not?: InputMaybe<Scalars['Int']>
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  url?: InputMaybe<Scalars['String']>
  url_contains?: InputMaybe<Scalars['String']>
  url_exists?: InputMaybe<Scalars['Boolean']>
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  url_not?: InputMaybe<Scalars['String']>
  url_not_contains?: InputMaybe<Scalars['String']>
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  width?: InputMaybe<Scalars['Int']>
  width_exists?: InputMaybe<Scalars['Boolean']>
  width_gt?: InputMaybe<Scalars['Int']>
  width_gte?: InputMaybe<Scalars['Int']>
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  width_lt?: InputMaybe<Scalars['Int']>
  width_lte?: InputMaybe<Scalars['Int']>
  width_not?: InputMaybe<Scalars['Int']>
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
}

export type AssetLinkingCollections = {
  __typename?: 'AssetLinkingCollections'
  caisiqArticleCollection?: Maybe<CaisiqArticleCollection>
  caisiqDefaultCourseImageSetCollection?: Maybe<CaisiqDefaultCourseImageSetCollection>
  caisiqDefaultFundsThumbnailSetCollection?: Maybe<CaisiqDefaultFundsThumbnailSetCollection>
  caisiqFundThumbnailCollection?: Maybe<CaisiqFundThumbnailCollection>
  caisiqVideoCollection?: Maybe<CaisiqVideoCollection>
  caisiqVideoPlaylistCollection?: Maybe<CaisiqVideoPlaylistCollection>
  callToActionCollection?: Maybe<CallToActionCollection>
  ciqArticleCollection?: Maybe<CiqArticleCollection>
  componentHeroCollection?: Maybe<ComponentHeroCollection>
  componentImageCollection?: Maybe<ComponentImageCollection>
  entryCollection?: Maybe<EntryCollection>
  externalContentCollection?: Maybe<ExternalContentCollection>
  firmCollection?: Maybe<FirmCollection>
  htmlTableCollection?: Maybe<HtmlTableCollection>
  loginScreenCollection?: Maybe<LoginScreenCollection>
  navHeaderThemeCollection?: Maybe<NavHeaderThemeCollection>
  pdfCollection?: Maybe<PdfCollection>
  personCollection?: Maybe<PersonCollection>
  pwsPressReleaseCollection?: Maybe<PwsPressReleaseCollection>
  seoCollection?: Maybe<SeoCollection>
  whitepaperCollection?: Maybe<WhitepaperCollection>
}

export type AssetLinkingCollectionsCaisiqArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsCaisiqDefaultCourseImageSetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsCaisiqDefaultFundsThumbnailSetCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type AssetLinkingCollectionsCaisiqFundThumbnailCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsCaisiqVideoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsCaisiqVideoPlaylistCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsCallToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsCiqArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsComponentHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsComponentImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsExternalContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsHtmlTableCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsLoginScreenCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsNavHeaderThemeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsPdfCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsPersonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsPwsPressReleaseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsSeoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type AssetLinkingCollectionsWhitepaperCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum AssetOrder {
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticle = Entry &
  _Node & {
    __typename?: 'CaisiqArticle'
    _id: Scalars['ID']
    accordionsCollection?: Maybe<CaisiqArticleAccordionsCollection>
    author?: Maybe<CaisiqAuthor>
    authorsCollection?: Maybe<CaisiqArticleAuthorsCollection>
    body?: Maybe<CaisiqArticleBody>
    byCais?: Maybe<Scalars['Boolean']>
    caisVideo?: Maybe<CaisiqVideo>
    callToAction?: Maybe<CallToAction>
    category?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    customDescription?: Maybe<Scalars['String']>
    customTitle?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    displayDate?: Maybe<Scalars['DateTime']>
    firmsCollection?: Maybe<CaisiqArticleFirmsCollection>
    footnotes?: Maybe<CaisiqArticleFootnotes>
    fundProductIds?: Maybe<FundProductId>
    hero?: Maybe<Asset>
    introduction?: Maybe<Scalars['String']>
    introductionRichText?: Maybe<CaisiqArticleIntroductionRichText>
    linkedFrom?: Maybe<CaisiqArticleLinkingCollections>
    name?: Maybe<Scalars['String']>
    pwsCategoryCollection?: Maybe<CaisiqArticlePwsCategoryCollection>
    relatedContent?: Maybe<RelatedContent>
    slug?: Maybe<Scalars['String']>
    sys: Sys
    tagsCollection?: Maybe<CaisiqArticleTagsCollection>
    testBodyWithEntryLinks?: Maybe<CaisiqArticleTestBodyWithEntryLinks>
    testExcludedFirmsCollection?: Maybe<CaisiqArticleTestExcludedFirmsCollection>
    thumbnail?: Maybe<Asset>
    title?: Maybe<Scalars['String']>
  }

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleAccordionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqArticleAccordionsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqArticleDropdownFilter>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleAuthorArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CaisiqAuthorFilter>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleAuthorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqArticleAuthorsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqAuthorFilter>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleBodyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleByCaisArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleCaisVideoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CaisiqVideoFilter>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleCallToActionArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CallToActionFilter>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleCategoryArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleCustomDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleCustomTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleDisplayDateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleFirmsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqArticleFirmsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MemberFirmFilter>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleFootnotesArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleFundProductIdsArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<FundProductIdFilter>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleHeroArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleIntroductionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleIntroductionRichTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticlePwsCategoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqArticlePwsCategoryCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PagePwsCategoryFilter>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleRelatedContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<RelatedContentFilter>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleTagsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqArticleTagsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TagFilter>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleTestBodyWithEntryLinksArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleTestExcludedFirmsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqArticleTestExcludedFirmsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MemberFirmFilter>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleThumbnailArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticle) */
export type CaisiqArticleTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type CaisiqArticleAccordionsCollection = {
  __typename?: 'CaisiqArticleAccordionsCollection'
  items: Array<Maybe<CaisiqArticleDropdown>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CaisiqArticleAccordionsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CaisiqArticleAuthorsCollection = {
  __typename?: 'CaisiqArticleAuthorsCollection'
  items: Array<Maybe<CaisiqAuthor>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CaisiqArticleAuthorsCollectionOrder {
  AuthorNameAsc = 'authorName_ASC',
  AuthorNameDesc = 'authorName_DESC',
  FirmAsc = 'firm_ASC',
  FirmDesc = 'firm_DESC',
  JobTitleAsc = 'jobTitle_ASC',
  JobTitleDesc = 'jobTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CaisiqArticleBody = {
  __typename?: 'CaisiqArticleBody'
  json: Scalars['JSON']
  links: CaisiqArticleBodyLinks
}

export type CaisiqArticleBodyAssets = {
  __typename?: 'CaisiqArticleBodyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type CaisiqArticleBodyEntries = {
  __typename?: 'CaisiqArticleBodyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type CaisiqArticleBodyLinks = {
  __typename?: 'CaisiqArticleBodyLinks'
  assets: CaisiqArticleBodyAssets
  entries: CaisiqArticleBodyEntries
  resources: CaisiqArticleBodyResources
}

export type CaisiqArticleBodyResources = {
  __typename?: 'CaisiqArticleBodyResources'
  block: Array<CaisiqArticleBodyResourcesBlock>
  hyperlink: Array<CaisiqArticleBodyResourcesHyperlink>
  inline: Array<CaisiqArticleBodyResourcesInline>
}

export type CaisiqArticleBodyResourcesBlock = ResourceLink & {
  __typename?: 'CaisiqArticleBodyResourcesBlock'
  sys: ResourceSys
}

export type CaisiqArticleBodyResourcesHyperlink = ResourceLink & {
  __typename?: 'CaisiqArticleBodyResourcesHyperlink'
  sys: ResourceSys
}

export type CaisiqArticleBodyResourcesInline = ResourceLink & {
  __typename?: 'CaisiqArticleBodyResourcesInline'
  sys: ResourceSys
}

export type CaisiqArticleCollection = {
  __typename?: 'CaisiqArticleCollection'
  items: Array<Maybe<CaisiqArticle>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

/** Accordion style component to be used in CE Credit Guide for CAIS IQ [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticleDropdown) */
export type CaisiqArticleDropdown = Entry &
  _Node & {
    __typename?: 'CaisiqArticleDropdown'
    _id: Scalars['ID']
    body?: Maybe<CaisiqArticleDropdownBody>
    contentfulMetadata: ContentfulMetadata
    description?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<CaisiqArticleDropdownLinkingCollections>
    sys: Sys
    title?: Maybe<CaisiqArticleDropdownTitle>
  }

/** Accordion style component to be used in CE Credit Guide for CAIS IQ [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticleDropdown) */
export type CaisiqArticleDropdownBodyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Accordion style component to be used in CE Credit Guide for CAIS IQ [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticleDropdown) */
export type CaisiqArticleDropdownDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Accordion style component to be used in CE Credit Guide for CAIS IQ [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticleDropdown) */
export type CaisiqArticleDropdownLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Accordion style component to be used in CE Credit Guide for CAIS IQ [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqArticleDropdown) */
export type CaisiqArticleDropdownTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type CaisiqArticleDropdownBody = {
  __typename?: 'CaisiqArticleDropdownBody'
  json: Scalars['JSON']
  links: CaisiqArticleDropdownBodyLinks
}

export type CaisiqArticleDropdownBodyAssets = {
  __typename?: 'CaisiqArticleDropdownBodyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type CaisiqArticleDropdownBodyEntries = {
  __typename?: 'CaisiqArticleDropdownBodyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type CaisiqArticleDropdownBodyLinks = {
  __typename?: 'CaisiqArticleDropdownBodyLinks'
  assets: CaisiqArticleDropdownBodyAssets
  entries: CaisiqArticleDropdownBodyEntries
  resources: CaisiqArticleDropdownBodyResources
}

export type CaisiqArticleDropdownBodyResources = {
  __typename?: 'CaisiqArticleDropdownBodyResources'
  block: Array<CaisiqArticleDropdownBodyResourcesBlock>
  hyperlink: Array<CaisiqArticleDropdownBodyResourcesHyperlink>
  inline: Array<CaisiqArticleDropdownBodyResourcesInline>
}

export type CaisiqArticleDropdownBodyResourcesBlock = ResourceLink & {
  __typename?: 'CaisiqArticleDropdownBodyResourcesBlock'
  sys: ResourceSys
}

export type CaisiqArticleDropdownBodyResourcesHyperlink = ResourceLink & {
  __typename?: 'CaisiqArticleDropdownBodyResourcesHyperlink'
  sys: ResourceSys
}

export type CaisiqArticleDropdownBodyResourcesInline = ResourceLink & {
  __typename?: 'CaisiqArticleDropdownBodyResourcesInline'
  sys: ResourceSys
}

export type CaisiqArticleDropdownCollection = {
  __typename?: 'CaisiqArticleDropdownCollection'
  items: Array<Maybe<CaisiqArticleDropdown>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CaisiqArticleDropdownFilter = {
  AND?: InputMaybe<Array<InputMaybe<CaisiqArticleDropdownFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CaisiqArticleDropdownFilter>>>
  body_contains?: InputMaybe<Scalars['String']>
  body_exists?: InputMaybe<Scalars['Boolean']>
  body_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_not_contains?: InputMaybe<Scalars['String']>
}

export type CaisiqArticleDropdownLinkingCollections = {
  __typename?: 'CaisiqArticleDropdownLinkingCollections'
  caisiqArticleCollection?: Maybe<CaisiqArticleCollection>
  ciqArticleCollection?: Maybe<CiqArticleCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type CaisiqArticleDropdownLinkingCollectionsCaisiqArticleCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<CaisiqArticleDropdownLinkingCollectionsCaisiqArticleCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type CaisiqArticleDropdownLinkingCollectionsCiqArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CaisiqArticleDropdownLinkingCollectionsCiqArticleCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqArticleDropdownLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum CaisiqArticleDropdownLinkingCollectionsCaisiqArticleCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CaisiqArticleDropdownLinkingCollectionsCiqArticleCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CaisiqArticleDropdownOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CaisiqArticleDropdownTitle = {
  __typename?: 'CaisiqArticleDropdownTitle'
  json: Scalars['JSON']
  links: CaisiqArticleDropdownTitleLinks
}

export type CaisiqArticleDropdownTitleAssets = {
  __typename?: 'CaisiqArticleDropdownTitleAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type CaisiqArticleDropdownTitleEntries = {
  __typename?: 'CaisiqArticleDropdownTitleEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type CaisiqArticleDropdownTitleLinks = {
  __typename?: 'CaisiqArticleDropdownTitleLinks'
  assets: CaisiqArticleDropdownTitleAssets
  entries: CaisiqArticleDropdownTitleEntries
  resources: CaisiqArticleDropdownTitleResources
}

export type CaisiqArticleDropdownTitleResources = {
  __typename?: 'CaisiqArticleDropdownTitleResources'
  block: Array<CaisiqArticleDropdownTitleResourcesBlock>
  hyperlink: Array<CaisiqArticleDropdownTitleResourcesHyperlink>
  inline: Array<CaisiqArticleDropdownTitleResourcesInline>
}

export type CaisiqArticleDropdownTitleResourcesBlock = ResourceLink & {
  __typename?: 'CaisiqArticleDropdownTitleResourcesBlock'
  sys: ResourceSys
}

export type CaisiqArticleDropdownTitleResourcesHyperlink = ResourceLink & {
  __typename?: 'CaisiqArticleDropdownTitleResourcesHyperlink'
  sys: ResourceSys
}

export type CaisiqArticleDropdownTitleResourcesInline = ResourceLink & {
  __typename?: 'CaisiqArticleDropdownTitleResourcesInline'
  sys: ResourceSys
}

export type CaisiqArticleFilter = {
  AND?: InputMaybe<Array<InputMaybe<CaisiqArticleFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CaisiqArticleFilter>>>
  accordions?: InputMaybe<CfCaisiqArticleDropdownNestedFilter>
  accordionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  author?: InputMaybe<CfCaisiqAuthorNestedFilter>
  author_exists?: InputMaybe<Scalars['Boolean']>
  authors?: InputMaybe<CfCaisiqAuthorNestedFilter>
  authorsCollection_exists?: InputMaybe<Scalars['Boolean']>
  body_contains?: InputMaybe<Scalars['String']>
  body_exists?: InputMaybe<Scalars['Boolean']>
  body_not_contains?: InputMaybe<Scalars['String']>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  caisVideo?: InputMaybe<CfCaisiqVideoNestedFilter>
  caisVideo_exists?: InputMaybe<Scalars['Boolean']>
  callToAction?: InputMaybe<CfCallToActionNestedFilter>
  callToAction_exists?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['String']>
  category_contains?: InputMaybe<Scalars['String']>
  category_exists?: InputMaybe<Scalars['Boolean']>
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category_not?: InputMaybe<Scalars['String']>
  category_not_contains?: InputMaybe<Scalars['String']>
  category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayDate?: InputMaybe<Scalars['DateTime']>
  displayDate_exists?: InputMaybe<Scalars['Boolean']>
  displayDate_gt?: InputMaybe<Scalars['DateTime']>
  displayDate_gte?: InputMaybe<Scalars['DateTime']>
  displayDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  displayDate_lt?: InputMaybe<Scalars['DateTime']>
  displayDate_lte?: InputMaybe<Scalars['DateTime']>
  displayDate_not?: InputMaybe<Scalars['DateTime']>
  displayDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  firms?: InputMaybe<CfMemberFirmNestedFilter>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  footnotes_contains?: InputMaybe<Scalars['String']>
  footnotes_exists?: InputMaybe<Scalars['Boolean']>
  footnotes_not_contains?: InputMaybe<Scalars['String']>
  fundProductIds?: InputMaybe<CfFundProductIdNestedFilter>
  fundProductIds_exists?: InputMaybe<Scalars['Boolean']>
  hero_exists?: InputMaybe<Scalars['Boolean']>
  introduction?: InputMaybe<Scalars['String']>
  introductionRichText_contains?: InputMaybe<Scalars['String']>
  introductionRichText_exists?: InputMaybe<Scalars['Boolean']>
  introductionRichText_not_contains?: InputMaybe<Scalars['String']>
  introduction_contains?: InputMaybe<Scalars['String']>
  introduction_exists?: InputMaybe<Scalars['Boolean']>
  introduction_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  introduction_not?: InputMaybe<Scalars['String']>
  introduction_not_contains?: InputMaybe<Scalars['String']>
  introduction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  pwsCategory?: InputMaybe<CfPagePwsCategoryNestedFilter>
  pwsCategoryCollection_exists?: InputMaybe<Scalars['Boolean']>
  relatedContent?: InputMaybe<CfRelatedContentNestedFilter>
  relatedContent_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tags?: InputMaybe<CfTagNestedFilter>
  tagsCollection_exists?: InputMaybe<Scalars['Boolean']>
  testBodyWithEntryLinks_contains?: InputMaybe<Scalars['String']>
  testBodyWithEntryLinks_exists?: InputMaybe<Scalars['Boolean']>
  testBodyWithEntryLinks_not_contains?: InputMaybe<Scalars['String']>
  testExcludedFirms?: InputMaybe<CfMemberFirmNestedFilter>
  testExcludedFirmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  thumbnail_exists?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CaisiqArticleFirmsCollection = {
  __typename?: 'CaisiqArticleFirmsCollection'
  items: Array<Maybe<MemberFirm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CaisiqArticleFirmsCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CaisiqArticleFootnotes = {
  __typename?: 'CaisiqArticleFootnotes'
  json: Scalars['JSON']
  links: CaisiqArticleFootnotesLinks
}

export type CaisiqArticleFootnotesAssets = {
  __typename?: 'CaisiqArticleFootnotesAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type CaisiqArticleFootnotesEntries = {
  __typename?: 'CaisiqArticleFootnotesEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type CaisiqArticleFootnotesLinks = {
  __typename?: 'CaisiqArticleFootnotesLinks'
  assets: CaisiqArticleFootnotesAssets
  entries: CaisiqArticleFootnotesEntries
  resources: CaisiqArticleFootnotesResources
}

export type CaisiqArticleFootnotesResources = {
  __typename?: 'CaisiqArticleFootnotesResources'
  block: Array<CaisiqArticleFootnotesResourcesBlock>
  hyperlink: Array<CaisiqArticleFootnotesResourcesHyperlink>
  inline: Array<CaisiqArticleFootnotesResourcesInline>
}

export type CaisiqArticleFootnotesResourcesBlock = ResourceLink & {
  __typename?: 'CaisiqArticleFootnotesResourcesBlock'
  sys: ResourceSys
}

export type CaisiqArticleFootnotesResourcesHyperlink = ResourceLink & {
  __typename?: 'CaisiqArticleFootnotesResourcesHyperlink'
  sys: ResourceSys
}

export type CaisiqArticleFootnotesResourcesInline = ResourceLink & {
  __typename?: 'CaisiqArticleFootnotesResourcesInline'
  sys: ResourceSys
}

export type CaisiqArticleIntroductionRichText = {
  __typename?: 'CaisiqArticleIntroductionRichText'
  json: Scalars['JSON']
  links: CaisiqArticleIntroductionRichTextLinks
}

export type CaisiqArticleIntroductionRichTextAssets = {
  __typename?: 'CaisiqArticleIntroductionRichTextAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type CaisiqArticleIntroductionRichTextEntries = {
  __typename?: 'CaisiqArticleIntroductionRichTextEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type CaisiqArticleIntroductionRichTextLinks = {
  __typename?: 'CaisiqArticleIntroductionRichTextLinks'
  assets: CaisiqArticleIntroductionRichTextAssets
  entries: CaisiqArticleIntroductionRichTextEntries
  resources: CaisiqArticleIntroductionRichTextResources
}

export type CaisiqArticleIntroductionRichTextResources = {
  __typename?: 'CaisiqArticleIntroductionRichTextResources'
  block: Array<CaisiqArticleIntroductionRichTextResourcesBlock>
  hyperlink: Array<CaisiqArticleIntroductionRichTextResourcesHyperlink>
  inline: Array<CaisiqArticleIntroductionRichTextResourcesInline>
}

export type CaisiqArticleIntroductionRichTextResourcesBlock = ResourceLink & {
  __typename?: 'CaisiqArticleIntroductionRichTextResourcesBlock'
  sys: ResourceSys
}

export type CaisiqArticleIntroductionRichTextResourcesHyperlink =
  ResourceLink & {
    __typename?: 'CaisiqArticleIntroductionRichTextResourcesHyperlink'
    sys: ResourceSys
  }

export type CaisiqArticleIntroductionRichTextResourcesInline = ResourceLink & {
  __typename?: 'CaisiqArticleIntroductionRichTextResourcesInline'
  sys: ResourceSys
}

export type CaisiqArticleLinkingCollections = {
  __typename?: 'CaisiqArticleLinkingCollections'
  accordionCollection?: Maybe<AccordionCollection>
  entryCollection?: Maybe<EntryCollection>
  featuredContentCollection?: Maybe<FeaturedContentCollection>
  firmCollection?: Maybe<FirmCollection>
  homepageAppMetadataCollection?: Maybe<HomepageAppMetadataCollection>
  linkCollection?: Maybe<LinkCollection>
  memberFirmCollection?: Maybe<MemberFirmCollection>
  pageCollection?: Maybe<PageCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
  pagePwsCategoryCollection?: Maybe<PagePwsCategoryCollection>
  pwsInsightsContentSetCollection?: Maybe<PwsInsightsContentSetCollection>
  relatedContentStrategyCollection?: Maybe<RelatedContentStrategyCollection>
}

export type CaisiqArticleLinkingCollectionsAccordionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqArticleLinkingCollectionsAccordionCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqArticleLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqArticleLinkingCollectionsFeaturedContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CaisiqArticleLinkingCollectionsFeaturedContentCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqArticleLinkingCollectionsFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqArticleLinkingCollectionsFirmCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqArticleLinkingCollectionsHomepageAppMetadataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CaisiqArticleLinkingCollectionsHomepageAppMetadataCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqArticleLinkingCollectionsLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqArticleLinkingCollectionsLinkCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqArticleLinkingCollectionsMemberFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqArticleLinkingCollectionsMemberFirmCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqArticleLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqArticleLinkingCollectionsPageCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqArticleLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqArticleLinkingCollectionsPageLandingCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqArticleLinkingCollectionsPagePwsCategoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CaisiqArticleLinkingCollectionsPagePwsCategoryCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqArticleLinkingCollectionsPwsInsightsContentSetCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<CaisiqArticleLinkingCollectionsPwsInsightsContentSetCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type CaisiqArticleLinkingCollectionsRelatedContentStrategyCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<CaisiqArticleLinkingCollectionsRelatedContentStrategyCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum CaisiqArticleLinkingCollectionsAccordionCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CaisiqArticleLinkingCollectionsFeaturedContentCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqArticleLinkingCollectionsFirmCollectionOrder {
  CeCreditsAvailableAsc = 'ceCreditsAvailable_ASC',
  CeCreditsAvailableDesc = 'ceCreditsAvailable_DESC',
  FirmIdAsc = 'firmId_ASC',
  FirmIdDesc = 'firmId_DESC',
  HeaderTypeAsc = 'headerType_ASC',
  HeaderTypeDesc = 'headerType_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqArticleLinkingCollectionsHomepageAppMetadataCollectionOrder {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqArticleLinkingCollectionsLinkCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  ExternalLinkAsc = 'externalLink_ASC',
  ExternalLinkDesc = 'externalLink_DESC',
  InternalLinkAsc = 'internalLink_ASC',
  InternalLinkDesc = 'internalLink_DESC',
  OpenInNewWindowAsc = 'openInNewWindow_ASC',
  OpenInNewWindowDesc = 'openInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CaisiqArticleLinkingCollectionsMemberFirmCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqArticleLinkingCollectionsPageCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CaisiqArticleLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqArticleLinkingCollectionsPagePwsCategoryCollectionOrder {
  CategoryIdAsc = 'categoryId_ASC',
  CategoryIdDesc = 'categoryId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ShowContentDateAsc = 'showContentDate_ASC',
  ShowContentDateDesc = 'showContentDate_DESC',
  ShowPostCountAsc = 'showPostCount_ASC',
  ShowPostCountDesc = 'showPostCount_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqArticleLinkingCollectionsPwsInsightsContentSetCollectionOrder {
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  LayoutStyleAsc = 'layoutStyle_ASC',
  LayoutStyleDesc = 'layoutStyle_DESC',
  SortingAsc = 'sorting_ASC',
  SortingDesc = 'sorting_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CaisiqArticleLinkingCollectionsRelatedContentStrategyCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SortingAsc = 'sorting_ASC',
  SortingDesc = 'sorting_DESC',
  SourceAsc = 'source_ASC',
  SourceDesc = 'source_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqArticleOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type CaisiqArticlePwsCategoryCollection = {
  __typename?: 'CaisiqArticlePwsCategoryCollection'
  items: Array<Maybe<PagePwsCategory>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CaisiqArticlePwsCategoryCollectionOrder {
  CategoryIdAsc = 'categoryId_ASC',
  CategoryIdDesc = 'categoryId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ShowContentDateAsc = 'showContentDate_ASC',
  ShowContentDateDesc = 'showContentDate_DESC',
  ShowPostCountAsc = 'showPostCount_ASC',
  ShowPostCountDesc = 'showPostCount_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CaisiqArticleTagsCollection = {
  __typename?: 'CaisiqArticleTagsCollection'
  items: Array<Maybe<Tag>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CaisiqArticleTagsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagIdAsc = 'tagId_ASC',
  TagIdDesc = 'tagId_DESC',
}

export type CaisiqArticleTestBodyWithEntryLinks = {
  __typename?: 'CaisiqArticleTestBodyWithEntryLinks'
  json: Scalars['JSON']
  links: CaisiqArticleTestBodyWithEntryLinksLinks
}

export type CaisiqArticleTestBodyWithEntryLinksAssets = {
  __typename?: 'CaisiqArticleTestBodyWithEntryLinksAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type CaisiqArticleTestBodyWithEntryLinksEntries = {
  __typename?: 'CaisiqArticleTestBodyWithEntryLinksEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type CaisiqArticleTestBodyWithEntryLinksLinks = {
  __typename?: 'CaisiqArticleTestBodyWithEntryLinksLinks'
  assets: CaisiqArticleTestBodyWithEntryLinksAssets
  entries: CaisiqArticleTestBodyWithEntryLinksEntries
  resources: CaisiqArticleTestBodyWithEntryLinksResources
}

export type CaisiqArticleTestBodyWithEntryLinksResources = {
  __typename?: 'CaisiqArticleTestBodyWithEntryLinksResources'
  block: Array<CaisiqArticleTestBodyWithEntryLinksResourcesBlock>
  hyperlink: Array<CaisiqArticleTestBodyWithEntryLinksResourcesHyperlink>
  inline: Array<CaisiqArticleTestBodyWithEntryLinksResourcesInline>
}

export type CaisiqArticleTestBodyWithEntryLinksResourcesBlock = ResourceLink & {
  __typename?: 'CaisiqArticleTestBodyWithEntryLinksResourcesBlock'
  sys: ResourceSys
}

export type CaisiqArticleTestBodyWithEntryLinksResourcesHyperlink =
  ResourceLink & {
    __typename?: 'CaisiqArticleTestBodyWithEntryLinksResourcesHyperlink'
    sys: ResourceSys
  }

export type CaisiqArticleTestBodyWithEntryLinksResourcesInline =
  ResourceLink & {
    __typename?: 'CaisiqArticleTestBodyWithEntryLinksResourcesInline'
    sys: ResourceSys
  }

export type CaisiqArticleTestExcludedFirmsCollection = {
  __typename?: 'CaisiqArticleTestExcludedFirmsCollection'
  items: Array<Maybe<MemberFirm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CaisiqArticleTestExcludedFirmsCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** These are the authors that are displayed on site, so do not necessarily represent individuals. An example author may be 'CAIS' [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqAuthor) */
export type CaisiqAuthor = Entry &
  _Node & {
    __typename?: 'CaisiqAuthor'
    _id: Scalars['ID']
    authorName?: Maybe<Scalars['String']>
    avatar?: Maybe<ComponentImage>
    contentfulMetadata: ContentfulMetadata
    firm?: Maybe<Scalars['String']>
    imageSet?: Maybe<Scalars['JSON']>
    jobTitle?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<CaisiqAuthorLinkingCollections>
    linkedin?: Maybe<Link>
    name?: Maybe<Scalars['String']>
    sys: Sys
  }

/** These are the authors that are displayed on site, so do not necessarily represent individuals. An example author may be 'CAIS' [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqAuthor) */
export type CaisiqAuthorAuthorNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** These are the authors that are displayed on site, so do not necessarily represent individuals. An example author may be 'CAIS' [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqAuthor) */
export type CaisiqAuthorAvatarArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentImageFilter>
}

/** These are the authors that are displayed on site, so do not necessarily represent individuals. An example author may be 'CAIS' [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqAuthor) */
export type CaisiqAuthorFirmArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** These are the authors that are displayed on site, so do not necessarily represent individuals. An example author may be 'CAIS' [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqAuthor) */
export type CaisiqAuthorImageSetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** These are the authors that are displayed on site, so do not necessarily represent individuals. An example author may be 'CAIS' [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqAuthor) */
export type CaisiqAuthorJobTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** These are the authors that are displayed on site, so do not necessarily represent individuals. An example author may be 'CAIS' [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqAuthor) */
export type CaisiqAuthorLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** These are the authors that are displayed on site, so do not necessarily represent individuals. An example author may be 'CAIS' [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqAuthor) */
export type CaisiqAuthorLinkedinArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<LinkFilter>
}

/** These are the authors that are displayed on site, so do not necessarily represent individuals. An example author may be 'CAIS' [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqAuthor) */
export type CaisiqAuthorNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type CaisiqAuthorCollection = {
  __typename?: 'CaisiqAuthorCollection'
  items: Array<Maybe<CaisiqAuthor>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CaisiqAuthorFilter = {
  AND?: InputMaybe<Array<InputMaybe<CaisiqAuthorFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CaisiqAuthorFilter>>>
  authorName?: InputMaybe<Scalars['String']>
  authorName_contains?: InputMaybe<Scalars['String']>
  authorName_exists?: InputMaybe<Scalars['Boolean']>
  authorName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  authorName_not?: InputMaybe<Scalars['String']>
  authorName_not_contains?: InputMaybe<Scalars['String']>
  authorName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  avatar?: InputMaybe<CfComponentImageNestedFilter>
  avatar_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  firm?: InputMaybe<Scalars['String']>
  firm_contains?: InputMaybe<Scalars['String']>
  firm_exists?: InputMaybe<Scalars['Boolean']>
  firm_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firm_not?: InputMaybe<Scalars['String']>
  firm_not_contains?: InputMaybe<Scalars['String']>
  firm_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  imageSet_exists?: InputMaybe<Scalars['Boolean']>
  jobTitle?: InputMaybe<Scalars['String']>
  jobTitle_contains?: InputMaybe<Scalars['String']>
  jobTitle_exists?: InputMaybe<Scalars['Boolean']>
  jobTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  jobTitle_not?: InputMaybe<Scalars['String']>
  jobTitle_not_contains?: InputMaybe<Scalars['String']>
  jobTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  linkedin?: InputMaybe<CfLinkNestedFilter>
  linkedin_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CaisiqAuthorLinkingCollections = {
  __typename?: 'CaisiqAuthorLinkingCollections'
  caisiqArticleCollection?: Maybe<CaisiqArticleCollection>
  ciqArticleCollection?: Maybe<CiqArticleCollection>
  entryCollection?: Maybe<EntryCollection>
  pageResourceCollection?: Maybe<PageResourceCollection>
  pwsPressReleaseCollection?: Maybe<PwsPressReleaseCollection>
  relatedContentStrategyCollection?: Maybe<RelatedContentStrategyCollection>
  whitepaperCollection?: Maybe<WhitepaperCollection>
}

export type CaisiqAuthorLinkingCollectionsCaisiqArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CaisiqAuthorLinkingCollectionsCaisiqArticleCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqAuthorLinkingCollectionsCiqArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqAuthorLinkingCollectionsCiqArticleCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqAuthorLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqAuthorLinkingCollectionsPageResourceCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqAuthorLinkingCollectionsPageResourceCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqAuthorLinkingCollectionsPwsPressReleaseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CaisiqAuthorLinkingCollectionsPwsPressReleaseCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqAuthorLinkingCollectionsRelatedContentStrategyCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<CaisiqAuthorLinkingCollectionsRelatedContentStrategyCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type CaisiqAuthorLinkingCollectionsWhitepaperCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqAuthorLinkingCollectionsWhitepaperCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum CaisiqAuthorLinkingCollectionsCaisiqArticleCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CaisiqAuthorLinkingCollectionsCiqArticleCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CaisiqAuthorLinkingCollectionsPageResourceCollectionOrder {
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CaisiqAuthorLinkingCollectionsPwsPressReleaseCollectionOrder {
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  ExternalNewsLinkAsc = 'externalNewsLink_ASC',
  ExternalNewsLinkDesc = 'externalNewsLink_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum CaisiqAuthorLinkingCollectionsRelatedContentStrategyCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SortingAsc = 'sorting_ASC',
  SortingDesc = 'sorting_DESC',
  SourceAsc = 'source_ASC',
  SourceDesc = 'source_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqAuthorLinkingCollectionsWhitepaperCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CaisiqAuthorOrder {
  AuthorNameAsc = 'authorName_ASC',
  AuthorNameDesc = 'authorName_DESC',
  FirmAsc = 'firm_ASC',
  FirmDesc = 'firm_DESC',
  JobTitleAsc = 'jobTitle_ASC',
  JobTitleDesc = 'jobTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** A set of default images to be used as course headers for when the cerego images don't fit. For now we are using the same default set for all firms, so there should only be one of these. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqDefaultCourseImageSet) */
export type CaisiqDefaultCourseImageSet = Entry &
  _Node & {
    __typename?: 'CaisiqDefaultCourseImageSet'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    imagesCollection?: Maybe<AssetCollection>
    linkedFrom?: Maybe<CaisiqDefaultCourseImageSetLinkingCollections>
    name?: Maybe<Scalars['String']>
    sys: Sys
  }

/** A set of default images to be used as course headers for when the cerego images don't fit. For now we are using the same default set for all firms, so there should only be one of these. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqDefaultCourseImageSet) */
export type CaisiqDefaultCourseImageSetImagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** A set of default images to be used as course headers for when the cerego images don't fit. For now we are using the same default set for all firms, so there should only be one of these. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqDefaultCourseImageSet) */
export type CaisiqDefaultCourseImageSetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A set of default images to be used as course headers for when the cerego images don't fit. For now we are using the same default set for all firms, so there should only be one of these. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqDefaultCourseImageSet) */
export type CaisiqDefaultCourseImageSetNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type CaisiqDefaultCourseImageSetCollection = {
  __typename?: 'CaisiqDefaultCourseImageSetCollection'
  items: Array<Maybe<CaisiqDefaultCourseImageSet>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CaisiqDefaultCourseImageSetFilter = {
  AND?: InputMaybe<Array<InputMaybe<CaisiqDefaultCourseImageSetFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CaisiqDefaultCourseImageSetFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  imagesCollection_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CaisiqDefaultCourseImageSetLinkingCollections = {
  __typename?: 'CaisiqDefaultCourseImageSetLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type CaisiqDefaultCourseImageSetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum CaisiqDefaultCourseImageSetOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqDefaultFundsThumbnailSet) */
export type CaisiqDefaultFundsThumbnailSet = Entry &
  _Node & {
    __typename?: 'CaisiqDefaultFundsThumbnailSet'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<CaisiqDefaultFundsThumbnailSetLinkingCollections>
    name?: Maybe<Scalars['String']>
    sys: Sys
    thumbnail54X54Collection?: Maybe<AssetCollection>
    thumbnail92X92Collection?: Maybe<AssetCollection>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqDefaultFundsThumbnailSet) */
export type CaisiqDefaultFundsThumbnailSetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqDefaultFundsThumbnailSet) */
export type CaisiqDefaultFundsThumbnailSetNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqDefaultFundsThumbnailSet) */
export type CaisiqDefaultFundsThumbnailSetThumbnail54X54CollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqDefaultFundsThumbnailSet) */
export type CaisiqDefaultFundsThumbnailSetThumbnail92X92CollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqDefaultFundsThumbnailSetCollection = {
  __typename?: 'CaisiqDefaultFundsThumbnailSetCollection'
  items: Array<Maybe<CaisiqDefaultFundsThumbnailSet>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CaisiqDefaultFundsThumbnailSetFilter = {
  AND?: InputMaybe<Array<InputMaybe<CaisiqDefaultFundsThumbnailSetFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CaisiqDefaultFundsThumbnailSetFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  thumbnail54x54Collection_exists?: InputMaybe<Scalars['Boolean']>
  thumbnail92x92Collection_exists?: InputMaybe<Scalars['Boolean']>
}

export type CaisiqDefaultFundsThumbnailSetLinkingCollections = {
  __typename?: 'CaisiqDefaultFundsThumbnailSetLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type CaisiqDefaultFundsThumbnailSetLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum CaisiqDefaultFundsThumbnailSetOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Represents a CAISIQ thumbnail image for a given fund type [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqFundThumbnail) */
export type CaisiqFundThumbnail = Entry &
  _Node & {
    __typename?: 'CaisiqFundThumbnail'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    fundType?: Maybe<Scalars['String']>
    large?: Maybe<Asset>
    linkedFrom?: Maybe<CaisiqFundThumbnailLinkingCollections>
    name?: Maybe<Scalars['String']>
    small?: Maybe<Asset>
    sys: Sys
  }

/** Represents a CAISIQ thumbnail image for a given fund type [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqFundThumbnail) */
export type CaisiqFundThumbnailFundTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a CAISIQ thumbnail image for a given fund type [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqFundThumbnail) */
export type CaisiqFundThumbnailLargeArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Represents a CAISIQ thumbnail image for a given fund type [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqFundThumbnail) */
export type CaisiqFundThumbnailLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Represents a CAISIQ thumbnail image for a given fund type [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqFundThumbnail) */
export type CaisiqFundThumbnailNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a CAISIQ thumbnail image for a given fund type [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqFundThumbnail) */
export type CaisiqFundThumbnailSmallArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type CaisiqFundThumbnailCollection = {
  __typename?: 'CaisiqFundThumbnailCollection'
  items: Array<Maybe<CaisiqFundThumbnail>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CaisiqFundThumbnailFilter = {
  AND?: InputMaybe<Array<InputMaybe<CaisiqFundThumbnailFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CaisiqFundThumbnailFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  fundType?: InputMaybe<Scalars['String']>
  fundType_contains?: InputMaybe<Scalars['String']>
  fundType_exists?: InputMaybe<Scalars['Boolean']>
  fundType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fundType_not?: InputMaybe<Scalars['String']>
  fundType_not_contains?: InputMaybe<Scalars['String']>
  fundType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  large_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  small_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type CaisiqFundThumbnailLinkingCollections = {
  __typename?: 'CaisiqFundThumbnailLinkingCollections'
  caisiqFundThumbnailSetCollection?: Maybe<CaisiqFundThumbnailSetCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type CaisiqFundThumbnailLinkingCollectionsCaisiqFundThumbnailSetCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<CaisiqFundThumbnailLinkingCollectionsCaisiqFundThumbnailSetCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type CaisiqFundThumbnailLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum CaisiqFundThumbnailLinkingCollectionsCaisiqFundThumbnailSetCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CaisiqFundThumbnailOrder {
  FundTypeAsc = 'fundType_ASC',
  FundTypeDesc = 'fundType_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqFundThumbnailSet) */
export type CaisiqFundThumbnailSet = Entry &
  _Node & {
    __typename?: 'CaisiqFundThumbnailSet'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<CaisiqFundThumbnailSetLinkingCollections>
    sys: Sys
    thumbnailCollection?: Maybe<CaisiqFundThumbnailSetThumbnailCollection>
    title?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqFundThumbnailSet) */
export type CaisiqFundThumbnailSetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqFundThumbnailSet) */
export type CaisiqFundThumbnailSetThumbnailCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqFundThumbnailSetThumbnailCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqFundThumbnailFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqFundThumbnailSet) */
export type CaisiqFundThumbnailSetTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type CaisiqFundThumbnailSetCollection = {
  __typename?: 'CaisiqFundThumbnailSetCollection'
  items: Array<Maybe<CaisiqFundThumbnailSet>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CaisiqFundThumbnailSetFilter = {
  AND?: InputMaybe<Array<InputMaybe<CaisiqFundThumbnailSetFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CaisiqFundThumbnailSetFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
  thumbnail?: InputMaybe<CfCaisiqFundThumbnailNestedFilter>
  thumbnailCollection_exists?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CaisiqFundThumbnailSetLinkingCollections = {
  __typename?: 'CaisiqFundThumbnailSetLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type CaisiqFundThumbnailSetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum CaisiqFundThumbnailSetOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type CaisiqFundThumbnailSetThumbnailCollection = {
  __typename?: 'CaisiqFundThumbnailSetThumbnailCollection'
  items: Array<Maybe<CaisiqFundThumbnail>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CaisiqFundThumbnailSetThumbnailCollectionOrder {
  FundTypeAsc = 'fundType_ASC',
  FundTypeDesc = 'fundType_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqHeroHeader) */
export type CaisiqHeroHeader = Entry &
  _Node & {
    __typename?: 'CaisiqHeroHeader'
    _id: Scalars['ID']
    body?: Maybe<CaisiqHeroHeaderBody>
    contentfulMetadata: ContentfulMetadata
    identifier?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<CaisiqHeroHeaderLinkingCollections>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqHeroHeader) */
export type CaisiqHeroHeaderBodyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqHeroHeader) */
export type CaisiqHeroHeaderIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqHeroHeader) */
export type CaisiqHeroHeaderLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CaisiqHeroHeaderBody = {
  __typename?: 'CaisiqHeroHeaderBody'
  json: Scalars['JSON']
  links: CaisiqHeroHeaderBodyLinks
}

export type CaisiqHeroHeaderBodyAssets = {
  __typename?: 'CaisiqHeroHeaderBodyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type CaisiqHeroHeaderBodyEntries = {
  __typename?: 'CaisiqHeroHeaderBodyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type CaisiqHeroHeaderBodyLinks = {
  __typename?: 'CaisiqHeroHeaderBodyLinks'
  assets: CaisiqHeroHeaderBodyAssets
  entries: CaisiqHeroHeaderBodyEntries
  resources: CaisiqHeroHeaderBodyResources
}

export type CaisiqHeroHeaderBodyResources = {
  __typename?: 'CaisiqHeroHeaderBodyResources'
  block: Array<CaisiqHeroHeaderBodyResourcesBlock>
  hyperlink: Array<CaisiqHeroHeaderBodyResourcesHyperlink>
  inline: Array<CaisiqHeroHeaderBodyResourcesInline>
}

export type CaisiqHeroHeaderBodyResourcesBlock = ResourceLink & {
  __typename?: 'CaisiqHeroHeaderBodyResourcesBlock'
  sys: ResourceSys
}

export type CaisiqHeroHeaderBodyResourcesHyperlink = ResourceLink & {
  __typename?: 'CaisiqHeroHeaderBodyResourcesHyperlink'
  sys: ResourceSys
}

export type CaisiqHeroHeaderBodyResourcesInline = ResourceLink & {
  __typename?: 'CaisiqHeroHeaderBodyResourcesInline'
  sys: ResourceSys
}

export type CaisiqHeroHeaderCollection = {
  __typename?: 'CaisiqHeroHeaderCollection'
  items: Array<Maybe<CaisiqHeroHeader>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CaisiqHeroHeaderFilter = {
  AND?: InputMaybe<Array<InputMaybe<CaisiqHeroHeaderFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CaisiqHeroHeaderFilter>>>
  body_contains?: InputMaybe<Scalars['String']>
  body_exists?: InputMaybe<Scalars['Boolean']>
  body_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  identifier?: InputMaybe<Scalars['String']>
  identifier_contains?: InputMaybe<Scalars['String']>
  identifier_exists?: InputMaybe<Scalars['Boolean']>
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  identifier_not?: InputMaybe<Scalars['String']>
  identifier_not_contains?: InputMaybe<Scalars['String']>
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CaisiqHeroHeaderLinkingCollections = {
  __typename?: 'CaisiqHeroHeaderLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  firmCollection?: Maybe<FirmCollection>
}

export type CaisiqHeroHeaderLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqHeroHeaderLinkingCollectionsFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqHeroHeaderLinkingCollectionsFirmCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum CaisiqHeroHeaderLinkingCollectionsFirmCollectionOrder {
  CeCreditsAvailableAsc = 'ceCreditsAvailable_ASC',
  CeCreditsAvailableDesc = 'ceCreditsAvailable_DESC',
  FirmIdAsc = 'firmId_ASC',
  FirmIdDesc = 'firmId_DESC',
  HeaderTypeAsc = 'headerType_ASC',
  HeaderTypeDesc = 'headerType_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqHeroHeaderNavigateToButton) */
export type CaisiqHeroHeaderNavigateToButton = Entry &
  _Node & {
    __typename?: 'CaisiqHeroHeaderNavigateToButton'
    _id: Scalars['ID']
    buttonText?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<CaisiqHeroHeaderNavigateToButtonLinkingCollections>
    name?: Maybe<Scalars['String']>
    navigateDestination?: Maybe<Scalars['String']>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqHeroHeaderNavigateToButton) */
export type CaisiqHeroHeaderNavigateToButtonButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqHeroHeaderNavigateToButton) */
export type CaisiqHeroHeaderNavigateToButtonLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqHeroHeaderNavigateToButton) */
export type CaisiqHeroHeaderNavigateToButtonNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqHeroHeaderNavigateToButton) */
export type CaisiqHeroHeaderNavigateToButtonNavigateDestinationArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type CaisiqHeroHeaderNavigateToButtonCollection = {
  __typename?: 'CaisiqHeroHeaderNavigateToButtonCollection'
  items: Array<Maybe<CaisiqHeroHeaderNavigateToButton>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CaisiqHeroHeaderNavigateToButtonFilter = {
  AND?: InputMaybe<Array<InputMaybe<CaisiqHeroHeaderNavigateToButtonFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CaisiqHeroHeaderNavigateToButtonFilter>>>
  buttonText?: InputMaybe<Scalars['String']>
  buttonText_contains?: InputMaybe<Scalars['String']>
  buttonText_exists?: InputMaybe<Scalars['Boolean']>
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonText_not?: InputMaybe<Scalars['String']>
  buttonText_not_contains?: InputMaybe<Scalars['String']>
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  navigateDestination?: InputMaybe<Scalars['String']>
  navigateDestination_contains?: InputMaybe<Scalars['String']>
  navigateDestination_exists?: InputMaybe<Scalars['Boolean']>
  navigateDestination_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  navigateDestination_not?: InputMaybe<Scalars['String']>
  navigateDestination_not_contains?: InputMaybe<Scalars['String']>
  navigateDestination_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CaisiqHeroHeaderNavigateToButtonLinkingCollections = {
  __typename?: 'CaisiqHeroHeaderNavigateToButtonLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  firmCollection?: Maybe<FirmCollection>
}

export type CaisiqHeroHeaderNavigateToButtonLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type CaisiqHeroHeaderNavigateToButtonLinkingCollectionsFirmCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<CaisiqHeroHeaderNavigateToButtonLinkingCollectionsFirmCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum CaisiqHeroHeaderNavigateToButtonLinkingCollectionsFirmCollectionOrder {
  CeCreditsAvailableAsc = 'ceCreditsAvailable_ASC',
  CeCreditsAvailableDesc = 'ceCreditsAvailable_DESC',
  FirmIdAsc = 'firmId_ASC',
  FirmIdDesc = 'firmId_DESC',
  HeaderTypeAsc = 'headerType_ASC',
  HeaderTypeDesc = 'headerType_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqHeroHeaderNavigateToButtonOrder {
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NavigateDestinationAsc = 'navigateDestination_ASC',
  NavigateDestinationDesc = 'navigateDestination_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqHeroHeaderOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Allows Experiences (CAISIQ Firms) to specify which tabs are available and in what order they ought to appear [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqHomeOffice) */
export type CaisiqHomeOffice = Entry &
  _Node & {
    __typename?: 'CaisiqHomeOffice'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    description?: Maybe<Scalars['String']>
    disclaimer?: Maybe<Disclaimer>
    hasSeparator?: Maybe<Scalars['Boolean']>
    label?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<CaisiqHomeOfficeLinkingCollections>
    path?: Maybe<Scalars['String']>
    sys: Sys
  }

/** Allows Experiences (CAISIQ Firms) to specify which tabs are available and in what order they ought to appear [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqHomeOffice) */
export type CaisiqHomeOfficeDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Allows Experiences (CAISIQ Firms) to specify which tabs are available and in what order they ought to appear [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqHomeOffice) */
export type CaisiqHomeOfficeDisclaimerArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<DisclaimerFilter>
}

/** Allows Experiences (CAISIQ Firms) to specify which tabs are available and in what order they ought to appear [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqHomeOffice) */
export type CaisiqHomeOfficeHasSeparatorArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Allows Experiences (CAISIQ Firms) to specify which tabs are available and in what order they ought to appear [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqHomeOffice) */
export type CaisiqHomeOfficeLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Allows Experiences (CAISIQ Firms) to specify which tabs are available and in what order they ought to appear [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqHomeOffice) */
export type CaisiqHomeOfficeLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Allows Experiences (CAISIQ Firms) to specify which tabs are available and in what order they ought to appear [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqHomeOffice) */
export type CaisiqHomeOfficePathArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type CaisiqHomeOfficeCollection = {
  __typename?: 'CaisiqHomeOfficeCollection'
  items: Array<Maybe<CaisiqHomeOffice>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CaisiqHomeOfficeFilter = {
  AND?: InputMaybe<Array<InputMaybe<CaisiqHomeOfficeFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CaisiqHomeOfficeFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  disclaimer?: InputMaybe<CfDisclaimerNestedFilter>
  disclaimer_exists?: InputMaybe<Scalars['Boolean']>
  hasSeparator?: InputMaybe<Scalars['Boolean']>
  hasSeparator_exists?: InputMaybe<Scalars['Boolean']>
  hasSeparator_not?: InputMaybe<Scalars['Boolean']>
  label?: InputMaybe<Scalars['String']>
  label_contains?: InputMaybe<Scalars['String']>
  label_exists?: InputMaybe<Scalars['Boolean']>
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  label_not?: InputMaybe<Scalars['String']>
  label_not_contains?: InputMaybe<Scalars['String']>
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  path?: InputMaybe<Scalars['String']>
  path_contains?: InputMaybe<Scalars['String']>
  path_exists?: InputMaybe<Scalars['Boolean']>
  path_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  path_not?: InputMaybe<Scalars['String']>
  path_not_contains?: InputMaybe<Scalars['String']>
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CaisiqHomeOfficeLinkingCollections = {
  __typename?: 'CaisiqHomeOfficeLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  firmCollection?: Maybe<FirmCollection>
}

export type CaisiqHomeOfficeLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqHomeOfficeLinkingCollectionsFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqHomeOfficeLinkingCollectionsFirmCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum CaisiqHomeOfficeLinkingCollectionsFirmCollectionOrder {
  CeCreditsAvailableAsc = 'ceCreditsAvailable_ASC',
  CeCreditsAvailableDesc = 'ceCreditsAvailable_DESC',
  FirmIdAsc = 'firmId_ASC',
  FirmIdDesc = 'firmId_DESC',
  HeaderTypeAsc = 'headerType_ASC',
  HeaderTypeDesc = 'headerType_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqHomeOfficeOrder {
  HasSeparatorAsc = 'hasSeparator_ASC',
  HasSeparatorDesc = 'hasSeparator_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  PathAsc = 'path_ASC',
  PathDesc = 'path_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqJoinCaisCallToAction) */
export type CaisiqJoinCaisCallToAction = Entry &
  _Node & {
    __typename?: 'CaisiqJoinCaisCallToAction'
    _id: Scalars['ID']
    buttonText?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    description?: Maybe<Scalars['String']>
    destination?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<CaisiqJoinCaisCallToActionLinkingCollections>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqJoinCaisCallToAction) */
export type CaisiqJoinCaisCallToActionButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqJoinCaisCallToAction) */
export type CaisiqJoinCaisCallToActionDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqJoinCaisCallToAction) */
export type CaisiqJoinCaisCallToActionDestinationArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqJoinCaisCallToAction) */
export type CaisiqJoinCaisCallToActionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqJoinCaisCallToAction) */
export type CaisiqJoinCaisCallToActionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type CaisiqJoinCaisCallToActionCollection = {
  __typename?: 'CaisiqJoinCaisCallToActionCollection'
  items: Array<Maybe<CaisiqJoinCaisCallToAction>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CaisiqJoinCaisCallToActionFilter = {
  AND?: InputMaybe<Array<InputMaybe<CaisiqJoinCaisCallToActionFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CaisiqJoinCaisCallToActionFilter>>>
  buttonText?: InputMaybe<Scalars['String']>
  buttonText_contains?: InputMaybe<Scalars['String']>
  buttonText_exists?: InputMaybe<Scalars['Boolean']>
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonText_not?: InputMaybe<Scalars['String']>
  buttonText_not_contains?: InputMaybe<Scalars['String']>
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  destination?: InputMaybe<Scalars['String']>
  destination_contains?: InputMaybe<Scalars['String']>
  destination_exists?: InputMaybe<Scalars['Boolean']>
  destination_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  destination_not?: InputMaybe<Scalars['String']>
  destination_not_contains?: InputMaybe<Scalars['String']>
  destination_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CaisiqJoinCaisCallToActionLinkingCollections = {
  __typename?: 'CaisiqJoinCaisCallToActionLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  firmCollection?: Maybe<FirmCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
}

export type CaisiqJoinCaisCallToActionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqJoinCaisCallToActionLinkingCollectionsFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CaisiqJoinCaisCallToActionLinkingCollectionsFirmCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqJoinCaisCallToActionLinkingCollectionsPageLandingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<CaisiqJoinCaisCallToActionLinkingCollectionsPageLandingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum CaisiqJoinCaisCallToActionLinkingCollectionsFirmCollectionOrder {
  CeCreditsAvailableAsc = 'ceCreditsAvailable_ASC',
  CeCreditsAvailableDesc = 'ceCreditsAvailable_DESC',
  FirmIdAsc = 'firmId_ASC',
  FirmIdDesc = 'firmId_DESC',
  HeaderTypeAsc = 'headerType_ASC',
  HeaderTypeDesc = 'headerType_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqJoinCaisCallToActionLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqJoinCaisCallToActionOrder {
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DestinationAsc = 'destination_ASC',
  DestinationDesc = 'destination_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Allows Experiences (CAISIQ Firms) to specify which tabs are available and in what order they ought to appear [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqTab) */
export type CaisiqTab = Entry &
  _Node & {
    __typename?: 'CaisiqTab'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    description?: Maybe<Scalars['String']>
    disclaimer?: Maybe<Disclaimer>
    hasSeparator?: Maybe<Scalars['Boolean']>
    label?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<CaisiqTabLinkingCollections>
    path?: Maybe<Scalars['String']>
    sys: Sys
  }

/** Allows Experiences (CAISIQ Firms) to specify which tabs are available and in what order they ought to appear [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqTab) */
export type CaisiqTabDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Allows Experiences (CAISIQ Firms) to specify which tabs are available and in what order they ought to appear [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqTab) */
export type CaisiqTabDisclaimerArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<DisclaimerFilter>
}

/** Allows Experiences (CAISIQ Firms) to specify which tabs are available and in what order they ought to appear [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqTab) */
export type CaisiqTabHasSeparatorArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Allows Experiences (CAISIQ Firms) to specify which tabs are available and in what order they ought to appear [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqTab) */
export type CaisiqTabLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Allows Experiences (CAISIQ Firms) to specify which tabs are available and in what order they ought to appear [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqTab) */
export type CaisiqTabLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Allows Experiences (CAISIQ Firms) to specify which tabs are available and in what order they ought to appear [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqTab) */
export type CaisiqTabPathArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type CaisiqTabCollection = {
  __typename?: 'CaisiqTabCollection'
  items: Array<Maybe<CaisiqTab>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CaisiqTabFilter = {
  AND?: InputMaybe<Array<InputMaybe<CaisiqTabFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CaisiqTabFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  disclaimer?: InputMaybe<CfDisclaimerNestedFilter>
  disclaimer_exists?: InputMaybe<Scalars['Boolean']>
  hasSeparator?: InputMaybe<Scalars['Boolean']>
  hasSeparator_exists?: InputMaybe<Scalars['Boolean']>
  hasSeparator_not?: InputMaybe<Scalars['Boolean']>
  label?: InputMaybe<Scalars['String']>
  label_contains?: InputMaybe<Scalars['String']>
  label_exists?: InputMaybe<Scalars['Boolean']>
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  label_not?: InputMaybe<Scalars['String']>
  label_not_contains?: InputMaybe<Scalars['String']>
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  path?: InputMaybe<Scalars['String']>
  path_contains?: InputMaybe<Scalars['String']>
  path_exists?: InputMaybe<Scalars['Boolean']>
  path_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  path_not?: InputMaybe<Scalars['String']>
  path_not_contains?: InputMaybe<Scalars['String']>
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CaisiqTabLinkingCollections = {
  __typename?: 'CaisiqTabLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  firmCollection?: Maybe<FirmCollection>
}

export type CaisiqTabLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqTabLinkingCollectionsFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqTabLinkingCollectionsFirmCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum CaisiqTabLinkingCollectionsFirmCollectionOrder {
  CeCreditsAvailableAsc = 'ceCreditsAvailable_ASC',
  CeCreditsAvailableDesc = 'ceCreditsAvailable_DESC',
  FirmIdAsc = 'firmId_ASC',
  FirmIdDesc = 'firmId_DESC',
  HeaderTypeAsc = 'headerType_ASC',
  HeaderTypeDesc = 'headerType_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqTabOrder {
  HasSeparatorAsc = 'hasSeparator_ASC',
  HasSeparatorDesc = 'hasSeparator_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  PathAsc = 'path_ASC',
  PathDesc = 'path_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideo = Entry &
  _Node & {
    __typename?: 'CaisiqVideo'
    _id: Scalars['ID']
    body?: Maybe<CaisiqVideoBody>
    byCais?: Maybe<Scalars['Boolean']>
    category?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    customDescription?: Maybe<Scalars['String']>
    customTitle?: Maybe<Scalars['String']>
    displayDate?: Maybe<Scalars['DateTime']>
    duration?: Maybe<Scalars['Float']>
    eyebrow?: Maybe<Scalars['String']>
    firmsCollection?: Maybe<CaisiqVideoFirmsCollection>
    fundProductIds?: Maybe<FundProductId>
    icon?: Maybe<Scalars['JSON']>
    linkedFrom?: Maybe<CaisiqVideoLinkingCollections>
    muxThumbnailTime?: Maybe<Scalars['Float']>
    muxVideo?: Maybe<Scalars['JSON']>
    name?: Maybe<Scalars['String']>
    notes?: Maybe<Scalars['String']>
    poster?: Maybe<Scalars['JSON']>
    pwsCategoryCollection?: Maybe<CaisiqVideoPwsCategoryCollection>
    summary?: Maybe<Scalars['String']>
    sys: Sys
    tagsCollection?: Maybe<CaisiqVideoTagsCollection>
    testExcludedFirmsCollection?: Maybe<CaisiqVideoTestExcludedFirmsCollection>
    thumbnail?: Maybe<Asset>
    title?: Maybe<Scalars['String']>
    video?: Maybe<Asset>
    wistia?: Maybe<Scalars['JSON']>
  }

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoBodyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoByCaisArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoCategoryArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoCustomDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoCustomTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoDisplayDateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoDurationArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoEyebrowArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoFirmsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqVideoFirmsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MemberFirmFilter>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoFundProductIdsArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<FundProductIdFilter>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoIconArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoMuxThumbnailTimeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoMuxVideoArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoNotesArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoPosterArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoPwsCategoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqVideoPwsCategoryCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PagePwsCategoryFilter>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoSummaryArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoTagsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqVideoTagsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TagFilter>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoTestExcludedFirmsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqVideoTestExcludedFirmsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MemberFirmFilter>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoThumbnailArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoVideoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** A video used in CAISIQ which is usually part of a CAISIQ video playlist [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideo) */
export type CaisiqVideoWistiaArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type CaisiqVideoBody = {
  __typename?: 'CaisiqVideoBody'
  json: Scalars['JSON']
  links: CaisiqVideoBodyLinks
}

export type CaisiqVideoBodyAssets = {
  __typename?: 'CaisiqVideoBodyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type CaisiqVideoBodyEntries = {
  __typename?: 'CaisiqVideoBodyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type CaisiqVideoBodyLinks = {
  __typename?: 'CaisiqVideoBodyLinks'
  assets: CaisiqVideoBodyAssets
  entries: CaisiqVideoBodyEntries
  resources: CaisiqVideoBodyResources
}

export type CaisiqVideoBodyResources = {
  __typename?: 'CaisiqVideoBodyResources'
  block: Array<CaisiqVideoBodyResourcesBlock>
  hyperlink: Array<CaisiqVideoBodyResourcesHyperlink>
  inline: Array<CaisiqVideoBodyResourcesInline>
}

export type CaisiqVideoBodyResourcesBlock = ResourceLink & {
  __typename?: 'CaisiqVideoBodyResourcesBlock'
  sys: ResourceSys
}

export type CaisiqVideoBodyResourcesHyperlink = ResourceLink & {
  __typename?: 'CaisiqVideoBodyResourcesHyperlink'
  sys: ResourceSys
}

export type CaisiqVideoBodyResourcesInline = ResourceLink & {
  __typename?: 'CaisiqVideoBodyResourcesInline'
  sys: ResourceSys
}

export type CaisiqVideoCollection = {
  __typename?: 'CaisiqVideoCollection'
  items: Array<Maybe<CaisiqVideo>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CaisiqVideoFilter = {
  AND?: InputMaybe<Array<InputMaybe<CaisiqVideoFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CaisiqVideoFilter>>>
  body_contains?: InputMaybe<Scalars['String']>
  body_exists?: InputMaybe<Scalars['Boolean']>
  body_not_contains?: InputMaybe<Scalars['String']>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['String']>
  category_contains?: InputMaybe<Scalars['String']>
  category_exists?: InputMaybe<Scalars['Boolean']>
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category_not?: InputMaybe<Scalars['String']>
  category_not_contains?: InputMaybe<Scalars['String']>
  category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayDate?: InputMaybe<Scalars['DateTime']>
  displayDate_exists?: InputMaybe<Scalars['Boolean']>
  displayDate_gt?: InputMaybe<Scalars['DateTime']>
  displayDate_gte?: InputMaybe<Scalars['DateTime']>
  displayDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  displayDate_lt?: InputMaybe<Scalars['DateTime']>
  displayDate_lte?: InputMaybe<Scalars['DateTime']>
  displayDate_not?: InputMaybe<Scalars['DateTime']>
  displayDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  duration?: InputMaybe<Scalars['Float']>
  duration_exists?: InputMaybe<Scalars['Boolean']>
  duration_gt?: InputMaybe<Scalars['Float']>
  duration_gte?: InputMaybe<Scalars['Float']>
  duration_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  duration_lt?: InputMaybe<Scalars['Float']>
  duration_lte?: InputMaybe<Scalars['Float']>
  duration_not?: InputMaybe<Scalars['Float']>
  duration_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  eyebrow?: InputMaybe<Scalars['String']>
  eyebrow_contains?: InputMaybe<Scalars['String']>
  eyebrow_exists?: InputMaybe<Scalars['Boolean']>
  eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eyebrow_not?: InputMaybe<Scalars['String']>
  eyebrow_not_contains?: InputMaybe<Scalars['String']>
  eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firms?: InputMaybe<CfMemberFirmNestedFilter>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  fundProductIds?: InputMaybe<CfFundProductIdNestedFilter>
  fundProductIds_exists?: InputMaybe<Scalars['Boolean']>
  icon_exists?: InputMaybe<Scalars['Boolean']>
  muxThumbnailTime?: InputMaybe<Scalars['Float']>
  muxThumbnailTime_exists?: InputMaybe<Scalars['Boolean']>
  muxThumbnailTime_gt?: InputMaybe<Scalars['Float']>
  muxThumbnailTime_gte?: InputMaybe<Scalars['Float']>
  muxThumbnailTime_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  muxThumbnailTime_lt?: InputMaybe<Scalars['Float']>
  muxThumbnailTime_lte?: InputMaybe<Scalars['Float']>
  muxThumbnailTime_not?: InputMaybe<Scalars['Float']>
  muxThumbnailTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  muxVideo_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  notes?: InputMaybe<Scalars['String']>
  notes_contains?: InputMaybe<Scalars['String']>
  notes_exists?: InputMaybe<Scalars['Boolean']>
  notes_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  notes_not?: InputMaybe<Scalars['String']>
  notes_not_contains?: InputMaybe<Scalars['String']>
  notes_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  poster_exists?: InputMaybe<Scalars['Boolean']>
  pwsCategory?: InputMaybe<CfPagePwsCategoryNestedFilter>
  pwsCategoryCollection_exists?: InputMaybe<Scalars['Boolean']>
  summary?: InputMaybe<Scalars['String']>
  summary_contains?: InputMaybe<Scalars['String']>
  summary_exists?: InputMaybe<Scalars['Boolean']>
  summary_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  summary_not?: InputMaybe<Scalars['String']>
  summary_not_contains?: InputMaybe<Scalars['String']>
  summary_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tags?: InputMaybe<CfTagNestedFilter>
  tagsCollection_exists?: InputMaybe<Scalars['Boolean']>
  testExcludedFirms?: InputMaybe<CfMemberFirmNestedFilter>
  testExcludedFirmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  thumbnail_exists?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  video_exists?: InputMaybe<Scalars['Boolean']>
  wistia_exists?: InputMaybe<Scalars['Boolean']>
}

export type CaisiqVideoFirmsCollection = {
  __typename?: 'CaisiqVideoFirmsCollection'
  items: Array<Maybe<MemberFirm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CaisiqVideoFirmsCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CaisiqVideoLinkingCollections = {
  __typename?: 'CaisiqVideoLinkingCollections'
  caisiqArticleCollection?: Maybe<CaisiqArticleCollection>
  caisiqVideoPlaylistCollection?: Maybe<CaisiqVideoPlaylistCollection>
  ciqArticleCollection?: Maybe<CiqArticleCollection>
  companyCollection?: Maybe<CompanyCollection>
  componentHeroCollection?: Maybe<ComponentHeroCollection>
  componentSectionCollection?: Maybe<ComponentSectionCollection>
  componentTextImageCollection?: Maybe<ComponentTextImageCollection>
  entryCollection?: Maybe<EntryCollection>
  featuredContentCollection?: Maybe<FeaturedContentCollection>
  firmCollection?: Maybe<FirmCollection>
  homepageAppMetadataCollection?: Maybe<HomepageAppMetadataCollection>
  homepageContentSectionCollection?: Maybe<HomepageContentSectionCollection>
  memberFirmCollection?: Maybe<MemberFirmCollection>
  pageCollection?: Maybe<PageCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
  personCollection?: Maybe<PersonCollection>
  presentationCollection?: Maybe<PresentationCollection>
  pwsInsightsContentSetCollection?: Maybe<PwsInsightsContentSetCollection>
  pwsPressReleaseCollection?: Maybe<PwsPressReleaseCollection>
}

export type CaisiqVideoLinkingCollectionsCaisiqArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqVideoLinkingCollectionsCaisiqArticleCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoLinkingCollectionsCaisiqVideoPlaylistCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CaisiqVideoLinkingCollectionsCaisiqVideoPlaylistCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoLinkingCollectionsCiqArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqVideoLinkingCollectionsCiqArticleCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoLinkingCollectionsCompanyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqVideoLinkingCollectionsCompanyCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoLinkingCollectionsComponentHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqVideoLinkingCollectionsComponentHeroCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoLinkingCollectionsComponentSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CaisiqVideoLinkingCollectionsComponentSectionCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoLinkingCollectionsComponentTextImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CaisiqVideoLinkingCollectionsComponentTextImageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoLinkingCollectionsFeaturedContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CaisiqVideoLinkingCollectionsFeaturedContentCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoLinkingCollectionsFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqVideoLinkingCollectionsFirmCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoLinkingCollectionsHomepageAppMetadataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CaisiqVideoLinkingCollectionsHomepageAppMetadataCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoLinkingCollectionsHomepageContentSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<CaisiqVideoLinkingCollectionsHomepageContentSectionCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type CaisiqVideoLinkingCollectionsMemberFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqVideoLinkingCollectionsMemberFirmCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqVideoLinkingCollectionsPageCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqVideoLinkingCollectionsPageLandingCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoLinkingCollectionsPersonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqVideoLinkingCollectionsPersonCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoLinkingCollectionsPresentationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqVideoLinkingCollectionsPresentationCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoLinkingCollectionsPwsInsightsContentSetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CaisiqVideoLinkingCollectionsPwsInsightsContentSetCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoLinkingCollectionsPwsPressReleaseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CaisiqVideoLinkingCollectionsPwsPressReleaseCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum CaisiqVideoLinkingCollectionsCaisiqArticleCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CaisiqVideoLinkingCollectionsCaisiqVideoPlaylistCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  EpisodicAsc = 'episodic_ASC',
  EpisodicDesc = 'episodic_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CaisiqVideoLinkingCollectionsCiqArticleCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CaisiqVideoLinkingCollectionsCompanyCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqVideoLinkingCollectionsComponentHeroCollectionOrder {
  BackgroundAsc = 'background_ASC',
  BackgroundDesc = 'background_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export enum CaisiqVideoLinkingCollectionsComponentSectionCollectionOrder {
  BookmarkIdAsc = 'bookmarkId_ASC',
  BookmarkIdDesc = 'bookmarkId_DESC',
  CardContainerOptionsAsc = 'cardContainerOptions_ASC',
  CardContainerOptionsDesc = 'cardContainerOptions_DESC',
  ComponentIdAsc = 'componentId_ASC',
  ComponentIdDesc = 'componentId_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqVideoLinkingCollectionsComponentTextImageCollectionOrder {
  BackgroundAsc = 'background_ASC',
  BackgroundDesc = 'background_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export enum CaisiqVideoLinkingCollectionsFeaturedContentCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqVideoLinkingCollectionsFirmCollectionOrder {
  CeCreditsAvailableAsc = 'ceCreditsAvailable_ASC',
  CeCreditsAvailableDesc = 'ceCreditsAvailable_DESC',
  FirmIdAsc = 'firmId_ASC',
  FirmIdDesc = 'firmId_DESC',
  HeaderTypeAsc = 'headerType_ASC',
  HeaderTypeDesc = 'headerType_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqVideoLinkingCollectionsHomepageAppMetadataCollectionOrder {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqVideoLinkingCollectionsHomepageContentSectionCollectionOrder {
  BookmarkIdAsc = 'bookmarkId_ASC',
  BookmarkIdDesc = 'bookmarkId_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  RemoveTopPaddingAsc = 'removeTopPadding_ASC',
  RemoveTopPaddingDesc = 'removeTopPadding_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum CaisiqVideoLinkingCollectionsMemberFirmCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqVideoLinkingCollectionsPageCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CaisiqVideoLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqVideoLinkingCollectionsPersonCollectionOrder {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqVideoLinkingCollectionsPresentationCollectionOrder {
  DatetimeAsc = 'datetime_ASC',
  DatetimeDesc = 'datetime_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CaisiqVideoLinkingCollectionsPwsInsightsContentSetCollectionOrder {
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  LayoutStyleAsc = 'layoutStyle_ASC',
  LayoutStyleDesc = 'layoutStyle_DESC',
  SortingAsc = 'sorting_ASC',
  SortingDesc = 'sorting_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CaisiqVideoLinkingCollectionsPwsPressReleaseCollectionOrder {
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  ExternalNewsLinkAsc = 'externalNewsLink_ASC',
  ExternalNewsLinkDesc = 'externalNewsLink_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum CaisiqVideoOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  MuxThumbnailTimeAsc = 'muxThumbnailTime_ASC',
  MuxThumbnailTimeDesc = 'muxThumbnailTime_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** A set of CAISIQ Videos which can be displayed in a carousel and played in a specific order. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideoPlaylist) */
export type CaisiqVideoPlaylist = Entry &
  _Node & {
    __typename?: 'CaisiqVideoPlaylist'
    _id: Scalars['ID']
    byCais?: Maybe<Scalars['Boolean']>
    category?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    customDescription?: Maybe<Scalars['String']>
    customTitle?: Maybe<Scalars['String']>
    displayDate?: Maybe<Scalars['DateTime']>
    episodic?: Maybe<Scalars['Boolean']>
    firmsCollection?: Maybe<CaisiqVideoPlaylistFirmsCollection>
    fundProductIds?: Maybe<FundProductId>
    heroImage?: Maybe<Asset>
    linkedFrom?: Maybe<CaisiqVideoPlaylistLinkingCollections>
    name?: Maybe<Scalars['String']>
    slug?: Maybe<Scalars['String']>
    summary?: Maybe<Scalars['String']>
    sys: Sys
    tagsCollection?: Maybe<CaisiqVideoPlaylistTagsCollection>
    testExcludedFirmsCollection?: Maybe<CaisiqVideoPlaylistTestExcludedFirmsCollection>
    title?: Maybe<Scalars['String']>
    videosCollection?: Maybe<CaisiqVideoPlaylistVideosCollection>
  }

/** A set of CAISIQ Videos which can be displayed in a carousel and played in a specific order. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideoPlaylist) */
export type CaisiqVideoPlaylistByCaisArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A set of CAISIQ Videos which can be displayed in a carousel and played in a specific order. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideoPlaylist) */
export type CaisiqVideoPlaylistCategoryArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A set of CAISIQ Videos which can be displayed in a carousel and played in a specific order. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideoPlaylist) */
export type CaisiqVideoPlaylistCustomDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A set of CAISIQ Videos which can be displayed in a carousel and played in a specific order. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideoPlaylist) */
export type CaisiqVideoPlaylistCustomTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A set of CAISIQ Videos which can be displayed in a carousel and played in a specific order. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideoPlaylist) */
export type CaisiqVideoPlaylistDisplayDateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A set of CAISIQ Videos which can be displayed in a carousel and played in a specific order. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideoPlaylist) */
export type CaisiqVideoPlaylistEpisodicArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A set of CAISIQ Videos which can be displayed in a carousel and played in a specific order. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideoPlaylist) */
export type CaisiqVideoPlaylistFirmsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqVideoPlaylistFirmsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MemberFirmFilter>
}

/** A set of CAISIQ Videos which can be displayed in a carousel and played in a specific order. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideoPlaylist) */
export type CaisiqVideoPlaylistFundProductIdsArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<FundProductIdFilter>
}

/** A set of CAISIQ Videos which can be displayed in a carousel and played in a specific order. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideoPlaylist) */
export type CaisiqVideoPlaylistHeroImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** A set of CAISIQ Videos which can be displayed in a carousel and played in a specific order. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideoPlaylist) */
export type CaisiqVideoPlaylistLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A set of CAISIQ Videos which can be displayed in a carousel and played in a specific order. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideoPlaylist) */
export type CaisiqVideoPlaylistNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A set of CAISIQ Videos which can be displayed in a carousel and played in a specific order. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideoPlaylist) */
export type CaisiqVideoPlaylistSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A set of CAISIQ Videos which can be displayed in a carousel and played in a specific order. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideoPlaylist) */
export type CaisiqVideoPlaylistSummaryArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A set of CAISIQ Videos which can be displayed in a carousel and played in a specific order. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideoPlaylist) */
export type CaisiqVideoPlaylistTagsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqVideoPlaylistTagsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TagFilter>
}

/** A set of CAISIQ Videos which can be displayed in a carousel and played in a specific order. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideoPlaylist) */
export type CaisiqVideoPlaylistTestExcludedFirmsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqVideoPlaylistTestExcludedFirmsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MemberFirmFilter>
}

/** A set of CAISIQ Videos which can be displayed in a carousel and played in a specific order. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideoPlaylist) */
export type CaisiqVideoPlaylistTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A set of CAISIQ Videos which can be displayed in a carousel and played in a specific order. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/caisiqVideoPlaylist) */
export type CaisiqVideoPlaylistVideosCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqVideoPlaylistVideosCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqVideoFilter>
}

export type CaisiqVideoPlaylistCollection = {
  __typename?: 'CaisiqVideoPlaylistCollection'
  items: Array<Maybe<CaisiqVideoPlaylist>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CaisiqVideoPlaylistFilter = {
  AND?: InputMaybe<Array<InputMaybe<CaisiqVideoPlaylistFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CaisiqVideoPlaylistFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['String']>
  category_contains?: InputMaybe<Scalars['String']>
  category_exists?: InputMaybe<Scalars['Boolean']>
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category_not?: InputMaybe<Scalars['String']>
  category_not_contains?: InputMaybe<Scalars['String']>
  category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayDate?: InputMaybe<Scalars['DateTime']>
  displayDate_exists?: InputMaybe<Scalars['Boolean']>
  displayDate_gt?: InputMaybe<Scalars['DateTime']>
  displayDate_gte?: InputMaybe<Scalars['DateTime']>
  displayDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  displayDate_lt?: InputMaybe<Scalars['DateTime']>
  displayDate_lte?: InputMaybe<Scalars['DateTime']>
  displayDate_not?: InputMaybe<Scalars['DateTime']>
  displayDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  episodic?: InputMaybe<Scalars['Boolean']>
  episodic_exists?: InputMaybe<Scalars['Boolean']>
  episodic_not?: InputMaybe<Scalars['Boolean']>
  firms?: InputMaybe<CfMemberFirmNestedFilter>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  fundProductIds?: InputMaybe<CfFundProductIdNestedFilter>
  fundProductIds_exists?: InputMaybe<Scalars['Boolean']>
  heroImage_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  summary?: InputMaybe<Scalars['String']>
  summary_contains?: InputMaybe<Scalars['String']>
  summary_exists?: InputMaybe<Scalars['Boolean']>
  summary_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  summary_not?: InputMaybe<Scalars['String']>
  summary_not_contains?: InputMaybe<Scalars['String']>
  summary_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tags?: InputMaybe<CfTagNestedFilter>
  tagsCollection_exists?: InputMaybe<Scalars['Boolean']>
  testExcludedFirms?: InputMaybe<CfMemberFirmNestedFilter>
  testExcludedFirmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  videos?: InputMaybe<CfCaisiqVideoNestedFilter>
  videosCollection_exists?: InputMaybe<Scalars['Boolean']>
}

export type CaisiqVideoPlaylistFirmsCollection = {
  __typename?: 'CaisiqVideoPlaylistFirmsCollection'
  items: Array<Maybe<MemberFirm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CaisiqVideoPlaylistFirmsCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CaisiqVideoPlaylistLinkingCollections = {
  __typename?: 'CaisiqVideoPlaylistLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  featuredContentCollection?: Maybe<FeaturedContentCollection>
  firmCollection?: Maybe<FirmCollection>
  homepageAppMetadataCollection?: Maybe<HomepageAppMetadataCollection>
  memberFirmCollection?: Maybe<MemberFirmCollection>
  pageCollection?: Maybe<PageCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
  playlistContentSetCollection?: Maybe<PlaylistContentSetCollection>
}

export type CaisiqVideoPlaylistLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoPlaylistLinkingCollectionsFeaturedContentCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<CaisiqVideoPlaylistLinkingCollectionsFeaturedContentCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type CaisiqVideoPlaylistLinkingCollectionsFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqVideoPlaylistLinkingCollectionsFirmCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoPlaylistLinkingCollectionsHomepageAppMetadataCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<CaisiqVideoPlaylistLinkingCollectionsHomepageAppMetadataCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type CaisiqVideoPlaylistLinkingCollectionsMemberFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CaisiqVideoPlaylistLinkingCollectionsMemberFirmCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoPlaylistLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CaisiqVideoPlaylistLinkingCollectionsPageCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoPlaylistLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CaisiqVideoPlaylistLinkingCollectionsPageLandingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CaisiqVideoPlaylistLinkingCollectionsPlaylistContentSetCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<CaisiqVideoPlaylistLinkingCollectionsPlaylistContentSetCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum CaisiqVideoPlaylistLinkingCollectionsFeaturedContentCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqVideoPlaylistLinkingCollectionsFirmCollectionOrder {
  CeCreditsAvailableAsc = 'ceCreditsAvailable_ASC',
  CeCreditsAvailableDesc = 'ceCreditsAvailable_DESC',
  FirmIdAsc = 'firmId_ASC',
  FirmIdDesc = 'firmId_DESC',
  HeaderTypeAsc = 'headerType_ASC',
  HeaderTypeDesc = 'headerType_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqVideoPlaylistLinkingCollectionsHomepageAppMetadataCollectionOrder {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqVideoPlaylistLinkingCollectionsMemberFirmCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqVideoPlaylistLinkingCollectionsPageCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CaisiqVideoPlaylistLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CaisiqVideoPlaylistLinkingCollectionsPlaylistContentSetCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CaisiqVideoPlaylistOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  EpisodicAsc = 'episodic_ASC',
  EpisodicDesc = 'episodic_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type CaisiqVideoPlaylistTagsCollection = {
  __typename?: 'CaisiqVideoPlaylistTagsCollection'
  items: Array<Maybe<Tag>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CaisiqVideoPlaylistTagsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagIdAsc = 'tagId_ASC',
  TagIdDesc = 'tagId_DESC',
}

export type CaisiqVideoPlaylistTestExcludedFirmsCollection = {
  __typename?: 'CaisiqVideoPlaylistTestExcludedFirmsCollection'
  items: Array<Maybe<MemberFirm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CaisiqVideoPlaylistTestExcludedFirmsCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CaisiqVideoPlaylistVideosCollection = {
  __typename?: 'CaisiqVideoPlaylistVideosCollection'
  items: Array<Maybe<CaisiqVideo>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CaisiqVideoPlaylistVideosCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  MuxThumbnailTimeAsc = 'muxThumbnailTime_ASC',
  MuxThumbnailTimeDesc = 'muxThumbnailTime_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type CaisiqVideoPwsCategoryCollection = {
  __typename?: 'CaisiqVideoPwsCategoryCollection'
  items: Array<Maybe<PagePwsCategory>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CaisiqVideoPwsCategoryCollectionOrder {
  CategoryIdAsc = 'categoryId_ASC',
  CategoryIdDesc = 'categoryId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ShowContentDateAsc = 'showContentDate_ASC',
  ShowContentDateDesc = 'showContentDate_DESC',
  ShowPostCountAsc = 'showPostCount_ASC',
  ShowPostCountDesc = 'showPostCount_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CaisiqVideoTagsCollection = {
  __typename?: 'CaisiqVideoTagsCollection'
  items: Array<Maybe<Tag>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CaisiqVideoTagsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagIdAsc = 'tagId_ASC',
  TagIdDesc = 'tagId_DESC',
}

export type CaisiqVideoTestExcludedFirmsCollection = {
  __typename?: 'CaisiqVideoTestExcludedFirmsCollection'
  items: Array<Maybe<MemberFirm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CaisiqVideoTestExcludedFirmsCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Call To Action component that is initially used as a banner on the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/callToAction) */
export type CallToAction = Entry &
  _Node & {
    __typename?: 'CallToAction'
    _id: Scalars['ID']
    buttonText?: Maybe<Scalars['String']>
    byCais?: Maybe<Scalars['Boolean']>
    contentfulMetadata: ContentfulMetadata
    description?: Maybe<Scalars['String']>
    destination?: Maybe<Link>
    firm?: Maybe<MemberFirm>
    id?: Maybe<Scalars['String']>
    imageSet?: Maybe<Scalars['JSON']>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<CallToActionLinkingCollections>
    mediaFile?: Maybe<Asset>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Call To Action component that is initially used as a banner on the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/callToAction) */
export type CallToActionButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Call To Action component that is initially used as a banner on the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/callToAction) */
export type CallToActionByCaisArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Call To Action component that is initially used as a banner on the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/callToAction) */
export type CallToActionDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Call To Action component that is initially used as a banner on the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/callToAction) */
export type CallToActionDestinationArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<LinkFilter>
}

/** Call To Action component that is initially used as a banner on the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/callToAction) */
export type CallToActionFirmArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<MemberFirmFilter>
}

/** Call To Action component that is initially used as a banner on the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/callToAction) */
export type CallToActionIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Call To Action component that is initially used as a banner on the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/callToAction) */
export type CallToActionImageSetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Call To Action component that is initially used as a banner on the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/callToAction) */
export type CallToActionInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Call To Action component that is initially used as a banner on the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/callToAction) */
export type CallToActionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Call To Action component that is initially used as a banner on the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/callToAction) */
export type CallToActionMediaFileArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Call To Action component that is initially used as a banner on the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/callToAction) */
export type CallToActionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type CallToActionCollection = {
  __typename?: 'CallToActionCollection'
  items: Array<Maybe<CallToAction>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CallToActionFilter = {
  AND?: InputMaybe<Array<InputMaybe<CallToActionFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CallToActionFilter>>>
  buttonText?: InputMaybe<Scalars['String']>
  buttonText_contains?: InputMaybe<Scalars['String']>
  buttonText_exists?: InputMaybe<Scalars['Boolean']>
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonText_not?: InputMaybe<Scalars['String']>
  buttonText_not_contains?: InputMaybe<Scalars['String']>
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  destination?: InputMaybe<CfLinkNestedFilter>
  destination_exists?: InputMaybe<Scalars['Boolean']>
  firm?: InputMaybe<CfMemberFirmNestedFilter>
  firm_exists?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['String']>
  id_contains?: InputMaybe<Scalars['String']>
  id_exists?: InputMaybe<Scalars['Boolean']>
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_not?: InputMaybe<Scalars['String']>
  id_not_contains?: InputMaybe<Scalars['String']>
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  imageSet_exists?: InputMaybe<Scalars['Boolean']>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mediaFile_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CallToActionLinkingCollections = {
  __typename?: 'CallToActionLinkingCollections'
  caisiqArticleCollection?: Maybe<CaisiqArticleCollection>
  ciqArticleCollection?: Maybe<CiqArticleCollection>
  componentSectionCollection?: Maybe<ComponentSectionCollection>
  entryCollection?: Maybe<EntryCollection>
  homepageContentSectionCollection?: Maybe<HomepageContentSectionCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
  personCollection?: Maybe<PersonCollection>
  pwsPressReleaseCollection?: Maybe<PwsPressReleaseCollection>
  pwsSiteSettingsCollection?: Maybe<PwsSiteSettingsCollection>
  whitepaperCollection?: Maybe<WhitepaperCollection>
}

export type CallToActionLinkingCollectionsCaisiqArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CallToActionLinkingCollectionsCaisiqArticleCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CallToActionLinkingCollectionsCiqArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CallToActionLinkingCollectionsCiqArticleCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CallToActionLinkingCollectionsComponentSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CallToActionLinkingCollectionsComponentSectionCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CallToActionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CallToActionLinkingCollectionsHomepageContentSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<CallToActionLinkingCollectionsHomepageContentSectionCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type CallToActionLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CallToActionLinkingCollectionsPageLandingCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CallToActionLinkingCollectionsPersonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CallToActionLinkingCollectionsPersonCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CallToActionLinkingCollectionsPwsPressReleaseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CallToActionLinkingCollectionsPwsPressReleaseCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CallToActionLinkingCollectionsPwsSiteSettingsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CallToActionLinkingCollectionsPwsSiteSettingsCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CallToActionLinkingCollectionsWhitepaperCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CallToActionLinkingCollectionsWhitepaperCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum CallToActionLinkingCollectionsCaisiqArticleCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CallToActionLinkingCollectionsCiqArticleCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CallToActionLinkingCollectionsComponentSectionCollectionOrder {
  BookmarkIdAsc = 'bookmarkId_ASC',
  BookmarkIdDesc = 'bookmarkId_DESC',
  CardContainerOptionsAsc = 'cardContainerOptions_ASC',
  CardContainerOptionsDesc = 'cardContainerOptions_DESC',
  ComponentIdAsc = 'componentId_ASC',
  ComponentIdDesc = 'componentId_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CallToActionLinkingCollectionsHomepageContentSectionCollectionOrder {
  BookmarkIdAsc = 'bookmarkId_ASC',
  BookmarkIdDesc = 'bookmarkId_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  RemoveTopPaddingAsc = 'removeTopPadding_ASC',
  RemoveTopPaddingDesc = 'removeTopPadding_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum CallToActionLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CallToActionLinkingCollectionsPersonCollectionOrder {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CallToActionLinkingCollectionsPwsPressReleaseCollectionOrder {
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  ExternalNewsLinkAsc = 'externalNewsLink_ASC',
  ExternalNewsLinkDesc = 'externalNewsLink_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum CallToActionLinkingCollectionsPwsSiteSettingsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CallToActionLinkingCollectionsWhitepaperCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CallToActionOrder {
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** A chart image with text fields for; title, source and description. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/chart) */
export type Chart = Entry &
  _Node & {
    __typename?: 'Chart'
    _id: Scalars['ID']
    chartImage?: Maybe<ComponentImage>
    contentfulMetadata: ContentfulMetadata
    footnote?: Maybe<ChartFootnote>
    imageToReplace?: Maybe<ComponentImage>
    linkedFrom?: Maybe<ChartLinkingCollections>
    longDescription?: Maybe<ChartLongDescription>
    longSource?: Maybe<Scalars['String']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** A chart image with text fields for; title, source and description. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/chart) */
export type ChartChartImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentImageFilter>
}

/** A chart image with text fields for; title, source and description. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/chart) */
export type ChartFootnoteArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A chart image with text fields for; title, source and description. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/chart) */
export type ChartImageToReplaceArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentImageFilter>
}

/** A chart image with text fields for; title, source and description. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/chart) */
export type ChartLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A chart image with text fields for; title, source and description. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/chart) */
export type ChartLongDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A chart image with text fields for; title, source and description. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/chart) */
export type ChartLongSourceArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A chart image with text fields for; title, source and description. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/chart) */
export type ChartTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ChartCollection = {
  __typename?: 'ChartCollection'
  items: Array<Maybe<Chart>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ChartFilter = {
  AND?: InputMaybe<Array<InputMaybe<ChartFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ChartFilter>>>
  chartImage?: InputMaybe<CfComponentImageNestedFilter>
  chartImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  footnote_contains?: InputMaybe<Scalars['String']>
  footnote_exists?: InputMaybe<Scalars['Boolean']>
  footnote_not_contains?: InputMaybe<Scalars['String']>
  imageToReplace?: InputMaybe<CfComponentImageNestedFilter>
  imageToReplace_exists?: InputMaybe<Scalars['Boolean']>
  longDescription_contains?: InputMaybe<Scalars['String']>
  longDescription_exists?: InputMaybe<Scalars['Boolean']>
  longDescription_not_contains?: InputMaybe<Scalars['String']>
  longSource?: InputMaybe<Scalars['String']>
  longSource_contains?: InputMaybe<Scalars['String']>
  longSource_exists?: InputMaybe<Scalars['Boolean']>
  longSource_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  longSource_not?: InputMaybe<Scalars['String']>
  longSource_not_contains?: InputMaybe<Scalars['String']>
  longSource_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ChartFootnote = {
  __typename?: 'ChartFootnote'
  json: Scalars['JSON']
  links: ChartFootnoteLinks
}

export type ChartFootnoteAssets = {
  __typename?: 'ChartFootnoteAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ChartFootnoteEntries = {
  __typename?: 'ChartFootnoteEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ChartFootnoteLinks = {
  __typename?: 'ChartFootnoteLinks'
  assets: ChartFootnoteAssets
  entries: ChartFootnoteEntries
  resources: ChartFootnoteResources
}

export type ChartFootnoteResources = {
  __typename?: 'ChartFootnoteResources'
  block: Array<ChartFootnoteResourcesBlock>
  hyperlink: Array<ChartFootnoteResourcesHyperlink>
  inline: Array<ChartFootnoteResourcesInline>
}

export type ChartFootnoteResourcesBlock = ResourceLink & {
  __typename?: 'ChartFootnoteResourcesBlock'
  sys: ResourceSys
}

export type ChartFootnoteResourcesHyperlink = ResourceLink & {
  __typename?: 'ChartFootnoteResourcesHyperlink'
  sys: ResourceSys
}

export type ChartFootnoteResourcesInline = ResourceLink & {
  __typename?: 'ChartFootnoteResourcesInline'
  sys: ResourceSys
}

export type ChartLinkingCollections = {
  __typename?: 'ChartLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type ChartLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ChartLongDescription = {
  __typename?: 'ChartLongDescription'
  json: Scalars['JSON']
  links: ChartLongDescriptionLinks
}

export type ChartLongDescriptionAssets = {
  __typename?: 'ChartLongDescriptionAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ChartLongDescriptionEntries = {
  __typename?: 'ChartLongDescriptionEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ChartLongDescriptionLinks = {
  __typename?: 'ChartLongDescriptionLinks'
  assets: ChartLongDescriptionAssets
  entries: ChartLongDescriptionEntries
  resources: ChartLongDescriptionResources
}

export type ChartLongDescriptionResources = {
  __typename?: 'ChartLongDescriptionResources'
  block: Array<ChartLongDescriptionResourcesBlock>
  hyperlink: Array<ChartLongDescriptionResourcesHyperlink>
  inline: Array<ChartLongDescriptionResourcesInline>
}

export type ChartLongDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'ChartLongDescriptionResourcesBlock'
  sys: ResourceSys
}

export type ChartLongDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'ChartLongDescriptionResourcesHyperlink'
  sys: ResourceSys
}

export type ChartLongDescriptionResourcesInline = ResourceLink & {
  __typename?: 'ChartLongDescriptionResourcesInline'
  sys: ResourceSys
}

export enum ChartOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/childFirm) */
export type ChildFirm = Entry &
  _Node & {
    __typename?: 'ChildFirm'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    firmId?: Maybe<Scalars['String']>
    firmName?: Maybe<Scalars['String']>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ChildFirmLinkingCollections>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/childFirm) */
export type ChildFirmFirmIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/childFirm) */
export type ChildFirmFirmNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/childFirm) */
export type ChildFirmInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/childFirm) */
export type ChildFirmLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ChildFirmCollection = {
  __typename?: 'ChildFirmCollection'
  items: Array<Maybe<ChildFirm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ChildFirmFilter = {
  AND?: InputMaybe<Array<InputMaybe<ChildFirmFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ChildFirmFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  firmId?: InputMaybe<Scalars['String']>
  firmId_contains?: InputMaybe<Scalars['String']>
  firmId_exists?: InputMaybe<Scalars['Boolean']>
  firmId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmId_not?: InputMaybe<Scalars['String']>
  firmId_not_contains?: InputMaybe<Scalars['String']>
  firmId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmName?: InputMaybe<Scalars['String']>
  firmName_contains?: InputMaybe<Scalars['String']>
  firmName_exists?: InputMaybe<Scalars['Boolean']>
  firmName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmName_not?: InputMaybe<Scalars['String']>
  firmName_not_contains?: InputMaybe<Scalars['String']>
  firmName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ChildFirmLinkingCollections = {
  __typename?: 'ChildFirmLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  parentFirmCollection?: Maybe<ParentFirmCollection>
}

export type ChildFirmLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ChildFirmLinkingCollectionsParentFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ChildFirmLinkingCollectionsParentFirmCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ChildFirmLinkingCollectionsParentFirmCollectionOrder {
  FirmIdAsc = 'firmId_ASC',
  FirmIdDesc = 'firmId_DESC',
  FirmNameAsc = 'firmName_ASC',
  FirmNameDesc = 'firmName_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ChildFirmOrder {
  FirmIdAsc = 'firmId_ASC',
  FirmIdDesc = 'firmId_DESC',
  FirmNameAsc = 'firmName_ASC',
  FirmNameDesc = 'firmName_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticle = Entry &
  _Node & {
    __typename?: 'CiqArticle'
    _id: Scalars['ID']
    accordionsCollection?: Maybe<CiqArticleAccordionsCollection>
    author?: Maybe<CaisiqAuthor>
    authorsCollection?: Maybe<CiqArticleAuthorsCollection>
    body?: Maybe<CiqArticleBody>
    caisVideo?: Maybe<CaisiqVideo>
    callToAction?: Maybe<CallToAction>
    category?: Maybe<Scalars['String']>
    cloudinaryImage?: Maybe<Scalars['JSON']>
    contentfulMetadata: ContentfulMetadata
    description?: Maybe<Scalars['String']>
    displayDate?: Maybe<Scalars['DateTime']>
    footnotes?: Maybe<CiqArticleFootnotes>
    fundProductIds?: Maybe<FundProductId>
    hero?: Maybe<Asset>
    introduction?: Maybe<Scalars['String']>
    introductionRichText?: Maybe<CiqArticleIntroductionRichText>
    linkedFrom?: Maybe<CiqArticleLinkingCollections>
    name?: Maybe<Scalars['String']>
    pwsCategoryCollection?: Maybe<CiqArticlePwsCategoryCollection>
    relatedContent?: Maybe<RelatedContent>
    slug?: Maybe<Scalars['String']>
    sys: Sys
    tagsCollection?: Maybe<CiqArticleTagsCollection>
    testBodyWithEntryLinks?: Maybe<CiqArticleTestBodyWithEntryLinks>
    thumbnail?: Maybe<Asset>
    title?: Maybe<Scalars['String']>
    topicsCollection?: Maybe<CiqArticleTopicsCollection>
  }

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleAccordionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CiqArticleAccordionsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqArticleDropdownFilter>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleAuthorArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CaisiqAuthorFilter>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleAuthorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CiqArticleAuthorsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqAuthorFilter>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleBodyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleCaisVideoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CaisiqVideoFilter>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleCallToActionArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CallToActionFilter>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleCategoryArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleCloudinaryImageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleDisplayDateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleFootnotesArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleFundProductIdsArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<FundProductIdFilter>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleHeroArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleIntroductionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleIntroductionRichTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticlePwsCategoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CiqArticlePwsCategoryCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PagePwsCategoryFilter>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleRelatedContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<RelatedContentFilter>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleTagsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CiqArticleTagsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TagFilter>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleTestBodyWithEntryLinksArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleThumbnailArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Articles, initially for the SALT conference, you can allow a firm to view these articles by checking the 'Can view articles' option in their CAISIQ Firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/ciqArticle) */
export type CiqArticleTopicsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CiqArticleAccordionsCollection = {
  __typename?: 'CiqArticleAccordionsCollection'
  items: Array<Maybe<CaisiqArticleDropdown>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CiqArticleAccordionsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CiqArticleAuthorsCollection = {
  __typename?: 'CiqArticleAuthorsCollection'
  items: Array<Maybe<CaisiqAuthor>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CiqArticleAuthorsCollectionOrder {
  AuthorNameAsc = 'authorName_ASC',
  AuthorNameDesc = 'authorName_DESC',
  FirmAsc = 'firm_ASC',
  FirmDesc = 'firm_DESC',
  JobTitleAsc = 'jobTitle_ASC',
  JobTitleDesc = 'jobTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CiqArticleBody = {
  __typename?: 'CiqArticleBody'
  json: Scalars['JSON']
  links: CiqArticleBodyLinks
}

export type CiqArticleBodyAssets = {
  __typename?: 'CiqArticleBodyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type CiqArticleBodyEntries = {
  __typename?: 'CiqArticleBodyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type CiqArticleBodyLinks = {
  __typename?: 'CiqArticleBodyLinks'
  assets: CiqArticleBodyAssets
  entries: CiqArticleBodyEntries
  resources: CiqArticleBodyResources
}

export type CiqArticleBodyResources = {
  __typename?: 'CiqArticleBodyResources'
  block: Array<CiqArticleBodyResourcesBlock>
  hyperlink: Array<CiqArticleBodyResourcesHyperlink>
  inline: Array<CiqArticleBodyResourcesInline>
}

export type CiqArticleBodyResourcesBlock = ResourceLink & {
  __typename?: 'CiqArticleBodyResourcesBlock'
  sys: ResourceSys
}

export type CiqArticleBodyResourcesHyperlink = ResourceLink & {
  __typename?: 'CiqArticleBodyResourcesHyperlink'
  sys: ResourceSys
}

export type CiqArticleBodyResourcesInline = ResourceLink & {
  __typename?: 'CiqArticleBodyResourcesInline'
  sys: ResourceSys
}

export type CiqArticleCollection = {
  __typename?: 'CiqArticleCollection'
  items: Array<Maybe<CiqArticle>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CiqArticleFilter = {
  AND?: InputMaybe<Array<InputMaybe<CiqArticleFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CiqArticleFilter>>>
  accordions?: InputMaybe<CfCaisiqArticleDropdownNestedFilter>
  accordionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  author?: InputMaybe<CfCaisiqAuthorNestedFilter>
  author_exists?: InputMaybe<Scalars['Boolean']>
  authors?: InputMaybe<CfCaisiqAuthorNestedFilter>
  authorsCollection_exists?: InputMaybe<Scalars['Boolean']>
  body_contains?: InputMaybe<Scalars['String']>
  body_exists?: InputMaybe<Scalars['Boolean']>
  body_not_contains?: InputMaybe<Scalars['String']>
  caisVideo?: InputMaybe<CfCaisiqVideoNestedFilter>
  caisVideo_exists?: InputMaybe<Scalars['Boolean']>
  callToAction?: InputMaybe<CfCallToActionNestedFilter>
  callToAction_exists?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['String']>
  category_contains?: InputMaybe<Scalars['String']>
  category_exists?: InputMaybe<Scalars['Boolean']>
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category_not?: InputMaybe<Scalars['String']>
  category_not_contains?: InputMaybe<Scalars['String']>
  category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  cloudinaryImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayDate?: InputMaybe<Scalars['DateTime']>
  displayDate_exists?: InputMaybe<Scalars['Boolean']>
  displayDate_gt?: InputMaybe<Scalars['DateTime']>
  displayDate_gte?: InputMaybe<Scalars['DateTime']>
  displayDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  displayDate_lt?: InputMaybe<Scalars['DateTime']>
  displayDate_lte?: InputMaybe<Scalars['DateTime']>
  displayDate_not?: InputMaybe<Scalars['DateTime']>
  displayDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  footnotes_contains?: InputMaybe<Scalars['String']>
  footnotes_exists?: InputMaybe<Scalars['Boolean']>
  footnotes_not_contains?: InputMaybe<Scalars['String']>
  fundProductIds?: InputMaybe<CfFundProductIdNestedFilter>
  fundProductIds_exists?: InputMaybe<Scalars['Boolean']>
  hero_exists?: InputMaybe<Scalars['Boolean']>
  introduction?: InputMaybe<Scalars['String']>
  introductionRichText_contains?: InputMaybe<Scalars['String']>
  introductionRichText_exists?: InputMaybe<Scalars['Boolean']>
  introductionRichText_not_contains?: InputMaybe<Scalars['String']>
  introduction_contains?: InputMaybe<Scalars['String']>
  introduction_exists?: InputMaybe<Scalars['Boolean']>
  introduction_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  introduction_not?: InputMaybe<Scalars['String']>
  introduction_not_contains?: InputMaybe<Scalars['String']>
  introduction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  pwsCategory?: InputMaybe<CfPagePwsCategoryNestedFilter>
  pwsCategoryCollection_exists?: InputMaybe<Scalars['Boolean']>
  relatedContent?: InputMaybe<CfRelatedContentNestedFilter>
  relatedContent_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tags?: InputMaybe<CfTagNestedFilter>
  tagsCollection_exists?: InputMaybe<Scalars['Boolean']>
  testBodyWithEntryLinks_contains?: InputMaybe<Scalars['String']>
  testBodyWithEntryLinks_exists?: InputMaybe<Scalars['Boolean']>
  testBodyWithEntryLinks_not_contains?: InputMaybe<Scalars['String']>
  thumbnail_exists?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  topicsCollection_exists?: InputMaybe<Scalars['Boolean']>
}

export type CiqArticleFootnotes = {
  __typename?: 'CiqArticleFootnotes'
  json: Scalars['JSON']
  links: CiqArticleFootnotesLinks
}

export type CiqArticleFootnotesAssets = {
  __typename?: 'CiqArticleFootnotesAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type CiqArticleFootnotesEntries = {
  __typename?: 'CiqArticleFootnotesEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type CiqArticleFootnotesLinks = {
  __typename?: 'CiqArticleFootnotesLinks'
  assets: CiqArticleFootnotesAssets
  entries: CiqArticleFootnotesEntries
  resources: CiqArticleFootnotesResources
}

export type CiqArticleFootnotesResources = {
  __typename?: 'CiqArticleFootnotesResources'
  block: Array<CiqArticleFootnotesResourcesBlock>
  hyperlink: Array<CiqArticleFootnotesResourcesHyperlink>
  inline: Array<CiqArticleFootnotesResourcesInline>
}

export type CiqArticleFootnotesResourcesBlock = ResourceLink & {
  __typename?: 'CiqArticleFootnotesResourcesBlock'
  sys: ResourceSys
}

export type CiqArticleFootnotesResourcesHyperlink = ResourceLink & {
  __typename?: 'CiqArticleFootnotesResourcesHyperlink'
  sys: ResourceSys
}

export type CiqArticleFootnotesResourcesInline = ResourceLink & {
  __typename?: 'CiqArticleFootnotesResourcesInline'
  sys: ResourceSys
}

export type CiqArticleIntroductionRichText = {
  __typename?: 'CiqArticleIntroductionRichText'
  json: Scalars['JSON']
  links: CiqArticleIntroductionRichTextLinks
}

export type CiqArticleIntroductionRichTextAssets = {
  __typename?: 'CiqArticleIntroductionRichTextAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type CiqArticleIntroductionRichTextEntries = {
  __typename?: 'CiqArticleIntroductionRichTextEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type CiqArticleIntroductionRichTextLinks = {
  __typename?: 'CiqArticleIntroductionRichTextLinks'
  assets: CiqArticleIntroductionRichTextAssets
  entries: CiqArticleIntroductionRichTextEntries
  resources: CiqArticleIntroductionRichTextResources
}

export type CiqArticleIntroductionRichTextResources = {
  __typename?: 'CiqArticleIntroductionRichTextResources'
  block: Array<CiqArticleIntroductionRichTextResourcesBlock>
  hyperlink: Array<CiqArticleIntroductionRichTextResourcesHyperlink>
  inline: Array<CiqArticleIntroductionRichTextResourcesInline>
}

export type CiqArticleIntroductionRichTextResourcesBlock = ResourceLink & {
  __typename?: 'CiqArticleIntroductionRichTextResourcesBlock'
  sys: ResourceSys
}

export type CiqArticleIntroductionRichTextResourcesHyperlink = ResourceLink & {
  __typename?: 'CiqArticleIntroductionRichTextResourcesHyperlink'
  sys: ResourceSys
}

export type CiqArticleIntroductionRichTextResourcesInline = ResourceLink & {
  __typename?: 'CiqArticleIntroductionRichTextResourcesInline'
  sys: ResourceSys
}

export type CiqArticleLinkingCollections = {
  __typename?: 'CiqArticleLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type CiqArticleLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum CiqArticleOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type CiqArticlePwsCategoryCollection = {
  __typename?: 'CiqArticlePwsCategoryCollection'
  items: Array<Maybe<PagePwsCategory>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CiqArticlePwsCategoryCollectionOrder {
  CategoryIdAsc = 'categoryId_ASC',
  CategoryIdDesc = 'categoryId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ShowContentDateAsc = 'showContentDate_ASC',
  ShowContentDateDesc = 'showContentDate_DESC',
  ShowPostCountAsc = 'showPostCount_ASC',
  ShowPostCountDesc = 'showPostCount_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CiqArticleTagsCollection = {
  __typename?: 'CiqArticleTagsCollection'
  items: Array<Maybe<Tag>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CiqArticleTagsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagIdAsc = 'tagId_ASC',
  TagIdDesc = 'tagId_DESC',
}

export type CiqArticleTestBodyWithEntryLinks = {
  __typename?: 'CiqArticleTestBodyWithEntryLinks'
  json: Scalars['JSON']
  links: CiqArticleTestBodyWithEntryLinksLinks
}

export type CiqArticleTestBodyWithEntryLinksAssets = {
  __typename?: 'CiqArticleTestBodyWithEntryLinksAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type CiqArticleTestBodyWithEntryLinksEntries = {
  __typename?: 'CiqArticleTestBodyWithEntryLinksEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type CiqArticleTestBodyWithEntryLinksLinks = {
  __typename?: 'CiqArticleTestBodyWithEntryLinksLinks'
  assets: CiqArticleTestBodyWithEntryLinksAssets
  entries: CiqArticleTestBodyWithEntryLinksEntries
  resources: CiqArticleTestBodyWithEntryLinksResources
}

export type CiqArticleTestBodyWithEntryLinksResources = {
  __typename?: 'CiqArticleTestBodyWithEntryLinksResources'
  block: Array<CiqArticleTestBodyWithEntryLinksResourcesBlock>
  hyperlink: Array<CiqArticleTestBodyWithEntryLinksResourcesHyperlink>
  inline: Array<CiqArticleTestBodyWithEntryLinksResourcesInline>
}

export type CiqArticleTestBodyWithEntryLinksResourcesBlock = ResourceLink & {
  __typename?: 'CiqArticleTestBodyWithEntryLinksResourcesBlock'
  sys: ResourceSys
}

export type CiqArticleTestBodyWithEntryLinksResourcesHyperlink =
  ResourceLink & {
    __typename?: 'CiqArticleTestBodyWithEntryLinksResourcesHyperlink'
    sys: ResourceSys
  }

export type CiqArticleTestBodyWithEntryLinksResourcesInline = ResourceLink & {
  __typename?: 'CiqArticleTestBodyWithEntryLinksResourcesInline'
  sys: ResourceSys
}

export type CiqArticleTopicsCollection = {
  __typename?: 'CiqArticleTopicsCollection'
  items: Array<Maybe<Entry>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

/** Used, for example, to say where a Person works [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/company) */
export type Company = Entry &
  _Node & {
    __typename?: 'Company'
    _id: Scalars['ID']
    about?: Maybe<CompanyAbout>
    caisVideo?: Maybe<CaisiqVideo>
    colorImageSet?: Maybe<Scalars['JSON']>
    contentfulMetadata: ContentfulMetadata
    disclosure?: Maybe<CompanyDisclosure>
    displayName?: Maybe<Scalars['String']>
    imageSet?: Maybe<Scalars['JSON']>
    linkedFrom?: Maybe<CompanyLinkingCollections>
    linkedin?: Maybe<Link>
    name?: Maybe<Scalars['String']>
    shortDescription?: Maybe<CompanyShortDescription>
    stats?: Maybe<CorporateStats>
    subtitle?: Maybe<Scalars['String']>
    sys: Sys
    twitter?: Maybe<Link>
    website?: Maybe<Link>
  }

/** Used, for example, to say where a Person works [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/company) */
export type CompanyAboutArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Used, for example, to say where a Person works [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/company) */
export type CompanyCaisVideoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CaisiqVideoFilter>
}

/** Used, for example, to say where a Person works [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/company) */
export type CompanyColorImageSetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Used, for example, to say where a Person works [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/company) */
export type CompanyDisclosureArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Used, for example, to say where a Person works [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/company) */
export type CompanyDisplayNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Used, for example, to say where a Person works [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/company) */
export type CompanyImageSetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Used, for example, to say where a Person works [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/company) */
export type CompanyLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Used, for example, to say where a Person works [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/company) */
export type CompanyLinkedinArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<LinkFilter>
}

/** Used, for example, to say where a Person works [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/company) */
export type CompanyNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Used, for example, to say where a Person works [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/company) */
export type CompanyShortDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Used, for example, to say where a Person works [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/company) */
export type CompanyStatsArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CorporateStatsFilter>
}

/** Used, for example, to say where a Person works [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/company) */
export type CompanySubtitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Used, for example, to say where a Person works [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/company) */
export type CompanyTwitterArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<LinkFilter>
}

/** Used, for example, to say where a Person works [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/company) */
export type CompanyWebsiteArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<LinkFilter>
}

export type CompanyAbout = {
  __typename?: 'CompanyAbout'
  json: Scalars['JSON']
  links: CompanyAboutLinks
}

export type CompanyAboutAssets = {
  __typename?: 'CompanyAboutAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type CompanyAboutEntries = {
  __typename?: 'CompanyAboutEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type CompanyAboutLinks = {
  __typename?: 'CompanyAboutLinks'
  assets: CompanyAboutAssets
  entries: CompanyAboutEntries
  resources: CompanyAboutResources
}

export type CompanyAboutResources = {
  __typename?: 'CompanyAboutResources'
  block: Array<CompanyAboutResourcesBlock>
  hyperlink: Array<CompanyAboutResourcesHyperlink>
  inline: Array<CompanyAboutResourcesInline>
}

export type CompanyAboutResourcesBlock = ResourceLink & {
  __typename?: 'CompanyAboutResourcesBlock'
  sys: ResourceSys
}

export type CompanyAboutResourcesHyperlink = ResourceLink & {
  __typename?: 'CompanyAboutResourcesHyperlink'
  sys: ResourceSys
}

export type CompanyAboutResourcesInline = ResourceLink & {
  __typename?: 'CompanyAboutResourcesInline'
  sys: ResourceSys
}

/** Used on PWS Press Releases for the About section [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/companyAndContactInfo) */
export type CompanyAndContactInfo = Entry &
  _Node & {
    __typename?: 'CompanyAndContactInfo'
    _id: Scalars['ID']
    company?: Maybe<Company>
    contactPerson?: Maybe<Person>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<CompanyAndContactInfoLinkingCollections>
    name?: Maybe<Scalars['String']>
    sys: Sys
  }

/** Used on PWS Press Releases for the About section [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/companyAndContactInfo) */
export type CompanyAndContactInfoCompanyArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CompanyFilter>
}

/** Used on PWS Press Releases for the About section [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/companyAndContactInfo) */
export type CompanyAndContactInfoContactPersonArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PersonFilter>
}

/** Used on PWS Press Releases for the About section [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/companyAndContactInfo) */
export type CompanyAndContactInfoLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Used on PWS Press Releases for the About section [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/companyAndContactInfo) */
export type CompanyAndContactInfoNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type CompanyAndContactInfoCollection = {
  __typename?: 'CompanyAndContactInfoCollection'
  items: Array<Maybe<CompanyAndContactInfo>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CompanyAndContactInfoFilter = {
  AND?: InputMaybe<Array<InputMaybe<CompanyAndContactInfoFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CompanyAndContactInfoFilter>>>
  company?: InputMaybe<CfCompanyNestedFilter>
  company_exists?: InputMaybe<Scalars['Boolean']>
  contactPerson?: InputMaybe<CfPersonNestedFilter>
  contactPerson_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CompanyAndContactInfoLinkingCollections = {
  __typename?: 'CompanyAndContactInfoLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pwsPressReleaseCollection?: Maybe<PwsPressReleaseCollection>
  pwsSiteSettingsCollection?: Maybe<PwsSiteSettingsCollection>
}

export type CompanyAndContactInfoLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CompanyAndContactInfoLinkingCollectionsPwsPressReleaseCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<CompanyAndContactInfoLinkingCollectionsPwsPressReleaseCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type CompanyAndContactInfoLinkingCollectionsPwsSiteSettingsCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<CompanyAndContactInfoLinkingCollectionsPwsSiteSettingsCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum CompanyAndContactInfoLinkingCollectionsPwsPressReleaseCollectionOrder {
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  ExternalNewsLinkAsc = 'externalNewsLink_ASC',
  ExternalNewsLinkDesc = 'externalNewsLink_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum CompanyAndContactInfoLinkingCollectionsPwsSiteSettingsCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CompanyAndContactInfoOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CompanyCollection = {
  __typename?: 'CompanyCollection'
  items: Array<Maybe<Company>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CompanyDisclosure = {
  __typename?: 'CompanyDisclosure'
  json: Scalars['JSON']
  links: CompanyDisclosureLinks
}

export type CompanyDisclosureAssets = {
  __typename?: 'CompanyDisclosureAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type CompanyDisclosureEntries = {
  __typename?: 'CompanyDisclosureEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type CompanyDisclosureLinks = {
  __typename?: 'CompanyDisclosureLinks'
  assets: CompanyDisclosureAssets
  entries: CompanyDisclosureEntries
  resources: CompanyDisclosureResources
}

export type CompanyDisclosureResources = {
  __typename?: 'CompanyDisclosureResources'
  block: Array<CompanyDisclosureResourcesBlock>
  hyperlink: Array<CompanyDisclosureResourcesHyperlink>
  inline: Array<CompanyDisclosureResourcesInline>
}

export type CompanyDisclosureResourcesBlock = ResourceLink & {
  __typename?: 'CompanyDisclosureResourcesBlock'
  sys: ResourceSys
}

export type CompanyDisclosureResourcesHyperlink = ResourceLink & {
  __typename?: 'CompanyDisclosureResourcesHyperlink'
  sys: ResourceSys
}

export type CompanyDisclosureResourcesInline = ResourceLink & {
  __typename?: 'CompanyDisclosureResourcesInline'
  sys: ResourceSys
}

export type CompanyFilter = {
  AND?: InputMaybe<Array<InputMaybe<CompanyFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CompanyFilter>>>
  about_contains?: InputMaybe<Scalars['String']>
  about_exists?: InputMaybe<Scalars['Boolean']>
  about_not_contains?: InputMaybe<Scalars['String']>
  caisVideo?: InputMaybe<CfCaisiqVideoNestedFilter>
  caisVideo_exists?: InputMaybe<Scalars['Boolean']>
  colorImageSet_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  disclosure_contains?: InputMaybe<Scalars['String']>
  disclosure_exists?: InputMaybe<Scalars['Boolean']>
  disclosure_not_contains?: InputMaybe<Scalars['String']>
  displayName?: InputMaybe<Scalars['String']>
  displayName_contains?: InputMaybe<Scalars['String']>
  displayName_exists?: InputMaybe<Scalars['Boolean']>
  displayName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayName_not?: InputMaybe<Scalars['String']>
  displayName_not_contains?: InputMaybe<Scalars['String']>
  displayName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  imageSet_exists?: InputMaybe<Scalars['Boolean']>
  linkedin?: InputMaybe<CfLinkNestedFilter>
  linkedin_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  shortDescription_contains?: InputMaybe<Scalars['String']>
  shortDescription_exists?: InputMaybe<Scalars['Boolean']>
  shortDescription_not_contains?: InputMaybe<Scalars['String']>
  stats?: InputMaybe<CfCorporateStatsNestedFilter>
  stats_exists?: InputMaybe<Scalars['Boolean']>
  subtitle?: InputMaybe<Scalars['String']>
  subtitle_contains?: InputMaybe<Scalars['String']>
  subtitle_exists?: InputMaybe<Scalars['Boolean']>
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subtitle_not?: InputMaybe<Scalars['String']>
  subtitle_not_contains?: InputMaybe<Scalars['String']>
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  twitter?: InputMaybe<CfLinkNestedFilter>
  twitter_exists?: InputMaybe<Scalars['Boolean']>
  website?: InputMaybe<CfLinkNestedFilter>
  website_exists?: InputMaybe<Scalars['Boolean']>
}

export type CompanyLinkingCollections = {
  __typename?: 'CompanyLinkingCollections'
  companyAndContactInfoCollection?: Maybe<CompanyAndContactInfoCollection>
  componentCompanyContainerCollection?: Maybe<ComponentCompanyContainerCollection>
  entryCollection?: Maybe<EntryCollection>
  pageCollection?: Maybe<PageCollection>
  personCollection?: Maybe<PersonCollection>
}

export type CompanyLinkingCollectionsCompanyAndContactInfoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CompanyLinkingCollectionsCompanyAndContactInfoCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CompanyLinkingCollectionsComponentCompanyContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CompanyLinkingCollectionsComponentCompanyContainerCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CompanyLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CompanyLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CompanyLinkingCollectionsPageCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CompanyLinkingCollectionsPersonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CompanyLinkingCollectionsPersonCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum CompanyLinkingCollectionsCompanyAndContactInfoCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CompanyLinkingCollectionsComponentCompanyContainerCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CompanyLinkingCollectionsPageCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum CompanyLinkingCollectionsPersonCollectionOrder {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CompanyOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CompanyShortDescription = {
  __typename?: 'CompanyShortDescription'
  json: Scalars['JSON']
  links: CompanyShortDescriptionLinks
}

export type CompanyShortDescriptionAssets = {
  __typename?: 'CompanyShortDescriptionAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type CompanyShortDescriptionEntries = {
  __typename?: 'CompanyShortDescriptionEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type CompanyShortDescriptionLinks = {
  __typename?: 'CompanyShortDescriptionLinks'
  assets: CompanyShortDescriptionAssets
  entries: CompanyShortDescriptionEntries
  resources: CompanyShortDescriptionResources
}

export type CompanyShortDescriptionResources = {
  __typename?: 'CompanyShortDescriptionResources'
  block: Array<CompanyShortDescriptionResourcesBlock>
  hyperlink: Array<CompanyShortDescriptionResourcesHyperlink>
  inline: Array<CompanyShortDescriptionResourcesInline>
}

export type CompanyShortDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'CompanyShortDescriptionResourcesBlock'
  sys: ResourceSys
}

export type CompanyShortDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'CompanyShortDescriptionResourcesHyperlink'
  sys: ResourceSys
}

export type CompanyShortDescriptionResourcesInline = ResourceLink & {
  __typename?: 'CompanyShortDescriptionResourcesInline'
  sys: ResourceSys
}

/** Component card description [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentCard) */
export type ComponentCard = Entry &
  _Node & {
    __typename?: 'ComponentCard'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    description?: Maybe<ComponentCardDescription>
    imageSet?: Maybe<Scalars['JSON']>
    internalName?: Maybe<Scalars['String']>
    link?: Maybe<Link>
    linkText?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentCardLinkingCollections>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Component card description [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentCard) */
export type ComponentCardDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Component card description [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentCard) */
export type ComponentCardImageSetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Component card description [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentCard) */
export type ComponentCardInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Component card description [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentCard) */
export type ComponentCardLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<LinkFilter>
}

/** Component card description [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentCard) */
export type ComponentCardLinkTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Component card description [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentCard) */
export type ComponentCardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Component card description [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentCard) */
export type ComponentCardTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentCardCollection = {
  __typename?: 'ComponentCardCollection'
  items: Array<Maybe<ComponentCard>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentCardDescription = {
  __typename?: 'ComponentCardDescription'
  json: Scalars['JSON']
  links: ComponentCardDescriptionLinks
}

export type ComponentCardDescriptionAssets = {
  __typename?: 'ComponentCardDescriptionAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentCardDescriptionEntries = {
  __typename?: 'ComponentCardDescriptionEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentCardDescriptionLinks = {
  __typename?: 'ComponentCardDescriptionLinks'
  assets: ComponentCardDescriptionAssets
  entries: ComponentCardDescriptionEntries
  resources: ComponentCardDescriptionResources
}

export type ComponentCardDescriptionResources = {
  __typename?: 'ComponentCardDescriptionResources'
  block: Array<ComponentCardDescriptionResourcesBlock>
  hyperlink: Array<ComponentCardDescriptionResourcesHyperlink>
  inline: Array<ComponentCardDescriptionResourcesInline>
}

export type ComponentCardDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'ComponentCardDescriptionResourcesBlock'
  sys: ResourceSys
}

export type ComponentCardDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'ComponentCardDescriptionResourcesHyperlink'
  sys: ResourceSys
}

export type ComponentCardDescriptionResourcesInline = ResourceLink & {
  __typename?: 'ComponentCardDescriptionResourcesInline'
  sys: ResourceSys
}

export type ComponentCardFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentCardFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentCardFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_not_contains?: InputMaybe<Scalars['String']>
  imageSet_exists?: InputMaybe<Scalars['Boolean']>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  link?: InputMaybe<CfLinkNestedFilter>
  linkText?: InputMaybe<Scalars['String']>
  linkText_contains?: InputMaybe<Scalars['String']>
  linkText_exists?: InputMaybe<Scalars['Boolean']>
  linkText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  linkText_not?: InputMaybe<Scalars['String']>
  linkText_not_contains?: InputMaybe<Scalars['String']>
  linkText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  link_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentCardLinkingCollections = {
  __typename?: 'ComponentCardLinkingCollections'
  componentSectionCollection?: Maybe<ComponentSectionCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type ComponentCardLinkingCollectionsComponentSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentCardLinkingCollectionsComponentSectionCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentCardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ComponentCardLinkingCollectionsComponentSectionCollectionOrder {
  BookmarkIdAsc = 'bookmarkId_ASC',
  BookmarkIdDesc = 'bookmarkId_DESC',
  CardContainerOptionsAsc = 'cardContainerOptions_ASC',
  CardContainerOptionsDesc = 'cardContainerOptions_DESC',
  ComponentIdAsc = 'componentId_ASC',
  ComponentIdDesc = 'componentId_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentCardOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LinkTextAsc = 'linkText_ASC',
  LinkTextDesc = 'linkText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Container component for Companies [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentCompanyContainer) */
export type ComponentCompanyContainer = Entry &
  _Node & {
    __typename?: 'ComponentCompanyContainer'
    _id: Scalars['ID']
    companiesCollection?: Maybe<ComponentCompanyContainerCompaniesCollection>
    contentfulMetadata: ContentfulMetadata
    defaultCompanyDisclosure?: Maybe<ComponentCompanyContainerDefaultCompanyDisclosure>
    description?: Maybe<ComponentCompanyContainerDescription>
    linkedFrom?: Maybe<ComponentCompanyContainerLinkingCollections>
    name?: Maybe<Scalars['String']>
    slug?: Maybe<Scalars['String']>
    sys: Sys
  }

/** Container component for Companies [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentCompanyContainer) */
export type ComponentCompanyContainerCompaniesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentCompanyContainerCompaniesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CompanyFilter>
}

/** Container component for Companies [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentCompanyContainer) */
export type ComponentCompanyContainerDefaultCompanyDisclosureArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Container component for Companies [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentCompanyContainer) */
export type ComponentCompanyContainerDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Container component for Companies [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentCompanyContainer) */
export type ComponentCompanyContainerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Container component for Companies [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentCompanyContainer) */
export type ComponentCompanyContainerNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Container component for Companies [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentCompanyContainer) */
export type ComponentCompanyContainerSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentCompanyContainerCollection = {
  __typename?: 'ComponentCompanyContainerCollection'
  items: Array<Maybe<ComponentCompanyContainer>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentCompanyContainerCompaniesCollection = {
  __typename?: 'ComponentCompanyContainerCompaniesCollection'
  items: Array<Maybe<Company>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentCompanyContainerCompaniesCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComponentCompanyContainerDefaultCompanyDisclosure = {
  __typename?: 'ComponentCompanyContainerDefaultCompanyDisclosure'
  json: Scalars['JSON']
  links: ComponentCompanyContainerDefaultCompanyDisclosureLinks
}

export type ComponentCompanyContainerDefaultCompanyDisclosureAssets = {
  __typename?: 'ComponentCompanyContainerDefaultCompanyDisclosureAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentCompanyContainerDefaultCompanyDisclosureEntries = {
  __typename?: 'ComponentCompanyContainerDefaultCompanyDisclosureEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentCompanyContainerDefaultCompanyDisclosureLinks = {
  __typename?: 'ComponentCompanyContainerDefaultCompanyDisclosureLinks'
  assets: ComponentCompanyContainerDefaultCompanyDisclosureAssets
  entries: ComponentCompanyContainerDefaultCompanyDisclosureEntries
  resources: ComponentCompanyContainerDefaultCompanyDisclosureResources
}

export type ComponentCompanyContainerDefaultCompanyDisclosureResources = {
  __typename?: 'ComponentCompanyContainerDefaultCompanyDisclosureResources'
  block: Array<ComponentCompanyContainerDefaultCompanyDisclosureResourcesBlock>
  hyperlink: Array<ComponentCompanyContainerDefaultCompanyDisclosureResourcesHyperlink>
  inline: Array<ComponentCompanyContainerDefaultCompanyDisclosureResourcesInline>
}

export type ComponentCompanyContainerDefaultCompanyDisclosureResourcesBlock =
  ResourceLink & {
    __typename?: 'ComponentCompanyContainerDefaultCompanyDisclosureResourcesBlock'
    sys: ResourceSys
  }

export type ComponentCompanyContainerDefaultCompanyDisclosureResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentCompanyContainerDefaultCompanyDisclosureResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentCompanyContainerDefaultCompanyDisclosureResourcesInline =
  ResourceLink & {
    __typename?: 'ComponentCompanyContainerDefaultCompanyDisclosureResourcesInline'
    sys: ResourceSys
  }

export type ComponentCompanyContainerDescription = {
  __typename?: 'ComponentCompanyContainerDescription'
  json: Scalars['JSON']
  links: ComponentCompanyContainerDescriptionLinks
}

export type ComponentCompanyContainerDescriptionAssets = {
  __typename?: 'ComponentCompanyContainerDescriptionAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentCompanyContainerDescriptionEntries = {
  __typename?: 'ComponentCompanyContainerDescriptionEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentCompanyContainerDescriptionLinks = {
  __typename?: 'ComponentCompanyContainerDescriptionLinks'
  assets: ComponentCompanyContainerDescriptionAssets
  entries: ComponentCompanyContainerDescriptionEntries
  resources: ComponentCompanyContainerDescriptionResources
}

export type ComponentCompanyContainerDescriptionResources = {
  __typename?: 'ComponentCompanyContainerDescriptionResources'
  block: Array<ComponentCompanyContainerDescriptionResourcesBlock>
  hyperlink: Array<ComponentCompanyContainerDescriptionResourcesHyperlink>
  inline: Array<ComponentCompanyContainerDescriptionResourcesInline>
}

export type ComponentCompanyContainerDescriptionResourcesBlock =
  ResourceLink & {
    __typename?: 'ComponentCompanyContainerDescriptionResourcesBlock'
    sys: ResourceSys
  }

export type ComponentCompanyContainerDescriptionResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentCompanyContainerDescriptionResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentCompanyContainerDescriptionResourcesInline =
  ResourceLink & {
    __typename?: 'ComponentCompanyContainerDescriptionResourcesInline'
    sys: ResourceSys
  }

export type ComponentCompanyContainerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentCompanyContainerFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentCompanyContainerFilter>>>
  companies?: InputMaybe<CfCompanyNestedFilter>
  companiesCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  defaultCompanyDisclosure_contains?: InputMaybe<Scalars['String']>
  defaultCompanyDisclosure_exists?: InputMaybe<Scalars['Boolean']>
  defaultCompanyDisclosure_not_contains?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_not_contains?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ComponentCompanyContainerLinkingCollections = {
  __typename?: 'ComponentCompanyContainerLinkingCollections'
  componentSectionCollection?: Maybe<ComponentSectionCollection>
  entryCollection?: Maybe<EntryCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
}

export type ComponentCompanyContainerLinkingCollectionsComponentSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCompanyContainerLinkingCollectionsComponentSectionCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentCompanyContainerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentCompanyContainerLinkingCollectionsPageLandingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentCompanyContainerLinkingCollectionsPageLandingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentCompanyContainerLinkingCollectionsComponentSectionCollectionOrder {
  BookmarkIdAsc = 'bookmarkId_ASC',
  BookmarkIdDesc = 'bookmarkId_DESC',
  CardContainerOptionsAsc = 'cardContainerOptions_ASC',
  CardContainerOptionsDesc = 'cardContainerOptions_DESC',
  ComponentIdAsc = 'componentId_ASC',
  ComponentIdDesc = 'componentId_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentCompanyContainerLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentCompanyContainerOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_hero) */
export type ComponentHero = Entry &
  _Node & {
    __typename?: 'ComponentHero'
    _id: Scalars['ID']
    background?: Maybe<Scalars['String']>
    buttonLink?: Maybe<Link>
    caisVideo?: Maybe<CaisiqVideo>
    contentfulMetadata: ContentfulMetadata
    eyebrow?: Maybe<Scalars['String']>
    icon?: Maybe<Scalars['JSON']>
    image?: Maybe<Asset>
    imageSet?: Maybe<Scalars['JSON']>
    linkedFrom?: Maybe<ComponentHeroLinkingCollections>
    name?: Maybe<Scalars['String']>
    subtitle?: Maybe<Scalars['String']>
    sys: Sys
    text?: Maybe<ComponentHeroText>
    title?: Maybe<Scalars['String']>
    variant?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_hero) */
export type ComponentHeroBackgroundArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_hero) */
export type ComponentHeroButtonLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<LinkFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_hero) */
export type ComponentHeroCaisVideoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CaisiqVideoFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_hero) */
export type ComponentHeroEyebrowArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_hero) */
export type ComponentHeroIconArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_hero) */
export type ComponentHeroImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_hero) */
export type ComponentHeroImageSetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_hero) */
export type ComponentHeroLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_hero) */
export type ComponentHeroNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_hero) */
export type ComponentHeroSubtitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_hero) */
export type ComponentHeroTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_hero) */
export type ComponentHeroTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_hero) */
export type ComponentHeroVariantArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentHeroCollection = {
  __typename?: 'ComponentHeroCollection'
  items: Array<Maybe<ComponentHero>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentHeroFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentHeroFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentHeroFilter>>>
  background?: InputMaybe<Scalars['String']>
  background_contains?: InputMaybe<Scalars['String']>
  background_exists?: InputMaybe<Scalars['Boolean']>
  background_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  background_not?: InputMaybe<Scalars['String']>
  background_not_contains?: InputMaybe<Scalars['String']>
  background_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonLink?: InputMaybe<CfLinkNestedFilter>
  buttonLink_exists?: InputMaybe<Scalars['Boolean']>
  caisVideo?: InputMaybe<CfCaisiqVideoNestedFilter>
  caisVideo_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  eyebrow?: InputMaybe<Scalars['String']>
  eyebrow_contains?: InputMaybe<Scalars['String']>
  eyebrow_exists?: InputMaybe<Scalars['Boolean']>
  eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eyebrow_not?: InputMaybe<Scalars['String']>
  eyebrow_not_contains?: InputMaybe<Scalars['String']>
  eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  icon_exists?: InputMaybe<Scalars['Boolean']>
  imageSet_exists?: InputMaybe<Scalars['Boolean']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subtitle?: InputMaybe<Scalars['String']>
  subtitle_contains?: InputMaybe<Scalars['String']>
  subtitle_exists?: InputMaybe<Scalars['Boolean']>
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subtitle_not?: InputMaybe<Scalars['String']>
  subtitle_not_contains?: InputMaybe<Scalars['String']>
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  text_contains?: InputMaybe<Scalars['String']>
  text_exists?: InputMaybe<Scalars['Boolean']>
  text_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  variant?: InputMaybe<Scalars['String']>
  variant_contains?: InputMaybe<Scalars['String']>
  variant_exists?: InputMaybe<Scalars['Boolean']>
  variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  variant_not?: InputMaybe<Scalars['String']>
  variant_not_contains?: InputMaybe<Scalars['String']>
  variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentHeroLinkingCollections = {
  __typename?: 'ComponentHeroLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
}

export type ComponentHeroLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentHeroLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentHeroLinkingCollectionsPageLandingCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ComponentHeroLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentHeroOrder {
  BackgroundAsc = 'background_ASC',
  BackgroundDesc = 'background_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export type ComponentHeroText = {
  __typename?: 'ComponentHeroText'
  json: Scalars['JSON']
  links: ComponentHeroTextLinks
}

export type ComponentHeroTextAssets = {
  __typename?: 'ComponentHeroTextAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentHeroTextEntries = {
  __typename?: 'ComponentHeroTextEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentHeroTextLinks = {
  __typename?: 'ComponentHeroTextLinks'
  assets: ComponentHeroTextAssets
  entries: ComponentHeroTextEntries
  resources: ComponentHeroTextResources
}

export type ComponentHeroTextResources = {
  __typename?: 'ComponentHeroTextResources'
  block: Array<ComponentHeroTextResourcesBlock>
  hyperlink: Array<ComponentHeroTextResourcesHyperlink>
  inline: Array<ComponentHeroTextResourcesInline>
}

export type ComponentHeroTextResourcesBlock = ResourceLink & {
  __typename?: 'ComponentHeroTextResourcesBlock'
  sys: ResourceSys
}

export type ComponentHeroTextResourcesHyperlink = ResourceLink & {
  __typename?: 'ComponentHeroTextResourcesHyperlink'
  sys: ResourceSys
}

export type ComponentHeroTextResourcesInline = ResourceLink & {
  __typename?: 'ComponentHeroTextResourcesInline'
  sys: ResourceSys
}

/** Component to display a hubspot form on a Landing Page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentHubspotForm) */
export type ComponentHubspotForm = Entry &
  _Node & {
    __typename?: 'ComponentHubspotForm'
    _id: Scalars['ID']
    containerId?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    formId?: Maybe<Scalars['String']>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentHubspotFormLinkingCollections>
    sys: Sys
  }

/** Component to display a hubspot form on a Landing Page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentHubspotForm) */
export type ComponentHubspotFormContainerIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Component to display a hubspot form on a Landing Page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentHubspotForm) */
export type ComponentHubspotFormFormIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Component to display a hubspot form on a Landing Page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentHubspotForm) */
export type ComponentHubspotFormInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Component to display a hubspot form on a Landing Page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentHubspotForm) */
export type ComponentHubspotFormLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentHubspotFormCollection = {
  __typename?: 'ComponentHubspotFormCollection'
  items: Array<Maybe<ComponentHubspotForm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentHubspotFormFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentHubspotFormFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentHubspotFormFilter>>>
  containerId?: InputMaybe<Scalars['String']>
  containerId_contains?: InputMaybe<Scalars['String']>
  containerId_exists?: InputMaybe<Scalars['Boolean']>
  containerId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  containerId_not?: InputMaybe<Scalars['String']>
  containerId_not_contains?: InputMaybe<Scalars['String']>
  containerId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  formId?: InputMaybe<Scalars['String']>
  formId_contains?: InputMaybe<Scalars['String']>
  formId_exists?: InputMaybe<Scalars['Boolean']>
  formId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  formId_not?: InputMaybe<Scalars['String']>
  formId_not_contains?: InputMaybe<Scalars['String']>
  formId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ComponentHubspotFormLinkingCollections = {
  __typename?: 'ComponentHubspotFormLinkingCollections'
  componentTextImageCollection?: Maybe<ComponentTextImageCollection>
  entryCollection?: Maybe<EntryCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
}

export type ComponentHubspotFormLinkingCollectionsComponentTextImageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentHubspotFormLinkingCollectionsComponentTextImageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentHubspotFormLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentHubspotFormLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentHubspotFormLinkingCollectionsPageLandingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ComponentHubspotFormLinkingCollectionsComponentTextImageCollectionOrder {
  BackgroundAsc = 'background_ASC',
  BackgroundDesc = 'background_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export enum ComponentHubspotFormLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentHubspotFormOrder {
  ContainerIdAsc = 'containerId_ASC',
  ContainerIdDesc = 'containerId_DESC',
  FormIdAsc = 'formId_ASC',
  FormIdDesc = 'formId_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_image) */
export type ComponentImage = Entry &
  _Node & {
    __typename?: 'ComponentImage'
    _id: Scalars['ID']
    altText?: Maybe<Scalars['String']>
    cloudinaryImage?: Maybe<Scalars['JSON']>
    contentfulMetadata: ContentfulMetadata
    image?: Maybe<Asset>
    linkedFrom?: Maybe<ComponentImageLinkingCollections>
    name?: Maybe<Scalars['String']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_image) */
export type ComponentImageAltTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_image) */
export type ComponentImageCloudinaryImageArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_image) */
export type ComponentImageImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_image) */
export type ComponentImageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_image) */
export type ComponentImageNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_image) */
export type ComponentImageTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentImageCollection = {
  __typename?: 'ComponentImageCollection'
  items: Array<Maybe<ComponentImage>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentImageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentImageFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentImageFilter>>>
  altText?: InputMaybe<Scalars['String']>
  altText_contains?: InputMaybe<Scalars['String']>
  altText_exists?: InputMaybe<Scalars['Boolean']>
  altText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  altText_not?: InputMaybe<Scalars['String']>
  altText_not_contains?: InputMaybe<Scalars['String']>
  altText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  cloudinaryImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  image_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentImageLinkingCollections = {
  __typename?: 'ComponentImageLinkingCollections'
  accordionCollection?: Maybe<AccordionCollection>
  accordionWithImageCollection?: Maybe<AccordionWithImageCollection>
  caisiqAuthorCollection?: Maybe<CaisiqAuthorCollection>
  chartCollection?: Maybe<ChartCollection>
  entryCollection?: Maybe<EntryCollection>
  fullWidthImageCollection?: Maybe<FullWidthImageCollection>
  homepageContentSectionCollection?: Maybe<HomepageContentSectionCollection>
  pageHelpCenterArticleCollection?: Maybe<PageHelpCenterArticleCollection>
  pressAwardCollection?: Maybe<PressAwardCollection>
}

export type ComponentImageLinkingCollectionsAccordionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentImageLinkingCollectionsAccordionCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentImageLinkingCollectionsAccordionWithImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentImageLinkingCollectionsAccordionWithImageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentImageLinkingCollectionsCaisiqAuthorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentImageLinkingCollectionsCaisiqAuthorCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentImageLinkingCollectionsChartCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentImageLinkingCollectionsChartCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentImageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentImageLinkingCollectionsFullWidthImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentImageLinkingCollectionsFullWidthImageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentImageLinkingCollectionsHomepageContentSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageLinkingCollectionsHomepageContentSectionCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentImageLinkingCollectionsPageHelpCenterArticleCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentImageLinkingCollectionsPageHelpCenterArticleCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentImageLinkingCollectionsPressAwardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentImageLinkingCollectionsPressAwardCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ComponentImageLinkingCollectionsAccordionCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageLinkingCollectionsAccordionWithImageCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageLinkingCollectionsCaisiqAuthorCollectionOrder {
  AuthorNameAsc = 'authorName_ASC',
  AuthorNameDesc = 'authorName_DESC',
  FirmAsc = 'firm_ASC',
  FirmDesc = 'firm_DESC',
  JobTitleAsc = 'jobTitle_ASC',
  JobTitleDesc = 'jobTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentImageLinkingCollectionsChartCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageLinkingCollectionsFullWidthImageCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentImageLinkingCollectionsHomepageContentSectionCollectionOrder {
  BookmarkIdAsc = 'bookmarkId_ASC',
  BookmarkIdDesc = 'bookmarkId_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  RemoveTopPaddingAsc = 'removeTopPadding_ASC',
  RemoveTopPaddingDesc = 'removeTopPadding_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum ComponentImageLinkingCollectionsPageHelpCenterArticleCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentImageLinkingCollectionsPressAwardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentImageOrder {
  AltTextAsc = 'altText_ASC',
  AltTextDesc = 'altText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Component to display a Transistor podcast embed player, can be used in other models like Component: Section or Component: TextImage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentPodcastPlayer) */
export type ComponentPodcastPlayer = Entry &
  _Node & {
    __typename?: 'ComponentPodcastPlayer'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    episodeId?: Maybe<Scalars['String']>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentPodcastPlayerLinkingCollections>
    playlistSlug?: Maybe<Scalars['String']>
    sys: Sys
    theme?: Maybe<Scalars['String']>
    variant?: Maybe<Scalars['String']>
  }

/** Component to display a Transistor podcast embed player, can be used in other models like Component: Section or Component: TextImage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentPodcastPlayer) */
export type ComponentPodcastPlayerEpisodeIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Component to display a Transistor podcast embed player, can be used in other models like Component: Section or Component: TextImage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentPodcastPlayer) */
export type ComponentPodcastPlayerInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Component to display a Transistor podcast embed player, can be used in other models like Component: Section or Component: TextImage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentPodcastPlayer) */
export type ComponentPodcastPlayerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Component to display a Transistor podcast embed player, can be used in other models like Component: Section or Component: TextImage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentPodcastPlayer) */
export type ComponentPodcastPlayerPlaylistSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Component to display a Transistor podcast embed player, can be used in other models like Component: Section or Component: TextImage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentPodcastPlayer) */
export type ComponentPodcastPlayerThemeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Component to display a Transistor podcast embed player, can be used in other models like Component: Section or Component: TextImage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentPodcastPlayer) */
export type ComponentPodcastPlayerVariantArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentPodcastPlayerCollection = {
  __typename?: 'ComponentPodcastPlayerCollection'
  items: Array<Maybe<ComponentPodcastPlayer>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentPodcastPlayerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentPodcastPlayerFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentPodcastPlayerFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  episodeId?: InputMaybe<Scalars['String']>
  episodeId_contains?: InputMaybe<Scalars['String']>
  episodeId_exists?: InputMaybe<Scalars['Boolean']>
  episodeId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  episodeId_not?: InputMaybe<Scalars['String']>
  episodeId_not_contains?: InputMaybe<Scalars['String']>
  episodeId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  playlistSlug?: InputMaybe<Scalars['String']>
  playlistSlug_contains?: InputMaybe<Scalars['String']>
  playlistSlug_exists?: InputMaybe<Scalars['Boolean']>
  playlistSlug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  playlistSlug_not?: InputMaybe<Scalars['String']>
  playlistSlug_not_contains?: InputMaybe<Scalars['String']>
  playlistSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  theme?: InputMaybe<Scalars['String']>
  theme_contains?: InputMaybe<Scalars['String']>
  theme_exists?: InputMaybe<Scalars['Boolean']>
  theme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  theme_not?: InputMaybe<Scalars['String']>
  theme_not_contains?: InputMaybe<Scalars['String']>
  theme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  variant?: InputMaybe<Scalars['String']>
  variant_contains?: InputMaybe<Scalars['String']>
  variant_exists?: InputMaybe<Scalars['Boolean']>
  variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  variant_not?: InputMaybe<Scalars['String']>
  variant_not_contains?: InputMaybe<Scalars['String']>
  variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentPodcastPlayerLinkingCollections = {
  __typename?: 'ComponentPodcastPlayerLinkingCollections'
  componentSectionCollection?: Maybe<ComponentSectionCollection>
  componentTextImageCollection?: Maybe<ComponentTextImageCollection>
  entryCollection?: Maybe<EntryCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
}

export type ComponentPodcastPlayerLinkingCollectionsComponentSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPodcastPlayerLinkingCollectionsComponentSectionCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPodcastPlayerLinkingCollectionsComponentTextImageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPodcastPlayerLinkingCollectionsComponentTextImageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentPodcastPlayerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentPodcastPlayerLinkingCollectionsPageLandingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentPodcastPlayerLinkingCollectionsPageLandingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentPodcastPlayerLinkingCollectionsComponentSectionCollectionOrder {
  BookmarkIdAsc = 'bookmarkId_ASC',
  BookmarkIdDesc = 'bookmarkId_DESC',
  CardContainerOptionsAsc = 'cardContainerOptions_ASC',
  CardContainerOptionsDesc = 'cardContainerOptions_DESC',
  ComponentIdAsc = 'componentId_ASC',
  ComponentIdDesc = 'componentId_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentPodcastPlayerLinkingCollectionsComponentTextImageCollectionOrder {
  BackgroundAsc = 'background_ASC',
  BackgroundDesc = 'background_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export enum ComponentPodcastPlayerLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentPodcastPlayerOrder {
  EpisodeIdAsc = 'episodeId_ASC',
  EpisodeIdDesc = 'episodeId_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  PlaylistSlugAsc = 'playlistSlug_ASC',
  PlaylistSlugDesc = 'playlistSlug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_section) */
export type ComponentSection = Entry &
  _Node & {
    __typename?: 'ComponentSection'
    _id: Scalars['ID']
    bookmarkId?: Maybe<Scalars['String']>
    cardContainerOptions?: Maybe<Scalars['String']>
    componentId?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    description?: Maybe<ComponentSectionDescription>
    firmsContainerDefaultDisclosure?: Maybe<ComponentSectionFirmsContainerDefaultDisclosure>
    internalName?: Maybe<Scalars['String']>
    itemsCollection?: Maybe<ComponentSectionItemsCollection>
    linkedFrom?: Maybe<ComponentSectionLinkingCollections>
    name?: Maybe<Scalars['String']>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_section) */
export type ComponentSectionBookmarkIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_section) */
export type ComponentSectionCardContainerOptionsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_section) */
export type ComponentSectionComponentIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_section) */
export type ComponentSectionDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_section) */
export type ComponentSectionFirmsContainerDefaultDisclosureArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_section) */
export type ComponentSectionInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_section) */
export type ComponentSectionItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentSectionItemsFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_section) */
export type ComponentSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_section) */
export type ComponentSectionNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentSectionCollection = {
  __typename?: 'ComponentSectionCollection'
  items: Array<Maybe<ComponentSection>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentSectionDescription = {
  __typename?: 'ComponentSectionDescription'
  json: Scalars['JSON']
  links: ComponentSectionDescriptionLinks
}

export type ComponentSectionDescriptionAssets = {
  __typename?: 'ComponentSectionDescriptionAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentSectionDescriptionEntries = {
  __typename?: 'ComponentSectionDescriptionEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentSectionDescriptionLinks = {
  __typename?: 'ComponentSectionDescriptionLinks'
  assets: ComponentSectionDescriptionAssets
  entries: ComponentSectionDescriptionEntries
  resources: ComponentSectionDescriptionResources
}

export type ComponentSectionDescriptionResources = {
  __typename?: 'ComponentSectionDescriptionResources'
  block: Array<ComponentSectionDescriptionResourcesBlock>
  hyperlink: Array<ComponentSectionDescriptionResourcesHyperlink>
  inline: Array<ComponentSectionDescriptionResourcesInline>
}

export type ComponentSectionDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'ComponentSectionDescriptionResourcesBlock'
  sys: ResourceSys
}

export type ComponentSectionDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'ComponentSectionDescriptionResourcesHyperlink'
  sys: ResourceSys
}

export type ComponentSectionDescriptionResourcesInline = ResourceLink & {
  __typename?: 'ComponentSectionDescriptionResourcesInline'
  sys: ResourceSys
}

export type ComponentSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentSectionFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentSectionFilter>>>
  bookmarkId?: InputMaybe<Scalars['String']>
  bookmarkId_contains?: InputMaybe<Scalars['String']>
  bookmarkId_exists?: InputMaybe<Scalars['Boolean']>
  bookmarkId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bookmarkId_not?: InputMaybe<Scalars['String']>
  bookmarkId_not_contains?: InputMaybe<Scalars['String']>
  bookmarkId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  cardContainerOptions?: InputMaybe<Scalars['String']>
  cardContainerOptions_contains?: InputMaybe<Scalars['String']>
  cardContainerOptions_exists?: InputMaybe<Scalars['Boolean']>
  cardContainerOptions_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  cardContainerOptions_not?: InputMaybe<Scalars['String']>
  cardContainerOptions_not_contains?: InputMaybe<Scalars['String']>
  cardContainerOptions_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  componentId?: InputMaybe<Scalars['String']>
  componentId_contains?: InputMaybe<Scalars['String']>
  componentId_exists?: InputMaybe<Scalars['Boolean']>
  componentId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  componentId_not?: InputMaybe<Scalars['String']>
  componentId_not_contains?: InputMaybe<Scalars['String']>
  componentId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_not_contains?: InputMaybe<Scalars['String']>
  firmsContainerDefaultDisclosure_contains?: InputMaybe<Scalars['String']>
  firmsContainerDefaultDisclosure_exists?: InputMaybe<Scalars['Boolean']>
  firmsContainerDefaultDisclosure_not_contains?: InputMaybe<Scalars['String']>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  items?: InputMaybe<CfitemsMultiTypeNestedFilter>
  itemsCollection_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ComponentSectionFirmsContainerDefaultDisclosure = {
  __typename?: 'ComponentSectionFirmsContainerDefaultDisclosure'
  json: Scalars['JSON']
  links: ComponentSectionFirmsContainerDefaultDisclosureLinks
}

export type ComponentSectionFirmsContainerDefaultDisclosureAssets = {
  __typename?: 'ComponentSectionFirmsContainerDefaultDisclosureAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentSectionFirmsContainerDefaultDisclosureEntries = {
  __typename?: 'ComponentSectionFirmsContainerDefaultDisclosureEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentSectionFirmsContainerDefaultDisclosureLinks = {
  __typename?: 'ComponentSectionFirmsContainerDefaultDisclosureLinks'
  assets: ComponentSectionFirmsContainerDefaultDisclosureAssets
  entries: ComponentSectionFirmsContainerDefaultDisclosureEntries
  resources: ComponentSectionFirmsContainerDefaultDisclosureResources
}

export type ComponentSectionFirmsContainerDefaultDisclosureResources = {
  __typename?: 'ComponentSectionFirmsContainerDefaultDisclosureResources'
  block: Array<ComponentSectionFirmsContainerDefaultDisclosureResourcesBlock>
  hyperlink: Array<ComponentSectionFirmsContainerDefaultDisclosureResourcesHyperlink>
  inline: Array<ComponentSectionFirmsContainerDefaultDisclosureResourcesInline>
}

export type ComponentSectionFirmsContainerDefaultDisclosureResourcesBlock =
  ResourceLink & {
    __typename?: 'ComponentSectionFirmsContainerDefaultDisclosureResourcesBlock'
    sys: ResourceSys
  }

export type ComponentSectionFirmsContainerDefaultDisclosureResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentSectionFirmsContainerDefaultDisclosureResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentSectionFirmsContainerDefaultDisclosureResourcesInline =
  ResourceLink & {
    __typename?: 'ComponentSectionFirmsContainerDefaultDisclosureResourcesInline'
    sys: ResourceSys
  }

export type ComponentSectionItemsCollection = {
  __typename?: 'ComponentSectionItemsCollection'
  items: Array<Maybe<ComponentSectionItemsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentSectionItemsFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentSectionItemsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentSectionItemsFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
}

export type ComponentSectionItemsItem =
  | CaisiqVideo
  | CallToAction
  | ComponentCard
  | ComponentCompanyContainer
  | ComponentPodcastPlayer
  | ComponentText
  | Link
  | PressAward
  | Quote
  | Team

export type ComponentSectionLinkingCollections = {
  __typename?: 'ComponentSectionLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
}

export type ComponentSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSectionLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSectionLinkingCollectionsPageLandingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ComponentSectionLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentSectionOrder {
  BookmarkIdAsc = 'bookmarkId_ASC',
  BookmarkIdDesc = 'bookmarkId_DESC',
  CardContainerOptionsAsc = 'cardContainerOptions_ASC',
  CardContainerOptionsDesc = 'cardContainerOptions_DESC',
  ComponentIdAsc = 'componentId_ASC',
  ComponentIdDesc = 'componentId_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Component to display the summary of a content like an article,  [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentSummary) */
export type ComponentSummary = Entry &
  _Node & {
    __typename?: 'ComponentSummary'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentSummaryLinkingCollections>
    summaryText?: Maybe<ComponentSummarySummaryText>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Component to display the summary of a content like an article,  [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentSummary) */
export type ComponentSummaryInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Component to display the summary of a content like an article,  [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentSummary) */
export type ComponentSummaryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Component to display the summary of a content like an article,  [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentSummary) */
export type ComponentSummarySummaryTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Component to display the summary of a content like an article,  [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentSummary) */
export type ComponentSummaryTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentSummaryCollection = {
  __typename?: 'ComponentSummaryCollection'
  items: Array<Maybe<ComponentSummary>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentSummaryFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentSummaryFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentSummaryFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  summaryText_contains?: InputMaybe<Scalars['String']>
  summaryText_exists?: InputMaybe<Scalars['Boolean']>
  summaryText_not_contains?: InputMaybe<Scalars['String']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentSummaryLinkingCollections = {
  __typename?: 'ComponentSummaryLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
}

export type ComponentSummaryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentSummaryLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentSummaryLinkingCollectionsPageLandingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ComponentSummaryLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentSummaryOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ComponentSummarySummaryText = {
  __typename?: 'ComponentSummarySummaryText'
  json: Scalars['JSON']
  links: ComponentSummarySummaryTextLinks
}

export type ComponentSummarySummaryTextAssets = {
  __typename?: 'ComponentSummarySummaryTextAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentSummarySummaryTextEntries = {
  __typename?: 'ComponentSummarySummaryTextEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentSummarySummaryTextLinks = {
  __typename?: 'ComponentSummarySummaryTextLinks'
  assets: ComponentSummarySummaryTextAssets
  entries: ComponentSummarySummaryTextEntries
  resources: ComponentSummarySummaryTextResources
}

export type ComponentSummarySummaryTextResources = {
  __typename?: 'ComponentSummarySummaryTextResources'
  block: Array<ComponentSummarySummaryTextResourcesBlock>
  hyperlink: Array<ComponentSummarySummaryTextResourcesHyperlink>
  inline: Array<ComponentSummarySummaryTextResourcesInline>
}

export type ComponentSummarySummaryTextResourcesBlock = ResourceLink & {
  __typename?: 'ComponentSummarySummaryTextResourcesBlock'
  sys: ResourceSys
}

export type ComponentSummarySummaryTextResourcesHyperlink = ResourceLink & {
  __typename?: 'ComponentSummarySummaryTextResourcesHyperlink'
  sys: ResourceSys
}

export type ComponentSummarySummaryTextResourcesInline = ResourceLink & {
  __typename?: 'ComponentSummarySummaryTextResourcesInline'
  sys: ResourceSys
}

/** Container component for Teams [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTeamContainer) */
export type ComponentTeamContainer = Entry &
  _Node & {
    __typename?: 'ComponentTeamContainer'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    description?: Maybe<ComponentTeamContainerDescription>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentTeamContainerLinkingCollections>
    sys: Sys
    teamsCollection?: Maybe<ComponentTeamContainerTeamsCollection>
  }

/** Container component for Teams [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTeamContainer) */
export type ComponentTeamContainerDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Container component for Teams [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTeamContainer) */
export type ComponentTeamContainerInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Container component for Teams [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTeamContainer) */
export type ComponentTeamContainerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Container component for Teams [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTeamContainer) */
export type ComponentTeamContainerTeamsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentTeamContainerTeamsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TeamFilter>
}

export type ComponentTeamContainerCollection = {
  __typename?: 'ComponentTeamContainerCollection'
  items: Array<Maybe<ComponentTeamContainer>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentTeamContainerDescription = {
  __typename?: 'ComponentTeamContainerDescription'
  json: Scalars['JSON']
  links: ComponentTeamContainerDescriptionLinks
}

export type ComponentTeamContainerDescriptionAssets = {
  __typename?: 'ComponentTeamContainerDescriptionAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentTeamContainerDescriptionEntries = {
  __typename?: 'ComponentTeamContainerDescriptionEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentTeamContainerDescriptionLinks = {
  __typename?: 'ComponentTeamContainerDescriptionLinks'
  assets: ComponentTeamContainerDescriptionAssets
  entries: ComponentTeamContainerDescriptionEntries
  resources: ComponentTeamContainerDescriptionResources
}

export type ComponentTeamContainerDescriptionResources = {
  __typename?: 'ComponentTeamContainerDescriptionResources'
  block: Array<ComponentTeamContainerDescriptionResourcesBlock>
  hyperlink: Array<ComponentTeamContainerDescriptionResourcesHyperlink>
  inline: Array<ComponentTeamContainerDescriptionResourcesInline>
}

export type ComponentTeamContainerDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'ComponentTeamContainerDescriptionResourcesBlock'
  sys: ResourceSys
}

export type ComponentTeamContainerDescriptionResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentTeamContainerDescriptionResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentTeamContainerDescriptionResourcesInline = ResourceLink & {
  __typename?: 'ComponentTeamContainerDescriptionResourcesInline'
  sys: ResourceSys
}

export type ComponentTeamContainerFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentTeamContainerFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentTeamContainerFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_not_contains?: InputMaybe<Scalars['String']>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  teams?: InputMaybe<CfTeamNestedFilter>
  teamsCollection_exists?: InputMaybe<Scalars['Boolean']>
}

export type ComponentTeamContainerLinkingCollections = {
  __typename?: 'ComponentTeamContainerLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
}

export type ComponentTeamContainerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentTeamContainerLinkingCollectionsPageLandingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTeamContainerLinkingCollectionsPageLandingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentTeamContainerLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentTeamContainerOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComponentTeamContainerTeamsCollection = {
  __typename?: 'ComponentTeamContainerTeamsCollection'
  items: Array<Maybe<Team>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentTeamContainerTeamsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_text) */
export type ComponentText = Entry &
  _Node & {
    __typename?: 'ComponentText'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    icon?: Maybe<Scalars['String']>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentTextLinkingCollections>
    sys: Sys
    text?: Maybe<ComponentTextText>
    title?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_text) */
export type ComponentTextIconArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_text) */
export type ComponentTextInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_text) */
export type ComponentTextLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_text) */
export type ComponentTextTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/component_text) */
export type ComponentTextTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentTextCollection = {
  __typename?: 'ComponentTextCollection'
  items: Array<Maybe<ComponentText>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentTextFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentTextFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentTextFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  icon?: InputMaybe<Scalars['String']>
  icon_contains?: InputMaybe<Scalars['String']>
  icon_exists?: InputMaybe<Scalars['Boolean']>
  icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  icon_not?: InputMaybe<Scalars['String']>
  icon_not_contains?: InputMaybe<Scalars['String']>
  icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  text_contains?: InputMaybe<Scalars['String']>
  text_exists?: InputMaybe<Scalars['Boolean']>
  text_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextIllustration) */
export type ComponentTextIllustration = Entry &
  _Node & {
    __typename?: 'ComponentTextIllustration'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<ComponentTextIllustrationLinkingCollections>
    name?: Maybe<Scalars['String']>
    sectionsCollection?: Maybe<ComponentTextIllustrationSectionsCollection>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextIllustration) */
export type ComponentTextIllustrationLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextIllustration) */
export type ComponentTextIllustrationNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextIllustration) */
export type ComponentTextIllustrationSectionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentTextIllustrationSectionsFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextIllustration) */
export type ComponentTextIllustrationTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentTextIllustrationCollection = {
  __typename?: 'ComponentTextIllustrationCollection'
  items: Array<Maybe<ComponentTextIllustration>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentTextIllustrationFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentTextIllustrationFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentTextIllustrationFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sections?: InputMaybe<CfsectionsMultiTypeNestedFilter>
  sectionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentTextIllustrationLinkingCollections = {
  __typename?: 'ComponentTextIllustrationLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
}

export type ComponentTextIllustrationLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentTextIllustrationLinkingCollectionsPageLandingCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTextIllustrationLinkingCollectionsPageLandingCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentTextIllustrationLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentTextIllustrationOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ComponentTextIllustrationSectionsCollection = {
  __typename?: 'ComponentTextIllustrationSectionsCollection'
  items: Array<Maybe<ComponentTextIllustrationSectionsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentTextIllustrationSectionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentTextIllustrationSectionsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentTextIllustrationSectionsFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ComponentTextIllustrationSectionsItem =
  | ComponentTextImageImageSection
  | ComponentTextImageTextSection

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage) */
export type ComponentTextImage = Entry &
  _Node & {
    __typename?: 'ComponentTextImage'
    _id: Scalars['ID']
    background?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentTextImageLinkingCollections>
    name?: Maybe<Scalars['String']>
    sectionsCollection?: Maybe<ComponentTextImageSectionsCollection>
    sys: Sys
    variant?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage) */
export type ComponentTextImageBackgroundArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage) */
export type ComponentTextImageInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage) */
export type ComponentTextImageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage) */
export type ComponentTextImageNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage) */
export type ComponentTextImageSectionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentTextImageSectionsFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage) */
export type ComponentTextImageVariantArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentTextImageCollection = {
  __typename?: 'ComponentTextImageCollection'
  items: Array<Maybe<ComponentTextImage>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentTextImageFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentTextImageFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentTextImageFilter>>>
  background?: InputMaybe<Scalars['String']>
  background_contains?: InputMaybe<Scalars['String']>
  background_exists?: InputMaybe<Scalars['Boolean']>
  background_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  background_not?: InputMaybe<Scalars['String']>
  background_not_contains?: InputMaybe<Scalars['String']>
  background_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sections?: InputMaybe<CfsectionsMultiTypeNestedFilter>
  sectionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  variant?: InputMaybe<Scalars['String']>
  variant_contains?: InputMaybe<Scalars['String']>
  variant_exists?: InputMaybe<Scalars['Boolean']>
  variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  variant_not?: InputMaybe<Scalars['String']>
  variant_not_contains?: InputMaybe<Scalars['String']>
  variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage_ImageSection) */
export type ComponentTextImageImageSection = Entry &
  _Node & {
    __typename?: 'ComponentTextImageImageSection'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    frame?: Maybe<Scalars['String']>
    imageSet?: Maybe<Scalars['JSON']>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentTextImageImageSectionLinkingCollections>
    name?: Maybe<Scalars['String']>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage_ImageSection) */
export type ComponentTextImageImageSectionFrameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage_ImageSection) */
export type ComponentTextImageImageSectionImageSetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage_ImageSection) */
export type ComponentTextImageImageSectionInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage_ImageSection) */
export type ComponentTextImageImageSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage_ImageSection) */
export type ComponentTextImageImageSectionNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentTextImageImageSectionCollection = {
  __typename?: 'ComponentTextImageImageSectionCollection'
  items: Array<Maybe<ComponentTextImageImageSection>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentTextImageImageSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentTextImageImageSectionFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentTextImageImageSectionFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  frame?: InputMaybe<Scalars['String']>
  frame_contains?: InputMaybe<Scalars['String']>
  frame_exists?: InputMaybe<Scalars['Boolean']>
  frame_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  frame_not?: InputMaybe<Scalars['String']>
  frame_not_contains?: InputMaybe<Scalars['String']>
  frame_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  imageSet_exists?: InputMaybe<Scalars['Boolean']>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ComponentTextImageImageSectionLinkingCollections = {
  __typename?: 'ComponentTextImageImageSectionLinkingCollections'
  componentTextIllustrationCollection?: Maybe<ComponentTextIllustrationCollection>
  componentTextImageCollection?: Maybe<ComponentTextImageCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type ComponentTextImageImageSectionLinkingCollectionsComponentTextIllustrationCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTextImageImageSectionLinkingCollectionsComponentTextIllustrationCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTextImageImageSectionLinkingCollectionsComponentTextImageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTextImageImageSectionLinkingCollectionsComponentTextImageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTextImageImageSectionLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentTextImageImageSectionLinkingCollectionsComponentTextIllustrationCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTextImageImageSectionLinkingCollectionsComponentTextImageCollectionOrder {
  BackgroundAsc = 'background_ASC',
  BackgroundDesc = 'background_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export enum ComponentTextImageImageSectionOrder {
  FrameAsc = 'frame_ASC',
  FrameDesc = 'frame_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ComponentTextImageLinkingCollections = {
  __typename?: 'ComponentTextImageLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
}

export type ComponentTextImageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentTextImageLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentTextImageLinkingCollectionsPageLandingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ComponentTextImageLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage_ListSection) */
export type ComponentTextImageListSection = Entry &
  _Node & {
    __typename?: 'ComponentTextImageListSection'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    internalName?: Maybe<Scalars['String']>
    items?: Maybe<Array<Maybe<Scalars['String']>>>
    linkedFrom?: Maybe<ComponentTextImageListSectionLinkingCollections>
    name?: Maybe<Scalars['String']>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage_ListSection) */
export type ComponentTextImageListSectionInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage_ListSection) */
export type ComponentTextImageListSectionItemsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage_ListSection) */
export type ComponentTextImageListSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage_ListSection) */
export type ComponentTextImageListSectionNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentTextImageListSectionCollection = {
  __typename?: 'ComponentTextImageListSectionCollection'
  items: Array<Maybe<ComponentTextImageListSection>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentTextImageListSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentTextImageListSectionFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentTextImageListSectionFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  items_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  items_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  items_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  items_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ComponentTextImageListSectionLinkingCollections = {
  __typename?: 'ComponentTextImageListSectionLinkingCollections'
  componentTextImageCollection?: Maybe<ComponentTextImageCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type ComponentTextImageListSectionLinkingCollectionsComponentTextImageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTextImageListSectionLinkingCollectionsComponentTextImageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTextImageListSectionLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentTextImageListSectionLinkingCollectionsComponentTextImageCollectionOrder {
  BackgroundAsc = 'background_ASC',
  BackgroundDesc = 'background_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export enum ComponentTextImageListSectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentTextImageOrder {
  BackgroundAsc = 'background_ASC',
  BackgroundDesc = 'background_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export type ComponentTextImageSectionsCollection = {
  __typename?: 'ComponentTextImageSectionsCollection'
  items: Array<Maybe<ComponentTextImageSectionsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentTextImageSectionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentTextImageSectionsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentTextImageSectionsFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
}

export type ComponentTextImageSectionsItem =
  | CaisiqVideo
  | ComponentHubspotForm
  | ComponentPodcastPlayer
  | ComponentTextImageImageSection
  | ComponentTextImageListSection
  | ComponentTextImageTextSection

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage_TextSection) */
export type ComponentTextImageTextSection = Entry &
  _Node & {
    __typename?: 'ComponentTextImageTextSection'
    _id: Scalars['ID']
    body?: Maybe<ComponentTextImageTextSectionBody>
    buttonsCollection?: Maybe<ComponentTextImageTextSectionButtonsCollection>
    contentfulMetadata: ContentfulMetadata
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ComponentTextImageTextSectionLinkingCollections>
    subtitle?: Maybe<Scalars['String']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage_TextSection) */
export type ComponentTextImageTextSectionBodyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage_TextSection) */
export type ComponentTextImageTextSectionButtonsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentTextImageTextSectionButtonsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<LinkFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage_TextSection) */
export type ComponentTextImageTextSectionInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage_TextSection) */
export type ComponentTextImageTextSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage_TextSection) */
export type ComponentTextImageTextSectionSubtitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/componentTextImage_TextSection) */
export type ComponentTextImageTextSectionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ComponentTextImageTextSectionBody = {
  __typename?: 'ComponentTextImageTextSectionBody'
  json: Scalars['JSON']
  links: ComponentTextImageTextSectionBodyLinks
}

export type ComponentTextImageTextSectionBodyAssets = {
  __typename?: 'ComponentTextImageTextSectionBodyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentTextImageTextSectionBodyEntries = {
  __typename?: 'ComponentTextImageTextSectionBodyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentTextImageTextSectionBodyLinks = {
  __typename?: 'ComponentTextImageTextSectionBodyLinks'
  assets: ComponentTextImageTextSectionBodyAssets
  entries: ComponentTextImageTextSectionBodyEntries
  resources: ComponentTextImageTextSectionBodyResources
}

export type ComponentTextImageTextSectionBodyResources = {
  __typename?: 'ComponentTextImageTextSectionBodyResources'
  block: Array<ComponentTextImageTextSectionBodyResourcesBlock>
  hyperlink: Array<ComponentTextImageTextSectionBodyResourcesHyperlink>
  inline: Array<ComponentTextImageTextSectionBodyResourcesInline>
}

export type ComponentTextImageTextSectionBodyResourcesBlock = ResourceLink & {
  __typename?: 'ComponentTextImageTextSectionBodyResourcesBlock'
  sys: ResourceSys
}

export type ComponentTextImageTextSectionBodyResourcesHyperlink =
  ResourceLink & {
    __typename?: 'ComponentTextImageTextSectionBodyResourcesHyperlink'
    sys: ResourceSys
  }

export type ComponentTextImageTextSectionBodyResourcesInline = ResourceLink & {
  __typename?: 'ComponentTextImageTextSectionBodyResourcesInline'
  sys: ResourceSys
}

export type ComponentTextImageTextSectionButtonsCollection = {
  __typename?: 'ComponentTextImageTextSectionButtonsCollection'
  items: Array<Maybe<Link>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ComponentTextImageTextSectionButtonsCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  ExternalLinkAsc = 'externalLink_ASC',
  ExternalLinkDesc = 'externalLink_DESC',
  InternalLinkAsc = 'internalLink_ASC',
  InternalLinkDesc = 'internalLink_DESC',
  OpenInNewWindowAsc = 'openInNewWindow_ASC',
  OpenInNewWindowDesc = 'openInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ComponentTextImageTextSectionCollection = {
  __typename?: 'ComponentTextImageTextSectionCollection'
  items: Array<Maybe<ComponentTextImageTextSection>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ComponentTextImageTextSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ComponentTextImageTextSectionFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ComponentTextImageTextSectionFilter>>>
  body_contains?: InputMaybe<Scalars['String']>
  body_exists?: InputMaybe<Scalars['Boolean']>
  body_not_contains?: InputMaybe<Scalars['String']>
  buttons?: InputMaybe<CfLinkNestedFilter>
  buttonsCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subtitle?: InputMaybe<Scalars['String']>
  subtitle_contains?: InputMaybe<Scalars['String']>
  subtitle_exists?: InputMaybe<Scalars['Boolean']>
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subtitle_not?: InputMaybe<Scalars['String']>
  subtitle_not_contains?: InputMaybe<Scalars['String']>
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ComponentTextImageTextSectionLinkingCollections = {
  __typename?: 'ComponentTextImageTextSectionLinkingCollections'
  componentTextIllustrationCollection?: Maybe<ComponentTextIllustrationCollection>
  componentTextImageCollection?: Maybe<ComponentTextImageCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type ComponentTextImageTextSectionLinkingCollectionsComponentTextIllustrationCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTextImageTextSectionLinkingCollectionsComponentTextIllustrationCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTextImageTextSectionLinkingCollectionsComponentTextImageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTextImageTextSectionLinkingCollectionsComponentTextImageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTextImageTextSectionLinkingCollectionsEntryCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum ComponentTextImageTextSectionLinkingCollectionsComponentTextIllustrationCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ComponentTextImageTextSectionLinkingCollectionsComponentTextImageCollectionOrder {
  BackgroundAsc = 'background_ASC',
  BackgroundDesc = 'background_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export enum ComponentTextImageTextSectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ComponentTextLinkingCollections = {
  __typename?: 'ComponentTextLinkingCollections'
  componentSectionCollection?: Maybe<ComponentSectionCollection>
  entryCollection?: Maybe<EntryCollection>
  homepageContentSectionCollection?: Maybe<HomepageContentSectionCollection>
  pageHelpCenterArticleCollection?: Maybe<PageHelpCenterArticleCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
}

export type ComponentTextLinkingCollectionsComponentSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ComponentTextLinkingCollectionsComponentSectionCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentTextLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ComponentTextLinkingCollectionsHomepageContentSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTextLinkingCollectionsHomepageContentSectionCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTextLinkingCollectionsPageHelpCenterArticleCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ComponentTextLinkingCollectionsPageHelpCenterArticleCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ComponentTextLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ComponentTextLinkingCollectionsPageLandingCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ComponentTextLinkingCollectionsComponentSectionCollectionOrder {
  BookmarkIdAsc = 'bookmarkId_ASC',
  BookmarkIdDesc = 'bookmarkId_DESC',
  CardContainerOptionsAsc = 'cardContainerOptions_ASC',
  CardContainerOptionsDesc = 'cardContainerOptions_DESC',
  ComponentIdAsc = 'componentId_ASC',
  ComponentIdDesc = 'componentId_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentTextLinkingCollectionsHomepageContentSectionCollectionOrder {
  BookmarkIdAsc = 'bookmarkId_ASC',
  BookmarkIdDesc = 'bookmarkId_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  RemoveTopPaddingAsc = 'removeTopPadding_ASC',
  RemoveTopPaddingDesc = 'removeTopPadding_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum ComponentTextLinkingCollectionsPageHelpCenterArticleCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentTextLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ComponentTextOrder {
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ComponentTextText = {
  __typename?: 'ComponentTextText'
  json: Scalars['JSON']
  links: ComponentTextTextLinks
}

export type ComponentTextTextAssets = {
  __typename?: 'ComponentTextTextAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type ComponentTextTextEntries = {
  __typename?: 'ComponentTextTextEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type ComponentTextTextLinks = {
  __typename?: 'ComponentTextTextLinks'
  assets: ComponentTextTextAssets
  entries: ComponentTextTextEntries
  resources: ComponentTextTextResources
}

export type ComponentTextTextResources = {
  __typename?: 'ComponentTextTextResources'
  block: Array<ComponentTextTextResourcesBlock>
  hyperlink: Array<ComponentTextTextResourcesHyperlink>
  inline: Array<ComponentTextTextResourcesInline>
}

export type ComponentTextTextResourcesBlock = ResourceLink & {
  __typename?: 'ComponentTextTextResourcesBlock'
  sys: ResourceSys
}

export type ComponentTextTextResourcesHyperlink = ResourceLink & {
  __typename?: 'ComponentTextTextResourcesHyperlink'
  sys: ResourceSys
}

export type ComponentTextTextResourcesInline = ResourceLink & {
  __typename?: 'ComponentTextTextResourcesInline'
  sys: ResourceSys
}

export type ContentfulMetadata = {
  __typename?: 'ContentfulMetadata'
  concepts: Array<Maybe<TaxonomyConcept>>
  tags: Array<Maybe<ContentfulTag>>
}

export type ContentfulMetadataConceptsDescendantsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ContentfulMetadataConceptsFilter = {
  descendants?: InputMaybe<ContentfulMetadataConceptsDescendantsFilter>
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ContentfulMetadataFilter = {
  concepts?: InputMaybe<ContentfulMetadataConceptsFilter>
  concepts_exists?: InputMaybe<Scalars['Boolean']>
  tags?: InputMaybe<ContentfulMetadataTagsFilter>
  tags_exists?: InputMaybe<Scalars['Boolean']>
}

export type ContentfulMetadataTagsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/**
 * Represents a tag entity for finding and organizing content easily.
 *       Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
  __typename?: 'ContentfulTag'
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

/** Stores 3 "corporate" statistics [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/corporateStats) */
export type CorporateStats = Entry &
  _Node & {
    __typename?: 'CorporateStats'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    footnotes?: Maybe<Markdown>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<CorporateStatsLinkingCollections>
    name?: Maybe<Scalars['String']>
    stats?: Maybe<Scalars['JSON']>
    sys: Sys
  }

/** Stores 3 "corporate" statistics [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/corporateStats) */
export type CorporateStatsFootnotesArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<MarkdownFilter>
}

/** Stores 3 "corporate" statistics [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/corporateStats) */
export type CorporateStatsInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Stores 3 "corporate" statistics [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/corporateStats) */
export type CorporateStatsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Stores 3 "corporate" statistics [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/corporateStats) */
export type CorporateStatsNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Stores 3 "corporate" statistics [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/corporateStats) */
export type CorporateStatsStatsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type CorporateStatsCollection = {
  __typename?: 'CorporateStatsCollection'
  items: Array<Maybe<CorporateStats>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CorporateStatsFilter = {
  AND?: InputMaybe<Array<InputMaybe<CorporateStatsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CorporateStatsFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  footnotes?: InputMaybe<CfMarkdownNestedFilter>
  footnotes_exists?: InputMaybe<Scalars['Boolean']>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  stats_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type CorporateStatsLinkingCollections = {
  __typename?: 'CorporateStatsLinkingCollections'
  companyCollection?: Maybe<CompanyCollection>
  entryCollection?: Maybe<EntryCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
}

export type CorporateStatsLinkingCollectionsCompanyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CorporateStatsLinkingCollectionsCompanyCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CorporateStatsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type CorporateStatsLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<CorporateStatsLinkingCollectionsPageLandingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum CorporateStatsLinkingCollectionsCompanyCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CorporateStatsLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CorporateStatsOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/curatedFilterGroup) */
export type CuratedFilterGroup = Entry &
  _Node & {
    __typename?: 'CuratedFilterGroup'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    filtersCollection?: Maybe<CuratedFilterGroupFiltersCollection>
    internalName?: Maybe<Scalars['String']>
    label?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<CuratedFilterGroupLinkingCollections>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/curatedFilterGroup) */
export type CuratedFilterGroupFiltersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CuratedFilterGroupFiltersCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TagFamilyFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/curatedFilterGroup) */
export type CuratedFilterGroupInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/curatedFilterGroup) */
export type CuratedFilterGroupLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/curatedFilterGroup) */
export type CuratedFilterGroupLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CuratedFilterGroupCollection = {
  __typename?: 'CuratedFilterGroupCollection'
  items: Array<Maybe<CuratedFilterGroup>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CuratedFilterGroupFilter = {
  AND?: InputMaybe<Array<InputMaybe<CuratedFilterGroupFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CuratedFilterGroupFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  filters?: InputMaybe<CfTagFamilyNestedFilter>
  filtersCollection_exists?: InputMaybe<Scalars['Boolean']>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  label?: InputMaybe<Scalars['String']>
  label_contains?: InputMaybe<Scalars['String']>
  label_exists?: InputMaybe<Scalars['Boolean']>
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  label_not?: InputMaybe<Scalars['String']>
  label_not_contains?: InputMaybe<Scalars['String']>
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CuratedFilterGroupFiltersCollection = {
  __typename?: 'CuratedFilterGroupFiltersCollection'
  items: Array<Maybe<TagFamily>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CuratedFilterGroupFiltersCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagFamilyTypeAsc = 'tagFamilyType_ASC',
  TagFamilyTypeDesc = 'tagFamilyType_DESC',
}

export type CuratedFilterGroupLinkingCollections = {
  __typename?: 'CuratedFilterGroupLinkingCollections'
  curatedResearchContentCollection?: Maybe<CuratedResearchContentCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type CuratedFilterGroupLinkingCollectionsCuratedResearchContentCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<CuratedFilterGroupLinkingCollectionsCuratedResearchContentCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type CuratedFilterGroupLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum CuratedFilterGroupLinkingCollectionsCuratedResearchContentCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum CuratedFilterGroupOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Curated featured research content for the Homepage application [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/curatedResearchContent) */
export type CuratedResearchContent = Entry &
  _Node & {
    __typename?: 'CuratedResearchContent'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    curatedFilterGroupCollection?: Maybe<CuratedResearchContentCuratedFilterGroupCollection>
    id?: Maybe<Scalars['String']>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<CuratedResearchContentLinkingCollections>
    sys: Sys
  }

/** Curated featured research content for the Homepage application [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/curatedResearchContent) */
export type CuratedResearchContentCuratedFilterGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<CuratedResearchContentCuratedFilterGroupCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CuratedFilterGroupFilter>
}

/** Curated featured research content for the Homepage application [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/curatedResearchContent) */
export type CuratedResearchContentIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Curated featured research content for the Homepage application [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/curatedResearchContent) */
export type CuratedResearchContentInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Curated featured research content for the Homepage application [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/curatedResearchContent) */
export type CuratedResearchContentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CuratedResearchContentCollection = {
  __typename?: 'CuratedResearchContentCollection'
  items: Array<Maybe<CuratedResearchContent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type CuratedResearchContentCuratedFilterGroupCollection = {
  __typename?: 'CuratedResearchContentCuratedFilterGroupCollection'
  items: Array<Maybe<CuratedFilterGroup>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum CuratedResearchContentCuratedFilterGroupCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type CuratedResearchContentFilter = {
  AND?: InputMaybe<Array<InputMaybe<CuratedResearchContentFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CuratedResearchContentFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  curatedFilterGroup?: InputMaybe<CfCuratedFilterGroupNestedFilter>
  curatedFilterGroupCollection_exists?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['String']>
  id_contains?: InputMaybe<Scalars['String']>
  id_exists?: InputMaybe<Scalars['Boolean']>
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_not?: InputMaybe<Scalars['String']>
  id_not_contains?: InputMaybe<Scalars['String']>
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CuratedResearchContentLinkingCollections = {
  __typename?: 'CuratedResearchContentLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type CuratedResearchContentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum CuratedResearchContentOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/disclaimer) */
export type Disclaimer = Entry &
  _Node & {
    __typename?: 'Disclaimer'
    _id: Scalars['ID']
    body?: Maybe<DisclaimerBody>
    contentfulMetadata: ContentfulMetadata
    identifier?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<DisclaimerLinkingCollections>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/disclaimer) */
export type DisclaimerBodyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/disclaimer) */
export type DisclaimerIdentifierArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/disclaimer) */
export type DisclaimerLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/disclaimer) */
export type DisclaimerTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type DisclaimerBody = {
  __typename?: 'DisclaimerBody'
  json: Scalars['JSON']
  links: DisclaimerBodyLinks
}

export type DisclaimerBodyAssets = {
  __typename?: 'DisclaimerBodyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type DisclaimerBodyEntries = {
  __typename?: 'DisclaimerBodyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type DisclaimerBodyLinks = {
  __typename?: 'DisclaimerBodyLinks'
  assets: DisclaimerBodyAssets
  entries: DisclaimerBodyEntries
  resources: DisclaimerBodyResources
}

export type DisclaimerBodyResources = {
  __typename?: 'DisclaimerBodyResources'
  block: Array<DisclaimerBodyResourcesBlock>
  hyperlink: Array<DisclaimerBodyResourcesHyperlink>
  inline: Array<DisclaimerBodyResourcesInline>
}

export type DisclaimerBodyResourcesBlock = ResourceLink & {
  __typename?: 'DisclaimerBodyResourcesBlock'
  sys: ResourceSys
}

export type DisclaimerBodyResourcesHyperlink = ResourceLink & {
  __typename?: 'DisclaimerBodyResourcesHyperlink'
  sys: ResourceSys
}

export type DisclaimerBodyResourcesInline = ResourceLink & {
  __typename?: 'DisclaimerBodyResourcesInline'
  sys: ResourceSys
}

export type DisclaimerCollection = {
  __typename?: 'DisclaimerCollection'
  items: Array<Maybe<Disclaimer>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type DisclaimerFilter = {
  AND?: InputMaybe<Array<InputMaybe<DisclaimerFilter>>>
  OR?: InputMaybe<Array<InputMaybe<DisclaimerFilter>>>
  body_contains?: InputMaybe<Scalars['String']>
  body_exists?: InputMaybe<Scalars['Boolean']>
  body_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  identifier?: InputMaybe<Scalars['String']>
  identifier_contains?: InputMaybe<Scalars['String']>
  identifier_exists?: InputMaybe<Scalars['Boolean']>
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  identifier_not?: InputMaybe<Scalars['String']>
  identifier_not_contains?: InputMaybe<Scalars['String']>
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type DisclaimerLinkingCollections = {
  __typename?: 'DisclaimerLinkingCollections'
  caisiqHomeOfficeCollection?: Maybe<CaisiqHomeOfficeCollection>
  caisiqTabCollection?: Maybe<CaisiqTabCollection>
  entryCollection?: Maybe<EntryCollection>
  firmCollection?: Maybe<FirmCollection>
  homepageLandingPageCollection?: Maybe<HomepageLandingPageCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
}

export type DisclaimerLinkingCollectionsCaisiqHomeOfficeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<DisclaimerLinkingCollectionsCaisiqHomeOfficeCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type DisclaimerLinkingCollectionsCaisiqTabCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<DisclaimerLinkingCollectionsCaisiqTabCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type DisclaimerLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type DisclaimerLinkingCollectionsFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<DisclaimerLinkingCollectionsFirmCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type DisclaimerLinkingCollectionsHomepageLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<DisclaimerLinkingCollectionsHomepageLandingPageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type DisclaimerLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<DisclaimerLinkingCollectionsPageLandingCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum DisclaimerLinkingCollectionsCaisiqHomeOfficeCollectionOrder {
  HasSeparatorAsc = 'hasSeparator_ASC',
  HasSeparatorDesc = 'hasSeparator_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  PathAsc = 'path_ASC',
  PathDesc = 'path_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum DisclaimerLinkingCollectionsCaisiqTabCollectionOrder {
  HasSeparatorAsc = 'hasSeparator_ASC',
  HasSeparatorDesc = 'hasSeparator_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  PathAsc = 'path_ASC',
  PathDesc = 'path_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum DisclaimerLinkingCollectionsFirmCollectionOrder {
  CeCreditsAvailableAsc = 'ceCreditsAvailable_ASC',
  CeCreditsAvailableDesc = 'ceCreditsAvailable_DESC',
  FirmIdAsc = 'firmId_ASC',
  FirmIdDesc = 'firmId_DESC',
  HeaderTypeAsc = 'headerType_ASC',
  HeaderTypeDesc = 'headerType_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum DisclaimerLinkingCollectionsHomepageLandingPageCollectionOrder {
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum DisclaimerLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum DisclaimerOrder {
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type Entry = {
  contentfulMetadata: ContentfulMetadata
  sys: Sys
}

export type EntryCollection = {
  __typename?: 'EntryCollection'
  items: Array<Maybe<Entry>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type EntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<EntryFilter>>>
  OR?: InputMaybe<Array<InputMaybe<EntryFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
}

export enum EntryOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Create events such as conferences and webinars [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/event) */
export type Event = Entry &
  _Node & {
    __typename?: 'Event'
    _id: Scalars['ID']
    allDay?: Maybe<Scalars['Boolean']>
    byCais?: Maybe<Scalars['Boolean']>
    contentfulMetadata: ContentfulMetadata
    customDescription?: Maybe<Scalars['String']>
    customTitle?: Maybe<Scalars['String']>
    dateTime?: Maybe<Scalars['DateTime']>
    dateTimeEnd?: Maybe<Scalars['DateTime']>
    description?: Maybe<Scalars['String']>
    eventThumbnail?: Maybe<Scalars['JSON']>
    eventType?: Maybe<Scalars['String']>
    firmsCollection?: Maybe<EventFirmsCollection>
    fundProductIds?: Maybe<FundProductId>
    internalName?: Maybe<Scalars['String']>
    link?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<EventLinkingCollections>
    locationName?: Maybe<Scalars['String']>
    status?: Maybe<Scalars['String']>
    sys: Sys
    tagsCollection?: Maybe<EventTagsCollection>
    testExcludedFirmsCollection?: Maybe<EventTestExcludedFirmsCollection>
    timezone?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
  }

/** Create events such as conferences and webinars [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/event) */
export type EventAllDayArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create events such as conferences and webinars [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/event) */
export type EventByCaisArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create events such as conferences and webinars [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/event) */
export type EventCustomDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create events such as conferences and webinars [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/event) */
export type EventCustomTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create events such as conferences and webinars [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/event) */
export type EventDateTimeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create events such as conferences and webinars [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/event) */
export type EventDateTimeEndArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create events such as conferences and webinars [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/event) */
export type EventDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create events such as conferences and webinars [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/event) */
export type EventEventThumbnailArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create events such as conferences and webinars [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/event) */
export type EventEventTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create events such as conferences and webinars [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/event) */
export type EventFirmsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<EventFirmsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MemberFirmFilter>
}

/** Create events such as conferences and webinars [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/event) */
export type EventFundProductIdsArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<FundProductIdFilter>
}

/** Create events such as conferences and webinars [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/event) */
export type EventInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create events such as conferences and webinars [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/event) */
export type EventLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create events such as conferences and webinars [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/event) */
export type EventLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create events such as conferences and webinars [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/event) */
export type EventLocationNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create events such as conferences and webinars [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/event) */
export type EventStatusArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create events such as conferences and webinars [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/event) */
export type EventTagsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<EventTagsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TagFilter>
}

/** Create events such as conferences and webinars [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/event) */
export type EventTestExcludedFirmsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<EventTestExcludedFirmsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MemberFirmFilter>
}

/** Create events such as conferences and webinars [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/event) */
export type EventTimezoneArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create events such as conferences and webinars [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/event) */
export type EventTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type EventCollection = {
  __typename?: 'EventCollection'
  items: Array<Maybe<Event>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type EventFilter = {
  AND?: InputMaybe<Array<InputMaybe<EventFilter>>>
  OR?: InputMaybe<Array<InputMaybe<EventFilter>>>
  allDay?: InputMaybe<Scalars['Boolean']>
  allDay_exists?: InputMaybe<Scalars['Boolean']>
  allDay_not?: InputMaybe<Scalars['Boolean']>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  dateTime?: InputMaybe<Scalars['DateTime']>
  dateTimeEnd?: InputMaybe<Scalars['DateTime']>
  dateTimeEnd_exists?: InputMaybe<Scalars['Boolean']>
  dateTimeEnd_gt?: InputMaybe<Scalars['DateTime']>
  dateTimeEnd_gte?: InputMaybe<Scalars['DateTime']>
  dateTimeEnd_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  dateTimeEnd_lt?: InputMaybe<Scalars['DateTime']>
  dateTimeEnd_lte?: InputMaybe<Scalars['DateTime']>
  dateTimeEnd_not?: InputMaybe<Scalars['DateTime']>
  dateTimeEnd_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  dateTime_exists?: InputMaybe<Scalars['Boolean']>
  dateTime_gt?: InputMaybe<Scalars['DateTime']>
  dateTime_gte?: InputMaybe<Scalars['DateTime']>
  dateTime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  dateTime_lt?: InputMaybe<Scalars['DateTime']>
  dateTime_lte?: InputMaybe<Scalars['DateTime']>
  dateTime_not?: InputMaybe<Scalars['DateTime']>
  dateTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eventThumbnail_exists?: InputMaybe<Scalars['Boolean']>
  eventType?: InputMaybe<Scalars['String']>
  eventType_contains?: InputMaybe<Scalars['String']>
  eventType_exists?: InputMaybe<Scalars['Boolean']>
  eventType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eventType_not?: InputMaybe<Scalars['String']>
  eventType_not_contains?: InputMaybe<Scalars['String']>
  eventType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firms?: InputMaybe<CfMemberFirmNestedFilter>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  fundProductIds?: InputMaybe<CfFundProductIdNestedFilter>
  fundProductIds_exists?: InputMaybe<Scalars['Boolean']>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  link?: InputMaybe<Scalars['String']>
  link_contains?: InputMaybe<Scalars['String']>
  link_exists?: InputMaybe<Scalars['Boolean']>
  link_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  link_not?: InputMaybe<Scalars['String']>
  link_not_contains?: InputMaybe<Scalars['String']>
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  locationName?: InputMaybe<Scalars['String']>
  locationName_contains?: InputMaybe<Scalars['String']>
  locationName_exists?: InputMaybe<Scalars['Boolean']>
  locationName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  locationName_not?: InputMaybe<Scalars['String']>
  locationName_not_contains?: InputMaybe<Scalars['String']>
  locationName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  status?: InputMaybe<Scalars['String']>
  status_contains?: InputMaybe<Scalars['String']>
  status_exists?: InputMaybe<Scalars['Boolean']>
  status_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  status_not?: InputMaybe<Scalars['String']>
  status_not_contains?: InputMaybe<Scalars['String']>
  status_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tags?: InputMaybe<CfTagNestedFilter>
  tagsCollection_exists?: InputMaybe<Scalars['Boolean']>
  testExcludedFirms?: InputMaybe<CfMemberFirmNestedFilter>
  testExcludedFirmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  timezone?: InputMaybe<Scalars['String']>
  timezone_contains?: InputMaybe<Scalars['String']>
  timezone_exists?: InputMaybe<Scalars['Boolean']>
  timezone_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  timezone_not?: InputMaybe<Scalars['String']>
  timezone_not_contains?: InputMaybe<Scalars['String']>
  timezone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type EventFirmsCollection = {
  __typename?: 'EventFirmsCollection'
  items: Array<Maybe<MemberFirm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum EventFirmsCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type EventLinkingCollections = {
  __typename?: 'EventLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  featuredContentCollection?: Maybe<FeaturedContentCollection>
  homepageAppMetadataCollection?: Maybe<HomepageAppMetadataCollection>
  memberFirmCollection?: Maybe<MemberFirmCollection>
}

export type EventLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type EventLinkingCollectionsFeaturedContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<EventLinkingCollectionsFeaturedContentCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type EventLinkingCollectionsHomepageAppMetadataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<EventLinkingCollectionsHomepageAppMetadataCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type EventLinkingCollectionsMemberFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<EventLinkingCollectionsMemberFirmCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum EventLinkingCollectionsFeaturedContentCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum EventLinkingCollectionsHomepageAppMetadataCollectionOrder {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum EventLinkingCollectionsMemberFirmCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum EventOrder {
  AllDayAsc = 'allDay_ASC',
  AllDayDesc = 'allDay_DESC',
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DateTimeEndAsc = 'dateTimeEnd_ASC',
  DateTimeEndDesc = 'dateTimeEnd_DESC',
  DateTimeAsc = 'dateTime_ASC',
  DateTimeDesc = 'dateTime_DESC',
  EventTypeAsc = 'eventType_ASC',
  EventTypeDesc = 'eventType_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  LocationNameAsc = 'locationName_ASC',
  LocationNameDesc = 'locationName_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimezoneAsc = 'timezone_ASC',
  TimezoneDesc = 'timezone_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type EventTagsCollection = {
  __typename?: 'EventTagsCollection'
  items: Array<Maybe<Tag>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum EventTagsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagIdAsc = 'tagId_ASC',
  TagIdDesc = 'tagId_DESC',
}

export type EventTestExcludedFirmsCollection = {
  __typename?: 'EventTestExcludedFirmsCollection'
  items: Array<Maybe<MemberFirm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum EventTestExcludedFirmsCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** A content type that contains fields to combine with data from content that is external from Contentful. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/externalContent) */
export type ExternalContent = Entry &
  _Node & {
    __typename?: 'ExternalContent'
    _id: Scalars['ID']
    byCais?: Maybe<Scalars['Boolean']>
    category?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    customDescription?: Maybe<Scalars['String']>
    customTitle?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    displayDate?: Maybe<Scalars['DateTime']>
    eligibility?: Maybe<Scalars['String']>
    externalId?: Maybe<Scalars['String']>
    externalLink?: Maybe<Scalars['String']>
    firmsCollection?: Maybe<ExternalContentFirmsCollection>
    fundProductIds?: Maybe<FundProductId>
    linkedFrom?: Maybe<ExternalContentLinkingCollections>
    minimumInitialInvestment?: Maybe<Scalars['Float']>
    strategy?: Maybe<Scalars['String']>
    subStrategy?: Maybe<Scalars['String']>
    sys: Sys
    tagsCollection?: Maybe<ExternalContentTagsCollection>
    testExcludedFirmsCollection?: Maybe<ExternalContentTestExcludedFirmsCollection>
    thumbnail?: Maybe<Asset>
    title?: Maybe<Scalars['String']>
    type?: Maybe<Scalars['String']>
  }

/** A content type that contains fields to combine with data from content that is external from Contentful. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/externalContent) */
export type ExternalContentByCaisArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content type that contains fields to combine with data from content that is external from Contentful. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/externalContent) */
export type ExternalContentCategoryArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content type that contains fields to combine with data from content that is external from Contentful. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/externalContent) */
export type ExternalContentCustomDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content type that contains fields to combine with data from content that is external from Contentful. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/externalContent) */
export type ExternalContentCustomTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content type that contains fields to combine with data from content that is external from Contentful. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/externalContent) */
export type ExternalContentDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content type that contains fields to combine with data from content that is external from Contentful. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/externalContent) */
export type ExternalContentDisplayDateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content type that contains fields to combine with data from content that is external from Contentful. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/externalContent) */
export type ExternalContentEligibilityArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content type that contains fields to combine with data from content that is external from Contentful. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/externalContent) */
export type ExternalContentExternalIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content type that contains fields to combine with data from content that is external from Contentful. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/externalContent) */
export type ExternalContentExternalLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content type that contains fields to combine with data from content that is external from Contentful. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/externalContent) */
export type ExternalContentFirmsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ExternalContentFirmsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MemberFirmFilter>
}

/** A content type that contains fields to combine with data from content that is external from Contentful. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/externalContent) */
export type ExternalContentFundProductIdsArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<FundProductIdFilter>
}

/** A content type that contains fields to combine with data from content that is external from Contentful. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/externalContent) */
export type ExternalContentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A content type that contains fields to combine with data from content that is external from Contentful. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/externalContent) */
export type ExternalContentMinimumInitialInvestmentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content type that contains fields to combine with data from content that is external from Contentful. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/externalContent) */
export type ExternalContentStrategyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content type that contains fields to combine with data from content that is external from Contentful. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/externalContent) */
export type ExternalContentSubStrategyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content type that contains fields to combine with data from content that is external from Contentful. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/externalContent) */
export type ExternalContentTagsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ExternalContentTagsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TagFilter>
}

/** A content type that contains fields to combine with data from content that is external from Contentful. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/externalContent) */
export type ExternalContentTestExcludedFirmsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ExternalContentTestExcludedFirmsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MemberFirmFilter>
}

/** A content type that contains fields to combine with data from content that is external from Contentful. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/externalContent) */
export type ExternalContentThumbnailArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** A content type that contains fields to combine with data from content that is external from Contentful. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/externalContent) */
export type ExternalContentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content type that contains fields to combine with data from content that is external from Contentful. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/externalContent) */
export type ExternalContentTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ExternalContentCollection = {
  __typename?: 'ExternalContentCollection'
  items: Array<Maybe<ExternalContent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ExternalContentFilter = {
  AND?: InputMaybe<Array<InputMaybe<ExternalContentFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ExternalContentFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['String']>
  category_contains?: InputMaybe<Scalars['String']>
  category_exists?: InputMaybe<Scalars['Boolean']>
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category_not?: InputMaybe<Scalars['String']>
  category_not_contains?: InputMaybe<Scalars['String']>
  category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayDate?: InputMaybe<Scalars['DateTime']>
  displayDate_exists?: InputMaybe<Scalars['Boolean']>
  displayDate_gt?: InputMaybe<Scalars['DateTime']>
  displayDate_gte?: InputMaybe<Scalars['DateTime']>
  displayDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  displayDate_lt?: InputMaybe<Scalars['DateTime']>
  displayDate_lte?: InputMaybe<Scalars['DateTime']>
  displayDate_not?: InputMaybe<Scalars['DateTime']>
  displayDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  eligibility?: InputMaybe<Scalars['String']>
  eligibility_contains?: InputMaybe<Scalars['String']>
  eligibility_exists?: InputMaybe<Scalars['Boolean']>
  eligibility_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eligibility_not?: InputMaybe<Scalars['String']>
  eligibility_not_contains?: InputMaybe<Scalars['String']>
  eligibility_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalId?: InputMaybe<Scalars['String']>
  externalId_contains?: InputMaybe<Scalars['String']>
  externalId_exists?: InputMaybe<Scalars['Boolean']>
  externalId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalId_not?: InputMaybe<Scalars['String']>
  externalId_not_contains?: InputMaybe<Scalars['String']>
  externalId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalLink?: InputMaybe<Scalars['String']>
  externalLink_contains?: InputMaybe<Scalars['String']>
  externalLink_exists?: InputMaybe<Scalars['Boolean']>
  externalLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalLink_not?: InputMaybe<Scalars['String']>
  externalLink_not_contains?: InputMaybe<Scalars['String']>
  externalLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firms?: InputMaybe<CfMemberFirmNestedFilter>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  fundProductIds?: InputMaybe<CfFundProductIdNestedFilter>
  fundProductIds_exists?: InputMaybe<Scalars['Boolean']>
  minimumInitialInvestment?: InputMaybe<Scalars['Float']>
  minimumInitialInvestment_exists?: InputMaybe<Scalars['Boolean']>
  minimumInitialInvestment_gt?: InputMaybe<Scalars['Float']>
  minimumInitialInvestment_gte?: InputMaybe<Scalars['Float']>
  minimumInitialInvestment_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  minimumInitialInvestment_lt?: InputMaybe<Scalars['Float']>
  minimumInitialInvestment_lte?: InputMaybe<Scalars['Float']>
  minimumInitialInvestment_not?: InputMaybe<Scalars['Float']>
  minimumInitialInvestment_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']>>
  >
  strategy?: InputMaybe<Scalars['String']>
  strategy_contains?: InputMaybe<Scalars['String']>
  strategy_exists?: InputMaybe<Scalars['Boolean']>
  strategy_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  strategy_not?: InputMaybe<Scalars['String']>
  strategy_not_contains?: InputMaybe<Scalars['String']>
  strategy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subStrategy?: InputMaybe<Scalars['String']>
  subStrategy_contains?: InputMaybe<Scalars['String']>
  subStrategy_exists?: InputMaybe<Scalars['Boolean']>
  subStrategy_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subStrategy_not?: InputMaybe<Scalars['String']>
  subStrategy_not_contains?: InputMaybe<Scalars['String']>
  subStrategy_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tags?: InputMaybe<CfTagNestedFilter>
  tagsCollection_exists?: InputMaybe<Scalars['Boolean']>
  testExcludedFirms?: InputMaybe<CfMemberFirmNestedFilter>
  testExcludedFirmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  thumbnail_exists?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type?: InputMaybe<Scalars['String']>
  type_contains?: InputMaybe<Scalars['String']>
  type_exists?: InputMaybe<Scalars['Boolean']>
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type_not?: InputMaybe<Scalars['String']>
  type_not_contains?: InputMaybe<Scalars['String']>
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ExternalContentFirmsCollection = {
  __typename?: 'ExternalContentFirmsCollection'
  items: Array<Maybe<MemberFirm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ExternalContentFirmsCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type ExternalContentLinkingCollections = {
  __typename?: 'ExternalContentLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  featuredContentCollection?: Maybe<FeaturedContentCollection>
  homepageAppMetadataCollection?: Maybe<HomepageAppMetadataCollection>
  memberFirmCollection?: Maybe<MemberFirmCollection>
}

export type ExternalContentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ExternalContentLinkingCollectionsFeaturedContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ExternalContentLinkingCollectionsFeaturedContentCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ExternalContentLinkingCollectionsHomepageAppMetadataCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<ExternalContentLinkingCollectionsHomepageAppMetadataCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type ExternalContentLinkingCollectionsMemberFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<ExternalContentLinkingCollectionsMemberFirmCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ExternalContentLinkingCollectionsFeaturedContentCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ExternalContentLinkingCollectionsHomepageAppMetadataCollectionOrder {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ExternalContentLinkingCollectionsMemberFirmCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ExternalContentOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  EligibilityAsc = 'eligibility_ASC',
  EligibilityDesc = 'eligibility_DESC',
  ExternalIdAsc = 'externalId_ASC',
  ExternalIdDesc = 'externalId_DESC',
  ExternalLinkAsc = 'externalLink_ASC',
  ExternalLinkDesc = 'externalLink_DESC',
  MinimumInitialInvestmentAsc = 'minimumInitialInvestment_ASC',
  MinimumInitialInvestmentDesc = 'minimumInitialInvestment_DESC',
  StrategyAsc = 'strategy_ASC',
  StrategyDesc = 'strategy_DESC',
  SubStrategyAsc = 'subStrategy_ASC',
  SubStrategyDesc = 'subStrategy_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type ExternalContentTagsCollection = {
  __typename?: 'ExternalContentTagsCollection'
  items: Array<Maybe<Tag>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ExternalContentTagsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagIdAsc = 'tagId_ASC',
  TagIdDesc = 'tagId_DESC',
}

export type ExternalContentTestExcludedFirmsCollection = {
  __typename?: 'ExternalContentTestExcludedFirmsCollection'
  items: Array<Maybe<MemberFirm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum ExternalContentTestExcludedFirmsCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Display a FAQ and answer [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/faq) */
export type Faq = Entry &
  _Node & {
    __typename?: 'Faq'
    _id: Scalars['ID']
    answer?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    faq?: Maybe<Scalars['String']>
    id?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<FaqLinkingCollections>
    sys: Sys
  }

/** Display a FAQ and answer [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/faq) */
export type FaqAnswerArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Display a FAQ and answer [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/faq) */
export type FaqFaqArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Display a FAQ and answer [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/faq) */
export type FaqIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Display a FAQ and answer [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/faq) */
export type FaqLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create a FAQ category and related FAQs [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/faqCategory) */
export type FaqCategory = Entry &
  _Node & {
    __typename?: 'FaqCategory'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    faqsCollection?: Maybe<FaqCategoryFaqsCollection>
    id?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<FaqCategoryLinkingCollections>
    sortOrder?: Maybe<Scalars['Int']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Create a FAQ category and related FAQs [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/faqCategory) */
export type FaqCategoryFaqsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<FaqCategoryFaqsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FaqFilter>
}

/** Create a FAQ category and related FAQs [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/faqCategory) */
export type FaqCategoryIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create a FAQ category and related FAQs [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/faqCategory) */
export type FaqCategoryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create a FAQ category and related FAQs [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/faqCategory) */
export type FaqCategorySortOrderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create a FAQ category and related FAQs [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/faqCategory) */
export type FaqCategoryTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type FaqCategoryCollection = {
  __typename?: 'FaqCategoryCollection'
  items: Array<Maybe<FaqCategory>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type FaqCategoryFaqsCollection = {
  __typename?: 'FaqCategoryFaqsCollection'
  items: Array<Maybe<Faq>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum FaqCategoryFaqsCollectionOrder {
  FaqAsc = 'faq_ASC',
  FaqDesc = 'faq_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type FaqCategoryFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqCategoryFilter>>>
  OR?: InputMaybe<Array<InputMaybe<FaqCategoryFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  faqs?: InputMaybe<CfFaqNestedFilter>
  faqsCollection_exists?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['String']>
  id_contains?: InputMaybe<Scalars['String']>
  id_exists?: InputMaybe<Scalars['Boolean']>
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_not?: InputMaybe<Scalars['String']>
  id_not_contains?: InputMaybe<Scalars['String']>
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sortOrder?: InputMaybe<Scalars['Int']>
  sortOrder_exists?: InputMaybe<Scalars['Boolean']>
  sortOrder_gt?: InputMaybe<Scalars['Int']>
  sortOrder_gte?: InputMaybe<Scalars['Int']>
  sortOrder_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  sortOrder_lt?: InputMaybe<Scalars['Int']>
  sortOrder_lte?: InputMaybe<Scalars['Int']>
  sortOrder_not?: InputMaybe<Scalars['Int']>
  sortOrder_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type FaqCategoryLinkingCollections = {
  __typename?: 'FaqCategoryLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type FaqCategoryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum FaqCategoryOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SortOrderAsc = 'sortOrder_ASC',
  SortOrderDesc = 'sortOrder_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FaqCollection = {
  __typename?: 'FaqCollection'
  items: Array<Maybe<Faq>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type FaqFilter = {
  AND?: InputMaybe<Array<InputMaybe<FaqFilter>>>
  OR?: InputMaybe<Array<InputMaybe<FaqFilter>>>
  answer?: InputMaybe<Scalars['String']>
  answer_contains?: InputMaybe<Scalars['String']>
  answer_exists?: InputMaybe<Scalars['Boolean']>
  answer_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  answer_not?: InputMaybe<Scalars['String']>
  answer_not_contains?: InputMaybe<Scalars['String']>
  answer_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  faq?: InputMaybe<Scalars['String']>
  faq_contains?: InputMaybe<Scalars['String']>
  faq_exists?: InputMaybe<Scalars['Boolean']>
  faq_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  faq_not?: InputMaybe<Scalars['String']>
  faq_not_contains?: InputMaybe<Scalars['String']>
  faq_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id?: InputMaybe<Scalars['String']>
  id_contains?: InputMaybe<Scalars['String']>
  id_exists?: InputMaybe<Scalars['Boolean']>
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_not?: InputMaybe<Scalars['String']>
  id_not_contains?: InputMaybe<Scalars['String']>
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type FaqLinkingCollections = {
  __typename?: 'FaqLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  faqCategoryCollection?: Maybe<FaqCategoryCollection>
}

export type FaqLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FaqLinkingCollectionsFaqCategoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<FaqLinkingCollectionsFaqCategoryCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum FaqLinkingCollectionsFaqCategoryCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SortOrderAsc = 'sortOrder_ASC',
  SortOrderDesc = 'sortOrder_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FaqOrder {
  FaqAsc = 'faq_ASC',
  FaqDesc = 'faq_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Curated featured content for the Homepage application [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/featuredContent) */
export type FeaturedContent = Entry &
  _Node & {
    __typename?: 'FeaturedContent'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    homepageDefault?: Maybe<FeaturedContentHomepageDefault>
    homepageOptionsCollection?: Maybe<FeaturedContentHomepageOptionsCollection>
    id?: Maybe<Scalars['String']>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<FeaturedContentLinkingCollections>
    researchDefaultCollection?: Maybe<FeaturedContentResearchDefaultCollection>
    researchOptionsCollection?: Maybe<FeaturedContentResearchOptionsCollection>
    sys: Sys
    tutorialsDefaultCollection?: Maybe<FeaturedContentTutorialsDefaultCollection>
    tutorialsOptionsCollection?: Maybe<FeaturedContentTutorialsOptionsCollection>
    webinarsDefault?: Maybe<FeaturedContentWebinarsDefault>
    webinarsOptionsCollection?: Maybe<FeaturedContentWebinarsOptionsCollection>
  }

/** Curated featured content for the Homepage application [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/featuredContent) */
export type FeaturedContentHomepageDefaultArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<FeaturedContentHomepageDefaultFilter>
}

/** Curated featured content for the Homepage application [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/featuredContent) */
export type FeaturedContentHomepageOptionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FeaturedContentHomepageOptionsFilter>
}

/** Curated featured content for the Homepage application [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/featuredContent) */
export type FeaturedContentIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Curated featured content for the Homepage application [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/featuredContent) */
export type FeaturedContentInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Curated featured content for the Homepage application [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/featuredContent) */
export type FeaturedContentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Curated featured content for the Homepage application [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/featuredContent) */
export type FeaturedContentResearchDefaultCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FeaturedContentResearchDefaultFilter>
}

/** Curated featured content for the Homepage application [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/featuredContent) */
export type FeaturedContentResearchOptionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FeaturedContentResearchOptionsFilter>
}

/** Curated featured content for the Homepage application [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/featuredContent) */
export type FeaturedContentTutorialsDefaultCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FeaturedContentTutorialsDefaultFilter>
}

/** Curated featured content for the Homepage application [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/featuredContent) */
export type FeaturedContentTutorialsOptionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FeaturedContentTutorialsOptionsFilter>
}

/** Curated featured content for the Homepage application [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/featuredContent) */
export type FeaturedContentWebinarsDefaultArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<FeaturedContentWebinarsDefaultFilter>
}

/** Curated featured content for the Homepage application [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/featuredContent) */
export type FeaturedContentWebinarsOptionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FeaturedContentWebinarsOptionsFilter>
}

export type FeaturedContentCollection = {
  __typename?: 'FeaturedContentCollection'
  items: Array<Maybe<FeaturedContent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type FeaturedContentFilter = {
  AND?: InputMaybe<Array<InputMaybe<FeaturedContentFilter>>>
  OR?: InputMaybe<Array<InputMaybe<FeaturedContentFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  homepageDefault?: InputMaybe<CfhomepageDefaultMultiTypeNestedFilter>
  homepageDefault_exists?: InputMaybe<Scalars['Boolean']>
  homepageOptions?: InputMaybe<CfhomepageOptionsMultiTypeNestedFilter>
  homepageOptionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['String']>
  id_contains?: InputMaybe<Scalars['String']>
  id_exists?: InputMaybe<Scalars['Boolean']>
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_not?: InputMaybe<Scalars['String']>
  id_not_contains?: InputMaybe<Scalars['String']>
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  researchDefault?: InputMaybe<CfresearchDefaultMultiTypeNestedFilter>
  researchDefaultCollection_exists?: InputMaybe<Scalars['Boolean']>
  researchOptions?: InputMaybe<CfresearchOptionsMultiTypeNestedFilter>
  researchOptionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  tutorialsDefault?: InputMaybe<CftutorialsDefaultMultiTypeNestedFilter>
  tutorialsDefaultCollection_exists?: InputMaybe<Scalars['Boolean']>
  tutorialsOptions?: InputMaybe<CftutorialsOptionsMultiTypeNestedFilter>
  tutorialsOptionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  webinarsDefault?: InputMaybe<CfwebinarsDefaultMultiTypeNestedFilter>
  webinarsDefault_exists?: InputMaybe<Scalars['Boolean']>
  webinarsOptions?: InputMaybe<CfwebinarsOptionsMultiTypeNestedFilter>
  webinarsOptionsCollection_exists?: InputMaybe<Scalars['Boolean']>
}

export type FeaturedContentHomepageDefault =
  | CaisiqArticle
  | CaisiqVideo
  | CaisiqVideoPlaylist
  | Event
  | ExternalContent
  | Link
  | Whitepaper

export type FeaturedContentHomepageDefaultFilter = {
  AND?: InputMaybe<Array<InputMaybe<FeaturedContentHomepageDefaultFilter>>>
  OR?: InputMaybe<Array<InputMaybe<FeaturedContentHomepageDefaultFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type FeaturedContentHomepageOptionsCollection = {
  __typename?: 'FeaturedContentHomepageOptionsCollection'
  items: Array<Maybe<FeaturedContentHomepageOptionsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type FeaturedContentHomepageOptionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<FeaturedContentHomepageOptionsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<FeaturedContentHomepageOptionsFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type FeaturedContentHomepageOptionsItem =
  | CaisiqArticle
  | CaisiqVideo
  | CaisiqVideoPlaylist
  | Event
  | ExternalContent
  | Link
  | Whitepaper

export type FeaturedContentLinkingCollections = {
  __typename?: 'FeaturedContentLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type FeaturedContentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum FeaturedContentOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type FeaturedContentResearchDefaultCollection = {
  __typename?: 'FeaturedContentResearchDefaultCollection'
  items: Array<Maybe<FeaturedContentResearchDefaultItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type FeaturedContentResearchDefaultFilter = {
  AND?: InputMaybe<Array<InputMaybe<FeaturedContentResearchDefaultFilter>>>
  OR?: InputMaybe<Array<InputMaybe<FeaturedContentResearchDefaultFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['String']>
  category_contains?: InputMaybe<Scalars['String']>
  category_exists?: InputMaybe<Scalars['Boolean']>
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category_not?: InputMaybe<Scalars['String']>
  category_not_contains?: InputMaybe<Scalars['String']>
  category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type FeaturedContentResearchDefaultItem =
  | CaisiqArticle
  | CaisiqVideo
  | CaisiqVideoPlaylist
  | ExternalContent
  | Link
  | Whitepaper

export type FeaturedContentResearchOptionsCollection = {
  __typename?: 'FeaturedContentResearchOptionsCollection'
  items: Array<Maybe<FeaturedContentResearchOptionsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type FeaturedContentResearchOptionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<FeaturedContentResearchOptionsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<FeaturedContentResearchOptionsFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['String']>
  category_contains?: InputMaybe<Scalars['String']>
  category_exists?: InputMaybe<Scalars['Boolean']>
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category_not?: InputMaybe<Scalars['String']>
  category_not_contains?: InputMaybe<Scalars['String']>
  category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type FeaturedContentResearchOptionsItem =
  | CaisiqArticle
  | CaisiqVideo
  | CaisiqVideoPlaylist
  | ExternalContent
  | Link
  | Whitepaper

export type FeaturedContentTutorialsDefaultCollection = {
  __typename?: 'FeaturedContentTutorialsDefaultCollection'
  items: Array<Maybe<FeaturedContentTutorialsDefaultItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type FeaturedContentTutorialsDefaultFilter = {
  AND?: InputMaybe<Array<InputMaybe<FeaturedContentTutorialsDefaultFilter>>>
  OR?: InputMaybe<Array<InputMaybe<FeaturedContentTutorialsDefaultFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['String']>
  category_contains?: InputMaybe<Scalars['String']>
  category_exists?: InputMaybe<Scalars['Boolean']>
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category_not?: InputMaybe<Scalars['String']>
  category_not_contains?: InputMaybe<Scalars['String']>
  category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type FeaturedContentTutorialsDefaultItem =
  | CaisiqArticle
  | CaisiqVideo
  | CaisiqVideoPlaylist
  | Link
  | Whitepaper

export type FeaturedContentTutorialsOptionsCollection = {
  __typename?: 'FeaturedContentTutorialsOptionsCollection'
  items: Array<Maybe<FeaturedContentTutorialsOptionsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type FeaturedContentTutorialsOptionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<FeaturedContentTutorialsOptionsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<FeaturedContentTutorialsOptionsFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['String']>
  category_contains?: InputMaybe<Scalars['String']>
  category_exists?: InputMaybe<Scalars['Boolean']>
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category_not?: InputMaybe<Scalars['String']>
  category_not_contains?: InputMaybe<Scalars['String']>
  category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type FeaturedContentTutorialsOptionsItem =
  | CaisiqArticle
  | CaisiqVideo
  | CaisiqVideoPlaylist
  | Link
  | Whitepaper

export type FeaturedContentWebinarsDefault =
  | CaisiqVideo
  | CaisiqVideoPlaylist
  | Event
  | Link

export type FeaturedContentWebinarsDefaultFilter = {
  AND?: InputMaybe<Array<InputMaybe<FeaturedContentWebinarsDefaultFilter>>>
  OR?: InputMaybe<Array<InputMaybe<FeaturedContentWebinarsDefaultFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type FeaturedContentWebinarsOptionsCollection = {
  __typename?: 'FeaturedContentWebinarsOptionsCollection'
  items: Array<Maybe<FeaturedContentWebinarsOptionsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type FeaturedContentWebinarsOptionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<FeaturedContentWebinarsOptionsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<FeaturedContentWebinarsOptionsFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type FeaturedContentWebinarsOptionsItem =
  | CaisiqVideo
  | CaisiqVideoPlaylist
  | Event
  | Link

/** Group multiple tag families into a filter group [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/filterGroup) */
export type FilterGroup = Entry &
  _Node & {
    __typename?: 'FilterGroup'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    filtersCollection?: Maybe<FilterGroupFiltersCollection>
    id?: Maybe<Scalars['String']>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<FilterGroupLinkingCollections>
    sys: Sys
  }

/** Group multiple tag families into a filter group [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/filterGroup) */
export type FilterGroupFiltersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<FilterGroupFiltersCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TagFamilyFilter>
}

/** Group multiple tag families into a filter group [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/filterGroup) */
export type FilterGroupIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Group multiple tag families into a filter group [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/filterGroup) */
export type FilterGroupInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Group multiple tag families into a filter group [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/filterGroup) */
export type FilterGroupLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type FilterGroupCollection = {
  __typename?: 'FilterGroupCollection'
  items: Array<Maybe<FilterGroup>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type FilterGroupFilter = {
  AND?: InputMaybe<Array<InputMaybe<FilterGroupFilter>>>
  OR?: InputMaybe<Array<InputMaybe<FilterGroupFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  filters?: InputMaybe<CfTagFamilyNestedFilter>
  filtersCollection_exists?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['String']>
  id_contains?: InputMaybe<Scalars['String']>
  id_exists?: InputMaybe<Scalars['Boolean']>
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_not?: InputMaybe<Scalars['String']>
  id_not_contains?: InputMaybe<Scalars['String']>
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type FilterGroupFiltersCollection = {
  __typename?: 'FilterGroupFiltersCollection'
  items: Array<Maybe<TagFamily>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum FilterGroupFiltersCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagFamilyTypeAsc = 'tagFamilyType_ASC',
  TagFamilyTypeDesc = 'tagFamilyType_DESC',
}

export type FilterGroupLinkingCollections = {
  __typename?: 'FilterGroupLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type FilterGroupLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum FilterGroupOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type Firm = Entry &
  _Node & {
    __typename?: 'Firm'
    _id: Scalars['ID']
    articlesCollection?: Maybe<FirmArticlesCollection>
    ceCreditsAvailable?: Maybe<Scalars['Boolean']>
    ceGuidePage?: Maybe<CaisiqArticle>
    conference?: Maybe<Entry>
    contentfulMetadata: ContentfulMetadata
    disclaimer?: Maybe<Disclaimer>
    featuredArticle?: Maybe<CaisiqArticle>
    firmId?: Maybe<Scalars['String']>
    headerImage?: Maybe<Asset>
    headerType?: Maybe<Scalars['String']>
    heroHeaderContent?: Maybe<CaisiqHeroHeader>
    heroHeaderNavigateToButton?: Maybe<CaisiqHeroHeaderNavigateToButton>
    heroVideoPlaylist?: Maybe<CaisiqVideoPlaylist>
    homeoffice?: Maybe<CaisiqHomeOffice>
    individualVideosCollection?: Maybe<FirmIndividualVideosCollection>
    joinCaisBanner?: Maybe<CaisiqJoinCaisCallToAction>
    linkedFrom?: Maybe<FirmLinkingCollections>
    loginScreen?: Maybe<LoginScreen>
    name?: Maybe<Scalars['String']>
    sys: Sys
    tabsCollection?: Maybe<FirmTabsCollection>
    theme?: Maybe<Theme>
    videoPlaylistsCollection?: Maybe<FirmVideoPlaylistsCollection>
    whitepapersCollection?: Maybe<FirmWhitepapersCollection>
  }

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmArticlesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<FirmArticlesCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqArticleFilter>
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmCeCreditsAvailableArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmCeGuidePageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CaisiqArticleFilter>
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmConferenceArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmDisclaimerArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<DisclaimerFilter>
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmFeaturedArticleArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CaisiqArticleFilter>
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmFirmIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmHeaderImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmHeaderTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmHeroHeaderContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CaisiqHeroHeaderFilter>
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmHeroHeaderNavigateToButtonArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CaisiqHeroHeaderNavigateToButtonFilter>
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmHeroVideoPlaylistArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CaisiqVideoPlaylistFilter>
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmHomeofficeArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CaisiqHomeOfficeFilter>
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmIndividualVideosCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<FirmIndividualVideosCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqVideoFilter>
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmJoinCaisBannerArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CaisiqJoinCaisCallToActionFilter>
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmLoginScreenArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<LoginScreenFilter>
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmTabsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<FirmTabsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqTabFilter>
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmThemeArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ThemeFilter>
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmVideoPlaylistsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<FirmVideoPlaylistsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqVideoPlaylistFilter>
}

/** Used to set up a 'firm', or more accurately an 'experience' since each conference is also considered a 'firm'. Here you can set the look and feel of the experience and assign videos, articles whitepapers etc, as well as which tabs are available in this experience. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/firm) */
export type FirmWhitepapersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<FirmWhitepapersCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<WhitepaperFilter>
}

export type FirmArticlesCollection = {
  __typename?: 'FirmArticlesCollection'
  items: Array<Maybe<CaisiqArticle>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum FirmArticlesCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FirmCollection = {
  __typename?: 'FirmCollection'
  items: Array<Maybe<Firm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type FirmFilter = {
  AND?: InputMaybe<Array<InputMaybe<FirmFilter>>>
  OR?: InputMaybe<Array<InputMaybe<FirmFilter>>>
  articles?: InputMaybe<CfCaisiqArticleNestedFilter>
  articlesCollection_exists?: InputMaybe<Scalars['Boolean']>
  ceCreditsAvailable?: InputMaybe<Scalars['Boolean']>
  ceCreditsAvailable_exists?: InputMaybe<Scalars['Boolean']>
  ceCreditsAvailable_not?: InputMaybe<Scalars['Boolean']>
  ceGuidePage?: InputMaybe<CfCaisiqArticleNestedFilter>
  ceGuidePage_exists?: InputMaybe<Scalars['Boolean']>
  conference_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  disclaimer?: InputMaybe<CfDisclaimerNestedFilter>
  disclaimer_exists?: InputMaybe<Scalars['Boolean']>
  featuredArticle?: InputMaybe<CfCaisiqArticleNestedFilter>
  featuredArticle_exists?: InputMaybe<Scalars['Boolean']>
  firmId?: InputMaybe<Scalars['String']>
  firmId_contains?: InputMaybe<Scalars['String']>
  firmId_exists?: InputMaybe<Scalars['Boolean']>
  firmId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmId_not?: InputMaybe<Scalars['String']>
  firmId_not_contains?: InputMaybe<Scalars['String']>
  firmId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  headerImage_exists?: InputMaybe<Scalars['Boolean']>
  headerType?: InputMaybe<Scalars['String']>
  headerType_contains?: InputMaybe<Scalars['String']>
  headerType_exists?: InputMaybe<Scalars['Boolean']>
  headerType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  headerType_not?: InputMaybe<Scalars['String']>
  headerType_not_contains?: InputMaybe<Scalars['String']>
  headerType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  heroHeaderContent?: InputMaybe<CfCaisiqHeroHeaderNestedFilter>
  heroHeaderContent_exists?: InputMaybe<Scalars['Boolean']>
  heroHeaderNavigateToButton?: InputMaybe<CfCaisiqHeroHeaderNavigateToButtonNestedFilter>
  heroHeaderNavigateToButton_exists?: InputMaybe<Scalars['Boolean']>
  heroVideoPlaylist?: InputMaybe<CfCaisiqVideoPlaylistNestedFilter>
  heroVideoPlaylist_exists?: InputMaybe<Scalars['Boolean']>
  homeoffice?: InputMaybe<CfCaisiqHomeOfficeNestedFilter>
  homeoffice_exists?: InputMaybe<Scalars['Boolean']>
  individualVideos?: InputMaybe<CfCaisiqVideoNestedFilter>
  individualVideosCollection_exists?: InputMaybe<Scalars['Boolean']>
  joinCaisBanner?: InputMaybe<CfCaisiqJoinCaisCallToActionNestedFilter>
  joinCaisBanner_exists?: InputMaybe<Scalars['Boolean']>
  loginScreen?: InputMaybe<CfLoginScreenNestedFilter>
  loginScreen_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tabs?: InputMaybe<CfCaisiqTabNestedFilter>
  tabsCollection_exists?: InputMaybe<Scalars['Boolean']>
  theme?: InputMaybe<CfThemeNestedFilter>
  theme_exists?: InputMaybe<Scalars['Boolean']>
  videoPlaylists?: InputMaybe<CfCaisiqVideoPlaylistNestedFilter>
  videoPlaylistsCollection_exists?: InputMaybe<Scalars['Boolean']>
  whitepapers?: InputMaybe<CfWhitepaperNestedFilter>
  whitepapersCollection_exists?: InputMaybe<Scalars['Boolean']>
}

export type FirmIndividualVideosCollection = {
  __typename?: 'FirmIndividualVideosCollection'
  items: Array<Maybe<CaisiqVideo>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum FirmIndividualVideosCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  MuxThumbnailTimeAsc = 'muxThumbnailTime_ASC',
  MuxThumbnailTimeDesc = 'muxThumbnailTime_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FirmLinkingCollections = {
  __typename?: 'FirmLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type FirmLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum FirmOrder {
  CeCreditsAvailableAsc = 'ceCreditsAvailable_ASC',
  CeCreditsAvailableDesc = 'ceCreditsAvailable_DESC',
  FirmIdAsc = 'firmId_ASC',
  FirmIdDesc = 'firmId_DESC',
  HeaderTypeAsc = 'headerType_ASC',
  HeaderTypeDesc = 'headerType_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type FirmTabsCollection = {
  __typename?: 'FirmTabsCollection'
  items: Array<Maybe<CaisiqTab>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum FirmTabsCollectionOrder {
  HasSeparatorAsc = 'hasSeparator_ASC',
  HasSeparatorDesc = 'hasSeparator_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  PathAsc = 'path_ASC',
  PathDesc = 'path_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type FirmVideoPlaylistsCollection = {
  __typename?: 'FirmVideoPlaylistsCollection'
  items: Array<Maybe<CaisiqVideoPlaylist>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum FirmVideoPlaylistsCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  EpisodicAsc = 'episodic_ASC',
  EpisodicDesc = 'episodic_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type FirmWhitepapersCollection = {
  __typename?: 'FirmWhitepapersCollection'
  items: Array<Maybe<Whitepaper>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum FirmWhitepapersCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Create full width image section [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/fullWidthImage) */
export type FullWidthImage = Entry &
  _Node & {
    __typename?: 'FullWidthImage'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    image?: Maybe<ComponentImage>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<FullWidthImageLinkingCollections>
    sys: Sys
  }

/** Create full width image section [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/fullWidthImage) */
export type FullWidthImageImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentImageFilter>
}

/** Create full width image section [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/fullWidthImage) */
export type FullWidthImageInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create full width image section [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/fullWidthImage) */
export type FullWidthImageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type FullWidthImageCollection = {
  __typename?: 'FullWidthImageCollection'
  items: Array<Maybe<FullWidthImage>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type FullWidthImageFilter = {
  AND?: InputMaybe<Array<InputMaybe<FullWidthImageFilter>>>
  OR?: InputMaybe<Array<InputMaybe<FullWidthImageFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  image?: InputMaybe<CfComponentImageNestedFilter>
  image_exists?: InputMaybe<Scalars['Boolean']>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type FullWidthImageLinkingCollections = {
  __typename?: 'FullWidthImageLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type FullWidthImageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum FullWidthImageOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** A list of fund product IDs that are associated with referenced content [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/fundProductId) */
export type FundProductId = Entry &
  _Node & {
    __typename?: 'FundProductId'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    description?: Maybe<Scalars['String']>
    fundProductIds?: Maybe<Array<Maybe<Scalars['String']>>>
    linkedFrom?: Maybe<FundProductIdLinkingCollections>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** A list of fund product IDs that are associated with referenced content [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/fundProductId) */
export type FundProductIdDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A list of fund product IDs that are associated with referenced content [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/fundProductId) */
export type FundProductIdFundProductIdsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A list of fund product IDs that are associated with referenced content [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/fundProductId) */
export type FundProductIdLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A list of fund product IDs that are associated with referenced content [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/fundProductId) */
export type FundProductIdTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type FundProductIdCollection = {
  __typename?: 'FundProductIdCollection'
  items: Array<Maybe<FundProductId>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type FundProductIdFilter = {
  AND?: InputMaybe<Array<InputMaybe<FundProductIdFilter>>>
  OR?: InputMaybe<Array<InputMaybe<FundProductIdFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fundProductIds_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fundProductIds_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >
  fundProductIds_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >
  fundProductIds_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type FundProductIdLinkingCollections = {
  __typename?: 'FundProductIdLinkingCollections'
  announcementCollection?: Maybe<AnnouncementCollection>
  caisiqArticleCollection?: Maybe<CaisiqArticleCollection>
  caisiqVideoCollection?: Maybe<CaisiqVideoCollection>
  caisiqVideoPlaylistCollection?: Maybe<CaisiqVideoPlaylistCollection>
  ciqArticleCollection?: Maybe<CiqArticleCollection>
  entryCollection?: Maybe<EntryCollection>
  eventCollection?: Maybe<EventCollection>
  externalContentCollection?: Maybe<ExternalContentCollection>
  homepageAppMetadataCollection?: Maybe<HomepageAppMetadataCollection>
  whitepaperCollection?: Maybe<WhitepaperCollection>
}

export type FundProductIdLinkingCollectionsAnnouncementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<FundProductIdLinkingCollectionsAnnouncementCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FundProductIdLinkingCollectionsCaisiqArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<FundProductIdLinkingCollectionsCaisiqArticleCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FundProductIdLinkingCollectionsCaisiqVideoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<FundProductIdLinkingCollectionsCaisiqVideoCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FundProductIdLinkingCollectionsCaisiqVideoPlaylistCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<FundProductIdLinkingCollectionsCaisiqVideoPlaylistCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FundProductIdLinkingCollectionsCiqArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<FundProductIdLinkingCollectionsCiqArticleCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FundProductIdLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FundProductIdLinkingCollectionsEventCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<FundProductIdLinkingCollectionsEventCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FundProductIdLinkingCollectionsExternalContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<FundProductIdLinkingCollectionsExternalContentCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FundProductIdLinkingCollectionsHomepageAppMetadataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<FundProductIdLinkingCollectionsHomepageAppMetadataCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type FundProductIdLinkingCollectionsWhitepaperCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<FundProductIdLinkingCollectionsWhitepaperCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum FundProductIdLinkingCollectionsAnnouncementCollectionOrder {
  DescriptionAsc = 'Description_ASC',
  DescriptionDesc = 'Description_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  DateTimeEndAsc = 'dateTimeEnd_ASC',
  DateTimeEndDesc = 'dateTimeEnd_DESC',
  DateTimeStartAsc = 'dateTimeStart_ASC',
  DateTimeStartDesc = 'dateTimeStart_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimezoneAsc = 'timezone_ASC',
  TimezoneDesc = 'timezone_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FundProductIdLinkingCollectionsCaisiqArticleCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FundProductIdLinkingCollectionsCaisiqVideoCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  MuxThumbnailTimeAsc = 'muxThumbnailTime_ASC',
  MuxThumbnailTimeDesc = 'muxThumbnailTime_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FundProductIdLinkingCollectionsCaisiqVideoPlaylistCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  EpisodicAsc = 'episodic_ASC',
  EpisodicDesc = 'episodic_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FundProductIdLinkingCollectionsCiqArticleCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FundProductIdLinkingCollectionsEventCollectionOrder {
  AllDayAsc = 'allDay_ASC',
  AllDayDesc = 'allDay_DESC',
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DateTimeEndAsc = 'dateTimeEnd_ASC',
  DateTimeEndDesc = 'dateTimeEnd_DESC',
  DateTimeAsc = 'dateTime_ASC',
  DateTimeDesc = 'dateTime_DESC',
  EventTypeAsc = 'eventType_ASC',
  EventTypeDesc = 'eventType_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  LocationNameAsc = 'locationName_ASC',
  LocationNameDesc = 'locationName_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimezoneAsc = 'timezone_ASC',
  TimezoneDesc = 'timezone_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FundProductIdLinkingCollectionsExternalContentCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  EligibilityAsc = 'eligibility_ASC',
  EligibilityDesc = 'eligibility_DESC',
  ExternalIdAsc = 'externalId_ASC',
  ExternalIdDesc = 'externalId_DESC',
  ExternalLinkAsc = 'externalLink_ASC',
  ExternalLinkDesc = 'externalLink_DESC',
  MinimumInitialInvestmentAsc = 'minimumInitialInvestment_ASC',
  MinimumInitialInvestmentDesc = 'minimumInitialInvestment_DESC',
  StrategyAsc = 'strategy_ASC',
  StrategyDesc = 'strategy_DESC',
  SubStrategyAsc = 'subStrategy_ASC',
  SubStrategyDesc = 'subStrategy_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum FundProductIdLinkingCollectionsHomepageAppMetadataCollectionOrder {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum FundProductIdLinkingCollectionsWhitepaperCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum FundProductIdOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Component to display a glossary term and its definition [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/glossaryTerm) */
export type GlossaryTerm = Entry &
  _Node & {
    __typename?: 'GlossaryTerm'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    definition?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    glossaryTerm?: Maybe<Scalars['String']>
    id?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<GlossaryTermLinkingCollections>
    sys: Sys
  }

/** Component to display a glossary term and its definition [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/glossaryTerm) */
export type GlossaryTermDefinitionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Component to display a glossary term and its definition [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/glossaryTerm) */
export type GlossaryTermDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Component to display a glossary term and its definition [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/glossaryTerm) */
export type GlossaryTermGlossaryTermArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Component to display a glossary term and its definition [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/glossaryTerm) */
export type GlossaryTermIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Component to display a glossary term and its definition [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/glossaryTerm) */
export type GlossaryTermLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type GlossaryTermCollection = {
  __typename?: 'GlossaryTermCollection'
  items: Array<Maybe<GlossaryTerm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type GlossaryTermFilter = {
  AND?: InputMaybe<Array<InputMaybe<GlossaryTermFilter>>>
  OR?: InputMaybe<Array<InputMaybe<GlossaryTermFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  definition?: InputMaybe<Scalars['String']>
  definition_contains?: InputMaybe<Scalars['String']>
  definition_exists?: InputMaybe<Scalars['Boolean']>
  definition_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  definition_not?: InputMaybe<Scalars['String']>
  definition_not_contains?: InputMaybe<Scalars['String']>
  definition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  glossaryTerm?: InputMaybe<Scalars['String']>
  glossaryTerm_contains?: InputMaybe<Scalars['String']>
  glossaryTerm_exists?: InputMaybe<Scalars['Boolean']>
  glossaryTerm_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  glossaryTerm_not?: InputMaybe<Scalars['String']>
  glossaryTerm_not_contains?: InputMaybe<Scalars['String']>
  glossaryTerm_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id?: InputMaybe<Scalars['String']>
  id_contains?: InputMaybe<Scalars['String']>
  id_exists?: InputMaybe<Scalars['Boolean']>
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_not?: InputMaybe<Scalars['String']>
  id_not_contains?: InputMaybe<Scalars['String']>
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type GlossaryTermLinkingCollections = {
  __typename?: 'GlossaryTermLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type GlossaryTermLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum GlossaryTermOrder {
  GlossaryTermAsc = 'glossaryTerm_ASC',
  GlossaryTermDesc = 'glossaryTerm_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Metadata for the Homepage content items [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepageAppMetadata) */
export type HomepageAppMetadata = Entry &
  _Node & {
    __typename?: 'HomepageAppMetadata'
    _id: Scalars['ID']
    content?: Maybe<HomepageAppMetadataContent>
    contentfulMetadata: ContentfulMetadata
    date?: Maybe<Scalars['DateTime']>
    fundProductIdsCollection?: Maybe<HomepageAppMetadataFundProductIdsCollection>
    linkedFrom?: Maybe<HomepageAppMetadataLinkingCollections>
    slug?: Maybe<Scalars['String']>
    sys: Sys
    tagsCollection?: Maybe<HomepageAppMetadataTagsCollection>
  }

/** Metadata for the Homepage content items [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepageAppMetadata) */
export type HomepageAppMetadataContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Metadata for the Homepage content items [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepageAppMetadata) */
export type HomepageAppMetadataDateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Metadata for the Homepage content items [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepageAppMetadata) */
export type HomepageAppMetadataFundProductIdsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<HomepageAppMetadataFundProductIdsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FundProductIdFilter>
}

/** Metadata for the Homepage content items [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepageAppMetadata) */
export type HomepageAppMetadataLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Metadata for the Homepage content items [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepageAppMetadata) */
export type HomepageAppMetadataSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Metadata for the Homepage content items [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepageAppMetadata) */
export type HomepageAppMetadataTagsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<HomepageAppMetadataTagsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TagFilter>
}

export type HomepageAppMetadataCollection = {
  __typename?: 'HomepageAppMetadataCollection'
  items: Array<Maybe<HomepageAppMetadata>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type HomepageAppMetadataContent =
  | CaisiqArticle
  | CaisiqVideo
  | CaisiqVideoPlaylist
  | Event
  | ExternalContent
  | PwsPressRelease
  | Whitepaper

export type HomepageAppMetadataFilter = {
  AND?: InputMaybe<Array<InputMaybe<HomepageAppMetadataFilter>>>
  OR?: InputMaybe<Array<InputMaybe<HomepageAppMetadataFilter>>>
  content_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  date?: InputMaybe<Scalars['DateTime']>
  date_exists?: InputMaybe<Scalars['Boolean']>
  date_gt?: InputMaybe<Scalars['DateTime']>
  date_gte?: InputMaybe<Scalars['DateTime']>
  date_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  date_lt?: InputMaybe<Scalars['DateTime']>
  date_lte?: InputMaybe<Scalars['DateTime']>
  date_not?: InputMaybe<Scalars['DateTime']>
  date_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  fundProductIds?: InputMaybe<CfFundProductIdNestedFilter>
  fundProductIdsCollection_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tags?: InputMaybe<CfTagNestedFilter>
  tagsCollection_exists?: InputMaybe<Scalars['Boolean']>
}

export type HomepageAppMetadataFundProductIdsCollection = {
  __typename?: 'HomepageAppMetadataFundProductIdsCollection'
  items: Array<Maybe<FundProductId>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum HomepageAppMetadataFundProductIdsCollectionOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type HomepageAppMetadataLinkingCollections = {
  __typename?: 'HomepageAppMetadataLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type HomepageAppMetadataLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum HomepageAppMetadataOrder {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type HomepageAppMetadataTagsCollection = {
  __typename?: 'HomepageAppMetadataTagsCollection'
  items: Array<Maybe<Tag>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum HomepageAppMetadataTagsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagIdAsc = 'tagId_ASC',
  TagIdDesc = 'tagId_DESC',
}

/** Content section component for the homepage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepage_content_section) */
export type HomepageContentSection = Entry &
  _Node & {
    __typename?: 'HomepageContentSection'
    _id: Scalars['ID']
    bookmarkId?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    description?: Maybe<Scalars['String']>
    internalName?: Maybe<Scalars['String']>
    itemsCollection?: Maybe<HomepageContentSectionItemsCollection>
    linkedFrom?: Maybe<HomepageContentSectionLinkingCollections>
    removeTopPadding?: Maybe<Scalars['Boolean']>
    sys: Sys
    title?: Maybe<Scalars['String']>
    type?: Maybe<Scalars['String']>
  }

/** Content section component for the homepage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepage_content_section) */
export type HomepageContentSectionBookmarkIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Content section component for the homepage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepage_content_section) */
export type HomepageContentSectionDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Content section component for the homepage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepage_content_section) */
export type HomepageContentSectionInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Content section component for the homepage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepage_content_section) */
export type HomepageContentSectionItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<HomepageContentSectionItemsFilter>
}

/** Content section component for the homepage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepage_content_section) */
export type HomepageContentSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Content section component for the homepage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepage_content_section) */
export type HomepageContentSectionRemoveTopPaddingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Content section component for the homepage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepage_content_section) */
export type HomepageContentSectionTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Content section component for the homepage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepage_content_section) */
export type HomepageContentSectionTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type HomepageContentSectionCollection = {
  __typename?: 'HomepageContentSectionCollection'
  items: Array<Maybe<HomepageContentSection>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type HomepageContentSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<HomepageContentSectionFilter>>>
  OR?: InputMaybe<Array<InputMaybe<HomepageContentSectionFilter>>>
  bookmarkId?: InputMaybe<Scalars['String']>
  bookmarkId_contains?: InputMaybe<Scalars['String']>
  bookmarkId_exists?: InputMaybe<Scalars['Boolean']>
  bookmarkId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bookmarkId_not?: InputMaybe<Scalars['String']>
  bookmarkId_not_contains?: InputMaybe<Scalars['String']>
  bookmarkId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  items?: InputMaybe<CfitemsMultiTypeNestedFilter>
  itemsCollection_exists?: InputMaybe<Scalars['Boolean']>
  removeTopPadding?: InputMaybe<Scalars['Boolean']>
  removeTopPadding_exists?: InputMaybe<Scalars['Boolean']>
  removeTopPadding_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type?: InputMaybe<Scalars['String']>
  type_contains?: InputMaybe<Scalars['String']>
  type_exists?: InputMaybe<Scalars['Boolean']>
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type_not?: InputMaybe<Scalars['String']>
  type_not_contains?: InputMaybe<Scalars['String']>
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type HomepageContentSectionItemsCollection = {
  __typename?: 'HomepageContentSectionItemsCollection'
  items: Array<Maybe<HomepageContentSectionItemsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type HomepageContentSectionItemsFilter = {
  AND?: InputMaybe<Array<InputMaybe<HomepageContentSectionItemsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<HomepageContentSectionItemsFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type HomepageContentSectionItemsItem =
  | CaisiqVideo
  | CallToAction
  | ComponentImage
  | ComponentText

export type HomepageContentSectionLinkingCollections = {
  __typename?: 'HomepageContentSectionLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  homepageLandingPageCollection?: Maybe<HomepageLandingPageCollection>
}

export type HomepageContentSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type HomepageContentSectionLinkingCollectionsHomepageLandingPageCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<HomepageContentSectionLinkingCollectionsHomepageLandingPageCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum HomepageContentSectionLinkingCollectionsHomepageLandingPageCollectionOrder {
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum HomepageContentSectionOrder {
  BookmarkIdAsc = 'bookmarkId_ASC',
  BookmarkIdDesc = 'bookmarkId_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  RemoveTopPaddingAsc = 'removeTopPadding_ASC',
  RemoveTopPaddingDesc = 'removeTopPadding_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

/** Landing page for the homepage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepageLandingPage) */
export type HomepageLandingPage = Entry &
  _Node & {
    __typename?: 'HomepageLandingPage'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    disclaimer?: Maybe<Disclaimer>
    icon?: Maybe<Scalars['String']>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<HomepageLandingPageLinkingCollections>
    sectionsCollection?: Maybe<HomepageLandingPageSectionsCollection>
    slug?: Maybe<Scalars['String']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Landing page for the homepage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepageLandingPage) */
export type HomepageLandingPageDisclaimerArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<DisclaimerFilter>
}

/** Landing page for the homepage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepageLandingPage) */
export type HomepageLandingPageIconArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Landing page for the homepage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepageLandingPage) */
export type HomepageLandingPageInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Landing page for the homepage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepageLandingPage) */
export type HomepageLandingPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Landing page for the homepage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepageLandingPage) */
export type HomepageLandingPageSectionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<HomepageLandingPageSectionsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<HomepageContentSectionFilter>
}

/** Landing page for the homepage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepageLandingPage) */
export type HomepageLandingPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Landing page for the homepage [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/homepageLandingPage) */
export type HomepageLandingPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type HomepageLandingPageCollection = {
  __typename?: 'HomepageLandingPageCollection'
  items: Array<Maybe<HomepageLandingPage>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type HomepageLandingPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<HomepageLandingPageFilter>>>
  OR?: InputMaybe<Array<InputMaybe<HomepageLandingPageFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  disclaimer?: InputMaybe<CfDisclaimerNestedFilter>
  disclaimer_exists?: InputMaybe<Scalars['Boolean']>
  icon?: InputMaybe<Scalars['String']>
  icon_contains?: InputMaybe<Scalars['String']>
  icon_exists?: InputMaybe<Scalars['Boolean']>
  icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  icon_not?: InputMaybe<Scalars['String']>
  icon_not_contains?: InputMaybe<Scalars['String']>
  icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sections?: InputMaybe<CfHomepageContentSectionNestedFilter>
  sectionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type HomepageLandingPageLinkingCollections = {
  __typename?: 'HomepageLandingPageLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  linkCollection?: Maybe<LinkCollection>
}

export type HomepageLandingPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type HomepageLandingPageLinkingCollectionsLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<HomepageLandingPageLinkingCollectionsLinkCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum HomepageLandingPageLinkingCollectionsLinkCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  ExternalLinkAsc = 'externalLink_ASC',
  ExternalLinkDesc = 'externalLink_DESC',
  InternalLinkAsc = 'internalLink_ASC',
  InternalLinkDesc = 'internalLink_DESC',
  OpenInNewWindowAsc = 'openInNewWindow_ASC',
  OpenInNewWindowDesc = 'openInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum HomepageLandingPageOrder {
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type HomepageLandingPageSectionsCollection = {
  __typename?: 'HomepageLandingPageSectionsCollection'
  items: Array<Maybe<HomepageContentSection>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum HomepageLandingPageSectionsCollectionOrder {
  BookmarkIdAsc = 'bookmarkId_ASC',
  BookmarkIdDesc = 'bookmarkId_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  RemoveTopPaddingAsc = 'removeTopPadding_ASC',
  RemoveTopPaddingDesc = 'removeTopPadding_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/htmlTable) */
export type HtmlTable = Entry &
  _Node & {
    __typename?: 'HtmlTable'
    _id: Scalars['ID']
    colorizeNumbers?: Maybe<Scalars['Boolean']>
    columnHeader?: Maybe<Scalars['Boolean']>
    contentfulMetadata: ContentfulMetadata
    hideTitle?: Maybe<Scalars['Boolean']>
    imageToReplace?: Maybe<Asset>
    linkedFrom?: Maybe<HtmlTableLinkingCollections>
    longSource?: Maybe<Scalars['String']>
    name?: Maybe<Scalars['String']>
    rowHeader?: Maybe<Scalars['Boolean']>
    sourceLink?: Maybe<Scalars['String']>
    sys: Sys
    table?: Maybe<Scalars['JSON']>
    title?: Maybe<Scalars['String']>
    useFixedHeaders?: Maybe<Scalars['Boolean']>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/htmlTable) */
export type HtmlTableColorizeNumbersArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/htmlTable) */
export type HtmlTableColumnHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/htmlTable) */
export type HtmlTableHideTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/htmlTable) */
export type HtmlTableImageToReplaceArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/htmlTable) */
export type HtmlTableLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/htmlTable) */
export type HtmlTableLongSourceArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/htmlTable) */
export type HtmlTableNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/htmlTable) */
export type HtmlTableRowHeaderArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/htmlTable) */
export type HtmlTableSourceLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/htmlTable) */
export type HtmlTableTableArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/htmlTable) */
export type HtmlTableTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/htmlTable) */
export type HtmlTableUseFixedHeadersArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type HtmlTableCollection = {
  __typename?: 'HtmlTableCollection'
  items: Array<Maybe<HtmlTable>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type HtmlTableFilter = {
  AND?: InputMaybe<Array<InputMaybe<HtmlTableFilter>>>
  OR?: InputMaybe<Array<InputMaybe<HtmlTableFilter>>>
  colorizeNumbers?: InputMaybe<Scalars['Boolean']>
  colorizeNumbers_exists?: InputMaybe<Scalars['Boolean']>
  colorizeNumbers_not?: InputMaybe<Scalars['Boolean']>
  columnHeader?: InputMaybe<Scalars['Boolean']>
  columnHeader_exists?: InputMaybe<Scalars['Boolean']>
  columnHeader_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  hideTitle?: InputMaybe<Scalars['Boolean']>
  hideTitle_exists?: InputMaybe<Scalars['Boolean']>
  hideTitle_not?: InputMaybe<Scalars['Boolean']>
  imageToReplace_exists?: InputMaybe<Scalars['Boolean']>
  longSource?: InputMaybe<Scalars['String']>
  longSource_contains?: InputMaybe<Scalars['String']>
  longSource_exists?: InputMaybe<Scalars['Boolean']>
  longSource_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  longSource_not?: InputMaybe<Scalars['String']>
  longSource_not_contains?: InputMaybe<Scalars['String']>
  longSource_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  rowHeader?: InputMaybe<Scalars['Boolean']>
  rowHeader_exists?: InputMaybe<Scalars['Boolean']>
  rowHeader_not?: InputMaybe<Scalars['Boolean']>
  sourceLink?: InputMaybe<Scalars['String']>
  sourceLink_contains?: InputMaybe<Scalars['String']>
  sourceLink_exists?: InputMaybe<Scalars['Boolean']>
  sourceLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sourceLink_not?: InputMaybe<Scalars['String']>
  sourceLink_not_contains?: InputMaybe<Scalars['String']>
  sourceLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  table_exists?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  useFixedHeaders?: InputMaybe<Scalars['Boolean']>
  useFixedHeaders_exists?: InputMaybe<Scalars['Boolean']>
  useFixedHeaders_not?: InputMaybe<Scalars['Boolean']>
}

export type HtmlTableLinkingCollections = {
  __typename?: 'HtmlTableLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type HtmlTableLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum HtmlTableOrder {
  ColorizeNumbersAsc = 'colorizeNumbers_ASC',
  ColorizeNumbersDesc = 'colorizeNumbers_DESC',
  ColumnHeaderAsc = 'columnHeader_ASC',
  ColumnHeaderDesc = 'columnHeader_DESC',
  HideTitleAsc = 'hideTitle_ASC',
  HideTitleDesc = 'hideTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RowHeaderAsc = 'rowHeader_ASC',
  RowHeaderDesc = 'rowHeader_DESC',
  SourceLinkAsc = 'sourceLink_ASC',
  SourceLinkDesc = 'sourceLink_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UseFixedHeadersAsc = 'useFixedHeaders_ASC',
  UseFixedHeadersDesc = 'useFixedHeaders_DESC',
}

export enum ImageFormat {
  Avif = 'AVIF',
  /** JPG image format. */
  Jpg = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = 'JPG_PROGRESSIVE',
  /** PNG image format */
  Png = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = 'PNG8',
  /** WebP image format. */
  Webp = 'WEBP',
}

export enum ImageResizeFocus {
  /** Focus the resizing on the bottom. */
  Bottom = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Focus the resizing on the bottom right. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Focus the resizing on the center. */
  Center = 'CENTER',
  /** Focus the resizing on the largest face. */
  Face = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  Faces = 'FACES',
  /** Focus the resizing on the left. */
  Left = 'LEFT',
  /** Focus the resizing on the right. */
  Right = 'RIGHT',
  /** Focus the resizing on the top. */
  Top = 'TOP',
  /** Focus the resizing on the top left. */
  TopLeft = 'TOP_LEFT',
  /** Focus the resizing on the top right. */
  TopRight = 'TOP_RIGHT',
}

export enum ImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = 'CROP',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = 'FILL',
  /** Resizes the image to fit into the specified dimensions. */
  Fit = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = 'PAD',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = 'SCALE',
  /** Creates a thumbnail from the image. */
  Thumb = 'THUMB',
}

/** Create an image section [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/imageSection) */
export type ImageSection = Entry &
  _Node & {
    __typename?: 'ImageSection'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    imageSet?: Maybe<Scalars['JSON']>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ImageSectionLinkingCollections>
    sys: Sys
    type?: Maybe<Scalars['String']>
  }

/** Create an image section [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/imageSection) */
export type ImageSectionImageSetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create an image section [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/imageSection) */
export type ImageSectionInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create an image section [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/imageSection) */
export type ImageSectionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create an image section [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/imageSection) */
export type ImageSectionTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type ImageSectionCollection = {
  __typename?: 'ImageSectionCollection'
  items: Array<Maybe<ImageSection>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ImageSectionFilter = {
  AND?: InputMaybe<Array<InputMaybe<ImageSectionFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ImageSectionFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  imageSet_exists?: InputMaybe<Scalars['Boolean']>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  type?: InputMaybe<Scalars['String']>
  type_contains?: InputMaybe<Scalars['String']>
  type_exists?: InputMaybe<Scalars['Boolean']>
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type_not?: InputMaybe<Scalars['String']>
  type_not_contains?: InputMaybe<Scalars['String']>
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ImageSectionLinkingCollections = {
  __typename?: 'ImageSectionLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
}

export type ImageSectionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ImageSectionLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ImageSectionLinkingCollectionsPageLandingCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ImageSectionLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ImageSectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type ImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: InputMaybe<Scalars['HexColor']>
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: InputMaybe<Scalars['Int']>
  /** Desired image format. Defaults to the original image format. */
  format?: InputMaybe<ImageFormat>
  /** Desired height in pixels. Defaults to the original image height. */
  height?: InputMaybe<Scalars['Dimension']>
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: InputMaybe<Scalars['Quality']>
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: InputMaybe<ImageResizeFocus>
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: InputMaybe<ImageResizeStrategy>
  /** Desired width in pixels. Defaults to the original image width. */
  width?: InputMaybe<Scalars['Dimension']>
}

/** A page that contains legal & compliance content along with downloadable PDF documents. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/legalPage) */
export type LegalPage = Entry &
  _Node & {
    __typename?: 'LegalPage'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<LegalPageLinkingCollections>
    pdfsCollection?: Maybe<LegalPagePdfsCollection>
    slug?: Maybe<Scalars['String']>
    subtitle?: Maybe<Scalars['String']>
    sys: Sys
    text?: Maybe<LegalPageText>
    title?: Maybe<Scalars['String']>
  }

/** A page that contains legal & compliance content along with downloadable PDF documents. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/legalPage) */
export type LegalPageInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page that contains legal & compliance content along with downloadable PDF documents. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/legalPage) */
export type LegalPageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A page that contains legal & compliance content along with downloadable PDF documents. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/legalPage) */
export type LegalPagePdfsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<LegalPagePdfsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PdfFilter>
}

/** A page that contains legal & compliance content along with downloadable PDF documents. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/legalPage) */
export type LegalPageSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page that contains legal & compliance content along with downloadable PDF documents. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/legalPage) */
export type LegalPageSubtitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page that contains legal & compliance content along with downloadable PDF documents. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/legalPage) */
export type LegalPageTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A page that contains legal & compliance content along with downloadable PDF documents. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/legalPage) */
export type LegalPageTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type LegalPageCollection = {
  __typename?: 'LegalPageCollection'
  items: Array<Maybe<LegalPage>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type LegalPageFilter = {
  AND?: InputMaybe<Array<InputMaybe<LegalPageFilter>>>
  OR?: InputMaybe<Array<InputMaybe<LegalPageFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  pdfs?: InputMaybe<CfPdfNestedFilter>
  pdfsCollection_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subtitle?: InputMaybe<Scalars['String']>
  subtitle_contains?: InputMaybe<Scalars['String']>
  subtitle_exists?: InputMaybe<Scalars['Boolean']>
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subtitle_not?: InputMaybe<Scalars['String']>
  subtitle_not_contains?: InputMaybe<Scalars['String']>
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  text_contains?: InputMaybe<Scalars['String']>
  text_exists?: InputMaybe<Scalars['Boolean']>
  text_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type LegalPageLinkingCollections = {
  __typename?: 'LegalPageLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type LegalPageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum LegalPageOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type LegalPagePdfsCollection = {
  __typename?: 'LegalPagePdfsCollection'
  items: Array<Maybe<Pdf>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum LegalPagePdfsCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type LegalPageText = {
  __typename?: 'LegalPageText'
  json: Scalars['JSON']
  links: LegalPageTextLinks
}

export type LegalPageTextAssets = {
  __typename?: 'LegalPageTextAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type LegalPageTextEntries = {
  __typename?: 'LegalPageTextEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type LegalPageTextLinks = {
  __typename?: 'LegalPageTextLinks'
  assets: LegalPageTextAssets
  entries: LegalPageTextEntries
  resources: LegalPageTextResources
}

export type LegalPageTextResources = {
  __typename?: 'LegalPageTextResources'
  block: Array<LegalPageTextResourcesBlock>
  hyperlink: Array<LegalPageTextResourcesHyperlink>
  inline: Array<LegalPageTextResourcesInline>
}

export type LegalPageTextResourcesBlock = ResourceLink & {
  __typename?: 'LegalPageTextResourcesBlock'
  sys: ResourceSys
}

export type LegalPageTextResourcesHyperlink = ResourceLink & {
  __typename?: 'LegalPageTextResourcesHyperlink'
  sys: ResourceSys
}

export type LegalPageTextResourcesInline = ResourceLink & {
  __typename?: 'LegalPageTextResourcesInline'
  sys: ResourceSys
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/link) */
export type Link = Entry &
  _Node & {
    __typename?: 'Link'
    _id: Scalars['ID']
    byCais?: Maybe<Scalars['Boolean']>
    category?: Maybe<Scalars['String']>
    cmsLink?: Maybe<LinkCmsLink>
    contentfulMetadata: ContentfulMetadata
    customDescription?: Maybe<Scalars['String']>
    customTitle?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    externalLink?: Maybe<Scalars['String']>
    firmsCollection?: Maybe<LinkFirmsCollection>
    internalLink?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<LinkLinkingCollections>
    openInNewWindow?: Maybe<Scalars['Boolean']>
    sys: Sys
    text?: Maybe<Scalars['String']>
    thumbnail?: Maybe<Scalars['JSON']>
    title?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/link) */
export type LinkByCaisArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/link) */
export type LinkCategoryArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/link) */
export type LinkCmsLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/link) */
export type LinkCustomDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/link) */
export type LinkCustomTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/link) */
export type LinkDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/link) */
export type LinkExternalLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/link) */
export type LinkFirmsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<LinkFirmsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MemberFirmFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/link) */
export type LinkInternalLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/link) */
export type LinkLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/link) */
export type LinkOpenInNewWindowArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/link) */
export type LinkTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/link) */
export type LinkThumbnailArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/link) */
export type LinkTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type LinkCmsLink =
  | CaisiqArticle
  | HomepageLandingPage
  | Page
  | PageResource
  | PwsPressRelease
  | Whitepaper

export type LinkCollection = {
  __typename?: 'LinkCollection'
  items: Array<Maybe<Link>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type LinkFilter = {
  AND?: InputMaybe<Array<InputMaybe<LinkFilter>>>
  OR?: InputMaybe<Array<InputMaybe<LinkFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['String']>
  category_contains?: InputMaybe<Scalars['String']>
  category_exists?: InputMaybe<Scalars['Boolean']>
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category_not?: InputMaybe<Scalars['String']>
  category_not_contains?: InputMaybe<Scalars['String']>
  category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  cmsLink_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalLink?: InputMaybe<Scalars['String']>
  externalLink_contains?: InputMaybe<Scalars['String']>
  externalLink_exists?: InputMaybe<Scalars['Boolean']>
  externalLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalLink_not?: InputMaybe<Scalars['String']>
  externalLink_not_contains?: InputMaybe<Scalars['String']>
  externalLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firms?: InputMaybe<CfMemberFirmNestedFilter>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  internalLink?: InputMaybe<Scalars['String']>
  internalLink_contains?: InputMaybe<Scalars['String']>
  internalLink_exists?: InputMaybe<Scalars['Boolean']>
  internalLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLink_not?: InputMaybe<Scalars['String']>
  internalLink_not_contains?: InputMaybe<Scalars['String']>
  internalLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  openInNewWindow?: InputMaybe<Scalars['Boolean']>
  openInNewWindow_exists?: InputMaybe<Scalars['Boolean']>
  openInNewWindow_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  text?: InputMaybe<Scalars['String']>
  text_contains?: InputMaybe<Scalars['String']>
  text_exists?: InputMaybe<Scalars['Boolean']>
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  text_not?: InputMaybe<Scalars['String']>
  text_not_contains?: InputMaybe<Scalars['String']>
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  thumbnail_exists?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type LinkFirmsCollection = {
  __typename?: 'LinkFirmsCollection'
  items: Array<Maybe<MemberFirm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum LinkFirmsCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type LinkLinkingCollections = {
  __typename?: 'LinkLinkingCollections'
  accordionCollection?: Maybe<AccordionCollection>
  announcementCollection?: Maybe<AnnouncementCollection>
  caisiqAuthorCollection?: Maybe<CaisiqAuthorCollection>
  callToActionCollection?: Maybe<CallToActionCollection>
  companyCollection?: Maybe<CompanyCollection>
  componentCardCollection?: Maybe<ComponentCardCollection>
  componentHeroCollection?: Maybe<ComponentHeroCollection>
  componentSectionCollection?: Maybe<ComponentSectionCollection>
  componentTextImageTextSectionCollection?: Maybe<ComponentTextImageTextSectionCollection>
  entryCollection?: Maybe<EntryCollection>
  featuredContentCollection?: Maybe<FeaturedContentCollection>
  memberFirmCollection?: Maybe<MemberFirmCollection>
  personCollection?: Maybe<PersonCollection>
  pressAwardCollection?: Maybe<PressAwardCollection>
  relatedContentCollection?: Maybe<RelatedContentCollection>
}

export type LinkLinkingCollectionsAccordionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsAccordionCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type LinkLinkingCollectionsAnnouncementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsAnnouncementCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type LinkLinkingCollectionsCaisiqAuthorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsCaisiqAuthorCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type LinkLinkingCollectionsCallToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsCallToActionCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type LinkLinkingCollectionsCompanyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsCompanyCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type LinkLinkingCollectionsComponentCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsComponentCardCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type LinkLinkingCollectionsComponentHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsComponentHeroCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type LinkLinkingCollectionsComponentSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsComponentSectionCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type LinkLinkingCollectionsComponentTextImageTextSectionCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<LinkLinkingCollectionsComponentTextImageTextSectionCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type LinkLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type LinkLinkingCollectionsFeaturedContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsFeaturedContentCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type LinkLinkingCollectionsMemberFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsMemberFirmCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type LinkLinkingCollectionsPersonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsPersonCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type LinkLinkingCollectionsPressAwardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsPressAwardCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type LinkLinkingCollectionsRelatedContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<LinkLinkingCollectionsRelatedContentCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum LinkLinkingCollectionsAccordionCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LinkLinkingCollectionsAnnouncementCollectionOrder {
  DescriptionAsc = 'Description_ASC',
  DescriptionDesc = 'Description_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  DateTimeEndAsc = 'dateTimeEnd_ASC',
  DateTimeEndDesc = 'dateTimeEnd_DESC',
  DateTimeStartAsc = 'dateTimeStart_ASC',
  DateTimeStartDesc = 'dateTimeStart_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimezoneAsc = 'timezone_ASC',
  TimezoneDesc = 'timezone_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LinkLinkingCollectionsCaisiqAuthorCollectionOrder {
  AuthorNameAsc = 'authorName_ASC',
  AuthorNameDesc = 'authorName_DESC',
  FirmAsc = 'firm_ASC',
  FirmDesc = 'firm_DESC',
  JobTitleAsc = 'jobTitle_ASC',
  JobTitleDesc = 'jobTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsCallToActionCollectionOrder {
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LinkLinkingCollectionsCompanyCollectionOrder {
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsComponentCardCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LinkTextAsc = 'linkText_ASC',
  LinkTextDesc = 'linkText_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LinkLinkingCollectionsComponentHeroCollectionOrder {
  BackgroundAsc = 'background_ASC',
  BackgroundDesc = 'background_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export enum LinkLinkingCollectionsComponentSectionCollectionOrder {
  BookmarkIdAsc = 'bookmarkId_ASC',
  BookmarkIdDesc = 'bookmarkId_DESC',
  CardContainerOptionsAsc = 'cardContainerOptions_ASC',
  CardContainerOptionsDesc = 'cardContainerOptions_DESC',
  ComponentIdAsc = 'componentId_ASC',
  ComponentIdDesc = 'componentId_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsComponentTextImageTextSectionCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LinkLinkingCollectionsFeaturedContentCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsMemberFirmCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsPersonCollectionOrder {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LinkLinkingCollectionsPressAwardCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LinkLinkingCollectionsRelatedContentCollectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum LinkOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  ExternalLinkAsc = 'externalLink_ASC',
  ExternalLinkDesc = 'externalLink_DESC',
  InternalLinkAsc = 'internalLink_ASC',
  InternalLinkDesc = 'internalLink_DESC',
  OpenInNewWindowAsc = 'openInNewWindow_ASC',
  OpenInNewWindowDesc = 'openInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Login Screen Data [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/loginScreen) */
export type LoginScreen = Entry &
  _Node & {
    __typename?: 'LoginScreen'
    _id: Scalars['ID']
    backgroundImage?: Maybe<Asset>
    contentfulMetadata: ContentfulMetadata
    countdownDatetime?: Maybe<Scalars['DateTime']>
    footerText?: Maybe<Scalars['String']>
    intro?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<LoginScreenLinkingCollections>
    logo?: Maybe<Asset>
    logoPosition?: Maybe<Scalars['String']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Login Screen Data [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/loginScreen) */
export type LoginScreenBackgroundImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Login Screen Data [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/loginScreen) */
export type LoginScreenCountdownDatetimeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Login Screen Data [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/loginScreen) */
export type LoginScreenFooterTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Login Screen Data [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/loginScreen) */
export type LoginScreenIntroArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Login Screen Data [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/loginScreen) */
export type LoginScreenLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Login Screen Data [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/loginScreen) */
export type LoginScreenLogoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Login Screen Data [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/loginScreen) */
export type LoginScreenLogoPositionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Login Screen Data [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/loginScreen) */
export type LoginScreenTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type LoginScreenCollection = {
  __typename?: 'LoginScreenCollection'
  items: Array<Maybe<LoginScreen>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type LoginScreenFilter = {
  AND?: InputMaybe<Array<InputMaybe<LoginScreenFilter>>>
  OR?: InputMaybe<Array<InputMaybe<LoginScreenFilter>>>
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  countdownDatetime?: InputMaybe<Scalars['DateTime']>
  countdownDatetime_exists?: InputMaybe<Scalars['Boolean']>
  countdownDatetime_gt?: InputMaybe<Scalars['DateTime']>
  countdownDatetime_gte?: InputMaybe<Scalars['DateTime']>
  countdownDatetime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  countdownDatetime_lt?: InputMaybe<Scalars['DateTime']>
  countdownDatetime_lte?: InputMaybe<Scalars['DateTime']>
  countdownDatetime_not?: InputMaybe<Scalars['DateTime']>
  countdownDatetime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  footerText?: InputMaybe<Scalars['String']>
  footerText_contains?: InputMaybe<Scalars['String']>
  footerText_exists?: InputMaybe<Scalars['Boolean']>
  footerText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  footerText_not?: InputMaybe<Scalars['String']>
  footerText_not_contains?: InputMaybe<Scalars['String']>
  footerText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  intro?: InputMaybe<Scalars['String']>
  intro_contains?: InputMaybe<Scalars['String']>
  intro_exists?: InputMaybe<Scalars['Boolean']>
  intro_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  intro_not?: InputMaybe<Scalars['String']>
  intro_not_contains?: InputMaybe<Scalars['String']>
  intro_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  logoPosition?: InputMaybe<Scalars['String']>
  logoPosition_contains?: InputMaybe<Scalars['String']>
  logoPosition_exists?: InputMaybe<Scalars['Boolean']>
  logoPosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  logoPosition_not?: InputMaybe<Scalars['String']>
  logoPosition_not_contains?: InputMaybe<Scalars['String']>
  logoPosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  logo_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type LoginScreenLinkingCollections = {
  __typename?: 'LoginScreenLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  firmCollection?: Maybe<FirmCollection>
}

export type LoginScreenLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type LoginScreenLinkingCollectionsFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<LoginScreenLinkingCollectionsFirmCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum LoginScreenLinkingCollectionsFirmCollectionOrder {
  CeCreditsAvailableAsc = 'ceCreditsAvailable_ASC',
  CeCreditsAvailableDesc = 'ceCreditsAvailable_DESC',
  FirmIdAsc = 'firmId_ASC',
  FirmIdDesc = 'firmId_DESC',
  HeaderTypeAsc = 'headerType_ASC',
  HeaderTypeDesc = 'headerType_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum LoginScreenOrder {
  CountdownDatetimeAsc = 'countdownDatetime_ASC',
  CountdownDatetimeDesc = 'countdownDatetime_DESC',
  LogoPositionAsc = 'logoPosition_ASC',
  LogoPositionDesc = 'logoPosition_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Simple field using Markdown. Can be used for footnotes in PWS [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/markdown) */
export type Markdown = Entry &
  _Node & {
    __typename?: 'Markdown'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<MarkdownLinkingCollections>
    sys: Sys
    text?: Maybe<Scalars['String']>
    type?: Maybe<Scalars['String']>
  }

/** Simple field using Markdown. Can be used for footnotes in PWS [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/markdown) */
export type MarkdownInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Simple field using Markdown. Can be used for footnotes in PWS [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/markdown) */
export type MarkdownLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Simple field using Markdown. Can be used for footnotes in PWS [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/markdown) */
export type MarkdownTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Simple field using Markdown. Can be used for footnotes in PWS [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/markdown) */
export type MarkdownTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type MarkdownCollection = {
  __typename?: 'MarkdownCollection'
  items: Array<Maybe<Markdown>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type MarkdownFilter = {
  AND?: InputMaybe<Array<InputMaybe<MarkdownFilter>>>
  OR?: InputMaybe<Array<InputMaybe<MarkdownFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  text?: InputMaybe<Scalars['String']>
  text_contains?: InputMaybe<Scalars['String']>
  text_exists?: InputMaybe<Scalars['Boolean']>
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  text_not?: InputMaybe<Scalars['String']>
  text_not_contains?: InputMaybe<Scalars['String']>
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type?: InputMaybe<Scalars['String']>
  type_contains?: InputMaybe<Scalars['String']>
  type_exists?: InputMaybe<Scalars['Boolean']>
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type_not?: InputMaybe<Scalars['String']>
  type_not_contains?: InputMaybe<Scalars['String']>
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type MarkdownLinkingCollections = {
  __typename?: 'MarkdownLinkingCollections'
  corporateStatsCollection?: Maybe<CorporateStatsCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type MarkdownLinkingCollectionsCorporateStatsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<MarkdownLinkingCollectionsCorporateStatsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type MarkdownLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum MarkdownLinkingCollectionsCorporateStatsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum MarkdownOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

/** Use for permission by firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/memberFirm) */
export type MemberFirm = Entry &
  _Node & {
    __typename?: 'MemberFirm'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    id?: Maybe<Scalars['String']>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<MemberFirmLinkingCollections>
    name?: Maybe<Scalars['String']>
    optionsCollection?: Maybe<MemberFirmOptionsCollection>
    overrideDefault?: Maybe<Scalars['Boolean']>
    parentFirmId?: Maybe<Scalars['String']>
    sys: Sys
  }

/** Use for permission by firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/memberFirm) */
export type MemberFirmIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Use for permission by firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/memberFirm) */
export type MemberFirmInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Use for permission by firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/memberFirm) */
export type MemberFirmLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Use for permission by firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/memberFirm) */
export type MemberFirmNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Use for permission by firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/memberFirm) */
export type MemberFirmOptionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MemberFirmOptionsFilter>
}

/** Use for permission by firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/memberFirm) */
export type MemberFirmOverrideDefaultArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Use for permission by firm [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/memberFirm) */
export type MemberFirmParentFirmIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type MemberFirmCollection = {
  __typename?: 'MemberFirmCollection'
  items: Array<Maybe<MemberFirm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type MemberFirmFilter = {
  AND?: InputMaybe<Array<InputMaybe<MemberFirmFilter>>>
  OR?: InputMaybe<Array<InputMaybe<MemberFirmFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  id?: InputMaybe<Scalars['String']>
  id_contains?: InputMaybe<Scalars['String']>
  id_exists?: InputMaybe<Scalars['Boolean']>
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_not?: InputMaybe<Scalars['String']>
  id_not_contains?: InputMaybe<Scalars['String']>
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  options?: InputMaybe<CfoptionsMultiTypeNestedFilter>
  optionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  overrideDefault?: InputMaybe<Scalars['Boolean']>
  overrideDefault_exists?: InputMaybe<Scalars['Boolean']>
  overrideDefault_not?: InputMaybe<Scalars['Boolean']>
  parentFirmId?: InputMaybe<Scalars['String']>
  parentFirmId_contains?: InputMaybe<Scalars['String']>
  parentFirmId_exists?: InputMaybe<Scalars['Boolean']>
  parentFirmId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  parentFirmId_not?: InputMaybe<Scalars['String']>
  parentFirmId_not_contains?: InputMaybe<Scalars['String']>
  parentFirmId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type MemberFirmLinkingCollections = {
  __typename?: 'MemberFirmLinkingCollections'
  announcementCollection?: Maybe<AnnouncementCollection>
  caisiqArticleCollection?: Maybe<CaisiqArticleCollection>
  caisiqVideoCollection?: Maybe<CaisiqVideoCollection>
  caisiqVideoPlaylistCollection?: Maybe<CaisiqVideoPlaylistCollection>
  callToActionCollection?: Maybe<CallToActionCollection>
  entryCollection?: Maybe<EntryCollection>
  eventCollection?: Maybe<EventCollection>
  externalContentCollection?: Maybe<ExternalContentCollection>
  linkCollection?: Maybe<LinkCollection>
  pdfCollection?: Maybe<PdfCollection>
  whitepaperCollection?: Maybe<WhitepaperCollection>
}

export type MemberFirmLinkingCollectionsAnnouncementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<MemberFirmLinkingCollectionsAnnouncementCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type MemberFirmLinkingCollectionsCaisiqArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<MemberFirmLinkingCollectionsCaisiqArticleCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type MemberFirmLinkingCollectionsCaisiqVideoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<MemberFirmLinkingCollectionsCaisiqVideoCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type MemberFirmLinkingCollectionsCaisiqVideoPlaylistCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<MemberFirmLinkingCollectionsCaisiqVideoPlaylistCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type MemberFirmLinkingCollectionsCallToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<MemberFirmLinkingCollectionsCallToActionCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type MemberFirmLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type MemberFirmLinkingCollectionsEventCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<MemberFirmLinkingCollectionsEventCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type MemberFirmLinkingCollectionsExternalContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<MemberFirmLinkingCollectionsExternalContentCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type MemberFirmLinkingCollectionsLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<MemberFirmLinkingCollectionsLinkCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type MemberFirmLinkingCollectionsPdfCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<MemberFirmLinkingCollectionsPdfCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type MemberFirmLinkingCollectionsWhitepaperCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<MemberFirmLinkingCollectionsWhitepaperCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum MemberFirmLinkingCollectionsAnnouncementCollectionOrder {
  DescriptionAsc = 'Description_ASC',
  DescriptionDesc = 'Description_DESC',
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  DateTimeEndAsc = 'dateTimeEnd_ASC',
  DateTimeEndDesc = 'dateTimeEnd_DESC',
  DateTimeStartAsc = 'dateTimeStart_ASC',
  DateTimeStartDesc = 'dateTimeStart_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimezoneAsc = 'timezone_ASC',
  TimezoneDesc = 'timezone_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum MemberFirmLinkingCollectionsCaisiqArticleCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum MemberFirmLinkingCollectionsCaisiqVideoCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  MuxThumbnailTimeAsc = 'muxThumbnailTime_ASC',
  MuxThumbnailTimeDesc = 'muxThumbnailTime_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum MemberFirmLinkingCollectionsCaisiqVideoPlaylistCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  EpisodicAsc = 'episodic_ASC',
  EpisodicDesc = 'episodic_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum MemberFirmLinkingCollectionsCallToActionCollectionOrder {
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum MemberFirmLinkingCollectionsEventCollectionOrder {
  AllDayAsc = 'allDay_ASC',
  AllDayDesc = 'allDay_DESC',
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DateTimeEndAsc = 'dateTimeEnd_ASC',
  DateTimeEndDesc = 'dateTimeEnd_DESC',
  DateTimeAsc = 'dateTime_ASC',
  DateTimeDesc = 'dateTime_DESC',
  EventTypeAsc = 'eventType_ASC',
  EventTypeDesc = 'eventType_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  LocationNameAsc = 'locationName_ASC',
  LocationNameDesc = 'locationName_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimezoneAsc = 'timezone_ASC',
  TimezoneDesc = 'timezone_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum MemberFirmLinkingCollectionsExternalContentCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  EligibilityAsc = 'eligibility_ASC',
  EligibilityDesc = 'eligibility_DESC',
  ExternalIdAsc = 'externalId_ASC',
  ExternalIdDesc = 'externalId_DESC',
  ExternalLinkAsc = 'externalLink_ASC',
  ExternalLinkDesc = 'externalLink_DESC',
  MinimumInitialInvestmentAsc = 'minimumInitialInvestment_ASC',
  MinimumInitialInvestmentDesc = 'minimumInitialInvestment_DESC',
  StrategyAsc = 'strategy_ASC',
  StrategyDesc = 'strategy_DESC',
  SubStrategyAsc = 'subStrategy_ASC',
  SubStrategyDesc = 'subStrategy_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum MemberFirmLinkingCollectionsLinkCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  ExternalLinkAsc = 'externalLink_ASC',
  ExternalLinkDesc = 'externalLink_DESC',
  InternalLinkAsc = 'internalLink_ASC',
  InternalLinkDesc = 'internalLink_DESC',
  OpenInNewWindowAsc = 'openInNewWindow_ASC',
  OpenInNewWindowDesc = 'openInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum MemberFirmLinkingCollectionsPdfCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum MemberFirmLinkingCollectionsWhitepaperCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type MemberFirmOptionsCollection = {
  __typename?: 'MemberFirmOptionsCollection'
  items: Array<Maybe<MemberFirmOptionsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type MemberFirmOptionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<MemberFirmOptionsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<MemberFirmOptionsFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type MemberFirmOptionsItem =
  | CaisiqArticle
  | CaisiqVideo
  | CaisiqVideoPlaylist
  | Event
  | ExternalContent
  | Link
  | Pdf
  | Whitepaper

export enum MemberFirmOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/migration) */
export type Migration = Entry &
  _Node & {
    __typename?: 'Migration'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<MigrationLinkingCollections>
    name?: Maybe<Scalars['String']>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/migration) */
export type MigrationLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/migration) */
export type MigrationNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type MigrationCollection = {
  __typename?: 'MigrationCollection'
  items: Array<Maybe<Migration>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type MigrationFilter = {
  AND?: InputMaybe<Array<InputMaybe<MigrationFilter>>>
  OR?: InputMaybe<Array<InputMaybe<MigrationFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type MigrationLinkingCollections = {
  __typename?: 'MigrationLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type MigrationLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum MigrationOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Used to allow different headers on different types of pages, eg admin pages [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/navHeaderTheme) */
export type NavHeaderTheme = Entry &
  _Node & {
    __typename?: 'NavHeaderTheme'
    _id: Scalars['ID']
    backgroundColor?: Maybe<Scalars['String']>
    clientLogo?: Maybe<Asset>
    contentfulMetadata: ContentfulMetadata
    darkAppLogo?: Maybe<Scalars['Boolean']>
    foregroundColor?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<NavHeaderThemeLinkingCollections>
    name?: Maybe<Scalars['String']>
    poweredBy?: Maybe<Scalars['Boolean']>
    sys: Sys
    underlineColor?: Maybe<Scalars['String']>
    underlineOpacity?: Maybe<Scalars['Float']>
  }

/** Used to allow different headers on different types of pages, eg admin pages [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/navHeaderTheme) */
export type NavHeaderThemeBackgroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Used to allow different headers on different types of pages, eg admin pages [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/navHeaderTheme) */
export type NavHeaderThemeClientLogoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Used to allow different headers on different types of pages, eg admin pages [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/navHeaderTheme) */
export type NavHeaderThemeDarkAppLogoArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Used to allow different headers on different types of pages, eg admin pages [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/navHeaderTheme) */
export type NavHeaderThemeForegroundColorArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Used to allow different headers on different types of pages, eg admin pages [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/navHeaderTheme) */
export type NavHeaderThemeLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Used to allow different headers on different types of pages, eg admin pages [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/navHeaderTheme) */
export type NavHeaderThemeNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Used to allow different headers on different types of pages, eg admin pages [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/navHeaderTheme) */
export type NavHeaderThemePoweredByArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Used to allow different headers on different types of pages, eg admin pages [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/navHeaderTheme) */
export type NavHeaderThemeUnderlineColorArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Used to allow different headers on different types of pages, eg admin pages [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/navHeaderTheme) */
export type NavHeaderThemeUnderlineOpacityArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type NavHeaderThemeCollection = {
  __typename?: 'NavHeaderThemeCollection'
  items: Array<Maybe<NavHeaderTheme>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type NavHeaderThemeFilter = {
  AND?: InputMaybe<Array<InputMaybe<NavHeaderThemeFilter>>>
  OR?: InputMaybe<Array<InputMaybe<NavHeaderThemeFilter>>>
  backgroundColor?: InputMaybe<Scalars['String']>
  backgroundColor_contains?: InputMaybe<Scalars['String']>
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  backgroundColor_not?: InputMaybe<Scalars['String']>
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  clientLogo_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  darkAppLogo?: InputMaybe<Scalars['Boolean']>
  darkAppLogo_exists?: InputMaybe<Scalars['Boolean']>
  darkAppLogo_not?: InputMaybe<Scalars['Boolean']>
  foregroundColor?: InputMaybe<Scalars['String']>
  foregroundColor_contains?: InputMaybe<Scalars['String']>
  foregroundColor_exists?: InputMaybe<Scalars['Boolean']>
  foregroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  foregroundColor_not?: InputMaybe<Scalars['String']>
  foregroundColor_not_contains?: InputMaybe<Scalars['String']>
  foregroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  poweredBy?: InputMaybe<Scalars['Boolean']>
  poweredBy_exists?: InputMaybe<Scalars['Boolean']>
  poweredBy_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  underlineColor?: InputMaybe<Scalars['String']>
  underlineColor_contains?: InputMaybe<Scalars['String']>
  underlineColor_exists?: InputMaybe<Scalars['Boolean']>
  underlineColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  underlineColor_not?: InputMaybe<Scalars['String']>
  underlineColor_not_contains?: InputMaybe<Scalars['String']>
  underlineColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  underlineOpacity?: InputMaybe<Scalars['Float']>
  underlineOpacity_exists?: InputMaybe<Scalars['Boolean']>
  underlineOpacity_gt?: InputMaybe<Scalars['Float']>
  underlineOpacity_gte?: InputMaybe<Scalars['Float']>
  underlineOpacity_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  underlineOpacity_lt?: InputMaybe<Scalars['Float']>
  underlineOpacity_lte?: InputMaybe<Scalars['Float']>
  underlineOpacity_not?: InputMaybe<Scalars['Float']>
  underlineOpacity_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
}

export type NavHeaderThemeLinkingCollections = {
  __typename?: 'NavHeaderThemeLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  themeCollection?: Maybe<ThemeCollection>
}

export type NavHeaderThemeLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type NavHeaderThemeLinkingCollectionsThemeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<NavHeaderThemeLinkingCollectionsThemeCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum NavHeaderThemeLinkingCollectionsThemeCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum NavHeaderThemeOrder {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  DarkAppLogoAsc = 'darkAppLogo_ASC',
  DarkAppLogoDesc = 'darkAppLogo_DESC',
  ForegroundColorAsc = 'foregroundColor_ASC',
  ForegroundColorDesc = 'foregroundColor_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PoweredByAsc = 'poweredBy_ASC',
  PoweredByDesc = 'poweredBy_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UnderlineColorAsc = 'underlineColor_ASC',
  UnderlineColorDesc = 'underlineColor_DESC',
  UnderlineOpacityAsc = 'underlineOpacity_ASC',
  UnderlineOpacityDesc = 'underlineOpacity_DESC',
}

/** Represents a web page in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/page) */
export type Page = Entry &
  _Node & {
    __typename?: 'Page'
    _id: Scalars['ID']
    content?: Maybe<PageContent>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<PageLinkingCollections>
    name?: Maybe<Scalars['String']>
    seo?: Maybe<Seo>
    slug?: Maybe<Scalars['String']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Represents a web page in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/page) */
export type PageContentArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** Represents a web page in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/page) */
export type PageLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Represents a web page in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/page) */
export type PageNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a web page in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/page) */
export type PageSeoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<SeoFilter>
}

/** Represents a web page in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/page) */
export type PageSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Represents a web page in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/page) */
export type PageTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PageCollection = {
  __typename?: 'PageCollection'
  items: Array<Maybe<Page>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PageContent =
  | CaisiqArticle
  | CaisiqVideo
  | CaisiqVideoPlaylist
  | Company
  | PageHelpCenterArticle
  | PageLanding
  | PagePwsCategory
  | Person
  | PwsPressRelease
  | Whitepaper

export type PageFilter = {
  AND?: InputMaybe<Array<InputMaybe<PageFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PageFilter>>>
  content_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  seo?: InputMaybe<CfSeoNestedFilter>
  seo_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** An entry in the help center documentation. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/page_help_center_article) */
export type PageHelpCenterArticle = Entry &
  _Node & {
    __typename?: 'PageHelpCenterArticle'
    _id: Scalars['ID']
    bodyCollection?: Maybe<PageHelpCenterArticleBodyCollection>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<PageHelpCenterArticleLinkingCollections>
    name?: Maybe<Scalars['String']>
    relatedPagesCollection?: Maybe<PageHelpCenterArticleRelatedPagesCollection>
    sys: Sys
  }

/** An entry in the help center documentation. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/page_help_center_article) */
export type PageHelpCenterArticleBodyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PageHelpCenterArticleBodyFilter>
}

/** An entry in the help center documentation. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/page_help_center_article) */
export type PageHelpCenterArticleLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** An entry in the help center documentation. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/page_help_center_article) */
export type PageHelpCenterArticleNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** An entry in the help center documentation. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/page_help_center_article) */
export type PageHelpCenterArticleRelatedPagesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<PageHelpCenterArticleRelatedPagesCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PageFilter>
}

export type PageHelpCenterArticleBodyCollection = {
  __typename?: 'PageHelpCenterArticleBodyCollection'
  items: Array<Maybe<PageHelpCenterArticleBodyItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PageHelpCenterArticleBodyFilter = {
  AND?: InputMaybe<Array<InputMaybe<PageHelpCenterArticleBodyFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PageHelpCenterArticleBodyFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PageHelpCenterArticleBodyItem = ComponentImage | ComponentText

export type PageHelpCenterArticleCollection = {
  __typename?: 'PageHelpCenterArticleCollection'
  items: Array<Maybe<PageHelpCenterArticle>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PageHelpCenterArticleFilter = {
  AND?: InputMaybe<Array<InputMaybe<PageHelpCenterArticleFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PageHelpCenterArticleFilter>>>
  body?: InputMaybe<CfbodyMultiTypeNestedFilter>
  bodyCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  relatedPages?: InputMaybe<CfPageNestedFilter>
  relatedPagesCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type PageHelpCenterArticleLinkingCollections = {
  __typename?: 'PageHelpCenterArticleLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pageCollection?: Maybe<PageCollection>
}

export type PageHelpCenterArticleLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PageHelpCenterArticleLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PageHelpCenterArticleLinkingCollectionsPageCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum PageHelpCenterArticleLinkingCollectionsPageCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PageHelpCenterArticleOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PageHelpCenterArticleRelatedPagesCollection = {
  __typename?: 'PageHelpCenterArticleRelatedPagesCollection'
  items: Array<Maybe<Page>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum PageHelpCenterArticleRelatedPagesCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/page_landing) */
export type PageLanding = Entry &
  _Node & {
    __typename?: 'PageLanding'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    disclaimer?: Maybe<Disclaimer>
    featured?: Maybe<PageLandingFeatured>
    hero?: Maybe<ComponentHero>
    linkedFrom?: Maybe<PageLandingLinkingCollections>
    name?: Maybe<Scalars['String']>
    pageType?: Maybe<Scalars['String']>
    sectionsCollection?: Maybe<PageLandingSectionsCollection>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/page_landing) */
export type PageLandingDisclaimerArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<DisclaimerFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/page_landing) */
export type PageLandingFeaturedArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/page_landing) */
export type PageLandingHeroArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentHeroFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/page_landing) */
export type PageLandingLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/page_landing) */
export type PageLandingNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/page_landing) */
export type PageLandingPageTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/page_landing) */
export type PageLandingSectionsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PageLandingSectionsFilter>
}

export type PageLandingCollection = {
  __typename?: 'PageLandingCollection'
  items: Array<Maybe<PageLanding>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PageLandingFeatured =
  | CaisiqArticle
  | CaisiqVideo
  | PwsPressRelease
  | Whitepaper

export type PageLandingFilter = {
  AND?: InputMaybe<Array<InputMaybe<PageLandingFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PageLandingFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  disclaimer?: InputMaybe<CfDisclaimerNestedFilter>
  disclaimer_exists?: InputMaybe<Scalars['Boolean']>
  featured_exists?: InputMaybe<Scalars['Boolean']>
  hero?: InputMaybe<CfComponentHeroNestedFilter>
  hero_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  pageType?: InputMaybe<Scalars['String']>
  pageType_contains?: InputMaybe<Scalars['String']>
  pageType_exists?: InputMaybe<Scalars['Boolean']>
  pageType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  pageType_not?: InputMaybe<Scalars['String']>
  pageType_not_contains?: InputMaybe<Scalars['String']>
  pageType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sections?: InputMaybe<CfsectionsMultiTypeNestedFilter>
  sectionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type PageLandingLinkingCollections = {
  __typename?: 'PageLandingLinkingCollections'
  accordionCollection?: Maybe<AccordionCollection>
  entryCollection?: Maybe<EntryCollection>
  pageCollection?: Maybe<PageCollection>
}

export type PageLandingLinkingCollectionsAccordionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<PageLandingLinkingCollectionsAccordionCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PageLandingLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PageLandingLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<PageLandingLinkingCollectionsPageCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum PageLandingLinkingCollectionsAccordionCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PageLandingLinkingCollectionsPageCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PageLandingOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PageLandingSectionsCollection = {
  __typename?: 'PageLandingSectionsCollection'
  items: Array<Maybe<PageLandingSectionsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PageLandingSectionsFilter = {
  AND?: InputMaybe<Array<InputMaybe<PageLandingSectionsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PageLandingSectionsFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
}

export type PageLandingSectionsItem =
  | AccordionWithImage
  | CaisiqJoinCaisCallToAction
  | CaisiqVideo
  | CaisiqVideoPlaylist
  | CallToAction
  | ComponentCompanyContainer
  | ComponentHubspotForm
  | ComponentPodcastPlayer
  | ComponentSection
  | ComponentSummary
  | ComponentTeamContainer
  | ComponentText
  | ComponentTextIllustration
  | ComponentTextImage
  | CorporateStats
  | ImageSection
  | PlaylistContentSet
  | PwsContactMedia
  | PwsInsightsContentSet
  | Quote
  | RelatedContent
  | SkPlayground

export type PageLinkingCollections = {
  __typename?: 'PageLinkingCollections'
  accordionCollection?: Maybe<AccordionCollection>
  entryCollection?: Maybe<EntryCollection>
  linkCollection?: Maybe<LinkCollection>
  pageHelpCenterArticleCollection?: Maybe<PageHelpCenterArticleCollection>
  relatedContentCollection?: Maybe<RelatedContentCollection>
  relatedContentStrategyCollection?: Maybe<RelatedContentStrategyCollection>
}

export type PageLinkingCollectionsAccordionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<PageLinkingCollectionsAccordionCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PageLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PageLinkingCollectionsLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<PageLinkingCollectionsLinkCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PageLinkingCollectionsPageHelpCenterArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PageLinkingCollectionsPageHelpCenterArticleCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PageLinkingCollectionsRelatedContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<PageLinkingCollectionsRelatedContentCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PageLinkingCollectionsRelatedContentStrategyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PageLinkingCollectionsRelatedContentStrategyCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum PageLinkingCollectionsAccordionCollectionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PageLinkingCollectionsLinkCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  ExternalLinkAsc = 'externalLink_ASC',
  ExternalLinkDesc = 'externalLink_DESC',
  InternalLinkAsc = 'internalLink_ASC',
  InternalLinkDesc = 'internalLink_DESC',
  OpenInNewWindowAsc = 'openInNewWindow_ASC',
  OpenInNewWindowDesc = 'openInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PageLinkingCollectionsPageHelpCenterArticleCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PageLinkingCollectionsRelatedContentCollectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PageLinkingCollectionsRelatedContentStrategyCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SortingAsc = 'sorting_ASC',
  SortingDesc = 'sorting_DESC',
  SourceAsc = 'source_ASC',
  SourceDesc = 'source_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PageOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Create categories for organizing PWS content such as; Articles, Whitepapers, and Videos [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pagePwsCategory) */
export type PagePwsCategory = Entry &
  _Node & {
    __typename?: 'PagePwsCategory'
    _id: Scalars['ID']
    categoryId?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    featuredItem?: Maybe<CaisiqArticle>
    linkedFrom?: Maybe<PagePwsCategoryLinkingCollections>
    name?: Maybe<Scalars['String']>
    showContentDate?: Maybe<Scalars['Boolean']>
    showPostCount?: Maybe<Scalars['Boolean']>
    summary?: Maybe<PagePwsCategorySummary>
    sys: Sys
  }

/** Create categories for organizing PWS content such as; Articles, Whitepapers, and Videos [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pagePwsCategory) */
export type PagePwsCategoryCategoryIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create categories for organizing PWS content such as; Articles, Whitepapers, and Videos [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pagePwsCategory) */
export type PagePwsCategoryFeaturedItemArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CaisiqArticleFilter>
}

/** Create categories for organizing PWS content such as; Articles, Whitepapers, and Videos [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pagePwsCategory) */
export type PagePwsCategoryLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Create categories for organizing PWS content such as; Articles, Whitepapers, and Videos [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pagePwsCategory) */
export type PagePwsCategoryNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create categories for organizing PWS content such as; Articles, Whitepapers, and Videos [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pagePwsCategory) */
export type PagePwsCategoryShowContentDateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create categories for organizing PWS content such as; Articles, Whitepapers, and Videos [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pagePwsCategory) */
export type PagePwsCategoryShowPostCountArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Create categories for organizing PWS content such as; Articles, Whitepapers, and Videos [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pagePwsCategory) */
export type PagePwsCategorySummaryArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PagePwsCategoryCollection = {
  __typename?: 'PagePwsCategoryCollection'
  items: Array<Maybe<PagePwsCategory>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PagePwsCategoryFilter = {
  AND?: InputMaybe<Array<InputMaybe<PagePwsCategoryFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PagePwsCategoryFilter>>>
  categoryId?: InputMaybe<Scalars['String']>
  categoryId_contains?: InputMaybe<Scalars['String']>
  categoryId_exists?: InputMaybe<Scalars['Boolean']>
  categoryId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  categoryId_not?: InputMaybe<Scalars['String']>
  categoryId_not_contains?: InputMaybe<Scalars['String']>
  categoryId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  featuredItem?: InputMaybe<CfCaisiqArticleNestedFilter>
  featuredItem_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showContentDate?: InputMaybe<Scalars['Boolean']>
  showContentDate_exists?: InputMaybe<Scalars['Boolean']>
  showContentDate_not?: InputMaybe<Scalars['Boolean']>
  showPostCount?: InputMaybe<Scalars['Boolean']>
  showPostCount_exists?: InputMaybe<Scalars['Boolean']>
  showPostCount_not?: InputMaybe<Scalars['Boolean']>
  summary_contains?: InputMaybe<Scalars['String']>
  summary_exists?: InputMaybe<Scalars['Boolean']>
  summary_not_contains?: InputMaybe<Scalars['String']>
  sys?: InputMaybe<SysFilter>
}

export type PagePwsCategoryLinkingCollections = {
  __typename?: 'PagePwsCategoryLinkingCollections'
  caisiqArticleCollection?: Maybe<CaisiqArticleCollection>
  caisiqVideoCollection?: Maybe<CaisiqVideoCollection>
  ciqArticleCollection?: Maybe<CiqArticleCollection>
  entryCollection?: Maybe<EntryCollection>
  pageCollection?: Maybe<PageCollection>
  pwsInsightsContentSetCollection?: Maybe<PwsInsightsContentSetCollection>
  whitepaperCollection?: Maybe<WhitepaperCollection>
}

export type PagePwsCategoryLinkingCollectionsCaisiqArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagePwsCategoryLinkingCollectionsCaisiqArticleCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagePwsCategoryLinkingCollectionsCaisiqVideoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagePwsCategoryLinkingCollectionsCaisiqVideoCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagePwsCategoryLinkingCollectionsCiqArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagePwsCategoryLinkingCollectionsCiqArticleCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagePwsCategoryLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagePwsCategoryLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<PagePwsCategoryLinkingCollectionsPageCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PagePwsCategoryLinkingCollectionsPwsInsightsContentSetCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PagePwsCategoryLinkingCollectionsPwsInsightsContentSetCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PagePwsCategoryLinkingCollectionsWhitepaperCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PagePwsCategoryLinkingCollectionsWhitepaperCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum PagePwsCategoryLinkingCollectionsCaisiqArticleCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagePwsCategoryLinkingCollectionsCaisiqVideoCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  MuxThumbnailTimeAsc = 'muxThumbnailTime_ASC',
  MuxThumbnailTimeDesc = 'muxThumbnailTime_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagePwsCategoryLinkingCollectionsCiqArticleCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagePwsCategoryLinkingCollectionsPageCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagePwsCategoryLinkingCollectionsPwsInsightsContentSetCollectionOrder {
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  LayoutStyleAsc = 'layoutStyle_ASC',
  LayoutStyleDesc = 'layoutStyle_DESC',
  SortingAsc = 'sorting_ASC',
  SortingDesc = 'sorting_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagePwsCategoryLinkingCollectionsWhitepaperCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PagePwsCategoryOrder {
  CategoryIdAsc = 'categoryId_ASC',
  CategoryIdDesc = 'categoryId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ShowContentDateAsc = 'showContentDate_ASC',
  ShowContentDateDesc = 'showContentDate_DESC',
  ShowPostCountAsc = 'showPostCount_ASC',
  ShowPostCountDesc = 'showPostCount_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PagePwsCategorySummary = {
  __typename?: 'PagePwsCategorySummary'
  json: Scalars['JSON']
  links: PagePwsCategorySummaryLinks
}

export type PagePwsCategorySummaryAssets = {
  __typename?: 'PagePwsCategorySummaryAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type PagePwsCategorySummaryEntries = {
  __typename?: 'PagePwsCategorySummaryEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type PagePwsCategorySummaryLinks = {
  __typename?: 'PagePwsCategorySummaryLinks'
  assets: PagePwsCategorySummaryAssets
  entries: PagePwsCategorySummaryEntries
  resources: PagePwsCategorySummaryResources
}

export type PagePwsCategorySummaryResources = {
  __typename?: 'PagePwsCategorySummaryResources'
  block: Array<PagePwsCategorySummaryResourcesBlock>
  hyperlink: Array<PagePwsCategorySummaryResourcesHyperlink>
  inline: Array<PagePwsCategorySummaryResourcesInline>
}

export type PagePwsCategorySummaryResourcesBlock = ResourceLink & {
  __typename?: 'PagePwsCategorySummaryResourcesBlock'
  sys: ResourceSys
}

export type PagePwsCategorySummaryResourcesHyperlink = ResourceLink & {
  __typename?: 'PagePwsCategorySummaryResourcesHyperlink'
  sys: ResourceSys
}

export type PagePwsCategorySummaryResourcesInline = ResourceLink & {
  __typename?: 'PagePwsCategorySummaryResourcesInline'
  sys: ResourceSys
}

/** A content type that is a general resource page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pageResource) */
export type PageResource = Entry &
  _Node & {
    __typename?: 'PageResource'
    _id: Scalars['ID']
    author?: Maybe<CaisiqAuthor>
    body?: Maybe<PageResourceBody>
    contentfulMetadata: ContentfulMetadata
    displayDate?: Maybe<Scalars['DateTime']>
    hero?: Maybe<Scalars['JSON']>
    introduction?: Maybe<PageResourceIntroduction>
    linkedFrom?: Maybe<PageResourceLinkingCollections>
    slug?: Maybe<Scalars['String']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** A content type that is a general resource page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pageResource) */
export type PageResourceAuthorArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CaisiqAuthorFilter>
}

/** A content type that is a general resource page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pageResource) */
export type PageResourceBodyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content type that is a general resource page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pageResource) */
export type PageResourceDisplayDateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content type that is a general resource page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pageResource) */
export type PageResourceHeroArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content type that is a general resource page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pageResource) */
export type PageResourceIntroductionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content type that is a general resource page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pageResource) */
export type PageResourceLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A content type that is a general resource page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pageResource) */
export type PageResourceSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A content type that is a general resource page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pageResource) */
export type PageResourceTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PageResourceBody = {
  __typename?: 'PageResourceBody'
  json: Scalars['JSON']
  links: PageResourceBodyLinks
}

export type PageResourceBodyAssets = {
  __typename?: 'PageResourceBodyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type PageResourceBodyEntries = {
  __typename?: 'PageResourceBodyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type PageResourceBodyLinks = {
  __typename?: 'PageResourceBodyLinks'
  assets: PageResourceBodyAssets
  entries: PageResourceBodyEntries
  resources: PageResourceBodyResources
}

export type PageResourceBodyResources = {
  __typename?: 'PageResourceBodyResources'
  block: Array<PageResourceBodyResourcesBlock>
  hyperlink: Array<PageResourceBodyResourcesHyperlink>
  inline: Array<PageResourceBodyResourcesInline>
}

export type PageResourceBodyResourcesBlock = ResourceLink & {
  __typename?: 'PageResourceBodyResourcesBlock'
  sys: ResourceSys
}

export type PageResourceBodyResourcesHyperlink = ResourceLink & {
  __typename?: 'PageResourceBodyResourcesHyperlink'
  sys: ResourceSys
}

export type PageResourceBodyResourcesInline = ResourceLink & {
  __typename?: 'PageResourceBodyResourcesInline'
  sys: ResourceSys
}

export type PageResourceCollection = {
  __typename?: 'PageResourceCollection'
  items: Array<Maybe<PageResource>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PageResourceFilter = {
  AND?: InputMaybe<Array<InputMaybe<PageResourceFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PageResourceFilter>>>
  author?: InputMaybe<CfCaisiqAuthorNestedFilter>
  author_exists?: InputMaybe<Scalars['Boolean']>
  body_contains?: InputMaybe<Scalars['String']>
  body_exists?: InputMaybe<Scalars['Boolean']>
  body_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  displayDate?: InputMaybe<Scalars['DateTime']>
  displayDate_exists?: InputMaybe<Scalars['Boolean']>
  displayDate_gt?: InputMaybe<Scalars['DateTime']>
  displayDate_gte?: InputMaybe<Scalars['DateTime']>
  displayDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  displayDate_lt?: InputMaybe<Scalars['DateTime']>
  displayDate_lte?: InputMaybe<Scalars['DateTime']>
  displayDate_not?: InputMaybe<Scalars['DateTime']>
  displayDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  hero_exists?: InputMaybe<Scalars['Boolean']>
  introduction_contains?: InputMaybe<Scalars['String']>
  introduction_exists?: InputMaybe<Scalars['Boolean']>
  introduction_not_contains?: InputMaybe<Scalars['String']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PageResourceIntroduction = {
  __typename?: 'PageResourceIntroduction'
  json: Scalars['JSON']
  links: PageResourceIntroductionLinks
}

export type PageResourceIntroductionAssets = {
  __typename?: 'PageResourceIntroductionAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type PageResourceIntroductionEntries = {
  __typename?: 'PageResourceIntroductionEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type PageResourceIntroductionLinks = {
  __typename?: 'PageResourceIntroductionLinks'
  assets: PageResourceIntroductionAssets
  entries: PageResourceIntroductionEntries
  resources: PageResourceIntroductionResources
}

export type PageResourceIntroductionResources = {
  __typename?: 'PageResourceIntroductionResources'
  block: Array<PageResourceIntroductionResourcesBlock>
  hyperlink: Array<PageResourceIntroductionResourcesHyperlink>
  inline: Array<PageResourceIntroductionResourcesInline>
}

export type PageResourceIntroductionResourcesBlock = ResourceLink & {
  __typename?: 'PageResourceIntroductionResourcesBlock'
  sys: ResourceSys
}

export type PageResourceIntroductionResourcesHyperlink = ResourceLink & {
  __typename?: 'PageResourceIntroductionResourcesHyperlink'
  sys: ResourceSys
}

export type PageResourceIntroductionResourcesInline = ResourceLink & {
  __typename?: 'PageResourceIntroductionResourcesInline'
  sys: ResourceSys
}

export type PageResourceLinkingCollections = {
  __typename?: 'PageResourceLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  linkCollection?: Maybe<LinkCollection>
}

export type PageResourceLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PageResourceLinkingCollectionsLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<PageResourceLinkingCollectionsLinkCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum PageResourceLinkingCollectionsLinkCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  ExternalLinkAsc = 'externalLink_ASC',
  ExternalLinkDesc = 'externalLink_DESC',
  InternalLinkAsc = 'internalLink_ASC',
  InternalLinkDesc = 'internalLink_DESC',
  OpenInNewWindowAsc = 'openInNewWindow_ASC',
  OpenInNewWindowDesc = 'openInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PageResourceOrder {
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/parentFirm) */
export type ParentFirm = Entry &
  _Node & {
    __typename?: 'ParentFirm'
    _id: Scalars['ID']
    childFirms2?: Maybe<Array<Maybe<Scalars['String']>>>
    childFirms3?: Maybe<Scalars['JSON']>
    childFirmsCollection?: Maybe<ParentFirmChildFirmsCollection>
    contentfulMetadata: ContentfulMetadata
    firmId?: Maybe<Scalars['String']>
    firmName?: Maybe<Scalars['String']>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<ParentFirmLinkingCollections>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/parentFirm) */
export type ParentFirmChildFirms2Args = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/parentFirm) */
export type ParentFirmChildFirms3Args = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/parentFirm) */
export type ParentFirmChildFirmsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ParentFirmChildFirmsFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/parentFirm) */
export type ParentFirmFirmIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/parentFirm) */
export type ParentFirmFirmNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/parentFirm) */
export type ParentFirmInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/parentFirm) */
export type ParentFirmLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type ParentFirmChildFirmsCollection = {
  __typename?: 'ParentFirmChildFirmsCollection'
  items: Array<Maybe<ParentFirmChildFirmsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ParentFirmChildFirmsFilter = {
  AND?: InputMaybe<Array<InputMaybe<ParentFirmChildFirmsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ParentFirmChildFirmsFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  firmId?: InputMaybe<Scalars['String']>
  firmId_contains?: InputMaybe<Scalars['String']>
  firmId_exists?: InputMaybe<Scalars['Boolean']>
  firmId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmId_not?: InputMaybe<Scalars['String']>
  firmId_not_contains?: InputMaybe<Scalars['String']>
  firmId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmName?: InputMaybe<Scalars['String']>
  firmName_contains?: InputMaybe<Scalars['String']>
  firmName_exists?: InputMaybe<Scalars['Boolean']>
  firmName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmName_not?: InputMaybe<Scalars['String']>
  firmName_not_contains?: InputMaybe<Scalars['String']>
  firmName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ParentFirmChildFirmsItem = ChildFirm | ParentFirm

export type ParentFirmCollection = {
  __typename?: 'ParentFirmCollection'
  items: Array<Maybe<ParentFirm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ParentFirmFilter = {
  AND?: InputMaybe<Array<InputMaybe<ParentFirmFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ParentFirmFilter>>>
  childFirms?: InputMaybe<CfchildFirmsMultiTypeNestedFilter>
  childFirms2_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  childFirms2_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  childFirms2_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  childFirms2_exists?: InputMaybe<Scalars['Boolean']>
  childFirms3_exists?: InputMaybe<Scalars['Boolean']>
  childFirmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  firmId?: InputMaybe<Scalars['String']>
  firmId_contains?: InputMaybe<Scalars['String']>
  firmId_exists?: InputMaybe<Scalars['Boolean']>
  firmId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmId_not?: InputMaybe<Scalars['String']>
  firmId_not_contains?: InputMaybe<Scalars['String']>
  firmId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmName?: InputMaybe<Scalars['String']>
  firmName_contains?: InputMaybe<Scalars['String']>
  firmName_exists?: InputMaybe<Scalars['Boolean']>
  firmName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmName_not?: InputMaybe<Scalars['String']>
  firmName_not_contains?: InputMaybe<Scalars['String']>
  firmName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type ParentFirmLinkingCollections = {
  __typename?: 'ParentFirmLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  parentFirmCollection?: Maybe<ParentFirmCollection>
}

export type ParentFirmLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ParentFirmLinkingCollectionsParentFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ParentFirmLinkingCollectionsParentFirmCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ParentFirmLinkingCollectionsParentFirmCollectionOrder {
  FirmIdAsc = 'firmId_ASC',
  FirmIdDesc = 'firmId_DESC',
  FirmNameAsc = 'firmName_ASC',
  FirmNameDesc = 'firmName_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ParentFirmOrder {
  FirmIdAsc = 'firmId_ASC',
  FirmIdDesc = 'firmId_DESC',
  FirmNameAsc = 'firmName_ASC',
  FirmNameDesc = 'firmName_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pdf) */
export type Pdf = Entry &
  _Node & {
    __typename?: 'Pdf'
    _id: Scalars['ID']
    byCais?: Maybe<Scalars['Boolean']>
    contentfulMetadata: ContentfulMetadata
    customDescription?: Maybe<Scalars['String']>
    customTitle?: Maybe<Scalars['String']>
    displayDate?: Maybe<Scalars['DateTime']>
    firmsCollection?: Maybe<PdfFirmsCollection>
    image?: Maybe<Asset>
    linkedFrom?: Maybe<PdfLinkingCollections>
    pdf?: Maybe<Asset>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pdf) */
export type PdfByCaisArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pdf) */
export type PdfCustomDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pdf) */
export type PdfCustomTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pdf) */
export type PdfDisplayDateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pdf) */
export type PdfFirmsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PdfFirmsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MemberFirmFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pdf) */
export type PdfImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pdf) */
export type PdfLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pdf) */
export type PdfPdfArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pdf) */
export type PdfTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PdfCollection = {
  __typename?: 'PdfCollection'
  items: Array<Maybe<Pdf>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PdfFilter = {
  AND?: InputMaybe<Array<InputMaybe<PdfFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PdfFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayDate?: InputMaybe<Scalars['DateTime']>
  displayDate_exists?: InputMaybe<Scalars['Boolean']>
  displayDate_gt?: InputMaybe<Scalars['DateTime']>
  displayDate_gte?: InputMaybe<Scalars['DateTime']>
  displayDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  displayDate_lt?: InputMaybe<Scalars['DateTime']>
  displayDate_lte?: InputMaybe<Scalars['DateTime']>
  displayDate_not?: InputMaybe<Scalars['DateTime']>
  displayDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  firms?: InputMaybe<CfMemberFirmNestedFilter>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  pdf_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PdfFirmsCollection = {
  __typename?: 'PdfFirmsCollection'
  items: Array<Maybe<MemberFirm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum PdfFirmsCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PdfLinkingCollections = {
  __typename?: 'PdfLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  legalPageCollection?: Maybe<LegalPageCollection>
  memberFirmCollection?: Maybe<MemberFirmCollection>
  whitepaperCollection?: Maybe<WhitepaperCollection>
}

export type PdfLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PdfLinkingCollectionsLegalPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<PdfLinkingCollectionsLegalPageCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PdfLinkingCollectionsMemberFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<PdfLinkingCollectionsMemberFirmCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PdfLinkingCollectionsWhitepaperCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<PdfLinkingCollectionsWhitepaperCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum PdfLinkingCollectionsLegalPageCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PdfLinkingCollectionsMemberFirmCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PdfLinkingCollectionsWhitepaperCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PdfOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** An individual that may, for example, appear in presentations at conferences [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/person) */
export type Person = Entry &
  _Node & {
    __typename?: 'Person'
    _id: Scalars['ID']
    bio?: Maybe<PersonBio>
    callToAction?: Maybe<CallToAction>
    company?: Maybe<Company>
    contentfulMetadata: ContentfulMetadata
    email?: Maybe<Scalars['String']>
    fullName?: Maybe<Scalars['String']>
    imageSet?: Maybe<Scalars['JSON']>
    linkedFrom?: Maybe<PersonLinkingCollections>
    linkedin?: Maybe<Link>
    name?: Maybe<Scalars['String']>
    position?: Maybe<Scalars['String']>
    sys: Sys
    thumbnail?: Maybe<Asset>
    twitter?: Maybe<Link>
    videosCollection?: Maybe<PersonVideosCollection>
  }

/** An individual that may, for example, appear in presentations at conferences [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/person) */
export type PersonBioArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** An individual that may, for example, appear in presentations at conferences [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/person) */
export type PersonCallToActionArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CallToActionFilter>
}

/** An individual that may, for example, appear in presentations at conferences [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/person) */
export type PersonCompanyArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CompanyFilter>
}

/** An individual that may, for example, appear in presentations at conferences [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/person) */
export type PersonEmailArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** An individual that may, for example, appear in presentations at conferences [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/person) */
export type PersonFullNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** An individual that may, for example, appear in presentations at conferences [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/person) */
export type PersonImageSetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** An individual that may, for example, appear in presentations at conferences [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/person) */
export type PersonLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** An individual that may, for example, appear in presentations at conferences [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/person) */
export type PersonLinkedinArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<LinkFilter>
}

/** An individual that may, for example, appear in presentations at conferences [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/person) */
export type PersonNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** An individual that may, for example, appear in presentations at conferences [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/person) */
export type PersonPositionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** An individual that may, for example, appear in presentations at conferences [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/person) */
export type PersonThumbnailArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** An individual that may, for example, appear in presentations at conferences [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/person) */
export type PersonTwitterArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<LinkFilter>
}

/** An individual that may, for example, appear in presentations at conferences [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/person) */
export type PersonVideosCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PersonVideosCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqVideoFilter>
}

export type PersonBio = {
  __typename?: 'PersonBio'
  json: Scalars['JSON']
  links: PersonBioLinks
}

export type PersonBioAssets = {
  __typename?: 'PersonBioAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type PersonBioEntries = {
  __typename?: 'PersonBioEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type PersonBioLinks = {
  __typename?: 'PersonBioLinks'
  assets: PersonBioAssets
  entries: PersonBioEntries
  resources: PersonBioResources
}

export type PersonBioResources = {
  __typename?: 'PersonBioResources'
  block: Array<PersonBioResourcesBlock>
  hyperlink: Array<PersonBioResourcesHyperlink>
  inline: Array<PersonBioResourcesInline>
}

export type PersonBioResourcesBlock = ResourceLink & {
  __typename?: 'PersonBioResourcesBlock'
  sys: ResourceSys
}

export type PersonBioResourcesHyperlink = ResourceLink & {
  __typename?: 'PersonBioResourcesHyperlink'
  sys: ResourceSys
}

export type PersonBioResourcesInline = ResourceLink & {
  __typename?: 'PersonBioResourcesInline'
  sys: ResourceSys
}

export type PersonCollection = {
  __typename?: 'PersonCollection'
  items: Array<Maybe<Person>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PersonFilter = {
  AND?: InputMaybe<Array<InputMaybe<PersonFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PersonFilter>>>
  bio_contains?: InputMaybe<Scalars['String']>
  bio_exists?: InputMaybe<Scalars['Boolean']>
  bio_not_contains?: InputMaybe<Scalars['String']>
  callToAction?: InputMaybe<CfCallToActionNestedFilter>
  callToAction_exists?: InputMaybe<Scalars['Boolean']>
  company?: InputMaybe<CfCompanyNestedFilter>
  company_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  email?: InputMaybe<Scalars['String']>
  email_contains?: InputMaybe<Scalars['String']>
  email_exists?: InputMaybe<Scalars['Boolean']>
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  email_not?: InputMaybe<Scalars['String']>
  email_not_contains?: InputMaybe<Scalars['String']>
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fullName?: InputMaybe<Scalars['String']>
  fullName_contains?: InputMaybe<Scalars['String']>
  fullName_exists?: InputMaybe<Scalars['Boolean']>
  fullName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fullName_not?: InputMaybe<Scalars['String']>
  fullName_not_contains?: InputMaybe<Scalars['String']>
  fullName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  imageSet_exists?: InputMaybe<Scalars['Boolean']>
  linkedin?: InputMaybe<CfLinkNestedFilter>
  linkedin_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  position?: InputMaybe<Scalars['String']>
  position_contains?: InputMaybe<Scalars['String']>
  position_exists?: InputMaybe<Scalars['Boolean']>
  position_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  position_not?: InputMaybe<Scalars['String']>
  position_not_contains?: InputMaybe<Scalars['String']>
  position_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  thumbnail_exists?: InputMaybe<Scalars['Boolean']>
  twitter?: InputMaybe<CfLinkNestedFilter>
  twitter_exists?: InputMaybe<Scalars['Boolean']>
  videos?: InputMaybe<CfCaisiqVideoNestedFilter>
  videosCollection_exists?: InputMaybe<Scalars['Boolean']>
}

export type PersonLinkingCollections = {
  __typename?: 'PersonLinkingCollections'
  companyAndContactInfoCollection?: Maybe<CompanyAndContactInfoCollection>
  entryCollection?: Maybe<EntryCollection>
  pageCollection?: Maybe<PageCollection>
  presentationCollection?: Maybe<PresentationCollection>
  quoteCollection?: Maybe<QuoteCollection>
  teamCollection?: Maybe<TeamCollection>
}

export type PersonLinkingCollectionsCompanyAndContactInfoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PersonLinkingCollectionsCompanyAndContactInfoCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PersonLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PersonLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<PersonLinkingCollectionsPageCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PersonLinkingCollectionsPresentationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<PersonLinkingCollectionsPresentationCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PersonLinkingCollectionsQuoteCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<PersonLinkingCollectionsQuoteCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PersonLinkingCollectionsTeamCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<PersonLinkingCollectionsTeamCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum PersonLinkingCollectionsCompanyAndContactInfoCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PersonLinkingCollectionsPageCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PersonLinkingCollectionsPresentationCollectionOrder {
  DatetimeAsc = 'datetime_ASC',
  DatetimeDesc = 'datetime_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PersonLinkingCollectionsQuoteCollectionOrder {
  BackgroundAsc = 'background_ASC',
  BackgroundDesc = 'background_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PersonLinkingCollectionsTeamCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PersonOrder {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PersonVideosCollection = {
  __typename?: 'PersonVideosCollection'
  items: Array<Maybe<CaisiqVideo>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum PersonVideosCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  MuxThumbnailTimeAsc = 'muxThumbnailTime_ASC',
  MuxThumbnailTimeDesc = 'muxThumbnailTime_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Stores a pet [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pet) */
export type Pet = Entry &
  _Node & {
    __typename?: 'Pet'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<PetLinkingCollections>
    name?: Maybe<Scalars['String']>
    sys: Sys
    type?: Maybe<Scalars['String']>
  }

/** Stores a pet [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pet) */
export type PetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Stores a pet [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pet) */
export type PetNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Stores a pet [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pet) */
export type PetTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PetCollection = {
  __typename?: 'PetCollection'
  items: Array<Maybe<Pet>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PetFilter = {
  AND?: InputMaybe<Array<InputMaybe<PetFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PetFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  type?: InputMaybe<Scalars['String']>
  type_contains?: InputMaybe<Scalars['String']>
  type_exists?: InputMaybe<Scalars['Boolean']>
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type_not?: InputMaybe<Scalars['String']>
  type_not_contains?: InputMaybe<Scalars['String']>
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PetLinkingCollections = {
  __typename?: 'PetLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type PetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum PetOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

/** Each set represents a group of content, in this case that group of content is a playlist. It will appear for example like a carrusel into a view. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/playlistContentSet) */
export type PlaylistContentSet = Entry &
  _Node & {
    __typename?: 'PlaylistContentSet'
    _id: Scalars['ID']
    contentCollection?: Maybe<PlaylistContentSetContentCollection>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<PlaylistContentSetLinkingCollections>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Each set represents a group of content, in this case that group of content is a playlist. It will appear for example like a carrusel into a view. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/playlistContentSet) */
export type PlaylistContentSetContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<PlaylistContentSetContentCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqVideoPlaylistFilter>
}

/** Each set represents a group of content, in this case that group of content is a playlist. It will appear for example like a carrusel into a view. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/playlistContentSet) */
export type PlaylistContentSetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Each set represents a group of content, in this case that group of content is a playlist. It will appear for example like a carrusel into a view. [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/playlistContentSet) */
export type PlaylistContentSetTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PlaylistContentSetCollection = {
  __typename?: 'PlaylistContentSetCollection'
  items: Array<Maybe<PlaylistContentSet>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PlaylistContentSetContentCollection = {
  __typename?: 'PlaylistContentSetContentCollection'
  items: Array<Maybe<CaisiqVideoPlaylist>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum PlaylistContentSetContentCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  EpisodicAsc = 'episodic_ASC',
  EpisodicDesc = 'episodic_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type PlaylistContentSetFilter = {
  AND?: InputMaybe<Array<InputMaybe<PlaylistContentSetFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PlaylistContentSetFilter>>>
  content?: InputMaybe<CfCaisiqVideoPlaylistNestedFilter>
  contentCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PlaylistContentSetLinkingCollections = {
  __typename?: 'PlaylistContentSetLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
}

export type PlaylistContentSetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PlaylistContentSetLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PlaylistContentSetLinkingCollectionsPageLandingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum PlaylistContentSetLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PlaylistContentSetOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/presentation) */
export type Presentation = Entry &
  _Node & {
    __typename?: 'Presentation'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    datetime?: Maybe<Scalars['DateTime']>
    linkedFrom?: Maybe<PresentationLinkingCollections>
    presentersCollection?: Maybe<PresentationPresentersCollection>
    sys: Sys
    title?: Maybe<Scalars['String']>
    videosCollection?: Maybe<PresentationVideosCollection>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/presentation) */
export type PresentationDatetimeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/presentation) */
export type PresentationLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/presentation) */
export type PresentationPresentersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PresentationPresentersCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PersonFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/presentation) */
export type PresentationTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/presentation) */
export type PresentationVideosCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PresentationVideosCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqVideoFilter>
}

export type PresentationCollection = {
  __typename?: 'PresentationCollection'
  items: Array<Maybe<Presentation>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PresentationFilter = {
  AND?: InputMaybe<Array<InputMaybe<PresentationFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PresentationFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  datetime?: InputMaybe<Scalars['DateTime']>
  datetime_exists?: InputMaybe<Scalars['Boolean']>
  datetime_gt?: InputMaybe<Scalars['DateTime']>
  datetime_gte?: InputMaybe<Scalars['DateTime']>
  datetime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  datetime_lt?: InputMaybe<Scalars['DateTime']>
  datetime_lte?: InputMaybe<Scalars['DateTime']>
  datetime_not?: InputMaybe<Scalars['DateTime']>
  datetime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  presenters?: InputMaybe<CfPersonNestedFilter>
  presentersCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  videos?: InputMaybe<CfCaisiqVideoNestedFilter>
  videosCollection_exists?: InputMaybe<Scalars['Boolean']>
}

export type PresentationLinkingCollections = {
  __typename?: 'PresentationLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type PresentationLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum PresentationOrder {
  DatetimeAsc = 'datetime_ASC',
  DatetimeDesc = 'datetime_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type PresentationPresentersCollection = {
  __typename?: 'PresentationPresentersCollection'
  items: Array<Maybe<Person>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum PresentationPresentersCollectionOrder {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PresentationVideosCollection = {
  __typename?: 'PresentationVideosCollection'
  items: Array<Maybe<CaisiqVideo>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum PresentationVideosCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  MuxThumbnailTimeAsc = 'muxThumbnailTime_ASC',
  MuxThumbnailTimeDesc = 'muxThumbnailTime_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Award object that we display on the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pressAward) */
export type PressAward = Entry &
  _Node & {
    __typename?: 'PressAward'
    _id: Scalars['ID']
    awardImage?: Maybe<ComponentImage>
    contentfulMetadata: ContentfulMetadata
    imageSet?: Maybe<Scalars['JSON']>
    link?: Maybe<Link>
    linkedFrom?: Maybe<PressAwardLinkingCollections>
    name?: Maybe<Scalars['String']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** Award object that we display on the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pressAward) */
export type PressAwardAwardImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<ComponentImageFilter>
}

/** Award object that we display on the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pressAward) */
export type PressAwardImageSetArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Award object that we display on the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pressAward) */
export type PressAwardLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<LinkFilter>
}

/** Award object that we display on the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pressAward) */
export type PressAwardLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Award object that we display on the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pressAward) */
export type PressAwardNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Award object that we display on the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pressAward) */
export type PressAwardTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PressAwardCollection = {
  __typename?: 'PressAwardCollection'
  items: Array<Maybe<PressAward>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PressAwardFilter = {
  AND?: InputMaybe<Array<InputMaybe<PressAwardFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PressAwardFilter>>>
  awardImage?: InputMaybe<CfComponentImageNestedFilter>
  awardImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  imageSet_exists?: InputMaybe<Scalars['Boolean']>
  link?: InputMaybe<CfLinkNestedFilter>
  link_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PressAwardLinkingCollections = {
  __typename?: 'PressAwardLinkingCollections'
  componentSectionCollection?: Maybe<ComponentSectionCollection>
  entryCollection?: Maybe<EntryCollection>
  pwsInsightsContentSetCollection?: Maybe<PwsInsightsContentSetCollection>
}

export type PressAwardLinkingCollectionsComponentSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PressAwardLinkingCollectionsComponentSectionCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PressAwardLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PressAwardLinkingCollectionsPwsInsightsContentSetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PressAwardLinkingCollectionsPwsInsightsContentSetCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum PressAwardLinkingCollectionsComponentSectionCollectionOrder {
  BookmarkIdAsc = 'bookmarkId_ASC',
  BookmarkIdDesc = 'bookmarkId_DESC',
  CardContainerOptionsAsc = 'cardContainerOptions_ASC',
  CardContainerOptionsDesc = 'cardContainerOptions_DESC',
  ComponentIdAsc = 'componentId_ASC',
  ComponentIdDesc = 'componentId_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PressAwardLinkingCollectionsPwsInsightsContentSetCollectionOrder {
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  LayoutStyleAsc = 'layoutStyle_ASC',
  LayoutStyleDesc = 'layoutStyle_DESC',
  SortingAsc = 'sorting_ASC',
  SortingDesc = 'sorting_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PressAwardOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Contact media section of the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsContactMedia) */
export type PwsContactMedia = Entry &
  _Node & {
    __typename?: 'PwsContactMedia'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    description?: Maybe<Scalars['String']>
    email?: Maybe<Scalars['String']>
    extraInfo?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<PwsContactMediaLinkingCollections>
    sys: Sys
  }

/** Contact media section of the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsContactMedia) */
export type PwsContactMediaDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Contact media section of the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsContactMedia) */
export type PwsContactMediaEmailArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Contact media section of the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsContactMedia) */
export type PwsContactMediaExtraInfoArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Contact media section of the press page [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsContactMedia) */
export type PwsContactMediaLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PwsContactMediaCollection = {
  __typename?: 'PwsContactMediaCollection'
  items: Array<Maybe<PwsContactMedia>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PwsContactMediaFilter = {
  AND?: InputMaybe<Array<InputMaybe<PwsContactMediaFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PwsContactMediaFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  email?: InputMaybe<Scalars['String']>
  email_contains?: InputMaybe<Scalars['String']>
  email_exists?: InputMaybe<Scalars['Boolean']>
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  email_not?: InputMaybe<Scalars['String']>
  email_not_contains?: InputMaybe<Scalars['String']>
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  extraInfo?: InputMaybe<Scalars['String']>
  extraInfo_contains?: InputMaybe<Scalars['String']>
  extraInfo_exists?: InputMaybe<Scalars['Boolean']>
  extraInfo_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  extraInfo_not?: InputMaybe<Scalars['String']>
  extraInfo_not_contains?: InputMaybe<Scalars['String']>
  extraInfo_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type PwsContactMediaLinkingCollections = {
  __typename?: 'PwsContactMediaLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
}

export type PwsContactMediaLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PwsContactMediaLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PwsContactMediaLinkingCollectionsPageLandingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum PwsContactMediaLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PwsContactMediaOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  ExtraInfoAsc = 'extraInfo_ASC',
  ExtraInfoDesc = 'extraInfo_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Each set represents a carousel on the PWS insights screen [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsInsightsContentSet) */
export type PwsInsightsContentSet = Entry &
  _Node & {
    __typename?: 'PwsInsightsContentSet'
    _id: Scalars['ID']
    category?: Maybe<PagePwsCategory>
    contentCollection?: Maybe<PwsInsightsContentSetContentCollection>
    contentfulMetadata: ContentfulMetadata
    displayTitle?: Maybe<Scalars['String']>
    layoutStyle?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<PwsInsightsContentSetLinkingCollections>
    sorting?: Maybe<Scalars['String']>
    sys: Sys
    theme?: Maybe<Scalars['String']>
    title?: Maybe<Scalars['String']>
  }

/** Each set represents a carousel on the PWS insights screen [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsInsightsContentSet) */
export type PwsInsightsContentSetCategoryArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PagePwsCategoryFilter>
}

/** Each set represents a carousel on the PWS insights screen [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsInsightsContentSet) */
export type PwsInsightsContentSetContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PwsInsightsContentSetContentFilter>
}

/** Each set represents a carousel on the PWS insights screen [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsInsightsContentSet) */
export type PwsInsightsContentSetDisplayTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Each set represents a carousel on the PWS insights screen [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsInsightsContentSet) */
export type PwsInsightsContentSetLayoutStyleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Each set represents a carousel on the PWS insights screen [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsInsightsContentSet) */
export type PwsInsightsContentSetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Each set represents a carousel on the PWS insights screen [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsInsightsContentSet) */
export type PwsInsightsContentSetSortingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Each set represents a carousel on the PWS insights screen [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsInsightsContentSet) */
export type PwsInsightsContentSetThemeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Each set represents a carousel on the PWS insights screen [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsInsightsContentSet) */
export type PwsInsightsContentSetTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PwsInsightsContentSetCollection = {
  __typename?: 'PwsInsightsContentSetCollection'
  items: Array<Maybe<PwsInsightsContentSet>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PwsInsightsContentSetContentCollection = {
  __typename?: 'PwsInsightsContentSetContentCollection'
  items: Array<Maybe<PwsInsightsContentSetContentItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PwsInsightsContentSetContentFilter = {
  AND?: InputMaybe<Array<InputMaybe<PwsInsightsContentSetContentFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PwsInsightsContentSetContentFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PwsInsightsContentSetContentItem =
  | CaisiqArticle
  | CaisiqVideo
  | PressAward
  | PwsPressRelease
  | Whitepaper

export type PwsInsightsContentSetFilter = {
  AND?: InputMaybe<Array<InputMaybe<PwsInsightsContentSetFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PwsInsightsContentSetFilter>>>
  category?: InputMaybe<CfPagePwsCategoryNestedFilter>
  category_exists?: InputMaybe<Scalars['Boolean']>
  content?: InputMaybe<CfcontentMultiTypeNestedFilter>
  contentCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  displayTitle?: InputMaybe<Scalars['String']>
  displayTitle_contains?: InputMaybe<Scalars['String']>
  displayTitle_exists?: InputMaybe<Scalars['Boolean']>
  displayTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayTitle_not?: InputMaybe<Scalars['String']>
  displayTitle_not_contains?: InputMaybe<Scalars['String']>
  displayTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  layoutStyle?: InputMaybe<Scalars['String']>
  layoutStyle_contains?: InputMaybe<Scalars['String']>
  layoutStyle_exists?: InputMaybe<Scalars['Boolean']>
  layoutStyle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  layoutStyle_not?: InputMaybe<Scalars['String']>
  layoutStyle_not_contains?: InputMaybe<Scalars['String']>
  layoutStyle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sorting?: InputMaybe<Scalars['String']>
  sorting_contains?: InputMaybe<Scalars['String']>
  sorting_exists?: InputMaybe<Scalars['Boolean']>
  sorting_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sorting_not?: InputMaybe<Scalars['String']>
  sorting_not_contains?: InputMaybe<Scalars['String']>
  sorting_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  theme?: InputMaybe<Scalars['String']>
  theme_contains?: InputMaybe<Scalars['String']>
  theme_exists?: InputMaybe<Scalars['Boolean']>
  theme_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  theme_not?: InputMaybe<Scalars['String']>
  theme_not_contains?: InputMaybe<Scalars['String']>
  theme_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type PwsInsightsContentSetLinkingCollections = {
  __typename?: 'PwsInsightsContentSetLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
}

export type PwsInsightsContentSetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PwsInsightsContentSetLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PwsInsightsContentSetLinkingCollectionsPageLandingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum PwsInsightsContentSetLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PwsInsightsContentSetOrder {
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  LayoutStyleAsc = 'layoutStyle_ASC',
  LayoutStyleDesc = 'layoutStyle_DESC',
  SortingAsc = 'sorting_ASC',
  SortingDesc = 'sorting_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** News or Press releases for Public Website [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsPressRelease) */
export type PwsPressRelease = Entry &
  _Node & {
    __typename?: 'PwsPressRelease'
    _id: Scalars['ID']
    aboutCompanyCollection?: Maybe<PwsPressReleaseAboutCompanyCollection>
    author?: Maybe<CaisiqAuthor>
    body?: Maybe<PwsPressReleaseBody>
    caisVideo?: Maybe<CaisiqVideo>
    callToAction?: Maybe<CallToAction>
    contentfulMetadata: ContentfulMetadata
    description?: Maybe<Scalars['String']>
    displayDate?: Maybe<Scalars['DateTime']>
    externalNewsLink?: Maybe<Scalars['String']>
    featuredThumbnail?: Maybe<Asset>
    footnotes?: Maybe<PwsPressReleaseFootnotes>
    introduction?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<PwsPressReleaseLinkingCollections>
    name?: Maybe<Scalars['String']>
    slug?: Maybe<Scalars['String']>
    sys: Sys
    tagsCollection?: Maybe<PwsPressReleaseTagsCollection>
    thumbnail?: Maybe<Asset>
    title?: Maybe<Scalars['String']>
    type?: Maybe<Scalars['String']>
    video?: Maybe<Asset>
  }

/** News or Press releases for Public Website [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsPressRelease) */
export type PwsPressReleaseAboutCompanyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<PwsPressReleaseAboutCompanyCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CompanyAndContactInfoFilter>
}

/** News or Press releases for Public Website [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsPressRelease) */
export type PwsPressReleaseAuthorArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CaisiqAuthorFilter>
}

/** News or Press releases for Public Website [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsPressRelease) */
export type PwsPressReleaseBodyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** News or Press releases for Public Website [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsPressRelease) */
export type PwsPressReleaseCaisVideoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CaisiqVideoFilter>
}

/** News or Press releases for Public Website [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsPressRelease) */
export type PwsPressReleaseCallToActionArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CallToActionFilter>
}

/** News or Press releases for Public Website [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsPressRelease) */
export type PwsPressReleaseDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** News or Press releases for Public Website [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsPressRelease) */
export type PwsPressReleaseDisplayDateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** News or Press releases for Public Website [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsPressRelease) */
export type PwsPressReleaseExternalNewsLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** News or Press releases for Public Website [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsPressRelease) */
export type PwsPressReleaseFeaturedThumbnailArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** News or Press releases for Public Website [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsPressRelease) */
export type PwsPressReleaseFootnotesArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** News or Press releases for Public Website [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsPressRelease) */
export type PwsPressReleaseIntroductionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** News or Press releases for Public Website [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsPressRelease) */
export type PwsPressReleaseLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** News or Press releases for Public Website [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsPressRelease) */
export type PwsPressReleaseNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** News or Press releases for Public Website [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsPressRelease) */
export type PwsPressReleaseSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** News or Press releases for Public Website [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsPressRelease) */
export type PwsPressReleaseTagsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PwsPressReleaseTagsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TagFilter>
}

/** News or Press releases for Public Website [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsPressRelease) */
export type PwsPressReleaseThumbnailArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** News or Press releases for Public Website [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsPressRelease) */
export type PwsPressReleaseTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** News or Press releases for Public Website [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsPressRelease) */
export type PwsPressReleaseTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** News or Press releases for Public Website [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsPressRelease) */
export type PwsPressReleaseVideoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type PwsPressReleaseAboutCompanyCollection = {
  __typename?: 'PwsPressReleaseAboutCompanyCollection'
  items: Array<Maybe<CompanyAndContactInfo>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum PwsPressReleaseAboutCompanyCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type PwsPressReleaseBody = {
  __typename?: 'PwsPressReleaseBody'
  json: Scalars['JSON']
  links: PwsPressReleaseBodyLinks
}

export type PwsPressReleaseBodyAssets = {
  __typename?: 'PwsPressReleaseBodyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type PwsPressReleaseBodyEntries = {
  __typename?: 'PwsPressReleaseBodyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type PwsPressReleaseBodyLinks = {
  __typename?: 'PwsPressReleaseBodyLinks'
  assets: PwsPressReleaseBodyAssets
  entries: PwsPressReleaseBodyEntries
  resources: PwsPressReleaseBodyResources
}

export type PwsPressReleaseBodyResources = {
  __typename?: 'PwsPressReleaseBodyResources'
  block: Array<PwsPressReleaseBodyResourcesBlock>
  hyperlink: Array<PwsPressReleaseBodyResourcesHyperlink>
  inline: Array<PwsPressReleaseBodyResourcesInline>
}

export type PwsPressReleaseBodyResourcesBlock = ResourceLink & {
  __typename?: 'PwsPressReleaseBodyResourcesBlock'
  sys: ResourceSys
}

export type PwsPressReleaseBodyResourcesHyperlink = ResourceLink & {
  __typename?: 'PwsPressReleaseBodyResourcesHyperlink'
  sys: ResourceSys
}

export type PwsPressReleaseBodyResourcesInline = ResourceLink & {
  __typename?: 'PwsPressReleaseBodyResourcesInline'
  sys: ResourceSys
}

export type PwsPressReleaseCollection = {
  __typename?: 'PwsPressReleaseCollection'
  items: Array<Maybe<PwsPressRelease>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PwsPressReleaseFilter = {
  AND?: InputMaybe<Array<InputMaybe<PwsPressReleaseFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PwsPressReleaseFilter>>>
  aboutCompany?: InputMaybe<CfCompanyAndContactInfoNestedFilter>
  aboutCompanyCollection_exists?: InputMaybe<Scalars['Boolean']>
  author?: InputMaybe<CfCaisiqAuthorNestedFilter>
  author_exists?: InputMaybe<Scalars['Boolean']>
  body_contains?: InputMaybe<Scalars['String']>
  body_exists?: InputMaybe<Scalars['Boolean']>
  body_not_contains?: InputMaybe<Scalars['String']>
  caisVideo?: InputMaybe<CfCaisiqVideoNestedFilter>
  caisVideo_exists?: InputMaybe<Scalars['Boolean']>
  callToAction?: InputMaybe<CfCallToActionNestedFilter>
  callToAction_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayDate?: InputMaybe<Scalars['DateTime']>
  displayDate_exists?: InputMaybe<Scalars['Boolean']>
  displayDate_gt?: InputMaybe<Scalars['DateTime']>
  displayDate_gte?: InputMaybe<Scalars['DateTime']>
  displayDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  displayDate_lt?: InputMaybe<Scalars['DateTime']>
  displayDate_lte?: InputMaybe<Scalars['DateTime']>
  displayDate_not?: InputMaybe<Scalars['DateTime']>
  displayDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  externalNewsLink?: InputMaybe<Scalars['String']>
  externalNewsLink_contains?: InputMaybe<Scalars['String']>
  externalNewsLink_exists?: InputMaybe<Scalars['Boolean']>
  externalNewsLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalNewsLink_not?: InputMaybe<Scalars['String']>
  externalNewsLink_not_contains?: InputMaybe<Scalars['String']>
  externalNewsLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  featuredThumbnail_exists?: InputMaybe<Scalars['Boolean']>
  footnotes_contains?: InputMaybe<Scalars['String']>
  footnotes_exists?: InputMaybe<Scalars['Boolean']>
  footnotes_not_contains?: InputMaybe<Scalars['String']>
  introduction?: InputMaybe<Scalars['String']>
  introduction_contains?: InputMaybe<Scalars['String']>
  introduction_exists?: InputMaybe<Scalars['Boolean']>
  introduction_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  introduction_not?: InputMaybe<Scalars['String']>
  introduction_not_contains?: InputMaybe<Scalars['String']>
  introduction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tags?: InputMaybe<CfTagNestedFilter>
  tagsCollection_exists?: InputMaybe<Scalars['Boolean']>
  thumbnail_exists?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type?: InputMaybe<Scalars['String']>
  type_contains?: InputMaybe<Scalars['String']>
  type_exists?: InputMaybe<Scalars['Boolean']>
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type_not?: InputMaybe<Scalars['String']>
  type_not_contains?: InputMaybe<Scalars['String']>
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  video_exists?: InputMaybe<Scalars['Boolean']>
}

export type PwsPressReleaseFootnotes = {
  __typename?: 'PwsPressReleaseFootnotes'
  json: Scalars['JSON']
  links: PwsPressReleaseFootnotesLinks
}

export type PwsPressReleaseFootnotesAssets = {
  __typename?: 'PwsPressReleaseFootnotesAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type PwsPressReleaseFootnotesEntries = {
  __typename?: 'PwsPressReleaseFootnotesEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type PwsPressReleaseFootnotesLinks = {
  __typename?: 'PwsPressReleaseFootnotesLinks'
  assets: PwsPressReleaseFootnotesAssets
  entries: PwsPressReleaseFootnotesEntries
  resources: PwsPressReleaseFootnotesResources
}

export type PwsPressReleaseFootnotesResources = {
  __typename?: 'PwsPressReleaseFootnotesResources'
  block: Array<PwsPressReleaseFootnotesResourcesBlock>
  hyperlink: Array<PwsPressReleaseFootnotesResourcesHyperlink>
  inline: Array<PwsPressReleaseFootnotesResourcesInline>
}

export type PwsPressReleaseFootnotesResourcesBlock = ResourceLink & {
  __typename?: 'PwsPressReleaseFootnotesResourcesBlock'
  sys: ResourceSys
}

export type PwsPressReleaseFootnotesResourcesHyperlink = ResourceLink & {
  __typename?: 'PwsPressReleaseFootnotesResourcesHyperlink'
  sys: ResourceSys
}

export type PwsPressReleaseFootnotesResourcesInline = ResourceLink & {
  __typename?: 'PwsPressReleaseFootnotesResourcesInline'
  sys: ResourceSys
}

export type PwsPressReleaseLinkingCollections = {
  __typename?: 'PwsPressReleaseLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  homepageAppMetadataCollection?: Maybe<HomepageAppMetadataCollection>
  linkCollection?: Maybe<LinkCollection>
  pageCollection?: Maybe<PageCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
  pwsInsightsContentSetCollection?: Maybe<PwsInsightsContentSetCollection>
  relatedContentStrategyCollection?: Maybe<RelatedContentStrategyCollection>
}

export type PwsPressReleaseLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PwsPressReleaseLinkingCollectionsHomepageAppMetadataCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PwsPressReleaseLinkingCollectionsHomepageAppMetadataCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PwsPressReleaseLinkingCollectionsLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<PwsPressReleaseLinkingCollectionsLinkCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PwsPressReleaseLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<PwsPressReleaseLinkingCollectionsPageCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PwsPressReleaseLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<PwsPressReleaseLinkingCollectionsPageLandingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type PwsPressReleaseLinkingCollectionsPwsInsightsContentSetCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PwsPressReleaseLinkingCollectionsPwsInsightsContentSetCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export type PwsPressReleaseLinkingCollectionsRelatedContentStrategyCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<PwsPressReleaseLinkingCollectionsRelatedContentStrategyCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum PwsPressReleaseLinkingCollectionsHomepageAppMetadataCollectionOrder {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PwsPressReleaseLinkingCollectionsLinkCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  ExternalLinkAsc = 'externalLink_ASC',
  ExternalLinkDesc = 'externalLink_DESC',
  InternalLinkAsc = 'internalLink_ASC',
  InternalLinkDesc = 'internalLink_DESC',
  OpenInNewWindowAsc = 'openInNewWindow_ASC',
  OpenInNewWindowDesc = 'openInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PwsPressReleaseLinkingCollectionsPageCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PwsPressReleaseLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PwsPressReleaseLinkingCollectionsPwsInsightsContentSetCollectionOrder {
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  LayoutStyleAsc = 'layoutStyle_ASC',
  LayoutStyleDesc = 'layoutStyle_DESC',
  SortingAsc = 'sorting_ASC',
  SortingDesc = 'sorting_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum PwsPressReleaseLinkingCollectionsRelatedContentStrategyCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SortingAsc = 'sorting_ASC',
  SortingDesc = 'sorting_DESC',
  SourceAsc = 'source_ASC',
  SourceDesc = 'source_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum PwsPressReleaseOrder {
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  ExternalNewsLinkAsc = 'externalNewsLink_ASC',
  ExternalNewsLinkDesc = 'externalNewsLink_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export type PwsPressReleaseTagsCollection = {
  __typename?: 'PwsPressReleaseTagsCollection'
  items: Array<Maybe<Tag>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum PwsPressReleaseTagsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagIdAsc = 'tagId_ASC',
  TagIdDesc = 'tagId_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsSiteSettings) */
export type PwsSiteSettings = Entry &
  _Node & {
    __typename?: 'PwsSiteSettings'
    _id: Scalars['ID']
    aboutCaisInfo?: Maybe<CompanyAndContactInfo>
    contentfulMetadata: ContentfulMetadata
    defaultCallToAction?: Maybe<CallToAction>
    linkedFrom?: Maybe<PwsSiteSettingsLinkingCollections>
    name?: Maybe<Scalars['String']>
    sys: Sys
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsSiteSettings) */
export type PwsSiteSettingsAboutCaisInfoArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CompanyAndContactInfoFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsSiteSettings) */
export type PwsSiteSettingsDefaultCallToActionArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CallToActionFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsSiteSettings) */
export type PwsSiteSettingsLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/pwsSiteSettings) */
export type PwsSiteSettingsNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type PwsSiteSettingsCollection = {
  __typename?: 'PwsSiteSettingsCollection'
  items: Array<Maybe<PwsSiteSettings>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type PwsSiteSettingsFilter = {
  AND?: InputMaybe<Array<InputMaybe<PwsSiteSettingsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<PwsSiteSettingsFilter>>>
  aboutCaisInfo?: InputMaybe<CfCompanyAndContactInfoNestedFilter>
  aboutCaisInfo_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  defaultCallToAction?: InputMaybe<CfCallToActionNestedFilter>
  defaultCallToAction_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type PwsSiteSettingsLinkingCollections = {
  __typename?: 'PwsSiteSettingsLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type PwsSiteSettingsLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum PwsSiteSettingsOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type Query = {
  __typename?: 'Query'
  _node?: Maybe<_Node>
  accessControl?: Maybe<AccessControl>
  accessControl2?: Maybe<AccessControl2>
  accessControl2Collection?: Maybe<AccessControl2Collection>
  accessControl3?: Maybe<AccessControl3>
  accessControl3Collection?: Maybe<AccessControl3Collection>
  accessControlCollection?: Maybe<AccessControlCollection>
  accordion?: Maybe<Accordion>
  accordionCollection?: Maybe<AccordionCollection>
  accordionWithImage?: Maybe<AccordionWithImage>
  accordionWithImageCollection?: Maybe<AccordionWithImageCollection>
  announcement?: Maybe<Announcement>
  announcementCollection?: Maybe<AnnouncementCollection>
  announcementSet?: Maybe<AnnouncementSet>
  announcementSetCollection?: Maybe<AnnouncementSetCollection>
  asset?: Maybe<Asset>
  assetCollection?: Maybe<AssetCollection>
  caisiqArticle?: Maybe<CaisiqArticle>
  caisiqArticleCollection?: Maybe<CaisiqArticleCollection>
  caisiqArticleDropdown?: Maybe<CaisiqArticleDropdown>
  caisiqArticleDropdownCollection?: Maybe<CaisiqArticleDropdownCollection>
  caisiqAuthor?: Maybe<CaisiqAuthor>
  caisiqAuthorCollection?: Maybe<CaisiqAuthorCollection>
  caisiqDefaultCourseImageSet?: Maybe<CaisiqDefaultCourseImageSet>
  caisiqDefaultCourseImageSetCollection?: Maybe<CaisiqDefaultCourseImageSetCollection>
  caisiqDefaultFundsThumbnailSet?: Maybe<CaisiqDefaultFundsThumbnailSet>
  caisiqDefaultFundsThumbnailSetCollection?: Maybe<CaisiqDefaultFundsThumbnailSetCollection>
  caisiqFundThumbnail?: Maybe<CaisiqFundThumbnail>
  caisiqFundThumbnailCollection?: Maybe<CaisiqFundThumbnailCollection>
  caisiqFundThumbnailSet?: Maybe<CaisiqFundThumbnailSet>
  caisiqFundThumbnailSetCollection?: Maybe<CaisiqFundThumbnailSetCollection>
  caisiqHeroHeader?: Maybe<CaisiqHeroHeader>
  caisiqHeroHeaderCollection?: Maybe<CaisiqHeroHeaderCollection>
  caisiqHeroHeaderNavigateToButton?: Maybe<CaisiqHeroHeaderNavigateToButton>
  caisiqHeroHeaderNavigateToButtonCollection?: Maybe<CaisiqHeroHeaderNavigateToButtonCollection>
  caisiqHomeOffice?: Maybe<CaisiqHomeOffice>
  caisiqHomeOfficeCollection?: Maybe<CaisiqHomeOfficeCollection>
  caisiqJoinCaisCallToAction?: Maybe<CaisiqJoinCaisCallToAction>
  caisiqJoinCaisCallToActionCollection?: Maybe<CaisiqJoinCaisCallToActionCollection>
  caisiqTab?: Maybe<CaisiqTab>
  caisiqTabCollection?: Maybe<CaisiqTabCollection>
  caisiqVideo?: Maybe<CaisiqVideo>
  caisiqVideoCollection?: Maybe<CaisiqVideoCollection>
  caisiqVideoPlaylist?: Maybe<CaisiqVideoPlaylist>
  caisiqVideoPlaylistCollection?: Maybe<CaisiqVideoPlaylistCollection>
  callToAction?: Maybe<CallToAction>
  callToActionCollection?: Maybe<CallToActionCollection>
  chart?: Maybe<Chart>
  chartCollection?: Maybe<ChartCollection>
  childFirm?: Maybe<ChildFirm>
  childFirmCollection?: Maybe<ChildFirmCollection>
  ciqArticle?: Maybe<CiqArticle>
  ciqArticleCollection?: Maybe<CiqArticleCollection>
  company?: Maybe<Company>
  companyAndContactInfo?: Maybe<CompanyAndContactInfo>
  companyAndContactInfoCollection?: Maybe<CompanyAndContactInfoCollection>
  companyCollection?: Maybe<CompanyCollection>
  componentCard?: Maybe<ComponentCard>
  componentCardCollection?: Maybe<ComponentCardCollection>
  componentCompanyContainer?: Maybe<ComponentCompanyContainer>
  componentCompanyContainerCollection?: Maybe<ComponentCompanyContainerCollection>
  componentHero?: Maybe<ComponentHero>
  componentHeroCollection?: Maybe<ComponentHeroCollection>
  componentHubspotForm?: Maybe<ComponentHubspotForm>
  componentHubspotFormCollection?: Maybe<ComponentHubspotFormCollection>
  componentImage?: Maybe<ComponentImage>
  componentImageCollection?: Maybe<ComponentImageCollection>
  componentPodcastPlayer?: Maybe<ComponentPodcastPlayer>
  componentPodcastPlayerCollection?: Maybe<ComponentPodcastPlayerCollection>
  componentSection?: Maybe<ComponentSection>
  componentSectionCollection?: Maybe<ComponentSectionCollection>
  componentSummary?: Maybe<ComponentSummary>
  componentSummaryCollection?: Maybe<ComponentSummaryCollection>
  componentTeamContainer?: Maybe<ComponentTeamContainer>
  componentTeamContainerCollection?: Maybe<ComponentTeamContainerCollection>
  componentText?: Maybe<ComponentText>
  componentTextCollection?: Maybe<ComponentTextCollection>
  componentTextIllustration?: Maybe<ComponentTextIllustration>
  componentTextIllustrationCollection?: Maybe<ComponentTextIllustrationCollection>
  componentTextImage?: Maybe<ComponentTextImage>
  componentTextImageCollection?: Maybe<ComponentTextImageCollection>
  componentTextImageImageSection?: Maybe<ComponentTextImageImageSection>
  componentTextImageImageSectionCollection?: Maybe<ComponentTextImageImageSectionCollection>
  componentTextImageListSection?: Maybe<ComponentTextImageListSection>
  componentTextImageListSectionCollection?: Maybe<ComponentTextImageListSectionCollection>
  componentTextImageTextSection?: Maybe<ComponentTextImageTextSection>
  componentTextImageTextSectionCollection?: Maybe<ComponentTextImageTextSectionCollection>
  corporateStats?: Maybe<CorporateStats>
  corporateStatsCollection?: Maybe<CorporateStatsCollection>
  curatedFilterGroup?: Maybe<CuratedFilterGroup>
  curatedFilterGroupCollection?: Maybe<CuratedFilterGroupCollection>
  curatedResearchContent?: Maybe<CuratedResearchContent>
  curatedResearchContentCollection?: Maybe<CuratedResearchContentCollection>
  disclaimer?: Maybe<Disclaimer>
  disclaimerCollection?: Maybe<DisclaimerCollection>
  entryCollection?: Maybe<EntryCollection>
  event?: Maybe<Event>
  eventCollection?: Maybe<EventCollection>
  externalContent?: Maybe<ExternalContent>
  externalContentCollection?: Maybe<ExternalContentCollection>
  faq?: Maybe<Faq>
  faqCategory?: Maybe<FaqCategory>
  faqCategoryCollection?: Maybe<FaqCategoryCollection>
  faqCollection?: Maybe<FaqCollection>
  featuredContent?: Maybe<FeaturedContent>
  featuredContentCollection?: Maybe<FeaturedContentCollection>
  filterGroup?: Maybe<FilterGroup>
  filterGroupCollection?: Maybe<FilterGroupCollection>
  firm?: Maybe<Firm>
  firmCollection?: Maybe<FirmCollection>
  fullWidthImage?: Maybe<FullWidthImage>
  fullWidthImageCollection?: Maybe<FullWidthImageCollection>
  fundProductId?: Maybe<FundProductId>
  fundProductIdCollection?: Maybe<FundProductIdCollection>
  glossaryTerm?: Maybe<GlossaryTerm>
  glossaryTermCollection?: Maybe<GlossaryTermCollection>
  homepageAppMetadata?: Maybe<HomepageAppMetadata>
  homepageAppMetadataCollection?: Maybe<HomepageAppMetadataCollection>
  homepageContentSection?: Maybe<HomepageContentSection>
  homepageContentSectionCollection?: Maybe<HomepageContentSectionCollection>
  homepageLandingPage?: Maybe<HomepageLandingPage>
  homepageLandingPageCollection?: Maybe<HomepageLandingPageCollection>
  htmlTable?: Maybe<HtmlTable>
  htmlTableCollection?: Maybe<HtmlTableCollection>
  imageSection?: Maybe<ImageSection>
  imageSectionCollection?: Maybe<ImageSectionCollection>
  legalPage?: Maybe<LegalPage>
  legalPageCollection?: Maybe<LegalPageCollection>
  link?: Maybe<Link>
  linkCollection?: Maybe<LinkCollection>
  loginScreen?: Maybe<LoginScreen>
  loginScreenCollection?: Maybe<LoginScreenCollection>
  markdown?: Maybe<Markdown>
  markdownCollection?: Maybe<MarkdownCollection>
  memberFirm?: Maybe<MemberFirm>
  memberFirmCollection?: Maybe<MemberFirmCollection>
  migration?: Maybe<Migration>
  migrationCollection?: Maybe<MigrationCollection>
  navHeaderTheme?: Maybe<NavHeaderTheme>
  navHeaderThemeCollection?: Maybe<NavHeaderThemeCollection>
  page?: Maybe<Page>
  pageCollection?: Maybe<PageCollection>
  pageHelpCenterArticle?: Maybe<PageHelpCenterArticle>
  pageHelpCenterArticleCollection?: Maybe<PageHelpCenterArticleCollection>
  pageLanding?: Maybe<PageLanding>
  pageLandingCollection?: Maybe<PageLandingCollection>
  pagePwsCategory?: Maybe<PagePwsCategory>
  pagePwsCategoryCollection?: Maybe<PagePwsCategoryCollection>
  pageResource?: Maybe<PageResource>
  pageResourceCollection?: Maybe<PageResourceCollection>
  parentFirm?: Maybe<ParentFirm>
  parentFirmCollection?: Maybe<ParentFirmCollection>
  pdf?: Maybe<Pdf>
  pdfCollection?: Maybe<PdfCollection>
  person?: Maybe<Person>
  personCollection?: Maybe<PersonCollection>
  pet?: Maybe<Pet>
  petCollection?: Maybe<PetCollection>
  playlistContentSet?: Maybe<PlaylistContentSet>
  playlistContentSetCollection?: Maybe<PlaylistContentSetCollection>
  presentation?: Maybe<Presentation>
  presentationCollection?: Maybe<PresentationCollection>
  pressAward?: Maybe<PressAward>
  pressAwardCollection?: Maybe<PressAwardCollection>
  pwsContactMedia?: Maybe<PwsContactMedia>
  pwsContactMediaCollection?: Maybe<PwsContactMediaCollection>
  pwsInsightsContentSet?: Maybe<PwsInsightsContentSet>
  pwsInsightsContentSetCollection?: Maybe<PwsInsightsContentSetCollection>
  pwsPressRelease?: Maybe<PwsPressRelease>
  pwsPressReleaseCollection?: Maybe<PwsPressReleaseCollection>
  pwsSiteSettings?: Maybe<PwsSiteSettings>
  pwsSiteSettingsCollection?: Maybe<PwsSiteSettingsCollection>
  quote?: Maybe<Quote>
  quoteCollection?: Maybe<QuoteCollection>
  relatedContent?: Maybe<RelatedContent>
  relatedContentCollection?: Maybe<RelatedContentCollection>
  relatedContentStrategy?: Maybe<RelatedContentStrategy>
  relatedContentStrategyCollection?: Maybe<RelatedContentStrategyCollection>
  seo?: Maybe<Seo>
  seoCollection?: Maybe<SeoCollection>
  skPlayground?: Maybe<SkPlayground>
  skPlaygroundCollection?: Maybe<SkPlaygroundCollection>
  tag?: Maybe<Tag>
  tagCollection?: Maybe<TagCollection>
  tagFamily?: Maybe<TagFamily>
  tagFamilyCollection?: Maybe<TagFamilyCollection>
  team?: Maybe<Team>
  teamCollection?: Maybe<TeamCollection>
  testModel?: Maybe<TestModel>
  testModelCollection?: Maybe<TestModelCollection>
  theme?: Maybe<Theme>
  themeCollection?: Maybe<ThemeCollection>
  whitepaper?: Maybe<Whitepaper>
  whitepaperCollection?: Maybe<WhitepaperCollection>
}

export type Query_NodeArgs = {
  id: Scalars['ID']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryAccessControlArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryAccessControl2Args = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryAccessControl2CollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AccessControl2Order>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<AccessControl2Filter>
}

export type QueryAccessControl3Args = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryAccessControl3CollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AccessControl3Order>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<AccessControl3Filter>
}

export type QueryAccessControlCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AccessControlOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<AccessControlFilter>
}

export type QueryAccordionArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryAccordionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AccordionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<AccordionFilter>
}

export type QueryAccordionWithImageArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryAccordionWithImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AccordionWithImageOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<AccordionWithImageFilter>
}

export type QueryAnnouncementArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryAnnouncementCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AnnouncementOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<AnnouncementFilter>
}

export type QueryAnnouncementSetArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryAnnouncementSetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AnnouncementSetOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<AnnouncementSetFilter>
}

export type QueryAssetArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryAssetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<AssetOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<AssetFilter>
}

export type QueryCaisiqArticleArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCaisiqArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqArticleOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqArticleFilter>
}

export type QueryCaisiqArticleDropdownArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCaisiqArticleDropdownCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqArticleDropdownOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqArticleDropdownFilter>
}

export type QueryCaisiqAuthorArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCaisiqAuthorCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqAuthorOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqAuthorFilter>
}

export type QueryCaisiqDefaultCourseImageSetArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCaisiqDefaultCourseImageSetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqDefaultCourseImageSetOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqDefaultCourseImageSetFilter>
}

export type QueryCaisiqDefaultFundsThumbnailSetArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCaisiqDefaultFundsThumbnailSetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqDefaultFundsThumbnailSetOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqDefaultFundsThumbnailSetFilter>
}

export type QueryCaisiqFundThumbnailArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCaisiqFundThumbnailCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqFundThumbnailOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqFundThumbnailFilter>
}

export type QueryCaisiqFundThumbnailSetArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCaisiqFundThumbnailSetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqFundThumbnailSetOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqFundThumbnailSetFilter>
}

export type QueryCaisiqHeroHeaderArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCaisiqHeroHeaderCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqHeroHeaderOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqHeroHeaderFilter>
}

export type QueryCaisiqHeroHeaderNavigateToButtonArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCaisiqHeroHeaderNavigateToButtonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqHeroHeaderNavigateToButtonOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqHeroHeaderNavigateToButtonFilter>
}

export type QueryCaisiqHomeOfficeArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCaisiqHomeOfficeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqHomeOfficeOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqHomeOfficeFilter>
}

export type QueryCaisiqJoinCaisCallToActionArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCaisiqJoinCaisCallToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqJoinCaisCallToActionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqJoinCaisCallToActionFilter>
}

export type QueryCaisiqTabArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCaisiqTabCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqTabOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqTabFilter>
}

export type QueryCaisiqVideoArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCaisiqVideoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqVideoOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqVideoFilter>
}

export type QueryCaisiqVideoPlaylistArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCaisiqVideoPlaylistCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CaisiqVideoPlaylistOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqVideoPlaylistFilter>
}

export type QueryCallToActionArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCallToActionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CallToActionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CallToActionFilter>
}

export type QueryChartArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryChartCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ChartOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ChartFilter>
}

export type QueryChildFirmArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryChildFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ChildFirmOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ChildFirmFilter>
}

export type QueryCiqArticleArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCiqArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CiqArticleOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CiqArticleFilter>
}

export type QueryCompanyArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCompanyAndContactInfoArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCompanyAndContactInfoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CompanyAndContactInfoOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CompanyAndContactInfoFilter>
}

export type QueryCompanyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CompanyOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CompanyFilter>
}

export type QueryComponentCardArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentCardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentCardOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentCardFilter>
}

export type QueryComponentCompanyContainerArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentCompanyContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentCompanyContainerOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentCompanyContainerFilter>
}

export type QueryComponentHeroArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentHeroCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentHeroOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentHeroFilter>
}

export type QueryComponentHubspotFormArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentHubspotFormCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentHubspotFormOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentHubspotFormFilter>
}

export type QueryComponentImageArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentImageOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentImageFilter>
}

export type QueryComponentPodcastPlayerArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentPodcastPlayerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentPodcastPlayerOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentPodcastPlayerFilter>
}

export type QueryComponentSectionArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentSectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentSectionFilter>
}

export type QueryComponentSummaryArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentSummaryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentSummaryOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentSummaryFilter>
}

export type QueryComponentTeamContainerArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentTeamContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentTeamContainerOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentTeamContainerFilter>
}

export type QueryComponentTextArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentTextCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentTextOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentTextFilter>
}

export type QueryComponentTextIllustrationArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentTextIllustrationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentTextIllustrationOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentTextIllustrationFilter>
}

export type QueryComponentTextImageArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentTextImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentTextImageOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentTextImageFilter>
}

export type QueryComponentTextImageImageSectionArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentTextImageImageSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentTextImageImageSectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentTextImageImageSectionFilter>
}

export type QueryComponentTextImageListSectionArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentTextImageListSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentTextImageListSectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentTextImageListSectionFilter>
}

export type QueryComponentTextImageTextSectionArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryComponentTextImageTextSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ComponentTextImageTextSectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ComponentTextImageTextSectionFilter>
}

export type QueryCorporateStatsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCorporateStatsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CorporateStatsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CorporateStatsFilter>
}

export type QueryCuratedFilterGroupArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCuratedFilterGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CuratedFilterGroupOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CuratedFilterGroupFilter>
}

export type QueryCuratedResearchContentArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryCuratedResearchContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<CuratedResearchContentOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CuratedResearchContentFilter>
}

export type QueryDisclaimerArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryDisclaimerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<DisclaimerOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<DisclaimerFilter>
}

export type QueryEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<EntryOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EntryFilter>
}

export type QueryEventArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryEventCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<EventOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<EventFilter>
}

export type QueryExternalContentArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryExternalContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ExternalContentOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ExternalContentFilter>
}

export type QueryFaqArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryFaqCategoryArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryFaqCategoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<FaqCategoryOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FaqCategoryFilter>
}

export type QueryFaqCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<FaqOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FaqFilter>
}

export type QueryFeaturedContentArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryFeaturedContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<FeaturedContentOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FeaturedContentFilter>
}

export type QueryFilterGroupArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryFilterGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<FilterGroupOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FilterGroupFilter>
}

export type QueryFirmArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<FirmOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FirmFilter>
}

export type QueryFullWidthImageArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryFullWidthImageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<FullWidthImageOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FullWidthImageFilter>
}

export type QueryFundProductIdArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryFundProductIdCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<FundProductIdOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<FundProductIdFilter>
}

export type QueryGlossaryTermArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryGlossaryTermCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<GlossaryTermOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<GlossaryTermFilter>
}

export type QueryHomepageAppMetadataArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryHomepageAppMetadataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<HomepageAppMetadataOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<HomepageAppMetadataFilter>
}

export type QueryHomepageContentSectionArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryHomepageContentSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<HomepageContentSectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<HomepageContentSectionFilter>
}

export type QueryHomepageLandingPageArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryHomepageLandingPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<HomepageLandingPageOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<HomepageLandingPageFilter>
}

export type QueryHtmlTableArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryHtmlTableCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<HtmlTableOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<HtmlTableFilter>
}

export type QueryImageSectionArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryImageSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ImageSectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ImageSectionFilter>
}

export type QueryLegalPageArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryLegalPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<LegalPageOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<LegalPageFilter>
}

export type QueryLinkArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<LinkOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<LinkFilter>
}

export type QueryLoginScreenArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryLoginScreenCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<LoginScreenOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<LoginScreenFilter>
}

export type QueryMarkdownArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryMarkdownCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<MarkdownOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MarkdownFilter>
}

export type QueryMemberFirmArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryMemberFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<MemberFirmOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MemberFirmFilter>
}

export type QueryMigrationArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryMigrationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<MigrationOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MigrationFilter>
}

export type QueryNavHeaderThemeArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryNavHeaderThemeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<NavHeaderThemeOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<NavHeaderThemeFilter>
}

export type QueryPageArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PageOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PageFilter>
}

export type QueryPageHelpCenterArticleArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPageHelpCenterArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PageHelpCenterArticleOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PageHelpCenterArticleFilter>
}

export type QueryPageLandingArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PageLandingOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PageLandingFilter>
}

export type QueryPagePwsCategoryArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPagePwsCategoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PagePwsCategoryOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PagePwsCategoryFilter>
}

export type QueryPageResourceArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPageResourceCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PageResourceOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PageResourceFilter>
}

export type QueryParentFirmArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryParentFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ParentFirmOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ParentFirmFilter>
}

export type QueryPdfArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPdfCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PdfOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PdfFilter>
}

export type QueryPersonArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPersonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PersonOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PersonFilter>
}

export type QueryPetArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PetOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PetFilter>
}

export type QueryPlaylistContentSetArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPlaylistContentSetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PlaylistContentSetOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PlaylistContentSetFilter>
}

export type QueryPresentationArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPresentationCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PresentationOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PresentationFilter>
}

export type QueryPressAwardArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPressAwardCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PressAwardOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PressAwardFilter>
}

export type QueryPwsContactMediaArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPwsContactMediaCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PwsContactMediaOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PwsContactMediaFilter>
}

export type QueryPwsInsightsContentSetArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPwsInsightsContentSetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PwsInsightsContentSetOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PwsInsightsContentSetFilter>
}

export type QueryPwsPressReleaseArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPwsPressReleaseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PwsPressReleaseOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PwsPressReleaseFilter>
}

export type QueryPwsSiteSettingsArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryPwsSiteSettingsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<PwsSiteSettingsOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PwsSiteSettingsFilter>
}

export type QueryQuoteArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryQuoteCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<QuoteOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<QuoteFilter>
}

export type QueryRelatedContentArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryRelatedContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<RelatedContentOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<RelatedContentFilter>
}

export type QueryRelatedContentStrategyArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryRelatedContentStrategyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<RelatedContentStrategyOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<RelatedContentStrategyFilter>
}

export type QuerySeoArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QuerySeoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SeoOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SeoFilter>
}

export type QuerySkPlaygroundArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QuerySkPlaygroundCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SkPlaygroundOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<SkPlaygroundFilter>
}

export type QueryTagArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTagCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TagOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TagFilter>
}

export type QueryTagFamilyArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTagFamilyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TagFamilyOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TagFamilyFilter>
}

export type QueryTeamArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTeamCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TeamOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TeamFilter>
}

export type QueryTestModelArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryTestModelCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TestModelOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TestModelFilter>
}

export type QueryThemeArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryThemeCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<ThemeOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<ThemeFilter>
}

export type QueryWhitepaperArgs = {
  id: Scalars['String']
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

export type QueryWhitepaperCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<WhitepaperOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<WhitepaperFilter>
}

/** Component to display a quote and its author info on PWS (Rebrand) [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/quote) */
export type Quote = Entry &
  _Node & {
    __typename?: 'Quote'
    _id: Scalars['ID']
    author?: Maybe<Person>
    background?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    date?: Maybe<Scalars['DateTime']>
    eyebrow?: Maybe<Scalars['String']>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<QuoteLinkingCollections>
    sys: Sys
    text?: Maybe<QuoteText>
  }

/** Component to display a quote and its author info on PWS (Rebrand) [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/quote) */
export type QuoteAuthorArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<PersonFilter>
}

/** Component to display a quote and its author info on PWS (Rebrand) [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/quote) */
export type QuoteBackgroundArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Component to display a quote and its author info on PWS (Rebrand) [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/quote) */
export type QuoteDateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Component to display a quote and its author info on PWS (Rebrand) [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/quote) */
export type QuoteEyebrowArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Component to display a quote and its author info on PWS (Rebrand) [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/quote) */
export type QuoteInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Component to display a quote and its author info on PWS (Rebrand) [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/quote) */
export type QuoteLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Component to display a quote and its author info on PWS (Rebrand) [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/quote) */
export type QuoteTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type QuoteCollection = {
  __typename?: 'QuoteCollection'
  items: Array<Maybe<Quote>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type QuoteFilter = {
  AND?: InputMaybe<Array<InputMaybe<QuoteFilter>>>
  OR?: InputMaybe<Array<InputMaybe<QuoteFilter>>>
  author?: InputMaybe<CfPersonNestedFilter>
  author_exists?: InputMaybe<Scalars['Boolean']>
  background?: InputMaybe<Scalars['String']>
  background_contains?: InputMaybe<Scalars['String']>
  background_exists?: InputMaybe<Scalars['Boolean']>
  background_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  background_not?: InputMaybe<Scalars['String']>
  background_not_contains?: InputMaybe<Scalars['String']>
  background_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  date?: InputMaybe<Scalars['DateTime']>
  date_exists?: InputMaybe<Scalars['Boolean']>
  date_gt?: InputMaybe<Scalars['DateTime']>
  date_gte?: InputMaybe<Scalars['DateTime']>
  date_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  date_lt?: InputMaybe<Scalars['DateTime']>
  date_lte?: InputMaybe<Scalars['DateTime']>
  date_not?: InputMaybe<Scalars['DateTime']>
  date_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  eyebrow?: InputMaybe<Scalars['String']>
  eyebrow_contains?: InputMaybe<Scalars['String']>
  eyebrow_exists?: InputMaybe<Scalars['Boolean']>
  eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eyebrow_not?: InputMaybe<Scalars['String']>
  eyebrow_not_contains?: InputMaybe<Scalars['String']>
  eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  text_contains?: InputMaybe<Scalars['String']>
  text_exists?: InputMaybe<Scalars['Boolean']>
  text_not_contains?: InputMaybe<Scalars['String']>
}

export type QuoteLinkingCollections = {
  __typename?: 'QuoteLinkingCollections'
  componentSectionCollection?: Maybe<ComponentSectionCollection>
  entryCollection?: Maybe<EntryCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
}

export type QuoteLinkingCollectionsComponentSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<QuoteLinkingCollectionsComponentSectionCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type QuoteLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type QuoteLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<QuoteLinkingCollectionsPageLandingCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum QuoteLinkingCollectionsComponentSectionCollectionOrder {
  BookmarkIdAsc = 'bookmarkId_ASC',
  BookmarkIdDesc = 'bookmarkId_DESC',
  CardContainerOptionsAsc = 'cardContainerOptions_ASC',
  CardContainerOptionsDesc = 'cardContainerOptions_DESC',
  ComponentIdAsc = 'componentId_ASC',
  ComponentIdDesc = 'componentId_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum QuoteLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum QuoteOrder {
  BackgroundAsc = 'background_ASC',
  BackgroundDesc = 'background_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type QuoteText = {
  __typename?: 'QuoteText'
  json: Scalars['JSON']
  links: QuoteTextLinks
}

export type QuoteTextAssets = {
  __typename?: 'QuoteTextAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type QuoteTextEntries = {
  __typename?: 'QuoteTextEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type QuoteTextLinks = {
  __typename?: 'QuoteTextLinks'
  assets: QuoteTextAssets
  entries: QuoteTextEntries
  resources: QuoteTextResources
}

export type QuoteTextResources = {
  __typename?: 'QuoteTextResources'
  block: Array<QuoteTextResourcesBlock>
  hyperlink: Array<QuoteTextResourcesHyperlink>
  inline: Array<QuoteTextResourcesInline>
}

export type QuoteTextResourcesBlock = ResourceLink & {
  __typename?: 'QuoteTextResourcesBlock'
  sys: ResourceSys
}

export type QuoteTextResourcesHyperlink = ResourceLink & {
  __typename?: 'QuoteTextResourcesHyperlink'
  sys: ResourceSys
}

export type QuoteTextResourcesInline = ResourceLink & {
  __typename?: 'QuoteTextResourcesInline'
  sys: ResourceSys
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/relatedContent) */
export type RelatedContent = Entry &
  _Node & {
    __typename?: 'RelatedContent'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    ctaLink?: Maybe<RelatedContentCtaLink>
    ctaText?: Maybe<Scalars['String']>
    description?: Maybe<RelatedContentDescription>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<RelatedContentLinkingCollections>
    strategiesCollection?: Maybe<RelatedContentStrategiesCollection>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/relatedContent) */
export type RelatedContentCtaLinkArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/relatedContent) */
export type RelatedContentCtaTextArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/relatedContent) */
export type RelatedContentDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/relatedContent) */
export type RelatedContentInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/relatedContent) */
export type RelatedContentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/relatedContent) */
export type RelatedContentStrategiesCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<RelatedContentStrategiesCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<RelatedContentStrategyFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/relatedContent) */
export type RelatedContentTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type RelatedContentCollection = {
  __typename?: 'RelatedContentCollection'
  items: Array<Maybe<RelatedContent>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type RelatedContentCtaLink = Link | Page

export type RelatedContentDescription = {
  __typename?: 'RelatedContentDescription'
  json: Scalars['JSON']
  links: RelatedContentDescriptionLinks
}

export type RelatedContentDescriptionAssets = {
  __typename?: 'RelatedContentDescriptionAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type RelatedContentDescriptionEntries = {
  __typename?: 'RelatedContentDescriptionEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type RelatedContentDescriptionLinks = {
  __typename?: 'RelatedContentDescriptionLinks'
  assets: RelatedContentDescriptionAssets
  entries: RelatedContentDescriptionEntries
  resources: RelatedContentDescriptionResources
}

export type RelatedContentDescriptionResources = {
  __typename?: 'RelatedContentDescriptionResources'
  block: Array<RelatedContentDescriptionResourcesBlock>
  hyperlink: Array<RelatedContentDescriptionResourcesHyperlink>
  inline: Array<RelatedContentDescriptionResourcesInline>
}

export type RelatedContentDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'RelatedContentDescriptionResourcesBlock'
  sys: ResourceSys
}

export type RelatedContentDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'RelatedContentDescriptionResourcesHyperlink'
  sys: ResourceSys
}

export type RelatedContentDescriptionResourcesInline = ResourceLink & {
  __typename?: 'RelatedContentDescriptionResourcesInline'
  sys: ResourceSys
}

export type RelatedContentFilter = {
  AND?: InputMaybe<Array<InputMaybe<RelatedContentFilter>>>
  OR?: InputMaybe<Array<InputMaybe<RelatedContentFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaLink_exists?: InputMaybe<Scalars['Boolean']>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_not_contains?: InputMaybe<Scalars['String']>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  strategies?: InputMaybe<CfRelatedContentStrategyNestedFilter>
  strategiesCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type RelatedContentLinkingCollections = {
  __typename?: 'RelatedContentLinkingCollections'
  caisiqArticleCollection?: Maybe<CaisiqArticleCollection>
  ciqArticleCollection?: Maybe<CiqArticleCollection>
  entryCollection?: Maybe<EntryCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
}

export type RelatedContentLinkingCollectionsCaisiqArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<RelatedContentLinkingCollectionsCaisiqArticleCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type RelatedContentLinkingCollectionsCiqArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<RelatedContentLinkingCollectionsCiqArticleCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type RelatedContentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type RelatedContentLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<RelatedContentLinkingCollectionsPageLandingCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum RelatedContentLinkingCollectionsCaisiqArticleCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum RelatedContentLinkingCollectionsCiqArticleCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum RelatedContentLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum RelatedContentOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type RelatedContentStrategiesCollection = {
  __typename?: 'RelatedContentStrategiesCollection'
  items: Array<Maybe<RelatedContentStrategy>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum RelatedContentStrategiesCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SortingAsc = 'sorting_ASC',
  SortingDesc = 'sorting_DESC',
  SourceAsc = 'source_ASC',
  SourceDesc = 'source_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/relatedContentStrategy) */
export type RelatedContentStrategy = Entry &
  _Node & {
    __typename?: 'RelatedContentStrategy'
    _id: Scalars['ID']
    authorsCollection?: Maybe<RelatedContentStrategyAuthorsCollection>
    contentfulMetadata: ContentfulMetadata
    internalName?: Maybe<Scalars['String']>
    itemsCollection?: Maybe<RelatedContentStrategyItemsCollection>
    linkedFrom?: Maybe<RelatedContentStrategyLinkingCollections>
    name?: Maybe<Scalars['String']>
    sorting?: Maybe<Scalars['String']>
    source?: Maybe<Scalars['String']>
    sys: Sys
    tagsCollection?: Maybe<RelatedContentStrategyTagsCollection>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/relatedContentStrategy) */
export type RelatedContentStrategyAuthorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<RelatedContentStrategyAuthorsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqAuthorFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/relatedContentStrategy) */
export type RelatedContentStrategyInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/relatedContentStrategy) */
export type RelatedContentStrategyItemsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<RelatedContentStrategyItemsFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/relatedContentStrategy) */
export type RelatedContentStrategyLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/relatedContentStrategy) */
export type RelatedContentStrategyNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/relatedContentStrategy) */
export type RelatedContentStrategySortingArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/relatedContentStrategy) */
export type RelatedContentStrategySourceArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/relatedContentStrategy) */
export type RelatedContentStrategyTagsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<RelatedContentStrategyTagsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TagFilter>
}

export type RelatedContentStrategyAuthorsCollection = {
  __typename?: 'RelatedContentStrategyAuthorsCollection'
  items: Array<Maybe<CaisiqAuthor>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum RelatedContentStrategyAuthorsCollectionOrder {
  AuthorNameAsc = 'authorName_ASC',
  AuthorNameDesc = 'authorName_DESC',
  FirmAsc = 'firm_ASC',
  FirmDesc = 'firm_DESC',
  JobTitleAsc = 'jobTitle_ASC',
  JobTitleDesc = 'jobTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type RelatedContentStrategyCollection = {
  __typename?: 'RelatedContentStrategyCollection'
  items: Array<Maybe<RelatedContentStrategy>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type RelatedContentStrategyFilter = {
  AND?: InputMaybe<Array<InputMaybe<RelatedContentStrategyFilter>>>
  OR?: InputMaybe<Array<InputMaybe<RelatedContentStrategyFilter>>>
  authors?: InputMaybe<CfCaisiqAuthorNestedFilter>
  authorsCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  items?: InputMaybe<CfitemsMultiTypeNestedFilter>
  itemsCollection_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sorting?: InputMaybe<Scalars['String']>
  sorting_contains?: InputMaybe<Scalars['String']>
  sorting_exists?: InputMaybe<Scalars['Boolean']>
  sorting_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sorting_not?: InputMaybe<Scalars['String']>
  sorting_not_contains?: InputMaybe<Scalars['String']>
  sorting_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  source?: InputMaybe<Scalars['String']>
  source_contains?: InputMaybe<Scalars['String']>
  source_exists?: InputMaybe<Scalars['Boolean']>
  source_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  source_not?: InputMaybe<Scalars['String']>
  source_not_contains?: InputMaybe<Scalars['String']>
  source_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tags?: InputMaybe<CfTagNestedFilter>
  tagsCollection_exists?: InputMaybe<Scalars['Boolean']>
}

export type RelatedContentStrategyItemsCollection = {
  __typename?: 'RelatedContentStrategyItemsCollection'
  items: Array<Maybe<RelatedContentStrategyItemsItem>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type RelatedContentStrategyItemsFilter = {
  AND?: InputMaybe<Array<InputMaybe<RelatedContentStrategyItemsFilter>>>
  OR?: InputMaybe<Array<InputMaybe<RelatedContentStrategyItemsFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type RelatedContentStrategyItemsItem =
  | CaisiqArticle
  | Page
  | PwsPressRelease
  | Whitepaper

export type RelatedContentStrategyLinkingCollections = {
  __typename?: 'RelatedContentStrategyLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  relatedContentCollection?: Maybe<RelatedContentCollection>
}

export type RelatedContentStrategyLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type RelatedContentStrategyLinkingCollectionsRelatedContentCollectionArgs =
  {
    limit?: InputMaybe<Scalars['Int']>
    locale?: InputMaybe<Scalars['String']>
    order?: InputMaybe<
      Array<
        InputMaybe<RelatedContentStrategyLinkingCollectionsRelatedContentCollectionOrder>
      >
    >
    preview?: InputMaybe<Scalars['Boolean']>
    skip?: InputMaybe<Scalars['Int']>
  }

export enum RelatedContentStrategyLinkingCollectionsRelatedContentCollectionOrder {
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum RelatedContentStrategyOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SortingAsc = 'sorting_ASC',
  SortingDesc = 'sorting_DESC',
  SourceAsc = 'source_ASC',
  SourceDesc = 'source_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type RelatedContentStrategyTagsCollection = {
  __typename?: 'RelatedContentStrategyTagsCollection'
  items: Array<Maybe<Tag>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum RelatedContentStrategyTagsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagIdAsc = 'tagId_ASC',
  TagIdDesc = 'tagId_DESC',
}

export type ResourceLink = {
  sys: ResourceSys
}

export type ResourceSys = {
  __typename?: 'ResourceSys'
  linkType: Scalars['String']
  urn: Scalars['String']
}

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/seo) */
export type Seo = Entry &
  _Node & {
    __typename?: 'Seo'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    description?: Maybe<Scalars['String']>
    keywords?: Maybe<Array<Maybe<Scalars['String']>>>
    linkedFrom?: Maybe<SeoLinkingCollections>
    name?: Maybe<Scalars['String']>
    noFollow?: Maybe<Scalars['Boolean']>
    noIndex?: Maybe<Scalars['Boolean']>
    openGraphImage?: Maybe<Asset>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/seo) */
export type SeoDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/seo) */
export type SeoKeywordsArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/seo) */
export type SeoLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/seo) */
export type SeoNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/seo) */
export type SeoNoFollowArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/seo) */
export type SeoNoIndexArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/seo) */
export type SeoOpenGraphImageArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** SEO Metadata for web pages in Compose. DO NOT DELETE [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/seo) */
export type SeoTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type SeoCollection = {
  __typename?: 'SeoCollection'
  items: Array<Maybe<Seo>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SeoFilter = {
  AND?: InputMaybe<Array<InputMaybe<SeoFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SeoFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  keywords_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  keywords_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  keywords_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  keywords_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  no_follow?: InputMaybe<Scalars['Boolean']>
  no_follow_exists?: InputMaybe<Scalars['Boolean']>
  no_follow_not?: InputMaybe<Scalars['Boolean']>
  no_index?: InputMaybe<Scalars['Boolean']>
  no_index_exists?: InputMaybe<Scalars['Boolean']>
  no_index_not?: InputMaybe<Scalars['Boolean']>
  openGraphImage_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type SeoLinkingCollections = {
  __typename?: 'SeoLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pageCollection?: Maybe<PageCollection>
}

export type SeoLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SeoLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<SeoLinkingCollectionsPageCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SeoLinkingCollectionsPageCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum SeoOrder {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  NoFollowAsc = 'no_follow_ASC',
  NoFollowDesc = 'no_follow_DESC',
  NoIndexAsc = 'no_index_ASC',
  NoIndexDesc = 'no_index_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** Test content model for PWS [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/skPlayground) */
export type SkPlayground = Entry &
  _Node & {
    __typename?: 'SkPlayground'
    _id: Scalars['ID']
    contentOwner?: Maybe<Array<Maybe<Scalars['String']>>>
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<SkPlaygroundLinkingCollections>
    name?: Maybe<Scalars['String']>
    overrideDefaultCuratedContent?: Maybe<Scalars['Boolean']>
    sys: Sys
    tagsCollection?: Maybe<SkPlaygroundTagsCollection>
  }

/** Test content model for PWS [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/skPlayground) */
export type SkPlaygroundContentOwnerArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Test content model for PWS [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/skPlayground) */
export type SkPlaygroundLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** Test content model for PWS [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/skPlayground) */
export type SkPlaygroundNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Test content model for PWS [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/skPlayground) */
export type SkPlaygroundOverrideDefaultCuratedContentArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** Test content model for PWS [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/skPlayground) */
export type SkPlaygroundTagsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<SkPlaygroundTagsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TagFilter>
}

export type SkPlaygroundCollection = {
  __typename?: 'SkPlaygroundCollection'
  items: Array<Maybe<SkPlayground>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type SkPlaygroundFilter = {
  AND?: InputMaybe<Array<InputMaybe<SkPlaygroundFilter>>>
  OR?: InputMaybe<Array<InputMaybe<SkPlaygroundFilter>>>
  contentOwner_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentOwner_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentOwner_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentOwner_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  overrideDefaultCuratedContent?: InputMaybe<Scalars['Boolean']>
  overrideDefaultCuratedContent_exists?: InputMaybe<Scalars['Boolean']>
  overrideDefaultCuratedContent_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  tags?: InputMaybe<CfTagNestedFilter>
  tagsCollection_exists?: InputMaybe<Scalars['Boolean']>
}

export type SkPlaygroundLinkingCollections = {
  __typename?: 'SkPlaygroundLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
}

export type SkPlaygroundLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type SkPlaygroundLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<SkPlaygroundLinkingCollectionsPageLandingCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum SkPlaygroundLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum SkPlaygroundOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultCuratedContentAsc = 'overrideDefaultCuratedContent_ASC',
  OverrideDefaultCuratedContentDesc = 'overrideDefaultCuratedContent_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type SkPlaygroundTagsCollection = {
  __typename?: 'SkPlaygroundTagsCollection'
  items: Array<Maybe<Tag>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum SkPlaygroundTagsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagIdAsc = 'tagId_ASC',
  TagIdDesc = 'tagId_DESC',
}

export type Sys = {
  __typename?: 'Sys'
  environmentId: Scalars['String']
  firstPublishedAt?: Maybe<Scalars['DateTime']>
  id: Scalars['String']
  /** The locale that was requested. */
  locale?: Maybe<Scalars['String']>
  publishedAt?: Maybe<Scalars['DateTime']>
  publishedVersion?: Maybe<Scalars['Int']>
  spaceId: Scalars['String']
}

export type SysFilter = {
  firstPublishedAt?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_exists?: InputMaybe<Scalars['Boolean']>
  firstPublishedAt_gt?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_gte?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  firstPublishedAt_lt?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_lte?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_not?: InputMaybe<Scalars['DateTime']>
  firstPublishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  id?: InputMaybe<Scalars['String']>
  id_contains?: InputMaybe<Scalars['String']>
  id_exists?: InputMaybe<Scalars['Boolean']>
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_not?: InputMaybe<Scalars['String']>
  id_not_contains?: InputMaybe<Scalars['String']>
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  publishedAt?: InputMaybe<Scalars['DateTime']>
  publishedAt_exists?: InputMaybe<Scalars['Boolean']>
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>
  publishedAt_not?: InputMaybe<Scalars['DateTime']>
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  publishedVersion?: InputMaybe<Scalars['Float']>
  publishedVersion_exists?: InputMaybe<Scalars['Boolean']>
  publishedVersion_gt?: InputMaybe<Scalars['Float']>
  publishedVersion_gte?: InputMaybe<Scalars['Float']>
  publishedVersion_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  publishedVersion_lt?: InputMaybe<Scalars['Float']>
  publishedVersion_lte?: InputMaybe<Scalars['Float']>
  publishedVersion_not?: InputMaybe<Scalars['Float']>
  publishedVersion_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/tag) */
export type Tag = Entry &
  _Node & {
    __typename?: 'Tag'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    internalName?: Maybe<Scalars['String']>
    label?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<TagLinkingCollections>
    sys: Sys
    tagId?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/tag) */
export type TagInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/tag) */
export type TagLabelArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/tag) */
export type TagLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/tag) */
export type TagTagIdArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TagCollection = {
  __typename?: 'TagCollection'
  items: Array<Maybe<Tag>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/tagFamily) */
export type TagFamily = Entry &
  _Node & {
    __typename?: 'TagFamily'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<TagFamilyLinkingCollections>
    name?: Maybe<Scalars['String']>
    sys: Sys
    tagFamilyType?: Maybe<Scalars['String']>
    tagsCollection?: Maybe<TagFamilyTagsCollection>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/tagFamily) */
export type TagFamilyInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/tagFamily) */
export type TagFamilyLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/tagFamily) */
export type TagFamilyNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/tagFamily) */
export type TagFamilyTagFamilyTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/tagFamily) */
export type TagFamilyTagsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TagFamilyTagsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TagFilter>
}

export type TagFamilyCollection = {
  __typename?: 'TagFamilyCollection'
  items: Array<Maybe<TagFamily>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TagFamilyFilter = {
  AND?: InputMaybe<Array<InputMaybe<TagFamilyFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TagFamilyFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tagFamilyType?: InputMaybe<Scalars['String']>
  tagFamilyType_contains?: InputMaybe<Scalars['String']>
  tagFamilyType_exists?: InputMaybe<Scalars['Boolean']>
  tagFamilyType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  tagFamilyType_not?: InputMaybe<Scalars['String']>
  tagFamilyType_not_contains?: InputMaybe<Scalars['String']>
  tagFamilyType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  tags?: InputMaybe<CfTagNestedFilter>
  tagsCollection_exists?: InputMaybe<Scalars['Boolean']>
}

export type TagFamilyLinkingCollections = {
  __typename?: 'TagFamilyLinkingCollections'
  curatedFilterGroupCollection?: Maybe<CuratedFilterGroupCollection>
  entryCollection?: Maybe<EntryCollection>
  filterGroupCollection?: Maybe<FilterGroupCollection>
}

export type TagFamilyLinkingCollectionsCuratedFilterGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<TagFamilyLinkingCollectionsCuratedFilterGroupCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TagFamilyLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TagFamilyLinkingCollectionsFilterGroupCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<TagFamilyLinkingCollectionsFilterGroupCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TagFamilyLinkingCollectionsCuratedFilterGroupCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TagFamilyLinkingCollectionsFilterGroupCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TagFamilyOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagFamilyTypeAsc = 'tagFamilyType_ASC',
  TagFamilyTypeDesc = 'tagFamilyType_DESC',
}

export type TagFamilyTagsCollection = {
  __typename?: 'TagFamilyTagsCollection'
  items: Array<Maybe<Tag>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum TagFamilyTagsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagIdAsc = 'tagId_ASC',
  TagIdDesc = 'tagId_DESC',
}

export type TagFilter = {
  AND?: InputMaybe<Array<InputMaybe<TagFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TagFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  label?: InputMaybe<Scalars['String']>
  label_contains?: InputMaybe<Scalars['String']>
  label_exists?: InputMaybe<Scalars['Boolean']>
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  label_not?: InputMaybe<Scalars['String']>
  label_not_contains?: InputMaybe<Scalars['String']>
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tagId?: InputMaybe<Scalars['String']>
  tagId_contains?: InputMaybe<Scalars['String']>
  tagId_exists?: InputMaybe<Scalars['Boolean']>
  tagId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  tagId_not?: InputMaybe<Scalars['String']>
  tagId_not_contains?: InputMaybe<Scalars['String']>
  tagId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TagLinkingCollections = {
  __typename?: 'TagLinkingCollections'
  caisiqArticleCollection?: Maybe<CaisiqArticleCollection>
  caisiqVideoCollection?: Maybe<CaisiqVideoCollection>
  caisiqVideoPlaylistCollection?: Maybe<CaisiqVideoPlaylistCollection>
  ciqArticleCollection?: Maybe<CiqArticleCollection>
  entryCollection?: Maybe<EntryCollection>
  eventCollection?: Maybe<EventCollection>
  externalContentCollection?: Maybe<ExternalContentCollection>
  homepageAppMetadataCollection?: Maybe<HomepageAppMetadataCollection>
  pwsPressReleaseCollection?: Maybe<PwsPressReleaseCollection>
  relatedContentStrategyCollection?: Maybe<RelatedContentStrategyCollection>
  skPlaygroundCollection?: Maybe<SkPlaygroundCollection>
  tagFamilyCollection?: Maybe<TagFamilyCollection>
  whitepaperCollection?: Maybe<WhitepaperCollection>
}

export type TagLinkingCollectionsCaisiqArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<TagLinkingCollectionsCaisiqArticleCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TagLinkingCollectionsCaisiqVideoCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<TagLinkingCollectionsCaisiqVideoCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TagLinkingCollectionsCaisiqVideoPlaylistCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<TagLinkingCollectionsCaisiqVideoPlaylistCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TagLinkingCollectionsCiqArticleCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<TagLinkingCollectionsCiqArticleCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TagLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TagLinkingCollectionsEventCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<TagLinkingCollectionsEventCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TagLinkingCollectionsExternalContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<TagLinkingCollectionsExternalContentCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TagLinkingCollectionsHomepageAppMetadataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<TagLinkingCollectionsHomepageAppMetadataCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TagLinkingCollectionsPwsPressReleaseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<TagLinkingCollectionsPwsPressReleaseCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TagLinkingCollectionsRelatedContentStrategyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<TagLinkingCollectionsRelatedContentStrategyCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TagLinkingCollectionsSkPlaygroundCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<TagLinkingCollectionsSkPlaygroundCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TagLinkingCollectionsTagFamilyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<TagLinkingCollectionsTagFamilyCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TagLinkingCollectionsWhitepaperCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<TagLinkingCollectionsWhitepaperCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TagLinkingCollectionsCaisiqArticleCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TagLinkingCollectionsCaisiqVideoCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  DurationAsc = 'duration_ASC',
  DurationDesc = 'duration_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  MuxThumbnailTimeAsc = 'muxThumbnailTime_ASC',
  MuxThumbnailTimeDesc = 'muxThumbnailTime_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TagLinkingCollectionsCaisiqVideoPlaylistCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  EpisodicAsc = 'episodic_ASC',
  EpisodicDesc = 'episodic_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TagLinkingCollectionsCiqArticleCollectionOrder {
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TagLinkingCollectionsEventCollectionOrder {
  AllDayAsc = 'allDay_ASC',
  AllDayDesc = 'allDay_DESC',
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DateTimeEndAsc = 'dateTimeEnd_ASC',
  DateTimeEndDesc = 'dateTimeEnd_DESC',
  DateTimeAsc = 'dateTime_ASC',
  DateTimeDesc = 'dateTime_DESC',
  EventTypeAsc = 'eventType_ASC',
  EventTypeDesc = 'eventType_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  LocationNameAsc = 'locationName_ASC',
  LocationNameDesc = 'locationName_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TimezoneAsc = 'timezone_ASC',
  TimezoneDesc = 'timezone_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TagLinkingCollectionsExternalContentCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  EligibilityAsc = 'eligibility_ASC',
  EligibilityDesc = 'eligibility_DESC',
  ExternalIdAsc = 'externalId_ASC',
  ExternalIdDesc = 'externalId_DESC',
  ExternalLinkAsc = 'externalLink_ASC',
  ExternalLinkDesc = 'externalLink_DESC',
  MinimumInitialInvestmentAsc = 'minimumInitialInvestment_ASC',
  MinimumInitialInvestmentDesc = 'minimumInitialInvestment_DESC',
  StrategyAsc = 'strategy_ASC',
  StrategyDesc = 'strategy_DESC',
  SubStrategyAsc = 'subStrategy_ASC',
  SubStrategyDesc = 'subStrategy_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum TagLinkingCollectionsHomepageAppMetadataCollectionOrder {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TagLinkingCollectionsPwsPressReleaseCollectionOrder {
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  ExternalNewsLinkAsc = 'externalNewsLink_ASC',
  ExternalNewsLinkDesc = 'externalNewsLink_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
}

export enum TagLinkingCollectionsRelatedContentStrategyCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SortingAsc = 'sorting_ASC',
  SortingDesc = 'sorting_DESC',
  SourceAsc = 'source_ASC',
  SourceDesc = 'source_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TagLinkingCollectionsSkPlaygroundCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultCuratedContentAsc = 'overrideDefaultCuratedContent_ASC',
  OverrideDefaultCuratedContentDesc = 'overrideDefaultCuratedContent_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TagLinkingCollectionsTagFamilyCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagFamilyTypeAsc = 'tagFamilyType_ASC',
  TagFamilyTypeDesc = 'tagFamilyType_DESC',
}

export enum TagLinkingCollectionsWhitepaperCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum TagOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagIdAsc = 'tagId_ASC',
  TagIdDesc = 'tagId_DESC',
}

/**
 * Represents a taxonomy concept entity for finding and organizing content easily.
 *         Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-concepts
 */
export type TaxonomyConcept = {
  __typename?: 'TaxonomyConcept'
  id?: Maybe<Scalars['String']>
}

/** A team that consist of one or more persons [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/team) */
export type Team = Entry &
  _Node & {
    __typename?: 'Team'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    description?: Maybe<TeamDescription>
    internalName?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<TeamLinkingCollections>
    membersCollection?: Maybe<TeamMembersCollection>
    slug?: Maybe<Scalars['String']>
    sys: Sys
    title?: Maybe<Scalars['String']>
  }

/** A team that consist of one or more persons [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/team) */
export type TeamDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A team that consist of one or more persons [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/team) */
export type TeamInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A team that consist of one or more persons [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/team) */
export type TeamLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A team that consist of one or more persons [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/team) */
export type TeamMembersCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<TeamMembersCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PersonFilter>
}

/** A team that consist of one or more persons [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/team) */
export type TeamSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A team that consist of one or more persons [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/team) */
export type TeamTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TeamCollection = {
  __typename?: 'TeamCollection'
  items: Array<Maybe<Team>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TeamDescription = {
  __typename?: 'TeamDescription'
  json: Scalars['JSON']
  links: TeamDescriptionLinks
}

export type TeamDescriptionAssets = {
  __typename?: 'TeamDescriptionAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type TeamDescriptionEntries = {
  __typename?: 'TeamDescriptionEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type TeamDescriptionLinks = {
  __typename?: 'TeamDescriptionLinks'
  assets: TeamDescriptionAssets
  entries: TeamDescriptionEntries
  resources: TeamDescriptionResources
}

export type TeamDescriptionResources = {
  __typename?: 'TeamDescriptionResources'
  block: Array<TeamDescriptionResourcesBlock>
  hyperlink: Array<TeamDescriptionResourcesHyperlink>
  inline: Array<TeamDescriptionResourcesInline>
}

export type TeamDescriptionResourcesBlock = ResourceLink & {
  __typename?: 'TeamDescriptionResourcesBlock'
  sys: ResourceSys
}

export type TeamDescriptionResourcesHyperlink = ResourceLink & {
  __typename?: 'TeamDescriptionResourcesHyperlink'
  sys: ResourceSys
}

export type TeamDescriptionResourcesInline = ResourceLink & {
  __typename?: 'TeamDescriptionResourcesInline'
  sys: ResourceSys
}

export type TeamFilter = {
  AND?: InputMaybe<Array<InputMaybe<TeamFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TeamFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_not_contains?: InputMaybe<Scalars['String']>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  members?: InputMaybe<CfPersonNestedFilter>
  membersCollection_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TeamLinkingCollections = {
  __typename?: 'TeamLinkingCollections'
  componentSectionCollection?: Maybe<ComponentSectionCollection>
  componentTeamContainerCollection?: Maybe<ComponentTeamContainerCollection>
  entryCollection?: Maybe<EntryCollection>
}

export type TeamLinkingCollectionsComponentSectionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<TeamLinkingCollectionsComponentSectionCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TeamLinkingCollectionsComponentTeamContainerCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<TeamLinkingCollectionsComponentTeamContainerCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type TeamLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TeamLinkingCollectionsComponentSectionCollectionOrder {
  BookmarkIdAsc = 'bookmarkId_ASC',
  BookmarkIdDesc = 'bookmarkId_DESC',
  CardContainerOptionsAsc = 'cardContainerOptions_ASC',
  CardContainerOptionsDesc = 'cardContainerOptions_DESC',
  ComponentIdAsc = 'componentId_ASC',
  ComponentIdDesc = 'componentId_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TeamLinkingCollectionsComponentTeamContainerCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type TeamMembersCollection = {
  __typename?: 'TeamMembersCollection'
  items: Array<Maybe<Person>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum TeamMembersCollectionOrder {
  EmailAsc = 'email_ASC',
  EmailDesc = 'email_DESC',
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum TeamOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** for testing purposes [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/testModel) */
export type TestModel = Entry &
  _Node & {
    __typename?: 'TestModel'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<TestModelLinkingCollections>
    sys: Sys
    testField?: Maybe<Scalars['String']>
  }

/** for testing purposes [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/testModel) */
export type TestModelLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** for testing purposes [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/testModel) */
export type TestModelTestFieldArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type TestModelCollection = {
  __typename?: 'TestModelCollection'
  items: Array<Maybe<TestModel>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type TestModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<TestModelFilter>>>
  OR?: InputMaybe<Array<InputMaybe<TestModelFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
  testField?: InputMaybe<Scalars['String']>
  testField_contains?: InputMaybe<Scalars['String']>
  testField_exists?: InputMaybe<Scalars['Boolean']>
  testField_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  testField_not?: InputMaybe<Scalars['String']>
  testField_not_contains?: InputMaybe<Scalars['String']>
  testField_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type TestModelLinkingCollections = {
  __typename?: 'TestModelLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
}

export type TestModelLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum TestModelOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TestFieldAsc = 'testField_ASC',
  TestFieldDesc = 'testField_DESC',
}

/** A set of colors, icons and images for a specific theme [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/theme) */
export type Theme = Entry &
  _Node & {
    __typename?: 'Theme'
    _id: Scalars['ID']
    contentfulMetadata: ContentfulMetadata
    linkedFrom?: Maybe<ThemeLinkingCollections>
    name?: Maybe<Scalars['String']>
    navHeaderForAdmin?: Maybe<NavHeaderTheme>
    navHeaderWithHero?: Maybe<NavHeaderTheme>
    navHeaderWithIframe?: Maybe<NavHeaderTheme>
    sys: Sys
  }

/** A set of colors, icons and images for a specific theme [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/theme) */
export type ThemeLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** A set of colors, icons and images for a specific theme [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/theme) */
export type ThemeNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** A set of colors, icons and images for a specific theme [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/theme) */
export type ThemeNavHeaderForAdminArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<NavHeaderThemeFilter>
}

/** A set of colors, icons and images for a specific theme [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/theme) */
export type ThemeNavHeaderWithHeroArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<NavHeaderThemeFilter>
}

/** A set of colors, icons and images for a specific theme [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/theme) */
export type ThemeNavHeaderWithIframeArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<NavHeaderThemeFilter>
}

export type ThemeCollection = {
  __typename?: 'ThemeCollection'
  items: Array<Maybe<Theme>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type ThemeFilter = {
  AND?: InputMaybe<Array<InputMaybe<ThemeFilter>>>
  OR?: InputMaybe<Array<InputMaybe<ThemeFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  navHeaderForAdmin?: InputMaybe<CfNavHeaderThemeNestedFilter>
  navHeaderForAdmin_exists?: InputMaybe<Scalars['Boolean']>
  navHeaderWithHero?: InputMaybe<CfNavHeaderThemeNestedFilter>
  navHeaderWithHero_exists?: InputMaybe<Scalars['Boolean']>
  navHeaderWithIframe?: InputMaybe<CfNavHeaderThemeNestedFilter>
  navHeaderWithIframe_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type ThemeLinkingCollections = {
  __typename?: 'ThemeLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  firmCollection?: Maybe<FirmCollection>
}

export type ThemeLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type ThemeLinkingCollectionsFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<ThemeLinkingCollectionsFirmCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum ThemeLinkingCollectionsFirmCollectionOrder {
  CeCreditsAvailableAsc = 'ceCreditsAvailable_ASC',
  CeCreditsAvailableDesc = 'ceCreditsAvailable_DESC',
  FirmIdAsc = 'firmId_ASC',
  FirmIdDesc = 'firmId_DESC',
  HeaderTypeAsc = 'headerType_ASC',
  HeaderTypeDesc = 'headerType_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum ThemeOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type Whitepaper = Entry &
  _Node & {
    __typename?: 'Whitepaper'
    _id: Scalars['ID']
    author?: Maybe<CaisiqAuthor>
    authorsCollection?: Maybe<WhitepaperAuthorsCollection>
    body?: Maybe<WhitepaperBody>
    byCais?: Maybe<Scalars['Boolean']>
    callToAction?: Maybe<CallToAction>
    category?: Maybe<Scalars['String']>
    contentfulMetadata: ContentfulMetadata
    customDescription?: Maybe<Scalars['String']>
    customTitle?: Maybe<Scalars['String']>
    displayDate?: Maybe<Scalars['DateTime']>
    firmsCollection?: Maybe<WhitepaperFirmsCollection>
    footnotes?: Maybe<WhitepaperFootnotes>
    fundProductIds?: Maybe<FundProductId>
    hero?: Maybe<Asset>
    introduction?: Maybe<Scalars['String']>
    linkedFrom?: Maybe<WhitepaperLinkingCollections>
    name?: Maybe<Scalars['String']>
    pdfsCollection?: Maybe<WhitepaperPdfsCollection>
    pwsCategoryCollection?: Maybe<WhitepaperPwsCategoryCollection>
    slug?: Maybe<Scalars['String']>
    sys: Sys
    tagsCollection?: Maybe<WhitepaperTagsCollection>
    testExcludedFirmsCollection?: Maybe<WhitepaperTestExcludedFirmsCollection>
    thumbnail?: Maybe<Asset>
    title?: Maybe<Scalars['String']>
    type?: Maybe<Scalars['String']>
  }

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperAuthorArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CaisiqAuthorFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperAuthorsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<WhitepaperAuthorsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<CaisiqAuthorFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperBodyArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperByCaisArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperCallToActionArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CallToActionFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperCategoryArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperCustomDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperCustomTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperDisplayDateArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperFirmsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<WhitepaperFirmsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MemberFirmFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperFootnotesArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperFundProductIdsArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<FundProductIdFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperHeroArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperIntroductionArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperNameArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperPdfsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<WhitepaperPdfsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PdfFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperPwsCategoryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<WhitepaperPwsCategoryCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<PagePwsCategoryFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperSlugArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperTagsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<Array<InputMaybe<WhitepaperTagsCollectionOrder>>>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<TagFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperTestExcludedFirmsCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<WhitepaperTestExcludedFirmsCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<MemberFirmFilter>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperThumbnailArgs = {
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperTitleArgs = {
  locale?: InputMaybe<Scalars['String']>
}

/** [See type definition](https://app.contentful.com/spaces/matahbe7799j/content_types/whitepaper) */
export type WhitepaperTypeArgs = {
  locale?: InputMaybe<Scalars['String']>
}

export type WhitepaperAuthorsCollection = {
  __typename?: 'WhitepaperAuthorsCollection'
  items: Array<Maybe<CaisiqAuthor>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum WhitepaperAuthorsCollectionOrder {
  AuthorNameAsc = 'authorName_ASC',
  AuthorNameDesc = 'authorName_DESC',
  FirmAsc = 'firm_ASC',
  FirmDesc = 'firm_DESC',
  JobTitleAsc = 'jobTitle_ASC',
  JobTitleDesc = 'jobTitle_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type WhitepaperBody = {
  __typename?: 'WhitepaperBody'
  json: Scalars['JSON']
  links: WhitepaperBodyLinks
}

export type WhitepaperBodyAssets = {
  __typename?: 'WhitepaperBodyAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type WhitepaperBodyEntries = {
  __typename?: 'WhitepaperBodyEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type WhitepaperBodyLinks = {
  __typename?: 'WhitepaperBodyLinks'
  assets: WhitepaperBodyAssets
  entries: WhitepaperBodyEntries
  resources: WhitepaperBodyResources
}

export type WhitepaperBodyResources = {
  __typename?: 'WhitepaperBodyResources'
  block: Array<WhitepaperBodyResourcesBlock>
  hyperlink: Array<WhitepaperBodyResourcesHyperlink>
  inline: Array<WhitepaperBodyResourcesInline>
}

export type WhitepaperBodyResourcesBlock = ResourceLink & {
  __typename?: 'WhitepaperBodyResourcesBlock'
  sys: ResourceSys
}

export type WhitepaperBodyResourcesHyperlink = ResourceLink & {
  __typename?: 'WhitepaperBodyResourcesHyperlink'
  sys: ResourceSys
}

export type WhitepaperBodyResourcesInline = ResourceLink & {
  __typename?: 'WhitepaperBodyResourcesInline'
  sys: ResourceSys
}

export type WhitepaperCollection = {
  __typename?: 'WhitepaperCollection'
  items: Array<Maybe<Whitepaper>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export type WhitepaperFilter = {
  AND?: InputMaybe<Array<InputMaybe<WhitepaperFilter>>>
  OR?: InputMaybe<Array<InputMaybe<WhitepaperFilter>>>
  author?: InputMaybe<CfCaisiqAuthorNestedFilter>
  author_exists?: InputMaybe<Scalars['Boolean']>
  authors?: InputMaybe<CfCaisiqAuthorNestedFilter>
  authorsCollection_exists?: InputMaybe<Scalars['Boolean']>
  body_contains?: InputMaybe<Scalars['String']>
  body_exists?: InputMaybe<Scalars['Boolean']>
  body_not_contains?: InputMaybe<Scalars['String']>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  callToAction?: InputMaybe<CfCallToActionNestedFilter>
  callToAction_exists?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['String']>
  category_contains?: InputMaybe<Scalars['String']>
  category_exists?: InputMaybe<Scalars['Boolean']>
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category_not?: InputMaybe<Scalars['String']>
  category_not_contains?: InputMaybe<Scalars['String']>
  category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayDate?: InputMaybe<Scalars['DateTime']>
  displayDate_exists?: InputMaybe<Scalars['Boolean']>
  displayDate_gt?: InputMaybe<Scalars['DateTime']>
  displayDate_gte?: InputMaybe<Scalars['DateTime']>
  displayDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  displayDate_lt?: InputMaybe<Scalars['DateTime']>
  displayDate_lte?: InputMaybe<Scalars['DateTime']>
  displayDate_not?: InputMaybe<Scalars['DateTime']>
  displayDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  firms?: InputMaybe<CfMemberFirmNestedFilter>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  footnotes_contains?: InputMaybe<Scalars['String']>
  footnotes_exists?: InputMaybe<Scalars['Boolean']>
  footnotes_not_contains?: InputMaybe<Scalars['String']>
  fundProductIds?: InputMaybe<CfFundProductIdNestedFilter>
  fundProductIds_exists?: InputMaybe<Scalars['Boolean']>
  hero_exists?: InputMaybe<Scalars['Boolean']>
  introduction?: InputMaybe<Scalars['String']>
  introduction_contains?: InputMaybe<Scalars['String']>
  introduction_exists?: InputMaybe<Scalars['Boolean']>
  introduction_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  introduction_not?: InputMaybe<Scalars['String']>
  introduction_not_contains?: InputMaybe<Scalars['String']>
  introduction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  pdfs?: InputMaybe<CfPdfNestedFilter>
  pdfsCollection_exists?: InputMaybe<Scalars['Boolean']>
  pwsCategory?: InputMaybe<CfPagePwsCategoryNestedFilter>
  pwsCategoryCollection_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tags?: InputMaybe<CfTagNestedFilter>
  tagsCollection_exists?: InputMaybe<Scalars['Boolean']>
  testExcludedFirms?: InputMaybe<CfMemberFirmNestedFilter>
  testExcludedFirmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  thumbnail_exists?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type?: InputMaybe<Scalars['String']>
  type_contains?: InputMaybe<Scalars['String']>
  type_exists?: InputMaybe<Scalars['Boolean']>
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type_not?: InputMaybe<Scalars['String']>
  type_not_contains?: InputMaybe<Scalars['String']>
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type WhitepaperFirmsCollection = {
  __typename?: 'WhitepaperFirmsCollection'
  items: Array<Maybe<MemberFirm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum WhitepaperFirmsCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type WhitepaperFootnotes = {
  __typename?: 'WhitepaperFootnotes'
  json: Scalars['JSON']
  links: WhitepaperFootnotesLinks
}

export type WhitepaperFootnotesAssets = {
  __typename?: 'WhitepaperFootnotesAssets'
  block: Array<Maybe<Asset>>
  hyperlink: Array<Maybe<Asset>>
}

export type WhitepaperFootnotesEntries = {
  __typename?: 'WhitepaperFootnotesEntries'
  block: Array<Maybe<Entry>>
  hyperlink: Array<Maybe<Entry>>
  inline: Array<Maybe<Entry>>
}

export type WhitepaperFootnotesLinks = {
  __typename?: 'WhitepaperFootnotesLinks'
  assets: WhitepaperFootnotesAssets
  entries: WhitepaperFootnotesEntries
  resources: WhitepaperFootnotesResources
}

export type WhitepaperFootnotesResources = {
  __typename?: 'WhitepaperFootnotesResources'
  block: Array<WhitepaperFootnotesResourcesBlock>
  hyperlink: Array<WhitepaperFootnotesResourcesHyperlink>
  inline: Array<WhitepaperFootnotesResourcesInline>
}

export type WhitepaperFootnotesResourcesBlock = ResourceLink & {
  __typename?: 'WhitepaperFootnotesResourcesBlock'
  sys: ResourceSys
}

export type WhitepaperFootnotesResourcesHyperlink = ResourceLink & {
  __typename?: 'WhitepaperFootnotesResourcesHyperlink'
  sys: ResourceSys
}

export type WhitepaperFootnotesResourcesInline = ResourceLink & {
  __typename?: 'WhitepaperFootnotesResourcesInline'
  sys: ResourceSys
}

export type WhitepaperLinkingCollections = {
  __typename?: 'WhitepaperLinkingCollections'
  entryCollection?: Maybe<EntryCollection>
  featuredContentCollection?: Maybe<FeaturedContentCollection>
  firmCollection?: Maybe<FirmCollection>
  homepageAppMetadataCollection?: Maybe<HomepageAppMetadataCollection>
  linkCollection?: Maybe<LinkCollection>
  memberFirmCollection?: Maybe<MemberFirmCollection>
  pageCollection?: Maybe<PageCollection>
  pageLandingCollection?: Maybe<PageLandingCollection>
  pwsInsightsContentSetCollection?: Maybe<PwsInsightsContentSetCollection>
  relatedContentStrategyCollection?: Maybe<RelatedContentStrategyCollection>
}

export type WhitepaperLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type WhitepaperLinkingCollectionsFeaturedContentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<WhitepaperLinkingCollectionsFeaturedContentCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type WhitepaperLinkingCollectionsFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<WhitepaperLinkingCollectionsFirmCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type WhitepaperLinkingCollectionsHomepageAppMetadataCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<WhitepaperLinkingCollectionsHomepageAppMetadataCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type WhitepaperLinkingCollectionsLinkCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<WhitepaperLinkingCollectionsLinkCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type WhitepaperLinkingCollectionsMemberFirmCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<WhitepaperLinkingCollectionsMemberFirmCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type WhitepaperLinkingCollectionsPageCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<WhitepaperLinkingCollectionsPageCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type WhitepaperLinkingCollectionsPageLandingCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<InputMaybe<WhitepaperLinkingCollectionsPageLandingCollectionOrder>>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type WhitepaperLinkingCollectionsPwsInsightsContentSetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<WhitepaperLinkingCollectionsPwsInsightsContentSetCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export type WhitepaperLinkingCollectionsRelatedContentStrategyCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']>
  locale?: InputMaybe<Scalars['String']>
  order?: InputMaybe<
    Array<
      InputMaybe<WhitepaperLinkingCollectionsRelatedContentStrategyCollectionOrder>
    >
  >
  preview?: InputMaybe<Scalars['Boolean']>
  skip?: InputMaybe<Scalars['Int']>
}

export enum WhitepaperLinkingCollectionsFeaturedContentCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum WhitepaperLinkingCollectionsFirmCollectionOrder {
  CeCreditsAvailableAsc = 'ceCreditsAvailable_ASC',
  CeCreditsAvailableDesc = 'ceCreditsAvailable_DESC',
  FirmIdAsc = 'firmId_ASC',
  FirmIdDesc = 'firmId_DESC',
  HeaderTypeAsc = 'headerType_ASC',
  HeaderTypeDesc = 'headerType_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum WhitepaperLinkingCollectionsHomepageAppMetadataCollectionOrder {
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum WhitepaperLinkingCollectionsLinkCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  ExternalLinkAsc = 'externalLink_ASC',
  ExternalLinkDesc = 'externalLink_DESC',
  InternalLinkAsc = 'internalLink_ASC',
  InternalLinkDesc = 'internalLink_DESC',
  OpenInNewWindowAsc = 'openInNewWindow_ASC',
  OpenInNewWindowDesc = 'openInNewWindow_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum WhitepaperLinkingCollectionsMemberFirmCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum WhitepaperLinkingCollectionsPageCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum WhitepaperLinkingCollectionsPageLandingCollectionOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PageTypeAsc = 'pageType_ASC',
  PageTypeDesc = 'pageType_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum WhitepaperLinkingCollectionsPwsInsightsContentSetCollectionOrder {
  DisplayTitleAsc = 'displayTitle_ASC',
  DisplayTitleDesc = 'displayTitle_DESC',
  LayoutStyleAsc = 'layoutStyle_ASC',
  LayoutStyleDesc = 'layoutStyle_DESC',
  SortingAsc = 'sorting_ASC',
  SortingDesc = 'sorting_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum WhitepaperLinkingCollectionsRelatedContentStrategyCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SortingAsc = 'sorting_ASC',
  SortingDesc = 'sorting_DESC',
  SourceAsc = 'source_ASC',
  SourceDesc = 'source_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export enum WhitepaperOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type WhitepaperPdfsCollection = {
  __typename?: 'WhitepaperPdfsCollection'
  items: Array<Maybe<Pdf>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum WhitepaperPdfsCollectionOrder {
  ByCaisAsc = 'byCAIS_ASC',
  ByCaisDesc = 'byCAIS_DESC',
  CustomTitleAsc = 'customTitle_ASC',
  CustomTitleDesc = 'customTitle_DESC',
  DisplayDateAsc = 'displayDate_ASC',
  DisplayDateDesc = 'displayDate_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type WhitepaperPwsCategoryCollection = {
  __typename?: 'WhitepaperPwsCategoryCollection'
  items: Array<Maybe<PagePwsCategory>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum WhitepaperPwsCategoryCollectionOrder {
  CategoryIdAsc = 'categoryId_ASC',
  CategoryIdDesc = 'categoryId_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ShowContentDateAsc = 'showContentDate_ASC',
  ShowContentDateDesc = 'showContentDate_DESC',
  ShowPostCountAsc = 'showPostCount_ASC',
  ShowPostCountDesc = 'showPostCount_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type WhitepaperTagsCollection = {
  __typename?: 'WhitepaperTagsCollection'
  items: Array<Maybe<Tag>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum WhitepaperTagsCollectionOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TagIdAsc = 'tagId_ASC',
  TagIdDesc = 'tagId_DESC',
}

export type WhitepaperTestExcludedFirmsCollection = {
  __typename?: 'WhitepaperTestExcludedFirmsCollection'
  items: Array<Maybe<MemberFirm>>
  limit: Scalars['Int']
  skip: Scalars['Int']
  total: Scalars['Int']
}

export enum WhitepaperTestExcludedFirmsCollectionOrder {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OverrideDefaultAsc = 'overrideDefault_ASC',
  OverrideDefaultDesc = 'overrideDefault_DESC',
  ParentFirmIdAsc = 'parentFirmId_ASC',
  ParentFirmIdDesc = 'parentFirmId_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type _Node = {
  _id: Scalars['ID']
}

export type CfAccordionNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfAccordionNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfAccordionNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_not_contains?: InputMaybe<Scalars['String']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  link_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfAnnouncementNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfAnnouncementNestedFilter>>>
  Description?: InputMaybe<Scalars['String']>
  Description_contains?: InputMaybe<Scalars['String']>
  Description_exists?: InputMaybe<Scalars['Boolean']>
  Description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  Description_not?: InputMaybe<Scalars['String']>
  Description_not_contains?: InputMaybe<Scalars['String']>
  Description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  OR?: InputMaybe<Array<InputMaybe<CfAnnouncementNestedFilter>>>
  buttonText?: InputMaybe<Scalars['String']>
  buttonText_contains?: InputMaybe<Scalars['String']>
  buttonText_exists?: InputMaybe<Scalars['Boolean']>
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonText_not?: InputMaybe<Scalars['String']>
  buttonText_not_contains?: InputMaybe<Scalars['String']>
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  dateTimeEnd?: InputMaybe<Scalars['DateTime']>
  dateTimeEnd_exists?: InputMaybe<Scalars['Boolean']>
  dateTimeEnd_gt?: InputMaybe<Scalars['DateTime']>
  dateTimeEnd_gte?: InputMaybe<Scalars['DateTime']>
  dateTimeEnd_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  dateTimeEnd_lt?: InputMaybe<Scalars['DateTime']>
  dateTimeEnd_lte?: InputMaybe<Scalars['DateTime']>
  dateTimeEnd_not?: InputMaybe<Scalars['DateTime']>
  dateTimeEnd_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  dateTimeStart?: InputMaybe<Scalars['DateTime']>
  dateTimeStart_exists?: InputMaybe<Scalars['Boolean']>
  dateTimeStart_gt?: InputMaybe<Scalars['DateTime']>
  dateTimeStart_gte?: InputMaybe<Scalars['DateTime']>
  dateTimeStart_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  dateTimeStart_lt?: InputMaybe<Scalars['DateTime']>
  dateTimeStart_lte?: InputMaybe<Scalars['DateTime']>
  dateTimeStart_not?: InputMaybe<Scalars['DateTime']>
  dateTimeStart_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  destination_exists?: InputMaybe<Scalars['Boolean']>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  fundProductIds_exists?: InputMaybe<Scalars['Boolean']>
  icon?: InputMaybe<Scalars['String']>
  icon_contains?: InputMaybe<Scalars['String']>
  icon_exists?: InputMaybe<Scalars['Boolean']>
  icon_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  icon_not?: InputMaybe<Scalars['String']>
  icon_not_contains?: InputMaybe<Scalars['String']>
  icon_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  image_exists?: InputMaybe<Scalars['Boolean']>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  timezone?: InputMaybe<Scalars['String']>
  timezone_contains?: InputMaybe<Scalars['String']>
  timezone_exists?: InputMaybe<Scalars['Boolean']>
  timezone_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  timezone_not?: InputMaybe<Scalars['String']>
  timezone_not_contains?: InputMaybe<Scalars['String']>
  timezone_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfCaisiqArticleDropdownNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCaisiqArticleDropdownNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfCaisiqArticleDropdownNestedFilter>>>
  body_contains?: InputMaybe<Scalars['String']>
  body_exists?: InputMaybe<Scalars['Boolean']>
  body_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_not_contains?: InputMaybe<Scalars['String']>
}

export type CfCaisiqArticleNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCaisiqArticleNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfCaisiqArticleNestedFilter>>>
  accordionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  author_exists?: InputMaybe<Scalars['Boolean']>
  authorsCollection_exists?: InputMaybe<Scalars['Boolean']>
  body_contains?: InputMaybe<Scalars['String']>
  body_exists?: InputMaybe<Scalars['Boolean']>
  body_not_contains?: InputMaybe<Scalars['String']>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  caisVideo_exists?: InputMaybe<Scalars['Boolean']>
  callToAction_exists?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['String']>
  category_contains?: InputMaybe<Scalars['String']>
  category_exists?: InputMaybe<Scalars['Boolean']>
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category_not?: InputMaybe<Scalars['String']>
  category_not_contains?: InputMaybe<Scalars['String']>
  category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayDate?: InputMaybe<Scalars['DateTime']>
  displayDate_exists?: InputMaybe<Scalars['Boolean']>
  displayDate_gt?: InputMaybe<Scalars['DateTime']>
  displayDate_gte?: InputMaybe<Scalars['DateTime']>
  displayDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  displayDate_lt?: InputMaybe<Scalars['DateTime']>
  displayDate_lte?: InputMaybe<Scalars['DateTime']>
  displayDate_not?: InputMaybe<Scalars['DateTime']>
  displayDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  footnotes_contains?: InputMaybe<Scalars['String']>
  footnotes_exists?: InputMaybe<Scalars['Boolean']>
  footnotes_not_contains?: InputMaybe<Scalars['String']>
  fundProductIds_exists?: InputMaybe<Scalars['Boolean']>
  hero_exists?: InputMaybe<Scalars['Boolean']>
  introduction?: InputMaybe<Scalars['String']>
  introductionRichText_contains?: InputMaybe<Scalars['String']>
  introductionRichText_exists?: InputMaybe<Scalars['Boolean']>
  introductionRichText_not_contains?: InputMaybe<Scalars['String']>
  introduction_contains?: InputMaybe<Scalars['String']>
  introduction_exists?: InputMaybe<Scalars['Boolean']>
  introduction_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  introduction_not?: InputMaybe<Scalars['String']>
  introduction_not_contains?: InputMaybe<Scalars['String']>
  introduction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  pwsCategoryCollection_exists?: InputMaybe<Scalars['Boolean']>
  relatedContent_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tagsCollection_exists?: InputMaybe<Scalars['Boolean']>
  testBodyWithEntryLinks_contains?: InputMaybe<Scalars['String']>
  testBodyWithEntryLinks_exists?: InputMaybe<Scalars['Boolean']>
  testBodyWithEntryLinks_not_contains?: InputMaybe<Scalars['String']>
  testExcludedFirmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  thumbnail_exists?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfCaisiqAuthorNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCaisiqAuthorNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfCaisiqAuthorNestedFilter>>>
  authorName?: InputMaybe<Scalars['String']>
  authorName_contains?: InputMaybe<Scalars['String']>
  authorName_exists?: InputMaybe<Scalars['Boolean']>
  authorName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  authorName_not?: InputMaybe<Scalars['String']>
  authorName_not_contains?: InputMaybe<Scalars['String']>
  authorName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  avatar_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  firm?: InputMaybe<Scalars['String']>
  firm_contains?: InputMaybe<Scalars['String']>
  firm_exists?: InputMaybe<Scalars['Boolean']>
  firm_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firm_not?: InputMaybe<Scalars['String']>
  firm_not_contains?: InputMaybe<Scalars['String']>
  firm_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  imageSet_exists?: InputMaybe<Scalars['Boolean']>
  jobTitle?: InputMaybe<Scalars['String']>
  jobTitle_contains?: InputMaybe<Scalars['String']>
  jobTitle_exists?: InputMaybe<Scalars['Boolean']>
  jobTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  jobTitle_not?: InputMaybe<Scalars['String']>
  jobTitle_not_contains?: InputMaybe<Scalars['String']>
  jobTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  linkedin_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CfCaisiqFundThumbnailNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCaisiqFundThumbnailNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfCaisiqFundThumbnailNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  fundType?: InputMaybe<Scalars['String']>
  fundType_contains?: InputMaybe<Scalars['String']>
  fundType_exists?: InputMaybe<Scalars['Boolean']>
  fundType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fundType_not?: InputMaybe<Scalars['String']>
  fundType_not_contains?: InputMaybe<Scalars['String']>
  fundType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  large_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  small_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type CfCaisiqHeroHeaderNavigateToButtonNestedFilter = {
  AND?: InputMaybe<
    Array<InputMaybe<CfCaisiqHeroHeaderNavigateToButtonNestedFilter>>
  >
  OR?: InputMaybe<
    Array<InputMaybe<CfCaisiqHeroHeaderNavigateToButtonNestedFilter>>
  >
  buttonText?: InputMaybe<Scalars['String']>
  buttonText_contains?: InputMaybe<Scalars['String']>
  buttonText_exists?: InputMaybe<Scalars['Boolean']>
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonText_not?: InputMaybe<Scalars['String']>
  buttonText_not_contains?: InputMaybe<Scalars['String']>
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  navigateDestination?: InputMaybe<Scalars['String']>
  navigateDestination_contains?: InputMaybe<Scalars['String']>
  navigateDestination_exists?: InputMaybe<Scalars['Boolean']>
  navigateDestination_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  navigateDestination_not?: InputMaybe<Scalars['String']>
  navigateDestination_not_contains?: InputMaybe<Scalars['String']>
  navigateDestination_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CfCaisiqHeroHeaderNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCaisiqHeroHeaderNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfCaisiqHeroHeaderNestedFilter>>>
  body_contains?: InputMaybe<Scalars['String']>
  body_exists?: InputMaybe<Scalars['Boolean']>
  body_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  identifier?: InputMaybe<Scalars['String']>
  identifier_contains?: InputMaybe<Scalars['String']>
  identifier_exists?: InputMaybe<Scalars['Boolean']>
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  identifier_not?: InputMaybe<Scalars['String']>
  identifier_not_contains?: InputMaybe<Scalars['String']>
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CfCaisiqHomeOfficeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCaisiqHomeOfficeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfCaisiqHomeOfficeNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  disclaimer_exists?: InputMaybe<Scalars['Boolean']>
  hasSeparator?: InputMaybe<Scalars['Boolean']>
  hasSeparator_exists?: InputMaybe<Scalars['Boolean']>
  hasSeparator_not?: InputMaybe<Scalars['Boolean']>
  label?: InputMaybe<Scalars['String']>
  label_contains?: InputMaybe<Scalars['String']>
  label_exists?: InputMaybe<Scalars['Boolean']>
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  label_not?: InputMaybe<Scalars['String']>
  label_not_contains?: InputMaybe<Scalars['String']>
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  path?: InputMaybe<Scalars['String']>
  path_contains?: InputMaybe<Scalars['String']>
  path_exists?: InputMaybe<Scalars['Boolean']>
  path_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  path_not?: InputMaybe<Scalars['String']>
  path_not_contains?: InputMaybe<Scalars['String']>
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CfCaisiqJoinCaisCallToActionNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCaisiqJoinCaisCallToActionNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfCaisiqJoinCaisCallToActionNestedFilter>>>
  buttonText?: InputMaybe<Scalars['String']>
  buttonText_contains?: InputMaybe<Scalars['String']>
  buttonText_exists?: InputMaybe<Scalars['Boolean']>
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonText_not?: InputMaybe<Scalars['String']>
  buttonText_not_contains?: InputMaybe<Scalars['String']>
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  destination?: InputMaybe<Scalars['String']>
  destination_contains?: InputMaybe<Scalars['String']>
  destination_exists?: InputMaybe<Scalars['Boolean']>
  destination_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  destination_not?: InputMaybe<Scalars['String']>
  destination_not_contains?: InputMaybe<Scalars['String']>
  destination_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfCaisiqTabNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCaisiqTabNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfCaisiqTabNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  disclaimer_exists?: InputMaybe<Scalars['Boolean']>
  hasSeparator?: InputMaybe<Scalars['Boolean']>
  hasSeparator_exists?: InputMaybe<Scalars['Boolean']>
  hasSeparator_not?: InputMaybe<Scalars['Boolean']>
  label?: InputMaybe<Scalars['String']>
  label_contains?: InputMaybe<Scalars['String']>
  label_exists?: InputMaybe<Scalars['Boolean']>
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  label_not?: InputMaybe<Scalars['String']>
  label_not_contains?: InputMaybe<Scalars['String']>
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  path?: InputMaybe<Scalars['String']>
  path_contains?: InputMaybe<Scalars['String']>
  path_exists?: InputMaybe<Scalars['Boolean']>
  path_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  path_not?: InputMaybe<Scalars['String']>
  path_not_contains?: InputMaybe<Scalars['String']>
  path_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CfCaisiqVideoNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCaisiqVideoNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfCaisiqVideoNestedFilter>>>
  body_contains?: InputMaybe<Scalars['String']>
  body_exists?: InputMaybe<Scalars['Boolean']>
  body_not_contains?: InputMaybe<Scalars['String']>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['String']>
  category_contains?: InputMaybe<Scalars['String']>
  category_exists?: InputMaybe<Scalars['Boolean']>
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category_not?: InputMaybe<Scalars['String']>
  category_not_contains?: InputMaybe<Scalars['String']>
  category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayDate?: InputMaybe<Scalars['DateTime']>
  displayDate_exists?: InputMaybe<Scalars['Boolean']>
  displayDate_gt?: InputMaybe<Scalars['DateTime']>
  displayDate_gte?: InputMaybe<Scalars['DateTime']>
  displayDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  displayDate_lt?: InputMaybe<Scalars['DateTime']>
  displayDate_lte?: InputMaybe<Scalars['DateTime']>
  displayDate_not?: InputMaybe<Scalars['DateTime']>
  displayDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  duration?: InputMaybe<Scalars['Float']>
  duration_exists?: InputMaybe<Scalars['Boolean']>
  duration_gt?: InputMaybe<Scalars['Float']>
  duration_gte?: InputMaybe<Scalars['Float']>
  duration_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  duration_lt?: InputMaybe<Scalars['Float']>
  duration_lte?: InputMaybe<Scalars['Float']>
  duration_not?: InputMaybe<Scalars['Float']>
  duration_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  eyebrow?: InputMaybe<Scalars['String']>
  eyebrow_contains?: InputMaybe<Scalars['String']>
  eyebrow_exists?: InputMaybe<Scalars['Boolean']>
  eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eyebrow_not?: InputMaybe<Scalars['String']>
  eyebrow_not_contains?: InputMaybe<Scalars['String']>
  eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  fundProductIds_exists?: InputMaybe<Scalars['Boolean']>
  icon_exists?: InputMaybe<Scalars['Boolean']>
  muxThumbnailTime?: InputMaybe<Scalars['Float']>
  muxThumbnailTime_exists?: InputMaybe<Scalars['Boolean']>
  muxThumbnailTime_gt?: InputMaybe<Scalars['Float']>
  muxThumbnailTime_gte?: InputMaybe<Scalars['Float']>
  muxThumbnailTime_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  muxThumbnailTime_lt?: InputMaybe<Scalars['Float']>
  muxThumbnailTime_lte?: InputMaybe<Scalars['Float']>
  muxThumbnailTime_not?: InputMaybe<Scalars['Float']>
  muxThumbnailTime_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  muxVideo_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  notes?: InputMaybe<Scalars['String']>
  notes_contains?: InputMaybe<Scalars['String']>
  notes_exists?: InputMaybe<Scalars['Boolean']>
  notes_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  notes_not?: InputMaybe<Scalars['String']>
  notes_not_contains?: InputMaybe<Scalars['String']>
  notes_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  poster_exists?: InputMaybe<Scalars['Boolean']>
  pwsCategoryCollection_exists?: InputMaybe<Scalars['Boolean']>
  summary?: InputMaybe<Scalars['String']>
  summary_contains?: InputMaybe<Scalars['String']>
  summary_exists?: InputMaybe<Scalars['Boolean']>
  summary_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  summary_not?: InputMaybe<Scalars['String']>
  summary_not_contains?: InputMaybe<Scalars['String']>
  summary_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tagsCollection_exists?: InputMaybe<Scalars['Boolean']>
  testExcludedFirmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  thumbnail_exists?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  video_exists?: InputMaybe<Scalars['Boolean']>
  wistia_exists?: InputMaybe<Scalars['Boolean']>
}

export type CfCaisiqVideoPlaylistNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCaisiqVideoPlaylistNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfCaisiqVideoPlaylistNestedFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['String']>
  category_contains?: InputMaybe<Scalars['String']>
  category_exists?: InputMaybe<Scalars['Boolean']>
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category_not?: InputMaybe<Scalars['String']>
  category_not_contains?: InputMaybe<Scalars['String']>
  category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayDate?: InputMaybe<Scalars['DateTime']>
  displayDate_exists?: InputMaybe<Scalars['Boolean']>
  displayDate_gt?: InputMaybe<Scalars['DateTime']>
  displayDate_gte?: InputMaybe<Scalars['DateTime']>
  displayDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  displayDate_lt?: InputMaybe<Scalars['DateTime']>
  displayDate_lte?: InputMaybe<Scalars['DateTime']>
  displayDate_not?: InputMaybe<Scalars['DateTime']>
  displayDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  episodic?: InputMaybe<Scalars['Boolean']>
  episodic_exists?: InputMaybe<Scalars['Boolean']>
  episodic_not?: InputMaybe<Scalars['Boolean']>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  fundProductIds_exists?: InputMaybe<Scalars['Boolean']>
  heroImage_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  summary?: InputMaybe<Scalars['String']>
  summary_contains?: InputMaybe<Scalars['String']>
  summary_exists?: InputMaybe<Scalars['Boolean']>
  summary_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  summary_not?: InputMaybe<Scalars['String']>
  summary_not_contains?: InputMaybe<Scalars['String']>
  summary_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tagsCollection_exists?: InputMaybe<Scalars['Boolean']>
  testExcludedFirmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  videosCollection_exists?: InputMaybe<Scalars['Boolean']>
}

export type CfCallToActionNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCallToActionNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfCallToActionNestedFilter>>>
  buttonText?: InputMaybe<Scalars['String']>
  buttonText_contains?: InputMaybe<Scalars['String']>
  buttonText_exists?: InputMaybe<Scalars['Boolean']>
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonText_not?: InputMaybe<Scalars['String']>
  buttonText_not_contains?: InputMaybe<Scalars['String']>
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  destination_exists?: InputMaybe<Scalars['Boolean']>
  firm_exists?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['String']>
  id_contains?: InputMaybe<Scalars['String']>
  id_exists?: InputMaybe<Scalars['Boolean']>
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_not?: InputMaybe<Scalars['String']>
  id_not_contains?: InputMaybe<Scalars['String']>
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  imageSet_exists?: InputMaybe<Scalars['Boolean']>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  mediaFile_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfCompanyAndContactInfoNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCompanyAndContactInfoNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfCompanyAndContactInfoNestedFilter>>>
  company_exists?: InputMaybe<Scalars['Boolean']>
  contactPerson_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CfCompanyNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCompanyNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfCompanyNestedFilter>>>
  about_contains?: InputMaybe<Scalars['String']>
  about_exists?: InputMaybe<Scalars['Boolean']>
  about_not_contains?: InputMaybe<Scalars['String']>
  caisVideo_exists?: InputMaybe<Scalars['Boolean']>
  colorImageSet_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  disclosure_contains?: InputMaybe<Scalars['String']>
  disclosure_exists?: InputMaybe<Scalars['Boolean']>
  disclosure_not_contains?: InputMaybe<Scalars['String']>
  displayName?: InputMaybe<Scalars['String']>
  displayName_contains?: InputMaybe<Scalars['String']>
  displayName_exists?: InputMaybe<Scalars['Boolean']>
  displayName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayName_not?: InputMaybe<Scalars['String']>
  displayName_not_contains?: InputMaybe<Scalars['String']>
  displayName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  imageSet_exists?: InputMaybe<Scalars['Boolean']>
  linkedin_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  shortDescription_contains?: InputMaybe<Scalars['String']>
  shortDescription_exists?: InputMaybe<Scalars['Boolean']>
  shortDescription_not_contains?: InputMaybe<Scalars['String']>
  stats_exists?: InputMaybe<Scalars['Boolean']>
  subtitle?: InputMaybe<Scalars['String']>
  subtitle_contains?: InputMaybe<Scalars['String']>
  subtitle_exists?: InputMaybe<Scalars['Boolean']>
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subtitle_not?: InputMaybe<Scalars['String']>
  subtitle_not_contains?: InputMaybe<Scalars['String']>
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  twitter_exists?: InputMaybe<Scalars['Boolean']>
  website_exists?: InputMaybe<Scalars['Boolean']>
}

export type CfComponentHeroNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfComponentHeroNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfComponentHeroNestedFilter>>>
  background?: InputMaybe<Scalars['String']>
  background_contains?: InputMaybe<Scalars['String']>
  background_exists?: InputMaybe<Scalars['Boolean']>
  background_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  background_not?: InputMaybe<Scalars['String']>
  background_not_contains?: InputMaybe<Scalars['String']>
  background_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  buttonLink_exists?: InputMaybe<Scalars['Boolean']>
  caisVideo_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  eyebrow?: InputMaybe<Scalars['String']>
  eyebrow_contains?: InputMaybe<Scalars['String']>
  eyebrow_exists?: InputMaybe<Scalars['Boolean']>
  eyebrow_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  eyebrow_not?: InputMaybe<Scalars['String']>
  eyebrow_not_contains?: InputMaybe<Scalars['String']>
  eyebrow_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  icon_exists?: InputMaybe<Scalars['Boolean']>
  imageSet_exists?: InputMaybe<Scalars['Boolean']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subtitle?: InputMaybe<Scalars['String']>
  subtitle_contains?: InputMaybe<Scalars['String']>
  subtitle_exists?: InputMaybe<Scalars['Boolean']>
  subtitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  subtitle_not?: InputMaybe<Scalars['String']>
  subtitle_not_contains?: InputMaybe<Scalars['String']>
  subtitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  text_contains?: InputMaybe<Scalars['String']>
  text_exists?: InputMaybe<Scalars['Boolean']>
  text_not_contains?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  variant?: InputMaybe<Scalars['String']>
  variant_contains?: InputMaybe<Scalars['String']>
  variant_exists?: InputMaybe<Scalars['Boolean']>
  variant_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  variant_not?: InputMaybe<Scalars['String']>
  variant_not_contains?: InputMaybe<Scalars['String']>
  variant_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfComponentImageNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfComponentImageNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfComponentImageNestedFilter>>>
  altText?: InputMaybe<Scalars['String']>
  altText_contains?: InputMaybe<Scalars['String']>
  altText_exists?: InputMaybe<Scalars['Boolean']>
  altText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  altText_not?: InputMaybe<Scalars['String']>
  altText_not_contains?: InputMaybe<Scalars['String']>
  altText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  cloudinaryImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  image_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfCorporateStatsNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCorporateStatsNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfCorporateStatsNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  footnotes_exists?: InputMaybe<Scalars['Boolean']>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  stats_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type CfCuratedFilterGroupNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfCuratedFilterGroupNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfCuratedFilterGroupNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  filtersCollection_exists?: InputMaybe<Scalars['Boolean']>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  label?: InputMaybe<Scalars['String']>
  label_contains?: InputMaybe<Scalars['String']>
  label_exists?: InputMaybe<Scalars['Boolean']>
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  label_not?: InputMaybe<Scalars['String']>
  label_not_contains?: InputMaybe<Scalars['String']>
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CfDisclaimerNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfDisclaimerNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfDisclaimerNestedFilter>>>
  body_contains?: InputMaybe<Scalars['String']>
  body_exists?: InputMaybe<Scalars['Boolean']>
  body_not_contains?: InputMaybe<Scalars['String']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  identifier?: InputMaybe<Scalars['String']>
  identifier_contains?: InputMaybe<Scalars['String']>
  identifier_exists?: InputMaybe<Scalars['Boolean']>
  identifier_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  identifier_not?: InputMaybe<Scalars['String']>
  identifier_not_contains?: InputMaybe<Scalars['String']>
  identifier_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfFaqNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFaqNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfFaqNestedFilter>>>
  answer?: InputMaybe<Scalars['String']>
  answer_contains?: InputMaybe<Scalars['String']>
  answer_exists?: InputMaybe<Scalars['Boolean']>
  answer_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  answer_not?: InputMaybe<Scalars['String']>
  answer_not_contains?: InputMaybe<Scalars['String']>
  answer_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  faq?: InputMaybe<Scalars['String']>
  faq_contains?: InputMaybe<Scalars['String']>
  faq_exists?: InputMaybe<Scalars['Boolean']>
  faq_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  faq_not?: InputMaybe<Scalars['String']>
  faq_not_contains?: InputMaybe<Scalars['String']>
  faq_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id?: InputMaybe<Scalars['String']>
  id_contains?: InputMaybe<Scalars['String']>
  id_exists?: InputMaybe<Scalars['Boolean']>
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_not?: InputMaybe<Scalars['String']>
  id_not_contains?: InputMaybe<Scalars['String']>
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CfFundProductIdNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfFundProductIdNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfFundProductIdNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fundProductIds_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fundProductIds_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >
  fundProductIds_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >
  fundProductIds_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfHomepageContentSectionNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfHomepageContentSectionNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfHomepageContentSectionNestedFilter>>>
  bookmarkId?: InputMaybe<Scalars['String']>
  bookmarkId_contains?: InputMaybe<Scalars['String']>
  bookmarkId_exists?: InputMaybe<Scalars['Boolean']>
  bookmarkId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  bookmarkId_not?: InputMaybe<Scalars['String']>
  bookmarkId_not_contains?: InputMaybe<Scalars['String']>
  bookmarkId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  itemsCollection_exists?: InputMaybe<Scalars['Boolean']>
  removeTopPadding?: InputMaybe<Scalars['Boolean']>
  removeTopPadding_exists?: InputMaybe<Scalars['Boolean']>
  removeTopPadding_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type?: InputMaybe<Scalars['String']>
  type_contains?: InputMaybe<Scalars['String']>
  type_exists?: InputMaybe<Scalars['Boolean']>
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type_not?: InputMaybe<Scalars['String']>
  type_not_contains?: InputMaybe<Scalars['String']>
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfLinkNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfLinkNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfLinkNestedFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['String']>
  category_contains?: InputMaybe<Scalars['String']>
  category_exists?: InputMaybe<Scalars['Boolean']>
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category_not?: InputMaybe<Scalars['String']>
  category_not_contains?: InputMaybe<Scalars['String']>
  category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  cmsLink_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalLink?: InputMaybe<Scalars['String']>
  externalLink_contains?: InputMaybe<Scalars['String']>
  externalLink_exists?: InputMaybe<Scalars['Boolean']>
  externalLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  externalLink_not?: InputMaybe<Scalars['String']>
  externalLink_not_contains?: InputMaybe<Scalars['String']>
  externalLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  internalLink?: InputMaybe<Scalars['String']>
  internalLink_contains?: InputMaybe<Scalars['String']>
  internalLink_exists?: InputMaybe<Scalars['Boolean']>
  internalLink_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalLink_not?: InputMaybe<Scalars['String']>
  internalLink_not_contains?: InputMaybe<Scalars['String']>
  internalLink_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  openInNewWindow?: InputMaybe<Scalars['Boolean']>
  openInNewWindow_exists?: InputMaybe<Scalars['Boolean']>
  openInNewWindow_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  text?: InputMaybe<Scalars['String']>
  text_contains?: InputMaybe<Scalars['String']>
  text_exists?: InputMaybe<Scalars['Boolean']>
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  text_not?: InputMaybe<Scalars['String']>
  text_not_contains?: InputMaybe<Scalars['String']>
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  thumbnail_exists?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfLoginScreenNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfLoginScreenNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfLoginScreenNestedFilter>>>
  backgroundImage_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  countdownDatetime?: InputMaybe<Scalars['DateTime']>
  countdownDatetime_exists?: InputMaybe<Scalars['Boolean']>
  countdownDatetime_gt?: InputMaybe<Scalars['DateTime']>
  countdownDatetime_gte?: InputMaybe<Scalars['DateTime']>
  countdownDatetime_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  countdownDatetime_lt?: InputMaybe<Scalars['DateTime']>
  countdownDatetime_lte?: InputMaybe<Scalars['DateTime']>
  countdownDatetime_not?: InputMaybe<Scalars['DateTime']>
  countdownDatetime_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  footerText?: InputMaybe<Scalars['String']>
  footerText_contains?: InputMaybe<Scalars['String']>
  footerText_exists?: InputMaybe<Scalars['Boolean']>
  footerText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  footerText_not?: InputMaybe<Scalars['String']>
  footerText_not_contains?: InputMaybe<Scalars['String']>
  footerText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  intro?: InputMaybe<Scalars['String']>
  intro_contains?: InputMaybe<Scalars['String']>
  intro_exists?: InputMaybe<Scalars['Boolean']>
  intro_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  intro_not?: InputMaybe<Scalars['String']>
  intro_not_contains?: InputMaybe<Scalars['String']>
  intro_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  logoPosition?: InputMaybe<Scalars['String']>
  logoPosition_contains?: InputMaybe<Scalars['String']>
  logoPosition_exists?: InputMaybe<Scalars['Boolean']>
  logoPosition_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  logoPosition_not?: InputMaybe<Scalars['String']>
  logoPosition_not_contains?: InputMaybe<Scalars['String']>
  logoPosition_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  logo_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfMarkdownNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfMarkdownNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfMarkdownNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  text?: InputMaybe<Scalars['String']>
  text_contains?: InputMaybe<Scalars['String']>
  text_exists?: InputMaybe<Scalars['Boolean']>
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  text_not?: InputMaybe<Scalars['String']>
  text_not_contains?: InputMaybe<Scalars['String']>
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type?: InputMaybe<Scalars['String']>
  type_contains?: InputMaybe<Scalars['String']>
  type_exists?: InputMaybe<Scalars['Boolean']>
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type_not?: InputMaybe<Scalars['String']>
  type_not_contains?: InputMaybe<Scalars['String']>
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfMemberFirmNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfMemberFirmNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfMemberFirmNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  id?: InputMaybe<Scalars['String']>
  id_contains?: InputMaybe<Scalars['String']>
  id_exists?: InputMaybe<Scalars['Boolean']>
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  id_not?: InputMaybe<Scalars['String']>
  id_not_contains?: InputMaybe<Scalars['String']>
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  optionsCollection_exists?: InputMaybe<Scalars['Boolean']>
  overrideDefault?: InputMaybe<Scalars['Boolean']>
  overrideDefault_exists?: InputMaybe<Scalars['Boolean']>
  overrideDefault_not?: InputMaybe<Scalars['Boolean']>
  parentFirmId?: InputMaybe<Scalars['String']>
  parentFirmId_contains?: InputMaybe<Scalars['String']>
  parentFirmId_exists?: InputMaybe<Scalars['Boolean']>
  parentFirmId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  parentFirmId_not?: InputMaybe<Scalars['String']>
  parentFirmId_not_contains?: InputMaybe<Scalars['String']>
  parentFirmId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CfNavHeaderThemeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfNavHeaderThemeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfNavHeaderThemeNestedFilter>>>
  backgroundColor?: InputMaybe<Scalars['String']>
  backgroundColor_contains?: InputMaybe<Scalars['String']>
  backgroundColor_exists?: InputMaybe<Scalars['Boolean']>
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  backgroundColor_not?: InputMaybe<Scalars['String']>
  backgroundColor_not_contains?: InputMaybe<Scalars['String']>
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  clientLogo_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  darkAppLogo?: InputMaybe<Scalars['Boolean']>
  darkAppLogo_exists?: InputMaybe<Scalars['Boolean']>
  darkAppLogo_not?: InputMaybe<Scalars['Boolean']>
  foregroundColor?: InputMaybe<Scalars['String']>
  foregroundColor_contains?: InputMaybe<Scalars['String']>
  foregroundColor_exists?: InputMaybe<Scalars['Boolean']>
  foregroundColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  foregroundColor_not?: InputMaybe<Scalars['String']>
  foregroundColor_not_contains?: InputMaybe<Scalars['String']>
  foregroundColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  poweredBy?: InputMaybe<Scalars['Boolean']>
  poweredBy_exists?: InputMaybe<Scalars['Boolean']>
  poweredBy_not?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  underlineColor?: InputMaybe<Scalars['String']>
  underlineColor_contains?: InputMaybe<Scalars['String']>
  underlineColor_exists?: InputMaybe<Scalars['Boolean']>
  underlineColor_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  underlineColor_not?: InputMaybe<Scalars['String']>
  underlineColor_not_contains?: InputMaybe<Scalars['String']>
  underlineColor_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  underlineOpacity?: InputMaybe<Scalars['Float']>
  underlineOpacity_exists?: InputMaybe<Scalars['Boolean']>
  underlineOpacity_gt?: InputMaybe<Scalars['Float']>
  underlineOpacity_gte?: InputMaybe<Scalars['Float']>
  underlineOpacity_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
  underlineOpacity_lt?: InputMaybe<Scalars['Float']>
  underlineOpacity_lte?: InputMaybe<Scalars['Float']>
  underlineOpacity_not?: InputMaybe<Scalars['Float']>
  underlineOpacity_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>
}

export type CfPageNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPageNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfPageNestedFilter>>>
  content_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  seo_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfPagePwsCategoryNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPagePwsCategoryNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfPagePwsCategoryNestedFilter>>>
  categoryId?: InputMaybe<Scalars['String']>
  categoryId_contains?: InputMaybe<Scalars['String']>
  categoryId_exists?: InputMaybe<Scalars['Boolean']>
  categoryId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  categoryId_not?: InputMaybe<Scalars['String']>
  categoryId_not_contains?: InputMaybe<Scalars['String']>
  categoryId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  featuredItem_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  showContentDate?: InputMaybe<Scalars['Boolean']>
  showContentDate_exists?: InputMaybe<Scalars['Boolean']>
  showContentDate_not?: InputMaybe<Scalars['Boolean']>
  showPostCount?: InputMaybe<Scalars['Boolean']>
  showPostCount_exists?: InputMaybe<Scalars['Boolean']>
  showPostCount_not?: InputMaybe<Scalars['Boolean']>
  summary_contains?: InputMaybe<Scalars['String']>
  summary_exists?: InputMaybe<Scalars['Boolean']>
  summary_not_contains?: InputMaybe<Scalars['String']>
  sys?: InputMaybe<SysFilter>
}

export type CfPdfNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPdfNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfPdfNestedFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayDate?: InputMaybe<Scalars['DateTime']>
  displayDate_exists?: InputMaybe<Scalars['Boolean']>
  displayDate_gt?: InputMaybe<Scalars['DateTime']>
  displayDate_gte?: InputMaybe<Scalars['DateTime']>
  displayDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  displayDate_lt?: InputMaybe<Scalars['DateTime']>
  displayDate_lte?: InputMaybe<Scalars['DateTime']>
  displayDate_not?: InputMaybe<Scalars['DateTime']>
  displayDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  image_exists?: InputMaybe<Scalars['Boolean']>
  pdf_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfPersonNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfPersonNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfPersonNestedFilter>>>
  bio_contains?: InputMaybe<Scalars['String']>
  bio_exists?: InputMaybe<Scalars['Boolean']>
  bio_not_contains?: InputMaybe<Scalars['String']>
  callToAction_exists?: InputMaybe<Scalars['Boolean']>
  company_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  email?: InputMaybe<Scalars['String']>
  email_contains?: InputMaybe<Scalars['String']>
  email_exists?: InputMaybe<Scalars['Boolean']>
  email_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  email_not?: InputMaybe<Scalars['String']>
  email_not_contains?: InputMaybe<Scalars['String']>
  email_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fullName?: InputMaybe<Scalars['String']>
  fullName_contains?: InputMaybe<Scalars['String']>
  fullName_exists?: InputMaybe<Scalars['Boolean']>
  fullName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  fullName_not?: InputMaybe<Scalars['String']>
  fullName_not_contains?: InputMaybe<Scalars['String']>
  fullName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  imageSet_exists?: InputMaybe<Scalars['Boolean']>
  linkedin_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  position?: InputMaybe<Scalars['String']>
  position_contains?: InputMaybe<Scalars['String']>
  position_exists?: InputMaybe<Scalars['Boolean']>
  position_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  position_not?: InputMaybe<Scalars['String']>
  position_not_contains?: InputMaybe<Scalars['String']>
  position_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  thumbnail_exists?: InputMaybe<Scalars['Boolean']>
  twitter_exists?: InputMaybe<Scalars['Boolean']>
  videosCollection_exists?: InputMaybe<Scalars['Boolean']>
}

export type CfRelatedContentNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfRelatedContentNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfRelatedContentNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  ctaLink_exists?: InputMaybe<Scalars['Boolean']>
  ctaText?: InputMaybe<Scalars['String']>
  ctaText_contains?: InputMaybe<Scalars['String']>
  ctaText_exists?: InputMaybe<Scalars['Boolean']>
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  ctaText_not?: InputMaybe<Scalars['String']>
  ctaText_not_contains?: InputMaybe<Scalars['String']>
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_not_contains?: InputMaybe<Scalars['String']>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  strategiesCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfRelatedContentStrategyNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfRelatedContentStrategyNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfRelatedContentStrategyNestedFilter>>>
  authorsCollection_exists?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  itemsCollection_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sorting?: InputMaybe<Scalars['String']>
  sorting_contains?: InputMaybe<Scalars['String']>
  sorting_exists?: InputMaybe<Scalars['Boolean']>
  sorting_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sorting_not?: InputMaybe<Scalars['String']>
  sorting_not_contains?: InputMaybe<Scalars['String']>
  sorting_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  source?: InputMaybe<Scalars['String']>
  source_contains?: InputMaybe<Scalars['String']>
  source_exists?: InputMaybe<Scalars['Boolean']>
  source_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  source_not?: InputMaybe<Scalars['String']>
  source_not_contains?: InputMaybe<Scalars['String']>
  source_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tagsCollection_exists?: InputMaybe<Scalars['Boolean']>
}

export type CfSeoNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfSeoNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfSeoNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description?: InputMaybe<Scalars['String']>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  description_not?: InputMaybe<Scalars['String']>
  description_not_contains?: InputMaybe<Scalars['String']>
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  keywords_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  keywords_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  keywords_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  keywords_exists?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  no_follow?: InputMaybe<Scalars['Boolean']>
  no_follow_exists?: InputMaybe<Scalars['Boolean']>
  no_follow_not?: InputMaybe<Scalars['Boolean']>
  no_index?: InputMaybe<Scalars['Boolean']>
  no_index_exists?: InputMaybe<Scalars['Boolean']>
  no_index_not?: InputMaybe<Scalars['Boolean']>
  openGraphImage_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfTagFamilyNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfTagFamilyNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfTagFamilyNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tagFamilyType?: InputMaybe<Scalars['String']>
  tagFamilyType_contains?: InputMaybe<Scalars['String']>
  tagFamilyType_exists?: InputMaybe<Scalars['Boolean']>
  tagFamilyType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  tagFamilyType_not?: InputMaybe<Scalars['String']>
  tagFamilyType_not_contains?: InputMaybe<Scalars['String']>
  tagFamilyType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  tagsCollection_exists?: InputMaybe<Scalars['Boolean']>
}

export type CfTagNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfTagNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfTagNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  label?: InputMaybe<Scalars['String']>
  label_contains?: InputMaybe<Scalars['String']>
  label_exists?: InputMaybe<Scalars['Boolean']>
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  label_not?: InputMaybe<Scalars['String']>
  label_not_contains?: InputMaybe<Scalars['String']>
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tagId?: InputMaybe<Scalars['String']>
  tagId_contains?: InputMaybe<Scalars['String']>
  tagId_exists?: InputMaybe<Scalars['Boolean']>
  tagId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  tagId_not?: InputMaybe<Scalars['String']>
  tagId_not_contains?: InputMaybe<Scalars['String']>
  tagId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfTeamNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfTeamNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfTeamNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  description_contains?: InputMaybe<Scalars['String']>
  description_exists?: InputMaybe<Scalars['Boolean']>
  description_not_contains?: InputMaybe<Scalars['String']>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  membersCollection_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfThemeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfThemeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfThemeNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  navHeaderForAdmin_exists?: InputMaybe<Scalars['Boolean']>
  navHeaderWithHero_exists?: InputMaybe<Scalars['Boolean']>
  navHeaderWithIframe_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
}

export type CfWhitepaperNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfWhitepaperNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfWhitepaperNestedFilter>>>
  author_exists?: InputMaybe<Scalars['Boolean']>
  authorsCollection_exists?: InputMaybe<Scalars['Boolean']>
  body_contains?: InputMaybe<Scalars['String']>
  body_exists?: InputMaybe<Scalars['Boolean']>
  body_not_contains?: InputMaybe<Scalars['String']>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  callToAction_exists?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['String']>
  category_contains?: InputMaybe<Scalars['String']>
  category_exists?: InputMaybe<Scalars['Boolean']>
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category_not?: InputMaybe<Scalars['String']>
  category_not_contains?: InputMaybe<Scalars['String']>
  category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  displayDate?: InputMaybe<Scalars['DateTime']>
  displayDate_exists?: InputMaybe<Scalars['Boolean']>
  displayDate_gt?: InputMaybe<Scalars['DateTime']>
  displayDate_gte?: InputMaybe<Scalars['DateTime']>
  displayDate_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  displayDate_lt?: InputMaybe<Scalars['DateTime']>
  displayDate_lte?: InputMaybe<Scalars['DateTime']>
  displayDate_not?: InputMaybe<Scalars['DateTime']>
  displayDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  footnotes_contains?: InputMaybe<Scalars['String']>
  footnotes_exists?: InputMaybe<Scalars['Boolean']>
  footnotes_not_contains?: InputMaybe<Scalars['String']>
  fundProductIds_exists?: InputMaybe<Scalars['Boolean']>
  hero_exists?: InputMaybe<Scalars['Boolean']>
  introduction?: InputMaybe<Scalars['String']>
  introduction_contains?: InputMaybe<Scalars['String']>
  introduction_exists?: InputMaybe<Scalars['Boolean']>
  introduction_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  introduction_not?: InputMaybe<Scalars['String']>
  introduction_not_contains?: InputMaybe<Scalars['String']>
  introduction_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  pdfsCollection_exists?: InputMaybe<Scalars['Boolean']>
  pwsCategoryCollection_exists?: InputMaybe<Scalars['Boolean']>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  tagsCollection_exists?: InputMaybe<Scalars['Boolean']>
  testExcludedFirmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  thumbnail_exists?: InputMaybe<Scalars['Boolean']>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type?: InputMaybe<Scalars['String']>
  type_contains?: InputMaybe<Scalars['String']>
  type_exists?: InputMaybe<Scalars['Boolean']>
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  type_not?: InputMaybe<Scalars['String']>
  type_not_contains?: InputMaybe<Scalars['String']>
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfbodyMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfbodyMultiTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfbodyMultiTypeNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfchildFirmsMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfchildFirmsMultiTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfchildFirmsMultiTypeNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  firmId?: InputMaybe<Scalars['String']>
  firmId_contains?: InputMaybe<Scalars['String']>
  firmId_exists?: InputMaybe<Scalars['Boolean']>
  firmId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmId_not?: InputMaybe<Scalars['String']>
  firmId_not_contains?: InputMaybe<Scalars['String']>
  firmId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmName?: InputMaybe<Scalars['String']>
  firmName_contains?: InputMaybe<Scalars['String']>
  firmName_exists?: InputMaybe<Scalars['Boolean']>
  firmName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmName_not?: InputMaybe<Scalars['String']>
  firmName_not_contains?: InputMaybe<Scalars['String']>
  firmName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName?: InputMaybe<Scalars['String']>
  internalName_contains?: InputMaybe<Scalars['String']>
  internalName_exists?: InputMaybe<Scalars['Boolean']>
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  internalName_not?: InputMaybe<Scalars['String']>
  internalName_not_contains?: InputMaybe<Scalars['String']>
  internalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
}

export type CfcontentMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfcontentMultiTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfcontentMultiTypeNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfhomepageDefaultMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfhomepageDefaultMultiTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfhomepageDefaultMultiTypeNestedFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfhomepageOptionsMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfhomepageOptionsMultiTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfhomepageOptionsMultiTypeNestedFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfitemsMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfitemsMultiTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfitemsMultiTypeNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  name?: InputMaybe<Scalars['String']>
  name_contains?: InputMaybe<Scalars['String']>
  name_exists?: InputMaybe<Scalars['Boolean']>
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  name_not?: InputMaybe<Scalars['String']>
  name_not_contains?: InputMaybe<Scalars['String']>
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug?: InputMaybe<Scalars['String']>
  slug_contains?: InputMaybe<Scalars['String']>
  slug_exists?: InputMaybe<Scalars['Boolean']>
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  slug_not?: InputMaybe<Scalars['String']>
  slug_not_contains?: InputMaybe<Scalars['String']>
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfoptionsMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfoptionsMultiTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfoptionsMultiTypeNestedFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfresearchDefaultMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfresearchDefaultMultiTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfresearchDefaultMultiTypeNestedFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['String']>
  category_contains?: InputMaybe<Scalars['String']>
  category_exists?: InputMaybe<Scalars['Boolean']>
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category_not?: InputMaybe<Scalars['String']>
  category_not_contains?: InputMaybe<Scalars['String']>
  category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfresearchOptionsMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfresearchOptionsMultiTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfresearchOptionsMultiTypeNestedFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['String']>
  category_contains?: InputMaybe<Scalars['String']>
  category_exists?: InputMaybe<Scalars['Boolean']>
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category_not?: InputMaybe<Scalars['String']>
  category_not_contains?: InputMaybe<Scalars['String']>
  category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfsectionsMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfsectionsMultiTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfsectionsMultiTypeNestedFilter>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  sys?: InputMaybe<SysFilter>
}

export type CftutorialsDefaultMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CftutorialsDefaultMultiTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CftutorialsDefaultMultiTypeNestedFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['String']>
  category_contains?: InputMaybe<Scalars['String']>
  category_exists?: InputMaybe<Scalars['Boolean']>
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category_not?: InputMaybe<Scalars['String']>
  category_not_contains?: InputMaybe<Scalars['String']>
  category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CftutorialsOptionsMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CftutorialsOptionsMultiTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CftutorialsOptionsMultiTypeNestedFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  category?: InputMaybe<Scalars['String']>
  category_contains?: InputMaybe<Scalars['String']>
  category_exists?: InputMaybe<Scalars['Boolean']>
  category_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  category_not?: InputMaybe<Scalars['String']>
  category_not_contains?: InputMaybe<Scalars['String']>
  category_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfwebinarsDefaultMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfwebinarsDefaultMultiTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfwebinarsDefaultMultiTypeNestedFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type CfwebinarsOptionsMultiTypeNestedFilter = {
  AND?: InputMaybe<Array<InputMaybe<CfwebinarsOptionsMultiTypeNestedFilter>>>
  OR?: InputMaybe<Array<InputMaybe<CfwebinarsOptionsMultiTypeNestedFilter>>>
  byCAIS?: InputMaybe<Scalars['Boolean']>
  byCAIS_exists?: InputMaybe<Scalars['Boolean']>
  byCAIS_not?: InputMaybe<Scalars['Boolean']>
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>
  customDescription?: InputMaybe<Scalars['String']>
  customDescription_contains?: InputMaybe<Scalars['String']>
  customDescription_exists?: InputMaybe<Scalars['Boolean']>
  customDescription_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customDescription_not?: InputMaybe<Scalars['String']>
  customDescription_not_contains?: InputMaybe<Scalars['String']>
  customDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle?: InputMaybe<Scalars['String']>
  customTitle_contains?: InputMaybe<Scalars['String']>
  customTitle_exists?: InputMaybe<Scalars['Boolean']>
  customTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  customTitle_not?: InputMaybe<Scalars['String']>
  customTitle_not_contains?: InputMaybe<Scalars['String']>
  customTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  firmsCollection_exists?: InputMaybe<Scalars['Boolean']>
  sys?: InputMaybe<SysFilter>
  title?: InputMaybe<Scalars['String']>
  title_contains?: InputMaybe<Scalars['String']>
  title_exists?: InputMaybe<Scalars['Boolean']>
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  title_not?: InputMaybe<Scalars['String']>
  title_not_contains?: InputMaybe<Scalars['String']>
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type GetArticleBySlugQueryVariables = Exact<{
  firmsLimit: Scalars['Int']
  slug: Scalars['String']
  preview?: InputMaybe<Scalars['Boolean']>
}>

export type GetArticleBySlugQuery = {
  __typename?: 'Query'
  caisiqArticleCollection?: {
    __typename?: 'CaisiqArticleCollection'
    total: number
    items: Array<{
      __typename: 'CaisiqArticle'
      customTitle?: string | null
      customDescription?: string | null
      introduction?: string | null
      slug?: string | null
      title?: string | null
      category?: string | null
      displayDate?: any | null
      description?: string | null
      byCais?: boolean | null
      hero?: { __typename?: 'Asset'; url?: string | null } | null
      author?: {
        __typename?: 'CaisiqAuthor'
        name?: string | null
        authorName?: string | null
        jobTitle?: string | null
        firm?: string | null
        avatar?: {
          __typename?: 'ComponentImage'
          altText?: string | null
          image?: {
            __typename?: 'Asset'
            url?: string | null
            title?: string | null
            width?: number | null
            height?: number | null
          } | null
        } | null
        linkedin?: { __typename?: 'Link'; externalLink?: string | null } | null
      } | null
      authorsCollection?: {
        __typename?: 'CaisiqArticleAuthorsCollection'
        items: Array<{
          __typename?: 'CaisiqAuthor'
          name?: string | null
          authorName?: string | null
          jobTitle?: string | null
          firm?: string | null
          imageSet?: any | null
          avatar?: {
            __typename?: 'ComponentImage'
            altText?: string | null
            image?: {
              __typename?: 'Asset'
              url?: string | null
              title?: string | null
              width?: number | null
              height?: number | null
            } | null
          } | null
          linkedin?: {
            __typename?: 'Link'
            externalLink?: string | null
          } | null
        } | null>
      } | null
      introductionRichText?: {
        __typename?: 'CaisiqArticleIntroductionRichText'
        json: any
      } | null
      tagsCollection?: {
        __typename?: 'CaisiqArticleTagsCollection'
        items: Array<{
          __typename?: 'Tag'
          tagId?: string | null
          label?: string | null
        } | null>
      } | null
      body?: {
        __typename?: 'CaisiqArticleBody'
        json: any
        links: {
          __typename?: 'CaisiqArticleBodyLinks'
          entries: {
            __typename?: 'CaisiqArticleBodyEntries'
            hyperlink: Array<
              | {
                  __typename: 'AccessControl'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AccessControl2'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AccessControl3'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Accordion'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AccordionWithImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Announcement'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AnnouncementSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqArticle'
                  slug?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqArticleDropdown'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqAuthor'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqDefaultCourseImageSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqDefaultFundsThumbnailSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqFundThumbnail'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqFundThumbnailSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqHeroHeader'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqHeroHeaderNavigateToButton'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqHomeOffice'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqJoinCaisCallToAction'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqTab'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqVideo'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqVideoPlaylist'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CallToAction'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Chart'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'ChildFirm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CiqArticle'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Company'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CompanyAndContactInfo'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentCard'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentCompanyContainer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentHero'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentHubspotForm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentPodcastPlayer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentSummary'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTeamContainer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentText'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextIllustration'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImageImageSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImageListSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImageTextSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CorporateStats'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CuratedFilterGroup'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CuratedResearchContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Disclaimer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Event'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'ExternalContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Faq'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'FaqCategory'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'FeaturedContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'FilterGroup'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Firm'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'FullWidthImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'FundProductId'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'GlossaryTerm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HomepageAppMetadata'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HomepageContentSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HomepageLandingPage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HtmlTable'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ImageSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'LegalPage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Link'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'LoginScreen'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Markdown'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'MemberFirm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Migration'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'NavHeaderTheme'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'PageHelpCenterArticle'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PageLanding'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PagePwsCategory'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PageResource'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ParentFirm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Pdf'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'Person'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Pet'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'PlaylistContentSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Presentation'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PressAward'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsContactMedia'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsInsightsContentSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsPressRelease'
                  slug?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsSiteSettings'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Quote'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'RelatedContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'RelatedContentStrategy'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Seo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'SkPlayground'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Tag'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'TagFamily'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Team'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'TestModel'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Theme'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'Whitepaper'
                  slug?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                }
              | null
            >
            block: Array<
              | {
                  __typename: 'AccessControl'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AccessControl2'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AccessControl3'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Accordion'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AccordionWithImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Announcement'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AnnouncementSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqArticle'
                  title?: string | null
                  slug?: string | null
                  displayDate?: any | null
                  author?: {
                    __typename?: 'CaisiqAuthor'
                    name?: string | null
                  } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                  } | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqArticleDropdown'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqAuthor'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqDefaultCourseImageSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqDefaultFundsThumbnailSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqFundThumbnail'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqFundThumbnailSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqHeroHeader'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqHeroHeaderNavigateToButton'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqHomeOffice'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqJoinCaisCallToAction'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqTab'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqVideo'
                  muxVideo?: any | null
                  title?: string | null
                  video?: { __typename?: 'Asset'; url?: string | null } | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqVideoPlaylist'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CallToAction'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Chart'
                  longSource?: string | null
                  title?: string | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                  chartImage?: {
                    __typename?: 'ComponentImage'
                    altText?: string | null
                    cloudinaryImage?: any | null
                    name?: string | null
                    image?: {
                      __typename?: 'Asset'
                      url?: string | null
                      height?: number | null
                      width?: number | null
                      title?: string | null
                      alt?: string | null
                    } | null
                  } | null
                  footnote?: { __typename?: 'ChartFootnote'; json: any } | null
                  longDescription?: {
                    __typename?: 'ChartLongDescription'
                    json: any
                  } | null
                }
              | {
                  __typename: 'ChildFirm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CiqArticle'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Company'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CompanyAndContactInfo'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentCard'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentCompanyContainer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentHero'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentHubspotForm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentImage'
                  cloudinaryImage?: any | null
                  name?: string | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentPodcastPlayer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentSummary'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTeamContainer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentText'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextIllustration'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImageImageSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImageListSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImageTextSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CorporateStats'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CuratedFilterGroup'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CuratedResearchContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Disclaimer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Event'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ExternalContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Faq'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FaqCategory'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FeaturedContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FilterGroup'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Firm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FullWidthImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FundProductId'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'GlossaryTerm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HomepageAppMetadata'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HomepageContentSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HomepageLandingPage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HtmlTable'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ImageSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'LegalPage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Link'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'LoginScreen'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Markdown'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'MemberFirm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Migration'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'NavHeaderTheme'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Page'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PageHelpCenterArticle'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PageLanding'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PagePwsCategory'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PageResource'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ParentFirm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Pdf'
                  title?: string | null
                  pdf?: { __typename?: 'Asset'; url?: string | null } | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Person'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Pet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PlaylistContentSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Presentation'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PressAward'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsContactMedia'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsInsightsContentSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsPressRelease'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsSiteSettings'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Quote'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'RelatedContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'RelatedContentStrategy'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Seo'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'SkPlayground'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Tag'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'TagFamily'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Team'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'TestModel'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Theme'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Whitepaper'
                  slug?: string | null
                  title?: string | null
                  displayDate?: any | null
                  author?: {
                    __typename?: 'CaisiqAuthor'
                    name?: string | null
                  } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                  } | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | null
            >
          }
          assets: {
            __typename?: 'CaisiqArticleBodyAssets'
            block: Array<{
              __typename?: 'Asset'
              url?: string | null
              title?: string | null
              width?: number | null
              height?: number | null
              description?: string | null
              fileName?: string | null
              contentType?: string | null
              sys: { __typename?: 'Sys'; id: string }
            } | null>
          }
        }
      } | null
      footnotes?: {
        __typename?: 'CaisiqArticleFootnotes'
        json: any
        links: {
          __typename?: 'CaisiqArticleFootnotesLinks'
          entries: {
            __typename?: 'CaisiqArticleFootnotesEntries'
            hyperlink: Array<
              | {
                  __typename: 'AccessControl'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AccessControl2'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AccessControl3'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Accordion'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AccordionWithImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Announcement'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AnnouncementSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqArticle'
                  slug?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqArticleDropdown'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqAuthor'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqDefaultCourseImageSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqDefaultFundsThumbnailSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqFundThumbnail'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqFundThumbnailSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqHeroHeader'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqHeroHeaderNavigateToButton'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqHomeOffice'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqJoinCaisCallToAction'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqTab'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqVideo'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqVideoPlaylist'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CallToAction'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Chart'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'ChildFirm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CiqArticle'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Company'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CompanyAndContactInfo'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentCard'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentCompanyContainer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentHero'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentHubspotForm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentPodcastPlayer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentSummary'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTeamContainer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentText'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextIllustration'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImageImageSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImageListSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImageTextSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CorporateStats'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CuratedFilterGroup'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CuratedResearchContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Disclaimer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Event'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'ExternalContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Faq'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'FaqCategory'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'FeaturedContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'FilterGroup'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Firm'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'FullWidthImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'FundProductId'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'GlossaryTerm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HomepageAppMetadata'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HomepageContentSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HomepageLandingPage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HtmlTable'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ImageSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'LegalPage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Link'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'LoginScreen'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Markdown'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'MemberFirm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Migration'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'NavHeaderTheme'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'PageHelpCenterArticle'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PageLanding'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PagePwsCategory'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PageResource'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ParentFirm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Pdf'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'Person'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Pet'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'PlaylistContentSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Presentation'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PressAward'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsContactMedia'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsInsightsContentSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsPressRelease'
                  slug?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsSiteSettings'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Quote'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'RelatedContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'RelatedContentStrategy'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Seo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'SkPlayground'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Tag'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'TagFamily'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Team'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'TestModel'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Theme'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'Whitepaper'
                  slug?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                }
              | null
            >
            block: Array<
              | {
                  __typename: 'AccessControl'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AccessControl2'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AccessControl3'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Accordion'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AccordionWithImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Announcement'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AnnouncementSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqArticle'
                  title?: string | null
                  slug?: string | null
                  displayDate?: any | null
                  author?: {
                    __typename?: 'CaisiqAuthor'
                    name?: string | null
                  } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                  } | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqArticleDropdown'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqAuthor'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqDefaultCourseImageSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqDefaultFundsThumbnailSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqFundThumbnail'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqFundThumbnailSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqHeroHeader'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqHeroHeaderNavigateToButton'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqHomeOffice'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqJoinCaisCallToAction'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqTab'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqVideo'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqVideoPlaylist'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CallToAction'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Chart'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ChildFirm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CiqArticle'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Company'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CompanyAndContactInfo'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentCard'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentCompanyContainer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentHero'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentHubspotForm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentPodcastPlayer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentSummary'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTeamContainer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentText'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextIllustration'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImageImageSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImageListSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImageTextSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CorporateStats'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CuratedFilterGroup'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CuratedResearchContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Disclaimer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Event'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ExternalContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Faq'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FaqCategory'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FeaturedContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FilterGroup'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Firm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FullWidthImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FundProductId'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'GlossaryTerm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HomepageAppMetadata'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HomepageContentSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HomepageLandingPage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HtmlTable'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ImageSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'LegalPage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Link'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'LoginScreen'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Markdown'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'MemberFirm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Migration'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'NavHeaderTheme'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Page'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PageHelpCenterArticle'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PageLanding'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PagePwsCategory'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PageResource'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ParentFirm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Pdf'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Person'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Pet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PlaylistContentSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Presentation'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PressAward'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsContactMedia'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsInsightsContentSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsPressRelease'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsSiteSettings'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Quote'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'RelatedContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'RelatedContentStrategy'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Seo'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'SkPlayground'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Tag'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'TagFamily'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Team'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'TestModel'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Theme'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Whitepaper'
                  slug?: string | null
                  title?: string | null
                  displayDate?: any | null
                  author?: {
                    __typename?: 'CaisiqAuthor'
                    name?: string | null
                  } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                  } | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | null
            >
          }
          assets: {
            __typename?: 'CaisiqArticleFootnotesAssets'
            block: Array<{
              __typename?: 'Asset'
              url?: string | null
              title?: string | null
              width?: number | null
              height?: number | null
              description?: string | null
              fileName?: string | null
              contentType?: string | null
              sys: { __typename?: 'Sys'; id: string }
            } | null>
          }
        }
      } | null
      accordionsCollection?: {
        __typename?: 'CaisiqArticleAccordionsCollection'
        items: Array<{
          __typename?: 'CaisiqArticleDropdown'
          body?: {
            __typename?: 'CaisiqArticleDropdownBody'
            json: any
            links: {
              __typename?: 'CaisiqArticleDropdownBodyLinks'
              entries: {
                __typename?: 'CaisiqArticleDropdownBodyEntries'
                hyperlink: Array<
                  | {
                      __typename: 'AccessControl'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'AccessControl2'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'AccessControl3'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Accordion'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'AccordionWithImage'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Announcement'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'AnnouncementSet'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqArticle'
                      slug?: string | null
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqArticleDropdown'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqAuthor'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqDefaultCourseImageSet'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqDefaultFundsThumbnailSet'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqFundThumbnail'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqFundThumbnailSet'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqHeroHeader'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqHeroHeaderNavigateToButton'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqHomeOffice'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqJoinCaisCallToAction'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqTab'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqVideo'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqVideoPlaylist'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CallToAction'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Chart'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ChildFirm'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CiqArticle'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Company'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CompanyAndContactInfo'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentCard'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentCompanyContainer'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentHero'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentHubspotForm'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentImage'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentPodcastPlayer'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentSection'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentSummary'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentTeamContainer'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentText'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentTextIllustration'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentTextImage'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentTextImageImageSection'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentTextImageListSection'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentTextImageTextSection'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CorporateStats'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CuratedFilterGroup'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CuratedResearchContent'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Disclaimer'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Event'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ExternalContent'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Faq'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'FaqCategory'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'FeaturedContent'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'FilterGroup'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Firm'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'FullWidthImage'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'FundProductId'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'GlossaryTerm'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'HomepageAppMetadata'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'HomepageContentSection'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'HomepageLandingPage'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'HtmlTable'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ImageSection'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'LegalPage'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Link'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'LoginScreen'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Markdown'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'MemberFirm'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Migration'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'NavHeaderTheme'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Page'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PageHelpCenterArticle'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PageLanding'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PagePwsCategory'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PageResource'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ParentFirm'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Pdf'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Person'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Pet'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PlaylistContentSet'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Presentation'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PressAward'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PwsContactMedia'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PwsInsightsContentSet'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PwsPressRelease'
                      slug?: string | null
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PwsSiteSettings'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Quote'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'RelatedContent'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'RelatedContentStrategy'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Seo'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'SkPlayground'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Tag'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'TagFamily'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Team'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'TestModel'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Theme'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Whitepaper'
                      slug?: string | null
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | null
                >
                block: Array<
                  | {
                      __typename: 'AccessControl'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'AccessControl2'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'AccessControl3'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Accordion'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'AccordionWithImage'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Announcement'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'AnnouncementSet'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'CaisiqArticle'
                      title?: string | null
                      slug?: string | null
                      displayDate?: any | null
                      author?: {
                        __typename?: 'CaisiqAuthor'
                        name?: string | null
                      } | null
                      thumbnail?: {
                        __typename?: 'Asset'
                        url?: string | null
                      } | null
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'CaisiqArticleDropdown'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'CaisiqAuthor'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'CaisiqDefaultCourseImageSet'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'CaisiqDefaultFundsThumbnailSet'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'CaisiqFundThumbnail'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'CaisiqFundThumbnailSet'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'CaisiqHeroHeader'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'CaisiqHeroHeaderNavigateToButton'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'CaisiqHomeOffice'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'CaisiqJoinCaisCallToAction'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'CaisiqTab'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'CaisiqVideo'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'CaisiqVideoPlaylist'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'CallToAction'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Chart'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'ChildFirm'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'CiqArticle'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Company'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'CompanyAndContactInfo'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'ComponentCard'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'ComponentCompanyContainer'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'ComponentHero'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'ComponentHubspotForm'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'ComponentImage'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'ComponentPodcastPlayer'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'ComponentSection'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'ComponentSummary'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'ComponentTeamContainer'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'ComponentText'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'ComponentTextIllustration'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'ComponentTextImage'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'ComponentTextImageImageSection'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'ComponentTextImageListSection'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'ComponentTextImageTextSection'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'CorporateStats'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'CuratedFilterGroup'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'CuratedResearchContent'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Disclaimer'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Event'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'ExternalContent'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Faq'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'FaqCategory'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'FeaturedContent'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'FilterGroup'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Firm'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'FullWidthImage'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'FundProductId'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'GlossaryTerm'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'HomepageAppMetadata'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'HomepageContentSection'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'HomepageLandingPage'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'HtmlTable'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'ImageSection'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'LegalPage'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Link'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'LoginScreen'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Markdown'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'MemberFirm'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Migration'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'NavHeaderTheme'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Page'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'PageHelpCenterArticle'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'PageLanding'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'PagePwsCategory'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'PageResource'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'ParentFirm'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Pdf'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Person'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Pet'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'PlaylistContentSet'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Presentation'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'PressAward'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'PwsContactMedia'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'PwsInsightsContentSet'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'PwsPressRelease'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'PwsSiteSettings'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Quote'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'RelatedContent'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'RelatedContentStrategy'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Seo'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'SkPlayground'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Tag'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'TagFamily'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Team'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'TestModel'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Theme'
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | {
                      __typename: 'Whitepaper'
                      slug?: string | null
                      title?: string | null
                      displayDate?: any | null
                      author?: {
                        __typename?: 'CaisiqAuthor'
                        name?: string | null
                      } | null
                      thumbnail?: {
                        __typename?: 'Asset'
                        url?: string | null
                      } | null
                      sys: {
                        __typename?: 'Sys'
                        id: string
                        firstPublishedAt?: any | null
                      }
                    }
                  | null
                >
              }
              assets: {
                __typename?: 'CaisiqArticleDropdownBodyAssets'
                block: Array<{
                  __typename?: 'Asset'
                  url?: string | null
                  title?: string | null
                  width?: number | null
                  height?: number | null
                  description?: string | null
                  fileName?: string | null
                  contentType?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                } | null>
              }
            }
          } | null
          title?: {
            __typename?: 'CaisiqArticleDropdownTitle'
            json: any
          } | null
        } | null>
      } | null
      callToAction?: {
        __typename: 'CallToAction'
        title?: string | null
        description?: string | null
        buttonText?: string | null
        destination?: {
          __typename?: 'Link'
          internalLink?: string | null
          externalLink?: string | null
          openInNewWindow?: boolean | null
          cmsLink?:
            | { __typename?: 'CaisiqArticle' }
            | { __typename?: 'HomepageLandingPage' }
            | {
                __typename?: 'Page'
                slug?: string | null
                content?:
                  | { __typename: 'CaisiqArticle' }
                  | { __typename: 'CaisiqVideo' }
                  | { __typename: 'CaisiqVideoPlaylist' }
                  | { __typename: 'Company' }
                  | { __typename: 'PageHelpCenterArticle' }
                  | { __typename: 'PageLanding' }
                  | { __typename: 'PagePwsCategory' }
                  | { __typename: 'Person' }
                  | { __typename: 'PwsPressRelease' }
                  | { __typename: 'Whitepaper' }
                  | null
              }
            | { __typename?: 'PageResource' }
            | { __typename?: 'PwsPressRelease' }
            | { __typename?: 'Whitepaper' }
            | null
        } | null
        mediaFile?: { __typename?: 'Asset'; url?: string | null } | null
      } | null
      caisVideo?: { __typename?: 'CaisiqVideo'; muxVideo?: any | null } | null
      sys: {
        __typename?: 'Sys'
        id: string
        firstPublishedAt?: any | null
        publishedAt?: any | null
      }
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqArticleFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
    } | null>
  } | null
}

export type ArticlePageFragment = {
  __typename: 'CaisiqArticle'
  customTitle?: string | null
  customDescription?: string | null
  introduction?: string | null
  slug?: string | null
  title?: string | null
  category?: string | null
  displayDate?: any | null
  description?: string | null
  byCais?: boolean | null
  hero?: { __typename?: 'Asset'; url?: string | null } | null
  author?: {
    __typename?: 'CaisiqAuthor'
    name?: string | null
    authorName?: string | null
    jobTitle?: string | null
    firm?: string | null
    avatar?: {
      __typename?: 'ComponentImage'
      altText?: string | null
      image?: {
        __typename?: 'Asset'
        url?: string | null
        title?: string | null
        width?: number | null
        height?: number | null
      } | null
    } | null
    linkedin?: { __typename?: 'Link'; externalLink?: string | null } | null
  } | null
  authorsCollection?: {
    __typename?: 'CaisiqArticleAuthorsCollection'
    items: Array<{
      __typename?: 'CaisiqAuthor'
      name?: string | null
      authorName?: string | null
      jobTitle?: string | null
      firm?: string | null
      imageSet?: any | null
      avatar?: {
        __typename?: 'ComponentImage'
        altText?: string | null
        image?: {
          __typename?: 'Asset'
          url?: string | null
          title?: string | null
          width?: number | null
          height?: number | null
        } | null
      } | null
      linkedin?: { __typename?: 'Link'; externalLink?: string | null } | null
    } | null>
  } | null
  introductionRichText?: {
    __typename?: 'CaisiqArticleIntroductionRichText'
    json: any
  } | null
  tagsCollection?: {
    __typename?: 'CaisiqArticleTagsCollection'
    items: Array<{
      __typename?: 'Tag'
      tagId?: string | null
      label?: string | null
    } | null>
  } | null
  body?: {
    __typename?: 'CaisiqArticleBody'
    json: any
    links: {
      __typename?: 'CaisiqArticleBodyLinks'
      entries: {
        __typename?: 'CaisiqArticleBodyEntries'
        hyperlink: Array<
          | {
              __typename: 'AccessControl'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'AccessControl2'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'AccessControl3'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Accordion'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'AccordionWithImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Announcement'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'AnnouncementSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqArticle'
              slug?: string | null
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqArticleDropdown'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqAuthor'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqDefaultCourseImageSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqDefaultFundsThumbnailSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqFundThumbnail'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqFundThumbnailSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqHeroHeader'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqHeroHeaderNavigateToButton'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqHomeOffice'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqJoinCaisCallToAction'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'CaisiqTab'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CaisiqVideo'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqVideoPlaylist'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CallToAction'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Chart'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'ChildFirm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CiqArticle'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Company'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CompanyAndContactInfo'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentCard'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentCompanyContainer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentHero'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentHubspotForm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentPodcastPlayer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentSummary'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTeamContainer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentText'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextIllustration'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImageImageSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImageListSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImageTextSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CorporateStats'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CuratedFilterGroup'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CuratedResearchContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Disclaimer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Event'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ExternalContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Faq'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FaqCategory'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'FeaturedContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'FilterGroup'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Firm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FullWidthImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'FundProductId'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'GlossaryTerm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'HomepageAppMetadata'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'HomepageContentSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'HomepageLandingPage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'HtmlTable'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ImageSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'LegalPage'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Link'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'LoginScreen'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Markdown'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'MemberFirm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Migration'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'NavHeaderTheme'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PageHelpCenterArticle'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PageLanding'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PagePwsCategory'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PageResource'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ParentFirm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Pdf'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Pet'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PlaylistContentSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Presentation'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PressAward'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsContactMedia'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsInsightsContentSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsPressRelease'
              slug?: string | null
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsSiteSettings'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Quote'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'RelatedContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'RelatedContentStrategy'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Seo'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'SkPlayground'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Tag'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'TagFamily'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Team'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'TestModel'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Theme'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'Whitepaper'
              slug?: string | null
              sys: { __typename?: 'Sys'; id: string }
            }
          | null
        >
        block: Array<
          | {
              __typename: 'AccessControl'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'AccessControl2'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'AccessControl3'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Accordion'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'AccordionWithImage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Announcement'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'AnnouncementSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqArticle'
              title?: string | null
              slug?: string | null
              displayDate?: any | null
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              thumbnail?: { __typename?: 'Asset'; url?: string | null } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqArticleDropdown'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqAuthor'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqDefaultCourseImageSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqDefaultFundsThumbnailSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqFundThumbnail'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqFundThumbnailSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqHeroHeader'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqHeroHeaderNavigateToButton'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqHomeOffice'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqJoinCaisCallToAction'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqTab'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqVideo'
              muxVideo?: any | null
              title?: string | null
              video?: { __typename?: 'Asset'; url?: string | null } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqVideoPlaylist'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CallToAction'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Chart'
              longSource?: string | null
              title?: string | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              chartImage?: {
                __typename?: 'ComponentImage'
                altText?: string | null
                cloudinaryImage?: any | null
                name?: string | null
                image?: {
                  __typename?: 'Asset'
                  url?: string | null
                  height?: number | null
                  width?: number | null
                  title?: string | null
                  alt?: string | null
                } | null
              } | null
              footnote?: { __typename?: 'ChartFootnote'; json: any } | null
              longDescription?: {
                __typename?: 'ChartLongDescription'
                json: any
              } | null
            }
          | {
              __typename: 'ChildFirm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CiqArticle'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Company'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CompanyAndContactInfo'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentCard'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentCompanyContainer'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentHero'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentHubspotForm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentImage'
              cloudinaryImage?: any | null
              name?: string | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentPodcastPlayer'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentSummary'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTeamContainer'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentText'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextIllustration'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextImage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextImageImageSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextImageListSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextImageTextSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CorporateStats'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CuratedFilterGroup'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CuratedResearchContent'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Disclaimer'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Event'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ExternalContent'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Faq'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FaqCategory'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FeaturedContent'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FilterGroup'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Firm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FullWidthImage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FundProductId'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'GlossaryTerm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'HomepageAppMetadata'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'HomepageContentSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'HomepageLandingPage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'HtmlTable'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ImageSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'LegalPage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Link'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'LoginScreen'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Markdown'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'MemberFirm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Migration'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'NavHeaderTheme'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Page'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PageHelpCenterArticle'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PageLanding'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PagePwsCategory'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PageResource'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ParentFirm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Pdf'
              title?: string | null
              pdf?: { __typename?: 'Asset'; url?: string | null } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Person'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Pet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PlaylistContentSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Presentation'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PressAward'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PwsContactMedia'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PwsInsightsContentSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PwsPressRelease'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PwsSiteSettings'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Quote'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'RelatedContent'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'RelatedContentStrategy'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Seo'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'SkPlayground'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Tag'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'TagFamily'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Team'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'TestModel'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Theme'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Whitepaper'
              slug?: string | null
              title?: string | null
              displayDate?: any | null
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              thumbnail?: { __typename?: 'Asset'; url?: string | null } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | null
        >
      }
      assets: {
        __typename?: 'CaisiqArticleBodyAssets'
        block: Array<{
          __typename?: 'Asset'
          url?: string | null
          title?: string | null
          width?: number | null
          height?: number | null
          description?: string | null
          fileName?: string | null
          contentType?: string | null
          sys: { __typename?: 'Sys'; id: string }
        } | null>
      }
    }
  } | null
  footnotes?: {
    __typename?: 'CaisiqArticleFootnotes'
    json: any
    links: {
      __typename?: 'CaisiqArticleFootnotesLinks'
      entries: {
        __typename?: 'CaisiqArticleFootnotesEntries'
        hyperlink: Array<
          | {
              __typename: 'AccessControl'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'AccessControl2'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'AccessControl3'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Accordion'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'AccordionWithImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Announcement'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'AnnouncementSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqArticle'
              slug?: string | null
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqArticleDropdown'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqAuthor'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqDefaultCourseImageSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqDefaultFundsThumbnailSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqFundThumbnail'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqFundThumbnailSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqHeroHeader'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqHeroHeaderNavigateToButton'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqHomeOffice'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqJoinCaisCallToAction'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'CaisiqTab'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CaisiqVideo'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqVideoPlaylist'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CallToAction'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Chart'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'ChildFirm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CiqArticle'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Company'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CompanyAndContactInfo'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentCard'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentCompanyContainer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentHero'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentHubspotForm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentPodcastPlayer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentSummary'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTeamContainer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentText'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextIllustration'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImageImageSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImageListSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImageTextSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CorporateStats'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CuratedFilterGroup'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CuratedResearchContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Disclaimer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Event'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ExternalContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Faq'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FaqCategory'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'FeaturedContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'FilterGroup'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Firm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FullWidthImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'FundProductId'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'GlossaryTerm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'HomepageAppMetadata'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'HomepageContentSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'HomepageLandingPage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'HtmlTable'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ImageSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'LegalPage'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Link'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'LoginScreen'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Markdown'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'MemberFirm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Migration'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'NavHeaderTheme'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PageHelpCenterArticle'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PageLanding'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PagePwsCategory'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PageResource'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ParentFirm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Pdf'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Pet'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PlaylistContentSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Presentation'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PressAward'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsContactMedia'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsInsightsContentSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsPressRelease'
              slug?: string | null
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsSiteSettings'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Quote'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'RelatedContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'RelatedContentStrategy'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Seo'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'SkPlayground'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Tag'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'TagFamily'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Team'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'TestModel'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Theme'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'Whitepaper'
              slug?: string | null
              sys: { __typename?: 'Sys'; id: string }
            }
          | null
        >
        block: Array<
          | {
              __typename: 'AccessControl'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'AccessControl2'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'AccessControl3'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Accordion'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'AccordionWithImage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Announcement'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'AnnouncementSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqArticle'
              title?: string | null
              slug?: string | null
              displayDate?: any | null
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              thumbnail?: { __typename?: 'Asset'; url?: string | null } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqArticleDropdown'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqAuthor'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqDefaultCourseImageSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqDefaultFundsThumbnailSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqFundThumbnail'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqFundThumbnailSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqHeroHeader'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqHeroHeaderNavigateToButton'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqHomeOffice'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqJoinCaisCallToAction'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqTab'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqVideo'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqVideoPlaylist'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CallToAction'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Chart'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ChildFirm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CiqArticle'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Company'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CompanyAndContactInfo'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentCard'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentCompanyContainer'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentHero'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentHubspotForm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentImage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentPodcastPlayer'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentSummary'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTeamContainer'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentText'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextIllustration'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextImage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextImageImageSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextImageListSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextImageTextSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CorporateStats'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CuratedFilterGroup'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CuratedResearchContent'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Disclaimer'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Event'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ExternalContent'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Faq'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FaqCategory'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FeaturedContent'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FilterGroup'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Firm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FullWidthImage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FundProductId'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'GlossaryTerm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'HomepageAppMetadata'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'HomepageContentSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'HomepageLandingPage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'HtmlTable'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ImageSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'LegalPage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Link'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'LoginScreen'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Markdown'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'MemberFirm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Migration'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'NavHeaderTheme'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Page'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PageHelpCenterArticle'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PageLanding'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PagePwsCategory'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PageResource'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ParentFirm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Pdf'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Person'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Pet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PlaylistContentSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Presentation'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PressAward'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PwsContactMedia'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PwsInsightsContentSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PwsPressRelease'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PwsSiteSettings'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Quote'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'RelatedContent'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'RelatedContentStrategy'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Seo'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'SkPlayground'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Tag'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'TagFamily'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Team'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'TestModel'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Theme'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Whitepaper'
              slug?: string | null
              title?: string | null
              displayDate?: any | null
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              thumbnail?: { __typename?: 'Asset'; url?: string | null } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | null
        >
      }
      assets: {
        __typename?: 'CaisiqArticleFootnotesAssets'
        block: Array<{
          __typename?: 'Asset'
          url?: string | null
          title?: string | null
          width?: number | null
          height?: number | null
          description?: string | null
          fileName?: string | null
          contentType?: string | null
          sys: { __typename?: 'Sys'; id: string }
        } | null>
      }
    }
  } | null
  accordionsCollection?: {
    __typename?: 'CaisiqArticleAccordionsCollection'
    items: Array<{
      __typename?: 'CaisiqArticleDropdown'
      body?: {
        __typename?: 'CaisiqArticleDropdownBody'
        json: any
        links: {
          __typename?: 'CaisiqArticleDropdownBodyLinks'
          entries: {
            __typename?: 'CaisiqArticleDropdownBodyEntries'
            hyperlink: Array<
              | {
                  __typename: 'AccessControl'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AccessControl2'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AccessControl3'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Accordion'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AccordionWithImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Announcement'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AnnouncementSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqArticle'
                  slug?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqArticleDropdown'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqAuthor'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqDefaultCourseImageSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqDefaultFundsThumbnailSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqFundThumbnail'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqFundThumbnailSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqHeroHeader'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqHeroHeaderNavigateToButton'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqHomeOffice'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqJoinCaisCallToAction'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqTab'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqVideo'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqVideoPlaylist'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CallToAction'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Chart'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'ChildFirm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CiqArticle'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Company'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CompanyAndContactInfo'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentCard'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentCompanyContainer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentHero'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentHubspotForm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentPodcastPlayer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentSummary'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTeamContainer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentText'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextIllustration'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImageImageSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImageListSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImageTextSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CorporateStats'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CuratedFilterGroup'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CuratedResearchContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Disclaimer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Event'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'ExternalContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Faq'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'FaqCategory'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'FeaturedContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'FilterGroup'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Firm'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'FullWidthImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'FundProductId'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'GlossaryTerm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HomepageAppMetadata'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HomepageContentSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HomepageLandingPage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HtmlTable'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ImageSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'LegalPage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Link'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'LoginScreen'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Markdown'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'MemberFirm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Migration'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'NavHeaderTheme'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'PageHelpCenterArticle'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PageLanding'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PagePwsCategory'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PageResource'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ParentFirm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Pdf'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'Person'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Pet'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'PlaylistContentSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Presentation'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PressAward'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsContactMedia'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsInsightsContentSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsPressRelease'
                  slug?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsSiteSettings'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Quote'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'RelatedContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'RelatedContentStrategy'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Seo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'SkPlayground'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Tag'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'TagFamily'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Team'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'TestModel'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Theme'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'Whitepaper'
                  slug?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                }
              | null
            >
            block: Array<
              | {
                  __typename: 'AccessControl'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AccessControl2'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AccessControl3'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Accordion'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AccordionWithImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Announcement'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AnnouncementSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqArticle'
                  title?: string | null
                  slug?: string | null
                  displayDate?: any | null
                  author?: {
                    __typename?: 'CaisiqAuthor'
                    name?: string | null
                  } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                  } | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqArticleDropdown'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqAuthor'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqDefaultCourseImageSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqDefaultFundsThumbnailSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqFundThumbnail'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqFundThumbnailSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqHeroHeader'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqHeroHeaderNavigateToButton'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqHomeOffice'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqJoinCaisCallToAction'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqTab'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqVideo'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqVideoPlaylist'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CallToAction'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Chart'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ChildFirm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CiqArticle'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Company'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CompanyAndContactInfo'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentCard'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentCompanyContainer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentHero'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentHubspotForm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentPodcastPlayer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentSummary'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTeamContainer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentText'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextIllustration'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImageImageSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImageListSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImageTextSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CorporateStats'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CuratedFilterGroup'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CuratedResearchContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Disclaimer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Event'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ExternalContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Faq'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FaqCategory'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FeaturedContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FilterGroup'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Firm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FullWidthImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FundProductId'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'GlossaryTerm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HomepageAppMetadata'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HomepageContentSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HomepageLandingPage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HtmlTable'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ImageSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'LegalPage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Link'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'LoginScreen'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Markdown'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'MemberFirm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Migration'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'NavHeaderTheme'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Page'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PageHelpCenterArticle'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PageLanding'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PagePwsCategory'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PageResource'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ParentFirm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Pdf'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Person'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Pet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PlaylistContentSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Presentation'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PressAward'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsContactMedia'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsInsightsContentSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsPressRelease'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsSiteSettings'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Quote'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'RelatedContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'RelatedContentStrategy'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Seo'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'SkPlayground'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Tag'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'TagFamily'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Team'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'TestModel'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Theme'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Whitepaper'
                  slug?: string | null
                  title?: string | null
                  displayDate?: any | null
                  author?: {
                    __typename?: 'CaisiqAuthor'
                    name?: string | null
                  } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                  } | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | null
            >
          }
          assets: {
            __typename?: 'CaisiqArticleDropdownBodyAssets'
            block: Array<{
              __typename?: 'Asset'
              url?: string | null
              title?: string | null
              width?: number | null
              height?: number | null
              description?: string | null
              fileName?: string | null
              contentType?: string | null
              sys: { __typename?: 'Sys'; id: string }
            } | null>
          }
        }
      } | null
      title?: { __typename?: 'CaisiqArticleDropdownTitle'; json: any } | null
    } | null>
  } | null
  callToAction?: {
    __typename: 'CallToAction'
    title?: string | null
    description?: string | null
    buttonText?: string | null
    destination?: {
      __typename?: 'Link'
      internalLink?: string | null
      externalLink?: string | null
      openInNewWindow?: boolean | null
      cmsLink?:
        | { __typename?: 'CaisiqArticle' }
        | { __typename?: 'HomepageLandingPage' }
        | {
            __typename?: 'Page'
            slug?: string | null
            content?:
              | { __typename: 'CaisiqArticle' }
              | { __typename: 'CaisiqVideo' }
              | { __typename: 'CaisiqVideoPlaylist' }
              | { __typename: 'Company' }
              | { __typename: 'PageHelpCenterArticle' }
              | { __typename: 'PageLanding' }
              | { __typename: 'PagePwsCategory' }
              | { __typename: 'Person' }
              | { __typename: 'PwsPressRelease' }
              | { __typename: 'Whitepaper' }
              | null
          }
        | { __typename?: 'PageResource' }
        | { __typename?: 'PwsPressRelease' }
        | { __typename?: 'Whitepaper' }
        | null
    } | null
    mediaFile?: { __typename?: 'Asset'; url?: string | null } | null
  } | null
  caisVideo?: { __typename?: 'CaisiqVideo'; muxVideo?: any | null } | null
  sys: {
    __typename?: 'Sys'
    id: string
    firstPublishedAt?: any | null
    publishedAt?: any | null
  }
  fundProductIds?: {
    __typename?: 'FundProductId'
    fundProductIds?: Array<string | null> | null
  } | null
  thumbnail?: {
    __typename?: 'Asset'
    url?: string | null
    width?: number | null
    height?: number | null
  } | null
  firmsCollection?: {
    __typename?: 'CaisiqArticleFirmsCollection'
    items: Array<{
      __typename?: 'MemberFirm'
      id?: string | null
      name?: string | null
    } | null>
  } | null
}

export type GetArticlePermissionsQueryVariables = Exact<{
  slug: Scalars['String']
  firmsLimit: Scalars['Int']
}>

export type GetArticlePermissionsQuery = {
  __typename?: 'Query'
  caisiqArticleCollection?: {
    __typename?: 'CaisiqArticleCollection'
    total: number
    items: Array<{
      __typename?: 'CaisiqArticle'
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqArticleFirmsCollection'
        items: Array<{ __typename?: 'MemberFirm'; id?: string | null } | null>
      } | null
    } | null>
  } | null
}

export type GetArticlesQueryVariables = Exact<{
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CaisiqArticleFilter>
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  firmsLimit: Scalars['Int']
}>

export type GetArticlesQuery = {
  __typename?: 'Query'
  caisiqArticleCollection?: {
    __typename?: 'CaisiqArticleCollection'
    total: number
    items: Array<{
      __typename: 'CaisiqArticle'
      slug?: string | null
      title?: string | null
      category?: string | null
      displayDate?: any | null
      description?: string | null
      introduction?: string | null
      byCais?: boolean | null
      sys: {
        __typename?: 'Sys'
        id: string
        firstPublishedAt?: any | null
        publishedAt?: any | null
      }
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      author?: { __typename?: 'CaisiqAuthor'; name?: string | null } | null
      firmsCollection?: {
        __typename?: 'CaisiqArticleFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
    } | null>
  } | null
}

export type ArticleCardFragment = {
  __typename: 'CaisiqArticle'
  slug?: string | null
  title?: string | null
  category?: string | null
  displayDate?: any | null
  description?: string | null
  introduction?: string | null
  byCais?: boolean | null
  sys: {
    __typename?: 'Sys'
    id: string
    firstPublishedAt?: any | null
    publishedAt?: any | null
  }
  fundProductIds?: {
    __typename?: 'FundProductId'
    fundProductIds?: Array<string | null> | null
  } | null
  thumbnail?: {
    __typename?: 'Asset'
    url?: string | null
    width?: number | null
    height?: number | null
  } | null
  author?: { __typename?: 'CaisiqAuthor'; name?: string | null } | null
  firmsCollection?: {
    __typename?: 'CaisiqArticleFirmsCollection'
    items: Array<{
      __typename?: 'MemberFirm'
      id?: string | null
      name?: string | null
    } | null>
  } | null
}

export type GetDisclaimerQueryVariables = Exact<{
  id: Scalars['String']
  limit?: InputMaybe<Scalars['Int']>
}>

export type GetDisclaimerQuery = {
  __typename?: 'Query'
  disclaimer?: {
    __typename?: 'DisclaimerCollection'
    items: Array<{
      __typename?: 'Disclaimer'
      title?: string | null
      body?: { __typename?: 'DisclaimerBody'; json: any } | null
    } | null>
  } | null
}

export type DisclaimerFragment = {
  __typename?: 'Disclaimer'
  title?: string | null
  body?: { __typename?: 'DisclaimerBody'; json: any } | null
}

export type GetEventQueryVariables = Exact<{
  id: Scalars['String']
  firmsLimit: Scalars['Int']
  tagsLimit: Scalars['Int']
}>

export type GetEventQuery = {
  __typename?: 'Query'
  event?: {
    __typename: 'Event'
    allDay?: boolean | null
    dateTimeEnd?: any | null
    description?: string | null
    eventThumbnail?: any | null
    eventType?: string | null
    byCais?: boolean | null
    link?: string | null
    locationName?: string | null
    status?: string | null
    timezone?: string | null
    title?: string | null
    date?: any | null
    displayDate?: any | null
    fundProductIds?: {
      __typename?: 'FundProductId'
      fundProductIds?: Array<string | null> | null
    } | null
    firmsCollection?: {
      __typename?: 'EventFirmsCollection'
      items: Array<{
        __typename?: 'MemberFirm'
        id?: string | null
        name?: string | null
      } | null>
    } | null
    sys: { __typename?: 'Sys'; id: string }
    tagsCollection?: {
      __typename?: 'EventTagsCollection'
      items: Array<{ __typename?: 'Tag'; tagId?: string | null } | null>
    } | null
  } | null
}

export type GetWebinarsQueryVariables = Exact<{
  upcomingWhere: EventFilter
  videosWhere: CaisiqVideoFilter
  playlistWhere: CaisiqVideoPlaylistFilter
  featuredId: Scalars['String']
  upcomingLimit?: InputMaybe<Scalars['Int']>
  replaysLimit?: InputMaybe<Scalars['Int']>
  preview?: InputMaybe<Scalars['Boolean']>
  includeFeatured?: InputMaybe<Scalars['Boolean']>
  firmsLimit: Scalars['Int']
  tagsLimit: Scalars['Int']
}>

export type GetWebinarsQuery = {
  __typename?: 'Query'
  featured?: {
    __typename?: 'FeaturedContentCollection'
    items?: Array<{
      __typename?: 'FeaturedContent'
      fallback?:
        | {
            __typename: 'CaisiqVideo'
            byCais?: boolean | null
            title?: string | null
            category?: string | null
            summary?: string | null
            duration?: number | null
            displayDate?: any | null
            muxVideo?: any | null
            poster?: any | null
            icon?: any | null
            muxThumbnailTime?: number | null
            description?: string | null
            sys: {
              __typename?: 'Sys'
              id: string
              firstPublishedAt?: any | null
            }
            fundProductIds?: {
              __typename?: 'FundProductId'
              fundProductIds?: Array<string | null> | null
            } | null
            firmsCollection?: {
              __typename?: 'CaisiqVideoFirmsCollection'
              items: Array<{
                __typename?: 'MemberFirm'
                id?: string | null
                name?: string | null
              } | null>
            } | null
            thumbnail?: {
              __typename?: 'Asset'
              url?: string | null
              width?: number | null
              height?: number | null
            } | null
            video?: { __typename?: 'Asset'; url?: string | null } | null
          }
        | {
            __typename: 'CaisiqVideoPlaylist'
            episodic?: boolean | null
            title?: string | null
            category?: string | null
            summary?: string | null
            displayDate?: any | null
            byCais?: boolean | null
            description?: string | null
            sys: {
              __typename?: 'Sys'
              id: string
              firstPublishedAt?: any | null
            }
            heroImage?: {
              __typename?: 'Asset'
              url?: string | null
              width?: number | null
              height?: number | null
            } | null
            fundProductIds?: {
              __typename?: 'FundProductId'
              fundProductIds?: Array<string | null> | null
            } | null
            firmsCollection?: {
              __typename?: 'CaisiqVideoPlaylistFirmsCollection'
              items: Array<{
                __typename?: 'MemberFirm'
                id?: string | null
                name?: string | null
              } | null>
            } | null
            videosCollection?: {
              __typename?: 'CaisiqVideoPlaylistVideosCollection'
              items: Array<{
                __typename?: 'CaisiqVideo'
                poster?: any | null
                sys: { __typename?: 'Sys'; id: string }
                thumbnail?: {
                  __typename?: 'Asset'
                  url?: string | null
                  width?: number | null
                  height?: number | null
                } | null
              } | null>
            } | null
          }
        | {
            __typename: 'Event'
            allDay?: boolean | null
            dateTimeEnd?: any | null
            description?: string | null
            eventThumbnail?: any | null
            eventType?: string | null
            byCais?: boolean | null
            link?: string | null
            locationName?: string | null
            status?: string | null
            timezone?: string | null
            title?: string | null
            date?: any | null
            displayDate?: any | null
            fundProductIds?: {
              __typename?: 'FundProductId'
              fundProductIds?: Array<string | null> | null
            } | null
            firmsCollection?: {
              __typename?: 'EventFirmsCollection'
              items: Array<{
                __typename?: 'MemberFirm'
                id?: string | null
                name?: string | null
              } | null>
            } | null
            sys: { __typename?: 'Sys'; id: string }
            tagsCollection?: {
              __typename?: 'EventTagsCollection'
              items: Array<{ __typename?: 'Tag'; tagId?: string | null } | null>
            } | null
          }
        | {
            __typename: 'Link'
            openInNewWindow?: boolean | null
            description?: string | null
            internalLink?: string | null
            byCais?: boolean | null
            title?: string | null
            poster?: any | null
            sys: { __typename?: 'Sys'; id: string }
            cmsLink?:
              | { __typename?: 'CaisiqArticle' }
              | { __typename: 'HomepageLandingPage'; slug?: string | null }
              | { __typename?: 'Page' }
              | { __typename: 'PageResource'; slug?: string | null }
              | { __typename?: 'PwsPressRelease' }
              | { __typename?: 'Whitepaper' }
              | null
          }
        | null
      options?: {
        __typename?: 'FeaturedContentWebinarsOptionsCollection'
        items?: Array<
          | {
              __typename: 'CaisiqVideo'
              byCais?: boolean | null
              title?: string | null
              category?: string | null
              summary?: string | null
              duration?: number | null
              displayDate?: any | null
              muxVideo?: any | null
              poster?: any | null
              icon?: any | null
              muxThumbnailTime?: number | null
              description?: string | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqVideoFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              video?: { __typename?: 'Asset'; url?: string | null } | null
            }
          | {
              __typename: 'CaisiqVideoPlaylist'
              episodic?: boolean | null
              title?: string | null
              category?: string | null
              summary?: string | null
              displayDate?: any | null
              byCais?: boolean | null
              description?: string | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              heroImage?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqVideoPlaylistFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              videosCollection?: {
                __typename?: 'CaisiqVideoPlaylistVideosCollection'
                items: Array<{
                  __typename?: 'CaisiqVideo'
                  poster?: any | null
                  sys: { __typename?: 'Sys'; id: string }
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                    width?: number | null
                    height?: number | null
                  } | null
                } | null>
              } | null
            }
          | {
              __typename: 'Event'
              allDay?: boolean | null
              dateTimeEnd?: any | null
              description?: string | null
              eventThumbnail?: any | null
              eventType?: string | null
              byCais?: boolean | null
              link?: string | null
              locationName?: string | null
              status?: string | null
              timezone?: string | null
              title?: string | null
              date?: any | null
              displayDate?: any | null
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'EventFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              sys: { __typename?: 'Sys'; id: string }
              tagsCollection?: {
                __typename?: 'EventTagsCollection'
                items: Array<{
                  __typename?: 'Tag'
                  tagId?: string | null
                } | null>
              } | null
            }
          | {
              __typename: 'Link'
              openInNewWindow?: boolean | null
              description?: string | null
              internalLink?: string | null
              byCais?: boolean | null
              title?: string | null
              poster?: any | null
              sys: { __typename?: 'Sys'; id: string }
              cmsLink?:
                | { __typename?: 'CaisiqArticle' }
                | { __typename: 'HomepageLandingPage'; slug?: string | null }
                | { __typename?: 'Page' }
                | { __typename: 'PageResource'; slug?: string | null }
                | { __typename?: 'PwsPressRelease' }
                | { __typename?: 'Whitepaper' }
                | null
            }
          | null
        >
      } | null
    } | null>
  } | null
  upcoming?: {
    __typename?: 'EventCollection'
    total: number
    items: Array<{
      __typename: 'Event'
      allDay?: boolean | null
      dateTimeEnd?: any | null
      description?: string | null
      eventThumbnail?: any | null
      eventType?: string | null
      byCais?: boolean | null
      link?: string | null
      locationName?: string | null
      status?: string | null
      timezone?: string | null
      title?: string | null
      date?: any | null
      displayDate?: any | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'EventFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      sys: { __typename?: 'Sys'; id: string }
      tagsCollection?: {
        __typename?: 'EventTagsCollection'
        items: Array<{ __typename?: 'Tag'; tagId?: string | null } | null>
      } | null
    } | null>
  } | null
  caisiqVideoPlaylistCollection?: {
    __typename?: 'CaisiqVideoPlaylistCollection'
    items: Array<{
      __typename: 'CaisiqVideoPlaylist'
      episodic?: boolean | null
      title?: string | null
      category?: string | null
      summary?: string | null
      displayDate?: any | null
      byCais?: boolean | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      heroImage?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoPlaylistFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      videosCollection?: {
        __typename?: 'CaisiqVideoPlaylistVideosCollection'
        items: Array<{
          __typename?: 'CaisiqVideo'
          poster?: any | null
          sys: { __typename?: 'Sys'; id: string }
          thumbnail?: {
            __typename?: 'Asset'
            url?: string | null
            width?: number | null
            height?: number | null
          } | null
        } | null>
      } | null
    } | null>
  } | null
  caisiqVideoCollection?: {
    __typename?: 'CaisiqVideoCollection'
    total: number
    items: Array<{
      __typename: 'CaisiqVideo'
      byCais?: boolean | null
      title?: string | null
      category?: string | null
      summary?: string | null
      duration?: number | null
      displayDate?: any | null
      muxVideo?: any | null
      poster?: any | null
      icon?: any | null
      muxThumbnailTime?: number | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      video?: { __typename?: 'Asset'; url?: string | null } | null
    } | null>
  } | null
}

export type GetWebinarsReplayQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  preview?: InputMaybe<Scalars['Boolean']>
  firmsLimit: Scalars['Int']
}>

export type GetWebinarsReplayQuery = {
  __typename?: 'Query'
  caisiqVideoPlaylistCollection?: {
    __typename?: 'CaisiqVideoPlaylistCollection'
    items: Array<{
      __typename: 'CaisiqVideoPlaylist'
      episodic?: boolean | null
      title?: string | null
      category?: string | null
      summary?: string | null
      displayDate?: any | null
      byCais?: boolean | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      heroImage?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoPlaylistFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      videosCollection?: {
        __typename?: 'CaisiqVideoPlaylistVideosCollection'
        items: Array<{
          __typename?: 'CaisiqVideo'
          poster?: any | null
          sys: { __typename?: 'Sys'; id: string }
          thumbnail?: {
            __typename?: 'Asset'
            url?: string | null
            width?: number | null
            height?: number | null
          } | null
        } | null>
      } | null
    } | null>
  } | null
  caisiqVideoCollection?: {
    __typename?: 'CaisiqVideoCollection'
    total: number
    items: Array<{
      __typename: 'CaisiqVideo'
      byCais?: boolean | null
      title?: string | null
      category?: string | null
      summary?: string | null
      duration?: number | null
      displayDate?: any | null
      muxVideo?: any | null
      poster?: any | null
      icon?: any | null
      muxThumbnailTime?: number | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      video?: { __typename?: 'Asset'; url?: string | null } | null
    } | null>
  } | null
}

export type EventCardFragment = {
  __typename: 'Event'
  allDay?: boolean | null
  dateTimeEnd?: any | null
  description?: string | null
  eventThumbnail?: any | null
  eventType?: string | null
  byCais?: boolean | null
  link?: string | null
  locationName?: string | null
  status?: string | null
  timezone?: string | null
  title?: string | null
  date?: any | null
  displayDate?: any | null
  fundProductIds?: {
    __typename?: 'FundProductId'
    fundProductIds?: Array<string | null> | null
  } | null
  firmsCollection?: {
    __typename?: 'EventFirmsCollection'
    items: Array<{
      __typename?: 'MemberFirm'
      id?: string | null
      name?: string | null
    } | null>
  } | null
  sys: { __typename?: 'Sys'; id: string }
  tagsCollection?: {
    __typename?: 'EventTagsCollection'
    items: Array<{ __typename?: 'Tag'; tagId?: string | null } | null>
  } | null
}

export type GetInPersonEventsQueryVariables = Exact<{
  upcomingWhere: EventFilter
  upcomingLimit?: InputMaybe<Scalars['Int']>
  preview?: InputMaybe<Scalars['Boolean']>
  firmsLimit: Scalars['Int']
  tagsLimit: Scalars['Int']
}>

export type GetInPersonEventsQuery = {
  __typename?: 'Query'
  upcoming?: {
    __typename?: 'EventCollection'
    total: number
    items: Array<{
      __typename: 'Event'
      allDay?: boolean | null
      dateTimeEnd?: any | null
      description?: string | null
      eventThumbnail?: any | null
      eventType?: string | null
      byCais?: boolean | null
      link?: string | null
      locationName?: string | null
      status?: string | null
      timezone?: string | null
      title?: string | null
      date?: any | null
      displayDate?: any | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'EventFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      sys: { __typename?: 'Sys'; id: string }
      tagsCollection?: {
        __typename?: 'EventTagsCollection'
        items: Array<{ __typename?: 'Tag'; tagId?: string | null } | null>
      } | null
    } | null>
  } | null
}

export type GetContentfulFundsQueryVariables = Exact<{
  fundProductIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >
  preview?: InputMaybe<Scalars['Boolean']>
  firmsLimit: Scalars['Int']
}>

export type GetContentfulFundsQuery = {
  __typename?: 'Query'
  contentfulFunds?: {
    __typename?: 'ExternalContentCollection'
    items: Array<{
      __typename: 'ExternalContent'
      type?: string | null
      title?: string | null
      displayDate?: any | null
      description?: string | null
      externalId?: string | null
      externalLink?: string | null
      strategy?: string | null
      subStrategy?: string | null
      eligibility?: string | null
      byCais?: boolean | null
      minimumInitialInvestment?: number | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      tagsCollection?: {
        __typename?: 'ExternalContentTagsCollection'
        items: Array<{
          __typename?: 'Tag'
          label?: string | null
          tagId?: string | null
        } | null>
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'ExternalContentFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
        alt?: string | null
      } | null
    } | null>
  } | null
}

export type GetExternalContentQueryVariables = Exact<{
  where?: InputMaybe<ExternalContentFilter>
  preview?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  firmsLimit: Scalars['Int']
}>

export type GetExternalContentQuery = {
  __typename?: 'Query'
  externalContentCollection?: {
    __typename?: 'ExternalContentCollection'
    items: Array<{
      __typename: 'ExternalContent'
      type?: string | null
      title?: string | null
      displayDate?: any | null
      description?: string | null
      externalId?: string | null
      externalLink?: string | null
      strategy?: string | null
      subStrategy?: string | null
      eligibility?: string | null
      byCais?: boolean | null
      minimumInitialInvestment?: number | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      tagsCollection?: {
        __typename?: 'ExternalContentTagsCollection'
        items: Array<{
          __typename?: 'Tag'
          label?: string | null
          tagId?: string | null
        } | null>
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'ExternalContentFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
        alt?: string | null
      } | null
    } | null>
  } | null
}

export type ExternalContentCardFragment = {
  __typename: 'ExternalContent'
  type?: string | null
  title?: string | null
  displayDate?: any | null
  description?: string | null
  externalId?: string | null
  externalLink?: string | null
  strategy?: string | null
  subStrategy?: string | null
  eligibility?: string | null
  byCais?: boolean | null
  minimumInitialInvestment?: number | null
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
  tagsCollection?: {
    __typename?: 'ExternalContentTagsCollection'
    items: Array<{
      __typename?: 'Tag'
      label?: string | null
      tagId?: string | null
    } | null>
  } | null
  fundProductIds?: {
    __typename?: 'FundProductId'
    fundProductIds?: Array<string | null> | null
  } | null
  firmsCollection?: {
    __typename?: 'ExternalContentFirmsCollection'
    items: Array<{
      __typename?: 'MemberFirm'
      id?: string | null
      name?: string | null
    } | null>
  } | null
  thumbnail?: {
    __typename?: 'Asset'
    url?: string | null
    width?: number | null
    height?: number | null
    alt?: string | null
  } | null
}

export type GetFirmContentOptionsQueryVariables = Exact<{
  firmId?: InputMaybe<Scalars['String']>
  firmsLimit: Scalars['Int']
}>

export type GetFirmContentOptionsQuery = {
  __typename?: 'Query'
  memberFirmCollection?: {
    __typename?: 'MemberFirmCollection'
    items: Array<{
      __typename?: 'MemberFirm'
      optionsCollection?: {
        __typename?: 'MemberFirmOptionsCollection'
        items: Array<
          | {
              __typename: 'CaisiqArticle'
              customTitle?: string | null
              customDescription?: string | null
              description?: string | null
              title?: string | null
              displayDate?: any | null
              slug?: string | null
              byCais?: boolean | null
              sys: { __typename?: 'Sys'; id: string }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqArticleFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
            }
          | {
              __typename: 'CaisiqVideo'
              customTitle?: string | null
              customDescription?: string | null
              category?: string | null
              title?: string | null
              displayDate?: any | null
              byCais?: boolean | null
              description?: string | null
              sys: { __typename?: 'Sys'; id: string }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqVideoFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
            }
          | {
              __typename: 'CaisiqVideoPlaylist'
              customTitle?: string | null
              customDescription?: string | null
              category?: string | null
              title?: string | null
              displayDate?: any | null
              byCais?: boolean | null
              description?: string | null
              sys: { __typename?: 'Sys'; id: string }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqVideoPlaylistFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              videosCollection?: {
                __typename?: 'CaisiqVideoPlaylistVideosCollection'
                items: Array<{
                  __typename?: 'CaisiqVideo'
                  sys: { __typename?: 'Sys'; id: string }
                } | null>
              } | null
            }
          | {
              __typename: 'Event'
              customTitle?: string | null
              title?: string | null
              customDescription?: string | null
              description?: string | null
              eventType?: string | null
              link?: string | null
              byCais?: boolean | null
              timezone?: string | null
              date?: any | null
              displayDate?: any | null
              sys: { __typename?: 'Sys'; id: string }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'EventFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
            }
          | {
              __typename: 'ExternalContent'
              type?: string | null
              externalLink?: string | null
              externalId?: string | null
              customTitle?: string | null
              customDescription?: string | null
              description?: string | null
              title?: string | null
              displayDate?: any | null
              byCais?: boolean | null
              sys: { __typename?: 'Sys'; id: string }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'ExternalContentFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
            }
          | {
              __typename: 'Link'
              openInNewWindow?: boolean | null
              customTitle?: string | null
              customDescription?: string | null
              internalLink?: string | null
              byCais?: boolean | null
              sys: { __typename?: 'Sys'; id: string }
              cmsLink?:
                | { __typename?: 'CaisiqArticle' }
                | { __typename: 'HomepageLandingPage'; slug?: string | null }
                | { __typename?: 'Page' }
                | { __typename: 'PageResource'; slug?: string | null }
                | { __typename?: 'PwsPressRelease' }
                | { __typename?: 'Whitepaper' }
                | null
              firmsCollection?: {
                __typename?: 'LinkFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
            }
          | {
              __typename: 'Pdf'
              customTitle?: string | null
              title?: string | null
              customDescription?: string | null
              displayDate?: any | null
              byCais?: boolean | null
              sys: { __typename?: 'Sys'; id: string }
              pdf?: { __typename?: 'Asset'; url?: string | null } | null
              firmsCollection?: {
                __typename?: 'PdfFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
            }
          | {
              __typename: 'Whitepaper'
              customTitle?: string | null
              customDescription?: string | null
              title?: string | null
              displayDate?: any | null
              slug?: string | null
              byCais?: boolean | null
              description?: string | null
              sys: { __typename?: 'Sys'; id: string }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'WhitepaperFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
            }
          | null
        >
      } | null
    } | null>
  } | null
}

export type ArticleCuratedContentCardFragment = {
  __typename: 'CaisiqArticle'
  customTitle?: string | null
  customDescription?: string | null
  description?: string | null
  title?: string | null
  displayDate?: any | null
  slug?: string | null
  byCais?: boolean | null
  sys: { __typename?: 'Sys'; id: string }
  fundProductIds?: {
    __typename?: 'FundProductId'
    fundProductIds?: Array<string | null> | null
  } | null
  firmsCollection?: {
    __typename?: 'CaisiqArticleFirmsCollection'
    items: Array<{
      __typename?: 'MemberFirm'
      id?: string | null
      name?: string | null
    } | null>
  } | null
}

export type WhitepaperCuratedContentCardFragment = {
  __typename: 'Whitepaper'
  customTitle?: string | null
  customDescription?: string | null
  title?: string | null
  displayDate?: any | null
  slug?: string | null
  byCais?: boolean | null
  description?: string | null
  sys: { __typename?: 'Sys'; id: string }
  fundProductIds?: {
    __typename?: 'FundProductId'
    fundProductIds?: Array<string | null> | null
  } | null
  firmsCollection?: {
    __typename?: 'WhitepaperFirmsCollection'
    items: Array<{
      __typename?: 'MemberFirm'
      id?: string | null
      name?: string | null
    } | null>
  } | null
}

export type VideoCuratedContentCardFragment = {
  __typename: 'CaisiqVideo'
  customTitle?: string | null
  customDescription?: string | null
  category?: string | null
  title?: string | null
  displayDate?: any | null
  byCais?: boolean | null
  description?: string | null
  sys: { __typename?: 'Sys'; id: string }
  fundProductIds?: {
    __typename?: 'FundProductId'
    fundProductIds?: Array<string | null> | null
  } | null
  firmsCollection?: {
    __typename?: 'CaisiqVideoFirmsCollection'
    items: Array<{
      __typename?: 'MemberFirm'
      id?: string | null
      name?: string | null
    } | null>
  } | null
}

export type VideoPlaylistCuratedContentCardFragment = {
  __typename: 'CaisiqVideoPlaylist'
  customTitle?: string | null
  customDescription?: string | null
  category?: string | null
  title?: string | null
  displayDate?: any | null
  byCais?: boolean | null
  description?: string | null
  sys: { __typename?: 'Sys'; id: string }
  fundProductIds?: {
    __typename?: 'FundProductId'
    fundProductIds?: Array<string | null> | null
  } | null
  firmsCollection?: {
    __typename?: 'CaisiqVideoPlaylistFirmsCollection'
    items: Array<{
      __typename?: 'MemberFirm'
      id?: string | null
      name?: string | null
    } | null>
  } | null
  videosCollection?: {
    __typename?: 'CaisiqVideoPlaylistVideosCollection'
    items: Array<{
      __typename?: 'CaisiqVideo'
      sys: { __typename?: 'Sys'; id: string }
    } | null>
  } | null
}

export type ExternalCuratedContentCardFragment = {
  __typename: 'ExternalContent'
  type?: string | null
  externalLink?: string | null
  externalId?: string | null
  customTitle?: string | null
  customDescription?: string | null
  description?: string | null
  title?: string | null
  displayDate?: any | null
  byCais?: boolean | null
  sys: { __typename?: 'Sys'; id: string }
  fundProductIds?: {
    __typename?: 'FundProductId'
    fundProductIds?: Array<string | null> | null
  } | null
  firmsCollection?: {
    __typename?: 'ExternalContentFirmsCollection'
    items: Array<{
      __typename?: 'MemberFirm'
      id?: string | null
      name?: string | null
    } | null>
  } | null
}

export type GetFirmsWithContentQueryVariables = Exact<{
  firmIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  >
  limit?: InputMaybe<Scalars['Int']>
}>

export type GetFirmsWithContentQuery = {
  __typename?: 'Query'
  memberFirmCollection?: {
    __typename?: 'MemberFirmCollection'
    items: Array<{
      __typename?: 'MemberFirm'
      id?: string | null
      overrideDefault?: boolean | null
      optionsCollection?: {
        __typename?: 'MemberFirmOptionsCollection'
        total: number
      } | null
    } | null>
  } | null
}

export type GetFeaturedHomeQueryVariables = Exact<{
  featuredId: Scalars['String']
  preview?: InputMaybe<Scalars['Boolean']>
  firmsLimit: Scalars['Int']
  tagsLimit: Scalars['Int']
}>

export type GetFeaturedHomeQuery = {
  __typename?: 'Query'
  featured?: {
    __typename?: 'FeaturedContentCollection'
    items: Array<{
      __typename?: 'FeaturedContent'
      fallback?:
        | {
            __typename: 'CaisiqArticle'
            slug?: string | null
            title?: string | null
            category?: string | null
            displayDate?: any | null
            description?: string | null
            introduction?: string | null
            byCais?: boolean | null
            sys: {
              __typename?: 'Sys'
              id: string
              firstPublishedAt?: any | null
              publishedAt?: any | null
            }
            fundProductIds?: {
              __typename?: 'FundProductId'
              fundProductIds?: Array<string | null> | null
            } | null
            thumbnail?: {
              __typename?: 'Asset'
              url?: string | null
              width?: number | null
              height?: number | null
            } | null
            author?: {
              __typename?: 'CaisiqAuthor'
              name?: string | null
            } | null
            firmsCollection?: {
              __typename?: 'CaisiqArticleFirmsCollection'
              items: Array<{
                __typename?: 'MemberFirm'
                id?: string | null
                name?: string | null
              } | null>
            } | null
          }
        | {
            __typename: 'CaisiqVideo'
            byCais?: boolean | null
            title?: string | null
            category?: string | null
            summary?: string | null
            duration?: number | null
            displayDate?: any | null
            muxVideo?: any | null
            poster?: any | null
            icon?: any | null
            muxThumbnailTime?: number | null
            description?: string | null
            sys: {
              __typename?: 'Sys'
              id: string
              firstPublishedAt?: any | null
            }
            fundProductIds?: {
              __typename?: 'FundProductId'
              fundProductIds?: Array<string | null> | null
            } | null
            firmsCollection?: {
              __typename?: 'CaisiqVideoFirmsCollection'
              items: Array<{
                __typename?: 'MemberFirm'
                id?: string | null
                name?: string | null
              } | null>
            } | null
            thumbnail?: {
              __typename?: 'Asset'
              url?: string | null
              width?: number | null
              height?: number | null
            } | null
            video?: { __typename?: 'Asset'; url?: string | null } | null
          }
        | {
            __typename: 'CaisiqVideoPlaylist'
            episodic?: boolean | null
            title?: string | null
            category?: string | null
            summary?: string | null
            displayDate?: any | null
            byCais?: boolean | null
            description?: string | null
            sys: {
              __typename?: 'Sys'
              id: string
              firstPublishedAt?: any | null
            }
            heroImage?: {
              __typename?: 'Asset'
              url?: string | null
              width?: number | null
              height?: number | null
            } | null
            fundProductIds?: {
              __typename?: 'FundProductId'
              fundProductIds?: Array<string | null> | null
            } | null
            firmsCollection?: {
              __typename?: 'CaisiqVideoPlaylistFirmsCollection'
              items: Array<{
                __typename?: 'MemberFirm'
                id?: string | null
                name?: string | null
              } | null>
            } | null
            videosCollection?: {
              __typename?: 'CaisiqVideoPlaylistVideosCollection'
              items: Array<{
                __typename?: 'CaisiqVideo'
                poster?: any | null
                sys: { __typename?: 'Sys'; id: string }
                thumbnail?: {
                  __typename?: 'Asset'
                  url?: string | null
                  width?: number | null
                  height?: number | null
                } | null
              } | null>
            } | null
          }
        | {
            __typename: 'Event'
            allDay?: boolean | null
            dateTimeEnd?: any | null
            description?: string | null
            eventThumbnail?: any | null
            eventType?: string | null
            byCais?: boolean | null
            link?: string | null
            locationName?: string | null
            status?: string | null
            timezone?: string | null
            title?: string | null
            date?: any | null
            displayDate?: any | null
            fundProductIds?: {
              __typename?: 'FundProductId'
              fundProductIds?: Array<string | null> | null
            } | null
            firmsCollection?: {
              __typename?: 'EventFirmsCollection'
              items: Array<{
                __typename?: 'MemberFirm'
                id?: string | null
                name?: string | null
              } | null>
            } | null
            sys: { __typename?: 'Sys'; id: string }
            tagsCollection?: {
              __typename?: 'EventTagsCollection'
              items: Array<{ __typename?: 'Tag'; tagId?: string | null } | null>
            } | null
          }
        | {
            __typename: 'ExternalContent'
            type?: string | null
            title?: string | null
            displayDate?: any | null
            description?: string | null
            externalId?: string | null
            externalLink?: string | null
            strategy?: string | null
            subStrategy?: string | null
            eligibility?: string | null
            byCais?: boolean | null
            minimumInitialInvestment?: number | null
            sys: {
              __typename?: 'Sys'
              id: string
              firstPublishedAt?: any | null
            }
            tagsCollection?: {
              __typename?: 'ExternalContentTagsCollection'
              items: Array<{
                __typename?: 'Tag'
                label?: string | null
                tagId?: string | null
              } | null>
            } | null
            fundProductIds?: {
              __typename?: 'FundProductId'
              fundProductIds?: Array<string | null> | null
            } | null
            firmsCollection?: {
              __typename?: 'ExternalContentFirmsCollection'
              items: Array<{
                __typename?: 'MemberFirm'
                id?: string | null
                name?: string | null
              } | null>
            } | null
            thumbnail?: {
              __typename?: 'Asset'
              url?: string | null
              width?: number | null
              height?: number | null
              alt?: string | null
            } | null
          }
        | {
            __typename: 'Link'
            openInNewWindow?: boolean | null
            description?: string | null
            internalLink?: string | null
            byCais?: boolean | null
            title?: string | null
            poster?: any | null
            sys: { __typename?: 'Sys'; id: string }
            cmsLink?:
              | { __typename?: 'CaisiqArticle' }
              | { __typename: 'HomepageLandingPage'; slug?: string | null }
              | { __typename?: 'Page' }
              | { __typename: 'PageResource'; slug?: string | null }
              | { __typename?: 'PwsPressRelease' }
              | { __typename?: 'Whitepaper' }
              | null
          }
        | {
            __typename: 'Whitepaper'
            byCais?: boolean | null
            type?: string | null
            slug?: string | null
            title?: string | null
            category?: string | null
            displayDate?: any | null
            introduction?: string | null
            description?: string | null
            fundProductIds?: {
              __typename?: 'FundProductId'
              fundProductIds?: Array<string | null> | null
            } | null
            firmsCollection?: {
              __typename?: 'WhitepaperFirmsCollection'
              items: Array<{
                __typename?: 'MemberFirm'
                id?: string | null
                name?: string | null
              } | null>
            } | null
            sys: {
              __typename?: 'Sys'
              id: string
              firstPublishedAt?: any | null
              publishedAt?: any | null
            }
            author?: {
              __typename?: 'CaisiqAuthor'
              name?: string | null
            } | null
            hero?: { __typename?: 'Asset'; url?: string | null } | null
            thumbnail?: {
              __typename?: 'Asset'
              url?: string | null
              width?: number | null
              height?: number | null
            } | null
            pdfsCollection?: {
              __typename?: 'WhitepaperPdfsCollection'
              items: Array<{
                __typename?: 'Pdf'
                title?: string | null
                displayDate?: any | null
                pdf?: { __typename?: 'Asset'; url?: string | null } | null
                image?: { __typename?: 'Asset'; url?: string | null } | null
              } | null>
            } | null
          }
        | null
      options?: {
        __typename?: 'FeaturedContentHomepageOptionsCollection'
        items: Array<
          | {
              __typename: 'CaisiqArticle'
              slug?: string | null
              title?: string | null
              category?: string | null
              displayDate?: any | null
              description?: string | null
              introduction?: string | null
              byCais?: boolean | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
                publishedAt?: any | null
              }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqArticleFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
            }
          | {
              __typename: 'CaisiqVideo'
              byCais?: boolean | null
              title?: string | null
              category?: string | null
              summary?: string | null
              duration?: number | null
              displayDate?: any | null
              muxVideo?: any | null
              poster?: any | null
              icon?: any | null
              muxThumbnailTime?: number | null
              description?: string | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqVideoFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              video?: { __typename?: 'Asset'; url?: string | null } | null
            }
          | {
              __typename: 'CaisiqVideoPlaylist'
              episodic?: boolean | null
              title?: string | null
              category?: string | null
              summary?: string | null
              displayDate?: any | null
              byCais?: boolean | null
              description?: string | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              heroImage?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqVideoPlaylistFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              videosCollection?: {
                __typename?: 'CaisiqVideoPlaylistVideosCollection'
                items: Array<{
                  __typename?: 'CaisiqVideo'
                  poster?: any | null
                  sys: { __typename?: 'Sys'; id: string }
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                    width?: number | null
                    height?: number | null
                  } | null
                } | null>
              } | null
            }
          | {
              __typename: 'Event'
              allDay?: boolean | null
              dateTimeEnd?: any | null
              description?: string | null
              eventThumbnail?: any | null
              eventType?: string | null
              byCais?: boolean | null
              link?: string | null
              locationName?: string | null
              status?: string | null
              timezone?: string | null
              title?: string | null
              date?: any | null
              displayDate?: any | null
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'EventFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              sys: { __typename?: 'Sys'; id: string }
              tagsCollection?: {
                __typename?: 'EventTagsCollection'
                items: Array<{
                  __typename?: 'Tag'
                  tagId?: string | null
                } | null>
              } | null
            }
          | {
              __typename: 'ExternalContent'
              type?: string | null
              title?: string | null
              displayDate?: any | null
              description?: string | null
              externalId?: string | null
              externalLink?: string | null
              strategy?: string | null
              subStrategy?: string | null
              eligibility?: string | null
              byCais?: boolean | null
              minimumInitialInvestment?: number | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              tagsCollection?: {
                __typename?: 'ExternalContentTagsCollection'
                items: Array<{
                  __typename?: 'Tag'
                  label?: string | null
                  tagId?: string | null
                } | null>
              } | null
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'ExternalContentFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
                alt?: string | null
              } | null
            }
          | {
              __typename: 'Link'
              openInNewWindow?: boolean | null
              description?: string | null
              internalLink?: string | null
              byCais?: boolean | null
              title?: string | null
              poster?: any | null
              sys: { __typename?: 'Sys'; id: string }
              cmsLink?:
                | { __typename?: 'CaisiqArticle' }
                | { __typename: 'HomepageLandingPage'; slug?: string | null }
                | { __typename?: 'Page' }
                | { __typename: 'PageResource'; slug?: string | null }
                | { __typename?: 'PwsPressRelease' }
                | { __typename?: 'Whitepaper' }
                | null
            }
          | {
              __typename: 'Whitepaper'
              byCais?: boolean | null
              type?: string | null
              slug?: string | null
              title?: string | null
              category?: string | null
              displayDate?: any | null
              introduction?: string | null
              description?: string | null
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'WhitepaperFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
                publishedAt?: any | null
              }
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              hero?: { __typename?: 'Asset'; url?: string | null } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              pdfsCollection?: {
                __typename?: 'WhitepaperPdfsCollection'
                items: Array<{
                  __typename?: 'Pdf'
                  title?: string | null
                  displayDate?: any | null
                  pdf?: { __typename?: 'Asset'; url?: string | null } | null
                  image?: { __typename?: 'Asset'; url?: string | null } | null
                } | null>
              } | null
            }
          | null
        >
      } | null
    } | null>
  } | null
}

export type GetHomeCarouselContentQueryVariables = Exact<{
  carouselItemLimit: Scalars['Int']
  preview?: InputMaybe<Scalars['Boolean']>
  date?: InputMaybe<Scalars['DateTime']>
  firmsLimit: Scalars['Int']
  tagsLimit: Scalars['Int']
}>

export type GetHomeCarouselContentQuery = {
  __typename?: 'Query'
  tutorialArticles?: {
    __typename?: 'CaisiqArticleCollection'
    items: Array<{
      __typename: 'CaisiqArticle'
      slug?: string | null
      title?: string | null
      category?: string | null
      displayDate?: any | null
      description?: string | null
      introduction?: string | null
      byCais?: boolean | null
      sys: {
        __typename?: 'Sys'
        id: string
        firstPublishedAt?: any | null
        publishedAt?: any | null
      }
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      author?: { __typename?: 'CaisiqAuthor'; name?: string | null } | null
      firmsCollection?: {
        __typename?: 'CaisiqArticleFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
    } | null>
  } | null
  tutorialPlaylists?: {
    __typename?: 'CaisiqVideoPlaylistCollection'
    items: Array<{
      __typename: 'CaisiqVideoPlaylist'
      episodic?: boolean | null
      title?: string | null
      category?: string | null
      summary?: string | null
      displayDate?: any | null
      byCais?: boolean | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      heroImage?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoPlaylistFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      videosCollection?: {
        __typename?: 'CaisiqVideoPlaylistVideosCollection'
        items: Array<{
          __typename?: 'CaisiqVideo'
          poster?: any | null
          sys: { __typename?: 'Sys'; id: string }
          thumbnail?: {
            __typename?: 'Asset'
            url?: string | null
            width?: number | null
            height?: number | null
          } | null
        } | null>
      } | null
    } | null>
  } | null
  tutorialVideos?: {
    __typename?: 'CaisiqVideoCollection'
    items: Array<{
      __typename: 'CaisiqVideo'
      byCais?: boolean | null
      title?: string | null
      category?: string | null
      summary?: string | null
      duration?: number | null
      displayDate?: any | null
      muxVideo?: any | null
      poster?: any | null
      icon?: any | null
      muxThumbnailTime?: number | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      video?: { __typename?: 'Asset'; url?: string | null } | null
    } | null>
  } | null
  tutorialWhitepapers?: {
    __typename?: 'WhitepaperCollection'
    items: Array<{
      __typename: 'Whitepaper'
      byCais?: boolean | null
      type?: string | null
      slug?: string | null
      title?: string | null
      category?: string | null
      displayDate?: any | null
      introduction?: string | null
      description?: string | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'WhitepaperFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      sys: {
        __typename?: 'Sys'
        id: string
        firstPublishedAt?: any | null
        publishedAt?: any | null
      }
      author?: { __typename?: 'CaisiqAuthor'; name?: string | null } | null
      hero?: { __typename?: 'Asset'; url?: string | null } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      pdfsCollection?: {
        __typename?: 'WhitepaperPdfsCollection'
        items: Array<{
          __typename?: 'Pdf'
          title?: string | null
          displayDate?: any | null
          pdf?: { __typename?: 'Asset'; url?: string | null } | null
          image?: { __typename?: 'Asset'; url?: string | null } | null
        } | null>
      } | null
    } | null>
  } | null
  researchArticles?: {
    __typename?: 'CaisiqArticleCollection'
    items: Array<{
      __typename: 'CaisiqArticle'
      slug?: string | null
      title?: string | null
      category?: string | null
      displayDate?: any | null
      description?: string | null
      introduction?: string | null
      byCais?: boolean | null
      sys: {
        __typename?: 'Sys'
        id: string
        firstPublishedAt?: any | null
        publishedAt?: any | null
      }
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      author?: { __typename?: 'CaisiqAuthor'; name?: string | null } | null
      firmsCollection?: {
        __typename?: 'CaisiqArticleFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
    } | null>
  } | null
  researchPlaylists?: {
    __typename?: 'CaisiqVideoPlaylistCollection'
    items: Array<{
      __typename: 'CaisiqVideoPlaylist'
      episodic?: boolean | null
      title?: string | null
      category?: string | null
      summary?: string | null
      displayDate?: any | null
      byCais?: boolean | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      heroImage?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoPlaylistFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      videosCollection?: {
        __typename?: 'CaisiqVideoPlaylistVideosCollection'
        items: Array<{
          __typename?: 'CaisiqVideo'
          poster?: any | null
          sys: { __typename?: 'Sys'; id: string }
          thumbnail?: {
            __typename?: 'Asset'
            url?: string | null
            width?: number | null
            height?: number | null
          } | null
        } | null>
      } | null
    } | null>
  } | null
  researchVideos?: {
    __typename?: 'CaisiqVideoCollection'
    items: Array<{
      __typename: 'CaisiqVideo'
      byCais?: boolean | null
      title?: string | null
      category?: string | null
      summary?: string | null
      duration?: number | null
      displayDate?: any | null
      muxVideo?: any | null
      poster?: any | null
      icon?: any | null
      muxThumbnailTime?: number | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      video?: { __typename?: 'Asset'; url?: string | null } | null
    } | null>
  } | null
  researchWhitepapers?: {
    __typename?: 'WhitepaperCollection'
    items: Array<{
      __typename: 'Whitepaper'
      byCais?: boolean | null
      type?: string | null
      slug?: string | null
      title?: string | null
      category?: string | null
      displayDate?: any | null
      introduction?: string | null
      description?: string | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'WhitepaperFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      sys: {
        __typename?: 'Sys'
        id: string
        firstPublishedAt?: any | null
        publishedAt?: any | null
      }
      author?: { __typename?: 'CaisiqAuthor'; name?: string | null } | null
      hero?: { __typename?: 'Asset'; url?: string | null } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      pdfsCollection?: {
        __typename?: 'WhitepaperPdfsCollection'
        items: Array<{
          __typename?: 'Pdf'
          title?: string | null
          displayDate?: any | null
          pdf?: { __typename?: 'Asset'; url?: string | null } | null
          image?: { __typename?: 'Asset'; url?: string | null } | null
        } | null>
      } | null
    } | null>
  } | null
  researchExternal?: {
    __typename?: 'ExternalContentCollection'
    total: number
    items: Array<{
      __typename: 'ExternalContent'
      type?: string | null
      title?: string | null
      displayDate?: any | null
      description?: string | null
      externalId?: string | null
      externalLink?: string | null
      strategy?: string | null
      subStrategy?: string | null
      eligibility?: string | null
      byCais?: boolean | null
      minimumInitialInvestment?: number | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      tagsCollection?: {
        __typename?: 'ExternalContentTagsCollection'
        items: Array<{
          __typename?: 'Tag'
          label?: string | null
          tagId?: string | null
        } | null>
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'ExternalContentFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
        alt?: string | null
      } | null
    } | null>
  } | null
  trendingFunds?: {
    __typename?: 'ExternalContentCollection'
    items: Array<{
      __typename: 'ExternalContent'
      type?: string | null
      title?: string | null
      displayDate?: any | null
      description?: string | null
      externalId?: string | null
      externalLink?: string | null
      strategy?: string | null
      subStrategy?: string | null
      eligibility?: string | null
      byCais?: boolean | null
      minimumInitialInvestment?: number | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      tagsCollection?: {
        __typename?: 'ExternalContentTagsCollection'
        items: Array<{
          __typename?: 'Tag'
          label?: string | null
          tagId?: string | null
        } | null>
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'ExternalContentFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
        alt?: string | null
      } | null
    } | null>
  } | null
  upcoming?: {
    __typename?: 'EventCollection'
    total: number
    items: Array<{
      __typename: 'Event'
      allDay?: boolean | null
      dateTimeEnd?: any | null
      description?: string | null
      eventThumbnail?: any | null
      eventType?: string | null
      byCais?: boolean | null
      link?: string | null
      locationName?: string | null
      status?: string | null
      timezone?: string | null
      title?: string | null
      date?: any | null
      displayDate?: any | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'EventFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      sys: { __typename?: 'Sys'; id: string }
      tagsCollection?: {
        __typename?: 'EventTagsCollection'
        items: Array<{ __typename?: 'Tag'; tagId?: string | null } | null>
      } | null
    } | null>
  } | null
  caisiqVideoPlaylistCollection?: {
    __typename?: 'CaisiqVideoPlaylistCollection'
    items: Array<{
      __typename: 'CaisiqVideoPlaylist'
      episodic?: boolean | null
      title?: string | null
      category?: string | null
      summary?: string | null
      displayDate?: any | null
      byCais?: boolean | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      heroImage?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoPlaylistFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      videosCollection?: {
        __typename?: 'CaisiqVideoPlaylistVideosCollection'
        items: Array<{
          __typename?: 'CaisiqVideo'
          poster?: any | null
          sys: { __typename?: 'Sys'; id: string }
          thumbnail?: {
            __typename?: 'Asset'
            url?: string | null
            width?: number | null
            height?: number | null
          } | null
        } | null>
      } | null
    } | null>
  } | null
  caisiqVideoCollection?: {
    __typename?: 'CaisiqVideoCollection'
    total: number
    items: Array<{
      __typename: 'CaisiqVideo'
      byCais?: boolean | null
      title?: string | null
      category?: string | null
      summary?: string | null
      duration?: number | null
      displayDate?: any | null
      muxVideo?: any | null
      poster?: any | null
      icon?: any | null
      muxThumbnailTime?: number | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      video?: { __typename?: 'Asset'; url?: string | null } | null
    } | null>
  } | null
  pressReleases?: {
    __typename?: 'PwsPressReleaseCollection'
    items: Array<{
      __typename: 'PwsPressRelease'
      description?: string | null
      displayDate?: any | null
      externalNewsLink?: string | null
      title?: string | null
      type?: string | null
      sys: { __typename?: 'Sys'; id: string }
      author?: { __typename?: 'CaisiqAuthor'; name?: string | null } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        height?: number | null
        width?: number | null
        title?: string | null
        alt?: string | null
      } | null
      linkedFrom?: {
        __typename?: 'PwsPressReleaseLinkingCollections'
        pageCollection?: {
          __typename?: 'PageCollection'
          items: Array<{
            __typename?: 'Page'
            slug?: string | null
            title?: string | null
          } | null>
        } | null
      } | null
    } | null>
  } | null
}

export type GetLegalPageBySlugQueryVariables = Exact<{
  slug: Scalars['String']
  preview?: InputMaybe<Scalars['Boolean']>
}>

export type GetLegalPageBySlugQuery = {
  __typename?: 'Query'
  legalPageCollection?: {
    __typename?: 'LegalPageCollection'
    items: Array<{
      __typename?: 'LegalPage'
      title?: string | null
      subtitle?: string | null
      slug?: string | null
      sys: { __typename?: 'Sys'; id: string }
      text?: { __typename?: 'LegalPageText'; json: any } | null
      pdfsCollection?: {
        __typename?: 'LegalPagePdfsCollection'
        items: Array<{
          __typename: 'Pdf'
          title?: string | null
          displayDate?: any | null
          sys: { __typename?: 'Sys'; id: string }
          pdf?: {
            __typename?: 'Asset'
            title?: string | null
            description?: string | null
            contentType?: string | null
            fileName?: string | null
            size?: number | null
            url?: string | null
          } | null
        } | null>
      } | null
    } | null>
  } | null
}

export type LegalPageFragment = {
  __typename?: 'LegalPage'
  title?: string | null
  subtitle?: string | null
  slug?: string | null
  sys: { __typename?: 'Sys'; id: string }
  text?: { __typename?: 'LegalPageText'; json: any } | null
  pdfsCollection?: {
    __typename?: 'LegalPagePdfsCollection'
    items: Array<{
      __typename: 'Pdf'
      title?: string | null
      displayDate?: any | null
      sys: { __typename?: 'Sys'; id: string }
      pdf?: {
        __typename?: 'Asset'
        title?: string | null
        description?: string | null
        contentType?: string | null
        fileName?: string | null
        size?: number | null
        url?: string | null
      } | null
    } | null>
  } | null
}

export type GetPressReleasesQueryVariables = Exact<{
  preview?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type GetPressReleasesQuery = {
  __typename?: 'Query'
  pressReleases?: {
    __typename?: 'PwsPressReleaseCollection'
    items: Array<{
      __typename: 'PwsPressRelease'
      description?: string | null
      displayDate?: any | null
      externalNewsLink?: string | null
      title?: string | null
      type?: string | null
      sys: { __typename?: 'Sys'; id: string }
      author?: { __typename?: 'CaisiqAuthor'; name?: string | null } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        height?: number | null
        width?: number | null
        title?: string | null
        alt?: string | null
      } | null
      linkedFrom?: {
        __typename?: 'PwsPressReleaseLinkingCollections'
        pageCollection?: {
          __typename?: 'PageCollection'
          items: Array<{
            __typename?: 'Page'
            slug?: string | null
            title?: string | null
          } | null>
        } | null
      } | null
    } | null>
  } | null
}

export type PressReleaseCardFragment = {
  __typename: 'PwsPressRelease'
  description?: string | null
  displayDate?: any | null
  externalNewsLink?: string | null
  title?: string | null
  type?: string | null
  sys: { __typename?: 'Sys'; id: string }
  author?: { __typename?: 'CaisiqAuthor'; name?: string | null } | null
  thumbnail?: {
    __typename?: 'Asset'
    url?: string | null
    height?: number | null
    width?: number | null
    title?: string | null
    alt?: string | null
  } | null
  linkedFrom?: {
    __typename?: 'PwsPressReleaseLinkingCollections'
    pageCollection?: {
      __typename?: 'PageCollection'
      items: Array<{
        __typename?: 'Page'
        slug?: string | null
        title?: string | null
      } | null>
    } | null
  } | null
}

export type GetAllResearchTagCountsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  articleWhere?: InputMaybe<CaisiqArticleFilter>
  videoWhere?: InputMaybe<CaisiqVideoFilter>
  videoPlaylistWhere?: InputMaybe<CaisiqVideoPlaylistFilter>
  whitepaperWhere?: InputMaybe<WhitepaperFilter>
  externalWhere?: InputMaybe<ExternalContentFilter>
  firmsLimit?: Scalars['Int']
  tagsLimit?: Scalars['Int']
}>

export type GetAllResearchTagCountsQuery = {
  __typename?: 'Query'
  caisiqArticleCollection?: {
    __typename?: 'CaisiqArticleCollection'
    total: number
    items: Array<{
      __typename: 'CaisiqArticle'
      sys: { __typename?: 'Sys'; id: string }
      firmsCollection?: {
        __typename?: 'CaisiqArticleFirmsCollection'
        items: Array<{ __typename?: 'MemberFirm'; id?: string | null } | null>
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      tagsCollection?: {
        __typename?: 'CaisiqArticleTagsCollection'
        items: Array<{ __typename?: 'Tag'; tagId?: string | null } | null>
      } | null
    } | null>
  } | null
  caisiqVideoCollection?: {
    __typename?: 'CaisiqVideoCollection'
    total: number
    items: Array<{
      __typename: 'CaisiqVideo'
      sys: { __typename?: 'Sys'; id: string }
      firmsCollection?: {
        __typename?: 'CaisiqVideoFirmsCollection'
        items: Array<{ __typename?: 'MemberFirm'; id?: string | null } | null>
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      tagsCollection?: {
        __typename?: 'CaisiqVideoTagsCollection'
        items: Array<{ __typename?: 'Tag'; tagId?: string | null } | null>
      } | null
    } | null>
  } | null
  whitepaperCollection?: {
    __typename?: 'WhitepaperCollection'
    total: number
    items: Array<{
      __typename: 'Whitepaper'
      sys: { __typename?: 'Sys'; id: string }
      firmsCollection?: {
        __typename?: 'WhitepaperFirmsCollection'
        items: Array<{ __typename?: 'MemberFirm'; id?: string | null } | null>
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      tagsCollection?: {
        __typename?: 'WhitepaperTagsCollection'
        items: Array<{ __typename?: 'Tag'; tagId?: string | null } | null>
      } | null
    } | null>
  } | null
  caisiqVideoPlaylistCollection?: {
    __typename?: 'CaisiqVideoPlaylistCollection'
    total: number
    items: Array<{
      __typename: 'CaisiqVideoPlaylist'
      sys: { __typename?: 'Sys'; id: string }
      firmsCollection?: {
        __typename?: 'CaisiqVideoPlaylistFirmsCollection'
        items: Array<{ __typename?: 'MemberFirm'; id?: string | null } | null>
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      tagsCollection?: {
        __typename?: 'CaisiqVideoPlaylistTagsCollection'
        items: Array<{ __typename?: 'Tag'; tagId?: string | null } | null>
      } | null
    } | null>
  } | null
  externalContentCollection?: {
    __typename?: 'ExternalContentCollection'
    total: number
    items: Array<{
      __typename: 'ExternalContent'
      sys: { __typename?: 'Sys'; id: string }
      firmsCollection?: {
        __typename?: 'ExternalContentFirmsCollection'
        items: Array<{ __typename?: 'MemberFirm'; id?: string | null } | null>
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      tagsCollection?: {
        __typename?: 'ExternalContentTagsCollection'
        items: Array<{ __typename?: 'Tag'; tagId?: string | null } | null>
      } | null
    } | null>
  } | null
}

export type GetAllResearchQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>
  preview?: InputMaybe<Scalars['Boolean']>
  articleWhere?: InputMaybe<CaisiqArticleFilter>
  videoWhere?: InputMaybe<CaisiqVideoFilter>
  videoPlaylistWhere?: InputMaybe<CaisiqVideoPlaylistFilter>
  whitepaperWhere?: InputMaybe<WhitepaperFilter>
  externalWhere?: InputMaybe<ExternalContentFilter>
  firmsLimit: Scalars['Int']
}>

export type GetAllResearchQuery = {
  __typename?: 'Query'
  caisiqArticleCollection?: {
    __typename?: 'CaisiqArticleCollection'
    total: number
    items: Array<{
      __typename: 'CaisiqArticle'
      slug?: string | null
      title?: string | null
      category?: string | null
      displayDate?: any | null
      description?: string | null
      introduction?: string | null
      byCais?: boolean | null
      sys: {
        __typename?: 'Sys'
        id: string
        firstPublishedAt?: any | null
        publishedAt?: any | null
      }
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      author?: { __typename?: 'CaisiqAuthor'; name?: string | null } | null
      firmsCollection?: {
        __typename?: 'CaisiqArticleFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
    } | null>
  } | null
  caisiqVideoCollection?: {
    __typename?: 'CaisiqVideoCollection'
    total: number
    items: Array<{
      __typename: 'CaisiqVideo'
      byCais?: boolean | null
      title?: string | null
      category?: string | null
      summary?: string | null
      duration?: number | null
      displayDate?: any | null
      muxVideo?: any | null
      poster?: any | null
      icon?: any | null
      muxThumbnailTime?: number | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      video?: { __typename?: 'Asset'; url?: string | null } | null
    } | null>
  } | null
  caisiqVideoPlaylistCollection?: {
    __typename?: 'CaisiqVideoPlaylistCollection'
    total: number
    items: Array<{
      __typename: 'CaisiqVideoPlaylist'
      episodic?: boolean | null
      title?: string | null
      category?: string | null
      summary?: string | null
      displayDate?: any | null
      byCais?: boolean | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      heroImage?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoPlaylistFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      videosCollection?: {
        __typename?: 'CaisiqVideoPlaylistVideosCollection'
        items: Array<{
          __typename?: 'CaisiqVideo'
          poster?: any | null
          sys: { __typename?: 'Sys'; id: string }
          thumbnail?: {
            __typename?: 'Asset'
            url?: string | null
            width?: number | null
            height?: number | null
          } | null
        } | null>
      } | null
    } | null>
  } | null
  whitepaperCollection?: {
    __typename?: 'WhitepaperCollection'
    total: number
    items: Array<{
      __typename: 'Whitepaper'
      byCais?: boolean | null
      type?: string | null
      slug?: string | null
      title?: string | null
      category?: string | null
      displayDate?: any | null
      introduction?: string | null
      description?: string | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'WhitepaperFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      sys: {
        __typename?: 'Sys'
        id: string
        firstPublishedAt?: any | null
        publishedAt?: any | null
      }
      author?: { __typename?: 'CaisiqAuthor'; name?: string | null } | null
      hero?: { __typename?: 'Asset'; url?: string | null } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      pdfsCollection?: {
        __typename?: 'WhitepaperPdfsCollection'
        items: Array<{
          __typename?: 'Pdf'
          title?: string | null
          displayDate?: any | null
          pdf?: { __typename?: 'Asset'; url?: string | null } | null
          image?: { __typename?: 'Asset'; url?: string | null } | null
        } | null>
      } | null
    } | null>
  } | null
  externalContentCollection?: {
    __typename?: 'ExternalContentCollection'
    total: number
    items: Array<{
      __typename: 'ExternalContent'
      type?: string | null
      title?: string | null
      displayDate?: any | null
      description?: string | null
      externalId?: string | null
      externalLink?: string | null
      strategy?: string | null
      subStrategy?: string | null
      eligibility?: string | null
      byCais?: boolean | null
      minimumInitialInvestment?: number | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      tagsCollection?: {
        __typename?: 'ExternalContentTagsCollection'
        items: Array<{
          __typename?: 'Tag'
          label?: string | null
          tagId?: string | null
        } | null>
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'ExternalContentFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
        alt?: string | null
      } | null
    } | null>
  } | null
}

export type GetCuratedContentFiltersQueryVariables = Exact<{
  id: Scalars['String']
  preview?: InputMaybe<Scalars['Boolean']>
  tagsLimit?: Scalars['Int']
}>

export type GetCuratedContentFiltersQuery = {
  __typename?: 'Query'
  curatedResearchContentCollection?: {
    __typename?: 'CuratedResearchContentCollection'
    items: Array<{
      __typename?: 'CuratedResearchContent'
      curatedFilterGroupCollection?: {
        __typename?: 'CuratedResearchContentCuratedFilterGroupCollection'
        total: number
        items: Array<{
          __typename?: 'CuratedFilterGroup'
          label?: string | null
          filtersCollection?: {
            __typename?: 'CuratedFilterGroupFiltersCollection'
            items: Array<{
              __typename?: 'TagFamily'
              tagFamilyType?: string | null
              tagsCollection?: {
                __typename?: 'TagFamilyTagsCollection'
                items: Array<{
                  __typename?: 'Tag'
                  tagId?: string | null
                } | null>
              } | null
            } | null>
          } | null
        } | null>
      } | null
    } | null>
  } | null
}

export type GetCuratedFilterGroupQueryVariables = Exact<{
  label: Scalars['String']
}>

export type GetCuratedFilterGroupQuery = {
  __typename?: 'Query'
  curatedFilterGroupCollection?: {
    __typename?: 'CuratedFilterGroupCollection'
    items: Array<{
      __typename?: 'CuratedFilterGroup'
      label?: string | null
      filtersCollection?: {
        __typename?: 'CuratedFilterGroupFiltersCollection'
        items: Array<{
          __typename?: 'TagFamily'
          tagFamilyType?: string | null
          sys: { __typename?: 'Sys'; id: string }
          tagsCollection?: {
            __typename?: 'TagFamilyTagsCollection'
            items: Array<{
              __typename?: 'Tag'
              tagId?: string | null
              label?: string | null
              sys: { __typename?: 'Sys'; id: string }
            } | null>
          } | null
        } | null>
      } | null
    } | null>
  } | null
}

export type GetFeaturedResearchContentQueryVariables = Exact<{
  preview?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  articleWhere?: InputMaybe<CaisiqArticleFilter>
  videoWhere?: InputMaybe<CaisiqVideoFilter>
  videoPlaylistWhere?: InputMaybe<CaisiqVideoPlaylistFilter>
  whitepaperWhere?: InputMaybe<WhitepaperFilter>
  externalWhere?: InputMaybe<ExternalContentFilter>
  firmsLimit: Scalars['Int']
}>

export type GetFeaturedResearchContentQuery = {
  __typename?: 'Query'
  articles?: {
    __typename?: 'CaisiqArticleCollection'
    items: Array<{
      __typename: 'CaisiqArticle'
      slug?: string | null
      title?: string | null
      category?: string | null
      displayDate?: any | null
      description?: string | null
      introduction?: string | null
      byCais?: boolean | null
      sys: {
        __typename?: 'Sys'
        id: string
        firstPublishedAt?: any | null
        publishedAt?: any | null
      }
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      author?: { __typename?: 'CaisiqAuthor'; name?: string | null } | null
      firmsCollection?: {
        __typename?: 'CaisiqArticleFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
    } | null>
  } | null
  whitepapers?: {
    __typename?: 'WhitepaperCollection'
    items: Array<{
      __typename: 'Whitepaper'
      byCais?: boolean | null
      type?: string | null
      slug?: string | null
      title?: string | null
      category?: string | null
      displayDate?: any | null
      introduction?: string | null
      description?: string | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'WhitepaperFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      sys: {
        __typename?: 'Sys'
        id: string
        firstPublishedAt?: any | null
        publishedAt?: any | null
      }
      author?: { __typename?: 'CaisiqAuthor'; name?: string | null } | null
      hero?: { __typename?: 'Asset'; url?: string | null } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      pdfsCollection?: {
        __typename?: 'WhitepaperPdfsCollection'
        items: Array<{
          __typename?: 'Pdf'
          title?: string | null
          displayDate?: any | null
          pdf?: { __typename?: 'Asset'; url?: string | null } | null
          image?: { __typename?: 'Asset'; url?: string | null } | null
        } | null>
      } | null
    } | null>
  } | null
  videos?: {
    __typename?: 'CaisiqVideoCollection'
    items: Array<{
      __typename: 'CaisiqVideo'
      byCais?: boolean | null
      title?: string | null
      category?: string | null
      summary?: string | null
      duration?: number | null
      displayDate?: any | null
      muxVideo?: any | null
      poster?: any | null
      icon?: any | null
      muxThumbnailTime?: number | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      video?: { __typename?: 'Asset'; url?: string | null } | null
    } | null>
  } | null
  videoPlaylists?: {
    __typename?: 'CaisiqVideoPlaylistCollection'
    items: Array<{
      __typename: 'CaisiqVideoPlaylist'
      episodic?: boolean | null
      title?: string | null
      category?: string | null
      summary?: string | null
      displayDate?: any | null
      byCais?: boolean | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      heroImage?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoPlaylistFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      videosCollection?: {
        __typename?: 'CaisiqVideoPlaylistVideosCollection'
        items: Array<{
          __typename?: 'CaisiqVideo'
          poster?: any | null
          sys: { __typename?: 'Sys'; id: string }
          thumbnail?: {
            __typename?: 'Asset'
            url?: string | null
            width?: number | null
            height?: number | null
          } | null
        } | null>
      } | null
    } | null>
  } | null
  externalContentCollection?: {
    __typename?: 'ExternalContentCollection'
    items: Array<{
      __typename: 'ExternalContent'
      type?: string | null
      title?: string | null
      displayDate?: any | null
      description?: string | null
      externalId?: string | null
      externalLink?: string | null
      strategy?: string | null
      subStrategy?: string | null
      eligibility?: string | null
      byCais?: boolean | null
      minimumInitialInvestment?: number | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      tagsCollection?: {
        __typename?: 'ExternalContentTagsCollection'
        items: Array<{
          __typename?: 'Tag'
          label?: string | null
          tagId?: string | null
        } | null>
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'ExternalContentFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
        alt?: string | null
      } | null
    } | null>
  } | null
}

export type GetFeaturedResearchQueryVariables = Exact<{
  featuredId: Scalars['String']
  fallbackWhere?: InputMaybe<FeaturedContentResearchDefaultFilter>
  optionsWhere: FeaturedContentResearchOptionsFilter
  preview?: InputMaybe<Scalars['Boolean']>
  firmsLimit: Scalars['Int']
}>

export type GetFeaturedResearchQuery = {
  __typename?: 'Query'
  featured?: {
    __typename?: 'FeaturedContentCollection'
    items: Array<{
      __typename?: 'FeaturedContent'
      fallback?: {
        __typename?: 'FeaturedContentResearchDefaultCollection'
        items: Array<
          | {
              __typename: 'CaisiqArticle'
              slug?: string | null
              title?: string | null
              category?: string | null
              displayDate?: any | null
              description?: string | null
              introduction?: string | null
              byCais?: boolean | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
                publishedAt?: any | null
              }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqArticleFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
            }
          | {
              __typename: 'CaisiqVideo'
              byCais?: boolean | null
              title?: string | null
              category?: string | null
              summary?: string | null
              duration?: number | null
              displayDate?: any | null
              muxVideo?: any | null
              poster?: any | null
              icon?: any | null
              muxThumbnailTime?: number | null
              description?: string | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqVideoFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              video?: { __typename?: 'Asset'; url?: string | null } | null
            }
          | {
              __typename: 'CaisiqVideoPlaylist'
              episodic?: boolean | null
              title?: string | null
              category?: string | null
              summary?: string | null
              displayDate?: any | null
              byCais?: boolean | null
              description?: string | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              heroImage?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqVideoPlaylistFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              videosCollection?: {
                __typename?: 'CaisiqVideoPlaylistVideosCollection'
                items: Array<{
                  __typename?: 'CaisiqVideo'
                  poster?: any | null
                  sys: { __typename?: 'Sys'; id: string }
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                    width?: number | null
                    height?: number | null
                  } | null
                } | null>
              } | null
            }
          | {
              __typename: 'ExternalContent'
              type?: string | null
              title?: string | null
              displayDate?: any | null
              description?: string | null
              externalId?: string | null
              externalLink?: string | null
              strategy?: string | null
              subStrategy?: string | null
              eligibility?: string | null
              byCais?: boolean | null
              minimumInitialInvestment?: number | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              tagsCollection?: {
                __typename?: 'ExternalContentTagsCollection'
                items: Array<{
                  __typename?: 'Tag'
                  label?: string | null
                  tagId?: string | null
                } | null>
              } | null
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'ExternalContentFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
                alt?: string | null
              } | null
            }
          | {
              __typename: 'Link'
              openInNewWindow?: boolean | null
              description?: string | null
              internalLink?: string | null
              byCais?: boolean | null
              title?: string | null
              poster?: any | null
              sys: { __typename?: 'Sys'; id: string }
              cmsLink?:
                | { __typename?: 'CaisiqArticle' }
                | { __typename: 'HomepageLandingPage'; slug?: string | null }
                | { __typename?: 'Page' }
                | { __typename: 'PageResource'; slug?: string | null }
                | { __typename?: 'PwsPressRelease' }
                | { __typename?: 'Whitepaper' }
                | null
            }
          | {
              __typename: 'Whitepaper'
              byCais?: boolean | null
              type?: string | null
              slug?: string | null
              title?: string | null
              category?: string | null
              displayDate?: any | null
              introduction?: string | null
              description?: string | null
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'WhitepaperFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
                publishedAt?: any | null
              }
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              hero?: { __typename?: 'Asset'; url?: string | null } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              pdfsCollection?: {
                __typename?: 'WhitepaperPdfsCollection'
                items: Array<{
                  __typename?: 'Pdf'
                  title?: string | null
                  displayDate?: any | null
                  pdf?: { __typename?: 'Asset'; url?: string | null } | null
                  image?: { __typename?: 'Asset'; url?: string | null } | null
                } | null>
              } | null
            }
          | null
        >
      } | null
      options?: {
        __typename?: 'FeaturedContentResearchOptionsCollection'
        items: Array<
          | {
              __typename: 'CaisiqArticle'
              slug?: string | null
              title?: string | null
              category?: string | null
              displayDate?: any | null
              description?: string | null
              introduction?: string | null
              byCais?: boolean | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
                publishedAt?: any | null
              }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqArticleFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
            }
          | {
              __typename: 'CaisiqVideo'
              byCais?: boolean | null
              title?: string | null
              category?: string | null
              summary?: string | null
              duration?: number | null
              displayDate?: any | null
              muxVideo?: any | null
              poster?: any | null
              icon?: any | null
              muxThumbnailTime?: number | null
              description?: string | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqVideoFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              video?: { __typename?: 'Asset'; url?: string | null } | null
            }
          | {
              __typename: 'CaisiqVideoPlaylist'
              episodic?: boolean | null
              title?: string | null
              category?: string | null
              summary?: string | null
              displayDate?: any | null
              byCais?: boolean | null
              description?: string | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              heroImage?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqVideoPlaylistFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              videosCollection?: {
                __typename?: 'CaisiqVideoPlaylistVideosCollection'
                items: Array<{
                  __typename?: 'CaisiqVideo'
                  poster?: any | null
                  sys: { __typename?: 'Sys'; id: string }
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                    width?: number | null
                    height?: number | null
                  } | null
                } | null>
              } | null
            }
          | {
              __typename: 'ExternalContent'
              type?: string | null
              title?: string | null
              displayDate?: any | null
              description?: string | null
              externalId?: string | null
              externalLink?: string | null
              strategy?: string | null
              subStrategy?: string | null
              eligibility?: string | null
              byCais?: boolean | null
              minimumInitialInvestment?: number | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              tagsCollection?: {
                __typename?: 'ExternalContentTagsCollection'
                items: Array<{
                  __typename?: 'Tag'
                  label?: string | null
                  tagId?: string | null
                } | null>
              } | null
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'ExternalContentFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
                alt?: string | null
              } | null
            }
          | {
              __typename: 'Link'
              openInNewWindow?: boolean | null
              description?: string | null
              internalLink?: string | null
              byCais?: boolean | null
              title?: string | null
              poster?: any | null
              sys: { __typename?: 'Sys'; id: string }
              cmsLink?:
                | { __typename?: 'CaisiqArticle' }
                | { __typename: 'HomepageLandingPage'; slug?: string | null }
                | { __typename?: 'Page' }
                | { __typename: 'PageResource'; slug?: string | null }
                | { __typename?: 'PwsPressRelease' }
                | { __typename?: 'Whitepaper' }
                | null
            }
          | {
              __typename: 'Whitepaper'
              byCais?: boolean | null
              type?: string | null
              slug?: string | null
              title?: string | null
              category?: string | null
              displayDate?: any | null
              introduction?: string | null
              description?: string | null
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'WhitepaperFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
                publishedAt?: any | null
              }
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              hero?: { __typename?: 'Asset'; url?: string | null } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              pdfsCollection?: {
                __typename?: 'WhitepaperPdfsCollection'
                items: Array<{
                  __typename?: 'Pdf'
                  title?: string | null
                  displayDate?: any | null
                  pdf?: { __typename?: 'Asset'; url?: string | null } | null
                  image?: { __typename?: 'Asset'; url?: string | null } | null
                } | null>
              } | null
            }
          | null
        >
      } | null
    } | null>
  } | null
}

export type GetFilterGroupByIdQueryVariables = Exact<{
  id: Scalars['String']
  preview?: InputMaybe<Scalars['Boolean']>
  tagsLimit?: Scalars['Int']
}>

export type GetFilterGroupByIdQuery = {
  __typename?: 'Query'
  filterGroupCollection?: {
    __typename?: 'FilterGroupCollection'
    items: Array<{
      __typename?: 'FilterGroup'
      filtersCollection?: {
        __typename?: 'FilterGroupFiltersCollection'
        items: Array<{
          __typename?: 'TagFamily'
          name?: string | null
          tagsCollection?: {
            __typename?: 'TagFamilyTagsCollection'
            items: Array<{
              __typename?: 'Tag'
              label?: string | null
              tagId?: string | null
            } | null>
          } | null
        } | null>
      } | null
    } | null>
  } | null
}

export type AssetFragment = {
  __typename?: 'Asset'
  height?: number | null
  title?: string | null
  url?: string | null
  width?: number | null
  alt?: string | null
  sys: { __typename?: 'Sys'; id: string }
}

export type SysFragment = {
  __typename?: 'Sys'
  id: string
  firstPublishedAt?: any | null
}

type Entry_AccessControl_Fragment = {
  __typename?: 'AccessControl'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_AccessControl2_Fragment = {
  __typename?: 'AccessControl2'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_AccessControl3_Fragment = {
  __typename?: 'AccessControl3'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Accordion_Fragment = {
  __typename?: 'Accordion'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_AccordionWithImage_Fragment = {
  __typename?: 'AccordionWithImage'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Announcement_Fragment = {
  __typename?: 'Announcement'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_AnnouncementSet_Fragment = {
  __typename?: 'AnnouncementSet'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_CaisiqArticle_Fragment = {
  __typename?: 'CaisiqArticle'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_CaisiqArticleDropdown_Fragment = {
  __typename?: 'CaisiqArticleDropdown'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_CaisiqAuthor_Fragment = {
  __typename?: 'CaisiqAuthor'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_CaisiqDefaultCourseImageSet_Fragment = {
  __typename?: 'CaisiqDefaultCourseImageSet'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_CaisiqDefaultFundsThumbnailSet_Fragment = {
  __typename?: 'CaisiqDefaultFundsThumbnailSet'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_CaisiqFundThumbnail_Fragment = {
  __typename?: 'CaisiqFundThumbnail'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_CaisiqFundThumbnailSet_Fragment = {
  __typename?: 'CaisiqFundThumbnailSet'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_CaisiqHeroHeader_Fragment = {
  __typename?: 'CaisiqHeroHeader'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_CaisiqHeroHeaderNavigateToButton_Fragment = {
  __typename?: 'CaisiqHeroHeaderNavigateToButton'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_CaisiqHomeOffice_Fragment = {
  __typename?: 'CaisiqHomeOffice'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_CaisiqJoinCaisCallToAction_Fragment = {
  __typename?: 'CaisiqJoinCaisCallToAction'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_CaisiqTab_Fragment = {
  __typename?: 'CaisiqTab'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_CaisiqVideo_Fragment = {
  __typename?: 'CaisiqVideo'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_CaisiqVideoPlaylist_Fragment = {
  __typename?: 'CaisiqVideoPlaylist'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_CallToAction_Fragment = {
  __typename?: 'CallToAction'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Chart_Fragment = {
  __typename?: 'Chart'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_ChildFirm_Fragment = {
  __typename?: 'ChildFirm'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_CiqArticle_Fragment = {
  __typename?: 'CiqArticle'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Company_Fragment = {
  __typename?: 'Company'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_CompanyAndContactInfo_Fragment = {
  __typename?: 'CompanyAndContactInfo'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_ComponentCard_Fragment = {
  __typename?: 'ComponentCard'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_ComponentCompanyContainer_Fragment = {
  __typename?: 'ComponentCompanyContainer'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_ComponentHero_Fragment = {
  __typename?: 'ComponentHero'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_ComponentHubspotForm_Fragment = {
  __typename?: 'ComponentHubspotForm'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_ComponentImage_Fragment = {
  __typename?: 'ComponentImage'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_ComponentPodcastPlayer_Fragment = {
  __typename?: 'ComponentPodcastPlayer'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_ComponentSection_Fragment = {
  __typename?: 'ComponentSection'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_ComponentSummary_Fragment = {
  __typename?: 'ComponentSummary'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_ComponentTeamContainer_Fragment = {
  __typename?: 'ComponentTeamContainer'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_ComponentText_Fragment = {
  __typename?: 'ComponentText'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_ComponentTextIllustration_Fragment = {
  __typename?: 'ComponentTextIllustration'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_ComponentTextImage_Fragment = {
  __typename?: 'ComponentTextImage'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_ComponentTextImageImageSection_Fragment = {
  __typename?: 'ComponentTextImageImageSection'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_ComponentTextImageListSection_Fragment = {
  __typename?: 'ComponentTextImageListSection'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_ComponentTextImageTextSection_Fragment = {
  __typename?: 'ComponentTextImageTextSection'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_CorporateStats_Fragment = {
  __typename?: 'CorporateStats'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_CuratedFilterGroup_Fragment = {
  __typename?: 'CuratedFilterGroup'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_CuratedResearchContent_Fragment = {
  __typename?: 'CuratedResearchContent'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Disclaimer_Fragment = {
  __typename?: 'Disclaimer'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Event_Fragment = {
  __typename?: 'Event'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_ExternalContent_Fragment = {
  __typename?: 'ExternalContent'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Faq_Fragment = {
  __typename?: 'Faq'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_FaqCategory_Fragment = {
  __typename?: 'FaqCategory'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_FeaturedContent_Fragment = {
  __typename?: 'FeaturedContent'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_FilterGroup_Fragment = {
  __typename?: 'FilterGroup'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Firm_Fragment = {
  __typename?: 'Firm'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_FullWidthImage_Fragment = {
  __typename?: 'FullWidthImage'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_FundProductId_Fragment = {
  __typename?: 'FundProductId'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_GlossaryTerm_Fragment = {
  __typename?: 'GlossaryTerm'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_HomepageAppMetadata_Fragment = {
  __typename?: 'HomepageAppMetadata'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_HomepageContentSection_Fragment = {
  __typename?: 'HomepageContentSection'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_HomepageLandingPage_Fragment = {
  __typename?: 'HomepageLandingPage'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_HtmlTable_Fragment = {
  __typename?: 'HtmlTable'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_ImageSection_Fragment = {
  __typename?: 'ImageSection'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_LegalPage_Fragment = {
  __typename?: 'LegalPage'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Link_Fragment = {
  __typename?: 'Link'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_LoginScreen_Fragment = {
  __typename?: 'LoginScreen'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Markdown_Fragment = {
  __typename?: 'Markdown'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_MemberFirm_Fragment = {
  __typename?: 'MemberFirm'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Migration_Fragment = {
  __typename?: 'Migration'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_NavHeaderTheme_Fragment = {
  __typename?: 'NavHeaderTheme'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Page_Fragment = {
  __typename?: 'Page'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_PageHelpCenterArticle_Fragment = {
  __typename?: 'PageHelpCenterArticle'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_PageLanding_Fragment = {
  __typename?: 'PageLanding'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_PagePwsCategory_Fragment = {
  __typename?: 'PagePwsCategory'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_PageResource_Fragment = {
  __typename?: 'PageResource'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_ParentFirm_Fragment = {
  __typename?: 'ParentFirm'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Pdf_Fragment = {
  __typename?: 'Pdf'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Person_Fragment = {
  __typename?: 'Person'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Pet_Fragment = {
  __typename?: 'Pet'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_PlaylistContentSet_Fragment = {
  __typename?: 'PlaylistContentSet'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Presentation_Fragment = {
  __typename?: 'Presentation'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_PressAward_Fragment = {
  __typename?: 'PressAward'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_PwsContactMedia_Fragment = {
  __typename?: 'PwsContactMedia'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_PwsInsightsContentSet_Fragment = {
  __typename?: 'PwsInsightsContentSet'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_PwsPressRelease_Fragment = {
  __typename?: 'PwsPressRelease'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_PwsSiteSettings_Fragment = {
  __typename?: 'PwsSiteSettings'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Quote_Fragment = {
  __typename?: 'Quote'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_RelatedContent_Fragment = {
  __typename?: 'RelatedContent'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_RelatedContentStrategy_Fragment = {
  __typename?: 'RelatedContentStrategy'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Seo_Fragment = {
  __typename?: 'Seo'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_SkPlayground_Fragment = {
  __typename?: 'SkPlayground'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Tag_Fragment = {
  __typename?: 'Tag'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_TagFamily_Fragment = {
  __typename?: 'TagFamily'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Team_Fragment = {
  __typename?: 'Team'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_TestModel_Fragment = {
  __typename?: 'TestModel'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Theme_Fragment = {
  __typename?: 'Theme'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

type Entry_Whitepaper_Fragment = {
  __typename?: 'Whitepaper'
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
}

export type EntryFragment =
  | Entry_AccessControl_Fragment
  | Entry_AccessControl2_Fragment
  | Entry_AccessControl3_Fragment
  | Entry_Accordion_Fragment
  | Entry_AccordionWithImage_Fragment
  | Entry_Announcement_Fragment
  | Entry_AnnouncementSet_Fragment
  | Entry_CaisiqArticle_Fragment
  | Entry_CaisiqArticleDropdown_Fragment
  | Entry_CaisiqAuthor_Fragment
  | Entry_CaisiqDefaultCourseImageSet_Fragment
  | Entry_CaisiqDefaultFundsThumbnailSet_Fragment
  | Entry_CaisiqFundThumbnail_Fragment
  | Entry_CaisiqFundThumbnailSet_Fragment
  | Entry_CaisiqHeroHeader_Fragment
  | Entry_CaisiqHeroHeaderNavigateToButton_Fragment
  | Entry_CaisiqHomeOffice_Fragment
  | Entry_CaisiqJoinCaisCallToAction_Fragment
  | Entry_CaisiqTab_Fragment
  | Entry_CaisiqVideo_Fragment
  | Entry_CaisiqVideoPlaylist_Fragment
  | Entry_CallToAction_Fragment
  | Entry_Chart_Fragment
  | Entry_ChildFirm_Fragment
  | Entry_CiqArticle_Fragment
  | Entry_Company_Fragment
  | Entry_CompanyAndContactInfo_Fragment
  | Entry_ComponentCard_Fragment
  | Entry_ComponentCompanyContainer_Fragment
  | Entry_ComponentHero_Fragment
  | Entry_ComponentHubspotForm_Fragment
  | Entry_ComponentImage_Fragment
  | Entry_ComponentPodcastPlayer_Fragment
  | Entry_ComponentSection_Fragment
  | Entry_ComponentSummary_Fragment
  | Entry_ComponentTeamContainer_Fragment
  | Entry_ComponentText_Fragment
  | Entry_ComponentTextIllustration_Fragment
  | Entry_ComponentTextImage_Fragment
  | Entry_ComponentTextImageImageSection_Fragment
  | Entry_ComponentTextImageListSection_Fragment
  | Entry_ComponentTextImageTextSection_Fragment
  | Entry_CorporateStats_Fragment
  | Entry_CuratedFilterGroup_Fragment
  | Entry_CuratedResearchContent_Fragment
  | Entry_Disclaimer_Fragment
  | Entry_Event_Fragment
  | Entry_ExternalContent_Fragment
  | Entry_Faq_Fragment
  | Entry_FaqCategory_Fragment
  | Entry_FeaturedContent_Fragment
  | Entry_FilterGroup_Fragment
  | Entry_Firm_Fragment
  | Entry_FullWidthImage_Fragment
  | Entry_FundProductId_Fragment
  | Entry_GlossaryTerm_Fragment
  | Entry_HomepageAppMetadata_Fragment
  | Entry_HomepageContentSection_Fragment
  | Entry_HomepageLandingPage_Fragment
  | Entry_HtmlTable_Fragment
  | Entry_ImageSection_Fragment
  | Entry_LegalPage_Fragment
  | Entry_Link_Fragment
  | Entry_LoginScreen_Fragment
  | Entry_Markdown_Fragment
  | Entry_MemberFirm_Fragment
  | Entry_Migration_Fragment
  | Entry_NavHeaderTheme_Fragment
  | Entry_Page_Fragment
  | Entry_PageHelpCenterArticle_Fragment
  | Entry_PageLanding_Fragment
  | Entry_PagePwsCategory_Fragment
  | Entry_PageResource_Fragment
  | Entry_ParentFirm_Fragment
  | Entry_Pdf_Fragment
  | Entry_Person_Fragment
  | Entry_Pet_Fragment
  | Entry_PlaylistContentSet_Fragment
  | Entry_Presentation_Fragment
  | Entry_PressAward_Fragment
  | Entry_PwsContactMedia_Fragment
  | Entry_PwsInsightsContentSet_Fragment
  | Entry_PwsPressRelease_Fragment
  | Entry_PwsSiteSettings_Fragment
  | Entry_Quote_Fragment
  | Entry_RelatedContent_Fragment
  | Entry_RelatedContentStrategy_Fragment
  | Entry_Seo_Fragment
  | Entry_SkPlayground_Fragment
  | Entry_Tag_Fragment
  | Entry_TagFamily_Fragment
  | Entry_Team_Fragment
  | Entry_TestModel_Fragment
  | Entry_Theme_Fragment
  | Entry_Whitepaper_Fragment

export type AuthorFragment = {
  __typename?: 'CaisiqAuthor'
  name?: string | null
  authorName?: string | null
  jobTitle?: string | null
  firm?: string | null
  imageSet?: any | null
  avatar?: {
    __typename?: 'ComponentImage'
    altText?: string | null
    image?: {
      __typename?: 'Asset'
      url?: string | null
      title?: string | null
      width?: number | null
      height?: number | null
    } | null
  } | null
  linkedin?: { __typename?: 'Link'; externalLink?: string | null } | null
}

export type CallToActionFieldsFragment = {
  __typename: 'CallToAction'
  title?: string | null
  id?: string | null
  description?: string | null
  buttonText?: string | null
  imageSet?: any | null
  byCais?: boolean | null
  sys: { __typename?: 'Sys'; id: string }
  destination?: {
    __typename?: 'Link'
    openInNewWindow?: boolean | null
    externalLink?: string | null
    internalLink?: string | null
    cmsLink?:
      | { __typename?: 'CaisiqArticle' }
      | { __typename?: 'HomepageLandingPage' }
      | { __typename?: 'Page' }
      | { __typename: 'PageResource'; slug?: string | null }
      | { __typename?: 'PwsPressRelease' }
      | { __typename?: 'Whitepaper' }
      | null
  } | null
  mediaFile?: {
    __typename?: 'Asset'
    url?: string | null
    fileName?: string | null
  } | null
  firm?: {
    __typename?: 'MemberFirm'
    id?: string | null
    name?: string | null
  } | null
}

export type ComponentImageFragment = {
  __typename: 'ComponentImage'
  title?: string | null
  cloudinaryImage?: any | null
  altText?: string | null
  sys: { __typename?: 'Sys'; id: string }
  image?: {
    __typename?: 'Asset'
    height?: number | null
    title?: string | null
    url?: string | null
    width?: number | null
    alt?: string | null
    sys: { __typename?: 'Sys'; id: string }
  } | null
}

export type ComponentSectionFragment = {
  __typename: 'ComponentSection'
  componentId?: string | null
  cardContainerOptions?: string | null
  title?: string | null
  sys: { __typename?: 'Sys'; id: string }
  componentSectionDescription?: {
    __typename?: 'ComponentSectionDescription'
    json: any
    links: {
      __typename?: 'ComponentSectionDescriptionLinks'
      entries: {
        __typename?: 'ComponentSectionDescriptionEntries'
        hyperlink: Array<
          | { __typename?: 'AccessControl' }
          | { __typename?: 'AccessControl2' }
          | { __typename?: 'AccessControl3' }
          | { __typename?: 'Accordion' }
          | { __typename?: 'AccordionWithImage' }
          | { __typename?: 'Announcement' }
          | { __typename?: 'AnnouncementSet' }
          | { __typename?: 'CaisiqArticle' }
          | { __typename?: 'CaisiqArticleDropdown' }
          | { __typename?: 'CaisiqAuthor' }
          | { __typename?: 'CaisiqDefaultCourseImageSet' }
          | { __typename?: 'CaisiqDefaultFundsThumbnailSet' }
          | { __typename?: 'CaisiqFundThumbnail' }
          | { __typename?: 'CaisiqFundThumbnailSet' }
          | { __typename?: 'CaisiqHeroHeader' }
          | { __typename?: 'CaisiqHeroHeaderNavigateToButton' }
          | { __typename?: 'CaisiqHomeOffice' }
          | { __typename?: 'CaisiqJoinCaisCallToAction' }
          | { __typename?: 'CaisiqTab' }
          | { __typename?: 'CaisiqVideo' }
          | { __typename?: 'CaisiqVideoPlaylist' }
          | { __typename?: 'CallToAction' }
          | { __typename?: 'Chart' }
          | { __typename?: 'ChildFirm' }
          | { __typename?: 'CiqArticle' }
          | { __typename?: 'Company' }
          | { __typename?: 'CompanyAndContactInfo' }
          | { __typename?: 'ComponentCard' }
          | { __typename?: 'ComponentCompanyContainer' }
          | { __typename?: 'ComponentHero' }
          | { __typename?: 'ComponentHubspotForm' }
          | { __typename?: 'ComponentImage' }
          | { __typename?: 'ComponentPodcastPlayer' }
          | { __typename?: 'ComponentSection' }
          | { __typename?: 'ComponentSummary' }
          | { __typename?: 'ComponentTeamContainer' }
          | { __typename?: 'ComponentText' }
          | { __typename?: 'ComponentTextIllustration' }
          | { __typename?: 'ComponentTextImage' }
          | { __typename?: 'ComponentTextImageImageSection' }
          | { __typename?: 'ComponentTextImageListSection' }
          | { __typename?: 'ComponentTextImageTextSection' }
          | { __typename?: 'CorporateStats' }
          | { __typename?: 'CuratedFilterGroup' }
          | { __typename?: 'CuratedResearchContent' }
          | { __typename?: 'Disclaimer' }
          | { __typename?: 'Event' }
          | { __typename?: 'ExternalContent' }
          | { __typename?: 'Faq' }
          | { __typename?: 'FaqCategory' }
          | { __typename?: 'FeaturedContent' }
          | { __typename?: 'FilterGroup' }
          | { __typename?: 'Firm' }
          | { __typename?: 'FullWidthImage' }
          | { __typename?: 'FundProductId' }
          | { __typename?: 'GlossaryTerm' }
          | { __typename?: 'HomepageAppMetadata' }
          | { __typename?: 'HomepageContentSection' }
          | { __typename?: 'HomepageLandingPage' }
          | { __typename?: 'HtmlTable' }
          | { __typename?: 'ImageSection' }
          | { __typename?: 'LegalPage' }
          | { __typename?: 'Link' }
          | { __typename?: 'LoginScreen' }
          | { __typename?: 'Markdown' }
          | { __typename?: 'MemberFirm' }
          | { __typename?: 'Migration' }
          | { __typename?: 'NavHeaderTheme' }
          | {
              __typename?: 'Page'
              name?: string | null
              slug?: string | null
              sys: { __typename?: 'Sys'; id: string }
              content?:
                | { __typename: 'CaisiqArticle' }
                | { __typename: 'CaisiqVideo' }
                | { __typename: 'CaisiqVideoPlaylist' }
                | { __typename: 'Company' }
                | { __typename: 'PageHelpCenterArticle' }
                | { __typename: 'PageLanding' }
                | { __typename: 'PagePwsCategory' }
                | { __typename: 'Person' }
                | { __typename: 'PwsPressRelease' }
                | { __typename: 'Whitepaper' }
                | null
            }
          | { __typename?: 'PageHelpCenterArticle' }
          | { __typename?: 'PageLanding' }
          | { __typename?: 'PagePwsCategory' }
          | { __typename?: 'PageResource' }
          | { __typename?: 'ParentFirm' }
          | { __typename?: 'Pdf' }
          | { __typename?: 'Person' }
          | { __typename?: 'Pet' }
          | { __typename?: 'PlaylistContentSet' }
          | { __typename?: 'Presentation' }
          | { __typename?: 'PressAward' }
          | { __typename?: 'PwsContactMedia' }
          | { __typename?: 'PwsInsightsContentSet' }
          | { __typename?: 'PwsPressRelease' }
          | { __typename?: 'PwsSiteSettings' }
          | { __typename?: 'Quote' }
          | { __typename?: 'RelatedContent' }
          | { __typename?: 'RelatedContentStrategy' }
          | { __typename?: 'Seo' }
          | { __typename?: 'SkPlayground' }
          | { __typename?: 'Tag' }
          | { __typename?: 'TagFamily' }
          | { __typename?: 'Team' }
          | { __typename?: 'TestModel' }
          | { __typename?: 'Theme' }
          | { __typename?: 'Whitepaper' }
          | null
        >
        inline: Array<
          | {
              __typename: 'AccessControl'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'AccessControl2'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'AccessControl3'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Accordion'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'AccordionWithImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Announcement'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'AnnouncementSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqArticle'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqArticleDropdown'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqAuthor'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqDefaultCourseImageSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqDefaultFundsThumbnailSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqFundThumbnail'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqFundThumbnailSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqHeroHeader'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqHeroHeaderNavigateToButton'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqHomeOffice'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqJoinCaisCallToAction'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'CaisiqTab'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CaisiqVideo'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqVideoPlaylist'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CallToAction'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Chart'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'ChildFirm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CiqArticle'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Company'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CompanyAndContactInfo'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentCard'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentCompanyContainer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentHero'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentHubspotForm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentPodcastPlayer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentSummary'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTeamContainer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentText'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextIllustration'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImageImageSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImageListSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImageTextSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CorporateStats'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CuratedFilterGroup'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CuratedResearchContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Disclaimer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Event'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ExternalContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Faq'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FaqCategory'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'FeaturedContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'FilterGroup'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Firm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FullWidthImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'FundProductId'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'GlossaryTerm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'HomepageAppMetadata'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'HomepageContentSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'HomepageLandingPage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'HtmlTable'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ImageSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'LegalPage'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Link'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'LoginScreen'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Markdown'
              text?: string | null
              type?: string | null
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'MemberFirm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Migration'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'NavHeaderTheme'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PageHelpCenterArticle'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PageLanding'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PagePwsCategory'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PageResource'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ParentFirm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Pdf'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Pet'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PlaylistContentSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Presentation'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PressAward'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsContactMedia'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsInsightsContentSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsPressRelease'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsSiteSettings'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Quote'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'RelatedContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'RelatedContentStrategy'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Seo'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'SkPlayground'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Tag'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'TagFamily'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Team'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'TestModel'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Theme'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'Whitepaper'
              sys: { __typename?: 'Sys'; id: string }
            }
          | null
        >
      }
    }
  } | null
  itemsCollection?: {
    __typename?: 'ComponentSectionItemsCollection'
    items: Array<
      | {
          __typename: 'CaisiqVideo'
          byCais?: boolean | null
          title?: string | null
          category?: string | null
          summary?: string | null
          duration?: number | null
          displayDate?: any | null
          muxVideo?: any | null
          poster?: any | null
          icon?: any | null
          muxThumbnailTime?: number | null
          description?: string | null
          sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
          fundProductIds?: {
            __typename?: 'FundProductId'
            fundProductIds?: Array<string | null> | null
          } | null
          firmsCollection?: {
            __typename?: 'CaisiqVideoFirmsCollection'
            items: Array<{
              __typename?: 'MemberFirm'
              id?: string | null
              name?: string | null
            } | null>
          } | null
          thumbnail?: {
            __typename?: 'Asset'
            url?: string | null
            width?: number | null
            height?: number | null
          } | null
          video?: { __typename?: 'Asset'; url?: string | null } | null
        }
      | {
          __typename: 'CallToAction'
          title?: string | null
          id?: string | null
          description?: string | null
          buttonText?: string | null
          imageSet?: any | null
          byCais?: boolean | null
          sys: { __typename?: 'Sys'; id: string }
          destination?: {
            __typename?: 'Link'
            openInNewWindow?: boolean | null
            externalLink?: string | null
            internalLink?: string | null
            cmsLink?:
              | { __typename?: 'CaisiqArticle' }
              | { __typename?: 'HomepageLandingPage' }
              | { __typename?: 'Page' }
              | { __typename: 'PageResource'; slug?: string | null }
              | { __typename?: 'PwsPressRelease' }
              | { __typename?: 'Whitepaper' }
              | null
          } | null
          mediaFile?: {
            __typename?: 'Asset'
            url?: string | null
            fileName?: string | null
          } | null
          firm?: {
            __typename?: 'MemberFirm'
            id?: string | null
            name?: string | null
          } | null
        }
      | {
          __typename?: 'ComponentCard'
          sys: { __typename?: 'Sys'; id: string }
        }
      | {
          __typename?: 'ComponentCompanyContainer'
          sys: { __typename?: 'Sys'; id: string }
        }
      | {
          __typename?: 'ComponentPodcastPlayer'
          sys: { __typename?: 'Sys'; id: string }
        }
      | {
          __typename: 'ComponentText'
          title?: string | null
          brandIcon?: string | null
          sys: { __typename?: 'Sys'; id: string }
          text?: {
            __typename?: 'ComponentTextText'
            json: any
            links: {
              __typename?: 'ComponentTextTextLinks'
              entries: {
                __typename?: 'ComponentTextTextEntries'
                hyperlink: Array<
                  | { __typename?: 'AccessControl' }
                  | { __typename?: 'AccessControl2' }
                  | { __typename?: 'AccessControl3' }
                  | { __typename?: 'Accordion' }
                  | { __typename?: 'AccordionWithImage' }
                  | { __typename?: 'Announcement' }
                  | { __typename?: 'AnnouncementSet' }
                  | { __typename?: 'CaisiqArticle' }
                  | { __typename?: 'CaisiqArticleDropdown' }
                  | { __typename?: 'CaisiqAuthor' }
                  | { __typename?: 'CaisiqDefaultCourseImageSet' }
                  | { __typename?: 'CaisiqDefaultFundsThumbnailSet' }
                  | { __typename?: 'CaisiqFundThumbnail' }
                  | { __typename?: 'CaisiqFundThumbnailSet' }
                  | { __typename?: 'CaisiqHeroHeader' }
                  | { __typename?: 'CaisiqHeroHeaderNavigateToButton' }
                  | { __typename?: 'CaisiqHomeOffice' }
                  | { __typename?: 'CaisiqJoinCaisCallToAction' }
                  | { __typename?: 'CaisiqTab' }
                  | { __typename?: 'CaisiqVideo' }
                  | { __typename?: 'CaisiqVideoPlaylist' }
                  | { __typename?: 'CallToAction' }
                  | { __typename?: 'Chart' }
                  | { __typename?: 'ChildFirm' }
                  | { __typename?: 'CiqArticle' }
                  | { __typename?: 'Company' }
                  | { __typename?: 'CompanyAndContactInfo' }
                  | { __typename?: 'ComponentCard' }
                  | { __typename?: 'ComponentCompanyContainer' }
                  | { __typename?: 'ComponentHero' }
                  | { __typename?: 'ComponentHubspotForm' }
                  | { __typename?: 'ComponentImage' }
                  | { __typename?: 'ComponentPodcastPlayer' }
                  | { __typename?: 'ComponentSection' }
                  | { __typename?: 'ComponentSummary' }
                  | { __typename?: 'ComponentTeamContainer' }
                  | { __typename?: 'ComponentText' }
                  | { __typename?: 'ComponentTextIllustration' }
                  | { __typename?: 'ComponentTextImage' }
                  | { __typename?: 'ComponentTextImageImageSection' }
                  | { __typename?: 'ComponentTextImageListSection' }
                  | { __typename?: 'ComponentTextImageTextSection' }
                  | { __typename?: 'CorporateStats' }
                  | { __typename?: 'CuratedFilterGroup' }
                  | { __typename?: 'CuratedResearchContent' }
                  | { __typename?: 'Disclaimer' }
                  | { __typename?: 'Event' }
                  | { __typename?: 'ExternalContent' }
                  | { __typename?: 'Faq' }
                  | { __typename?: 'FaqCategory' }
                  | { __typename?: 'FeaturedContent' }
                  | { __typename?: 'FilterGroup' }
                  | { __typename?: 'Firm' }
                  | { __typename?: 'FullWidthImage' }
                  | { __typename?: 'FundProductId' }
                  | { __typename?: 'GlossaryTerm' }
                  | { __typename?: 'HomepageAppMetadata' }
                  | { __typename?: 'HomepageContentSection' }
                  | { __typename?: 'HomepageLandingPage' }
                  | { __typename?: 'HtmlTable' }
                  | { __typename?: 'ImageSection' }
                  | { __typename?: 'LegalPage' }
                  | { __typename?: 'Link' }
                  | { __typename?: 'LoginScreen' }
                  | { __typename?: 'Markdown' }
                  | { __typename?: 'MemberFirm' }
                  | { __typename?: 'Migration' }
                  | { __typename?: 'NavHeaderTheme' }
                  | {
                      __typename?: 'Page'
                      name?: string | null
                      slug?: string | null
                      sys: { __typename?: 'Sys'; id: string }
                      content?:
                        | { __typename: 'CaisiqArticle' }
                        | { __typename: 'CaisiqVideo' }
                        | { __typename: 'CaisiqVideoPlaylist' }
                        | { __typename: 'Company' }
                        | { __typename: 'PageHelpCenterArticle' }
                        | { __typename: 'PageLanding' }
                        | { __typename: 'PagePwsCategory' }
                        | { __typename: 'Person' }
                        | { __typename: 'PwsPressRelease' }
                        | { __typename: 'Whitepaper' }
                        | null
                    }
                  | { __typename?: 'PageHelpCenterArticle' }
                  | { __typename?: 'PageLanding' }
                  | { __typename?: 'PagePwsCategory' }
                  | { __typename?: 'PageResource' }
                  | { __typename?: 'ParentFirm' }
                  | { __typename?: 'Pdf' }
                  | { __typename?: 'Person' }
                  | { __typename?: 'Pet' }
                  | { __typename?: 'PlaylistContentSet' }
                  | { __typename?: 'Presentation' }
                  | { __typename?: 'PressAward' }
                  | { __typename?: 'PwsContactMedia' }
                  | { __typename?: 'PwsInsightsContentSet' }
                  | { __typename?: 'PwsPressRelease' }
                  | { __typename?: 'PwsSiteSettings' }
                  | { __typename?: 'Quote' }
                  | { __typename?: 'RelatedContent' }
                  | { __typename?: 'RelatedContentStrategy' }
                  | { __typename?: 'Seo' }
                  | { __typename?: 'SkPlayground' }
                  | { __typename?: 'Tag' }
                  | { __typename?: 'TagFamily' }
                  | { __typename?: 'Team' }
                  | { __typename?: 'TestModel' }
                  | { __typename?: 'Theme' }
                  | { __typename?: 'Whitepaper' }
                  | null
                >
                inline: Array<
                  | {
                      __typename: 'AccessControl'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'AccessControl2'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'AccessControl3'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Accordion'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'AccordionWithImage'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Announcement'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'AnnouncementSet'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqArticle'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqArticleDropdown'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqAuthor'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqDefaultCourseImageSet'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqDefaultFundsThumbnailSet'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqFundThumbnail'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqFundThumbnailSet'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqHeroHeader'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqHeroHeaderNavigateToButton'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqHomeOffice'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqJoinCaisCallToAction'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqTab'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqVideo'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqVideoPlaylist'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CallToAction'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Chart'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ChildFirm'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CiqArticle'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Company'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CompanyAndContactInfo'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentCard'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentCompanyContainer'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentHero'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentHubspotForm'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentImage'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentPodcastPlayer'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentSection'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentSummary'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentTeamContainer'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentText'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentTextIllustration'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentTextImage'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentTextImageImageSection'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentTextImageListSection'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentTextImageTextSection'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CorporateStats'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CuratedFilterGroup'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CuratedResearchContent'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Disclaimer'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Event'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ExternalContent'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Faq'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'FaqCategory'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'FeaturedContent'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'FilterGroup'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Firm'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'FullWidthImage'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'FundProductId'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'GlossaryTerm'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'HomepageAppMetadata'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'HomepageContentSection'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'HomepageLandingPage'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'HtmlTable'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ImageSection'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'LegalPage'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Link'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'LoginScreen'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Markdown'
                      text?: string | null
                      type?: string | null
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'MemberFirm'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Migration'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'NavHeaderTheme'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Page'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PageHelpCenterArticle'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PageLanding'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PagePwsCategory'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PageResource'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ParentFirm'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Pdf'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Person'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Pet'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PlaylistContentSet'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Presentation'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PressAward'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PwsContactMedia'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PwsInsightsContentSet'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PwsPressRelease'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PwsSiteSettings'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Quote'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'RelatedContent'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'RelatedContentStrategy'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Seo'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'SkPlayground'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Tag'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'TagFamily'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Team'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'TestModel'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Theme'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Whitepaper'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | null
                >
              }
            }
          } | null
        }
      | { __typename?: 'Link'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename?: 'PressAward'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename?: 'Quote'; sys: { __typename?: 'Sys'; id: string } }
      | { __typename?: 'Team'; sys: { __typename?: 'Sys'; id: string } }
      | null
    >
  } | null
}

export type ComponentTextFragment = {
  __typename: 'ComponentText'
  title?: string | null
  brandIcon?: string | null
  sys: { __typename?: 'Sys'; id: string }
  text?: {
    __typename?: 'ComponentTextText'
    json: any
    links: {
      __typename?: 'ComponentTextTextLinks'
      entries: {
        __typename?: 'ComponentTextTextEntries'
        hyperlink: Array<
          | { __typename?: 'AccessControl' }
          | { __typename?: 'AccessControl2' }
          | { __typename?: 'AccessControl3' }
          | { __typename?: 'Accordion' }
          | { __typename?: 'AccordionWithImage' }
          | { __typename?: 'Announcement' }
          | { __typename?: 'AnnouncementSet' }
          | { __typename?: 'CaisiqArticle' }
          | { __typename?: 'CaisiqArticleDropdown' }
          | { __typename?: 'CaisiqAuthor' }
          | { __typename?: 'CaisiqDefaultCourseImageSet' }
          | { __typename?: 'CaisiqDefaultFundsThumbnailSet' }
          | { __typename?: 'CaisiqFundThumbnail' }
          | { __typename?: 'CaisiqFundThumbnailSet' }
          | { __typename?: 'CaisiqHeroHeader' }
          | { __typename?: 'CaisiqHeroHeaderNavigateToButton' }
          | { __typename?: 'CaisiqHomeOffice' }
          | { __typename?: 'CaisiqJoinCaisCallToAction' }
          | { __typename?: 'CaisiqTab' }
          | { __typename?: 'CaisiqVideo' }
          | { __typename?: 'CaisiqVideoPlaylist' }
          | { __typename?: 'CallToAction' }
          | { __typename?: 'Chart' }
          | { __typename?: 'ChildFirm' }
          | { __typename?: 'CiqArticle' }
          | { __typename?: 'Company' }
          | { __typename?: 'CompanyAndContactInfo' }
          | { __typename?: 'ComponentCard' }
          | { __typename?: 'ComponentCompanyContainer' }
          | { __typename?: 'ComponentHero' }
          | { __typename?: 'ComponentHubspotForm' }
          | { __typename?: 'ComponentImage' }
          | { __typename?: 'ComponentPodcastPlayer' }
          | { __typename?: 'ComponentSection' }
          | { __typename?: 'ComponentSummary' }
          | { __typename?: 'ComponentTeamContainer' }
          | { __typename?: 'ComponentText' }
          | { __typename?: 'ComponentTextIllustration' }
          | { __typename?: 'ComponentTextImage' }
          | { __typename?: 'ComponentTextImageImageSection' }
          | { __typename?: 'ComponentTextImageListSection' }
          | { __typename?: 'ComponentTextImageTextSection' }
          | { __typename?: 'CorporateStats' }
          | { __typename?: 'CuratedFilterGroup' }
          | { __typename?: 'CuratedResearchContent' }
          | { __typename?: 'Disclaimer' }
          | { __typename?: 'Event' }
          | { __typename?: 'ExternalContent' }
          | { __typename?: 'Faq' }
          | { __typename?: 'FaqCategory' }
          | { __typename?: 'FeaturedContent' }
          | { __typename?: 'FilterGroup' }
          | { __typename?: 'Firm' }
          | { __typename?: 'FullWidthImage' }
          | { __typename?: 'FundProductId' }
          | { __typename?: 'GlossaryTerm' }
          | { __typename?: 'HomepageAppMetadata' }
          | { __typename?: 'HomepageContentSection' }
          | { __typename?: 'HomepageLandingPage' }
          | { __typename?: 'HtmlTable' }
          | { __typename?: 'ImageSection' }
          | { __typename?: 'LegalPage' }
          | { __typename?: 'Link' }
          | { __typename?: 'LoginScreen' }
          | { __typename?: 'Markdown' }
          | { __typename?: 'MemberFirm' }
          | { __typename?: 'Migration' }
          | { __typename?: 'NavHeaderTheme' }
          | {
              __typename?: 'Page'
              name?: string | null
              slug?: string | null
              sys: { __typename?: 'Sys'; id: string }
              content?:
                | { __typename: 'CaisiqArticle' }
                | { __typename: 'CaisiqVideo' }
                | { __typename: 'CaisiqVideoPlaylist' }
                | { __typename: 'Company' }
                | { __typename: 'PageHelpCenterArticle' }
                | { __typename: 'PageLanding' }
                | { __typename: 'PagePwsCategory' }
                | { __typename: 'Person' }
                | { __typename: 'PwsPressRelease' }
                | { __typename: 'Whitepaper' }
                | null
            }
          | { __typename?: 'PageHelpCenterArticle' }
          | { __typename?: 'PageLanding' }
          | { __typename?: 'PagePwsCategory' }
          | { __typename?: 'PageResource' }
          | { __typename?: 'ParentFirm' }
          | { __typename?: 'Pdf' }
          | { __typename?: 'Person' }
          | { __typename?: 'Pet' }
          | { __typename?: 'PlaylistContentSet' }
          | { __typename?: 'Presentation' }
          | { __typename?: 'PressAward' }
          | { __typename?: 'PwsContactMedia' }
          | { __typename?: 'PwsInsightsContentSet' }
          | { __typename?: 'PwsPressRelease' }
          | { __typename?: 'PwsSiteSettings' }
          | { __typename?: 'Quote' }
          | { __typename?: 'RelatedContent' }
          | { __typename?: 'RelatedContentStrategy' }
          | { __typename?: 'Seo' }
          | { __typename?: 'SkPlayground' }
          | { __typename?: 'Tag' }
          | { __typename?: 'TagFamily' }
          | { __typename?: 'Team' }
          | { __typename?: 'TestModel' }
          | { __typename?: 'Theme' }
          | { __typename?: 'Whitepaper' }
          | null
        >
        inline: Array<
          | {
              __typename: 'AccessControl'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'AccessControl2'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'AccessControl3'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Accordion'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'AccordionWithImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Announcement'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'AnnouncementSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqArticle'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqArticleDropdown'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqAuthor'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqDefaultCourseImageSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqDefaultFundsThumbnailSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqFundThumbnail'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqFundThumbnailSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqHeroHeader'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqHeroHeaderNavigateToButton'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqHomeOffice'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqJoinCaisCallToAction'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'CaisiqTab'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CaisiqVideo'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqVideoPlaylist'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CallToAction'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Chart'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'ChildFirm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CiqArticle'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Company'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CompanyAndContactInfo'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentCard'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentCompanyContainer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentHero'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentHubspotForm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentPodcastPlayer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentSummary'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTeamContainer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentText'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextIllustration'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImageImageSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImageListSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImageTextSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CorporateStats'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CuratedFilterGroup'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CuratedResearchContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Disclaimer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Event'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ExternalContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Faq'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FaqCategory'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'FeaturedContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'FilterGroup'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Firm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FullWidthImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'FundProductId'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'GlossaryTerm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'HomepageAppMetadata'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'HomepageContentSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'HomepageLandingPage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'HtmlTable'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ImageSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'LegalPage'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Link'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'LoginScreen'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Markdown'
              text?: string | null
              type?: string | null
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'MemberFirm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Migration'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'NavHeaderTheme'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PageHelpCenterArticle'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PageLanding'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PagePwsCategory'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PageResource'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ParentFirm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Pdf'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Pet'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PlaylistContentSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Presentation'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PressAward'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsContactMedia'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsInsightsContentSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsPressRelease'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsSiteSettings'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Quote'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'RelatedContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'RelatedContentStrategy'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Seo'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'SkPlayground'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Tag'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'TagFamily'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Team'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'TestModel'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Theme'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'Whitepaper'
              sys: { __typename?: 'Sys'; id: string }
            }
          | null
        >
      }
    }
  } | null
}

export type LinkCardFragment = {
  __typename: 'Link'
  openInNewWindow?: boolean | null
  description?: string | null
  internalLink?: string | null
  byCais?: boolean | null
  title?: string | null
  poster?: any | null
  sys: { __typename?: 'Sys'; id: string }
  cmsLink?:
    | { __typename?: 'CaisiqArticle' }
    | { __typename: 'HomepageLandingPage'; slug?: string | null }
    | { __typename?: 'Page' }
    | { __typename: 'PageResource'; slug?: string | null }
    | { __typename?: 'PwsPressRelease' }
    | { __typename?: 'Whitepaper' }
    | null
}

export type TagFragment = {
  __typename?: 'Tag'
  tagId?: string | null
  label?: string | null
}

export type GetLandingPageQueryVariables = Exact<{
  slug: Scalars['String']
  preview: Scalars['Boolean']
  firmsLimit: Scalars['Int']
}>

export type GetLandingPageQuery = {
  __typename?: 'Query'
  pageCollection?: {
    __typename?: 'PageCollection'
    items: Array<{
      __typename?: 'Page'
      title?: string | null
      slug?: string | null
      content?:
        | { __typename?: 'CaisiqArticle' }
        | { __typename?: 'CaisiqVideo' }
        | { __typename?: 'CaisiqVideoPlaylist' }
        | { __typename?: 'Company' }
        | { __typename?: 'PageHelpCenterArticle' }
        | {
            __typename: 'PageLanding'
            pageType?: string | null
            name?: string | null
            disclaimer?: {
              __typename?: 'Disclaimer'
              title?: string | null
              body?: { __typename?: 'DisclaimerBody'; json: any } | null
            } | null
            sectionsCollection?: {
              __typename?: 'PageLandingSectionsCollection'
              items: Array<
                | { __typename: 'AccordionWithImage' }
                | { __typename: 'CaisiqJoinCaisCallToAction' }
                | { __typename: 'CaisiqVideo' }
                | { __typename: 'CaisiqVideoPlaylist' }
                | { __typename: 'CallToAction' }
                | { __typename: 'ComponentCompanyContainer' }
                | { __typename: 'ComponentHubspotForm' }
                | { __typename: 'ComponentPodcastPlayer' }
                | {
                    __typename: 'ComponentSection'
                    componentId?: string | null
                    cardContainerOptions?: string | null
                    title?: string | null
                    sys: { __typename?: 'Sys'; id: string }
                    componentSectionDescription?: {
                      __typename?: 'ComponentSectionDescription'
                      json: any
                      links: {
                        __typename?: 'ComponentSectionDescriptionLinks'
                        entries: {
                          __typename?: 'ComponentSectionDescriptionEntries'
                          hyperlink: Array<
                            | { __typename?: 'AccessControl' }
                            | { __typename?: 'AccessControl2' }
                            | { __typename?: 'AccessControl3' }
                            | { __typename?: 'Accordion' }
                            | { __typename?: 'AccordionWithImage' }
                            | { __typename?: 'Announcement' }
                            | { __typename?: 'AnnouncementSet' }
                            | { __typename?: 'CaisiqArticle' }
                            | { __typename?: 'CaisiqArticleDropdown' }
                            | { __typename?: 'CaisiqAuthor' }
                            | { __typename?: 'CaisiqDefaultCourseImageSet' }
                            | { __typename?: 'CaisiqDefaultFundsThumbnailSet' }
                            | { __typename?: 'CaisiqFundThumbnail' }
                            | { __typename?: 'CaisiqFundThumbnailSet' }
                            | { __typename?: 'CaisiqHeroHeader' }
                            | {
                                __typename?: 'CaisiqHeroHeaderNavigateToButton'
                              }
                            | { __typename?: 'CaisiqHomeOffice' }
                            | { __typename?: 'CaisiqJoinCaisCallToAction' }
                            | { __typename?: 'CaisiqTab' }
                            | { __typename?: 'CaisiqVideo' }
                            | { __typename?: 'CaisiqVideoPlaylist' }
                            | { __typename?: 'CallToAction' }
                            | { __typename?: 'Chart' }
                            | { __typename?: 'ChildFirm' }
                            | { __typename?: 'CiqArticle' }
                            | { __typename?: 'Company' }
                            | { __typename?: 'CompanyAndContactInfo' }
                            | { __typename?: 'ComponentCard' }
                            | { __typename?: 'ComponentCompanyContainer' }
                            | { __typename?: 'ComponentHero' }
                            | { __typename?: 'ComponentHubspotForm' }
                            | { __typename?: 'ComponentImage' }
                            | { __typename?: 'ComponentPodcastPlayer' }
                            | { __typename?: 'ComponentSection' }
                            | { __typename?: 'ComponentSummary' }
                            | { __typename?: 'ComponentTeamContainer' }
                            | { __typename?: 'ComponentText' }
                            | { __typename?: 'ComponentTextIllustration' }
                            | { __typename?: 'ComponentTextImage' }
                            | { __typename?: 'ComponentTextImageImageSection' }
                            | { __typename?: 'ComponentTextImageListSection' }
                            | { __typename?: 'ComponentTextImageTextSection' }
                            | { __typename?: 'CorporateStats' }
                            | { __typename?: 'CuratedFilterGroup' }
                            | { __typename?: 'CuratedResearchContent' }
                            | { __typename?: 'Disclaimer' }
                            | { __typename?: 'Event' }
                            | { __typename?: 'ExternalContent' }
                            | { __typename?: 'Faq' }
                            | { __typename?: 'FaqCategory' }
                            | { __typename?: 'FeaturedContent' }
                            | { __typename?: 'FilterGroup' }
                            | { __typename?: 'Firm' }
                            | { __typename?: 'FullWidthImage' }
                            | { __typename?: 'FundProductId' }
                            | { __typename?: 'GlossaryTerm' }
                            | { __typename?: 'HomepageAppMetadata' }
                            | { __typename?: 'HomepageContentSection' }
                            | { __typename?: 'HomepageLandingPage' }
                            | { __typename?: 'HtmlTable' }
                            | { __typename?: 'ImageSection' }
                            | { __typename?: 'LegalPage' }
                            | { __typename?: 'Link' }
                            | { __typename?: 'LoginScreen' }
                            | { __typename?: 'Markdown' }
                            | { __typename?: 'MemberFirm' }
                            | { __typename?: 'Migration' }
                            | { __typename?: 'NavHeaderTheme' }
                            | {
                                __typename?: 'Page'
                                name?: string | null
                                slug?: string | null
                                sys: { __typename?: 'Sys'; id: string }
                                content?:
                                  | { __typename: 'CaisiqArticle' }
                                  | { __typename: 'CaisiqVideo' }
                                  | { __typename: 'CaisiqVideoPlaylist' }
                                  | { __typename: 'Company' }
                                  | { __typename: 'PageHelpCenterArticle' }
                                  | { __typename: 'PageLanding' }
                                  | { __typename: 'PagePwsCategory' }
                                  | { __typename: 'Person' }
                                  | { __typename: 'PwsPressRelease' }
                                  | { __typename: 'Whitepaper' }
                                  | null
                              }
                            | { __typename?: 'PageHelpCenterArticle' }
                            | { __typename?: 'PageLanding' }
                            | { __typename?: 'PagePwsCategory' }
                            | { __typename?: 'PageResource' }
                            | { __typename?: 'ParentFirm' }
                            | { __typename?: 'Pdf' }
                            | { __typename?: 'Person' }
                            | { __typename?: 'Pet' }
                            | { __typename?: 'PlaylistContentSet' }
                            | { __typename?: 'Presentation' }
                            | { __typename?: 'PressAward' }
                            | { __typename?: 'PwsContactMedia' }
                            | { __typename?: 'PwsInsightsContentSet' }
                            | { __typename?: 'PwsPressRelease' }
                            | { __typename?: 'PwsSiteSettings' }
                            | { __typename?: 'Quote' }
                            | { __typename?: 'RelatedContent' }
                            | { __typename?: 'RelatedContentStrategy' }
                            | { __typename?: 'Seo' }
                            | { __typename?: 'SkPlayground' }
                            | { __typename?: 'Tag' }
                            | { __typename?: 'TagFamily' }
                            | { __typename?: 'Team' }
                            | { __typename?: 'TestModel' }
                            | { __typename?: 'Theme' }
                            | { __typename?: 'Whitepaper' }
                            | null
                          >
                          inline: Array<
                            | {
                                __typename: 'AccessControl'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'AccessControl2'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'AccessControl3'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Accordion'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'AccordionWithImage'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Announcement'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'AnnouncementSet'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqArticle'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqArticleDropdown'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqAuthor'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqDefaultCourseImageSet'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqDefaultFundsThumbnailSet'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqFundThumbnail'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqFundThumbnailSet'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqHeroHeader'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqHeroHeaderNavigateToButton'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqHomeOffice'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqJoinCaisCallToAction'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqTab'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqVideo'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqVideoPlaylist'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CallToAction'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Chart'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ChildFirm'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CiqArticle'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Company'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CompanyAndContactInfo'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentCard'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentCompanyContainer'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentHero'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentHubspotForm'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentImage'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentPodcastPlayer'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentSection'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentSummary'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentTeamContainer'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentText'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentTextIllustration'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentTextImage'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentTextImageImageSection'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentTextImageListSection'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentTextImageTextSection'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CorporateStats'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CuratedFilterGroup'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CuratedResearchContent'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Disclaimer'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Event'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ExternalContent'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Faq'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'FaqCategory'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'FeaturedContent'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'FilterGroup'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Firm'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'FullWidthImage'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'FundProductId'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'GlossaryTerm'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'HomepageAppMetadata'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'HomepageContentSection'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'HomepageLandingPage'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'HtmlTable'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ImageSection'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'LegalPage'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Link'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'LoginScreen'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Markdown'
                                text?: string | null
                                type?: string | null
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'MemberFirm'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Migration'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'NavHeaderTheme'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Page'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'PageHelpCenterArticle'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'PageLanding'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'PagePwsCategory'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'PageResource'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ParentFirm'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Pdf'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Person'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Pet'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'PlaylistContentSet'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Presentation'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'PressAward'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'PwsContactMedia'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'PwsInsightsContentSet'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'PwsPressRelease'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'PwsSiteSettings'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Quote'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'RelatedContent'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'RelatedContentStrategy'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Seo'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'SkPlayground'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Tag'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'TagFamily'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Team'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'TestModel'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Theme'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Whitepaper'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | null
                          >
                        }
                      }
                    } | null
                    itemsCollection?: {
                      __typename?: 'ComponentSectionItemsCollection'
                      items: Array<
                        | {
                            __typename: 'CaisiqVideo'
                            byCais?: boolean | null
                            title?: string | null
                            category?: string | null
                            summary?: string | null
                            duration?: number | null
                            displayDate?: any | null
                            muxVideo?: any | null
                            poster?: any | null
                            icon?: any | null
                            muxThumbnailTime?: number | null
                            description?: string | null
                            sys: {
                              __typename?: 'Sys'
                              id: string
                              firstPublishedAt?: any | null
                            }
                            fundProductIds?: {
                              __typename?: 'FundProductId'
                              fundProductIds?: Array<string | null> | null
                            } | null
                            firmsCollection?: {
                              __typename?: 'CaisiqVideoFirmsCollection'
                              items: Array<{
                                __typename?: 'MemberFirm'
                                id?: string | null
                                name?: string | null
                              } | null>
                            } | null
                            thumbnail?: {
                              __typename?: 'Asset'
                              url?: string | null
                              width?: number | null
                              height?: number | null
                            } | null
                            video?: {
                              __typename?: 'Asset'
                              url?: string | null
                            } | null
                          }
                        | {
                            __typename: 'CallToAction'
                            title?: string | null
                            id?: string | null
                            description?: string | null
                            buttonText?: string | null
                            imageSet?: any | null
                            byCais?: boolean | null
                            sys: { __typename?: 'Sys'; id: string }
                            destination?: {
                              __typename?: 'Link'
                              openInNewWindow?: boolean | null
                              externalLink?: string | null
                              internalLink?: string | null
                              cmsLink?:
                                | { __typename?: 'CaisiqArticle' }
                                | { __typename?: 'HomepageLandingPage' }
                                | { __typename?: 'Page' }
                                | {
                                    __typename: 'PageResource'
                                    slug?: string | null
                                  }
                                | { __typename?: 'PwsPressRelease' }
                                | { __typename?: 'Whitepaper' }
                                | null
                            } | null
                            mediaFile?: {
                              __typename?: 'Asset'
                              url?: string | null
                              fileName?: string | null
                            } | null
                            firm?: {
                              __typename?: 'MemberFirm'
                              id?: string | null
                              name?: string | null
                            } | null
                          }
                        | {
                            __typename?: 'ComponentCard'
                            sys: { __typename?: 'Sys'; id: string }
                          }
                        | {
                            __typename?: 'ComponentCompanyContainer'
                            sys: { __typename?: 'Sys'; id: string }
                          }
                        | {
                            __typename?: 'ComponentPodcastPlayer'
                            sys: { __typename?: 'Sys'; id: string }
                          }
                        | {
                            __typename: 'ComponentText'
                            title?: string | null
                            brandIcon?: string | null
                            sys: { __typename?: 'Sys'; id: string }
                            text?: {
                              __typename?: 'ComponentTextText'
                              json: any
                              links: {
                                __typename?: 'ComponentTextTextLinks'
                                entries: {
                                  __typename?: 'ComponentTextTextEntries'
                                  hyperlink: Array<
                                    | { __typename?: 'AccessControl' }
                                    | { __typename?: 'AccessControl2' }
                                    | { __typename?: 'AccessControl3' }
                                    | { __typename?: 'Accordion' }
                                    | { __typename?: 'AccordionWithImage' }
                                    | { __typename?: 'Announcement' }
                                    | { __typename?: 'AnnouncementSet' }
                                    | { __typename?: 'CaisiqArticle' }
                                    | { __typename?: 'CaisiqArticleDropdown' }
                                    | { __typename?: 'CaisiqAuthor' }
                                    | {
                                        __typename?: 'CaisiqDefaultCourseImageSet'
                                      }
                                    | {
                                        __typename?: 'CaisiqDefaultFundsThumbnailSet'
                                      }
                                    | { __typename?: 'CaisiqFundThumbnail' }
                                    | { __typename?: 'CaisiqFundThumbnailSet' }
                                    | { __typename?: 'CaisiqHeroHeader' }
                                    | {
                                        __typename?: 'CaisiqHeroHeaderNavigateToButton'
                                      }
                                    | { __typename?: 'CaisiqHomeOffice' }
                                    | {
                                        __typename?: 'CaisiqJoinCaisCallToAction'
                                      }
                                    | { __typename?: 'CaisiqTab' }
                                    | { __typename?: 'CaisiqVideo' }
                                    | { __typename?: 'CaisiqVideoPlaylist' }
                                    | { __typename?: 'CallToAction' }
                                    | { __typename?: 'Chart' }
                                    | { __typename?: 'ChildFirm' }
                                    | { __typename?: 'CiqArticle' }
                                    | { __typename?: 'Company' }
                                    | { __typename?: 'CompanyAndContactInfo' }
                                    | { __typename?: 'ComponentCard' }
                                    | {
                                        __typename?: 'ComponentCompanyContainer'
                                      }
                                    | { __typename?: 'ComponentHero' }
                                    | { __typename?: 'ComponentHubspotForm' }
                                    | { __typename?: 'ComponentImage' }
                                    | { __typename?: 'ComponentPodcastPlayer' }
                                    | { __typename?: 'ComponentSection' }
                                    | { __typename?: 'ComponentSummary' }
                                    | { __typename?: 'ComponentTeamContainer' }
                                    | { __typename?: 'ComponentText' }
                                    | {
                                        __typename?: 'ComponentTextIllustration'
                                      }
                                    | { __typename?: 'ComponentTextImage' }
                                    | {
                                        __typename?: 'ComponentTextImageImageSection'
                                      }
                                    | {
                                        __typename?: 'ComponentTextImageListSection'
                                      }
                                    | {
                                        __typename?: 'ComponentTextImageTextSection'
                                      }
                                    | { __typename?: 'CorporateStats' }
                                    | { __typename?: 'CuratedFilterGroup' }
                                    | { __typename?: 'CuratedResearchContent' }
                                    | { __typename?: 'Disclaimer' }
                                    | { __typename?: 'Event' }
                                    | { __typename?: 'ExternalContent' }
                                    | { __typename?: 'Faq' }
                                    | { __typename?: 'FaqCategory' }
                                    | { __typename?: 'FeaturedContent' }
                                    | { __typename?: 'FilterGroup' }
                                    | { __typename?: 'Firm' }
                                    | { __typename?: 'FullWidthImage' }
                                    | { __typename?: 'FundProductId' }
                                    | { __typename?: 'GlossaryTerm' }
                                    | { __typename?: 'HomepageAppMetadata' }
                                    | { __typename?: 'HomepageContentSection' }
                                    | { __typename?: 'HomepageLandingPage' }
                                    | { __typename?: 'HtmlTable' }
                                    | { __typename?: 'ImageSection' }
                                    | { __typename?: 'LegalPage' }
                                    | { __typename?: 'Link' }
                                    | { __typename?: 'LoginScreen' }
                                    | { __typename?: 'Markdown' }
                                    | { __typename?: 'MemberFirm' }
                                    | { __typename?: 'Migration' }
                                    | { __typename?: 'NavHeaderTheme' }
                                    | {
                                        __typename?: 'Page'
                                        name?: string | null
                                        slug?: string | null
                                        sys: { __typename?: 'Sys'; id: string }
                                        content?:
                                          | { __typename: 'CaisiqArticle' }
                                          | { __typename: 'CaisiqVideo' }
                                          | {
                                              __typename: 'CaisiqVideoPlaylist'
                                            }
                                          | { __typename: 'Company' }
                                          | {
                                              __typename: 'PageHelpCenterArticle'
                                            }
                                          | { __typename: 'PageLanding' }
                                          | { __typename: 'PagePwsCategory' }
                                          | { __typename: 'Person' }
                                          | { __typename: 'PwsPressRelease' }
                                          | { __typename: 'Whitepaper' }
                                          | null
                                      }
                                    | { __typename?: 'PageHelpCenterArticle' }
                                    | { __typename?: 'PageLanding' }
                                    | { __typename?: 'PagePwsCategory' }
                                    | { __typename?: 'PageResource' }
                                    | { __typename?: 'ParentFirm' }
                                    | { __typename?: 'Pdf' }
                                    | { __typename?: 'Person' }
                                    | { __typename?: 'Pet' }
                                    | { __typename?: 'PlaylistContentSet' }
                                    | { __typename?: 'Presentation' }
                                    | { __typename?: 'PressAward' }
                                    | { __typename?: 'PwsContactMedia' }
                                    | { __typename?: 'PwsInsightsContentSet' }
                                    | { __typename?: 'PwsPressRelease' }
                                    | { __typename?: 'PwsSiteSettings' }
                                    | { __typename?: 'Quote' }
                                    | { __typename?: 'RelatedContent' }
                                    | { __typename?: 'RelatedContentStrategy' }
                                    | { __typename?: 'Seo' }
                                    | { __typename?: 'SkPlayground' }
                                    | { __typename?: 'Tag' }
                                    | { __typename?: 'TagFamily' }
                                    | { __typename?: 'Team' }
                                    | { __typename?: 'TestModel' }
                                    | { __typename?: 'Theme' }
                                    | { __typename?: 'Whitepaper' }
                                    | null
                                  >
                                  inline: Array<
                                    | {
                                        __typename: 'AccessControl'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'AccessControl2'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'AccessControl3'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Accordion'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'AccordionWithImage'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Announcement'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'AnnouncementSet'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'CaisiqArticle'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'CaisiqArticleDropdown'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'CaisiqAuthor'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'CaisiqDefaultCourseImageSet'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'CaisiqDefaultFundsThumbnailSet'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'CaisiqFundThumbnail'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'CaisiqFundThumbnailSet'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'CaisiqHeroHeader'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'CaisiqHeroHeaderNavigateToButton'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'CaisiqHomeOffice'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'CaisiqJoinCaisCallToAction'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'CaisiqTab'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'CaisiqVideo'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'CaisiqVideoPlaylist'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'CallToAction'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Chart'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'ChildFirm'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'CiqArticle'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Company'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'CompanyAndContactInfo'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'ComponentCard'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'ComponentCompanyContainer'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'ComponentHero'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'ComponentHubspotForm'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'ComponentImage'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'ComponentPodcastPlayer'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'ComponentSection'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'ComponentSummary'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'ComponentTeamContainer'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'ComponentText'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'ComponentTextIllustration'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'ComponentTextImage'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'ComponentTextImageImageSection'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'ComponentTextImageListSection'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'ComponentTextImageTextSection'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'CorporateStats'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'CuratedFilterGroup'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'CuratedResearchContent'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Disclaimer'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Event'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'ExternalContent'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Faq'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'FaqCategory'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'FeaturedContent'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'FilterGroup'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Firm'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'FullWidthImage'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'FundProductId'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'GlossaryTerm'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'HomepageAppMetadata'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'HomepageContentSection'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'HomepageLandingPage'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'HtmlTable'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'ImageSection'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'LegalPage'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Link'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'LoginScreen'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Markdown'
                                        text?: string | null
                                        type?: string | null
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'MemberFirm'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Migration'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'NavHeaderTheme'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Page'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'PageHelpCenterArticle'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'PageLanding'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'PagePwsCategory'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'PageResource'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'ParentFirm'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Pdf'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Person'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Pet'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'PlaylistContentSet'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Presentation'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'PressAward'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'PwsContactMedia'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'PwsInsightsContentSet'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'PwsPressRelease'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'PwsSiteSettings'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Quote'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'RelatedContent'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'RelatedContentStrategy'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Seo'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'SkPlayground'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Tag'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'TagFamily'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Team'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'TestModel'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Theme'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | {
                                        __typename: 'Whitepaper'
                                        sys: { __typename?: 'Sys'; id: string }
                                      }
                                    | null
                                  >
                                }
                              }
                            } | null
                          }
                        | {
                            __typename?: 'Link'
                            sys: { __typename?: 'Sys'; id: string }
                          }
                        | {
                            __typename?: 'PressAward'
                            sys: { __typename?: 'Sys'; id: string }
                          }
                        | {
                            __typename?: 'Quote'
                            sys: { __typename?: 'Sys'; id: string }
                          }
                        | {
                            __typename?: 'Team'
                            sys: { __typename?: 'Sys'; id: string }
                          }
                        | null
                      >
                    } | null
                  }
                | { __typename: 'ComponentSummary' }
                | { __typename: 'ComponentTeamContainer' }
                | {
                    __typename: 'ComponentText'
                    brandIcon?: string | null
                    sys: { __typename?: 'Sys'; id: string }
                    text?: {
                      __typename?: 'ComponentTextText'
                      json: any
                      links: {
                        __typename?: 'ComponentTextTextLinks'
                        entries: {
                          __typename?: 'ComponentTextTextEntries'
                          hyperlink: Array<
                            | { __typename?: 'AccessControl' }
                            | { __typename?: 'AccessControl2' }
                            | { __typename?: 'AccessControl3' }
                            | { __typename?: 'Accordion' }
                            | { __typename?: 'AccordionWithImage' }
                            | { __typename?: 'Announcement' }
                            | { __typename?: 'AnnouncementSet' }
                            | { __typename?: 'CaisiqArticle' }
                            | { __typename?: 'CaisiqArticleDropdown' }
                            | { __typename?: 'CaisiqAuthor' }
                            | { __typename?: 'CaisiqDefaultCourseImageSet' }
                            | { __typename?: 'CaisiqDefaultFundsThumbnailSet' }
                            | { __typename?: 'CaisiqFundThumbnail' }
                            | { __typename?: 'CaisiqFundThumbnailSet' }
                            | { __typename?: 'CaisiqHeroHeader' }
                            | {
                                __typename?: 'CaisiqHeroHeaderNavigateToButton'
                              }
                            | { __typename?: 'CaisiqHomeOffice' }
                            | { __typename?: 'CaisiqJoinCaisCallToAction' }
                            | { __typename?: 'CaisiqTab' }
                            | { __typename?: 'CaisiqVideo' }
                            | { __typename?: 'CaisiqVideoPlaylist' }
                            | { __typename?: 'CallToAction' }
                            | { __typename?: 'Chart' }
                            | { __typename?: 'ChildFirm' }
                            | { __typename?: 'CiqArticle' }
                            | { __typename?: 'Company' }
                            | { __typename?: 'CompanyAndContactInfo' }
                            | { __typename?: 'ComponentCard' }
                            | { __typename?: 'ComponentCompanyContainer' }
                            | { __typename?: 'ComponentHero' }
                            | { __typename?: 'ComponentHubspotForm' }
                            | { __typename?: 'ComponentImage' }
                            | { __typename?: 'ComponentPodcastPlayer' }
                            | { __typename?: 'ComponentSection' }
                            | { __typename?: 'ComponentSummary' }
                            | { __typename?: 'ComponentTeamContainer' }
                            | { __typename?: 'ComponentText' }
                            | { __typename?: 'ComponentTextIllustration' }
                            | { __typename?: 'ComponentTextImage' }
                            | { __typename?: 'ComponentTextImageImageSection' }
                            | { __typename?: 'ComponentTextImageListSection' }
                            | { __typename?: 'ComponentTextImageTextSection' }
                            | { __typename?: 'CorporateStats' }
                            | { __typename?: 'CuratedFilterGroup' }
                            | { __typename?: 'CuratedResearchContent' }
                            | { __typename?: 'Disclaimer' }
                            | { __typename?: 'Event' }
                            | { __typename?: 'ExternalContent' }
                            | { __typename?: 'Faq' }
                            | { __typename?: 'FaqCategory' }
                            | { __typename?: 'FeaturedContent' }
                            | { __typename?: 'FilterGroup' }
                            | { __typename?: 'Firm' }
                            | { __typename?: 'FullWidthImage' }
                            | { __typename?: 'FundProductId' }
                            | { __typename?: 'GlossaryTerm' }
                            | { __typename?: 'HomepageAppMetadata' }
                            | { __typename?: 'HomepageContentSection' }
                            | { __typename?: 'HomepageLandingPage' }
                            | { __typename?: 'HtmlTable' }
                            | { __typename?: 'ImageSection' }
                            | { __typename?: 'LegalPage' }
                            | { __typename?: 'Link' }
                            | { __typename?: 'LoginScreen' }
                            | { __typename?: 'Markdown' }
                            | { __typename?: 'MemberFirm' }
                            | { __typename?: 'Migration' }
                            | { __typename?: 'NavHeaderTheme' }
                            | {
                                __typename?: 'Page'
                                name?: string | null
                                slug?: string | null
                                sys: { __typename?: 'Sys'; id: string }
                                content?:
                                  | { __typename: 'CaisiqArticle' }
                                  | { __typename: 'CaisiqVideo' }
                                  | { __typename: 'CaisiqVideoPlaylist' }
                                  | { __typename: 'Company' }
                                  | { __typename: 'PageHelpCenterArticle' }
                                  | { __typename: 'PageLanding' }
                                  | { __typename: 'PagePwsCategory' }
                                  | { __typename: 'Person' }
                                  | { __typename: 'PwsPressRelease' }
                                  | { __typename: 'Whitepaper' }
                                  | null
                              }
                            | { __typename?: 'PageHelpCenterArticle' }
                            | { __typename?: 'PageLanding' }
                            | { __typename?: 'PagePwsCategory' }
                            | { __typename?: 'PageResource' }
                            | { __typename?: 'ParentFirm' }
                            | { __typename?: 'Pdf' }
                            | { __typename?: 'Person' }
                            | { __typename?: 'Pet' }
                            | { __typename?: 'PlaylistContentSet' }
                            | { __typename?: 'Presentation' }
                            | { __typename?: 'PressAward' }
                            | { __typename?: 'PwsContactMedia' }
                            | { __typename?: 'PwsInsightsContentSet' }
                            | { __typename?: 'PwsPressRelease' }
                            | { __typename?: 'PwsSiteSettings' }
                            | { __typename?: 'Quote' }
                            | { __typename?: 'RelatedContent' }
                            | { __typename?: 'RelatedContentStrategy' }
                            | { __typename?: 'Seo' }
                            | { __typename?: 'SkPlayground' }
                            | { __typename?: 'Tag' }
                            | { __typename?: 'TagFamily' }
                            | { __typename?: 'Team' }
                            | { __typename?: 'TestModel' }
                            | { __typename?: 'Theme' }
                            | { __typename?: 'Whitepaper' }
                            | null
                          >
                          inline: Array<
                            | {
                                __typename: 'AccessControl'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'AccessControl2'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'AccessControl3'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Accordion'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'AccordionWithImage'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Announcement'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'AnnouncementSet'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqArticle'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqArticleDropdown'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqAuthor'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqDefaultCourseImageSet'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqDefaultFundsThumbnailSet'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqFundThumbnail'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqFundThumbnailSet'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqHeroHeader'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqHeroHeaderNavigateToButton'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqHomeOffice'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqJoinCaisCallToAction'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqTab'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqVideo'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CaisiqVideoPlaylist'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CallToAction'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Chart'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ChildFirm'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CiqArticle'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Company'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CompanyAndContactInfo'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentCard'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentCompanyContainer'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentHero'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentHubspotForm'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentImage'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentPodcastPlayer'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentSection'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentSummary'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentTeamContainer'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentText'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentTextIllustration'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentTextImage'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentTextImageImageSection'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentTextImageListSection'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ComponentTextImageTextSection'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CorporateStats'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CuratedFilterGroup'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'CuratedResearchContent'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Disclaimer'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Event'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ExternalContent'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Faq'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'FaqCategory'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'FeaturedContent'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'FilterGroup'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Firm'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'FullWidthImage'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'FundProductId'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'GlossaryTerm'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'HomepageAppMetadata'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'HomepageContentSection'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'HomepageLandingPage'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'HtmlTable'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ImageSection'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'LegalPage'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Link'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'LoginScreen'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Markdown'
                                text?: string | null
                                type?: string | null
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'MemberFirm'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Migration'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'NavHeaderTheme'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Page'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'PageHelpCenterArticle'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'PageLanding'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'PagePwsCategory'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'PageResource'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'ParentFirm'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Pdf'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Person'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Pet'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'PlaylistContentSet'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Presentation'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'PressAward'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'PwsContactMedia'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'PwsInsightsContentSet'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'PwsPressRelease'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'PwsSiteSettings'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Quote'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'RelatedContent'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'RelatedContentStrategy'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Seo'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'SkPlayground'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Tag'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'TagFamily'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Team'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'TestModel'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Theme'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | {
                                __typename: 'Whitepaper'
                                sys: { __typename?: 'Sys'; id: string }
                              }
                            | null
                          >
                        }
                      }
                    } | null
                  }
                | { __typename: 'ComponentTextIllustration' }
                | { __typename: 'ComponentTextImage' }
                | { __typename: 'CorporateStats' }
                | { __typename: 'ImageSection' }
                | { __typename: 'PlaylistContentSet' }
                | { __typename: 'PwsContactMedia' }
                | { __typename: 'PwsInsightsContentSet' }
                | { __typename: 'Quote' }
                | { __typename: 'RelatedContent' }
                | { __typename: 'SkPlayground' }
                | null
              >
            } | null
          }
        | { __typename?: 'PagePwsCategory' }
        | { __typename?: 'Person' }
        | { __typename?: 'PwsPressRelease' }
        | { __typename?: 'Whitepaper' }
        | null
    } | null>
  } | null
}

export type GetPageResourcesQueryVariables = Exact<{
  where: PageResourceFilter
  preview?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  shouldIncludeDate?: InputMaybe<Scalars['Boolean']>
  firmsLimit: Scalars['Int']
}>

export type GetPageResourcesQuery = {
  __typename?: 'Query'
  pageResourceCollection?: {
    __typename?: 'PageResourceCollection'
    total: number
    items: Array<{
      __typename?: 'PageResource'
      title?: string | null
      displayDate?: any | null
      hero?: any | null
      sys?: { __typename?: 'Sys'; publishedAt?: any | null }
      introduction?: {
        __typename?: 'PageResourceIntroduction'
        json: any
        links: {
          __typename?: 'PageResourceIntroductionLinks'
          assets: {
            __typename?: 'PageResourceIntroductionAssets'
            block: Array<{
              __typename?: 'Asset'
              height?: number | null
              title?: string | null
              url?: string | null
              width?: number | null
              alt?: string | null
              sys: { __typename?: 'Sys'; id: string }
            } | null>
            hyperlink: Array<{
              __typename?: 'Asset'
              height?: number | null
              title?: string | null
              url?: string | null
              width?: number | null
              alt?: string | null
              sys: { __typename?: 'Sys'; id: string }
            } | null>
          }
          entries: {
            __typename?: 'PageResourceIntroductionEntries'
            block: Array<
              | { __typename?: 'AccessControl' }
              | { __typename?: 'AccessControl2' }
              | { __typename?: 'AccessControl3' }
              | { __typename?: 'Accordion' }
              | { __typename?: 'AccordionWithImage' }
              | { __typename?: 'Announcement' }
              | { __typename?: 'AnnouncementSet' }
              | {
                  __typename: 'CaisiqArticle'
                  slug?: string | null
                  title?: string | null
                  category?: string | null
                  displayDate?: any | null
                  description?: string | null
                  introduction?: string | null
                  byCais?: boolean | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                    publishedAt?: any | null
                  }
                  fundProductIds?: {
                    __typename?: 'FundProductId'
                    fundProductIds?: Array<string | null> | null
                  } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                    width?: number | null
                    height?: number | null
                  } | null
                  author?: {
                    __typename?: 'CaisiqAuthor'
                    name?: string | null
                  } | null
                  firmsCollection?: {
                    __typename?: 'CaisiqArticleFirmsCollection'
                    items: Array<{
                      __typename?: 'MemberFirm'
                      id?: string | null
                      name?: string | null
                    } | null>
                  } | null
                }
              | { __typename?: 'CaisiqArticleDropdown' }
              | { __typename?: 'CaisiqAuthor' }
              | { __typename?: 'CaisiqDefaultCourseImageSet' }
              | { __typename?: 'CaisiqDefaultFundsThumbnailSet' }
              | { __typename?: 'CaisiqFundThumbnail' }
              | { __typename?: 'CaisiqFundThumbnailSet' }
              | { __typename?: 'CaisiqHeroHeader' }
              | { __typename?: 'CaisiqHeroHeaderNavigateToButton' }
              | { __typename?: 'CaisiqHomeOffice' }
              | { __typename?: 'CaisiqJoinCaisCallToAction' }
              | { __typename?: 'CaisiqTab' }
              | {
                  __typename: 'CaisiqVideo'
                  byCais?: boolean | null
                  title?: string | null
                  category?: string | null
                  summary?: string | null
                  duration?: number | null
                  displayDate?: any | null
                  muxVideo?: any | null
                  poster?: any | null
                  icon?: any | null
                  muxThumbnailTime?: number | null
                  description?: string | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                  fundProductIds?: {
                    __typename?: 'FundProductId'
                    fundProductIds?: Array<string | null> | null
                  } | null
                  firmsCollection?: {
                    __typename?: 'CaisiqVideoFirmsCollection'
                    items: Array<{
                      __typename?: 'MemberFirm'
                      id?: string | null
                      name?: string | null
                    } | null>
                  } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                    width?: number | null
                    height?: number | null
                  } | null
                  video?: { __typename?: 'Asset'; url?: string | null } | null
                }
              | { __typename?: 'CaisiqVideoPlaylist' }
              | { __typename?: 'CallToAction' }
              | {
                  __typename: 'Chart'
                  longSource?: string | null
                  title?: string | null
                  chartImage?: {
                    __typename?: 'ComponentImage'
                    altText?: string | null
                    cloudinaryImage?: any | null
                    name?: string | null
                    image?: {
                      __typename?: 'Asset'
                      url?: string | null
                      height?: number | null
                      width?: number | null
                      title?: string | null
                      alt?: string | null
                    } | null
                  } | null
                  footnote?: { __typename?: 'ChartFootnote'; json: any } | null
                  longDescription?: {
                    __typename?: 'ChartLongDescription'
                    json: any
                  } | null
                }
              | { __typename?: 'ChildFirm' }
              | { __typename?: 'CiqArticle' }
              | { __typename?: 'Company' }
              | { __typename?: 'CompanyAndContactInfo' }
              | { __typename?: 'ComponentCard' }
              | { __typename?: 'ComponentCompanyContainer' }
              | { __typename?: 'ComponentHero' }
              | { __typename?: 'ComponentHubspotForm' }
              | { __typename?: 'ComponentImage' }
              | { __typename?: 'ComponentPodcastPlayer' }
              | { __typename?: 'ComponentSection' }
              | { __typename?: 'ComponentSummary' }
              | { __typename?: 'ComponentTeamContainer' }
              | { __typename?: 'ComponentText' }
              | { __typename?: 'ComponentTextIllustration' }
              | { __typename?: 'ComponentTextImage' }
              | { __typename?: 'ComponentTextImageImageSection' }
              | { __typename?: 'ComponentTextImageListSection' }
              | { __typename?: 'ComponentTextImageTextSection' }
              | { __typename?: 'CorporateStats' }
              | { __typename?: 'CuratedFilterGroup' }
              | { __typename?: 'CuratedResearchContent' }
              | { __typename?: 'Disclaimer' }
              | { __typename?: 'Event' }
              | { __typename?: 'ExternalContent' }
              | { __typename?: 'Faq' }
              | { __typename?: 'FaqCategory' }
              | { __typename?: 'FeaturedContent' }
              | { __typename?: 'FilterGroup' }
              | { __typename?: 'Firm' }
              | { __typename?: 'FullWidthImage' }
              | { __typename?: 'FundProductId' }
              | { __typename?: 'GlossaryTerm' }
              | { __typename?: 'HomepageAppMetadata' }
              | { __typename?: 'HomepageContentSection' }
              | { __typename?: 'HomepageLandingPage' }
              | { __typename?: 'HtmlTable' }
              | { __typename?: 'ImageSection' }
              | { __typename?: 'LegalPage' }
              | { __typename?: 'Link' }
              | { __typename?: 'LoginScreen' }
              | { __typename?: 'Markdown' }
              | { __typename?: 'MemberFirm' }
              | { __typename?: 'Migration' }
              | { __typename?: 'NavHeaderTheme' }
              | { __typename?: 'Page' }
              | { __typename?: 'PageHelpCenterArticle' }
              | { __typename?: 'PageLanding' }
              | { __typename?: 'PagePwsCategory' }
              | { __typename?: 'PageResource' }
              | { __typename?: 'ParentFirm' }
              | {
                  __typename: 'Pdf'
                  title?: string | null
                  displayDate?: any | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                  image?: {
                    __typename?: 'Asset'
                    height?: number | null
                    title?: string | null
                    url?: string | null
                    width?: number | null
                    alt?: string | null
                  } | null
                  pdf?: {
                    __typename?: 'Asset'
                    height?: number | null
                    title?: string | null
                    url?: string | null
                    width?: number | null
                    fileName?: string | null
                    alt?: string | null
                  } | null
                }
              | { __typename?: 'Person' }
              | { __typename?: 'Pet' }
              | { __typename?: 'PlaylistContentSet' }
              | { __typename?: 'Presentation' }
              | { __typename?: 'PressAward' }
              | { __typename?: 'PwsContactMedia' }
              | { __typename?: 'PwsInsightsContentSet' }
              | { __typename?: 'PwsPressRelease' }
              | { __typename?: 'PwsSiteSettings' }
              | { __typename?: 'Quote' }
              | { __typename?: 'RelatedContent' }
              | { __typename?: 'RelatedContentStrategy' }
              | { __typename?: 'Seo' }
              | { __typename?: 'SkPlayground' }
              | { __typename?: 'Tag' }
              | { __typename?: 'TagFamily' }
              | { __typename?: 'Team' }
              | { __typename?: 'TestModel' }
              | { __typename?: 'Theme' }
              | {
                  __typename: 'Whitepaper'
                  byCais?: boolean | null
                  type?: string | null
                  slug?: string | null
                  title?: string | null
                  category?: string | null
                  displayDate?: any | null
                  introduction?: string | null
                  description?: string | null
                  fundProductIds?: {
                    __typename?: 'FundProductId'
                    fundProductIds?: Array<string | null> | null
                  } | null
                  firmsCollection?: {
                    __typename?: 'WhitepaperFirmsCollection'
                    items: Array<{
                      __typename?: 'MemberFirm'
                      id?: string | null
                      name?: string | null
                    } | null>
                  } | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                    publishedAt?: any | null
                  }
                  author?: {
                    __typename?: 'CaisiqAuthor'
                    name?: string | null
                  } | null
                  hero?: { __typename?: 'Asset'; url?: string | null } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                    width?: number | null
                    height?: number | null
                  } | null
                  pdfsCollection?: {
                    __typename?: 'WhitepaperPdfsCollection'
                    items: Array<{
                      __typename?: 'Pdf'
                      title?: string | null
                      displayDate?: any | null
                      pdf?: { __typename?: 'Asset'; url?: string | null } | null
                      image?: {
                        __typename?: 'Asset'
                        url?: string | null
                      } | null
                    } | null>
                  } | null
                }
              | null
            >
            hyperlink: Array<
              | { __typename?: 'AccessControl' }
              | { __typename?: 'AccessControl2' }
              | { __typename?: 'AccessControl3' }
              | { __typename?: 'Accordion' }
              | { __typename?: 'AccordionWithImage' }
              | { __typename?: 'Announcement' }
              | { __typename?: 'AnnouncementSet' }
              | { __typename?: 'CaisiqArticle'; slug?: string | null }
              | { __typename?: 'CaisiqArticleDropdown' }
              | { __typename?: 'CaisiqAuthor' }
              | { __typename?: 'CaisiqDefaultCourseImageSet' }
              | { __typename?: 'CaisiqDefaultFundsThumbnailSet' }
              | { __typename?: 'CaisiqFundThumbnail' }
              | { __typename?: 'CaisiqFundThumbnailSet' }
              | { __typename?: 'CaisiqHeroHeader' }
              | { __typename?: 'CaisiqHeroHeaderNavigateToButton' }
              | { __typename?: 'CaisiqHomeOffice' }
              | { __typename?: 'CaisiqJoinCaisCallToAction' }
              | { __typename?: 'CaisiqTab' }
              | { __typename?: 'CaisiqVideo' }
              | { __typename?: 'CaisiqVideoPlaylist' }
              | { __typename?: 'CallToAction' }
              | { __typename?: 'Chart' }
              | { __typename?: 'ChildFirm' }
              | { __typename?: 'CiqArticle' }
              | { __typename?: 'Company' }
              | { __typename?: 'CompanyAndContactInfo' }
              | { __typename?: 'ComponentCard' }
              | { __typename?: 'ComponentCompanyContainer' }
              | { __typename?: 'ComponentHero' }
              | { __typename?: 'ComponentHubspotForm' }
              | { __typename?: 'ComponentImage' }
              | { __typename?: 'ComponentPodcastPlayer' }
              | { __typename?: 'ComponentSection' }
              | { __typename?: 'ComponentSummary' }
              | { __typename?: 'ComponentTeamContainer' }
              | { __typename?: 'ComponentText' }
              | { __typename?: 'ComponentTextIllustration' }
              | { __typename?: 'ComponentTextImage' }
              | { __typename?: 'ComponentTextImageImageSection' }
              | { __typename?: 'ComponentTextImageListSection' }
              | { __typename?: 'ComponentTextImageTextSection' }
              | { __typename?: 'CorporateStats' }
              | { __typename?: 'CuratedFilterGroup' }
              | { __typename?: 'CuratedResearchContent' }
              | { __typename?: 'Disclaimer' }
              | { __typename?: 'Event' }
              | { __typename?: 'ExternalContent' }
              | { __typename?: 'Faq' }
              | { __typename?: 'FaqCategory' }
              | { __typename?: 'FeaturedContent' }
              | { __typename?: 'FilterGroup' }
              | { __typename?: 'Firm' }
              | { __typename?: 'FullWidthImage' }
              | { __typename?: 'FundProductId' }
              | { __typename?: 'GlossaryTerm' }
              | { __typename?: 'HomepageAppMetadata' }
              | { __typename?: 'HomepageContentSection' }
              | { __typename?: 'HomepageLandingPage' }
              | { __typename?: 'HtmlTable' }
              | { __typename?: 'ImageSection' }
              | { __typename?: 'LegalPage' }
              | { __typename?: 'Link' }
              | { __typename?: 'LoginScreen' }
              | { __typename?: 'Markdown' }
              | { __typename?: 'MemberFirm' }
              | { __typename?: 'Migration' }
              | { __typename?: 'NavHeaderTheme' }
              | { __typename?: 'Page' }
              | { __typename?: 'PageHelpCenterArticle' }
              | { __typename?: 'PageLanding' }
              | { __typename?: 'PagePwsCategory' }
              | { __typename?: 'PageResource' }
              | { __typename?: 'ParentFirm' }
              | {
                  __typename: 'Pdf'
                  title?: string | null
                  displayDate?: any | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                  image?: {
                    __typename?: 'Asset'
                    height?: number | null
                    title?: string | null
                    url?: string | null
                    width?: number | null
                    alt?: string | null
                  } | null
                  pdf?: {
                    __typename?: 'Asset'
                    height?: number | null
                    title?: string | null
                    url?: string | null
                    width?: number | null
                    fileName?: string | null
                    alt?: string | null
                  } | null
                }
              | { __typename?: 'Person' }
              | { __typename?: 'Pet' }
              | { __typename?: 'PlaylistContentSet' }
              | { __typename?: 'Presentation' }
              | { __typename?: 'PressAward' }
              | { __typename?: 'PwsContactMedia' }
              | { __typename?: 'PwsInsightsContentSet' }
              | { __typename?: 'PwsPressRelease'; slug?: string | null }
              | { __typename?: 'PwsSiteSettings' }
              | { __typename?: 'Quote' }
              | { __typename?: 'RelatedContent' }
              | { __typename?: 'RelatedContentStrategy' }
              | { __typename?: 'Seo' }
              | { __typename?: 'SkPlayground' }
              | { __typename?: 'Tag' }
              | { __typename?: 'TagFamily' }
              | { __typename?: 'Team' }
              | { __typename?: 'TestModel' }
              | { __typename?: 'Theme' }
              | { __typename?: 'Whitepaper'; slug?: string | null }
              | null
            >
            inline: Array<
              | { __typename?: 'AccessControl' }
              | { __typename?: 'AccessControl2' }
              | { __typename?: 'AccessControl3' }
              | { __typename?: 'Accordion' }
              | { __typename?: 'AccordionWithImage' }
              | { __typename?: 'Announcement' }
              | { __typename?: 'AnnouncementSet' }
              | {
                  __typename: 'CaisiqArticle'
                  slug?: string | null
                  title?: string | null
                  category?: string | null
                  displayDate?: any | null
                  description?: string | null
                  introduction?: string | null
                  byCais?: boolean | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                    publishedAt?: any | null
                  }
                  fundProductIds?: {
                    __typename?: 'FundProductId'
                    fundProductIds?: Array<string | null> | null
                  } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                    width?: number | null
                    height?: number | null
                  } | null
                  author?: {
                    __typename?: 'CaisiqAuthor'
                    name?: string | null
                  } | null
                  firmsCollection?: {
                    __typename?: 'CaisiqArticleFirmsCollection'
                    items: Array<{
                      __typename?: 'MemberFirm'
                      id?: string | null
                      name?: string | null
                    } | null>
                  } | null
                }
              | { __typename?: 'CaisiqArticleDropdown' }
              | { __typename?: 'CaisiqAuthor' }
              | { __typename?: 'CaisiqDefaultCourseImageSet' }
              | { __typename?: 'CaisiqDefaultFundsThumbnailSet' }
              | { __typename?: 'CaisiqFundThumbnail' }
              | { __typename?: 'CaisiqFundThumbnailSet' }
              | { __typename?: 'CaisiqHeroHeader' }
              | { __typename?: 'CaisiqHeroHeaderNavigateToButton' }
              | { __typename?: 'CaisiqHomeOffice' }
              | { __typename?: 'CaisiqJoinCaisCallToAction' }
              | { __typename?: 'CaisiqTab' }
              | {
                  __typename: 'CaisiqVideo'
                  byCais?: boolean | null
                  title?: string | null
                  category?: string | null
                  summary?: string | null
                  duration?: number | null
                  displayDate?: any | null
                  muxVideo?: any | null
                  poster?: any | null
                  icon?: any | null
                  muxThumbnailTime?: number | null
                  description?: string | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                  fundProductIds?: {
                    __typename?: 'FundProductId'
                    fundProductIds?: Array<string | null> | null
                  } | null
                  firmsCollection?: {
                    __typename?: 'CaisiqVideoFirmsCollection'
                    items: Array<{
                      __typename?: 'MemberFirm'
                      id?: string | null
                      name?: string | null
                    } | null>
                  } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                    width?: number | null
                    height?: number | null
                  } | null
                  video?: { __typename?: 'Asset'; url?: string | null } | null
                }
              | { __typename?: 'CaisiqVideoPlaylist' }
              | { __typename?: 'CallToAction' }
              | {
                  __typename: 'Chart'
                  longSource?: string | null
                  title?: string | null
                  chartImage?: {
                    __typename?: 'ComponentImage'
                    altText?: string | null
                    cloudinaryImage?: any | null
                    name?: string | null
                    image?: {
                      __typename?: 'Asset'
                      url?: string | null
                      height?: number | null
                      width?: number | null
                      title?: string | null
                      alt?: string | null
                    } | null
                  } | null
                  footnote?: { __typename?: 'ChartFootnote'; json: any } | null
                  longDescription?: {
                    __typename?: 'ChartLongDescription'
                    json: any
                  } | null
                }
              | { __typename?: 'ChildFirm' }
              | { __typename?: 'CiqArticle' }
              | { __typename?: 'Company' }
              | { __typename?: 'CompanyAndContactInfo' }
              | { __typename?: 'ComponentCard' }
              | { __typename?: 'ComponentCompanyContainer' }
              | { __typename?: 'ComponentHero' }
              | { __typename?: 'ComponentHubspotForm' }
              | { __typename?: 'ComponentImage' }
              | { __typename?: 'ComponentPodcastPlayer' }
              | { __typename?: 'ComponentSection' }
              | { __typename?: 'ComponentSummary' }
              | { __typename?: 'ComponentTeamContainer' }
              | { __typename?: 'ComponentText' }
              | { __typename?: 'ComponentTextIllustration' }
              | { __typename?: 'ComponentTextImage' }
              | { __typename?: 'ComponentTextImageImageSection' }
              | { __typename?: 'ComponentTextImageListSection' }
              | { __typename?: 'ComponentTextImageTextSection' }
              | { __typename?: 'CorporateStats' }
              | { __typename?: 'CuratedFilterGroup' }
              | { __typename?: 'CuratedResearchContent' }
              | { __typename?: 'Disclaimer' }
              | { __typename?: 'Event' }
              | { __typename?: 'ExternalContent' }
              | { __typename?: 'Faq' }
              | { __typename?: 'FaqCategory' }
              | { __typename?: 'FeaturedContent' }
              | { __typename?: 'FilterGroup' }
              | { __typename?: 'Firm' }
              | { __typename?: 'FullWidthImage' }
              | { __typename?: 'FundProductId' }
              | { __typename?: 'GlossaryTerm' }
              | { __typename?: 'HomepageAppMetadata' }
              | { __typename?: 'HomepageContentSection' }
              | { __typename?: 'HomepageLandingPage' }
              | { __typename?: 'HtmlTable' }
              | { __typename?: 'ImageSection' }
              | { __typename?: 'LegalPage' }
              | { __typename?: 'Link' }
              | { __typename?: 'LoginScreen' }
              | { __typename?: 'Markdown' }
              | { __typename?: 'MemberFirm' }
              | { __typename?: 'Migration' }
              | { __typename?: 'NavHeaderTheme' }
              | { __typename?: 'Page' }
              | { __typename?: 'PageHelpCenterArticle' }
              | { __typename?: 'PageLanding' }
              | { __typename?: 'PagePwsCategory' }
              | { __typename?: 'PageResource' }
              | { __typename?: 'ParentFirm' }
              | {
                  __typename: 'Pdf'
                  title?: string | null
                  displayDate?: any | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                  image?: {
                    __typename?: 'Asset'
                    height?: number | null
                    title?: string | null
                    url?: string | null
                    width?: number | null
                    alt?: string | null
                  } | null
                  pdf?: {
                    __typename?: 'Asset'
                    height?: number | null
                    title?: string | null
                    url?: string | null
                    width?: number | null
                    fileName?: string | null
                    alt?: string | null
                  } | null
                }
              | { __typename?: 'Person' }
              | { __typename?: 'Pet' }
              | { __typename?: 'PlaylistContentSet' }
              | { __typename?: 'Presentation' }
              | { __typename?: 'PressAward' }
              | { __typename?: 'PwsContactMedia' }
              | { __typename?: 'PwsInsightsContentSet' }
              | { __typename?: 'PwsPressRelease' }
              | { __typename?: 'PwsSiteSettings' }
              | { __typename?: 'Quote' }
              | { __typename?: 'RelatedContent' }
              | { __typename?: 'RelatedContentStrategy' }
              | { __typename?: 'Seo' }
              | { __typename?: 'SkPlayground' }
              | { __typename?: 'Tag' }
              | { __typename?: 'TagFamily' }
              | { __typename?: 'Team' }
              | { __typename?: 'TestModel' }
              | { __typename?: 'Theme' }
              | {
                  __typename: 'Whitepaper'
                  byCais?: boolean | null
                  type?: string | null
                  slug?: string | null
                  title?: string | null
                  category?: string | null
                  displayDate?: any | null
                  introduction?: string | null
                  description?: string | null
                  fundProductIds?: {
                    __typename?: 'FundProductId'
                    fundProductIds?: Array<string | null> | null
                  } | null
                  firmsCollection?: {
                    __typename?: 'WhitepaperFirmsCollection'
                    items: Array<{
                      __typename?: 'MemberFirm'
                      id?: string | null
                      name?: string | null
                    } | null>
                  } | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                    publishedAt?: any | null
                  }
                  author?: {
                    __typename?: 'CaisiqAuthor'
                    name?: string | null
                  } | null
                  hero?: { __typename?: 'Asset'; url?: string | null } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                    width?: number | null
                    height?: number | null
                  } | null
                  pdfsCollection?: {
                    __typename?: 'WhitepaperPdfsCollection'
                    items: Array<{
                      __typename?: 'Pdf'
                      title?: string | null
                      displayDate?: any | null
                      pdf?: { __typename?: 'Asset'; url?: string | null } | null
                      image?: {
                        __typename?: 'Asset'
                        url?: string | null
                      } | null
                    } | null>
                  } | null
                }
              | null
            >
          }
        }
      } | null
      body?: {
        __typename?: 'PageResourceBody'
        json: any
        links: {
          __typename?: 'PageResourceBodyLinks'
          assets: {
            __typename?: 'PageResourceBodyAssets'
            block: Array<{
              __typename?: 'Asset'
              height?: number | null
              title?: string | null
              url?: string | null
              width?: number | null
              alt?: string | null
              sys: { __typename?: 'Sys'; id: string }
            } | null>
            hyperlink: Array<{
              __typename?: 'Asset'
              height?: number | null
              title?: string | null
              url?: string | null
              width?: number | null
              alt?: string | null
              sys: { __typename?: 'Sys'; id: string }
            } | null>
          }
          entries: {
            __typename?: 'PageResourceBodyEntries'
            block: Array<
              | { __typename?: 'AccessControl' }
              | { __typename?: 'AccessControl2' }
              | { __typename?: 'AccessControl3' }
              | { __typename?: 'Accordion' }
              | { __typename?: 'AccordionWithImage' }
              | { __typename?: 'Announcement' }
              | { __typename?: 'AnnouncementSet' }
              | {
                  __typename: 'CaisiqArticle'
                  slug?: string | null
                  title?: string | null
                  category?: string | null
                  displayDate?: any | null
                  description?: string | null
                  introduction?: string | null
                  byCais?: boolean | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                    publishedAt?: any | null
                  }
                  fundProductIds?: {
                    __typename?: 'FundProductId'
                    fundProductIds?: Array<string | null> | null
                  } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                    width?: number | null
                    height?: number | null
                  } | null
                  author?: {
                    __typename?: 'CaisiqAuthor'
                    name?: string | null
                  } | null
                  firmsCollection?: {
                    __typename?: 'CaisiqArticleFirmsCollection'
                    items: Array<{
                      __typename?: 'MemberFirm'
                      id?: string | null
                      name?: string | null
                    } | null>
                  } | null
                }
              | { __typename?: 'CaisiqArticleDropdown' }
              | { __typename?: 'CaisiqAuthor' }
              | { __typename?: 'CaisiqDefaultCourseImageSet' }
              | { __typename?: 'CaisiqDefaultFundsThumbnailSet' }
              | { __typename?: 'CaisiqFundThumbnail' }
              | { __typename?: 'CaisiqFundThumbnailSet' }
              | { __typename?: 'CaisiqHeroHeader' }
              | { __typename?: 'CaisiqHeroHeaderNavigateToButton' }
              | { __typename?: 'CaisiqHomeOffice' }
              | { __typename?: 'CaisiqJoinCaisCallToAction' }
              | { __typename?: 'CaisiqTab' }
              | {
                  __typename: 'CaisiqVideo'
                  byCais?: boolean | null
                  title?: string | null
                  category?: string | null
                  summary?: string | null
                  duration?: number | null
                  displayDate?: any | null
                  muxVideo?: any | null
                  poster?: any | null
                  icon?: any | null
                  muxThumbnailTime?: number | null
                  description?: string | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                  fundProductIds?: {
                    __typename?: 'FundProductId'
                    fundProductIds?: Array<string | null> | null
                  } | null
                  firmsCollection?: {
                    __typename?: 'CaisiqVideoFirmsCollection'
                    items: Array<{
                      __typename?: 'MemberFirm'
                      id?: string | null
                      name?: string | null
                    } | null>
                  } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                    width?: number | null
                    height?: number | null
                  } | null
                  video?: { __typename?: 'Asset'; url?: string | null } | null
                }
              | { __typename?: 'CaisiqVideoPlaylist' }
              | { __typename?: 'CallToAction' }
              | {
                  __typename: 'Chart'
                  longSource?: string | null
                  title?: string | null
                  chartImage?: {
                    __typename?: 'ComponentImage'
                    altText?: string | null
                    cloudinaryImage?: any | null
                    name?: string | null
                    image?: {
                      __typename?: 'Asset'
                      url?: string | null
                      height?: number | null
                      width?: number | null
                      title?: string | null
                      alt?: string | null
                    } | null
                  } | null
                  footnote?: { __typename?: 'ChartFootnote'; json: any } | null
                  longDescription?: {
                    __typename?: 'ChartLongDescription'
                    json: any
                  } | null
                }
              | { __typename?: 'ChildFirm' }
              | { __typename?: 'CiqArticle' }
              | { __typename?: 'Company' }
              | { __typename?: 'CompanyAndContactInfo' }
              | { __typename?: 'ComponentCard' }
              | { __typename?: 'ComponentCompanyContainer' }
              | { __typename?: 'ComponentHero' }
              | { __typename?: 'ComponentHubspotForm' }
              | { __typename?: 'ComponentImage' }
              | { __typename?: 'ComponentPodcastPlayer' }
              | { __typename?: 'ComponentSection' }
              | { __typename?: 'ComponentSummary' }
              | { __typename?: 'ComponentTeamContainer' }
              | { __typename?: 'ComponentText' }
              | { __typename?: 'ComponentTextIllustration' }
              | { __typename?: 'ComponentTextImage' }
              | { __typename?: 'ComponentTextImageImageSection' }
              | { __typename?: 'ComponentTextImageListSection' }
              | { __typename?: 'ComponentTextImageTextSection' }
              | { __typename?: 'CorporateStats' }
              | { __typename?: 'CuratedFilterGroup' }
              | { __typename?: 'CuratedResearchContent' }
              | { __typename?: 'Disclaimer' }
              | { __typename?: 'Event' }
              | { __typename?: 'ExternalContent' }
              | { __typename?: 'Faq' }
              | { __typename?: 'FaqCategory' }
              | { __typename?: 'FeaturedContent' }
              | { __typename?: 'FilterGroup' }
              | { __typename?: 'Firm' }
              | { __typename?: 'FullWidthImage' }
              | { __typename?: 'FundProductId' }
              | { __typename?: 'GlossaryTerm' }
              | { __typename?: 'HomepageAppMetadata' }
              | { __typename?: 'HomepageContentSection' }
              | { __typename?: 'HomepageLandingPage' }
              | { __typename?: 'HtmlTable' }
              | { __typename?: 'ImageSection' }
              | { __typename?: 'LegalPage' }
              | { __typename?: 'Link' }
              | { __typename?: 'LoginScreen' }
              | { __typename?: 'Markdown' }
              | { __typename?: 'MemberFirm' }
              | { __typename?: 'Migration' }
              | { __typename?: 'NavHeaderTheme' }
              | { __typename?: 'Page' }
              | { __typename?: 'PageHelpCenterArticle' }
              | { __typename?: 'PageLanding' }
              | { __typename?: 'PagePwsCategory' }
              | { __typename?: 'PageResource' }
              | { __typename?: 'ParentFirm' }
              | {
                  __typename: 'Pdf'
                  title?: string | null
                  displayDate?: any | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                  image?: {
                    __typename?: 'Asset'
                    height?: number | null
                    title?: string | null
                    url?: string | null
                    width?: number | null
                    alt?: string | null
                  } | null
                  pdf?: {
                    __typename?: 'Asset'
                    height?: number | null
                    title?: string | null
                    url?: string | null
                    width?: number | null
                    fileName?: string | null
                    alt?: string | null
                  } | null
                }
              | { __typename?: 'Person' }
              | { __typename?: 'Pet' }
              | { __typename?: 'PlaylistContentSet' }
              | { __typename?: 'Presentation' }
              | { __typename?: 'PressAward' }
              | { __typename?: 'PwsContactMedia' }
              | { __typename?: 'PwsInsightsContentSet' }
              | { __typename?: 'PwsPressRelease' }
              | { __typename?: 'PwsSiteSettings' }
              | { __typename?: 'Quote' }
              | { __typename?: 'RelatedContent' }
              | { __typename?: 'RelatedContentStrategy' }
              | { __typename?: 'Seo' }
              | { __typename?: 'SkPlayground' }
              | { __typename?: 'Tag' }
              | { __typename?: 'TagFamily' }
              | { __typename?: 'Team' }
              | { __typename?: 'TestModel' }
              | { __typename?: 'Theme' }
              | {
                  __typename: 'Whitepaper'
                  byCais?: boolean | null
                  type?: string | null
                  slug?: string | null
                  title?: string | null
                  category?: string | null
                  displayDate?: any | null
                  introduction?: string | null
                  description?: string | null
                  fundProductIds?: {
                    __typename?: 'FundProductId'
                    fundProductIds?: Array<string | null> | null
                  } | null
                  firmsCollection?: {
                    __typename?: 'WhitepaperFirmsCollection'
                    items: Array<{
                      __typename?: 'MemberFirm'
                      id?: string | null
                      name?: string | null
                    } | null>
                  } | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                    publishedAt?: any | null
                  }
                  author?: {
                    __typename?: 'CaisiqAuthor'
                    name?: string | null
                  } | null
                  hero?: { __typename?: 'Asset'; url?: string | null } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                    width?: number | null
                    height?: number | null
                  } | null
                  pdfsCollection?: {
                    __typename?: 'WhitepaperPdfsCollection'
                    items: Array<{
                      __typename?: 'Pdf'
                      title?: string | null
                      displayDate?: any | null
                      pdf?: { __typename?: 'Asset'; url?: string | null } | null
                      image?: {
                        __typename?: 'Asset'
                        url?: string | null
                      } | null
                    } | null>
                  } | null
                }
              | null
            >
            hyperlink: Array<
              | { __typename?: 'AccessControl' }
              | { __typename?: 'AccessControl2' }
              | { __typename?: 'AccessControl3' }
              | { __typename?: 'Accordion' }
              | { __typename?: 'AccordionWithImage' }
              | { __typename?: 'Announcement' }
              | { __typename?: 'AnnouncementSet' }
              | { __typename?: 'CaisiqArticle'; slug?: string | null }
              | { __typename?: 'CaisiqArticleDropdown' }
              | { __typename?: 'CaisiqAuthor' }
              | { __typename?: 'CaisiqDefaultCourseImageSet' }
              | { __typename?: 'CaisiqDefaultFundsThumbnailSet' }
              | { __typename?: 'CaisiqFundThumbnail' }
              | { __typename?: 'CaisiqFundThumbnailSet' }
              | { __typename?: 'CaisiqHeroHeader' }
              | { __typename?: 'CaisiqHeroHeaderNavigateToButton' }
              | { __typename?: 'CaisiqHomeOffice' }
              | { __typename?: 'CaisiqJoinCaisCallToAction' }
              | { __typename?: 'CaisiqTab' }
              | { __typename?: 'CaisiqVideo' }
              | { __typename?: 'CaisiqVideoPlaylist' }
              | { __typename?: 'CallToAction' }
              | { __typename?: 'Chart' }
              | { __typename?: 'ChildFirm' }
              | { __typename?: 'CiqArticle' }
              | { __typename?: 'Company' }
              | { __typename?: 'CompanyAndContactInfo' }
              | { __typename?: 'ComponentCard' }
              | { __typename?: 'ComponentCompanyContainer' }
              | { __typename?: 'ComponentHero' }
              | { __typename?: 'ComponentHubspotForm' }
              | { __typename?: 'ComponentImage' }
              | { __typename?: 'ComponentPodcastPlayer' }
              | { __typename?: 'ComponentSection' }
              | { __typename?: 'ComponentSummary' }
              | { __typename?: 'ComponentTeamContainer' }
              | { __typename?: 'ComponentText' }
              | { __typename?: 'ComponentTextIllustration' }
              | { __typename?: 'ComponentTextImage' }
              | { __typename?: 'ComponentTextImageImageSection' }
              | { __typename?: 'ComponentTextImageListSection' }
              | { __typename?: 'ComponentTextImageTextSection' }
              | { __typename?: 'CorporateStats' }
              | { __typename?: 'CuratedFilterGroup' }
              | { __typename?: 'CuratedResearchContent' }
              | { __typename?: 'Disclaimer' }
              | { __typename?: 'Event' }
              | { __typename?: 'ExternalContent' }
              | { __typename?: 'Faq' }
              | { __typename?: 'FaqCategory' }
              | { __typename?: 'FeaturedContent' }
              | { __typename?: 'FilterGroup' }
              | { __typename?: 'Firm' }
              | { __typename?: 'FullWidthImage' }
              | { __typename?: 'FundProductId' }
              | { __typename?: 'GlossaryTerm' }
              | { __typename?: 'HomepageAppMetadata' }
              | { __typename?: 'HomepageContentSection' }
              | { __typename?: 'HomepageLandingPage' }
              | { __typename?: 'HtmlTable' }
              | { __typename?: 'ImageSection' }
              | { __typename?: 'LegalPage' }
              | { __typename?: 'Link' }
              | { __typename?: 'LoginScreen' }
              | { __typename?: 'Markdown' }
              | { __typename?: 'MemberFirm' }
              | { __typename?: 'Migration' }
              | { __typename?: 'NavHeaderTheme' }
              | { __typename?: 'Page' }
              | { __typename?: 'PageHelpCenterArticle' }
              | { __typename?: 'PageLanding' }
              | { __typename?: 'PagePwsCategory' }
              | { __typename?: 'PageResource' }
              | { __typename?: 'ParentFirm' }
              | {
                  __typename: 'Pdf'
                  title?: string | null
                  displayDate?: any | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                  image?: {
                    __typename?: 'Asset'
                    height?: number | null
                    title?: string | null
                    url?: string | null
                    width?: number | null
                    alt?: string | null
                  } | null
                  pdf?: {
                    __typename?: 'Asset'
                    height?: number | null
                    title?: string | null
                    url?: string | null
                    width?: number | null
                    fileName?: string | null
                    alt?: string | null
                  } | null
                }
              | { __typename?: 'Person' }
              | { __typename?: 'Pet' }
              | { __typename?: 'PlaylistContentSet' }
              | { __typename?: 'Presentation' }
              | { __typename?: 'PressAward' }
              | { __typename?: 'PwsContactMedia' }
              | { __typename?: 'PwsInsightsContentSet' }
              | { __typename?: 'PwsPressRelease'; slug?: string | null }
              | { __typename?: 'PwsSiteSettings' }
              | { __typename?: 'Quote' }
              | { __typename?: 'RelatedContent' }
              | { __typename?: 'RelatedContentStrategy' }
              | { __typename?: 'Seo' }
              | { __typename?: 'SkPlayground' }
              | { __typename?: 'Tag' }
              | { __typename?: 'TagFamily' }
              | { __typename?: 'Team' }
              | { __typename?: 'TestModel' }
              | { __typename?: 'Theme' }
              | { __typename?: 'Whitepaper'; slug?: string | null }
              | null
            >
            inline: Array<
              | { __typename?: 'AccessControl' }
              | { __typename?: 'AccessControl2' }
              | { __typename?: 'AccessControl3' }
              | { __typename?: 'Accordion' }
              | { __typename?: 'AccordionWithImage' }
              | { __typename?: 'Announcement' }
              | { __typename?: 'AnnouncementSet' }
              | {
                  __typename: 'CaisiqArticle'
                  slug?: string | null
                  title?: string | null
                  category?: string | null
                  displayDate?: any | null
                  description?: string | null
                  introduction?: string | null
                  byCais?: boolean | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                    publishedAt?: any | null
                  }
                  fundProductIds?: {
                    __typename?: 'FundProductId'
                    fundProductIds?: Array<string | null> | null
                  } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                    width?: number | null
                    height?: number | null
                  } | null
                  author?: {
                    __typename?: 'CaisiqAuthor'
                    name?: string | null
                  } | null
                  firmsCollection?: {
                    __typename?: 'CaisiqArticleFirmsCollection'
                    items: Array<{
                      __typename?: 'MemberFirm'
                      id?: string | null
                      name?: string | null
                    } | null>
                  } | null
                }
              | { __typename?: 'CaisiqArticleDropdown' }
              | { __typename?: 'CaisiqAuthor' }
              | { __typename?: 'CaisiqDefaultCourseImageSet' }
              | { __typename?: 'CaisiqDefaultFundsThumbnailSet' }
              | { __typename?: 'CaisiqFundThumbnail' }
              | { __typename?: 'CaisiqFundThumbnailSet' }
              | { __typename?: 'CaisiqHeroHeader' }
              | { __typename?: 'CaisiqHeroHeaderNavigateToButton' }
              | { __typename?: 'CaisiqHomeOffice' }
              | { __typename?: 'CaisiqJoinCaisCallToAction' }
              | { __typename?: 'CaisiqTab' }
              | {
                  __typename: 'CaisiqVideo'
                  byCais?: boolean | null
                  title?: string | null
                  category?: string | null
                  summary?: string | null
                  duration?: number | null
                  displayDate?: any | null
                  muxVideo?: any | null
                  poster?: any | null
                  icon?: any | null
                  muxThumbnailTime?: number | null
                  description?: string | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                  fundProductIds?: {
                    __typename?: 'FundProductId'
                    fundProductIds?: Array<string | null> | null
                  } | null
                  firmsCollection?: {
                    __typename?: 'CaisiqVideoFirmsCollection'
                    items: Array<{
                      __typename?: 'MemberFirm'
                      id?: string | null
                      name?: string | null
                    } | null>
                  } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                    width?: number | null
                    height?: number | null
                  } | null
                  video?: { __typename?: 'Asset'; url?: string | null } | null
                }
              | { __typename?: 'CaisiqVideoPlaylist' }
              | { __typename?: 'CallToAction' }
              | {
                  __typename: 'Chart'
                  longSource?: string | null
                  title?: string | null
                  chartImage?: {
                    __typename?: 'ComponentImage'
                    altText?: string | null
                    cloudinaryImage?: any | null
                    name?: string | null
                    image?: {
                      __typename?: 'Asset'
                      url?: string | null
                      height?: number | null
                      width?: number | null
                      title?: string | null
                      alt?: string | null
                    } | null
                  } | null
                  footnote?: { __typename?: 'ChartFootnote'; json: any } | null
                  longDescription?: {
                    __typename?: 'ChartLongDescription'
                    json: any
                  } | null
                }
              | { __typename?: 'ChildFirm' }
              | { __typename?: 'CiqArticle' }
              | { __typename?: 'Company' }
              | { __typename?: 'CompanyAndContactInfo' }
              | { __typename?: 'ComponentCard' }
              | { __typename?: 'ComponentCompanyContainer' }
              | { __typename?: 'ComponentHero' }
              | { __typename?: 'ComponentHubspotForm' }
              | { __typename?: 'ComponentImage' }
              | { __typename?: 'ComponentPodcastPlayer' }
              | { __typename?: 'ComponentSection' }
              | { __typename?: 'ComponentSummary' }
              | { __typename?: 'ComponentTeamContainer' }
              | { __typename?: 'ComponentText' }
              | { __typename?: 'ComponentTextIllustration' }
              | { __typename?: 'ComponentTextImage' }
              | { __typename?: 'ComponentTextImageImageSection' }
              | { __typename?: 'ComponentTextImageListSection' }
              | { __typename?: 'ComponentTextImageTextSection' }
              | { __typename?: 'CorporateStats' }
              | { __typename?: 'CuratedFilterGroup' }
              | { __typename?: 'CuratedResearchContent' }
              | { __typename?: 'Disclaimer' }
              | { __typename?: 'Event' }
              | { __typename?: 'ExternalContent' }
              | { __typename?: 'Faq' }
              | { __typename?: 'FaqCategory' }
              | { __typename?: 'FeaturedContent' }
              | { __typename?: 'FilterGroup' }
              | { __typename?: 'Firm' }
              | { __typename?: 'FullWidthImage' }
              | { __typename?: 'FundProductId' }
              | { __typename?: 'GlossaryTerm' }
              | { __typename?: 'HomepageAppMetadata' }
              | { __typename?: 'HomepageContentSection' }
              | { __typename?: 'HomepageLandingPage' }
              | { __typename?: 'HtmlTable' }
              | { __typename?: 'ImageSection' }
              | { __typename?: 'LegalPage' }
              | { __typename?: 'Link' }
              | { __typename?: 'LoginScreen' }
              | { __typename?: 'Markdown' }
              | { __typename?: 'MemberFirm' }
              | { __typename?: 'Migration' }
              | { __typename?: 'NavHeaderTheme' }
              | { __typename?: 'Page' }
              | { __typename?: 'PageHelpCenterArticle' }
              | { __typename?: 'PageLanding' }
              | { __typename?: 'PagePwsCategory' }
              | { __typename?: 'PageResource' }
              | { __typename?: 'ParentFirm' }
              | {
                  __typename: 'Pdf'
                  title?: string | null
                  displayDate?: any | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                  image?: {
                    __typename?: 'Asset'
                    height?: number | null
                    title?: string | null
                    url?: string | null
                    width?: number | null
                    alt?: string | null
                  } | null
                  pdf?: {
                    __typename?: 'Asset'
                    height?: number | null
                    title?: string | null
                    url?: string | null
                    width?: number | null
                    fileName?: string | null
                    alt?: string | null
                  } | null
                }
              | { __typename?: 'Person' }
              | { __typename?: 'Pet' }
              | { __typename?: 'PlaylistContentSet' }
              | { __typename?: 'Presentation' }
              | { __typename?: 'PressAward' }
              | { __typename?: 'PwsContactMedia' }
              | { __typename?: 'PwsInsightsContentSet' }
              | { __typename?: 'PwsPressRelease' }
              | { __typename?: 'PwsSiteSettings' }
              | { __typename?: 'Quote' }
              | { __typename?: 'RelatedContent' }
              | { __typename?: 'RelatedContentStrategy' }
              | { __typename?: 'Seo' }
              | { __typename?: 'SkPlayground' }
              | { __typename?: 'Tag' }
              | { __typename?: 'TagFamily' }
              | { __typename?: 'Team' }
              | { __typename?: 'TestModel' }
              | { __typename?: 'Theme' }
              | {
                  __typename: 'Whitepaper'
                  byCais?: boolean | null
                  type?: string | null
                  slug?: string | null
                  title?: string | null
                  category?: string | null
                  displayDate?: any | null
                  introduction?: string | null
                  description?: string | null
                  fundProductIds?: {
                    __typename?: 'FundProductId'
                    fundProductIds?: Array<string | null> | null
                  } | null
                  firmsCollection?: {
                    __typename?: 'WhitepaperFirmsCollection'
                    items: Array<{
                      __typename?: 'MemberFirm'
                      id?: string | null
                      name?: string | null
                    } | null>
                  } | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                    publishedAt?: any | null
                  }
                  author?: {
                    __typename?: 'CaisiqAuthor'
                    name?: string | null
                  } | null
                  hero?: { __typename?: 'Asset'; url?: string | null } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                    width?: number | null
                    height?: number | null
                  } | null
                  pdfsCollection?: {
                    __typename?: 'WhitepaperPdfsCollection'
                    items: Array<{
                      __typename?: 'Pdf'
                      title?: string | null
                      displayDate?: any | null
                      pdf?: { __typename?: 'Asset'; url?: string | null } | null
                      image?: {
                        __typename?: 'Asset'
                        url?: string | null
                      } | null
                    } | null>
                  } | null
                }
              | null
            >
          }
        }
      } | null
      author?: {
        __typename?: 'CaisiqAuthor'
        name?: string | null
        authorName?: string | null
        jobTitle?: string | null
        firm?: string | null
        avatar?: {
          __typename?: 'ComponentImage'
          altText?: string | null
          image?: {
            __typename?: 'Asset'
            url?: string | null
            title?: string | null
          } | null
        } | null
        linkedin?: { __typename?: 'Link'; externalLink?: string | null } | null
      } | null
    } | null>
  } | null
}

export type PageResourceFragment = {
  __typename?: 'PageResource'
  title?: string | null
  displayDate?: any | null
  hero?: any | null
  sys?: { __typename?: 'Sys'; publishedAt?: any | null }
  introduction?: {
    __typename?: 'PageResourceIntroduction'
    json: any
    links: {
      __typename?: 'PageResourceIntroductionLinks'
      assets: {
        __typename?: 'PageResourceIntroductionAssets'
        block: Array<{
          __typename?: 'Asset'
          height?: number | null
          title?: string | null
          url?: string | null
          width?: number | null
          alt?: string | null
          sys: { __typename?: 'Sys'; id: string }
        } | null>
        hyperlink: Array<{
          __typename?: 'Asset'
          height?: number | null
          title?: string | null
          url?: string | null
          width?: number | null
          alt?: string | null
          sys: { __typename?: 'Sys'; id: string }
        } | null>
      }
      entries: {
        __typename?: 'PageResourceIntroductionEntries'
        block: Array<
          | { __typename?: 'AccessControl' }
          | { __typename?: 'AccessControl2' }
          | { __typename?: 'AccessControl3' }
          | { __typename?: 'Accordion' }
          | { __typename?: 'AccordionWithImage' }
          | { __typename?: 'Announcement' }
          | { __typename?: 'AnnouncementSet' }
          | {
              __typename: 'CaisiqArticle'
              slug?: string | null
              title?: string | null
              category?: string | null
              displayDate?: any | null
              description?: string | null
              introduction?: string | null
              byCais?: boolean | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
                publishedAt?: any | null
              }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqArticleFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
            }
          | { __typename?: 'CaisiqArticleDropdown' }
          | { __typename?: 'CaisiqAuthor' }
          | { __typename?: 'CaisiqDefaultCourseImageSet' }
          | { __typename?: 'CaisiqDefaultFundsThumbnailSet' }
          | { __typename?: 'CaisiqFundThumbnail' }
          | { __typename?: 'CaisiqFundThumbnailSet' }
          | { __typename?: 'CaisiqHeroHeader' }
          | { __typename?: 'CaisiqHeroHeaderNavigateToButton' }
          | { __typename?: 'CaisiqHomeOffice' }
          | { __typename?: 'CaisiqJoinCaisCallToAction' }
          | { __typename?: 'CaisiqTab' }
          | {
              __typename: 'CaisiqVideo'
              byCais?: boolean | null
              title?: string | null
              category?: string | null
              summary?: string | null
              duration?: number | null
              displayDate?: any | null
              muxVideo?: any | null
              poster?: any | null
              icon?: any | null
              muxThumbnailTime?: number | null
              description?: string | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqVideoFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              video?: { __typename?: 'Asset'; url?: string | null } | null
            }
          | { __typename?: 'CaisiqVideoPlaylist' }
          | { __typename?: 'CallToAction' }
          | {
              __typename: 'Chart'
              longSource?: string | null
              title?: string | null
              chartImage?: {
                __typename?: 'ComponentImage'
                altText?: string | null
                cloudinaryImage?: any | null
                name?: string | null
                image?: {
                  __typename?: 'Asset'
                  url?: string | null
                  height?: number | null
                  width?: number | null
                  title?: string | null
                  alt?: string | null
                } | null
              } | null
              footnote?: { __typename?: 'ChartFootnote'; json: any } | null
              longDescription?: {
                __typename?: 'ChartLongDescription'
                json: any
              } | null
            }
          | { __typename?: 'ChildFirm' }
          | { __typename?: 'CiqArticle' }
          | { __typename?: 'Company' }
          | { __typename?: 'CompanyAndContactInfo' }
          | { __typename?: 'ComponentCard' }
          | { __typename?: 'ComponentCompanyContainer' }
          | { __typename?: 'ComponentHero' }
          | { __typename?: 'ComponentHubspotForm' }
          | { __typename?: 'ComponentImage' }
          | { __typename?: 'ComponentPodcastPlayer' }
          | { __typename?: 'ComponentSection' }
          | { __typename?: 'ComponentSummary' }
          | { __typename?: 'ComponentTeamContainer' }
          | { __typename?: 'ComponentText' }
          | { __typename?: 'ComponentTextIllustration' }
          | { __typename?: 'ComponentTextImage' }
          | { __typename?: 'ComponentTextImageImageSection' }
          | { __typename?: 'ComponentTextImageListSection' }
          | { __typename?: 'ComponentTextImageTextSection' }
          | { __typename?: 'CorporateStats' }
          | { __typename?: 'CuratedFilterGroup' }
          | { __typename?: 'CuratedResearchContent' }
          | { __typename?: 'Disclaimer' }
          | { __typename?: 'Event' }
          | { __typename?: 'ExternalContent' }
          | { __typename?: 'Faq' }
          | { __typename?: 'FaqCategory' }
          | { __typename?: 'FeaturedContent' }
          | { __typename?: 'FilterGroup' }
          | { __typename?: 'Firm' }
          | { __typename?: 'FullWidthImage' }
          | { __typename?: 'FundProductId' }
          | { __typename?: 'GlossaryTerm' }
          | { __typename?: 'HomepageAppMetadata' }
          | { __typename?: 'HomepageContentSection' }
          | { __typename?: 'HomepageLandingPage' }
          | { __typename?: 'HtmlTable' }
          | { __typename?: 'ImageSection' }
          | { __typename?: 'LegalPage' }
          | { __typename?: 'Link' }
          | { __typename?: 'LoginScreen' }
          | { __typename?: 'Markdown' }
          | { __typename?: 'MemberFirm' }
          | { __typename?: 'Migration' }
          | { __typename?: 'NavHeaderTheme' }
          | { __typename?: 'Page' }
          | { __typename?: 'PageHelpCenterArticle' }
          | { __typename?: 'PageLanding' }
          | { __typename?: 'PagePwsCategory' }
          | { __typename?: 'PageResource' }
          | { __typename?: 'ParentFirm' }
          | {
              __typename: 'Pdf'
              title?: string | null
              displayDate?: any | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              image?: {
                __typename?: 'Asset'
                height?: number | null
                title?: string | null
                url?: string | null
                width?: number | null
                alt?: string | null
              } | null
              pdf?: {
                __typename?: 'Asset'
                height?: number | null
                title?: string | null
                url?: string | null
                width?: number | null
                fileName?: string | null
                alt?: string | null
              } | null
            }
          | { __typename?: 'Person' }
          | { __typename?: 'Pet' }
          | { __typename?: 'PlaylistContentSet' }
          | { __typename?: 'Presentation' }
          | { __typename?: 'PressAward' }
          | { __typename?: 'PwsContactMedia' }
          | { __typename?: 'PwsInsightsContentSet' }
          | { __typename?: 'PwsPressRelease' }
          | { __typename?: 'PwsSiteSettings' }
          | { __typename?: 'Quote' }
          | { __typename?: 'RelatedContent' }
          | { __typename?: 'RelatedContentStrategy' }
          | { __typename?: 'Seo' }
          | { __typename?: 'SkPlayground' }
          | { __typename?: 'Tag' }
          | { __typename?: 'TagFamily' }
          | { __typename?: 'Team' }
          | { __typename?: 'TestModel' }
          | { __typename?: 'Theme' }
          | {
              __typename: 'Whitepaper'
              byCais?: boolean | null
              type?: string | null
              slug?: string | null
              title?: string | null
              category?: string | null
              displayDate?: any | null
              introduction?: string | null
              description?: string | null
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'WhitepaperFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
                publishedAt?: any | null
              }
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              hero?: { __typename?: 'Asset'; url?: string | null } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              pdfsCollection?: {
                __typename?: 'WhitepaperPdfsCollection'
                items: Array<{
                  __typename?: 'Pdf'
                  title?: string | null
                  displayDate?: any | null
                  pdf?: { __typename?: 'Asset'; url?: string | null } | null
                  image?: { __typename?: 'Asset'; url?: string | null } | null
                } | null>
              } | null
            }
          | null
        >
        hyperlink: Array<
          | { __typename?: 'AccessControl' }
          | { __typename?: 'AccessControl2' }
          | { __typename?: 'AccessControl3' }
          | { __typename?: 'Accordion' }
          | { __typename?: 'AccordionWithImage' }
          | { __typename?: 'Announcement' }
          | { __typename?: 'AnnouncementSet' }
          | { __typename?: 'CaisiqArticle'; slug?: string | null }
          | { __typename?: 'CaisiqArticleDropdown' }
          | { __typename?: 'CaisiqAuthor' }
          | { __typename?: 'CaisiqDefaultCourseImageSet' }
          | { __typename?: 'CaisiqDefaultFundsThumbnailSet' }
          | { __typename?: 'CaisiqFundThumbnail' }
          | { __typename?: 'CaisiqFundThumbnailSet' }
          | { __typename?: 'CaisiqHeroHeader' }
          | { __typename?: 'CaisiqHeroHeaderNavigateToButton' }
          | { __typename?: 'CaisiqHomeOffice' }
          | { __typename?: 'CaisiqJoinCaisCallToAction' }
          | { __typename?: 'CaisiqTab' }
          | { __typename?: 'CaisiqVideo' }
          | { __typename?: 'CaisiqVideoPlaylist' }
          | { __typename?: 'CallToAction' }
          | { __typename?: 'Chart' }
          | { __typename?: 'ChildFirm' }
          | { __typename?: 'CiqArticle' }
          | { __typename?: 'Company' }
          | { __typename?: 'CompanyAndContactInfo' }
          | { __typename?: 'ComponentCard' }
          | { __typename?: 'ComponentCompanyContainer' }
          | { __typename?: 'ComponentHero' }
          | { __typename?: 'ComponentHubspotForm' }
          | { __typename?: 'ComponentImage' }
          | { __typename?: 'ComponentPodcastPlayer' }
          | { __typename?: 'ComponentSection' }
          | { __typename?: 'ComponentSummary' }
          | { __typename?: 'ComponentTeamContainer' }
          | { __typename?: 'ComponentText' }
          | { __typename?: 'ComponentTextIllustration' }
          | { __typename?: 'ComponentTextImage' }
          | { __typename?: 'ComponentTextImageImageSection' }
          | { __typename?: 'ComponentTextImageListSection' }
          | { __typename?: 'ComponentTextImageTextSection' }
          | { __typename?: 'CorporateStats' }
          | { __typename?: 'CuratedFilterGroup' }
          | { __typename?: 'CuratedResearchContent' }
          | { __typename?: 'Disclaimer' }
          | { __typename?: 'Event' }
          | { __typename?: 'ExternalContent' }
          | { __typename?: 'Faq' }
          | { __typename?: 'FaqCategory' }
          | { __typename?: 'FeaturedContent' }
          | { __typename?: 'FilterGroup' }
          | { __typename?: 'Firm' }
          | { __typename?: 'FullWidthImage' }
          | { __typename?: 'FundProductId' }
          | { __typename?: 'GlossaryTerm' }
          | { __typename?: 'HomepageAppMetadata' }
          | { __typename?: 'HomepageContentSection' }
          | { __typename?: 'HomepageLandingPage' }
          | { __typename?: 'HtmlTable' }
          | { __typename?: 'ImageSection' }
          | { __typename?: 'LegalPage' }
          | { __typename?: 'Link' }
          | { __typename?: 'LoginScreen' }
          | { __typename?: 'Markdown' }
          | { __typename?: 'MemberFirm' }
          | { __typename?: 'Migration' }
          | { __typename?: 'NavHeaderTheme' }
          | { __typename?: 'Page' }
          | { __typename?: 'PageHelpCenterArticle' }
          | { __typename?: 'PageLanding' }
          | { __typename?: 'PagePwsCategory' }
          | { __typename?: 'PageResource' }
          | { __typename?: 'ParentFirm' }
          | {
              __typename: 'Pdf'
              title?: string | null
              displayDate?: any | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              image?: {
                __typename?: 'Asset'
                height?: number | null
                title?: string | null
                url?: string | null
                width?: number | null
                alt?: string | null
              } | null
              pdf?: {
                __typename?: 'Asset'
                height?: number | null
                title?: string | null
                url?: string | null
                width?: number | null
                fileName?: string | null
                alt?: string | null
              } | null
            }
          | { __typename?: 'Person' }
          | { __typename?: 'Pet' }
          | { __typename?: 'PlaylistContentSet' }
          | { __typename?: 'Presentation' }
          | { __typename?: 'PressAward' }
          | { __typename?: 'PwsContactMedia' }
          | { __typename?: 'PwsInsightsContentSet' }
          | { __typename?: 'PwsPressRelease'; slug?: string | null }
          | { __typename?: 'PwsSiteSettings' }
          | { __typename?: 'Quote' }
          | { __typename?: 'RelatedContent' }
          | { __typename?: 'RelatedContentStrategy' }
          | { __typename?: 'Seo' }
          | { __typename?: 'SkPlayground' }
          | { __typename?: 'Tag' }
          | { __typename?: 'TagFamily' }
          | { __typename?: 'Team' }
          | { __typename?: 'TestModel' }
          | { __typename?: 'Theme' }
          | { __typename?: 'Whitepaper'; slug?: string | null }
          | null
        >
        inline: Array<
          | { __typename?: 'AccessControl' }
          | { __typename?: 'AccessControl2' }
          | { __typename?: 'AccessControl3' }
          | { __typename?: 'Accordion' }
          | { __typename?: 'AccordionWithImage' }
          | { __typename?: 'Announcement' }
          | { __typename?: 'AnnouncementSet' }
          | {
              __typename: 'CaisiqArticle'
              slug?: string | null
              title?: string | null
              category?: string | null
              displayDate?: any | null
              description?: string | null
              introduction?: string | null
              byCais?: boolean | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
                publishedAt?: any | null
              }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqArticleFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
            }
          | { __typename?: 'CaisiqArticleDropdown' }
          | { __typename?: 'CaisiqAuthor' }
          | { __typename?: 'CaisiqDefaultCourseImageSet' }
          | { __typename?: 'CaisiqDefaultFundsThumbnailSet' }
          | { __typename?: 'CaisiqFundThumbnail' }
          | { __typename?: 'CaisiqFundThumbnailSet' }
          | { __typename?: 'CaisiqHeroHeader' }
          | { __typename?: 'CaisiqHeroHeaderNavigateToButton' }
          | { __typename?: 'CaisiqHomeOffice' }
          | { __typename?: 'CaisiqJoinCaisCallToAction' }
          | { __typename?: 'CaisiqTab' }
          | {
              __typename: 'CaisiqVideo'
              byCais?: boolean | null
              title?: string | null
              category?: string | null
              summary?: string | null
              duration?: number | null
              displayDate?: any | null
              muxVideo?: any | null
              poster?: any | null
              icon?: any | null
              muxThumbnailTime?: number | null
              description?: string | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqVideoFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              video?: { __typename?: 'Asset'; url?: string | null } | null
            }
          | { __typename?: 'CaisiqVideoPlaylist' }
          | { __typename?: 'CallToAction' }
          | {
              __typename: 'Chart'
              longSource?: string | null
              title?: string | null
              chartImage?: {
                __typename?: 'ComponentImage'
                altText?: string | null
                cloudinaryImage?: any | null
                name?: string | null
                image?: {
                  __typename?: 'Asset'
                  url?: string | null
                  height?: number | null
                  width?: number | null
                  title?: string | null
                  alt?: string | null
                } | null
              } | null
              footnote?: { __typename?: 'ChartFootnote'; json: any } | null
              longDescription?: {
                __typename?: 'ChartLongDescription'
                json: any
              } | null
            }
          | { __typename?: 'ChildFirm' }
          | { __typename?: 'CiqArticle' }
          | { __typename?: 'Company' }
          | { __typename?: 'CompanyAndContactInfo' }
          | { __typename?: 'ComponentCard' }
          | { __typename?: 'ComponentCompanyContainer' }
          | { __typename?: 'ComponentHero' }
          | { __typename?: 'ComponentHubspotForm' }
          | { __typename?: 'ComponentImage' }
          | { __typename?: 'ComponentPodcastPlayer' }
          | { __typename?: 'ComponentSection' }
          | { __typename?: 'ComponentSummary' }
          | { __typename?: 'ComponentTeamContainer' }
          | { __typename?: 'ComponentText' }
          | { __typename?: 'ComponentTextIllustration' }
          | { __typename?: 'ComponentTextImage' }
          | { __typename?: 'ComponentTextImageImageSection' }
          | { __typename?: 'ComponentTextImageListSection' }
          | { __typename?: 'ComponentTextImageTextSection' }
          | { __typename?: 'CorporateStats' }
          | { __typename?: 'CuratedFilterGroup' }
          | { __typename?: 'CuratedResearchContent' }
          | { __typename?: 'Disclaimer' }
          | { __typename?: 'Event' }
          | { __typename?: 'ExternalContent' }
          | { __typename?: 'Faq' }
          | { __typename?: 'FaqCategory' }
          | { __typename?: 'FeaturedContent' }
          | { __typename?: 'FilterGroup' }
          | { __typename?: 'Firm' }
          | { __typename?: 'FullWidthImage' }
          | { __typename?: 'FundProductId' }
          | { __typename?: 'GlossaryTerm' }
          | { __typename?: 'HomepageAppMetadata' }
          | { __typename?: 'HomepageContentSection' }
          | { __typename?: 'HomepageLandingPage' }
          | { __typename?: 'HtmlTable' }
          | { __typename?: 'ImageSection' }
          | { __typename?: 'LegalPage' }
          | { __typename?: 'Link' }
          | { __typename?: 'LoginScreen' }
          | { __typename?: 'Markdown' }
          | { __typename?: 'MemberFirm' }
          | { __typename?: 'Migration' }
          | { __typename?: 'NavHeaderTheme' }
          | { __typename?: 'Page' }
          | { __typename?: 'PageHelpCenterArticle' }
          | { __typename?: 'PageLanding' }
          | { __typename?: 'PagePwsCategory' }
          | { __typename?: 'PageResource' }
          | { __typename?: 'ParentFirm' }
          | {
              __typename: 'Pdf'
              title?: string | null
              displayDate?: any | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              image?: {
                __typename?: 'Asset'
                height?: number | null
                title?: string | null
                url?: string | null
                width?: number | null
                alt?: string | null
              } | null
              pdf?: {
                __typename?: 'Asset'
                height?: number | null
                title?: string | null
                url?: string | null
                width?: number | null
                fileName?: string | null
                alt?: string | null
              } | null
            }
          | { __typename?: 'Person' }
          | { __typename?: 'Pet' }
          | { __typename?: 'PlaylistContentSet' }
          | { __typename?: 'Presentation' }
          | { __typename?: 'PressAward' }
          | { __typename?: 'PwsContactMedia' }
          | { __typename?: 'PwsInsightsContentSet' }
          | { __typename?: 'PwsPressRelease' }
          | { __typename?: 'PwsSiteSettings' }
          | { __typename?: 'Quote' }
          | { __typename?: 'RelatedContent' }
          | { __typename?: 'RelatedContentStrategy' }
          | { __typename?: 'Seo' }
          | { __typename?: 'SkPlayground' }
          | { __typename?: 'Tag' }
          | { __typename?: 'TagFamily' }
          | { __typename?: 'Team' }
          | { __typename?: 'TestModel' }
          | { __typename?: 'Theme' }
          | {
              __typename: 'Whitepaper'
              byCais?: boolean | null
              type?: string | null
              slug?: string | null
              title?: string | null
              category?: string | null
              displayDate?: any | null
              introduction?: string | null
              description?: string | null
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'WhitepaperFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
                publishedAt?: any | null
              }
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              hero?: { __typename?: 'Asset'; url?: string | null } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              pdfsCollection?: {
                __typename?: 'WhitepaperPdfsCollection'
                items: Array<{
                  __typename?: 'Pdf'
                  title?: string | null
                  displayDate?: any | null
                  pdf?: { __typename?: 'Asset'; url?: string | null } | null
                  image?: { __typename?: 'Asset'; url?: string | null } | null
                } | null>
              } | null
            }
          | null
        >
      }
    }
  } | null
  body?: {
    __typename?: 'PageResourceBody'
    json: any
    links: {
      __typename?: 'PageResourceBodyLinks'
      assets: {
        __typename?: 'PageResourceBodyAssets'
        block: Array<{
          __typename?: 'Asset'
          height?: number | null
          title?: string | null
          url?: string | null
          width?: number | null
          alt?: string | null
          sys: { __typename?: 'Sys'; id: string }
        } | null>
        hyperlink: Array<{
          __typename?: 'Asset'
          height?: number | null
          title?: string | null
          url?: string | null
          width?: number | null
          alt?: string | null
          sys: { __typename?: 'Sys'; id: string }
        } | null>
      }
      entries: {
        __typename?: 'PageResourceBodyEntries'
        block: Array<
          | { __typename?: 'AccessControl' }
          | { __typename?: 'AccessControl2' }
          | { __typename?: 'AccessControl3' }
          | { __typename?: 'Accordion' }
          | { __typename?: 'AccordionWithImage' }
          | { __typename?: 'Announcement' }
          | { __typename?: 'AnnouncementSet' }
          | {
              __typename: 'CaisiqArticle'
              slug?: string | null
              title?: string | null
              category?: string | null
              displayDate?: any | null
              description?: string | null
              introduction?: string | null
              byCais?: boolean | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
                publishedAt?: any | null
              }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqArticleFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
            }
          | { __typename?: 'CaisiqArticleDropdown' }
          | { __typename?: 'CaisiqAuthor' }
          | { __typename?: 'CaisiqDefaultCourseImageSet' }
          | { __typename?: 'CaisiqDefaultFundsThumbnailSet' }
          | { __typename?: 'CaisiqFundThumbnail' }
          | { __typename?: 'CaisiqFundThumbnailSet' }
          | { __typename?: 'CaisiqHeroHeader' }
          | { __typename?: 'CaisiqHeroHeaderNavigateToButton' }
          | { __typename?: 'CaisiqHomeOffice' }
          | { __typename?: 'CaisiqJoinCaisCallToAction' }
          | { __typename?: 'CaisiqTab' }
          | {
              __typename: 'CaisiqVideo'
              byCais?: boolean | null
              title?: string | null
              category?: string | null
              summary?: string | null
              duration?: number | null
              displayDate?: any | null
              muxVideo?: any | null
              poster?: any | null
              icon?: any | null
              muxThumbnailTime?: number | null
              description?: string | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqVideoFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              video?: { __typename?: 'Asset'; url?: string | null } | null
            }
          | { __typename?: 'CaisiqVideoPlaylist' }
          | { __typename?: 'CallToAction' }
          | {
              __typename: 'Chart'
              longSource?: string | null
              title?: string | null
              chartImage?: {
                __typename?: 'ComponentImage'
                altText?: string | null
                cloudinaryImage?: any | null
                name?: string | null
                image?: {
                  __typename?: 'Asset'
                  url?: string | null
                  height?: number | null
                  width?: number | null
                  title?: string | null
                  alt?: string | null
                } | null
              } | null
              footnote?: { __typename?: 'ChartFootnote'; json: any } | null
              longDescription?: {
                __typename?: 'ChartLongDescription'
                json: any
              } | null
            }
          | { __typename?: 'ChildFirm' }
          | { __typename?: 'CiqArticle' }
          | { __typename?: 'Company' }
          | { __typename?: 'CompanyAndContactInfo' }
          | { __typename?: 'ComponentCard' }
          | { __typename?: 'ComponentCompanyContainer' }
          | { __typename?: 'ComponentHero' }
          | { __typename?: 'ComponentHubspotForm' }
          | { __typename?: 'ComponentImage' }
          | { __typename?: 'ComponentPodcastPlayer' }
          | { __typename?: 'ComponentSection' }
          | { __typename?: 'ComponentSummary' }
          | { __typename?: 'ComponentTeamContainer' }
          | { __typename?: 'ComponentText' }
          | { __typename?: 'ComponentTextIllustration' }
          | { __typename?: 'ComponentTextImage' }
          | { __typename?: 'ComponentTextImageImageSection' }
          | { __typename?: 'ComponentTextImageListSection' }
          | { __typename?: 'ComponentTextImageTextSection' }
          | { __typename?: 'CorporateStats' }
          | { __typename?: 'CuratedFilterGroup' }
          | { __typename?: 'CuratedResearchContent' }
          | { __typename?: 'Disclaimer' }
          | { __typename?: 'Event' }
          | { __typename?: 'ExternalContent' }
          | { __typename?: 'Faq' }
          | { __typename?: 'FaqCategory' }
          | { __typename?: 'FeaturedContent' }
          | { __typename?: 'FilterGroup' }
          | { __typename?: 'Firm' }
          | { __typename?: 'FullWidthImage' }
          | { __typename?: 'FundProductId' }
          | { __typename?: 'GlossaryTerm' }
          | { __typename?: 'HomepageAppMetadata' }
          | { __typename?: 'HomepageContentSection' }
          | { __typename?: 'HomepageLandingPage' }
          | { __typename?: 'HtmlTable' }
          | { __typename?: 'ImageSection' }
          | { __typename?: 'LegalPage' }
          | { __typename?: 'Link' }
          | { __typename?: 'LoginScreen' }
          | { __typename?: 'Markdown' }
          | { __typename?: 'MemberFirm' }
          | { __typename?: 'Migration' }
          | { __typename?: 'NavHeaderTheme' }
          | { __typename?: 'Page' }
          | { __typename?: 'PageHelpCenterArticle' }
          | { __typename?: 'PageLanding' }
          | { __typename?: 'PagePwsCategory' }
          | { __typename?: 'PageResource' }
          | { __typename?: 'ParentFirm' }
          | {
              __typename: 'Pdf'
              title?: string | null
              displayDate?: any | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              image?: {
                __typename?: 'Asset'
                height?: number | null
                title?: string | null
                url?: string | null
                width?: number | null
                alt?: string | null
              } | null
              pdf?: {
                __typename?: 'Asset'
                height?: number | null
                title?: string | null
                url?: string | null
                width?: number | null
                fileName?: string | null
                alt?: string | null
              } | null
            }
          | { __typename?: 'Person' }
          | { __typename?: 'Pet' }
          | { __typename?: 'PlaylistContentSet' }
          | { __typename?: 'Presentation' }
          | { __typename?: 'PressAward' }
          | { __typename?: 'PwsContactMedia' }
          | { __typename?: 'PwsInsightsContentSet' }
          | { __typename?: 'PwsPressRelease' }
          | { __typename?: 'PwsSiteSettings' }
          | { __typename?: 'Quote' }
          | { __typename?: 'RelatedContent' }
          | { __typename?: 'RelatedContentStrategy' }
          | { __typename?: 'Seo' }
          | { __typename?: 'SkPlayground' }
          | { __typename?: 'Tag' }
          | { __typename?: 'TagFamily' }
          | { __typename?: 'Team' }
          | { __typename?: 'TestModel' }
          | { __typename?: 'Theme' }
          | {
              __typename: 'Whitepaper'
              byCais?: boolean | null
              type?: string | null
              slug?: string | null
              title?: string | null
              category?: string | null
              displayDate?: any | null
              introduction?: string | null
              description?: string | null
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'WhitepaperFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
                publishedAt?: any | null
              }
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              hero?: { __typename?: 'Asset'; url?: string | null } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              pdfsCollection?: {
                __typename?: 'WhitepaperPdfsCollection'
                items: Array<{
                  __typename?: 'Pdf'
                  title?: string | null
                  displayDate?: any | null
                  pdf?: { __typename?: 'Asset'; url?: string | null } | null
                  image?: { __typename?: 'Asset'; url?: string | null } | null
                } | null>
              } | null
            }
          | null
        >
        hyperlink: Array<
          | { __typename?: 'AccessControl' }
          | { __typename?: 'AccessControl2' }
          | { __typename?: 'AccessControl3' }
          | { __typename?: 'Accordion' }
          | { __typename?: 'AccordionWithImage' }
          | { __typename?: 'Announcement' }
          | { __typename?: 'AnnouncementSet' }
          | { __typename?: 'CaisiqArticle'; slug?: string | null }
          | { __typename?: 'CaisiqArticleDropdown' }
          | { __typename?: 'CaisiqAuthor' }
          | { __typename?: 'CaisiqDefaultCourseImageSet' }
          | { __typename?: 'CaisiqDefaultFundsThumbnailSet' }
          | { __typename?: 'CaisiqFundThumbnail' }
          | { __typename?: 'CaisiqFundThumbnailSet' }
          | { __typename?: 'CaisiqHeroHeader' }
          | { __typename?: 'CaisiqHeroHeaderNavigateToButton' }
          | { __typename?: 'CaisiqHomeOffice' }
          | { __typename?: 'CaisiqJoinCaisCallToAction' }
          | { __typename?: 'CaisiqTab' }
          | { __typename?: 'CaisiqVideo' }
          | { __typename?: 'CaisiqVideoPlaylist' }
          | { __typename?: 'CallToAction' }
          | { __typename?: 'Chart' }
          | { __typename?: 'ChildFirm' }
          | { __typename?: 'CiqArticle' }
          | { __typename?: 'Company' }
          | { __typename?: 'CompanyAndContactInfo' }
          | { __typename?: 'ComponentCard' }
          | { __typename?: 'ComponentCompanyContainer' }
          | { __typename?: 'ComponentHero' }
          | { __typename?: 'ComponentHubspotForm' }
          | { __typename?: 'ComponentImage' }
          | { __typename?: 'ComponentPodcastPlayer' }
          | { __typename?: 'ComponentSection' }
          | { __typename?: 'ComponentSummary' }
          | { __typename?: 'ComponentTeamContainer' }
          | { __typename?: 'ComponentText' }
          | { __typename?: 'ComponentTextIllustration' }
          | { __typename?: 'ComponentTextImage' }
          | { __typename?: 'ComponentTextImageImageSection' }
          | { __typename?: 'ComponentTextImageListSection' }
          | { __typename?: 'ComponentTextImageTextSection' }
          | { __typename?: 'CorporateStats' }
          | { __typename?: 'CuratedFilterGroup' }
          | { __typename?: 'CuratedResearchContent' }
          | { __typename?: 'Disclaimer' }
          | { __typename?: 'Event' }
          | { __typename?: 'ExternalContent' }
          | { __typename?: 'Faq' }
          | { __typename?: 'FaqCategory' }
          | { __typename?: 'FeaturedContent' }
          | { __typename?: 'FilterGroup' }
          | { __typename?: 'Firm' }
          | { __typename?: 'FullWidthImage' }
          | { __typename?: 'FundProductId' }
          | { __typename?: 'GlossaryTerm' }
          | { __typename?: 'HomepageAppMetadata' }
          | { __typename?: 'HomepageContentSection' }
          | { __typename?: 'HomepageLandingPage' }
          | { __typename?: 'HtmlTable' }
          | { __typename?: 'ImageSection' }
          | { __typename?: 'LegalPage' }
          | { __typename?: 'Link' }
          | { __typename?: 'LoginScreen' }
          | { __typename?: 'Markdown' }
          | { __typename?: 'MemberFirm' }
          | { __typename?: 'Migration' }
          | { __typename?: 'NavHeaderTheme' }
          | { __typename?: 'Page' }
          | { __typename?: 'PageHelpCenterArticle' }
          | { __typename?: 'PageLanding' }
          | { __typename?: 'PagePwsCategory' }
          | { __typename?: 'PageResource' }
          | { __typename?: 'ParentFirm' }
          | {
              __typename: 'Pdf'
              title?: string | null
              displayDate?: any | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              image?: {
                __typename?: 'Asset'
                height?: number | null
                title?: string | null
                url?: string | null
                width?: number | null
                alt?: string | null
              } | null
              pdf?: {
                __typename?: 'Asset'
                height?: number | null
                title?: string | null
                url?: string | null
                width?: number | null
                fileName?: string | null
                alt?: string | null
              } | null
            }
          | { __typename?: 'Person' }
          | { __typename?: 'Pet' }
          | { __typename?: 'PlaylistContentSet' }
          | { __typename?: 'Presentation' }
          | { __typename?: 'PressAward' }
          | { __typename?: 'PwsContactMedia' }
          | { __typename?: 'PwsInsightsContentSet' }
          | { __typename?: 'PwsPressRelease'; slug?: string | null }
          | { __typename?: 'PwsSiteSettings' }
          | { __typename?: 'Quote' }
          | { __typename?: 'RelatedContent' }
          | { __typename?: 'RelatedContentStrategy' }
          | { __typename?: 'Seo' }
          | { __typename?: 'SkPlayground' }
          | { __typename?: 'Tag' }
          | { __typename?: 'TagFamily' }
          | { __typename?: 'Team' }
          | { __typename?: 'TestModel' }
          | { __typename?: 'Theme' }
          | { __typename?: 'Whitepaper'; slug?: string | null }
          | null
        >
        inline: Array<
          | { __typename?: 'AccessControl' }
          | { __typename?: 'AccessControl2' }
          | { __typename?: 'AccessControl3' }
          | { __typename?: 'Accordion' }
          | { __typename?: 'AccordionWithImage' }
          | { __typename?: 'Announcement' }
          | { __typename?: 'AnnouncementSet' }
          | {
              __typename: 'CaisiqArticle'
              slug?: string | null
              title?: string | null
              category?: string | null
              displayDate?: any | null
              description?: string | null
              introduction?: string | null
              byCais?: boolean | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
                publishedAt?: any | null
              }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqArticleFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
            }
          | { __typename?: 'CaisiqArticleDropdown' }
          | { __typename?: 'CaisiqAuthor' }
          | { __typename?: 'CaisiqDefaultCourseImageSet' }
          | { __typename?: 'CaisiqDefaultFundsThumbnailSet' }
          | { __typename?: 'CaisiqFundThumbnail' }
          | { __typename?: 'CaisiqFundThumbnailSet' }
          | { __typename?: 'CaisiqHeroHeader' }
          | { __typename?: 'CaisiqHeroHeaderNavigateToButton' }
          | { __typename?: 'CaisiqHomeOffice' }
          | { __typename?: 'CaisiqJoinCaisCallToAction' }
          | { __typename?: 'CaisiqTab' }
          | {
              __typename: 'CaisiqVideo'
              byCais?: boolean | null
              title?: string | null
              category?: string | null
              summary?: string | null
              duration?: number | null
              displayDate?: any | null
              muxVideo?: any | null
              poster?: any | null
              icon?: any | null
              muxThumbnailTime?: number | null
              description?: string | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqVideoFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              video?: { __typename?: 'Asset'; url?: string | null } | null
            }
          | { __typename?: 'CaisiqVideoPlaylist' }
          | { __typename?: 'CallToAction' }
          | {
              __typename: 'Chart'
              longSource?: string | null
              title?: string | null
              chartImage?: {
                __typename?: 'ComponentImage'
                altText?: string | null
                cloudinaryImage?: any | null
                name?: string | null
                image?: {
                  __typename?: 'Asset'
                  url?: string | null
                  height?: number | null
                  width?: number | null
                  title?: string | null
                  alt?: string | null
                } | null
              } | null
              footnote?: { __typename?: 'ChartFootnote'; json: any } | null
              longDescription?: {
                __typename?: 'ChartLongDescription'
                json: any
              } | null
            }
          | { __typename?: 'ChildFirm' }
          | { __typename?: 'CiqArticle' }
          | { __typename?: 'Company' }
          | { __typename?: 'CompanyAndContactInfo' }
          | { __typename?: 'ComponentCard' }
          | { __typename?: 'ComponentCompanyContainer' }
          | { __typename?: 'ComponentHero' }
          | { __typename?: 'ComponentHubspotForm' }
          | { __typename?: 'ComponentImage' }
          | { __typename?: 'ComponentPodcastPlayer' }
          | { __typename?: 'ComponentSection' }
          | { __typename?: 'ComponentSummary' }
          | { __typename?: 'ComponentTeamContainer' }
          | { __typename?: 'ComponentText' }
          | { __typename?: 'ComponentTextIllustration' }
          | { __typename?: 'ComponentTextImage' }
          | { __typename?: 'ComponentTextImageImageSection' }
          | { __typename?: 'ComponentTextImageListSection' }
          | { __typename?: 'ComponentTextImageTextSection' }
          | { __typename?: 'CorporateStats' }
          | { __typename?: 'CuratedFilterGroup' }
          | { __typename?: 'CuratedResearchContent' }
          | { __typename?: 'Disclaimer' }
          | { __typename?: 'Event' }
          | { __typename?: 'ExternalContent' }
          | { __typename?: 'Faq' }
          | { __typename?: 'FaqCategory' }
          | { __typename?: 'FeaturedContent' }
          | { __typename?: 'FilterGroup' }
          | { __typename?: 'Firm' }
          | { __typename?: 'FullWidthImage' }
          | { __typename?: 'FundProductId' }
          | { __typename?: 'GlossaryTerm' }
          | { __typename?: 'HomepageAppMetadata' }
          | { __typename?: 'HomepageContentSection' }
          | { __typename?: 'HomepageLandingPage' }
          | { __typename?: 'HtmlTable' }
          | { __typename?: 'ImageSection' }
          | { __typename?: 'LegalPage' }
          | { __typename?: 'Link' }
          | { __typename?: 'LoginScreen' }
          | { __typename?: 'Markdown' }
          | { __typename?: 'MemberFirm' }
          | { __typename?: 'Migration' }
          | { __typename?: 'NavHeaderTheme' }
          | { __typename?: 'Page' }
          | { __typename?: 'PageHelpCenterArticle' }
          | { __typename?: 'PageLanding' }
          | { __typename?: 'PagePwsCategory' }
          | { __typename?: 'PageResource' }
          | { __typename?: 'ParentFirm' }
          | {
              __typename: 'Pdf'
              title?: string | null
              displayDate?: any | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              image?: {
                __typename?: 'Asset'
                height?: number | null
                title?: string | null
                url?: string | null
                width?: number | null
                alt?: string | null
              } | null
              pdf?: {
                __typename?: 'Asset'
                height?: number | null
                title?: string | null
                url?: string | null
                width?: number | null
                fileName?: string | null
                alt?: string | null
              } | null
            }
          | { __typename?: 'Person' }
          | { __typename?: 'Pet' }
          | { __typename?: 'PlaylistContentSet' }
          | { __typename?: 'Presentation' }
          | { __typename?: 'PressAward' }
          | { __typename?: 'PwsContactMedia' }
          | { __typename?: 'PwsInsightsContentSet' }
          | { __typename?: 'PwsPressRelease' }
          | { __typename?: 'PwsSiteSettings' }
          | { __typename?: 'Quote' }
          | { __typename?: 'RelatedContent' }
          | { __typename?: 'RelatedContentStrategy' }
          | { __typename?: 'Seo' }
          | { __typename?: 'SkPlayground' }
          | { __typename?: 'Tag' }
          | { __typename?: 'TagFamily' }
          | { __typename?: 'Team' }
          | { __typename?: 'TestModel' }
          | { __typename?: 'Theme' }
          | {
              __typename: 'Whitepaper'
              byCais?: boolean | null
              type?: string | null
              slug?: string | null
              title?: string | null
              category?: string | null
              displayDate?: any | null
              introduction?: string | null
              description?: string | null
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'WhitepaperFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
                publishedAt?: any | null
              }
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              hero?: { __typename?: 'Asset'; url?: string | null } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              pdfsCollection?: {
                __typename?: 'WhitepaperPdfsCollection'
                items: Array<{
                  __typename?: 'Pdf'
                  title?: string | null
                  displayDate?: any | null
                  pdf?: { __typename?: 'Asset'; url?: string | null } | null
                  image?: { __typename?: 'Asset'; url?: string | null } | null
                } | null>
              } | null
            }
          | null
        >
      }
    }
  } | null
  author?: {
    __typename?: 'CaisiqAuthor'
    name?: string | null
    authorName?: string | null
    jobTitle?: string | null
    firm?: string | null
    avatar?: {
      __typename?: 'ComponentImage'
      altText?: string | null
      image?: {
        __typename?: 'Asset'
        url?: string | null
        title?: string | null
      } | null
    } | null
    linkedin?: { __typename?: 'Link'; externalLink?: string | null } | null
  } | null
}

export type PdfFragment = {
  __typename: 'Pdf'
  title?: string | null
  displayDate?: any | null
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
  image?: {
    __typename?: 'Asset'
    height?: number | null
    title?: string | null
    url?: string | null
    width?: number | null
    alt?: string | null
  } | null
  pdf?: {
    __typename?: 'Asset'
    height?: number | null
    title?: string | null
    url?: string | null
    width?: number | null
    fileName?: string | null
    alt?: string | null
  } | null
}

export type ChartFragment = {
  __typename: 'Chart'
  longSource?: string | null
  title?: string | null
  chartImage?: {
    __typename?: 'ComponentImage'
    altText?: string | null
    cloudinaryImage?: any | null
    name?: string | null
    image?: {
      __typename?: 'Asset'
      url?: string | null
      height?: number | null
      width?: number | null
      title?: string | null
      alt?: string | null
    } | null
  } | null
  footnote?: { __typename?: 'ChartFootnote'; json: any } | null
  longDescription?: { __typename?: 'ChartLongDescription'; json: any } | null
}

export type GetTutorialsPageContentQueryVariables = Exact<{
  featuredId: Scalars['String']
  preview?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  articleWhere?: InputMaybe<CaisiqArticleFilter>
  videoWhere?: InputMaybe<CaisiqVideoFilter>
  videoPlaylistWhere?: InputMaybe<CaisiqVideoPlaylistFilter>
  whitepaperWhere?: InputMaybe<WhitepaperFilter>
  includeFeatured?: InputMaybe<Scalars['Boolean']>
  firmsLimit: Scalars['Int']
}>

export type GetTutorialsPageContentQuery = {
  __typename?: 'Query'
  featured?: {
    __typename?: 'FeaturedContentCollection'
    items?: Array<{
      __typename?: 'FeaturedContent'
      fallback?: {
        __typename?: 'FeaturedContentTutorialsDefaultCollection'
        items: Array<
          | {
              __typename: 'CaisiqArticle'
              slug?: string | null
              title?: string | null
              category?: string | null
              displayDate?: any | null
              description?: string | null
              introduction?: string | null
              byCais?: boolean | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
                publishedAt?: any | null
              }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqArticleFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
            }
          | {
              __typename: 'CaisiqVideo'
              byCais?: boolean | null
              title?: string | null
              category?: string | null
              summary?: string | null
              duration?: number | null
              displayDate?: any | null
              muxVideo?: any | null
              poster?: any | null
              icon?: any | null
              muxThumbnailTime?: number | null
              description?: string | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqVideoFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              video?: { __typename?: 'Asset'; url?: string | null } | null
            }
          | {
              __typename: 'CaisiqVideoPlaylist'
              episodic?: boolean | null
              title?: string | null
              category?: string | null
              summary?: string | null
              displayDate?: any | null
              byCais?: boolean | null
              description?: string | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              heroImage?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqVideoPlaylistFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              videosCollection?: {
                __typename?: 'CaisiqVideoPlaylistVideosCollection'
                items: Array<{
                  __typename?: 'CaisiqVideo'
                  poster?: any | null
                  sys: { __typename?: 'Sys'; id: string }
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                    width?: number | null
                    height?: number | null
                  } | null
                } | null>
              } | null
            }
          | {
              __typename: 'Link'
              openInNewWindow?: boolean | null
              description?: string | null
              internalLink?: string | null
              byCais?: boolean | null
              title?: string | null
              poster?: any | null
              sys: { __typename?: 'Sys'; id: string }
              cmsLink?:
                | { __typename?: 'CaisiqArticle' }
                | { __typename: 'HomepageLandingPage'; slug?: string | null }
                | { __typename?: 'Page' }
                | { __typename: 'PageResource'; slug?: string | null }
                | { __typename?: 'PwsPressRelease' }
                | { __typename?: 'Whitepaper' }
                | null
            }
          | {
              __typename: 'Whitepaper'
              byCais?: boolean | null
              type?: string | null
              slug?: string | null
              title?: string | null
              category?: string | null
              displayDate?: any | null
              introduction?: string | null
              description?: string | null
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'WhitepaperFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
                publishedAt?: any | null
              }
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              hero?: { __typename?: 'Asset'; url?: string | null } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              pdfsCollection?: {
                __typename?: 'WhitepaperPdfsCollection'
                items: Array<{
                  __typename?: 'Pdf'
                  title?: string | null
                  displayDate?: any | null
                  pdf?: { __typename?: 'Asset'; url?: string | null } | null
                  image?: { __typename?: 'Asset'; url?: string | null } | null
                } | null>
              } | null
            }
          | null
        >
      } | null
      options?: {
        __typename?: 'FeaturedContentTutorialsOptionsCollection'
        items?: Array<
          | {
              __typename: 'CaisiqArticle'
              slug?: string | null
              title?: string | null
              category?: string | null
              displayDate?: any | null
              description?: string | null
              introduction?: string | null
              byCais?: boolean | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
                publishedAt?: any | null
              }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqArticleFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
            }
          | {
              __typename: 'CaisiqVideo'
              byCais?: boolean | null
              title?: string | null
              category?: string | null
              summary?: string | null
              duration?: number | null
              displayDate?: any | null
              muxVideo?: any | null
              poster?: any | null
              icon?: any | null
              muxThumbnailTime?: number | null
              description?: string | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqVideoFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              video?: { __typename?: 'Asset'; url?: string | null } | null
            }
          | {
              __typename: 'CaisiqVideoPlaylist'
              episodic?: boolean | null
              title?: string | null
              category?: string | null
              summary?: string | null
              displayDate?: any | null
              byCais?: boolean | null
              description?: string | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              heroImage?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'CaisiqVideoPlaylistFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              videosCollection?: {
                __typename?: 'CaisiqVideoPlaylistVideosCollection'
                items: Array<{
                  __typename?: 'CaisiqVideo'
                  poster?: any | null
                  sys: { __typename?: 'Sys'; id: string }
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                    width?: number | null
                    height?: number | null
                  } | null
                } | null>
              } | null
            }
          | {
              __typename: 'Link'
              openInNewWindow?: boolean | null
              description?: string | null
              internalLink?: string | null
              byCais?: boolean | null
              title?: string | null
              poster?: any | null
              sys: { __typename?: 'Sys'; id: string }
              cmsLink?:
                | { __typename?: 'CaisiqArticle' }
                | { __typename: 'HomepageLandingPage'; slug?: string | null }
                | { __typename?: 'Page' }
                | { __typename: 'PageResource'; slug?: string | null }
                | { __typename?: 'PwsPressRelease' }
                | { __typename?: 'Whitepaper' }
                | null
            }
          | {
              __typename: 'Whitepaper'
              byCais?: boolean | null
              type?: string | null
              slug?: string | null
              title?: string | null
              category?: string | null
              displayDate?: any | null
              introduction?: string | null
              description?: string | null
              fundProductIds?: {
                __typename?: 'FundProductId'
                fundProductIds?: Array<string | null> | null
              } | null
              firmsCollection?: {
                __typename?: 'WhitepaperFirmsCollection'
                items: Array<{
                  __typename?: 'MemberFirm'
                  id?: string | null
                  name?: string | null
                } | null>
              } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
                publishedAt?: any | null
              }
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              hero?: { __typename?: 'Asset'; url?: string | null } | null
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              pdfsCollection?: {
                __typename?: 'WhitepaperPdfsCollection'
                items: Array<{
                  __typename?: 'Pdf'
                  title?: string | null
                  displayDate?: any | null
                  pdf?: { __typename?: 'Asset'; url?: string | null } | null
                  image?: { __typename?: 'Asset'; url?: string | null } | null
                } | null>
              } | null
            }
          | null
        >
      } | null
    } | null>
  } | null
  caisiqArticleCollection?: {
    __typename?: 'CaisiqArticleCollection'
    total: number
    items: Array<{
      __typename: 'CaisiqArticle'
      slug?: string | null
      title?: string | null
      category?: string | null
      displayDate?: any | null
      description?: string | null
      introduction?: string | null
      byCais?: boolean | null
      sys: {
        __typename?: 'Sys'
        id: string
        firstPublishedAt?: any | null
        publishedAt?: any | null
      }
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      author?: { __typename?: 'CaisiqAuthor'; name?: string | null } | null
      firmsCollection?: {
        __typename?: 'CaisiqArticleFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
    } | null>
  } | null
  caisiqVideoPlaylistCollection?: {
    __typename?: 'CaisiqVideoPlaylistCollection'
    total: number
    items: Array<{
      __typename: 'CaisiqVideoPlaylist'
      episodic?: boolean | null
      title?: string | null
      category?: string | null
      summary?: string | null
      displayDate?: any | null
      byCais?: boolean | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      heroImage?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoPlaylistFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      videosCollection?: {
        __typename?: 'CaisiqVideoPlaylistVideosCollection'
        items: Array<{
          __typename?: 'CaisiqVideo'
          poster?: any | null
          sys: { __typename?: 'Sys'; id: string }
          thumbnail?: {
            __typename?: 'Asset'
            url?: string | null
            width?: number | null
            height?: number | null
          } | null
        } | null>
      } | null
    } | null>
  } | null
  caisiqVideoCollection?: {
    __typename?: 'CaisiqVideoCollection'
    total: number
    items: Array<{
      __typename: 'CaisiqVideo'
      byCais?: boolean | null
      title?: string | null
      category?: string | null
      summary?: string | null
      duration?: number | null
      displayDate?: any | null
      muxVideo?: any | null
      poster?: any | null
      icon?: any | null
      muxThumbnailTime?: number | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      video?: { __typename?: 'Asset'; url?: string | null } | null
    } | null>
  } | null
  whitepaperCollection?: {
    __typename?: 'WhitepaperCollection'
    total: number
    items: Array<{
      __typename: 'Whitepaper'
      byCais?: boolean | null
      type?: string | null
      slug?: string | null
      title?: string | null
      category?: string | null
      displayDate?: any | null
      introduction?: string | null
      description?: string | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'WhitepaperFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      sys: {
        __typename?: 'Sys'
        id: string
        firstPublishedAt?: any | null
        publishedAt?: any | null
      }
      author?: { __typename?: 'CaisiqAuthor'; name?: string | null } | null
      hero?: { __typename?: 'Asset'; url?: string | null } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      pdfsCollection?: {
        __typename?: 'WhitepaperPdfsCollection'
        items: Array<{
          __typename?: 'Pdf'
          title?: string | null
          displayDate?: any | null
          pdf?: { __typename?: 'Asset'; url?: string | null } | null
          image?: { __typename?: 'Asset'; url?: string | null } | null
        } | null>
      } | null
    } | null>
  } | null
}

export type GetVideoQueryVariables = Exact<{
  id: Scalars['String']
  preview?: InputMaybe<Scalars['Boolean']>
  firmsLimit: Scalars['Int']
}>

export type GetVideoQuery = {
  __typename?: 'Query'
  caisiqVideoCollection?: {
    __typename?: 'CaisiqVideoCollection'
    total: number
    items: Array<{
      __typename: 'CaisiqVideo'
      byCais?: boolean | null
      title?: string | null
      category?: string | null
      summary?: string | null
      duration?: number | null
      displayDate?: any | null
      muxVideo?: any | null
      poster?: any | null
      icon?: any | null
      muxThumbnailTime?: number | null
      description?: string | null
      body?: {
        __typename?: 'CaisiqVideoBody'
        json: any
        links: {
          __typename?: 'CaisiqVideoBodyLinks'
          entries: {
            __typename?: 'CaisiqVideoBodyEntries'
            hyperlink: Array<
              | {
                  __typename: 'AccessControl'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AccessControl2'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AccessControl3'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Accordion'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AccordionWithImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Announcement'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AnnouncementSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqArticle'
                  slug?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqArticleDropdown'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqAuthor'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqDefaultCourseImageSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqDefaultFundsThumbnailSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqFundThumbnail'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqFundThumbnailSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqHeroHeader'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqHeroHeaderNavigateToButton'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqHomeOffice'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqJoinCaisCallToAction'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqTab'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqVideo'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqVideoPlaylist'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CallToAction'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Chart'
                  longSource?: string | null
                  title?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                  chartImage?: {
                    __typename?: 'ComponentImage'
                    altText?: string | null
                    cloudinaryImage?: any | null
                    name?: string | null
                    image?: {
                      __typename?: 'Asset'
                      url?: string | null
                      height?: number | null
                      width?: number | null
                      title?: string | null
                      alt?: string | null
                    } | null
                  } | null
                  footnote?: { __typename?: 'ChartFootnote'; json: any } | null
                  longDescription?: {
                    __typename?: 'ChartLongDescription'
                    json: any
                  } | null
                }
              | {
                  __typename: 'ChildFirm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CiqArticle'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Company'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CompanyAndContactInfo'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentCard'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentCompanyContainer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentHero'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentHubspotForm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentImage'
                  cloudinaryImage?: any | null
                  name?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentPodcastPlayer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentSummary'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTeamContainer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentText'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextIllustration'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImageImageSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImageListSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImageTextSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CorporateStats'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CuratedFilterGroup'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CuratedResearchContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Disclaimer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Event'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'ExternalContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Faq'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'FaqCategory'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'FeaturedContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'FilterGroup'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Firm'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'FullWidthImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'FundProductId'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'GlossaryTerm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HomepageAppMetadata'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HomepageContentSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HomepageLandingPage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HtmlTable'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ImageSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'LegalPage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Link'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'LoginScreen'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Markdown'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'MemberFirm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Migration'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'NavHeaderTheme'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'PageHelpCenterArticle'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PageLanding'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PagePwsCategory'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PageResource'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ParentFirm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Pdf'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'Person'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Pet'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'PlaylistContentSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Presentation'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PressAward'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsContactMedia'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsInsightsContentSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsPressRelease'
                  slug?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsSiteSettings'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Quote'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'RelatedContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'RelatedContentStrategy'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Seo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'SkPlayground'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Tag'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'TagFamily'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Team'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'TestModel'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Theme'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'Whitepaper'
                  slug?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                }
              | null
            >
            block: Array<
              | {
                  __typename: 'AccessControl'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AccessControl2'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AccessControl3'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Accordion'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AccordionWithImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Announcement'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AnnouncementSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqArticle'
                  title?: string | null
                  displayDate?: any | null
                  author?: {
                    __typename?: 'CaisiqAuthor'
                    name?: string | null
                  } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                  } | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqArticleDropdown'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqAuthor'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqDefaultCourseImageSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqDefaultFundsThumbnailSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqFundThumbnail'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqFundThumbnailSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqHeroHeader'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqHeroHeaderNavigateToButton'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqHomeOffice'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqJoinCaisCallToAction'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqTab'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqVideo'
                  title?: string | null
                  displayDate?: any | null
                  summary?: string | null
                  duration?: number | null
                  poster?: any | null
                  video?: { __typename?: 'Asset'; url?: string | null } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                  } | null
                  linkedFrom?: {
                    __typename?: 'CaisiqVideoLinkingCollections'
                    caisiqVideoPlaylistCollection?: {
                      __typename?: 'CaisiqVideoPlaylistCollection'
                      items: Array<{
                        __typename?: 'CaisiqVideoPlaylist'
                        episodic?: boolean | null
                        title?: string | null
                        summary?: string | null
                        displayDate?: any | null
                        category?: string | null
                        sys: {
                          __typename?: 'Sys'
                          id: string
                          firstPublishedAt?: any | null
                        }
                        heroImage?: {
                          __typename?: 'Asset'
                          url?: string | null
                        } | null
                        videosCollection?: {
                          __typename?: 'CaisiqVideoPlaylistVideosCollection'
                          items: Array<{
                            __typename?: 'CaisiqVideo'
                            title?: string | null
                            summary?: string | null
                            duration?: number | null
                            poster?: any | null
                            displayDate?: any | null
                            muxVideo?: any | null
                            sys: {
                              __typename?: 'Sys'
                              id: string
                              firstPublishedAt?: any | null
                            }
                            thumbnail?: {
                              __typename?: 'Asset'
                              url?: string | null
                            } | null
                            video?: {
                              __typename?: 'Asset'
                              url?: string | null
                            } | null
                          } | null>
                        } | null
                      } | null>
                    } | null
                  } | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqVideoPlaylist'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CallToAction'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Chart'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ChildFirm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CiqArticle'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Company'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CompanyAndContactInfo'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentCard'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentCompanyContainer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentHero'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentHubspotForm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentPodcastPlayer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentSummary'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTeamContainer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentText'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextIllustration'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImageImageSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImageListSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImageTextSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CorporateStats'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CuratedFilterGroup'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CuratedResearchContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Disclaimer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Event'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ExternalContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Faq'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FaqCategory'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FeaturedContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FilterGroup'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Firm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FullWidthImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FundProductId'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'GlossaryTerm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HomepageAppMetadata'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HomepageContentSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HomepageLandingPage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HtmlTable'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ImageSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'LegalPage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Link'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'LoginScreen'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Markdown'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'MemberFirm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Migration'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'NavHeaderTheme'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Page'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PageHelpCenterArticle'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PageLanding'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PagePwsCategory'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PageResource'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ParentFirm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Pdf'
                  displayDate?: any | null
                  title?: string | null
                  pdf?: { __typename?: 'Asset'; url?: string | null } | null
                  image?: { __typename?: 'Asset'; url?: string | null } | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Person'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Pet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PlaylistContentSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Presentation'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PressAward'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsContactMedia'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsInsightsContentSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsPressRelease'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsSiteSettings'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Quote'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'RelatedContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'RelatedContentStrategy'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Seo'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'SkPlayground'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Tag'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'TagFamily'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Team'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'TestModel'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Theme'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Whitepaper'
                  title?: string | null
                  displayDate?: any | null
                  author?: {
                    __typename?: 'CaisiqAuthor'
                    name?: string | null
                  } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                  } | null
                  pdfsCollection?: {
                    __typename?: 'WhitepaperPdfsCollection'
                    items: Array<{
                      __typename?: 'Pdf'
                      title?: string | null
                      displayDate?: any | null
                      sys: { __typename?: 'Sys'; id: string }
                      pdf?: { __typename?: 'Asset'; url?: string | null } | null
                      image?: {
                        __typename?: 'Asset'
                        url?: string | null
                      } | null
                    } | null>
                  } | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | null
            >
          }
          assets: {
            __typename?: 'CaisiqVideoBodyAssets'
            block: Array<{
              __typename?: 'Asset'
              url?: string | null
              title?: string | null
              width?: number | null
              height?: number | null
              description?: string | null
              sys: { __typename?: 'Sys'; id: string }
            } | null>
          }
        }
      } | null
      linkedFrom?: {
        __typename?: 'CaisiqVideoLinkingCollections'
        caisiqVideoPlaylistCollection?: {
          __typename?: 'CaisiqVideoPlaylistCollection'
          items: Array<{
            __typename?: 'CaisiqVideoPlaylist'
            episodic?: boolean | null
            title?: string | null
            summary?: string | null
            displayDate?: any | null
            category?: string | null
            sys: {
              __typename?: 'Sys'
              id: string
              firstPublishedAt?: any | null
            }
            heroImage?: { __typename?: 'Asset'; url?: string | null } | null
            videosCollection?: {
              __typename?: 'CaisiqVideoPlaylistVideosCollection'
              items: Array<{
                __typename: 'CaisiqVideo'
                byCais?: boolean | null
                title?: string | null
                category?: string | null
                summary?: string | null
                duration?: number | null
                displayDate?: any | null
                muxVideo?: any | null
                poster?: any | null
                icon?: any | null
                muxThumbnailTime?: number | null
                description?: string | null
                sys: {
                  __typename?: 'Sys'
                  id: string
                  firstPublishedAt?: any | null
                }
                fundProductIds?: {
                  __typename?: 'FundProductId'
                  fundProductIds?: Array<string | null> | null
                } | null
                firmsCollection?: {
                  __typename?: 'CaisiqVideoFirmsCollection'
                  items: Array<{
                    __typename?: 'MemberFirm'
                    id?: string | null
                    name?: string | null
                  } | null>
                } | null
                thumbnail?: {
                  __typename?: 'Asset'
                  url?: string | null
                  width?: number | null
                  height?: number | null
                } | null
                video?: { __typename?: 'Asset'; url?: string | null } | null
              } | null>
            } | null
          } | null>
        } | null
      } | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      video?: { __typename?: 'Asset'; url?: string | null } | null
    } | null>
  } | null
}

export type GetVideoPermissionsQueryVariables = Exact<{
  id: Scalars['String']
  firmsLimit: Scalars['Int']
}>

export type GetVideoPermissionsQuery = {
  __typename?: 'Query'
  caisiqVideoCollection?: {
    __typename?: 'CaisiqVideoCollection'
    total: number
    items: Array<{
      __typename?: 'CaisiqVideo'
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoFirmsCollection'
        items: Array<{ __typename?: 'MemberFirm'; id?: string | null } | null>
      } | null
    } | null>
  } | null
}

export type VideoPageFragment = {
  __typename: 'CaisiqVideo'
  byCais?: boolean | null
  title?: string | null
  category?: string | null
  summary?: string | null
  duration?: number | null
  displayDate?: any | null
  muxVideo?: any | null
  poster?: any | null
  icon?: any | null
  muxThumbnailTime?: number | null
  description?: string | null
  body?: {
    __typename?: 'CaisiqVideoBody'
    json: any
    links: {
      __typename?: 'CaisiqVideoBodyLinks'
      entries: {
        __typename?: 'CaisiqVideoBodyEntries'
        hyperlink: Array<
          | {
              __typename: 'AccessControl'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'AccessControl2'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'AccessControl3'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Accordion'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'AccordionWithImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Announcement'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'AnnouncementSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqArticle'
              slug?: string | null
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqArticleDropdown'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqAuthor'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqDefaultCourseImageSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqDefaultFundsThumbnailSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqFundThumbnail'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqFundThumbnailSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqHeroHeader'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqHeroHeaderNavigateToButton'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqHomeOffice'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqJoinCaisCallToAction'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'CaisiqTab'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CaisiqVideo'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqVideoPlaylist'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CallToAction'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Chart'
              longSource?: string | null
              title?: string | null
              sys: { __typename?: 'Sys'; id: string }
              chartImage?: {
                __typename?: 'ComponentImage'
                altText?: string | null
                cloudinaryImage?: any | null
                name?: string | null
                image?: {
                  __typename?: 'Asset'
                  url?: string | null
                  height?: number | null
                  width?: number | null
                  title?: string | null
                  alt?: string | null
                } | null
              } | null
              footnote?: { __typename?: 'ChartFootnote'; json: any } | null
              longDescription?: {
                __typename?: 'ChartLongDescription'
                json: any
              } | null
            }
          | { __typename: 'ChildFirm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CiqArticle'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Company'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CompanyAndContactInfo'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentCard'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentCompanyContainer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentHero'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentHubspotForm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentImage'
              cloudinaryImage?: any | null
              name?: string | null
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentPodcastPlayer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentSummary'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTeamContainer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentText'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextIllustration'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImageImageSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImageListSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImageTextSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CorporateStats'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CuratedFilterGroup'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CuratedResearchContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Disclaimer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Event'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ExternalContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Faq'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FaqCategory'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'FeaturedContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'FilterGroup'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Firm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FullWidthImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'FundProductId'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'GlossaryTerm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'HomepageAppMetadata'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'HomepageContentSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'HomepageLandingPage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'HtmlTable'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ImageSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'LegalPage'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Link'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'LoginScreen'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Markdown'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'MemberFirm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Migration'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'NavHeaderTheme'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PageHelpCenterArticle'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PageLanding'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PagePwsCategory'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PageResource'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ParentFirm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Pdf'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Pet'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PlaylistContentSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Presentation'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PressAward'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsContactMedia'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsInsightsContentSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsPressRelease'
              slug?: string | null
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsSiteSettings'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Quote'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'RelatedContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'RelatedContentStrategy'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Seo'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'SkPlayground'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Tag'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'TagFamily'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Team'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'TestModel'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Theme'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'Whitepaper'
              slug?: string | null
              sys: { __typename?: 'Sys'; id: string }
            }
          | null
        >
        block: Array<
          | {
              __typename: 'AccessControl'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'AccessControl2'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'AccessControl3'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Accordion'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'AccordionWithImage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Announcement'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'AnnouncementSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqArticle'
              title?: string | null
              displayDate?: any | null
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              thumbnail?: { __typename?: 'Asset'; url?: string | null } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqArticleDropdown'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqAuthor'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqDefaultCourseImageSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqDefaultFundsThumbnailSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqFundThumbnail'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqFundThumbnailSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqHeroHeader'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqHeroHeaderNavigateToButton'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqHomeOffice'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqJoinCaisCallToAction'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqTab'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqVideo'
              title?: string | null
              displayDate?: any | null
              summary?: string | null
              duration?: number | null
              poster?: any | null
              video?: { __typename?: 'Asset'; url?: string | null } | null
              thumbnail?: { __typename?: 'Asset'; url?: string | null } | null
              linkedFrom?: {
                __typename?: 'CaisiqVideoLinkingCollections'
                caisiqVideoPlaylistCollection?: {
                  __typename?: 'CaisiqVideoPlaylistCollection'
                  items: Array<{
                    __typename?: 'CaisiqVideoPlaylist'
                    episodic?: boolean | null
                    title?: string | null
                    summary?: string | null
                    displayDate?: any | null
                    category?: string | null
                    sys: {
                      __typename?: 'Sys'
                      id: string
                      firstPublishedAt?: any | null
                    }
                    heroImage?: {
                      __typename?: 'Asset'
                      url?: string | null
                    } | null
                    videosCollection?: {
                      __typename?: 'CaisiqVideoPlaylistVideosCollection'
                      items: Array<{
                        __typename?: 'CaisiqVideo'
                        title?: string | null
                        summary?: string | null
                        duration?: number | null
                        poster?: any | null
                        displayDate?: any | null
                        muxVideo?: any | null
                        sys: {
                          __typename?: 'Sys'
                          id: string
                          firstPublishedAt?: any | null
                        }
                        thumbnail?: {
                          __typename?: 'Asset'
                          url?: string | null
                        } | null
                        video?: {
                          __typename?: 'Asset'
                          url?: string | null
                        } | null
                      } | null>
                    } | null
                  } | null>
                } | null
              } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqVideoPlaylist'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CallToAction'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Chart'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ChildFirm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CiqArticle'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Company'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CompanyAndContactInfo'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentCard'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentCompanyContainer'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentHero'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentHubspotForm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentImage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentPodcastPlayer'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentSummary'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTeamContainer'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentText'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextIllustration'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextImage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextImageImageSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextImageListSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextImageTextSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CorporateStats'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CuratedFilterGroup'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CuratedResearchContent'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Disclaimer'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Event'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ExternalContent'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Faq'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FaqCategory'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FeaturedContent'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FilterGroup'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Firm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FullWidthImage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FundProductId'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'GlossaryTerm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'HomepageAppMetadata'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'HomepageContentSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'HomepageLandingPage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'HtmlTable'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ImageSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'LegalPage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Link'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'LoginScreen'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Markdown'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'MemberFirm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Migration'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'NavHeaderTheme'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Page'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PageHelpCenterArticle'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PageLanding'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PagePwsCategory'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PageResource'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ParentFirm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Pdf'
              displayDate?: any | null
              title?: string | null
              pdf?: { __typename?: 'Asset'; url?: string | null } | null
              image?: { __typename?: 'Asset'; url?: string | null } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Person'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Pet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PlaylistContentSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Presentation'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PressAward'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PwsContactMedia'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PwsInsightsContentSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PwsPressRelease'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PwsSiteSettings'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Quote'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'RelatedContent'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'RelatedContentStrategy'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Seo'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'SkPlayground'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Tag'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'TagFamily'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Team'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'TestModel'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Theme'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Whitepaper'
              title?: string | null
              displayDate?: any | null
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              thumbnail?: { __typename?: 'Asset'; url?: string | null } | null
              pdfsCollection?: {
                __typename?: 'WhitepaperPdfsCollection'
                items: Array<{
                  __typename?: 'Pdf'
                  title?: string | null
                  displayDate?: any | null
                  sys: { __typename?: 'Sys'; id: string }
                  pdf?: { __typename?: 'Asset'; url?: string | null } | null
                  image?: { __typename?: 'Asset'; url?: string | null } | null
                } | null>
              } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | null
        >
      }
      assets: {
        __typename?: 'CaisiqVideoBodyAssets'
        block: Array<{
          __typename?: 'Asset'
          url?: string | null
          title?: string | null
          width?: number | null
          height?: number | null
          description?: string | null
          sys: { __typename?: 'Sys'; id: string }
        } | null>
      }
    }
  } | null
  linkedFrom?: {
    __typename?: 'CaisiqVideoLinkingCollections'
    caisiqVideoPlaylistCollection?: {
      __typename?: 'CaisiqVideoPlaylistCollection'
      items: Array<{
        __typename?: 'CaisiqVideoPlaylist'
        episodic?: boolean | null
        title?: string | null
        summary?: string | null
        displayDate?: any | null
        category?: string | null
        sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
        heroImage?: { __typename?: 'Asset'; url?: string | null } | null
        videosCollection?: {
          __typename?: 'CaisiqVideoPlaylistVideosCollection'
          items: Array<{
            __typename: 'CaisiqVideo'
            byCais?: boolean | null
            title?: string | null
            category?: string | null
            summary?: string | null
            duration?: number | null
            displayDate?: any | null
            muxVideo?: any | null
            poster?: any | null
            icon?: any | null
            muxThumbnailTime?: number | null
            description?: string | null
            sys: {
              __typename?: 'Sys'
              id: string
              firstPublishedAt?: any | null
            }
            fundProductIds?: {
              __typename?: 'FundProductId'
              fundProductIds?: Array<string | null> | null
            } | null
            firmsCollection?: {
              __typename?: 'CaisiqVideoFirmsCollection'
              items: Array<{
                __typename?: 'MemberFirm'
                id?: string | null
                name?: string | null
              } | null>
            } | null
            thumbnail?: {
              __typename?: 'Asset'
              url?: string | null
              width?: number | null
              height?: number | null
            } | null
            video?: { __typename?: 'Asset'; url?: string | null } | null
          } | null>
        } | null
      } | null>
    } | null
  } | null
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
  fundProductIds?: {
    __typename?: 'FundProductId'
    fundProductIds?: Array<string | null> | null
  } | null
  firmsCollection?: {
    __typename?: 'CaisiqVideoFirmsCollection'
    items: Array<{
      __typename?: 'MemberFirm'
      id?: string | null
      name?: string | null
    } | null>
  } | null
  thumbnail?: {
    __typename?: 'Asset'
    url?: string | null
    width?: number | null
    height?: number | null
  } | null
  video?: { __typename?: 'Asset'; url?: string | null } | null
}

export type GetVideoPlaylistsQueryVariables = Exact<{
  preview?: InputMaybe<Scalars['Boolean']>
  limit?: InputMaybe<Scalars['Int']>
  firmsLimit: Scalars['Int']
}>

export type GetVideoPlaylistsQuery = {
  __typename?: 'Query'
  caisiqVideoPlaylistCollection?: {
    __typename?: 'CaisiqVideoPlaylistCollection'
    items: Array<{
      __typename: 'CaisiqVideoPlaylist'
      episodic?: boolean | null
      title?: string | null
      category?: string | null
      summary?: string | null
      displayDate?: any | null
      byCais?: boolean | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      heroImage?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoPlaylistFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      videosCollection?: {
        __typename?: 'CaisiqVideoPlaylistVideosCollection'
        items: Array<{
          __typename?: 'CaisiqVideo'
          poster?: any | null
          sys: { __typename?: 'Sys'; id: string }
          thumbnail?: {
            __typename?: 'Asset'
            url?: string | null
            width?: number | null
            height?: number | null
          } | null
        } | null>
      } | null
    } | null>
  } | null
}

export type VideoPlaylistCardFragment = {
  __typename: 'CaisiqVideoPlaylist'
  episodic?: boolean | null
  title?: string | null
  category?: string | null
  summary?: string | null
  displayDate?: any | null
  byCais?: boolean | null
  description?: string | null
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
  heroImage?: {
    __typename?: 'Asset'
    url?: string | null
    width?: number | null
    height?: number | null
  } | null
  fundProductIds?: {
    __typename?: 'FundProductId'
    fundProductIds?: Array<string | null> | null
  } | null
  firmsCollection?: {
    __typename?: 'CaisiqVideoPlaylistFirmsCollection'
    items: Array<{
      __typename?: 'MemberFirm'
      id?: string | null
      name?: string | null
    } | null>
  } | null
  videosCollection?: {
    __typename?: 'CaisiqVideoPlaylistVideosCollection'
    items: Array<{
      __typename?: 'CaisiqVideo'
      poster?: any | null
      sys: { __typename?: 'Sys'; id: string }
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
    } | null>
  } | null
}

export type GetVideosQueryVariables = Exact<{
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<CaisiqVideoFilter>
  limit?: InputMaybe<Scalars['Int']>
  firmsLimit: Scalars['Int']
}>

export type GetVideosQuery = {
  __typename?: 'Query'
  caisiqVideoCollection?: {
    __typename?: 'CaisiqVideoCollection'
    total: number
    items: Array<{
      __typename: 'CaisiqVideo'
      byCais?: boolean | null
      title?: string | null
      category?: string | null
      summary?: string | null
      duration?: number | null
      displayDate?: any | null
      muxVideo?: any | null
      poster?: any | null
      icon?: any | null
      muxThumbnailTime?: number | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      video?: { __typename?: 'Asset'; url?: string | null } | null
    } | null>
  } | null
}

export type VideoCardFragment = {
  __typename: 'CaisiqVideo'
  byCais?: boolean | null
  title?: string | null
  category?: string | null
  summary?: string | null
  duration?: number | null
  displayDate?: any | null
  muxVideo?: any | null
  poster?: any | null
  icon?: any | null
  muxThumbnailTime?: number | null
  description?: string | null
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
  fundProductIds?: {
    __typename?: 'FundProductId'
    fundProductIds?: Array<string | null> | null
  } | null
  firmsCollection?: {
    __typename?: 'CaisiqVideoFirmsCollection'
    items: Array<{
      __typename?: 'MemberFirm'
      id?: string | null
      name?: string | null
    } | null>
  } | null
  thumbnail?: {
    __typename?: 'Asset'
    url?: string | null
    width?: number | null
    height?: number | null
  } | null
  video?: { __typename?: 'Asset'; url?: string | null } | null
}

export type GetWhitepaperBySlugQueryVariables = Exact<{
  slug: Scalars['String']
  preview?: InputMaybe<Scalars['Boolean']>
  firmsLimit: Scalars['Int']
}>

export type GetWhitepaperBySlugQuery = {
  __typename?: 'Query'
  whitepaperCollection?: {
    __typename?: 'WhitepaperCollection'
    total: number
    items: Array<{
      __typename: 'Whitepaper'
      customDescription?: string | null
      customTitle?: string | null
      category?: string | null
      byCais?: boolean | null
      type?: string | null
      slug?: string | null
      title?: string | null
      displayDate?: any | null
      introduction?: string | null
      description?: string | null
      author?: {
        __typename?: 'CaisiqAuthor'
        name?: string | null
        authorName?: string | null
        jobTitle?: string | null
        firm?: string | null
        avatar?: {
          __typename?: 'ComponentImage'
          altText?: string | null
          image?: {
            __typename?: 'Asset'
            url?: string | null
            title?: string | null
          } | null
        } | null
        linkedin?: { __typename?: 'Link'; externalLink?: string | null } | null
      } | null
      pdfsCollection?: {
        __typename?: 'WhitepaperPdfsCollection'
        items: Array<{
          __typename?: 'Pdf'
          title?: string | null
          displayDate?: any | null
          sys: { __typename?: 'Sys'; id: string }
          pdf?: { __typename?: 'Asset'; url?: string | null } | null
          image?: { __typename?: 'Asset'; url?: string | null } | null
        } | null>
      } | null
      tagsCollection?: {
        __typename?: 'WhitepaperTagsCollection'
        items: Array<{
          __typename?: 'Tag'
          tagId?: string | null
          label?: string | null
        } | null>
      } | null
      authorsCollection?: {
        __typename?: 'WhitepaperAuthorsCollection'
        items: Array<{
          __typename?: 'CaisiqAuthor'
          name?: string | null
          authorName?: string | null
          jobTitle?: string | null
          firm?: string | null
          imageSet?: any | null
          avatar?: {
            __typename?: 'ComponentImage'
            altText?: string | null
            image?: {
              __typename?: 'Asset'
              url?: string | null
              title?: string | null
              width?: number | null
              height?: number | null
            } | null
          } | null
          linkedin?: {
            __typename?: 'Link'
            externalLink?: string | null
          } | null
        } | null>
      } | null
      body?: {
        __typename?: 'WhitepaperBody'
        json: any
        links: {
          __typename?: 'WhitepaperBodyLinks'
          entries: {
            __typename?: 'WhitepaperBodyEntries'
            hyperlink: Array<
              | {
                  __typename: 'AccessControl'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AccessControl2'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AccessControl3'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Accordion'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AccordionWithImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Announcement'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AnnouncementSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqArticle'
                  slug?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqArticleDropdown'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqAuthor'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqDefaultCourseImageSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqDefaultFundsThumbnailSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqFundThumbnail'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqFundThumbnailSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqHeroHeader'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqHeroHeaderNavigateToButton'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqHomeOffice'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqJoinCaisCallToAction'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqTab'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqVideo'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqVideoPlaylist'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CallToAction'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Chart'
                  longSource?: string | null
                  title?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                  chartImage?: {
                    __typename?: 'ComponentImage'
                    altText?: string | null
                    cloudinaryImage?: any | null
                    name?: string | null
                    image?: {
                      __typename?: 'Asset'
                      url?: string | null
                      height?: number | null
                      width?: number | null
                      title?: string | null
                      alt?: string | null
                    } | null
                  } | null
                  footnote?: { __typename?: 'ChartFootnote'; json: any } | null
                  longDescription?: {
                    __typename?: 'ChartLongDescription'
                    json: any
                  } | null
                }
              | {
                  __typename: 'ChildFirm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CiqArticle'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Company'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CompanyAndContactInfo'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentCard'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentCompanyContainer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentHero'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentHubspotForm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentImage'
                  cloudinaryImage?: any | null
                  name?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentPodcastPlayer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentSummary'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTeamContainer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentText'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextIllustration'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImageImageSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImageListSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImageTextSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CorporateStats'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CuratedFilterGroup'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CuratedResearchContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Disclaimer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Event'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'ExternalContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Faq'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'FaqCategory'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'FeaturedContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'FilterGroup'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Firm'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'FullWidthImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'FundProductId'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'GlossaryTerm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HomepageAppMetadata'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HomepageContentSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HomepageLandingPage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HtmlTable'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ImageSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'LegalPage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Link'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'LoginScreen'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Markdown'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'MemberFirm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Migration'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'NavHeaderTheme'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'PageHelpCenterArticle'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PageLanding'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PagePwsCategory'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PageResource'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ParentFirm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Pdf'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'Person'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Pet'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'PlaylistContentSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Presentation'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PressAward'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsContactMedia'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsInsightsContentSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsPressRelease'
                  slug?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsSiteSettings'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Quote'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'RelatedContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'RelatedContentStrategy'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Seo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'SkPlayground'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Tag'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'TagFamily'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Team'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'TestModel'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Theme'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'Whitepaper'
                  slug?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                }
              | null
            >
            block: Array<
              | {
                  __typename: 'AccessControl'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AccessControl2'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AccessControl3'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Accordion'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AccordionWithImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Announcement'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AnnouncementSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqArticle'
                  title?: string | null
                  slug?: string | null
                  displayDate?: any | null
                  author?: {
                    __typename?: 'CaisiqAuthor'
                    name?: string | null
                  } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                  } | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqArticleDropdown'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqAuthor'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqDefaultCourseImageSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqDefaultFundsThumbnailSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqFundThumbnail'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqFundThumbnailSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqHeroHeader'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqHeroHeaderNavigateToButton'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqHomeOffice'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqJoinCaisCallToAction'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqTab'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqVideo'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqVideoPlaylist'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CallToAction'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Chart'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ChildFirm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CiqArticle'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Company'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CompanyAndContactInfo'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentCard'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentCompanyContainer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentHero'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentHubspotForm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentPodcastPlayer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentSummary'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTeamContainer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentText'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextIllustration'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImageImageSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImageListSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImageTextSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CorporateStats'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CuratedFilterGroup'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CuratedResearchContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Disclaimer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Event'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ExternalContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Faq'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FaqCategory'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FeaturedContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FilterGroup'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Firm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FullWidthImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FundProductId'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'GlossaryTerm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HomepageAppMetadata'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HomepageContentSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HomepageLandingPage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HtmlTable'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ImageSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'LegalPage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Link'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'LoginScreen'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Markdown'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'MemberFirm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Migration'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'NavHeaderTheme'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Page'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PageHelpCenterArticle'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PageLanding'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PagePwsCategory'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PageResource'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ParentFirm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Pdf'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Person'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Pet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PlaylistContentSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Presentation'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PressAward'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsContactMedia'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsInsightsContentSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsPressRelease'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsSiteSettings'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Quote'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'RelatedContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'RelatedContentStrategy'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Seo'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'SkPlayground'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Tag'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'TagFamily'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Team'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'TestModel'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Theme'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Whitepaper'
                  title?: string | null
                  displayDate?: any | null
                  author?: {
                    __typename?: 'CaisiqAuthor'
                    name?: string | null
                  } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                  } | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | null
            >
          }
          assets: {
            __typename?: 'WhitepaperBodyAssets'
            block: Array<{
              __typename?: 'Asset'
              url?: string | null
              title?: string | null
              width?: number | null
              height?: number | null
              description?: string | null
              fileName?: string | null
              contentType?: string | null
              sys: { __typename?: 'Sys'; id: string }
            } | null>
          }
        }
      } | null
      footnotes?: {
        __typename?: 'WhitepaperFootnotes'
        json: any
        links: {
          __typename?: 'WhitepaperFootnotesLinks'
          entries: {
            __typename?: 'WhitepaperFootnotesEntries'
            hyperlink: Array<
              | {
                  __typename: 'AccessControl'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AccessControl2'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AccessControl3'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Accordion'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AccordionWithImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Announcement'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'AnnouncementSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqArticle'
                  slug?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqArticleDropdown'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqAuthor'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqDefaultCourseImageSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqDefaultFundsThumbnailSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqFundThumbnail'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqFundThumbnailSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqHeroHeader'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqHeroHeaderNavigateToButton'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqHomeOffice'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqJoinCaisCallToAction'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqTab'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqVideo'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CaisiqVideoPlaylist'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CallToAction'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Chart'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'ChildFirm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CiqArticle'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Company'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CompanyAndContactInfo'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentCard'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentCompanyContainer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentHero'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentHubspotForm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentPodcastPlayer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentSummary'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTeamContainer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentText'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextIllustration'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImageImageSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImageListSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentTextImageTextSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CorporateStats'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CuratedFilterGroup'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'CuratedResearchContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Disclaimer'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Event'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'ExternalContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Faq'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'FaqCategory'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'FeaturedContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'FilterGroup'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Firm'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'FullWidthImage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'FundProductId'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'GlossaryTerm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HomepageAppMetadata'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HomepageContentSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HomepageLandingPage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'HtmlTable'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ImageSection'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'LegalPage'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Link'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'LoginScreen'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Markdown'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'MemberFirm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Migration'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'NavHeaderTheme'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Page'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'PageHelpCenterArticle'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PageLanding'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PagePwsCategory'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PageResource'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ParentFirm'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Pdf'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'Person'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Pet'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'PlaylistContentSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'Presentation'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PressAward'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsContactMedia'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsInsightsContentSet'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsPressRelease'
                  slug?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'PwsSiteSettings'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Quote'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'RelatedContent'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'RelatedContentStrategy'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Seo'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'SkPlayground'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Tag'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'TagFamily'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Team'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'TestModel'
                  sys: { __typename?: 'Sys'; id: string }
                }
              | { __typename: 'Theme'; sys: { __typename?: 'Sys'; id: string } }
              | {
                  __typename: 'Whitepaper'
                  slug?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                }
              | null
            >
            block: Array<
              | {
                  __typename: 'AccessControl'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AccessControl2'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AccessControl3'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Accordion'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AccordionWithImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Announcement'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'AnnouncementSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqArticle'
                  title?: string | null
                  slug?: string | null
                  displayDate?: any | null
                  author?: {
                    __typename?: 'CaisiqAuthor'
                    name?: string | null
                  } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                  } | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqArticleDropdown'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqAuthor'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqDefaultCourseImageSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqDefaultFundsThumbnailSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqFundThumbnail'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqFundThumbnailSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqHeroHeader'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqHeroHeaderNavigateToButton'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqHomeOffice'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqJoinCaisCallToAction'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqTab'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqVideo'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CaisiqVideoPlaylist'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CallToAction'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Chart'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ChildFirm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CiqArticle'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Company'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CompanyAndContactInfo'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentCard'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentCompanyContainer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentHero'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentHubspotForm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentPodcastPlayer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentSummary'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTeamContainer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentText'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextIllustration'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImageImageSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImageListSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ComponentTextImageTextSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CorporateStats'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CuratedFilterGroup'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'CuratedResearchContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Disclaimer'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Event'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ExternalContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Faq'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FaqCategory'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FeaturedContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FilterGroup'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Firm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FullWidthImage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'FundProductId'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'GlossaryTerm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HomepageAppMetadata'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HomepageContentSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HomepageLandingPage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'HtmlTable'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ImageSection'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'LegalPage'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Link'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'LoginScreen'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Markdown'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'MemberFirm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Migration'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'NavHeaderTheme'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Page'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PageHelpCenterArticle'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PageLanding'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PagePwsCategory'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PageResource'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'ParentFirm'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Pdf'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Person'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Pet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PlaylistContentSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Presentation'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PressAward'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsContactMedia'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsInsightsContentSet'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsPressRelease'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'PwsSiteSettings'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Quote'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'RelatedContent'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'RelatedContentStrategy'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Seo'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'SkPlayground'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Tag'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'TagFamily'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Team'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'TestModel'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Theme'
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | {
                  __typename: 'Whitepaper'
                  slug?: string | null
                  title?: string | null
                  displayDate?: any | null
                  author?: {
                    __typename?: 'CaisiqAuthor'
                    name?: string | null
                  } | null
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                  } | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                }
              | null
            >
          }
          assets: {
            __typename?: 'WhitepaperFootnotesAssets'
            block: Array<{
              __typename?: 'Asset'
              url?: string | null
              title?: string | null
              width?: number | null
              height?: number | null
              description?: string | null
              fileName?: string | null
              contentType?: string | null
              sys: { __typename?: 'Sys'; id: string }
            } | null>
          }
        }
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'WhitepaperFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      sys: {
        __typename?: 'Sys'
        id: string
        firstPublishedAt?: any | null
        publishedAt?: any | null
      }
      hero?: { __typename?: 'Asset'; url?: string | null } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
    } | null>
  } | null
}

export type GetWhitepaperPermissionDataQueryVariables = Exact<{
  slug: Scalars['String']
  firmsLimit: Scalars['Int']
}>

export type GetWhitepaperPermissionDataQuery = {
  __typename?: 'Query'
  whitepaperCollection?: {
    __typename?: 'WhitepaperCollection'
    total: number
    items: Array<{
      __typename?: 'Whitepaper'
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'WhitepaperFirmsCollection'
        items: Array<{ __typename?: 'MemberFirm'; id?: string | null } | null>
      } | null
    } | null>
  } | null
}

export type WhitepaperPageFragment = {
  __typename: 'Whitepaper'
  customDescription?: string | null
  customTitle?: string | null
  category?: string | null
  byCais?: boolean | null
  type?: string | null
  slug?: string | null
  title?: string | null
  displayDate?: any | null
  introduction?: string | null
  description?: string | null
  author?: {
    __typename?: 'CaisiqAuthor'
    name?: string | null
    authorName?: string | null
    jobTitle?: string | null
    firm?: string | null
    avatar?: {
      __typename?: 'ComponentImage'
      altText?: string | null
      image?: {
        __typename?: 'Asset'
        url?: string | null
        title?: string | null
      } | null
    } | null
    linkedin?: { __typename?: 'Link'; externalLink?: string | null } | null
  } | null
  pdfsCollection?: {
    __typename?: 'WhitepaperPdfsCollection'
    items: Array<{
      __typename?: 'Pdf'
      title?: string | null
      displayDate?: any | null
      sys: { __typename?: 'Sys'; id: string }
      pdf?: { __typename?: 'Asset'; url?: string | null } | null
      image?: { __typename?: 'Asset'; url?: string | null } | null
    } | null>
  } | null
  tagsCollection?: {
    __typename?: 'WhitepaperTagsCollection'
    items: Array<{
      __typename?: 'Tag'
      tagId?: string | null
      label?: string | null
    } | null>
  } | null
  authorsCollection?: {
    __typename?: 'WhitepaperAuthorsCollection'
    items: Array<{
      __typename?: 'CaisiqAuthor'
      name?: string | null
      authorName?: string | null
      jobTitle?: string | null
      firm?: string | null
      imageSet?: any | null
      avatar?: {
        __typename?: 'ComponentImage'
        altText?: string | null
        image?: {
          __typename?: 'Asset'
          url?: string | null
          title?: string | null
          width?: number | null
          height?: number | null
        } | null
      } | null
      linkedin?: { __typename?: 'Link'; externalLink?: string | null } | null
    } | null>
  } | null
  body?: {
    __typename?: 'WhitepaperBody'
    json: any
    links: {
      __typename?: 'WhitepaperBodyLinks'
      entries: {
        __typename?: 'WhitepaperBodyEntries'
        hyperlink: Array<
          | {
              __typename: 'AccessControl'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'AccessControl2'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'AccessControl3'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Accordion'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'AccordionWithImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Announcement'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'AnnouncementSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqArticle'
              slug?: string | null
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqArticleDropdown'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqAuthor'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqDefaultCourseImageSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqDefaultFundsThumbnailSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqFundThumbnail'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqFundThumbnailSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqHeroHeader'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqHeroHeaderNavigateToButton'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqHomeOffice'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqJoinCaisCallToAction'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'CaisiqTab'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CaisiqVideo'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqVideoPlaylist'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CallToAction'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Chart'
              longSource?: string | null
              title?: string | null
              sys: { __typename?: 'Sys'; id: string }
              chartImage?: {
                __typename?: 'ComponentImage'
                altText?: string | null
                cloudinaryImage?: any | null
                name?: string | null
                image?: {
                  __typename?: 'Asset'
                  url?: string | null
                  height?: number | null
                  width?: number | null
                  title?: string | null
                  alt?: string | null
                } | null
              } | null
              footnote?: { __typename?: 'ChartFootnote'; json: any } | null
              longDescription?: {
                __typename?: 'ChartLongDescription'
                json: any
              } | null
            }
          | { __typename: 'ChildFirm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CiqArticle'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Company'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CompanyAndContactInfo'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentCard'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentCompanyContainer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentHero'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentHubspotForm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentImage'
              cloudinaryImage?: any | null
              name?: string | null
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentPodcastPlayer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentSummary'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTeamContainer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentText'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextIllustration'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImageImageSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImageListSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImageTextSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CorporateStats'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CuratedFilterGroup'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CuratedResearchContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Disclaimer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Event'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ExternalContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Faq'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FaqCategory'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'FeaturedContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'FilterGroup'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Firm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FullWidthImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'FundProductId'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'GlossaryTerm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'HomepageAppMetadata'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'HomepageContentSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'HomepageLandingPage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'HtmlTable'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ImageSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'LegalPage'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Link'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'LoginScreen'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Markdown'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'MemberFirm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Migration'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'NavHeaderTheme'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PageHelpCenterArticle'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PageLanding'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PagePwsCategory'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PageResource'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ParentFirm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Pdf'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Pet'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PlaylistContentSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Presentation'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PressAward'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsContactMedia'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsInsightsContentSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsPressRelease'
              slug?: string | null
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsSiteSettings'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Quote'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'RelatedContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'RelatedContentStrategy'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Seo'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'SkPlayground'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Tag'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'TagFamily'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Team'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'TestModel'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Theme'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'Whitepaper'
              slug?: string | null
              sys: { __typename?: 'Sys'; id: string }
            }
          | null
        >
        block: Array<
          | {
              __typename: 'AccessControl'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'AccessControl2'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'AccessControl3'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Accordion'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'AccordionWithImage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Announcement'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'AnnouncementSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqArticle'
              title?: string | null
              slug?: string | null
              displayDate?: any | null
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              thumbnail?: { __typename?: 'Asset'; url?: string | null } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqArticleDropdown'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqAuthor'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqDefaultCourseImageSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqDefaultFundsThumbnailSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqFundThumbnail'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqFundThumbnailSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqHeroHeader'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqHeroHeaderNavigateToButton'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqHomeOffice'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqJoinCaisCallToAction'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqTab'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqVideo'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqVideoPlaylist'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CallToAction'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Chart'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ChildFirm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CiqArticle'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Company'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CompanyAndContactInfo'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentCard'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentCompanyContainer'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentHero'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentHubspotForm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentImage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentPodcastPlayer'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentSummary'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTeamContainer'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentText'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextIllustration'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextImage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextImageImageSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextImageListSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextImageTextSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CorporateStats'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CuratedFilterGroup'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CuratedResearchContent'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Disclaimer'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Event'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ExternalContent'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Faq'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FaqCategory'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FeaturedContent'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FilterGroup'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Firm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FullWidthImage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FundProductId'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'GlossaryTerm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'HomepageAppMetadata'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'HomepageContentSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'HomepageLandingPage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'HtmlTable'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ImageSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'LegalPage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Link'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'LoginScreen'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Markdown'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'MemberFirm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Migration'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'NavHeaderTheme'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Page'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PageHelpCenterArticle'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PageLanding'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PagePwsCategory'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PageResource'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ParentFirm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Pdf'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Person'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Pet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PlaylistContentSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Presentation'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PressAward'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PwsContactMedia'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PwsInsightsContentSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PwsPressRelease'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PwsSiteSettings'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Quote'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'RelatedContent'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'RelatedContentStrategy'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Seo'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'SkPlayground'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Tag'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'TagFamily'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Team'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'TestModel'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Theme'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Whitepaper'
              title?: string | null
              displayDate?: any | null
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              thumbnail?: { __typename?: 'Asset'; url?: string | null } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | null
        >
      }
      assets: {
        __typename?: 'WhitepaperBodyAssets'
        block: Array<{
          __typename?: 'Asset'
          url?: string | null
          title?: string | null
          width?: number | null
          height?: number | null
          description?: string | null
          fileName?: string | null
          contentType?: string | null
          sys: { __typename?: 'Sys'; id: string }
        } | null>
      }
    }
  } | null
  footnotes?: {
    __typename?: 'WhitepaperFootnotes'
    json: any
    links: {
      __typename?: 'WhitepaperFootnotesLinks'
      entries: {
        __typename?: 'WhitepaperFootnotesEntries'
        hyperlink: Array<
          | {
              __typename: 'AccessControl'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'AccessControl2'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'AccessControl3'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Accordion'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'AccordionWithImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Announcement'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'AnnouncementSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqArticle'
              slug?: string | null
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqArticleDropdown'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqAuthor'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqDefaultCourseImageSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqDefaultFundsThumbnailSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqFundThumbnail'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqFundThumbnailSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqHeroHeader'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqHeroHeaderNavigateToButton'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqHomeOffice'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqJoinCaisCallToAction'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'CaisiqTab'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CaisiqVideo'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CaisiqVideoPlaylist'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CallToAction'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Chart'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'ChildFirm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CiqArticle'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Company'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'CompanyAndContactInfo'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentCard'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentCompanyContainer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentHero'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentHubspotForm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentPodcastPlayer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentSummary'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTeamContainer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentText'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextIllustration'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImageImageSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImageListSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentTextImageTextSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CorporateStats'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CuratedFilterGroup'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'CuratedResearchContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Disclaimer'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Event'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ExternalContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Faq'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FaqCategory'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'FeaturedContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'FilterGroup'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Firm'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'FullWidthImage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'FundProductId'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'GlossaryTerm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'HomepageAppMetadata'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'HomepageContentSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'HomepageLandingPage'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'HtmlTable'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'ImageSection'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'LegalPage'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Link'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'LoginScreen'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Markdown'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'MemberFirm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Migration'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'NavHeaderTheme'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Page'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PageHelpCenterArticle'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PageLanding'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PagePwsCategory'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PageResource'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ParentFirm'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Pdf'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Person'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Pet'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'PlaylistContentSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'Presentation'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PressAward'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsContactMedia'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsInsightsContentSet'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsPressRelease'
              slug?: string | null
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'PwsSiteSettings'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Quote'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'RelatedContent'
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'RelatedContentStrategy'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Seo'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'SkPlayground'
              sys: { __typename?: 'Sys'; id: string }
            }
          | { __typename: 'Tag'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'TagFamily'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Team'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'TestModel'; sys: { __typename?: 'Sys'; id: string } }
          | { __typename: 'Theme'; sys: { __typename?: 'Sys'; id: string } }
          | {
              __typename: 'Whitepaper'
              slug?: string | null
              sys: { __typename?: 'Sys'; id: string }
            }
          | null
        >
        block: Array<
          | {
              __typename: 'AccessControl'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'AccessControl2'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'AccessControl3'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Accordion'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'AccordionWithImage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Announcement'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'AnnouncementSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqArticle'
              title?: string | null
              slug?: string | null
              displayDate?: any | null
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              thumbnail?: { __typename?: 'Asset'; url?: string | null } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqArticleDropdown'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqAuthor'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqDefaultCourseImageSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqDefaultFundsThumbnailSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqFundThumbnail'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqFundThumbnailSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqHeroHeader'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqHeroHeaderNavigateToButton'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqHomeOffice'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqJoinCaisCallToAction'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqTab'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqVideo'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CaisiqVideoPlaylist'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CallToAction'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Chart'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ChildFirm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CiqArticle'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Company'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CompanyAndContactInfo'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentCard'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentCompanyContainer'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentHero'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentHubspotForm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentImage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentPodcastPlayer'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentSummary'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTeamContainer'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentText'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextIllustration'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextImage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextImageImageSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextImageListSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ComponentTextImageTextSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CorporateStats'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CuratedFilterGroup'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'CuratedResearchContent'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Disclaimer'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Event'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ExternalContent'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Faq'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FaqCategory'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FeaturedContent'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FilterGroup'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Firm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FullWidthImage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'FundProductId'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'GlossaryTerm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'HomepageAppMetadata'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'HomepageContentSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'HomepageLandingPage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'HtmlTable'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ImageSection'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'LegalPage'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Link'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'LoginScreen'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Markdown'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'MemberFirm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Migration'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'NavHeaderTheme'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Page'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PageHelpCenterArticle'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PageLanding'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PagePwsCategory'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PageResource'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'ParentFirm'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Pdf'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Person'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Pet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PlaylistContentSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Presentation'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PressAward'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PwsContactMedia'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PwsInsightsContentSet'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PwsPressRelease'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'PwsSiteSettings'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Quote'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'RelatedContent'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'RelatedContentStrategy'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Seo'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'SkPlayground'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Tag'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'TagFamily'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Team'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'TestModel'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Theme'
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | {
              __typename: 'Whitepaper'
              slug?: string | null
              title?: string | null
              displayDate?: any | null
              author?: {
                __typename?: 'CaisiqAuthor'
                name?: string | null
              } | null
              thumbnail?: { __typename?: 'Asset'; url?: string | null } | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
            }
          | null
        >
      }
      assets: {
        __typename?: 'WhitepaperFootnotesAssets'
        block: Array<{
          __typename?: 'Asset'
          url?: string | null
          title?: string | null
          width?: number | null
          height?: number | null
          description?: string | null
          fileName?: string | null
          contentType?: string | null
          sys: { __typename?: 'Sys'; id: string }
        } | null>
      }
    }
  } | null
  fundProductIds?: {
    __typename?: 'FundProductId'
    fundProductIds?: Array<string | null> | null
  } | null
  firmsCollection?: {
    __typename?: 'WhitepaperFirmsCollection'
    items: Array<{
      __typename?: 'MemberFirm'
      id?: string | null
      name?: string | null
    } | null>
  } | null
  sys: {
    __typename?: 'Sys'
    id: string
    firstPublishedAt?: any | null
    publishedAt?: any | null
  }
  hero?: { __typename?: 'Asset'; url?: string | null } | null
  thumbnail?: {
    __typename?: 'Asset'
    url?: string | null
    width?: number | null
    height?: number | null
  } | null
}

export type GetWhitepaperCollectionQueryVariables = Exact<{
  preview?: InputMaybe<Scalars['Boolean']>
  where?: InputMaybe<WhitepaperFilter>
  limit?: InputMaybe<Scalars['Int']>
  firmsLimit: Scalars['Int']
}>

export type GetWhitepaperCollectionQuery = {
  __typename?: 'Query'
  whitepaperCollection?: {
    __typename?: 'WhitepaperCollection'
    total: number
    items: Array<{
      __typename: 'Whitepaper'
      byCais?: boolean | null
      type?: string | null
      slug?: string | null
      title?: string | null
      category?: string | null
      displayDate?: any | null
      introduction?: string | null
      description?: string | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'WhitepaperFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      sys: {
        __typename?: 'Sys'
        id: string
        firstPublishedAt?: any | null
        publishedAt?: any | null
      }
      author?: { __typename?: 'CaisiqAuthor'; name?: string | null } | null
      hero?: { __typename?: 'Asset'; url?: string | null } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      pdfsCollection?: {
        __typename?: 'WhitepaperPdfsCollection'
        items: Array<{
          __typename?: 'Pdf'
          title?: string | null
          displayDate?: any | null
          pdf?: { __typename?: 'Asset'; url?: string | null } | null
          image?: { __typename?: 'Asset'; url?: string | null } | null
        } | null>
      } | null
    } | null>
  } | null
}

export type WhitepaperCardFragment = {
  __typename: 'Whitepaper'
  byCais?: boolean | null
  type?: string | null
  slug?: string | null
  title?: string | null
  category?: string | null
  displayDate?: any | null
  introduction?: string | null
  description?: string | null
  fundProductIds?: {
    __typename?: 'FundProductId'
    fundProductIds?: Array<string | null> | null
  } | null
  firmsCollection?: {
    __typename?: 'WhitepaperFirmsCollection'
    items: Array<{
      __typename?: 'MemberFirm'
      id?: string | null
      name?: string | null
    } | null>
  } | null
  sys: {
    __typename?: 'Sys'
    id: string
    firstPublishedAt?: any | null
    publishedAt?: any | null
  }
  author?: { __typename?: 'CaisiqAuthor'; name?: string | null } | null
  hero?: { __typename?: 'Asset'; url?: string | null } | null
  thumbnail?: {
    __typename?: 'Asset'
    url?: string | null
    width?: number | null
    height?: number | null
  } | null
  pdfsCollection?: {
    __typename?: 'WhitepaperPdfsCollection'
    items: Array<{
      __typename?: 'Pdf'
      title?: string | null
      displayDate?: any | null
      pdf?: { __typename?: 'Asset'; url?: string | null } | null
      image?: { __typename?: 'Asset'; url?: string | null } | null
    } | null>
  } | null
}

export type GetAnnouncementsQueryVariables = Exact<{
  id: Scalars['String']
  date?: InputMaybe<Scalars['DateTime']>
}>

export type GetAnnouncementsQuery = {
  __typename?: 'Query'
  announcementSetCollection?: {
    __typename?: 'AnnouncementSetCollection'
    total: number
    items: Array<{
      __typename?: 'AnnouncementSet'
      excludedUrls?: Array<string | null> | null
      announcementDefault?: {
        __typename?: 'Announcement'
        dateTimeStart?: any | null
        dateTimeEnd?: any | null
        timezone?: string | null
        icon?: string | null
        image?: any | null
        title?: string | null
        description?: string | null
        buttonText?: string | null
        sys: { __typename?: 'Sys'; id: string }
        destination?: {
          __typename?: 'Link'
          internalLink?: string | null
          externalLink?: string | null
          openInNewWindow?: boolean | null
          cmsLink?:
            | { __typename: 'CaisiqArticle'; slug?: string | null }
            | { __typename: 'HomepageLandingPage'; slug?: string | null }
            | { __typename: 'Page'; slug?: string | null }
            | { __typename: 'PageResource'; slug?: string | null }
            | { __typename: 'PwsPressRelease' }
            | { __typename: 'Whitepaper'; slug?: string | null }
            | null
        } | null
        firmsCollection?: {
          __typename?: 'AnnouncementFirmsCollection'
          items: Array<{ __typename?: 'MemberFirm'; id?: string | null } | null>
        } | null
        fundProductIds?: {
          __typename?: 'FundProductId'
          fundProductIds?: Array<string | null> | null
        } | null
      } | null
      announcementOptionsCollection?: {
        __typename?: 'AnnouncementSetAnnouncementOptionsCollection'
        total: number
        items: Array<{
          __typename?: 'Announcement'
          dateTimeStart?: any | null
          dateTimeEnd?: any | null
          timezone?: string | null
          icon?: string | null
          image?: any | null
          title?: string | null
          description?: string | null
          buttonText?: string | null
          sys: { __typename?: 'Sys'; id: string }
          destination?: {
            __typename?: 'Link'
            internalLink?: string | null
            externalLink?: string | null
            openInNewWindow?: boolean | null
            cmsLink?:
              | { __typename: 'CaisiqArticle'; slug?: string | null }
              | { __typename: 'HomepageLandingPage'; slug?: string | null }
              | { __typename: 'Page'; slug?: string | null }
              | { __typename: 'PageResource'; slug?: string | null }
              | { __typename: 'PwsPressRelease' }
              | { __typename: 'Whitepaper'; slug?: string | null }
              | null
          } | null
          firmsCollection?: {
            __typename?: 'AnnouncementFirmsCollection'
            items: Array<{
              __typename?: 'MemberFirm'
              id?: string | null
            } | null>
          } | null
          fundProductIds?: {
            __typename?: 'FundProductId'
            fundProductIds?: Array<string | null> | null
          } | null
        } | null>
      } | null
    } | null>
  } | null
}

export type AnnouncementFragment = {
  __typename?: 'Announcement'
  dateTimeStart?: any | null
  dateTimeEnd?: any | null
  timezone?: string | null
  icon?: string | null
  image?: any | null
  title?: string | null
  description?: string | null
  buttonText?: string | null
  sys: { __typename?: 'Sys'; id: string }
  destination?: {
    __typename?: 'Link'
    internalLink?: string | null
    externalLink?: string | null
    openInNewWindow?: boolean | null
    cmsLink?:
      | { __typename: 'CaisiqArticle'; slug?: string | null }
      | { __typename: 'HomepageLandingPage'; slug?: string | null }
      | { __typename: 'Page'; slug?: string | null }
      | { __typename: 'PageResource'; slug?: string | null }
      | { __typename: 'PwsPressRelease' }
      | { __typename: 'Whitepaper'; slug?: string | null }
      | null
  } | null
  firmsCollection?: {
    __typename?: 'AnnouncementFirmsCollection'
    items: Array<{ __typename?: 'MemberFirm'; id?: string | null } | null>
  } | null
  fundProductIds?: {
    __typename?: 'FundProductId'
    fundProductIds?: Array<string | null> | null
  } | null
}

export type VideoPlaylistFieldsFragment = {
  __typename?: 'CaisiqVideoPlaylist'
  episodic?: boolean | null
  title?: string | null
  summary?: string | null
  category?: string | null
  displayDate?: any | null
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
  heroImage?: { __typename?: 'Asset'; url?: string | null } | null
  videosCollection?: {
    __typename?: 'CaisiqVideoPlaylistVideosCollection'
    items: Array<{
      __typename?: 'CaisiqVideo'
      muxThumbnailTime?: number | null
      title?: string | null
      summary?: string | null
      category?: string | null
      duration?: number | null
      displayDate?: any | null
      muxVideo?: any | null
      poster?: any | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      video?: { __typename?: 'Asset'; url?: string | null } | null
    } | null>
  } | null
}

export type GetCallToActionByIdAndFirmQueryVariables = Exact<{
  id: Scalars['String']
  preview?: InputMaybe<Scalars['Boolean']>
  firms: Array<Scalars['String']> | Scalars['String']
}>

export type GetCallToActionByIdAndFirmQuery = {
  __typename?: 'Query'
  callToActionCollection?: {
    __typename?: 'CallToActionCollection'
    items: Array<{
      __typename: 'CallToAction'
      title?: string | null
      id?: string | null
      description?: string | null
      buttonText?: string | null
      imageSet?: any | null
      byCais?: boolean | null
      sys: { __typename?: 'Sys'; id: string }
      destination?: {
        __typename?: 'Link'
        openInNewWindow?: boolean | null
        externalLink?: string | null
        internalLink?: string | null
        cmsLink?:
          | { __typename?: 'CaisiqArticle' }
          | { __typename?: 'HomepageLandingPage' }
          | { __typename?: 'Page' }
          | { __typename: 'PageResource'; slug?: string | null }
          | { __typename?: 'PwsPressRelease' }
          | { __typename?: 'Whitepaper' }
          | null
      } | null
      mediaFile?: {
        __typename?: 'Asset'
        url?: string | null
        fileName?: string | null
      } | null
      firm?: {
        __typename?: 'MemberFirm'
        id?: string | null
        name?: string | null
      } | null
    } | null>
  } | null
}

export type GetEventsQueryVariables = Exact<{
  where?: InputMaybe<EventFilter>
  order?: InputMaybe<EventOrder>
  limit: Scalars['Int']
  preview?: InputMaybe<Scalars['Boolean']>
  firmsLimit: Scalars['Int']
  tagsLimit: Scalars['Int']
}>

export type GetEventsQuery = {
  __typename?: 'Query'
  eventCollection?: {
    __typename?: 'EventCollection'
    total: number
    items: Array<{
      __typename: 'Event'
      allDay?: boolean | null
      dateTimeEnd?: any | null
      description?: string | null
      eventThumbnail?: any | null
      eventType?: string | null
      byCais?: boolean | null
      link?: string | null
      locationName?: string | null
      status?: string | null
      timezone?: string | null
      title?: string | null
      date?: any | null
      displayDate?: any | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'EventFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      sys: { __typename?: 'Sys'; id: string }
      tagsCollection?: {
        __typename?: 'EventTagsCollection'
        items: Array<{ __typename?: 'Tag'; tagId?: string | null } | null>
      } | null
    } | null>
  } | null
}

export type GetFaQsCategoriesQueryVariables = Exact<{
  order?: InputMaybe<FaqCategoryOrder>
}>

export type GetFaQsCategoriesQuery = {
  __typename?: 'Query'
  faqCategoryCollection?: {
    __typename?: 'FaqCategoryCollection'
    items: Array<{
      __typename?: 'FaqCategory'
      title?: string | null
      id?: string | null
    } | null>
  } | null
}

export type FaqCategoryFragment = {
  __typename?: 'FaqCategory'
  title?: string | null
  id?: string | null
}

export type GetFaQsByCategoryQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetFaQsByCategoryQuery = {
  __typename?: 'Query'
  faqCategoryCollection?: {
    __typename?: 'FaqCategoryCollection'
    items: Array<{
      __typename?: 'FaqCategory'
      title?: string | null
      faqsCollection?: {
        __typename?: 'FaqCategoryFaqsCollection'
        items: Array<{
          __typename?: 'Faq'
          faq?: string | null
          answer?: string | null
          id?: string | null
        } | null>
      } | null
    } | null>
  } | null
}

export type GetFaQsQueryVariables = Exact<{
  searchTerm?: InputMaybe<Scalars['String']>
}>

export type GetFaQsQuery = {
  __typename?: 'Query'
  faqCollection?: {
    __typename?: 'FaqCollection'
    items: Array<{
      __typename: 'Faq'
      faq?: string | null
      answer?: string | null
      id?: string | null
      sys: { __typename?: 'Sys'; id: string }
    } | null>
  } | null
}

export type FaqFragment = {
  __typename?: 'Faq'
  faq?: string | null
  answer?: string | null
  id?: string | null
}

export type GetGlossaryQueryVariables = Exact<{
  preview?: InputMaybe<Scalars['Boolean']>
}>

export type GetGlossaryQuery = {
  __typename?: 'Query'
  glossaryTermCollection?: {
    __typename?: 'GlossaryTermCollection'
    items: Array<{
      __typename?: 'GlossaryTerm'
      definition?: string | null
      id?: string | null
      term?: string | null
    } | null>
  } | null
}

export type HomepageContentSectionFragment = {
  __typename: 'HomepageContentSection'
  title?: string | null
  bookmarkId?: string | null
  removeTopPadding?: boolean | null
  componentId?: string | null
  componentSectionDescription?: string | null
  sys: { __typename?: 'Sys'; id: string }
  itemsCollection?: {
    __typename?: 'HomepageContentSectionItemsCollection'
    items: Array<
      | {
          __typename: 'CaisiqVideo'
          title?: string | null
          category?: string | null
          summary?: string | null
          duration?: number | null
          displayDate?: any | null
          muxVideo?: any | null
          poster?: any | null
          icon?: any | null
          muxThumbnailTime?: number | null
          description?: string | null
          sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
          thumbnail?: {
            __typename?: 'Asset'
            url?: string | null
            width?: number | null
            height?: number | null
          } | null
          video?: { __typename?: 'Asset'; url?: string | null } | null
        }
      | {
          __typename: 'CallToAction'
          title?: string | null
          id?: string | null
          description?: string | null
          buttonText?: string | null
          imageSet?: any | null
          byCais?: boolean | null
          sys: { __typename?: 'Sys'; id: string }
          destination?: {
            __typename?: 'Link'
            openInNewWindow?: boolean | null
            externalLink?: string | null
            internalLink?: string | null
            cmsLink?:
              | { __typename?: 'CaisiqArticle' }
              | { __typename?: 'HomepageLandingPage' }
              | { __typename?: 'Page' }
              | { __typename: 'PageResource'; slug?: string | null }
              | { __typename?: 'PwsPressRelease' }
              | { __typename?: 'Whitepaper' }
              | null
          } | null
          mediaFile?: {
            __typename?: 'Asset'
            url?: string | null
            fileName?: string | null
          } | null
          firm?: {
            __typename?: 'MemberFirm'
            id?: string | null
            name?: string | null
          } | null
        }
      | {
          __typename: 'ComponentImage'
          title?: string | null
          cloudinaryImage?: any | null
          sys: { __typename?: 'Sys'; id: string }
        }
      | {
          __typename: 'ComponentText'
          title?: string | null
          brandIcon?: string | null
          sys: { __typename?: 'Sys'; id: string }
          text?: {
            __typename?: 'ComponentTextText'
            json: any
            links: {
              __typename?: 'ComponentTextTextLinks'
              entries: {
                __typename?: 'ComponentTextTextEntries'
                hyperlink: Array<
                  | { __typename?: 'AccessControl' }
                  | { __typename?: 'AccessControl2' }
                  | { __typename?: 'AccessControl3' }
                  | { __typename?: 'Accordion' }
                  | { __typename?: 'AccordionWithImage' }
                  | { __typename?: 'Announcement' }
                  | { __typename?: 'AnnouncementSet' }
                  | { __typename?: 'CaisiqArticle' }
                  | { __typename?: 'CaisiqArticleDropdown' }
                  | { __typename?: 'CaisiqAuthor' }
                  | { __typename?: 'CaisiqDefaultCourseImageSet' }
                  | { __typename?: 'CaisiqDefaultFundsThumbnailSet' }
                  | { __typename?: 'CaisiqFundThumbnail' }
                  | { __typename?: 'CaisiqFundThumbnailSet' }
                  | { __typename?: 'CaisiqHeroHeader' }
                  | { __typename?: 'CaisiqHeroHeaderNavigateToButton' }
                  | { __typename?: 'CaisiqHomeOffice' }
                  | { __typename?: 'CaisiqJoinCaisCallToAction' }
                  | { __typename?: 'CaisiqTab' }
                  | { __typename?: 'CaisiqVideo' }
                  | { __typename?: 'CaisiqVideoPlaylist' }
                  | { __typename?: 'CallToAction' }
                  | { __typename?: 'Chart' }
                  | { __typename?: 'ChildFirm' }
                  | { __typename?: 'CiqArticle' }
                  | { __typename?: 'Company' }
                  | { __typename?: 'CompanyAndContactInfo' }
                  | { __typename?: 'ComponentCard' }
                  | { __typename?: 'ComponentCompanyContainer' }
                  | { __typename?: 'ComponentHero' }
                  | { __typename?: 'ComponentHubspotForm' }
                  | { __typename?: 'ComponentImage' }
                  | { __typename?: 'ComponentPodcastPlayer' }
                  | { __typename?: 'ComponentSection' }
                  | { __typename?: 'ComponentSummary' }
                  | { __typename?: 'ComponentTeamContainer' }
                  | { __typename?: 'ComponentText' }
                  | { __typename?: 'ComponentTextIllustration' }
                  | { __typename?: 'ComponentTextImage' }
                  | { __typename?: 'ComponentTextImageImageSection' }
                  | { __typename?: 'ComponentTextImageListSection' }
                  | { __typename?: 'ComponentTextImageTextSection' }
                  | { __typename?: 'CorporateStats' }
                  | { __typename?: 'CuratedFilterGroup' }
                  | { __typename?: 'CuratedResearchContent' }
                  | { __typename?: 'Disclaimer' }
                  | { __typename?: 'Event' }
                  | { __typename?: 'ExternalContent' }
                  | { __typename?: 'Faq' }
                  | { __typename?: 'FaqCategory' }
                  | { __typename?: 'FeaturedContent' }
                  | { __typename?: 'FilterGroup' }
                  | { __typename?: 'Firm' }
                  | { __typename?: 'FullWidthImage' }
                  | { __typename?: 'FundProductId' }
                  | { __typename?: 'GlossaryTerm' }
                  | { __typename?: 'HomepageAppMetadata' }
                  | { __typename?: 'HomepageContentSection' }
                  | { __typename?: 'HomepageLandingPage' }
                  | { __typename?: 'HtmlTable' }
                  | { __typename?: 'ImageSection' }
                  | { __typename?: 'LegalPage' }
                  | { __typename?: 'Link' }
                  | { __typename?: 'LoginScreen' }
                  | { __typename?: 'Markdown' }
                  | { __typename?: 'MemberFirm' }
                  | { __typename?: 'Migration' }
                  | { __typename?: 'NavHeaderTheme' }
                  | {
                      __typename?: 'Page'
                      name?: string | null
                      slug?: string | null
                      sys: { __typename?: 'Sys'; id: string }
                      content?:
                        | { __typename: 'CaisiqArticle' }
                        | { __typename: 'CaisiqVideo' }
                        | { __typename: 'CaisiqVideoPlaylist' }
                        | { __typename: 'Company' }
                        | { __typename: 'PageHelpCenterArticle' }
                        | { __typename: 'PageLanding' }
                        | { __typename: 'PagePwsCategory' }
                        | { __typename: 'Person' }
                        | { __typename: 'PwsPressRelease' }
                        | { __typename: 'Whitepaper' }
                        | null
                    }
                  | { __typename?: 'PageHelpCenterArticle' }
                  | { __typename?: 'PageLanding' }
                  | { __typename?: 'PagePwsCategory' }
                  | { __typename?: 'PageResource' }
                  | { __typename?: 'ParentFirm' }
                  | { __typename?: 'Pdf' }
                  | { __typename?: 'Person' }
                  | { __typename?: 'Pet' }
                  | { __typename?: 'PlaylistContentSet' }
                  | { __typename?: 'Presentation' }
                  | { __typename?: 'PressAward' }
                  | { __typename?: 'PwsContactMedia' }
                  | { __typename?: 'PwsInsightsContentSet' }
                  | { __typename?: 'PwsPressRelease' }
                  | { __typename?: 'PwsSiteSettings' }
                  | { __typename?: 'Quote' }
                  | { __typename?: 'RelatedContent' }
                  | { __typename?: 'RelatedContentStrategy' }
                  | { __typename?: 'Seo' }
                  | { __typename?: 'SkPlayground' }
                  | { __typename?: 'Tag' }
                  | { __typename?: 'TagFamily' }
                  | { __typename?: 'Team' }
                  | { __typename?: 'TestModel' }
                  | { __typename?: 'Theme' }
                  | { __typename?: 'Whitepaper' }
                  | null
                >
                inline: Array<
                  | {
                      __typename: 'AccessControl'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'AccessControl2'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'AccessControl3'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Accordion'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'AccordionWithImage'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Announcement'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'AnnouncementSet'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqArticle'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqArticleDropdown'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqAuthor'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqDefaultCourseImageSet'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqDefaultFundsThumbnailSet'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqFundThumbnail'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqFundThumbnailSet'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqHeroHeader'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqHeroHeaderNavigateToButton'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqHomeOffice'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqJoinCaisCallToAction'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqTab'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqVideo'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CaisiqVideoPlaylist'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CallToAction'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Chart'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ChildFirm'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CiqArticle'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Company'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CompanyAndContactInfo'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentCard'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentCompanyContainer'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentHero'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentHubspotForm'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentImage'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentPodcastPlayer'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentSection'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentSummary'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentTeamContainer'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentText'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentTextIllustration'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentTextImage'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentTextImageImageSection'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentTextImageListSection'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ComponentTextImageTextSection'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CorporateStats'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CuratedFilterGroup'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'CuratedResearchContent'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Disclaimer'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Event'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ExternalContent'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Faq'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'FaqCategory'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'FeaturedContent'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'FilterGroup'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Firm'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'FullWidthImage'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'FundProductId'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'GlossaryTerm'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'HomepageAppMetadata'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'HomepageContentSection'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'HomepageLandingPage'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'HtmlTable'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ImageSection'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'LegalPage'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Link'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'LoginScreen'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Markdown'
                      text?: string | null
                      type?: string | null
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'MemberFirm'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Migration'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'NavHeaderTheme'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Page'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PageHelpCenterArticle'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PageLanding'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PagePwsCategory'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PageResource'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'ParentFirm'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Pdf'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Person'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Pet'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PlaylistContentSet'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Presentation'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PressAward'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PwsContactMedia'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PwsInsightsContentSet'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PwsPressRelease'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'PwsSiteSettings'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Quote'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'RelatedContent'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'RelatedContentStrategy'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Seo'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'SkPlayground'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Tag'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'TagFamily'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Team'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'TestModel'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Theme'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | {
                      __typename: 'Whitepaper'
                      sys: { __typename?: 'Sys'; id: string }
                    }
                  | null
                >
              }
            }
          } | null
        }
      | null
    >
  } | null
}

export type VideoSectionItemCardFragment = {
  __typename: 'CaisiqVideo'
  title?: string | null
  category?: string | null
  summary?: string | null
  duration?: number | null
  displayDate?: any | null
  muxVideo?: any | null
  poster?: any | null
  icon?: any | null
  muxThumbnailTime?: number | null
  description?: string | null
  sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
  thumbnail?: {
    __typename?: 'Asset'
    url?: string | null
    width?: number | null
    height?: number | null
  } | null
  video?: { __typename?: 'Asset'; url?: string | null } | null
}

export type GetHomepageLandingPageQueryVariables = Exact<{
  slug: Scalars['String']
  preview?: InputMaybe<Scalars['Boolean']>
}>

export type GetHomepageLandingPageQuery = {
  __typename?: 'Query'
  homepageLandingPageCollection?: {
    __typename?: 'HomepageLandingPageCollection'
    total: number
    items: Array<{
      __typename?: 'HomepageLandingPage'
      title?: string | null
      icon?: string | null
      sectionsCollection?: {
        __typename?: 'HomepageLandingPageSectionsCollection'
        items: Array<{
          __typename: 'HomepageContentSection'
          title?: string | null
          bookmarkId?: string | null
          removeTopPadding?: boolean | null
          componentId?: string | null
          componentSectionDescription?: string | null
          sys: { __typename?: 'Sys'; id: string }
          itemsCollection?: {
            __typename?: 'HomepageContentSectionItemsCollection'
            items: Array<
              | {
                  __typename: 'CaisiqVideo'
                  title?: string | null
                  category?: string | null
                  summary?: string | null
                  duration?: number | null
                  displayDate?: any | null
                  muxVideo?: any | null
                  poster?: any | null
                  icon?: any | null
                  muxThumbnailTime?: number | null
                  description?: string | null
                  sys: {
                    __typename?: 'Sys'
                    id: string
                    firstPublishedAt?: any | null
                  }
                  thumbnail?: {
                    __typename?: 'Asset'
                    url?: string | null
                    width?: number | null
                    height?: number | null
                  } | null
                  video?: { __typename?: 'Asset'; url?: string | null } | null
                }
              | {
                  __typename: 'CallToAction'
                  title?: string | null
                  id?: string | null
                  description?: string | null
                  buttonText?: string | null
                  imageSet?: any | null
                  byCais?: boolean | null
                  sys: { __typename?: 'Sys'; id: string }
                  destination?: {
                    __typename?: 'Link'
                    openInNewWindow?: boolean | null
                    externalLink?: string | null
                    internalLink?: string | null
                    cmsLink?:
                      | { __typename?: 'CaisiqArticle' }
                      | { __typename?: 'HomepageLandingPage' }
                      | { __typename?: 'Page' }
                      | { __typename: 'PageResource'; slug?: string | null }
                      | { __typename?: 'PwsPressRelease' }
                      | { __typename?: 'Whitepaper' }
                      | null
                  } | null
                  mediaFile?: {
                    __typename?: 'Asset'
                    url?: string | null
                    fileName?: string | null
                  } | null
                  firm?: {
                    __typename?: 'MemberFirm'
                    id?: string | null
                    name?: string | null
                  } | null
                }
              | {
                  __typename: 'ComponentImage'
                  title?: string | null
                  cloudinaryImage?: any | null
                  sys: { __typename?: 'Sys'; id: string }
                }
              | {
                  __typename: 'ComponentText'
                  title?: string | null
                  brandIcon?: string | null
                  sys: { __typename?: 'Sys'; id: string }
                  text?: {
                    __typename?: 'ComponentTextText'
                    json: any
                    links: {
                      __typename?: 'ComponentTextTextLinks'
                      entries: {
                        __typename?: 'ComponentTextTextEntries'
                        hyperlink: Array<
                          | { __typename?: 'AccessControl' }
                          | { __typename?: 'AccessControl2' }
                          | { __typename?: 'AccessControl3' }
                          | { __typename?: 'Accordion' }
                          | { __typename?: 'AccordionWithImage' }
                          | { __typename?: 'Announcement' }
                          | { __typename?: 'AnnouncementSet' }
                          | { __typename?: 'CaisiqArticle' }
                          | { __typename?: 'CaisiqArticleDropdown' }
                          | { __typename?: 'CaisiqAuthor' }
                          | { __typename?: 'CaisiqDefaultCourseImageSet' }
                          | { __typename?: 'CaisiqDefaultFundsThumbnailSet' }
                          | { __typename?: 'CaisiqFundThumbnail' }
                          | { __typename?: 'CaisiqFundThumbnailSet' }
                          | { __typename?: 'CaisiqHeroHeader' }
                          | { __typename?: 'CaisiqHeroHeaderNavigateToButton' }
                          | { __typename?: 'CaisiqHomeOffice' }
                          | { __typename?: 'CaisiqJoinCaisCallToAction' }
                          | { __typename?: 'CaisiqTab' }
                          | { __typename?: 'CaisiqVideo' }
                          | { __typename?: 'CaisiqVideoPlaylist' }
                          | { __typename?: 'CallToAction' }
                          | { __typename?: 'Chart' }
                          | { __typename?: 'ChildFirm' }
                          | { __typename?: 'CiqArticle' }
                          | { __typename?: 'Company' }
                          | { __typename?: 'CompanyAndContactInfo' }
                          | { __typename?: 'ComponentCard' }
                          | { __typename?: 'ComponentCompanyContainer' }
                          | { __typename?: 'ComponentHero' }
                          | { __typename?: 'ComponentHubspotForm' }
                          | { __typename?: 'ComponentImage' }
                          | { __typename?: 'ComponentPodcastPlayer' }
                          | { __typename?: 'ComponentSection' }
                          | { __typename?: 'ComponentSummary' }
                          | { __typename?: 'ComponentTeamContainer' }
                          | { __typename?: 'ComponentText' }
                          | { __typename?: 'ComponentTextIllustration' }
                          | { __typename?: 'ComponentTextImage' }
                          | { __typename?: 'ComponentTextImageImageSection' }
                          | { __typename?: 'ComponentTextImageListSection' }
                          | { __typename?: 'ComponentTextImageTextSection' }
                          | { __typename?: 'CorporateStats' }
                          | { __typename?: 'CuratedFilterGroup' }
                          | { __typename?: 'CuratedResearchContent' }
                          | { __typename?: 'Disclaimer' }
                          | { __typename?: 'Event' }
                          | { __typename?: 'ExternalContent' }
                          | { __typename?: 'Faq' }
                          | { __typename?: 'FaqCategory' }
                          | { __typename?: 'FeaturedContent' }
                          | { __typename?: 'FilterGroup' }
                          | { __typename?: 'Firm' }
                          | { __typename?: 'FullWidthImage' }
                          | { __typename?: 'FundProductId' }
                          | { __typename?: 'GlossaryTerm' }
                          | { __typename?: 'HomepageAppMetadata' }
                          | { __typename?: 'HomepageContentSection' }
                          | { __typename?: 'HomepageLandingPage' }
                          | { __typename?: 'HtmlTable' }
                          | { __typename?: 'ImageSection' }
                          | { __typename?: 'LegalPage' }
                          | { __typename?: 'Link' }
                          | { __typename?: 'LoginScreen' }
                          | { __typename?: 'Markdown' }
                          | { __typename?: 'MemberFirm' }
                          | { __typename?: 'Migration' }
                          | { __typename?: 'NavHeaderTheme' }
                          | {
                              __typename?: 'Page'
                              name?: string | null
                              slug?: string | null
                              sys: { __typename?: 'Sys'; id: string }
                              content?:
                                | { __typename: 'CaisiqArticle' }
                                | { __typename: 'CaisiqVideo' }
                                | { __typename: 'CaisiqVideoPlaylist' }
                                | { __typename: 'Company' }
                                | { __typename: 'PageHelpCenterArticle' }
                                | { __typename: 'PageLanding' }
                                | { __typename: 'PagePwsCategory' }
                                | { __typename: 'Person' }
                                | { __typename: 'PwsPressRelease' }
                                | { __typename: 'Whitepaper' }
                                | null
                            }
                          | { __typename?: 'PageHelpCenterArticle' }
                          | { __typename?: 'PageLanding' }
                          | { __typename?: 'PagePwsCategory' }
                          | { __typename?: 'PageResource' }
                          | { __typename?: 'ParentFirm' }
                          | { __typename?: 'Pdf' }
                          | { __typename?: 'Person' }
                          | { __typename?: 'Pet' }
                          | { __typename?: 'PlaylistContentSet' }
                          | { __typename?: 'Presentation' }
                          | { __typename?: 'PressAward' }
                          | { __typename?: 'PwsContactMedia' }
                          | { __typename?: 'PwsInsightsContentSet' }
                          | { __typename?: 'PwsPressRelease' }
                          | { __typename?: 'PwsSiteSettings' }
                          | { __typename?: 'Quote' }
                          | { __typename?: 'RelatedContent' }
                          | { __typename?: 'RelatedContentStrategy' }
                          | { __typename?: 'Seo' }
                          | { __typename?: 'SkPlayground' }
                          | { __typename?: 'Tag' }
                          | { __typename?: 'TagFamily' }
                          | { __typename?: 'Team' }
                          | { __typename?: 'TestModel' }
                          | { __typename?: 'Theme' }
                          | { __typename?: 'Whitepaper' }
                          | null
                        >
                        inline: Array<
                          | {
                              __typename: 'AccessControl'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'AccessControl2'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'AccessControl3'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Accordion'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'AccordionWithImage'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Announcement'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'AnnouncementSet'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'CaisiqArticle'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'CaisiqArticleDropdown'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'CaisiqAuthor'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'CaisiqDefaultCourseImageSet'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'CaisiqDefaultFundsThumbnailSet'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'CaisiqFundThumbnail'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'CaisiqFundThumbnailSet'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'CaisiqHeroHeader'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'CaisiqHeroHeaderNavigateToButton'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'CaisiqHomeOffice'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'CaisiqJoinCaisCallToAction'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'CaisiqTab'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'CaisiqVideo'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'CaisiqVideoPlaylist'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'CallToAction'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Chart'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'ChildFirm'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'CiqArticle'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Company'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'CompanyAndContactInfo'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'ComponentCard'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'ComponentCompanyContainer'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'ComponentHero'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'ComponentHubspotForm'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'ComponentImage'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'ComponentPodcastPlayer'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'ComponentSection'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'ComponentSummary'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'ComponentTeamContainer'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'ComponentText'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'ComponentTextIllustration'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'ComponentTextImage'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'ComponentTextImageImageSection'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'ComponentTextImageListSection'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'ComponentTextImageTextSection'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'CorporateStats'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'CuratedFilterGroup'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'CuratedResearchContent'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Disclaimer'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Event'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'ExternalContent'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Faq'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'FaqCategory'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'FeaturedContent'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'FilterGroup'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Firm'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'FullWidthImage'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'FundProductId'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'GlossaryTerm'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'HomepageAppMetadata'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'HomepageContentSection'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'HomepageLandingPage'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'HtmlTable'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'ImageSection'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'LegalPage'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Link'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'LoginScreen'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Markdown'
                              text?: string | null
                              type?: string | null
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'MemberFirm'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Migration'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'NavHeaderTheme'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Page'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'PageHelpCenterArticle'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'PageLanding'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'PagePwsCategory'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'PageResource'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'ParentFirm'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Pdf'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Person'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Pet'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'PlaylistContentSet'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Presentation'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'PressAward'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'PwsContactMedia'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'PwsInsightsContentSet'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'PwsPressRelease'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'PwsSiteSettings'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Quote'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'RelatedContent'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'RelatedContentStrategy'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Seo'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'SkPlayground'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Tag'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'TagFamily'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Team'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'TestModel'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Theme'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | {
                              __typename: 'Whitepaper'
                              sys: { __typename?: 'Sys'; id: string }
                            }
                          | null
                        >
                      }
                    }
                  } | null
                }
              | null
            >
          } | null
        } | null>
      } | null
      disclaimer?: {
        __typename?: 'Disclaimer'
        title?: string | null
        body?: { __typename?: 'DisclaimerBody'; json: any } | null
      } | null
    } | null>
  } | null
}

export type HomepageLandingPageFragment = {
  __typename?: 'HomepageLandingPage'
  title?: string | null
  icon?: string | null
  sectionsCollection?: {
    __typename?: 'HomepageLandingPageSectionsCollection'
    items: Array<{
      __typename: 'HomepageContentSection'
      title?: string | null
      bookmarkId?: string | null
      removeTopPadding?: boolean | null
      componentId?: string | null
      componentSectionDescription?: string | null
      sys: { __typename?: 'Sys'; id: string }
      itemsCollection?: {
        __typename?: 'HomepageContentSectionItemsCollection'
        items: Array<
          | {
              __typename: 'CaisiqVideo'
              title?: string | null
              category?: string | null
              summary?: string | null
              duration?: number | null
              displayDate?: any | null
              muxVideo?: any | null
              poster?: any | null
              icon?: any | null
              muxThumbnailTime?: number | null
              description?: string | null
              sys: {
                __typename?: 'Sys'
                id: string
                firstPublishedAt?: any | null
              }
              thumbnail?: {
                __typename?: 'Asset'
                url?: string | null
                width?: number | null
                height?: number | null
              } | null
              video?: { __typename?: 'Asset'; url?: string | null } | null
            }
          | {
              __typename: 'CallToAction'
              title?: string | null
              id?: string | null
              description?: string | null
              buttonText?: string | null
              imageSet?: any | null
              byCais?: boolean | null
              sys: { __typename?: 'Sys'; id: string }
              destination?: {
                __typename?: 'Link'
                openInNewWindow?: boolean | null
                externalLink?: string | null
                internalLink?: string | null
                cmsLink?:
                  | { __typename?: 'CaisiqArticle' }
                  | { __typename?: 'HomepageLandingPage' }
                  | { __typename?: 'Page' }
                  | { __typename: 'PageResource'; slug?: string | null }
                  | { __typename?: 'PwsPressRelease' }
                  | { __typename?: 'Whitepaper' }
                  | null
              } | null
              mediaFile?: {
                __typename?: 'Asset'
                url?: string | null
                fileName?: string | null
              } | null
              firm?: {
                __typename?: 'MemberFirm'
                id?: string | null
                name?: string | null
              } | null
            }
          | {
              __typename: 'ComponentImage'
              title?: string | null
              cloudinaryImage?: any | null
              sys: { __typename?: 'Sys'; id: string }
            }
          | {
              __typename: 'ComponentText'
              title?: string | null
              brandIcon?: string | null
              sys: { __typename?: 'Sys'; id: string }
              text?: {
                __typename?: 'ComponentTextText'
                json: any
                links: {
                  __typename?: 'ComponentTextTextLinks'
                  entries: {
                    __typename?: 'ComponentTextTextEntries'
                    hyperlink: Array<
                      | { __typename?: 'AccessControl' }
                      | { __typename?: 'AccessControl2' }
                      | { __typename?: 'AccessControl3' }
                      | { __typename?: 'Accordion' }
                      | { __typename?: 'AccordionWithImage' }
                      | { __typename?: 'Announcement' }
                      | { __typename?: 'AnnouncementSet' }
                      | { __typename?: 'CaisiqArticle' }
                      | { __typename?: 'CaisiqArticleDropdown' }
                      | { __typename?: 'CaisiqAuthor' }
                      | { __typename?: 'CaisiqDefaultCourseImageSet' }
                      | { __typename?: 'CaisiqDefaultFundsThumbnailSet' }
                      | { __typename?: 'CaisiqFundThumbnail' }
                      | { __typename?: 'CaisiqFundThumbnailSet' }
                      | { __typename?: 'CaisiqHeroHeader' }
                      | { __typename?: 'CaisiqHeroHeaderNavigateToButton' }
                      | { __typename?: 'CaisiqHomeOffice' }
                      | { __typename?: 'CaisiqJoinCaisCallToAction' }
                      | { __typename?: 'CaisiqTab' }
                      | { __typename?: 'CaisiqVideo' }
                      | { __typename?: 'CaisiqVideoPlaylist' }
                      | { __typename?: 'CallToAction' }
                      | { __typename?: 'Chart' }
                      | { __typename?: 'ChildFirm' }
                      | { __typename?: 'CiqArticle' }
                      | { __typename?: 'Company' }
                      | { __typename?: 'CompanyAndContactInfo' }
                      | { __typename?: 'ComponentCard' }
                      | { __typename?: 'ComponentCompanyContainer' }
                      | { __typename?: 'ComponentHero' }
                      | { __typename?: 'ComponentHubspotForm' }
                      | { __typename?: 'ComponentImage' }
                      | { __typename?: 'ComponentPodcastPlayer' }
                      | { __typename?: 'ComponentSection' }
                      | { __typename?: 'ComponentSummary' }
                      | { __typename?: 'ComponentTeamContainer' }
                      | { __typename?: 'ComponentText' }
                      | { __typename?: 'ComponentTextIllustration' }
                      | { __typename?: 'ComponentTextImage' }
                      | { __typename?: 'ComponentTextImageImageSection' }
                      | { __typename?: 'ComponentTextImageListSection' }
                      | { __typename?: 'ComponentTextImageTextSection' }
                      | { __typename?: 'CorporateStats' }
                      | { __typename?: 'CuratedFilterGroup' }
                      | { __typename?: 'CuratedResearchContent' }
                      | { __typename?: 'Disclaimer' }
                      | { __typename?: 'Event' }
                      | { __typename?: 'ExternalContent' }
                      | { __typename?: 'Faq' }
                      | { __typename?: 'FaqCategory' }
                      | { __typename?: 'FeaturedContent' }
                      | { __typename?: 'FilterGroup' }
                      | { __typename?: 'Firm' }
                      | { __typename?: 'FullWidthImage' }
                      | { __typename?: 'FundProductId' }
                      | { __typename?: 'GlossaryTerm' }
                      | { __typename?: 'HomepageAppMetadata' }
                      | { __typename?: 'HomepageContentSection' }
                      | { __typename?: 'HomepageLandingPage' }
                      | { __typename?: 'HtmlTable' }
                      | { __typename?: 'ImageSection' }
                      | { __typename?: 'LegalPage' }
                      | { __typename?: 'Link' }
                      | { __typename?: 'LoginScreen' }
                      | { __typename?: 'Markdown' }
                      | { __typename?: 'MemberFirm' }
                      | { __typename?: 'Migration' }
                      | { __typename?: 'NavHeaderTheme' }
                      | {
                          __typename?: 'Page'
                          name?: string | null
                          slug?: string | null
                          sys: { __typename?: 'Sys'; id: string }
                          content?:
                            | { __typename: 'CaisiqArticle' }
                            | { __typename: 'CaisiqVideo' }
                            | { __typename: 'CaisiqVideoPlaylist' }
                            | { __typename: 'Company' }
                            | { __typename: 'PageHelpCenterArticle' }
                            | { __typename: 'PageLanding' }
                            | { __typename: 'PagePwsCategory' }
                            | { __typename: 'Person' }
                            | { __typename: 'PwsPressRelease' }
                            | { __typename: 'Whitepaper' }
                            | null
                        }
                      | { __typename?: 'PageHelpCenterArticle' }
                      | { __typename?: 'PageLanding' }
                      | { __typename?: 'PagePwsCategory' }
                      | { __typename?: 'PageResource' }
                      | { __typename?: 'ParentFirm' }
                      | { __typename?: 'Pdf' }
                      | { __typename?: 'Person' }
                      | { __typename?: 'Pet' }
                      | { __typename?: 'PlaylistContentSet' }
                      | { __typename?: 'Presentation' }
                      | { __typename?: 'PressAward' }
                      | { __typename?: 'PwsContactMedia' }
                      | { __typename?: 'PwsInsightsContentSet' }
                      | { __typename?: 'PwsPressRelease' }
                      | { __typename?: 'PwsSiteSettings' }
                      | { __typename?: 'Quote' }
                      | { __typename?: 'RelatedContent' }
                      | { __typename?: 'RelatedContentStrategy' }
                      | { __typename?: 'Seo' }
                      | { __typename?: 'SkPlayground' }
                      | { __typename?: 'Tag' }
                      | { __typename?: 'TagFamily' }
                      | { __typename?: 'Team' }
                      | { __typename?: 'TestModel' }
                      | { __typename?: 'Theme' }
                      | { __typename?: 'Whitepaper' }
                      | null
                    >
                    inline: Array<
                      | {
                          __typename: 'AccessControl'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'AccessControl2'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'AccessControl3'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Accordion'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'AccordionWithImage'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Announcement'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'AnnouncementSet'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'CaisiqArticle'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'CaisiqArticleDropdown'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'CaisiqAuthor'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'CaisiqDefaultCourseImageSet'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'CaisiqDefaultFundsThumbnailSet'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'CaisiqFundThumbnail'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'CaisiqFundThumbnailSet'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'CaisiqHeroHeader'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'CaisiqHeroHeaderNavigateToButton'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'CaisiqHomeOffice'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'CaisiqJoinCaisCallToAction'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'CaisiqTab'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'CaisiqVideo'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'CaisiqVideoPlaylist'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'CallToAction'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Chart'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'ChildFirm'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'CiqArticle'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Company'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'CompanyAndContactInfo'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'ComponentCard'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'ComponentCompanyContainer'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'ComponentHero'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'ComponentHubspotForm'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'ComponentImage'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'ComponentPodcastPlayer'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'ComponentSection'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'ComponentSummary'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'ComponentTeamContainer'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'ComponentText'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'ComponentTextIllustration'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'ComponentTextImage'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'ComponentTextImageImageSection'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'ComponentTextImageListSection'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'ComponentTextImageTextSection'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'CorporateStats'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'CuratedFilterGroup'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'CuratedResearchContent'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Disclaimer'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Event'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'ExternalContent'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Faq'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'FaqCategory'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'FeaturedContent'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'FilterGroup'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Firm'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'FullWidthImage'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'FundProductId'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'GlossaryTerm'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'HomepageAppMetadata'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'HomepageContentSection'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'HomepageLandingPage'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'HtmlTable'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'ImageSection'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'LegalPage'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Link'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'LoginScreen'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Markdown'
                          text?: string | null
                          type?: string | null
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'MemberFirm'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Migration'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'NavHeaderTheme'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Page'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'PageHelpCenterArticle'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'PageLanding'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'PagePwsCategory'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'PageResource'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'ParentFirm'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Pdf'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Person'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Pet'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'PlaylistContentSet'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Presentation'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'PressAward'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'PwsContactMedia'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'PwsInsightsContentSet'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'PwsPressRelease'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'PwsSiteSettings'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Quote'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'RelatedContent'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'RelatedContentStrategy'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Seo'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'SkPlayground'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Tag'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'TagFamily'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Team'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'TestModel'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Theme'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | {
                          __typename: 'Whitepaper'
                          sys: { __typename?: 'Sys'; id: string }
                        }
                      | null
                    >
                  }
                }
              } | null
            }
          | null
        >
      } | null
    } | null>
  } | null
  disclaimer?: {
    __typename?: 'Disclaimer'
    title?: string | null
    body?: { __typename?: 'DisclaimerBody'; json: any } | null
  } | null
}

export type GetTermsAndConditionsLegalPageBySlugQueryVariables = Exact<{
  slug: Scalars['String']
  preview?: InputMaybe<Scalars['Boolean']>
}>

export type GetTermsAndConditionsLegalPageBySlugQuery = {
  __typename?: 'Query'
  legalPageCollection?: {
    __typename?: 'LegalPageCollection'
    items: Array<{
      __typename?: 'LegalPage'
      slug?: string | null
      sys: { __typename?: 'Sys'; id: string }
      pdfsCollection?: {
        __typename?: 'LegalPagePdfsCollection'
        items: Array<{
          __typename: 'Pdf'
          title?: string | null
          sys: { __typename?: 'Sys'; id: string }
          pdf?: { __typename?: 'Asset'; url?: string | null } | null
        } | null>
      } | null
    } | null>
  } | null
}

export type GetAllVideosQueryVariables = Exact<{
  limit: Scalars['Int']
  preview?: InputMaybe<Scalars['Boolean']>
  wherePlaylist?: InputMaybe<CaisiqVideoPlaylistFilter>
  whereVideo?: InputMaybe<CaisiqVideoFilter>
  orderPlaylist?: InputMaybe<CaisiqVideoPlaylistOrder>
  orderVideo?: InputMaybe<CaisiqVideoOrder>
  firmsLimit: Scalars['Int']
}>

export type GetAllVideosQuery = {
  __typename?: 'Query'
  caisiqVideoPlaylistCollection?: {
    __typename?: 'CaisiqVideoPlaylistCollection'
    items: Array<{
      __typename: 'CaisiqVideoPlaylist'
      episodic?: boolean | null
      title?: string | null
      category?: string | null
      summary?: string | null
      displayDate?: any | null
      byCais?: boolean | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      heroImage?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoPlaylistFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      videosCollection?: {
        __typename?: 'CaisiqVideoPlaylistVideosCollection'
        items: Array<{
          __typename?: 'CaisiqVideo'
          poster?: any | null
          sys: { __typename?: 'Sys'; id: string }
          thumbnail?: {
            __typename?: 'Asset'
            url?: string | null
            width?: number | null
            height?: number | null
          } | null
        } | null>
      } | null
    } | null>
  } | null
  caisiqVideoCollection?: {
    __typename?: 'CaisiqVideoCollection'
    total: number
    items: Array<{
      __typename: 'CaisiqVideo'
      byCais?: boolean | null
      title?: string | null
      category?: string | null
      summary?: string | null
      duration?: number | null
      displayDate?: any | null
      muxVideo?: any | null
      poster?: any | null
      icon?: any | null
      muxThumbnailTime?: number | null
      description?: string | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      fundProductIds?: {
        __typename?: 'FundProductId'
        fundProductIds?: Array<string | null> | null
      } | null
      firmsCollection?: {
        __typename?: 'CaisiqVideoFirmsCollection'
        items: Array<{
          __typename?: 'MemberFirm'
          id?: string | null
          name?: string | null
        } | null>
      } | null
      thumbnail?: {
        __typename?: 'Asset'
        url?: string | null
        width?: number | null
        height?: number | null
      } | null
      video?: { __typename?: 'Asset'; url?: string | null } | null
    } | null>
  } | null
}

export type GetVideoPlaylistBySlugQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']>
}>

export type GetVideoPlaylistBySlugQuery = {
  __typename?: 'Query'
  caisiqVideoPlaylistCollection?: {
    __typename?: 'CaisiqVideoPlaylistCollection'
    items: Array<{
      __typename?: 'CaisiqVideoPlaylist'
      episodic?: boolean | null
      title?: string | null
      summary?: string | null
      category?: string | null
      displayDate?: any | null
      sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
      heroImage?: { __typename?: 'Asset'; url?: string | null } | null
      videosCollection?: {
        __typename?: 'CaisiqVideoPlaylistVideosCollection'
        items: Array<{
          __typename?: 'CaisiqVideo'
          muxThumbnailTime?: number | null
          title?: string | null
          summary?: string | null
          category?: string | null
          duration?: number | null
          displayDate?: any | null
          muxVideo?: any | null
          poster?: any | null
          sys: { __typename?: 'Sys'; id: string; firstPublishedAt?: any | null }
          thumbnail?: {
            __typename?: 'Asset'
            url?: string | null
            width?: number | null
            height?: number | null
          } | null
          video?: { __typename?: 'Asset'; url?: string | null } | null
        } | null>
      } | null
    } | null>
  } | null
}

export const ArticleCardFragmentDoc = `
    fragment articleCard on CaisiqArticle {
  __typename
  sys {
    id
    firstPublishedAt
    publishedAt
  }
  fundProductIds {
    fundProductIds
  }
  slug
  title
  category
  displayDate
  description
  thumbnail {
    url
    width
    height
  }
  author {
    name
  }
  introduction
  firmsCollection(limit: $firmsLimit) {
    items {
      id
      name
    }
  }
  byCais
}
    `
export const AuthorFragmentDoc = `
    fragment author on CaisiqAuthor {
  name
  authorName
  jobTitle
  firm
  avatar {
    altText
    image {
      url
      title
      width
      height
    }
  }
  linkedin {
    externalLink
  }
  imageSet
}
    `
export const TagFragmentDoc = `
    fragment tag on Tag {
  tagId
  label
}
    `
export const ChartFragmentDoc = `
    fragment chart on Chart {
  __typename
  chartImage {
    altText
    image {
      ... on Asset {
        url
        height
        width
        title
        alt: description
      }
    }
    cloudinaryImage
    name
  }
  footnote {
    json
  }
  longDescription {
    json
  }
  longSource
  title
}
    `
export const ArticlePageFragmentDoc = `
    fragment articlePage on CaisiqArticle {
  customTitle
  customDescription
  ...articleCard
  hero {
    url
  }
  author {
    name
    authorName
    jobTitle
    firm
    avatar {
      altText
      image {
        url
        title
        width
        height
      }
    }
    linkedin {
      externalLink
    }
  }
  authorsCollection {
    items {
      ...author
    }
  }
  introduction
  introductionRichText {
    json
  }
  tagsCollection {
    items {
      ...tag
    }
  }
  body {
    json
    links {
      entries {
        hyperlink {
          __typename
          sys {
            id
          }
          ... on CaisiqArticle {
            slug
          }
          ... on Whitepaper {
            slug
          }
          ... on PwsPressRelease {
            slug
          }
        }
        block {
          sys {
            id
            firstPublishedAt
          }
          __typename
          ... on CaisiqArticle {
            title
            slug
            displayDate
            author {
              name
            }
            thumbnail {
              url
            }
          }
          ... on Whitepaper {
            slug
            title
            displayDate
            author {
              name
            }
            thumbnail {
              url
            }
          }
          ... on CaisiqVideo {
            muxVideo
            title
            video {
              url
            }
          }
          ... on Chart {
            ...chart
          }
          ... on Pdf {
            __typename
            title
            pdf {
              url
            }
          }
          ... on ComponentImage {
            cloudinaryImage
            name
          }
        }
      }
      assets {
        block {
          sys {
            id
          }
          url
          title
          width
          height
          description
          fileName
          contentType
        }
      }
    }
  }
  footnotes {
    json
    links {
      entries {
        hyperlink {
          __typename
          sys {
            id
          }
          ... on CaisiqArticle {
            slug
          }
          ... on Whitepaper {
            slug
          }
          ... on PwsPressRelease {
            slug
          }
        }
        block {
          sys {
            id
            firstPublishedAt
          }
          __typename
          ... on CaisiqArticle {
            title
            slug
            displayDate
            author {
              name
            }
            thumbnail {
              url
            }
          }
          ... on Whitepaper {
            slug
            title
            displayDate
            author {
              name
            }
            thumbnail {
              url
            }
          }
        }
      }
      assets {
        block {
          sys {
            id
          }
          url
          title
          width
          height
          description
          fileName
          contentType
        }
      }
    }
  }
  accordionsCollection {
    items {
      body {
        json
        links {
          entries {
            hyperlink {
              __typename
              sys {
                id
              }
              ... on CaisiqArticle {
                slug
              }
              ... on Whitepaper {
                slug
              }
              ... on PwsPressRelease {
                slug
              }
            }
            block {
              sys {
                id
                firstPublishedAt
              }
              __typename
              ... on CaisiqArticle {
                title
                slug
                displayDate
                author {
                  name
                }
                thumbnail {
                  url
                }
              }
              ... on Whitepaper {
                slug
                title
                displayDate
                author {
                  name
                }
                thumbnail {
                  url
                }
              }
            }
          }
          assets {
            block {
              sys {
                id
              }
              url
              title
              width
              height
              description
              fileName
              contentType
            }
          }
        }
      }
      title {
        json
      }
    }
  }
  callToAction {
    __typename
    title
    description
    buttonText
    destination {
      cmsLink {
        ... on Page {
          content {
            __typename
          }
          slug
        }
      }
      internalLink
      externalLink
      openInNewWindow
    }
    mediaFile {
      url
    }
  }
  caisVideo {
    muxVideo
  }
}
    ${ArticleCardFragmentDoc}
${AuthorFragmentDoc}
${TagFragmentDoc}
${ChartFragmentDoc}`
export const DisclaimerFragmentDoc = `
    fragment disclaimer on Disclaimer {
  title
  body {
    json
  }
}
    `
export const EventCardFragmentDoc = `
    fragment eventCard on Event {
  __typename
  allDay
  date: dateTime
  displayDate: dateTime
  dateTimeEnd
  description
  eventThumbnail
  eventType
  fundProductIds {
    fundProductIds
  }
  firmsCollection(limit: $firmsLimit) {
    items {
      id
      name
    }
  }
  byCais
  link
  locationName
  status
  sys {
    id
  }
  tagsCollection(limit: $tagsLimit) {
    items {
      tagId
    }
  }
  timezone
  title
}
    `
export const ExternalContentCardFragmentDoc = `
    fragment externalContentCard on ExternalContent {
  sys {
    id
    firstPublishedAt
  }
  __typename
  type
  title
  tagsCollection(limit: 5) {
    items {
      ... on Tag {
        label
        tagId
      }
    }
  }
  displayDate
  description
  externalId
  externalLink
  strategy
  subStrategy
  eligibility
  fundProductIds {
    fundProductIds
  }
  firmsCollection(limit: $firmsLimit) {
    items {
      id
      name
    }
  }
  byCais
  minimumInitialInvestment
  thumbnail {
    url
    width
    height
    alt: description
  }
}
    `
export const ArticleCuratedContentCardFragmentDoc = `
    fragment articleCuratedContentCard on CaisiqArticle {
  sys {
    id
  }
  customTitle
  customDescription
  description
  title
  displayDate
  __typename
  slug
  fundProductIds {
    fundProductIds
  }
  firmsCollection(limit: $firmsLimit) {
    items {
      id
      name
    }
  }
  byCais
}
    `
export const WhitepaperCuratedContentCardFragmentDoc = `
    fragment whitepaperCuratedContentCard on Whitepaper {
  sys {
    id
  }
  customTitle
  customDescription
  description: introduction
  title
  displayDate
  __typename
  slug
  fundProductIds {
    fundProductIds
  }
  firmsCollection(limit: $firmsLimit) {
    items {
      id
      name
    }
  }
  byCais
}
    `
export const VideoCuratedContentCardFragmentDoc = `
    fragment videoCuratedContentCard on CaisiqVideo {
  sys {
    id
  }
  customTitle
  customDescription
  category
  description: summary
  title
  displayDate
  fundProductIds {
    fundProductIds
  }
  firmsCollection(limit: $firmsLimit) {
    items {
      id
      name
    }
  }
  byCais
  __typename
}
    `
export const VideoPlaylistCuratedContentCardFragmentDoc = `
    fragment videoPlaylistCuratedContentCard on CaisiqVideoPlaylist {
  sys {
    id
  }
  customTitle
  customDescription
  category
  description: summary
  title
  displayDate
  fundProductIds {
    fundProductIds
  }
  firmsCollection(limit: $firmsLimit) {
    items {
      id
      name
    }
  }
  byCais
  videosCollection(limit: 1, where: {sys: {id_exists: true}}) {
    items {
      sys {
        id
      }
    }
  }
  __typename
}
    `
export const ExternalCuratedContentCardFragmentDoc = `
    fragment externalCuratedContentCard on ExternalContent {
  sys {
    id
  }
  type
  externalLink
  externalId
  customTitle
  customDescription
  description
  title
  displayDate
  fundProductIds {
    fundProductIds
  }
  firmsCollection(limit: $firmsLimit) {
    items {
      id
      name
    }
  }
  byCais
  __typename
}
    `
export const LegalPageFragmentDoc = `
    fragment legalPage on LegalPage {
  sys {
    id
  }
  title
  subtitle
  slug
  text {
    json
  }
  pdfsCollection {
    items {
      sys {
        id
      }
      __typename
      title
      displayDate
      pdf {
        title
        description
        contentType
        fileName
        size
        url
      }
    }
  }
}
    `
export const PressReleaseCardFragmentDoc = `
    fragment pressReleaseCard on PwsPressRelease {
  __typename
  sys {
    id
  }
  author {
    name
  }
  description
  displayDate
  externalNewsLink
  title
  thumbnail {
    url
    height
    width
    title
    alt: description
  }
  type
  linkedFrom {
    pageCollection(limit: 1) {
      items {
        slug
        title
      }
    }
  }
}
    `
export const SysFragmentDoc = `
    fragment sys on Sys {
  id
  firstPublishedAt
}
    `
export const EntryFragmentDoc = `
    fragment entry on Entry {
  sys {
    ...sys
  }
}
    ${SysFragmentDoc}`
export const AssetFragmentDoc = `
    fragment asset on Asset {
  alt: description
  height
  title
  url
  width
  sys {
    id
  }
}
    `
export const ComponentImageFragmentDoc = `
    fragment componentImage on ComponentImage {
  title
  cloudinaryImage
  __typename
  sys {
    id
  }
  altText
  image {
    ... on Asset {
      ...asset
    }
  }
}
    ${AssetFragmentDoc}`
export const VideoCardFragmentDoc = `
    fragment videoCard on CaisiqVideo {
  sys {
    id
    firstPublishedAt
  }
  fundProductIds {
    fundProductIds
  }
  firmsCollection(limit: $firmsLimit) {
    items {
      id
      name
    }
  }
  byCais
  __typename
  title
  category
  summary
  description: summary
  duration
  thumbnail {
    url
    width
    height
  }
  displayDate
  muxVideo
  poster
  icon
  muxThumbnailTime
  video {
    url
  }
}
    `
export const ComponentTextFragmentDoc = `
    fragment componentText on ComponentText {
  __typename
  sys {
    id
  }
  brandIcon: icon
  title
  text {
    json
    links {
      entries {
        hyperlink {
          ... on Page {
            sys {
              id
            }
            name
            slug
            content {
              __typename
            }
          }
        }
        inline {
          __typename
          sys {
            id
          }
          ... on Markdown {
            text
            type
          }
        }
      }
    }
  }
}
    `
export const CallToActionFieldsFragmentDoc = `
    fragment callToActionFields on CallToAction {
  sys {
    id
  }
  __typename
  title
  id
  description
  buttonText
  destination {
    openInNewWindow
    cmsLink {
      ... on PageResource {
        __typename
        slug
      }
    }
    externalLink
    internalLink
  }
  imageSet
  byCais
  mediaFile {
    url
    fileName
  }
  firm {
    id
    name
  }
}
    `
export const ComponentSectionFragmentDoc = `
    fragment componentSection on ComponentSection {
  __typename
  sys {
    id
  }
  componentId
  title: name
  cardContainerOptions
  componentSectionDescription: description {
    json
    links {
      entries {
        hyperlink {
          ... on Page {
            sys {
              id
            }
            name
            slug
            content {
              __typename
            }
          }
        }
        inline {
          __typename
          sys {
            id
          }
          ... on Markdown {
            text
            type
          }
        }
      }
    }
  }
  itemsCollection {
    items {
      ... on CaisiqVideo {
        ...videoCard
      }
      ... on ComponentText {
        ...componentText
      }
      ... on CallToAction {
        ...callToActionFields
      }
      ... on ComponentCard {
        sys {
          id
        }
      }
      ... on Entry {
        sys {
          id
        }
      }
    }
  }
}
    ${VideoCardFragmentDoc}
${ComponentTextFragmentDoc}
${CallToActionFieldsFragmentDoc}`
export const LinkCardFragmentDoc = `
    fragment linkCard on Link {
  sys {
    id
  }
  openInNewWindow
  description
  __typename
  internalLink
  cmsLink {
    ... on PageResource {
      __typename
      slug
    }
    ... on HomepageLandingPage {
      __typename
      slug
    }
  }
  byCais
  poster: thumbnail
  title
}
    `
export const PdfFragmentDoc = `
    fragment pdf on Pdf {
  __typename
  sys {
    id
    firstPublishedAt
  }
  title
  displayDate
  image {
    ... on Asset {
      alt: description
      height
      title
      url
      width
    }
  }
  pdf {
    ... on Asset {
      alt: description
      height
      title
      url
      width
      fileName
    }
  }
}
    `
export const WhitepaperCardFragmentDoc = `
    fragment whitepaperCard on Whitepaper {
  fundProductIds {
    fundProductIds
  }
  firmsCollection(limit: $firmsLimit) {
    items {
      id
      name
    }
  }
  byCais
  type
  sys {
    id
    firstPublishedAt
    publishedAt
  }
  __typename
  slug
  title
  category
  displayDate
  author {
    name
  }
  introduction
  description: introduction
  hero {
    url
  }
  thumbnail {
    url
    width
    height
  }
  pdfsCollection(limit: 10) {
    items {
      pdf {
        url
      }
      title
      image {
        url
      }
      displayDate
    }
  }
}
    `
export const PageResourceFragmentDoc = `
    fragment pageResource on PageResource {
  title
  sys @include(if: $shouldIncludeDate) {
    publishedAt
  }
  displayDate @include(if: $shouldIncludeDate)
  hero
  introduction {
    json
    links {
      assets {
        block {
          ... on Asset {
            ...asset
          }
        }
        hyperlink {
          ... on Asset {
            ...asset
          }
        }
      }
      entries {
        block {
          ... on Pdf {
            ...pdf
          }
          ... on CaisiqArticle {
            ...articleCard
          }
          ... on Whitepaper {
            ...whitepaperCard
          }
          ... on CaisiqVideo {
            ...videoCard
          }
          ... on Chart {
            ...chart
          }
        }
        hyperlink {
          ... on Pdf {
            ...pdf
          }
          ... on CaisiqArticle {
            slug
          }
          ... on Whitepaper {
            slug
          }
          ... on PwsPressRelease {
            slug
          }
        }
        inline {
          ... on Pdf {
            ...pdf
          }
          ... on CaisiqArticle {
            ...articleCard
          }
          ... on Whitepaper {
            ...whitepaperCard
          }
          ... on CaisiqVideo {
            ...videoCard
          }
          ... on Chart {
            ...chart
          }
        }
      }
    }
  }
  body {
    json
    links {
      assets {
        block {
          ... on Asset {
            ...asset
          }
        }
        hyperlink {
          ... on Asset {
            ...asset
          }
        }
      }
      entries {
        block {
          ... on Pdf {
            ...pdf
          }
          ... on CaisiqArticle {
            ...articleCard
          }
          ... on Whitepaper {
            ...whitepaperCard
          }
          ... on CaisiqVideo {
            ...videoCard
          }
          ... on Chart {
            ...chart
          }
        }
        hyperlink {
          ... on Pdf {
            ...pdf
          }
          ... on CaisiqArticle {
            slug
          }
          ... on Whitepaper {
            slug
          }
          ... on PwsPressRelease {
            slug
          }
        }
        inline {
          ... on Pdf {
            ...pdf
          }
          ... on CaisiqArticle {
            ...articleCard
          }
          ... on Whitepaper {
            ...whitepaperCard
          }
          ... on CaisiqVideo {
            ...videoCard
          }
          ... on Chart {
            ...chart
          }
        }
      }
    }
  }
  author {
    name
    authorName
    jobTitle
    firm
    avatar {
      altText
      image {
        url
        title
      }
    }
    linkedin {
      externalLink
    }
  }
}
    ${AssetFragmentDoc}
${PdfFragmentDoc}
${ArticleCardFragmentDoc}
${WhitepaperCardFragmentDoc}
${VideoCardFragmentDoc}
${ChartFragmentDoc}`
export const VideoPageFragmentDoc = `
    fragment videoPage on CaisiqVideo {
  ...videoCard
  body {
    json
    links {
      entries {
        hyperlink {
          __typename
          sys {
            id
          }
          ... on CaisiqArticle {
            slug
          }
          ... on Whitepaper {
            slug
          }
          ... on PwsPressRelease {
            slug
          }
          ... on Chart {
            ...chart
          }
          ... on ComponentImage {
            cloudinaryImage
            name
          }
        }
        block {
          sys {
            id
            firstPublishedAt
          }
          __typename
          ... on CaisiqArticle {
            title
            displayDate
            author {
              name
            }
            thumbnail {
              url
            }
          }
          ... on Whitepaper {
            title
            displayDate
            author {
              name
            }
            thumbnail {
              url
            }
            pdfsCollection(limit: 10) {
              items {
                sys {
                  id
                }
                title
                displayDate
                pdf {
                  url
                }
                image {
                  url
                }
              }
            }
          }
          ... on Pdf {
            pdf {
              url
            }
            displayDate
            title
            image {
              url
            }
          }
          ... on CaisiqVideo {
            title
            displayDate
            summary
            duration
            video {
              url
            }
            poster
            thumbnail {
              url
            }
            linkedFrom {
              caisiqVideoPlaylistCollection(limit: 1) {
                items {
                  sys {
                    id
                    firstPublishedAt
                  }
                  episodic
                  title
                  summary
                  displayDate
                  heroImage {
                    url
                  }
                  category
                  videosCollection(limit: 40, where: {sys: {id_exists: true}}) {
                    items {
                      sys {
                        id
                        firstPublishedAt
                      }
                      title
                      summary
                      duration
                      poster
                      thumbnail {
                        url
                      }
                      displayDate
                      muxVideo
                      video {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      assets {
        block {
          sys {
            id
          }
          url
          title
          width
          height
          description
        }
      }
    }
  }
  linkedFrom {
    caisiqVideoPlaylistCollection(limit: 1) {
      items {
        sys {
          id
          firstPublishedAt
        }
        episodic
        title
        summary
        displayDate
        category
        heroImage {
          url
        }
        videosCollection(limit: 40, where: {sys: {id_exists: true}}) {
          items {
            ... on CaisiqVideo {
              ...videoCard
            }
          }
        }
      }
    }
  }
}
    ${VideoCardFragmentDoc}
${ChartFragmentDoc}`
export const VideoPlaylistCardFragmentDoc = `
    fragment videoPlaylistCard on CaisiqVideoPlaylist {
  sys {
    id
    firstPublishedAt
  }
  __typename
  episodic
  title
  category
  summary
  description: summary
  displayDate
  heroImage {
    url
    width
    height
  }
  fundProductIds {
    fundProductIds
  }
  firmsCollection(limit: $firmsLimit) {
    items {
      id
      name
    }
  }
  byCais
  videosCollection(limit: 1, where: {sys: {id_exists: true}}) {
    items {
      sys {
        id
      }
      poster
      thumbnail {
        url
        width
        height
      }
    }
  }
}
    `
export const WhitepaperPageFragmentDoc = `
    fragment whitepaperPage on Whitepaper {
  ... on Whitepaper {
    ...whitepaperCard
    customDescription
    customTitle
    category
    author {
      name
      authorName
      jobTitle
      firm
      avatar {
        altText
        image {
          url
          title
        }
      }
      linkedin {
        externalLink
      }
    }
    pdfsCollection(limit: 10) {
      items {
        sys {
          id
        }
        title
        displayDate
        pdf {
          url
        }
        image {
          url
        }
      }
    }
    tagsCollection {
      items {
        ...tag
      }
    }
    authorsCollection {
      items {
        ...author
      }
    }
    body {
      json
      links {
        entries {
          hyperlink {
            __typename
            sys {
              id
            }
            ... on CaisiqArticle {
              slug
            }
            ... on Whitepaper {
              slug
            }
            ... on PwsPressRelease {
              slug
            }
            ... on Chart {
              ...chart
            }
            ... on ComponentImage {
              cloudinaryImage
              name
            }
          }
          block {
            sys {
              id
              firstPublishedAt
            }
            __typename
            ... on CaisiqArticle {
              title
              slug
              displayDate
              author {
                name
              }
              thumbnail {
                url
              }
            }
            ... on Whitepaper {
              title
              displayDate
              author {
                name
              }
              thumbnail {
                url
              }
            }
          }
        }
        assets {
          block {
            sys {
              id
            }
            url
            title
            width
            height
            description
            fileName
            contentType
          }
        }
      }
    }
    footnotes {
      json
      links {
        entries {
          hyperlink {
            __typename
            sys {
              id
            }
            ... on CaisiqArticle {
              slug
            }
            ... on Whitepaper {
              slug
            }
            ... on PwsPressRelease {
              slug
            }
          }
          block {
            sys {
              id
              firstPublishedAt
            }
            __typename
            ... on CaisiqArticle {
              title
              slug
              displayDate
              author {
                name
              }
              thumbnail {
                url
              }
            }
            ... on Whitepaper {
              slug
              title
              displayDate
              author {
                name
              }
              thumbnail {
                url
              }
            }
          }
        }
        assets {
          block {
            sys {
              id
            }
            url
            title
            width
            height
            description
            fileName
            contentType
          }
        }
      }
    }
  }
}
    ${WhitepaperCardFragmentDoc}
${TagFragmentDoc}
${AuthorFragmentDoc}
${ChartFragmentDoc}`
export const AnnouncementFragmentDoc = `
    fragment announcement on Announcement {
  sys {
    id
  }
  dateTimeStart
  dateTimeEnd
  timezone
  icon
  image
  title
  description
  buttonText
  destination {
    cmsLink {
      __typename
      ... on Page {
        slug
        __typename
      }
      ... on PageResource {
        __typename
        slug
      }
      ... on Whitepaper {
        __typename
        slug
      }
      ... on CaisiqArticle {
        __typename
        slug
      }
      ... on HomepageLandingPage {
        slug
        __typename
      }
    }
    internalLink
    externalLink
    openInNewWindow
  }
  firmsCollection(limit: 1) {
    items {
      id
    }
  }
  fundProductIds {
    fundProductIds
  }
}
    `
export const VideoPlaylistFieldsFragmentDoc = `
    fragment videoPlaylistFields on CaisiqVideoPlaylist {
  sys {
    id
    firstPublishedAt
  }
  episodic
  title
  summary
  category
  displayDate
  heroImage {
    url
  }
  videosCollection(limit: 40) {
    items {
      sys {
        id
        firstPublishedAt
      }
      muxThumbnailTime
      title
      summary
      category
      duration
      thumbnail {
        url
        width
        height
      }
      displayDate
      muxVideo
      poster
      video {
        url
      }
    }
  }
}
    `
export const FaqCategoryFragmentDoc = `
    fragment faqCategory on FaqCategory {
  title
  id
}
    `
export const FaqFragmentDoc = `
    fragment faq on Faq {
  faq
  answer
  id
}
    `
export const VideoSectionItemCardFragmentDoc = `
    fragment videoSectionItemCard on CaisiqVideo {
  sys {
    id
    firstPublishedAt
  }
  __typename
  title
  category
  summary
  description: summary
  duration
  thumbnail {
    url
    width
    height
  }
  displayDate
  muxVideo
  poster
  icon
  muxThumbnailTime
  video {
    url
  }
}
    `
export const HomepageContentSectionFragmentDoc = `
    fragment homepageContentSection on HomepageContentSection {
  componentId: type
  title
  bookmarkId
  componentSectionDescription: description
  sys {
    id
  }
  __typename
  removeTopPadding
  itemsCollection {
    items {
      ... on CaisiqVideo {
        ...videoSectionItemCard
      }
      ... on ComponentText {
        ...componentText
      }
      ... on CallToAction {
        ...callToActionFields
      }
      ... on ComponentImage {
        sys {
          id
        }
        __typename
        title
        cloudinaryImage
      }
    }
  }
}
    ${VideoSectionItemCardFragmentDoc}
${ComponentTextFragmentDoc}
${CallToActionFieldsFragmentDoc}`
export const HomepageLandingPageFragmentDoc = `
    fragment homepageLandingPage on HomepageLandingPage {
  title
  icon
  sectionsCollection(limit: 12) {
    items {
      ... on HomepageContentSection {
        ...homepageContentSection
      }
    }
  }
  disclaimer {
    title
    body {
      json
    }
  }
}
    ${HomepageContentSectionFragmentDoc}`
export const GetArticleBySlugDocument = `
    query getArticleBySlug($firmsLimit: Int!, $slug: String!, $preview: Boolean = false) {
  caisiqArticleCollection(preview: $preview, limit: 1, where: {slug: $slug}) {
    items {
      ... on CaisiqArticle {
        ...articlePage
      }
    }
    total
  }
}
    ${ArticlePageFragmentDoc}`
export const useGetArticleBySlugQuery = <
  TData = GetArticleBySlugQuery,
  TError = unknown
>(
  variables: GetArticleBySlugQueryVariables,
  options?: UseQueryOptions<GetArticleBySlugQuery, TError, TData>
) =>
  useQuery<GetArticleBySlugQuery, TError, TData>(
    ['getArticleBySlug', variables],
    createFetcher<GetArticleBySlugQuery, GetArticleBySlugQueryVariables>(
      GetArticleBySlugDocument,
      variables
    ),
    options
  )
export const useInfiniteGetArticleBySlugQuery = <
  TData = GetArticleBySlugQuery,
  TError = unknown
>(
  variables: GetArticleBySlugQueryVariables,
  options?: UseInfiniteQueryOptions<GetArticleBySlugQuery, TError, TData>
) => {
  return useInfiniteQuery<GetArticleBySlugQuery, TError, TData>(
    ['getArticleBySlug.infinite', variables],
    (metaData) =>
      createFetcher<GetArticleBySlugQuery, GetArticleBySlugQueryVariables>(
        GetArticleBySlugDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetArticlePermissionsDocument = `
    query getArticlePermissions($slug: String!, $firmsLimit: Int!) {
  caisiqArticleCollection(limit: 1, where: {slug: $slug}) {
    total
    items {
      ... on CaisiqArticle {
        fundProductIds {
          fundProductIds
        }
        firmsCollection(limit: $firmsLimit) {
          items {
            id
          }
        }
      }
    }
  }
}
    `
export const useGetArticlePermissionsQuery = <
  TData = GetArticlePermissionsQuery,
  TError = unknown
>(
  variables: GetArticlePermissionsQueryVariables,
  options?: UseQueryOptions<GetArticlePermissionsQuery, TError, TData>
) =>
  useQuery<GetArticlePermissionsQuery, TError, TData>(
    ['getArticlePermissions', variables],
    createFetcher<
      GetArticlePermissionsQuery,
      GetArticlePermissionsQueryVariables
    >(GetArticlePermissionsDocument, variables),
    options
  )
export const useInfiniteGetArticlePermissionsQuery = <
  TData = GetArticlePermissionsQuery,
  TError = unknown
>(
  variables: GetArticlePermissionsQueryVariables,
  options?: UseInfiniteQueryOptions<GetArticlePermissionsQuery, TError, TData>
) => {
  return useInfiniteQuery<GetArticlePermissionsQuery, TError, TData>(
    ['getArticlePermissions.infinite', variables],
    (metaData) =>
      createFetcher<
        GetArticlePermissionsQuery,
        GetArticlePermissionsQueryVariables
      >(GetArticlePermissionsDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetArticlesDocument = `
    query getArticles($preview: Boolean = false, $where: CaisiqArticleFilter, $limit: Int = 40, $skip: Int = 0, $firmsLimit: Int!) {
  caisiqArticleCollection(
    order: [displayDate_DESC]
    skip: $skip
    limit: $limit
    preview: $preview
    where: $where
  ) {
    total
    items {
      ... on CaisiqArticle {
        ...articleCard
      }
    }
  }
}
    ${ArticleCardFragmentDoc}`
export const useGetArticlesQuery = <TData = GetArticlesQuery, TError = unknown>(
  variables: GetArticlesQueryVariables,
  options?: UseQueryOptions<GetArticlesQuery, TError, TData>
) =>
  useQuery<GetArticlesQuery, TError, TData>(
    ['getArticles', variables],
    createFetcher<GetArticlesQuery, GetArticlesQueryVariables>(
      GetArticlesDocument,
      variables
    ),
    options
  )
export const useInfiniteGetArticlesQuery = <
  TData = GetArticlesQuery,
  TError = unknown
>(
  variables: GetArticlesQueryVariables,
  options?: UseInfiniteQueryOptions<GetArticlesQuery, TError, TData>
) => {
  return useInfiniteQuery<GetArticlesQuery, TError, TData>(
    ['getArticles.infinite', variables],
    (metaData) =>
      createFetcher<GetArticlesQuery, GetArticlesQueryVariables>(
        GetArticlesDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetDisclaimerDocument = `
    query getDisclaimer($id: String!, $limit: Int = 1) {
  disclaimer: disclaimerCollection(where: {identifier: $id}, limit: $limit) {
    items {
      ...disclaimer
    }
  }
}
    ${DisclaimerFragmentDoc}`
export const useGetDisclaimerQuery = <
  TData = GetDisclaimerQuery,
  TError = unknown
>(
  variables: GetDisclaimerQueryVariables,
  options?: UseQueryOptions<GetDisclaimerQuery, TError, TData>
) =>
  useQuery<GetDisclaimerQuery, TError, TData>(
    ['getDisclaimer', variables],
    createFetcher<GetDisclaimerQuery, GetDisclaimerQueryVariables>(
      GetDisclaimerDocument,
      variables
    ),
    options
  )
export const useInfiniteGetDisclaimerQuery = <
  TData = GetDisclaimerQuery,
  TError = unknown
>(
  variables: GetDisclaimerQueryVariables,
  options?: UseInfiniteQueryOptions<GetDisclaimerQuery, TError, TData>
) => {
  return useInfiniteQuery<GetDisclaimerQuery, TError, TData>(
    ['getDisclaimer.infinite', variables],
    (metaData) =>
      createFetcher<GetDisclaimerQuery, GetDisclaimerQueryVariables>(
        GetDisclaimerDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetEventDocument = `
    query getEvent($id: String!, $firmsLimit: Int!, $tagsLimit: Int!) {
  event(id: $id) {
    ... on Event {
      ...eventCard
    }
  }
}
    ${EventCardFragmentDoc}`
export const useGetEventQuery = <TData = GetEventQuery, TError = unknown>(
  variables: GetEventQueryVariables,
  options?: UseQueryOptions<GetEventQuery, TError, TData>
) =>
  useQuery<GetEventQuery, TError, TData>(
    ['getEvent', variables],
    createFetcher<GetEventQuery, GetEventQueryVariables>(
      GetEventDocument,
      variables
    ),
    options
  )
export const useInfiniteGetEventQuery = <
  TData = GetEventQuery,
  TError = unknown
>(
  variables: GetEventQueryVariables,
  options?: UseInfiniteQueryOptions<GetEventQuery, TError, TData>
) => {
  return useInfiniteQuery<GetEventQuery, TError, TData>(
    ['getEvent.infinite', variables],
    (metaData) =>
      createFetcher<GetEventQuery, GetEventQueryVariables>(GetEventDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetWebinarsDocument = `
    query getWebinars($upcomingWhere: EventFilter!, $videosWhere: CaisiqVideoFilter!, $playlistWhere: CaisiqVideoPlaylistFilter!, $featuredId: String!, $upcomingLimit: Int = 12, $replaysLimit: Int = 20, $preview: Boolean = false, $includeFeatured: Boolean = true, $firmsLimit: Int!, $tagsLimit: Int!) {
  featured: featuredContentCollection(
    where: {id: $featuredId}
    limit: 1
    preview: $preview
  ) {
    items @include(if: $includeFeatured) {
      fallback: webinarsDefault(preview: $preview) {
        ... on Event {
          ...eventCard
        }
        ... on CaisiqVideoPlaylist {
          ...videoPlaylistCard
        }
        ... on CaisiqVideo {
          ...videoCard
        }
        ... on Link {
          ...linkCard
        }
      }
      options: webinarsOptionsCollection(limit: 5, preview: $preview) {
        items @include(if: $includeFeatured) {
          ... on Event {
            ...eventCard
          }
          ... on CaisiqVideoPlaylist {
            ...videoPlaylistCard
          }
          ... on CaisiqVideo {
            ...videoCard
          }
          ... on Link {
            ...linkCard
          }
        }
      }
    }
  }
  upcoming: eventCollection(
    where: $upcomingWhere
    order: dateTime_ASC
    preview: $preview
    limit: $upcomingLimit
  ) {
    total
    items {
      ...eventCard
    }
  }
  caisiqVideoPlaylistCollection(
    limit: $replaysLimit
    preview: $preview
    order: displayDate_DESC
    where: $playlistWhere
  ) {
    items {
      ... on CaisiqVideoPlaylist {
        ...videoPlaylistCard
      }
    }
  }
  caisiqVideoCollection(
    order: [displayDate_DESC]
    limit: $replaysLimit
    preview: $preview
    where: $videosWhere
  ) {
    total
    items {
      ... on CaisiqVideo {
        ...videoCard
      }
    }
  }
}
    ${EventCardFragmentDoc}
${VideoPlaylistCardFragmentDoc}
${VideoCardFragmentDoc}
${LinkCardFragmentDoc}`
export const useGetWebinarsQuery = <TData = GetWebinarsQuery, TError = unknown>(
  variables: GetWebinarsQueryVariables,
  options?: UseQueryOptions<GetWebinarsQuery, TError, TData>
) =>
  useQuery<GetWebinarsQuery, TError, TData>(
    ['getWebinars', variables],
    createFetcher<GetWebinarsQuery, GetWebinarsQueryVariables>(
      GetWebinarsDocument,
      variables
    ),
    options
  )
export const useInfiniteGetWebinarsQuery = <
  TData = GetWebinarsQuery,
  TError = unknown
>(
  variables: GetWebinarsQueryVariables,
  options?: UseInfiniteQueryOptions<GetWebinarsQuery, TError, TData>
) => {
  return useInfiniteQuery<GetWebinarsQuery, TError, TData>(
    ['getWebinars.infinite', variables],
    (metaData) =>
      createFetcher<GetWebinarsQuery, GetWebinarsQueryVariables>(
        GetWebinarsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetWebinarsReplayDocument = `
    query getWebinarsReplay($limit: Int, $skip: Int, $preview: Boolean = false, $firmsLimit: Int!) {
  caisiqVideoPlaylistCollection(
    limit: $limit
    preview: $preview
    order: displayDate_DESC
    where: {category: "webinars"}
    skip: $skip
  ) {
    items {
      ... on CaisiqVideoPlaylist {
        ...videoPlaylistCard
      }
    }
  }
  caisiqVideoCollection(
    order: [displayDate_DESC]
    limit: $limit
    preview: $preview
    where: {category: "webinars"}
    skip: $skip
  ) {
    total
    items {
      ... on CaisiqVideo {
        ...videoCard
      }
    }
  }
}
    ${VideoPlaylistCardFragmentDoc}
${VideoCardFragmentDoc}`
export const useGetWebinarsReplayQuery = <
  TData = GetWebinarsReplayQuery,
  TError = unknown
>(
  variables: GetWebinarsReplayQueryVariables,
  options?: UseQueryOptions<GetWebinarsReplayQuery, TError, TData>
) =>
  useQuery<GetWebinarsReplayQuery, TError, TData>(
    ['getWebinarsReplay', variables],
    createFetcher<GetWebinarsReplayQuery, GetWebinarsReplayQueryVariables>(
      GetWebinarsReplayDocument,
      variables
    ),
    options
  )
export const useInfiniteGetWebinarsReplayQuery = <
  TData = GetWebinarsReplayQuery,
  TError = unknown
>(
  variables: GetWebinarsReplayQueryVariables,
  options?: UseInfiniteQueryOptions<GetWebinarsReplayQuery, TError, TData>
) => {
  return useInfiniteQuery<GetWebinarsReplayQuery, TError, TData>(
    ['getWebinarsReplay.infinite', variables],
    (metaData) =>
      createFetcher<GetWebinarsReplayQuery, GetWebinarsReplayQueryVariables>(
        GetWebinarsReplayDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetInPersonEventsDocument = `
    query getInPersonEvents($upcomingWhere: EventFilter!, $upcomingLimit: Int = 12, $preview: Boolean = false, $firmsLimit: Int!, $tagsLimit: Int!) {
  upcoming: eventCollection(
    where: $upcomingWhere
    order: dateTime_ASC
    preview: $preview
    limit: $upcomingLimit
  ) {
    total
    items {
      ...eventCard
    }
  }
}
    ${EventCardFragmentDoc}`
export const useGetInPersonEventsQuery = <
  TData = GetInPersonEventsQuery,
  TError = unknown
>(
  variables: GetInPersonEventsQueryVariables,
  options?: UseQueryOptions<GetInPersonEventsQuery, TError, TData>
) =>
  useQuery<GetInPersonEventsQuery, TError, TData>(
    ['getInPersonEvents', variables],
    createFetcher<GetInPersonEventsQuery, GetInPersonEventsQueryVariables>(
      GetInPersonEventsDocument,
      variables
    ),
    options
  )
export const useInfiniteGetInPersonEventsQuery = <
  TData = GetInPersonEventsQuery,
  TError = unknown
>(
  variables: GetInPersonEventsQueryVariables,
  options?: UseInfiniteQueryOptions<GetInPersonEventsQuery, TError, TData>
) => {
  return useInfiniteQuery<GetInPersonEventsQuery, TError, TData>(
    ['getInPersonEvents.infinite', variables],
    (metaData) =>
      createFetcher<GetInPersonEventsQuery, GetInPersonEventsQueryVariables>(
        GetInPersonEventsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetContentfulFundsDocument = `
    query getContentfulFunds($fundProductIds: [String], $preview: Boolean = false, $firmsLimit: Int!) {
  contentfulFunds: externalContentCollection(
    where: {externalId_in: $fundProductIds, type: "Fund"}
    order: [displayDate_DESC]
    preview: $preview
  ) {
    items {
      ... on ExternalContent {
        ...externalContentCard
      }
    }
  }
}
    ${ExternalContentCardFragmentDoc}`
export const useGetContentfulFundsQuery = <
  TData = GetContentfulFundsQuery,
  TError = unknown
>(
  variables: GetContentfulFundsQueryVariables,
  options?: UseQueryOptions<GetContentfulFundsQuery, TError, TData>
) =>
  useQuery<GetContentfulFundsQuery, TError, TData>(
    ['getContentfulFunds', variables],
    createFetcher<GetContentfulFundsQuery, GetContentfulFundsQueryVariables>(
      GetContentfulFundsDocument,
      variables
    ),
    options
  )
export const useInfiniteGetContentfulFundsQuery = <
  TData = GetContentfulFundsQuery,
  TError = unknown
>(
  variables: GetContentfulFundsQueryVariables,
  options?: UseInfiniteQueryOptions<GetContentfulFundsQuery, TError, TData>
) => {
  return useInfiniteQuery<GetContentfulFundsQuery, TError, TData>(
    ['getContentfulFunds.infinite', variables],
    (metaData) =>
      createFetcher<GetContentfulFundsQuery, GetContentfulFundsQueryVariables>(
        GetContentfulFundsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetExternalContentDocument = `
    query getExternalContent($where: ExternalContentFilter, $preview: Boolean = false, $limit: Int = 20, $firmsLimit: Int!) {
  externalContentCollection(
    limit: $limit
    order: [displayDate_DESC]
    preview: $preview
    where: $where
  ) {
    items {
      ... on ExternalContent {
        ...externalContentCard
      }
    }
  }
}
    ${ExternalContentCardFragmentDoc}`
export const useGetExternalContentQuery = <
  TData = GetExternalContentQuery,
  TError = unknown
>(
  variables: GetExternalContentQueryVariables,
  options?: UseQueryOptions<GetExternalContentQuery, TError, TData>
) =>
  useQuery<GetExternalContentQuery, TError, TData>(
    ['getExternalContent', variables],
    createFetcher<GetExternalContentQuery, GetExternalContentQueryVariables>(
      GetExternalContentDocument,
      variables
    ),
    options
  )
export const useInfiniteGetExternalContentQuery = <
  TData = GetExternalContentQuery,
  TError = unknown
>(
  variables: GetExternalContentQueryVariables,
  options?: UseInfiniteQueryOptions<GetExternalContentQuery, TError, TData>
) => {
  return useInfiniteQuery<GetExternalContentQuery, TError, TData>(
    ['getExternalContent.infinite', variables],
    (metaData) =>
      createFetcher<GetExternalContentQuery, GetExternalContentQueryVariables>(
        GetExternalContentDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetFirmContentOptionsDocument = `
    query getFirmContentOptions($firmId: String, $firmsLimit: Int!) {
  memberFirmCollection(where: {id: $firmId}, limit: 1) {
    items {
      optionsCollection(limit: 4) {
        items {
          ... on CaisiqArticle {
            ...articleCuratedContentCard
          }
          ... on Whitepaper {
            ...whitepaperCuratedContentCard
          }
          ... on CaisiqVideo {
            ...videoCuratedContentCard
          }
          ... on CaisiqVideoPlaylist {
            ...videoPlaylistCuratedContentCard
          }
          ... on ExternalContent {
            ...externalCuratedContentCard
          }
          ... on Link {
            sys {
              id
            }
            openInNewWindow
            customTitle
            customDescription
            __typename
            internalLink
            cmsLink {
              ... on PageResource {
                __typename
                slug
              }
              ... on HomepageLandingPage {
                __typename
                slug
              }
            }
            firmsCollection(limit: $firmsLimit) {
              items {
                id
                name
              }
            }
            byCais
          }
          ... on Event {
            sys {
              id
            }
            customTitle
            title
            customDescription
            description
            __typename
            eventType
            link
            fundProductIds {
              fundProductIds
            }
            firmsCollection(limit: $firmsLimit) {
              items {
                id
                name
              }
            }
            byCais
            date: dateTime
            displayDate: dateTime
            timezone
          }
          ... on Pdf {
            sys {
              id
            }
            customTitle
            title
            customDescription
            displayDate
            __typename
            pdf {
              url
            }
            firmsCollection(limit: $firmsLimit) {
              items {
                id
                name
              }
            }
            byCais
          }
        }
      }
    }
  }
}
    ${ArticleCuratedContentCardFragmentDoc}
${WhitepaperCuratedContentCardFragmentDoc}
${VideoCuratedContentCardFragmentDoc}
${VideoPlaylistCuratedContentCardFragmentDoc}
${ExternalCuratedContentCardFragmentDoc}`
export const useGetFirmContentOptionsQuery = <
  TData = GetFirmContentOptionsQuery,
  TError = unknown
>(
  variables: GetFirmContentOptionsQueryVariables,
  options?: UseQueryOptions<GetFirmContentOptionsQuery, TError, TData>
) =>
  useQuery<GetFirmContentOptionsQuery, TError, TData>(
    ['getFirmContentOptions', variables],
    createFetcher<
      GetFirmContentOptionsQuery,
      GetFirmContentOptionsQueryVariables
    >(GetFirmContentOptionsDocument, variables),
    options
  )
export const useInfiniteGetFirmContentOptionsQuery = <
  TData = GetFirmContentOptionsQuery,
  TError = unknown
>(
  variables: GetFirmContentOptionsQueryVariables,
  options?: UseInfiniteQueryOptions<GetFirmContentOptionsQuery, TError, TData>
) => {
  return useInfiniteQuery<GetFirmContentOptionsQuery, TError, TData>(
    ['getFirmContentOptions.infinite', variables],
    (metaData) =>
      createFetcher<
        GetFirmContentOptionsQuery,
        GetFirmContentOptionsQueryVariables
      >(GetFirmContentOptionsDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetFirmsWithContentDocument = `
    query getFirmsWithContent($firmIds: [String], $limit: Int) {
  memberFirmCollection(where: {id_in: $firmIds}, limit: $limit) {
    items {
      id
      overrideDefault
      optionsCollection {
        total
      }
    }
  }
}
    `
export const useGetFirmsWithContentQuery = <
  TData = GetFirmsWithContentQuery,
  TError = unknown
>(
  variables?: GetFirmsWithContentQueryVariables,
  options?: UseQueryOptions<GetFirmsWithContentQuery, TError, TData>
) =>
  useQuery<GetFirmsWithContentQuery, TError, TData>(
    variables === undefined
      ? ['getFirmsWithContent']
      : ['getFirmsWithContent', variables],
    createFetcher<GetFirmsWithContentQuery, GetFirmsWithContentQueryVariables>(
      GetFirmsWithContentDocument,
      variables
    ),
    options
  )
export const useInfiniteGetFirmsWithContentQuery = <
  TData = GetFirmsWithContentQuery,
  TError = unknown
>(
  variables?: GetFirmsWithContentQueryVariables,
  options?: UseInfiniteQueryOptions<GetFirmsWithContentQuery, TError, TData>
) => {
  return useInfiniteQuery<GetFirmsWithContentQuery, TError, TData>(
    variables === undefined
      ? ['getFirmsWithContent.infinite']
      : ['getFirmsWithContent.infinite', variables],
    (metaData) =>
      createFetcher<
        GetFirmsWithContentQuery,
        GetFirmsWithContentQueryVariables
      >(GetFirmsWithContentDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetFeaturedHomeDocument = `
    query getFeaturedHome($featuredId: String!, $preview: Boolean = false, $firmsLimit: Int!, $tagsLimit: Int!) {
  featured: featuredContentCollection(
    where: {id: $featuredId}
    limit: 1
    preview: $preview
  ) {
    items {
      fallback: homepageDefault(preview: $preview) {
        ... on CaisiqArticle {
          ...articleCard
        }
        ... on Event {
          ...eventCard
        }
        ... on CaisiqVideo {
          ...videoCard
        }
        ... on CaisiqVideoPlaylist {
          ...videoPlaylistCard
        }
        ... on ExternalContent {
          ...externalContentCard
        }
        ... on Whitepaper {
          ...whitepaperCard
        }
        ... on Link {
          ...linkCard
        }
      }
      options: homepageOptionsCollection(preview: $preview) {
        items {
          ... on CaisiqArticle {
            ...articleCard
          }
          ... on Event {
            ...eventCard
          }
          ... on CaisiqVideo {
            ...videoCard
          }
          ... on CaisiqVideoPlaylist {
            ...videoPlaylistCard
          }
          ... on ExternalContent {
            ...externalContentCard
          }
          ... on Whitepaper {
            ...whitepaperCard
          }
          ... on Link {
            ...linkCard
          }
        }
      }
    }
  }
}
    ${ArticleCardFragmentDoc}
${EventCardFragmentDoc}
${VideoCardFragmentDoc}
${VideoPlaylistCardFragmentDoc}
${ExternalContentCardFragmentDoc}
${WhitepaperCardFragmentDoc}
${LinkCardFragmentDoc}`
export const useGetFeaturedHomeQuery = <
  TData = GetFeaturedHomeQuery,
  TError = unknown
>(
  variables: GetFeaturedHomeQueryVariables,
  options?: UseQueryOptions<GetFeaturedHomeQuery, TError, TData>
) =>
  useQuery<GetFeaturedHomeQuery, TError, TData>(
    ['getFeaturedHome', variables],
    createFetcher<GetFeaturedHomeQuery, GetFeaturedHomeQueryVariables>(
      GetFeaturedHomeDocument,
      variables
    ),
    options
  )
export const useInfiniteGetFeaturedHomeQuery = <
  TData = GetFeaturedHomeQuery,
  TError = unknown
>(
  variables: GetFeaturedHomeQueryVariables,
  options?: UseInfiniteQueryOptions<GetFeaturedHomeQuery, TError, TData>
) => {
  return useInfiniteQuery<GetFeaturedHomeQuery, TError, TData>(
    ['getFeaturedHome.infinite', variables],
    (metaData) =>
      createFetcher<GetFeaturedHomeQuery, GetFeaturedHomeQueryVariables>(
        GetFeaturedHomeDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetHomeCarouselContentDocument = `
    query getHomeCarouselContent($carouselItemLimit: Int!, $preview: Boolean = false, $date: DateTime, $firmsLimit: Int!, $tagsLimit: Int!) {
  tutorialArticles: caisiqArticleCollection(
    where: {category: "tutorials"}
    order: [displayDate_DESC]
    limit: $carouselItemLimit
    preview: $preview
  ) {
    items {
      ... on CaisiqArticle {
        ...articleCard
      }
    }
  }
  tutorialPlaylists: caisiqVideoPlaylistCollection(
    where: {OR: [{category: "tutorials"}]}
    limit: $carouselItemLimit
    preview: $preview
    order: displayDate_DESC
  ) {
    items {
      ... on CaisiqVideoPlaylist {
        ...videoPlaylistCard
      }
    }
  }
  tutorialVideos: caisiqVideoCollection(
    where: {OR: [{category: "tutorials"}]}
    order: [displayDate_DESC]
    limit: $carouselItemLimit
    preview: $preview
  ) {
    items {
      ... on CaisiqVideo {
        ...videoCard
      }
    }
  }
  tutorialWhitepapers: whitepaperCollection(
    where: {OR: [{category: "tutorials"}]}
    order: [displayDate_DESC]
    limit: $carouselItemLimit
    preview: $preview
  ) {
    items {
      ... on Whitepaper {
        ...whitepaperCard
      }
    }
  }
  researchArticles: caisiqArticleCollection(
    where: {OR: [{category: "research"}]}
    order: [displayDate_DESC]
    limit: $carouselItemLimit
    preview: $preview
  ) {
    items {
      ... on CaisiqArticle {
        ...articleCard
      }
    }
  }
  researchPlaylists: caisiqVideoPlaylistCollection(
    where: {OR: [{category: "research"}]}
    limit: $carouselItemLimit
    preview: $preview
    order: displayDate_DESC
  ) {
    items {
      ... on CaisiqVideoPlaylist {
        ...videoPlaylistCard
      }
    }
  }
  researchVideos: caisiqVideoCollection(
    where: {OR: [{category: "research"}]}
    order: [displayDate_DESC]
    limit: $carouselItemLimit
    preview: $preview
  ) {
    items {
      ... on CaisiqVideo {
        ...videoCard
      }
    }
  }
  researchWhitepapers: whitepaperCollection(
    where: {OR: [{category: "research"}]}
    order: [displayDate_DESC]
    limit: $carouselItemLimit
    preview: $preview
  ) {
    items {
      ... on Whitepaper {
        ...whitepaperCard
      }
    }
  }
  researchExternal: externalContentCollection(
    where: {AND: [{category: "research"}, {type_not: "Fund"}]}
    order: [displayDate_DESC]
    preview: $preview
    limit: $carouselItemLimit
  ) {
    total
    items {
      ... on ExternalContent {
        ...externalContentCard
      }
    }
  }
  trendingFunds: externalContentCollection(
    where: {type: "Fund"}
    order: [displayDate_DESC]
    preview: $preview
    limit: $carouselItemLimit
  ) {
    items {
      ... on ExternalContent {
        ...externalContentCard
      }
    }
  }
  upcoming: eventCollection(
    where: {eventType: "Webinar", dateTime_gte: $date}
    order: dateTime_ASC
    preview: $preview
    limit: $carouselItemLimit
  ) {
    total
    items {
      ...eventCard
    }
  }
  caisiqVideoPlaylistCollection(
    limit: $carouselItemLimit
    preview: $preview
    order: displayDate_DESC
    where: {category: "webinars", displayDate_lte: $date}
  ) {
    items {
      ... on CaisiqVideoPlaylist {
        ...videoPlaylistCard
      }
    }
  }
  caisiqVideoCollection(
    order: [displayDate_DESC]
    limit: $carouselItemLimit
    preview: $preview
    where: {category: "webinars", displayDate_lte: $date}
  ) {
    total
    items {
      ... on CaisiqVideo {
        ...videoCard
      }
    }
  }
  pressReleases: pwsPressReleaseCollection(
    order: [displayDate_DESC]
    preview: $preview
    limit: $carouselItemLimit
  ) {
    items {
      ... on PwsPressRelease {
        ...pressReleaseCard
      }
    }
  }
}
    ${ArticleCardFragmentDoc}
${VideoPlaylistCardFragmentDoc}
${VideoCardFragmentDoc}
${WhitepaperCardFragmentDoc}
${ExternalContentCardFragmentDoc}
${EventCardFragmentDoc}
${PressReleaseCardFragmentDoc}`
export const useGetHomeCarouselContentQuery = <
  TData = GetHomeCarouselContentQuery,
  TError = unknown
>(
  variables: GetHomeCarouselContentQueryVariables,
  options?: UseQueryOptions<GetHomeCarouselContentQuery, TError, TData>
) =>
  useQuery<GetHomeCarouselContentQuery, TError, TData>(
    ['getHomeCarouselContent', variables],
    createFetcher<
      GetHomeCarouselContentQuery,
      GetHomeCarouselContentQueryVariables
    >(GetHomeCarouselContentDocument, variables),
    options
  )
export const useInfiniteGetHomeCarouselContentQuery = <
  TData = GetHomeCarouselContentQuery,
  TError = unknown
>(
  variables: GetHomeCarouselContentQueryVariables,
  options?: UseInfiniteQueryOptions<GetHomeCarouselContentQuery, TError, TData>
) => {
  return useInfiniteQuery<GetHomeCarouselContentQuery, TError, TData>(
    ['getHomeCarouselContent.infinite', variables],
    (metaData) =>
      createFetcher<
        GetHomeCarouselContentQuery,
        GetHomeCarouselContentQueryVariables
      >(GetHomeCarouselContentDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetLegalPageBySlugDocument = `
    query getLegalPageBySlug($slug: String!, $preview: Boolean = false) {
  legalPageCollection(where: {slug: $slug}, preview: $preview, limit: 1) {
    items {
      ...legalPage
    }
  }
}
    ${LegalPageFragmentDoc}`
export const useGetLegalPageBySlugQuery = <
  TData = GetLegalPageBySlugQuery,
  TError = unknown
>(
  variables: GetLegalPageBySlugQueryVariables,
  options?: UseQueryOptions<GetLegalPageBySlugQuery, TError, TData>
) =>
  useQuery<GetLegalPageBySlugQuery, TError, TData>(
    ['getLegalPageBySlug', variables],
    createFetcher<GetLegalPageBySlugQuery, GetLegalPageBySlugQueryVariables>(
      GetLegalPageBySlugDocument,
      variables
    ),
    options
  )
export const useInfiniteGetLegalPageBySlugQuery = <
  TData = GetLegalPageBySlugQuery,
  TError = unknown
>(
  variables: GetLegalPageBySlugQueryVariables,
  options?: UseInfiniteQueryOptions<GetLegalPageBySlugQuery, TError, TData>
) => {
  return useInfiniteQuery<GetLegalPageBySlugQuery, TError, TData>(
    ['getLegalPageBySlug.infinite', variables],
    (metaData) =>
      createFetcher<GetLegalPageBySlugQuery, GetLegalPageBySlugQueryVariables>(
        GetLegalPageBySlugDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetPressReleasesDocument = `
    query getPressReleases($preview: Boolean = false, $limit: Int) {
  pressReleases: pwsPressReleaseCollection(
    order: [displayDate_DESC]
    preview: $preview
    limit: $limit
  ) {
    items {
      ... on PwsPressRelease {
        ...pressReleaseCard
      }
    }
  }
}
    ${PressReleaseCardFragmentDoc}`
export const useGetPressReleasesQuery = <
  TData = GetPressReleasesQuery,
  TError = unknown
>(
  variables?: GetPressReleasesQueryVariables,
  options?: UseQueryOptions<GetPressReleasesQuery, TError, TData>
) =>
  useQuery<GetPressReleasesQuery, TError, TData>(
    variables === undefined
      ? ['getPressReleases']
      : ['getPressReleases', variables],
    createFetcher<GetPressReleasesQuery, GetPressReleasesQueryVariables>(
      GetPressReleasesDocument,
      variables
    ),
    options
  )
export const useInfiniteGetPressReleasesQuery = <
  TData = GetPressReleasesQuery,
  TError = unknown
>(
  variables?: GetPressReleasesQueryVariables,
  options?: UseInfiniteQueryOptions<GetPressReleasesQuery, TError, TData>
) => {
  return useInfiniteQuery<GetPressReleasesQuery, TError, TData>(
    variables === undefined
      ? ['getPressReleases.infinite']
      : ['getPressReleases.infinite', variables],
    (metaData) =>
      createFetcher<GetPressReleasesQuery, GetPressReleasesQueryVariables>(
        GetPressReleasesDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetAllResearchTagCountsDocument = `
    query getAllResearchTagCounts($limit: Int = 100, $articleWhere: CaisiqArticleFilter, $videoWhere: CaisiqVideoFilter, $videoPlaylistWhere: CaisiqVideoPlaylistFilter, $whitepaperWhere: WhitepaperFilter, $externalWhere: ExternalContentFilter, $firmsLimit: Int! = 10, $tagsLimit: Int! = 19) {
  caisiqArticleCollection(
    where: $articleWhere
    limit: $limit
    order: [displayDate_DESC]
  ) {
    total
    items {
      ... on CaisiqArticle {
        __typename
        sys {
          id
        }
        firmsCollection(limit: $firmsLimit) {
          items {
            id
          }
        }
        fundProductIds {
          fundProductIds
        }
        tagsCollection(limit: $tagsLimit) {
          items {
            tagId
          }
        }
      }
    }
  }
  caisiqVideoCollection(
    where: $videoWhere
    limit: $limit
    order: [displayDate_DESC]
  ) {
    total
    items {
      ... on CaisiqVideo {
        __typename
        sys {
          id
        }
        firmsCollection(limit: $firmsLimit) {
          items {
            id
          }
        }
        fundProductIds {
          fundProductIds
        }
        tagsCollection(limit: $tagsLimit) {
          items {
            tagId
          }
        }
      }
    }
  }
  whitepaperCollection(
    where: $whitepaperWhere
    limit: $limit
    order: [displayDate_DESC]
  ) {
    total
    items {
      ... on Whitepaper {
        __typename
        sys {
          id
        }
        firmsCollection(limit: $firmsLimit) {
          items {
            id
          }
        }
        fundProductIds {
          fundProductIds
        }
        tagsCollection(limit: $tagsLimit) {
          items {
            tagId
          }
        }
      }
    }
  }
  caisiqVideoPlaylistCollection(
    where: $videoPlaylistWhere
    limit: $limit
    order: [displayDate_DESC]
  ) {
    total
    items {
      ... on CaisiqVideoPlaylist {
        __typename
        sys {
          id
        }
        firmsCollection(limit: $firmsLimit) {
          items {
            id
          }
        }
        fundProductIds {
          fundProductIds
        }
        tagsCollection(limit: $tagsLimit) {
          items {
            tagId
          }
        }
      }
    }
  }
  externalContentCollection(
    where: {AND: [$externalWhere, {type_not: "Fund"}]}
    order: [displayDate_DESC]
    limit: $limit
  ) {
    total
    items {
      ... on ExternalContent {
        __typename
        sys {
          id
        }
        firmsCollection(limit: $firmsLimit) {
          items {
            id
          }
        }
        fundProductIds {
          fundProductIds
        }
        tagsCollection(limit: $tagsLimit) {
          items {
            tagId
          }
        }
      }
    }
  }
}
    `
export const useGetAllResearchTagCountsQuery = <
  TData = GetAllResearchTagCountsQuery,
  TError = unknown
>(
  variables?: GetAllResearchTagCountsQueryVariables,
  options?: UseQueryOptions<GetAllResearchTagCountsQuery, TError, TData>
) =>
  useQuery<GetAllResearchTagCountsQuery, TError, TData>(
    variables === undefined
      ? ['getAllResearchTagCounts']
      : ['getAllResearchTagCounts', variables],
    createFetcher<
      GetAllResearchTagCountsQuery,
      GetAllResearchTagCountsQueryVariables
    >(GetAllResearchTagCountsDocument, variables),
    options
  )
export const useInfiniteGetAllResearchTagCountsQuery = <
  TData = GetAllResearchTagCountsQuery,
  TError = unknown
>(
  variables?: GetAllResearchTagCountsQueryVariables,
  options?: UseInfiniteQueryOptions<GetAllResearchTagCountsQuery, TError, TData>
) => {
  return useInfiniteQuery<GetAllResearchTagCountsQuery, TError, TData>(
    variables === undefined
      ? ['getAllResearchTagCounts.infinite']
      : ['getAllResearchTagCounts.infinite', variables],
    (metaData) =>
      createFetcher<
        GetAllResearchTagCountsQuery,
        GetAllResearchTagCountsQueryVariables
      >(GetAllResearchTagCountsDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetAllResearchDocument = `
    query getAllResearch($limit: Int = 20, $preview: Boolean = false, $articleWhere: CaisiqArticleFilter, $videoWhere: CaisiqVideoFilter, $videoPlaylistWhere: CaisiqVideoPlaylistFilter, $whitepaperWhere: WhitepaperFilter, $externalWhere: ExternalContentFilter, $firmsLimit: Int!) {
  caisiqArticleCollection(
    where: $articleWhere
    limit: $limit
    order: [displayDate_DESC]
    preview: $preview
  ) {
    total
    items {
      ... on CaisiqArticle {
        ...articleCard
      }
    }
  }
  caisiqVideoCollection(
    where: $videoWhere
    limit: $limit
    order: [displayDate_DESC]
    preview: $preview
  ) {
    total
    items {
      ... on CaisiqVideo {
        ...videoCard
      }
    }
  }
  caisiqVideoPlaylistCollection(
    where: $videoPlaylistWhere
    limit: $limit
    order: [displayDate_DESC]
    preview: $preview
  ) {
    total
    items {
      ... on CaisiqVideoPlaylist {
        ...videoPlaylistCard
      }
    }
  }
  whitepaperCollection(
    where: $whitepaperWhere
    limit: $limit
    order: [displayDate_DESC]
    preview: $preview
  ) {
    total
    items {
      ... on Whitepaper {
        ...whitepaperCard
      }
    }
  }
  externalContentCollection(
    where: {AND: [$externalWhere, {type_not: "Fund"}]}
    order: [displayDate_DESC]
    preview: $preview
  ) {
    total
    items {
      ... on ExternalContent {
        ...externalContentCard
      }
    }
  }
}
    ${ArticleCardFragmentDoc}
${VideoCardFragmentDoc}
${VideoPlaylistCardFragmentDoc}
${WhitepaperCardFragmentDoc}
${ExternalContentCardFragmentDoc}`
export const useGetAllResearchQuery = <
  TData = GetAllResearchQuery,
  TError = unknown
>(
  variables: GetAllResearchQueryVariables,
  options?: UseQueryOptions<GetAllResearchQuery, TError, TData>
) =>
  useQuery<GetAllResearchQuery, TError, TData>(
    ['getAllResearch', variables],
    createFetcher<GetAllResearchQuery, GetAllResearchQueryVariables>(
      GetAllResearchDocument,
      variables
    ),
    options
  )
export const useInfiniteGetAllResearchQuery = <
  TData = GetAllResearchQuery,
  TError = unknown
>(
  variables: GetAllResearchQueryVariables,
  options?: UseInfiniteQueryOptions<GetAllResearchQuery, TError, TData>
) => {
  return useInfiniteQuery<GetAllResearchQuery, TError, TData>(
    ['getAllResearch.infinite', variables],
    (metaData) =>
      createFetcher<GetAllResearchQuery, GetAllResearchQueryVariables>(
        GetAllResearchDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetCuratedContentFiltersDocument = `
    query getCuratedContentFilters($id: String!, $preview: Boolean = false, $tagsLimit: Int! = 10) {
  curatedResearchContentCollection(limit: 1, where: {id: $id}, preview: $preview) {
    items {
      curatedFilterGroupCollection(limit: 5) {
        total
        items {
          label
          filtersCollection(limit: 4) {
            items {
              tagFamilyType
              tagsCollection(limit: $tagsLimit) {
                items {
                  tagId
                }
              }
            }
          }
        }
      }
    }
  }
}
    `
export const useGetCuratedContentFiltersQuery = <
  TData = GetCuratedContentFiltersQuery,
  TError = unknown
>(
  variables: GetCuratedContentFiltersQueryVariables,
  options?: UseQueryOptions<GetCuratedContentFiltersQuery, TError, TData>
) =>
  useQuery<GetCuratedContentFiltersQuery, TError, TData>(
    ['getCuratedContentFilters', variables],
    createFetcher<
      GetCuratedContentFiltersQuery,
      GetCuratedContentFiltersQueryVariables
    >(GetCuratedContentFiltersDocument, variables),
    options
  )
export const useInfiniteGetCuratedContentFiltersQuery = <
  TData = GetCuratedContentFiltersQuery,
  TError = unknown
>(
  variables: GetCuratedContentFiltersQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetCuratedContentFiltersQuery,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<GetCuratedContentFiltersQuery, TError, TData>(
    ['getCuratedContentFilters.infinite', variables],
    (metaData) =>
      createFetcher<
        GetCuratedContentFiltersQuery,
        GetCuratedContentFiltersQueryVariables
      >(GetCuratedContentFiltersDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetCuratedFilterGroupDocument = `
    query getCuratedFilterGroup($label: String!) {
  curatedFilterGroupCollection(where: {label_contains: $label}, limit: 1) {
    items {
      label
      filtersCollection {
        items {
          sys {
            id
          }
          tagFamilyType
          tagsCollection {
            items {
              tagId
              label
              sys {
                id
              }
            }
          }
        }
      }
    }
  }
}
    `
export const useGetCuratedFilterGroupQuery = <
  TData = GetCuratedFilterGroupQuery,
  TError = unknown
>(
  variables: GetCuratedFilterGroupQueryVariables,
  options?: UseQueryOptions<GetCuratedFilterGroupQuery, TError, TData>
) =>
  useQuery<GetCuratedFilterGroupQuery, TError, TData>(
    ['getCuratedFilterGroup', variables],
    createFetcher<
      GetCuratedFilterGroupQuery,
      GetCuratedFilterGroupQueryVariables
    >(GetCuratedFilterGroupDocument, variables),
    options
  )
export const useInfiniteGetCuratedFilterGroupQuery = <
  TData = GetCuratedFilterGroupQuery,
  TError = unknown
>(
  variables: GetCuratedFilterGroupQueryVariables,
  options?: UseInfiniteQueryOptions<GetCuratedFilterGroupQuery, TError, TData>
) => {
  return useInfiniteQuery<GetCuratedFilterGroupQuery, TError, TData>(
    ['getCuratedFilterGroup.infinite', variables],
    (metaData) =>
      createFetcher<
        GetCuratedFilterGroupQuery,
        GetCuratedFilterGroupQueryVariables
      >(GetCuratedFilterGroupDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetFeaturedResearchContentDocument = `
    query getFeaturedResearchContent($preview: Boolean = false, $limit: Int = 20, $articleWhere: CaisiqArticleFilter, $videoWhere: CaisiqVideoFilter, $videoPlaylistWhere: CaisiqVideoPlaylistFilter, $whitepaperWhere: WhitepaperFilter, $externalWhere: ExternalContentFilter, $firmsLimit: Int!) {
  articles: caisiqArticleCollection(
    where: $articleWhere
    order: displayDate_DESC
    preview: $preview
    limit: $limit
  ) {
    items {
      ... on CaisiqArticle {
        ...articleCard
      }
    }
  }
  whitepapers: whitepaperCollection(
    where: $whitepaperWhere
    order: displayDate_DESC
    preview: $preview
    limit: $limit
  ) {
    items {
      ... on Whitepaper {
        ...whitepaperCard
      }
    }
  }
  videos: caisiqVideoCollection(
    where: $videoWhere
    order: displayDate_DESC
    preview: $preview
    limit: $limit
  ) {
    items {
      ... on CaisiqVideo {
        ...videoCard
      }
    }
  }
  videoPlaylists: caisiqVideoPlaylistCollection(
    order: displayDate_DESC
    preview: $preview
    where: $videoPlaylistWhere
    limit: $limit
  ) {
    items {
      ... on CaisiqVideoPlaylist {
        ...videoPlaylistCard
      }
    }
  }
  externalContentCollection(
    where: {AND: [$externalWhere, {type_not: "Fund"}]}
    order: [displayDate_DESC]
    preview: $preview
    limit: $limit
  ) {
    items {
      ... on ExternalContent {
        ...externalContentCard
      }
    }
  }
}
    ${ArticleCardFragmentDoc}
${WhitepaperCardFragmentDoc}
${VideoCardFragmentDoc}
${VideoPlaylistCardFragmentDoc}
${ExternalContentCardFragmentDoc}`
export const useGetFeaturedResearchContentQuery = <
  TData = GetFeaturedResearchContentQuery,
  TError = unknown
>(
  variables: GetFeaturedResearchContentQueryVariables,
  options?: UseQueryOptions<GetFeaturedResearchContentQuery, TError, TData>
) =>
  useQuery<GetFeaturedResearchContentQuery, TError, TData>(
    ['getFeaturedResearchContent', variables],
    createFetcher<
      GetFeaturedResearchContentQuery,
      GetFeaturedResearchContentQueryVariables
    >(GetFeaturedResearchContentDocument, variables),
    options
  )
export const useInfiniteGetFeaturedResearchContentQuery = <
  TData = GetFeaturedResearchContentQuery,
  TError = unknown
>(
  variables: GetFeaturedResearchContentQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetFeaturedResearchContentQuery,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<GetFeaturedResearchContentQuery, TError, TData>(
    ['getFeaturedResearchContent.infinite', variables],
    (metaData) =>
      createFetcher<
        GetFeaturedResearchContentQuery,
        GetFeaturedResearchContentQueryVariables
      >(GetFeaturedResearchContentDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetFeaturedResearchDocument = `
    query getFeaturedResearch($featuredId: String!, $fallbackWhere: FeaturedContentResearchDefaultFilter, $optionsWhere: FeaturedContentResearchOptionsFilter!, $preview: Boolean = false, $firmsLimit: Int!) {
  featured: featuredContentCollection(
    where: {id: $featuredId}
    limit: 1
    preview: $preview
  ) {
    items {
      fallback: researchDefaultCollection(
        limit: 1
        preview: $preview
        where: $fallbackWhere
      ) {
        items {
          ... on CaisiqArticle {
            ...articleCard
          }
          ... on CaisiqVideo {
            ...videoCard
          }
          ... on CaisiqVideoPlaylist {
            ...videoPlaylistCard
          }
          ... on Whitepaper {
            ...whitepaperCard
          }
          ... on ExternalContent {
            ...externalContentCard
          }
          ... on Link {
            ...linkCard
          }
        }
      }
      options: researchOptionsCollection(
        limit: 5
        preview: $preview
        where: $optionsWhere
      ) {
        items {
          ... on CaisiqArticle {
            ...articleCard
          }
          ... on CaisiqVideo {
            ...videoCard
          }
          ... on CaisiqVideoPlaylist {
            ...videoPlaylistCard
          }
          ... on Whitepaper {
            ...whitepaperCard
          }
          ... on ExternalContent {
            ...externalContentCard
          }
          ... on Link {
            ...linkCard
          }
        }
      }
    }
  }
}
    ${ArticleCardFragmentDoc}
${VideoCardFragmentDoc}
${VideoPlaylistCardFragmentDoc}
${WhitepaperCardFragmentDoc}
${ExternalContentCardFragmentDoc}
${LinkCardFragmentDoc}`
export const useGetFeaturedResearchQuery = <
  TData = GetFeaturedResearchQuery,
  TError = unknown
>(
  variables: GetFeaturedResearchQueryVariables,
  options?: UseQueryOptions<GetFeaturedResearchQuery, TError, TData>
) =>
  useQuery<GetFeaturedResearchQuery, TError, TData>(
    ['getFeaturedResearch', variables],
    createFetcher<GetFeaturedResearchQuery, GetFeaturedResearchQueryVariables>(
      GetFeaturedResearchDocument,
      variables
    ),
    options
  )
export const useInfiniteGetFeaturedResearchQuery = <
  TData = GetFeaturedResearchQuery,
  TError = unknown
>(
  variables: GetFeaturedResearchQueryVariables,
  options?: UseInfiniteQueryOptions<GetFeaturedResearchQuery, TError, TData>
) => {
  return useInfiniteQuery<GetFeaturedResearchQuery, TError, TData>(
    ['getFeaturedResearch.infinite', variables],
    (metaData) =>
      createFetcher<
        GetFeaturedResearchQuery,
        GetFeaturedResearchQueryVariables
      >(GetFeaturedResearchDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetFilterGroupByIdDocument = `
    query getFilterGroupById($id: String!, $preview: Boolean = false, $tagsLimit: Int! = 10) {
  filterGroupCollection(where: {id: $id}, limit: 1, preview: $preview) {
    items {
      filtersCollection(limit: 10) {
        items {
          name
          tagsCollection(limit: $tagsLimit) {
            items {
              label
              tagId
            }
          }
        }
      }
    }
  }
}
    `
export const useGetFilterGroupByIdQuery = <
  TData = GetFilterGroupByIdQuery,
  TError = unknown
>(
  variables: GetFilterGroupByIdQueryVariables,
  options?: UseQueryOptions<GetFilterGroupByIdQuery, TError, TData>
) =>
  useQuery<GetFilterGroupByIdQuery, TError, TData>(
    ['getFilterGroupById', variables],
    createFetcher<GetFilterGroupByIdQuery, GetFilterGroupByIdQueryVariables>(
      GetFilterGroupByIdDocument,
      variables
    ),
    options
  )
export const useInfiniteGetFilterGroupByIdQuery = <
  TData = GetFilterGroupByIdQuery,
  TError = unknown
>(
  variables: GetFilterGroupByIdQueryVariables,
  options?: UseInfiniteQueryOptions<GetFilterGroupByIdQuery, TError, TData>
) => {
  return useInfiniteQuery<GetFilterGroupByIdQuery, TError, TData>(
    ['getFilterGroupById.infinite', variables],
    (metaData) =>
      createFetcher<GetFilterGroupByIdQuery, GetFilterGroupByIdQueryVariables>(
        GetFilterGroupByIdDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetLandingPageDocument = `
    query getLandingPage($slug: String!, $preview: Boolean!, $firmsLimit: Int!) {
  pageCollection(where: {slug: $slug}, limit: 1, preview: $preview) {
    items {
      title
      slug
      content {
        ... on PageLanding {
          __typename
          disclaimer {
            title
            body {
              json
            }
          }
          pageType
          name
          sectionsCollection(limit: 20) {
            items {
              __typename
              ... on ComponentText {
                sys {
                  id
                }
                brandIcon: icon
                text {
                  json
                  links {
                    entries {
                      hyperlink {
                        ... on Page {
                          sys {
                            id
                          }
                          name
                          slug
                          content {
                            __typename
                          }
                        }
                      }
                      inline {
                        __typename
                        sys {
                          id
                        }
                        ... on Markdown {
                          text
                          type
                        }
                      }
                    }
                  }
                }
              }
              ... on ComponentSection {
                ...componentSection
              }
            }
          }
        }
      }
    }
  }
}
    ${ComponentSectionFragmentDoc}`
export const useGetLandingPageQuery = <
  TData = GetLandingPageQuery,
  TError = unknown
>(
  variables: GetLandingPageQueryVariables,
  options?: UseQueryOptions<GetLandingPageQuery, TError, TData>
) =>
  useQuery<GetLandingPageQuery, TError, TData>(
    ['getLandingPage', variables],
    createFetcher<GetLandingPageQuery, GetLandingPageQueryVariables>(
      GetLandingPageDocument,
      variables
    ),
    options
  )
export const useInfiniteGetLandingPageQuery = <
  TData = GetLandingPageQuery,
  TError = unknown
>(
  variables: GetLandingPageQueryVariables,
  options?: UseInfiniteQueryOptions<GetLandingPageQuery, TError, TData>
) => {
  return useInfiniteQuery<GetLandingPageQuery, TError, TData>(
    ['getLandingPage.infinite', variables],
    (metaData) =>
      createFetcher<GetLandingPageQuery, GetLandingPageQueryVariables>(
        GetLandingPageDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetPageResourcesDocument = `
    query getPageResources($where: PageResourceFilter!, $preview: Boolean, $limit: Int = 1, $shouldIncludeDate: Boolean = false, $firmsLimit: Int!) {
  pageResourceCollection(limit: $limit, where: $where, preview: $preview) {
    items {
      ... on PageResource {
        ...pageResource
      }
    }
    total
  }
}
    ${PageResourceFragmentDoc}`
export const useGetPageResourcesQuery = <
  TData = GetPageResourcesQuery,
  TError = unknown
>(
  variables: GetPageResourcesQueryVariables,
  options?: UseQueryOptions<GetPageResourcesQuery, TError, TData>
) =>
  useQuery<GetPageResourcesQuery, TError, TData>(
    ['getPageResources', variables],
    createFetcher<GetPageResourcesQuery, GetPageResourcesQueryVariables>(
      GetPageResourcesDocument,
      variables
    ),
    options
  )
export const useInfiniteGetPageResourcesQuery = <
  TData = GetPageResourcesQuery,
  TError = unknown
>(
  variables: GetPageResourcesQueryVariables,
  options?: UseInfiniteQueryOptions<GetPageResourcesQuery, TError, TData>
) => {
  return useInfiniteQuery<GetPageResourcesQuery, TError, TData>(
    ['getPageResources.infinite', variables],
    (metaData) =>
      createFetcher<GetPageResourcesQuery, GetPageResourcesQueryVariables>(
        GetPageResourcesDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetTutorialsPageContentDocument = `
    query getTutorialsPageContent($featuredId: String!, $preview: Boolean = false, $limit: Int = 20, $articleWhere: CaisiqArticleFilter, $videoWhere: CaisiqVideoFilter, $videoPlaylistWhere: CaisiqVideoPlaylistFilter, $whitepaperWhere: WhitepaperFilter, $includeFeatured: Boolean = false, $firmsLimit: Int!) {
  featured: featuredContentCollection(
    where: {id: $featuredId}
    limit: 1
    preview: $preview
  ) {
    items @include(if: $includeFeatured) {
      fallback: tutorialsDefaultCollection(
        limit: 1
        preview: $preview
        where: {category: "tutorials"}
      ) {
        items {
          ... on CaisiqArticle {
            ...articleCard
          }
          ... on CaisiqVideo {
            ...videoCard
          }
          ... on CaisiqVideoPlaylist {
            ...videoPlaylistCard
          }
          ... on Whitepaper {
            ...whitepaperCard
          }
          ... on Link {
            ...linkCard
          }
        }
      }
      options: tutorialsOptionsCollection(
        limit: 5
        preview: $preview
        where: {category: "tutorials"}
      ) {
        items @include(if: $includeFeatured) {
          ... on CaisiqArticle {
            ...articleCard
          }
          ... on CaisiqVideo {
            ...videoCard
          }
          ... on CaisiqVideoPlaylist {
            ...videoPlaylistCard
          }
          ... on Whitepaper {
            ...whitepaperCard
          }
          ... on Link {
            ...linkCard
          }
        }
      }
    }
  }
  caisiqArticleCollection(
    where: $articleWhere
    order: [displayDate_DESC]
    limit: $limit
    preview: $preview
  ) {
    total
    items {
      ... on CaisiqArticle {
        ...articleCard
      }
    }
  }
  caisiqVideoPlaylistCollection(
    where: $videoPlaylistWhere
    limit: $limit
    preview: $preview
    order: displayDate_DESC
  ) {
    total
    items {
      ... on CaisiqVideoPlaylist {
        ...videoPlaylistCard
      }
    }
  }
  caisiqVideoCollection(
    where: $videoWhere
    order: [displayDate_DESC]
    limit: $limit
    preview: $preview
  ) {
    total
    items {
      ... on CaisiqVideo {
        ...videoCard
      }
    }
  }
  whitepaperCollection(
    where: $whitepaperWhere
    order: [displayDate_DESC]
    limit: $limit
    preview: $preview
  ) {
    total
    items {
      ... on Whitepaper {
        ...whitepaperCard
      }
    }
  }
}
    ${ArticleCardFragmentDoc}
${VideoCardFragmentDoc}
${VideoPlaylistCardFragmentDoc}
${WhitepaperCardFragmentDoc}
${LinkCardFragmentDoc}`
export const useGetTutorialsPageContentQuery = <
  TData = GetTutorialsPageContentQuery,
  TError = unknown
>(
  variables: GetTutorialsPageContentQueryVariables,
  options?: UseQueryOptions<GetTutorialsPageContentQuery, TError, TData>
) =>
  useQuery<GetTutorialsPageContentQuery, TError, TData>(
    ['getTutorialsPageContent', variables],
    createFetcher<
      GetTutorialsPageContentQuery,
      GetTutorialsPageContentQueryVariables
    >(GetTutorialsPageContentDocument, variables),
    options
  )
export const useInfiniteGetTutorialsPageContentQuery = <
  TData = GetTutorialsPageContentQuery,
  TError = unknown
>(
  variables: GetTutorialsPageContentQueryVariables,
  options?: UseInfiniteQueryOptions<GetTutorialsPageContentQuery, TError, TData>
) => {
  return useInfiniteQuery<GetTutorialsPageContentQuery, TError, TData>(
    ['getTutorialsPageContent.infinite', variables],
    (metaData) =>
      createFetcher<
        GetTutorialsPageContentQuery,
        GetTutorialsPageContentQueryVariables
      >(GetTutorialsPageContentDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetVideoDocument = `
    query getVideo($id: String!, $preview: Boolean = false, $firmsLimit: Int!) {
  caisiqVideoCollection(
    order: [displayDate_DESC]
    limit: 1
    preview: $preview
    where: {sys: {id: $id}}
  ) {
    items {
      ...videoPage
    }
    total
  }
}
    ${VideoPageFragmentDoc}`
export const useGetVideoQuery = <TData = GetVideoQuery, TError = unknown>(
  variables: GetVideoQueryVariables,
  options?: UseQueryOptions<GetVideoQuery, TError, TData>
) =>
  useQuery<GetVideoQuery, TError, TData>(
    ['getVideo', variables],
    createFetcher<GetVideoQuery, GetVideoQueryVariables>(
      GetVideoDocument,
      variables
    ),
    options
  )
export const useInfiniteGetVideoQuery = <
  TData = GetVideoQuery,
  TError = unknown
>(
  variables: GetVideoQueryVariables,
  options?: UseInfiniteQueryOptions<GetVideoQuery, TError, TData>
) => {
  return useInfiniteQuery<GetVideoQuery, TError, TData>(
    ['getVideo.infinite', variables],
    (metaData) =>
      createFetcher<GetVideoQuery, GetVideoQueryVariables>(GetVideoDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetVideoPermissionsDocument = `
    query getVideoPermissions($id: String!, $firmsLimit: Int!) {
  caisiqVideoCollection(limit: 1, where: {sys: {id: $id}}) {
    total
    items {
      ... on CaisiqVideo {
        fundProductIds {
          fundProductIds
        }
        firmsCollection(limit: $firmsLimit) {
          items {
            id
          }
        }
      }
    }
  }
}
    `
export const useGetVideoPermissionsQuery = <
  TData = GetVideoPermissionsQuery,
  TError = unknown
>(
  variables: GetVideoPermissionsQueryVariables,
  options?: UseQueryOptions<GetVideoPermissionsQuery, TError, TData>
) =>
  useQuery<GetVideoPermissionsQuery, TError, TData>(
    ['getVideoPermissions', variables],
    createFetcher<GetVideoPermissionsQuery, GetVideoPermissionsQueryVariables>(
      GetVideoPermissionsDocument,
      variables
    ),
    options
  )
export const useInfiniteGetVideoPermissionsQuery = <
  TData = GetVideoPermissionsQuery,
  TError = unknown
>(
  variables: GetVideoPermissionsQueryVariables,
  options?: UseInfiniteQueryOptions<GetVideoPermissionsQuery, TError, TData>
) => {
  return useInfiniteQuery<GetVideoPermissionsQuery, TError, TData>(
    ['getVideoPermissions.infinite', variables],
    (metaData) =>
      createFetcher<
        GetVideoPermissionsQuery,
        GetVideoPermissionsQueryVariables
      >(GetVideoPermissionsDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetVideoPlaylistsDocument = `
    query getVideoPlaylists($preview: Boolean = false, $limit: Int = 40, $firmsLimit: Int!) {
  caisiqVideoPlaylistCollection(
    limit: $limit
    preview: $preview
    order: displayDate_DESC
  ) {
    items {
      ... on CaisiqVideoPlaylist {
        ...videoPlaylistCard
      }
    }
  }
}
    ${VideoPlaylistCardFragmentDoc}`
export const useGetVideoPlaylistsQuery = <
  TData = GetVideoPlaylistsQuery,
  TError = unknown
>(
  variables: GetVideoPlaylistsQueryVariables,
  options?: UseQueryOptions<GetVideoPlaylistsQuery, TError, TData>
) =>
  useQuery<GetVideoPlaylistsQuery, TError, TData>(
    ['getVideoPlaylists', variables],
    createFetcher<GetVideoPlaylistsQuery, GetVideoPlaylistsQueryVariables>(
      GetVideoPlaylistsDocument,
      variables
    ),
    options
  )
export const useInfiniteGetVideoPlaylistsQuery = <
  TData = GetVideoPlaylistsQuery,
  TError = unknown
>(
  variables: GetVideoPlaylistsQueryVariables,
  options?: UseInfiniteQueryOptions<GetVideoPlaylistsQuery, TError, TData>
) => {
  return useInfiniteQuery<GetVideoPlaylistsQuery, TError, TData>(
    ['getVideoPlaylists.infinite', variables],
    (metaData) =>
      createFetcher<GetVideoPlaylistsQuery, GetVideoPlaylistsQueryVariables>(
        GetVideoPlaylistsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetVideosDocument = `
    query getVideos($preview: Boolean = false, $where: CaisiqVideoFilter, $limit: Int = 40, $firmsLimit: Int!) {
  caisiqVideoCollection(
    order: [displayDate_DESC]
    limit: $limit
    preview: $preview
    where: $where
  ) {
    total
    items {
      ... on CaisiqVideo {
        ...videoCard
      }
    }
  }
}
    ${VideoCardFragmentDoc}`
export const useGetVideosQuery = <TData = GetVideosQuery, TError = unknown>(
  variables: GetVideosQueryVariables,
  options?: UseQueryOptions<GetVideosQuery, TError, TData>
) =>
  useQuery<GetVideosQuery, TError, TData>(
    ['getVideos', variables],
    createFetcher<GetVideosQuery, GetVideosQueryVariables>(
      GetVideosDocument,
      variables
    ),
    options
  )
export const useInfiniteGetVideosQuery = <
  TData = GetVideosQuery,
  TError = unknown
>(
  variables: GetVideosQueryVariables,
  options?: UseInfiniteQueryOptions<GetVideosQuery, TError, TData>
) => {
  return useInfiniteQuery<GetVideosQuery, TError, TData>(
    ['getVideos.infinite', variables],
    (metaData) =>
      createFetcher<GetVideosQuery, GetVideosQueryVariables>(
        GetVideosDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetWhitepaperBySlugDocument = `
    query getWhitepaperBySlug($slug: String!, $preview: Boolean = false, $firmsLimit: Int!) {
  whitepaperCollection(where: {slug: $slug}, preview: $preview, limit: 1) {
    items {
      ...whitepaperPage
    }
    total
  }
}
    ${WhitepaperPageFragmentDoc}`
export const useGetWhitepaperBySlugQuery = <
  TData = GetWhitepaperBySlugQuery,
  TError = unknown
>(
  variables: GetWhitepaperBySlugQueryVariables,
  options?: UseQueryOptions<GetWhitepaperBySlugQuery, TError, TData>
) =>
  useQuery<GetWhitepaperBySlugQuery, TError, TData>(
    ['getWhitepaperBySlug', variables],
    createFetcher<GetWhitepaperBySlugQuery, GetWhitepaperBySlugQueryVariables>(
      GetWhitepaperBySlugDocument,
      variables
    ),
    options
  )
export const useInfiniteGetWhitepaperBySlugQuery = <
  TData = GetWhitepaperBySlugQuery,
  TError = unknown
>(
  variables: GetWhitepaperBySlugQueryVariables,
  options?: UseInfiniteQueryOptions<GetWhitepaperBySlugQuery, TError, TData>
) => {
  return useInfiniteQuery<GetWhitepaperBySlugQuery, TError, TData>(
    ['getWhitepaperBySlug.infinite', variables],
    (metaData) =>
      createFetcher<
        GetWhitepaperBySlugQuery,
        GetWhitepaperBySlugQueryVariables
      >(GetWhitepaperBySlugDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetWhitepaperPermissionDataDocument = `
    query getWhitepaperPermissionData($slug: String!, $firmsLimit: Int!) {
  whitepaperCollection(limit: 1, where: {slug: $slug}) {
    total
    items {
      ... on Whitepaper {
        fundProductIds {
          fundProductIds
        }
        firmsCollection(limit: $firmsLimit) {
          items {
            id
          }
        }
      }
    }
  }
}
    `
export const useGetWhitepaperPermissionDataQuery = <
  TData = GetWhitepaperPermissionDataQuery,
  TError = unknown
>(
  variables: GetWhitepaperPermissionDataQueryVariables,
  options?: UseQueryOptions<GetWhitepaperPermissionDataQuery, TError, TData>
) =>
  useQuery<GetWhitepaperPermissionDataQuery, TError, TData>(
    ['getWhitepaperPermissionData', variables],
    createFetcher<
      GetWhitepaperPermissionDataQuery,
      GetWhitepaperPermissionDataQueryVariables
    >(GetWhitepaperPermissionDataDocument, variables),
    options
  )
export const useInfiniteGetWhitepaperPermissionDataQuery = <
  TData = GetWhitepaperPermissionDataQuery,
  TError = unknown
>(
  variables: GetWhitepaperPermissionDataQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetWhitepaperPermissionDataQuery,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<GetWhitepaperPermissionDataQuery, TError, TData>(
    ['getWhitepaperPermissionData.infinite', variables],
    (metaData) =>
      createFetcher<
        GetWhitepaperPermissionDataQuery,
        GetWhitepaperPermissionDataQueryVariables
      >(GetWhitepaperPermissionDataDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetWhitepaperCollectionDocument = `
    query getWhitepaperCollection($preview: Boolean = false, $where: WhitepaperFilter, $limit: Int = 40, $firmsLimit: Int!) {
  whitepaperCollection(
    order: [displayDate_DESC]
    limit: $limit
    preview: $preview
    where: $where
  ) {
    total
    items {
      ... on Whitepaper {
        ...whitepaperCard
      }
    }
  }
}
    ${WhitepaperCardFragmentDoc}`
export const useGetWhitepaperCollectionQuery = <
  TData = GetWhitepaperCollectionQuery,
  TError = unknown
>(
  variables: GetWhitepaperCollectionQueryVariables,
  options?: UseQueryOptions<GetWhitepaperCollectionQuery, TError, TData>
) =>
  useQuery<GetWhitepaperCollectionQuery, TError, TData>(
    ['getWhitepaperCollection', variables],
    createFetcher<
      GetWhitepaperCollectionQuery,
      GetWhitepaperCollectionQueryVariables
    >(GetWhitepaperCollectionDocument, variables),
    options
  )
export const useInfiniteGetWhitepaperCollectionQuery = <
  TData = GetWhitepaperCollectionQuery,
  TError = unknown
>(
  variables: GetWhitepaperCollectionQueryVariables,
  options?: UseInfiniteQueryOptions<GetWhitepaperCollectionQuery, TError, TData>
) => {
  return useInfiniteQuery<GetWhitepaperCollectionQuery, TError, TData>(
    ['getWhitepaperCollection.infinite', variables],
    (metaData) =>
      createFetcher<
        GetWhitepaperCollectionQuery,
        GetWhitepaperCollectionQueryVariables
      >(GetWhitepaperCollectionDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetAnnouncementsDocument = `
    query getAnnouncements($id: String!, $date: DateTime) {
  announcementSetCollection(limit: 1, where: {id: $id}) {
    total
    items {
      excludedUrls
      announcementDefault(
        where: {AND: [{dateTimeStart_lte: $date}, {OR: [{dateTimeEnd_gte: $date}, {dateTimeEnd_exists: false}]}]}
      ) {
        ...announcement
      }
      announcementOptionsCollection(
        limit: 5
        where: {AND: [{dateTimeStart_lte: $date}, {OR: [{dateTimeEnd_gte: $date}, {dateTimeEnd_exists: false}]}]}
        order: [dateTimeEnd_ASC]
      ) {
        total
        items {
          ...announcement
        }
      }
    }
  }
}
    ${AnnouncementFragmentDoc}`
export const useGetAnnouncementsQuery = <
  TData = GetAnnouncementsQuery,
  TError = unknown
>(
  variables: GetAnnouncementsQueryVariables,
  options?: UseQueryOptions<GetAnnouncementsQuery, TError, TData>
) =>
  useQuery<GetAnnouncementsQuery, TError, TData>(
    ['getAnnouncements', variables],
    createFetcher<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>(
      GetAnnouncementsDocument,
      variables
    ),
    options
  )
export const useInfiniteGetAnnouncementsQuery = <
  TData = GetAnnouncementsQuery,
  TError = unknown
>(
  variables: GetAnnouncementsQueryVariables,
  options?: UseInfiniteQueryOptions<GetAnnouncementsQuery, TError, TData>
) => {
  return useInfiniteQuery<GetAnnouncementsQuery, TError, TData>(
    ['getAnnouncements.infinite', variables],
    (metaData) =>
      createFetcher<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>(
        GetAnnouncementsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetCallToActionByIdAndFirmDocument = `
    query getCallToActionByIdAndFirm($id: String!, $preview: Boolean = false, $firms: [String!]!) {
  callToActionCollection(
    preview: $preview
    where: {AND: [{id: $id}, {firm: {id_in: $firms}}]}
    limit: 1
  ) {
    items {
      ...callToActionFields
    }
  }
}
    ${CallToActionFieldsFragmentDoc}`
export const useGetCallToActionByIdAndFirmQuery = <
  TData = GetCallToActionByIdAndFirmQuery,
  TError = unknown
>(
  variables: GetCallToActionByIdAndFirmQueryVariables,
  options?: UseQueryOptions<GetCallToActionByIdAndFirmQuery, TError, TData>
) =>
  useQuery<GetCallToActionByIdAndFirmQuery, TError, TData>(
    ['getCallToActionByIdAndFirm', variables],
    createFetcher<
      GetCallToActionByIdAndFirmQuery,
      GetCallToActionByIdAndFirmQueryVariables
    >(GetCallToActionByIdAndFirmDocument, variables),
    options
  )
export const useInfiniteGetCallToActionByIdAndFirmQuery = <
  TData = GetCallToActionByIdAndFirmQuery,
  TError = unknown
>(
  variables: GetCallToActionByIdAndFirmQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetCallToActionByIdAndFirmQuery,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<GetCallToActionByIdAndFirmQuery, TError, TData>(
    ['getCallToActionByIdAndFirm.infinite', variables],
    (metaData) =>
      createFetcher<
        GetCallToActionByIdAndFirmQuery,
        GetCallToActionByIdAndFirmQueryVariables
      >(GetCallToActionByIdAndFirmDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetEventsDocument = `
    query getEvents($where: EventFilter, $order: EventOrder, $limit: Int!, $preview: Boolean = false, $firmsLimit: Int!, $tagsLimit: Int!) {
  eventCollection(
    preview: $preview
    where: $where
    order: [$order]
    limit: $limit
  ) {
    total
    items {
      ... on Event {
        ...eventCard
      }
    }
  }
}
    ${EventCardFragmentDoc}`
export const useGetEventsQuery = <TData = GetEventsQuery, TError = unknown>(
  variables: GetEventsQueryVariables,
  options?: UseQueryOptions<GetEventsQuery, TError, TData>
) =>
  useQuery<GetEventsQuery, TError, TData>(
    ['getEvents', variables],
    createFetcher<GetEventsQuery, GetEventsQueryVariables>(
      GetEventsDocument,
      variables
    ),
    options
  )
export const useInfiniteGetEventsQuery = <
  TData = GetEventsQuery,
  TError = unknown
>(
  variables: GetEventsQueryVariables,
  options?: UseInfiniteQueryOptions<GetEventsQuery, TError, TData>
) => {
  return useInfiniteQuery<GetEventsQuery, TError, TData>(
    ['getEvents.infinite', variables],
    (metaData) =>
      createFetcher<GetEventsQuery, GetEventsQueryVariables>(
        GetEventsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetFaQsCategoriesDocument = `
    query getFAQsCategories($order: FaqCategoryOrder) {
  faqCategoryCollection(order: [$order]) {
    items {
      ...faqCategory
    }
  }
}
    ${FaqCategoryFragmentDoc}`
export const useGetFaQsCategoriesQuery = <
  TData = GetFaQsCategoriesQuery,
  TError = unknown
>(
  variables?: GetFaQsCategoriesQueryVariables,
  options?: UseQueryOptions<GetFaQsCategoriesQuery, TError, TData>
) =>
  useQuery<GetFaQsCategoriesQuery, TError, TData>(
    variables === undefined
      ? ['getFAQsCategories']
      : ['getFAQsCategories', variables],
    createFetcher<GetFaQsCategoriesQuery, GetFaQsCategoriesQueryVariables>(
      GetFaQsCategoriesDocument,
      variables
    ),
    options
  )
export const useInfiniteGetFaQsCategoriesQuery = <
  TData = GetFaQsCategoriesQuery,
  TError = unknown
>(
  variables?: GetFaQsCategoriesQueryVariables,
  options?: UseInfiniteQueryOptions<GetFaQsCategoriesQuery, TError, TData>
) => {
  return useInfiniteQuery<GetFaQsCategoriesQuery, TError, TData>(
    variables === undefined
      ? ['getFAQsCategories.infinite']
      : ['getFAQsCategories.infinite', variables],
    (metaData) =>
      createFetcher<GetFaQsCategoriesQuery, GetFaQsCategoriesQueryVariables>(
        GetFaQsCategoriesDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetFaQsByCategoryDocument = `
    query getFAQsByCategory($id: String!) {
  faqCategoryCollection(where: {id: $id}, limit: 1) {
    items {
      title
      faqsCollection {
        items {
          ...faq
        }
      }
    }
  }
}
    ${FaqFragmentDoc}`
export const useGetFaQsByCategoryQuery = <
  TData = GetFaQsByCategoryQuery,
  TError = unknown
>(
  variables: GetFaQsByCategoryQueryVariables,
  options?: UseQueryOptions<GetFaQsByCategoryQuery, TError, TData>
) =>
  useQuery<GetFaQsByCategoryQuery, TError, TData>(
    ['getFAQsByCategory', variables],
    createFetcher<GetFaQsByCategoryQuery, GetFaQsByCategoryQueryVariables>(
      GetFaQsByCategoryDocument,
      variables
    ),
    options
  )
export const useInfiniteGetFaQsByCategoryQuery = <
  TData = GetFaQsByCategoryQuery,
  TError = unknown
>(
  variables: GetFaQsByCategoryQueryVariables,
  options?: UseInfiniteQueryOptions<GetFaQsByCategoryQuery, TError, TData>
) => {
  return useInfiniteQuery<GetFaQsByCategoryQuery, TError, TData>(
    ['getFAQsByCategory.infinite', variables],
    (metaData) =>
      createFetcher<GetFaQsByCategoryQuery, GetFaQsByCategoryQueryVariables>(
        GetFaQsByCategoryDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetFaQsDocument = `
    query getFAQs($searchTerm: String) {
  faqCollection(
    where: {OR: [{answer_contains: $searchTerm}, {faq_contains: $searchTerm}]}
    order: [faq_ASC]
  ) {
    items {
      __typename
      sys {
        id
      }
      ...faq
    }
  }
}
    ${FaqFragmentDoc}`
export const useGetFaQsQuery = <TData = GetFaQsQuery, TError = unknown>(
  variables?: GetFaQsQueryVariables,
  options?: UseQueryOptions<GetFaQsQuery, TError, TData>
) =>
  useQuery<GetFaQsQuery, TError, TData>(
    variables === undefined ? ['getFAQs'] : ['getFAQs', variables],
    createFetcher<GetFaQsQuery, GetFaQsQueryVariables>(
      GetFaQsDocument,
      variables
    ),
    options
  )
export const useInfiniteGetFaQsQuery = <TData = GetFaQsQuery, TError = unknown>(
  variables?: GetFaQsQueryVariables,
  options?: UseInfiniteQueryOptions<GetFaQsQuery, TError, TData>
) => {
  return useInfiniteQuery<GetFaQsQuery, TError, TData>(
    variables === undefined
      ? ['getFAQs.infinite']
      : ['getFAQs.infinite', variables],
    (metaData) =>
      createFetcher<GetFaQsQuery, GetFaQsQueryVariables>(GetFaQsDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetGlossaryDocument = `
    query getGlossary($preview: Boolean = false) {
  glossaryTermCollection(preview: $preview, order: glossaryTerm_ASC, limit: 500) {
    items {
      definition
      id
      term: glossaryTerm
    }
  }
}
    `
export const useGetGlossaryQuery = <TData = GetGlossaryQuery, TError = unknown>(
  variables?: GetGlossaryQueryVariables,
  options?: UseQueryOptions<GetGlossaryQuery, TError, TData>
) =>
  useQuery<GetGlossaryQuery, TError, TData>(
    variables === undefined ? ['getGlossary'] : ['getGlossary', variables],
    createFetcher<GetGlossaryQuery, GetGlossaryQueryVariables>(
      GetGlossaryDocument,
      variables
    ),
    options
  )
export const useInfiniteGetGlossaryQuery = <
  TData = GetGlossaryQuery,
  TError = unknown
>(
  variables?: GetGlossaryQueryVariables,
  options?: UseInfiniteQueryOptions<GetGlossaryQuery, TError, TData>
) => {
  return useInfiniteQuery<GetGlossaryQuery, TError, TData>(
    variables === undefined
      ? ['getGlossary.infinite']
      : ['getGlossary.infinite', variables],
    (metaData) =>
      createFetcher<GetGlossaryQuery, GetGlossaryQueryVariables>(
        GetGlossaryDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetHomepageLandingPageDocument = `
    query getHomepageLandingPage($slug: String!, $preview: Boolean) {
  homepageLandingPageCollection(where: {slug: $slug}, limit: 1, preview: $preview) {
    items {
      ...homepageLandingPage
    }
    total
  }
}
    ${HomepageLandingPageFragmentDoc}`
export const useGetHomepageLandingPageQuery = <
  TData = GetHomepageLandingPageQuery,
  TError = unknown
>(
  variables: GetHomepageLandingPageQueryVariables,
  options?: UseQueryOptions<GetHomepageLandingPageQuery, TError, TData>
) =>
  useQuery<GetHomepageLandingPageQuery, TError, TData>(
    ['getHomepageLandingPage', variables],
    createFetcher<
      GetHomepageLandingPageQuery,
      GetHomepageLandingPageQueryVariables
    >(GetHomepageLandingPageDocument, variables),
    options
  )
export const useInfiniteGetHomepageLandingPageQuery = <
  TData = GetHomepageLandingPageQuery,
  TError = unknown
>(
  variables: GetHomepageLandingPageQueryVariables,
  options?: UseInfiniteQueryOptions<GetHomepageLandingPageQuery, TError, TData>
) => {
  return useInfiniteQuery<GetHomepageLandingPageQuery, TError, TData>(
    ['getHomepageLandingPage.infinite', variables],
    (metaData) =>
      createFetcher<
        GetHomepageLandingPageQuery,
        GetHomepageLandingPageQueryVariables
      >(GetHomepageLandingPageDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetTermsAndConditionsLegalPageBySlugDocument = `
    query getTermsAndConditionsLegalPageBySlug($slug: String!, $preview: Boolean = false) {
  legalPageCollection(where: {slug: $slug}, preview: $preview, limit: 1) {
    items {
      sys {
        id
      }
      slug
      pdfsCollection(limit: 1) {
        items {
          sys {
            id
          }
          __typename
          title
          pdf {
            url
          }
        }
      }
    }
  }
}
    `
export const useGetTermsAndConditionsLegalPageBySlugQuery = <
  TData = GetTermsAndConditionsLegalPageBySlugQuery,
  TError = unknown
>(
  variables: GetTermsAndConditionsLegalPageBySlugQueryVariables,
  options?: UseQueryOptions<
    GetTermsAndConditionsLegalPageBySlugQuery,
    TError,
    TData
  >
) =>
  useQuery<GetTermsAndConditionsLegalPageBySlugQuery, TError, TData>(
    ['getTermsAndConditionsLegalPageBySlug', variables],
    createFetcher<
      GetTermsAndConditionsLegalPageBySlugQuery,
      GetTermsAndConditionsLegalPageBySlugQueryVariables
    >(GetTermsAndConditionsLegalPageBySlugDocument, variables),
    options
  )
export const useInfiniteGetTermsAndConditionsLegalPageBySlugQuery = <
  TData = GetTermsAndConditionsLegalPageBySlugQuery,
  TError = unknown
>(
  variables: GetTermsAndConditionsLegalPageBySlugQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetTermsAndConditionsLegalPageBySlugQuery,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<
    GetTermsAndConditionsLegalPageBySlugQuery,
    TError,
    TData
  >(
    ['getTermsAndConditionsLegalPageBySlug.infinite', variables],
    (metaData) =>
      createFetcher<
        GetTermsAndConditionsLegalPageBySlugQuery,
        GetTermsAndConditionsLegalPageBySlugQueryVariables
      >(GetTermsAndConditionsLegalPageBySlugDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetAllVideosDocument = `
    query getAllVideos($limit: Int!, $preview: Boolean = false, $wherePlaylist: CaisiqVideoPlaylistFilter, $whereVideo: CaisiqVideoFilter, $orderPlaylist: CaisiqVideoPlaylistOrder, $orderVideo: CaisiqVideoOrder, $firmsLimit: Int!) {
  caisiqVideoPlaylistCollection(
    limit: $limit
    preview: $preview
    order: [$orderPlaylist]
    where: $wherePlaylist
  ) {
    items {
      ... on CaisiqVideoPlaylist {
        ...videoPlaylistCard
      }
    }
  }
  caisiqVideoCollection(
    order: [$orderVideo]
    limit: $limit
    preview: $preview
    where: $whereVideo
  ) {
    total
    items {
      ... on CaisiqVideo {
        ...videoCard
      }
    }
  }
}
    ${VideoPlaylistCardFragmentDoc}
${VideoCardFragmentDoc}`
export const useGetAllVideosQuery = <
  TData = GetAllVideosQuery,
  TError = unknown
>(
  variables: GetAllVideosQueryVariables,
  options?: UseQueryOptions<GetAllVideosQuery, TError, TData>
) =>
  useQuery<GetAllVideosQuery, TError, TData>(
    ['getAllVideos', variables],
    createFetcher<GetAllVideosQuery, GetAllVideosQueryVariables>(
      GetAllVideosDocument,
      variables
    ),
    options
  )
export const useInfiniteGetAllVideosQuery = <
  TData = GetAllVideosQuery,
  TError = unknown
>(
  variables: GetAllVideosQueryVariables,
  options?: UseInfiniteQueryOptions<GetAllVideosQuery, TError, TData>
) => {
  return useInfiniteQuery<GetAllVideosQuery, TError, TData>(
    ['getAllVideos.infinite', variables],
    (metaData) =>
      createFetcher<GetAllVideosQuery, GetAllVideosQueryVariables>(
        GetAllVideosDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetVideoPlaylistBySlugDocument = `
    query getVideoPlaylistBySlug($slug: String) {
  caisiqVideoPlaylistCollection(limit: 1, where: {slug: $slug}) {
    items {
      ...videoPlaylistFields
    }
  }
}
    ${VideoPlaylistFieldsFragmentDoc}`
export const useGetVideoPlaylistBySlugQuery = <
  TData = GetVideoPlaylistBySlugQuery,
  TError = unknown
>(
  variables?: GetVideoPlaylistBySlugQueryVariables,
  options?: UseQueryOptions<GetVideoPlaylistBySlugQuery, TError, TData>
) =>
  useQuery<GetVideoPlaylistBySlugQuery, TError, TData>(
    variables === undefined
      ? ['getVideoPlaylistBySlug']
      : ['getVideoPlaylistBySlug', variables],
    createFetcher<
      GetVideoPlaylistBySlugQuery,
      GetVideoPlaylistBySlugQueryVariables
    >(GetVideoPlaylistBySlugDocument, variables),
    options
  )
export const useInfiniteGetVideoPlaylistBySlugQuery = <
  TData = GetVideoPlaylistBySlugQuery,
  TError = unknown
>(
  variables?: GetVideoPlaylistBySlugQueryVariables,
  options?: UseInfiniteQueryOptions<GetVideoPlaylistBySlugQuery, TError, TData>
) => {
  return useInfiniteQuery<GetVideoPlaylistBySlugQuery, TError, TData>(
    variables === undefined
      ? ['getVideoPlaylistBySlug.infinite']
      : ['getVideoPlaylistBySlug.infinite', variables],
    (metaData) =>
      createFetcher<
        GetVideoPlaylistBySlugQuery,
        GetVideoPlaylistBySlugQueryVariables
      >(GetVideoPlaylistBySlugDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}
