import cx from 'classnames'
import { ReactNode, useEffect, useId } from 'react'

import { AllColor } from '../../../../particles/colors'
import { IconType } from '../../../../particles/icons'
import { Icon } from '../../../atoms/icon'
import { IconButton } from '../../../atoms/icon-button'

import { BaseModal, ModalVariants } from './base-modal'
import { ModalActions, ModalActionType } from './modal-actions'
import { ModalControlProps } from './useModal'
import { useScrollableContent } from './useScrollableContent'

/** Properties for the Modal component. */
export type ModalProps = {
  /** The optional actions to be rendered within the modal. */
  actions?: ModalActionType[]
  /** The title for the modal. */
  title: string
  /** The content to be rendered within the modal. */
  children: ReactNode
  /** The control props for managing modal state. */
  control: ModalControlProps
  /** The optional callback to be invoked when the modal is closed. */
  onClose?: () => void
  /** The optional variant of the modal. */
  variant?: `${ModalVariants}`
  /** Whether the backdrop is transparent or not. */
  transparentBackdrop?: boolean
  /** Whether the modal is dismissible or not. */
  isDismissible?: boolean
  /** test id for container; NB this maybe rendered when modal not visible so take care */
  testId?: string
} & (
  | {
      /** The optional icon to be displayed alongside the title. */
      titleIcon: IconType
      /** The optional color for the title icon. */
      titleIconColor: AllColor
    }
  | {
      titleIcon?: never
      titleIconColor?: never
    }
)

/**
 * A modal component that renders a dialog with a set of actions and a title.
 */
export const Modal = ({
  actions = [],
  title,
  children,
  control,
  titleIcon,
  titleIconColor,
  variant = ModalVariants.Default,
  transparentBackdrop = false,
  isDismissible = true,
  onClose,
  testId,
}: ModalProps) => {
  const { scrollableContentRef, hasScrollableContent, isScrolledToBottom } =
    useScrollableContent(control, children)
  const dialogTitleId = useId()

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key !== 'Escape') return
      if (!isDismissible) event.preventDefault()
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isDismissible])

  return (
    <BaseModal
      control={control}
      variant={variant}
      transparentBackdrop={transparentBackdrop}
      onBackdropClick={() => {
        if (!isDismissible) {
          return
        }
        control.modalRef.current?.close()
        onClose?.()
      }}
      aria-labelledby={dialogTitleId}
    >
      <div
        className={cx(
          'flex flex-col p-32',
          variant === ModalVariants.Small && 'h-[400px]',
          variant === ModalVariants.Default &&
            'max-h-[90vh] max-sm:max-h-screen',
          variant === ModalVariants.Large && 'h-full max-sm:max-h-screen'
        )}
      >
        <div
          className="flex w-full flex-shrink-0 items-start justify-between border-0 border-b border-solid border-neutral-200 pb-16"
          data-testid={testId}
        >
          <h1
            id={dialogTitleId}
            className="headline-m-strong flex-column flex items-center gap-24"
          >
            {titleIcon && (
              <Icon type={titleIcon} color={titleIconColor} size="large" />
            )}
            {title}
          </h1>
          {variant === ModalVariants.Large && actions.length ? (
            <ModalActions actions={actions} />
          ) : isDismissible ? (
            <IconButton
              iconType="Close"
              color="neutral"
              type="reset"
              onClick={(event) => {
                event.preventDefault()
                onClose?.()
                control.closeModal()
              }}
              aria-label="Close modal"
              title="Close modal"
            />
          ) : null}
        </div>
        <div
          className={cx(
            'mt-16 grow overflow-auto',
            variant === ModalVariants.Large && 'sm:max-h-[80vh]',
            hasScrollableContent &&
              !isScrolledToBottom &&
              'shadow-[inset_0px_-5px_5px_-5px_rgba(0,0,0,.2)]'
          )}
          ref={scrollableContentRef}
          data-testid="contentContainer"
        >
          {children}
        </div>
        {variant !== ModalVariants.Large && actions.length ? (
          <div className="flex flex-shrink-0 flex-col justify-end pt-16">
            <ModalActions actions={actions} />
          </div>
        ) : null}
      </div>
    </BaseModal>
  )
}
