import dayjs, { extend } from 'dayjs'
import utc from 'dayjs/plugin/utc'

extend(utc)

const validDate = (date: string) => {
  const utcDate = dayjs.utc(date)
  return utcDate.isValid() ? utcDate : null
}

export const publishedDateFormat = (date: string, useShortMonth?: boolean) => {
  const dateToFormat = validDate(date)
  return dateToFormat
    ? dateToFormat.format(`${useShortMonth ? 'MMM' : 'MMMM'} D, YYYY`)
    : ''
}

export const getYear = (date: string): number => dayjs.utc(date).year()

export const getTime = (date: string): string =>
  dayjs.utc(date).format('h:mm A')

export const today = (format = 'YYYY-MM-DD') => dayjs().format(format)
